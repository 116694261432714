import type {GetTransactionResponse, SaveRequest} from './serverProtocol'
import {nextTransactionId, TestServer} from './TestServer'
import _ from 'lodash'

export enum AutoAction {
    Approve,
    Reject,
    Ignore
}

export class MockCEditTestServer extends TestServer {
    constructor(private autoAction: AutoAction = AutoAction.Approve) {
        super()
    }

    autoApprove() {
        this.autoAction = AutoAction.Approve
    }
    autoReject() {
        this.autoAction = AutoAction.Reject
    }
    autoIgnore() {
        this.autoAction = AutoAction.Ignore
    }

    nextStep() {
        if (this.autoAction === AutoAction.Reject || this.duplexer.shouldReject()) {
            return AutoAction.Reject
        }
        if (this.autoAction === AutoAction.Approve) {
            return AutoAction.Approve
        }
        return AutoAction.Ignore
    }

    async asyncSave(payload: SaveRequest): Promise<void> {
        let transactionId = this.server.last
        const next = this.nextStep()
        payload.transactions!.forEach(t => {
            const correlationId = t.correlationId!
            if (next === AutoAction.Approve) {
                if (_.isEmpty(t.actions)) {
                    this.duplexer?.approved({correlationId, transactionId: null})
                } else {
                    transactionId = nextTransactionId(transactionId)
                    this.setNextTransaction({transaction: {metadata: {}, actions: []}})
                    this.duplexer?.approved({correlationId, transactionId})
                }
            } else if (next === AutoAction.Reject) {
                this.duplexer?.rejected({correlationId})
            }
        })
    }

    approveForeignTransaction(t: GetTransactionRes, withTransaction: boolean = false) {
        super.setNextTransaction(t)
        const transactionId = this.server.getLastPlusOne()
        this.duplexer.approved({
            // @ts-expect-error
            correlationId: t.transaction.correlationId!,
            transactionId,
            transaction: withTransaction ? t.transaction : undefined
        })
    }
}

export interface GetTransactionRes extends GetTransactionResponse {
    correlationId?: string
}
