import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import {constants} from '@wix/document-manager-extensions'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {DataExtensionAPI} from '@wix/document-manager-extensions/src/extensions/data'

const cleanBrokenDataQueriesInConnection = (documentManager: DocumentManager, pageId: string) => {
    const connections = (documentManager.extensionAPI as DataExtensionAPI).data.query(
        constants.DATA_TYPES.connections,
        pageId
    )
    _.forEach(connections, connection => {
        const updatedConnectionItems = _.reject(
            connection.items,
            controllerItemData =>
                controllerItemData.type === 'ConnectionItem' &&
                !documentManager.dal.has(
                    documentManager.pointers.data.getDataItem(controllerItemData.controllerId, pageId)
                ) &&
                !documentManager.dal.has(
                    documentManager.pointers.data.getDataItem(controllerItemData.controllerId, 'masterPage')
                )
        )
        if (!_.isEqual(updatedConnectionItems, connection.items)) {
            const connectionPointer = documentManager.pointers.data.getConnectionsItem(connection.id, pageId)
            const updatedConnection = deepClone(connection)
            updatedConnection.items = updatedConnectionItems
            documentManager.dal.set(connectionPointer, updatedConnection)
        }
    })
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    cleanBrokenDataQueriesInConnection(documentManager, pageId)
}

const name = 'removeFromConnectionBrokenControllers'
const version = 1

export {migratePage, name, version}
