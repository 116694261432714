import {createDataSchemasClientForBrowser} from '@wix/wix-data-schemas-client'

const getWixDataSchemasClient = ({signedInstance, appId}) => {
    return createDataSchemasClientForBrowser(signedInstance, appId, {
        baseUrl: '/_api/cloud-data/v1/schemas'
    })
}

export default {
    list: codeAppInfo => getWixDataSchemasClient(codeAppInfo).list({includeDeletedCollections: true}),

    get: (codeAppInfo, schemaId) => getWixDataSchemasClient(codeAppInfo).get(schemaId),

    save: async (boundExtensionsAPI, codeAppInfo, schemaId, schema) => {
        const currentKnownVersion = boundExtensionsAPI.wixDataSchemas.getLastModifiedVersion(schemaId)
        if (currentKnownVersion) {
            schema.version = Math.max(currentKnownVersion, schema.version || 0)
        }
        const result = await getWixDataSchemasClient(codeAppInfo).save(schemaId, schema, {returnUpdated: true})

        if (result?.length) {
            result.forEach(updatedSchema => {
                const {id, version} = updatedSchema
                if (version) {
                    boundExtensionsAPI.wixDataSchemas.setLastModifiedVersion(id, version)
                }
            })
        }
        return result
    },

    remove: (codeAppInfo, schemaId) => getWixDataSchemasClient(codeAppInfo).remove(schemaId, {returnUpdated: true})
}
