import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import dataModel from '../../dataModel/dataModel'
import getBlogNewSocialShareButtonsCustomizationForView from './getBlogNewSocialShareButtonsCustomizationForView'
import _ from 'lodash'
import * as coreUtils from '@wix/santa-core-utils'

function useNewBlogSocialShareButtons(ps) {
    _(componentDetectorAPI.getComponentByType(ps, 'wixapps.integration.components.AppPart'))
        .filter(pointer => {
            const data = dataModel.getDataItem(ps, pointer)
            return data.appPartName === coreUtils.blogAppPartNames.SINGLE_POST
        })
        .forEach(pointer => {
            const data = dataModel.getDataItem(ps, pointer)
            const customization = getBlogNewSocialShareButtonsCustomizationForView(data.viewName)
            data.appLogicCustomizations.push(customization)
            dataModel.updateDataItem(ps, pointer, data)
        })
}

export default useNewBlogSocialShareButtons
