import _ from 'lodash'

export default function getAddressLinkPatcher(siteData) {
    if (!siteData.mobile.isAndroidMobileDevice()) {
        return _.noop
    }

    const addressLinksNodesFromDOM: any = window.document.querySelectorAll('a[data-type=address]')

    const addressLinksNodesMap = _(addressLinksNodesFromDOM)
        .filter(link => !/^(geo:0,0\?)/.test(link.href))
        .map(link => ({
            node: link,
            src: `geo:0,0?q=${link.getAttribute('data-content')}`
        }))
        .value()

    return function addressLinksPatcher() {
        addressLinksNodesMap.forEach(link => {
            link.node.setAttribute('href', link.src)
            link.node.removeAttribute('target')
        })
    }
}
