import _ from 'lodash'
import pagesGroup from '../../pagesGroup/pagesGroup'
import modesUtils from '../../modes/modesUtils'
import sospModes from '../../component/sospModes'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import component from '../../component/component'
import documentModeInfo from '../../documentMode/documentModeInfo'
import componentModes from '../../component/componentModes'

const SITE_REGION_CONTAINER_TYPE = 'wysiwyg.viewer.components.SiteRegionContainer'

function removeOrphanPagesGroups(ps) {
    const allPagesGroups = pagesGroup.getAll(ps)
    _(allPagesGroups)
        .filter(pageGroup => _.isEmpty(pagesGroup.serializePagesGroup(ps, pageGroup).pages))
        .forEach(pageGroup => {
            pagesGroup.removePagesGroup(ps, pageGroup)
        })
}

function removeOrphanSOSPModes(ps) {
    const allPagesGroups = _.keyBy(pagesGroup.getAll(ps), 'id')
    const allSospModes = modesUtils.getSOSPModes(ps)
    _(allSospModes)
        .filter(mode => {
            const pagesGroupId = _.get(mode, 'settings.pagesGroupId')
            return !pagesGroupId || !allPagesGroups[pagesGroupId.replace(/^#/, '')]
        })
        .forEach(sospMode => {
            sospModes.removeSOSPMode(ps, sospMode.modeId)
        })
}

function siteRegionContainerWillNeverBeShown(ps, allSospModes, siteRegionContainer) {
    const overrides = componentModes.overrides.getAllOverrides(ps, siteRegionContainer)
    return _(overrides)
        .flatMap('modeIds')
        .every(modeId => !_.includes(allSospModes, modeId))
}

function removeOrphanSiteRegionContainers(ps) {
    const masterPagePointer = ps.pointers.components.getMasterPage(documentModeInfo.getViewMode(ps))
    const allSiteRegionContainers = componentDetectorAPI.getComponentByType(
        ps,
        SITE_REGION_CONTAINER_TYPE,
        masterPagePointer
    )
    const allSospModes = _.map(modesUtils.getSOSPModes(ps), 'modeId')
    _(allSiteRegionContainers)
        .filter(compRef => siteRegionContainerWillNeverBeShown(ps, allSospModes, compRef))
        .forEach(siteRegionContainerRef => {
            componentModes.overrides.applyCurrentToAllModes(ps, siteRegionContainerRef)
            component.remove(ps, siteRegionContainerRef)
        })
}

export default {
    exec(ps) {
        removeOrphanPagesGroups(ps)
        removeOrphanSOSPModes(ps)
        removeOrphanSiteRegionContainers(ps)
    },
    name: 'sospDataFixer',
    version: 1
}
