import type {ApplicationId, PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import page from '../../page/page'
import platformConstants from '../../platform/common/constants'
import platformAppDataGetter from '../../platform/services/platformAppDataGetter'
import * as visitableData from '../../utils/visitableData'
import tpaConstants from '../constants'
import tpaUtils from '../utils/tpaUtils'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnPageService from './installedTpaAppsOnPageService'
import pendingAppsService from './pendingAppsService'

const shouldAppBeRevoked = (app, appIdsInstalledOnSite, appsState, shouldAvoidRevoking) => {
    const appDefinitionId = _.get(app, 'appDefinitionId')
    const appState = _.get(appsState, appDefinitionId, {})
    return (
        appState.pendingAction === platformConstants.APP_ACTION_TYPES.REMOVE ||
        (clientSpecMapService.isAppAutoRevoked(app) &&
            isAppPermissionGranted(app, appIdsInstalledOnSite, appsState) &&
            !isAppPreInstalled(app) &&
            !pendingAppsService.isPending(undefined, app) &&
            !shouldAvoidRevoking)
    )
}

const getAppsToRevokePermissions = (clientSpecMap, appIdsInstalledOnSite, options, appsState) => {
    const {excludeHybrid, shouldAvoidRevoking} = options ?? {}
    const appsToRevokePermissions = _.filter(clientSpecMap, app =>
        shouldAppBeRevoked(app, appIdsInstalledOnSite, appsState, shouldAvoidRevoking)
    )

    if (options && _.isBoolean(excludeHybrid) && excludeHybrid) {
        return _.filter(
            appsToRevokePermissions,
            app =>
                clientSpecMapService.isEditorApp(app) || clientSpecMapService.isHybridAppAndEditorPartNotDismissed(app)
        )
    }

    return appsToRevokePermissions
}

const getDeletedAppsIds = (ps: PS) => {
    const appsDataWithPredicate = clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        _.filter(
            csm,
            app => !isApplicationIdExists(ps, app.applicationId) && clientSpecMapService.isEditorOrHybridApp(app)
        )
    )
    return _.map(appsDataWithPredicate, 'applicationId')
}

const isAppIsPlatformEditorOnly = app =>
    _.get(app, 'appFields.platform.editorScriptUrl') &&
    !_.get(app, 'appFields.platform.viewerScriptUrl') &&
    _.isEmpty(app.widgets)

const isAppIsPlatformEditorOnlyAndNotProvisioned = function (app) {
    return app.notProvisioned && isAppIsPlatformEditorOnly(app)
}

const getAppsToGrantPermissions = function (clientSpecMap, appIdsInstalledOnSite, appsState?) {
    return _.filter(
        clientSpecMap,
        app =>
            isAppPermissionRevoked(app, appIdsInstalledOnSite, appsState) ||
            isAppIsPlatformEditorOnlyAndNotProvisioned(app)
    )
}

const isPlatformOnlyAppUninstalled = function (_visitableData, clientSpecMap, routerConfigMap, appsState) {
    const uninstalledPlatformOnly = _.filter(clientSpecMap, app => {
        if (!clientSpecMapService.hasEditorPlatformPart(app)) {
            return false
        }
        return (
            clientSpecMapService.isAppPermissionsIsGranted(app, appsState) &&
            !installedTpaAppsOnPageService.isPlatformAppActive(app, _visitableData, routerConfigMap)
        )
    })
    return !_.isEmpty(uninstalledPlatformOnly)
}

/** This returns the apps that should have been revoked if branches weren't applied on this site */
const getUnusedApps = function (ps: PS) {
    const visitableDataAllPages = visitableData.createFromPrivateServices(ps)
    const clientSpecMap = clientSpecMapService.filterApps(ps.dal.get(ps.pointers.general.getClientSpecMap()))
    const routerConfigMap = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())
    const appIdsInstalledOnSite = installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages(
        visitableDataAllPages,
        clientSpecMap,
        routerConfigMap
    )

    return getAppsToRevokePermissions(clientSpecMap, appIdsInstalledOnSite, null, {})
}

const getAppsToGrantAndRevoke = function (clientSpecMap, _visitableData, options?, routerConfigMap?, appsState?) {
    const appIdsInstalledOnSite = installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages(
        _visitableData,
        clientSpecMap,
        routerConfigMap
    )

    return {
        revoke: getAppsToRevokePermissions(clientSpecMap, appIdsInstalledOnSite, options, appsState),
        grant: getAppsToGrantPermissions(clientSpecMap, appIdsInstalledOnSite, appsState)
    }
}

const getAppsToProvision = clientSpecMap => _.filter(clientSpecMap, app => isAppInDemoMode(app))

const isAppInDemoMode = app => app.demoMode

const isAppPermissionGranted = function (app, appIdsInstalledOnSite, appsState) {
    return (
        clientSpecMapService.isEditorOrHybridApp(app) &&
        clientSpecMapService.isAppPermissionsIsGranted(app, appsState) &&
        !_.includes(_.invokeMap(appIdsInstalledOnSite, 'toString'), app.applicationId.toString()) &&
        !isAppIsPlatformEditorOnly(app)
    )
}

const isAppPermissionRevoked = (app, appIdsInstalledOnSite, appsState) =>
    clientSpecMapService.isEditorOrHybridApp(app) &&
    clientSpecMapService.isAppPermissionsIsRevoked(app, appsState) &&
    _.includes(_.invokeMap(appIdsInstalledOnSite, 'toString'), app.applicationId.toString())

const isAppPreInstalled = app => _.isBoolean(app.preInstalled) && app.preInstalled

const getInstalledAppsOnSite = function (ps: PS) {
    const pagesIds = page.getPageIdList(ps, true, true)
    const apps = _.reduce(
        pagesIds,
        function (result, pageId) {
            const installedAppsOnPage = _.map(getInstalledAppsOnPage(ps, pageId), 'applicationId')
            return _.union(result, installedAppsOnPage)
        },
        []
    )
    return _.uniq(apps)
}

const getInstalledAppsOnPage = function (ps: PS, pageId: string) {
    const pointerToDataItems = ps.pointers.page.getPageData(pageId)
    const dataItems = ps.dal.get(pointerToDataItems)
    const applicationIds = _(dataItems)
        .filter(dataItem => tpaUtils.isTpaByDataType(dataItem.type))
        .map('applicationId')
        .uniq()
        .value()
    return _.values(clientSpecMapService.getAppsDataWithPredicate(ps, csm => _.pick(csm, applicationIds)))
}

const getFirstMainSectionInstalledData = function (ps: PS, applicationId) {
    return getFirstCompData(ps, applicationId, comp => comp.type === tpaConstants.DATA_TYPE.TPA_SECTION)
}

const getDefaultWidgetInstalledData = function (ps: PS, appData) {
    const defaultWidget = _.find(appData.widgets, {default: true})
    if (defaultWidget) {
        return getFirstCompData(ps, appData.applicationId, comp => comp.widgetId === defaultWidget.widgetId)
    }
    return null
}

const getFirstCompData = function (ps: PS, applicationId, predicateFunc) {
    const pagesIds = page.getPageIdList(ps, true, true)
    let comp
    const pageIdWithComp = _.find(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        comp = _.find(
            tpaCompsOnPage,
            tpaComp => tpaComp.applicationId.toString() === applicationId.toString() && predicateFunc(tpaComp)
        )
        return !_.isUndefined(comp)
    })

    return pageIdWithComp ? {pageId: pageIdWithComp, compId: comp.id} : null
}

const getFirstAppCompPageId = function (ps: PS, appDefinitionId: string, useDefaultWidget?: boolean) {
    const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    const applicationId = _.get(appData, 'applicationId', null)
    let data = applicationId && getFirstMainSectionInstalledData(ps, applicationId)
    if (!data && useDefaultWidget) {
        data = getDefaultWidgetInstalledData(ps, appData)
    }
    data = applicationId && (data || getFirstCompData(ps, applicationId, () => true))

    return data
}

const isMainSectionInstalled = function (ps: PS, applicationId: ApplicationId) {
    const pagesIds = page.getPageIdList(ps, true)
    return _.some(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        return _.some(
            tpaCompsOnPage,
            tpaComp =>
                tpaComp.applicationId.toString() === applicationId.toString() &&
                tpaComp.type === tpaConstants.DATA_TYPE.TPA_SECTION
        )
    })
}

const emptyToNull = v => (_.isEmpty(v) ? null : v)

const getHiddenSections = function (ps: PS, applicationId: ApplicationId) {
    let hiddenSections = []
    const pagesIds = page.getPageIdList(ps, true)
    _.forEach(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        hiddenSections = _.union(
            hiddenSections,
            _.filter(
                tpaCompsOnPage,
                tpaComp =>
                    tpaComp.applicationId.toString() === applicationId.toString() &&
                    tpaComp.type === tpaConstants.DATA_TYPE.TPA_MULTI_SECTION
            )
        )
    })

    return emptyToNull(hiddenSections)
}

const getWidgetsByAppId = function (ps: PS, applicationId: ApplicationId) {
    let widgets = []
    const pagesIds = page.getPageIdList(ps, true, true)
    _.forEach(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        widgets = _.union(
            widgets,
            _.filter(
                tpaCompsOnPage,
                tpaComp =>
                    tpaComp.applicationId.toString() === applicationId.toString() &&
                    tpaComp.type === tpaConstants.DATA_TYPE.TPA_WIDGET
            )
        )
    })

    return emptyToNull(widgets)
}

const getAllAppCompsByAppId = function (ps: PS, applicationIds?: ApplicationId | ApplicationId[]) {
    let tpaComps = []

    if (!_.isArray(applicationIds)) {
        applicationIds = [applicationIds]
    }

    applicationIds = _(applicationIds).compact().invokeMap('toString').value()
    const pagesIds = page.getPageIdList(ps, true, true)
    _.forEach(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        tpaComps = _.union(
            tpaComps,
            _.filter(
                tpaCompsOnPage,
                tpaComp =>
                    tpaComp?.applicationId &&
                    _.includes(applicationIds as ApplicationId[], tpaComp.applicationId.toString())
            )
        )
    })

    return emptyToNull(tpaComps)
}

const isMultiSectionInstalled = function (ps: PS, applicationId?: ApplicationId) {
    let mainSections = []
    applicationId = applicationId || -1
    const pagesIds = page.getPageIdList(ps, true)
    _.forEach(pagesIds, function (pageId) {
        const tpaCompsOnPage = getAllTpaCompsOnPage(ps, pageId)
        mainSections = _.union(
            mainSections,
            _.filter(
                tpaCompsOnPage,
                tpaComp =>
                    tpaComp?.applicationId &&
                    tpaComp.applicationId.toString() === applicationId.toString() &&
                    tpaComp.type === tpaConstants.DATA_TYPE.TPA_SECTION
            )
        )
    })

    return _.size(mainSections) > 1
}

const isAppInstalledBy = function (ps: PS, appDefinitionId: string, filterOutDemoMode?) {
    const tpaApp = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

    if (tpaApp) {
        const applicationIdExists =
            clientSpecMapService.isDashboardAppOnly(tpaApp) || isApplicationIdExists(ps, tpaApp.applicationId)
        if (filterOutDemoMode) {
            return applicationIdExists && !tpaApp.demoMode
        }
        return applicationIdExists
    }

    return false
}

const isAppInstalledOnPage = function (ps: PS, pageId: string, appDefinitionId: string, filterOutDemoMode?) {
    const tpaApp = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

    if (tpaApp) {
        const applicationIdExists = isApplicationExistsOnPage(ps, pageId, tpaApp.applicationId)
        if (filterOutDemoMode) {
            return applicationIdExists && !tpaApp.demoMode
        }
        return applicationIdExists
    }

    return false
}

const isApplicationIdExists = function (ps: PS, applicationId: ApplicationId) {
    const applicationIdString = (applicationId || -1).toString()
    const pagesIds = page.getPageIdList(ps, true, true)
    const predicate = dataItem => _.get(dataItem, 'applicationId', '').toString() === applicationIdString
    return _.some(pagesIds, pageId => !!ps.pointers.data.getDataItemWithPredicate(predicate, pageId))
}

const isApplicationExistsOnPage = function (ps: PS, pageId: string, applicationId: ApplicationId) {
    const applicationIdString = applicationId.toString()
    const predicate = dataItem => _.get(dataItem, 'applicationId', '').toString() === applicationIdString
    return !!ps.pointers.data.getDataItemWithPredicate(predicate, pageId)
}

const isSectionInstalledByTpaPageId = function (ps: PS, applicationId: ApplicationId, tpaPageId: string) {
    const pagesData = page.getPagesDataItems(ps)
    return _.some(pagesData, {tpaApplicationId: applicationId, tpaPageId})
}

const isAppComponentInstalled = function (ps: PS, widgetId: string) {
    const pagesIds = page.getPageIdList(ps, true, true)
    return _.some(pagesIds, function (pageId) {
        const pointerToDataItems = ps.pointers.page.getPageData(pageId)
        const dataItems = ps.dal.get(pointerToDataItems)
        return _.some(dataItems, {widgetId})
    })
}

const getAllTpaCompsOnPage = function (ps: PS, pageId: string) {
    const pagePointer = page.getPage(ps, pageId)
    const tpaCompPointers = component.getTpaChildren(ps, pagePointer)

    return _.map(tpaCompPointers, tpaCompPointer =>
        _.assign(component.data.get(ps, tpaCompPointer), {id: tpaCompPointer.id, pageId})
    )
}

const isMultiSectionPage = function (ps: PS, pageData) {
    const applicationId = _.get(pageData, 'tpaApplicationId')
    const tpaPageId = _.get(pageData, 'tpaPageId')
    const appData = clientSpecMapService.getAppData(ps, applicationId)
    if (appData?.appDefinitionId) {
        const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, appData.appDefinitionId, tpaPageId)
        return _.get(widgetData, 'appPage.hidden')
    }
    return false
}

const isTpaCompsInstalledOnPages = function (_visitableData) {
    if (!_visitableData) {
        return false
    }

    let result = false
    _visitableData.accept((get, data) => {
        result = result || tpaUtils.isTpaByDataType(get(data, 'type'))
    })
    return result
}

const getAppsDefIdToProvisionOnSiteLoad = function (ps: PS) {
    const appsDataWithPredicate = clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        _.filter(
            csm,
            appData =>
                appData.demoMode &&
                (isApplicationIdExists(ps, appData.applicationId) ||
                    platformAppDataGetter.isPlatformAppInstalled(ps, appData.appDefinitionId))
        )
    )
    return _.map(appsDataWithPredicate, 'appDefinitionId')
}

const isDataFixerAddedChatRemoved = (
    isMasterPageUpdated: boolean,
    tpaCompsOnLastPages,
    tpaCompsOnUpdatedPages,
    clientSpecMap,
    appsState
) => {
    if (isMasterPageUpdated) {
        const chatApp = _.find(clientSpecMap, {appDefinitionId: tpaConstants.APP_DEF_ID.CHAT})

        return (
            chatApp &&
            clientSpecMapService.isAppPermissionsIsGranted(chatApp, appsState) &&
            !_.includes(tpaCompsOnLastPages, chatApp.appDefinitionId) &&
            !_.includes(tpaCompsOnUpdatedPages, chatApp.appDefinitionId)
        )
    }

    return false
}

const areTpaCompsWereUnInstalled = function (
    lastPagesVisitable,
    updatedPagesVisitable,
    deletedPagesVisitable?,
    clientSpecMap?,
    isMasterPageUpdated?: boolean,
    appsState?
) {
    if (isTpaCompsInstalledOnPages(deletedPagesVisitable)) {
        return true
    }

    const tpaCompsOnLastPages = installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages(lastPagesVisitable) || []
    const tpaCompsOnUpdatedPages =
        installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages(updatedPagesVisitable) || []

    const someAppsAddedSomeRemoved =
        tpaCompsOnLastPages.length === tpaCompsOnUpdatedPages.length &&
        !_.isEqual(tpaCompsOnLastPages, tpaCompsOnUpdatedPages)

    return (
        tpaCompsOnLastPages.length > tpaCompsOnUpdatedPages.length ||
        someAppsAddedSomeRemoved ||
        isDataFixerAddedChatRemoved(
            isMasterPageUpdated,
            tpaCompsOnLastPages,
            tpaCompsOnUpdatedPages,
            clientSpecMap,
            appsState
        )
    )
}

const getAppPages = function (ps: PS, applicationId: string | number) {
    const pagesIds = page.getPageIdList(ps, false, false)
    return _(pagesIds)
        .map(pageId => _.merge(page.data.get(ps, pageId), {pageId}))
        .filter({tpaApplicationId: parseInt(applicationId as string, 10)})
        .value()
}

const getPagesByApplicationIds = function (ps: PS, applicationIds: ApplicationId[]) {
    const pagesData = page.getPagesDataItems(ps)
    return _.filter(pagesData, pageData => _.includes(applicationIds, pageData.tpaApplicationId))
}

const isAppInstalled = function (ps: PS, installedAppIds, appData) {
    if (clientSpecMapService.isAppAutoRevoked(appData)) {
        return _.includes(installedAppIds, _.get(appData, 'applicationId'))
    }
    return clientSpecMapService.isAppActive(ps, appData)
}

const getInstalledDependentAppsData = function (ps: PS, applicationId: ApplicationId) {
    const mainAppData = clientSpecMapService.getAppData(ps, applicationId)
    const mainAppDefId = _.get(mainAppData, 'appDefinitionId')
    // @ts-expect-error
    const dependentAppDefIds = clientSpecMapService.getDependentApps(ps, mainAppDefId)
    if (!_.isEmpty(dependentAppDefIds)) {
        const installedAppIds = getInstalledAppsOnSite(ps)
        return _.reduce(
            dependentAppDefIds,
            function (result, appDefId) {
                const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefId)
                if (isAppInstalled(ps, installedAppIds, appData)) {
                    result.push(appData)
                }
                return result
            },
            []
        )
    }
    return []
}

export default {
    isMultiSectionPage,
    getInstalledAppsOnPage,
    getAllTpaCompsOnPage,
    isMainSectionInstalled,
    getWidgetsByAppId,
    getAllAppCompsByAppId,
    isMultiSectionInstalled,
    getHiddenSections,
    getFirstAppCompPageId,
    isApplicationIdExists,
    isAppInstalledBy,
    isAppInstalledOnPage,
    getDeletedAppsIds,
    getAppsDefIdToProvisionOnSiteLoad,
    getAppsToGrantAndRevoke,
    getUnusedApps,
    getAllAppIdsInstalledOnPages: installedTpaAppsOnPageService.getAllAppIdsInstalledOnPages,
    areTpaCompsWereUnInstalled,
    getAppsToGrantPermissions,
    getAppsToProvision,
    getAppPages,
    getPagesByApplicationIds,
    isAppComponentInstalled,
    isSectionInstalledByTpaPageId,
    isPlatformOnlyAppUninstalled,
    getInstalledDependentAppsData
}
