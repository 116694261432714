import type {Pointer, PS} from '@wix/document-services-types'
import {blogAppPartNames} from '@wix/santa-core-utils'
import _ from 'lodash'
import wixappsClassics from 'wixappsClassics'
import consts from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import dsUtils from '../../utils/utils'
import blogUtils from '../../wixapps/services/blogUtils'
import classicsUtils from '../../wixapps/utils/classicsUtils'
import metaDataUtils from '../metaDataUtils'

function getResizableSides(ps: PS, compPointer: Pointer) {
    let resizableSides = [consts.RESIZE_SIDES.LEFT, consts.RESIZE_SIDES.RIGHT]
    const data = dataModel.getDataItem(ps, compPointer)
    const packageName = classicsUtils.getPackageName(ps, data.appInnerID)
    const partDefinition = classicsUtils.getAppPartDefinition(ps, packageName, data.appPartName)
    if (wixappsClassics.descriptorUtils.doesAllowHeightResize(partDefinition, data.viewName)) {
        resizableSides = resizableSides.concat([consts.RESIZE_SIDES.TOP, consts.RESIZE_SIDES.BOTTOM])
    }
    return resizableSides
}

function isMasterPageScope(ps: PS, comp) {
    return ps.pointers.components.isMasterPage(comp) || ps.pointers.components.isInMasterPage(comp)
}

function isChangingScope(ps: PS, compPointer: Pointer, potentialContainer) {
    return isMasterPageScope(ps, compPointer) !== isMasterPageScope(ps, potentialContainer)
}

function isCompInList(ps: PS, compPointer: Pointer, list) {
    const compData = dataModel.getDataItem(ps, compPointer)
    const packageName = classicsUtils.getPackageName(ps, compData.appInnerID)

    return (
        packageName === 'blog' && _.includes(list, classicsUtils.getAppPartRole(ps, packageName, compData.appPartName))
    )
}

function isAppPartPageFixed(ps: PS, compPointer: Pointer) {
    return isCompInList(ps, compPointer, ['BLOG_FEED', 'SINGLE_POST', 'RELATED_POSTS'])
}

function isContainable(ps: PS, compPointer: Pointer, potentialContainer) {
    return (
        potentialContainer &&
        !(isChangingScope(ps, compPointer, potentialContainer) && isAppPartPageFixed(ps, compPointer))
    )
}

function isStretchable(ps: PS, compPointer: Pointer) {
    const strechableComps = ['POST_GALLERY', 'BLOG_FEED', 'CUSTOM_FEED']
    return isCompInList(ps, compPointer, strechableComps.concat(['RELATED_POSTS']))
}

function containableByStructure(ps: PS, comp, potentialContainerPointer: Pointer) {
    const isOnPopup = !metaDataUtils.notContainableByPopup(ps, comp, potentialContainerPointer)

    const pagePointer = ps.pointers.components.getPageOfComponent(potentialContainerPointer)
    const isSinglePostPage = blogUtils.isSinglePost(ps, pagePointer.id)
    if (comp.data.appPartName === 'ea63bc0f-c09f-470c-ac9e-2a408b499f22') {
        // is singlePost Part
        const {type, appPartName} = comp.data
        return isSinglePostPage && !ps.pointers.data.getDataItemWithPredicate({type, appPartName}, pagePointer.id)
    }

    const isRelatedPostComponent = comp.data.appPartName === blogAppPartNames.RELATED_POSTS

    return !(isOnPopup || (isRelatedPostComponent && !isSinglePostPage))
}

function getLayoutLimits(ps: PS, compPointer: Pointer) {
    const {appPartName} = dataModel.getDataItem(ps, compPointer)

    if (appPartName === blogAppPartNames.HERO_IMAGE) {
        return {minHeight: consts.HERO_IMAGE.MIN_HEIGHT}
    }

    return {}
}

function getFixedSize(ps: PS, component, pageId: string) {
    const compData = dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(component.dataQuery)!, pageId)
    const appPartName = _.get(compData, 'appPartName')
    if (appPartName === blogAppPartNames.POSTS_GALLERY || appPartName === blogAppPartNames.RELATED_POSTS) {
        return {height: component.layout.height}
    }

    return {}
}

export default {
    layoutLimits: getLayoutLimits,
    resizableSides: getResizableSides,
    containable: isContainable,
    containableByStructure,
    canBeStretched: isStretchable,
    mobileConversionConfig: {
        fixedSize: getFixedSize
    }
}
