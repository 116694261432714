import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import type {BehaviorDef, Pointer} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from './dataModel'
import {DATA_TYPES} from '../constants/constants'

export interface ActionsAndBehaviorsAPI {
    getBehaviors(actionSourceRef: Pointer): any
    isCodeBehavior(behavior: BehaviorDef): boolean
}

export type ActionsAndBehaviorsExtensionAPI = ExtensionAPI & {
    actionsAndBehaviors: ActionsAndBehaviorsAPI
}

const CODE_BEHAVIOR_NAMES = {runCode: true, runAppCode: true}
const CODE_BEHAVIOR_TYPE = 'widget'

const createExtension = (): Extension => {
    const createExtensionAPI = ({pointers, extensionAPI}: CreateExtArgs): ActionsAndBehaviorsExtensionAPI => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI

        const getOriginalCompRef = (actionSourceRef: Pointer) => {
            if (displayedOnlyStructureUtil.isRepeatedComponent(actionSourceRef.id)) {
                const originalId = displayedOnlyStructureUtil.getRepeaterTemplateId(actionSourceRef.id)
                const pagePointer = pointers.structure.getPageOfComponent(actionSourceRef)
                const originalCompPointer = pointers.structure.getComponent(originalId, pagePointer)
                return originalCompPointer
            }
            return actionSourceRef
        }

        const getBehaviors = (actionSourceRef: Pointer) => {
            const compRef = getOriginalCompRef(actionSourceRef)
            const behaviors = dataModel.components.getItem(compRef, DATA_TYPES.behaviors)

            return behaviors?.items ? JSON.parse(behaviors.items) : []
        }

        const isCodeBehavior = (behavior: BehaviorDef) => {
            const {type, name} = behavior || {}
            return type === CODE_BEHAVIOR_TYPE && _.has(CODE_BEHAVIOR_NAMES, name)
        }

        return {
            actionsAndBehaviors: {
                getBehaviors,
                isCodeBehavior
            }
        }
    }

    return {
        name: 'actionsAndBehaviors',
        createExtensionAPI,
        dependencies: new Set(['structure', 'dataModel'])
    }
}

export {createExtension}
