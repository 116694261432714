import {CreateExtArgs, DAL, DalValue, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import type {Pointer, Pointers, RefInfo} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import _ from 'lodash'
import {
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPES,
    DATA_TYPES_VALUES_WITH_HASH,
    VIEW_MODES
} from '../constants/constants'
import {
    addDefaultMetaData,
    generateItemIdWithPrefix,
    generateUniqueIdByType,
    shouldMergeDataItems
} from '../utils/dataUtils'
import {validateCompNamespaceType} from '../utils/schemaUtils'
import {translateIfNeeded} from './page/language'
import type {RelationshipsAPI} from './relationships'
import type {VariantsExtensionAPI} from './variants'
import {getUniqueDisplayedId, isRepeater} from '../utils/repeaterUtils'

const {getInnerPointer} = pointerUtils
export type AddCompItem = (compPointer: Pointer, namespace: string, data: DalValue, languageCode?: string) => Pointer
export type GetCompItem = (
    compPointer: Pointer,
    namespace: string,
    languageCode?: string,
    useOriginalLanguageFallback?: boolean
) => DalValue | void
export type GenerateItemIdWithPrefix = (prefix: string) => string
export type GenerateUniqueIdByType = (type: string, pageId: string, dal: DAL, pointers: Pointers) => string
export type LinkComponentToItemByTypeDesktopAndMobile = (
    componentPointer: Pointer,
    itemId: string,
    itemType: string,
    itemQuery?: string
) => void

export interface DataModelAPI {
    addItem(item: DalValue, itemType: string, pageId: string, customId?: string, languageCode?: string): Pointer
    getItem(
        id: string,
        namespace: string,
        pageId: string,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ): DalValue | void
    generateItemIdWithPrefix: GenerateItemIdWithPrefix
    generateUniqueIdByType: GenerateUniqueIdByType
    components: {
        linkComponentToItemByTypeDesktopAndMobile: LinkComponentToItemByTypeDesktopAndMobile
        addItem: AddCompItem
        getItem: GetCompItem
        getItemWithVariants: GetCompItem
        removeItem(compPointer: Pointer, namespace: string): void
        getAllDataOverridesForComponent(componentPointer: Pointer, langCode?: string): Pointer[]
    }
    removeItemRecursively(pointer: Pointer): void
}

export type DataModelExtensionAPI = ExtensionAPI & {
    dataModel: DataModelAPI
}

const IGNORED_ITEM_NAMESPACES_FOR_RECURSIVE_REMOVAL = VIEW_MODES

const isUpdatingTranslatedItemThatDoesntHaveTranslation = (
    itemInDALPointer: Pointer,
    itemInDALPointerBeforeTranslation: Pointer,
    itemInDAL: Record<string, any>
) => !_.isEqual(itemInDALPointer, itemInDALPointerBeforeTranslation) && !itemInDAL

const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): DataModelExtensionAPI => {
    const {relationships} = extensionAPI as RelationshipsAPI
    const variantApi = () => (extensionAPI as VariantsExtensionAPI).variants

    const addItem = (item: DalValue, namespace: string, pageId: string, customId?: string, languageCode?: string) => {
        const itemToUpdatePointer = pointers.data.getItem(namespace, item.id, pageId)
        const id =
            customId ??
            (item.id && !dal.has(itemToUpdatePointer)
                ? item.id
                : generateUniqueIdByType(namespace, pageId, dal, pointers))
        const itemInDALPointerBeforeTranslation = pointers.data.getItem(namespace, id, pageId)
        const itemInDALPointer = translateIfNeeded(dal, pointers, itemInDALPointerBeforeTranslation, languageCode)
        const itemInDAL = dal.get(itemInDALPointer)
        let newItem: DalValue
        let metaDataObj = {id, metaData: {...item.metaData, pageId}}
        if (itemInDAL) {
            metaDataObj = {id, metaData: {...itemInDAL.metaData, ...item.metaData, pageId}}
        }
        if (
            isUpdatingTranslatedItemThatDoesntHaveTranslation(
                itemInDALPointer,
                itemInDALPointerBeforeTranslation,
                itemInDAL
            )
        ) {
            const itemDalOriginalLang = dal.get(itemInDALPointerBeforeTranslation)
            newItem = _.assign(deepClone(itemDalOriginalLang), item, metaDataObj)
        } else {
            newItem = shouldMergeDataItems(itemInDAL, item)
                ? _.assign(deepClone(itemInDAL), item, metaDataObj)
                : {...item, ...metaDataObj}
        }
        if (!newItem.type) {
            throw Error(
                `item ${id} from namespace ${namespace} does not have type the item passed is ${JSON.stringify(item)}`
            )
        }
        const {schema} = dal
        const schemaRefFieldsInfo = schema
            .extractReferenceFieldsInfoForSchema(namespace, newItem.type)
            ?.filter(schemaInfo => schemaInfo.isRefOwner)
        _.forEach(schemaRefFieldsInfo, ({path, referencedMap}) => {
            const val = _.get(newItem, path)
            if (_.isArray(val)) {
                const refArr: string[] = []
                const oldRefs = _.keyBy(_.get(itemInDAL, path), refId => refId.replace(/^#/, ''))
                _.forEach(val, itemInArr => {
                    const reusedId = oldRefs[itemInArr.id] ? itemInArr.id : undefined
                    const addedPointer = addItem(itemInArr, namespace, pageId, reusedId, languageCode)
                    refArr.push(`#${addedPointer.id}`)
                })
                _.setWith(newItem, path, refArr, Object)
            } else if (_.isObject(val) && _.has(val, ['type'])) {
                const currentDataItemId = _.get(itemInDAL, path)
                const reusedId =
                    // @ts-expect-error
                    (val.id && currentDataItemId && currentDataItemId.replace(/^#/, '')) === val.id ? val.id : undefined
                const addedPointer = addItem(val, referencedMap, pageId, reusedId, languageCode)
                _.setWith(newItem, path, `#${addedPointer.id}`, Object)
            }
        })
        addDefaultMetaData(newItem, pageId, namespace)
        newItem = _.omitBy(newItem, _.isNil)
        const itemPointerBeforeTranslation = pointers.data.getItem(namespace, id, pageId)
        const itemPointer = translateIfNeeded(dal, pointers, itemPointerBeforeTranslation, languageCode)
        schema.addDefaultsAndValidate(newItem.type, newItem, namespace)
        dal.set(itemPointer, newItem)
        return itemPointer
    }

    const updateComponentItem = (compPointer: Pointer, namespace: string, item: DalValue, languageCode?: string) => {
        const componentTypePointer = getInnerPointer(compPointer, 'componentType')
        const componentType = dal.get(componentTypePointer)
        if (item.type) {
            const validCompNamespaceType = validateCompNamespaceType(dal, componentType, item.type, namespace)
            if (!validCompNamespaceType.isValid) {
                throw new ReportableError({
                    message: validCompNamespaceType.message ?? 'validateCompNamespaceType',
                    errorType: 'invalidComponent'
                })
            }
        }
        const namespaceKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
        const refPointer = getInnerPointer(compPointer, namespaceKey)
        const ref = dal.get(refPointer)
        const idBeforeUpdate = _.isString(ref) ? relationships.getIdFromRef(ref) : ref
        const isPagePointer = pointers.structure.isPage(compPointer)
        const pageId =
            isPagePointer && namespace === DATA_TYPES.data
                ? 'masterPage'
                : _.get(pointers.structure.getPageOfComponent(compPointer), ['id'])
        const idToSet = idBeforeUpdate ?? (isPagePointer ? compPointer.id : undefined)
        const itemPointer = addItem(item, namespace, pageId, idToSet, languageCode)
        if (!ref) {
            const idAfterUpdate = _.get(dal.get(itemPointer), ['id'])
            const newRef = DATA_TYPES_VALUES_WITH_HASH[namespace] ? `#${idAfterUpdate}` : idAfterUpdate
            dal.set(refPointer, newRef)
        }

        return itemPointer
    }

    const getItemInternal = (
        id: string,
        namespace: string,
        pageId: string,
        languageCode?: string,
        useOriginalLanguageFallback: boolean = false
    ) => {
        const itemPointerBeforeTranslate = pointers.data.getItem(namespace, id, pageId)
        const itemOrNull = deepClone(
            dal.get(translateIfNeeded(dal, pointers, itemPointerBeforeTranslate, languageCode))
        )
        const item =
            !itemOrNull && useOriginalLanguageFallback ? deepClone(dal.get(itemPointerBeforeTranslate)) : itemOrNull
        const {schema} = dal
        if (item) {
            const references = _.filter(schema.getReferences(namespace, item), {refInfo: {isRefOwner: true}})
            _.forEach(references, reference => {
                const referredItem = getItemInternal(reference.id, reference.referencedMap, pageId, languageCode, true)
                const oldReferred = _.get(item, reference.refInfo.path)
                if (_.isString(oldReferred)) {
                    _.set(item, reference.refInfo.path, referredItem)
                } else if (_.isArray(oldReferred)) {
                    _.remove(oldReferred, _.isString)
                    oldReferred.push(referredItem)
                }
            })
        }

        return item?.metaData ? _.omit(item, ['metaData']) : item
    }

    const getItem = (
        id: string,
        namespace: string,
        pageId: string,
        languageCode?: string,
        useOriginalLanguageFallback: boolean = false
    ) => {
        const item = getItemInternal(id, namespace, pageId, languageCode)
        return !item && useOriginalLanguageFallback && languageCode ? getItemInternal(id, namespace, pageId) : item
    }

    const getComponentItem = (
        compPointer: Pointer,
        namespace: string,
        languageCode?: string,
        useOriginalLanguageFallback: boolean = false
    ) => {
        const namespaceKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
        const refPointer = getInnerPointer(compPointer, namespaceKey)
        const ref = dal.get(refPointer)
        if (!ref) {
            return undefined
        }
        const id = relationships.getIdFromRef(ref)
        const pageId = _.get(pointers.structure.getPageOfComponent(compPointer), ['id'])
        return getItem(id, namespace, pageId, languageCode, useOriginalLanguageFallback)
    }

    const getComponentItemWithVariants = (
        compPointerWithVariants: Pointer,
        namespace: string,
        languageCode?: string,
        useOriginalLanguageFallback: boolean = false
    ) => {
        const namespaceKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
        const refPointer = getInnerPointer(compPointerWithVariants, namespaceKey)
        const ref = dal.get(refPointer)
        if (!ref) {
            return undefined
        }

        const id = relationships.getIdFromRef(ref)
        const pageId = _.get(pointers.structure.getPageOfComponent(compPointerWithVariants), ['id'])
        const variantPointer = variantApi().getDataPointerConsideringVariants(compPointerWithVariants, namespace, {
            id,
            type: namespace
        })
        if (!variantPointer) {
            return getItem(id, namespace, pageId, languageCode, useOriginalLanguageFallback)
        }
        return getItem(variantPointer.id, namespace, pageId, languageCode, useOriginalLanguageFallback)
    }

    const getOwnedRefFieldSchemasForItemRemoval = (itemNamespace: string, itemType: string): RefInfo[] => {
        const refFields = dal.schema.extractReferenceFieldsInfoForSchema(itemNamespace, itemType)
        const ownedFields = refFields?.filter(schemaInfo => schemaInfo.isRefOwner)
        return (
            ownedFields?.filter(
                schemaInfo => !IGNORED_ITEM_NAMESPACES_FOR_RECURSIVE_REMOVAL[schemaInfo.referencedMap]
            ) ?? []
        )
    }

    const removeItemRecursively = (itemPointer: Pointer) => {
        const item = dal.get(itemPointer)

        if (!item || dal.schema.isPermanentDataType(itemPointer.type, item.type)) {
            return
        }

        const ownedRefFields = getOwnedRefFieldSchemasForItemRemoval(itemPointer.type, item.type)
        for (const ownedRefField of ownedRefFields) {
            const queryPath = _.flatMap(ownedRefField.path)
            const itemQuery = _.get(item, queryPath)

            if (typeof itemQuery === 'string' && itemQuery.length) {
                const subItemPointer = pointerUtils.getPointer(_.trimStart(itemQuery, '#'), ownedRefField.referencedMap)
                removeItemRecursively(subItemPointer)
            } else if (Array.isArray(itemQuery)) {
                for (const query of itemQuery) {
                    const subItemPointer = pointerUtils.getPointer(_.trimStart(query, '#'), ownedRefField.referencedMap)
                    removeItemRecursively(subItemPointer)
                }
            }
        }

        dal.remove(itemPointer)
    }

    const removeItem = (compPointer: Pointer, namespace: string) => {
        const item = getComponentItem(compPointer, namespace)
        if (item) {
            const queryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
            const refPointer = getInnerPointer(compPointer, queryKey)
            dal.remove(refPointer)

            removeItemRecursively(pointerUtils.getPointer(item.id, namespace))
        }
    }

    const linkComponentToItem = (componentPointer: Pointer, dataItemId: string, itemQuery: string) => {
        const compDataPointer = getInnerPointer(componentPointer, itemQuery)
        dal.set(compDataPointer, dataItemId)
    }

    const linkComponentToItemByTypeDesktopAndMobile = (
        componentPointer: Pointer,
        itemId: string,
        itemType: string,
        itemQuery?: string
    ): void => {
        const itemIdWithHashIfNeeded = DATA_TYPES_VALUES_WITH_HASH[itemType] ? `#${itemId}` : itemId
        const itemQueryToUse = itemQuery || COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
        _.forEach(
            [
                pointers.structure.getDesktopPointer(componentPointer),
                pointers.structure.getMobilePointer(componentPointer)
            ],
            ptr => {
                if (dal.has(ptr)) {
                    linkComponentToItem(ptr, itemIdWithHashIfNeeded, itemQueryToUse)
                }
            }
        )
    }

    const getAllDataOverridesForComponent = (pointer: Pointer, langCode?: string) => {
        const compDataQuery = dal.get(pointer).dataQuery
        if (!compDataQuery) {
            return []
        }
        const repeaterPointer = pointers.structure.getAncestorByPredicate(pointer, (ancestorPointer: Pointer) =>
            isRepeater(dal, ancestorPointer)
        )

        if (repeaterPointer) {
            const repeaterDataQuery = relationships.getIdFromRef(dal.getWithPath(repeaterPointer, 'dataQuery'))
            const repeaterDataPointer = pointerUtils.getPointer(repeaterDataQuery, 'data')
            const repeaterData = dal.get(repeaterDataPointer)
            return _.reduce(
                repeaterData.items,
                (res: Pointer[], itemId) => {
                    const dataPointer = pointers.data.getItem(
                        'data',
                        getUniqueDisplayedId(compDataQuery.replace('#', ''), itemId),
                        repeaterData.metaData.pageId
                    )
                    const translatedPointer = translateIfNeeded(dal, pointers, dataPointer, langCode)
                    if (dal.has(translatedPointer)) {
                        res.push(dataPointer)
                    }
                    return res
                },
                []
            )
        }

        return []
    }

    return {
        dataModel: {
            addItem,
            getItem,
            generateItemIdWithPrefix,
            generateUniqueIdByType,
            components: {
                linkComponentToItemByTypeDesktopAndMobile,
                addItem: updateComponentItem,
                getItem: getComponentItem,
                getItemWithVariants: getComponentItemWithVariants,
                removeItem,
                getAllDataOverridesForComponent
            },
            removeItemRecursively
        }
    }
}

const createExtension = (): Extension => ({
    name: 'dataModel',
    dependencies: new Set(['data']),
    createExtensionAPI
})

export {createExtension}
