import type {Pointer, PS} from '@wix/document-services-types'
import variantsUtils from './variantsUtils'
import _ from 'lodash'
import relationsUtils from './relationsUtils'
import {DalValue, pointerUtils} from '@wix/document-manager-core'
import utils from '../utils/utils'
import constants from '../constants/constants'

const {
    RELATION_DATA_TYPES: {VARIANTS}
} = constants

interface Operation {
    from: Pointer[]
    to: Pointer[]
}

interface RelationData {
    originalVariants: Set<string>
    transformedVariants: Set<string>
    relationPointer?: Pointer
    from?: string
    to?: string
    value?: DalValue
    indexInRefArray?: number
}

interface ScopedValueToMaintain {
    variants: string[]
    value: DalValue
    indexInRefArray: number
}

interface ScopedValuesToMaintain {
    [variantsKey: string]: ScopedValueToMaintain
}

const NON_SCOPED_KEY = ''

const createVariantRelation = (id: string, variants: string[], from: string, to: string) => ({
    id,
    type: VARIANTS,
    variants: variants.map(variant => (typeof variant === 'string' ? `#${utils.stripHashIfExists(variant)}` : variant)),
    from: `#${utils.stripHashIfExists(from)}`,
    to: typeof to === 'string' ? `#${utils.stripHashIfExists(to)}` : to
})

const sortRelationsData = ({originalVariants: originalVariants1}, {originalVariants: originalVariants2}) =>
    originalVariants1.size - originalVariants2.size

export const applyOperations = (operations: Operation[], relationsData: RelationData[]) => {
    for (const operation of operations) {
        const {from, to} = operation

        for (const {originalVariants, transformedVariants} of relationsData) {
            const shouldApplyOperation = from.every(variant => originalVariants.has(variant.id))

            if (shouldApplyOperation) {
                for (const variant of from) {
                    transformedVariants.delete(variant.id)
                }

                for (const variant of to) {
                    transformedVariants.add(variant.id)
                }
            }
        }
    }
}

const updateVariantRelationsIfNeeded = (ps: PS, relationsData: RelationData[]) => {
    for (const {relationPointer, from, to, originalVariants, transformedVariants} of relationsData) {
        const variants = [...transformedVariants]
        if (
            variants.length !== originalVariants.size ||
            _.intersection(variants, [...originalVariants]).length !== originalVariants.size
        ) {
            ps.dal.set(relationPointer, createVariantRelation(relationPointer.id, variants, from, to))
        }
    }
}

const setRelationValueAsDefault = (ps: PS, componentPointer: Pointer, relationToDefault, dataType: string) => {
    if (!relationToDefault) {
        return
    }

    const newNonScopedDataPointer = relationsUtils.scopedValuePointer(ps, dataType, relationToDefault)
    const compPointerWithNoVariants = pointerUtils.getPointer(componentPointer.id, componentPointer.type)
    const newNonScopedValue = ps.dal.get(newNonScopedDataPointer)
    variantsUtils.updateComponentDataConsideringVariants(ps, compPointerWithNoVariants, newNonScopedValue, dataType)
}

const getValidRelations = (relationsData: RelationData[], validVariantsIds, relationsPointersToRemove) => {
    const validRelations = {}
    const validVariants = new Set(validVariantsIds)
    let relationToDefault

    for (const {relationPointer, from, to, originalVariants, transformedVariants} of relationsData) {
        const variants = [...transformedVariants]

        if (transformedVariants.size === 0) {
            relationToDefault = relationPointer
            relationsPointersToRemove.push(relationPointer)
            continue
        }

        if (variants.some(variantId => !validVariants.has(variantId))) {
            relationsPointersToRemove.push(relationPointer)
            continue
        }

        const relationKey = variants.sort().join(',')

        if (validRelations[relationKey]) {
            relationsPointersToRemove.push(validRelations[relationKey].relationPointer)
        }

        validRelations[relationKey] = {
            relationPointer,
            from,
            to,
            originalVariants,
            transformedVariants
        }
    }

    return {
        validRelations,
        relationToDefault,
        relationsPointersToRemove
    }
}

export const replaceVariantsOnSerialized = (refArrayValues: Object, operations: Operation[]) => {
    const relationsData: RelationData[] = []

    for (const [indexInRefArray, [variants, value]] of Object.entries(refArrayValues).entries()) {
        const relationVariants = variants.split(',').filter(variantId => variantId !== NON_SCOPED_KEY)

        relationsData.push({
            originalVariants: new Set(relationVariants),
            transformedVariants: new Set(relationVariants),
            value,
            indexInRefArray
        })
    }

    relationsData.sort(sortRelationsData)
    applyOperations(operations, relationsData)

    const scopedValuesToMaintain: ScopedValuesToMaintain = {}
    let defaultValue

    for (const {transformedVariants, value, indexInRefArray} of relationsData) {
        const variants = [...transformedVariants]
        const variantsKey = variants.sort().join(',')

        if (variantsKey === '') {
            defaultValue = value
            continue
        }

        scopedValuesToMaintain[variantsKey] = {variants, value, indexInRefArray}
    }

    /**
     *  Please note the following:
     *
     *  1. The order of variant relations in the refArray is important to the viewer.
     *  2. scopedValues in the refArray are serialized as an object,
     *     so we have no guarantee regarding the actual order.
     *  3. When this code was written, the system already expected to preserve order in this way.
     *     Thus, we had to maintain this behavior (insertion according to order of values in object).
     */

    const scopedValues: Object = Object.values(scopedValuesToMaintain)
        .sort(
            (updatedRelation1, updatedRelation2) => updatedRelation1.indexInRefArray - updatedRelation2.indexInRefArray
        )
        .reduce(
            (relations: Object, updatedRelation) => ({
                ...relations,
                [updatedRelation.variants.join(',')]: updatedRelation.value
            }),
            {}
        )

    return {defaultValue, scopedValues}
}

const getRelationDataFromPointer = (ps: PS, relationPointer: Pointer): RelationData => {
    const relation = ps.dal.get(relationPointer)

    if (!relation?.variants) {
        return undefined
    }

    const relationVariants = (relation.variants || []).map(utils.stripHashIfExists)

    return {
        relationPointer,
        to: relation.to,
        from: relation.from,
        originalVariants: new Set(relationVariants),
        transformedVariants: new Set(relationVariants)
    }
}

export const fixVariantsRelationsIfPossible = (
    ps: PS,
    relationsPointersToRemove: [],
    relationsPointers: Pointer[],
    componentPointer: Pointer,
    validVariantsIds: string[],
    dataType: string,
    operations: Operation[] = []
) => {
    const relationsData: RelationData[] = relationsPointers
        .map(relationPointer => getRelationDataFromPointer(ps, relationPointer))
        .filter(Boolean)
        .sort(sortRelationsData)

    applyOperations(operations, relationsData)

    const {validRelations, relationToDefault} = getValidRelations(
        relationsData,
        validVariantsIds,
        relationsPointersToRemove
    )

    updateVariantRelationsIfNeeded(ps, Object.values(validRelations))
    setRelationValueAsDefault(ps, componentPointer, relationToDefault, dataType)

    return relationsPointersToRemove
}
