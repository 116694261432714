/*eslint max-statements:0*/
import type {Point, Pointer, PS, RectTL} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import componentScroll from '../component/componentScroll'
import componentStructureInfo from '../component/componentStructureInfo'
import componentStylesAndSkinsAPI from '../component/componentStylesAndSkinsAPI'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import dataModel from '../dataModel/dataModel'
import documentModeInfo from '../documentMode/documentModeInfo'
import popupUtils from '../page/popupUtils'
import fixedComponentMeasuring from './fixedComponentMeasuring'
import relativeToScreenPlugins from './relativeToScreenPlugins/relativeToScreenPlugins'
import structureUtils from './structureUtils'

const FIXED_POSITION_COMPS_WHICH_HAVE_RENDER_PLUGIN = {
    'wysiwyg.viewer.components.HeaderContainer': true,
    'wysiwyg.viewer.components.FooterContainer': true,
    'wysiwyg.viewer.components.mobile.TinyMenu': true,
    'wysiwyg.viewer.components.MenuToggle': true
}

function getFixedCompXYCoordinates(compStyle, windowWidth: number, windowHeight, siteMarginBottom): Point {
    return {
        y: _.isNumber(compStyle.top) ? compStyle.top : windowHeight - compStyle.height - siteMarginBottom,
        x: _.isNumber(compStyle.left) ? compStyle.left : windowWidth - compStyle.width
    }
}

function buildComponentMeasuringInfo(ps: PS, compPointer: Pointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')

    return {
        data: dataModel.getDataItem(ps, compPointer),
        props: dataModel.getPropertiesItem(ps, compPointer),
        layout: ps.dal.get(compLayoutPointer)
    }
}

function getComponentLayoutProp(ps: PS, compPointer: Pointer, propName) {
    const propPointer = ps.pointers.getInnerPointer(compPointer, ['layout', propName])
    return ps.dal.get(propPointer) || 0
}

/**
 * Relative to structure
 */
function getXYInPixels(ps: PS, compPointer: Pointer): Point {
    if (ps.pointers.components.isPage(compPointer)) {
        return {
            x: 0,
            y: 0
        }
    }
    return _.pick(structureUtils.getPositionAndSize(ps, compPointer), ['x', 'y'])
}

function getFixedCompCoordinatesRelativeToStructure(ps: PS, compPointer: Pointer) {
    const relativeToStructureCoordinates = getXYInPixels(ps, compPointer)
    if (documentModeInfo.getViewMode(ps) === 'MOBILE') {
        return relativeToStructureCoordinates
    }

    relativeToStructureCoordinates.x -= getSiteX(ps)
    return relativeToStructureCoordinates
}

function sumCoordinates(coordinate1: Point, coordinate2: Point): Point {
    return {
        x: coordinate1.x + coordinate2.x,
        y: coordinate1.y + coordinate2.y
    }
}

/**
 * @param ps
 * @param viewMode
 * @param pageId
 * @returns the coordinates of the pagesContainer relative to masterPage
 */
function getPageCoordinates(ps: PS, viewMode: string, pageId: string): Point {
    const pagesContainer = ps.pointers.components.getPagesContainer(viewMode)

    return {
        x: getComponentLayoutProp(ps, pagesContainer, 'x'),
        y: popupUtils.isPopup(ps, pageId) ? 0 : getComponentLayoutProp(ps, pagesContainer, 'y')
    }
}

function isFixedPosition(ps: PS, compPointer: Pointer): boolean {
    const compFixedPositionPointer = ps.pointers.getInnerPointer(compPointer, ['layout', 'fixedPosition'])
    return ps.dal.get(compFixedPositionPointer)
}

function isRenderedInFixedPosition(ps: PS, compPointer: Pointer): boolean {
    if (!isFixedPosition(ps, compPointer)) {
        return false
    }

    const renderFlagPointer = ps.pointers.general.getRenderFlag('renderFixedPositionContainers')
    if (ps.dal.get(renderFlagPointer)) {
        return true
    }

    const compType = componentStructureInfo.getType(ps, compPointer)
    return !FIXED_POSITION_COMPS_WHICH_HAVE_RENDER_PLUGIN[compType]
}

function getCoordinatesRelativeToStructure(ps: PS, compPointer: Pointer) {
    if (isRenderedInFixedPosition(ps, compPointer)) {
        return getFixedCompCoordinatesRelativeToStructure(ps, compPointer)
    }

    const parentCompPointer = ps.pointers.components.getParent(compPointer)
    const coordinates = getXYInPixels(ps, compPointer)

    if (parentCompPointer) {
        const parentCoordinates = getCoordinatesRelativeToStructure(ps, parentCompPointer)
        if (componentsMetaData.public.isFullWidth(ps, compPointer)) {
            parentCoordinates.x = 0
        }
        return sumCoordinates(coordinates, parentCoordinates)
    }

    if (!ps.pointers.components.isInMasterPage(compPointer)) {
        const pageCoordinates = getPageCoordinates(ps, ps.pointers.components.getViewMode(compPointer), compPointer.id)
        return sumCoordinates(coordinates, pageCoordinates)
    }

    return coordinates
}

function getCompLayoutRelativeToStructure(ps: PS, compPointer: Pointer) {
    const relativeToStructureCoordinates = getCoordinatesRelativeToStructure(ps, compPointer)
    let compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
    const actualPositionAndSize = structureUtils.getPositionAndSize(ps, compPointer, compLayout)

    compLayout = _.defaults(compLayout, actualPositionAndSize)
    const relativeToStructureLayout = _.assign(compLayout, relativeToStructureCoordinates)
    relativeToStructureLayout.bounding = structureUtils.getBoundingLayout(ps, relativeToStructureLayout)
    return relativeToStructureLayout
}

function getLayoutRelativeToScreenWithMeasurer(ps: PS, compPointer: Pointer, measurer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)
    const screenSize = ps.siteAPI.getScreenSize()
    const measureMap = ps.siteAPI.getSiteMeasureMap()
    const compMeasuringInfo = buildComponentMeasuringInfo(ps, compPointer)
    let compMeasurements = fixedComponentMeasuring.getFixedComponentMeasurements(
        measurer,
        compMeasuringInfo,
        screenSize,
        measureMap.siteMarginBottom
    )
    compMeasurements = getFixedCompXYCoordinates(
        compMeasurements,
        screenSize.width,
        screenSize.height,
        measureMap.siteMarginBottom
    ) as unknown as RectTL
    return _.assign(compLayout, compMeasurements)
}

function subtractTotalScrolls(ps: PS, compPointer: Pointer, layout) {
    const {x: totalScrollX, y: totalScrollY} = componentScroll.getTotalScrolls(ps, compPointer)
    layout.x -= totalScrollX
    layout.y -= totalScrollY
}

function getMeasuredRefComponentLayout(ps: PS, compPointer: Pointer, compLayoutRelativeToScreen) {
    const layout: any = {}
    const measureMap = ps.siteAPI.getSiteMeasureMap()

    layout.y = measureMap.absoluteTop[compPointer.id]
    layout.height = measureMap.height[compPointer.id]
    layout.width = measureMap.width[compPointer.id]

    return _.defaults(layout, compLayoutRelativeToScreen)
}

function getAdjustedX(ps: PS, compPointer: Pointer, compLayoutRelativeToScreen, ignorePlugins) {
    let x: number
    if (!isHorizontallyDockedAndNotFixed(ps, compPointer) || ps.siteAPI.isMobileView()) {
        x = compLayoutRelativeToScreen.x + getSiteX(ps)
    } else {
        x = getRelativeToScreenX(ps, compPointer, ignorePlugins)
    }
    return x
}

function getCompLayoutRelativeToScreen(ps: PS, compPointer: Pointer, ignorePlugins?) {
    if (!compPointer) {
        throw new Error('Invalid component pointer')
    }
    let compLayoutRelativeToScreen
    const compType = componentStructureInfo.getType(ps, compPointer)

    const measurer = fixedComponentMeasuring.getMeasuringByType(compType)
    if (measurer) {
        compLayoutRelativeToScreen = getLayoutRelativeToScreenWithMeasurer(ps, compPointer, measurer)
    } else {
        compLayoutRelativeToScreen = getCompLayoutRelativeToStructure(ps, compPointer)

        if (santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compPointer)) {
            compLayoutRelativeToScreen = getMeasuredRefComponentLayout(ps, compPointer, compLayoutRelativeToScreen)
        }

        compLayoutRelativeToScreen.x = getAdjustedX(ps, compPointer, compLayoutRelativeToScreen, ignorePlugins)

        if (!ignorePlugins) {
            const measureMap = ps.siteAPI.getSiteMeasureMap()
            const measuredRelativeToScreen = _.get(measureMap, ['relativeToScreenOverrides', compPointer.id])
            if (measuredRelativeToScreen) {
                _.assign(compLayoutRelativeToScreen, measuredRelativeToScreen)
            }

            const relativeToScreenPlugin = relativeToScreenPlugins.getPlugin(ps, compPointer)
            if (relativeToScreenPlugin) {
                const compProperties = dataModel.getPropertiesItem(ps, compPointer)
                const skinExports = componentStylesAndSkinsAPI.skin.getComponentSkinExports(ps, compPointer)
                _.assign(
                    compLayoutRelativeToScreen,
                    relativeToScreenPlugin(
                        ps.siteAPI,
                        compLayoutRelativeToScreen,
                        compProperties,
                        compPointer,
                        skinExports
                    )
                )
            }
        }
    }

    subtractTotalScrolls(ps, compPointer, compLayoutRelativeToScreen)

    compLayoutRelativeToScreen.bounding = structureUtils.getBoundingLayout(ps, compLayoutRelativeToScreen)
    return compLayoutRelativeToScreen
}

const getRelativeToScreenX = (ps: PS, compPointer: Pointer, ignorePlugins) => {
    if (!compPointer) {
        return 0
    }

    const measureMap = ps.siteAPI.getSiteMeasureMap()
    const measuredRelativeToScreen = _.get(measureMap, ['relativeToScreenOverrides', compPointer.id, 'x'])

    if (_.isNumber(measuredRelativeToScreen)) {
        return measuredRelativeToScreen
    }

    const layoutXY = getXYInPixels(ps, compPointer)

    return layoutXY.x + getRelativeToScreenX(ps, ps.pointers.components.getParent(compPointer), ignorePlugins)
}

function getCompLayoutRelativeToScreenConsideringScroll(ps: PS, compPointer: Pointer, ignorePlugins?) {
    const compLayoutRelativeToScreen = getCompLayoutRelativeToScreen(ps, compPointer, ignorePlugins)

    if (!isShowOnFixedPosition(ps, compPointer)) {
        const scrollY = ps.siteAPI.getScroll().y
        compLayoutRelativeToScreen.y -= scrollY
        compLayoutRelativeToScreen.bounding.y -= scrollY
    }

    return compLayoutRelativeToScreen
}

function isHorizontallyDocked(ps: PS, compPointer: Pointer) {
    return structureUtils.isHorizontallyDocked(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
}

const isHorizontallyDockedAndNotFixed = (ps: PS, compPointer: Pointer) => {
    if (!compPointer) {
        return false
    }
    return (
        (isHorizontallyDocked(ps, compPointer) && !isRenderedInFixedPosition(ps, compPointer)) ||
        isHorizontallyDockedAndNotFixed(ps, ps.pointers.components.getParent(compPointer))
    )
}

function isAncestorRenderedInFixedPosition(ps: PS, compPointer: Pointer) {
    let parentCompPointer = compPointer
    do {
        parentCompPointer = ps.pointers.components.getParent(parentCompPointer)
        if (!parentCompPointer) {
            return false
        }
    } while (!isRenderedInFixedPosition(ps, parentCompPointer))
    return true
}

function getClosestAncestorRenderedInFixedPosition(ps: PS, compPointer: Pointer) {
    let parentCompPointer = compPointer
    do {
        parentCompPointer = ps.pointers.components.getParent(parentCompPointer)
        if (!parentCompPointer) {
            return false
        }
    } while (!isRenderedInFixedPosition(ps, parentCompPointer))
    return parentCompPointer
}

function getSiteX(ps: PS) {
    const siteWidth = ps.siteAPI.getSiteWidth()
    const measureMap = ps.siteAPI.getSiteMeasureMap()
    return measureMap.clientWidth > siteWidth ? 0.5 * (measureMap.clientWidth - siteWidth) : 0
}

function isShowOnFixedPosition(ps: PS, compPointer: Pointer) {
    return isRenderedInFixedPosition(ps, compPointer) || isAncestorRenderedInFixedPosition(ps, compPointer)
}

export default {
    /**
     * Calculates the coordinates of a component in the viewer relative to the masterPage
     * This is the site, not the window.
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @returns {Object} a coordinates object of the corresponding Component, with x and y properties.
     *      @example
     *      const compAbsPosition = documentServices.components.layout.getCompLayoutRelativeToStructure(componentPointer);
     *
     */
    getCompLayoutRelativeToStructure,
    /**
     * Calculates the coordinates of a component in the viewer relative to the masterPage
     * This is the site, not the window.
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @returns {Object} a Layout object of the corresponding Component.
     *      @example
     *      const compLayoutRelativeToScreen = documentServices.components.layout.getCompLayoutRelativeToScreen(componentPointer);
     *
     */
    getCompLayoutRelativeToScreen,
    getCompLayoutRelativeToScreenConsideringScroll,
    isRenderedInFixedPosition,
    isShowOnFixedPosition,
    isFixedPosition,
    isAncestorRenderedInFixedPosition,
    getClosestAncestorRenderedInFixedPosition,
    isHorizontallyDocked,
    getSiteX
}
