import {
    CreateExtArgs,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    pointerUtils,
    CreateExtensionArgument,
    DmApis
} from '@wix/document-manager-core'
import type {Pointer, ClientSpecMap, WixCodeModel} from '@wix/document-services-types'
import type {SnapshotExtApi} from './snapshots'
import _ from 'lodash'
import type {RMApi} from './rendererModel'
import * as constants from '../constants/constants'

const {getPointer} = pointerUtils
const pointerType = 'wixCode'
const pointerUndoableType = 'wixCodeUndoable'
const waitForApprovalPointerType = 'wixCodeWaitForApproval'
const isWixCodeProvisionedKey = 'isWixCodeProvisionedKey'
const isWixCodeModelExistKey = 'isWixCodeModelExistKey'
const hasSiteExtensionKey = 'hasSiteExtensionKey'

const rootId = pointerType
const NON_UNDOABLE_BASE_PATH = ['nonUndoable']
const UNDOABLE_BASE_PATH = ['undoable']
const MODIFIED_FILE_CONTENTS = NON_UNDOABLE_BASE_PATH.concat('modifiedFileContents')
const LOADED_FILE_CONTENTS = NON_UNDOABLE_BASE_PATH.concat('loadedFileContents')
const UNDOABLE_MODIFIED_FILE_CONTENTS = UNDOABLE_BASE_PATH.concat('modifiedFileContents')
const FILE_PATH_TO_ID_MAP = NON_UNDOABLE_BASE_PATH.concat('filePathToIdMap')
const DUPLICATED_FILES_INFO = NON_UNDOABLE_BASE_PATH.concat('duplicatedFilesInfo')
const GLOBAL_CACHE_KILLER = NON_UNDOABLE_BASE_PATH.concat('defaultBundleCacheKiller')
const CACHE_KILLERS_MAP = NON_UNDOABLE_BASE_PATH.concat('bundleCacheKillers')
const LOADED_CHILDREN = NON_UNDOABLE_BASE_PATH.concat('loadedChildren')
const ARE_CHILDREN_LOADED = NON_UNDOABLE_BASE_PATH.concat('areChildrenLoaded')
const IS_APP_READ_ONLY_PATH = NON_UNDOABLE_BASE_PATH.concat('isAppReadOnly')
const DIRECTORY_FLAG_BY_DELETED_PATH = NON_UNDOABLE_BASE_PATH.concat('directoryFlagByDeletedPath')
const ISOLATED_GRID_APP = 'ISOLATED_GRID_APP'

const createPointersMethods = () => {
    const getRoot = () => getPointer(rootId, pointerType)
    const getNonUndoableRoot = () => getPointer(rootId, pointerType, {innerPath: NON_UNDOABLE_BASE_PATH})
    const getUndoableRoot = () => getPointer(rootId, pointerUndoableType, {innerPath: UNDOABLE_BASE_PATH})
    const getModifiedFileContentMap = () => getPointer(rootId, pointerType, {innerPath: MODIFIED_FILE_CONTENTS})
    const getModifiedFileContent = (filePath: string[]) =>
        getPointer(rootId, pointerType, {innerPath: MODIFIED_FILE_CONTENTS.concat(filePath)})
    const getLoadedFileContentMap = () => getPointer(rootId, pointerType, {innerPath: LOADED_FILE_CONTENTS})
    const getLoadedFileContent = (filePath: string[]) =>
        getPointer(rootId, pointerType, {innerPath: LOADED_FILE_CONTENTS.concat(filePath)})
    const getUndoableModifiedFileContentMap = () =>
        getPointer(rootId, pointerUndoableType, {innerPath: UNDOABLE_MODIFIED_FILE_CONTENTS})
    const getUndoableModifiedFileContent = (filePathId: string[]) =>
        getPointer(rootId, pointerUndoableType, {innerPath: UNDOABLE_MODIFIED_FILE_CONTENTS.concat(filePathId)})
    const getFilePathToIdMap = () => getPointer(rootId, pointerType, {innerPath: FILE_PATH_TO_ID_MAP})
    const getFilePathId = (filePath: string[]) =>
        getPointer(rootId, pointerType, {innerPath: FILE_PATH_TO_ID_MAP.concat(filePath)})
    const getDuplicatedFilesInfoMap = () => getPointer(rootId, pointerType, {innerPath: DUPLICATED_FILES_INFO})
    const getGlobalBundleCacheKiller = () => getPointer(rootId, pointerType, {innerPath: GLOBAL_CACHE_KILLER})
    const getBundleCacheKillerMap = () => getPointer(rootId, pointerType, {innerPath: CACHE_KILLERS_MAP})
    const getBundleCacheKiller = (bundleId: string[]) =>
        getPointer(rootId, pointerType, {innerPath: CACHE_KILLERS_MAP.concat(bundleId)})
    const getLoadedChildrenMap = () => getPointer(rootId, pointerType, {innerPath: LOADED_CHILDREN})
    const getLoadedChildren = (parentId: string[]) =>
        getPointer(rootId, pointerType, {innerPath: LOADED_CHILDREN.concat(parentId)})
    const getAreChildrenLoadedMap = () => getPointer(rootId, pointerType, {innerPath: ARE_CHILDREN_LOADED})
    const getAreChildrenLoaded = (folderPath: string[]) =>
        getPointer(rootId, pointerType, {innerPath: ARE_CHILDREN_LOADED.concat(folderPath)})
    const getIsAppReadOnly = () => getPointer(rootId, pointerType, {innerPath: IS_APP_READ_ONLY_PATH})
    const getDirectoryFlagByDeletedPathMap = () =>
        getPointer(rootId, pointerType, {innerPath: DIRECTORY_FLAG_BY_DELETED_PATH})
    const getWaitForApproval = () => getPointer(waitForApprovalPointerType, waitForApprovalPointerType)
    const getIsolatedGridApp = () => getPointer(ISOLATED_GRID_APP, pointerType)
    //Supporting set by path as a result of returned data from the server. Should be removed once we change this methodology
    const getUndoablePointerByPath = (path: string[]) => getPointer(rootId, pointerType, {innerPath: path})

    return {
        wixCode: {
            getRoot,
            getNonUndoableRoot,
            getUndoableRoot,
            getModifiedFileContentMap,
            getModifiedFileContent,
            getLoadedFileContentMap,
            getLoadedFileContent,
            getUndoableModifiedFileContentMap,
            getUndoableModifiedFileContent,
            getFilePathToIdMap,
            getFilePathId,
            getDuplicatedFilesInfoMap,
            getLoadedChildrenMap,
            getLoadedChildren,
            getAreChildrenLoadedMap,
            getAreChildrenLoaded,
            getIsAppReadOnly,
            getDirectoryFlagByDeletedPathMap,
            getUndoablePointerByPath,
            getGlobalBundleCacheKiller,
            getBundleCacheKillerMap,
            getBundleCacheKiller,
            getWaitForApproval,
            getIsolatedGridApp
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [pointerType]: {},
    [pointerUndoableType]: {},
    [waitForApprovalPointerType]: {}
})
type WixCodePreProvisionListener = () => void

export interface WixCodeExtensionAPI extends ExtensionAPI {
    wixCode: {
        subscribeToWixCodeProvision(listener: WixCodePreProvisionListener): void
        updateWixCodeLocalProvisionState(isProvisioned: boolean): void
        updateWixCodeModelLocalState(hasWixCodeModel: boolean): void
        updateSiteExtensionState(hasSiteExtension: boolean): void
        getEditedGridAppId(): string | undefined
        getOpenGridAppId(): string | undefined
        getRevisionGridAppId(): string | undefined
        getWixCodeModel(): WixCodeModel
        syncGridAppToViewer(): void
        isProvisioned(): boolean
    }
    wixDataSchemas: {
        setLastModifiedVersion(fileId: string, version: Number): void
        getLastModifiedVersion(fileId: string): Number
    }
    siteAPI: {
        wasCodeAppIdValueChangedSinceLastSnapshot(tag: string, pointer: Pointer): boolean
    }
}

const createWixDataSchemasAPI = () => {
    const lastModifiedSchemaVersion = new Map()
    return {
        setLastModifiedVersion: (fileId: string, version: Number) => lastModifiedSchemaVersion.set(fileId, version),
        getLastModifiedVersion: (fileId: string) => lastModifiedSchemaVersion.get(fileId)
    }
}

const createExtension = ({logger}: CreateExtensionArgument): Extension => {
    const localChangesMap = new Map()
    const wixCodePreProvisionListeners: WixCodePreProvisionListener[] = []
    const notifyWixCodeProvisioned = () => {
        wixCodePreProvisionListeners.forEach(listener => {
            try {
                listener()
            } catch (e) {
                console.error(e)
                logger.captureError(e as Error, {tags: {wixCodeExtensionProvisionCallbackFailed: true}})
            }
        })
    }

    const triggerWixCodeProvisionedPostActions = (
        isWixCodeProvisioned: boolean,
        hasSiteExtension: boolean,
        hasGridAppId: boolean
    ) => {
        if (!isWixCodeProvisioned && hasSiteExtension && hasGridAppId) {
            localChangesMap.set(isWixCodeProvisionedKey, true)
            notifyWixCodeProvisioned()
        }
    }

    const onClientSpecMapUpdated = (clientSpecMap: ClientSpecMap) => {
        const isWixCodeProvisioned = localChangesMap.get(isWixCodeProvisionedKey)
        const wixCodeSiteExtension = _.find(clientSpecMap, {type: 'siteextension'})
        localChangesMap.set(hasSiteExtensionKey, !!wixCodeSiteExtension)
        const hasGridAppId = localChangesMap.get(isWixCodeModelExistKey)
        triggerWixCodeProvisionedPostActions(isWixCodeProvisioned, !!wixCodeSiteExtension, hasGridAppId)
    }

    const onWixCodeModelUpdated = (wixCodeModel: WixCodeModel) => {
        const isWixCodeProvisioned = localChangesMap.get(isWixCodeProvisionedKey)
        const hasGridAppId = !!_.get(wixCodeModel, ['appData', 'codeAppId'])
        localChangesMap.set(isWixCodeModelExistKey, hasGridAppId)
        const hasSiteExtensionInCsm = localChangesMap.get(hasSiteExtensionKey)
        triggerWixCodeProvisionedPostActions(isWixCodeProvisioned, hasSiteExtensionInCsm, hasGridAppId)
    }

    const updateWixCodeLocalProvisionState = (isWixCodeProvisioned: boolean) =>
        localChangesMap.set(isWixCodeProvisionedKey, isWixCodeProvisioned)
    const updateWixCodeModelLocalState = (isWixCodeModelExist: boolean) =>
        localChangesMap.set(isWixCodeModelExistKey, isWixCodeModelExist)
    const updateSiteExtensionState = (hasSiteExtension: boolean) =>
        localChangesMap.set(hasSiteExtensionKey, hasSiteExtension)

    const createExtensionAPI = ({extensionAPI, dal, pointers}: CreateExtArgs): WixCodeExtensionAPI => {
        const getEditedGridAppId = () => {
            if (dal.has(pointers.wixCode.getIsolatedGridApp())) {
                return dal.get(pointers.wixCode.getIsolatedGridApp())
            }
            const isEditingATemplate = dal.get(pointers.general.getNeverSaved())
            if (isEditingATemplate) {
                return dal.get(pointers.wixCode.getRevisionGridAppId())
            }
            if (dal.has(pointers.wixCode.getOpenWixCodeAppId())) {
                return dal.get(pointers.wixCode.getOpenWixCodeAppId())
            }
            return dal.get(pointers.wixCode.getRevisionGridAppId())
        }
        const hasWixCodeSpec = () => {
            const clientSpecMap = dal.get(pointers.rendererModel.getClientSpecMap())
            return !_.isEmpty(_.find(clientSpecMap, {type: 'siteextension'}))
        }
        const hasGridApp = () => !!getEditedGridAppId()

        const isProvisioned = () => hasWixCodeSpec() && hasGridApp()
        return {
            wixCode: {
                subscribeToWixCodeProvision: (listener: WixCodePreProvisionListener) => {
                    if (_.isFunction(listener)) {
                        wixCodePreProvisionListeners.push(listener)
                    }
                },
                updateWixCodeLocalProvisionState,
                updateWixCodeModelLocalState,
                updateSiteExtensionState,
                isProvisioned,
                getEditedGridAppId,
                getOpenGridAppId: () => dal.get(pointers.wixCode.getOpenWixCodeAppId()),
                getRevisionGridAppId: () => dal.get(pointers.wixCode.getRevisionGridAppId()),
                getWixCodeModel: () => dal.get(pointers.wixCode.getWixCodeModel()),
                syncGridAppToViewer: () => {
                    dal.touch(pointers.wixCode.getOpenWixCodeAppId())
                }
            },
            wixDataSchemas: createWixDataSchemasAPI(),
            siteAPI: {
                wasCodeAppIdValueChangedSinceLastSnapshot: (tag: string, pointer: Pointer) => {
                    const {snapshots} = extensionAPI as SnapshotExtApi
                    const previousSnapshot = snapshots.getLastSnapshotByTagName(tag)

                    if (!previousSnapshot) {
                        return false
                    }

                    const currentSnapshot = snapshots.getCurrentSnapshot()

                    const prevVal = previousSnapshot.getValue(pointer)
                    const currentVal = currentSnapshot.getValue(pointer)

                    return prevVal !== currentVal
                }
            }
        }
    }

    const recoverFromHavingOpenGridAppIdInTheRevisionGridAppPointer = ({extensionAPI, pointers, dal}: DmApis) => {
        // in the past, on load, we used to set the revision grid app pointer with the open grid app id
        // some sites have this saved in csave transactions
        // this code sets back the revision grid app pointer to have the value of the revision grid app id
        const {snapshots} = extensionAPI as SnapshotExtApi
        const {wixCode} = extensionAPI as WixCodeExtensionAPI
        const openGridApp = wixCode.getOpenGridAppId()
        const currentRevisionGridApp = wixCode.getRevisionGridAppId()
        if (openGridApp && openGridApp === currentRevisionGridApp) {
            const beforeCsaveSnapshot = snapshots.getLastSnapshotByTagName(constants.SNAPSHOTS.BEFORE_AUTOSAVE_APPLY)
            if (!beforeCsaveSnapshot) {
                return
            }
            const revisionGridAppPointer = pointers.wixCode.getRevisionGridAppId()
            const previousRevisionGridApp = beforeCsaveSnapshot.getValue(revisionGridAppPointer)
            if (previousRevisionGridApp !== currentRevisionGridApp) {
                dal.set(revisionGridAppPointer, previousRevisionGridApp)
            }
        }
    }
    const initialize = async (dmApis: DmApis) => {
        const {rendererModel} = dmApis.extensionAPI as RMApi
        rendererModel.registerToClientSpecMapUpdate(onClientSpecMapUpdated)
        rendererModel.registerToWixCodeModelUpdate(onWixCodeModelUpdated)
        recoverFromHavingOpenGridAppIdInTheRevisionGridAppPointer(dmApis)
    }

    return {
        name: 'wixCode',
        createPointersMethods,
        initialize,
        createExtensionAPI,
        getDocumentDataTypes
    }
}

export {createExtension}
