import componentStructureInfoBuilder from './services/componentStructureInfoBuilder'
import dataModelBuilder from './services/dataModelBuilder'
import dataValidatorsBuilder from './services/dataValidatorsBuilder'
import skinsRegistrar from './services/skinsRegistrar'
import themeValidationHelper from './services/themeValidationHelper'
import createSchemasService from './util/createSchemasService'
import {createDualService} from './util/dualSchemaService'
import type {DualSchemaService} from '@wix/document-services-types'
import {componentTypeAliases, skinsByComponentType, newSchemaService} from '@wix/document-services-json-schemas'

/**
 * Create the underlying schema service with new schema approach
 * @param clientOnlySchemas
 * @param newSchemaServiceInstance
 * @returns A schema service
 */
const createMainSchemaService = (
    clientOnlySchemas,
    newSchemaServiceInstance = newSchemaService.staticInstance
): DualSchemaService => createDualService(clientOnlySchemas, newSchemaServiceInstance, true)

export default (clientOnlySchemas, newSchemaServiceInstance) => {
    const schemasService = createMainSchemaService(clientOnlySchemas, newSchemaServiceInstance)
    const componentStructureInfo = componentStructureInfoBuilder(schemasService)
    const dataValidators = dataValidatorsBuilder(schemasService)
    const dataModel = dataModelBuilder(schemasService)

    const extraSkins = {}

    const skinsByComponentTypeInstance = skinsByComponentType.createSkinsByComponentType(
        clientOnlySchemas.componentsDefinitionsMap,
        extraSkins
    )
    const skinsRegistrarInstance = skinsRegistrar.createRegistrar(skinsByComponentTypeInstance, componentTypeAliases)

    return {
        schemas: clientOnlySchemas,
        util: {
            createSchemasService
        },
        services: {
            buildDefaultComponentStructure: componentStructureInfo.buildDefaultComponentStructure,
            createDataItemByType: dataModel.createDataItemByType,
            createStyleItemByType: dataModel.createStyleItemByType,
            createPropertiesItemByType: dataModel.createPropertiesItemByType,
            dataValidators,
            schemasService,
            getComponentSkins: skinsRegistrarInstance.getComponentSkins,
            getComponentResponsiveSkins: skinsRegistrarInstance.getComponentResponsiveSkins,
            registerSkinsByCompType: skinsRegistrarInstance.registerSkinsByCompType,
            isContainer: schemasService.isContainer,
            themeValidationHelper
        }
    }
}
