import * as dmCore from '@wix/document-manager-core'
import type {Callback1, Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import experiments from 'experiment-amd'
import _ from 'lodash'
import componentCode from '../../component/componentCode'
import customNicknameRegistrar from '../../component/customNicknameRegistrar'
import constants from '../../constants/constants'
import common from '../../dataModel/common'
import dataModel from '../../dataModel/dataModel'
import refComponentUtils from '../../refComponent/refComponentUtils'
import connectionsHooks from './connectionsHooks'
import nickname from './nickname'

const {DATA_TYPES, VIEWER_PAGE_DATA_TYPES, COMP_DATA_QUERY_KEYS_WITH_STYLE} = constants
const CONNECTIONS_ITEM_TYPE = 'connections'

const REF_OVERRIDES_TO_REMOVE = {}
const IGNORED_REFRENCES_FOR_TYPES = {
    StyledText: ['linkList']
}

/**
 * @param ps
 * @param baseId
 * @param itemType
 * @param {*} serializedItem
 * @return {*}
 */
function generateUniqueIdsForInnerRefs(ps: PS, baseId, itemType, serializedItem) {
    if (!_.isObject(serializedItem)) {
        return serializedItem
    }

    // @ts-expect-error
    const dataPtr = ps.pointers.data.getItem(itemType, serializedItem.id)

    if (!santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(dataPtr)) {
        // @ts-expect-error
        serializedItem.id = santaCoreUtils.guidUtils.getUniqueId(baseId, '-', {bucket: 'innerRefs'})
    }

    // @ts-expect-error
    const schemaName = serializedItem.type

    let serializedItemToIterate = serializedItem
    if (IGNORED_REFRENCES_FOR_TYPES[schemaName]) {
        serializedItemToIterate = _.omit(serializedItemToIterate, IGNORED_REFRENCES_FOR_TYPES[schemaName])
    }
    _.forOwn(serializedItemToIterate, function (value, key) {
        if (_.isPlainObject(value) && common.isOfType(itemType, schemaName, key, 'ref')) {
            serializedItem[key] = generateUniqueIdsForInnerRefs(ps, baseId, itemType, value)
        } else if (common.isOfType(itemType, schemaName, key, 'refList')) {
            serializedItem[key] = _.map(value, generateUniqueIdsForInnerRefs.bind(null, ps, baseId, itemType))
        } else if (key !== 'id') {
            serializedItem[key] = value
        }
    })

    return serializedItem
}

function filterConnectionItems(ps: PS, connectionList) {
    connectionList.items = _.reject(connectionList.items, ({controllerId, isPrimary}) => {
        if (!isPrimary) {
            return false
        }

        const controllerPtr = ps.pointers.data.getItem(DATA_TYPES.connections, controllerId)
        return santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(controllerPtr)
    })

    return connectionList
}

function beforeSetRefArray(ps: PS, curResultValue, compRef, itemType, pageId, relId, refArrayId) {
    if (!santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compRef)) {
        return curResultValue
    }

    const overrideRefArrayPointer = ps.pointers.data.getItem(itemType, refArrayId, pageId)
    const arrayOfOverridesPointer = ps.pointers.referredStructure.getPointerWithoutFallbacks(overrideRefArrayPointer)
    const overrideItems = ps.dal.get(arrayOfOverridesPointer)

    const newValues = overrideItems?.values ? [...overrideItems.values, `#${relId}`] : [`#${relId}`]

    return newValues
}

function beforeDeserialize(ps: PS, serializedItem, itemType, dataItemId: string) {
    if (dataItemId) {
        const dataPointer = ps.pointers.data.getItem(itemType, dataItemId)

        if (santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(dataPointer)) {
            const originalDataItem = dataModel.getDataByPointer(ps, itemType, dataPointer) || {}
            const baseId = refComponentUtils.extractBaseComponentId(dataPointer)
            serializedItem.type = serializedItem.type || originalDataItem.type

            if (serializedItem.type === originalDataItem.type) {
                serializedItem = {...originalDataItem, ...serializedItem}
            }

            const dataItemWithFixedIds = generateUniqueIdsForInnerRefs(ps, baseId, itemType, serializedItem)

            if (itemType === DATA_TYPES.connections) {
                return filterConnectionItems(ps, dataItemWithFixedIds)
            }

            return dataItemWithFixedIds
        }
    }

    return serializedItem
}

function copyOverridenData(ps: PS, compToAddPointer, overriddenData, originalNicknameContext) {
    const pageId = ps.pointers.full.components.getPageOfComponent(compToAddPointer).id
    _.forEach(overriddenData, ({compId, itemType, dataItem, isMobile}) => {
        refComponentUtils.createOverrideDataItem(
            ps,
            itemType,
            compToAddPointer,
            compId,
            pageId,
            dataItem,
            isMobile,
            originalNicknameContext
        )
    })
}

function handleDataOverridesAfterAdd(ps: PS, compToAddPointer, clonedSerializedComp) {
    const {overriddenData, originalNicknameContext} = clonedSerializedComp.custom || {}
    if (overriddenData) {
        copyOverridenData(ps, compToAddPointer, overriddenData, originalNicknameContext)
    }
}

function handleRootCompDataOverridesAfterAdd(ps: PS, compToAddPointer, clonedSerializedComp) {
    const {overrideRootConnections, overrideRootData} = clonedSerializedComp.custom || {}
    const rootComp = _.head(ps.pointers.components.getChildren(compToAddPointer))
    if (overrideRootConnections) {
        dataModel.updateConnectionsItem(ps, rootComp, overrideRootConnections)
    }
    if (overrideRootData) {
        const pageId = ps.pointers.full.components.getPageOfComponent(compToAddPointer).id
        const dataItemId = dataModel.getDataItemPointer(ps, rootComp).id
        refComponentUtils.DATA_SETTERS.data(ps, pageId, overrideRootData, dataItemId)
    }
}

const filterConnectionOverrides = overriddenData => _.filter(overriddenData, {itemType: CONNECTIONS_ITEM_TYPE})

function updateConnectionOverridesControllerIds(ps: PS, overriddenData, containerRef, compId, oldToNewIdMap) {
    const connectionOverrideItems = filterConnectionOverrides(overriddenData)
    _.forEach(connectionOverrideItems, item => {
        const updatedConnectionItems = connectionsHooks.getUpdatedSerializedConnections(
            ps,
            containerRef,
            compId,
            item.dataItem.items,
            false,
            oldToNewIdMap
        )
        if (updatedConnectionItems) {
            item.dataItem.items = updatedConnectionItems
        }
    })
}

function beforeAddComponent(ps: PS, componentRef, containerRef, compDefinition, optionalCustomId, isPage, mappers) {
    const {overriddenData} = compDefinition.custom || {}
    compDefinition.components = []

    if (overriddenData) {
        updateConnectionOverridesControllerIds(
            ps,
            overriddenData,
            containerRef,
            compDefinition.id,
            mappers?.oldToNewIdMap
        )
    }
}

function afterAddComponent(ps: PS, compToAddPointer, clonedSerializedComp) {
    handleDataOverridesAfterAdd(ps, compToAddPointer, clonedSerializedComp)

    const generateNickname = ps.extensionAPI.scopes?.wrapMethodWithDisableScopes(() => {
        const pagePointer = ps.pointers.components.getPageOfComponent(compToAddPointer)
        const templatePointer = dmCore.pointerUtils.getRepeatedItemPointerIfNeeded(compToAddPointer)
        const referredComponents = ps.pointers.components.getChildrenRecursively(templatePointer)
        nickname.generateNicknamesForComponents(ps, referredComponents, pagePointer)
        handleRootCompDataOverridesAfterAdd(ps, compToAddPointer, clonedSerializedComp)
    })

    if (generateNickname) {
        ps.setOperationsQueue.registerToNextSiteChanged(generateNickname as Callback1<any>)
    }
}

function customNicknameGetter(ps: PS, compPointer, compNickname, context) {
    const pagePointer = ps.pointers.full.components.getPageOfComponent(compPointer)

    return _(compPointer)
        .thru(ps.pointers.referredStructure.getConnectionOverrides)
        .mapKeys(connectionPtr => refComponentUtils.extractBaseComponentId(connectionPtr))
        .mapValues(connectionPtr =>
            componentCode.getNicknameByConnectionPointer(ps, connectionPtr, pagePointer, context)
        )
        .assign({[compPointer.id]: compNickname})
        .pickBy()
        .value()
}

const removeAllOverrides = (ps: PS, compPointer) => {
    const overrides = REF_OVERRIDES_TO_REMOVE[compPointer.id] || []
    refComponentUtils.removeOverrides(ps, overrides)
    delete REF_OVERRIDES_TO_REMOVE[compPointer.id]
}

customNicknameRegistrar.registerCustomGetter('wysiwyg.viewer.components.RefComponent', customNicknameGetter)

const saveOverrides = (ps: PS, compPointer) => {
    const overrides = refComponentUtils.getAllOverridesToBeRemoved(ps, compPointer, false, true)
    REF_OVERRIDES_TO_REMOVE[compPointer.id] = overrides
}

const QUERY_TO_NS = _.invert(COMP_DATA_QUERY_KEYS_WITH_STYLE)

const getItemQueryId = (ps: PS, dataId: string, compPointer: Pointer, propName: string) => {
    if (dataId || !santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(compPointer)) {
        return dataId
    }

    const namespaceItemType = QUERY_TO_NS[propName]
    //ref component inflation happens in viewer, and only supports viewer types
    if (!VIEWER_PAGE_DATA_TYPES[namespaceItemType]) {
        return dataId
    }
    // this means we will create an override item using this id, which is the convention the component expects
    // essentially we enable editing data for a component which did not have it in the remote
    if (experiments.isOpen('dm_mobileSuffixForNewRefComponents')) {
        return refComponentUtils.getItemQueryId(compPointer, namespaceItemType)
    }

    return `${compPointer.id}-${propName}`
}

export default {
    getItemQueryId,
    saveOverrides,
    beforeSetRefArray,
    beforeDeserialize,
    beforeAddComponent,
    afterAddComponent,
    setCustomSerializeData: refComponentUtils.setCustomSerializeData,
    removeAllOverrides
}
