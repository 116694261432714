import type {NestedRecord} from '@wix/document-manager-utils'
import type {
    Callback,
    DataFixer,
    DocumentServicesObject,
    DSConfig,
    FedopsLogger,
    PS,
    PublicMethodDefinition,
    SetOperationsQueueNoRender,
    SetOperationsQueuePublic
} from '@wix/document-services-types'
import _ from 'lodash'
import {Deprecation, deprecationUtil} from './publicAPI/apiUtils/deprecation'
import {createPublicMethodUtils} from './publicAPI/apiUtils/publicMethods'
import {createPromisesObject, createTransactionsApi} from './transactions/transactions'

const addTransactionsAndPromisesApiToNamespaces = (
    publicNamespaces: PublicNamespaces,
    documentServices: DocumentServicesObject
) => {
    _.set(publicNamespaces, ['actions', 'promises'], documentServices.promises)
    _.set(publicNamespaces, ['actions', 'transactions', 'run'], documentServices.transactions.run)
    _.set(
        publicNamespaces,
        ['actions', 'transactions', 'runAndWaitForApproval'],
        documentServices.transactions.runAndWaitForApproval
    )
    _.set(publicNamespaces, ['constants', 'transactions', 'errors'], documentServices.transactions.errors)
    _.set(publicNamespaces, ['read', 'transactions', 'isRunning'], documentServices.transactions.isRunning)
}

function initPublicNamespaces(
    publicNamespaces: Record<string, any>,
    utils: Utils,
    parentPath: string,
    value: any,
    key: string
) {
    const path = (parentPath ? `${parentPath}.` : '') + key

    value = utils.wrapDeprecatedFunction(value, path)

    if (_.get(value, ['isPublicAPIDefinition'])) {
        _.set(publicNamespaces[value.methodType], path, utils.resolvePublicAPIDefinition(value, path))
        value.path = path
        return
    }
    if (!_.isObject(value) || _.isArray(value)) {
        _.set(publicNamespaces.constants, path, value)
        return
    }
    _.forEach(value, initPublicNamespaces.bind(null, publicNamespaces, utils, path))
}

function getConfigurationMethods(modules: PublicModuleDeclaration[]) {
    const methods = {}
    const modulesMethods = _(modules).map('methods').clone()
    modulesMethods.unshift(methods)
    _.merge.apply(_, modulesMethods as [o: any, ...a: any[]]) // eslint-disable-line prefer-spread

    return methods
}

function addPublicMethodsToScope(
    namespace: Record<string, any>,
    utils: Utils,
    parentPath: string,
    value: any,
    key: string
) {
    const path = (parentPath ? `${parentPath}.` : '') + key

    value = utils.wrapDeprecatedFunction(value, path)

    if (_.get(value, ['isPublicAPIDefinition'])) {
        namespace[key] = utils.resolvePublicAPIDefinition(value, path)
    } else if (_.isArray(value)) {
        namespace[key] = value
    } else if (_.isObject(value)) {
        namespace[key] = {}
        _.forEach(value, addPublicMethodsToScope.bind(null, namespace[key], utils, path))
    } else {
        namespace[key] = value
    }
}

const waitForChangesFunc = (ps: PS) =>
    function (callback: Callback, onlyChangesAlreadyRegistered: boolean) {
        if (onlyChangesAlreadyRegistered) {
            ps.setOperationsQueue.flushQueueAndExecute(callback)
        } else {
            ps.setOperationsQueue.waitForChangesApplied(callback)
        }
    }

const getSOQPublicAPI = (
    ps: PS,
    config: DSConfig
): SetOperationsQueuePublic | SetOperationsQueueNoRender | undefined => {
    if (ps.setOperationsQueue) {
        if (config.shouldRender) {
            return {
                waitForChangesApplied: waitForChangesFunc(ps),
                // for testing purposes only
                waitForChangesAppliedAsync: ps.setOperationsQueue.waitForChangesAppliedAsync.bind(
                    ps.setOperationsQueue
                ),
                //this method has a different timing than waitForChangesApplied. it will be resolved after the siteChanged is fired instead of before
                forSiteChanged: (onlyChangesAlreadyRegistered: boolean) =>
                    new Promise(resolve => waitForChangesFunc(ps)(resolve, onlyChangesAlreadyRegistered)),
                registerToErrorThrown: ps.setOperationsQueue.registerToErrorThrown.bind(ps.setOperationsQueue),
                registerToErrorThrownForContext: ps.setOperationsQueue.registerToErrorThrownForContext.bind(
                    ps.setOperationsQueue
                ),
                unRegisterToErrorThrownForContext: ps.setOperationsQueue.unRegisterToErrorThrownForContext.bind(
                    ps.setOperationsQueue
                ),
                unRegisterFromErrorThrown: ps.setOperationsQueue.unRegisterFromErrorThrown.bind(ps.setOperationsQueue),
                registerToSiteChanged: ps.setOperationsQueue.registerToSiteChanged.bind(ps.setOperationsQueue),
                useSimpleTimeoutToDeferQueue: ps.setOperationsQueue.useSimpleTimeoutToDefer.bind(ps.setOperationsQueue),
                useAnimationFrameToDeferQueue: ps.setOperationsQueue.useAnimationFrameToDefer.bind(
                    ps.setOperationsQueue
                ),
                addCallContext: ps.setOperationsQueue.addCallContext.bind(ps.setOperationsQueue),
                removeCallContext: ps.setOperationsQueue.removeCallContext.bind(ps.setOperationsQueue),
                runInContext: ps.setOperationsQueue.runInContext.bind(ps.setOperationsQueue, ps)
            }
        }
        return {
            // @ts-ignore
            registerToSiteDataChanges: ps.setOperationsQueue.registerToSiteDataChanged.bind(ps.setOperationsQueue),
            waitForChangesApplied: (callback: Callback) => callback()
        }
    }
}

export const addSetOperationsQueueToPublicAPI = (
    ps: PS,
    config: DSConfig,
    documentServices: DocumentServicesObject
) => {
    const SOQ = getSOQPublicAPI(ps, config)
    documentServices.SOQ = SOQ
    _.assign(documentServices, SOQ)
}

const getPartialConfig = ({
    runStylesGC,
    settleInServer,
    disableSave,
    firstSaveExtraPayload,
    selectiveCompsDS
}: any) => ({
    runStylesGC,
    disableSave,
    settleInServer,
    firstSaveExtraPayload,
    selectiveCompsDS
})

const runDocumentServicesDataFixers = (ps: PS, documentServicesDataFixer: DataFixer) => {
    documentServicesDataFixer.fix(ps)
}

const initModulesPromise = (modules: PublicModuleDeclaration[], ps: PS, config: DSConfig, logger: FedopsLogger) => {
    const fakeLogger = {
        interactionStarted: _.noop,
        interactionEnded: _.noop,
        breadcrumb: _.noop,
        captureError: _.noop
    }
    const isLoggerExist = !_.isUndefined(logger)
    const fedopsLogger = isLoggerExist ? logger : fakeLogger
    const partialConfig = getPartialConfig(config)
    const INTERACTION_ASYNC_INIT = 'ds-modules-async-init'
    const INTERACTION_INIT = 'ds-modules-init'

    fedopsLogger.interactionStarted(INTERACTION_INIT)
    _.invokeMap(modules, 'initMethod', ps, partialConfig)
    fedopsLogger.interactionEnded(INTERACTION_INIT)
    const promises = _(modules).invokeMap('asyncInitMethod', ps, partialConfig).compact().value()

    if (promises.length) {
        fedopsLogger.interactionStarted(INTERACTION_ASYNC_INIT)
        return Promise.all(promises).then(() => {
            fedopsLogger.interactionEnded(INTERACTION_ASYNC_INIT)
        })
    }
    return Promise.resolve()
}

const addTransactionsApi = (ds: DocumentServicesObject, ps: PS, methods: NestedRecord<PublicMethodDefinition>) => {
    ds.promises = createPromisesObject(ps, ds, methods)
    ds.transactions = createTransactionsApi(ds, ps)
}

/**
 * This init assumes any autosave patches have already been applied if needed
 * @param adapter
 * @param modules
 * @param {*} documentServices
 * @param logger
 * @returns {Promise<void>}
 */
const initDocumentServices = async (
    adapter: Adapter,
    modules: PublicModuleDeclaration[],
    documentServices: DocumentServicesObject,
    logger: FedopsLogger
) => {
    const {config, ps, documentServicesDataFixer} = adapter
    runDocumentServicesDataFixers(ps, documentServicesDataFixer)
    ps.dal.commitTransaction('DSDataFixers', true)
    addSetOperationsQueueToPublicAPI(ps, config, documentServices)
    await initModulesPromise(modules, ps, config, logger)
}

/**
 * @param adapter
 * @param modules
 * @param logger
 * @returns {Promise<*>}
 */
export const createDocumentServices = async (
    adapter: Adapter,
    modules: PublicModuleDeclaration[],
    logger: FedopsLogger
) => {
    const documentServices = initDocumentServicesPublicAPI(adapter, {} as DocumentServicesObject, modules)
    await initDocumentServices(adapter, modules, documentServices, logger)
    return documentServices
}

export interface PublicModuleDeclaration {
    methods?: any
    initMethod?: Function
    asyncInitMethod(): Promise<any>
}

interface PublicNamespaces {
    read: NestedRecord<any>
    actions: NestedRecord<any>
    constants: NestedRecord<any>
}

interface Adapter {
    ps: PS
    config: any
    documentServicesDataFixer: any
}

interface Utils {
    wrapDeprecatedFunction(methodDef: PublicMethodDefinition, methodName?: string): PublicMethodDefinition
    resolvePublicAPIDefinition(apiDefinition: PublicMethodDefinition, methodPath?: string): Function
}

export function initDocumentServicesPublicAPI(
    adapter: Adapter,
    documentServices: DocumentServicesObject,
    modules: PublicModuleDeclaration[]
): DocumentServicesObject {
    const {config, ps} = adapter
    const publicMethodUtils = createPublicMethodUtils()
    const deprecation: Deprecation = deprecationUtil(publicMethodUtils)

    const utils: Utils = {
        wrapDeprecatedFunction: deprecation.wrapDeprecatedFunction.bind(null, ps),
        resolvePublicAPIDefinition: publicMethodUtils.resolvePublicAPIDefinition.bind(
            null,
            documentServices,
            config,
            ps
        )
    }

    const methods = getConfigurationMethods(modules || config.modules)
    const rootNamespacePath = ''
    _.forEach(methods, addPublicMethodsToScope.bind(null, documentServices, utils, rootNamespacePath))

    addTransactionsApi(documentServices, ps, methods)

    const publicNamespaces: Partial<PublicNamespaces> = {}

    function getPublicMethodsByNamespaceWithLazyInit(namespace: string) {
        if (_.isEmpty(publicNamespaces)) {
            _.assign(publicNamespaces, {read: {}, actions: {}, constants: {}})
            _.forEach(methods, initPublicNamespaces.bind(null, publicNamespaces, utils, rootNamespacePath))
            _.assign(publicNamespaces.actions, documentServices.SOQ)
            // @ts-ignore
            publicNamespaces.actions.history = documentServices.history

            addTransactionsAndPromisesApiToNamespaces(publicNamespaces as unknown as PublicNamespaces, documentServices)
        }

        return publicNamespaces[namespace]
    }

    documentServices.getReadMethods = /** dsRead */ getPublicMethodsByNamespaceWithLazyInit.bind(null, 'read')
    documentServices.getActions = /** dsWrite */ getPublicMethodsByNamespaceWithLazyInit.bind(null, 'actions')
    documentServices.getConstants = /** dsConstants */ getPublicMethodsByNamespaceWithLazyInit.bind(null, 'constants')

    return documentServices
}
