import component from '../../component/component'
import structure from '../../structure/structure'
import dataModel from '../../dataModel/dataModel'

export default (ps, compPointer) => {
    const bindToComp =
        func =>
        (...args) =>
            func(ps, compPointer, ...args)
    return {
        layout: {
            update: bindToComp(structure.updateCompLayout)
        },
        data: {
            update: bindToComp(dataModel.updateDataItem)
        },
        properties: {
            update: bindToComp(component.properties.update)
        }
    }
}
