import type {Callback1, PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import _ from 'lodash'
import editorServerFacade from '../editorServerFacade/editorServerFacade'
import appStudioDataModel from './appStudioDataModel'

const ERRORS = {
    INVALID_CHARS: 'INVALID_CHARS',
    EMPTY_NAME: 'EMPTY_NAME',
    INVALID_LENGTH: 'INVALID_LENGTH',
    ONLY_SPACES: 'ONLY_SPACES'
}

const hasEmojis = (str: string) =>
    str.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)

const onlySpaces = (str: string) => _.trim(str) === ''

const isValidAppName = (appName: string) => {
    if (_.size(appName) === 0) {
        return {success: false, errorCode: ERRORS.EMPTY_NAME}
    }
    if (_.size(appName) < 1 || _.size(appName) > 30) {
        return {success: false, errorCode: ERRORS.INVALID_LENGTH}
    }
    if (hasEmojis(appName)) {
        return {success: false, errorCode: ERRORS.INVALID_CHARS}
    }
    if (onlySpaces(appName)) {
        return {success: false, errorCode: ERRORS.ONLY_SPACES}
    }

    return {success: true}
}

const setAppNameData = (ps: PS, appName?: string) => {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    appStudioData.name = appName
    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
}

const setAppName = (ps: PS, appName: string) => {
    if (!isValidAppName(appName).success) {
        throw new Error('invalid appName')
    }
    setAppNameData(ps, appName)
}

const clearAppName = (ps: PS) => setAppNameData(ps)

const getAppName = (ps: PS) => {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)
    return appStudioData.name
}

const getUsedAppNames = (ps: PS, onSuccess: Callback1<any>, onError?: Callback1<any>) => {
    const {accountId} = ps.dal.get(ps.pointers.documentServicesModel.getUserInfo())

    editorServerFacade.sendWithPs(
        ps,
        editorServerFacade.ENDPOINTS.GET_APPS_NEW,
        {query: {filter: {accountId}}},
        async response => {
            if (response.apps) {
                const appNames = _.map(response.apps, 'name')
                onSuccess(appNames)
            }
        },
        (error: Callback1<any>) => onError(error)
    )
}

const getUsedAppNamesOld = (ps: PS, onSuccess: Callback1<any>, onError?: Callback1<any>) => {
    editorServerFacade.sendWithPs(
        ps,
        editorServerFacade.ENDPOINTS.GET_APPS,
        {},
        async response => {
            if (response.apps) {
                const appNames = _.map(response.apps, 'name')
                onSuccess(appNames)
            }
        },
        (error: Callback1<any>) => onError(error)
    )
}

export default {
    set: setAppName,
    get: getAppName,
    clear: clearAppName,
    validate: (ps: PS, appName: string) => isValidAppName(appName),
    getUsedAppNames: experiment.isOpen('dm_blocksNewDevCenterApiApps') ? getUsedAppNames : getUsedAppNamesOld,
    getAppName,
    ERRORS
}
