import _ from 'lodash'
import wixImmutable from '@wix/santa-core-utils/dist/wixImmutable'
import diffCalculator from '../utils/diffCalculator'
import pathUtils from '../utils/pathUtils'
import serverApi from './serverApi'

const parts = wixImmutable.fromJS({parts: null})
const dataSelectors = wixImmutable.fromJS({dataSelectors: null})
const views = wixImmutable.fromJS({views: null})
const types = wixImmutable.fromJS({types: null})

// wrap parts, dataSelectors, views and types, because of the difference in json structure
// between them and items. items has another level of type/itemId, and diffCalculator knows
// this structure. this is done in order to avoid deep diff
const appbuilderGetters = {
    items(data) {
        return getByPath(data, pathUtils.getBaseItemsPath())
    },
    parts(data) {
        return parts.update('parts', getByPath.bind(null, data, pathUtils.getBasePartsPath()))
    },
    dataSelectors(data) {
        return dataSelectors.update('dataSelectors', getByPath.bind(this, data, pathUtils.getBaseDataSelectorsPath()))
    },
    views(data) {
        return views.update('views', getByPath.bind(this, data, pathUtils.getBaseViewsPath()))
    },
    types(data) {
        return types.update('types', getByPath.bind(this, data, pathUtils.getBaseTypesPath()))
    }
}

const fieldsToIgnoreOnDiff = ['_state']

function applyTimestamp(itemBefore, itemAfter) {
    return _.assign(itemAfter, {_timestamp: itemBefore._updatedAt})
}

function getByPath(data, path) {
    return data.getIn(path, wixImmutable.fromJS({}))
}

function getDiffForDataType(lastSavedData, currentData, ignoreList, transformFunc, dataType) {
    const lastSnapshotOfDataType = appbuilderGetters[dataType](lastSavedData)
    const currentSnapshotOfDataType = appbuilderGetters[dataType](currentData)
    return diffCalculator.getItemsDiff(lastSnapshotOfDataType, currentSnapshotOfDataType, ignoreList, transformFunc)
}

function getDiff(lastSavedData, currentData, dataTypes, ignoreList?, transformFunc?) {
    const diffsPerDataType = _.map(
        dataTypes,
        getDiffForDataType.bind(null, lastSavedData, currentData, ignoreList, transformFunc)
    )

    return {
        created: _(diffsPerDataType).map('created').flattenDeep().value(),
        updated: _(diffsPerDataType).map('updated').flattenDeep().value(),
        deleted: _(diffsPerDataType).map('deleted').flattenDeep().value()
    }
}

function hasNoData(data) {
    return !data || _.every(appbuilderGetters, getter => getter(data).every(immutableData => immutableData?.isEmpty()))
}

function hasDataInPayload(payload) {
    return payload.repo || _.some(payload.dataItems, arr => arr.length)
}

function saveAll(getAppInstance, lastSavedData, currentData, resolve, reject, biCallbacks) {
    if (currentData === lastSavedData || (hasNoData(currentData) && hasNoData(lastSavedData))) {
        resolve()
        return
    }

    const payload = getSavePayload(lastSavedData, currentData)

    if (!hasDataInPayload(payload)) {
        resolve()
        return
    }

    serverApi.saveRepoAndItems(getAppInstance(), payload.repo, payload.dataItems, biCallbacks).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
}

function getRepo(lastSavedData, currentData) {
    const repoDiffs = getDiff(lastSavedData, currentData, ['types', 'parts', 'dataSelectors', 'views'])
    const hasChanges = _.some(repoDiffs, function (changeList) {
        return changeList.length > 0
    })

    if (!hasChanges) {
        return null
    }

    const convertedToArrays = _.transform(
        ['dataSelectors', 'views', 'types'],
        function (res, dataType) {
            const repoPart = appbuilderGetters[dataType](currentData).toJS()
            res[dataType] = _(repoPart).map(_.values).flattenDeep().value()
        },
        {}
    )

    const asObjects = appbuilderGetters.parts(currentData).toJS()

    return _.assign(convertedToArrays, asObjects)
}

function getSavePayload(lastSavedData, currentData) {
    return {
        dataItems: getDiff(lastSavedData, currentData, ['items'], fieldsToIgnoreOnDiff, applyTimestamp),
        repo: getRepo(lastSavedData, currentData)
    }
}

function publish(getAppInstance, currentData, resolve, reject, biCallbacks) {
    if (hasNoData(currentData)) {
        resolve()
        return
    }

    serverApi.publish(getAppInstance(), biCallbacks).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
}

export default {
    saveAll,
    publish
}
