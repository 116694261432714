import _ from 'lodash'
import dataGetters from './dataGetters'

const urlTemplateMatcher = /{urlTemplate:(.*)}/

const resolveByDataGetter = (url: string, dataSources, dataGetterKey) => {
    const matcher = new RegExp(`{${dataGetterKey}:(.*?)}`, 'g')

    return url.replace(matcher, (match, dataPath) => {
        const resolvedData = dataGetters[dataGetterKey](dataPath, dataSources)
        if (_.isUndefined(resolvedData)) {
            throw new Error(`Data getter '${dataGetterKey}' returned no value for data '${dataPath}'`)
        }

        return resolvedData
    })
}

const resolveUrlCore = (url: string, dataSources) =>
    _.reduce(
        dataGetters,
        (currUrl, dataGetter, dataGetterKey) => resolveByDataGetter(currUrl, dataSources, dataGetterKey),
        url
    )

//Example: {urlTemplate:http://{serviceTopology:someService}/bundler/{clientSpec:appFields.appStudio.version}.js}
const resolve = (url: string, dataSources) => {
    const [, template] = urlTemplateMatcher.exec(url) ?? []
    if (!_.isUndefined(template)) {
        return resolveUrlCore(template, dataSources)
    }

    return url
}

const validate = url => {
    if (!_.isString(url)) {
        return false
    }

    const [, template] = urlTemplateMatcher.exec(url) ?? []
    return !_.isUndefined(template) || _.startsWith(url, 'http')
}

export default {resolve, validate}
