import _ from 'lodash'
import {constants, dataUtils} from '@wix/santa-core-utils'

function shouldBeRendered(rootIdsWhichShouldBeRendered, rootId: string) {
    return _.includes(rootIdsWhichShouldBeRendered, rootId) || rootId === 'masterPage'
}

function getChildrenFromPageMap(structure, pageComponentsMap) {
    return _.map(structure.components, compId => pageComponentsMap[compId])
}

function convertNestedStructureToFlat(structure, viewMode = constants.VIEW_MODES.DESKTOP) {
    const convertChildrenToIds = (isMobile, comp, compId, map) => {
        const childrenKey = dataUtils.getChildrenKey(comp, isMobile)
        const children = comp[childrenKey]
        _.forEach(children, child => {
            map[child.id].parent = compId
        })
        if (children) {
            return _({components: _.map(children, 'id')})
                .defaults(comp)
                .omit(['children', 'mobileComponents'])
                .value()
        }
        return _.omit(comp, ['children', 'mobileComponents', 'components'])
    }
    const isMobile = viewMode === constants.VIEW_MODES.MOBILE
    return _.mapValues(dataUtils.getAllCompsInStructure(structure, isMobile), (...args) =>
        convertChildrenToIds(isMobile, ...args)
    )
}

export default {
    shouldBeRendered,
    getChildrenFromPageMap,
    convertNestedStructureToFlat
}
