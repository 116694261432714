import _ from 'lodash'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'

const ACTIVE_MODES_TYPE = 'activeModes'

pointerGeneratorsRegistry.registerPointerType(ACTIVE_MODES_TYPE, _.constant(null), _.constant(true))

const getterFunctions = {
    getAllActiveModes(getItemAt, cache) {
        return cache.getPointer(ACTIVE_MODES_TYPE, ACTIVE_MODES_TYPE, [ACTIVE_MODES_TYPE])
    },
    getPageActiveModes(getItemAt, cache, pageId) {
        return cache.getPointer(pageId, ACTIVE_MODES_TYPE, [ACTIVE_MODES_TYPE, pageId])
    }
}

pointerGeneratorsRegistry.registerDataAccessPointersGenerator(ACTIVE_MODES_TYPE, getterFunctions)
