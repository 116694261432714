import component from '../component/component'
import multiComponentsUtils from './multiComponentsUtils'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import page from '../page/page'
import type {Pointer, PS} from '@wix/document-services-types'

//TODO not tested
function alignInContainer(ps: PS, compPointer: Pointer, alignment) {
    if (!componentsMetaData.public.isAlignable(ps, compPointer)) {
        throw new Error('cannot align component')
    }

    const compContainer = component.getContainer(ps, compPointer)
    const containerLayout = component.isPageComponent(ps, compContainer)
        ? page.getLayout(ps, compContainer.id)
        : component.layout.get(ps, compContainer)

    multiComponentsUtils.alignComponentsToOuterLayout(ps, [compPointer], containerLayout, alignment, true)
}

export default {
    alignInContainer
}
