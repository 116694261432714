/**
 * Efficiently compares two objects that can be represented in JSON format.
 * Returns true if the objects are identical, else returns false
 * @param a
 * @param b
 * @returns {boolean}
 */
export function deepCompare(a: any, b: any): boolean {
    if (a === null || typeof a !== 'object') {
        return a === b
    }
    if (Array.isArray(a)) {
        if (!Array.isArray(b) || a.length !== b.length) {
            return false
        }
        for (let i = 0; i < a.length; ++i) {
            if (!deepCompare(a[i], b[i])) {
                return false
            }
        }
    } else {
        if (b === null || typeof b !== 'object' || Array.isArray(b)) {
            return false
        }
        let aKeyCount = 0
        // eslint-disable-next-line guard-for-in
        for (const key in a) {
            ++aKeyCount
            if (!b.hasOwnProperty(key) || !deepCompare(a[key], b[key])) {
                return false
            }
        }
        if (Object.keys(b).length !== aKeyCount) {
            return false
        }
    }
    return true
}

export function deepCompare4(a: any, b: any): boolean {
    if (a === null || typeof a !== 'object') {
        if (a === b) {
            return true
        }

        return false
    }
    if (Array.isArray(a)) {
        if (!Array.isArray(b)) {
            return false
        }
        if (a.length !== b.length) {
            return false
        }
        for (let i = 0; i < a.length; ++i) {
            if (!deepCompare(a[i], b[i])) {
                return false
            }
        }
    } else {
        if (b === null) {
            return false
        }
        if (typeof b !== 'object') {
            return false
        }
        if (Array.isArray(b)) {
            return false
        }
        let aKeyCount = 0
        // eslint-disable-next-line guard-for-in
        for (const key in a) {
            ++aKeyCount
            if (!b.hasOwnProperty(key)) {
                return false
            }
            if (!deepCompare(a[key], b[key])) {
                return false
            }
        }
        if (Object.keys(b).length !== aKeyCount) {
            return false
        }
    }
    return true
}

const isMeaningful = (val: string): boolean => {
    if (val === 'same' || val === 'top') {
        return false
    }

    return true
}

export function deepCompareDebug(a: any, b: any): string {
    if (a === null || typeof a !== 'object') {
        if (a === b) {
            return 'top'
        }

        return 'a is different than b'
    }
    if (Array.isArray(a)) {
        if (!Array.isArray(b)) {
            return 'b is not an array'
        }

        if (a.length !== b.length) {
            return `different array lengths ${a.length} / ${b.length}`
        }
        for (let i = 0; i < a.length; ++i) {
            const res = deepCompareDebug(a[i], b[i])
            if (isMeaningful(res)) {
                return res
            }
        }
    } else {
        if (b === null) {
            return 'b is null'
        }
        if (typeof b !== 'object') {
            return 'b is not an object'
        }
        if (Array.isArray(b)) {
            return 'b is an array'
        }
        let aKeyCount = 0
        // eslint-disable-next-line guard-for-in
        for (const key in a) {
            ++aKeyCount
            if (!b.hasOwnProperty(key)) {
                return `b does not have property ${key}`
            }
            const res = deepCompareDebug(a[key], b[key])
            if (isMeaningful(res)) {
                return res
            }
        }
        if (Object.keys(b).length !== aKeyCount) {
            return `b has ${Object.keys(b).length} keys while a has ${aKeyCount}`
        }
    }
    return 'same'
}

export function deepCompareDebug2(a: any, b: any): string {
    if (a === null || typeof a !== 'object') {
        if (a === b) {
            return 'top'
        }

        return 'a is different than b'
    }
    if (Array.isArray(a)) {
        if (!Array.isArray(b)) {
            return 'b is not an array'
        }

        if (a.length !== b.length) {
            return `different array lengths ${a.length} / ${b.length}`
        }
        for (let i = 0; i < a.length; ++i) {
            const res = deepCompareDebug2(a[i], b[i])
            if (isMeaningful(res)) {
                return res
            }
        }
    } else {
        if (b === null) {
            return 'b is null'
        }
        if (typeof b !== 'object') {
            return 'b is not an object'
        }
        if (Array.isArray(b)) {
            return 'b is an array'
        }
        let aKeyCount = 0
        // eslint-disable-next-line guard-for-in
        for (const key in a) {
            ++aKeyCount
            if (!b.hasOwnProperty(key)) {
                return 'b does not have property'
            }
            const res = deepCompareDebug2(a[key], b[key])
            if (isMeaningful(res)) {
                return res
            }
        }
        if (Object.keys(b).length !== aKeyCount) {
            return 'b has more keys'
        }
    }
    return 'same'
}
