import type {DocumentManager} from '@wix/document-manager-core'
import type {PageAPI} from '@wix/document-manager-extensions/src/extensions/page'

const migrateMasterPage = (documentManager: DocumentManager) => {
    const {dal, pointers, extensionAPI} = documentManager
    const pageAPI = extensionAPI.page as PageAPI
    const allPageIds = pageAPI.getAllPagesIds(false)
    allPageIds.forEach(pageId => {
        const pageData = dal.get(pointers.data.getDataItemFromMaster(pageId))
        if (pageData.tpaApplicationId) {
            const specMapEntry = dal.get(pointers.general.getClientSpecMapEntry(pageData.tpaApplicationId))
            if (specMapEntry?.appDefinitionId) {
                dal.set(pointers.data.getDataItemFromMaster(pageId), {
                    ...pageData,
                    appDefinitionId: specMapEntry.appDefinitionId
                })
            }
        }
    })
}

const name = 'addAppDefinitionIdToTpaPages'
const version = 1

export {migrateMasterPage, name, version}
