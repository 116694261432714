import _ from 'lodash'
import {linkUtils} from '@wix/santa-ds-libs/src/coreUtils'
import * as tpaComponents from '../tpaComponents'
import anchorHandlers from '../handlers/anchorHandlers'

const isRgbaColor = function (colorValue) {
    if (!_.startsWith(colorValue, 'rgba')) {
        return false
    }

    colorValue = _(colorValue).replace('rgba', '').replace('(', '').replace(')', '')

    const split = colorValue.split(',')
    if (split.length !== 4) {
        return false
    }

    const validRgb = _.every(split.slice(0, 3), function (number) {
        const isRgbValid = number >= 0 && number <= 255
        const percentage = parseFloat(number)
        const isPercent = !isNaN(percentage) && percentage > 0 && percentage <= 100

        return isRgbValid || isPercent
    })

    if (!validRgb) {
        return false
    }

    const alpha = _.last(split)

    if (alpha) {
        return alpha >= 0 && alpha <= 1
    }

    return true
}

const addAppSectionParamsToNavInfo = function (appSectionParams = {}, navInfo?) {
    if (!_.isEmpty(appSectionParams)) {
        try {
            navInfo.queryParams = {appSectionParams: JSON.stringify(appSectionParams)}
            navInfo.hasAppSectionParams = true
        } catch (ex) {
            // bummer
        }
    }
}

const getWidgetDataByTpaPageId = function (appData, sectionId) {
    const {widgets} = appData || {}
    return _.find(widgets, widget => _.get(widget, 'appPage.id') === sectionId)
}

const navigateToSection = function (
    reducedSiteAPI,
    appData,
    msg,
    appPages,
    primaryPageId,
    getSectionCompId,
    isDynamicPage,
    callback,
    onComplete
) {
    if (appData) {
        let pageId
        let tpaPageId
        let widgetData
        let pageBackgroundColorOverride
        let noTransition = false
        let state = msg.data || ''
        let shouldRefreshIframe = true
        let appSectionParams = {}
        let ignorePageUriSeo = false

        if (_.isObject(msg.data)) {
            shouldRefreshIframe = _.get(msg, 'data.sectionIdentifier.shouldRefreshIframe', true)
            state = _.get(msg, 'data.sectionIdentifier.state') || msg.data.state || ''
            appSectionParams = _.get(msg, 'data.sectionIdentifier.queryParams')
            tpaPageId = _.get(msg, 'data.sectionIdentifier.sectionId')
            noTransition = _.get(msg, 'data.sectionIdentifier.noTransition')
            pageBackgroundColorOverride = _.get(msg.data, ['sectionIdentifier', 'customizeTarget', 'pageBackground'])
            ignorePageUriSeo = _.get(msg.data, 'ignorePageUriSeo', false)
        }

        if (_.isUndefined(appPages) || _.isEmpty(appPages)) {
            reportNavigateToSectionBIError(reducedSiteAPI, appData.appDefinitionName, tpaPageId)
            callback({
                error: {
                    message: `Page with app "${appData.appDefinitionName}" was not found.`
                }
            })
        } else {
            if (_.isUndefined(tpaPageId)) {
                pageId = appPages[0].pageId
                widgetData = _.find(
                    _.get(appData, 'widgets'),
                    widget => _.get(widget, 'appPage.name') && !_.get(widget, 'appPage.hidden')
                )
            } else {
                const page = _.find(appPages, {tpaPageId})

                if (_.isUndefined(page)) {
                    reportNavigateToSectionBIError(reducedSiteAPI, appData.appDefinitionName, tpaPageId)
                    callback({
                        error: {
                            message: `App page with sectionId "${tpaPageId}" was not found.`
                        }
                    })
                    return
                }

                pageId = page.pageId
                widgetData = getWidgetDataByTpaPageId(appData, tpaPageId)
            }

            if (isDynamicPage(pageId)) {
                reportNavigateToSectionBIError(reducedSiteAPI, appData.appDefinitionName, tpaPageId)
                callback({
                    error: {
                        message: "Can't navigate to a dynamic page. Please use the platform app API instead."
                    }
                })
                return
            }

            const sectionCompId = getSectionCompId(pageId)
            setSectionStateAndQueryParams(reducedSiteAPI, shouldRefreshIframe, sectionCompId, state, appSectionParams)

            const navInfo: any = {
                pageId,
                tpaInnerRoute: state
            }

            addAppSectionParamsToNavInfo(appSectionParams, navInfo)

            if (noTransition) {
                navInfo.transition = 'none'
            }
            if (pageBackgroundColorOverride) {
                if (isRgbaColor(pageBackgroundColorOverride)) {
                    navInfo.pageBackgroundColorOverride = pageBackgroundColorOverride
                } else {
                    callback({
                        error: {
                            message: `Color should be rgba but "${pageBackgroundColorOverride}" is not rgba format.`
                        }
                    })
                }
            }

            let navigationComplete
            if (_.isFunction(onComplete)) {
                navigationComplete = _.partial(onComplete, _.get(widgetData, 'widgetId'), sectionCompId)
            }

            navigateWithoutClosingPopupIfPossible(
                reducedSiteAPI,
                navInfo,
                primaryPageId,
                undefined,
                undefined,
                true,
                navigationComplete,
                ignorePageUriSeo
            )
        }
    } else {
        const appDefinitionId = _.get(msg, 'data.sectionIdentifier.appDefinitionId')
        if (appDefinitionId) {
            reportNavigateToSectionBIError(reducedSiteAPI)
            callback({
                error: {
                    message: `Application with appDefinitionId "${appDefinitionId}" was not found on the site.`
                }
            })
        } else {
            reportNavigateToSectionBIError(reducedSiteAPI)
            callback({
                error: {
                    message: 'Component was not found.'
                }
            })
        }
    }
}

const setSectionStateAndQueryParams = function (siteAPI, shouldRefreshIframe, sectionCompId, state, queryParams) {
    if (sectionCompId) {
        if (shouldRefreshIframe) {
            siteAPI.updateSectionUrlState(sectionCompId, state)
            siteAPI.updateSectionUrlParams(sectionCompId, queryParams)
        } else {
            // no solution implemented for queryParams
            siteAPI.updatePushState(sectionCompId, state)
            siteAPI.reportStateChanged(sectionCompId, state)
        }
    }
}

const reportNavigateToSectionBIError = function (reducedSiteAPI, appDefinitionName?, tpaPageId?) {
    const params = {
        appDefinitionName: appDefinitionName || '',
        sectionId: tpaPageId || ''
    }
    reducedSiteAPI.reportBI(tpaComponents.bi.errors.SDK_NAVIGATION_TO_SECTION_ERROR, params)
}

const navigateWithoutClosingPopupIfPossible = function (
    reducedSiteAPI,
    navInfo,
    primaryPageId,
    shouldSkipHistory,
    shouldReplaceHistory,
    shouldScrollToTop,
    onComplete,
    ignorePageUriSeo?
) {
    if (navInfo.pageId === primaryPageId) {
        reducedSiteAPI.updatePageNavInfo(navInfo, shouldSkipHistory, shouldReplaceHistory, ignorePageUriSeo)
        if (shouldScrollToTop) {
            reducedSiteAPI.scrollSiteTo(0, 0)
        }
    } else {
        reducedSiteAPI.navigateToPage(navInfo, shouldSkipHistory, shouldReplaceHistory, ignorePageUriSeo)
    }

    if (_.isFunction(onComplete)) {
        onComplete()
    }
}

const navigateToPage = function (
    reducedSiteAPI,
    msg,
    primaryPageId,
    allPageIds,
    getPageAnchors,
    getAnchorDataId,
    callback
) {
    const pageId = _.get(msg, 'data.pageId')
    const noTransition = _.get(msg, 'data.noTransition')
    const anchorCompId = _.get(msg, 'data.anchorId')
    const pageAnchors = getPageAnchors(pageId)

    if (primaryPageId === pageId) {
        if (anchorCompId) {
            anchorHandlers.navigateToAnchor(reducedSiteAPI, msg, pageId, pageAnchors, getAnchorDataId, callback)
        }
    } else {
        linkUtils.navigateToPage(
            reducedSiteAPI,
            pageId,
            allPageIds,
            noTransition,
            undefined,
            function () {
                if (anchorCompId) {
                    anchorHandlers.navigateToAnchor(reducedSiteAPI, msg, pageId, pageAnchors, getAnchorDataId, callback)
                }
            },
            callback
        )
    }
}

export default {
    addAppSectionParamsToNavInfo,
    navigateToSection,
    navigateWithoutClosingPopupIfPossible,
    navigateToPage
}
