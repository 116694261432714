import {
    CreateExtArgs,
    DmApis,
    DocumentDataTypes,
    Extension,
    KeyValPredicate,
    PointerMethods,
    pointerUtils,
    ExtensionAPI
} from '@wix/document-manager-core'
import type {Pointer, RefArray, BreakpointRelation, VariantRelation} from '@wix/document-services-types'
import _ from 'lodash'
import * as constants from '../constants/constants'
import {refArray, breakpointRelation, variantRelation} from '../utils/refArrayUtils'
import type {PageAPI} from './page'
import {MASTER_PAGE_ID} from '../constants/constants'

const {getPointer, getInnerPointer} = pointerUtils
const DATA_TYPES = _.mapKeys(constants.DATA_TYPES)
const masterPageId = 'masterPage'

type Predicate = KeyValPredicate | Record<string, any>

export const createItemGetter = (itemType: string) => (itemId: string, pageId: string) => ({
    ...getPointer(itemId, itemType),
    pageId
})

const createPointersMethods = ({dal, pointers, extensionAPI}: DmApis): PointerMethods => {
    const getItem = (itemType: string, itemId: string, pageId: string) => ({...getPointer(itemId, itemType), pageId}) //adding pageId to pointer. hack for now
    const getDataItem = createItemGetter(DATA_TYPES.data)
    const getSlotsItem = createItemGetter(DATA_TYPES.slots)
    const getDesignItem = createItemGetter(DATA_TYPES.design)
    const getBehaviorsItem = createItemGetter(DATA_TYPES.behaviors)
    const getPropertyItem = createItemGetter(DATA_TYPES.props)
    const getThemeItem = createItemGetter(DATA_TYPES.style)
    const getConnectionsItem = createItemGetter(DATA_TYPES.connections)
    const getMobileHintsItem = createItemGetter(DATA_TYPES.mobileHints)
    const getAnchorsItem = createItemGetter(DATA_TYPES.anchors)
    const getDataByQueryPointer = (query: string, pageId: string, dataType: string) => {
        pageId = pageId || constants.MASTER_PAGE_ID
        dataType = dataType || DATA_TYPES.data

        return getItem(dataType, query, pageId)
    }
    const getBreakpointsDataItem = createItemGetter(DATA_TYPES.breakpoints)
    const getVariantsDataItem = createItemGetter(DATA_TYPES.variants)
    const getEffectsDataItem = createItemGetter(DATA_TYPES.effects)
    const getTransformationsDataItem = createItemGetter(DATA_TYPES.transformations)
    const getTransitionsDataItem = createItemGetter(DATA_TYPES.transitions)
    const getReactionsDataItem = createItemGetter(DATA_TYPES.reactions)

    const getKeyframeEffectDataItem = (id: string) => getItem(DATA_TYPES.effects, id, MASTER_PAGE_ID)

    const getAllStylesInPage = (pageId: string) => ({type: 'page', id: pageId, innerPath: ['data', 'theme_data']})

    const getPageIdOfData = (pointer: Pointer) =>
        dal.get(getPointer(pointer.id, pointer.type, {innerPath: ['metaData', 'pageId']}))

    const getTranslatedData = (pointer: Pointer, languageCode?: string): Pointer => {
        const pageId = getPageIdOfData(pointer) || pointer.pageId || constants.MASTER_PAGE_ID
        const originalLanguage = dal.get(getInnerPointer(pointers.multilingual.originalLanguage(), 'languageCode'))

        if (languageCode === originalLanguage || !languageCode) {
            return pointer
        }

        return pointers.multilingualTranslations.translationDataItem(pageId, languageCode, pointer.id)
    }

    const getItemsWithPredicate = (namespace: string, predicate: Predicate = {}, pageId: string = 'masterPage') => {
        const pred: KeyValPredicate = typeof predicate === 'object' ? _.matches(predicate) : predicate
        const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)
        const result = dal.query(namespace, pageCompFilter, pred)
        return _(result)
            .keys()
            .map(id => getItem(namespace, id, pageId))
            .value()
    }

    const findItemWithPredicate = (type: string, predicate: Predicate = {}, pageId: string = 'masterPage') => {
        predicate = typeof predicate === 'object' ? _.matches(predicate) : predicate
        const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)
        const item = dal.find(type, pageCompFilter, predicate as KeyValPredicate)
        return item && getItem(type, item.value.id, pageId)
    }

    const getDataItemsWithPredicate = (predicate: Predicate, pageId: string) =>
        getItemsWithPredicate(DATA_TYPES.data, predicate, pageId)
    const getLayoutItemsWithPredicate = (predicate: Predicate, pageId: string) =>
        getItemsWithPredicate(DATA_TYPES.layout, predicate, pageId)
    const getStyleItemsWithPredicate = (predicate: Predicate, pageId: string) =>
        getItemsWithPredicate(DATA_TYPES.style, predicate, pageId)
    const getDataItemWithPredicate = (predicate: Predicate, pageId: string) =>
        findItemWithPredicate(DATA_TYPES.data, predicate, pageId)
    const getVariantItemsWithPredicate = (predicate: Predicate, pageId: string) =>
        getItemsWithPredicate(DATA_TYPES.variants, predicate, pageId)
    const getReactionItemsWithPredicate = (predicate: Predicate, pageId: string) =>
        getItemsWithPredicate(DATA_TYPES.reactions, predicate, pageId)

    const getDesignItemsWithPredicate = (predicate: Predicate, pageId: string) =>
        getItemsWithPredicate(DATA_TYPES.design, predicate, pageId)

    const getDataItemFromMaster = (id: string) => getDataItem(id, masterPageId)

    const dataPointers = {
        getItem,
        getDataItem,
        getSlotsItem,
        getDesignItem,
        getBehaviorsItem,
        getPageIdOfData,
        getPropertyItem,
        getThemeItem,
        getMobileHintsItem,
        getConnectionsItem,
        getBreakpointsDataItem,
        getVariantsDataItem,
        getEffectsDataItem,
        getKeyframeEffectDataItem,
        getAnchorsItem,
        getTransformationsDataItem,
        getTransitionsDataItem,
        getReactionsDataItem,
        getItemsWithPredicate,
        getDataItemsWithPredicate,
        getLayoutItemsWithPredicate,
        getStyleItemsWithPredicate,
        getDataItemWithPredicate,
        getVariantItemsWithPredicate,
        getReactionItemsWithPredicate,
        getDesignItemsWithPredicate,
        getDataItemFromMaster,
        getDataByQueryPointer,
        getAllStylesInPage,
        getTranslatedData
    }

    const generalPointers = {
        getAllStylesInPage
    }

    return {
        data: dataPointers,
        general: generalPointers,
        full: {
            // @ts-expect-error
            data: dataPointers,
            // @ts-expect-error
            general: generalPointers
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => _.mapValues(DATA_TYPES, () => ({hasSignature: true}))

export interface DataExtensionAPI extends ExtensionAPI {
    data: {
        query(namespace: string, pageId?: string | null, pred?: (a: any) => boolean): any
        queryKeys(namespace: string, pageId?: string | null, pred?: (a: any) => boolean): string[]
        refArray: {
            extractValues(obj: RefArray): any[]
            extractValuesWithoutHash(obj: RefArray): any[]
            create(values: any[]): Partial<RefArray>
            isRefArray(obj: RefArray): boolean
            update(obj: RefArray, values: any): Partial<RefArray>
        }
        breakpointRelation: {
            extractBreakpoint(obj: BreakpointRelation): any
            extractRef(obj: BreakpointRelation): any
            extractRefWithoutHash(obj: BreakpointRelation): any
            create(breakpoint: string, ref: string): Partial<BreakpointRelation>
            isBreakpointRelation(obj: BreakpointRelation): any
        }
        variantRelation: {
            extractVariants(obj: VariantRelation): any
            extractTo(obj: VariantRelation): any
            extractFrom(obj: VariantRelation): any
            create(variants: string[], from: string, to: string): Partial<VariantRelation>
            isVariantRelation(obj: VariantRelation): boolean
        }
    }
}

const createExtensionAPI = ({dal, extensionAPI}: CreateExtArgs): DataExtensionAPI => ({
    data: {
        query: (namespace: string, pageId: string | null = null, pred?: (a: any) => boolean) => {
            const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)
            return dal.query(namespace, pageCompFilter, pred)
        },
        queryKeys: (namespace: string, pageId: string | null = null, pred?: (a: any) => boolean) => {
            const pageCompFilter = (extensionAPI.page as PageAPI).getPageIndexId(pageId)
            return dal.queryKeys(namespace, pageCompFilter, pred)
        },
        refArray,
        breakpointRelation,
        variantRelation
    }
})

const createExtension = (): Extension => ({
    name: 'data',
    createExtensionAPI,
    createPointersMethods,
    getDocumentDataTypes
})

export {createExtension}
