import _ from 'lodash'
import metaDataUtils from '../metaDataUtils'

export default {
    isRepeatable: false,
    styleCanBeApplied: false,
    layoutLimits(ps, compPointer) {
        const measureMap = ps.siteAPI.getSiteMeasureMap()
        const customMeasure = _.get(measureMap, ['custom', compPointer.id], {})

        return {
            minHeight: customMeasure.minHeight,
            minWidth: customMeasure.minWidth
        }
    },
    containableByStructure: metaDataUtils.notContainableByPopup,
    mobileConversionConfig: {
        desktopOnly: true
    }
}
