import type {PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import _ from 'lodash'
import mobileUtilities from '../mobileUtilities/mobileUtilities'
import {shouldEnableImprovedMergeFlow} from './modules/utils'

const {isMobileSupported} = mobileUtilities

const validateIfNeeded = (ps: PS, name: string, isAfter) => {
    if (!experiment.isOpen('dm_mobileAlgoValidationNoConduct') || !isMobileSupported(ps)) {
        return
    }

    const tags: any = {mobileFunction: name, afterMobileFunction: isAfter}
    tags.mobileAlgoImprovements = shouldEnableImprovedMergeFlow(ps)

    ps.extensionAPI.logger.interactionStarted('mobileAlgoValidation', {tags})
    ps.extensionAPI.validation.validateOpenTransactionAndReportOnly(tags)
}

const wrap = (facade, exclusions) =>
    _.mapValues(facade, (facadeMethod, name) => {
        if (exclusions.includes(name)) {
            return facadeMethod
        }

        return _.wrap(facadeMethod, (func, ...args) => {
            const ps = args[0] as PS

            validateIfNeeded(ps, name, false)
            const value = func(...args)
            validateIfNeeded(ps, name, true)

            return value
        })
    })

export default {
    wrap
}
