import type {Pointer, PS} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import {dataUtils, displayedOnlyStructureUtil, mobileUtils} from '@wix/santa-core-utils'
import experiment from 'experiment-amd'
import _ from 'lodash'
import component from '../component/component'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import hooks from '../hooks/hooks'
import pageProperties from '../page/pageProperties'
import refComponent from '../refComponent/refComponent'
import refComponentUtils from '../refComponent/refComponentUtils'
import mobileConversion from './mobileConversionFacade'
import backToTopButtonDefinitions from './mobileEditorSettings/backToTopButtonDefinitions'
import conversionSettings from './modules/conversionSettings'
import displayModeUtils from './modules/displayModeUtils'
import hideMobileComponent from './modules/hideMobileComponent'
import menuContainer from './modules/menuContainer/menuContainer'
import mergeAggregator from './modules/mergeAggregator'
import mobileHints from './modules/mobileHints'
import mobileOnlyComponents from './modules/mobileOnlyComponents'
import userModifiedComponents from './modules/userModifiedComponentHandler'

const getDesktopPointer = (ps: PS, compId: string, pageId: string) =>
    ps.pointers.full.components.getComponent(
        compId,
        ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    )
const getMobilePointer = (ps: PS, compId: string, pageId: string) =>
    ps.pointers.components.getComponent(compId, ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE))
const getMetaData = (ps: PS, comp, pageId, metaDataPath) =>
    _.get(componentsMetaData.public.getMobileConversionConfig(ps, ps.dal.full.get(comp), pageId), metaDataPath)

const {isMobileOnlyComponent, isNativeMobileOnlyComponent} = mobileOnlyComponents

function resetPageMinHeight(ps: PS, pageId: string) {
    const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
    if (pagePointer.id !== 'masterPage') {
        pageProperties.updatePageProperties(ps, pagePointer, {minHeight: null})
    }
}

function runHoverBoxHooksOnPage(ps: PS, pagePointer: Pointer) {
    const type = ps.dal.get(ps.pointers.getInnerPointer(pagePointer, 'componentType'))
    hooks.executeHook(hooks.HOOKS.MOBILE_CONVERSION.BEFORE, type, [ps, pagePointer])
}

function handleMobileSlotsRefs(ps: PS, ownerOfComponentInSlot, componentWithSlots, compInSlotPointer: Pointer) {
    if (experiment.isOpen('dm_useSlotsExt')) {
        ps.extensionAPI.mobileConversion.handleMobileSlotsRefs(
            ownerOfComponentInSlot,
            componentWithSlots,
            compInSlotPointer
        )
    } else {
        const mobileParentPointer = ps.pointers.getPointer(ownerOfComponentInSlot.id, constants.VIEW_MODES.MOBILE)
        const mobileParentFromDal = ps.dal.get(mobileParentPointer)
        const compWithSlotsFromDal = ps.dal.get(componentWithSlots)

        if (
            mobileParentFromDal &&
            compWithSlotsFromDal.slotsQuery &&
            !mobileParentFromDal.components.includes(compInSlotPointer.id)
        ) {
            const mobileCompInSlotPointer = ps.pointers.getPointer(compInSlotPointer.id, constants.VIEW_MODES.MOBILE)

            if (!ps.dal.isExist(mobileCompInSlotPointer)) {
                ps.dal.set(mobileCompInSlotPointer, ps.dal.get(compInSlotPointer))
            }

            ps.dal.merge(mobileParentPointer, {
                components: _.uniq([compInSlotPointer.id, ...mobileParentFromDal.components])
            })

            const mobileCompWithSlots = ps.pointers.getPointer(componentWithSlots.id, constants.VIEW_MODES.MOBILE)
            ps.dal.merge(mobileCompWithSlots, {
                slotsQuery: compWithSlotsFromDal.slotsQuery
            })
        }
    }
}

function moveBetweenMobileParents(ps: PS, newParent, compToMove, oldParent) {
    const mobileCompToMove = ps.pointers.getPointer(compToMove.id, constants.VIEW_MODES.MOBILE)
    const newMobileParentPointer = ps.pointers.getPointer(newParent.id, constants.VIEW_MODES.MOBILE)
    const oldMobileParentPointer = ps.pointers.getPointer(oldParent.id, constants.VIEW_MODES.MOBILE)
    if (ps.dal.isExist(oldMobileParentPointer)) {
        const oldParentComps = ps.dal.get(oldMobileParentPointer).components

        if (oldParentComps.length > 0) {
            oldParentComps.splice(oldParentComps.indexOf(mobileCompToMove.id), 1)
            ps.dal.set({...oldMobileParentPointer, innerPath: ['components']}, oldParentComps)
        }
    }

    if (ps.dal.isExist(newMobileParentPointer)) {
        const newParentComps = ps.dal.get(newMobileParentPointer).components
        ps.dal.set({...newMobileParentPointer, innerPath: ['components']}, [...newParentComps, mobileCompToMove.id])
    }
}

function runHoverBoxHooksIfNeeded(ps: PS) {
    const changedPagesPointers = mergeAggregator.getChangedPagesPointers(ps)
    const renderedPagesPointers = _.map(ps.siteAPI.getAllRenderedRootIds(), rootId =>
        ps.pointers.page.getPagePointer(rootId)
    )
    _.forEach(_.uniqBy(changedPagesPointers.concat(renderedPagesPointers), 'id'), pagePointer =>
        runHoverBoxHooksOnPage(ps, pagePointer)
    )
}

function convertMobileStructure(ps: PS) {
    runHoverBoxHooksIfNeeded(ps)
    mobileConversion.runPartialConversionAllPages(ps)
}

/***********************PUBLIC FUNCTIONS *************************************/
function initialize(ps: PS) {
    hooks.registerHook(hooks.HOOKS.SWITCH_VIEW_MODE.MOBILE, convertMobileStructure)
    hooks.registerHook(hooks.HOOKS.SLOTS.AFTER_POPULATE, handleMobileSlotsRefs)
    hooks.registerHook(hooks.HOOKS.SLOTS.AFTER_MOVE, moveBetweenMobileParents)
    mobileHints.initialize(ps)
}

function reLayoutPage(ps: PS, pageId: string) {
    pageId = pageId || ps.siteAPI.getCurrentUrlPageId()
    runHoverBoxHooksOnPage(ps, ps.pointers.page.getPagePointer(pageId))
    resetPageMinHeight(ps, pageId)
    const page = mergeAggregator.getPage(ps, pageId)
    mobileConversion.runMobileConversionOnPage(ps, page.desktop)
    hooks.executeHook(hooks.HOOKS.RELAYOUT_MOBILE_PAGE.AFTER, null, [ps, pageId])
}

function handleReAddMobileOnlyComponent(ps: PS, compId: string) {
    switch (compId) {
        case constants.MOBILE_ONLY_COMPONENTS.TINY_MENU:
            mobileConversion.addMobileOnlyComponent(ps, constants.MOBILE_ONLY_COMPONENTS.TINY_MENU, {
                commitConversionResults: false
            })
            break
        case constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE:
            menuContainer.toggle.add(ps)
            break
        case constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU:
            menuContainer.expandableMenu.add(ps)
            break
    }
}

function reAddDeletedMobileComponent(ps: PS, compToReAddRef, compId: string, pageId: string) {
    if (!compId || !pageId) {
        throw new Error('function must receive component id and page id')
    }
    const mobilePtr = getMobilePointer(ps, compId, pageId)
    if (isMobileOnlyComponent(ps, compId)) {
        handleReAddMobileOnlyComponent(ps, compId)
        return
    }
    const desktopCompPointer = getDesktopPointer(ps, compId, pageId)
    mobileHints.updateProperty(ps, {hidden: false}, desktopCompPointer, {
        updateChildren: !displayedOnlyStructureUtil.isRefPointer(desktopCompPointer),
        childrenSourceView: constants.VIEW_MODES.DESKTOP
    })
    if (mobilePtr && displayedOnlyStructureUtil.isRefPointer(mobilePtr)) {
        return refComponent.unGhostifyComponent(ps, mobilePtr)
    }
    const {desktop, mobile} = mergeAggregator.getPage(ps, pageId)
    const settings = conversionSettings.getConversionSettings(ps, {conversionType: 'MERGE_UNHIDE'})
    mobileConversion.runMobileMergeOnPage(ps, desktop, mobile, settings)
}

function isLegacyBackToTopButton(ps: PS, backToTopPointer: Pointer) {
    const backToTopButtonType = ps.dal.get(ps.pointers.getInnerPointer(backToTopPointer, 'componentType'))
    return backToTopButtonType === constants.MOBILE_ONLY_COMPONENT_TYPES.BACK_TO_TOP_BUTTON_LEGACY
}

function addBackToTopButton(ps: PS, isLegacyMigration, dockedOverrides) {
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const compToAddRef = component.getComponentToAddRef(
        ps,
        masterPagePointer,
        null,
        constants.MOBILE_ONLY_COMPONENTS.BACK_TO_TOP_BUTTON
    )
    const backToTopButtonDefaultDefinition = backToTopButtonDefinitions.getBackToTopButtonDefinition(
        isLegacyMigration,
        dockedOverrides
    )
    component.add(ps, compToAddRef, masterPagePointer, backToTopButtonDefaultDefinition as any)
}

function toggleBackToTopButton(ps: PS, isToggleOn: boolean, dockedOverrides?) {
    const backToTopPointer = getMobilePointer(
        ps,
        constants.MOBILE_ONLY_COMPONENTS.BACK_TO_TOP_BUTTON,
        constants.MASTER_PAGE_ID
    )
    const isExist = ps.dal.isExist(backToTopPointer)

    if (isExist && isToggleOn && isLegacyBackToTopButton(ps, backToTopPointer)) {
        component.deleteComponent(ps, backToTopPointer)
        addBackToTopButton(ps, true, dockedOverrides)
    } else if (!isExist && isToggleOn) {
        addBackToTopButton(ps, false, dockedOverrides)
    } else if (isExist && !isToggleOn) {
        component.deleteComponent(ps, backToTopPointer)
    }
}

function setBackToTopButton(ps: PS, enable: boolean) {
    if (typeof enable !== 'boolean') {
        throw new Error('invalid parameter type')
    }
    const pageId = constants.MASTER_PAGE_ID
    const compPointer = getMobilePointer(ps, constants.MOBILE_ONLY_COMPONENTS.BACK_TO_TOP_BUTTON, pageId)
    const isEnabled = compPointer && ps.dal.isExist(compPointer)
    if (enable && !isEnabled) {
        mobileConversion.addMobileOnlyComponent(ps, constants.MOBILE_ONLY_COMPONENTS.BACK_TO_TOP_BUTTON)
    }
    if (!enable && isEnabled) {
        hideMobileComponent.hideMobileComponent(ps, compPointer, pageId, {updateLayout: false})
    }
}

function omitChildrenOfHiddenComponentsIfNeeded(ps: PS, deletedCompList, pageId: string) {
    const componentsToFilter = _.flatMap(deletedCompList, function (deletedCompId) {
        if (isMobileOnlyComponent(ps, deletedCompId)) {
            return []
        }
        const desktopPage = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        const deletedCompPointer = ps.pointers.full.components.getComponent(deletedCompId, desktopPage)
        const filterChildrenWhenHidden = getMetaData(ps, deletedCompPointer, pageId, 'filterChildrenWhenHidden')
        return filterChildrenWhenHidden
            ? _.map(ps.pointers.full.components.getChildrenRecursively(deletedCompPointer), 'id')
            : []
    })
    return _.difference(deletedCompList, componentsToFilter)
}

function getFilteredMobileDeletedComponentsMap(ps: PS, deletedCompsMap) {
    return _.reduce(
        deletedCompsMap,
        (res, deletedCompList, id) => _.set(res, id, omitChildrenOfHiddenComponentsIfNeeded(ps, deletedCompList, id)),
        {}
    )
}

function getFilteredMobileDeletedComponents(ps: PS, pageId?: string) {
    const deletedComps = getMobileDeletedCompMap(ps, pageId)
    const deletedCompsMap = pageId ? _.set({}, pageId, deletedComps) : deletedComps
    const filteredDeletedCompsMap = getFilteredMobileDeletedComponentsMap(ps, deletedCompsMap)
    return pageId ? filteredDeletedCompsMap[pageId] : filteredDeletedCompsMap
}

function addMobileOnlyComponentsToDeletedCompsListIfNeeded(ps: PS, deletedComps) {
    const shouldAddTinyMenu =
        !existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER) &&
        !existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.TINY_MENU)
    if (shouldAddTinyMenu) {
        deletedComps.push(constants.MOBILE_ONLY_COMPONENTS.TINY_MENU)
    }

    const shouldAddMenuToggle =
        existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER) &&
        !existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE)
    if (shouldAddMenuToggle) {
        deletedComps.push(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_TOGGLE)
    }

    const shouldAddExpandableMenu =
        existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER) &&
        !existsOnMasterPage(ps, constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU)
    if (shouldAddExpandableMenu) {
        deletedComps.push(constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER_EXPANDABLE_MENU)
    }
}

const getCompPointersFromHiddenCompsMap = (ps: PS, hiddenCompsMap, pageId: string) =>
    _(hiddenCompsMap)
        .keys()
        .map(compId => componentDetectorAPI.getComponentById(ps, compId, pageId, constants.VIEW_MODES.DESKTOP))
        .value()

const getGhostCompsOfPage = (ps: PS, pagePointer: Pointer) => {
    const pageCompsFromDisplayed = componentDetectorAPI.getComponentsUnderAncestor(ps, pagePointer)
    return _(pageCompsFromDisplayed)
        .filter(compPointer => refComponentUtils.isRefHost(ps, compPointer))
        .flatMap(compPointer => ps.pointers.referredStructure.getGhostRefComponents(compPointer.id))
        .compact()
        .flatMap(hiddenCompsMap => getCompPointersFromHiddenCompsMap(ps, hiddenCompsMap, pagePointer.id))
        .value()
}

const getCompId = hiddenComp => {
    if (displayedOnlyStructureUtil.isRefPointer(hiddenComp)) {
        return hiddenComp.id
    }
    return _.get(hiddenComp.mobileHints, 'displayedCompId', hiddenComp.id)
}

// TODO: will fail if active modes on mobile are not as on desktop
function getMobileDeletedCompsList(ps: PS, pageId: string) {
    const desktopPagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    if (!desktopPagePointer) {
        return null
    }
    const pageChildren = ps.pointers.full.components.getChildrenRecursively(desktopPagePointer)

    const deletedComps = _(pageChildren)
        .concat(getGhostCompsOfPage(ps, desktopPagePointer))
        .map(compPointer => ({id: compPointer.id, mobileHints: dataModel.getMobileHintsItem(ps, compPointer)}))
        .filter(({mobileHints: hints}) => hints?.hidden)
        .map(hiddenComp => getCompId(hiddenComp))
        .value()

    if (pageId !== constants.MASTER_PAGE_ID) {
        return deletedComps
    }

    addMobileOnlyComponentsToDeletedCompsListIfNeeded(ps, deletedComps)

    return _.compact(deletedComps)
}

function setHiddenComponentsList(ps: PS, pageId: string, hiddenCompsList) {
    const pageStructure = ps.dal.full.get(ps.pointers.page.getPagePointer(pageId)).structure
    const allCompsIds = _(dataUtils.getAllCompsInStructure(pageStructure, undefined))
        .reject(mobileCore.conversionUtils.isPageComponent)
        .map('id')
        .value()
    _.forEach(allCompsIds, compId => {
        const hidden = _.includes(hiddenCompsList, compId)
        const compPointer = getDesktopPointer(ps, compId, pageId)
        mobileHints.updateProperty(ps, {hidden}, compPointer)
    })
}

function existsOnMasterPage(ps: PS, compId: string) {
    const compPointer = getMobilePointer(ps, compId, constants.MASTER_PAGE_ID)
    return compPointer && ps.dal.isExist(compPointer)
}

function implicitFontSize(ps: PS, desktopFontSize) {
    return mobileUtils.convertFontSizeToMobile(desktopFontSize, 1)
}

function getMobileDeletedCompMap(ps: PS, pageId?: string) {
    const pageIds = pageId ? [pageId] : ps.siteAPI.getAllPagesIds(true)
    const deletedCompsMap = _.reduce(pageIds, (res, id) => _.set(res, id, getMobileDeletedCompsList(ps, id)), {})
    return pageId ? deletedCompsMap[pageId] : deletedCompsMap
}

function hide(ps: PS, compPointer: Pointer, settings = {updateLayout: true}) {
    compPointer = ps.pointers.components.getMobilePointer(compPointer)
    if (componentsMetaData.shouldBeRemovedByParent(ps, compPointer)) {
        compPointer = ps.pointers.components.getParent(compPointer)
    }
    if (!ps.dal.isExist(compPointer)) {
        return
    }
    const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
    resetPageMinHeight(ps, pageId)
    const desktopCompPointer = getDesktopPointer(ps, compPointer.id, pageId)
    const isDisplayedOnlyComp = displayedOnlyStructureUtil.isDisplayedOnlyComponent(compPointer.id)
    const property = {hidden: true}

    if (isDisplayedOnlyComp) {
        _.assign(property, {displayedCompId: compPointer.id})
    }

    if (ps.dal.full.isExist(desktopCompPointer)) {
        mobileHints.updateProperty(
            ps,
            property,
            desktopCompPointer,
            {
                updateChildren: true,
                childrenSourceView: constants.VIEW_MODES.MOBILE
            },
            pageId
        )
    }

    hideMobileComponent.hideMobileComponent(ps, compPointer, pageId, settings)
}

function markMobileComponentChangedByUser(
    ps: PS,
    pageId: string,
    compPointer: Pointer,
    shouldMarkParent: boolean = false
) {
    userModifiedComponents.markComponentAsTouched(ps, pageId, compPointer, shouldMarkParent)
}

const markComponentAsDirtyForForceReRender = (ps: PS, desktopCompPointer: Pointer) => {
    mobileHints.markComponentAsDirtyForForceReRender(ps, desktopCompPointer)
}

const clearMobileReferences = (ps: PS) => {
    const allMobileComp = ps.siteAPI.getAllMobileComponents()
    _.forEach(allMobileComp, compVal => {
        if (compVal?.id) {
            const compId = compVal.id
            const pageId = _.get(compVal, ['metaData', 'pageId'])
            const compRef = ps.pointers.components.getComponent(
                compId,
                ps.pointers.components.getPage(pageId, 'MOBILE')
            )
            if (component.isComponentRemovable(ps, compRef)) {
                component.deleteComponent(ps, compRef)
            }
        }
    })
}

export default {
    initialize,
    /**
     * clears all changes preformed by user on mobile structure and runs the mobile algorithm again for the given page. If a page is not given
     * the algorithm we run on the current page.
     * @member documentServices.mobile
     * @param {string} [pageId] pageId to re-layout - runs mobile conversion on the page - if null re-layout current page.
     */
    reLayoutPage,
    /**
     * show or hide back to top button based on enable parameter
     * @member documentServices.mobile
     * @param {boolean} enable - enable or disable to add to top button
     *
     */
    enableBackToTopButton: setBackToTopButton,
    toggleBackToTopButton,
    isLegacyBackToTopButton,
    /**
     * updates display mode of component in the mobile view and page layout when needed
     * @member documentServices.mobile
     * @param compPointer pointer of component in mobile
     * @param mobileDisplayedModeId display mode of component
     *
     */
    setComponentDisplayMode: displayModeUtils.setComponentDisplayMode,
    /**
     * Return the default position of tinyMenu, to be used when
     * setting tinyMenu as fixedPosition while tinyMenu is outside the 'green zone'
     *
     * @returns {{x: number, y: number}}
     */
    getTinyMenuDefaultPosition: mobileCore.conversionUtils.getTinyMenuDefaultPosition,
    /**
     * @class documentServices.mobile.hiddenComponents
     */
    hiddenComponents: {
        /**
         * returns all components deleted for mobile structure - if page id is passed an array with comps for the page will be returned
         * @param {string} [pageId] -  deleted comps of the page id. if not passed return deleted comps for all pages.
         */
        get: getMobileDeletedCompMap,
        /**
         * returns a subset of components deleted for mobile structure - if page id is passed an array with comps for the page will be returned
         * the filter will return only components that their parent on desktop is not presented on the list. That way a child can be hidden and appear in the list
         * only if its parent is not also hidden (if the parent is hidden, only the parent will appear in the list)
         * @param {string} [pageId] -  deleted comps of the page id. if not passed return deleted comps for all pages.
         */
        getFiltered: getFilteredMobileDeletedComponents,
        /**
         * hides the component from mobile structure
         * @param {object} compPointer - pathAbstraction of the comp we want to hide
         * @param {string} compPointer.id - id of component
         * @param {string} compPointer.type - DESKTOP/MOBILE
         *
         */
        hide,
        /**
         * allows to re add component to mobile structure. You can re add only components that were hidden before.
         * @param {string} compId to show
         * @param {string} pageId the component was deleted from
         */
        show: reAddDeletedMobileComponent,
        set: setHiddenComponentsList
    },
    /**
     * Checks if a component is only mobile component
     *
     * @param {ps} ps - private document services
     * @param {string} compId
     *
     * @returns {boolean}
     */
    isMobileOnlyComponent,
    /**
     * Checks if a component is a legacy mobile only component (Legacy components - Like Back to Top, QAB, Tiny Menu, etc..)
     *
     * @param {ps} ps - private document services
     * @param {string} compId The component to check
     *
     * @returns {boolean}
     */
    isNativeMobileOnlyComponent,
    /**
     * Checks if a component that is only mobile exist on mobile structure
     *
     * @param {ps} ps - private document services
     * @param {string} compId
     *
     * @returns {boolean}
     */
    isMobileOnlyComponentExistOnStructure: existsOnMasterPage,
    /**
     * return object with mobile Only components and their ids
     *
     * @returns {object}
     */
    mobileOnlyComps: constants.MOBILE_ONLY_COMPONENTS,
    /**
     * return compRef for mobile component to be shown (re-added)
     *
     * @returns {object}
     */
    implicitFontSize,
    getMobileComponentToShow: getMobilePointer,
    markMobileComponentChangedByUser,
    markComponentAsDirtyForForceReRender,
    clearMobileReferences
}
