import _ from 'lodash'

function buildDeepStructure(comp, map) {
    const children = comp.children || comp.components
    if (!_.isEmpty(children)) {
        const components = _.map(children, child => buildDeepStructure(map[child], map))
        return (
            _(comp)
                .omit(['components', 'parent'])
                // @ts-ignore
                .defaults({[comp.id === 'masterPage' ? 'children' : 'components']: components})
                .value()
        )
    }

    return _.omit(_.clone(comp), 'parent')
}

export default {
    buildDeepStructure
}
