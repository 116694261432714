import type {PublicMethodUtils} from '@wix/document-services-types'
import {responsiveLayout} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                responsiveLayout: {
                    update: publicMethodUtils.defineDataManipulationAction(responsiveLayout.update),
                    get: publicMethodUtils.defineGetter(responsiveLayout.get)
                }
            }
        }
    }
}
