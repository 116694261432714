import _ from 'lodash'
import dataModelBuilder from './dataModelBuilder'
import stylableUtils from '../../../../stylableEditor/stylableUtils'
import type {OldSchemaService} from '@wix/document-services-types'

export default (schemasService: OldSchemaService) => {
    const dataModel = dataModelBuilder(schemasService)
    const DEFAULT_COMP_LAYOUT = {
        width: 100,
        height: 100,
        x: 0,
        y: 0
    }

    /**
     * does not create children
     */
    function buildDefaultComponentStructure(componentType: string) {
        const compDefinition = schemasService.getDefinition(componentType)
        if (!_.isString(componentType)) {
            throw new Error('Must pass componentType as string')
        }

        if (!compDefinition) {
            throw new Error(`Component type "${componentType}" is not supported`)
        }

        const styleId = _.head(_.keys(compDefinition.styles))
        let style
        if (!styleId) {
            const skin = _.head(compDefinition.skins || [])
            if (skin) {
                style = dataModel.createStyleItemByType('TopLevelStyle')
                style.skin = skin
            }
            if (compDefinition.isStylableComp) {
                style = {
                    ...dataModel.createStyleItemByType('ComponentStyle'),
                    ...stylableUtils.createEmptyStylableStyleItem(componentType)
                }
            }
        }

        const defaultDataItemType = _.includes(compDefinition.dataTypes, '')
            ? ''
            : _.head(compDefinition.dataTypes as string[])
        const defaultDataItem = dataModel.createDataItemByType(defaultDataItemType)

        const defaultDesignItemType = _.includes(compDefinition.designDataTypes, '')
            ? ''
            : _.head(compDefinition.designDataTypes as string[])
        const defaultDesignItem = dataModel.createDesignItemByType(defaultDesignItemType)

        const defaultPropertiesItemType =
            compDefinition.propertyType ||
            (_.includes(compDefinition.propertyTypes, '') ? '' : _.head(compDefinition.propertyTypes))
        let defaultPropertiesItem
        if (defaultPropertiesItemType) {
            defaultPropertiesItem = dataModel.createPropertiesItemByType(defaultPropertiesItemType)
        }

        const result = {
            layout: _.clone(DEFAULT_COMP_LAYOUT),
            componentType,
            data: defaultDataItem,
            props: defaultPropertiesItem,
            design: defaultDesignItem,
            style: styleId ?? style
        }

        if (schemasService.isContainer(componentType)) {
            _.assign(result, {components: []})
        }

        if (compDefinition.requiredChildType) {
            _.assign(result, {components: [buildDefaultComponentStructure(compDefinition.requiredChildType)]})
        }

        return result
    }

    return {
        buildDefaultComponentStructure
    }
}
