export default {
    createRegistrar: (
        {skins: skinsByComponentType, responsiveSkins: responsiveSkinsByComponentType},
        componentTypeAliases
    ) => {
        function getComponentSkins(componentType: string) {
            const compType = componentTypeAliases.getAlias(componentType)
            if (skinsByComponentType[compType]) {
                return skinsByComponentType[compType].slice() //return a copy for public
            }
            return []
        }

        function registerSkinsByCompType(compType: string, {skins, responsiveSkins}: {skins; responsiveSkins}) {
            skinsByComponentType[compType] = skins
            if (responsiveSkins) {
                responsiveSkinsByComponentType[compType] = responsiveSkins
            }
        }

        function getComponentResponsiveSkins(componentType: string) {
            const compType = componentTypeAliases.getAlias(componentType)
            return responsiveSkinsByComponentType[compType] || getComponentSkins(compType)
        }

        return {
            getComponentResponsiveSkins,
            getComponentSkins,
            registerSkinsByCompType
        }
    }
}
