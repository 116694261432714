import * as _ from 'lodash'
import * as conversionUtils from '../conversionUtils'
import {conversionConfig} from '../conversionConfig'
import {ConversionSettings, DeepStructure, Layout} from '../../types'

export const getBlockIdsFromConversionData = (component): string[] => _.get(component, ['conversionData', 'blockIds'], [])

export const getBlocksLayoutFromConversionData = (component): Layout[] => _.get(component, ['conversionData', 'blockLayout'], [])

export const setBlockLayoutData = (layout: Layout, key, value): void => {
    _.set(layout, [key], value)
}

export const getBlockComponentType = (component: DeepStructure, indexOfblock: number) => {
    const blockComponentsType = _.get(component, ['conversionData', 'blockComponentTypes'], [])
    return blockComponentsType[indexOfblock][0]
}

export const getBlocksLayoutData = (layout: Layout, key: keyof Layout): number | undefined => layout && layout[key]

export const removeBlocksFromConversionData = (component, i) => {
    component.conversionData.blockIds.splice(i, 1)
    component.conversionData.blockLayout.splice(i, 1)
    component.conversionData.blockComponentTypes.splice(i, 1)
}

export const insertBlockBetweenExisting = (container: DeepStructure, indextToIsert: number, componentToAdd: DeepStructure | undefined) => {
    if (!componentToAdd) return

    const {id, layout, componentType} = componentToAdd

    container.conversionData.blockIds.splice(indextToIsert, 0, [id])
    container.conversionData.blockLayout.splice(indextToIsert, 0, layout)
    container.conversionData.blockComponentTypes.splice(indextToIsert, 0, [componentType])
}

export const setBlocksToConversionData = (
    component: DeepStructure,
    blockIds: string[][] = [],
    blockLayout: Layout[] = [],
    blockComponentTypes: string[][] = []
) => {
    component.conversionData.blockIds = blockIds
    component.conversionData.blockLayout = blockLayout
    component.conversionData.blockComponentTypes = blockComponentTypes
}

export const addMissedBlocksDataIfNeeded = container => {
    _.defaults(container.conversionData, {
        blockIds: [],
        blockLayout: [],
        blockComponentTypes: []
    })
}

export const findBlockNumberOfComponent = (componentId: string, container: DeepStructure): number => {
    const blockIds: string[] = getBlockIdsFromConversionData(container)
    return _.findIndex(blockIds, block => _.includes(block, componentId))
}

export const shouldBeTightAccordingToParent = (component: DeepStructure) => _.get(component, ['conversionData', 'hasTightMarginBetweenChildren'], false)

export const shouldBeTightWithPreviousSibling = (component: DeepStructure) => _.get(component, ['conversionData', 'tightWithPreviousSibling'], false)

export const getYGapsBetweenBlocks = (comp: DeepStructure, enableImprovedMergeFlow = false) => {
    const shouldIgnoreGapsForContainers = enableImprovedMergeFlow && conversionUtils.isContainerComponent(comp)
    if (
        shouldIgnoreGapsForContainers ||
        conversionUtils.isClassicSectionComponent(comp) ||
        shouldBeTightAccordingToParent(comp) ||
        shouldBeTightWithPreviousSibling(comp)
    ) {
        return 0
    }

    return conversionConfig.COMPONENT_MOBILE_MARGIN_Y
}

export const calculateYForComponents = (component: DeepStructure, blockLayout: Layout[], blockIndexOfComponent: number) => {
    const margin = getYGapsBetweenBlocks(component)
    return blockLayout[blockIndexOfComponent].y + blockLayout[blockIndexOfComponent].height + margin
}

export const calculateYFromBlockLayout = (
    blockNumberOfPreviousComponent: number,
    container: DeepStructure,
    componentToAdd: DeepStructure,
    settings: ConversionSettings
): number => {
    const blockLayout = getBlocksLayoutFromConversionData(container)
    /** It means component inserted as first in container */
    if (blockNumberOfPreviousComponent < 0) {
        let shouldUseComponentMargins
        let shouldAddRootContainerMargins

        if (settings.enableImprovedMergeFlow) {
            shouldUseComponentMargins = !conversionUtils.shouldStretchToScreenWidth(componentToAdd) && !conversionUtils.shouldStretchToScreenWidth(container)
            shouldAddRootContainerMargins = !conversionUtils.hasTightTopMargin(container) && container.layout.y >= 0
        } else {
            shouldUseComponentMargins = !conversionUtils.shouldStretchToScreenWidth(componentToAdd)
            shouldAddRootContainerMargins = container.layout.y > 0
        }

        if (shouldUseComponentMargins) {
            return getYGapsBetweenBlocks(componentToAdd)
        }

        return shouldAddRootContainerMargins ? conversionConfig.ROOT_COMPONENT_MARGIN_Y : 0
    }

    const y = calculateYForComponents(componentToAdd, blockLayout, blockNumberOfPreviousComponent)
    return y
}
