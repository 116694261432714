import {
    DocumentManager,
    EnvironmentContext,
    CoreLogger,
    store,
    documentManagerCoreTestkit
} from '@wix/document-manager-core'
const {createTestExperimentInstance, createTestLogger} = documentManagerCoreTestkit
import {constants, TestServer, SnapshotExtApi, testExports} from '@wix/document-manager-extensions'
const {savedBlankSite} = testExports
import type {
    Experiment,
    RunningExperiments,
    RendererModel,
    ServiceTopology,
    DocumentServicesModel
} from '@wix/document-services-types'
import _ from 'lodash'
import {
    createDataMigrationRunner,
    MasterPageMigrator,
    PageMigrator,
    SiteMigrator
} from '../../src/dataMigration/dataMigrationRunner'
import {createHost} from '../../src/host'
import * as modelsInitializer from '../../src/initialize/modelsInitializer'
import {initialize} from '../../src/initialize/mainInitialization'
import {newSchemaService} from '@wix/document-services-json-schemas'
import {DefaultRendererModelBuilderForHost} from '../../src'

const {createStore} = store
const {SNAPSHOTS} = constants

const addModelsToDocumentManager = (
    documentManager: DocumentManager,
    {serviceTopology, documentServicesModel, rendererModel}: any
) => {
    const initialState = createStore()
    modelsInitializer.initialize({documentManager, serviceTopology, documentServicesModel, rendererModel}, initialState)
    documentManager.dal.mergeToApprovedStore(initialState)
}

const commonInitialization = (documentManager: DocumentManager, models: any) => {
    addModelsToDocumentManager(documentManager, models)
    documentManager.dal.commitTransaction('test', true)
    const {snapshots} = documentManager.extensionAPI as SnapshotExtApi
    snapshots.takeSnapshot(SNAPSHOTS.DAL_INITIAL)
    snapshots.takeSnapshot(SNAPSHOTS.MOBILE_MERGE)
}

const createHostCommon = ({
    rendererModel,
    serviceTopology,
    documentServicesModel,
    config,
    initializationFunc,
    environmentContext = {},
    logger
}: any) => {
    config.dsOrigin = config.dsOrigin || 'DSFunctionalTestOrigin'
    config.signatureSeed = 'sig'

    const host = createHost({
        models: {serviceTopology, documentServicesModel},
        partialPages: [],
        config,
        experimentInstance: createTestExperimentInstance(rendererModel.runningExperiments),
        logger: logger ?? createTestLogger(),
        schemaService: newSchemaService.staticInstance,
        environmentContext,
        pageList: rendererModel.pageList,
        rendererModelBuilder: new DefaultRendererModelBuilderForHost(rendererModel),
        initFunc: initialize
    })

    const {documentManager} = host
    initializationFunc(documentManager)
    commonInitialization(documentManager, {serviceTopology, documentServicesModel, rendererModel})

    return host
}

const initializeWithSnapshot = (snapshot: Record<string, any>, documentManager: DocumentManager) => {
    const initialData = createStore()
    _(snapshot)
        .omit(['serviceTopology', 'documentServicesModel', 'rendererModel'])
        .forEach((typeMap, type) => {
            _.forEach(typeMap, (data, id) => {
                const pointer = documentManager.pointers.getPointer(id, type)
                initialData.set(pointer, data)
            })
        })
    documentManager.dal.mergeToApprovedStore(initialData, 'initializeWithSnapshot')
}

const getModelsAndQueryFromSnapshot = (
    snapshot: any,
    experiments?: RunningExperiments,
    configName = 'testFullFunctionality'
) => {
    const rendererModel = _.merge({}, snapshot.rendererModel, {
        urlFormatModel: {forbiddenPageUriSEOs: snapshot.rendererModel?.urlFormatModel?.forbiddenPageUriSEOs},
        runningExperiments: experiments
    })
    const documentServicesModel = _.merge({}, snapshot.documentServicesModel, {
        silentSaveInitiator: snapshot.save.silentSaveInitiator,
        publishSaveInitiator: snapshot.save.publishSaveInitiator
    })
    const {serviceTopology} = snapshot.serviceTopology
    const config = {
        debug: 'all',
        dsOrigin: 'Editor1.4',
        editorSessionId: documentServicesModel.editorSessionId,
        esi: documentServicesModel.editorSessionId,
        isEdited: 'true',
        isSantaEditor: 'true',
        lang: 'en',
        metaSiteId: rendererModel.metaSiteId,
        configName,
        enableScopes: 'true'
    }
    return {
        rendererModel,
        documentServicesModel,
        serviceTopology,
        config
    }
}

const createHostFromSnapshot = ({
    snapshot,
    experiments,
    overrides,
    environmentContext = {},
    configName,
    logger
}: {
    snapshot?: any
    experiments?: RunningExperiments
    overrides?: Record<string, unknown>
    environmentContext?: EnvironmentContext
    configName?: string
    logger?: CoreLogger
}) => {
    if (!environmentContext?.serverFacade) {
        const serverFacade = new TestServer()
        _.set(environmentContext, ['serverFacade'], serverFacade)
    }
    const models = getModelsAndQueryFromSnapshot(snapshot, experiments, configName)
    const mergedModels = _.merge({}, models, overrides)
    const {serviceTopology, documentServicesModel, rendererModel, config} = mergedModels

    const initializationFunc = (documentManager: DocumentManager) => initializeWithSnapshot(snapshot, documentManager)

    const host = createHostCommon({
        rendererModel,
        serviceTopology,
        documentServicesModel,
        config,
        initializationFunc,
        environmentContext,
        logger
    })

    return {host, config}
}

const createHostFromBlankSite = (options = {}) =>
    createHostFromSnapshot(_.merge({}, {snapshot: savedBlankSite}, options))

const createTestDataMigrationRunner = (
    runAlways: PageMigrator[],
    runOnce?: PageMigrator[],
    runOncePerSite?: SiteMigrator[],
    masterPageMigrators?: MasterPageMigrator[]
) => createDataMigrationRunner(runAlways, runOnce, runOncePerSite, masterPageMigrators)

const getInitializedModels = (
    experimentInstance: Experiment,
    models: any
): {rendererModel: RendererModel; serviceTopology: ServiceTopology; documentServicesModel: DocumentServicesModel} => {
    const documentManager = {experimentInstance} as unknown as DocumentManager
    const dmStore = createStore()
    const initConfig = {documentManager, ...models}
    modelsInitializer.initialize(initConfig, dmStore)
    return _.pick(dmStore.asJson(), ['rendererModel', 'serviceTopology', 'documentServicesModel']) as any
}

export {
    createHostCommon,
    savedBlankSite,
    createHostFromSnapshot,
    createHostFromBlankSite,
    createTestDataMigrationRunner,
    getInitializedModels
}
