import _ from 'lodash'

function getItemsDiff(lastSnapshot, currentSnapshot, ignoreFields, transformFunc) {
    const updated = []
    const deleted = []

    lastSnapshot.forEach(function (typeItems, typeId) {
        typeItems.forEach(function (item, itemKey) {
            const itemInCurrentSnapshot = currentSnapshot.getIn([typeId, itemKey])
            if (itemInCurrentSnapshot) {
                const itemJS = item.toJS()
                const itemInCurrentSnapshotJS = itemInCurrentSnapshot.toJS()
                if (!_.isEqual(_.omit(itemJS, ignoreFields), _.omit(itemInCurrentSnapshotJS, ignoreFields))) {
                    const changedItem = transformFunc
                        ? transformFunc(itemJS, itemInCurrentSnapshotJS)
                        : itemInCurrentSnapshotJS
                    updated.push(changedItem)
                }
            } else {
                deleted.push(itemKey)
            }
        })
    })

    const created = currentSnapshot.reduce(function (createdItems, itemsOfType, typeId) {
        const lastItemsOfType = lastSnapshot.get(typeId)
        const newItemsOfType = lastItemsOfType
            ? itemsOfType.filterNot(function (item, itemKey) {
                  return lastItemsOfType.has(itemKey)
              })
            : itemsOfType

        return createdItems.concat(newItemsOfType.toList().toJS())
    }, [])

    return {
        created,
        updated,
        deleted
    }
}

export default {
    getItemsDiff
}
