import _ from 'lodash'
import component from '../component/component'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import boxSlideShowLayouter from './boxSlideShowLayouter'
import type {Pointer, PS} from '@wix/document-services-types'

const LAYOUTER_TYPES = {
    'wysiwyg.viewer.components.BoxSlideShow': boxSlideShowLayouter,
    'wysiwyg.viewer.components.StateBox': boxSlideShowLayouter,
    'wysiwyg.viewer.components.StateStrip': boxSlideShowLayouter,
    'wysiwyg.viewer.components.StripContainerSlideShow': boxSlideShowLayouter
}

function isLayouterComponent(ps: PS, compPointer: Pointer) {
    return LAYOUTER_TYPES[componentsMetaData.getComponentType(ps, compPointer)]
}

function callLayouterParentFunction(ps: PS, componentPointer: Pointer, funcName: string) {
    if (!ps?.dal.isExist(componentPointer) || !isLayouterComponent(ps, componentPointer)) {
        throw new Error('invalid args')
    }

    const compType = componentsMetaData.getComponentType(ps, componentPointer)
    return LAYOUTER_TYPES[compType][funcName].call(this, ps, componentPointer, compType)
}

function callLayouterChildFunction(ps: PS, componentPointer: Pointer, parentType: string, funcName: string) {
    if (!ps?.dal.isExist(componentPointer) || !LAYOUTER_TYPES[parentType]) {
        throw new Error('invalid args')
    }

    return LAYOUTER_TYPES[parentType][funcName].call(this, ps, componentPointer, parentType)
}

function findParentLayouter(ps: PS, componentPointer: Pointer) {
    if (ps.pointers.components.isPage(componentPointer)) {
        return null
    }
    let currParent = component.getContainer(ps, componentPointer)

    while (currParent) {
        const parentCompType = componentsMetaData.getComponentType(ps, currParent)
        if (_.includes(_.keys(LAYOUTER_TYPES), parentCompType)) {
            return parentCompType
        }
        currParent = component.getContainer(ps, currParent)
    }
    return null
}

function getMasterChildren(ps: PS, componentPointer: Pointer): Pointer[] {
    return callLayouterParentFunction(ps, componentPointer, 'getMasterChildren')
}

function getNonMasterChildren(ps: PS, componentPointer: Pointer): Pointer[] {
    return callLayouterParentFunction(ps, componentPointer, 'getNonMasterChildren')
}

/**
 * @param {ps} ps
 * @param componentPointer
 * @param parentType
 * @returns {Boolean}
 */
function isMasterChild(ps: PS, componentPointer: Pointer, parentType: string) {
    return callLayouterChildFunction(ps, componentPointer, parentType, 'isMasterChild')
}

/**
 * @param {ps} ps
 * @param componentPointer
 * @param parentType
 */
function toggleMasterChild(ps: PS, componentPointer: Pointer, parentType: string) {
    if (!canBeMasterChild(ps, componentPointer, parentType)) {
        throw new Error("component can't be shown on all states")
    }
    return callLayouterChildFunction(ps, componentPointer, parentType, 'toggleMasterChild')
}

/**
 * @param {ps} ps
 * @param componentPointer
 * @param parentType
 */
function canBeMasterChild(ps: PS, componentPointer: Pointer, parentType: string) {
    return callLayouterChildFunction(ps, componentPointer, parentType, 'canBeMasterChild')
}

function getParentCompWithOverflowHidden(ps: PS, componentPointer: Pointer) {
    const parentLayouterComponentType = findParentLayouter(ps, componentPointer)
    return parentLayouterComponentType
        ? callLayouterChildFunction(
              ps,
              componentPointer,
              parentLayouterComponentType,
              'getParentCompWithOverflowHidden'
          )
        : false
}

/** @class documentServices.layouters */
export default {
    /**
     * Returns all children that behave as master components (i.e shown on all father component's changing states)
     * @param {Pointer} componentReference the reference to the component to get its master children.
     * @returns an array of components references
     *
     *      @example
     *      const masterChildren = documentServices.layouters.getMasterChildren(compReference);
     */
    getMasterChildren,
    /**
     * Returns all children that don't behave as master components (i.e shown on a specific father's state, and not on all states)
     * @param {Pointer} componentReference the reference to the component to get its non master children.
     * @returns an array of components references
     *
     *      @example
     *      const nonMasterChildren = documentServices.layouters.getNonMasterChildren(compReference);
     */
    getNonMasterChildren,
    /**
     * Returns whether or not the component behave as a master component relative to its ancestor parent with the corresponding type
     * @param {Pointer} componentReference the reference to the component to check if it's a master component
     * @param {String} parentType layouter component type
     * @returns true or false
     *
     *      @example
     *      const isMasterChild = documentServices.layouters.isMasterChild(compReference, 'wysiwyg.viewer.components.BoxSlideShow');
     */
    isMasterChild,
    /**
     * Toggle the component to behave as a master component relative to its ancestor parent, or not, depending on the current state
     * @param {Pointer} componentReference the reference to the component to toggle as a master component
     * @param {String} parentType layouter component type - the ancestor's parent type
     *
     *      @example
     *      documentServices.layouters.toggleMasterChild(compReference, 'wysiwyg.viewer.components.BoxSlideShow');
     */
    toggleMasterChild,
    /**
     * Returns whether or not the component can behave as a master component relative to its ancestor parent with the corresponding type
     * @param {Pointer} componentReference the reference to the component to check if it can be a master component
     * @param {String} parentType layouter component type
     * @returns true or false
     *
     *      @example
     *      const canBeMasterChild = documentServices.layouters.canBeMasterChild(compReference, 'wysiwyg.viewer.components.BoxSlideShow');
     */
    canBeMasterChild,
    /**
     * Returns the parent container of the component that has an overflow flag
     * @param {Pointer} componentReference the reference to the component to check if it is hidden by an overflow flag
     * @returns true or false
     *
     *      @example
     *      const getParentCompWithOverflowHidden = documentServices.layouters.getParentCompWithOverflowHidden(compReference);
     */
    getParentCompWithOverflowHidden
}
