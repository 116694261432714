import type {AppDefinitionId, ApplicationId, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataManipulation from './dataManipulation'

function getAppsData(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.CLIENT_SPEC_MAP) || {}
}

function registerAppData(ps: PS, newAppData) {
    const appsData = getAppsData(ps)
    if (newAppData.applicationId) {
        appsData[newAppData.applicationId] = newAppData
        dataManipulation.setProperty(ps, dataManipulation.PROPERTY_NAMES.CLIENT_SPEC_MAP, appsData)
    }
}

function updateAppInstance(ps: PS, applicationId: ApplicationId, instance) {
    const appData = getAppData(ps, applicationId)
    if (appData) {
        appData.instance = instance
        registerAppData(ps, appData)
    }
}

function getAppsDataWithPredicate(ps: PS, predicate): any[] {
    return _.map(ps.pointers.general.getClientSpecMapEntriesByPredicate(predicate), ps.dal.get)
}

function getAppData(ps: PS, applicationId: ApplicationId) {
    return ps.dal.get(ps.pointers.general.getClientSpecMapEntry(applicationId as string)) || {}
}

function getAppDataByAppDefinitionId(ps: PS, appDefinitionId: AppDefinitionId) {
    return ps.dal.get(ps.pointers.general.getClientSpecMapEntryByAppDefId(appDefinitionId))
}

function filterAppsDataByType(ps: PS, type: string): any[] {
    return getAppsDataWithPredicate(ps, csm => _.filter(csm, {type}))
}

function getApplicationIdFromAppDefinitionId(ps: PS, appDefinitionId: AppDefinitionId): ApplicationId {
    const csmEntry = ps.dal.get(ps.pointers.general.getClientSpecMapEntryByAppDefId(appDefinitionId))
    return csmEntry.applicationId
}

function getAppDefinitionIdFromApplicationId(ps: PS, applicationId: ApplicationId): AppDefinitionId {
    const csmEntry = ps.dal.get(ps.pointers.general.getClientSpecMapEntry(applicationId.toString()))
    return csmEntry.appDefinitionId
}

function getApplicationIdFromAppDefinitionIdBulk(ps: PS, appDefinitionIds: AppDefinitionId[]): ApplicationId[] {
    return appDefinitionIds.map(appDefinitionId => getApplicationIdFromAppDefinitionId(ps, appDefinitionId))
}

function getAppDefinitionIdFromApplicationIdBulk(ps: PS, applicationIds: ApplicationId[]): AppDefinitionId[] {
    return applicationIds.map(applicationId => getAppDefinitionIdFromApplicationId(ps, applicationId))
}

export default {
    registerAppData,
    updateAppInstance,
    getAppData,
    getAppDataByAppDefinitionId,
    getAppsData,
    filterAppsDataByType,
    getAppsDataWithPredicate,
    getApplicationIdFromAppDefinitionId,
    getAppDefinitionIdFromApplicationId,
    getApplicationIdFromAppDefinitionIdBulk,
    getAppDefinitionIdFromApplicationIdBulk
}
