import type {Pointer, PS} from '@wix/document-services-types'
import type {DeepStructure} from '@wix/mobile-conversion/src/types'
import {objectUtils} from '@wix/santa-core-utils'
import {fullToDisplayedJson} from '@wix/santa-ds-libs/src/coreUtils'
import _ from 'lodash'
import componentModes from '../../component/componentModes'
import constants from '../../constants/constants'
import hooks from '../../hooks/hooks'
import structuresComparer from './structuresComparer'

interface FullPageStructure {
    desktop: DeepStructure
    mobile: DeepStructure
}

function initialize(ps: PS) {
    hooks.unregisterHooks([hooks.HOOKS.PUBLISH.BEFORE])
    const mobileMergeSnapshot = ps.dal.full.immutable.getLastSnapshotByTagName(constants.SNAPSHOT_TAGS.MOBILE_MERGE)
    if (!mobileMergeSnapshot) {
        ps.dal.takeSnapshot(constants.SNAPSHOT_TAGS.MOBILE_MERGE)
    }
}

function getChangedPagesPointers(ps: PS): Pointer[] {
    if (!structuresComparer.hasMobileStructure(ps)) {
        return ps.pointers.page.getNonDeletedPagesPointers(true)
    }
    return ps.extensionAPI.page.getNonDeletedChangedPagePointersSinceLastSnapshot(constants.SNAPSHOT_TAGS.MOBILE_MERGE)
}

function getChangedPages(ps: PS): Record<string, FullPageStructure> {
    const pagePointers = getChangedPagesPointers(ps)
    return _.transform(
        pagePointers,
        (acc, pagePointer) => {
            const pageId = pagePointer.id
            const pageComponent = getPage(ps, pageId)
            _.set(acc, pageId, pageComponent)
        },
        {}
    )
}

function getPage(ps: PS, pageId: string): FullPageStructure {
    const pagePointer = ps.pointers.page.getPagePointer(pageId)
    const pageActiveModesMap = componentModes.getMobileActiveModesMap(ps, pageId)
    const activeModesMap = _.set({}, pageId, pageActiveModesMap)
    const desktop = fullToDisplayedJson.getDisplayedJson(ps.dal.full.get(pagePointer), activeModesMap, pageId).structure
    if (desktop.children) {
        desktop.components = desktop.children
        delete desktop.children
    }
    const mobile = objectUtils.cloneDeep(desktop)
    mobile.components = mobile.mobileComponents
    return {desktop, mobile}
}

function commitResults(ps: PS) {
    ps.dal.takeSnapshot(constants.SNAPSHOT_TAGS.MOBILE_MERGE)
}

export default {
    initialize,
    getChangedPages,
    getChangedPagesPointers,
    getPage,
    commitResults
}
