import type {Pointer, PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import _ from 'lodash'
import connections from '../../connections/connections'
import dataModel from '../../dataModel/dataModel'
import hooks from '../../hooks/hooks'
import appFilters from './appFilters'
import appFinder from './appFinder'

const notifyLivePreviewStateIfNeeded = (ps: PS, effectiveOptions) => {
    const shouldSync = _.get(effectiveOptions, ['sharedStateSyncOptions', 'shouldSync'])
    if (shouldSync) {
        ps.setOperationsQueue.runSetOperation(() => {
            ps.extensionAPI.livePreviewSharedState.notifyLivePreviewDataChanged(effectiveOptions)
        })
    }
}

const refreshAppsInternal = (ps: PS, effectiveOptions) => {
    if (effectiveOptions.immediate && effectiveOptions.shouldRunInQueue !== false) {
        ps.setOperationsQueue.runSetOperation(
            () => {
                ps.siteAPI.refreshAppsInCurrentPage(effectiveOptions)
            },
            [],
            {enforceType: 'GENERATE_AND_ENFORCE'}
        )
    } else {
        ps.siteAPI.refreshAppsInCurrentPage(effectiveOptions)
    }
}

const refreshAfterDataOrPropertyUpdate = (hookName: string, ps: PS, componentPointer: Pointer) => {
    if (
        experiment.isOpen('dm_disableBlocksWidgetLivePreviewOnHooks') &&
        appFilters.isBlocksAppWidget(ps, componentPointer)
    ) {
        return
    }
    const controllersToRefresh = getComponentsControllers(ps, componentPointer, true)
    if (_.isEmpty(controllersToRefresh)) {
        return
    }
    const compData = dataModel.getDataItem(ps, componentPointer)
    const options = getOptionsWithAllAppsModifiers(ps, componentPointer, _.get(hooks.HOOKS, hookName))
    refreshWhenAppsNotEmpty(
        ps,
        appFinder.appsOfComp(ps, compData, componentPointer),
        hookName,
        componentPointer,
        _.assign({controllersToRefresh}, options)
    )
}

/**
 * @param {ps} ps
 * @param {Object} options
 * @param [originatingCmpPtr] - optional, used to customize options according to originating comp
 */
function refresh(ps: PS, options, originatingCmpPtr?: Pointer) {
    if (!isActive(ps)) {
        return
    }
    options.apps = _.intersection(options.apps, ps.siteAPI.getAllowedApps())
    options.apps = appFilters.addDependantApps(ps, options.apps)
    if (!options.skipAppsCheck && _.isEmpty(options.apps)) {
        return
    }
    const effectiveOptions = extendOptionsByApp(ps, options, originatingCmpPtr)
    refreshAppsInternal(ps, effectiveOptions)
    notifyLivePreviewStateIfNeeded(ps, effectiveOptions)
}

function refreshAppsAPI(ps: PS, {apps, source, shouldFetchData}) {
    const options = _.assign(appFilters.optionsModifierSelfRefresh(ps, apps), {
        source,
        shouldFetchData,
        sendInitAndStart: true,
        sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
    })

    if (!_.isEmpty(options.controllersToRefresh) && !_.isEmpty(options.compsIdsToReset)) {
        refresh(ps, options)
    }
}

function extendOptionsByApp(ps: PS, options, componentPointer: Pointer) {
    if (!options?.apps) {
        return options
    }
    return options.apps.reduce((res, app) => {
        const changeFromApp = _.get(appFilters.optionsModifiers, app, _.noop)(ps, componentPointer)
        return _.assign(res, changeFromApp)
    }, options)
}

function refreshWhenAppsNotEmpty(ps: PS, apps, source, componentPointer: Pointer, extraOptions) {
    const options = _.assign({apps, source, sendInitAndStart: true}, extraOptions || {})
    if (!_.isEmpty(apps)) {
        refresh(ps, options, componentPointer)
    }
}

function refreshAllApps(ps: PS, options) {
    refresh(ps, _.assign(options, {apps: ps.siteAPI.getAllowedApps()}))
}

function isActive(ps: PS) {
    const enabledEditors = {
        'Editor1.4': true,
        editor_x: true,
        onboarding: ps.runtimeConfig.viewerName === 'tb'
    }
    return enabledEditors[ps.config.origin]
}

let registeredHooks

function registerHook(hookName: string, cb) {
    registeredHooks[hookName] = hooks.registerHook(hookName, cb)
}

function unregisterHooks() {
    _.forEach(registeredHooks, (hookValue, hookKey) => hooks.unregisterHook(hookKey, hookValue))
    registeredHooks = Object.create(null)
}

function getOptionsWithAllAppsModifiers(ps: PS, componentPointer: Pointer, hook) {
    const options = _.get(appFilters.optionsModifiersAllApps, hook, _.noop)(ps, componentPointer)
    _.assign(options, {sharedStateSyncOptions: getGenericSharedStateComponentOptions(ps, componentPointer)})
    return options
}

const getGenericSharedStateComponentOptions = (ps: PS, compPointer: Pointer) => ({
    shouldSync: true,
    pageId: _.get(ps.pointers.full.components.getPageOfComponent(compPointer), 'id')
})

function getComponentsControllers(ps: PS, componentPointer: Pointer, excludeOOIControllers?) {
    const compControllers = []
    const componentType = ps.dal.get(ps.pointers.getInnerPointer(componentPointer, 'componentType'))
    const isIncludedOOIController = connections.isOOIController(componentType) && !excludeOOIControllers
    if (connections.isControllerType(componentType) || isIncludedOOIController) {
        const controllerData = dataModel.getDataItem(ps, componentPointer)
        compControllers.push(controllerData.id)
    }
    const connectionItemPointer = dataModel.getConnectionsItemPointer(ps, componentPointer)
    if (!connectionItemPointer) {
        return compControllers
    }
    const componentConnections = ps.dal.get(connectionItemPointer)
    if (!componentConnections?.items) {
        return compControllers
    }
    return _.map(_.filter(componentConnections.items, {type: 'ConnectionItem'}), 'controllerId').concat(compControllers)
}

function isDynamicPageDataChanged(pageInfo, prevPageInfo) {
    if (
        _.isObject(pageInfo) &&
        _.isObject(prevPageInfo) &&
        // @ts-expect-error
        prevPageInfo.routerDefinition &&
        // @ts-expect-error
        pageInfo.routerDefinition
    ) {
        return (
            // @ts-expect-error
            pageInfo.innerRoute === prevPageInfo.innerRoute &&
            // @ts-expect-error
            pageInfo.routerDefinition.routerId === prevPageInfo.routerDefinition.routerId &&
            // @ts-expect-error
            !_.isEqual(pageInfo.routerDefinition, prevPageInfo.routerDefinition)
        )
    }
    return false
}

const afterDataUpdate = (ps: PS, componentPointer: Pointer) => {
    refreshAfterDataOrPropertyUpdate('DATA.UPDATE_AFTER', ps, componentPointer)
}
const afterPropertiesUpdate = (ps: PS, componentPointer: Pointer) => {
    refreshAfterDataOrPropertyUpdate('PROPERTIES.UPDATE_AFTER', ps, componentPointer)
}

const afterConnectionsUpdate = (ps: PS, data, componentPointer: Pointer) => {
    const controllersToRefresh = getComponentsControllers(ps, componentPointer)
    const options = getOptionsWithAllAppsModifiers(ps, componentPointer, hooks.HOOKS.DATA.AFTER_UPDATE_CONNECTIONS)
    const dataItem = dataModel.getDataItem(ps, componentPointer)
    refreshWhenAppsNotEmpty(
        ps,
        appFinder.appsOfComp(ps, dataItem, componentPointer),
        'AFTER_UPDATE_CONNECTIONS',
        componentPointer,
        _.assign({controllersToRefresh}, options)
    )
}

const afterDisconnect = (ps: PS, componentPointer: Pointer, controllerRef) => {
    const controllerData = dataModel.getDataItem(ps, controllerRef)
    const controllersToRefresh = getComponentsControllers(ps, componentPointer).concat(controllerData.id)
    const options = getOptionsWithAllAppsModifiers(ps, componentPointer, hooks.HOOKS.CONNECTION.AFTER_DISCONNECT)
    refreshWhenAppsNotEmpty(
        ps,
        [appFinder.getAppOfController(ps, controllerRef)],
        'AFTER_DISCONNECT',
        componentPointer,
        _.assign({controllersToRefresh}, options)
    )
}

const afterSetByPointer = (ps: PS, dataItemPointer: Pointer, data) =>
    refreshWhenAppsNotEmpty(ps, appFinder.appsOfComp(ps, data, dataItemPointer), 'SET_BY_POINTER_AFTER', null, {
        sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
    })

const afterAddRoot = (ps: PS, componentPointer: Pointer) =>
    refreshWhenAppsNotEmpty(ps, appFinder.appsOfComp(ps, null, componentPointer), 'ADD_ROOT.AFTER', null, {
        sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
    })

const afterChangeCompViewMode = (ps: PS, viewMode: string) => {
    if (viewMode === 'editor') {
        refreshAllApps(ps, {
            immediate: true,
            restartWorker: true,
            skipAppsCheck: !!appFilters.hasAppsWithViewerScript(ps),
            source: 'CHANGE_COMPONENT_VIEW_MODE',
            sharedStateSyncOptions: {shouldSync: false}
        })
    } else {
        refreshAllApps(ps, {
            sendLoad: true,
            sendInitAndStart: true,
            skipAppsCheck: !!appFilters.hasAppsWithViewerScript(ps),
            source: 'CHANGE_COMPONENT_VIEW_MODE',
            sharedStateSyncOptions: {shouldSync: false}
        })
    }
}

const afterChangeParent = (ps: PS, componentPointer: Pointer) => {
    if (ps.dal.get(componentPointer)) {
        const controllersToRefresh = getComponentsControllers(ps, componentPointer)
        const options = getOptionsWithAllAppsModifiers(ps, componentPointer, hooks.HOOKS.CHANGE_PARENT.AFTER)
        const dataItem = dataModel.getDataItem(ps, componentPointer)
        refreshWhenAppsNotEmpty(
            ps,
            appFinder.appsOfComp(ps, dataItem, componentPointer),
            'CHANGE_PARENT',
            componentPointer,
            {
                ...options,
                controllersToRefresh,
                sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
            }
        )
    } else {
        refreshAllApps(ps, {
            resetRuntime: true,
            sendInitAndStart: true,
            source: 'CHANGE_PARENT',
            sharedStateSyncOptions: {shouldSync: true, pageId: ps.siteAPI.getPrimaryPageId()}
        })
    }
}

const afterNavigateToPage = (ps: PS) =>
    refreshAllApps(ps, {
        source: 'AFTER_NAVIGATE_TO_PAGE',
        immediate: true,
        shouldRunInQueue: false,
        sharedStateSyncOptions: {shouldSync: false}
    })

const afterNavigateToPageDone = (ps: PS, pageInfo, prevPageInfo) => {
    const isDataChanged = isDynamicPageDataChanged(pageInfo, prevPageInfo)
    if (isDataChanged) {
        refreshAllApps(ps, {
            source: 'AFTER_NAVIGATE_TO_PAGE_DONE',
            immediate: true,
            sendLoad: true,
            sendInitAndStart: true,
            resetRuntime: true,
            sharedStateSyncOptions: {shouldSync: false}
        })
    }
}

const afterUpdateWixCodeModel = (ps: PS) =>
    refreshAllApps(ps, {
        source: 'WIXCODE.UPDATE_MODEL',
        immediate: true,
        sendLoad: true,
        sendInitAndStart: true,
        resetRuntime: true,
        sharedStateSyncOptions: {shouldSync: false}
    })

const afterRouterDataReloaded = (ps: PS) =>
    refreshAllApps(ps, {
        source: 'DATA_RELOADED',
        immediate: true,
        sendLoad: true,
        sendInitAndStart: true,
        resetRuntime: true,
        sharedStateSyncOptions: {shouldSync: false}
    })

const afterChangeLanguage = (ps: PS) =>
    refreshAllApps(ps, {
        source: 'AFTER_CHANGE_LANGUAGE',
        immediate: true,
        shouldRunInQueue: false,
        sharedStateSyncOptions: {shouldSync: false}
    })

const afterSwitchViewMode = (ps: PS) =>
    refreshAllApps(ps, {restartWorker: true, source: 'SWITCH_VIEW_MODE', sharedStateSyncOptions: {shouldSync: false}})

const afterApplySnapshot = (ps: PS) =>
    refreshAllApps(ps, {
        restartWorker: true,
        source: 'UNDO_REDO.AFTER_APPLY_SNAPSHOT',
        shouldFetchData: true,
        sharedStateSyncOptions: {shouldSync: true}
    })

function autoRun(ps: PS) {
    ps.extensionAPI.livePreviewSharedState.subscribeToLivePreviewDataChanges(livePreviewOptions => {
        const syncByPageId = _.get(livePreviewOptions, ['sharedStateSyncOptions', 'pageId'])
        const currentPageId = ps.siteAPI.getPrimaryPageId()
        if (!syncByPageId || syncByPageId === currentPageId || syncByPageId === 'masterPage') {
            refreshAppsInternal(ps, livePreviewOptions)
        }
    })
    unregisterHooks()

    registerHook(hooks.HOOKS.DATA.UPDATE_AFTER, afterDataUpdate)

    registerHook(hooks.HOOKS.PROPERTIES.UPDATE_AFTER, afterPropertiesUpdate)

    registerHook(hooks.HOOKS.DATA.AFTER_UPDATE_CONNECTIONS, afterConnectionsUpdate)

    registerHook(hooks.HOOKS.CONNECTION.AFTER_DISCONNECT, afterDisconnect)

    registerHook(hooks.HOOKS.DATA.SET_BY_POINTER_AFTER, afterSetByPointer)

    registerHook(hooks.HOOKS.ADD_ROOT.AFTER, afterAddRoot)

    registerHook(hooks.HOOKS.CHANGE_COMPONENT_VIEW_MODE.AFTER, afterChangeCompViewMode)

    registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, afterChangeParent)

    registerHook(hooks.HOOKS.PAGE.AFTER_NAVIGATE_TO_PAGE, afterNavigateToPage)
    registerHook(hooks.HOOKS.PAGE.AFTER_NAVIGATE_TO_PAGE_DONE, afterNavigateToPageDone)

    registerHook(hooks.HOOKS.WIXCODE.UPDATE_MODEL, afterUpdateWixCodeModel)

    registerHook(hooks.HOOKS.ROUTER.DATA_RELOADED, afterRouterDataReloaded)
    registerHook(hooks.HOOKS.MULTILINGUAL.AFTER_CHANGE_LANGUAGE, afterChangeLanguage)

    registerHook(hooks.HOOKS.SWITCH_VIEW_MODE.AFTER, afterSwitchViewMode)
    registerHook(hooks.HOOKS.UNDO_REDO.AFTER_APPLY_SNAPSHOT, afterApplySnapshot)
}

export default {
    debouncedRefresh: refreshAppsAPI,
    isActive,
    autoRun
}
