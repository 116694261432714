import type {CompRef, CompStructure, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import variantsUtils from './variantsUtils'
import dataModel from '../dataModel/dataModel'
import dsUtils from '../utils/utils'
import dataIds from '../dataModel/dataIds'
import hooks from '../hooks/hooks'
import relationsUtils from './relationsUtils'
import {updateConsideringOverrides} from '../overrides/overrides'
import {shouldUseDesignInVariants} from '../overrides/designOverVariants'

const {DATA_TYPES, COMP_DATA_QUERY_KEYS} = constants

const ERRORS = {
    designOverVariants: (componentPointer: Pointer) =>
        `design over variants should not be used, please contact DM group, component pointer: ${componentPointer}`,
    designOverVariantsWithModes: (componentPointer: Pointer) =>
        `variant designs should not be used in modes, component pointer: ${componentPointer}`
}

const throwErrorIfDesignWithVariantsUsedInModes = (ps: PS, componentPointer: Pointer) => {
    if (ps.pointers.components.isWithVariants(componentPointer)) {
        throw new Error(ERRORS.designOverVariantsWithModes(componentPointer))
    }
}

const getDesignItemPointer = (ps: PS, componentVariantsPointer: Pointer) =>
    variantsUtils.getComponentDataPointerConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.design)

const getDesignItem = (ps: PS, componentVariantsPointer: Pointer, deleteId?: boolean) =>
    variantsUtils.getComponentDataConsideringVariants(ps, componentVariantsPointer, DATA_TYPES.design, deleteId)

const getDesignPointerInModes = (ps: PS, componentPointer: Pointer, modes) => {
    const overrides = ps.dal.full.get(ps.pointers.componentStructure.getModesOverrides(componentPointer))
    const overrideInMode = _.find(overrides, function (override) {
        return (
            modes &&
            override.modeIds.length === modes.length &&
            _.intersection(override.modeIds, modes).length === override.modeIds.length
        )
    })
    let designQuery = _.get(overrideInMode, COMP_DATA_QUERY_KEYS.design)
    if (!designQuery) {
        const designQueryPointer = ps.pointers.getInnerPointer(componentPointer, COMP_DATA_QUERY_KEYS.design)
        designQuery = ps.dal.full.get(designQueryPointer)
    }
    if (designQuery) {
        const page = ps.pointers.full.components.getPageOfComponent(componentPointer)
        const designItemPointer = ps.pointers.data.getDesignItem(dsUtils.stripHashIfExists(designQuery), page.id)
        const designItem = ps.dal.get(designItemPointer) || ps.dal.full.get(designItemPointer)
        if (dataModel.refArray.isRefArray(ps, designItem)) {
            return getDesignItemPointer(ps, componentPointer)
        }

        return designItemPointer
    }
}

const getDesignItemByModes = (ps: PS, componentPointer: Pointer, modes?) => {
    const designPointer = getDesignPointerInModes(ps, componentPointer, modes)
    return dataModel.getDataByPointer(ps, DATA_TYPES.design, designPointer)
}

const getDesignItemById = (ps: PS, dataItemId: string, pageId: string, deleteId = false) => {
    const designPointer = ps.pointers.data.getDesignItem(dataItemId, pageId || 'masterPage')
    return dataModel.getDataByPointer(ps, DATA_TYPES.design, designPointer, deleteId)
}

const shouldRemoveOverlay = (ps: PS, componentPointer: CompRef, designItem) =>
    designItem.background &&
    !designItem.background.colorOverlay &&
    !designItem.background.imageOverlay &&
    !shouldUseDesignInVariants(ps, componentPointer)

const updateDesignItem = (ps: PS, componentPointer: CompRef, designItem, retainCharas?: boolean) => {
    const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)
    if (designItem.dataChangeBehaviors && shouldRemoveOverlay(ps, componentPointer, designItem)) {
        designItem.dataChangeBehaviors = _.reject(designItem.dataChangeBehaviors, {part: 'overlay'})
    }
    const compType = dsUtils.getComponentType(ps, actualComponentPointer)

    if (!retainCharas) {
        designItem.charas = dataIds.generateNewDesignId()
    }

    const designId = updateConsideringOverrides(ps, componentPointer, designItem, DATA_TYPES.design)

    hooks.executeHook(hooks.HOOKS.DESIGN.UPDATE_AFTER, compType, [ps, actualComponentPointer, designItem])

    return designId
}

const BEHAVIOR_PROPS_TO_COMPARE = ['trigger', 'type', 'part', 'name']

const areBehaviorsEqual = (behavior1, behavior2) =>
    BEHAVIOR_PROPS_TO_COMPARE.every(prop => behavior1[prop] === behavior2[prop])

const getUpdatedBehaviors = (oldBehaviors, behaviorsToUpdate, shouldRemove: boolean) => {
    const isEqualToUpdatedBehaviorObjects = _.map(behaviorsToUpdate, behavior => ({
        isEqualFunc: _.partial(areBehaviorsEqual, behavior),
        behavior
    }))

    let updatedBehaviors = oldBehaviors

    _.forEach(isEqualToUpdatedBehaviorObjects, isEqualToUpdatedBehaviorObject => {
        updatedBehaviors = _.reject(updatedBehaviors, isEqualToUpdatedBehaviorObject.isEqualFunc)
        if (!shouldRemove) {
            updatedBehaviors.push(isEqualToUpdatedBehaviorObject.behavior)
        }
    })

    return updatedBehaviors
}

const updateDesignItemBehaviors = (ps: PS, componentPointer: CompRef, newBehaviors?) => {
    throwErrorIfDesignWithVariantsUsedInModes(ps, componentPointer)
    const designItem = getDesignItem(ps, componentPointer)

    designItem.dataChangeBehaviors = getUpdatedBehaviors(designItem.dataChangeBehaviors, newBehaviors, false)

    return updateDesignItem(ps, componentPointer, designItem)
}

const removeDesignItemBehaviors = (ps: PS, componentPointer: CompRef, behaviorsToRemove?) => {
    throwErrorIfDesignWithVariantsUsedInModes(ps, componentPointer)
    const designItem = getDesignItem(ps, componentPointer)

    designItem.dataChangeBehaviors = getUpdatedBehaviors(designItem.dataChangeBehaviors, behaviorsToRemove, true)

    return updateDesignItem(ps, componentPointer, designItem)
}

const removeComponentDesignItem = (ps: PS, componentPointer: CompRef) => {
    if (shouldUseDesignInVariants(ps, componentPointer)) {
        variantsUtils.removeComponentDataConsideringVariants(ps, componentPointer, DATA_TYPES.design)
    } else {
        const designQueryPointer = ps.pointers.getInnerPointer(componentPointer, 'designQuery')
        //do not handle deleting of data items themselves (e.g deleteDataItem),
        //can be risky and will be handled via garbage collection
        ps.dal.remove(designQueryPointer)
    }
}

const getMediaRef = designItem => _.get(designItem, 'background.mediaRef')

const getComponentDefaultDesign = (ps: PS, compStructure: CompStructure, pageId: string) => {
    const designItemId = compStructure.designQuery && dsUtils.stripHashIfExists(compStructure.designQuery)
    const designItem = ps.dal.get(ps.pointers.data.getDesignItem(designItemId, pageId || 'masterPage'))
    const nonScopesPointer = relationsUtils.nonScopedValuePointer(ps, DATA_TYPES.design, designItem, pageId)
    const defaultDesignItemId = nonScopesPointer ? nonScopesPointer.id : designItemId
    return defaultDesignItemId && getDesignItemById(ps, defaultDesignItemId, pageId)
}

export default {
    getComponentDefaultDesign,
    getDesignItemById,
    getDesignItemPointer,
    updateDesignItem,
    updateDesignItemBehaviors,
    removeDesignItemBehaviors,
    getDesignItem,
    getDesignItemByModes,
    removeComponentDesignItem,
    getMediaRef,
    ERRORS
}
