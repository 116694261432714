import _ from 'lodash'

const parentLayoutProperties = ['width', 'box-flex', 'flex']
const irrelevantComps = ['VSpacer', 'HSpacer']

function isWidthAmbiguous(parentLayout) {
    if (!parentLayout) {
        return true
    }
    return _.every(parentLayoutProperties, prop => !parentLayout[prop])
}

function hasChildContent(getCompProp, items) {
    return _.some(items, child => !_.includes(irrelevantComps, getCompProp('name', child)))
}

function findMaxManualWidth(getCompProp, items) {
    const childrenWithManualWidth = _.filter(items, function (child) {
        const widthMode = getCompProp('width-mode', child)
        if (widthMode) {
            return widthMode === 'manual'
        }
        return !_.isUndefined(getCompProp('width', child))
    })

    if (_.isEmpty(childrenWithManualWidth)) {
        return 200
    }

    return _(childrenWithManualWidth)
        .map(function (child) {
            const spacers = getCompProp('spacers', child)
            return getCompProp('width', child) + (spacers['xax-before'] || 0) + (spacers['xax-after'] || 0)
        })
        .max()
}

function isDisplayed(getCompProp, item) {
    return !getCompProp('hidden', item)
}

function getFieldBoxDef(getCompProp, orientation, viewDef) {
    const fieldBoxOrientation = getCompProp('orientation', viewDef)
    const newCompName = fieldBoxOrientation === 'vertical' ? 'VBox' : 'HBox'
    const def = {
        comp: {
            name: newCompName,
            items: _.filter(viewDef.comp.items, isDisplayed.bind(null, getCompProp))
        }
    }

    const items = getCompProp('items', def)
    if (
        orientation === 'horizontal' &&
        fieldBoxOrientation === 'vertical' &&
        isWidthAmbiguous(viewDef.layout) &&
        hasChildContent(getCompProp, items)
    ) {
        const maxManualWidth = findMaxManualWidth(getCompProp, items)
        const widthStyle: any = {comp: {css: {width: maxManualWidth}}}
        if (_.has(viewDef, 'layout') && _.has(viewDef.layout, 'min-width')) {
            widthStyle.layout = {'min-width': maxManualWidth}
        }
        return _.merge(def, widthStyle)
    }
    return _.merge(def, {comp: {css: {width: 'auto'}}})
}

export default {
    getFieldBoxDef
}
