import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import page from '../../page/page'
import clientSpecMapService from './clientSpecMapService'

function getSubPages(ps: PS, pageId) {
    const data = page.data.get(ps, pageId)
    if (data && data.tpaApplicationId > 0 && data.tpaPageId) {
        const appData = clientSpecMapService.getAppData(ps, data.tpaApplicationId)
        const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, appData.appDefinitionId, data.tpaPageId)
        /*
         * In app CSM data, each widget can have router types (sub pages) linked to it.
         * These sub pages are a list of string identifiers that can be used to fetch sub page inner routes.
         * Each tpa page can have multiple usages and the app populates the content on runtime
         * The different usages are mapped to sub pages.
         */
        return _.get(widgetData, ['componentFields', 'subPages'], [])
    }
    return []
}

export default {
    getSubPages
}
