import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Experiment} from '@wix/document-services-types'

const experimentName = 'dm_fixBrokenStylableMenuSubSubStyles'
const shmType = 'wixui.StylableHorizontalMenu'

const HEADING_SELECTOR = '.root::columnsLayout::heading'
const START_COMMENT = '/* ADD_MISSING_HEADING_STYLES_DATA_FIXER start */'
const END_COMMENT = '/* ADD_MISSING_HEADING_STYLES_DATA_FIXER end */'

const HEADING_STYLES = `
${START_COMMENT}
${HEADING_SELECTOR}{
  padding-top:10px;
  padding-bottom:10px;
  padding-left:10px;
  padding-right:10px
  background-color: rgba(255, 255, 255, 0.5);
}
${HEADING_SELECTOR}::label{
  font-size:16px;
  font-weight: bold;
  font-family: Helvetica, sans-serif;
  color: #000;
}
${HEADING_SELECTOR}:isHovered::label{
  text-decoration: underline;
}
${HEADING_SELECTOR}:isCurrentPage::label{
  text-decoration: underline;
}
${END_COMMENT}`

const cssPath = 'style.properties["$st-css"]'
const getCss = (style: any): string => _.get(style, cssPath)
const setCss = (style: any, css: string): void => _.set(_.cloneDeep(style), cssPath, css)

export const migratePage = (dm: DocumentManager, pageId: string, experimentInstance: Experiment) => {
    if (!experimentInstance.isOpen(experimentName)) {
        return
    }

    const shmStylePointers = dm.pointers.data.getStyleItemsWithPredicate(
        ({componentClassName}) => componentClassName === shmType,
        pageId
    )
    const shmStyles = shmStylePointers.map(pointer => ({
        pointer,
        style: dm.dal.get(pointer)
    }))
    const shmStylesToFix = shmStyles.filter(({style}) => !getCss(style).includes(HEADING_SELECTOR))

    shmStylesToFix.forEach(({pointer, style}) => {
        const css = getCss(style)
        const fixedCss = css + HEADING_STYLES
        const updatedStyle = setCss(style, fixedCss)
        dm.dal.set(pointer, updatedStyle)
    })
}

export const name = 'fixBrokenStylableMenuSubSubStyles'
export const version = 0
export const experimentalVersions = [{version: 1, experiment: experimentName}]
