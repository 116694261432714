import _ from 'lodash'
import * as componentDefinitionsMap from '../originalSchemas/componentsDefinitionsMap.json'

const aliases = _(componentDefinitionsMap)
    .pickBy('aliases')
    .mapValues('aliases')
    .reduce((aliasMap, compAliases, compType) => {
        _.forEach(compAliases, alias => {
            aliasMap[alias] = compType
        })
        return aliasMap
    }, {})

export function getAlias(compType: string) {
    return aliases[compType] || compType
}
