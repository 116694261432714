import {
    CreateExtArgs,
    Extension,
    ExtensionAPI,
    DeepFunctionMap,
    pointerUtils,
    CreateExtensionArgument
} from '@wix/document-manager-core'
import type {Pointer, PatternItem, CompRef} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from './dataModel'
import {
    buildOldInflatedId,
    getInflatedPointer,
    getItemAndRepeaterFromScope,
    getRepeatersInScope,
    isRepeaterScope
} from '../utils/scopesUtils'
import {DATA_TYPES} from '../constants/constants'
import {getIdFromRef} from '../utils/dataUtils'
import type {ViewerExtensionAPI} from './viewer/viewerExtension'

const {getPointer} = pointerUtils

export interface PatternsAPI extends DeepFunctionMap {
    getPatternVariants(repeaterIds: RepeaterItemPosition[]): Pointer[]
    getRepeaterIndicesForItem(compPointer: Pointer, itemPositionsFromViewer?: number[]): RepeaterItemPosition[]
    getVariantsForItem(itemPointer: Pointer): Pointer[]
}

export interface PatternsExtensionAPI extends ExtensionAPI {
    patterns: PatternsAPI
}

const constants = {
    PATTERNS_POINTER_TYPE: 'pattern',
    PATTERNS_EXTENSION_NAME: 'patterns'
}

export interface RepeaterItemPosition {
    repeaterPointer: Pointer
    itemPosition: number
}

const createExtension = ({dsConfig}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = ({extensionAPI, dal}: CreateExtArgs): PatternsExtensionAPI => {
        const dataModel = () => (extensionAPI as DataModelExtensionAPI).dataModel

        const getRepeaterIndicesForItemWithoutItemPositions = (compPointer: Pointer) => {
            const scopePointer = compPointer.scope!
            if (!isRepeaterScope(scopePointer) || scopePointer.scope) {
                throw Error('Repeaters in widgets and repeaters in repeaters are not yet supported ') //DM-7223
            }

            const {repeaterPointer, item} = getItemAndRepeaterFromScope(scopePointer, compPointer.type)
            const repeater = dal.get(repeaterPointer)

            if (repeater.connectionQuery) {
                throw Error('Dynamic repeaters are not yet supported') //DM-7219
            }

            const data = dataModel().components.getItem(repeaterPointer, DATA_TYPES.data)
            const itemId = data.items.indexOf(item)
            return [{repeaterPointer, itemPosition: itemId + 1}]
        }

        const getRepeaterIndicesForItem = (compPointer: Pointer, itemPositions?: number[]): RepeaterItemPosition[] => {
            const repeaters = getRepeatersInScope(compPointer)

            if (repeaters.length === 0) {
                return []
            }

            if (itemPositions && repeaters.length === itemPositions.length) {
                return repeaters.map((repeaterPointer, index) => ({
                    repeaterPointer,
                    itemPosition: itemPositions[index]
                }))
            }
            if (itemPositions && itemPositions.length > 0) {
                throw Error('The repeater count does not match the item position count')
            }
            return getRepeaterIndicesForItemWithoutItemPositions(compPointer)
        }

        const getPatternVariants = (repeaterIds: RepeaterItemPosition[]): Pointer[] => {
            const result: Pointer[] = []
            repeaterIds.forEach(({repeaterPointer, itemPosition}) => {
                const pattern = dataModel().components.getItem(repeaterPointer, DATA_TYPES.patterns)
                if (pattern) {
                    pattern.items?.forEach((item: PatternItem) => {
                        if (item.type === 'NthChild' && (item.offset + itemPosition - 1) % item.repeat === 0) {
                            result.push(getPointer(getIdFromRef(item.variant), DATA_TYPES.variants))
                        }
                    })
                }
            })

            return result
        }

        const getVariantsForItem = (itemPointer: Pointer): Pointer[] => {
            const {
                viewer: {convertIdToScopedPointer, getRepeaterItemsIndexesById}
            } = extensionAPI as ViewerExtensionAPI

            itemPointer = convertIdToScopedPointer(
                buildOldInflatedId(itemPointer, dsConfig.enableRepeatersInScopes),
                true
            )

            const inflatedId = getInflatedPointer(itemPointer as CompRef, true).id
            const itemPositions = getRepeaterItemsIndexesById(inflatedId)
            const repeaterItems = getRepeaterIndicesForItem(itemPointer, itemPositions)

            return getPatternVariants(repeaterItems)
        }

        return {
            patterns: {
                getVariantsForItem,
                getRepeaterIndicesForItem,
                getPatternVariants
            }
        }
    }

    return {
        name: constants.PATTERNS_EXTENSION_NAME,
        createExtensionAPI
    }
}

export {createExtension}
