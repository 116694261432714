import savePublishUtils from './savePublishUtils'
import appBuilderUtils from '../utils/appBuilder'

const TASK_NAME = 'appbuilder'

const saveAll = (lastSavedData, currentData, resolve, reject, biCallbacks) =>
    savePublishUtils.saveAll(
        () => appBuilderUtils.getAppInstance(currentData),
        lastSavedData,
        currentData,
        resolve,
        reject,
        biCallbacks
    )

export default {
    partialSave: saveAll,

    fullSave: saveAll,

    firstSave(lastSavedData, currentData, resolve, reject, biCallbacks) {
        const lastInstanceId = appBuilderUtils.getAppInstanceIdFromImmutable(lastSavedData)

        const currentInstanceId = appBuilderUtils.getAppInstanceIdFromImmutable(currentData)
        if (lastInstanceId && lastInstanceId === currentInstanceId) {
            reject({
                errorCode: 1970,
                errorDescription: 'application instance id is the template id',
                changes: null
            })
        } else {
            saveAll(lastSavedData, currentData, resolve, reject, biCallbacks)
        }
    },

    saveAsTemplate(lastSavedData, currentData, resolve) {
        resolve()
    },

    autosave: saveAll,

    publish(currentData, resolve, reject, biCallbacks) {
        savePublishUtils.publish(
            () => appBuilderUtils.getAppInstance(currentData),
            currentData,
            resolve,
            reject,
            biCallbacks
        )
    },

    getTaskName() {
        return TASK_NAME
    },

    getSnapshotTags() {
        return ['primary']
    }
}
