import _ from 'lodash'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'
import DataAccessPointers from './DataAccessPointers'

const type = 'platform'
const semanticAppVersionsPointerType = 'semanticAppVersions'
const appsStatePointerType = 'appsState'

const pointers = new DataAccessPointers()

pointerGeneratorsRegistry.registerPointerType(type, _.constant(null), _.constant(true))
pointerGeneratorsRegistry.registerPointerType(semanticAppVersionsPointerType, _.constant(null), _.constant(true))
pointerGeneratorsRegistry.registerPointerType(appsStatePointerType, _.constant(null), _.constant(true))

function getPlatformPointer(getItemAt, cache) {
    return cache.getPointer('platform', type, ['platform'])
}

function getAppStatePointer(getItemAt, cache) {
    const platformPointer = getPlatformPointer(getItemAt, cache)
    return pointers.getInnerPointer(platformPointer, 'appState')
}

function getControllerStatePointer(getItemAt, cache, controllerId) {
    return cache.getPointer(`${controllerId} state`, type, ['platform', 'appState', controllerId])
}

function getAppManifestPointer(getItemAt, cache, appId) {
    return cache.getPointer(`appManifest ${appId}`, type, ['platform', 'appManifest', appId])
}

function appPublicApiNamePointer(getItemAt, cache, appId) {
    return cache.getPointer(`appPublicApiName ${appId}`, type, ['platform', 'appPublicApiName', appId])
}
function getControllerStageDataPointer(getItemAt, cache, appId, controllerType, controllerState) {
    return cache.getPointer(`controllerStageData ${appId}${controllerType}${controllerState}`, type, [
        'platform',
        'appManifest',
        appId,
        'controllersStageData',
        controllerType,
        controllerState
    ])
}

function getControllerRolePointer(getItemAt, cache, appId, controllerType, controllerState, role) {
    return cache.getPointer(`controllerRole ${appId}${controllerType}${controllerState}${role}`, type, [
        'platform',
        'appManifest',
        appId,
        'controllersStageData',
        controllerType,
        controllerState,
        'connections',
        role
    ])
}

function getPagesPlatformApplicationsPointer(getItemAt, cache) {
    return cache.getPointer('pagesPlatformApplications', type, ['pagesPlatformApplications'])
}

function getPagesPlatformApplicationPointer(getItemAt, cache, appId) {
    return cache.getPointer(`${appId} pages`, type, ['pagesPlatformApplications', appId])
}

function getSemanticAppVersionPointer(getItemAt, cache, applicationId) {
    return cache.getPointer(`semanticAppVersionPointer ${applicationId}`, semanticAppVersionsPointerType, [
        'semanticAppVersions',
        applicationId
    ])
}

function getSemanticAppVersionsPointer(getItemAt, cache) {
    return cache.getPointer('semanticAppVersionPointer', semanticAppVersionsPointerType, ['semanticAppVersions'])
}

function getAppInstallationStatePointer(getItemAt, cache, appDefinitionId) {
    return cache.getPointer(`appsState ${appDefinitionId}`, appsStatePointerType, ['appsState', appDefinitionId])
}

function getAppsInstallationStatePointer(getItemAt, cache) {
    return cache.getPointer('appsState', appsStatePointerType, ['appsState'])
}

const getterFunctions = Object.freeze({
    getPlatformPointer,
    getAppStatePointer,
    getControllerStatePointer,
    getControllerStageDataPointer,
    getAppManifestPointer,
    appPublicApiNamePointer,
    getPagesPlatformApplicationsPointer,
    getPagesPlatformApplicationPointer,
    getControllerRolePointer,
    getSemanticAppVersionPointer,
    getSemanticAppVersionsPointer,
    getAppInstallationStatePointer,
    getAppsInstallationStatePointer
})

pointerGeneratorsRegistry.registerDataAccessPointersGenerator(type, getterFunctions)
