import _ from 'lodash'
import clientSpecMapService from './clientSpecMapService'
import tpaUtils from '../utils/tpaUtils'

const getPageAppIds = visitableData => {
    const allAppIds = new Set()
    const tpaAppIds = new Set()
    visitableData.accept((get, data) => {
        if (tpaUtils.isTpaByDataType(get(data, 'type'))) {
            tpaAppIds.add(get(data, 'applicationId'))
        }

        ;['appId', 'applicationId', 'appDefinitionId', 'appDefId'].forEach(id => allAppIds.add(get(data, id)))
    })
    return {
        tpaAppIds: _.compact(Array.from(tpaAppIds)),
        allAppIds: _(Array.from(allAppIds)).compact().keyBy().value()
    }
}

const appHasRouter = function ({appDefinitionId}, routerConfigMap) {
    return _.some(routerConfigMap, {appDefinitionId})
}

const isPlatformAppActive = function (app, allAppIds, routerConfigMap) {
    return (
        clientSpecMapService.hasEditorPlatformPart(app) &&
        (appHasRouter(app, routerConfigMap) ||
            allAppIds.hasOwnProperty(app.applicationId) ||
            allAppIds.hasOwnProperty(app.appDefinitionId) ||
            clientSpecMapService.isDashboardAppOnly(app))
    )
}

const getInstalledPlatformApps = function (clientSpecMap, routerConfigMap, allAppIds) {
    const retVal = _.reduce(
        clientSpecMap,
        function (platformApps, app, appId) {
            if (isPlatformAppActive(app, allAppIds, routerConfigMap)) {
                platformApps.add(appId)
            }
            return platformApps
        },
        new Set()
    )

    return Array.from(retVal)
}

const getAllAppIdsInstalledOnPages = (visitableData, clientSpecMap?, routerConfigMap?) => {
    const {tpaAppIds, allAppIds} = getPageAppIds(visitableData)
    const installedPlatformApps = getInstalledPlatformApps(clientSpecMap, routerConfigMap, allAppIds)
    return _.union(tpaAppIds, installedPlatformApps)
}

export default {
    getAllAppIdsInstalledOnPages,
    isPlatformAppActive: (app, visitableData, routerConfigMap) =>
        isPlatformAppActive(app, getPageAppIds(visitableData).allAppIds, routerConfigMap)
}
