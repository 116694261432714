import wixCode from 'wixCode'
import type {PS} from '@wix/document-services-types'

const {filePathAffectsBundles, filePathAffectsSingleBundle, bundleIdFromFilePath} = wixCode.wixCodeUserScriptsService

function init(ps: PS) {
    reset(ps)
}

function reset(ps) {
    clearBundleCacheKillerMap(ps)
    updateCacheKillerValue(ps, ps.pointers.wixCode.getGlobalBundleCacheKiller())
}

function notifyPathModified(ps: PS, path) {
    if (filePathAffectsSingleBundle(path)) {
        const bundleId = bundleIdFromFilePath(path)
        const bundleCacheKillerPointer = ps.pointers.wixCode.getBundleCacheKiller(bundleId)
        updateCacheKillerValue(ps, bundleCacheKillerPointer)
    } else if (filePathAffectsBundles(path)) {
        reset(ps)
    }
}

function getNewCacheKillerValue(existingValue) {
    const suggestedValue = Date.now().toString()
    return suggestedValue === existingValue ? `${suggestedValue}_1` : suggestedValue
}

function updateCacheKillerValue(ps: PS, pointer) {
    const currentVal = ps.dal.get(pointer)
    const newValue = getNewCacheKillerValue(currentVal)

    ps.dal.set(pointer, newValue)
}

function clearBundleCacheKillerMap(ps: PS) {
    ps.dal.set(ps.pointers.wixCode.getBundleCacheKillerMap(), {})
}

export default {
    init,
    getNewCacheKillerValue,
    notifyPathModified
}
