import _ from 'lodash'
import experiment from 'experiment-amd'
import wixCodeUtils from '../utils/utils'
import wixCodeConstants from '../utils/constants'
import type {PS} from '@wix/document-services-types'

const wixCodeProvisionUrlTemplate = _.template('<%= baseUrl %>/api/code/apps?msid=<%= metasiteId %>')
const wixCodeNewProvisionUrlTemplate = _.template(
    '<%= baseUrl %>/api/code/apps?msid=<%= metasiteId %>&ce=true<%= branchId ? `&branchId=${branchId}` : "" %>'
)
const wixCodeCloneAppUrlTemplate = _.template(
    '<%= baseUrl %>/api/code/apps/<%= gridAppId %>/clone?msid=<%= metasiteId %>&isFirstSave=<%= isFirstSave %>'
)
const wixCodeCloneImmutableAppUrlTemplate = _.template('<%= baseUrl %>/v1/apps')
const wixCodeSaveUrlTemplate = _.template('<%= baseUrl %>/api/code/apps/<%= gridAppId %>/save?msid=<%= metasiteId %>')

const _createHeaders = ({signedInstance, accept, withAutorization = false}) =>
    _.pickBy({
        [withAutorization ? 'Authorization' : 'X-Wix-Si']: signedInstance,
        Accept: accept
    })

const _createRequest = ({url, data, signedInstance, accept, withAutorization = false}) =>
    _.assign({
        url,
        type: wixCodeUtils.requestTypes.POST,
        contentType: 'application/json',
        data,
        headers: _createHeaders({signedInstance, accept, withAutorization}),
        timeout: 15000,
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true
    })

function getBaseUrlFromPS(ps: PS) {
    const pointer = ps.pointers.general.getServiceTopology()
    return ps.dal.get(ps.pointers.getInnerPointer(pointer, wixCodeConstants.WIX_CODE_SERVICE_URL_KEY))
}

const create = async ({baseUrl, metasiteId, signedInstance, branchId}) => {
    const url = experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')
        ? wixCodeNewProvisionUrlTemplate({baseUrl, metasiteId, branchId})
        : wixCodeProvisionUrlTemplate({baseUrl, metasiteId})

    // @ts-expect-error
    const request = _createRequest({url, data: {}, signedInstance})
    const response = await wixCodeUtils.sendRequestObj(request)
    return response.payload
}

const save = ({baseUrl, gridAppId, metasiteId, signedInstance}) => {
    const url = wixCodeSaveUrlTemplate({baseUrl, gridAppId, metasiteId})
    // @ts-expect-error
    const request = _createRequest({url, data: {}, signedInstance})
    return wixCodeUtils.sendRequestObj(request)
}

const clone = async ({baseUrl, gridAppId, metasiteId, signedInstance, isFirstSave}) => {
    const url = wixCodeCloneAppUrlTemplate({baseUrl, gridAppId, metasiteId, isFirstSave})
    const accept = '*/*'
    const request = _createRequest({url, data: {}, signedInstance, accept})
    const response = await wixCodeUtils.sendRequestObj(request)
    return response.payload
}

const cloneImmutable = async ({baseUrl, gridAppId, signedInstance}) => {
    const url = wixCodeCloneImmutableAppUrlTemplate({baseUrl})
    const request = _createRequest({
        withAutorization: true,
        url,
        data: {
            app: {
                sourceAppId: gridAppId,
                mutable: false
            }
        },
        signedInstance,
        accept: '*/*'
    })
    const response = await wixCodeUtils.sendRequestObj(request)
    return {newGridAppId: response.app.id}
}

export default {
    create,
    save,
    clone,
    getBaseUrlFromPS,
    cloneImmutable
}
