import dataModel from '../../dataModel/dataModel'
import constants from '../../constants/constants'

const resizeOptions = [
    constants.RESIZE_SIDES.TOP,
    constants.RESIZE_SIDES.LEFT,
    constants.RESIZE_SIDES.BOTTOM,
    constants.RESIZE_SIDES.RIGHT
]

export default {
    isRepeatable: false,
    mobileConversionConfig: {
        hideByDefault: true
    },
    resizableSides(ps, compPointer) {
        const {displayMode} = dataModel.getPropertiesItem(ps, compPointer)

        if (displayMode === 'original') {
            return []
        }

        return resizeOptions
    }
}
