import _ from 'lodash'
import type {FetchFn} from '../../utils/fetchUtils'

const ensureRepoFields = (repo: any) => {
    repo = repo || {}
    repo.views = repo.views || {}
    repo.types = repo.types || {}
    repo.dataSelectors = repo.dataSelectors || {}
    repo.parts = repo.parts || {}
    return repo
}

function getViewId(type: string, name: string, format: string) {
    return _.compact([type, name, format]).join('|')
}

/**
 * Convert the AppRpo that return from the server to the one we work with in the client.
 * @param repo
 * @returns {*}
 */
const transformAppRepo = (repo: any) => {
    repo = ensureRepoFields(repo)
    const views = _.transform(
        repo.views,
        (result, viewDef: {forType: any; name: any; format: any}) => {
            const id = getViewId(viewDef.forType, viewDef.name, viewDef.format)
            result[id] = viewDef
        },
        {}
    )
    const dataSelectors = _.transform(
        repo.dataSelectors,
        (result, dataSelectorDef: any) => {
            result[dataSelectorDef.id] = dataSelectorDef
        },
        {}
    )
    const types = _.transform(
        repo.types,
        (result, typeDef: any) => {
            result[typeDef.name] = typeDef
        },
        {}
    )

    return _.defaults(
        {
            views,
            dataSelectors,
            types,
            offsetFromServerTime: 666
        },
        repo
    )
}

const getRepoUrl = (instanceId = 'WIXAPPS_APPBUILDER_INSTANCE_ID', isFirstSave = false) =>
    `/apps/appBuilder/${isFirstSave ? 'published' : 'saved'}/${instanceId}`
const fetchWixappsRepo = async (applicationInstanceId: string, isFirstSave: boolean, fetchFn: Function) => {
    const response = await fetchFn(getRepoUrl(applicationInstanceId, isFirstSave))
    const jsonData = await response.json()
    if (!jsonData.success) {
        throw new Error(`failed to fetch repo: ${jsonData}`)
    }

    const repo = jsonData.payload
    const transformedRepo = transformAppRepo(repo)
    return transformedRepo
}

const DATA_URL = '/apps/appBuilder/1/editor/Query?consistentRead=true'
const fetchAllPartDataForType = async (
    partType: string,
    applicationInstanceId: string,
    applicationInstanceVersion: string,
    fetchFn: FetchFn
) => {
    const data = {
        filter: {},
        type: partType,
        applicationInstanceId,
        applicationInstanceVersion,
        skip: 0,
        sort: {},
        limit: null as any
    }
    const response = await fetchFn(DATA_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    const jsonData = await response.json()
    if (!jsonData.success) {
        throw new Error(`failed to fetch appParts data: ${jsonData}`)
    }

    const transformedItems = _.keyBy(jsonData.payload.items, '_iid')
    return transformedItems
}

const REPO_PATH = ['wixapps', 'appbuilder', 'descriptor']
const METADATA_PATH = ['wixapps', 'appbuilder', 'metadata']
const ITEMS_PATH = ['wixapps', 'appbuilder', 'items']
const loadWixappsComponentsData = async (
    wixappsDAL: any,
    appPartNames: string[],
    applicationInstanceId: string,
    fetchFn: FetchFn
) => {
    const applicationInstanceVersion = wixappsDAL.getByPath([...REPO_PATH, 'applicationInstanceVersion'])
    return (
        _(appPartNames) //eslint-disable-line lodash/prefer-invoke-map
            .groupBy(appPartName => wixappsDAL.getByPath([...REPO_PATH, 'parts', appPartName, 'type']))
            .map(async (parts, partType) => {
                const items = await fetchAllPartDataForType(
                    partType,
                    applicationInstanceId,
                    applicationInstanceVersion,
                    fetchFn
                )
                wixappsDAL.setByPath([...ITEMS_PATH, partType], items)
            })
            // To prevent any race-conditions with initial snapshot if a single request should fail, we catch them individually.
            // This ensures the Promise.all will wait for them all to resolve.
            .map(promise => promise.catch(e => console.error(e)))
            .thru(v => Promise.all(v))
            .value()
    )
}

const markAppPartsAsLoaded = (wixappsDAL: any) => {
    const appPartNames = wixappsDAL.getKeysByPath([...REPO_PATH, 'parts'])
    appPartNames.forEach((partName: string) => {
        wixappsDAL.setByPath([...METADATA_PATH, partName], {loading: false})
    })
    wixappsDAL.setByPath([...METADATA_PATH, 'appbuilder_metadata'], {loading: false})
}

const handleErroneousAppParts = (wixappsDAL: any, appPartNames: string[]) => {
    const {true: goodAppParts = [], false: badAppParts = []} = _.groupBy(appPartNames, partName =>
        wixappsDAL.isPathExist([...REPO_PATH, 'parts', partName])
    )
    badAppParts.forEach(partName => {
        wixappsDAL.setByPath([...METADATA_PATH, partName], {error: true, loading: false})
    })
    return goodAppParts
}

const loadWixappsData = async (
    wixappsDAL: any,
    appPartNames: string[],
    applicationInstanceId: string,
    {isFirstSave}: {isFirstSave: boolean},
    fetchFn: FetchFn
) => {
    try {
        const repo = await fetchWixappsRepo(applicationInstanceId, isFirstSave, fetchFn)
        wixappsDAL.setByPath(REPO_PATH, repo)
    } catch (e) {
        console.error(e)
    }

    appPartNames = handleErroneousAppParts(wixappsDAL, appPartNames)
    try {
        await loadWixappsComponentsData(wixappsDAL, appPartNames, applicationInstanceId, fetchFn)
    } catch (e) {
        console.error(e)
    }

    markAppPartsAsLoaded(wixappsDAL)
}

export {loadWixappsData}
