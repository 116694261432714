import type {CreateExtensionArgument, DalValue, Extension, ValidateValue} from '@wix/document-manager-core'
import * as documentServicesJsonSchemas from '@wix/document-services-json-schemas'
import type {Pointer} from '@wix/document-services-types'
import {ReportableError} from '@wix/document-manager-utils'

const createExtension = ({logger}: CreateExtensionArgument): Extension => {
    const createValidator = (): Record<string, ValidateValue> => {
        const externalLinkUrlValidator = documentServicesJsonSchemas.schemaUtils.getExternalLinkSchemaValidators()

        return {
            checkValidExternalLink: (pointer: Pointer, value: DalValue) => {
                if (value?.type === 'ExternalLink') {
                    const validateByFormat = externalLinkUrlValidator.validate('externalLinkUrlFormatSchema', value.url)
                    const validateByRegex = externalLinkUrlValidator.validate('externalLinkUrlRegexSchema', value.url)
                    if (!validateByFormat || !validateByRegex) {
                        logger.captureError(
                            new ReportableError({
                                errorType: 'ExternalLinkInvalidUrl',
                                extras: {validateByFormat, validateByRegex, url: value.url},
                                message: 'ExternalLink has invalid url'
                            })
                        )
                    }
                }
            }
        }
    }

    return {
        name: 'schemaValidator',
        createValidator
    }
}

export {createExtension}
