import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import hooks from '../hooks/hooks'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import dataSerialization from '../dataModel/dataSerialization'
import isSystemStyle from '../theme/isSystemStyle'
import * as dmUtils from '@wix/document-manager-utils'
import type {
    CompRef,
    Pointer,
    PS,
    RefArray,
    RemoveScopedAndNonScopedDataHandlers,
    VariantRelation
} from '@wix/document-services-types'

const {
    DATA_TYPES,
    VARIANTS: {VALID_VARIANTS_DATA_TYPES},
    DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT
} = constants

const {ReportableError} = dmUtils

/**
 * Returns true if the given VariantRelation variants matches ALL of the provided variant IDs, false otherwise.
 */
const exactMatchVariantRelationPredicateitem = (ps: PS, relation: VariantRelation, variants: string[]): boolean =>
    ps.extensionAPI.variants.exactMatchVariantRelationPredicateitem(relation, variants)

/**
 * connect the scopedValue id to relation, and will add relation and refArray if not exiting already
 *
 * @param {ps} ps
 * @param compRef
 * @param {string} scopedValueId
 * @param {string} itemType
 * @param {Object} refArrayOrDataItem
 * @param {string []} variants
 * @param {string} pageId
 * @returns {string} returns refArray Id
 */
const addScopedValueToRelation = (
    ps: PS,
    compRef: CompRef,
    scopedValueId: string,
    itemType: string,
    refArrayOrDataItem,
    variants: string[],
    pageId: string
): string => {
    const compIdToAdd = displayedOnlyStructureUtil.getRepeaterTemplateId(compRef.id)
    const variantRelation = dataModel.variantRelation.create(ps, variants, compIdToAdd, scopedValueId)
    const relId = dataSerialization.addDeserializedItemToPage(ps, pageId, itemType, variantRelation)

    if (dataModel.refArray.isRefArray(ps, refArrayOrDataItem)) {
        const newValues = [...dataModel.refArray.extractValues(ps, refArrayOrDataItem), `#${relId}`]

        const newValuesAfterHook = hooks.executeHookAndUpdateValue(
            ps,
            hooks.HOOKS.SET_SCOPED_VALUE.BEFORE,
            undefined,
            [compRef, itemType, pageId, relId, refArrayOrDataItem.id],
            newValues
        )

        const refArrayPointer = ps.pointers.data.getItem(itemType, refArrayOrDataItem.id, pageId)
        ps.dal.set(ps.pointers.getInnerPointer(refArrayPointer, 'values'), newValuesAfterHook)
        return refArrayOrDataItem.id
    }

    const refValues = _.get(refArrayOrDataItem, 'id') ? [refArrayOrDataItem.id, relId] : [relId]
    const refArr = dataModel.refArray.create(ps, refValues)
    return dataSerialization.addDeserializedItemToPage(ps, pageId, itemType, refArr)
}

/**
 * finds the scoped value that been referenced by _relationPointer
 * in case of not passing _relationPointer the func search for relation that holds the same variants that is passed to func (full comparison)
 * @param ps
 * @param namespace
 * @param refArray
 * @param variants
 * @param pageId
 * @param _relationPointer - this is optional when passing its skips the part of finding the variant relation
 * @returns {Pointer | undefined} returns scoped value pointer
 */
const getScopedValuePointerByVariants = (
    ps: PS,
    namespace: string,
    refArray: RefArray,
    variants: string[],
    pageId: string,
    _relationPointer: Pointer
) => {
    return ps.extensionAPI.variants.getConditionalValuePointerByVariants(
        namespace,
        refArray,
        variants,
        pageId,
        _relationPointer
    )
}

/**
 * get scoped data pointer from a variant relation pointer
 * @param ps
 * @param namespace
 * @param relationPointer
 * @returns {Pointer | undefined} returns scoped value pointer
 */
const getScopedValueByRelationPtr = (ps: PS, namespace: string, relationPointer: Pointer) => {
    return ps.extensionAPI.variants.getConditionalValueByRelationPtr(namespace, relationPointer)
}

/**
 * search in the schema that corresponds to itemType for relation that holds the same variants that is passed to func (full comparison)
 * @param ps
 * @param itemType
 * @param refArray
 * @param variants
 * @param pageId
 * @returns {DataItem | null} returns relation
 */
const getRelationPointerFromRefArrayByVariants = (ps: PS, itemType, refArray: RefArray, variants, pageId: string) => {
    return ps.extensionAPI.variants.getRelationPointerFromRefArrayByVariants(itemType.refArray, variants, pageId)
}

/**
 * search in the schema that corresponds to itemType for non scooped value item in the refArray
 * @param ps
 * @param itemType
 * @param refArray
 * @param pageId
 * @returns {DataItem | null} returns relation
 */
const nonScopedValuePointer = (ps: PS, itemType: string, refArray: RefArray, pageId: string) => {
    return ps.extensionAPI.variants.getDefaultValuePointer(itemType, refArray, pageId)
}

const removeScopedValue = (ps: PS, scopedValueId: string, itemType: string, pageId: string) => {
    const scopedValuePointer = ps.pointers.data.getItem(itemType, scopedValueId, pageId)
    dataModel.removeItemRecursivelyByType(ps, scopedValuePointer)
}

const getComponentFromRelation = (ps: PS, relation, pageId: string) => {
    const compId = dataModel.variantRelation.extractFrom(ps, relation)
    const pagePointer = ps.pointers.components.getPage(pageId, ps.siteAPI.getViewMode())
    return ps.pointers.full.components.getComponent(compId, pagePointer)
}

/**
 * getting the default handlers for removing relation from RefArray
 * @param ps
 * @param {Object} relation
 * @param itemType
 * @param pageId
 * @returns handlers
 */
const getDefaultRemoveRelationFromRefArrayHandlers = (
    ps: PS,
    relation,
    itemType: string,
    pageId: string
): RemoveScopedAndNonScopedDataHandlers => {
    const pointer = getComponentFromRelation(ps, relation, pageId)
    return {
        refArrayOrValuePointerResolver: () => dataModel.getComponentDataPointerByType(ps, pointer, itemType),
        removeRefArrayFromReferenceResolver: () => dataModel.removeComponentDataByType(ps, pointer, itemType, true)
    }
}

/**
 * remove relation from the ref array
 * @param ps
 * @param {Object} relation
 * @param itemType
 * @param pageId
 * @param {RemoveScopedAndNonScopedDataHandlers | undefined} handlers
 */
const removeRelationFromRefArray = (
    ps: PS,
    relation,
    itemType: string,
    pageId: string,
    handlers: RemoveScopedAndNonScopedDataHandlers
) => {
    const {refArrayOrValuePointerResolver, removeRefArrayFromReferenceResolver} =
        handlers || getDefaultRemoveRelationFromRefArrayHandlers(ps, relation, itemType, pageId)
    const refArrayPointer = refArrayOrValuePointerResolver()
    if (!ps.dal.isExist(refArrayPointer)) {
        return
    }
    const refArrayData = ps.dal.get(refArrayPointer)
    const newValues = _.without(dataModel.refArray.extractValues(ps, refArrayData), `#${_.get(relation, 'id')}`)
    if (!_.isEmpty(newValues)) {
        ps.dal.set(ps.pointers.getInnerPointer(refArrayPointer, 'values'), newValues)
    } else {
        removeRefArrayFromReferenceResolver()
    }
}

const removeRelation = (
    ps: PS,
    relationPointer: Pointer,
    itemType: string,
    pageId: string,
    handlers: RemoveScopedAndNonScopedDataHandlers = undefined
) => {
    const relation = ps.dal.get(relationPointer)
    const scopedValueId = dataModel.variantRelation.extractTo(ps, relation)
    const shouldRemoveScopedDataItem = itemType !== DATA_TYPES.theme || !isSystemStyle(scopedValueId)
    if (shouldRemoveScopedDataItem) {
        removeScopedValue(ps, scopedValueId, itemType, pageId)
    }

    removeRelationFromRefArray(ps, relation, itemType, pageId, handlers)
    ps.dal.remove(relationPointer)
}

/**
 * filter given relations that contains exactly the given variants
 * @param ps
 * @param variantRelationsPointers
 * @param variants
 * @returns filteredVariantsPointers
 */
const filterRelationsWithExactVariants = (ps: PS, variantRelationsPointers: Pointer[], variants: string[]): Pointer[] =>
    _.filter(variantRelationsPointers, relationPointer => {
        const relation = ps.dal.get(relationPointer)
        return exactMatchVariantRelationPredicateitem(ps, relation, variants)
    })

/**
 * get the relations that will be deleted according to the following cases:
 * 1. single variant - we will delete all relations that contains the variant (may contain more)
 * 2. multiple variants - we will delete only the relations that contains exactly the variants.
 * @param ps
 * @param variantsPointers
 * @param itemDataType
 * @returns relationsPointers
 */
const getRelationsToDelete = (ps: PS, variantsPointers: Pointer[], itemDataType: string): Pointer[] => {
    const variantsIds = _.map(variantsPointers, 'id')
    const shouldDeleteAllRelationsWithVariant = variantsPointers.length === 1

    const relationsToDeleteByVariantId = variantId => {
        const variantRelationsPointers = ps.pointers.data.getVariantRelations(itemDataType, variantId)
        return shouldDeleteAllRelationsWithVariant
            ? variantRelationsPointers
            : filterRelationsWithExactVariants(ps, variantRelationsPointers, variantsIds)
    }

    return _(variantsIds).map(relationsToDeleteByVariantId).flatMap().value()
}

/**
 * Validates the variants array if more than one variant was provided. Throws error if there was a validation issue
 * @param ps
 * @param variantPointers
 */
const validateVariants = (ps: PS, variantPointers: Pointer[]) => {
    const variantsData = variantPointers.map(ps.dal.get)
    if (_.uniqBy(variantsData, 'type').length !== variantsData.length) {
        throw new ReportableError({
            message: 'Invalid variants array provided, please ensure that no more than one variant type is present',
            errorType: 'variantValidation'
        })
    }
}

/**
 * remove the variants according to the following algorithm:
 * 1. for each schema looks for the relations that includes the variants and remove them
 * 2. for each removed relation looks for the ref array and removes the ref to relation from the refArray
 * 3. remove all variants
 * @param {ps} ps
 * @param {Pointer[]} variantsPointers
 */
const removeScopedValuesByVariants = (ps: PS, variantsPointers: Pointer[]) => {
    const pageId = ps.pointers.data.getPageIdOfData(_.head(variantsPointers))
    _.forEach(VALID_VARIANTS_DATA_TYPES, itemDataType => {
        const variantRelationsPointersToBeDeleted = getRelationsToDelete(ps, variantsPointers, itemDataType)

        _.forEach(variantRelationsPointersToBeDeleted, relationPointer => {
            let handlers
            if (DATA_TYPES_SUPPORT_VARIANTS_BUT_NOT_SCOPED_ON_ROOT[itemDataType]) {
                handlers = {
                    refArrayOrValuePointerResolver: () =>
                        ps.extensionAPI.relationships.getOwningReferencesToPointer(relationPointer, itemDataType)[0],
                    removeRefArrayFromReferenceResolver: _.noop
                }
            }

            removeRelation(ps, relationPointer, itemDataType, pageId, handlers)
        })
    })

    // in case of multiple variants GC will remove variants when there no relation referring to variants
    if (variantsPointers.length === 1) {
        ps.dal.remove(_.head(variantsPointers))
    } else if (variantsPointers.length > 1) {
        validateVariants(ps, variantsPointers)
    }
}

/**
 * search in the schema that corresponds to itemType for relations that holds the same variants that is passed to func (full comparison)
 * predicate is optional when no predicate all relations that matches to variants exactly are returned
 * @param ps
 * @param variantsPointers Ids
 * @param itemType
 * @param shouldMatchExactly
 * @param predicate
 * @returns returns comp ids
 */
const getRelationsByVariantsAndPredicate = (
    ps: PS,
    variantsPointers: Pointer[],
    itemType: string,
    shouldMatchExactly: boolean = true,
    predicate: (a: any) => boolean = _.identity
): Pointer[] | null => {
    const variants = _.map(variantsPointers, 'id')

    return _.reduce(
        variants,
        (result, variantId) => {
            const variantRelationsPointers = ps.pointers.data.getVariantRelations(itemType, variantId)

            const filteredRelationsPointers = _.filter(variantRelationsPointers, relationPointer => {
                const relation = ps.dal.get(relationPointer)

                return (
                    (shouldMatchExactly ? exactMatchVariantRelationPredicateitem(ps, relation, variants) : true) &&
                    predicate(relation)
                )
            })

            return _.unionBy(result, filteredRelationsPointers, 'id')
        },
        []
    )
}

/**
 * remove all illegal relations from ref array + it scoped value.
 * illegal relation is relation with variant that no in validVariantsIds list
 * if the func deletes all refArray values it will delete the refArray as well
 *
 * @param ps privateServices
 * @param componentPointer
 * @param dataType
 * @param validVariantIds
 * @param variantsReplacementOperations
 */
const removeIllegalRelationsFromRefArray = (
    ps: PS,
    componentPointer: Pointer,
    dataType: string,
    validVariantIds: string[],
    variantsReplacementOperations
) => {
    const componentPage = ps.pointers.full.components.getPageOfComponent(componentPointer)
    const dataPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, dataType)
    const compType = ps.dal.get(ps.pointers.getInnerPointer(componentPointer, 'componentType'))
    if (dataPointer) {
        const data = ps.dal.get(dataPointer)
        if (dataModel.refArray.isRefArray(ps, data)) {
            const relationIds = dataModel.refArray.extractValuesWithoutHash(ps, data)
            const relationPointers = relationIds.map(relationId =>
                ps.pointers.data.getItem(dataType, relationId, componentPage.id)
            )
            const variantRelationsToRemove = hooks.executeHookAndUpdateValue(
                ps,
                hooks.HOOKS.VARIANTS.RELATION_REMOVAL_BEFORE,
                compType,
                [relationPointers, componentPointer, validVariantIds, dataType, variantsReplacementOperations],
                []
            )

            for (const relationPointer of variantRelationsToRemove) {
                removeRelation(ps, relationPointer, dataType, componentPage.id)
            }
        }
    }
}

/**
 * get all variants from a component refArray > variantRelations
 *
 * @param ps privateServices
 * @param compPointer
 * @param dataType
 * @return {string []}
 */
const getCompVariantsFromRelations = (ps: PS, compPointer: Pointer, dataType: string): string[] => {
    const data = dataModel.getComponentDataItemByType(ps, compPointer, dataType)
    if (dataModel.refArray.isRefArray(ps, data)) {
        const variantsIdsFromRelations = _(data.values)
            .map('variants')
            .compact()
            .flatten()
            .map(id => _.replace(id, '#', ''))
            .value()

        return variantsIdsFromRelations
    }
    return []
}

const getAllAffectingVariants = (ps: PS, componentPointer: Pointer): Pointer[] => {
    const variantPointers = []

    for (const dataType of VALID_VARIANTS_DATA_TYPES) {
        variantPointers.push(...getAllAffectingVariantsForDataType(ps, componentPointer, dataType))
    }

    return variantPointers
}

/**
 * returns all variants pointers from a component refArray > variantRelations for all namespaces
 *
 * @param ps privateServices
 * @param componentPointer
 */
const getAllAffectingVariantsGroupedByVariantType = (ps: PS, componentPointer: Pointer) => {
    const variantPointers = getAllAffectingVariants(ps, componentPointer)
    const variantsAndVariantType = _.map(variantPointers, pointer => {
        const variantData = ps.dal.get(pointer)
        return {pointer, type: variantData.type}
    })

    return _(variantsAndVariantType)
        .uniqBy(item => item.pointer.id)
        .groupBy('type')
        .mapValues(arr => _.map(arr, 'pointer'))
        .value()
}

/**
 * returns all variants pointers from a component refArray > variantRelations for all namespaces
 *
 * @param {ps} ps privateServices
 * @param {Pointer} componentPointer
 * @param dataType
 */
const getAllAffectingVariantsForDataType = (ps: PS, componentPointer: Pointer, dataType: string) => {
    const variantIds = getCompVariantsFromRelations(ps, componentPointer, dataType)
    const componentPage = ps.pointers.full.components.getPageOfComponent(componentPointer)
    return _.map(variantIds, variantId => ps.pointers.data.getVariantsDataItem(variantId, componentPage.id))
}

const extractDataItemsIds = (ps: PS, componentPointer: Pointer, dataType: string) => {
    const dataPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, dataType)
    if (dataPointer) {
        const data = ps.dal.get(dataPointer)
        return dataModel.refArray.isRefArray(ps, data) ? dataModel.refArray.extractValuesWithoutHash(ps, data) : [data]
    }
    return []
}

export default {
    exactMatchVariantRelationPredicateitem,
    nonScopedValuePointer,
    scopedValuePointer: getScopedValueByRelationPtr,
    getScopedValuePointerByVariants,
    addScopedValueToRelation,
    getRelationPointerFromRefArrayByVariants,
    removeScopedValuesByVariants,
    getRelationsByVariantsAndPredicate,
    getComponentFromRelation,
    removeIllegalRelationsFromRefArray,
    removeRelation,
    getAllAffectingVariants,
    getAllAffectingVariantsGroupedByVariantType,
    getAllAffectingVariantsForDataType,
    getCompVariantsFromRelations,
    extractDataItemsIds
}
