import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import sospModes from '../component/sospModes'
import modesUtils from '../modes/modesUtils'
import dataIds from '../dataModel/dataIds'
import dataModel from '../dataModel/dataModel'
import dsUtils from '../utils/utils'

const {DATA_TYPES} = santaCoreUtils.constants
const PAGES_GROUP_COLLECTION_ID = 'PAGES_GROUP_COLLECTION'
const PAGES_GROUP_COLLECTION_TYPE = 'PagesGroupCollection'
const PAGES_GROUP_TYPE = 'PagesGroupData'

function isPagesGroupCollectionExist(ps: PS) {
    return Boolean(dataModel.getDataItemById(ps, PAGES_GROUP_COLLECTION_ID))
}

function getDataItemPointer(ps: PS, dateQuery: string) {
    return ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(dateQuery), 'masterPage')
}

function getAllPagesGroups(ps: PS): Pointer[] {
    const pagesGroupCollectionPointer = ps.pointers.data.getDataItem(PAGES_GROUP_COLLECTION_ID, 'masterPage')

    if (!pagesGroupCollectionPointer) {
        return []
    }

    const groupsPointer = ps.pointers.getInnerPointer(pagesGroupCollectionPointer, 'groups')
    const allPagesGroupsQueries = ps.dal.full.get(groupsPointer)

    return _.map(allPagesGroupsQueries, q => getDataItemPointer(ps, q))
}

function getAllPagesGroupsSerialized(ps: PS) {
    return _.map(getAllPagesGroups(ps), serializePagesGroup.bind(null, ps))
}

function createPagesGroupCollection(ps: PS) {
    const pagesGroupCollection = dataModel.createDataItemByType(ps, PAGES_GROUP_COLLECTION_TYPE)

    dataModel.addSerializedDataItemToPage(ps, 'masterPage', pagesGroupCollection, PAGES_GROUP_COLLECTION_ID)
}

function addGroupToPagesGroupCollection(ps: PS, newGroupId: string) {
    const pagesGroupCollectionPointer = ps.pointers.data.getDataItemFromMaster(PAGES_GROUP_COLLECTION_ID)
    const groupsPointer = ps.pointers.getInnerPointer(pagesGroupCollectionPointer, 'groups')

    ps.dal.push(groupsPointer, `#${newGroupId}`)
}

function hasPagesGroupsIntersection(pagesGroupToCheck, pagesIds) {
    return _.some(pagesGroupToCheck, pagesGroup => _.some(pagesIds, pageId => _.includes(pagesGroup.pages, pageId)))
}

function validateGroupsIntersection(ps: PS, updatedPagesGroupId, newPages) {
    const allPagesGroups = getAllPagesGroupsSerialized(ps)
    const pagesGroupToCheck = _.reject(allPagesGroups, {id: updatedPagesGroupId})

    if (hasPagesGroupsIntersection(pagesGroupToCheck, newPages)) {
        throw new Error('pagesGroup: page already exists in another pagesGroup')
    }
}

function validatePagesGroup(ps: PS, pagesGroup, pagesGroupId: string) {
    const allPagesGroups = getAllPagesGroupsSerialized(ps)

    if (_.isEmpty(pagesGroup.groupName)) {
        throw new Error('pagesGroup: groupName is required')
    }

    if (_.some(allPagesGroups, ['groupName', pagesGroup.groupName])) {
        throw new Error('pagesGroup: groupName must be unique')
    }

    if (_.has(pagesGroup, 'appId') && _.some(allPagesGroups, ['appId', pagesGroup.appId])) {
        throw new Error('pagesGroup: appId must be unique')
    }

    validateGroupsIntersection(ps, pagesGroupId, pagesGroup.pages)
}

function getNewPagesGroupData(ps: PS, pagesGroup) {
    const pagesGroupDefaults = dataModel.createDataItemByType(ps, PAGES_GROUP_TYPE)

    return _.defaults(pagesGroup, pagesGroupDefaults)
}

function createPagesGroup(ps: PS, pagesGroupId: string, pagesGroup) {
    const newPagesGroup = getNewPagesGroupData(ps, pagesGroup)
    validatePagesGroup(ps, newPagesGroup, pagesGroupId)
    dataModel.addSerializedDataItemToPage(ps, 'masterPage', newPagesGroup, pagesGroupId)

    if (!isPagesGroupCollectionExist(ps)) {
        createPagesGroupCollection(ps)
    }

    addGroupToPagesGroupCollection(ps, pagesGroupId)
}

function removeSOSPModeIfExist(ps: PS, pagesGroupPointer: Pointer) {
    const sospMode = modesUtils.getSospModeByPagesGroup(ps, pagesGroupPointer)

    if (sospMode) {
        sospModes.removeSOSPMode(ps, sospMode.modeId)
    }
}

function removeFromPagesGroupCollection(ps: PS, pagesGroupPointer: Pointer) {
    const pagesGroupCollectionPointer = ps.pointers.data.getDataItemFromMaster(PAGES_GROUP_COLLECTION_ID)
    const groupsPointer = ps.pointers.getInnerPointer(pagesGroupCollectionPointer, 'groups')
    const groups = ps.dal.get(groupsPointer)
    const newGroups = _.pull(groups, `#${pagesGroupPointer.id}`)

    ps.dal.set(groupsPointer, newGroups)
}

function removePagesGroup(ps: PS, pagesGroupPointer: Pointer) {
    if (!ps.dal.isExist(pagesGroupPointer)) {
        return
    }

    removeSOSPModeIfExist(ps, pagesGroupPointer)
    removeFromPagesGroupCollection(ps, pagesGroupPointer)
    dataModel.removeItemRecursivelyByType(ps, pagesGroupPointer)
}

function getPagesGroupByPredicate(ps: PS, predicate) {
    const pagesGroup: any = _.find(getAllPagesGroupsSerialized(ps), predicate)
    return pagesGroup ? ps.pointers.data.getDataItem(pagesGroup.id, 'masterPage') : null
}

function getPagesGroupByPageId(ps: PS, pageId: string) {
    const newPageQuery = `#${pageId}`
    return getPagesGroupByPredicate(ps, pagesGroupData => _.includes(pagesGroupData.pages, newPageQuery))
}

function getPagesGroupById(ps: PS, pagesGroupId: string) {
    return getPagesGroupByPredicate(ps, {id: pagesGroupId})
}

function getPagesGroupByGroupName(ps: PS, groupName: string) {
    return getPagesGroupByPredicate(ps, {groupName})
}

function getPagesGroupByAppId(ps: PS, appId: string | number) {
    return getPagesGroupByPredicate(ps, {appId})
}

function serializePagesGroup(ps: PS, pagesGroupPointer?: Pointer) {
    if (!pagesGroupPointer) {
        return null
    }
    return ps.dal.get(pagesGroupPointer)
}

function addPageToPagesGroup(ps: PS, pagesGroupPointer: Pointer, pageId?: string) {
    const groupPagesPointer = ps.pointers.getInnerPointer(pagesGroupPointer, 'pages')
    const groupPages = ps.dal.get(groupPagesPointer)
    const newPageQuery = `#${pageId}`

    if (groupPages && !_.includes(groupPages, newPageQuery)) {
        validateGroupsIntersection(ps, pagesGroupPointer.id, [newPageQuery])
        ps.dal.push(groupPagesPointer, newPageQuery)
        ps.siteAPI.updateActiveSOSPModes()
    }
}

function removePageFromPagesGroup(ps: PS, pagesGroupPointer: Pointer, pageId?: string) {
    const groupPagesPointer = ps.pointers.getInnerPointer(pagesGroupPointer, 'pages')
    const groupPages = ps.dal.get(groupPagesPointer)
    const pageToRemoveQuery = `#${pageId}`

    if (_.includes(groupPages, pageToRemoveQuery)) {
        const newPages = _.without(groupPages, pageToRemoveQuery)
        if (_.isEmpty(newPages)) {
            removePagesGroup(ps, pagesGroupPointer)
        } else {
            ps.dal.set(groupPagesPointer, newPages)
        }
        ps.siteAPI.updateActiveSOSPModes()
    }
}

function getComponentPagesGroup(ps: PS, compPointer: Pointer) {
    const pagesGroupId = sospModes.getComponentPagesGroupId(ps, compPointer)
    return pagesGroupId ? getPagesGroupById(ps, pagesGroupId) : null
}

function getNewPagesGroupId() {
    return dataIds.generateNewId(DATA_TYPES.data)
}

export default {
    createPagesGroup,
    removePagesGroup,
    getNewPagesGroupId,
    getAll: getAllPagesGroups,
    getPagesGroupById,
    getPagesGroupByGroupName,
    getPagesGroupByAppId,
    getPagesGroupByPageId,
    getComponentPagesGroup,
    serializePagesGroup,
    addPageToPagesGroup,
    removePageFromPagesGroup
}
