import type {RefInfo, ResolvedReference, SchemaOptions} from '@wix/document-services-types'
import type {DalValue} from '../store'

export interface DalSchema {
    isSystemStyle(id: string): boolean
    validate(schemaType: string, data: DalValue, namespace: string): void
    validateStrict(schemaType: string, data: DalValue, namespace: string): void
    addDefaultsAndValidate(schemaType: string, value: DalValue, namespace: string): void
    hasNamespace(namespace: string): boolean
    isDraftItem(data: DalValue): boolean
    getReferences(namespace: string, value: DalValue): readonly ResolvedReference[]
    getSchemaType(namespace: string, value: DalValue): string
    getComponentDefinition(componentType: string): any
    isPermanentDataType(namespace: string, schemaType: string): boolean
    extractReferenceFieldsInfoForSchema(namespace: string, dataTypeName: string): readonly RefInfo[]
    createItemAccordingToSchema(schemaType: string, namespace: string): Record<string, any>
}

const createDalSchema = (options: SchemaOptions): DalSchema => {
    const {experiments, schemaService} = options

    const isSystemStyle = (id: string): boolean => schemaService.isSystemStyle(id)

    const getSchemaType = (namespace: string, value: any): string => value.type

    const getReferences = (namespace: string, value: any): readonly ResolvedReference[] => {
        const schemaType = getSchemaType(namespace, value)

        return schemaService.extractReferences(namespace, schemaType, value, experiments!)
    }
    return {
        isSystemStyle,
        validate: schemaService.validateNoDefaults,
        validateStrict: schemaService.validateStrict,
        hasNamespace: schemaService.hasNamespace,
        isDraftItem: schemaService.isDraftItem,
        addDefaultsAndValidate: schemaService.addDefaultsAndValidate,
        createItemAccordingToSchema: schemaService.createItemAccordingToSchema,
        getReferences,
        getSchemaType,
        extractReferenceFieldsInfoForSchema: schemaService.extractReferenceFieldsInfo,
        getComponentDefinition: schemaService.getDefinition,
        isPermanentDataType: schemaService.isPermanentDataType
    }
}

export {createDalSchema}
