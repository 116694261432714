import _ from 'lodash'
import documentServicesSchemas from 'document-services-schemas'

const {schemasService} = documentServicesSchemas.services

const removeDraftAnnotations = item => {
    delete item._isDraftItem
    delete item._draftRefs
}

const addDraftAnnotations = (namespace: string, dataItem) => {
    if (schemasService.isDraftDataSchema(namespace, dataItem.type)) {
        dataItem._isDraftItem = true
        const refInfos = schemasService.extractReferenceFieldsInfo(namespace, dataItem.type, dataItem)
        if (refInfos?.length) {
            dataItem._draftRefs = _.map(refInfos, info => _.pick(info, ['jsonPointer', 'path']))
        }
    }
}

export default {
    removeDraftAnnotations,
    addDraftAnnotations
}
