import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import layouters from '../../layouters/layouters'
import componentBehaviors from '../../component/componentBehaviors'
import layoutHooksUtils from './utils/layoutHooksUtils'
import layoutConstraintsUtils from '../../structure/utils/layoutConstraintsUtils'

function applyLayoutToStateBoxChildrenBeforeUpdate(ps: PS, compPointer, stateBoxNewLayout, updateCompLayoutCallback) {
    if (!_.isFunction(updateCompLayoutCallback)) {
        return
    }

    const stateBoxCurrentLayout = component.layout.get(ps, compPointer)
    if (layoutHooksUtils.isCompResizing(stateBoxCurrentLayout, stateBoxNewLayout)) {
        const states = layouters.getNonMasterChildren(ps, compPointer)
        const {currentIndex} = componentBehaviors.getRuntimeState(ps, compPointer)
        const dy = layoutHooksUtils.calculateDelta(stateBoxNewLayout.y, stateBoxCurrentLayout.y)
        const dx = layoutHooksUtils.calculateDelta(stateBoxNewLayout.x, stateBoxCurrentLayout.x)
        const heightBeforeConstraint = stateBoxNewLayout.height
        const statesLayoutConstraints = _.merge({}, _.pick(stateBoxNewLayout, ['width', 'height']), {x: dx, y: dy})

        states.forEach(state => {
            const compType = component.getType(ps, state)
            const dontConstrainStatesByWidth = compType === 'wysiwyg.viewer.components.Repeater'
            const dontConstrainStatesByHeight = !_.eq(state, states[currentIndex])
            layoutConstraintsUtils.constrainByChildrenLayout(
                ps,
                state,
                statesLayoutConstraints,
                dontConstrainStatesByWidth,
                dontConstrainStatesByHeight
            )
        })

        const statesNewLayout = _.pick(statesLayoutConstraints, ['width', 'x', 'y'])
        const currentStateNewLayout = _.merge({}, statesNewLayout, _.pick(statesLayoutConstraints, ['height']))
        states.forEach(state => {
            if (_.eq(state, states[currentIndex])) {
                updateCompLayoutCallback(ps, state, currentStateNewLayout)
            } else {
                updateCompLayoutCallback(ps, state, statesNewLayout)
            }
        })

        if (currentStateNewLayout.height > stateBoxNewLayout.height) {
            stateBoxNewLayout.height = currentStateNewLayout.height
            if (dy > 0) {
                stateBoxNewLayout.y -= statesLayoutConstraints.height - heightBeforeConstraint
            }
        }
    }
}

function applyLayoutToStateBoxChildrenAfterUpdate(ps: PS, compPointer, newLayout, updateCompLayoutCallback) {
    const {currentIndex} = componentBehaviors.getRuntimeState(ps, compPointer)
    const states = layouters.getNonMasterChildren(ps, compPointer)
    const renderedChildCompType = component.getType(ps, states[currentIndex])
    const supportIndividualHeightForStates =
        renderedChildCompType === 'wysiwyg.viewer.components.StateBoxFormState' ||
        renderedChildCompType === 'wysiwyg.viewer.components.StateBoxState'

    const getChildNewLayout = height => ({
        x: 0,
        y: 0,
        width: newLayout.width,
        height
    })

    states.forEach(state => {
        if (!_.eq(state, states[currentIndex]) && supportIndividualHeightForStates) {
            const stateBoxLayout = component.layout.get(ps, state)

            updateCompLayoutCallback(ps, state, getChildNewLayout(stateBoxLayout.height))
        } else {
            updateCompLayoutCallback(ps, state, getChildNewLayout(newLayout.height))
        }
    })
}

export default {
    applyLayoutToStateBoxChildrenBeforeUpdate,
    applyLayoutToStateBoxChildrenAfterUpdate
}
