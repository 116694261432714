import _ from 'lodash'
import {fonts, urlUtils} from '@wix/santa-core-utils'
import media from './media'

const commonFontUtils = fonts
const {joinURL} = urlUtils

const GOOGLE_FONT_SERVICE_URL = '//static.parastorage.com/tag-bundler/api/v1/fonts-cache/googleAPI/css?family='
const POSSIBLE_CHARACTERS_SETS = ['latin-ext', 'cyrillic', 'japanese', 'korean', 'arabic', 'hebrew', 'latin']
const LANGUAGES_CSS_RELATIVE_PATH = 'user-site-fonts/v16'
joinURL(LANGUAGES_CSS_RELATIVE_PATH, 'languages-woff2.css')
joinURL(LANGUAGES_CSS_RELATIVE_PATH, 'languages-woff.css')
joinURL(LANGUAGES_CSS_RELATIVE_PATH, 'languages-truetype.css')
function getMetadata(fontNames: string[]) {
    return _.compact(_.map(fontNames, fontName => commonFontUtils.getFontsMetaData()[fontName]))
}
function getWixStoredFontsCssUrls(serviceTopology, documentType: string) {
    const fontUrls = getWixStaticsFontsLinks(serviceTopology, documentType)
    return _.values(fontUrls)
}

function getFontsUrlWithParams(fontNamesObject, documentType: string, characterSets: string[]) {
    const fontsFamiliesArray = _.isArray(fontNamesObject) ? fontNamesObject : _.keys(fontNamesObject)
    const query = getFontsQuery(fontsFamiliesArray, documentType, characterSets)
    if (query) {
        return GOOGLE_FONT_SERVICE_URL + query
    }
    return ''
}

function getFontsPermissions(documentType: string) {
    const permissions = ['all', 'legacy']
    if (documentType === 'WixSite') {
        permissions.push('studio')
    }
    return permissions
}

function getFontsQuery(fontsFamiliesArray, documentType: string, characterSets: string[]) {
    let fontQuery = ''
    const permissions = getFontsPermissions(documentType)
    _.forEach(fontsFamiliesArray, function (fontFamily) {
        const font = commonFontUtils.getFontsMetaData()[fontFamily]
        if (font?.cdnName && _.includes(permissions, font.permissions)) {
            fontQuery += font.cdnName
            fontQuery += ':n,b,i,bi|'
        }
    })
    if (fontQuery === '') {
        return null
    }
    fontQuery = _.trimEnd(fontQuery, '|')
    if (characterSets) {
        fontQuery += `&subset=${characterSets.join(',')}`
    }
    return fontQuery
}

function getWixStaticsFontsLinks(serviceTopology, documentType: string) {
    const result: Record<string, string> = {
        languages: media.getMediaUrl(serviceTopology, joinURL(LANGUAGES_CSS_RELATIVE_PATH, 'languages.css')),
        wixmadefor: media.getMediaUrl(serviceTopology, 'user-site-fonts/v16/wixMadefor.css')
    }
    if (documentType === 'WixSite') {
        result.helveticas = media.getMediaUrl(serviceTopology, 'user-site-fonts/v7/helvetica.css')
    }
    return result
}

function getCssUrls(documentType: string, serviceTopology: Record<string, any>): Record<string, any> {
    const cssLinks: Record<string, any> = {}
    cssLinks.googleFonts = getFontsUrlWithParams(
        commonFontUtils.getFontsMetaData(),
        documentType,
        POSSIBLE_CHARACTERS_SETS
    )
    _.merge(cssLinks, getWixStaticsFontsLinks(serviceTopology, documentType))
    return cssLinks
}

export default {
    parseFontStr: commonFontUtils.parseFontStr,
    parseStyleFont: commonFontUtils.parseStyleFont,
    getMetadata,
    getFontFamily: commonFontUtils.getFontFamily,
    getFontFallback: commonFontUtils.getFontFallback,
    getFontFamilyWithFallbacks: commonFontUtils.getFontFamilyWithFallbacks,
    getWixStoredFontsCssUrls,
    getWixStoredFontsCssUrlsWithParams: commonFontUtils.getWixStoredFontsCssUrlsWithParams,
    getCurrentSelectablefontsWithParams: commonFontUtils.getCurrentSelectablefontsWithParams,
    getFontsUrlWithParams: commonFontUtils.getFontsUrlWithParams,
    getThemeFontsCss: commonFontUtils.getThemeFontsCss,
    getCssUrls,
    fontToCSSWithColor: commonFontUtils.fontToCSSWithColor,
    getFontFamilyPermissions: commonFontUtils.getFontFamilyPermissions
}
