import _ from 'lodash'
import constants from '../constants/constants'

const {COMP_DATA_QUERY_KEYS} = constants

function sanitizeSerializedComponent(componentDefinition) {
    delete componentDefinition.activeVariants
    delete componentDefinition.referredVariants

    _.forEach(COMP_DATA_QUERY_KEYS, compDataQueryKey => {
        delete componentDefinition[compDataQueryKey]
    })

    _.forEach(componentDefinition.components, sanitizeSerializedComponent)
}

export default sanitizeSerializedComponent
