import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import {constants} from '@wix/document-manager-extensions'

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const {dal, pointers} = documentManager
    const pageFilter = dal.queryFilterGetters.getPageCompFilter(pageId)
    const connectionLists = documentManager.dal.query(
        constants.DATA_TYPES.connections,
        pageFilter,
        ({type}) => type === 'ConnectionList'
    )
    _.forOwn(connectionLists, connectionList => {
        let isMissingType = false
        const newItems = connectionList.items.map((item: any) => {
            if (!item.type) {
                isMissingType = true
                if (_.keys(item).length === 1 && item.role) {
                    return {role: item.role, type: 'WixCodeConnectionItem'}
                }
                return {...item, type: 'ConnectionItem'}
            }
            return item
        })
        if (isMissingType) {
            const fixedConnectionsList = {...connectionList, items: newItems}
            dal.set(pointers.data.getConnectionsItem(connectionList.id, 'c1dmp'), fixedConnectionsList)
        }
    })
}

const name = 'fixBrokenConnections'
const version = 1

export {migratePage, name, version}
