import _ from 'lodash'
import jsonSchemaUtils from '@wix/wix-json-schema-utils'
import {displayedOnlyStructureUtil, eventsMap} from '@wix/santa-core-utils'
import dsUtils from '../utils/utils'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import actionsAndBehaviors from '../actionsAndBehaviors/actionsAndBehaviors'
import component from '../component/component'
import componentCode from '../component/componentCode'
import appStudioDataModel from './appStudioDataModel'
import fileSystemAPI from '../wixCode/services/fileSystemAPI'
import type {Pointer, PS} from '@wix/document-services-types'

const isEventBehavior = ({behavior}) => actionsAndBehaviors.isCodeBehavior(behavior)

const pascalCase = (str: string) => _.upperFirst(_.camelCase(str))

const createCustomEventName = (name: string) => `on${pascalCase(name)}`

const getRepeaterTemplatePointer = (ps: PS, repeatedComponentId: string) => {
    const templateId = displayedOnlyStructureUtil.getRepeaterTemplateId(repeatedComponentId)
    return componentDetectorAPI.getComponentById(ps, templateId)
}

const toTemplateItemPointer = (ps: PS, compPointer: Pointer) => {
    const isRepeated = displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id)
    return isRepeated ? getRepeaterTemplatePointer(ps, compPointer.id) : compPointer
}

// returns template item for repeated comps, and the actual pointer otherwise
const getChildrenForStaticEvents = (ps: PS, rootCompId: string) => {
    const comps = component.getChildren(ps, componentDetectorAPI.getComponentById(ps, rootCompId), true)

    return _(comps)
        .map(compRef => toTemplateItemPointer(ps, compRef))
        .uniqBy('id')
        .value()
}

// returns event name based on eventsMap from santa-core-utils if exists, and creates a dynamic name for custom events that are not in the map
const getEventFromAction = (actionName: string) => {
    const eventName = eventsMap[actionName]
    return eventName || createCustomEventName(actionName)
}

/**
 * @param ps
 * @param rootCompId
 * @returns {object[]}
 */
const getChildrenStaticEvents = (ps: PS, rootCompId: string) => {
    const comps = getChildrenForStaticEvents(ps, rootCompId)
    const compsStaticEventDefinitions = _.flatMap(comps, comp => {
        const role = componentCode.getNickname(ps, comp)
        const behaviors = actionsAndBehaviors.getBehaviors(ps, comp)
        return _(behaviors)
            .filter(isEventBehavior)
            .map(({action, behavior}) => ({
                role,
                event: getEventFromAction(action.name),
                callbackId: behavior.params.callbackId
            }))
            .value()
    })

    return compsStaticEventDefinitions
}

const serializeDefaultValues = (ps: PS, propertiesSchemas) => {
    const customDefinitions = _.assign({}, ...appStudioDataModel.getAllSerializedCustomDefinitions(ps))
    const resolver = jsonSchemaUtils.createResolver(jsonSchemaUtils.baseDefinitions, customDefinitions)
    const defaultValues = _(propertiesSchemas)
        .map(propSchema => resolver.resolve(propSchema.structure))
        // @ts-expect-error
        .thru(resolvedProperty => _.assign(...resolvedProperty))
        // @ts-expect-error
        .mapValues('default')
        .value()

    return defaultValues
}

const serializeWidgetAPI = (ps: PS, widget) => {
    const {widgetApi} = widget
    const rootCompId = dsUtils.stripHashIfExists(widget.rootCompId)
    const widgetAPIDescriptor: any = _(widgetApi)
        .pick(['functions', 'events'])
        .mapValues(property => _.map(property, 'name'))
        .value()
    const propertiesNames = _.flatMap(widgetApi.propertiesSchemas, property => _.keys(property.structure))
    widgetAPIDescriptor.defaultValues = serializeDefaultValues(ps, widgetApi.propertiesSchemas)
    widgetAPIDescriptor.behaviors = getChildrenStaticEvents(ps, rootCompId)
    return _.defaults({properties: propertiesNames}, widgetAPIDescriptor)
}

const saveMetadata = (ps: PS): Promise<unknown> => {
    const fileLocation = fileSystemAPI.getRoots().public.location
    const fileName = `${fileLocation}app-metadata.json`
    const fileDescriptor = fileSystemAPI.getVirtualDescriptor(ps, fileName, false)
    const widgets = appStudioDataModel.getAllWidgets(ps)
    const widgetsApi = {}
    widgets.forEach(widget => {
        const widgetData = appStudioDataModel.getData(ps, widget.pointer)
        const rootCompId = dsUtils.stripHashIfExists(widgetData.rootCompId)
        widgetsApi[rootCompId] = serializeWidgetAPI(ps, widgetData)
    })
    return fileSystemAPI.writeFile(ps, fileDescriptor, JSON.stringify(widgetsApi))
}

export default {
    getChildrenStaticEvents,
    serializeWidgetAPI,
    saveMetadata
}
