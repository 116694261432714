import {constants} from '@wix/santa-core-utils'
import type {OldSchemaService} from '@wix/document-services-types'
import dataValidatorsBuilder from './dataValidatorsBuilder'

const {DATA_TYPES} = constants

export default (schemasService: OldSchemaService) => {
    const dataValidators = dataValidatorsBuilder(schemasService)

    const createItemAccordingToSchema = (schemaName: string) => {
        const item = {type: schemaName}
        dataValidators.resolveDefaultItem(schemaName, item)
        //todo copy common.js here
        //
        //write a test for createDataItemToSchema
        return item
    }

    const defaultDataItemCreatorFactory = (schemaType: string) => (schemaName: string) => {
        if (schemasService.getSchema(schemaType, schemaName)) {
            return createItemAccordingToSchema(schemaName)
        }
        return null
    }

    const createDataItemByType = defaultDataItemCreatorFactory(DATA_TYPES.data)
    const createDesignItemByType = defaultDataItemCreatorFactory(DATA_TYPES.design)
    const createPropertiesItemByType = defaultDataItemCreatorFactory(DATA_TYPES.prop)
    const createStyleItemByType = defaultDataItemCreatorFactory(DATA_TYPES.theme)

    return {
        createDataItemByType,
        createStyleItemByType,
        createDesignItemByType,
        createPropertiesItemByType
    }
}
