import type {
    CreateExtArgs,
    DalValue,
    DmApis,
    Extension,
    ExtensionAPI,
    ValidatorResult
} from '@wix/document-manager-core'
import type {DalSchema} from '@wix/document-manager-core/src/dal/schema/dalSchema'
import type {ReportableError} from '@wix/document-manager-utils'
import {namespaceMapping} from '@wix/document-services-json-schemas'
import type {Pointer, RefInfo, ResolvedReference} from '@wix/document-services-types'
import _ from 'lodash'
import {namespacesWithoutTypeAndId} from './namespacesWithoutTypeAndId'

export interface SchemaValidationError {
    message: string
    dataPath: string
    keyword: string
    schemaPath: string
    params: {
        additionalProperty: string
    }
}
export interface ParsedSchemaValidationErrorMessage {
    offendedDataId: string
    offendedDataNamespace: string
    errors: SchemaValidationError[]
}
export interface SafeRemovalError {
    readonly namespace: string
    readonly dataType: string
    readonly invalidData: any
    readonly exception: Error
}

export interface SafeRemovalResult {
    readonly error: SafeRemovalError | null
    readonly result: Record<string, Record<string, any>> | null
}

export interface SchemaAPI {
    isSystemStyle(id: string): boolean
    getReferences(namespace: string, value: DalValue): readonly ResolvedReference[]
    validate(dataTypeName: string, data: any, namespace: string): void
    hasNamespace(namespace: string): boolean
    removeAdditionalProperties(namespace: string, value: DalValue): void
    removeWhitelistedProperties(namespace: string, value: DalValue, conservativeRemoval: boolean): void
    removeAdditionalPropertiesSafely(data: Record<string, Record<string, DalValue>>): SafeRemovalResult
    removeWhitelistedPropertiesSafely(
        data: Record<string, Record<string, DalValue>>,
        conservativeRemoval: boolean
    ): Record<string, Record<string, any>>
    extractReferenceFieldsInfoForSchema(namespace: string, dataTypeName: string): readonly RefInfo[]
    convertNamespaceFromServerStyle(name: string): string
    convertNamespaceToServerStyle(name: string): string
}

export type SchemaExtensionAPI = ExtensionAPI & {
    schemaAPI: SchemaAPI
}

const SINGLE_LAYOUT_DATA_TYPE = 'SingleLayoutData'

const REMOVAL_EXCLUSIONS = {
    removeInnerAdditionalProperties: {
        [SINGLE_LAYOUT_DATA_TYPE]: ['itemLayout', 'componentLayout', 'containerLayout']
    },
    additionalProperties: new Set([SINGLE_LAYOUT_DATA_TYPE]),
    whitelist: new Set([SINGLE_LAYOUT_DATA_TYPE])
}

const REMOVE_INNER_ADDITIONAL_PROPERTIES_TYPES = new Set(
    Object.keys(REMOVAL_EXCLUSIONS.removeInnerAdditionalProperties)
)

const createIsTypeInSetFunc =
    (set: Set<string>) =>
    (schema: any, namespace: string, value: DalValue): boolean => {
        const type = schema.getSchemaType(namespace, value)
        return set.has(type)
    }

const getRemoveWhitelistedPropertiesMethods = (schema: DalSchema, schemaService: any) => {
    const shouldExcludeFromRemoveWhitelistedProperties = createIsTypeInSetFunc(REMOVAL_EXCLUSIONS.whitelist)

    const removeWhitelistedProperties = (namespace: string, value: DalValue, conservativeRemoval: boolean) => {
        if (value?.type && schemaService.hasNamespace(namespace)) {
            if (shouldExcludeFromRemoveWhitelistedProperties(schema, namespace, value)) {
                return value
            }
            schemaService.whitelistCleanup.removeWhitelistedProperties(
                namespace,
                value.type,
                value,
                conservativeRemoval
            )
        }
    }

    return {
        removeWhitelistedProperties
    }
}

const createExtensionAPI = ({dal, coreConfig}: CreateExtArgs): SchemaExtensionAPI => {
    const {schemaService} = coreConfig
    const {schema} = dal
    const {removeWhitelistedProperties} = getRemoveWhitelistedPropertiesMethods(schema, schemaService)

    const shouldRemoveInnerAdditionalProperties = createIsTypeInSetFunc(REMOVE_INNER_ADDITIONAL_PROPERTIES_TYPES)

    const removeInnerAdditionalProperties = (namespace: string, value: DalValue): void => {
        const innerPropertiesKeys = REMOVAL_EXCLUSIONS.removeInnerAdditionalProperties[value.type]
        innerPropertiesKeys.forEach((key: string) => {
            const innerValue = value[key]
            if (innerValue?.type) {
                schemaService.removeAdditionalProperties(namespace, innerValue.type, innerValue)
                delete innerValue.metaData
            }
        })
    }

    const shouldExcludeFromRemoveAdditionalProperties = createIsTypeInSetFunc(REMOVAL_EXCLUSIONS.additionalProperties)

    const removeAdditionalProperties = (namespace: string, value: DalValue) => {
        if (value?.type && schemaService.hasNamespace(namespace)) {
            if (shouldRemoveInnerAdditionalProperties(schema, namespace, value)) {
                return removeInnerAdditionalProperties(namespace, value)
            }
            if (shouldExcludeFromRemoveAdditionalProperties(schema, namespace, value)) {
                return value
            }
            schemaService.removeAdditionalProperties(namespace, value.type, value)
        }
    }

    const {validateStrict, hasNamespace} = schemaService

    const removeAdditionalPropertiesSafely = (data: Record<string, Record<string, DalValue>>): SafeRemovalResult => {
        const result = _.cloneDeep(data)
        for (const [namespace, v] of _.toPairs(result)) {
            for (const [id, value] of _.toPairs(v)) {
                try {
                    removeAdditionalProperties(namespace, value)
                } catch (e) {
                    return {
                        result: null,
                        error: {
                            namespace,
                            dataType: value.type,
                            exception: e as Error,
                            invalidData: data[namespace][id]
                        }
                    }
                }
            }
        }
        return {error: null, result}
    }
    const removeWhitelistedPropertiesSafely = (
        data: Record<string, Record<string, DalValue>>,
        conservativeRemoval: boolean
    ): Record<string, Record<string, any>> => {
        const result = _.cloneDeep(data)
        _.forEach(result, (namespaceData, namespace) => {
            _.forEach(namespaceData, value => {
                removeWhitelistedProperties(namespace, value, conservativeRemoval)
            })
        })
        return result
    }

    return {
        schemaAPI: {
            isSystemStyle: schema.isSystemStyle,
            getReferences: schema.getReferences,
            validate: validateStrict,
            hasNamespace,
            removeAdditionalProperties,
            removeWhitelistedProperties,
            removeAdditionalPropertiesSafely,
            removeWhitelistedPropertiesSafely,
            extractReferenceFieldsInfoForSchema: schema.extractReferenceFieldsInfoForSchema,
            convertNamespaceFromServerStyle: namespaceMapping.convertNamespaceFromServerStyle,
            convertNamespaceToServerStyle: namespaceMapping.convertNamespaceToServerStyle
        }
    }
}

const getValidationErrorMessage = (err: ReportableError, dataId: string, dataNamespace: string): string => {
    let parsedMessage

    try {
        parsedMessage = JSON.parse(err.message)
    } catch (e) {
        // no json provided, then plain error
        parsedMessage = err.message
    }

    const validationErrorMessage: ParsedSchemaValidationErrorMessage = {
        offendedDataId: dataId,
        offendedDataNamespace: dataNamespace,
        errors: parsedMessage
    }

    return JSON.stringify(validationErrorMessage)
}

const createValidator = ({dal, coreConfig}: DmApis) => {
    const {schema} = dal
    const {experimentInstance, schemaService} = coreConfig
    const {removeWhitelistedProperties} = getRemoveWhitelistedPropertiesMethods(schema, schemaService)

    const strictValidationFunction = schema.validateStrict

    const nonStrictValidationFunction = schema.validate

    const validateSchemaNonStrict = (schemaType: string, value: DalValue, namespace: string): ValidatorResult[] => {
        try {
            nonStrictValidationFunction(schemaType, value, namespace)
        } catch (nonStrictSchemaError) {
            const err = nonStrictSchemaError as ReportableError
            const errorMessage = getValidationErrorMessage(err, value.id, namespace)
            return [
                {
                    shouldFail: true,
                    type: err.errorType,
                    message: errorMessage,
                    tags: {...(err.tags ?? {}), withDalStrictSchemaValidation: false},
                    extras: err.extras
                }
            ]
        }
        return []
    }

    const validateSchemaStrict = (schemaType: string, value: DalValue, namespace: string): ValidatorResult[] => {
        const isConservative = true
        const shouldFailStrictValidation = experimentInstance.isOpen('dm_failDalStrictSchemaValidation')
        const shouldCleanWithWhitelist = !experimentInstance.isOpen('dm_noWhitelistDalStrictSchemaValidation')
        const isDataTypeWhitelisted = schemaService.whitelistCleanup.isDataTypeWhitelisted(
            namespace,
            value?.type,
            isConservative
        )

        // omit basedOnSignature instead of removing inside of whitelist removal
        let dalValue: any = _.omit(value, ['metaData', 'basedOnSignature'])

        if (isDataTypeWhitelisted && shouldCleanWithWhitelist) {
            // deep clone only whitelisted data to avoid mutation
            dalValue = _.cloneDeep(dalValue)

            removeWhitelistedProperties(namespace, dalValue, isConservative)
        }

        // validation function itself returns void, so if it returns something here, then it is an Error
        const strictValidationResult = _.attempt(strictValidationFunction, schemaType, dalValue, namespace)

        if (!strictValidationResult) {
            return []
        }

        const err = strictValidationResult as ReportableError

        let shouldFail = shouldFailStrictValidation
        let tags: Record<string, any> = {...(err.tags ?? {}), withDalStrictSchemaValidation: true}
        let extras: Record<string, any> = {...(err.extras ?? {})}

        const nonStrictValidationResult = _.attempt(nonStrictValidationFunction, schemaType, dalValue, namespace)

        if (nonStrictValidationResult) {
            shouldFail = true
            tags = {...tags, failedOnNonStrictFallback: true}
            extras = {
                ...extras,
                nonStrictError: _.pick(nonStrictValidationResult as ReportableError, ['errorType', 'message'])
            }
        }

        const errorMessage = getValidationErrorMessage(err, dalValue.id, namespace)

        return [
            {
                shouldFail,
                type: err.errorType,
                message: errorMessage,
                tags,
                extras
            }
        ]
    }

    return {
        validateSchema: (pointer: Pointer, value: DalValue): ValidatorResult[] => {
            if (_.isNil(value)) {
                return []
            }
            const namespace = pointer.type
            const schemaType = schema.getSchemaType(namespace, value)
            const hasNamespace = schema.hasNamespace(namespace)
            const isRunningStrictValidation = experimentInstance.isOpen('dm_runDalStrictSchemaValidation')

            if (hasNamespace) {
                return isRunningStrictValidation
                    ? validateSchemaStrict(schemaType, value, namespace)
                    : validateSchemaNonStrict(schemaType, value, namespace)
            }
            return []
        },
        validateDalSetValue: (pointer: Pointer, setValue: DalValue) => {
            const isValueInvalid = (value: DalValue) => !(value.type && value.id)
            const shouldCheck = !namespacesWithoutTypeAndId.has(pointer.type)
            const hasProblem = shouldCheck && setValue && isValueInvalid(setValue)
            if (hasProblem) {
                return [
                    {
                        shouldFail: true,
                        type: 'invalidDalSetValues',
                        message: `pointer of type ${pointer.type} is missing id or type`,
                        extras: {
                            id: pointer.id,
                            type: pointer.type,
                            setValue
                        }
                    }
                ]
            }
        }
    }
}

const createExtension = (): Extension => ({
    name: 'schema',
    dependencies: new Set([]),
    createExtensionAPI,
    createValidator
})

export {createExtension}
