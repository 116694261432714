import platformInit from '@wix/santa-ds-libs/src/platformInit'

function getWidgetSpec(clientSpecMap) {
    return platformInit.specMapUtils.getAppSpec(clientSpecMap)
}

function hasWixCodeWidgetSpecs(clientSpecMap) {
    return !!getWidgetSpec(clientSpecMap)
}

export default {
    hasWixCodeWidgetSpecs,
    getWixCodeSpec: getWidgetSpec
}
