import _ from 'lodash'
import platformAppComp from './utils/platformAppComp'
import component from '../../component/component'
import dataModel from '../../dataModel/dataModel'
import type {Pointer, PS} from '@wix/document-services-types'

const DIMENSIONS = ['width', 'height']

function verifyAppWidgetStructure(serializedComp) {
    if (_.size(serializedComp.components) !== 1) {
        throw new Error('Invalid app widget structure - must have single child')
    }
}

function adjustLayout(serializedComp) {
    const isResponsiveLayout = serializedComp.layouts
    if (isResponsiveLayout) {
        return
    }
    const widgetRootStructure = serializedComp.components[0]
    _.merge(widgetRootStructure, {layout: {x: 0, y: 0}})
    _.forEach(DIMENSIONS, dim => {
        if (_.has(serializedComp, ['layout', dim])) {
            widgetRootStructure.layout[dim] = serializedComp.layout[dim]
        } else if (_.has(widgetRootStructure, ['layout', dim])) {
            _.set(serializedComp, ['layout', dim], widgetRootStructure.layout[dim])
        }
    })
}

function beforeAddHook(ps: PS, compToAddPointer: Pointer, containerPointer: Pointer, serializedComp) {
    verifyAppWidgetStructure(serializedComp)
    adjustLayout(serializedComp)
}

function handleAppWidgetDeletion(ps: PS, widgetRef) {
    platformAppComp.removePlatformApplicationFromPageIfLastController(ps, widgetRef)
    platformAppComp.removeControllerFromStateMap(ps, widgetRef)
}

function handleAppWidgetSerialization(ps: PS, controllerRef, customStructureData) {
    platformAppComp.addControllerStateToSerializedStructure(ps, controllerRef, customStructureData)
}

function handleAppWidgetAddition(ps: PS, widgetRef, widgetDefinition) {
    platformAppComp.setControllerStateFromSerializedStructure(ps, widgetRef, widgetDefinition)
    platformAppComp.addPlatformAppToPageIfFirstController(ps, widgetRef, widgetDefinition.data.applicationId)
}

function handleAppWidgetParentChange(ps: PS, controllerRef, newParentPointer) {
    const {applicationId} = dataModel.getDataItem(ps, controllerRef)
    platformAppComp.removePlatformApplicationFromPageIfLastController(ps, controllerRef)
    platformAppComp.addPlatformAppToPageIfFirstController(
        ps,
        controllerRef,
        applicationId,
        component.getPage(ps, newParentPointer)
    )
}

export default {
    beforeAddHook,
    handleAppWidgetAddition,
    handleAppWidgetDeletion,
    handleAppWidgetSerialization,
    handleAppWidgetParentChange
}
