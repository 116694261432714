import type {DocumentManager} from '@wix/document-manager-core'
import type {Hooks, Pointer, PS, RoutersDefinition} from '@wix/document-services-types'

export function bridgeDMEventsToHooks(ps: PS, documentManager: DocumentManager, dsHooks: Hooks) {
    const {EVENTS, eventEmitter} = documentManager
    const undoRedoEventsToTriggerAutoSave = [
        EVENTS.UNDO_REDO.TAKE_SNAPSHOT,
        EVENTS.UNDO_REDO.UNDO,
        EVENTS.UNDO_REDO.REDO
    ]

    const undoRedoEventsToTriggerAfterApplySnapShot = [EVENTS.UNDO_REDO.UNDO, EVENTS.UNDO_REDO.REDO]

    const notifyAutosave = () => dsHooks.executeHook(dsHooks.HOOKS.AUTOSAVE.ACTION, null!, [ps])

    const notifyAfterApplySnapshot = () =>
        dsHooks.executeHook(dsHooks.HOOKS.UNDO_REDO.AFTER_APPLY_SNAPSHOT, null!, [ps])

    dsHooks.registerHook(dsHooks.HOOKS.SAVE.SITE_SAVED, (_scopedPs: PS, isFirstSave: boolean) => {
        if (EVENTS.CSAVE) {
            eventEmitter.emit(EVENTS.CSAVE.SITE_SAVED, isFirstSave)
        }
    })
    if (EVENTS.CSAVE) {
        eventEmitter.on(EVENTS.CSAVE.NON_RECOVERABLE_ERROR, () => {
            dsHooks.executeHook(dsHooks.HOOKS.AUTOSAVE.CSAVE_NON_RECOVERABLE_ERROR, null!, [ps])
        })
    }

    eventEmitter.on(EVENTS.ROUTERS.BEFORE_UPDATE, (routerPtr: Pointer, routerData: RoutersDefinition) => {
        dsHooks.executeHook(dsHooks.HOOKS.ROUTER.BEFORE_UPDATE, 'router', [ps, routerPtr, routerData])
    })

    eventEmitter.on(EVENTS.COMPONENTS.AFTER_REMOVE, (compPointer: Pointer, compType: string) => {
        dsHooks.executeHook(dsHooks.HOOKS.REMOVE.AFTER!, compType, [ps, compPointer])
    })

    undoRedoEventsToTriggerAutoSave.forEach(ev => eventEmitter.on(ev, notifyAutosave))
    undoRedoEventsToTriggerAfterApplySnapShot.forEach(ev => eventEmitter.on(ev, notifyAfterApplySnapshot))
}
