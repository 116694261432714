import type {BehaviorObject, Pointer, PS} from '@wix/document-services-types'
import type {Callback} from '@wix/viewer-manager-interface'

const BEHAVIOR_TYPE = 'comp'

function executeBehavior(ps: PS, compPointer: Pointer, behaviorName: string, params, callback: Callback) {
    const behavior: BehaviorObject = {
        type: BEHAVIOR_TYPE,
        targetId: compPointer.id,
        name: behaviorName,
        params
    } as unknown as BehaviorObject
    ps.siteAPI.handleBehavior(behavior, {group: BEHAVIOR_TYPE, callback})
}

function getRuntimeState(ps: PS, compPointer: Pointer) {
    return ps.siteAPI.getRuntimeDal().getCompState(compPointer.id)
}

export default {
    executeBehavior,
    getRuntimeState
}
