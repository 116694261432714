import type {PS} from '@wix/document-services-types'
import constants from '../../constants/constants'
import * as santaCoreUtils from '@wix/santa-core-utils'

function isStretched(ps, componentPointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)
    return santaCoreUtils.dockUtils.isStretched(compLayout)
}

function isHorizontallyStretchedToScreen(ps, componentPointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)
    return santaCoreUtils.dockUtils.isHorizontalDockToScreen(compLayout)
}

function moveDirections(isFullWidth) {
    if (isFullWidth) {
        return [constants.MOVE_DIRECTIONS.VERTICAL]
    }
    return [constants.MOVE_DIRECTIONS.HORIZONTAL, constants.MOVE_DIRECTIONS.VERTICAL]
}

export default {
    mobileConversionConfig: {
        category: 'graphic'
    },

    styleCanBeApplied: true,
    rotatable(ps: PS, compPointer) {
        return !isStretched(ps, compPointer)
    },

    resizableSides(/*ps, compPointer*/) {
        return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
    },

    moveDirections(ps, compPointer) {
        return moveDirections(isStretched(ps, compPointer))
    },

    fullWidth(ps, compPointer) {
        return isHorizontallyStretchedToScreen(ps, compPointer)
    },

    fullWidthByStructure(ps, compStructure) {
        return !!santaCoreUtils.dockUtils.isHorizontalDockToScreen(compStructure.layout)
    },

    canBeStretched: true,

    heightAuto: true
}
