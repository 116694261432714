import {APP_WIDGET_TYPE} from '@wix/document-manager-extensions/src/constants/constants'
import type {Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import component from '../../component/component'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import connections from '../../connections/connections'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import hooks from '../../hooks/hooks'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'
import tpaUtils from '../../tpa/utils/tpaUtils'

function addDependantApps(ps: PS, apps) {
    const refreshedAppsDataWithBuilderWidgets = clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        _.filter(csm, appData => _.includes(apps, appData.appDefinitionId) && appHasBuilderWidgets(appData))
    )
    if (_.includes(apps, 'dataBinding') || !_.isEmpty(refreshedAppsDataWithBuilderWidgets)) {
        const wixCodeAppDefId = getWixCodeAppDefIdIfExists(ps)
        if (wixCodeAppDefId && !_.includes(apps, wixCodeAppDefId)) {
            apps.push(wixCodeAppDefId)
        }
    }
    return apps
}

function getWixCodeAppDefIdIfExists(ps: PS) {
    const wixCodeAppData: any = clientSpecMapService.filterAppsDataByType(ps, 'siteextension')[0]
    return wixCodeAppData ? wixCodeAppData.appDefinitionId : null
}

function hasAppsWithViewerScript(ps: PS) {
    return clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        _.some(
            csm,
            appData =>
                _.get(appData, ['appFields', 'platform', 'viewerScriptUrl']) ||
                _.get(appData, 'type') === 'siteextension'
        )
    )
}

function isBlocksWidgetComponent(widget) {
    return _.has(widget, ['componentFields', 'appStudioFields'])
}

function appHasBuilderWidgets(appData) {
    const widgets = _.values(_.get(appData, 'widgets', {}))
    return _.some(widgets, isBlocksWidgetComponent)
}

function isBlocksAppWidget(ps: PS, compPointer: Pointer) {
    if (component.getType(ps, compPointer) !== APP_WIDGET_TYPE) {
        return false
    }
    const parentCompPointer = component.getContainer(ps, compPointer)
    const {appDefinitionId, widgetId} = dataModel.getDataItem(ps, parentCompPointer)
    if (!appDefinitionId) {
        return false
    }
    const {widgets} = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId) ?? {}
    const widgetComponent = widgets?.[widgetId]
    return isBlocksWidgetComponent(widgetComponent)
}

function addResetRuntimeOverridesForRepeatedItemTemplate(ps: PS, componentPointer) {
    const hasRepeaterAncestor = component
        .getAncestorsFromFull(ps, componentPointer)
        .some(ancestor => component.getType(ps, ancestor) === 'wysiwyg.viewer.components.Repeater')

    if (hasRepeaterAncestor) {
        return {resetRuntime: true}
    }
}

function getCompsIdsToReset(ps: PS, componentPointer) {
    if (component.getType(ps, componentPointer) === 'wysiwyg.viewer.components.Repeater') {
        const compsIdsToReset = _.map(component.getChildren(ps, componentPointer, true), 'id').concat([
            componentPointer.id
        ])
        return {compsIdsToReset, resetRuntime: true}
    }
    const compAncestors = component.getAncestors(ps, componentPointer)
    const repeaterAncestorPointer = compAncestors.find(
        ancestor =>
            component.isExist(ps, ancestor) && // ref component ancestor on mobile is not available until mobile editor create it
            component.getType(ps, ancestor) === 'wysiwyg.viewer.components.Repeater'
    )
    /*can be temple item or repeated item*/
    if (repeaterAncestorPointer) {
        const templateId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(componentPointer.id)
        const repeaterData = dataModel.getRuntimeDataItem(ps, repeaterAncestorPointer)
        if (repeaterData) {
            const items = _.map(repeaterData.items, itemId =>
                santaCoreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(templateId, itemId)
            )
            return {compsIdsToReset: items.concat(repeaterAncestorPointer.id), resetRuntime: true}
        }
    }
    return {compsIdsToReset: [componentPointer.id], resetRuntime: true}
}

function getAppsPartsToRefresh(ps: PS, apps) {
    const controllersToRefresh = apps.reduce(
        (flatControllers, appDefinitionId) =>
            flatControllers.concat(
                getAppControllersOnPage(ps, appDefinitionId, ps.siteAPI.getPrimaryPageId()),
                getAppControllersOnPage(ps, appDefinitionId, constants.MASTER_PAGE_ID)
            ),
        []
    )

    const compsIdsToReset = controllersToRefresh.reduce((flatComponents, controllerRef) => {
        if (tpaUtils.isTpaByCompType(component.getType(ps, controllerRef))) {
            flatComponents.push(controllerRef)
        }
        return flatComponents.concat(_.map(getControllerConnectedComponents(ps, controllerRef), 'id'))
    }, [])

    const controllersDataItemsToRefresh = _.map(controllersToRefresh, componentPointer => {
        const controllerData = dataModel.getDataItem(ps, componentPointer)
        return controllerData.id
    })
    return {apps, compsIdsToReset, controllersToRefresh: controllersDataItemsToRefresh, resetRuntime: true}
}

function getAppControllersOnPage(ps: PS, appDefId: string, pageId: string) {
    const isControllerOnPage = compRef => {
        const compType = component.getType(ps, compRef)
        const isController = connections.isControllerType(compType) || connections.isOOIController(compType)
        const {appDefinitionId, applicationId} = component.data.get(ps, compRef) || {}
        return isController && (appDefinitionId === appDefId || applicationId === appDefId)
    }
    return componentDetectorAPI.getAllComponents(ps, pageId, compRef => isControllerOnPage(compRef))
}

function getControllerConnectedComponents(ps: PS, controllerRef) {
    return connections.getConnectedComponents(ps, controllerRef)
}

export default {
    addDependantApps,
    getWixCodeAppDefIdIfExists,
    hasAppsWithViewerScript,
    isBlocksAppWidget,
    optionsModifierSelfRefresh: getAppsPartsToRefresh,
    optionsModifiersAllApps: {
        [hooks.HOOKS.DATA.UPDATE_AFTER]: getCompsIdsToReset,
        [hooks.HOOKS.PROPERTIES.UPDATE_AFTER]: getCompsIdsToReset,
        [hooks.HOOKS.CONNECTION.AFTER_DISCONNECT]: getCompsIdsToReset,
        [hooks.HOOKS.DATA.AFTER_UPDATE_CONNECTIONS]: getCompsIdsToReset,
        [hooks.HOOKS.CHANGE_PARENT.AFTER]: getCompsIdsToReset
    },
    optionsModifiers: {
        dataBinding: addResetRuntimeOverridesForRepeatedItemTemplate
    }
}
