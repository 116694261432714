import sessionFreezer from '../core/SessionFreezer'

const regularJsonPointerGenerators = {}

const bothRegularAndFullPointerGenerators = {}

const pointersGenerators = {}

const types = {}

sessionFreezer.freeze(regularJsonPointerGenerators)
sessionFreezer.freeze(bothRegularAndFullPointerGenerators)
sessionFreezer.freeze(pointersGenerators)
sessionFreezer.freeze(types)

export default {
    registerDataAccessPointersGenerator(name, pointerGetterFunctions, isUsingDifferentNameSpaceForFull?) {
        if (isUsingDifferentNameSpaceForFull) {
            bothRegularAndFullPointerGenerators[name] = pointerGetterFunctions
        } else {
            regularJsonPointerGenerators[name] = pointerGetterFunctions
        }

        pointersGenerators[name] = pointerGetterFunctions
    },

    registerPointerType(
        name,
        findItemFunction,
        identityCheckFunction,
        isUsingDifferentNameSpaceForFull?,
        isExistInFullJson?,
        supportDisplayedOnlySplit?
    ) {
        types[name] = {
            findItemFunction,
            identityCheckFunction,
            isUsingDifferentNameSpaceForFull,
            isExistInFullJson,
            supportDisplayedOnlySplit
        }
    },

    getRegularJsonGenerators() {
        return regularJsonPointerGenerators
    },

    getBothRegularAndFullJsonGenerators() {
        return bothRegularAndFullPointerGenerators
    },

    getAllTypes() {
        return types
    }
}
