import {CreateExtArgs, Extension, ExtensionAPI, IndexKey, pointerUtils, DmApis, DAL} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {constants} from '..'
import {MASTER_PAGE_ID, VIEWER_DATA_TYPES} from '../constants/constants'
import {getPointerWithoutFallbacksFromPointer, RefDelimiter} from '../utils/refStructureUtils'
import {EVENTS as COMPONENT_EVENTS} from './components'
import type {DataModelExtensionAPI} from './dataModel'
import {createItemGetter} from './data'

const {getRepeatedItemPointerIfNeeded} = pointerUtils

const NO_MATCH: string[] = []
export interface RefOverridesAPI {
    removeAllOverrides(compPointer: Pointer): void
}

export type RefOverridesExtensionAPI = ExtensionAPI & {
    refOverrides: RefOverridesAPI
}

const createExtension = (): Extension => {
    const getOverridesByType = (dal: DAL, dataType: string, queryFilter: IndexKey) => {
        const overrides = dal.query(dataType, queryFilter)
        return _(overrides)
            .values()
            .map(({id, metaData}) => createItemGetter(dataType)(id, _.get(metaData, ['pageId'], MASTER_PAGE_ID)))
            .value()
    }
    const getOverridesFilter = (dal: DAL, compPtr: Pointer) =>
        dal.queryFilterGetters.overridesFilterFactory(getRepeatedItemPointerIfNeeded(compPtr).id)
    const getAllOverrides = (dal: DAL, queryFilter: IndexKey) => {
        return _.reduce(
            VIEWER_DATA_TYPES,
            (result, dataType) => _.concat(result, getOverridesByType(dal, dataType, queryFilter)),
            [] as Pointer[]
        )
    }
    const createExtensionAPI = ({
        pointers,
        eventEmitter,
        extensionAPI,
        dal
    }: CreateExtArgs): RefOverridesExtensionAPI => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const getAllOverridesToBeRemoved = (
            compPointer: Pointer,
            excludeConnectionItems = true,
            excludeNonPropertiesItemsForMobile = false
        ): Pointer[] => {
            const compType = dal.getWithPath(compPointer, 'componentType')
            if (compType !== constants.COMP_TYPES.REF_TYPE) {
                return []
            }

            const overrides = getAllOverrides(dal, getOverridesFilter(dal, compPointer))
            return _(overrides)
                .filter(override =>
                    pointers.structure.isMobile(compPointer) && excludeNonPropertiesItemsForMobile
                        ? override.type === constants.DATA_TYPES.prop
                        : true
                )
                .reject(override =>
                    excludeConnectionItems ? override.type === constants.DATA_TYPES.connections : false
                )
                .map(pointer => getPointerWithoutFallbacksFromPointer(pointer))
                .value() as Pointer[]
        }

        const removeOverrides = (overrides: Pointer[]) => {
            overrides.forEach(pointer => dataModel.removeItemRecursively(pointer))
        }

        const removeAllOverrides = (
            compPointer: Pointer,
            excludeConnectionItems = true,
            excludeNonPropertiesItemsForMobile = false
        ) => {
            const overrides = getAllOverridesToBeRemoved(
                compPointer,
                excludeConnectionItems,
                excludeNonPropertiesItemsForMobile
            )
            removeOverrides(overrides)
        }

        eventEmitter.addListener(COMPONENT_EVENTS.COMPONENTS.BEFORE_REMOVE, removeAllOverrides)
        return {
            refOverrides: {
                removeAllOverrides
            }
        }
    }
    const getReferredStructurePointers = (dal: DAL): any => ({
        getPointerWithoutFallbacks: getPointerWithoutFallbacksFromPointer,
        getAllOverrides: (compPtr: Pointer) => getAllOverrides(dal, getOverridesFilter(dal, compPtr)),
        getConnectionOverrides: (compPtr: Pointer) =>
            getOverridesByType(dal, VIEWER_DATA_TYPES.connections, getOverridesFilter(dal, compPtr))
    })
    const createPointersMethods = ({dal}: DmApis) => ({
        referredStructure: getReferredStructurePointers(dal),
        full: {
            referredStructure: getReferredStructurePointers(dal)
        }
    })

    const createFilters = () => {
        //e.g. 'comp1_r_comp2_r_prop' -> ['comp1', 'comp1_r_comp2']
        const getAllCompIdsFromOverrideId = (overrideId: string) => {
            const refDelimCompIds = overrideId.split(RefDelimiter).slice(0, -1)

            return refDelimCompIds.reduce((result: string[], current: string) => {
                const previousId = result[result.length - 1]
                const compId = previousId ? `${previousId}${RefDelimiter}${current}` : current
                result.push(compId)

                return result
            }, [])
        }

        return {
            overridesFilterFactory: (namespace: string, value: any): string[] => {
                const id = value?.id
                if (id?.includes?.(RefDelimiter)) {
                    return getAllCompIdsFromOverrideId(id)
                }
                return NO_MATCH
            }
        }
    }

    return {
        name: 'refOverrides',
        dependencies: new Set(['structure', 'dataModel']),
        createExtensionAPI,
        createPointersMethods,
        createFilters
    }
}

export {createExtension}
