import _ from 'lodash'
import {DAL, DalItem, DocumentManager, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {RelationshipsAPI} from '@wix/document-manager-extensions/src/extensions/relationships'
import {dataUtils} from '@wix/document-manager-extensions'
import {getFixerRules} from './rules'
import type {FixDescriptor, UpdateReferrer} from './types'
import {getAllPossibleKeys} from './utils'

const {getInnerPointer} = pointerUtils

interface ReferrerDetails {
    value: DalItem
    pointer: Pointer
    updateFunc: UpdateReferrer
}

const pageIdPath = ['metaData', 'pageId']
const getPageId = (dal: DAL, pointer: Pointer) => dal.get(getInnerPointer(pointer, pageIdPath))
const isDuplicateOrCrossPageRef = (dal: DAL, referrers: ReferrerDetails[], pointer: Pointer) =>
    referrers.length > 1 || (referrers.length === 1 && getPageId(dal, pointer) !== getPageId(dal, referrers[0].pointer))

const duplicateItem = (documentManager: DocumentManager, namespace: string, item: DalItem, pageId: string) => {
    const {dal, pointers} = documentManager
    const newId = dataUtils.generateUniqueIdByType(namespace, pageId, dal, pointers)
    const newItem = _.merge(_.cloneDeep(item), {id: newId, metaData: {pageId}})
    documentManager.dal.set({type: namespace, id: newId}, newItem)
    return newId
}

const replaceRefs = (documentManager: DocumentManager, referrers: ReferrerDetails[], refPointer: Pointer) => {
    const {dal} = documentManager
    const ref = dal.get(refPointer)
    _.forEach(referrers, ({value, pointer, updateFunc}) => {
        const {pageId} = value.metaData!
        const newId = duplicateItem(documentManager, refPointer.type, ref, pageId!)
        updateFunc(documentManager, value, pointer, refPointer.id, newId)
    })
}

const executeRule = (documentManager: DocumentManager, pageId: string, rule: FixDescriptor) => {
    const {dal, pointers, logger, extensionAPI} = documentManager
    const {relationships} = extensionAPI as RelationshipsAPI
    const {namespace, schema} = rule
    const refs = pointers.page.getPointersByPageAndSchemaType(schema, namespace, pageId)
    _.forEach(refs, ref => {
        const referrers = _(relationships.getReferencesToPointer(ref))
            .map(referrerPointer => {
                const referrer = dal.get(referrerPointer)
                const schemaKeys = getAllPossibleKeys(referrerPointer.type, referrer.type, referrer.componentType)
                return {
                    value: referrer,
                    pointer: referrerPointer,
                    updateFunc: _(schemaKeys)
                        .map(key => rule.referrerUpdatesMap[key])
                        .find(_.isFunction)!
                }
            })
            .filter('updateFunc')
            .value()

        if (isDuplicateOrCrossPageRef(dal, referrers, ref)) {
            logger.interactionStarted('replaceRefs', {
                extras: {
                    referrers: referrers.length,
                    namespace: rule.namespace,
                    schema: rule.schema,
                    ref
                }
            })

            replaceRefs(documentManager, referrers, ref)
        }
    })
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    const rules = getFixerRules()
    _.forEach(rules, rule => executeRule(documentManager, pageId, rule))
}

const name = 'duplicateAndCrossPageRefFixer'
const version = 1

export {migratePage, name, version}
