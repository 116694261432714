import {
    pointerUtils,
    HistoryItem,
    DocumentDataTypes,
    Extension,
    Transaction,
    CreateExtArgs,
    ExtensionAPI,
    CreateExtensionArgument
} from '@wix/document-manager-core'
import _ from 'lodash'
import {guidUtils} from '@wix/santa-core-utils'
import {TPA_CONSTANTS} from '@wix/document-manager-utils'
const {getGUID: generateGUID} = guidUtils

const {getPointer} = pointerUtils
const NO_MATCH: string[] = []
const EMPTY_COMP_ID = 'EMPTY_COMP_ID'
const MATCH: string[] = [EMPTY_COMP_ID]
const tpaSharedStatePointerType = 'tpaSharedState'

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [tpaSharedStatePointerType]: {}
})

const initialState = {}

export type Syncer = (id: string | string[], data: any) => void
type CompIdFromIdFetcher = (id: string | string[]) => string

export interface TPAExtensionAPI extends ExtensionAPI {
    tpa: {
        setSyncer(type: string, syncer: Syncer, options?: {fetchCompIdFromId(id: string | string[]): string}): void
        sync(type: string, id: string | string[], data: any): void
        isTpaByCompType(type: string): boolean
        fixers: {
            removeItemsWithEmptyCompId(): void
        }
    }
}

const createPointersMethods = () => ({
    tpa: {
        getTpaSharedStatePointer: (hash: string) => getPointer(hash, tpaSharedStatePointerType)
    }
})

const createFilters = () => ({
    getBrokenSharedState: (namespace: string, value: any): string[] => {
        if (namespace !== tpaSharedStatePointerType) {
            return NO_MATCH
        }

        if (_.trim(value?.compId)) {
            return NO_MATCH
        }

        return MATCH
    }
})

const createExtension = ({logger}: CreateExtensionArgument): Extension => {
    interface TpaState {
        type: string
        id: string | string[]
        data: any
        compId: string
        cacheKiller: string
    }
    const methods: {[key: string]: {syncer: Syncer; fetchCompIdFromId(id: string | string[]): string}} = {}
    const localStateByHash = new Map<string, TpaState>()

    const shouldTPAgetUpdatedMessage = (last: TpaState | undefined, curr: TpaState) => {
        const lastCacheKiller = _.get(last, ['cacheKiller'])
        const newCacheKiller = _.get(curr, ['cacheKiller'])
        return lastCacheKiller !== newCacheKiller
    }
    const syncTPA = (syncer: Syncer, curr: TpaState, hash: string) => {
        syncer(curr.id, curr.data)

        localStateByHash.set(hash, curr)
    }

    const createExtensionAPI = ({dal, pointers}: CreateExtArgs): TPAExtensionAPI => ({
        tpa: {
            setSyncer(
                type: string,
                syncer: Syncer,
                options: {fetchCompIdFromId: CompIdFromIdFetcher} = {fetchCompIdFromId: _.identity}
            ) {
                if (methods[type]) {
                    throw new Error(`Syncer for ${type} was already configured`)
                }
                if (typeof syncer !== 'function') {
                    throw new Error(`Syncer for ${type} must be of type function`)
                }

                methods[type] = {
                    syncer,
                    fetchCompIdFromId: options.fetchCompIdFromId
                }
            },
            sync(type: string, id: string | string[], data: any) {
                if (!methods[type]) {
                    return
                }

                const hash = `${type};${_.isArray(id) ? id.join(';') : id}`
                const sharedPointer = pointers.tpa.getTpaSharedStatePointer(hash)

                const curr: TpaState = {
                    data,
                    type,
                    id,
                    compId: methods[type].fetchCompIdFromId(id),
                    cacheKiller: generateGUID()
                }

                dal.set(sharedPointer, curr)
            },
            isTpaByCompType(type: string) {
                return _.includes(TPA_CONSTANTS.COMP_TYPES, type) || _.includes(TPA_CONSTANTS.TPA_COMP_TYPES, type)
            },
            fixers: {
                removeItemsWithEmptyCompId() {
                    const brokenSharedStateFilter = dal.queryFilterGetters.getBrokenSharedState(EMPTY_COMP_ID)
                    const brokenSharedStates = dal.query(tpaSharedStatePointerType, brokenSharedStateFilter)

                    _.forEach(brokenSharedStates, (v, hash) => {
                        dal.remove(pointers.tpa.getTpaSharedStatePointer(hash))
                    })
                }
            }
        }
    })

    const createPostTransactionOperations = () => ({
        tpa: (documentTransaction: Transaction) => {
            const approvedTpaStateItems = _.filter(
                documentTransaction.items,
                e => e?.key?.type === tpaSharedStatePointerType
            )
            _.forEach(approvedTpaStateItems, (item: HistoryItem) => {
                const id = item?.key?.id
                if (!id) {
                    return
                }

                const value = item?.value

                if (!value) {
                    return
                }

                const type = value?.type
                try {
                    if (shouldTPAgetUpdatedMessage(localStateByHash.get(id), value)) {
                        syncTPA(methods[type].syncer, value, item.key.id)
                    }
                } catch (e) {
                    logger.captureError(e as Error, {
                        tags: {
                            dm_tpaStateThroughStore: true,
                            syncerType: type
                        }
                    })
                }
            })
        }
    })

    return {
        name: 'tpa',
        getDocumentDataTypes,
        initialState,
        createExtensionAPI,
        createFilters,
        createPointersMethods,
        createPostTransactionOperations
    }
}

export {createExtension}
