import runSaveTask from '../utils/runSaveTask'
import createSaveCodeTask from '../saveTasks/saveCode'
import appSaveState from './appSaveState'
import * as santaCoreUtils from '@wix/santa-core-utils'
import type {PS} from '@wix/document-services-types'

const finalizeSave = function () {
    try {
        appSaveState.setState(null)
        appSaveState.setAnotherSaveRequested(false)
    } catch (e) {
        santaCoreUtils.log.error(e)
    }
}

function saveWhileThereArePendingSaveRequests(ps: PS, isFullSave: boolean, saveCode) {
    let saveInProgressPromise = appSaveState.getState()
    if (saveInProgressPromise) {
        appSaveState.setAnotherSaveRequested(true)
        return saveInProgressPromise
    }

    saveInProgressPromise = new Promise<void>(function (resolve, reject) {
        runSaveTask(ps, saveCode, isFullSave, onSuccess, onError)

        function onSuccess() {
            if (!appSaveState.getAnotherSaveRequested()) {
                resolve()
                return
            }

            appSaveState.setAnotherSaveRequested(false)
            runSaveTask(ps, saveCode, isFullSave, onSuccess, onError)
        }

        function onError(err) {
            if (!appSaveState.getAnotherSaveRequested()) {
                reject(err)
                return
            }
            appSaveState.setAnotherSaveRequested(false)
            runSaveTask(ps, saveCode, isFullSave, onSuccess, onError)
        }
    })
    saveInProgressPromise.then(finalizeSave, finalizeSave) // eslint-disable-line promise/prefer-await-to-then
    appSaveState.setState(saveInProgressPromise)
    return saveInProgressPromise
}

function save(ps: PS, isFullSave?: boolean) {
    const saveCode = createSaveCodeTask()
    return saveWhileThereArePendingSaveRequests(ps, isFullSave, saveCode)
    //TODO handle reject upon ensureAppIsWritable fail
}

export default {
    save
}
