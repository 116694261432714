import type {CreateExtArgs, DAL, Extension, ExtensionAPI} from '@wix/document-manager-core'
// eslint-disable-next-line no-duplicate-imports
import {pointerUtils} from '@wix/document-manager-core'
import {schemas} from '@wix/document-services-json-schemas'
import type {PartialTextThemeMap, Pointer, Pointers, TextTheme, TextThemeMap} from '@wix/document-services-types'
import _ from 'lodash'
import {MASTER_PAGE_ID} from '../../constants/constants'
import {generateItemIdWithPrefix} from '../../utils/dataUtils'
import type {DefaultDefinitionsAPI} from '../defaultDefinitions/defaultDefinitions'
import {systemStyles} from './defaultValues'
import {deepClone} from '@wix/wix-immutable-proxy'
import Color from 'color'
import {fonts} from '@wix/santa-core-utils'

export interface ThemeAPI extends ExtensionAPI {
    onChange: {
        onThemeChangeAddListener(callback: (changedData: any) => void): void
        removeChangeThemeListener(listenerId: number): void
        onThemeChangeRunCallbacks(changedData: any): void
    }
    ensureDefaultStyleItemExists(compType: string, styleId: string): Pointer
    cloneStyle(styleId: string): Pointer
    getColors(): Colors
    updateColors(colors: Colors): Colors
    getTextTheme(): TextThemeMap | {}
    updateTextTheme(textThemesMap: PartialTextThemeMap): void
}

export interface ThemeExtPI extends ExtensionAPI {
    theme: ThemeAPI
}
const TEXT_THEMES_MAP_KEY = 'font'
export const PROPERTY_TYPE = {
    TEXT_THEME: 'textTheme',
    COLOR: 'color',
    FONT: 'font'
}

const getThemePointer = (pointers: any): Pointer => {
    return pointers.data.getThemeItem('THEME_DATA', MASTER_PAGE_ID)
}

const getCollectionItemPointer = (
    pointers: any,
    collectionType: string,
    index: string | number | undefined
): Pointer => {
    return pointerUtils.getInnerPointer(
        getThemePointer(pointers),
        !_.isNil(index) ? [collectionType, index.toString()] : collectionType
    )
}

const getCollectionPointer = (pointers: Pointers, collectionType: string, index?: number): Pointer => {
    return getCollectionItemPointer(pointers, collectionType, index)
}

function normalizeColorValue(colorValue: string) {
    if (colorValue) {
        const regexRes = /^(rgb|rgba)\(([0-9,\\.]*)\)$/.exec(colorValue)
        colorValue = regexRes?.at(2) ?? colorValue
    }
    return colorValue
}

function getPropIndex(name: string): number {
    const index = name.split('font_')[1] || name.split('color_')[1]
    return parseInt(index, 10)
}

function validateTypeAndKeys(dal: DAL, pointers: Pointers, valuesToMerge: Colors, type: string) {
    if (!PROPERTY_TYPE[type.toUpperCase()]) {
        throw new Error(`Type ${type} is not supported in this extension`)
    }

    if (typeof valuesToMerge !== 'object') {
        throw new Error(`Value "${valuesToMerge}" is not valid.Param should be an object`)
    }

    const allValues = dal.get(getCollectionPointer(pointers, type))

    _.forEach(valuesToMerge, function (val, key) {
        const index = getPropIndex(key)
        if (index === undefined || !(allValues && (allValues[index] || allValues[key]))) {
            throw new Error(`Invalid Key ${key}`)
        }
    })
}

function isThemeColor(value: string): boolean {
    return value.includes('color_')
}

function validateHexColor(hexColor: string): boolean {
    return !!hexColor && /^#(([0-9|a-f|A-F]){3}){1,2}$/.test(hexColor)
}

function rgbaColor(value: string): boolean {
    if (value === null) {
        return true
    }
    const split = value.split(',')

    if (split.length !== 3 && split.length !== 4) {
        return false
    }

    const alpha = parseFloat(split[3])
    const validRgb = _.every(split.slice(0, 3), number => parseInt(number, 10) >= 0 && parseInt(number, 10) <= 255)

    if (!validRgb) {
        return false
    }

    if (alpha) {
        return alpha >= 0 && alpha <= 1
    }

    return true
}

function isColorValid(colorToValidate: string | undefined): boolean {
    if (colorToValidate) {
        const isHexColor = validateHexColor(colorToValidate)
        const isRGBAColor = rgbaColor(colorToValidate)
        return isThemeColor(colorToValidate) || isHexColor || isRGBAColor
    }
    return false
}

function validateColor(colors: Colors) {
    _.forEach(colors, function (val, key) {
        const normalizedColorVal = normalizeColorValue(val)
        if (!isColorValid(normalizedColorVal)) {
            throw new Error(`color value isn't valid ${val} .Please supply or hex/rgb string`)
        }
        colors[key] = normalizedColorVal
    })
}

interface Colors {
    [key: string]: string
}

const mergeOldAndNewColors = (colorsToModify: string[], colors: Colors) => {
    _.mapKeys(colors, (colorValue, colorName) => {
        colorsToModify[getPropIndex(colorName)] = colorValue
    })

    return colorsToModify
}

const setCollection = (pointers: any, dal: DAL, propertyType: string, colors: Colors): void => {
    const pointer = getCollectionPointer(pointers, propertyType)
    const oldColors = _.cloneDeep(dal.get(pointer))
    const newColors = mergeOldAndNewColors(oldColors, colors)
    dal.set(pointer, newColors)
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, extensionAPI, pointers}: CreateExtArgs): ThemeExtPI => {
        const defaultDefinitions = () => extensionAPI.defaultDefinitions as DefaultDefinitionsAPI
        const themeChangeListeners: {listenerId: number; callback: Function}[] = []

        function removeChangeThemeListener(listenerId: number) {
            if (_.isNil(listenerId)) {
                throw new Error(`missing argument - listenerId: ${listenerId}`)
            }
            const indexToRemove = _.findIndex(themeChangeListeners, {listenerId})
            if (indexToRemove === -1) {
                throw new Error(`Value "${listenerId}" is not valid.No listener with this id exist`)
            }
            themeChangeListeners.splice(indexToRemove, 1)
        }

        function onThemeChangeAddListener(callback: (changedData: any) => void) {
            if (typeof callback !== 'function') {
                throw new Error(`Value "${callback}" is not valid.Param should be function`)
            }
            const listenerId = themeChangeListeners.length
            themeChangeListeners.push({listenerId, callback})
            return listenerId
        }

        function onThemeChangeRunCallbacks(changedData: any) {
            _(themeChangeListeners).filter('callback').invokeMap('callback', changedData).value()
        }

        const createSystemSkin = (compType: string, styleId: string, definition: any) => {
            const skinName = definition.styles[styleId]
            const skin = systemStyles[skinName]
            const props = skin ? skin.properties : {}
            const propsSource = skin ? skin.propertiesSource : {}
            return defaultDefinitions().createSystemStyle(styleId, compType, skinName, props, propsSource)
        }

        const addDefaultStyle = (compType: string, stylePointer: Pointer) => {
            const styleId = stylePointer.id
            const definition = schemas.default.allComponentsDefinitionsMap[compType]
            const styleData = createSystemSkin(compType, styleId, definition)
            dal.set(stylePointer, styleData)
        }

        const cloneStyle = (styleId: string): Pointer => {
            const clonedTheme = _.cloneDeep(dal.get(pointerUtils.getPointer(styleId, 'style')))
            const newPointer = pointerUtils.getPointer(generateItemIdWithPrefix('style'), 'style')
            dal.set(newPointer, {...clonedTheme, type: 'ComponentStyle', styleType: 'custom', id: newPointer.id})
            return newPointer
        }

        const ensureDefaultStyleItemExists = (compType: string, styleId: string): Pointer => {
            const stylePointer = {type: 'style', id: styleId}
            if (!dal.has(stylePointer)) {
                addDefaultStyle(compType, stylePointer)
            }
            return stylePointer
        }

        const convertToHex = (colors: Colors) => {
            const valueToHex = (colorString: string) => {
                let colorObj

                if (colorString.indexOf('#') !== 0) {
                    const rgbString = colorString.indexOf('r') === 0 ? colorString : `rgba(${colorString})`
                    colorObj = new Color(rgbString)
                } else {
                    colorObj = new Color(colorString)
                }

                // @ts-ignore
                return colorObj.hexString()
            }

            if (_.isArray(colors)) {
                return _.map(colors, valueToHex)
            } else if (_.isObject(colors)) {
                return _.mapValues(colors, valueToHex)
            }
            return valueToHex(colors)
        }

        const getColors = (): Colors => {
            const colors = getPropertiesAccordingToType(PROPERTY_TYPE.COLOR)
            return convertToHex(colors)
        }

        const getTextThemesMap = (textThemes: TextTheme) => {
            const textThemesMap: TextThemeMap | {} = {}

            _.forEach(textThemes, (textTheme, index) => {
                const key = `${TEXT_THEMES_MAP_KEY}_${index}`
                textThemesMap[key] = textTheme
            })

            return textThemesMap
        }

        const getTextTheme = (): TextThemeMap | {} => {
            const allTextTheme = dal.get(getCollectionPointer(pointers, PROPERTY_TYPE.TEXT_THEME))
            return getTextThemesMap(allTextTheme)
        }

        function getPropertiesAccordingToType(type: string): Record<string, any> {
            const result = {}
            const pointer = getCollectionPointer(pointers, type)
            const getter = deepClone(dal.get(pointer))
            const values = getter ? getter : []
            _.forEach(values, (value, index) => {
                result[`${type}_${index}`] = value
            })
            return result
        }

        const updateColors = (colors: Colors): Colors => {
            validateTypeAndKeys(dal, pointers, colors, PROPERTY_TYPE.COLOR)
            validateColor(colors)
            setCollection(pointers, dal, PROPERTY_TYPE.COLOR, colors)

            onThemeChangeRunCallbacks({
                type: PROPERTY_TYPE.COLOR,
                values: colors
            })
            return colors
        }
        const validateGetterAndKey = (valuesToMerge: PartialTextThemeMap) => {
            if (typeof valuesToMerge !== 'object') {
                throw new Error(`Value "${valuesToMerge}" is not valid.Param should be an object`)
            }
            const allValues = getTextTheme()
            _.forEach(valuesToMerge, function (val, key) {
                const index = getPropIndex(key)
                if (index === undefined || !(allValues[index] || allValues[key])) {
                    throw new Error(`Invalid Key ${key}`)
                }
            })
        }
        const setCollectionItem = (collectionType: string, index: number, value: any) => {
            const itemPointer = getCollectionItemPointer(pointers, collectionType, index)
            dal.set(itemPointer, value)
        }
        const setTextThemeToData = (valuesMap: PartialTextThemeMap) => {
            _.forEach(valuesMap, (value, name) => {
                const index = getPropIndex(name)
                const fontString = fonts.textThemeToFontString(value)
                setCollectionItem(PROPERTY_TYPE.TEXT_THEME, index, value)
                setCollectionItem(PROPERTY_TYPE.FONT, index, fontString)
            })
        }

        const updateTextTheme = (updates: PartialTextThemeMap) => {
            validateGetterAndKey(updates)
            _.forEach(updates, (textTheme: Partial<TextTheme> | undefined) => {
                dal.schema.validate('TextTheme', textTheme, 'style')
            })
            setTextThemeToData(updates)
            onThemeChangeRunCallbacks({
                type: PROPERTY_TYPE.TEXT_THEME,
                values: updates
            })
        }

        return {
            theme: {
                onChange: {
                    onThemeChangeAddListener,
                    removeChangeThemeListener,
                    onThemeChangeRunCallbacks
                },
                ensureDefaultStyleItemExists,
                cloneStyle,
                getColors,
                getTextTheme,
                updateTextTheme,
                updateColors
            }
        }
    }

    return {
        name: 'theme',
        createExtensionAPI
    }
}

export {createExtension}
