import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import routersGetters from '../routers/routersGetters'

export type PageDataChangedCallback = (
    ps: PS,
    pageId: string,
    changedData: any,
    useOriginalLanguage?: boolean,
    applyChangeToAllLanguages?: boolean
) => void

export type PageAddedCallback = (ps: PS, pageId: string, addMenuItem: boolean) => void

let pageDataChangedCallback: PageDataChangedCallback, pageAddedCallback: PageAddedCallback

// checking if environment supports unicode. IE11 does not
// eslint-disable-next-line prefer-regex-literals
const unicodeSupported = !_.isUndefined(RegExp('').unicode)
const getIllegalCharacterPattern = () => (unicodeSupported ? /[^\p{L}0-9\-]/gu : /[^a-zA-Z0-9\-]/g)
const getStartingIllegalCharactersPattern = () => (unicodeSupported ? /^[^\p{L}0-9]*/gu : /^[a-zA-Z0-9]*/g)
const getEndingIllegalCharactersPattern = () => (unicodeSupported ? /[^\p{L}0-9]*$/gu : /[^a-zA-Z0-9]*$/g)

const uriHasIllegalCharacters = (pageUriSEO: string) => getIllegalCharacterPattern().test(pageUriSEO)

function registerPageDataChangedCallback(callback: PageDataChangedCallback) {
    pageDataChangedCallback = callback
}

function executePageDataChangedCallback(
    ps: PS,
    pageId: string,
    data,
    useOriginalLanguage = false,
    applyChangeToAllLanguages = false
) {
    if (_.isFunction(pageDataChangedCallback)) {
        pageDataChangedCallback(ps, pageId, data, useOriginalLanguage, applyChangeToAllLanguages)
    }
}

function registerPageAddedCallback(callback: PageAddedCallback) {
    pageAddedCallback = callback
}

function executePageAddedCallback(ps: PS, pageId: string, addMenuItem: boolean) {
    if (pageAddedCallback) {
        pageAddedCallback(ps, pageId, addMenuItem)
    }
}

function getHomepageId(ps: PS) {
    return ps.extensionAPI.page.getMainPageId()
}

function isHomepage(ps: PS, pageId: string) {
    return pageId === getHomepageId(ps)
}

function isMasterPage(ps: PS, pageId: string) {
    return pageId === 'masterPage'
}

function convertPageNameToUrl(ps: PS, title: string = '') {
    const fixedTitle = title ? title : '' // defaulting to '' for every falsy value
    const uri = fixedTitle
        .toLowerCase()
        .trim()
        .replace(getStartingIllegalCharactersPattern(), '')
        .replace(getEndingIllegalCharactersPattern(), '')
        .replace(/\s+/g, '-')
        .replace(getIllegalCharacterPattern(), '-')
        .replace(/-+/gu, '-')

    return uri || 'blank'
}

function getCurrentUrl(ps: PS, urlFormat: string, baseUrl: string) {
    return getPageUrl(ps, {pageId: ps.siteAPI.getCurrentUrlPageId()}, urlFormat, baseUrl)
}

function getMainPageUrl(ps: PS, urlFormat: string, baseUrl: string) {
    return getPageUrl(ps, {pageId: getHomepageId(ps)}, urlFormat, baseUrl)
}

function getPageUrl(ps: PS, pageInfo, urlFormat: string, baseUrl: string) {
    return ps.siteAPI.getPageUrl(pageInfo, urlFormat, baseUrl)
}

function isDynamicPage(ps: PS, pageId: string) {
    return !!routersGetters.get.byPage(ps, ps.pointers.data.getDataItemFromMaster(pageId))
}

function isLandingPage(ps: PS, pageId: string) {
    const pageDataPointer = ps.pointers.data.getDataItemFromMaster(pageId)
    const isLandingPagePointer = ps.pointers.getInnerPointer(pageDataPointer, 'isLandingPage')
    const isMobileLandingPagePointer = ps.pointers.getInnerPointer(pageDataPointer, 'isMobileLandingPage')
    const isMobile = ps.siteAPI.isMobileView()
    const isLandingPageVal = ps.dal.isExist(isLandingPagePointer) && ps.dal.get(isLandingPagePointer)
    const isMobileLandingPage =
        isMobile && ps.dal.isExist(isMobileLandingPagePointer) && ps.dal.get(isMobileLandingPagePointer)

    return isMobile ? isMobileLandingPage : isLandingPageVal
}

export default {
    getHomepageId,
    isMasterPage,
    isHomepage,
    registerPageDataChangedCallback,
    executePageDataChangedCallback,
    registerPageAddedCallback,
    executePageAddedCallback,
    convertPageNameToUrl,
    getCurrentUrl,
    getMainPageUrl,
    getPageUrl,
    isDynamicPage,
    uriHasIllegalCharacters,
    isLandingPage
}
