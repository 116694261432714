import type {Pointer, PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import _ from 'lodash'
import constants from '../platform/common/constants'
import dsUtils from '../utils/utils'
import appControllerDataItem from './appControllerDataItem'
import appControllerState from './appControllerState'
import appControllerUtils from './appControllerUtils'

function getControllerTypeAndApplicationId(ps: PS, controllerRef: Pointer) {
    if (experiment.isOpen('dm_moveControllerStageDataToExt')) {
        return ps.extensionAPI.appController.getControllerTypeAndApplicationId(controllerRef)
    }
    const {applicationId, controllerType, widgetId, appDefinitionId} =
        appControllerDataItem.getControllerDataItem(ps, controllerRef) || {}
    const ooiController = appControllerUtils.isOOIController(ps, dsUtils.getComponentType(ps, controllerRef))
    return {
        controllerType: ooiController ? widgetId : controllerType,
        applicationId: ooiController ? appDefinitionId : applicationId
    }
}

function getDisabledActionsConfig() {
    const behavior = {
        duplicatable: false,
        canReparent: false,
        rotatable: false,
        pinnable: false,
        resizable: false,
        toggleShowOnAllPagesEnabled: false
    }
    return {
        behavior,
        connections: {
            '*': {
                behavior
            }
        }
    }
}

/**
 * @param ps
 * @param controllerId
 * @param controllerType
 * @param applicationId
 * @returns {any|{behavior: {canReparent: boolean, duplicatable: boolean, rotatable: boolean, pinnable: boolean, toggleShowOnAllPagesEnabled: boolean, resizable: boolean}, connections: {'*': {behavior: {canReparent: boolean, duplicatable: boolean, rotatable: boolean, pinnable: boolean, toggleShowOnAllPagesEnabled: boolean, resizable: boolean}}}}}
 */
function getControllerStageData(ps: PS, controllerId: string, controllerType: string, applicationId: string) {
    if (experiment.isOpen('dm_moveControllerStageDataToExt')) {
        return ps.extensionAPI.appController.getControllerStageData(controllerId, controllerType, applicationId)
    }
    const applicationHasAppManifest = hasAppManifest(ps, applicationId)
    if (!applicationHasAppManifest) {
        return getDisabledActionsConfig()
    }

    const setDefaultConnectionIntoConnections = state => {
        const stateConnections = _.get(state, constants.Controller.CONNECTIONS)
        const stateDefaultConnection = _.get(stateConnections, constants.Controller.WILDCARD_ROLE)
        const setDefaultConnection = connection => _.defaultsDeep({}, connection, stateDefaultConnection)
        if (stateDefaultConnection) {
            _.set(state, constants.Controller.CONNECTIONS, _.mapValues(stateConnections, setDefaultConnection))
        }
        return state
    }

    const nonDefaultStateWithDefaults = (state, defaultState) => {
        const stateConnections = _.get(state, constants.Controller.CONNECTIONS)
        const defaultStateConnections = _.get(defaultState, constants.Controller.CONNECTIONS)
        const setDefaultsIntoOverrides = (connection, connectionKey) =>
            _.defaultsDeep({}, connection, defaultStateConnections[connectionKey])
        if (stateConnections && defaultStateConnections) {
            const stateConnectionsWithDefaults = _.mapValues(stateConnections, setDefaultsIntoOverrides)
            _.set(
                state,
                constants.Controller.CONNECTIONS,
                _.defaultsDeep(stateConnectionsWithDefaults, defaultStateConnections)
            )
        }
        return _.defaultsDeep({}, state, defaultState)
    }

    const controllerState = appControllerState.getState(ps, controllerId)
    const controllerStageDataPointer = ps.pointers.platform.getControllerStageDataPointer(
        applicationId,
        controllerType,
        controllerState
    )
    const stateStageData = setDefaultConnectionIntoConnections(ps.dal.get(controllerStageDataPointer))

    if (controllerState === constants.Controller.DEFAULT_STATE) {
        return stateStageData
    }

    const defaultStageDataPointer = ps.pointers.platform.getControllerStageDataPointer(
        applicationId,
        controllerType,
        constants.Controller.DEFAULT_STATE
    )
    const defaultStageData = setDefaultConnectionIntoConnections(ps.dal.get(defaultStageDataPointer))

    return nonDefaultStateWithDefaults(stateStageData, defaultStageData)
}

function hasAppManifestByControllerRef(ps: PS, controllerRef: Pointer): boolean {
    const {applicationId} = getControllerTypeAndApplicationId(ps, controllerRef)
    return hasAppManifest(ps, applicationId)
}

function getControllerStageDataByControllerRef(ps: PS, controllerRef: Pointer) {
    if (experiment.isOpen('dm_moveControllerStageDataToExt')) {
        return ps.extensionAPI.appController.getControllerStageDataByControllerRef(controllerRef)
    }
    if (!controllerRef) {
        return
    }
    const {applicationId, controllerType} = getControllerTypeAndApplicationId(ps, controllerRef)
    return getControllerStageData(ps, controllerRef.id, controllerType, applicationId)
}

function getControllerRoleStageDataByStateRefAndType(ps: PS, stateRef, controllerType: string, appDefId: string) {
    return ps.extensionAPI.appController.getControllerStageDataByStateRefAndType(stateRef, controllerType, appDefId)
}

/**
 * @param ps
 * @param controllerId
 * @param controllerType
 * @param applicationId
 * @param role
 * @param subRole
 * @returns {any| {behavior: {canReparent: boolean, duplicatable: boolean, rotatable: boolean, pinnable: boolean, toggleShowOnAllPagesEnabled: boolean, resizable: boolean}}}
 */
function getControllerStageDataByRole(
    ps: PS,
    controllerId: string,
    controllerType: string,
    applicationId: string,
    role: string,
    subRole: string
) {
    const applicationHasAppManifest = hasAppManifest(ps, applicationId)
    if (!applicationHasAppManifest) {
        return null
    }

    const getSubRoleData = (state: string) =>
        subRole
            ? ps.dal.get(ps.pointers.platform.getControllerRolePointer(applicationId, controllerType, state, subRole))
            : null

    const roleWithDefault = (state: string) => {
        const roleStageDataPointer = ps.pointers.platform.getControllerRolePointer(
            applicationId,
            controllerType,
            state,
            role
        )
        const defaultRoleStageDataPointer = ps.pointers.platform.getControllerRolePointer(
            applicationId,
            controllerType,
            state,
            constants.Controller.WILDCARD_ROLE
        )
        const roleStageData = ps.dal.get(roleStageDataPointer)
        const defaultRoleStageData = ps.dal.get(defaultRoleStageDataPointer)
        const subRoleData = getSubRoleData(state)
        if (!roleStageData && !defaultRoleStageData && !subRoleData) {
            return null
        }
        return _.defaultsDeep({}, subRoleData, roleStageData, defaultRoleStageData)
    }

    const controllerState = appControllerState.getState(ps, controllerId)
    const roleStageDataWithDefaultRoleData = roleWithDefault(controllerState)

    if (controllerState === constants.Controller.DEFAULT_STATE) {
        return roleStageDataWithDefaultRoleData
    }

    const roleInDefaultStateWithDefaultRoleData = roleWithDefault(constants.Controller.DEFAULT_STATE)
    if (!roleStageDataWithDefaultRoleData && !roleInDefaultStateWithDefaultRoleData) {
        return null
    }
    return _.defaultsDeep({}, roleStageDataWithDefaultRoleData, roleInDefaultStateWithDefaultRoleData)
}

function getControllerRoleStageDataByControllerRefAndRole(ps: PS, controllerRef, role: string, subRole: string) {
    if (!controllerRef || !role) {
        return
    }
    const {applicationId, controllerType} = getControllerTypeAndApplicationId(ps, controllerRef)
    return getControllerStageDataByRole(ps, controllerRef.id, controllerType, applicationId, role, subRole)
}

function hasAppManifest(ps: PS, applicationId: string): boolean {
    if (experiment.isOpen('dm_moveControllerStageDataToExt')) {
        return ps.extensionAPI.appController.hasAppManifest(applicationId)
    }
    const appManifestPointer = ps.pointers.platform.getAppManifestPointer(applicationId)
    return ps.dal.isExist(appManifestPointer)
}

export default {
    getControllerStageDataByControllerRef,
    getControllerRoleStageDataByControllerRefAndRole,
    getControllerRoleStageDataByStateRefAndType,
    // privateAPI
    getControllerStageData,
    hasAppManifestByControllerRef
}
