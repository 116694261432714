import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../dataModel/dataModel'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import constants from '../constants/constants'
import documentServicesSchemas from 'document-services-schemas'
import dsUtils from '../utils/utils'

const {schemasService} = documentServicesSchemas.services

function getDesktopPointer(ps: PS, compPointer: Pointer) {
    return ps.pointers.full.components.getDesktopPointer(compPointer)
}

function getMobilePointer(ps: PS, compPointer: Pointer) {
    return ps.pointers.full.components.getMobilePointer(compPointer)
}

function shouldGetDesktopComp(ps: PS, compPointer: Pointer) {
    return !componentsMetaData.public.isMobileOnly(ps, compPointer)
}

/**
 * Resets the properties item for the mobile component.
 * @param {ps} ps
 * @param {Pointer} mobileCompPointer
 */
function resetMobileComponentProperties(ps: PS, mobileCompPointer: Pointer) {
    if (!ps || !mobileCompPointer || !shouldGetDesktopComp(ps, mobileCompPointer)) {
        return
    }

    const equivalentCompInDesktop = getDesktopPointer(ps, mobileCompPointer)
    const desktopPropsItem = dataModel.getPropertiesItem(ps, equivalentCompInDesktop)

    dataModel.deletePropertiesItem(ps, mobileCompPointer)
    dataModel.updatePropertiesItem(ps, mobileCompPointer, desktopPropsItem)
}

function splitMobileComponentProperties(ps: PS, compPointer: Pointer) {
    if (
        !ps ||
        !compPointer ||
        !shouldGetDesktopComp(ps, compPointer) ||
        isMobileComponentPropertiesSplit(ps, compPointer)
    ) {
        return
    }
    const equivalentCompInDesktopPointer = getDesktopPointer(ps, compPointer)
    const clonedDesktopPropsItem = dataModel.getPropertiesItem(ps, equivalentCompInDesktopPointer)
    const desktopProps = ps.dal.full.get(ps.pointers.getInnerPointer(equivalentCompInDesktopPointer, 'propertyQuery'))
    const desktopPropsItemId = desktopProps && dsUtils.stripHashIfExists(desktopProps)
    const mobilePropsItemId = constants.DOM_ID_PREFIX.MOBILE + desktopPropsItemId
    const mobileCompPointer = getMobilePointer(ps, compPointer)

    dataModel.setPropertiesItem(ps, mobileCompPointer, clonedDesktopPropsItem, mobilePropsItemId)
    ps.dal.set(ps.pointers.getInnerPointer(mobileCompPointer, 'propertyQuery'), mobilePropsItemId)
}

/**
 * Checks if component has split properties - mobile and desktop.
 * @param {ps} ps
 * @param {Pointer} compPointer
 * @returns {boolean} true if component has split properties
 */
function isMobileComponentPropertiesSplit(ps: PS, compPointer: Pointer) {
    return isSplit(ps, compPointer, dataModel.getPropertyItemPointer)
}

function isMobileDesignDataSplit(ps: PS, compPointer: Pointer) {
    return isSplit(ps, compPointer, dataModel.getDesignItemPointer)
}

function isSplit(ps: PS, compPointer: Pointer, dataItemGetterFunction: (ps: PS, pointer: Pointer) => Pointer) {
    if (componentsMetaData.public.isMobileOnly(ps, compPointer)) {
        return true
    }

    const mobileDataItemId = dataItemGetterFunction(ps, getMobilePointer(ps, compPointer))?.id
    const desktopDataItemId = dataItemGetterFunction(ps, getDesktopPointer(ps, compPointer))?.id

    return !_.isEqual(mobileDataItemId, desktopDataItemId)
}

function getComponentDataSchemasByType(ps: PS, componentType: string) {
    if (ps && componentType) {
        const compDefinition = schemasService.getDefinition(componentType)
        if (!compDefinition) {
            throw new Error(`component type: ${componentType} is unsupported`)
        }
        const dataTypes = compDefinition.dataTypes || []
        return _.map(dataTypes, dataType => dataModel.getDataSchemaByType(ps, dataType))
    }
    return null
}

function getComponentPropertiesByType(ps: PS, componentType: string) {
    if (ps && componentType) {
        const compDefinition = schemasService.getDefinition(componentType)
        if (!compDefinition) {
            throw new Error(`component type: ${componentType} is unsupported`)
        }
        const propType = _(compDefinition.propertyTypes || [])
            .without('')
            .find()
        if (propType) {
            return dataModel.getPropertiesSchemaByType(ps, propType)
        }
    }
    return null
}

export default {
    getComponentDataSchemasByType,
    getComponentPropertiesByType,
    /**
     * Resets a separation of a component properties in mobile & desktop structures if exists.
     *
     * @function
     * @memberof documentServices.components.componentData
     *
     * @param {Object} mobileCompRef a reference to a Component in the Mobile structure.
     * @returns undefined
     *
     *      @example
     *      documentServices.components.properties.mobile.join(mobileComponentRef);
     */
    resetMobileComponentProperties,
    /**
     * Performs a separation of the properties of a component from its mobile structure and desktop.
     * (new properties data item will be created for the mobile structure, separated from the desktop's).
     *
     * @function
     * @memberof documentServices.components.componentData
     *
     * @param {Object} mobileCompRef a reference to a Component in the Mobile structure.
     * @returns undefined
     *
     *      @example
     *      documentServices.components.properties.mobile.fork(mobileComponentRef);
     */
    splitMobileComponentProperties,
    /**
     * Checks if separation exists between component Desktop & Mobile Properties items.
     *
     * @function
     * @memberof documentServices.components.componentData
     *
     * @param {Object} mobileCompRef a reference to a Component in the Mobile structure.
     * @returns {Boolean} true iff the corresponding Component's Mobile Properties & Desktop Properties Items are different instances.
     */
    isMobileComponentPropertiesSplit,
    isMobileDesignDataSplit
}
