const getAppComponents = app => app?.appFields?.appService?.components || app?.components || app?.data?.components || []

const hasCodePackage = app => {
    const components = getAppComponents(app)
    return components.some(comp => comp.type === 'CODE_PACKAGE')
}

export default {
    getAppComponents,
    hasCodePackage
}
