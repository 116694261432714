import _ from 'lodash'
import * as coreUtils from '@wix/santa-core-utils'

const viewNamesByAppPartName = {}

viewNamesByAppPartName[coreUtils.blogAppPartNames.FEED] = [
    'MasonryPage',
    'MediaBottomPage',
    'MediaLeftPage',
    'MediaRightPage',
    'MediaTopPage',
    'MediaZigzagPage'
]

viewNamesByAppPartName[coreUtils.blogAppPartNames.CUSTOM_FEED] = [
    'Masonry',
    'MediaBottom',
    'MediaLeft',
    'MediaRight',
    'MediaTop',
    'MediaZigzag'
]

export default {
    getBlogPaginationCustomizationsByAppPartName(appPartName) {
        return _.map(viewNamesByAppPartName[appPartName], viewName => ({
            type: 'AppPartCustomization',
            forType: 'Array',
            format: '*',
            view: viewName,
            fieldId: 'vars',
            key: 'pageNavigationType',
            value: 'pagination'
        }))
    }
}
