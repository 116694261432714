import type {AppDefinitionId, PS} from '@wix/document-services-types'
import _ from 'lodash'
import workerService from './workerService'
import platformEventsService from './platformEventsService'
import type {PlatformEvent} from '@wix/platform-editor-sdk/lib/platformEvents.min'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'

function notifyApplication(
    ps: PS,
    appDefinitionId: AppDefinitionId,
    options: Partial<PlatformEvent>,
    isAmendableAction?: boolean
) {
    if (isAmendableAction) {
        _.set(options, 'eventPayload.historySnapshotId', ps.runtimeConfig.nextHistorySnapshotId)
    }
    workerService.triggerEvent(ps, appDefinitionId, options)
    const applicationId = clientSpecMapService.getApplicationIdFromAppDefinitionId(ps, appDefinitionId)
    platformEventsService.propagateGeneralEvent(applicationId, options.eventType, options.eventPayload)
}

export type NotifyApplication = typeof notifyApplication
export default {
    notifyApplication
}
