import type {BoltSiteAPI, BoltViewerManager, ViewerManager, ViewerManagerActions, ViewerSiteAPI} from './types'
import type {Callback, ResponsiveViewerAPI, TransitionDef} from '@wix/viewer-manager-interface'
import _ from 'lodash'
import type {Padding, Pointer, Rect, ServiceTopology} from '@wix/document-services-types'
import {animateScroll, getScroll, setScroll, setScrollAndScale} from './windowScroll'
import {pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils

const mockFn =
    (methodName: string, returnedValue: any = undefined) =>
    () => {
        console.warn(`Mocked responsive method '${methodName}' was called`)
        return returnedValue
    }

// currently bolt-viewer-manager is provided as separate bundle for responsive
// where those methods are implemented. In case we got classic bundle, we
// should mock methods
const getResponsiveMockedApis = (): ResponsiveViewerAPI => ({
    getScrollHeight: mockFn('getScrollHeight', null),
    getClientHeight: mockFn('getClientHeight', null),
    getScrollWidth: mockFn('getScrollWidth', null),
    getClientWidth: mockFn('getClientWidth', null),
    getGridMeasures: mockFn('getGridMeasures', {}),
    detach: mockFn('detach'),
    detachMulti: mockFn('detachMulti'),
    updateDetached: mockFn('updateDetached'),
    updateDetachedRotation: mockFn('updateDetachedRotation'),
    updateDetachedTransformation: mockFn('updateDetachedTransformation'),
    clearDetached: mockFn('clearDetached')
})

const buildBoltActionsAPI = (bolt: BoltViewerManager): ViewerManagerActions => ({
    runInBatch: (callback: Callback) => bolt.boltInstance.$runInBatch(callback)
})

const promise = (register: (reason?: any) => void) => () => new Promise(register)
const waitIfNeeded = (tester: () => boolean, promiseFactory: () => Promise<any>) =>
    tester() ? promiseFactory() : Promise.resolve()

/** Wait for view mode switch, if one in pending
 *
 * @param {ViewerSiteAPI}  viewerSiteAPI
 * @returns {Promise<void>}
 */
const waitForViewModeSwitch = (viewerSiteAPI: BoltSiteAPI) =>
    waitIfNeeded(viewerSiteAPI.isDuringViewModeSwitch, promise(viewerSiteAPI.registerToNextSwitchViewModeDone))

/** Wait for navigation, if one is pending
 *
 * @param {ViewerSiteAPI}  viewerSiteAPI
 * @returns {Promise<void>}
 */
const waitForNavigation = (viewerSiteAPI: BoltSiteAPI) =>
    waitIfNeeded(() => viewerSiteAPI.isDuringNavigationInteraction(), promise(viewerSiteAPI.registerNavigationComplete))

/** Wait for layout, if one is pending
 *
 * @param {ViewerSiteAPI}  viewerSiteAPI
 * @returns {Promise<void>}
 */
const waitForLayout = (viewerSiteAPI: BoltSiteAPI) =>
    waitIfNeeded(viewerSiteAPI.isLayoutPending, promise(viewerSiteAPI.registerToNextLayoutDone))

const createWaitForViewer = (boltSiteAPI: BoltSiteAPI) => async () => {
    await waitForViewModeSwitch(boltSiteAPI)
    await waitForNavigation(boltSiteAPI)
    await boltSiteAPI.waitForDataRequirements()
    await waitForLayout(boltSiteAPI)
}

const createRegisterToLayoutApis = (boltSiteAPI: BoltSiteAPI) => ({
    registerToNextRenderDone: boltSiteAPI.registerToNextLayoutDone,
    registerToRenderDone: boltSiteAPI.registerToLayoutDone,
    registerToComponentsLayoutChange: boltSiteAPI.registerToLayoutDone
})

const createScrollApis = (boltSiteAPI: BoltSiteAPI) => ({
    setScroll: setScroll.bind(null, boltSiteAPI),
    getScroll: getScroll.bind(null, boltSiteAPI),
    setScrollAndScale: setScrollAndScale.bind(null, boltSiteAPI),
    animateScroll: animateScroll.bind(null, boltSiteAPI)
})

export const extendBoltSiteAPI = (bolt: BoltViewerManager): ViewerSiteAPI => {
    const convertIdToPointer = (id: string) => {
        return getPointer(id, 'DESKTOP')
    }

    return _.defaults(
        {
            getComponentsUnderXY: (x: number, y: number): Pointer[] =>
                bolt.siteAPI.getComponentsUnderXY(x, y).map(convertIdToPointer),
            isComponentRenderedOnSite: (compPointer: Pointer): boolean =>
                bolt.siteAPI.isComponentRenderedOnSite(compPointer.id),
            getComponentBoundingBox: (compPointer: Pointer): ClientRect =>
                bolt.siteAPI.getComponentBoundingBox(compPointer.id),
            getComponentInnerElementBoundingBoxes: (compPointer: Pointer, selector: string): ClientRect[] =>
                bolt.siteAPI.getComponentInnerElementBoundingBoxes(compPointer.id, selector),
            getRelativeToViewportBoundingBox: (compPointer: Pointer): ClientRect =>
                bolt.siteAPI.getRelativeToViewportBoundingBox(compPointer.id),
            getPadding: (compPointer: Pointer): Padding => bolt.siteAPI.getPadding(compPointer.id),
            getBasicMeasureForComp: (compPointer: Pointer): Rect => bolt.siteAPI.getBasicMeasureForComp(compPointer.id),
            previewAnimation: (
                compPointer: Pointer,
                pageId: string,
                animationDef: TransitionDef,
                onComplete: Callback
            ): string | null => bolt.siteAPI.previewAnimation(compPointer.id, pageId, animationDef, onComplete)
        },
        {
            getViewerFragment: (reactHost: any, serviceTopology: ServiceTopology) =>
                bolt.getBoltFragment(reactHost, serviceTopology)
        },
        bolt.siteAPI,
        {
            waitForViewer: createWaitForViewer(bolt.siteAPI),
            responsive: getResponsiveMockedApis(),
            ...createRegisterToLayoutApis(bolt.siteAPI),
            ...createScrollApis(bolt.siteAPI)
        }
    ) as unknown as ViewerSiteAPI
}

export const createBoltAPIAdapter = (bolt: BoltViewerManager): ViewerManager =>
    _.merge(
        // important: we can not mutate this, high probability that viewer instance
        // is already provided and used somewhere else (for example testkits)
        // fix this we will try here {@see DM-3262}
        bolt,
        {
            viewerSiteAPI: extendBoltSiteAPI(bolt),
            actions: buildBoltActionsAPI(bolt)
        }
    ) as ViewerManager
