import baseConfig from './baseConfig'
import {serverCsaveExtensions} from './csaveConfigs'

const modulesList: string[] = []

const extensionsList: string[] = [...baseConfig.extensionsList, ...serverCsaveExtensions, 'seo', 'advancedSeo']

export default {
    modulesList,
    extensionsList,
    shouldRender: true,
    isReadOnly: false,
    noUndo: false,
    runStylesGC: false,
    supportMobile: true,
    postUpdateOperation: true,
    disableCommonConfig: true,
    continuousSave: true,
    dontCollectFixerVersionData: true,
    disableCSaveValidationOnInitialization: true,
    keepMultiLingualModelsFromServer: true
}
