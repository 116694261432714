'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

let brokenButtonStyleIds = null //eslint-disable-line @wix/santa/no-module-state

module.exports = {
    name: 'appPartBrokenButtonStyleFixer',
    version: 1,
    exec(pageJson) {
        // Styles are stored in the master page that is processed first by data fixers.
        if (_.isNull(brokenButtonStyleIds)) {
            brokenButtonStyleIds = []

            _.forEach(pageJson.data.theme_data, (style, styleId, styleById) => {
                const buttonStyleIsBroken = _.matches({
                    componentClassName: 'wysiwyg.viewer.components.SiteButton',
                    skin: 'wysiwyg.viewer.skins.AppPartSkin'
                })(style)

                if (buttonStyleIsBroken) {
                    brokenButtonStyleIds.push(styleId)
                    delete styleById[styleId]
                }
            })

            // Data fixers run synchronously.
            // Store broken button style IDs collected on the master page for use on other pages.
            // Free memory at the end.
            setTimeout(() => {
                brokenButtonStyleIds = null
            })
        }

        _(pageJson.data.document_data)
            .filter(
                compData => compData.type === 'AppPart' && _.includes(coreUtils.blogAppPartNames, compData.appPartName)
            )
            .forEach(compData => {
                _.remove(
                    compData.appLogicCustomizations,
                    customization =>
                        customization.key === 'comp.style' && _.includes(brokenButtonStyleIds, customization.value)
                )
            })
    }
}
