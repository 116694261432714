import type {Pointer, PS} from '@wix/document-services-types'
import constants from '../constants/constants'
import pageData from '../page/pageData'

const {
    DATA_TYPES,
    MASTER_PAGE_ID,
    ATOMIC_SCOPES: {TYPE, URI_PREFIX}
} = constants

const getAtomicScopePointer = (ps: PS, id: string) =>
    ps.pointers.data.getItem(DATA_TYPES.atomicScopes, id, MASTER_PAGE_ID)

const addAtomicScope = (ps: PS, id: string) => {
    const data = {type: TYPE, id}
    const pointer = getAtomicScopePointer(ps, id)
    ps.dal.set(pointer, data)
}

const removeAtomicScope = (ps: PS, id: string) => {
    const pointer = getAtomicScopePointer(ps, id)
    ps.dal.remove(pointer)
}

const changeAtomicScope = (ps: PS, oldId: string, newId: string) => {
    removeAtomicScope(ps, oldId)
    addAtomicScope(ps, newId)
}

const getPageUriAtomicScopeId = (ps: PS, pageId: string) => {
    const pageUriSEO = pageData.getPageUriSEO(ps, pageId)
    return `${URI_PREFIX}-${pageUriSEO}`
}

const getRouterAtomicScopeId = (ps: PS, routerPointer: Pointer) => {
    const routerData = ps.dal.get(routerPointer)
    const {prefix} = routerData
    return `${URI_PREFIX}-${prefix}`
}

export default {
    addAtomicScope,
    removeAtomicScope,
    changeAtomicScope,
    getAtomicScopePointer,
    getPageUriAtomicScopeId,
    getRouterAtomicScopeId
}
