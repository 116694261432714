import siteMetadata from '../../siteMetadata/siteMetadata'
import appPart2ComponentHooks from './appPart2ComponentHooks'
import blogPaginationSwitch from './blogPaginationSwitch'
import pathUtils from './pathUtils'
import useNewBlogSocialShareButtons from './useNewBlogSocialShareButtons'

function initialize(ps) {
    pathUtils.initBasePaths(ps)
    appPart2ComponentHooks.registerHooks()

    blogPaginationSwitch.switchOnBlogPaginationIfSiteIsNew(ps)

    if (siteMetadata.generalInfo.isFirstSave(ps)) {
        useNewBlogSocialShareButtons(ps)
    }
}

export default {
    initialize
}
