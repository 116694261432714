import type {Pointer, PS} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import * as santaCoreUtils from '@wix/santa-core-utils'
import {loggingUtils} from '@wix/santa-ds-libs/src/warmupUtils'
import experiment from 'experiment-amd'
import _ from 'lodash'
import biErrors from '../../bi/errors'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import hooks from '../../hooks/hooks'
import dsUtils from '../../utils/utils'
import componentReducer from './componentReducer'
import conversionSettingsBuilder from './conversionSettings'
import mergeAggregator from './mergeAggregator'
import mobileConversionFedops from './mobileConversionFedops'
import mobileHints from './mobileHints'
import mobileHintsValidator from './mobileHintsValidator'
import mobileOnlyComponents from './mobileOnlyComponents'
import mobilePresetsHandler from './mobilePresets/mobilePresetsHandler'
import mobilePropertiesHandler from './mobilePropertiesHandler'
import modesConverter from './modesConverter'
import nonLayoutComponentHandler from './nonLayoutComponentHandler'
import structuresComparer from './structuresComparer'
import textStats from './textStats'
import tinyMenuHandler from './tinyMenuHandler'
import userModifiedComponentHandler from './userModifiedComponentHandler'
import * as utils from './utils'

function executePostConversionOperations(ps: PS, parameters) {
    const updatedPagePointers = _.map(parameters.updatedPageIds, pageId =>
        ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
    )
    _.forEach(updatedPagePointers, _.partial(modesConverter.convertModes, ps))
    hooks.executeHook(hooks.HOOKS.MOBILE_CONVERSION.AFTER, null, [
        ps,
        updatedPagePointers,
        parameters.commitConversionResults
    ])
    if (parameters.commitConversionResults) {
        mergeAggregator.commitResults(ps)
    }
}

function resolvePresets(ps: PS, heuristicStrategy: unknown, comps, page) {
    mobilePresetsHandler.applyPresetsToConversionData(ps, comps, page.id)
    _.forEach(comps, component => {
        if (_.has(component, ['conversionData', 'mobileProps'])) {
            mobilePropertiesHandler.createPropertiesAccordingToConversionData(ps, component, page.id)
        }
    })
    return page
}

/**
 * After deleting components by mobile algo from the page it still exists in dal,
 * and this prevents us from deleting page of TPA page in the same session.
 * This is a reason for this issue https://jira.wixpress.com/browse/WEED-22664
 * @param ps
 */
const removeDeadComponents = (ps: PS) => {
    if (!ps.pointers.data.getMobileComponentsByDataType) {
        return
    }
    const removedComponents = ps.pointers.data.getMobileComponentsByDataType(constants.DEAD_MOBILE_COMPONENT_TYPE)
    removedComponents.forEach(pointer => ps.dal.full.remove(pointer))
}

function applyConvertedComponentsToPage(
    ps: PS,
    mobileComponents,
    pageId: string,
    removePreviousMobileStructure?: boolean
) {
    const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
    const mobileComponentsPointer = ps.pointers.getInnerPointer(pagePointer, 'mobileComponents')

    cleanUpShouldBeForceConvertedMark(ps, ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP), pageId)

    if (removePreviousMobileStructure) {
        ps.extensionAPI.page.removeMobileStructure(pageId)
    }
    ps.dal.full.set(mobileComponentsPointer, mobileComponents)
    mobileHints.updateHiddenComponentsOnPage(ps, pageId)
    mobileHints.markPageAsInitialized(ps, pageId)
    removeDeadComponents(ps)
    if (pageId === constants.MASTER_PAGE_ID) {
        tinyMenuHandler.applyDataToTinyMenu(ps)
        tinyMenuHandler.addAdditionalStyles(ps)
    }
}

function prepareConversion(ps: PS, heuristicStrategy, page, mobilePage?) {
    const allComps = _.values(mobileCore.conversionUtils.getAllCompsInStructure(page)).concat(
        mobileCore.conversionUtils.isMasterPage(page) ? [] : [page]
    )
    const override = !mobilePage
    page.mobileComponents = []
    _.forEach(allComps, component => {
        const mobileComponent = mobilePage
            ? mobileCore.conversionUtils.getComponentByIdFromStructure(component.id, mobilePage) ?? component
            : component
        if (mobileComponent !== component) {
            const designQueryProperty = 'designQuery'
            const desktopDesignQuery = component[designQueryProperty]
            const mobileDesignQuery = mobileComponent[designQueryProperty]
            if (desktopDesignQuery !== mobileDesignQuery) {
                const designQueryPointer = ps.pointers.data.getDesignItem(dsUtils.stripHashIfExists(mobileDesignQuery))
                if (!ps.dal.isExist(designQueryPointer)) {
                    mobileComponent.designQuery = desktopDesignQuery
                }
            }
        }

        const overrideMobileProps = (currentMobilePropsPointer: Pointer) => {
            const modifiedMobilePropsQuery = mobilePropertiesHandler.modifyComponentProperties(ps, component, page.id, {
                override
            })

            const keepMobilePropsByDefault =
                !override && currentMobilePropsPointer && ps.dal.isExist(currentMobilePropsPointer)
            const defaultMobileProps = keepMobilePropsByDefault
                ? mobileComponent.propertyQuery
                : component.propertyQuery

            component.propertyQuery = mobileComponent.propertyQuery = modifiedMobilePropsQuery ?? defaultMobileProps
        }

        const currentMobilePropsPointer =
            mobileComponent.propertyQuery &&
            ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(mobileComponent.propertyQuery), page.id)

        if (experiment.isOpen('dm_mobilePropsOverwriteFix') && utils.shouldEnableImprovedMergeFlow(ps)) {
            if (!currentMobilePropsPointer) {
                overrideMobileProps(currentMobilePropsPointer)
            }
        } else {
            overrideMobileProps(currentMobilePropsPointer)
        }

        componentReducer.createConversionData(ps, component, page.id)
    })

    if (mobilePage) {
        const allMobileComponents = mobileCore.conversionUtils.getAllCompsInStructure(mobilePage)
        const mobileOnlyComponentsThatNeedConversionData = _.filter(allMobileComponents, comp =>
            mobileOnlyComponents.isNeedConversionData(ps, comp)
        )
        _.forEach(mobileOnlyComponentsThatNeedConversionData, component =>
            componentReducer.createConversionDataForMobileOnlyComp(ps, component, page.id)
        )
    }

    textStats.reduceAllTextsData(ps, allComps, page.id)
    return resolvePresets(
        ps,
        heuristicStrategy,
        allComps,
        _.set(page, 'conversionData', _.get(page, 'conversionData', {}))
    )
}

function getComponentsOrder(ps: PS, pageId: string, viewMode): Record<string, any[]> {
    return utils.getComponentsOrderInStructure(ps, pageId, viewMode)
}

function resetModifiedComponents(ps: PS, desktopStructure) {
    const allChildren = mobileCore.conversionUtils.getAllCompsInStructure(desktopStructure, false)
    _.forOwn(allChildren, comp => {
        const mobileHintsItem = dataModel.getMobileHintsItem(ps, {id: comp.id, type: 'DESKTOP'})

        if (mobileHintsItem && userModifiedComponentHandler.isModifiedByUser(mobileHintsItem)) {
            userModifiedComponentHandler.unmarkComponentAsTouched(ps, comp.id, {id: comp.id, type: 'DESKTOP'})
        }
    })
}

function convertPageInternal(ps: PS, desktopPage, mobilePage, heuristicStrategy, settings) {
    prepareConversion(ps, heuristicStrategy, mobilePage)
    const nonLayoutComps = nonLayoutComponentHandler.extractNonLayoutComponentsFromPages(desktopPage, mobilePage)
    const mobileComponents = mobileCore.conversion.convertPage(mobilePage, settings)
    nonLayoutComponentHandler.insertNonLayoutComponentsToPage(mobilePage, nonLayoutComps)
    return mobileComponents
}

function runMobileConversionOnPage(ps: PS, heuristicStrategy, fullConversionSettings, page) {
    const shouldEnableImprovedMergeFlow = utils.shouldEnableImprovedMergeFlow(ps)
    loggingUtils.performance.start(loggingUtils.performanceMetrics.MOBILE.CONVERT_PAGE)

    // @ts-expect-error BUG
    mobileConversionFedops.logInteractionStart(ps, mobileConversionFedops.INTERACTIONS.RUN_OPTIMIZE_LAYOUT_ON_PAGE)

    const mobilePage = santaCoreUtils.objectUtils.cloneDeep(page)
    if (shouldEnableImprovedMergeFlow) {
        resetModifiedComponents(ps, mobilePage)
    }

    const mobileComponents = convertPageInternal(ps, page, mobilePage, heuristicStrategy, fullConversionSettings)
    applyConvertedComponentsToPage(ps, mobileComponents, page.id, true)

    executePostConversionOperations(ps, {
        updatedPageIds: [page.id],
        commitConversionResults: false
    })
    loggingUtils.performance.finish(loggingUtils.performanceMetrics.MOBILE.CONVERT_PAGE)
    // @ts-expect-error BUG
    mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_OPTIMIZE_LAYOUT_ON_PAGE)
}

function runMobileConversionOnAllPages(
    ps: PS,
    heuristicStrategy,
    fullConversionSettings,
    commitConversionResults = true
) {
    mobileConversionFedops.logInteractionStart(ps, mobileConversionFedops.INTERACTIONS.RUN_OPTIMIZE_LAYOUT_ON_ALL_PAGES)

    const pages = mergeAggregator.getChangedPages(ps)
    _.forOwn(pages, ({desktop}, pageId) => {
        const mobilePage = santaCoreUtils.objectUtils.cloneDeep(desktop)
        const mobileComponents = convertPageInternal(ps, desktop, mobilePage, heuristicStrategy, fullConversionSettings)
        applyConvertedComponentsToPage(ps, mobileComponents, pageId, true)
    })
    executePostConversionOperations(ps, {
        updatedPageIds: _.keys(pages),
        commitConversionResults
    })
    mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_OPTIMIZE_LAYOUT_ON_ALL_PAGES)
}

function syncMobileSite(ps: PS, heuristicStrategy, settings?) {
    const pages = mergeAggregator.getChangedPages(ps)
    if (_.isEmpty(pages)) {
        return
    }

    const disableMobileHintsValidation =
        settings?.skipMobileHintsValidation ||
        ps.config.disableMobileConversion ||
        experiment.isOpen('dm_disableMobileHintsValidationInADI')
    if (!disableMobileHintsValidation) {
        mobileHintsValidator.validateMobileHintsOnPages(ps, _.keys(pages))
    }

    const nonLayoutCompsMap = {}

    _.forOwn(pages, ({desktop, mobile}, pageId) => {
        prepareConversion(ps, heuristicStrategy, desktop, mobile)
        nonLayoutCompsMap[pageId] = nonLayoutComponentHandler.extractNonLayoutComponentsFromPages(desktop, mobile)
        mobileOnlyComponents.handleComponentsBeforeMobileAlgo(desktop, mobile)
        const mobileComponents = mobileCore.conversion.synchronizePage(desktop, mobile)
        nonLayoutComponentHandler.insertNonLayoutComponentsToPage(mobile, nonLayoutCompsMap[pageId])
        mobileOnlyComponents.handleComponentsAfterMobileAlgo(desktop, mobile)
        applyConvertedComponentsToPage(ps, mobileComponents, pageId, true)
    })

    executePostConversionOperations(ps, {
        updatedPageIds: _.keys(pages),
        commitConversionResults: true
    })
}

function mergePageInternal(ps: PS, desktopPage, mobilePage, heuristicStrategy, settings) {
    prepareConversion(ps, heuristicStrategy, desktopPage, mobilePage)
    const nonLayoutComps = nonLayoutComponentHandler.extractNonLayoutComponentsFromPages(desktopPage, mobilePage)
    mobileOnlyComponents.handleComponentsBeforeMobileAlgo(desktopPage, mobilePage)
    const mobileComponents = mobileCore.conversion.mergePage(desktopPage, mobilePage, settings)
    nonLayoutComponentHandler.insertNonLayoutComponentsToPage(mobilePage, nonLayoutComps)
    mobileOnlyComponents.handleComponentsAfterMobileAlgo(
        desktopPage,
        mobilePage,
        utils.shouldEnableImprovedMergeFlow(ps)
    )
    return mobileComponents
}

function runMobileMergeOnPage(ps: PS, heuristicStrategy, desktopPage, mobilePage, settings) {
    mobileConversionFedops.logInteractionStart(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ONE_PAGE)
    settings.enableImprovedMergeFlow = utils.shouldEnableImprovedMergeFlow(ps)

    const mobileComponents = mergePageInternal(ps, desktopPage, mobilePage, heuristicStrategy, settings)
    applyConvertedComponentsToPage(ps, mobileComponents, desktopPage.id, true)

    executePostConversionOperations(ps, {
        updatedPageIds: [desktopPage.id],
        commitConversionResults: !!settings.commitConversionResults
    })
    mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ONE_PAGE)
}

function isValidDesktopStructure(ps: PS) {
    const {DESKTOP} = constants.VIEW_MODES
    const COMP = ps.pointers.components
    return _.every([COMP.getFooter(DESKTOP), COMP.getHeader(DESKTOP), COMP.getPagesContainer(DESKTOP)], ps.dal.isExist)
}

/**
 * Removes shouldBeForceConverted mark after any mobile merge run
 * @param ps
 * @param desktopPointer
 * @param pageId
 */
const cleanUpShouldBeForceConvertedMark = (ps: PS, desktopPointer: Pointer, pageId: string) => {
    const children = ps.pointers.full.components.getChildren(desktopPointer)
    children.forEach(pointer => {
        cleanUpShouldBeForceConvertedMark(ps, pointer, pageId)
    })
    const fullComp = ps.dal.full.get(desktopPointer)

    const mobileHintsItem = dataModel.getMobileHintsItemById(ps, fullComp.mobileHintsQuery, pageId)

    if (!mobileHintsItem?.shouldBeForceConverted) {
        return
    }

    mobileHints.updateProperty(
        ps,
        {
            shouldBeForceConverted: undefined
        },
        desktopPointer,
        {},
        pageId
    )
}

function runMobileMergeOnAllPages(ps: PS, heuristicStrategy, fullConversionSettings, commitConversionResults) {
    mobileConversionFedops.logInteractionStart(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ALL_PAGES)

    if (!isValidDesktopStructure(ps)) {
        ps.siteAPI.reportBI(biErrors.MOBILE_STRUCTURE_NOT_SAVED_DUE_TO_CORRUPTION as any)
        return
    }
    // in this case we run optimize layout to generate base mobile structure
    if (!structuresComparer.hasMobileStructure(ps)) {
        ps.extensionAPI.logger.interactionStarted('convertAllPagesDuringMobileMerge')
        runMobileConversionOnAllPages(ps, heuristicStrategy, fullConversionSettings, commitConversionResults)
        mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ALL_PAGES)
        return
    }
    hooks.executeHook(hooks.HOOKS.MOBILE_MERGE.BEFORE, null, [ps])
    const pages = mergeAggregator.getChangedPages(ps)
    if (_.isEmpty(pages)) {
        return
    }

    const fixMasterPageStructureOrder = shouldFixMasterPageStructureOrder(pages)

    const settings = conversionSettingsBuilder.getConversionSettings(ps, {
        conversionType: 'MERGE_ALL_PAGES',
        heuristicStrategy
    })
    const shouldEnableImprovedMergeFlow = utils.shouldEnableImprovedMergeFlow(ps)

    settings.fixMasterPageStructureOrder = fixMasterPageStructureOrder
    settings.enableImprovedMergeFlow = shouldEnableImprovedMergeFlow
    settings.keepEmptyTextComponents = fullConversionSettings.keepEmptyTextComponents
    settings.keepNotRecommendedMobileComponents = fullConversionSettings.keepNotRecommendedMobileComponents
    settings.keepAnchorsAsDirectChildOfPage = fullConversionSettings.keepAnchorsAsDirectChildOfPage

    _.forOwn(pages, ({desktop, mobile}, pageId) => {
        let mobileComponents
        /**
         * We don't need to run mobile conversion at all for any case because optimize layout algorithm works worth than merge one,
         * changes covered by experiment to avoid unexpected complains/break on user sites
         */
        if (_.isEmpty(mobile.components) && !fullConversionSettings.fitContainerToChildrenHeightRecoursively) {
            const mobilePage = santaCoreUtils.objectUtils.cloneDeep(desktop)
            mobileComponents = convertPageInternal(ps, desktop, mobilePage, heuristicStrategy, fullConversionSettings)
        } else {
            mobileComponents = mergePageInternal(ps, desktop, mobile, heuristicStrategy, settings)
        }

        applyConvertedComponentsToPage(ps, mobileComponents, pageId)
    })

    executePostConversionOperations(ps, {
        updatedPageIds: _.keys(pages),
        commitConversionResults
    })
    loggingUtils.performance.finish(loggingUtils.performanceMetrics.MOBILE.MERGE_ALL_PAGES)

    mobileConversionFedops.logInteractionEnd(ps, mobileConversionFedops.INTERACTIONS.RUN_MERGE_ON_ALL_PAGES)
}

const shouldFixMasterPageStructureOrder = pages => {
    const masterPage = _.get(pages, constants.MASTER_PAGE_ID, null)
    if (!masterPage?.desktop) {
        return false
    }
    const desktopChildren = mobileCore.conversionUtils.getChildren(masterPage.desktop)
    const mobileChildren = mobileCore.conversionUtils.getChildren(masterPage.mobile)
    const SOSP_COMP = {id: 'SOSP_CONTAINER_CUSTOM_ID'}
    return _.some(desktopChildren, SOSP_COMP) && !_.some(mobileChildren, SOSP_COMP)
}

const runMobileMergeOnPageById = (ps: PS, heuristicStrategy, fullConversionSettings, pageId) => {
    const {desktop, mobile} = mergeAggregator.getPage(ps, pageId)
    if (!mobile) {
        return
    }
    /**
     * This setting use for commit merge result to dal
     */
    const commitConversionResults = true

    runMobileMergeOnPage(ps, heuristicStrategy, desktop, mobile, {
        ...fullConversionSettings,
        commitConversionResults
    })
}

const runMobileMergeOnAllPagesWithoutLayoutOverwrite = (ps: PS) => {
    /** This method use only in sections migration and emulate mobile algo runs. All components adjustments
     * made on editor level during migration, so here we only need to run code that will apply mobile components to
     * page and prevent MA to run it's calculation next time user go to mobile/save site. Mobile components in this
     * step already contains all layout data because it was calculated applyed during section migration*/
    const pages = mergeAggregator.getChangedPages(ps)
    if (_.isEmpty(pages)) {
        return
    }

    const pagesIdsToMobileComponentsMap = _.mapValues(pages, page => page.mobile.components)

    _.forEach(pagesIdsToMobileComponentsMap, (mobileComponents, pageId) =>
        applyConvertedComponentsToPage(ps, mobileComponents, pageId, true)
    )
    executePostConversionOperations(ps, {
        updatedPageIds: _.keys(pagesIdsToMobileComponentsMap),
        commitConversionResults: true
    })
}

function addMobileOnlyComponent(ps: PS, compId: string, postConversionParameters) {
    const mobileComponentsPointer = ps.pointers.page.getComponentsMapPointer('masterPage', constants.VIEW_MODES.MOBILE)
    const compsMap = ps.dal.get(mobileComponentsPointer)
    const masterPage = santaCoreUtils.mobileUtils.buildDeepStructure(compsMap.masterPage, compsMap)
    masterPage.mobileComponents = masterPage.children

    prepareConversion(ps, undefined, masterPage)
    mobileCore.mobileOnlyComponents.addComponent(masterPage, compId)
    applyConvertedComponentsToPage(ps, mobileCore.conversionUtils.getChildren(masterPage), masterPage.id)
    if (postConversionParameters) {
        executePostConversionOperations(ps, {
            updatedPageIds: [masterPage.id],
            commitConversionResults: postConversionParameters.commitConversionResults
        })
    }
}

function mobileConversion(options: {ps: PS; heuristicStrategy?: string}) {
    const {ps} = options

    const heuristicStrategy =
        options.heuristicStrategy || ps.dal.get(ps.pointers.general.getMobileConversionHeuristicStrategy())
    const fullConversionSettings = conversionSettingsBuilder.getConversionSettings(ps, {
        conversionType: 'FULL',
        heuristicStrategy
    })

    return {
        getComponentsOrder: getComponentsOrder.bind(null, ps),
        runMobileMergeOnAllPages: runMobileMergeOnAllPages.bind(null, ps, heuristicStrategy, fullConversionSettings),
        runMobileMergeOnAllPagesWithoutLayoutOverwrite: runMobileMergeOnAllPagesWithoutLayoutOverwrite.bind(
            null,
            ps,
            heuristicStrategy,
            fullConversionSettings
        ),
        runMobileMergeOnPage: runMobileMergeOnPage.bind(null, ps, heuristicStrategy),
        runMobileMergeOnPageById: runMobileMergeOnPageById.bind(null, ps, heuristicStrategy, fullConversionSettings),
        runMobileConversionOnPage: runMobileConversionOnPage.bind(null, ps, heuristicStrategy, fullConversionSettings),
        runMobileConversionOnAllPages: runMobileConversionOnAllPages.bind(
            null,
            ps,
            heuristicStrategy,
            fullConversionSettings
        ),
        syncMobileSite: syncMobileSite.bind(null, ps, heuristicStrategy),
        addMobileOnlyComponent: addMobileOnlyComponent.bind(null, ps),
        prepareConversion: prepareConversion.bind(null, ps, heuristicStrategy)
    }
}

export default {
    mobileConversion
}
