import type {DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'

const migrateComponent = (documentManager: DocumentManager, pointer: Pointer, compStructure: any) => {
    if (compStructure.skin || compStructure.styleId) {
        const componentStructure = deepClone(compStructure)
        delete componentStructure.skin
        const {styleId} = componentStructure
        const compPageId = componentStructure.metaData.pageId
        if (styleId && compPageId) {
            const styleItemPointer = documentManager.pointers.data.getThemeItem(
                styleId,
                componentStructure.metaData.pageId
            )
            documentManager.dal.remove(styleItemPointer)
            delete componentStructure.styleId
        }
        documentManager.dal.set(pointer, componentStructure)
    }
}

const components = {
    'wixui.CustomElementComponent': migrateComponent
    // technically, these 2 also have no skin or style in the componentDefinition.
    // However, it doesn't mean that its not really working there, as the viewer component might still render with a skin/style
    // currently not adding these 2 so that we do not introduce unnecessary risk. We can uncomment these separately
    // after verifying the actual status in viewer in production
    // 'wysiwyg.viewer.components.Grid': migrateComponent,
    // 'wysiwyg.viewer.components.Table': migrateComponent
}

export {components}
