import _ from 'lodash'
import experiment from 'experiment-amd'

//legcay data item should not be deleted!
const MAIN_MENU = 'MAIN_MENU'
const removeBadMenuDataItems = (ps, badMenuDataItems) => {
    _.forEach(badMenuDataItems, badMenuDataItem => ps.dal.full.remove(badMenuDataItem))
}
export default {
    exec(ps) {
        if (experiment.isOpen('dm_customMenuPermanentDataNodesFixer')) {
            // get all old data-items of type:'Menu' (old custom menu) which reside in masterPage document-data
            const allOldMenuMasterPageDataItems = ps.pointers.data.getDataItemsWithPredicate(
                {type: 'Menu'},
                'masterPage'
            )
            // filter legacy custom MAIN_MENU entry
            const filteredOldMenuMasterPageDataItems = allOldMenuMasterPageDataItems.filter(
                dataItem => dataItem.id !== MAIN_MENU
            )
            if (!_.isEmpty(filteredOldMenuMasterPageDataItems)) {
                const pageDataItemsIds = _.uniq(filteredOldMenuMasterPageDataItems.map(key => key.id))
                const orphanDataNodesPointer = ps.pointers.general.getOrphanPermanentDataNodes()
                const orphanDataNodes = ps.dal.full.get(orphanDataNodesPointer)
                const updatedOrphanPermanentDataNodes = _.uniq(orphanDataNodes.concat(pageDataItemsIds))
                ps.dal.full.set(orphanDataNodesPointer, updatedOrphanPermanentDataNodes)
                removeBadMenuDataItems(ps, filteredOldMenuMasterPageDataItems)
            }
        }
    },
    name: 'customMenuPermanentDataNodesFixer',
    version: 1
}
