import relationsUtils from '../../variants/relationsUtils'

function removeUnknownDataNodeVariantsAndData(ps) {
    if (!ps.pointers.variants) {
        return
    }

    const allPages = ps.siteAPI.getAllPagesIds(true)

    allPages.forEach(pageId => {
        const corruptedVariants = ps.pointers.data.getVariantItemsWithPredicate(
            x => x.type === 'UnknownDataNode',
            pageId
        )

        if (corruptedVariants.length > 0) {
            relationsUtils.removeScopedValuesByVariants(ps, corruptedVariants)
        }
    })
}

export default {
    exec(ps) {
        removeUnknownDataNodeVariantsAndData(ps)
    },
    name: 'unknownTypeVariantsFixer',
    version: 1
}
