import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import type {AutosaveConfig, Logger, PS} from '@wix/document-services-types'
import hooks from '../hooks/hooks'
import saveAPI from '../saveAPI/saveAPI'
import generalInfo from '../siteMetadata/generalInfo'
import pageData from '../page/pageData'
import experiment from 'experiment-amd'

let performAutosaveDebounced
let autosaveTimeoutId

const defaultConfig = {
    AUTOSAVE_ACTION_COUNT: 2,
    SAVE_AFTER_AUTOSAVE_COUNT: 3,
    DEBOUNCE_WAIT: 2,
    AUTOSAVE_TIMEOUT: 10
}

let config: {
    allowOnBoarding?: boolean
    enabled?: boolean
    AUTOSAVE_TIMEOUT?: number
    SAVE_AFTER_AUTOSAVE_COUNT?: number
    AUTOSAVE_ACTION_COUNT?: number
    DEBOUNCE_WAIT?: number
} = {}

const innerPaths = {
    SHOULD_AUTOSAVE: 'shouldAutoSave',
    ACTIONS_COUNT: 'actionsCount',
    AUTOSAVES_COUNT: 'autosaveCount',
    AUTO_FULL_SAVE_FLAG: 'autoFullSaveFlag',
    REPORTING_INFO: 'reportingInfo'
}
const counterOps = {
    INCREASE: 'increase',
    DECREASE: 'decrease',
    RESET: 'reset'
}
const INIT_AUTOSAVE_INTERACTION = 'autosave-init'
const {HOOKS} = hooks
let hooksMap = {}

function autosaveDebouncedWithDefaultCallbacks(ps: PS, trigger: string) {
    performAutosaveDebounced(ps, _.noop, _.noop, trigger)
}

function handleUserAction(ps: PS) {
    if (!canSave(ps)) {
        return
    }
    performAutosaveDebounced = performAutosaveDebounced || _.debounce(performAutosave, config.DEBOUNCE_WAIT * 1000)
    setAutosaveTimeout(ps)
    const newCount = increaseCounter(ps, innerPaths.ACTIONS_COUNT)
    const markedForPartialUpdate =
        ps.siteAPI.getMarkForPartialUpdate() && !experiment.isOpen('dm_checkTransactionsLimitOnAutosave')
    if (newCount >= config.AUTOSAVE_ACTION_COUNT || markedForPartialUpdate) {
        autosaveDebouncedWithDefaultCallbacks(ps, 'num of actions')
    }
}

function performAutosave(ps: PS, onSuccess, onError, trigger: string) {
    const autosaveCount = increaseCounter(ps, innerPaths.AUTOSAVES_COUNT)
    const autoSavesThresholdPassed = autosaveCount >= config.SAVE_AFTER_AUTOSAVE_COUNT
    const unappliedTransactionThresholdPassed = experiment.isOpen('dm_checkTransactionsLimitOnAutosave')
        ? ps.extensionAPI.continuousSave.isUnappliedTransactionThresholdPassed()
        : ps.siteAPI.getMarkForPartialUpdate()
    const forcePartialSave = autoSavesThresholdPassed || unappliedTransactionThresholdPassed
    clear(ps)

    if (!forcePartialSave) {
        const reportingInfoPointer = getAutoSaveInnerPointer(ps, innerPaths.REPORTING_INFO)

        ps.dal.set(reportingInfoPointer, {
            actionsPerAutosave: config.AUTOSAVE_ACTION_COUNT,
            autosavesPerFull: config.SAVE_AFTER_AUTOSAVE_COUNT,
            pagesCount: pageData.getNumberOfPages(ps),
            trigger
        })

        _.invoke(config, 'onDiffSaveStarted', trigger)

        // eslint-disable-next-line promise/prefer-await-to-then
        ps.extensionAPI.continuousSave.save().then(onSuccess, e => {
            onError(e, trigger)
        })
    } else {
        ;(ps.extensionAPI.logger as Logger).interactionStarted('forcePartialUpdate', {
            extras: {
                autoSavesThresholdPassed,
                unappliedTransactionThresholdPassed,
                newMechanism: experiment.isOpen('dm_checkTransactionsLimitOnAutosave')
            }
        })
        const onPartialSaveSuccess = getPartialSaveCompletedCallback(ps, trigger, onSuccess, true)
        const onPartialSaveFail = getPartialSaveCompletedCallback(ps, trigger, onError, false)
        const autoFullSaveFlagPointer = getAutoSaveInnerPointer(ps, innerPaths.AUTO_FULL_SAVE_FLAG)
        ps.dal.set(autoFullSaveFlagPointer, true)
        _.invoke(config, 'onPartialSaveStarted', trigger)
        const isFullSave = ps.extensionAPI.continuousSave.isValidationRecovery()
        saveAPI.save(ps, onPartialSaveSuccess as any, onPartialSaveFail, isFullSave, {origin: 'autosave'})
    }
}

async function partialSave(ps: PS, trigger: string) {
    const onFinish = (e?) => {
        ps.dal.remove(autoFullSaveFlagPointer)
        _.invoke(config, 'onPartialSaveFinished', e, trigger)
        resetCounter(ps, innerPaths.AUTOSAVES_COUNT)
    }
    const autoFullSaveFlagPointer = getAutoSaveInnerPointer(ps, innerPaths.AUTO_FULL_SAVE_FLAG)
    ps.dal.set(autoFullSaveFlagPointer, true)
    _.invoke(config, 'onPartialSaveStarted', trigger)
    try {
        await saveAPI.promises.save(ps, false, {trigger})
        onFinish()
    } catch (e) {
        onFinish(e)
    }
}

function getPartialSaveCompletedCallback(
    ps: PS,
    trigger: string,
    callback: (error: any, trigger: string) => void,
    success: boolean
) {
    return function (error) {
        ps.extensionAPI.logger.interactionEnded('forcePartialUpdate', {extras: {success}})
        const autoFullSaveFlagPointer = getAutoSaveInnerPointer(ps, innerPaths.AUTO_FULL_SAVE_FLAG)
        ps.dal.remove(autoFullSaveFlagPointer)
        _.invoke(config, 'onPartialSaveFinished', error, trigger)
        resetCounter(ps, innerPaths.AUTOSAVES_COUNT)
        if (_.isFunction(callback)) {
            callback(error, trigger)
        }
    }
}

function handleValidationError(ps: PS) {
    disableAutosave(ps)
}

function disableAutosave(ps: PS) {
    init(ps, {enabled: false})
}

function setAutosaveTimeout(ps: PS) {
    clearAutosaveTimeout()
    autosaveTimeoutId = setTimeout(() => {
        autosaveDebouncedWithDefaultCallbacks(ps, 'idle')
    }, config.AUTOSAVE_TIMEOUT * 1000)
}

function clearAutosaveTimeout() {
    if (autosaveTimeoutId) {
        clearTimeout(autosaveTimeoutId)
        autosaveTimeoutId = null
    }
    if (performAutosaveDebounced) {
        performAutosaveDebounced.cancel()
    }
}

function updateCounter(ps: PS, key: string, operation: string) {
    const counterPointer = getAutoSaveInnerPointer(ps, key)
    const currentCount = ps.dal.get(counterPointer) || 0
    let newCount

    switch (operation) {
        case counterOps.INCREASE:
            newCount = currentCount + 1
            break
        case counterOps.DECREASE:
            newCount = currentCount - 1
            break
        case counterOps.RESET:
            newCount = 0
            break
    }

    ps.dal.set(counterPointer, newCount)

    return newCount
}

function increaseCounter(ps: PS, key: string) {
    return updateCounter(ps, key, counterOps.INCREASE)
}

function resetCounter(ps: PS, key: string) {
    return updateCounter(ps, key, counterOps.RESET)
}

function clear(ps: PS) {
    clearAutosaveTimeout()
    resetCounter(ps, innerPaths.ACTIONS_COUNT)
}

function clearAll(ps: PS) {
    clear(ps)
    resetCounter(ps, innerPaths.AUTOSAVES_COUNT)
}

function getAutoSaveInnerPointer(ps: PS, key: string) {
    return ps.pointers.general.getAutoSaveInnerPointer(key)
}

function isNeverSaved(ps: PS) {
    const neverSavedPointer = ps.pointers.general.getNeverSaved()
    return ps.dal.get(neverSavedPointer)
}

function isSiteFromOnBoarding(ps: PS) {
    return generalInfo.isSiteFromOnBoarding(ps)
}

const registerHook = (name: string, hook: Function) => {
    hooksMap[name] = hooks.registerHook(name, hook)
}

function registerHooks() {
    registerHook(HOOKS.AUTOSAVE.ACTION, handleUserAction)
    registerHook(HOOKS.SAVE.SITE_SAVED, clearAll)
    registerHook(HOOKS.SAVE.VALIDATION_ERROR, handleValidationError)
}

function unregisterHooks() {
    _.forEach(hooksMap, (hookValue, hookKey) => hooks.unregisterHook(hookKey, hookValue))
    hooksMap = {}
}

function isAutoSaveAllowed(ps: PS) {
    const autoSaveInfo = getAutoSaveInfo(ps)
    return autoSaveInfo?.shouldAutoSave && !ps.config.disableAutoSave
}

function canSave(ps: PS) {
    return (
        config.enabled !== false &&
        !!(
            isAutoSaveAllowed(ps) &&
            !isNeverSaved(ps) &&
            (config.allowOnBoarding || !isSiteFromOnBoarding(ps)) &&
            saveAPI.saveState.canSave(ps)
        )
    )
}

function getAutoSaveInfo(ps: PS) {
    const autosaveInfoPointer = ps.pointers.general.getAutosaveInfo()
    return ps.dal.get(autosaveInfoPointer)
}

const saveAfterNonRecoverableError = (ps: PS) => partialSave(ps, HOOKS.AUTOSAVE.CSAVE_NON_RECOVERABLE_ERROR)

function init(ps: PS, _config: AutosaveConfig) {
    if (!_config) {
        santaCoreUtils.log.error(new Error('Missing autosave config object'))
        return false
    }

    const wrappedHooks = ps.extensionAPI.continuousSave.getWrappedHooks(_config)
    config = _.defaults(wrappedHooks, _config, defaultConfig)
    ps.extensionAPI.logger.interactionStarted(INIT_AUTOSAVE_INTERACTION, {tags: {enable: config.enabled}})

    unregisterHooks()

    ps.extensionAPI.continuousSave.setEnabled(config.enabled)
    ps.extensionAPI.continuousSave.initHooks(config)
    registerHook(HOOKS.AUTOSAVE.CSAVE_NON_RECOVERABLE_ERROR, saveAfterNonRecoverableError)

    clearAutosaveTimeout()

    if (_config.enabled !== true || !saveAPI.saveState.isEnabled(ps)) {
        return false
    }

    registerHooks()
    ps.extensionAPI.logger.interactionEnded(INIT_AUTOSAVE_INTERACTION, {tags: {enable: config.enabled}})
    return true
}

const initMethod = (ps: PS, {disableAutoSave = false} = {disableAutoSave: false}) => {
    if (disableAutoSave) {
        const shouldAutoSavePtr = getAutoSaveInnerPointer(ps, innerPaths.SHOULD_AUTOSAVE)
        ps.dal.set(shouldAutoSavePtr, false)
    }
}

export default {
    initMethod,
    init,
    autosave(ps: PS, onSuccess, onError, triggerName?: string) {
        if (!canSave(ps)) {
            return onError({document: 'CSave not available'})
        }
        performAutosave(ps, onSuccess, onError, triggerName)
    },
    canAutosave: canSave,
    getAutoSaveInfo,
    clearAll,
    disableAutosave,
    handleValidationError,
    isAllowed: isAutoSaveAllowed
}
