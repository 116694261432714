export class Deferred<T> {
    public resolve!: (value: T | PromiseLike<T>) => void
    public reject!: (reason?: any) => void
    public promise: Promise<T>

    constructor() {
        this.promise = new Promise<T>((res, rej) => {
            this.resolve = res
            this.reject = rej
        })
    }
}

export interface DeferReq<T> {
    deferred: Deferred<T>
    timeout: any
}

export interface Callbacks<T> {
    onResolve?(key: T): void
    onReject?(key: T): void
    onTimeout?(key: T): void
}

const notifierTimeout = 1000 * 10

export class Notifier<K, V = void, E = Error> {
    constructor(private readonly callbacks: Callbacks<K> = {}, private readonly timeout: number = notifierTimeout) {}

    private readonly map: Map<K, DeferReq<V>> = new Map<K, DeferReq<V>>()

    private pop(key: K): DeferReq<V> | undefined {
        const req = this.map.get(key)
        if (req) {
            this.map.delete(key)
            clearTimeout(req.timeout)
        }
        return req
    }

    register(key: K): Promise<V> {
        const deferred = new Deferred<V>()
        const timeoutHandle = setTimeout(() => {
            this.callbacks.onTimeout?.(key)
            this.map.delete(key)
            deferred.reject(new Error('Request timed out'))
        }, this.timeout)
        this.map.set(key, {deferred, timeout: timeoutHandle})
        return deferred.promise
    }

    resolve(key: K, value: V) {
        const req = this.pop(key)
        if (req) {
            this.callbacks.onResolve?.(key)
            req.deferred.resolve(value)
        }
    }

    reject(key: K, error?: E) {
        const req = this.pop(key)
        if (req) {
            this.callbacks.onReject?.(key)
            req.deferred.reject(error)
        }
    }
}
