import {viewerExtensions, ViewerExtensionFactory} from '@wix/document-manager-extensions'
import {extensionLoader} from '@wix/document-manager-host-common'
import type {HostWithViewerManager} from './host'

const {loadExtensions} = extensionLoader

const loadViewerExtensions = (host: HostWithViewerManager) => {
    const {documentManager, viewerManager, config: dsConfig, environmentContext} = host
    const {experimentInstance, logger} = documentManager

    const extensionsToRegister: ViewerExtensionFactory[] = [
        viewerExtensions.viewerExtension,
        viewerExtensions.displayedStructure,
        viewerExtensions.refComponents,
        viewerExtensions.scopes,
        viewerExtensions.scopesMetaData
    ]
    extensionsToRegister.forEach(extension =>
        documentManager.registerExtension(
            extension.createExtension({viewerManager, experimentInstance, environmentContext, dsConfig, logger})
        )
    )
}

export {loadExtensions, loadViewerExtensions}
