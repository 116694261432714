import _ from 'lodash'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import dataIds from '../dataModel/dataIds'
import relationsUtils from './relationsUtils'
import refComponent from '../refComponent/refComponent'
import variantsUtils from './variantsUtils'
import documentModeInfo from '../documentMode/documentModeInfo'
import type {CompRef, CompVariantPointer, Pointer, PS} from '@wix/document-services-types'
import {asArray} from '@wix/document-manager-utils'
import experiment from 'experiment'

const {DATA_TYPES, MASTER_PAGE_ID} = constants
const {VALID_VARIANTS_DATA_TYPES, TYPES, SINGLE_VARIANT_PER_COMP_TYPES, MOBILE_VARIANT_ID, VARIANTS_QUERY} =
    constants.VARIANTS
const implicitApiAddTypes = [TYPES.STATE, TYPES.TRIGGER]
const implicitApiAddTypesWithMobile = implicitApiAddTypes.concat(TYPES.MOBILE)

const getData = (ps, variantPointer) => dataModel.getDataByPointer(ps, DATA_TYPES.variants, variantPointer)

const getComponentVariantsDataByType = (ps: PS, compPointer: Pointer, variantType: string) => {
    const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
    const compPointerToGet = ps.pointers.full.components.getComponent(compPointer.id, pagePointer)
    return dataModel.getVariantsDataByVariantType(ps, variantType, {compPointer: compPointerToGet})
}

const getAllAffectingVariantsGroupedByVariantType = (ps: PS, componentPointer: Pointer) =>
    relationsUtils.getAllAffectingVariantsGroupedByVariantType(ps, componentPointer)

const getAllAffectingVariantsForPresets = (ps: PS, componentPointer: Pointer) =>
    relationsUtils.getAllAffectingVariantsForDataType(ps, componentPointer, 'presets')

const createVariantForComponent = (
    ps: PS,
    variantToAddRef,
    compPointer: Pointer,
    variantType: string,
    variantData?
) => {
    const notAllowedvariantTypes = experiment.isOpen('dm_oneMobileVariant')
        ? implicitApiAddTypesWithMobile
        : implicitApiAddTypes

    if (notAllowedvariantTypes.includes(variantType)) {
        throw new Error(`Please use the public API to add variants of type ${variantType}`)
    }

    return createAnyVariantForComponent(ps, variantToAddRef, compPointer, variantType, variantData)
}

const createAnyVariantForComponent = (
    ps: PS,
    variantToAddRef,
    compPointer: Pointer,
    variantType: string,
    variantData?
) => {
    if (!ps || !variantType || !compPointer) {
        throw new Error('invalid args')
    }

    const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
    const compPointerToAdd = ps.pointers.full.components.getComponent(compPointer.id, pagePointer)

    if (variantToAddRef) {
        dataModel.createVariantData(ps, variantToAddRef, variantType, {compPointer: compPointerToAdd}, variantData)
    }
}

const createVariantsListForComponent = (
    ps: PS,
    currentId: string,
    compPointer: Pointer,
    variantsListType: string,
    variantsList: {values: object[]}
): string => {
    const variantsListId = currentId || dataIds.generateNewId(DATA_TYPES.variants)
    const componentPage = ps.pointers.full.components.getPageOfComponent(compPointer)
    const variantsListPointer = ps.pointers.data.getVariantsDataItem(variantsListId, componentPage.id)
    const values = variantsList?.values
    return dataModel.createVariantData(ps, variantsListPointer, variantsListType, {compPointer, values})
}

const getVariantToAddRef = (ps: PS, compPointer: Pointer, variantType: string) => {
    const pagePointer = ps.pointers.components.getPageOfComponent(compPointer)
    const compPointerToAdd = compPointer && ps.pointers.full.components.getComponent(compPointer.id, pagePointer)
    const compVariantsByType = getComponentVariantsDataByType(ps, compPointerToAdd, variantType)

    if (_.includes(SINGLE_VARIANT_PER_COMP_TYPES, variantType) && !_.isEmpty(compVariantsByType)) {
        return null
    }

    const variantId = dataIds.generateNewId(DATA_TYPES.variants)
    return ps.pointers.data.getVariantsDataItem(variantId, pagePointer.id)
}

const removeVariants = (ps: PS, variantsPointers) => {
    const variantsPointersArray = asArray(variantsPointers)
    relationsUtils.removeScopedValuesByVariants(ps, variantsPointersArray)
}

const getCompnentsWithOverridesGroupedByType = (ps: PS, variantsPointers: Pointer | Pointer[] = []) => {
    if ((variantsPointers as Pointer[]).length < 1) {
        return {}
    }
    const variantsPointersArray = asArray(variantsPointers)
    const pageId = ps.pointers.data.getPageIdOfData(_.head(variantsPointersArray))

    const result = {}

    _.forEach(VALID_VARIANTS_DATA_TYPES, itemType => {
        const relationsPointersByItemType = relationsUtils
            .getRelationsByVariantsAndPredicate(ps, variantsPointersArray, itemType)
            .flat()
        const components = _.map(relationsPointersByItemType, relationPtr => {
            const relationData = ps.dal.get(relationPtr)
            return relationsUtils.getComponentFromRelation(ps, relationData, pageId)
        })
        if (components.length > 0) {
            result[itemType] = components
        }
    })

    return result
}

const getPointerWithoutVariants = (ps: PS, pointer: CompVariantPointer): CompRef =>
    variantsUtils.getPointerWithoutVariants(pointer)

const hasOverrides = (ps: PS, compPointerWithVariants: CompVariantPointer, shouldCheckChildren = true) => {
    if (!ps.pointers.components.isWithVariants(compPointerWithVariants)) {
        return false
    }
    const pagePointer = ps.pointers.full.components.getPageOfComponent(compPointerWithVariants)
    const compPointer = ps.pointers.full.components.getComponent(compPointerWithVariants.id, pagePointer)

    const rootCompId = compPointer.id
    const isRecursive =
        shouldCheckChildren &&
        !!_.find(
            compPointerWithVariants.variants,
            variantPointer => ps.dal.get(variantPointer).componentId === rootCompId
        )

    const componentsWithOverrides = getCompnentsWithOverridesGroupedByType(ps, compPointerWithVariants.variants)
    // @ts-expect-error
    const componentIdsThatMatchesVariants = _(componentsWithOverrides).values().flatten().map('id').uniq().value()

    const compHasOverrides = _compId => _.includes(componentIdsThatMatchesVariants, _compId)
    const descendantsHasOverrides =
        isRecursive && !_.isEmpty(componentIdsThatMatchesVariants)
            ? !!ps.pointers.components.findDescendant(compPointer, comp => compHasOverrides(comp.id))
            : false

    return compHasOverrides(rootCompId) || descendantsHasOverrides
}

const setToActiveVariantMap = (ps: PS, variantsPointers, isUnset?) => {
    const variantsPointersArray = _.isArray(variantsPointers) ? variantsPointers : [variantsPointers]
    const focusedPageId = ps.siteAPI.getFocusedRootId()
    const pagePointer = ps.pointers.components.getPage(focusedPageId, ps.siteAPI.getViewMode())

    variantsPointersArray.forEach(variantPointer => {
        const variantComponentId = ps.dal.get(variantPointer).componentId
        const valueToSet = isUnset ? undefined : variantPointer.id
        const compPointer = ps.pointers.full.components.getComponent(variantComponentId, pagePointer)
        const components = _.concat(
            ps.pointers.components.getAllDisplayedOnlyComponents(compPointer),
            refComponent.getReferredComponents(ps, compPointer)
        )
        components.forEach(component => {
            const compActiveVariantPointer = ps.pointers.activeVariants.getActiveVariant(component.id)
            ps.dal.set(compActiveVariantPointer, valueToSet)
        })
    })
}

const enable = (ps: PS, variantsPointers) => setToActiveVariantMap(ps, variantsPointers)

const getComponentEnabledVariants = (ps: PS, compPointerWithVariants: CompVariantPointer) => {
    const activeVariantsPointer = ps.pointers.activeVariants.getActiveVariant(compPointerWithVariants.id)
    return ps.dal.get(activeVariantsPointer)
}

const getMobileVariantRef = (ps: PS): Pointer => {
    return ps.pointers.data.getVariantsDataItem(MOBILE_VARIANT_ID, MASTER_PAGE_ID)
}

const getMobileVariant = (ps: PS): Pointer | null => {
    const mobileVariantPointer = getMobileVariantRef(ps)
    if (ps.dal.isExist(mobileVariantPointer)) {
        return mobileVariantPointer
    }
    return null
}

const createMobileVariant = (ps: PS, variantToAddRef: Pointer): Pointer => {
    const mobileVariant = getMobileVariant(ps)
    if (mobileVariant) {
        return mobileVariant
    }
    const masterPage = ps.pointers.components.getMasterPage(documentModeInfo.getViewMode(ps))
    const variantsListId = createVariantsListForComponent(ps, undefined, masterPage, TYPES.VARIANTS_LIST, {
        values: [{id: MOBILE_VARIANT_ID, type: TYPES.MOBILE, componentId: masterPage.id}]
    })

    dataModel.linkComponentToItem(ps, masterPage, variantsListId, VARIANTS_QUERY)
    return variantToAddRef
}

const getVariantOwner = (ps: PS, variantPointer: Pointer): CompRef =>
    ps.extensionAPI.variants.getVariantOwner(variantPointer)

const disable = (ps: PS, variantsPointers) => setToActiveVariantMap(ps, variantsPointers, true)

const getRepeaterPatternType = () => TYPES.REPEATER_PATTERN
const getHoverType = () => TYPES.HOVER
const getMobileType = () => TYPES.MOBILE
const getPresetType = () => TYPES.PRESET
const getStateType = () => TYPES.STATE
const getTriggerType = () => TYPES.TRIGGER
const getBreakpointsDataType = () => TYPES.BREAKPOINTS

export default {
    getVariantToAddRef,
    getAllAffectingVariantsGroupedByVariantType,
    getAllAffectingVariantsForPresets,
    create: createVariantForComponent,
    createInternal: createAnyVariantForComponent,
    getByComponentAndType: getComponentVariantsDataByType,
    createVariantsList: createVariantsListForComponent,
    createMobileVariant,
    getMobileVariant,
    getMobileVariantRef,
    enable,
    getComponentEnabledVariants,
    disable,
    remove: removeVariants,
    getPointerWithVariants: variantsUtils.getPointerWithVariants,
    getPointerWithoutVariants,
    hasOverrides,
    getComponentsWithOverrides: getCompnentsWithOverridesGroupedByType,
    getHoverType,
    getPresetType,
    getMobileType,
    getStateType,
    getTriggerType,
    getRepeaterPatternType,
    getBreakpointsDataType,
    getData,
    getVariantOwner
}
