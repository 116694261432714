import {ReportableError} from '@wix/document-manager-utils'
import type {Pointer} from '@wix/document-services-types'
import {createImmutableProxy} from '@wix/wix-immutable-proxy'
import _ from 'lodash'
import type {Null} from '../../types'
import {deepCompare} from '../../utils/deepCompare'
import type {DalJsStore, DmStore} from '../store'
import type {SnapshotList} from './snapshotList'

/**
 * Returns a list of all the snapshots from but not including fromSnapshot, up to and including toSnapshot
 * @param {SnapshotDal} fromSnapshot
 * @param {SnapshotDal} toSnapshot
 * @param source
 * @returns {SnapshotDal[]}
 */
function createSnapshotChain(
    fromSnapshot: Null<SnapshotDal>,
    toSnapshot: Null<SnapshotDal>,
    source: string
): SnapshotDal[] {
    const chain: SnapshotDal[] = []
    let snapshot: Null<SnapshotDal> = toSnapshot
    let containsSnapshotId = false
    while (snapshot !== null && snapshot.id !== fromSnapshot?.id) {
        containsSnapshotId = containsSnapshotId || fromSnapshot?.id === snapshot.id
        chain.push(snapshot)
        snapshot = snapshot.getPreviousSnapshot()
    }
    if (snapshot === null && snapshot !== fromSnapshot) {
        throw new ReportableError({
            message: 'Could not locate the source snapshot',
            errorType: 'failedToCreateSnapshotChain',
            extras: {source, fromSnapshot: fromSnapshot?.id, toSnapshot: toSnapshot?.id, containsSnapshotId}
        })
    }
    return chain.reverse()
}

// A private method that returns the value for the given pointer at the time when the snapshot was taken
const getRawValue = (snapshot: Null<SnapshotDal>, pointer: Pointer): any => {
    let tempSnap: Null<SnapshotDal> = snapshot
    while (tempSnap) {
        if (tempSnap._snapshotStore.has(pointer)) {
            return tempSnap._snapshotStore.get(pointer)
        }
        tempSnap = tempSnap.getPreviousSnapshot()
    }
    return undefined
}

// A private method that returns the difference between this snapshot and the from snapshot
const getRawDiff = (thisSnapshot: SnapshotDal, fromSnapshot: Null<SnapshotDal>) => {
    // Iterating forwards and applying each snapshot in succession allows easy handling of deleted pointers
    const chain = createSnapshotChain(fromSnapshot, thisSnapshot, 'getRawDiff')
    const js = {}
    chain.forEach(snapshot => {
        snapshot._snapshotStore.applyValues(js)
    })
    return js
}

type CompareCB = (isEqual: boolean, type: string, id: string, originalValue: any) => void

const compareValuesToOriginal = (diff: any, fromSnapshot: Null<SnapshotDal>, callback: CompareCB): void => {
    Object.entries(diff).forEach(([type, changedValues]) => {
        Object.entries(changedValues as any).forEach(([id, changedValue]) => {
            const originalValue = getRawValue(fromSnapshot, {id, type})
            const isEqual = deepCompare(originalValue, changedValue)
            callback(isEqual, type, id, originalValue)
        })
    })
}

/**
 * A SnapshotDal object reflects the data in the Document Manager Dal at the time the SnapshotDal was created.
 */
class SnapshotDal {
    _isForeign: boolean = false

    public lastTransactionId?: string

    constructor(
        public _previousSnapshot: Null<SnapshotDal>,
        public readonly _snapshotStore: DmStore,
        public readonly id: string = ''
    ) {}

    getPreviousSnapshot(): Null<SnapshotDal> {
        return this._previousSnapshot
    }

    /** Returns true if the pointer exists at the time when the snapshot was taken */
    exists(pointer: Pointer) {
        return getRawValue(this, pointer) !== undefined
    }

    /** Returns true if the snapshot contains the pointer in it's store */
    contains(pointer: Pointer) {
        return this._snapshotStore.has(pointer)
    }

    /** Returns the immutable value for the given pointer at the time when the snapshot was taken
     * The pointer parameter can have 3 properties:
     * type (string), id (string), and innerPath (string or array of strings)
     */
    getValue(pointer: Pointer) {
        let value = getRawValue(this, pointer)
        if (typeof value !== 'object' || value === null) {
            return value
        }
        let {innerPath} = pointer
        if (innerPath) {
            if (typeof innerPath === 'string') {
                innerPath = [innerPath]
            }
            if (innerPath.length) {
                value = _.get(value, innerPath)
            }
        }
        return createImmutableProxy(value)
    }

    /** Creates an object that when applied will revert from toSnapshot back to fromSnapshot */
    createRevertJS(fromSnapshot: Null<SnapshotDal>) {
        const diff = getRawDiff(this, fromSnapshot)
        const result = {}
        compareValuesToOriginal(diff, fromSnapshot, (valueIsEqual, type, id, originalValue) => {
            // Only add the value if it's really different
            if (!valueIsEqual) {
                let typeStore = result[type]
                if (!typeStore) {
                    typeStore = {}
                    result[type] = typeStore
                }
                typeStore[id] = originalValue
            }
        })
        return createImmutableProxy(result)
    }

    /** This returns a diff that is not wrapped in an immutable proxy.
     * THE VALUES IN THE DIFF ARE REFERENCES TO DATA THAT MUST NOT BE MODIFIED.
     * Please make sure that the pointer values returned by this function are not be modified.
     * @param fromSnapshot
     **/
    mutableDiff(fromSnapshot: SnapshotDal | null): any {
        if (!fromSnapshot) {
            return this.toJS()
        }
        const diff = getRawDiff(this, fromSnapshot)
        compareValuesToOriginal(diff, fromSnapshot, (valueIsEqual, type, id) => {
            // Only add the value if it's really different
            if (valueIsEqual) {
                delete diff[type][id]
            }
        })
        return diff
    }

    /** Returns an object representing the difference in state from this snapshot to fromSnapshot. wrapped in immutableProxy*/
    diff(fromSnapshot: SnapshotDal | null): any {
        return createImmutableProxy(this.mutableDiff(fromSnapshot))
    }

    /** Returns an object containing all the values represented by this snapshot for the specified type. */
    toJSForType(type: string): any {
        const chain = createSnapshotChain(null, this, 'toJSForType')
        const js = {}
        chain.forEach(snapshot => {
            snapshot._snapshotStore.applyTypeValuesWithDelete(type, js)
        })
        return createImmutableProxy(js)
    }

    /** Returns an object representing the state when this snapshot was taken */
    toJS(): DalJsStore {
        const chain = createSnapshotChain(null, this, 'toJS')
        const js: DalJsStore = {}
        chain.forEach(snapshot => {
            snapshot._snapshotStore.applyValuesWithDelete(js)
        })
        return createImmutableProxy(js)
    }

    isPredecessorOf(snapshot: SnapshotDal) {
        let _snapshot: Null<SnapshotDal> = snapshot.getPreviousSnapshot()
        while (_snapshot) {
            if (_snapshot === this) {
                return true
            }
            _snapshot = _snapshot.getPreviousSnapshot()
        }
        return false
    }

    equals(otherSnapshot: SnapshotDal): boolean {
        return this === otherSnapshot
    }

    createSnapshotChainTo(toSnapshot: Null<SnapshotDal>) {
        return createSnapshotChain(this, toSnapshot, 'createSnapshotChainTo')
    }

    setPreviousSnapshot(otherSnapshot: Null<SnapshotDal>): void {
        this._previousSnapshot = otherSnapshot
    }

    get isForeign() {
        return this._isForeign
    }

    setAsForeign() {
        this._isForeign = true
    }

    /**
     * find next snapshot
     * @param {SnapshotList} list
     * @returns {SnapshotDal}
     */
    findNext(list: SnapshotList): Null<SnapshotDal> {
        return list.find(s => s._previousSnapshot === this)
    }

    /**
     * Returns the internal store.
     * THE RETURNED STORE MUST NOT BE MODIFIED. It should only be used for reading values.
     * Store values can be copied into other stores because store values are never modified, only replaced.
     */
    getStore(): DmStore {
        return this._snapshotStore
    }

    _toArray() {
        const arr: SnapshotDal[] = []
        // eslint-disable-next-line @typescript-eslint/no-this-alias,consistent-this
        let t: Null<SnapshotDal> = this
        while (t !== null) {
            arr.push(t)
            t = t.getPreviousSnapshot()
        }
        return arr.reverse()
    }

    [Symbol.iterator](): Iterator<Null<SnapshotDal>> {
        let curr: SnapshotDal | null
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this
        return {
            next(): IteratorResult<Null<SnapshotDal>> {
                curr = curr ? curr._previousSnapshot : self
                return {
                    done: !curr,
                    value: curr
                }
            }
        }
    }
}

export {SnapshotDal, createSnapshotChain}
