import type {DocumentManager} from '@wix/document-manager-core'
import type {PageAPI} from '@wix/document-manager-extensions/src/extensions/page'
import _ from 'lodash'
import {DATA_TYPES} from '../../constants/constants'
import {cleanRef} from './utils/migrationUtil'

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    // 1 - find existing refs from document_data.CUSTOM_MENUS.menus
    // 2 - find all items from data for all pages
    // 3 - add difference to document_data.CUSTOM_MENUS.menus
    const {dal} = documentManager

    const pageIndex = (documentManager.extensionAPI.page as PageAPI).getPageIndexId(pageId)
    // basically it is enough just to set CUSTOM_MENUS.menus with this value, but we are doing following logic
    // in order to consider elements order and save semantic for data structure in this  context
    const customMenuReferences = _(dal.query(DATA_TYPES.data, pageIndex, dataItem => dataItem.type === 'CustomMenu'))
        .keys()
        .map(k => `#${k}`)
        .value()
    const customMenusCollection = dal.get({id: 'CUSTOM_MENUS', type: DATA_TYPES.data, innerPath: ['menus']})
    const existingReferencedMenus = _.filter(customMenusCollection, menuQuery =>
        dal.has({id: cleanRef(menuQuery), type: 'data'})
    )
    const missingRefs = _.difference(customMenuReferences, existingReferencedMenus)

    dal.set(
        {id: 'CUSTOM_MENUS', type: DATA_TYPES.data, innerPath: ['menus']},
        existingReferencedMenus.concat(missingRefs)
    )
}

const name = 'customMenusCollectionFixer'
const version = 1

export {migratePage, name, version}
