import type {PublicMethodUtils} from '@wix/document-services-types'
import {siteMetadata} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {deprecate, defineAction, defineGetter, defineDataManipulationAction} = publicMethodUtils

    return {
        methods: {
            compatibility: {
                hasAppPage: defineGetter(siteMetadata.compatibility.hasAppPage),
                hasOldApp: defineGetter(siteMetadata.compatibility.hasOldApp),
                hasNewBlog: defineGetter(siteMetadata.compatibility.hasNewBlog)
            },
            seo: {
                indexing: {
                    enable: defineAction(siteMetadata.seo.indexing.enable),
                    isEnabled: defineGetter(siteMetadata.seo.indexing.isEnabled)
                },
                title: {
                    set: deprecate(defineAction(siteMetadata.seo.title.set), 'deprecated'),
                    get: deprecate(defineGetter(siteMetadata.seo.title.get), 'deprecated'),
                    validate: deprecate(defineGetter(siteMetadata.seo.title.validate), 'deprecated')
                },
                description: {
                    set: deprecate(defineAction(siteMetadata.seo.description.set), 'deprecated'),
                    get: deprecate(defineGetter(siteMetadata.seo.description.get), 'deprecated'),
                    validate: deprecate(defineGetter(siteMetadata.seo.description.validate), 'deprecated')
                },
                keywords: {
                    set: defineAction(siteMetadata.seo.keywords.set),
                    get: defineGetter(siteMetadata.seo.keywords.get),
                    validate: defineGetter(siteMetadata.seo.keywords.validate)
                },
                headTags: {
                    set: defineAction(siteMetadata.seo.headTags.set),
                    get: defineGetter(siteMetadata.seo.headTags.get),
                    clientSideValidation: defineGetter(siteMetadata.seo.headTags.clientSideValidation),
                    validate: defineGetter(siteMetadata.seo.headTags.validate)
                },
                redirectUrls: {
                    update: defineAction(siteMetadata.seo.redirectUrls.update),
                    get: defineGetter(siteMetadata.seo.redirectUrls.get),
                    remove: defineAction(siteMetadata.seo.redirectUrls.remove),
                    validate: defineGetter(siteMetadata.seo.redirectUrls.validate)
                },
                ERRORS: siteMetadata.seo.ERRORS
            },
            social: {
                facebook: {
                    thumbnail: {
                        set: defineAction(siteMetadata.social.facebook.setThumbnail),
                        get: defineGetter(siteMetadata.social.facebook.getThumbnail)
                    },
                    userName: {
                        set: defineAction(siteMetadata.social.facebook.setUsername),
                        get: defineGetter(siteMetadata.social.facebook.getUsername),
                        validate: defineGetter(siteMetadata.social.facebook.validateUsername)
                    },
                    ERRORS: siteMetadata.social.facebook.ERRORS
                }
            },
            favicon: {
                set: defineAction(siteMetadata.favicon.set),
                get: defineGetter(siteMetadata.favicon.get),
                ERRORS: siteMetadata.favicon.ERRORS
            },
            statistics: {
                cookies: {
                    enable: defineAction(siteMetadata.statistics.cookies.enable),
                    isEnabled: defineGetter(siteMetadata.statistics.cookies.isEnabled)
                }
            },
            premiumFeatures: {
                get: defineGetter(siteMetadata.premiumFeatures.getFeatures),
                set: defineAction(siteMetadata.premiumFeatures.setFeatures)
            },
            generalInfo: {
                //is this namespace redundant?
                isHttpsEnabled: defineGetter(siteMetadata.generalInfo.isHttpsEnabled),
                getCurrency: defineGetter(siteMetadata.generalInfo.getCurrency),
                getRegionalLanguage: defineGetter(siteMetadata.generalInfo.getRegionalLanguage),
                getLocale: defineGetter(siteMetadata.generalInfo.getLocale),
                getUserInfo: defineGetter(siteMetadata.generalInfo.getUserInfo),
                getUserId: defineGetter(siteMetadata.generalInfo.getUserId),
                getPublicUrl: defineGetter(siteMetadata.generalInfo.getPublicUrl),
                getMetaSiteId: defineGetter(siteMetadata.generalInfo.getMetaSiteId),
                getSiteId: defineGetter(siteMetadata.generalInfo.getSiteId),
                getSiteToken: defineGetter(siteMetadata.generalInfo.getSiteToken),
                getUserPermissions: defineGetter(siteMetadata.generalInfo.getUserPermissions),
                getArtifactsUrl: defineGetter(siteMetadata.generalInfo.getArtifactsUrl),
                getArtifactOverrides: defineGetter(siteMetadata.generalInfo.getArtifactOverrides),
                media: {
                    getMediaAuthToken: defineGetter(siteMetadata.generalInfo.getMediaAuthToken),
                    getSiteUploadToken: defineGetter(siteMetadata.generalInfo.getMediaSiteUploadToken),
                    getUserUploadToken: defineGetter(siteMetadata.generalInfo.getMediaUserUploadToken)
                },
                getSiteOriginalTemplateId: defineGetter(siteMetadata.generalInfo.getSiteOriginalTemplateId),
                getLanguage: defineGetter(siteMetadata.generalInfo.getLanguage),
                getGeo: defineGetter(siteMetadata.generalInfo.getGeo),
                getTimeZone: defineGetter(siteMetadata.generalInfo.getTimeZone),
                isFirstSave: defineGetter(siteMetadata.generalInfo.isFirstSave),
                isDraft: defineGetter(siteMetadata.generalInfo.isDraft),
                isSitePublished: defineGetter(siteMetadata.generalInfo.isSitePublished),
                isTemplate: defineGetter(siteMetadata.generalInfo.isTemplate),
                getDocumentType: defineGetter(siteMetadata.generalInfo.getDocumentType),
                isSiteFromOnBoarding: defineGetter(siteMetadata.generalInfo.isSiteFromOnBoarding),
                setPublicUrl: defineAction(siteMetadata.generalInfo.setPublicUrl),
                getCurrentRevisionPublicUrl: defineGetter(siteMetadata.generalInfo.getCurrentRevisionPublicUrl),
                getBranchId: defineGetter(siteMetadata.generalInfo.getBranchId),
                getRevision: defineGetter(siteMetadata.generalInfo.getRevision),
                sitePropertiesInfo: {
                    reload: defineAction(siteMetadata.generalInfo.sitePropertiesInfo.reload)
                },
                urlFormat: {
                    get: defineGetter(siteMetadata.generalInfo.getUrlFormat),
                    is: defineGetter(siteMetadata.generalInfo.isUsingUrlFormat),
                    isSlash: defineGetter(siteMetadata.generalInfo.isUsingSlashUrlFormat),
                    list: defineGetter(siteMetadata.generalInfo.getPossibleUrlFormats)
                }
            },
            language: {
                multilingual: {
                    isEnabled: defineGetter(siteMetadata.language.multilingual.isEnabled)
                },
                current: {
                    get: defineGetter(siteMetadata.language.current.get),
                    set: defineDataManipulationAction(siteMetadata.language.current.set)
                },
                original: {
                    get: defineGetter(siteMetadata.language.original.get),
                    set: defineAction(siteMetadata.language.original.set)
                },
                public: {
                    get: defineGetter(siteMetadata.language.public.get)
                },
                status: {
                    set: defineAction(siteMetadata.language.status.set)
                },
                get: defineGetter(siteMetadata.language.get),
                getFull: defineGetter(siteMetadata.language.getFull),
                add: defineAction(siteMetadata.language.add),
                remove: defineAction(siteMetadata.language.remove),
                init: publicMethodUtils.defineDataManipulationAction(siteMetadata.language.init, {
                    noBatchingAfter: true
                }),
                isCurrentLanguageSecondary: defineGetter(siteMetadata.language.isCurrentLanguageSecondary)
            },
            siteName: {
                set: defineAction(siteMetadata.siteName.set),
                setAsync: defineAction(siteMetadata.siteName.setAsync),
                get: defineGetter(siteMetadata.siteName.get),
                sanitize: defineAction(siteMetadata.siteName.sanitize),
                validate: defineGetter(siteMetadata.siteName.validate),
                validateAsync: defineGetter(siteMetadata.siteName.validateAsync),
                getUsed: defineGetter(siteMetadata.siteName.getUsedSiteNames),
                generate: defineAction(siteMetadata.siteName.generate),
                markAsUsed: defineAction(siteMetadata.siteName.markSiteNameAsUsed),
                ERRORS: siteMetadata.siteName.ERRORS
            },
            siteDisplayName: {
                get: defineGetter(siteMetadata.siteDisplayName.get)
            }
        }
    }
}
