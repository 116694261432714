import _ from 'lodash'
import slots from '../../slots/slots'

function cleanupCompsInSlots(ps, compInSlotPointer) {
    if (compInSlotPointer) {
        const parentPointer = ps.pointers.components.getParent(compInSlotPointer)
        const slotsData = slots.getSlotsData(ps, parentPointer)

        if (!_.isEmpty(slotsData)) {
            for (const slotName in slotsData) {
                if (slotsData[slotName].id === compInSlotPointer.id) {
                    slots.removeInternal(ps, parentPointer, slotName, true)
                    return
                }
            }
        }
    }
}

export default {cleanupCompsInSlots}
