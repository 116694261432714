import {
    CreateExtArgs,
    DalItem,
    DalValue,
    DmApis,
    Extension,
    ExtensionAPI,
    PointerMethods,
    pointerUtils,
    ValidatorMap
} from '@wix/document-manager-core'
import {removePrefix} from '@wix/document-manager-utils'
import type {
    CompRef,
    Pointer,
    RefArray,
    ResolvedRefArray,
    ResolvedVariantRelation,
    VariantRelation,
    VariantDataItem,
    PossibleViewModes
} from '@wix/document-services-types'
import _ from 'lodash'
import {DATA_TYPES, DATA_TYPES_VALUES_MAP, RELATION_DATA_TYPES} from '../constants/constants'
import type {DataModelExtensionAPI} from './dataModel'
import type {RelationshipsAPI} from './relationships'
import type {DataExtensionAPI} from './data'

const {getPointer} = pointerUtils

export class NonExistentVariantError extends Error {
    constructor(public variantId: string) {
        super(`variant ${variantId} does not exist`)
    }
}
interface PointerVariantData {
    pageId: string
    variants?: any[]
    relationPointer: Pointer | null
    refArrayOrValue: Pointer
    isRefArray: boolean
}
export interface VariantsAPI {
    setComponentVariantRelationRefArray(
        compPointer: Pointer,
        refArrayNamespace: string,
        newRefArray: Partial<ResolvedRefArray<any>>,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ): void
    getPointerVariantsData(
        pointerWithVariants: Pointer,
        itemType: string,
        refArrayOrValuePointer: Pointer
    ): PointerVariantData
    getRelationPointerFromRefArrayByVariants(
        itemType: string,
        refArray: RefArray,
        variants: any[] | undefined,
        pageId: string
    ): Pointer | null
    exactMatchVariantRelationPredicateitem(relation: VariantRelation, variants: any[]): boolean
    getConditionalValueByRelationPtr(namespace: string, relationPointer: Pointer): Pointer
    getConditionalValuePointerByVariants(
        namespace: string,
        refArray: any,
        variants: any[],
        pageId: string,
        relationPointer: Pointer
    ): Pointer | null | undefined
    getDefaultValuePointer(itemType: string, refArray: any, pageId: string): null | Pointer | null
    getDataPointerConsideringVariants(
        pointerWithVariants: Pointer,
        itemType: string,
        refArrayOrValuePointer: Pointer
    ): Pointer | null | undefined
    getVariantOwner(variantPointer: Pointer): CompRef
}

export type VariantsExtensionAPI = ExtensionAPI & {
    variants: VariantsAPI
}

const NO_MATCH: string[] = []

const createPointersMethods = ({dal}: DmApis): PointerMethods => {
    const getVariantRelations = (itemDataType: string, variantId: string) => {
        const result = dal.query(itemDataType, dal.queryFilterGetters.getVariantRelationFilter(`#${variantId}`))
        return _(result)
            .keys()
            .map(id => getPointer(id, itemDataType))
            .value()
    }
    const getVariantDataItemsByComponentId = (componentId: string) =>
        dal.query(DATA_TYPES.variants, dal.queryFilterGetters.getVariantFilter(componentId))

    return {
        data: {
            getVariantRelations,
            getVariantDataItemsByComponentId
        },
        variants: {
            getVariantRelations,
            getVariantDataItemsByComponentId
        }
    }
}

const createFilters = () => ({
    getVariantRelationFilter: (namespace: string, value: DalItem): string[] => {
        if (value.variants && value.type === RELATION_DATA_TYPES.VARIANTS) {
            return value.variants
        }
        return NO_MATCH
    },
    getVariantFilter: (namespace: string, value: any): string[] => {
        if (value?.componentId) {
            return [value.componentId]
        }
        return NO_MATCH
    }
})

const createExtensionAPI = ({dal, extensionAPI, pointers}: CreateExtArgs): VariantsExtensionAPI => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const {data: dataApi} = extensionAPI as DataExtensionAPI
    const {relationships} = extensionAPI as RelationshipsAPI

    const getVariantRelationsByVariantsMap = (
        variantRelations: ResolvedVariantRelation<any>[]
    ): Record<string, ResolvedVariantRelation<any>> => {
        const variantRelationsByVariants = {}
        for (const variantRelation of variantRelations ?? []) {
            const key = variantRelation.variants?.join(',')
            variantRelationsByVariants[key] = variantRelation
        }

        return variantRelationsByVariants
    }

    const removeItemIfOrphaned = (itemPointer: Pointer) => {
        const referencesToPointer = relationships.getReferencesToPointer(itemPointer)
        if (referencesToPointer.length === 0) {
            dal.remove(itemPointer)
        }
    }

    const removeVariantRelationAndRefsIfNecessary = (
        dalItem: ResolvedVariantRelation<any> | any,
        itemNamespace: string
    ) => {
        const itemPointer = getPointer(dalItem.id, itemNamespace)
        removeItemIfOrphaned(itemPointer)

        for (const variantId of dalItem.variants ?? []) {
            const variantPointer = getPointer(removePrefix(variantId, '#'), DATA_TYPES.variants)
            removeItemIfOrphaned(variantPointer)
        }

        if (dalItem.to) {
            const toPointer = getPointer(removePrefix(dalItem.to.id, '#'), itemNamespace)
            removeItemIfOrphaned(toPointer)
        }
    }

    const verifyVariantsExist = (variantRelations: ResolvedVariantRelation<any>[]) => {
        const variantLists = variantRelations.map(
            value => value.variants?.map((variantId: string) => removePrefix(variantId, '#')) ?? []
        )
        const allVariants = _.uniq(_.flatten(variantLists))
        for (const variantId of allVariants) {
            const variantPointer = getPointer(variantId, DATA_TYPES.variants)
            if (!dal.get(variantPointer)) {
                throw new NonExistentVariantError(variantId)
            }
        }
    }

    const setComponentVariantRelationRefArray = (
        compPointer: Pointer,
        refArrayNamespace: string,
        newRefArray: Partial<ResolvedRefArray<any>>,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ) => {
        verifyVariantsExist(newRefArray.values!)

        const refArrayInDal = dataModel.components.getItem(
            compPointer,
            refArrayNamespace,
            languageCode,
            useOriginalLanguageFallback
        ) as ResolvedRefArray<any>

        const refArrayInDalByVariants = getVariantRelationsByVariantsMap(refArrayInDal?.values)
        const newRefArrayByVariants = getVariantRelationsByVariantsMap(newRefArray.values!)

        for (const [variantsKey, variantRelation] of Object.entries(newRefArrayByVariants)) {
            const matchingVariantRelationInDal = refArrayInDalByVariants[variantsKey]
            if (matchingVariantRelationInDal) {
                // reuse the id of the existing variant relation to prevent the creation of a new one
                variantRelation.id = matchingVariantRelationInDal.id
                if (
                    variantRelation.to &&
                    typeof variantRelation.to === 'object' &&
                    typeof matchingVariantRelationInDal.to === 'object'
                ) {
                    // reuse the id of the data item to prevent the creation of a new one
                    variantRelation.to.id = matchingVariantRelationInDal.to.id
                }
                delete refArrayInDalByVariants[variantsKey]
            }
        }

        dataModel.components.addItem(compPointer, refArrayNamespace, newRefArray, languageCode)

        for (const leftoverVariantRelation of Object.values(refArrayInDalByVariants)) {
            removeVariantRelationAndRefsIfNecessary(leftoverVariantRelation, refArrayNamespace)
        }
    }

    const exactMatchVariantRelationPredicateitem = (relation: VariantRelation, variants: any[]) =>
        _.get(relation, ['type']) === RELATION_DATA_TYPES.VARIANTS &&
        _.isEqual(_.sortBy(dataApi.variantRelation.extractVariants(relation)), _.sortBy(variants))

    const getRelationPointerFromRefArrayByVariants = (
        itemType: string,
        refArray: RefArray,
        variants: any[] | undefined,
        pageId: string
    ): Pointer | null => {
        if (!dataApi.refArray.isRefArray(refArray) || !variants) {
            return null
        }

        const relationId = _.find(dataApi.refArray.extractValuesWithoutHash(refArray), refValueId => {
            const refValuePointer = pointers.data.getItem(itemType, refValueId, pageId)
            const refValue = dal.get(refValuePointer)
            return exactMatchVariantRelationPredicateitem(refValue, variants)
        })

        return relationId ? pointers.data.getItem(itemType, relationId, pageId) : null
    }

    const getPointerVariantsData = (
        pointerWithVariants: Pointer,
        itemType: string,
        refArrayOrValuePointer: Pointer
    ): PointerVariantData => {
        const pagePointer = pointers.structure.getPageOfComponent(pointerWithVariants)
        const pageId = pagePointer?.id
        const refArrayOrValue =
            refArrayOrValuePointer && dal.has(refArrayOrValuePointer) ? dal.get(refArrayOrValuePointer) : null

        const variants = pointers.structure.isWithVariants(pointerWithVariants)
            ? _.map(pointerWithVariants.variants, 'id')
            : undefined
        const relationPointer = getRelationPointerFromRefArrayByVariants(itemType, refArrayOrValue, variants, pageId)
        const isRefArray = dataApi.refArray.isRefArray(refArrayOrValue)
        return {
            pageId,
            variants,
            relationPointer,
            refArrayOrValue,
            isRefArray
        }
    }

    const getDefaultValuePointer = (itemType: string, refArray: any, pageId: string) => {
        if (!dataApi.refArray.isRefArray(refArray)) {
            return null
        }

        const nonRelationId = _.find(dataApi.refArray.extractValuesWithoutHash(refArray), refValueId => {
            const refValuePointer = pointers.data.getItem(itemType, refValueId, pageId)
            const refValue = dal.get(refValuePointer)
            return _.get(refValue, ['type']) !== RELATION_DATA_TYPES.VARIANTS
        })

        return nonRelationId ? pointers.data.getItem(itemType, nonRelationId, pageId) : null
    }

    const getConditionalValueByRelationPtr = (namespace: string, relationPointer: Pointer) => {
        const pageId = pointers.data.getPageIdOfData(relationPointer)
        const relation = dal.get(relationPointer)
        const scopedValueId = dataApi.variantRelation.extractTo(relation)
        return pointers.data.getItem(namespace, scopedValueId, pageId)
    }

    const getConditionalValuePointerByVariants = (
        namespace: string,
        refArray: any,
        variants: any[],
        pageId: string,
        relationPointer: Pointer
    ): Pointer | null | undefined => {
        if (!DATA_TYPES_VALUES_MAP[namespace]) {
            throw new Error(`data type ${namespace}, is not valid`)
        }

        const relationPointerFromArray =
            relationPointer || getRelationPointerFromRefArrayByVariants(namespace, refArray, variants, pageId)
        if (!relationPointerFromArray) {
            return undefined
        }

        return getConditionalValueByRelationPtr(namespace, relationPointer)
    }

    const getDataPointerConsideringVariants = (
        pointerWithVariants: Pointer,
        itemType: string,
        refArrayOrValuePointer: Pointer
    ): Pointer | null | undefined => {
        const {pageId, refArrayOrValue, variants, relationPointer, isRefArray} = getPointerVariantsData(
            pointerWithVariants,
            itemType,
            refArrayOrValuePointer
        )
        if (isRefArray) {
            if (variants) {
                const scopedValuePointer = getConditionalValuePointerByVariants(
                    itemType,
                    refArrayOrValue,
                    variants,
                    pageId,
                    relationPointer!
                )
                return scopedValuePointer
            }

            return getDefaultValuePointer(itemType, refArrayOrValue, pageId)
        }

        return !variants ? refArrayOrValuePointer : null
    }

    const getVariantOwner = (variantPointer: Pointer, viewMode: PossibleViewModes = 'DESKTOP'): CompRef => {
        const variantData = dal.get(variantPointer) as VariantDataItem

        if (variantData.type === 'BreakpointRange') {
            const [breakpointsData] = relationships.getReferencesToPointer(variantPointer, 'variants')
            return getVariantOwner(breakpointsData, viewMode)
        }

        return pointerUtils.getPointer(variantData.componentId, viewMode) as CompRef
    }

    return {
        variants: {
            setComponentVariantRelationRefArray,
            getPointerVariantsData,
            getRelationPointerFromRefArrayByVariants,
            exactMatchVariantRelationPredicateitem,
            getConditionalValueByRelationPtr,
            getConditionalValuePointerByVariants,
            getDefaultValuePointer,
            getDataPointerConsideringVariants,
            getVariantOwner
        }
    }
}

const uniqueVariantTypesInRelation = ['Preset']

const createValidator = ({dal, pointers, coreConfig}: DmApis): ValidatorMap => {
    const validateVariantRelation = (pointer: Pointer, value: DalValue) => {
        if (value?.type === 'VariantRelation' && value.variants.length > 1) {
            const {variants} = value

            const variantsFromDal = variants.map((variantRef: string) =>
                dal.get(pointers.getPointer(removePrefix(variantRef, '#'), DATA_TYPES.variants))
            )
            const nonUniqueVariants = uniqueVariantTypesInRelation
                .map(type => variantsFromDal.filter((variant: any) => variant.type === type))
                .filter(item => item.length > 1)
                .map(arr => arr[0].id)

            if (!_.isEmpty(nonUniqueVariants)) {
                return [
                    {
                        shouldFail: coreConfig.experimentInstance.isOpen('dm_failOnNonUniquePresetVariantsInBlocks'),
                        type: 'nonUniqueVariantsInRelation',
                        message: `VariantRelation ${JSON.stringify(
                            pointer
                        )} has more than one variant that should be unique`,
                        extras: {
                            namespace: pointer.type,
                            pointer,
                            variantRefs: nonUniqueVariants
                        }
                    }
                ]
            }
        }
    }

    return coreConfig.supportsUsingPresetVariants ? {validateVariantRelation} : {}
}

const createExtension = (): Extension => ({
    name: 'variants',
    createFilters,
    createPointersMethods,
    createExtensionAPI,
    createValidator
})

export {createExtension}
