import {mediaControlsMetadata} from './components/mediaControlsMetadata'
import {mediaOverlayControlsMetadata} from './components/mediaOverlayControlsMetadata'
import {appWidgetMetaData} from './components/appWidgetMetaData'
import {backToTopButtonLegacyMetaData} from './components/backToTopButtonLegacyMetaData'
import {backToTopButtonMetaData} from './components/backToTopButtonMetaData'
import {controllerMetaData} from './components/controllerMetaData'
import {menuToggleMetaData} from './components/menuToggleMetaData'
import {quickActionBarItemMetaData} from './components/quickActionBarItemMetaData'
import {quickActionBarMetaData} from './components/quickActionBarMetaData'
import {tinyMenuMetaData} from './components/tinyMenuMetaData'
import {tpaSectionMetaData} from './components/tpaSectionMetaData'
import {tpaWidgetMetaData} from './components/tpaWidgetMetaData'
import {tpaGluedWidgetMetaData} from './components/tpaGluedWidgetMetaData'
import {refComponentMetaData} from './components/refComponentMetaData'

export const metadataMap = {
    'wysiwyg.viewer.components.MediaControls': mediaControlsMetadata,
    'wysiwyg.viewer.components.MediaOverlayControls': mediaOverlayControlsMetadata,
    'platform.components.AppWidget': appWidgetMetaData,
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton': backToTopButtonLegacyMetaData,
    'wysiwyg.viewer.components.BackToTopButton': backToTopButtonMetaData,
    'platform.components.AppController': controllerMetaData,
    'wysiwyg.viewer.components.MenuToggle': menuToggleMetaData,
    'wysiwyg.viewer.components.QuickActionBarItem': quickActionBarItemMetaData,
    'wysiwyg.viewer.components.QuickActionBar': quickActionBarMetaData,
    'wysiwyg.viewer.components.mobile.TinyMenu': tinyMenuMetaData,
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget': tpaGluedWidgetMetaData,
    'tpa.viewer.components.tpapps.TPAGluedWidget': tpaGluedWidgetMetaData,
    'wysiwyg.viewer.components.tpapps.TPAWidget': tpaWidgetMetaData,
    'tpa.viewer.components.tpapps.TPAWidget': tpaWidgetMetaData,
    'wysiwyg.viewer.components.tpapps.TPASection': tpaSectionMetaData,
    'wysiwyg.viewer.components.tpapps.TPAMultiSection': tpaSectionMetaData,
    'wysiwyg.viewer.components.RefComponent': refComponentMetaData
}
