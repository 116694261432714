import type {PS} from '@wix/document-services-types'
import {ajaxLibrary} from '@wix/santa-ds-libs/src/warmupUtils'
import _ from 'lodash'
import tpa from 'tpa'
import siteMetadata from '../../siteMetadata/siteMetadata'
import clientSpecMapService from './clientSpecMapService'

const premiumAppsUrl = _.template('<%= premiumStateAPIUrl %>?siteGuid=<%= metasiteId %>')
const YEARLY = 6

const getPremiumStateTopology = function (ps: PS) {
    const serviceTopology = ps.pointers.general.getServiceTopology()
    const premiumStatePointer = ps.pointers.getInnerPointer(serviceTopology, 'premiumStateApiUrl')
    return ps.dal.get(premiumStatePointer) // 'https://editor.wix.com/_api/wix/getTpaPremiumState'
}

const getPremiumApps = function (ps: PS, metasiteId: string, onSuccess, onError?) {
    const base = getPremiumStateTopology(ps)
    const url = premiumAppsUrl({
        premiumStateAPIUrl: base,
        metasiteId
    })
    ajaxLibrary.ajax({
        type: 'POST',
        url,
        data: {},
        dataType: 'json',
        success(data) {
            if (_.isFunction(onSuccess)) {
                onSuccess(data.payload)
            }
        },
        error: onError
    })
}

const isYearly = function (packageData) {
    return packageData.cycle === YEARLY
}

const extractYearlyPremiumApps = function (json) {
    const yearlyAppDefIds = []
    const packageData = _.get(json, 'tpaPackages') || []
    if (_.isArray(packageData)) {
        _.forEach(packageData, function (packag) {
            if (isYearly(packag)) {
                yearlyAppDefIds.push(packag.appDefinitionId)
            }
        })
    } else if (_.isObject(packageData)) {
        if (isYearly(packageData)) {
            // @ts-expect-error
            yearlyAppDefIds.push(packageData.appDefinitionId)
        }
    }
    return yearlyAppDefIds
}

const getSiteUpgradeUrl = function (ps: PS, queryParameters?) {
    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
    const baseUpgradeUrl = `${serviceTopology.premiumServerUrl}/wix/api/premiumStart`
    const metaSiteId = siteMetadata.generalInfo.getMetaSiteId(ps)
    queryParameters = queryParameters || {}
    queryParameters.siteGuid = metaSiteId

    return new tpa.common.TPABaseUrlBuilder(baseUpgradeUrl).addMultipleQueryParams(queryParameters).build()
}

const getAppUpgradeUrl = function (ps: PS, applicationId: string, vendorProductId: string, paymentCycle?, options?) {
    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
    const baseUpgradeUrl = `${serviceTopology.premiumServerUrl}/wix/api/tpaStartPurchase`
    const appData = clientSpecMapService.getAppData(ps, applicationId)
    const metaSiteId = siteMetadata.generalInfo.getMetaSiteId(ps)
    options = options || {}
    const params: any = {
        applicationId,
        vendorProductId,
        paymentCycle: paymentCycle || 'MONTHLY',
        appDefinitionId: appData.appDefinitionId,
        metaSiteId,
        pp_type: options.pp_type,
        referralAdditionalInfo: options.referralAdditionalInfo
    }
    if (options.premiumIntent) {
        params.premiumIntent = options.premiumIntent
    }

    return new tpa.common.TPABaseUrlBuilder(baseUpgradeUrl).addMultipleQueryParams(params).build()
}

export default {
    getPremiumApps,
    extractYearlyPremiumApps,
    isYearly,
    getSiteUpgradeUrl,
    getAppUpgradeUrl
}
