import _ from 'lodash'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import dataModel from '../../dataModel/dataModel'
import componentStylesAndSkinsAPI from '../../component/componentStylesAndSkinsAPI'
import constants from '../../constants/constants'
import componentData from '../../component/componentData'

const LANGUAGE_SELECTOR_TYPE = 'wysiwyg.viewer.components.LanguageSelector'
const {isMobileComponentPropertiesSplit: isForked, splitMobileComponentProperties: fork} = componentData

const isLanguageSelector = ps => compPtr =>
    ps.dal.full.get(ps.pointers.getInnerPointer(compPtr, 'componentType')) === LANGUAGE_SELECTOR_TYPE

function prepareAndMigrateMobileLanguageSelector(ps, desktopCompPointer) {
    const mobileComp = ps.pointers.components.getMobilePointer(desktopCompPointer)
    if (!ps.dal.isExist(mobileComp)) {
        return false
    }
    const properties = dataModel.getPropertiesItem(ps, mobileComp)
    if (!isForked(ps, mobileComp)) {
        fork(ps, mobileComp)
    }

    migrateLanguageSelector(ps, mobileComp, properties, true)
    return true
}

function migrateLanguageSelector(ps, compPointer, compProperties, isMobile) {
    const compStyle = componentStylesAndSkinsAPI.style.get(ps, compPointer)
    const styleProperties = _.get(compStyle, ['style', 'properties'], {})
    const {iconSize: iconSizeStyleProp, iconType} = styleProperties
    const iconSize = iconSizeStyleProp && _.isFinite(Number(iconSizeStyleProp)) ? Number(iconSizeStyleProp) : undefined
    let newCompProperties
    if (isMobile) {
        newCompProperties = {
            iconSize,
            hasArrow: false,
            iconType: 'circle',
            itemFormat: 'iconOnly',
            alignTabs: 'center'
        }
    } else {
        newCompProperties = {
            iconSize,
            iconType,
            hasArrow: true
        }
    }

    dataModel.updatePropertiesItem(ps, compPointer, _.defaults(newCompProperties, compProperties))
}

export default {
    exec(ps) {
        const allLanguageSelectors = componentDetectorAPI.getAllComponentsFromFull(
            ps,
            null,
            isLanguageSelector(ps),
            constants.VIEW_MODES.DESKTOP
        )
        _.forEach(allLanguageSelectors, function (compPointer) {
            const compProperties = dataModel.getPropertiesItem(ps, compPointer)
            if (typeof compProperties.iconSize === 'undefined') {
                const isMobileMigrated = prepareAndMigrateMobileLanguageSelector(ps, compPointer)
                if (isMobileMigrated) {
                    migrateLanguageSelector(ps, compPointer, compProperties, false)
                }
            }
        })
    },
    name: 'languageSelectorFixer',
    version: 1
}
