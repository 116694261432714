type ReportTags = Record<string, any>
type ReportExtras = Record<string, any>

export interface ReportableErrorParams {
    errorType: string
    message: string
    tags?: ReportTags
    extras?: ReportExtras
}

export class ReportableError extends Error {
    errorType: string
    tags?: ReportTags
    extras?: ReportExtras
    originalMessage?: string

    constructor(params: ReportableErrorParams) {
        super(params.message)
        this.errorType = params.errorType
        this.tags = params.tags
        this.extras = params.extras

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ReportableError.prototype)
    }
}

export const getReportableFromError = (err: any, params: ReportableErrorParams) => {
    const reportableError = new ReportableError(params)

    const message = err.message ?? err.toString()
    reportableError.originalMessage = message
    reportableError.message = `${reportableError.message}|${message}`

    // eslint-disable-next-line lodash/prefer-lodash-typecheck
    if (err instanceof Error) {
        reportableError.stack = err.stack
    }

    if (err instanceof ReportableError) {
        reportableError.tags = Object.assign({}, reportableError.tags, err.tags)
        reportableError.extras = Object.assign({}, reportableError.extras, err.extras)
    }

    return reportableError
}
