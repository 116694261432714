import _ from 'lodash'
import type {OldSchemaService} from '@wix/document-services-types'

export default (schemasService: OldSchemaService) => {
    function validateItem(dataItem, schemaOrigin: string, options: {schemaName: string}) {
        if (!dataItem) {
            throw new Error('Data is not set')
        }
        const schemaName = _.get(dataItem, 'type') || _.get(options, 'schemaName')
        if (!schemaName) {
            throw new Error("dataItem has no 'type' nor options has 'schemaName'")
        }
        schemasService.validate(schemaName, dataItem, schemaOrigin)
    }

    function isItemValid(dataItem, fieldName: string, fieldValue, origin: string) {
        const schemaName = dataItem?.type
        if (!schemaName) {
            throw new Error("dataItem has no 'type' nor options has 'schemaName'")
        }
        const newValue = _.cloneDeep(dataItem)
        newValue[fieldName] = fieldValue
        return schemasService.isValid(schemaName, newValue, origin)
    }

    function resolveDefaultItem(schemaName: string, emptyItem) {
        //change to schemasService.createDefaultItem
        let validator = null
        if (schemasService.validators.data.getSchema(schemaName)) {
            validator = schemasService.validators.data
        } else if (schemasService.validators.props.getSchema(schemaName)) {
            validator = schemasService.validators.props
        }
        if (validator) {
            emptyItem = emptyItem || {}
            validator.validate(schemaName, emptyItem)
        }
        return emptyItem
    }

    return {
        validateDataBySchema: validateItem,
        validateItem,
        resolveDefaultItem,
        isItemValid
    }
}
