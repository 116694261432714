import consts from '../../constants/constants'
import siteGapMap from '../../structure/siteGapMap'
import layoutSettingsUtils from '../../structure/utils/layoutSettingsUtils'

function canBeFixedPosition(ps, compPointer) {
    return !ps.pointers.components.isMobile(compPointer)
}

export default {
    resizableSides: [consts.RESIZE_SIDES.BOTTOM],
    moveDirections(ps) {
        const masterPageLayoutSettings = layoutSettingsUtils.getLayoutSettings(ps)
        if (masterPageLayoutSettings.useDesktopSectionsLayout) {
            return []
        }
        const initialSiteGapMap = siteGapMap.getInitialGapMap(ps)
        return initialSiteGapMap.aboveFooter >= consts.SITE_SEGMENTS_GAP_THRESHOLD
            ? [consts.MOVE_DIRECTIONS.VERTICAL]
            : []
    },
    canBeFixedPosition,
    removable: false,
    duplicatable: false,
    containable: false,
    fullWidth: true,
    styleCanBeApplied(ps) {
        const isStudioUserPointer = ps.pointers.general.getIsStudioUser()
        return ps.dal.get(isStudioUserPointer)
    },
    hiddenable: false,
    collapsible: false,
    mobileConversionConfig: {
        preserveAspectRatio: false,
        siteSegmentRole: 'SITE_FOOTER',
        category: 'siteSegments'
    }
}
