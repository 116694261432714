import _ from 'lodash'
import constants from '../common/constants'
import type {AppDefinitionId} from '@wix/document-services-types'

function triggerEvent(appDefinitionId: AppDefinitionId, options) {
    return {
        intent: constants.Intents.PLATFORM_WORKER,
        type: constants.MessageTypes.TRIGGER_EVENT,
        appDefinitionId,
        args: options
    }
}

const addApps = (apps, initData) => ({
    intent: constants.Intents.PLATFORM_WORKER,
    type: constants.MessageTypes.ADD_APPS,
    apps: _.map(apps, app =>
        _.pick(app, [
            'applicationId',
            'appFields.platform.editorScriptUrl',
            'appFields.platform.baseUrls',
            'appDefinitionId',
            'firstInstall',
            'origin',
            'biData',
            'instance',
            'instanceId',
            'appUrlQueryParams',
            'silentInstallation',
            'headlessInstallation',
            'components',
            'settings'
        ])
    ),
    initData
})

function addApp(app, initData) {
    return addApps([app], initData)
}

const removeApp = (appDefinitionId: AppDefinitionId) => ({
    intent: constants.Intents.PLATFORM_WORKER,
    type: constants.NotifyMethods.REMOVE_APP,
    appDefinitionId
})

function loadManifest(appData, initData) {
    return {
        intent: constants.Intents.PLATFORM_WORKER,
        type: constants.MessageTypes.LOAD_MANIFEST,
        appDefinitionId: appData.appDefinitionId,
        appData,
        initData
    }
}

const initialize = (
    {
        extraNamespaces,
        sdkVersion,
        editorType,
        metaSiteId,
        userId,
        commonConfig,
        wixExpConfig,
        concurrentEditing,
        editorScriptHot
    },
    initData
) => ({
    intent: constants.Intents.PLATFORM_WORKER,
    type: constants.MessageTypes.INITIALIZE,
    extraNamespaces,
    sdkVersion,
    editorType,
    metaSiteId,
    userId,
    commonConfig,
    wixExpConfig,
    concurrentEditing,
    editorScriptHot,
    initData
})

export default _.mapValues(
    {
        initialize,
        triggerEvent,
        addApp,
        addApps,
        removeApp,
        loadManifest
    },
    fn => _.flow(fn, JSON.stringify)
)
