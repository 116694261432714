'use strict'

const _ = require('lodash')

const TPA_WIDGET_DATA_TYPE = 'TPAWidget'
const TPA_GLOBAL_DATA = 'TPAGlobalData'
const TPA_MULTI_SECTION_DATA_TYPE = 'TPAMultiSection'
const TPA_SECTION_DATA_TYPE = 'TPA'

const BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97'
const BOOKINGS_MAIN_SECTION_ID = '13d27016-697f-b82f-7512-8e20854c09f6'

const RESTAURANTS_APP_DEF_ID = '13e8d036-5516-6104-b456-c8466db39542'
const RESTAURANTS_MAIN_SECTION_ID = '13e8d047-31b9-9c1f-4f89-48ba9430f838'

const isTPAWidget = dataType => dataType === TPA_WIDGET_DATA_TYPE
const isTPAGlobalData = dataType => dataType === TPA_GLOBAL_DATA
const isTPASection = dataType => dataType === TPA_SECTION_DATA_TYPE
const isTPAMultiSection = dataType => dataType === TPA_MULTI_SECTION_DATA_TYPE

const getAllTPAWidgetsDataItems = pageJson => {
    const dataItems = _.get(pageJson, 'data.document_data')
    return _.filter(
        dataItems,
        ({type}) => isTPAWidget(type) || isTPAGlobalData(type) || isTPASection(type) || isTPAMultiSection(type)
    )
}

const completeDataItemWithAppDefinitionId = (componentData, clientSpecMap) => {
    const applicationId = isTPAGlobalData(componentData.type)
        ? componentData.id.split('-')[1]
        : componentData.applicationId
    const appDefinitionId = _.get(clientSpecMap, [applicationId, 'appDefinitionId'])
    if (appDefinitionId) {
        _.set(componentData, 'appDefinitionId', appDefinitionId)
    }
}

const completeSectionDataItemWithWidgetId = (componentData, clientSpecMap) => {
    const appWidgets = _.get(clientSpecMap, [componentData.applicationId, 'widgets'])
    const appDefinitionId = _.get(clientSpecMap, [componentData.applicationId, 'appDefinitionId'])
    const mainSectionWidget = _.find(
        appWidgets,
        widget => widget.appPage && widget.appPage.name && !widget.appPage.hidden
    )
    const widgetId = _.get(mainSectionWidget, 'widgetId')
    if (appDefinitionId === BOOKINGS_APP_DEF_ID) {
        _.set(componentData, 'widgetId', BOOKINGS_MAIN_SECTION_ID)
    } else if (appDefinitionId === RESTAURANTS_APP_DEF_ID) {
        _.set(componentData, 'widgetId', RESTAURANTS_MAIN_SECTION_ID)
    } else {
        _.set(componentData, 'widgetId', widgetId)
    }
}

module.exports = {
    name: 'tpaAddDataToDataItemFixer',
    version: 1,
    /*
     * Add appDefinitionId property to each TPA component data item
     * Add widgetId property to TPA Section data items
     */
    exec(pageJson, pageIdsArray, {clientSpecMap}) {
        const tpaWidgetsDataItems = getAllTPAWidgetsDataItems(pageJson)

        _.forEach(tpaWidgetsDataItems, dataItem => {
            completeDataItemWithAppDefinitionId(dataItem, clientSpecMap)

            if (isTPASection(dataItem.type) && !_.get(dataItem, 'widgetId')) {
                completeSectionDataItemWithWidgetId(dataItem, clientSpecMap)
            }
        })
    }
}
