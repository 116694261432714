import _ from 'lodash'
import type {Experiment, RunningExperiments} from '@wix/document-services-types'

const isTb = process.env.VIEWER_NAME === 'tb'
const runningExperiments: RunningExperiments = {
    dm_uninstallCodePackages: 'new',
    dm_strictModeFail: 'new',
    dm_duplicateRefFail: 'new',
    dm_failSplitQueries: 'new',
    dm_shouldBrokenRefFail: 'new',
    dm_throwOnSoqFailure: 'new',
    dm_failCsaveInitConflict: 'new',
    dm_useWhitelistRemovalForCsave: 'new',
    dm_repeatersFromViewer: 'old', //should turn on when we are ready to open in prod
    dm_disableValidationWhitelist: 'new',
    dm_componentNamespacesTypesValidator: 'new',
    dm_componentNamespacesTypesValidatorFail: 'old', //will turn on by default once we resolve all currently failing tests
    dm_validateSamePageMobileReport: 'new',
    dm_validateSamePageMobileFail: 'new',
    dm_repeaterDontAddRepeatedTemplateData: 'new',
    dm_repeaterDontAddRepeatedRefCompData: 'new',
    dm_mergeDesignOverVariants: 'new',
    dm_mobileAlgoValidationNoConduct: 'true',
    dm_changedOnCsaveInit: 'true',
    dm_lazyFilterRegistration: 'true',
    dm_copyTemplateBeforeCreatingOverride: 'new',
    'specs.UseTBAsMainRScript': isTb ? 'new' : 'old',
    'specs.UseTbInPreview': isTb ? 'new' : 'old',
    dm_fixerVersioning: 'new',
    dm_runDalStrictSchemaValidation: 'true',
    dm_failDalStrictSchemaValidation: 'true',
    dm_noWhitelistDalStrictSchemaValidation: 'false', // will be turned on only after cleanup of whitelist
    dm_saveWaitForReorder: 'true',
    dm_forceConfigName: 'true',
    dm_useComponentRegistry: 'true',
    dm_fitContainerToChildrenHeightRecoursively: 'true',
    dm_mobileSuffixForNewRefComponents: 'true',
    dm_blocksNewDevCenterApiComponents: 'true',
    dm_postUpdateLayoutIgnoreRemoteComps: 'true',
    dm_mobilePropsOverwriteFix: 'true',
    dm_reportRemoteStructureSet: 'true',
    dm_allowLayoutUpdateInRepeatersInMobile: 'true',
    dm_convertMobileComponentToMobileHints: 'true',
    dm_settleThroughProxy: 'true',
    dm_preventNestingSectionsInSections: 'true'
}

export const getDefaultExperiments = () => _.cloneDeep(runningExperiments)

export const createTestExperimentInstance = (experimentOverrides?: RunningExperiments): Experiment => {
    const experiments = _.merge({}, runningExperiments, experimentOverrides)
    return {
        getRunningExperiments: () => experiments,
        getValue: (name: string) => experiments[name],
        isOpen: (name: string) => {
            const value = experiments[name]
            return !!(value && value !== 'old' && value !== 'false')
        },
        isMultiValueExperimentOpen: (name: string) => !!experiments[name]
    }
}
