import _ from 'lodash'

const childTypeByContainerType = {
    'wysiwyg.viewer.components.BoxSlideShow': 'wysiwyg.viewer.components.BoxSlideShowSlide',
    'wysiwyg.viewer.components.StripContainerSlideShow': 'wysiwyg.viewer.components.StripContainerSlideShowSlide',
    'wysiwyg.viewer.components.StateBox': [
        'wysiwyg.viewer.components.StateBoxState',
        'wysiwyg.viewer.components.StateBoxFormState',
        'wysiwyg.viewer.components.Repeater'
    ],
    'wysiwyg.viewer.components.StateStrip': 'wysiwyg.viewer.components.StateStripState'
}

const childTypes = _.flatten(_.values(childTypeByContainerType))

const isSlideShowComponent = componentType => _.has(childTypeByContainerType, componentType)
const isSlideComponent = componentType => _.includes(childTypes, componentType)

export default {
    isSlideShowComponent,

    getSlidesFromChildrenByStructure: children =>
        _.filter(children, ({componentType}) => isSlideComponent(componentType)),
    getShownOnAllSlidesFromChildrenByStructure: children =>
        _.reject(children, ({componentType}) => isSlideComponent(componentType)),
    isSlideComponent,

    getMatchingChildSlideTypes(compType: string) {
        const childType = childTypeByContainerType[compType]
        if (!childType) {
            throw new Error('invalid comp type, not a slideshow component')
        }

        return _.flatten([childType])
    }
}
