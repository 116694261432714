import _ from 'lodash'
import clientSpecMap from '../../siteMetadata/clientSpecMap'
import type {PS} from '@wix/document-services-types'

function getAppBuilderClientSpecMap(ps: PS): any {
    return _.head(clientSpecMap.filterAppsDataByType(ps, 'appbuilder')) || {}
}

function getInstanceId(ps: PS) {
    return getAppBuilderClientSpecMap(ps).instanceId
}

function getApplicationId(ps: PS) {
    return getAppBuilderClientSpecMap(ps).applicationId
}

export default {
    getInstanceId,
    getApplicationId
}
