import type {CreateExtArgs, DAL} from '@wix/document-manager-core'
import type {Pointer, Pointers} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import _ from 'lodash'
import {DATA_TYPES} from '../../constants/constants'

const getTranslationLanguages = (ps: CreateExtArgs) => {
    return ps.dal.get(ps.pointers.multilingual.translationLanguages()) || []
}

const getLanguageCode = _.property(['languageCode'])

const getTranslationLanguageCodes = (dataAccessArgs: CreateExtArgs) =>
    getTranslationLanguages(dataAccessArgs).map(getLanguageCode)

const patchTranslations = (dataAccessArgs: CreateExtArgs, pageId: string, dataItemId: string, patch: any) => {
    _.forEach(getTranslationLanguages(dataAccessArgs), ({languageCode}) => {
        const translationDataItemPointer = dataAccessArgs.pointers.multilingualTranslations.translationDataItem(
            pageId,
            languageCode,
            dataItemId
        )
        if (dataAccessArgs.dal.has(translationDataItemPointer)) {
            const translationDataItem = deepClone(dataAccessArgs.dal.get(translationDataItemPointer))
            const updatedTranslationDataItem = _.isFunction(patch)
                ? patch(translationDataItem)
                : _.merge(translationDataItem, patch)
            dataAccessArgs.dal.set(translationDataItemPointer, updatedTranslationDataItem)
        }
    })
}

const patchPageTranslations = (dataAccessArgs: CreateExtArgs, pageId: string, patch: any) => {
    patchTranslations(dataAccessArgs, 'masterPage', pageId, patch)
}

const getLanguageByUseOriginal = ({dal, pointers}: CreateExtArgs, useOriginal: boolean) => {
    return useOriginal
        ? _.get(dal.get(pointers.multilingual.originalLanguage()), ['languageCode'])
        : dal.get(pointers.multilingual.currentLanguageCode())
}

const translateIfNeeded = (dal: DAL, pointers: Pointers, itemPointer: Pointer, languageCode: string | undefined) => {
    if (itemPointer.type !== 'data' || itemPointer.id === 'masterPage') {
        return itemPointer
    }
    const originalLanguage = _.get(dal.get(pointers.multilingual.originalLanguage()), ['languageCode'])
    if (!originalLanguage || originalLanguage === languageCode) {
        return itemPointer
    }
    const {pageId} = itemPointer
    if (!pageId) {
        return itemPointer
    }
    const mainLanguageDataItemValue = dal.get(itemPointer)
    if (!mainLanguageDataItemValue) {
        return itemPointer
    }

    return languageCode && DATA_TYPES.data === itemPointer.type
        ? pointers.data.getTranslatedData(itemPointer, languageCode)
        : itemPointer
}

export {patchPageTranslations, getLanguageByUseOriginal, getTranslationLanguageCodes, translateIfNeeded}
