export const DEAD_COMP_TYPE = 'to.be.removed.by.MobileMerge'

export const NON_SPLITTABLE_QUERIES = [
    'anchorQuery',
    'behaviorQuery',
    'breakpointsQuery',
    'connectionQuery',
    'contentRoleQuery',
    'dataQuery',
    'discussionsQuery',
    'fixerVersionsQuery',
    'groupingQuery',
    'intentQuery',
    'layoutQuery',
    'manifestQuery',
    'migrationQuery',
    'mobileHintsQuery',
    'namingQuery',
    'nickname',
    'pageSectionsQuery',
    'presetsQuery',
    'reactionsQuery',
    'slotsQuery',
    'statesQuery',
    'styleId',
    'transformationQuery',
    'transitionQuery',
    'triggersQuery',
    'variablesQuery',
    'breakpointVariantsQuery',
    'variantsQuery'
]
