import _ from 'lodash'
import clientSpecMapUtils from '../utils/clientSpecMapUtils'
import wixCodeUtils from '../utils/utils'
import constants from '../utils/constants'
import wixCodeMonitoring from '../utils/wixCodeMonitoringReporter'
import type {SnapshotDal} from '@wix/document-manager-core'
import type {Pointer, PS} from '@wix/document-services-types'
import {getMetaSiteId} from '../../utils/dalUtil'

const BASE_URL_KEY = 'wixCodeMonitoringHubUrl'

const psGetter = (ps: PS) => ({
    getBaseUrl() {
        const pointer = ps.pointers.general.getServiceTopology()
        return ps.dal.get(ps.pointers.getInnerPointer(pointer, BASE_URL_KEY))
    },
    getAppId() {
        return ps.extensionAPI.wixCode.getEditedGridAppId()
    },
    getMetaSiteId() {
        return getMetaSiteId(ps)
    },
    getInstanceId() {
        const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
        return wixCodeApp ? wixCodeApp.instanceId : ''
    }
})

const {
    paths: {ISOLATED_CODE_APP_ID, REVISION_GRID_APP_ID, OPEN_WIX_CODE_APP_ID}
} = constants

const pathToPointer = (path: string[]) => {
    const [type, id, ...innerPath] = path
    const pointer: Pointer = {type, id}
    if (innerPath?.length) {
        pointer.innerPath = innerPath
    }
    return pointer
}

const gridappPaths = {
    isolated: ISOLATED_CODE_APP_ID,
    revisioned: REVISION_GRID_APP_ID,
    open: OPEN_WIX_CODE_APP_ID
}

const pointers = _.mapValues(gridappPaths, v => pathToPointer(v))

const snapshotDalGetter = (snapshotDal: SnapshotDal) => ({
    getBaseUrl() {
        return snapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'})[BASE_URL_KEY]
    },
    getAppId() {
        return (
            snapshotDal.getValue(pointers.isolated) ??
            snapshotDal.getValue(pointers.open) ??
            snapshotDal.getValue(pointers.revisioned)
        )
    },
    getMetaSiteId() {
        return snapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'})
    },
    getInstanceId() {
        const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromSnapshot(snapshotDal)
        return wixCodeApp ? wixCodeApp.instanceId : ''
    }
})

const snapshotGetter = snapshot => ({
    getBaseUrl() {
        return snapshot.getIn(['serviceTopology', BASE_URL_KEY])
    },
    getAppId() {
        return (
            wixCodeUtils.extractFromSnapshot(snapshot, ISOLATED_CODE_APP_ID) ??
            wixCodeUtils.extractFromSnapshot(snapshot, OPEN_WIX_CODE_APP_ID) ??
            wixCodeUtils.extractFromSnapshot(snapshot, REVISION_GRID_APP_ID)
        )
    },
    getMetaSiteId() {
        return wixCodeUtils.extractFromSnapshot(snapshot, ['rendererModel', 'metaSiteId'])
    },
    getInstanceId() {
        const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromSnapshot(snapshot)
        return wixCodeApp ? wixCodeApp.instanceId : ''
    }
})

function getExtendedParams(getter, params) {
    const addedParams = {
        appId: getter.getAppId() || '',
        params: {
            metaSiteId: getter.getMetaSiteId(),
            instanceId: getter.getInstanceId()
        }
    }

    return _.merge({}, addedParams, params)
}

function trace(getter, params) {
    const extendedParams = getExtendedParams(getter, params)
    const baseUrl = getter.getBaseUrl()

    return wixCodeMonitoring.trace(extendedParams, baseUrl)
}

function traceWithPS(ps: PS, params?) {
    return trace(psGetter(ps), params)
}

function traceWithSnapshot(snapshot, params, snapshotDal?: SnapshotDal) {
    const getter = snapshotDal ? snapshotDalGetter(snapshotDal) : snapshotGetter(snapshot)
    return trace(getter, params)
}

export default {
    levels: wixCodeMonitoring.levels,
    trace: traceWithPS,
    traceWithSnapshot
}
