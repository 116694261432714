import type {Pointer, PS} from '@wix/document-services-types'
import structure from '../structure/structure'
import constants from '../constants/constants'
import validations from './validations'
import variantsUtils from '../variants/variantsUtils'
import hooks from '../hooks/hooks'
import metaDataUtils from '../componentsMetaData/metaDataUtils'
import {updateConsideringOverrides} from '../overrides/overrides'

const getResponsiveLayoutPointer = (ps: PS, compPointer: Pointer) =>
    variantsUtils.getComponentDataPointerConsideringVariants(ps, compPointer, constants.DATA_TYPES.layout)

const getResponsiveLayout = (ps: PS, compPointer: Pointer) =>
    variantsUtils.getComponentDataConsideringVariants(ps, compPointer, constants.DATA_TYPES.layout)

const updateResponsiveLayout = (ps: PS, compPointer, newLayout) => {
    validations.validateSingleLayoutObject(newLayout)
    const compType = metaDataUtils.getComponentType(ps, compPointer)
    const layoutPointer = getResponsiveLayoutPointer(ps, compPointer)
    const modifiedNewLayout = hooks.executeHookAndUpdateValue(
        ps,
        hooks.HOOKS.RESPONSIVE_LAYOUT.BEFORE_UPDATE,
        compType,
        [compPointer, layoutPointer],
        newLayout
    )
    return updateConsideringOverrides(ps, compPointer, modifiedNewLayout, constants.DATA_TYPES.layout)
}

const pinComponent = (ps: PS, compPointer: Pointer, newLayout) => {
    structure.reparentComponentToPage(ps, compPointer, false)
    return updateResponsiveLayout(ps, compPointer, newLayout)
}

const unpinComponent = (ps: PS, compPointer: Pointer, newContainerPointer: Pointer, newLayout) => {
    structure.setContainer(ps, null, compPointer, newContainerPointer)
    return updateResponsiveLayout(ps, compPointer, newLayout)
}

const removeScopedLayout = (ps: PS, compPointer: Pointer) => {
    const isWithVariants = ps.pointers.components.isWithVariants(compPointer)
    if (!isWithVariants) {
        throw new Error('cannot remove non scoped layout')
    }
    variantsUtils.removeComponentDataConsideringVariants(ps, compPointer, constants.DATA_TYPES.layout)
}

export default {
    get: getResponsiveLayout,
    update: updateResponsiveLayout,
    pin: pinComponent,
    unpin: unpinComponent,
    isNewLayoutMigration: () => true,
    removeScopedLayout,

    // runtime
    measure: (ps: PS, compPointer: Pointer) => ({
        boundingBox: ps.siteAPI.getComponentBoundingBox(compPointer),
        containerMeasures: ps.siteAPI.responsive.getGridMeasures(compPointer)
    }),
    getBoundingBox: (ps: PS, compPointer: Pointer) => ps.siteAPI.getComponentBoundingBox(compPointer),
    getNonRotatedBoundingBox: (ps: PS, compPointer: Pointer) => ps.siteAPI.getOriginalComponentBoundingBox(compPointer),
    getRelativeToViewportBoundingBox: (ps: PS, compPointer: Pointer) =>
        ps.siteAPI.getRelativeToViewportBoundingBox(compPointer),
    getPadding: (ps: PS, compPointer: Pointer) => ps.siteAPI.getPadding(compPointer),
    getScrollHeight: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getScrollHeight(compPointer),
    getClientHeight: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getClientHeight(compPointer),
    getScrollWidth: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getScrollWidth(compPointer),
    getClientWidth: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getClientWidth(compPointer),
    getGridMeasures: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.getGridMeasures(compPointer),
    detachLayout: (ps: PS, compPointer: Pointer) => ps.siteAPI.responsive.detach(compPointer),
    detachComponents: (ps: PS, componentsPointers) => ps.siteAPI.responsive.detachMulti(componentsPointers),
    updateDetachedLayout: (ps: PS, detachedCompPointer: Pointer, boundingBox) =>
        ps.siteAPI.responsive.updateDetached(detachedCompPointer, boundingBox),
    updateDetachedRotation: (ps: PS, detachedCompPointer: Pointer, rotationsInDegrees) =>
        ps.siteAPI.responsive.updateDetachedRotation(detachedCompPointer, rotationsInDegrees),
    updateDetachedTransformation: (ps: PS, detachedCompPointer: Pointer, transformationOverrides) =>
        ps.siteAPI.responsive.updateDetachedTransformation(detachedCompPointer, transformationOverrides),
    reattachLayout: (ps: PS, detachedCompPointer: Pointer) => {
        ps.siteAPI.responsive.clearDetached(detachedCompPointer.id)
    },
    reattachComponents: (ps: PS) => {
        ps.siteAPI.responsive.clearDetached()
    },
    detachGridItem: (ps: PS, compPointer: Pointer) => {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        ps.siteAPI.responsive.detachGridItem(compPointer.id, parentPointer.id)
    },
    updateDetachedGridItem: (ps: PS, detachedCompPointer: Pointer, boundingBox) =>
        ps.siteAPI.responsive.updateDetachedGridItem(detachedCompPointer.id, boundingBox),
    reattachGridItem: (ps: PS, detachedCompPointer: Pointer) => {
        ps.siteAPI.responsive.clearDetachedGridItem(detachedCompPointer.id)
    },
    detachStackItem: (ps: PS, compPointer: Pointer) => {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        ps.siteAPI.responsive.detachStackItem(compPointer.id, parentPointer.id)
    },
    updateDetachedStackItem: (ps: PS, detachedCompPointer: Pointer, boundingBox) =>
        ps.siteAPI.responsive.updateDetachedStackItem(detachedCompPointer.id, boundingBox),
    updateDetachedStackItemOrder: (ps: PS, detachedCompPointer: Pointer, order) =>
        // @ts-expect-error
        ps.siteAPI.responsive.updateDetachedStackItemOrder(detachedCompPointer.id, order),
    reattachStackItem: (ps: PS, detachedCompPointer: Pointer) => {
        ps.siteAPI.responsive.clearDetachedStackItem(detachedCompPointer.id)
    }
}
