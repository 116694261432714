import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import namespaces from '../namespaces/namespaces'
import constants from '../constants/constants'
import relationsUtils from '../variants/relationsUtils'
import dataModel from '../dataModel/dataModel'
import triggers from '../triggers/triggers'

const {REACTIONS, DATA_TYPES, VARIANTS, RELATION_DATA_TYPES} = constants
const REACTIONS_TYPE = REACTIONS.TYPE
const {CHILDREN_VARIANT_TO_SERIALIZE} = VARIANTS

const removeAllReactionsDataFromComp = (ps: PS, compPointerWithVariants) => {
    const hasReactions = namespaces.getNamespaceData(ps, compPointerWithVariants, DATA_TYPES.reactions)

    if (hasReactions) {
        namespaces.removeComponentNamespaceData(ps, compPointerWithVariants, DATA_TYPES.reactions)
    }
}

const getAllReactionsTo = (ps: PS, referencedPointer: Pointer) => {
    return ps.extensionAPI.relationships.getReferencesToPointer(referencedPointer, 'reactions')
}

const removeReaction = (ps: PS, reactionPointer: Pointer, pageId: string) => {
    if (!ps.dal.isExist(reactionPointer)) {
        return
    }

    const reactionListPointer = _.head(
        ps.pointers.data.getReactionItemsWithPredicate(
            item => item.type === REACTIONS_TYPE && item.values.includes(`#${reactionPointer.id}`),
            pageId
        )
    )
    const reactionsArray = ps.dal.get(reactionListPointer)

    reactionsArray.values = _.without(reactionsArray.values, `#${reactionPointer.id}`)

    if (_.isEmpty(reactionsArray.values)) {
        const relationToRemove: any = _.head(
            ps.pointers.data.getReactionItemsWithPredicate(
                {type: RELATION_DATA_TYPES.VARIANTS, to: `#${reactionsArray.id}`},
                pageId
            )
        )
        relationsUtils.removeRelation(ps, relationToRemove, DATA_TYPES.reactions, pageId)
    } else {
        ps.dal.set(reactionListPointer, reactionsArray)
        ps.dal.remove(reactionPointer)
    }
}

const removeReactionsByTrigger = (ps: PS, componentPointer: Pointer) => {
    const compTriggers = triggers.getAllTriggers(ps, componentPointer)
    compTriggers.forEach(triggerPointer => {
        const pageId = ps.pointers.data.getPageIdOfData(triggerPointer)
        const variantRelations = relationsUtils.getRelationsByVariantsAndPredicate(
            ps,
            [triggerPointer],
            DATA_TYPES.reactions,
            false
        )

        _.forEach(variantRelations, relation => {
            relationsUtils.removeRelation(ps, relation, DATA_TYPES.reactions, pageId)
        })
    })
}

const removeAllReactionsTo = (ps: PS, referencedPointer: Pointer) => {
    if (ps.dal.isExist(referencedPointer)) {
        const pageId = ps.pointers.data.getPageIdOfData(referencedPointer)
        const reactions = getAllReactionsTo(ps, referencedPointer)

        _.forEach(reactions, reactionPointer => {
            removeReaction(ps, reactionPointer, pageId)
        })
    }
}

const serializeVariantsOnParentComponentIfNeeded = (
    ps: PS,
    compPointer: Pointer,
    componentStructure,
    flags,
    pageId: string
) => {
    const children = ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(compPointer)
    const variantsToSerialize = _.reduce(
        children,
        (result, comp) => {
            const affectingVaraints = relationsUtils.getAllAffectingVariantsGroupedByVariantType(ps, comp)
            const affectingVaraintsArr = _(affectingVaraints)
                .pick(CHILDREN_VARIANT_TO_SERIALIZE)
                .values()
                .flattenDeep()
                .value()
            result.push(...affectingVaraintsArr)
            return result
        },
        []
    )

    const variantIds = _(variantsToSerialize)
        .filter(variantPtr => {
            const variant = ps.dal.get(variantPtr)
            return _.some(children, child => child.id === variant.componentId)
        })
        .map('id')
        .value()

    if (!_.isEmpty(variantIds)) {
        dataModel.serializeVariantsData(ps, componentStructure, flags.maintainIdentifiers, variantIds, pageId)
    }
}

const getComponentByReaction = (ps: PS, reactionPointer: Pointer, pageId: string) => {
    const reactionListPointer: any = _.head(
        ps.pointers.data.getReactionItemsWithPredicate(
            item => item.type === REACTIONS_TYPE && item.values.includes(`#${reactionPointer.id}`),
            pageId
        )
    )
    const relationPointer = _.head(
        ps.pointers.data.getReactionItemsWithPredicate(
            {type: RELATION_DATA_TYPES.VARIANTS, to: `#${reactionListPointer.id}`},
            pageId
        )
    )
    const relationData = ps.dal.get(relationPointer)
    return relationsUtils.getComponentFromRelation(ps, relationData, pageId)
}

const removeReactionsIfNeeded = (ps: PS, componentPointer: Pointer, newContainerPointer: Pointer) => {
    const currentPage = ps.pointers.components.getPageOfComponent(componentPointer)
    const newPage = ps.pointers.components.getPageOfComponent(newContainerPointer)
    if (newPage.id === currentPage.id) {
        return
    }

    const getRelationsOfOtherCompsByTrigger = triggerPointer =>
        relationsUtils.getRelationsByVariantsAndPredicate(
            ps,
            [triggerPointer],
            DATA_TYPES.reactions,
            false,
            item => item.from !== `#${componentPointer.id}`
        )

    const allCompTriggers = triggers.getAllTriggers(ps, componentPointer)
    const relationsToRemove = allCompTriggers
        .map(triggerPointer => getRelationsOfOtherCompsByTrigger(triggerPointer))
        .flat()
    relationsToRemove.forEach(relation =>
        relationsUtils.removeRelation(ps, relation, DATA_TYPES.reactions, currentPage.id)
    )
}

export default {
    getAllReactionsTo,
    getComponentByReaction,
    serializeVariantsOnParentComponentIfNeeded,
    removeAllReactionsDataFromComp,
    removeReactionsByTrigger,
    removeReaction,
    removeReactionsIfNeeded,
    removeAllReactionsTo
}
