/**
 * Created by Talm on 29/09/2014.
 */
import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as wixImmutableProxy from '@wix/wix-immutable-proxy'
import dataModel from '../dataModel/dataModel'
import {getComponentsEditorParams} from '../componentsEditorParams/componentsEditorParams'
import wixUiSantaMetaData from '@wix/wix-ui-santa/dist/statics/wix-ui-santa.metadata.json.bundle'
import Color from 'color'
import colorPresets from './colorPresets.json'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import * as santaCoreUtils from '@wix/santa-core-utils'
import generalInfo from '../siteMetadata/generalInfo'
import documentServicesSchemas from 'document-services-schemas'
import constants from '../constants/constants'
import themeConstants from './common/constants'
import isSystemStyle from './isSystemStyle'
import themeFonts from './fonts/fonts'
import themeColors from './colors/colors'
import textThemes from './textThemes/textThemes'
import idGenerator from '../utils/idGenerator'
import * as documentManagerUtils from '@wix/document-manager-utils'
import experiment from 'experiment-amd'
import stylableUtils from '../stylableEditor/stylableUtils'
import editorElementsThemePresets from '@wix/editor-elements-schemas/themePresets'

const {ReportableError} = documentManagerUtils
const {deepClone} = wixImmutableProxy
const {themeValidationHelper, schemasService} = documentServicesSchemas.services
const {DATA_TYPES} = santaCoreUtils.constants
const {PROPERTY_TYPE} = themeConstants
const COLOR_HEX_REGEX = '#([\\da-f]{3}){1,2}'
const COLOR_RGBA_REGEX =
    'rgba\\((?:([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]){1,3},\\s?){3}(?:1|0?\\.\\d+)\\)|rgb\\(([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]){1,3}(?:,\\s?([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]){1,3}){2}\\)'
const COLORS_REGEX = new RegExp(`(${COLOR_HEX_REGEX})|(${COLOR_RGBA_REGEX})`, 'ig')

const themePropertiesGettersMap = {
    [PROPERTY_TYPE.FONT]: themeFonts.getAll,
    [PROPERTY_TYPE.COLOR]: themeColors.getAll,
    [PROPERTY_TYPE.TEXT_THEME]: textThemes.getAll
}

const themePropertiesItemGettersMap = {
    [PROPERTY_TYPE.FONT]: themeFonts.get,
    [PROPERTY_TYPE.COLOR]: themeColors.get,
    [PROPERTY_TYPE.TEXT_THEME]: textThemes.get
}

const themePropertiesItemSetterMap = {
    [PROPERTY_TYPE.FONT]: themeFonts.set,
    [PROPERTY_TYPE.COLOR]: themeColors.set,
    [PROPERTY_TYPE.TEXT_THEME]: textThemes.set
}

function updateFonts(ps: PS, fonts: Record<string, string>) {
    validateGetterAndKey(ps, fonts, PROPERTY_TYPE.FONT)
    _.forEach(fonts, fontStr => {
        validateFont(ps, fontStr)
    })
    setToDAL(ps, fonts, PROPERTY_TYPE.FONT)

    ps.setOperationsQueue.executeAfterCurrentOperationDone(() => {
        ps.extensionAPI.theme.onChange.onThemeChangeRunCallbacks({
            type: PROPERTY_TYPE.FONT,
            values: fonts
        })
    })
}

function updateTextThemes(ps: PS, updates) {
    if (experiment.isOpen('dm_moveTextThemeGetUpdateToExt')) {
        return ps.extensionAPI.theme.updateTextTheme(updates)
    }
    validateGetterAndKey(ps, updates, PROPERTY_TYPE.TEXT_THEME)
    _.forEach(updates, textTheme => {
        validateTextTheme(ps, textTheme)
    })
    setToDAL(ps, updates, PROPERTY_TYPE.TEXT_THEME)

    ps.setOperationsQueue.executeAfterCurrentOperationDone(() => {
        ps.extensionAPI.theme.onChange.onThemeChangeRunCallbacks({
            type: PROPERTY_TYPE.TEXT_THEME,
            values: updates
        })
    })
}

function forkStyleInternal(ps: PS, styleValue, pageId: string, isFull: boolean, styleId?: string) {
    const styleDefToAdd = _.omit(styleValue, ['id'])
    styleDefToAdd.styleType = 'custom'
    return createAndAddStyleItemInternal(ps, styleDefToAdd, styleId, pageId, isFull)
}

const forkStyle = (ps: PS, styleValue, pageId: string) => forkStyleInternal(ps, styleValue, pageId, false)

function updateStyleType(ps: PS, styleType: string, styleValueToSet, supportComponentStyle) {
    const setComponentStyle =
        supportComponentStyle && ps.runtimeConfig.stylesPerPage && styleType === constants.STYLES.TYPES.CUSTOM
    styleValueToSet.type = setComponentStyle ? constants.STYLES.COMPONENT_STYLE : constants.STYLES.TOP_LEVEL_STYLE
}

function addListener(ps: PS, callback) {
    return ps.extensionAPI.theme.onChange.onThemeChangeAddListener(callback)
}

function removeListener(ps: PS, listenerId: string) {
    return ps.extensionAPI.theme.onChange.removeChangeThemeListener(listenerId)
}

function executeListeners(ps: PS, changedData) {
    return ps.extensionAPI.theme.onChange.onThemeChangeRunCallbacks(changedData)
}

function internalUpdateStyle(ps: PS, styleId: string, styleValue, pageId: string, supportComponentStyle) {
    runStyleValidations(ps, styleId, styleValue)
    const newStyleValue = _.omit(styleValue, ['styleType', 'type'])
    const stylePointer = ps.pointers.data.getThemeItem(styleId, pageId)
    const currentStyleValue = ps.dal.get(stylePointer)
    const styleValueToSet = _.assign(currentStyleValue || {}, newStyleValue)
    const styleType = _.get(currentStyleValue, 'styleType') || _.get(styleValue, 'styleType')

    updateStyleType(ps, styleType, styleValueToSet, supportComponentStyle)

    styleValueToSet.styleType = styleType
    styleValueToSet.id = styleId
    ps.dal.set(stylePointer, styleValueToSet)

    ps.setOperationsQueue.executeAfterCurrentOperationDone(() => {
        ps.extensionAPI.theme.onChange.onThemeChangeRunCallbacks({
            type: 'STYLE',
            values: styleId
        })
    })
}

function updateStyle(ps: PS, styleId, styleValue, pageId = 'masterPage') {
    internalUpdateStyle(ps, styleId, styleValue, pageId, true)
}

function updateStyleWithoutChangingType(ps: PS, styleId, styleValue, pageId = 'masterPage') {
    internalUpdateStyle(ps, styleId, styleValue, pageId, false)
}

// @ts-expect-error
const warnUpdateStyleOnce = _.once((...args) => santaCoreUtils.log.warnDeprecation(...args))

function publicUpdateStyle(ps: PS, styleId, styleValue, pageId = 'masterPage') {
    runStyleValidations(ps, styleId, styleValue)
    const existingStyleItem = getStyle(ps, styleId, pageId)
    if (_.get(existingStyleItem, 'styleType') === 'custom') {
        warnUpdateStyleOnce(
            `You tried to update a custom style with id ${styleId}. \nPlease use components.style.update(compPointer, style) instead. theme.style.update should only be used for theme styles`
        )
    }
    if (dataModel.refArray.isRefArray(ps, existingStyleItem)) {
        warnUpdateStyleOnce(
            `You a style with variant overrides ${styleId}. \nPlease use components.style.update(compPointer, style) instead. theme.style.update should only be used for theme styles`
        )
    }
    const stylePointer = ps.pointers.data.getThemeItem(styleId, pageId)
    if (!ps.dal.isExist(stylePointer)) {
        warnUpdateStyleOnce(
            `You are trying to update style with id ${styleId} and page ${pageId}, but the style does not exist. Previously, a style would be created with this id - but this is no longer supported and will be deprecated soon.`
        )
    } else {
        const currentStylePageId = ps.pointers.data.getPageIdOfData(stylePointer)
        if (currentStylePageId !== pageId) {
            santaCoreUtils.log.warn(
                `You tried to update a style with id ${styleId} and page ${pageId}, but the style belongs to page ${currentStylePageId}. Please update API call to use the correct page`
            )
        }
    }
    return updateStyle(ps, styleId, styleValue, pageId)
}

function getThemeStyles(ps: PS) {
    const colors = themeColors.getAll(ps)
    const fonts = themeFonts.getAll(ps)
    return coreUtils.fontUtils.getThemeFontsCss(fonts, colors)
}

function isKnownSystemStyle(styleId: string) {
    return isSystemStyle(styleId)
}

const ALLOWED_STYLE_TYPES = ['system', 'custom']

function createAndAddStyleItemInternal(
    ps: PS,
    styleRawData,
    styleId,
    pageId = constants.MASTER_PAGE_ID,
    isFull = false
) {
    const styleItem = createStyleItemToAdd(ps, styleRawData, styleId)
    addStyleItemInternal(ps, styleItem, styleItem, pageId, isFull)
    return styleItem.id
}

function generateSystemStyle(compType, skin, styleProperties) {
    return {
        compId: '',
        componentClassName: compType,
        pageId: '',
        styleType: 'system',
        type: 'TopLevelStyle',
        skin,
        style: {
            groups: {},
            properties: styleProperties,
            propertiesSource: {}
        }
    }
}

const THEME_STYLE_CREATORS = {
    skin: (ps: PS, compType, definition, styleId) => {
        const skinName = definition.styles[styleId]
        return generateSystemStyle(compType, skinName, _.mapValues(getSkinDefinition(ps, skinName), 'defaultValue'))
    },
    stylable: (ps: PS, compType, definition, styleId) => {
        const {styles} = definition
        const compName = stylableUtils.getComponentStylableName(compType)
        const stylablePresets = experiment.isOpen('dm_deprecateWixUiSanta')
            ? editorElementsThemePresets[compName]
            : // eslint-disable-next-line  @typescript-eslint/prefer-optional-chain
              (wixUiSantaMetaData.components[compName] || {}).themePresets
        const defaultPreset = stylableUtils.createEmptyStylableStylesheet(compType)
        const preset = stylablePresets ? stylablePresets[styleId] || defaultPreset : defaultPreset
        if (!styles[styleId] || !preset) {
            throw new Error(`there is no preset for comp ${compType} and styleId ${styleId}`)
        }
        return generateSystemStyle(compType, stylableUtils.getStylableSkinName(), {'$st-css': preset})
    }
}

const createOrAddDefaultStyleItem = (ps: PS, compType, styleId, createOrAddFunction) => {
    verifySystemStyleHasDefaultSkin(styleId, compType)
    const definition = schemasService.getDefinition(compType)
    const themeType = definition.isStylableComp ? 'stylable' : 'skin'
    return createOrAddFunction(ps, THEME_STYLE_CREATORS[themeType](ps, compType, definition, styleId), styleId)
}
const createDefaultThemeStyle = (ps: PS, compType, styleId) =>
    createOrAddDefaultStyleItem(ps, compType, styleId, createAndAddStyleItem)
const getDefaultThemeStyle = (ps: PS, compType, styleId) =>
    createOrAddDefaultStyleItem(ps, compType, styleId, createStyleItemToAdd)

const createAndAddStyleItem = (ps: PS, styleRawData, styleId, pageId = constants.MASTER_PAGE_ID) =>
    createAndAddStyleItemInternal(ps, styleRawData, styleId, pageId, false)

function addStyleItemInternal(
    ps: PS,
    styleItemInjectedFromCreate,
    originalStyleItem,
    pageId = constants.MASTER_PAGE_ID,
    isFull = false
) {
    if (pageId !== constants.MASTER_PAGE_ID && _.get(styleItemInjectedFromCreate, 'styleType') === 'system') {
        pageId = constants.MASTER_PAGE_ID
        santaCoreUtils.log.warn(
            `You tried to add a system style with page ${pageId}. \nPlease update API call to use the master page`
        )
    } else {
        pageId = ps.runtimeConfig.stylesPerPage ? pageId : constants.MASTER_PAGE_ID
    }

    runStyleValidations(ps, styleItemInjectedFromCreate.id, styleItemInjectedFromCreate)

    const dal = isFull ? ps.dal.full : ps.dal
    const stylePointer = ps.pointers.data.getThemeItem(styleItemInjectedFromCreate.id, pageId)
    dal.set(stylePointer, styleItemInjectedFromCreate)
}

const addStyleItem = (ps, styleItemInjectedFromCreate, originalStyleItem, pageId = constants.MASTER_PAGE_ID) =>
    addStyleItemInternal(ps, styleItemInjectedFromCreate, originalStyleItem, pageId, false)

function createStyleItemToAdd(ps: PS, styleRawData, styleId?: string) {
    if (!_.includes(ALLOWED_STYLE_TYPES, styleRawData.styleType)) {
        throw new Error(
            `Unable to create a style without a styleType. styleType must be one of ${JSON.stringify(
                ALLOWED_STYLE_TYPES
            )}`
        )
    }
    if (styleRawData.styleType === 'system' && !isKnownSystemStyle(styleId)) {
        throw new Error(`Unable to create a system style whose id - ${styleId}, is not in componentDefinitionMap`)
    }
    return buildStyleByData(ps, styleRawData, styleId)
}

function runStyleValidations(ps, styleId, styleValue) {
    if (!styleId || !styleValue) {
        throw new Error(`missing arguments - styleId: ${styleId}, styleValue: ${styleValue}`)
    }
    const validationResult = validateStyleValue(ps, styleValue)
    if (!validationResult.success) {
        throw new Error(validationResult.error)
    }
}

function verifySystemStyleHasDefaultSkin(styleId, componentType) {
    const componentDefaults = schemasService.getDefinition(componentType)
    if (!componentDefaults) {
        throw new Error(`Component of type - ${componentType} is not listed in componentsDefinitionsMap`)
    }
    const defaultSkin = componentDefaults.styles[styleId]
    if (!defaultSkin) {
        throw new Error(`Style id - ${styleId} is not a known system style id.`)
    }
}

function buildStyleByData(ps: PS, styleData, styleId) {
    const type =
        ps.runtimeConfig.stylesPerPage && styleData.styleType === constants.STYLES.TYPES.CUSTOM
            ? constants.STYLES.COMPONENT_STYLE
            : constants.STYLES.TOP_LEVEL_STYLE

    const styleItem = dataModel.createStyleItemByType(type)
    _.merge(styleItem, styleData)
    styleItem.compId = ''
    styleItem.pageId = ''
    styleItem.id = styleId || idGenerator.getStyleIdToAdd()
    styleItem.metaData = {isPreset: false, schemaVersion: '1.0', isHidden: false} //TODO: Shahar - extremely ugly! Remove!
    styleItem.style = _(styleItem.style || {})
        .pickBy(_.identity)
        .defaults({properties: {}, propertiesSource: {}})
        .value()
    styleItem.type = type
    return styleItem
}

function getAllFonts(ps: PS) {
    return getPropertiesAccordingToType(ps, PROPERTY_TYPE.FONT)
}

function getThemeFontsMap(ps: PS) {
    const colors = getAllColors(ps)
    const fonts = getAllFonts(ps)

    return _.transform(
        fonts,
        function (res, font, fontIndex) {
            res[fontIndex] = coreUtils.fontUtils.parseStyleFont(fontIndex, fonts, colors)
            return res
        },
        {}
    )
}

function convertToHex(colors) {
    function valueToHex(colorString) {
        let colorObj

        if (colorString.indexOf('#') !== 0) {
            const rgbString = colorString.indexOf('r') === 0 ? colorString : `rgba(${colorString})`
            colorObj = new Color(rgbString)
        } else {
            colorObj = new Color(colorString)
        }

        return colorObj.hexString()
    }

    if (_.isArray(colors)) {
        return _.map(colors, valueToHex)
    } else if (_.isObject(colors)) {
        return _.mapValues(colors, valueToHex)
    }
    return valueToHex(colors)
}

function getAllColors(ps: PS) {
    if (experiment.isOpen('dm_moveGetColorsToExtensions')) {
        return ps.extensionAPI.theme.getColors()
    }
    const colors = getPropertiesAccordingToType(ps, PROPERTY_TYPE.COLOR)
    return convertToHex(colors)
}

function createColorHashMapFromStyleData(ps: PS, stylesInTheme) {
    return _.transform(
        stylesInTheme,
        function (colorHashMap, styleDataItem /*, styleId*/) {
            const skinDescription = getSkinDefinitionInternal(ps, styleDataItem.skin)
            if (styleDataItem.type !== constants.STYLES.FLAT_THEME && skinDescription) {
                const props = styleDataItem.style?.properties || {}
                _(props)
                    .pickBy(function (propValue, propName) {
                        return skinDescription[propName] && _.includes(skinDescription[propName].type, 'COLOR')
                    })
                    .forOwn(function (propVal /*, propName*/) {
                        colorHashMap[propVal] = propVal
                    })
            }
        },
        {}
    )
}

function getColorsFromBackgroundMedia(data) {
    return _(data).pick(['color', 'colorOverlay']).values().value()
}

function getBackgroundColors(allDesignData) {
    return _(allDesignData).flatMap(getColorsFromBackgroundMedia).uniq().compact().keyBy().value()
}

const getColorsFromString = (colorsAccu, string) => {
    const colorsFromString = string.match(COLORS_REGEX)
    return colorsFromString ? colorsAccu.concat(colorsFromString) : colorsAccu
}

function getCustomColorsUsedInTexts(ps: PS) {
    const allThemeFonts = Object.values(getAllFonts(ps))
    const allPagesIds = ps.siteAPI.getAllPagesIds(true)
    const textCompsTexts = allPagesIds
        .flatMap(pageId => ps.pointers.data.getDataItemsWithPredicate(item => item.type === 'StyledText', pageId))
        .map(itemPointer => ps.dal.get(itemPointer).text)

    return _.flatMap([allThemeFonts, textCompsTexts], source => source.reduce(getColorsFromString, []))
}

function getCustomColorsUsedInSkins(ps: PS, removeDuplicateToThemeColors) {
    const stylesInTheme = getAllStylesFromAllPages(ps)
    const allDesign = getAllDesignDataWithPredicate(ps, item => item.type === 'BackgroundMedia')
    const designColorHashMap = getBackgroundColors(allDesign)
    const styleColorsHashMap = createColorHashMapFromStyleData(ps, stylesInTheme)
    const colorsHashMap = _.merge(designColorHashMap, styleColorsHashMap)
    let colors = _.filter(colorsHashMap, colorValue => !_.includes(colorValue, 'color'))

    if (experiment.isOpen('dm_customColorsFromText')) {
        colors = colors.concat(getCustomColorsUsedInTexts(ps))
    }
    colors = [...new Set(convertToHex(colors))]

    if (removeDuplicateToThemeColors) {
        const allThemeColors = getAllColors(ps)
        colors = _.difference(colors, allThemeColors)
    }

    return colors
}

function getFont(ps: PS, fontName) {
    return getProperty(ps, PROPERTY_TYPE.FONT, fontName)
}

function getTextTheme(ps: PS, fontName) {
    return getProperty(ps, PROPERTY_TYPE.TEXT_THEME, fontName)
}

function renderColor(ps: PS, color) {
    if (_.includes(color, 'color')) {
        return getColor(ps, color)
    }
    if (color.charAt(0) === '#' || _.includes(color, 'rgb')) {
        return color
    }
    const splitColor = color.split(',')
    if (splitColor.length === 3) {
        return `rgb(${color})`
    }
    if (splitColor.length === 4) {
        return `rgba(${color})`
    }
    return color
}

function getColor(ps: PS, colorName) {
    const color = getProperty(ps, PROPERTY_TYPE.COLOR, colorName)
    return color && convertToHex(color)
}

// @ts-expect-error
const warnGetStyleOnce = _.once((...args) => santaCoreUtils.log.warnDeprecation(...args))

function publicGetStyle(ps: PS, styleId, pageId = constants.MASTER_PAGE_ID) {
    const styleItem = getStyle(ps, styleId, pageId)
    if (_.get(styleItem, 'styleType') === 'custom') {
        warnGetStyleOnce(
            `You tried to get a custom style with id ${styleId} and page ${pageId}. \nPlease use components.style.get(compPointer) instead. theme.style.get should only be used for theme styles`
        )
    }
    return styleItem
}

function getStyleInternal(ps: PS, styleId, pageId = constants.MASTER_PAGE_ID, isFull?) {
    const dal = isFull ? ps.dal.full : ps.dal
    const stylePointer = ps.pointers.data.getThemeItem(styleId, pageId)
    return dal.get(stylePointer)
}

const getStyle = (ps: PS, styleId, pageId = constants.MASTER_PAGE_ID) => getStyleInternal(ps, styleId, pageId, false)

const getAllStylesFromAllPages = (ps: PS) => deepClone(ps.extensionAPI.data.query(DATA_TYPES.theme))
const getAllStylesFromPage = (ps: PS, pageId) => deepClone(ps.extensionAPI.data.query(DATA_TYPES.theme, pageId))

// @ts-expect-error
const warnGetAllOnce = _.once((...args) => santaCoreUtils.log.warnDeprecation(...args))
const getAllStyles = (ps: PS, pageId?: string) => {
    if (!pageId) {
        warnGetAllOnce(
            'You tried to get all styles without providing pageId, which causes styles from all pages to be returned. Please use theme.styles.getAllFromAllPages instead'
        )
        return getAllStylesFromAllPages(ps)
    }
    return getAllStylesFromPage(ps, pageId)
}

function getAllDesignDataWithPredicate(ps: PS, predicate) {
    const pageIds = ps.siteAPI.getAllPagesIds(true)
    return _.reduce(
        pageIds,
        function (acc, pageId) {
            const pageDesignPointers = ps.pointers.data.getDesignItemsWithPredicate(predicate, pageId)
            _.forEach(pageDesignPointers, function (backgroundPointer) {
                acc.push(ps.dal.get(backgroundPointer))
            })
            return acc
        },
        []
    )
}

const getAllStyleIdsFromAllPages = (ps: PS) => ps.extensionAPI.data.queryKeys(DATA_TYPES.theme)
const getAllStyleIdsFromPage = (ps: PS, pageId) => ps.extensionAPI.data.queryKeys(DATA_TYPES.theme, pageId)

// @ts-expect-error
const warnGetAllIdsOnce = _.once((...args) => santaCoreUtils.log.warnDeprecation(...args))
const getAllStyleIds = (ps: PS, pageId?: string) => {
    if (!pageId) {
        warnGetAllIdsOnce(
            'You tried to get all styles Ids without providing pageId, which causes styles Ids from all pages to be returned. Please use theme.styles.getAllIdsFromAllPages instead'
        )
        return getAllStyleIdsFromAllPages(ps)
    }
    return getAllStyleIdsFromPage(ps, pageId)
}

// it seems that no one is using this func
function removeStyle(ps: PS, styleId, pageId) {
    ps.dal.remove(ps.pointers.data.getThemeItem(styleId, pageId))
}

/******Internal Functions *********/

function validateStyleValue(ps, styleValue) {
    if (typeof styleValue !== 'object') {
        return {success: false, error: 'received style value is not an object'}
    }
    if (!styleValue.skin) {
        return {success: false, error: 'received style did not contain a skin property'}
    }
    if (!styleValue.type) {
        return {success: false, error: 'received style did not contain a type property'}
    }

    const definition = schemasService.getDefinition(styleValue.componentClassName)
    if (definition?.isStylableComp && !_.get(styleValue, ['style', 'properties', '$st-css'])) {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                errorType: 'missingStylableProperties',
                message: 'Stylable values must contain an $st-css property',
                extras: {
                    value: styleValue
                }
            })
        )
        return {success: false, error: 'Stylable style did not contain an $st-css property'}
    }

    if (styleValue.style?.propertiesOverride && styleValue.style.properties) {
        const propertiesOverrideKeys = Object.keys(styleValue.style.propertiesOverride)
        for (const propOverrideKey of propertiesOverrideKeys) {
            if (!styleValue.style.properties.hasOwnProperty(propOverrideKey)) {
                return {success: false, error: 'received style propertiesOverride contains invalid property'}
            }
        }
    }

    return {success: true}
}

function getPropertiesAccordingToType(ps: PS, type: string): Record<string, any> {
    const result = {}
    const getter = themePropertiesGettersMap[type]
    const values = getter ? getter(ps) : []

    _.forEach(values, (value, index) => {
        result[`${type}_${index}`] = value
    })
    return result
}

/**
 * @param ps
 * @param {Object} valuesMap
 * @param {string} type
 */
function setToDAL(ps: PS, valuesMap, type: string) {
    const set = themePropertiesItemSetterMap[type]

    if (!set) {
        throw new Error(`Setter for property ${type} is not defined`)
    }

    _.forEach(valuesMap, (value, name) => {
        const index = getPropIndex(name)
        set(ps, index, value)
    })
}

function validateGetterAndKey(ps: PS, valuesToMerge, type: string) {
    const getAll = themePropertiesGettersMap[type]
    if (!getAll) {
        throw new Error(`Getter for property ${type} is not defined`)
    }
    if (typeof valuesToMerge !== 'object') {
        throw new Error(`Value "${valuesToMerge}" is not valid.Param should be an object`)
    }
    const allValues = getAll(ps)
    _.forEach(valuesToMerge, function (val, key) {
        const index = getPropIndex(key)
        if (index === undefined || !(allValues[index] || allValues[key])) {
            throw new Error(`Invalid Key ${key}`)
        }
    })
}

function validateFont(ps: PS, fontStr: string) {
    const textTheme = santaCoreUtils.fonts.fontStringToTextTheme(fontStr)
    validateTextTheme(ps, textTheme)
}

function validateTextTheme(ps: PS, textTheme): void {
    schemasService.validate('TextTheme', textTheme, 'style')
}

function getProperty(ps: PS, type: string, name: string) {
    const index = getPropIndex(name)
    if (!_.isNaN(index)) {
        const getter = themePropertiesItemGettersMap[type]
        return getter ? getter(ps, index) : null
    }
    const resultTemplate = _.template('Non valid <%=type %> value <%=name %>')
    return resultTemplate({
        type,
        name
    })
}

function getPropIndex(name: string) {
    const index = name.split('font_')[1] || name.split('color_')[1]
    return parseInt(index, 10)
}

function getSchema() {
    return _.cloneDeep(schemasService.getSchema(DATA_TYPES.data, 'FlatTheme').properties)
}

function getComponentSkins(ps: PS, componentType: string) {
    return documentServicesSchemas.services.getComponentSkins(componentType)
}

function getComponentResponsiveSkins(ps: PS, componentType: string) {
    return documentServicesSchemas.services.getComponentResponsiveSkins(componentType)
}

const SVG_SKIN_DEFINITION = 'skins.viewer.svgshape.SvgShapeDefaultSkin'

function getSkinDefinitionInternal(ps: PS, skinClassName: string) {
    const componentsEditorParams = getComponentsEditorParams()
    return skinClassName?.includes('svgshape.')
        ? componentsEditorParams[SVG_SKIN_DEFINITION]
        : componentsEditorParams[skinClassName]
}

function getSkinDefinition(ps: PS, skinClassName: string) {
    return _.clone(getSkinDefinitionInternal(ps, skinClassName))
}

function getColorPresets() {
    return colorPresets
}

function getColorCssString(ps: PS) {
    const colors = themeColors.getAll(ps)
    return santaCoreUtils.cssUtils.getColorsCssString(colors)
}

function getCharacterSet(ps: PS) {
    const dataItemPointer = ps.pointers.data.getDataItemFromMaster('masterPage')
    let result = ps.dal.get(dataItemPointer).characterSets
    if (!result) {
        result = ['latin']
    } else if (!_.includes(result, 'latin')) {
        result.push('latin')
    }

    return result
}

const wixLanguageCharacterSet = {
    pl: ['latin-ext', 'latin'],
    ru: ['cyrillic', 'latin'],
    ja: ['japanese', 'latin'],
    ko: ['korean', 'latin']
}

function getLanguageCharacterSet(ps: PS, languageSymbol: string) {
    if (languageSymbol) {
        return wixLanguageCharacterSet[languageSymbol]
    }
}

function updateCharacterSet(ps: PS, characterSetArr) {
    if (_.isArray(characterSetArr)) {
        const dataItemPointer = ps.pointers.data.getDataItemFromMaster('masterPage')
        const value = ps.dal.get(dataItemPointer) ?? {}
        ps.dal.set(dataItemPointer, {
            ...value,
            characterSets: characterSetArr
        })
    }
}

function getCharacterSetByGeo(ps: PS) {
    let sets = []
    const geo = generalInfo.getGeo(ps)
    if (geo && coreUtils.countryCodes.countries[geo]) {
        sets = _.clone(coreUtils.countryCodes.countries[geo].characterSets)
    }

    if (sets.length > 0 && !_.includes(sets, 'latin')) {
        sets.push('latin')
    } else {
        sets = ['latin']
    }

    return sets
}

const updateColors = (ps: PS, colors) => ps.extensionAPI.theme.updateColors(colors)
/**
 * @class documentServices.theme
 */
export default {
    /**
     * @class documentServices.theme.colors
     */
    colors: {
        /**
         * The function receive an object with the colors we want to update and update theme data schema with the new colors (the object key is the color name and the value is the color value we want to set).
         * Color name can be from color_0 to color_35 and the color value can be hex string or rgba.
         * @example hex : color_1: "#FFFFFF") , rgba : (color_3: "237,28,5,1")
         * @param {Object.<string, string>} colors  object with the colors we want to update (key - color name (between color_0 to color_35), value -  color value we want to set in hex/rgba value
         *
         */
        update: updateColors,
        /**
         * The function returns the color value for a given color name.
         * @param {string} colorName - color name should be between color_0 to color_35
         * @return {string} color value for the given color name
         * @example
         * //return #FFFFFF
         */
        get: getColor,
        /**
         * The function returns an object with all the colors on the theme data schema.
         * @return {Object.<string,string>} all preset colors (key- color name , value - color value)
         * @example
         * //return {"color_0":"#ffffff","color_1":"#FFFFFF","color_2":"#000000","color_3":"237,28,36,1"}
         */
        getAll: getAllColors,
        getCustomUsedInSkins: getCustomColorsUsedInSkins,
        getColorPresets,
        /**
         * The function returns a css string represents the theme color_* and backcolor_* classes
         * @return css string with theme colors classes
         * @example;
         * //return ".color_0 {color: #123456;} .backcolor_0 {background-color: #123456;}"
         */
        getCssString: getColorCssString,
        render: renderColor
    },
    /**
     * @class documentServices.theme.fonts
     */
    fonts: {
        /**
         * The function receives an object with the fonts we want to update and updates the theme data schema with the new fonts (the object key is the font name and the value is the new font css string).
         * Font key can be from font_0 to font_10 and the font string should be in the following format : font-style font-variant font-weight font-size/line-height font-family color
         * @example normal normal normal 40px/1.4em din-next-w01-light {color_14};
         * font-style - Specifies the font style for text. possible values : 'normal', 'italic'.
         * font-variant -Specifies whether or not a text should be displayed in a small-caps font. possible values : 'normal', 'small-caps', 'inherit'.
         * font- weight - Specifies how thick or thin characters in text should be displayed. possible values:'normal', 'bold', 'bolder', 'lighter'.
         * font-size - Specifies the size of a font. should be int value and the possible units are: 'px', 'em', 'pt', 'ex', 'in', 'cm', 'mm', 'pc'.
         * line-height - Specifies the line height. should be int value and the possible units are: 'px', 'em', 'pt', 'ex', 'in', 'cm', 'mm', 'pc'.
         * font-family - Specifies the font. The font-family property can hold several font names as a "fallback" system. If the browser does not support the first font, it tries the next font.
         * color - Specifies the text color. possible values : can have one of the preset colors (color0-color35) : {color_0} or Hex/Rgba string
         * @param {Object.<string, string>} fonts object with the fonts we want to update (key - font name (between font_0 to font_10), value - new fonts string in the following format :font-style font-variant font-weight font-size/line-height font-family color
         *
         */
        update: updateFonts,
        /**
         * The function returns the font string for a given font name.
         * @param {string} fontName should be between font_0 to font_10
         * @return {string} font value for the given font name
         * @example
         * //return normal normal normal 45px/1.4em Open+Sans {color_14}
         */
        get: getFont,
        /**
         * The function returns an object with all the fonts on the theme data schema.
         * @return {Object.<string,string>} all preset fonts (key- fontName , value - font string)
         * @example
         * // returns {"font_0":"normal normal normal 45px/1.4em Open+Sans {color_14}","font_1":"normal normal normal 13px/1.4em Arial {color_11}"}
         */
        getAll: getAllFonts,
        /**
         * The function returns an object with all the fonts on the theme data schema as object.
         * @return {Object.<string,*>} all preset fonts (key- fontName , value - theme font as object)
         * @example
         * // returns 'font_1': { style: 'normal', variant: 'normal', weight: 'normal', size: '13px', lineHeight: '1.4em', family: 'Arial', color: '{color_1}', bold: false, italic: false, fontWithFallbacks: 'arial,arial_fallback,sans-serif', cssColor: '#A0CF8E'}
         */
        getMap: getThemeFontsMap,
        /**
         * The function returns an Array with all the character sets of the current site from the site structure
         * @return Array
         * @example
         * // returns ['latin']
         */
        getCharacterSet,
        /**
         * The function returns an Array with all the character sets of a specific language symbol (As configured in My Account)
         * @param languageSymbol - the language symbol to return its languages
         * @return Array, there should be always array with at least one character set -> ['latin']
         * @example
         * // for languageSymbol === 'pl' returns ['latin-ext', 'latin']
         */
        getLanguageCharacterSet,
        /**
         * The function returns an Array with all the character sets of the IP/GEO
         * @return Array, there should be always array with at least one character set -> ['latin']
         * @example
         * // for geo ISR should returns ['hebrew', 'arabic', 'latin']
         */
        getCharacterSetByGeo,
        /**
         * The function update the site structure character sets of the current site, with new array
         * @param Array, of character set
         */
        updateCharacterSet,
        /**
         * @param {ps} ps
         * @returns {*} font_0: font: 'normal normal normal 45px/1.4em Open+Sans; color: #FFFFFF;
         */
        getThemeStyles
    },
    textThemes: {
        /**
         * The function receives an object with the fonts we want to update and updates the theme data schema with
         * the new fonts (the object key is the font name and the value is the new text theme object).
         * Font key can be from font_0 to font_10 and the text theme should be in the following format:
         * {fontStyle, fontWeight, fontVariant, fontFamily, fontSize, lineHeight, color, letterSpacing},
         * @example {fontStyle: 'normal', fontWeight: 'normal', fontVariant: 'normal', fontFamily: 'proxima-n-w01-reg', fontSize: '40px', lineHeight: '1.4em', color: '{color_10}', letterSpacing: '0em'}
         * The allowed values are in the style schema under TextTheme
         * document-services-json-schemas/src/namespaces/style/schemas.json
         */
        update: updateTextThemes,
        /**
         * The function returns the text theme object for a given font name.
         * @param {string} fontName should be between font_0 to font_10
         * @return {object} text theme object for the given font name
         * @example
         * // returns
         * {fontStyle: 'normal', fontWeight: 'normal', fontVariant: 'normal', fontFamily: 'din-next-w01-light', fontSize: '16px', lineHeight: '1.4em', color: '{color_7}', letterSpacing: '0em'}
         */
        get: getTextTheme,
        /**
         * The function returns an object with all the fonts text theme objects on the theme data schema.
         * @return {Object.<string,object>} all preset fonts (key- fontName , value - TextTheme object)
         * @example
         * // returns
         * {
         *  font_0: {color: '{color_10}', fontFamily: 'proxima-n-w01-reg', fontSize: '40px', fontStyle: 'normal', fontVariant: 'normal', fontWeight: 'normal', letterSpacing: '0em', lineHeight: '1.4em'},
         *  font_1: {color: '{color_7}', fontFamily: 'din-next-w01-light', fontSize: '16px', fontStyle: 'normal', fontVariant: 'normal', fontWeight: 'normal', letterSpacing: '0em', lineHeight: '1.4em'}
         * }
         */
        getAll: textThemes.getAll,
        /**
         * The function returns a string with all the fonts text theme css props.
         * @return {string} all preset fonts (key- fontName , value - TextTheme object)
         * @example
         * // returns
         * `.font_0 {font: normal normal normal 40px/1.4em proxima-n-w01-reg,sans-serif; color: #DEAF21; letter-spacing: 0em;}
         * .font_1 {font: normal normal normal 16px/1.4em din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif; color: #F4C0AF; letter-spacing: 0em;}`
         */
        getStyles: textThemes.getStyles
    },
    /**
     * @class documentServices.theme.styles
     */
    styles: {
        /**
         * The function receive styleId which is the system style name and styleValue which is the style object representing the style and the pageId.
         * style id must exist and the style object must be an object containing at least skin name and type
         * @example  {compId:"",componentClassName:"",id:"mockId",skin:"wysiwyg.viewer.skins.button.BasicButton",type: "TopLevelStyle",style:{groups:{},properties:{},propertiesSource:{}},styleType: "system"
         * @param {string} styleId  the styleId we want to update
         * @param {object} styleValue style objects we want to update
         * @param {string} pageId
         *
         */
        updateAndWarn: publicUpdateStyle,

        /**
         * The function receive styleId which is the style name and styleValue which is the style object representing the style.
         * style id must exist and the style object must be an object containing at least skin name and type
         * This function works both on system and custom styles and will be deprecated
         * * @example  {compId:"",componentClassName:"",id:"mockId",skin:"wysiwyg.viewer.skins.button.BasicButton",type: "TopLevelStyle",style:{groups:{},properties:{},propertiesSource:{}},styleType: "system"
         * @param {string} styleId  the styleId we want to update
         * @param {object} styleValue style objects we want to update
         * @param {string} pageId
         *
         */
        update: updateStyle,

        /**
         * The function receive styleId which is the style name and styleValue which is the style object representing the style.
         * style id must exist and the style object must be an object containing at least skin name and type
         * This function is dedicated for wixapps, which shouldn't modify their style type to ComponentStyle and leave it with TopLevelStyle
         * * @example  {compId:"",componentClassName:"",id:"mockId",skin:"wysiwyg.viewer.skins.button.BasicButton",type: "TopLevelStyle",style:{groups:{},properties:{},propertiesSource:{}},styleType: "system"
         * @param {string} styleId  the styleId we want to update
         * @param {object} styleValue style objects we want to update
         * @param {string} pageId
         *
         */
        updateWithoutChangingType: updateStyleWithoutChangingType,

        /**
         * Deprecated as public method. Should only be used by inner DS moduels. Create a new style object with the given properties and add it to the master page
         * @param {object} styleRawData - style object with properties from which the new style will be created
         * @param {string} [styleId] - style id to create the new style with. Should be used when creating <b>system</b> styles only.
         * @param {string} pageId
         * @returns {string} styleId - the created style id
         */
        createAndAddStyleItem,

        /**
         * Add the styleItem to the master page
         * @param {string} pageId
         * @param {object} styleItem - valid style item to add to the document.
         */
        addStyleItem,

        /**
         * Create a new style object with the given properties
         * @param {object} styleRawData - style object with properties from which the new style will be created
         * @param {string} [styleId] - style id to create the new style with. Should be used when creating <b>system</b> styles only.
         * @returns {object} styleItem - the created style item
         */
        createStyleItemToAdd,

        /**
         * The function returns the system style object for a given style name.
         * @param {string} styleId - styleId we want to get
         * @param {string} pageId
         * @return {object} style object for the given style name
         * @example
         * //return {"componentClassName":"","pageId":"","compId":"","styleType":"system","metaData":{"isPreset":false,"isHidden":false,"schemaVersion":"1.0"},"type":"TopLevelStyle","id":"twt1","skin":"skins.core.TwitterTweetSkin"}
         */
        getAndWarn: publicGetStyle,

        /**
         * The function returns the style object for a given style name.
         * This function works both on system and custom styles and will be deprecated
         * @param {string} styleId - styleId we want to get
         * @param {string} pageId
         * @return {object} style object for the given style name
         * @example
         * //return {"componentClassName":"","pageId":"","compId":"","styleType":"system","metaData":{"isPreset":false,"isHidden":false,"schemaVersion":"1.0"},"type":"TopLevelStyle","id":"twt1","skin":"skins.core.TwitterTweetSkin"}
         */
        get: getStyle,

        /**
         * The function returns all styles from all pages or from all/specific page
         * @param {string} pageId - pageId to find styles from. If page isn't provided, styles from all pages will be returned by calling getAllFromAllPages
         * @return {Object.<string, object>} object containing all styles (key- style name , value - style object)
         */
        getAll: getAllStyles,

        /**
         * The function returns all styles from all pages
         * @return {Object.<string, object>} object containing all styles from all pages (key- style name , value - style object)
         */
        getAllFromAllPages: getAllStylesFromAllPages,

        /**
         * The function returns all styles Ids (system and custom) from all/specific page
         * @param {string} pageId - pageId to find styles from. If page isn't provided, styles from all pages will be returned by calling getAllIdsFromAllPages
         */
        getAllIds: getAllStyleIds,

        /**
         * The function returns all styles Ids (system and custom) from all pages
         */
        getAllIdsFromAllPages: getAllStyleIdsFromAllPages,

        /**
         * The function clones a style (creates and add) to a new custom style
         * @param {object} style definition
         * @param {string} page Id
         * @return {string} The new style Id
         */
        fork: forkStyle,

        /**
         * The function removes a style from the dal
         * @param {string} styleId
         * @param {string} page Id
         */
        remove: removeStyle,
        /**
         * Creates a default theme style and adds it
         * @param compType - component type
         * @param styleId - styleId for theme style
         * @throws an exception if no corresponding component-type exists or invalid style value.
         */
        createDefaultThemeStyle,
        /**
         * Creates a default theme style without adding it
         * @param compType - component type
         * @param styleId - styleId for theme style
         * @throws an exception if no corresponding component-type exists or invalid style value.
         */
        getDefaultThemeStyle,

        internal: {
            /**
             * Add the styleItem to the master page
             * @param {boolean} True to use full dal
             * @param {string} pageId
             * @param {object} styleItem - valid style item to add to the document.
             * @param {boolean} True to use full dal
             */
            addStyleItem: addStyleItemInternal,

            /**
             * Deprecated as public method. Should only be used by inner DS moduels. Create a new style object with the given properties and add it to the master page
             * @param {object} styleRawData - style object with properties from which the new style will be created
             * @param {string} [styleId] - style id to create the new style with. Should be used when creating <b>system</b> styles only.
             * @param {string} pageId
             * @param {boolean} True to use full dal
             * @returns {string} styleId - the created style id
             */
            createAndAddStyleItem: createAndAddStyleItemInternal,

            /**
             * The function returns the style object for a given style name.
             * This function works both on system and custom styles and will be deprecated
             * @param {string} styleId - styleId we want to get
             * @param {string} pageId
             * @param {boolean} True to use full dal
             * @return {object} style object for the given style name
             * @example
             * //return {"componentClassName":"","pageId":"","compId":"","styleType":"system","metaData":{"isPreset":false,"isHidden":false,"schemaVersion":"1.0"},"type":"TopLevelStyle","id":"twt1","skin":"skins.core.TwitterTweetSkin"}
             */
            get: getStyleInternal,

            /**
             * The function clones a style (creates and add) to a new custom style
             * @param {object} style definition
             * @param {string} page Id
             * @param {boolean} True to use full dal
             * @param {string} styleId to override generated new styleId
             * @return {string} The new style Id
             */
            fork: forkStyleInternal
        }
    },

    skins: {
        getComponentSkins,
        getComponentResponsiveSkins,
        getSkinDefinition
    },
    /**
     * The function returns the schema of the site's theme
     * @return {Object.<string, object>} object containing all schema properties (key- schema property, value - type and default value of schema property)
     */
    getSchema,
    /**
     * @class documentServices.theme.events
     */
    events: {
        onChange: {
            /**
             * The function add listener and the listener is triggered when the theme changes: font change, color change, style change
             * @param {requestCallback} callback  called when the theme is change
             * @return {int} listener id - used if you want to remove the listener
             * @example
             * //returns 2
             */
            addListener,
            /**
             * The function removes listener based on listener id.
             * @param {int} listenerId - the listener id given when the callback was registered
             */
            removeListener,
            executeListeners
        }
    },
    FONT_POSSIBLE_VALUES: themeValidationHelper.FONT_POSSIBLE_VALUES
}
