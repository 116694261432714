import type {Pointer, PS} from '@wix/document-services-types'
import constants from '../../constants/constants'
import metaDataUtils from '../metaDataUtils'
import popupUtils from '../../page/popupUtils'
import documentServicesSchemas from 'document-services-schemas'

const {schemasService} = documentServicesSchemas.services

const isContainable = (ps: PS, compPointer: Pointer, containerPointer: Pointer) => {
    const containerCompType = metaDataUtils.getComponentType(ps, containerPointer)
    const isContainerPage = schemasService.isPage(containerCompType)
    return isContainerPage && !popupUtils.isPopup(ps, containerPointer.id)
}

export default {
    alignable: false,

    container: true,
    containable: isContainable,
    containableByStructure: isContainable,

    canBeFixedPosition: false,

    showMarginsIndicator: true,

    fullWidth: true,
    fullWidthByStructure: true,

    isRepeatable: false,
    groupable: false,

    resizableSides: [constants.RESIZE_SIDES.BOTTOM],
    moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],

    layoutLimits: {
        minHeight: 40
    },

    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: true,

    mobileConversionConfig: {
        structuralItem: true,
        filterChildrenWhenHidden: true,
        stretchHorizontally: true,
        isTightContainer: false,
        minHeight: 200,
        category: 'sections',
        preserveAspectRatio: false,
        hideWhenEmptyBackgroundContainer: true,
        fixedSize(ps: PS, comp, pageId: string) {
            return metaDataUtils.getMobileFixedSizesForColumn(ps, comp, pageId)
        }
    },
    alwaysContainRecursively: true
}
