import {ReportableError} from '@wix/document-manager-utils'
import type {
    CompStructure,
    ControllerConnectionItem,
    IConnectionItem,
    Pointer,
    PossibleViewModes,
    PS,
    WixCodeConnectionItem
} from '@wix/document-services-types'
import _ from 'lodash'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import connections from '../connections/connections'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import documentModeInfo from '../documentMode/documentModeInfo'
import hooks from '../hooks/hooks'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import pageData from '../page/pageData'
import componentStructureInfo from './componentStructureInfo'
import customNicknameRegistrar from './customNicknameRegistrar'
import nicknameContextRegistrar from './nicknameContextRegistrar'
import {NICKNAMES} from '@wix/document-manager-extensions/src/constants/constants'
import * as experiment from 'experiment'

const {VALIDATIONS} = NICKNAMES

const ORIGINAL_CONTEXT_FIELD = 'originalNicknameContext'

function getNextSuffixIndex(compNickname: string, usedNicknames: string[]): number {
    const regex = new RegExp(`${compNickname}(\\d+)`) //will match the number in the end of the nickname
    const maxSuffixOfDefaultNickname = _(usedNicknames)
        .map(nickname => {
            const match = regex.exec(nickname)
            return match ? _.parseInt(match[1]) : null
        })
        .concat(0)
        .max()

    return maxSuffixOfDefaultNickname + 1
}

function setNicknamesForComponentsWithoutNickname(
    ps: PS,
    comps: Pointer[],
    defaultCompNickname: string,
    maxSuffixOfDefaultNickname: number,
    context: Pointer | null
): Pointer[] {
    return _(comps)
        .filter(compPointer => shouldSetNickname(ps, compPointer, context))
        .map(function (comp) {
            const newNickname = defaultCompNickname + maxSuffixOfDefaultNickname++
            setNickname(ps, comp, newNickname, context)
            return comp
        })
        .value()
}

function getNicknames(ps: PS, componentPointers: Pointer[], context: Pointer | null): string[] {
    return _(componentPointers)
        .map(compPointer => getComponentNickname(ps, compPointer, context))
        .reduce(_.assign)
}

function getComponentsInContainer(ps: PS, containerPointer: Pointer) {
    return ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(containerPointer)
}

function getComponentsInContext(ps: PS, pagePointer: Pointer, context: Pointer | null) {
    return context
        ? _.reject(getComponentsInContainer(ps, context), context)
        : getComponentsInContainer(ps, pagePointer)
}

function generateNicknamesForPage(ps: PS, usedNicknames, pagePointer: Pointer) {
    const context = getNicknameContext(ps, pagePointer)
    const allCompsInPageContext = getComponentsInContext(ps, pagePointer, context)
    const usedNicknamesInPage = getNicknames(ps, allCompsInPageContext, context)
    const allUsedNickNames = _.assign({}, usedNicknamesInPage, usedNicknames)

    const componentsWithNewNicknamesInPage = generateNicknamesForComponentsImpl(
        ps,
        allCompsInPageContext,
        allUsedNickNames,
        context
    )

    return _.assign(usedNicknamesInPage, getNicknames(ps, componentsWithNewNicknamesInPage, context))
}

function generateNicknamesForComponentsImpl(ps: PS, compsPointers: Pointer[], usedNickNames, context: Pointer | null) {
    const compGroupsByBaseNickname = _.groupBy(compsPointers, compPointer =>
        componentsMetaData.getDefaultNickname(ps, compPointer)
    )

    return _.flatMap(compGroupsByBaseNickname, function (comps, defaultCompNickname) {
        const maxSuffixOfDefaultNickname = getNextSuffixIndex(defaultCompNickname, usedNickNames)
        return setNicknamesForComponentsWithoutNickname(
            ps,
            comps,
            defaultCompNickname,
            maxSuffixOfDefaultNickname,
            context
        )
    })
}

function generateNicknamesForComponents(
    ps: PS,
    compsPointers: Pointer[],
    pagePointer: Pointer,
    viewMode: PossibleViewModes
) {
    const context = getNicknameContext(ps, pagePointer)
    const masterPagePointer = ps.pointers.components.getPage(constants.MASTER_PAGE_ID, viewMode)
    const allCompsInContext = getComponentsInContext(ps, pagePointer, context).concat(
        getComponentsInContext(ps, masterPagePointer, context)
    )
    const usedNicknames = getNicknames(ps, allCompsInContext, context)

    generateNicknamesForComponentsImpl(ps, compsPointers, usedNicknames, context)
}

function generateNicknamesForPagesInViewMode(ps: PS, pageIdList: string[], viewMode: PossibleViewModes) {
    //TODO: split this to private and public for pages and site
    const masterPagePointer = ps.pointers.components.getPage(constants.MASTER_PAGE_ID, viewMode)
    const masterPageNickNames = getNicknames(ps, getComponentsInContainer(ps, masterPagePointer), null)
    const nicknamesInAllSite = _.reduce(
        pageIdList,
        function (nickNames, pageId) {
            const pagePointer = ps.pointers.components.getPage(pageId, viewMode)
            const nicknamesForPage = generateNicknamesForPage(ps, masterPageNickNames, pagePointer)
            return _.assign(nickNames, nicknamesForPage)
        },
        {}
    )

    generateNicknamesForPage(ps, nicknamesInAllSite, masterPagePointer)
}

/**
 * @param ps
 * @param pageIdList
 * @param viewMode default is current view mode
 */
function generateNicknamesForPages(ps: PS, pageIdList: string[], viewMode?: PossibleViewModes) {
    if (_.includes(pageIdList, constants.MASTER_PAGE_ID)) {
        generateNicknamesForSite(ps, viewMode)
        return
    }

    if (!viewMode) {
        viewMode = mobileUtil.getViewMode(ps, viewMode, documentModeInfo)
    }
    generateNicknamesForPagesInViewMode(ps, pageIdList, constants.VIEW_MODES.DESKTOP)

    if (viewMode !== constants.VIEW_MODES.DESKTOP) {
        copyNicknamesFromDesktopToViewMode(ps, pageIdList, viewMode)
        generateNicknamesForPagesInViewMode(ps, pageIdList, viewMode)
    }
}

function copyNicknamesFromDesktopToViewMode(ps: PS, pageIdList: string[], viewMode: string) {
    _(pageIdList)
        .concat(constants.MASTER_PAGE_ID)
        .uniq()
        .forEach(function (pageId) {
            const viewModePagePointer = ps.pointers.components.getPage(pageId, viewMode)
            const context = getNicknameContext(ps, viewModePagePointer)
            const viewModePageComponents = getComponentsInContainer(ps, viewModePagePointer)
            const desktopPagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
            _(viewModePageComponents)
                .filter(compPointer => shouldSetNickname(ps, compPointer, context))
                .forEach(function (viewModeCompPointer) {
                    const desktopCompPointer = ps.pointers.components.getComponent(
                        viewModeCompPointer.id,
                        desktopPagePointer
                    )
                    if (desktopCompPointer) {
                        const desktopCompNickname = getNickname(ps, desktopCompPointer, context)
                        if (desktopCompNickname) {
                            setNickname(ps, viewModeCompPointer, desktopCompNickname, context)
                        }
                    }
                })
        })
}

//TODO: split this to private and public for pages and site
/**
 * @param ps
 * @param [viewMode] default is current view mode
 */
function generateNicknamesForSite(ps: PS, viewMode?: PossibleViewModes) {
    const popupIdList = _.map(pageData.getPopupsDataItems(ps), 'id')
    const pageIdList = pageData.getPagesList(ps)
    generateNicknamesForPages(ps, pageIdList.concat(popupIdList), viewMode)
}

function getNicknameContext(ps: PS, compPointer: Pointer) {
    return nicknameContextRegistrar.getNicknameContext(ps, compPointer)
}

function findConnectionByContext(context: Pointer | null, compConnections: IConnectionItem[]): IConnectionItem {
    return context
        ? (_.find(compConnections, {controllerRef: {id: context.id}}) as IConnectionItem)
        : getWixCodeConnectionItem(compConnections)
}

function findSerializedConnectionByContext(
    ps: PS,
    context: Pointer | null,
    compConnections: IConnectionItem[]
): IConnectionItem {
    if (context) {
        const {id: controllerId} = dataModel.getDataItem(ps, context) || {}
        if (controllerId) {
            return _.find(compConnections, {controllerId}) as IConnectionItem
        }
    }
    return getWixCodeConnectionItem(compConnections)
}

function getNicknameFromConnectionList(connectionList, context: Pointer | null) {
    const connectionItem = findConnectionByContext(context, connectionList)
    if (connectionItem) {
        return connectionItem.role
    }
}

function getNickname(ps: PS, compPointer: Pointer, context: null | Pointer = getNicknameContext(ps, compPointer)) {
    const compConnections = connections.get(ps, compPointer)
    return getNicknameFromConnectionList(compConnections, context)
}

function getNicknameByConnectionPointer(ps: PS, connectionPtr: Pointer, pagePointer: Pointer, context: null | Pointer) {
    const connectionItem = connections.getByConnectionPointer(ps, connectionPtr, pagePointer)
    return getNicknameFromConnectionList(connectionItem, context)
}

function getComponentNickname(
    ps: PS,
    compPointer: Pointer,
    context: null | Pointer = getNicknameContext(ps, compPointer)
) {
    if (experiment.isOpen('dm_getNicknameFromExt')) {
        return ps.extensionAPI.nicknames.getComponentNickname(compPointer, context)
    }

    const compConnections = connections.get(ps, compPointer)
    const nickname = getNicknameFromConnectionList(compConnections, context)
    const componentType = componentStructureInfo.getType(ps, compPointer)
    const customNicknameGetter = customNicknameRegistrar.getCustomGetter(componentType)

    if (customNicknameGetter) {
        return customNicknameGetter(ps, compPointer, nickname, context)
    }

    return nickname ? {[compPointer.id]: nickname} : {}
}

function getWixCodeConnectionItem(currentConnections: IConnectionItem[]): IConnectionItem {
    return _.find(currentConnections, {type: 'WixCodeConnectionItem'})
}

function createConnectionItem(context: null | Pointer, nickname: string): IConnectionItem {
    return context
        ? {
              type: 'ConnectionItem',
              role: nickname,
              // @ts-expect-error
              controllerRef: context,
              isPrimary: true
          }
        : {
              type: 'WixCodeConnectionItem',
              role: nickname
          }
}

function setNickname(ps: PS, compPointer: Pointer, nickname: string, context = getNicknameContext(ps, compPointer)) {
    if (validateNickname(ps, compPointer, nickname) !== VALIDATIONS.VALID) {
        throw new ReportableError({errorType: 'invalidNickname', message: 'The new nickname you provided is invalid'})
    }
    hooks.executeHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_BEFORE, 'updateConnectionsItem', [ps, compPointer, nickname])

    if (_.get(compPointer, 'id') === _.get(context, 'id')) {
        throw new Error('Cannot set nickname of context component to itself')
    }

    let currentConnections: IConnectionItem[] = connections.get(ps, compPointer)
    const connection = findConnectionByContext(context, currentConnections)
    if (connection) {
        connection.role = nickname
    } else {
        const newConnectionItem = createConnectionItem(context, nickname)
        currentConnections = [newConnectionItem].concat(currentConnections)
    }

    dataModel.updateConnectionsItem(ps, compPointer, currentConnections)
    hooks.executeHook(hooks.HOOKS.WIX_CODE.SET_NICKNAME_AFTER, 'updateConnectionsItem', [ps, compPointer, nickname])
}

function removeNickname(ps: PS, compPointer: Pointer, context = getNicknameContext(ps, compPointer)) {
    const currentConnections = connections.get(ps, compPointer)
    const connection = findConnectionByContext(context, currentConnections)
    if (connection) {
        _.remove(currentConnections, connection)
        if (currentConnections.length > 0) {
            dataModel.updateConnectionsItem(ps, compPointer, currentConnections)
        } else {
            dataModel.removeConnectionsItem(ps, compPointer)
        }
    }
}

function getPagePointersInSameContext(ps: PS, pagePointer: Pointer): Pointer[] {
    const viewMode = pagePointer.type
    if (pagePointer.id === constants.MASTER_PAGE_ID) {
        const nonDeletedPagesPointers = ps.pointers.page.getNonDeletedPagesPointers(true)
        return _.map(nonDeletedPagesPointers, nonDeletedPagePointer =>
            ps.pointers.full.components.getPage(nonDeletedPagePointer.id, viewMode)
        )
    }
    return [pagePointer, ps.pointers.full.components.getMasterPage(viewMode)]
}

function hasComponentWithThatNickname(
    ps: PS,
    containingPagePointer: Pointer,
    searchedNickname: string,
    compPointerToExclude?: Pointer
): boolean {
    if (!searchedNickname) {
        return false
    }

    compPointerToExclude = compPointerToExclude
        ? ps.pointers.full.components.getComponent(compPointerToExclude.id, containingPagePointer)
        : undefined
    const pagesSharingNicknames = getPagePointersInSameContext(ps, containingPagePointer)

    return _.some(pagesSharingNicknames, pagePointer => {
        const context = getNicknameContext(ps, pagePointer)
        const compsInPage = ps.pointers.full.components.getChildrenRecursivelyRightLeftRootIncludingRoot(pagePointer)
        return !_(compsInPage)
            .thru(comps => getNicknames(ps, comps, context))
            .pickBy((nickname, compId) => (compPointerToExclude ? compPointerToExclude.id !== compId : true))
            .pickBy(nickname => nickname === searchedNickname)
            .isEmpty()
    })
}

function validateNickname(ps: PS, compPointer: Pointer, nickname: string) {
    return ps.extensionAPI.nicknames.validateNickname(
        compPointer,
        nickname,
        hasComponentWithThatNickname.bind(this, ps)
    )
}

function shouldSetNickname(ps: PS, compPointer: Pointer, context: null | Pointer): boolean {
    return (
        !ps.pointers.components.isMasterPage(compPointer) &&
        !getNickname(ps, compPointer, context) &&
        componentsMetaData.shouldAutoSetNickname(ps, compPointer)
    )
}

function removeNicknameFromComponentIfInvalid(ps: PS, compPointer: Pointer, containerPointer: Pointer) {
    const pagePointer = componentStructureInfo.isPageComponent(ps, containerPointer)
        ? containerPointer
        : componentStructureInfo.getPage(ps, containerPointer)
    const context = getNicknameContext(ps, pagePointer)
    _.forEach(ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(compPointer), function (comp) {
        const nickname = getNickname(ps, comp, context)
        if (hasComponentWithThatNickname(ps, pagePointer, nickname, comp)) {
            removeNickname(ps, comp, context)
        }
    })
}

function fixConnections(ps: PS, context: null | Pointer, compDefinition: CompStructure) {
    const items = _.get(compDefinition, ['connections', 'items'], [])
    const wixCodeConnectionItem: WixCodeConnectionItem = _.find(items, {type: 'WixCodeConnectionItem'})
    if (context && wixCodeConnectionItem) {
        const {role} = wixCodeConnectionItem
        const controllerId = dataModel.getDataItem(ps, context).id
        const connectionItem = connections.createConnectionItem(role, controllerId)

        compDefinition.connections.items = _(items).without(wixCodeConnectionItem).concat([connectionItem]).value()
    }
}

function removeConnectionFromSerializedComponentIfInvalidNickname(
    ps: PS,
    compPointer: Pointer,
    context: null | Pointer,
    connectionItems,
    pagePointer: Pointer
) {
    const nicknameConnectionItem = findSerializedConnectionByContext(ps, context, connectionItems)
    if (
        nicknameConnectionItem &&
        hasComponentWithThatNickname(
            ps,
            pagePointer,
            (nicknameConnectionItem as ControllerConnectionItem).role,
            compPointer
        )
    ) {
        _.remove(connectionItems, nicknameConnectionItem)
    }
}

function updateConnectionsIfNeeded(
    ps: PS,
    compPointer: Pointer,
    containerPointer: Pointer,
    rootCompDefinition: CompStructure
) {
    const pagePointer = ps.pointers.full.components.getPageOfComponent(containerPointer)
    const nicknameContext = getNicknameContext(ps, pagePointer)
    const compsQueue = [rootCompDefinition]

    while (compsQueue.length) {
        const compDefinition = compsQueue.shift()

        fixConnections(ps, nicknameContext, compDefinition)
        removeConnectionFromSerializedComponentIfInvalidNickname(
            ps,
            compPointer,
            nicknameContext,
            _.get(compDefinition, ['connections', 'items']),
            pagePointer
        )

        compsQueue.push(...((compDefinition.components as CompStructure[]) || []))
    }
}

function getContextControllerId(ps: PS, context: Pointer | null) {
    return _.get(dataModel.getDataItem(ps, context), 'id')
}

function updateNicknameContextByNewContainer(
    ps: PS,
    compPointer: Pointer,
    componentDefinition: CompStructure,
    newContainerPointer: Pointer
) {
    const connectionItems = _.get(componentDefinition, ['connections', 'items'])
    if (_.isEmpty(connectionItems)) {
        return
    }

    const contextInCurrentContainer = _.get(componentDefinition, ['custom', ORIGINAL_CONTEXT_FIELD])
    if (contextInCurrentContainer) {
        const pagePointer = ps.pointers.full.components.getPageOfComponent(newContainerPointer)
        updateConnectionItemsNickname(ps, connectionItems, compPointer, pagePointer, contextInCurrentContainer)
    }
}

function updateConnectionItemsNickname(
    ps: PS,
    connectionItems,
    compPointer: Pointer,
    pagePointer: Pointer,
    contextInCurrentContainer: Pointer
) {
    const connectionItem = findSerializedConnectionByContext(ps, contextInCurrentContainer, connectionItems)
    if (connectionItem) {
        const context = getNicknameContext(ps, pagePointer)
        ;(connectionItem as ControllerConnectionItem).controllerId = getContextControllerId(ps, context)
        removeConnectionFromSerializedComponentIfInvalidNickname(ps, compPointer, context, connectionItems, pagePointer)
    }
}

function setOriginalContextToSerializedComponent(ps: PS, compPointer: Pointer, customStructureData) {
    const context = getNicknameContext(ps, compPointer)

    if (context) {
        customStructureData[ORIGINAL_CONTEXT_FIELD] = context
    }
}

export default {
    getNicknameByConnectionPointer,
    generateNicknamesForComponents,
    generateNicknamesForSite,
    getNickname,
    setNickname,
    removeNickname,
    removeNicknameFromComponentIfInvalid,
    updateConnectionsIfNeeded,
    updateNicknameContextByNewContainer,
    setOriginalContextToSerializedComponent,
    updateConnectionItemsNickname,
    validateNickname,
    generateNicknamesForPages,
    hasComponentWithThatNickname,
    getComponentsInPage: getComponentsInContainer,
    findSerializedConnectionByContext,
    VALIDATIONS
}
