import _ from 'lodash'

const ROUTER_PREFIX_TYPE = 'router'
const DYNAMIC_PAGE_PREFIX_TYPE = 'dynamicPages'
const TEMPLATE_PAGE_TYPE = 'template'
const STATIC_PAGE_TYPE = 'static'

function getRouterPrefixes(routers) {
    const pagesPrefixesMap = getPagesPrefixesMap(routers)
    return getPrefixStructures(pagesPrefixesMap)
}

function getPages(
    pagesDataItems,
    isPopup,
    mainPageId,
    routers,
    clientSpecMap,
    {includeBlankUrl = false, includeAppsHiddenPages = false} = {}
) {
    const nonPopupPagesDataItems = _.filter(pagesDataItems, pageData => !isPopup(pageData.id))
    // @ts-ignore
    const pagesPrefixesMap = getPagesPrefixesMap(routers, clientSpecMap)
    const pages = _.map(nonPopupPagesDataItems, page =>
        getPageStructure(
            page,
            mainPageId,
            pagesPrefixesMap[page.id],
            clientSpecMap,
            includeBlankUrl,
            includeAppsHiddenPages
        )
    )
    return _.compact(pages)
}

function getPageStructure(page, mainPageId, prefix, clientSpecMap, includeBlankUrl, includeAppsHiddenPages) {
    const {pageUriSEO} = page
    const applicationId = page.tpaApplicationId || page.appInnerID

    const data: Record<string, any> = {
        name: page.title,
        id: page.id,
        type: STATIC_PAGE_TYPE
    }

    if (pageUriSEO && (!_.startsWith(pageUriSEO, 'blank') || includeBlankUrl)) {
        data.url = `/${page.pageUriSEO}`
    }

    if (applicationId) {
        data.type = TEMPLATE_PAGE_TYPE
        const appData = clientSpecMap[applicationId]
        data.applicationId = _.get(appData, ['appDefinitionId'])
        if (page.tpaApplicationId && !hasMainSection(appData) && !includeAppsHiddenPages) {
            return null
        }
        data.tpaPageId = page.tpaPageId
    }

    if (prefix) {
        data.type = TEMPLATE_PAGE_TYPE
        data.prefix = prefix.value
    }

    if (data.id === mainPageId) {
        data.isMainPage = true
    }

    return data
}

function getPagesPrefixesMap(routers) {
    const routersPrefixes = _.reduce(
        routers.configMap,
        function (res, val, key) {
            const pageIds = _.values(val.pages)
            _.forEach(pageIds, function (page) {
                res[page] = {
                    value: val.prefix,
                    id: key,
                    type: getPrefixType(val)
                }
            })
            return res
        },
        {}
    )
    return !routers ? {} : routersPrefixes
}

function getPrefixStructures(pagesPrefixesMap) {
    return _.map(pagesPrefixesMap, prefix => ({
        name: prefix.value,
        type: prefix.type,
        id: prefix.id,
        prefix: `/${prefix.value}`
    }))
}

function getPrefixType(router) {
    return router.appDefinitionId === 'wix-code' ? ROUTER_PREFIX_TYPE : DYNAMIC_PAGE_PREFIX_TYPE
}

function getAppSections(appData) {
    let widgets = appData?.widgets
    widgets = _.filter(widgets, widget => !_.isNil(widget.appPage))
    return _.filter(widgets, isWidgetPublished)
}

function isWidgetPublished(widget) {
    const isPublishedInNewAndOldEditor = widget.published
    const isPublishedOnlyInNewEditor = widget.santaEditorPublished
    return isPublishedInNewAndOldEditor || isPublishedOnlyInNewEditor
}

function hasMainSection(appData) {
    const sections = getAppSections(appData)
    return _.some(sections, app => !app.appPage.hidden)
}

export default {
    getPages,
    getRouterPrefixes,
    clientSpecMap: {
        hasMainSection
    }
}
