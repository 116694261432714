import experiment from 'experiment-amd'

export default {
    anchors: {
        to: false,
        from: false
    },
    dockable: false,
    alignable: false,
    removable: false,
    groupable: false,
    rotatable: true,
    duplicatable: false,
    canConnectToCode: false,
    shouldAutoSetNickname: false,
    canBeFixedPosition: false,
    isRepeatable: Boolean(experiment.isOpen('dm_mediaPlayerInRepeater')),
    mobileConversionConfig: {
        minHeight: 44
    }
}
