import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'

function isMainSideResizeEvent(newLayout, currLayout, sizeKey) {
    if (newLayout && !_.isUndefined(newLayout[sizeKey]) && currLayout) {
        return newLayout[sizeKey] !== currLayout[sizeKey]
    }
}

export default function (ps: PS, compPointer, newLayout) {
    const compProps = component.properties.get(ps, compPointer)

    if (_.isNil(compProps)) {
        return
    }

    const currLayout = component.layout.get(ps, compPointer)
    const isVerticalText = compProps.verticalText
    const sizeKey = isVerticalText ? 'width' : 'height'

    if (isMainSideResizeEvent(newLayout, currLayout, sizeKey)) {
        const isPacked = newLayout[sizeKey] === 0

        component.properties.update(ps, compPointer, {
            minHeight: isVerticalText ? null : newLayout.height,
            minWidth: isVerticalText ? newLayout.width : null,
            packed: isPacked
        })
    }
}
