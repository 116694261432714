import type {Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import actionsAndBehaviors from '../../actionsAndBehaviors/actionsAndBehaviors'
import component from '../../component/component'
import dsConstants from '../../constants/constants'
import documentModeInfo from '../../documentMode/documentModeInfo'
import hooks from '../../hooks/hooks'
import page from '../../page/page'
import pageData from '../../page/pageData'
import platformProvision from '../../platform/provision'
import workerService from '../../platform/services/workerService'
import compStructure from '../compStructure'
import tpaConstants from '../constants'
import responsiveUtils from '../utils/responsiveUtils'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'
import sectionsTranslatedPageTitlesCache from './sectionsTranslatedPageTitlesCache'

const getPageUriSEO = function (ps: PS, name: string) {
    const invalidUrlCharacters = /[^A-Za-z0-9-]/g
    return pageData.getValidPageUriSEO(ps, '', name.replace(invalidUrlCharacters, '-').toLowerCase() || 'blank')
}

const addSectionComponentOptionsIfNeeded = function (sectionId: string, options) {
    if (sectionId && _.get(options, 'componentOptions.page.pageId') === sectionId) {
        options.title = options.componentOptions.page.title || options.title
        options.requireLogin = _.isUndefined(options.componentOptions.page.requireLogin)
            ? options.requireLogin
            : options.componentOptions.page.requireLogin
        options.styleId = options.componentOptions.styleId || options.styleId
        options.layout = options.componentOptions.layout || options.layout
        options.isHidden = _.isUndefined(options.componentOptions.page.isHidden)
            ? options.isHidden
            : options.componentOptions.page.isHidden
    }
}

//hidden sub-pages
const addSubSectionInternal = function (ps: PS, pageToAddPointer, widgetData, appData, options) {
    options = options || {}
    addSectionComponentOptionsIfNeeded(widgetData.appPage.id, options)

    const sectionTitle =
        options.title ||
        sectionsTranslatedPageTitlesCache.getTitle(appData.appDefinitionId, widgetData.widgetId) ||
        widgetData.appPage.name
    const sectionId = `${tpaConstants.TYPE.TPA_MULTI_SECTION}_${santaCoreUtils.guidUtils.getUniqueId(
        undefined,
        undefined
    )}`
    _.assign(widgetData, {applicationId: appData.applicationId})
    const pageUriSEO = getPageUriSEO(ps, options.title || widgetData.appPage.name)
    widgetData.name = sectionTitle

    const serializedPage = compStructure.getSubSectionStructure(
        ps,
        widgetData,
        sectionId,
        pageUriSEO,
        options.requireLogin,
        options.isHidden
    )
    // @ts-expect-error
    pageToAddPointer = pageToAddPointer || page.getPageIdToAdd(ps, sectionTitle, serializedPage)
    const sectionPointer = addPageAndSection(
        ps,
        pageToAddPointer,
        sectionId,
        appData.applicationId,
        appData.appDefinitionId,
        widgetData,
        serializedPage,
        widgetData.widgetId,
        tpaConstants.COMP_TYPES.TPA_MULTI_SECTION,
        tpaConstants.DATA_TYPE.TPA_MULTI_SECTION,
        options
    )

    return {
        sectionPointer,
        pagePointer: pageToAddPointer
    }
}

const addSubSection = function (ps: PS, pageToAddPointer, widgetData, appData, options?) {
    const addedData = addSubSectionInternal(ps, pageToAddPointer, widgetData, appData, options)
    return addedData.sectionPointer.id
}

const addHiddenPages = function (ps: PS, appData, componentOptions?) {
    const sections = clientSpecMapService.getAppSectionsToInstall(ps, appData.applicationId)
    let addedComps = []
    _.forEach(sections, function (widget) {
        if (widget.appPage.hidden && widget.appPage.id) {
            const addedPageAndSection = addSubSectionInternal(ps, null, widget, appData, {componentOptions})
            addedComps = addedComps.concat(_.values(addedPageAndSection))
        }
    })
    return addedComps
}

const getSectionLayout = function (layout) {
    layout = layout || {}
    return {
        x: layout.x || 0,
        y: layout.y || 0,
        width: layout.width || 980,
        height: layout.height || 500
    }
}

const addPageAndSection = function (
    ps: PS,
    pageToAddPointer,
    sectionId: string,
    applicationId,
    appDefinitionId: string,
    widgetData,
    pageStructure,
    widgetId,
    tpaSectionCompType,
    tpaSectionDataType,
    options?
) {
    options = options || {}
    const viewMode = ps.pointers.components.getViewMode(pageToAddPointer)
    const sectionPointer = ps.pointers.components.getUnattached(sectionId, viewMode)

    const sectionComponentDefinition = {
        componentType: tpaSectionCompType,
        type: 'Component',
        id: sectionPointer.id,
        style: options.styleId || tpaConstants.STYLE.TPA_SECTION,
        skin: tpaConstants.SKINS.TPA_SECTION,
        layout: getSectionLayout(options.layout),
        data: {
            appDefinitionId,
            type: tpaSectionDataType,
            applicationId: `${applicationId}`,
            metaData: {
                isPreset: true,
                schemaVersion: '1.0',
                isHidden: false
            },
            widgetId
        }
    }

    if (_.get(widgetData, 'appPage.fullPage') || _.get(widgetData, 'componentFields.fullPageDesktopOnly')) {
        const docked = {top: {px: 0}, right: {px: 0}, bottom: {px: 0}, left: {px: 0}}
        _.assign(sectionComponentDefinition.layout, {fixedPosition: true, docked})
    } else if (widgetData.canBeStretched && widgetData.shouldBeStretchedByDefault) {
        _.assign(sectionComponentDefinition.layout, {docked: {left: {vw: 0}, right: {vw: 0}}})
    }

    hooks.executeHook(hooks.HOOKS.ADD_TPA.COMPONENT_DEFINITION_MODIFIER, undefined, [pageStructure, undefined])

    if (_.get(options, 'managingAppDefId')) {
        pageStructure = _.defaultsDeep({}, pageStructure, {data: {managingAppDefId: options.managingAppDefId}})
    }
    ps.extensionAPI.logger.interactionStarted(dsConstants.TPA_SECTION_ADDITION.ADD_PAGE, {
        extras: {pageToAddPointer, pageStructure}
    })
    page.add(ps, pageToAddPointer, widgetData.name, pageStructure)
    ps.extensionAPI.logger.interactionEnded(dsConstants.TPA_SECTION_ADDITION.ADD_PAGE, {
        extras: {
            pageToAddPointer,
            isCompExist: ps.dal.isExist(pageToAddPointer),
            isCompExistFull: ps.dal.full.isExist(pageToAddPointer)
        }
    })
    compStructure.convertStyleIdToStyleDef(ps, sectionComponentDefinition)

    hooks.executeHook(hooks.HOOKS.ADD_TPA.COMPONENT_DEFINITION_MODIFIER, undefined, [
        sectionComponentDefinition,
        pageToAddPointer
    ])

    if (options.responsiveLayout) {
        responsiveUtils.replaceLayout(sectionComponentDefinition, options.responsiveLayout)
    }
    ps.extensionAPI.logger.interactionStarted(dsConstants.TPA_SECTION_ADDITION.ADD_SECTION, {
        extras: {containerPointer: pageToAddPointer, sectionPointer, sectionComponentDefinition}
    })
    component.add(ps, sectionPointer, pageToAddPointer, sectionComponentDefinition)
    ps.extensionAPI.logger.interactionEnded(dsConstants.TPA_SECTION_ADDITION.ADD_SECTION, {
        extras: {
            sectionPointer,
            isCompExist: ps.dal.isExist(sectionPointer),
            isCompExistFull: ps.dal.full.isExist(sectionPointer)
        }
    })
    return sectionPointer
}

const setPrefetchPageBehaviorIfNeeded = function (ps: PS, widgetPointer: Pointer, appData) {
    const tpaWidgetIdToPreFetch = clientSpecMapService.getSectionsWidgetIdsToPreFetch(appData)

    if (_.isEmpty(tpaWidgetIdToPreFetch)) {
        return
    }

    const hiddenSections = installedTpaAppsOnSiteService.getHiddenSections(ps, appData.applicationId)

    if (_.isEmpty(hiddenSections)) {
        return
    }

    const pageIdsToFetch = _(hiddenSections)
        .filter(hiddenSection => _.includes(tpaWidgetIdToPreFetch, hiddenSection.widgetId))
        .map('pageId')
        .value()

    if (_.size(pageIdsToFetch) > 0) {
        const pagePointer = ps.pointers.components.getPage(_.head(pageIdsToFetch), documentModeInfo.getViewMode(ps))
        const behaviorDefinition = actionsAndBehaviors.getBehaviorDefinition(ps, 'prefetchPages')
        const actionDefinition = actionsAndBehaviors.getActionDefinition(ps, 'screenIn')

        behaviorDefinition.params.prefetchFilters.id = pageIdsToFetch

        removeExistingBehavior(ps, widgetPointer)

        actionsAndBehaviors.updateBehavior(ps, widgetPointer, actionDefinition, pagePointer, behaviorDefinition)
    }
}

const removeExistingBehavior = function (ps: PS, widgetPointer: Pointer) {
    const existingBehaviors = actionsAndBehaviors.getBehaviors(ps, widgetPointer)
    const behaviorToRemove = _.find(existingBehaviors, {behavior: {name: 'prefetchPages'}})

    if (behaviorToRemove) {
        actionsAndBehaviors.removeComponentSingleBehavior(
            ps,
            widgetPointer,
            behaviorToRemove,
            behaviorToRemove.action.name
        )
    }
}

/**
 * @param {ps} ps
 * @param appData
 * @param options
 * @returns {Promise<void>}
 */
const addPlatformAppIfNeeded = function (ps: PS, appData, options) {
    return new Promise<void>(function (resolve, reject) {
        if (clientSpecMapService.hasEditorPlatformPart(appData)) {
            platformProvision
                .provision(ps, appData.appDefinitionId, {
                    ...options,
                    firstInstall: clientSpecMapService.isAppActive(ps, appData)
                })
                .then(resolve, reject)
            return
        }

        if (appData.isWixTPA) {
            workerService.notifyAppInstalled(ps, {
                // @ts-expect-error
                applicationId: appData.applicationId,
                appDefinitionId: appData.appDefinitionId,
                biData: options.biData
            })
        }

        if (clientSpecMapService.hasViewerPlatformPart(appData)) {
            hooks.executeHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, '', [ps])
        }

        resolve()
    })
}

export default {
    addSubSection,
    addSubSectionInternal,
    addHiddenPages,
    addPageAndSection,
    getPageUriSEO,
    setPrefetchPageBehaviorIfNeeded,
    addPlatformAppIfNeeded,
    addSectionComponentOptionsIfNeeded
}
