import type {SnapshotDal} from '@wix/document-manager-core'
import _ from 'lodash'
import type {SaveTaskDefinition} from '../../saveAPI/lib/registry'
import provisionService from '../services/provisionService'
import metaSiteProvisioner from '../../metaSiteProvisioner/metaSiteProvisioner'

const TASK_NAME = 'unProvisionedApps'

const fetchProvisionDataFromSnapshotDal = (snapshotDal: SnapshotDal) => ({
    metaSiteId: snapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'}),
    sessionId: snapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'}),
    clientSpecMap: snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'}),
    provisionBaseUrl: _.get(snapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'}), 'appStoreUrl')
})

const createTask = (): SaveTaskDefinition => ({
    partialSave(lastSavedData, currentData, resolve) {
        resolve()
    },
    fullSave(lastSavedData, currentData, resolve) {
        resolve()
    },
    firstSave: metaSiteProvisioner(function (
        lastSavedData,
        currentData,
        resolve,
        reject,
        bi,
        options,
        lastSnapshotDal,
        currentSnapshotDal
    ) {
        const {metaSiteId, sessionId, clientSpecMap, provisionBaseUrl} =
            fetchProvisionDataFromSnapshotDal(currentSnapshotDal)
        provisionService.completeProvisionAppsAfterSave(
            provisionBaseUrl,
            metaSiteId,
            sessionId,
            clientSpecMap,
            resolve,
            reject
        )
    }),
    saveAsTemplate(lastSavedData, currentData, resolve) {
        resolve()
    },
    autosave(lastSavedData, currentData, resolve) {
        resolve()
    },
    publish(currentData, resolve) {
        resolve()
    },
    getTaskName() {
        return TASK_NAME
    },
    getSnapshotTags() {
        return ['primary']
    }
})

export default () => createTask()
