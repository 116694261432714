import type {PS} from '@wix/document-services-types'
import {urlUtils, cookieUtils} from '@wix/santa-core-utils'
import {requestsUtil} from '@wix/santa-ds-libs/src/warmupUtils'
import experiment from 'experiment-amd'

const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    Accept: 'application/json, text/plain, */*',
    'X-XSRF-TOKEN': cookieUtils.getCookie('XSRF-TOKEN')
}

const buildAppsServiceBaseUrl = (ps: PS) => {
    const serviceTopologyPointer = ps.pointers.general.getServiceTopology()
    const editorServerRootPointer = ps.pointers.getInnerPointer(serviceTopologyPointer, 'editorServerRoot')
    const editorServerRoot = ps.dal.get(editorServerRootPointer)
    return `${urlUtils.baseUrl(editorServerRoot)}/_api`
}

const fetchAppDataNew = async (ps: PS, appId: string) => {
    const res = await requestsUtil.fetchJson(
        `${buildAppsServiceBaseUrl(ps)}/app-components-service/v1/components/query`,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({query: {filter: {appId, version: 0}}}),
            headers
        }
    )

    return {
        // @ts-ignore
        components: res.components.forEach(component => {
            return {
                compData: component.data,
                compId: component.id,
                compName: component.name,
                compType: component.type
            }
        })
    }
}

const fetchAppDataOld = (ps: PS, appDefinitionId: string) =>
    requestsUtil.fetchJson(`${buildAppsServiceBaseUrl(ps)}/app-service/v1/apps/${appDefinitionId}`, {
        credentials: 'include'
    })

const fetchAppData = experiment.isOpen('dm_blocksNewDevCenterApiApps') ? fetchAppDataNew : fetchAppDataOld

const postComponentDataNew = async (ps: PS, appId: string, componentData) => {
    const response: any = await requestsUtil.fetchJson(
        `${buildAppsServiceBaseUrl(ps)}/app-components-service/v1/components`,
        {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                component: {
                    appId,
                    data: componentData.compData,
                    type: componentData.compType,
                    name: componentData.compName
                }
            }),
            headers
        }
    )
    return response.component.id
}

const postComponentDataOld = async (ps: PS, appDefinitionId: string, componentData) => {
    const response: any = await requestsUtil.fetchJson(
        `${buildAppsServiceBaseUrl(ps)}/app-service/v1/apps/${appDefinitionId}/components`,
        {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(componentData)
        }
    )
    return response.compId
}

const postComponentData = (ps: PS, appId: string, componentData) =>
    experiment.isOpen('dm_blocksNewDevCenterApiComponents')
        ? postComponentDataNew(ps, appId, componentData)
        : postComponentDataOld(ps, appId, componentData)

export default {
    fetchAppData,
    postComponentData
}
