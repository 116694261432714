import {
    CoreLogger,
    DalStore,
    DalValue,
    deepCompare,
    deepCompare4,
    deepCompareDebug,
    deepCompareDebug2,
    DocumentManager,
    store as dmStore
} from '@wix/document-manager-core'
import {constants, extensions, FetchFn, siteDataImmutableFromSnapshot} from '@wix/document-manager-extensions'
import type {CSaveApi} from '@wix/document-manager-extensions/src/extensions/csave/continuousSave'
import type {DataFixerVersioningApi} from '@wix/document-manager-extensions/src/extensions/dataFixerVersioning/dataFixerVersioning'
import type {ServiceTopology} from '@wix/document-manager-extensions/src/extensions/serviceTopology'
import type {SnapshotExtApi} from '@wix/document-manager-extensions/src/extensions/snapshots'
import type {ViewsAPI, ViewsExtensionAPI} from '@wix/document-manager-extensions/src/extensions/views/views'
import {ReportableError} from '@wix/document-manager-utils'
import type {DataFixer, Experiment, PageList, Pointer, DSConfig} from '@wix/document-services-types'
import _ from 'lodash'
import {INTERACTIONS} from '../constants/constants'
import {DataMigrationRunner, FixerCategory} from '../dataMigration/dataMigrationRunner'
import type {RendererModelBuilderForHost} from '../host'
import type {FetchPagesFacade, InitConfig} from '../types'
import {buildPageList} from '../utils/pageListUtils'
import * as commonConfigInitializer from './commonConfigInitializer'
import * as modelsInitializer from './modelsInitializer'
import type {
    DocumentServicesModel,
    DocumentServicesModelForServer,
    RendererModel,
    RendererModelForServer
} from './modelTypes'
import multilingualInitializer from './multilingualInitializer'
import {movePageDataToMaster} from './pageDataMigrator'
import pagesPlatformApplicationsInitializer from './pagesPlatformApplicationsInitializer'
import {
    addStoreToDal,
    convertStore,
    getRemovalCandidates,
    mergeDeletionsToDalApprovedStore,
    mergeToDalApprovedStore,
    removeDataFromDal
} from './storeToDal'

const {SNAPSHOTS} = constants
const {getSiteDataJson} = siteDataImmutableFromSnapshot
const {createStore} = dmStore

const isExperimentOpen = (value: string) => !!(value && value !== 'old' && value !== 'false')

const getDataFixersParams = (store: ServerStore) => {
    const {rendererModel} = store
    const pageIdsArray = _(store.pagesData.masterPage.data.document_data)
        .filter(data => (data?.type === 'Page' || data?.type === 'AppPage') && data?.id !== 'SITE_STRUCTURE')
        .map('id')
        .value()
    const runningExperiments = rendererModel.runningExperiments || {}
    const {urlFormatModel} = rendererModel
    const {quickActionsMenuEnabled} = _.get(rendererModel, ['siteMetaData', 'quickActions', 'configuration'], {
        quickActionsMenuEnabled: false
    })
    const experiments = _(runningExperiments)
        .pickBy(val => isExperimentOpen(val))
        .keys()
        .value()

    return {
        clientSpecMap: rendererModel.clientSpecMap,
        urlFormatModel,
        quickActionsMenuEnabled,
        isViewerMode: !rendererModel.previewMode,
        experiments,
        pageIdsArray
    }
}

export interface InitParams {
    documentManager: DocumentManager
    dataFixer: DataFixer
    partialPages: string[]
    dataMigrationRunner: DataMigrationRunner
    rendererModelBuilder: RendererModelBuilderForHost
    serviceTopology: ServiceTopology
    documentServicesModel: DocumentServicesModelForServer | DocumentServicesModel
    config: DSConfig
    logger: CoreLogger
    fetchFn: FetchFn
    trackingFn<T>(...args: any[]): Promise<T>
    fetchPagesFacade: FetchPagesFacade
    experimentInstance: Experiment
    pageList: PageList
}

export type FetchPagesToDalFunction = (pageIds: string[]) => Promise<void>

export interface InitResult {
    store: ServerStore
    fetchPagesToDal: FetchPagesToDalFunction
}

export interface InitFromCacheParams {
    documentManager: DocumentManager
    partialPages: string[]
    logger: CoreLogger
    rendererModelBuilder: RendererModelBuilderForHost
    documentServicesModel: DocumentServicesModelForServer | DocumentServicesModel
    serviceTopology: ServiceTopology
    trackingFn<T>(...args: any[]): Promise<T>
}

export interface ServerStore {
    rendererModel: RendererModelForServer | RendererModel
    documentServicesModel: DocumentServicesModelForServer | DocumentServicesModel
    serviceTopology: ServiceTopology
    pagesData: Record<string, any>
    orphanPermanentDataNodes?: any[]
    routers?: any
    pagesPlatformApplications?: any
    origin?: string
}

const stubTrackingFn = async (name: string, fn: Function) => await fn()

const initialize = async ({
    documentManager,
    dataFixer,
    partialPages,
    dataMigrationRunner,
    serviceTopology,
    rendererModelBuilder,
    documentServicesModel,
    config,
    logger,
    fetchFn,
    fetchPagesFacade,
    pageList,
    trackingFn = stubTrackingFn
}: InitParams): Promise<InitResult> => {
    const options = {
        paramsOverrides: {
            isDraft: _.get(documentServicesModel, ['isDraft'])
        }
    }

    let serverStoreBase = {} as ServerStore

    const interactionStartedWithOptions = (name: string) => logger.interactionStarted(name, options)
    const interactionEndedWithOptions = (name: string) => logger.interactionEnded(name, options)
    async function trackingAndInteraction<T>(name: string, fn: (...args: any[]) => Promise<T>): Promise<T> {
        interactionStartedWithOptions(name)
        const returnValue: T = await trackingFn(name, async () => await fn())
        interactionEndedWithOptions(name)

        return returnValue
    }

    const initializeModels = async (store: ServerStore, disableCommonConfig = false) => {
        const initConfig: InitConfig = {
            documentManager,
            rendererModel: store.rendererModel,
            documentServicesModel: store.documentServicesModel,
            serviceTopology: store.serviceTopology
        }

        const initialModelsState = createStore()
        const initializers = disableCommonConfig
            ? [modelsInitializer, pagesPlatformApplicationsInitializer]
            : [modelsInitializer, pagesPlatformApplicationsInitializer, commonConfigInitializer]

        const promises = initializers.map(init => init.initialize(initConfig, initialModelsState))

        await Promise.all(promises)
        documentManager.dal.mergeToApprovedStore(initialModelsState, 'model-initializer')
    }

    const minorFixing = (pageJson: any, pageId: string) => {
        if (!pageJson.structure.id && pageId === 'masterPage') {
            pageJson.structure.id = 'masterPage'
        }
        return pageJson
    }

    const applyAndUpdateToApprovedStore = (
        store: ServerStore,
        op: (store: ServerStore) => ServerStore,
        label: string
    ): ServerStore => {
        const removalCandidates = getRemovalCandidates(documentManager, store)
        const newStore = op(store)
        mergeToDalApprovedStore(documentManager, newStore, removalCandidates, `${label}-update`)
        mergeDeletionsToDalApprovedStore(documentManager, removalCandidates, `${label}-remove`)
        return newStore
    }

    const updateApprovedStoreBeforeCSaveAndVerifyNoChanges = (serverStore: ServerStore) => {
        const changes: object[] = []

        const verifyUnchanged = (pointer: Pointer, oldValue: DalValue, newValue: DalValue) => {
            if (!deepCompare(oldValue, newValue)) {
                changes.push({
                    pointer,
                    oldValueExists: !!oldValue,
                    newValueExists: !!newValue,
                    oldSig: _.get(oldValue, ['metaData', 'sig']),
                    newSig: _.get(newValue, ['metaData', 'sig']),
                    reallyDifferent: !_.isEqual(oldValue, newValue),
                    compareDebug: deepCompareDebug(oldValue, newValue),
                    compareDebug2: deepCompareDebug2(oldValue, newValue),
                    deep4: deepCompare4(oldValue, newValue),
                    oldToOld: deepCompare(oldValue, oldValue),
                    newToNew: deepCompare(newValue, newValue)
                })
            }
        }
        documentManager.dal.registerForChangesCallback(verifyUnchanged)

        applyAndUpdateToApprovedStore(serverStore, _.identity, 'before-csave')

        documentManager.dal.unregisterForChangesCallback(verifyUnchanged)
        if (changes.length) {
            logger.captureError(
                new ReportableError({
                    errorType: 'initCSaveFixFailed',
                    message: `${changes.length} values changed during cSave initialization`,
                    extras: {
                        numberOfChanges: changes.length,
                        examples: changes.slice(0, 5),
                        revision: _.get(serverStore, ['documentServicesModel', 'revision'])
                    }
                })
            )
        }
    }

    const applyAndUpdate = async (
        store: ServerStore,
        op: (store: ServerStore) => Promise<ServerStore>
    ): Promise<ServerStore> => {
        const removalCandidates = getRemovalCandidates(documentManager, store)
        const newStore = await op(store)
        addStoreToDal(documentManager, newStore, removalCandidates)
        removeDataFromDal(documentManager, removalCandidates)
        documentManager.dal.commitTransaction('dataFixers', true)
        return newStore
    }

    const createServerStore = async (
        serverStorePagesData: Record<string, any>,
        disableCommonConfig: boolean
    ): Promise<ServerStore> => {
        // Setup
        const serverStore = {
            ...serverStoreBase,
            pagesData: serverStorePagesData
        }
        // Load to DAL
        await initializeModels(serverStore, disableCommonConfig)

        _(serverStore.pagesData)
            .omit(['masterPage'])
            .forEach(page => movePageDataToMaster(page, serverStore.pagesData.masterPage))

        _.forEach(serverStore.pagesData, minorFixing)

        const initialDocument = convertStore(serverStore, logger)
        const storeLabel = 'create-store'

        if (documentManager.config.experimentInstance.isOpen('dm_mergeToApprovedStoreAsync')) {
            await documentManager.dal.mergeToApprovedStoreAsync(initialDocument, storeLabel)
        } else {
            documentManager.dal.mergeToApprovedStore(initialDocument, storeLabel)
        }

        const {snapshots} = documentManager.extensionAPI as SnapshotExtApi
        snapshots.takeSnapshot(SNAPSHOTS.DAL_INITIAL)
        return serverStore
    }

    /**
     * @param patchedStore
     * @returns {*}
     */

    const runFixers = async (patchedStore: ServerStore): Promise<ServerStore> => {
        const fixerParams = getDataFixersParams(patchedStore)

        const {dataFixerVersioning} = documentManager.extensionAPI as DataFixerVersioningApi

        const fixerVersions = {}
        const fixerChangesOnReruns = {}

        const fixPages = async (pagesData: Record<string, any>) => {
            const fixedPagesData = {}
            for (const [pageId, pageJson] of Object.entries(pagesData)) {
                // we await here to avoid blocking the event loop
                fixedPagesData[pageId] = await Promise.resolve(
                    dataFixer.fix({
                        ...fixerParams,
                        pageId: _.get(pageJson, ['structure', 'id'], 'masterPage'),
                        pageJson,
                        fixerVersions,
                        fixerChangesOnReruns,
                        captureError: logger.captureError
                    })
                )
            }

            return fixedPagesData
        }

        const fixedStore = await applyAndUpdate(patchedStore, async store => ({
            ...store,
            pagesData: await fixPages(patchedStore.pagesData)
        }))

        Object.keys(fixerVersions).forEach(pageId => {
            dataFixerVersioning.updatePageVersionData(pageId, fixerVersions[pageId])
        })

        dataFixerVersioning.reportFixerActions(FixerCategory.VIEWER, fixerChangesOnReruns)

        return fixedStore
    }

    const buildServerStore = async (): Promise<ServerStore> => {
        const pageListToLoad = buildPageList(pageList, partialPages)

        const pagesDataPromise = trackingAndInteraction(INTERACTIONS.FETCH_PAGES, async () =>
            fetchPagesFacade.fetchPages(fetchFn, pageListToLoad)
        )
        const [pagesData, rendererModel] = await Promise.all([
            pagesDataPromise,
            rendererModelBuilder.getRendererModel()
        ])

        serverStoreBase = {
            rendererModel,
            documentServicesModel,
            serviceTopology,
            orphanPermanentDataNodes: [] as any[],
            routers: rendererModel.routers,
            pagesPlatformApplications: rendererModel.pagesPlatformApplications
        } as ServerStore

        interactionEndedWithOptions(INTERACTIONS.LOAD_PAGE_PAYLOADS)

        const serverStore = await trackingAndInteraction(INTERACTIONS.CREATE_SERVER_STORE, async () =>
            createServerStore(pagesData, config.disableCommonConfig)
        )

        return serverStore
    }

    const initCSave = async (serverStore: ServerStore): Promise<ServerStore> => {
        const {snapshots} = documentManager.extensionAPI as SnapshotExtApi

        if (documentManager.config.experimentInstance.isOpen('dm_changedOnCsaveInit')) {
            interactionStartedWithOptions(INTERACTIONS.APPLY_TO_APPROVED_STORE)
            updateApprovedStoreBeforeCSaveAndVerifyNoChanges(serverStore)
            interactionEndedWithOptions(INTERACTIONS.APPLY_TO_APPROVED_STORE)
        }

        const {continuousSave} = documentManager.extensionAPI as CSaveApi
        // apply csave transactions and take csave snapshot
        const changedApplied = await trackingAndInteraction(INTERACTIONS.INIT_CSAVE, async () =>
            continuousSave.initCSave(partialPages)
        )

        if (!changedApplied) {
            return serverStore
        }

        // rebuild a server store (use site data immutable)
        const store = getSiteDataJson(
            snapshots.getLastSnapshotByTagName(extensions.continuousSave.CSAVE_TAG),
            config.origin,
            {
                withPagesData: true
            }
        ) as ServerStore
        store.rendererModel.pagesPlatformApplications = store.pagesPlatformApplications
        return store
    }

    const initCSaveIfNeeded = async (serverStore: ServerStore): Promise<ServerStore> => {
        const {continuousSave} = documentManager.extensionAPI as CSaveApi
        if (continuousSave && config.continuousSave) {
            return await initCSave(serverStore)
        }
        return serverStore
    }

    const initViews = async (views: ViewsAPI) => {
        await views.initViews()
    }

    const initViewsIfNeeded = async () => {
        const {views} = documentManager.extensionAPI as ViewsExtensionAPI
        if (views && config.cedit) {
            return trackingAndInteraction(INTERACTIONS.INIT_VIEWS, () => initViews(views))
        }
    }

    const main = async (): Promise<InitResult> => {
        interactionStartedWithOptions(INTERACTIONS.LOAD_PAGE_PAYLOADS)

        const serverStore = await buildServerStore()
        const newStorePending = initCSaveIfNeeded(serverStore)
        const views = initViewsIfNeeded()
        const resolvedStoredAndViews = await Promise.all([newStorePending, views])
        const newStore = resolvedStoredAndViews[0]
        interactionStartedWithOptions(INTERACTIONS.RUN_FIXERS)
        const fixedStore = await trackingAndInteraction(INTERACTIONS.RUN_VIEWER_FIXERS, async () => runFixers(newStore))
        await trackingAndInteraction(INTERACTIONS.RUN_MIGRATORS, async () =>
            dataMigrationRunner.runDataMigration(documentManager)
        )
        interactionEndedWithOptions(INTERACTIONS.RUN_FIXERS)

        // Applying multilingual overrides
        if (!config.keepMultiLingualModelsFromServer) {
            await trackingAndInteraction(INTERACTIONS.MULTILINGUAL_INIT, async () =>
                multilingualInitializer.initialize(documentManager)
            )
        }

        // Closing the transaction
        await trackingAndInteraction(INTERACTIONS.INIT_FINAL_COMMIT, async () =>
            documentManager.dal.commitTransaction('mainInitialization', true)
        )

        const fetchPagesToDal = (() => {
            const loadedPages = new Set(partialPages)
            return async (pageIds: string[]) => {
                const pageIdsToFetch = _.reject(pageIds, pageId => loadedPages.has(pageId))
                if (_.isEmpty(pageIdsToFetch)) {
                    return
                }

                _.forEach(pageIdsToFetch, pageId => loadedPages.add(pageId))
                const pageListToLoad = buildPageList(pageList, pageIdsToFetch)
                const rawPagesData = await fetchPagesFacade.fetchPages(fetchFn, pageListToLoad)
                const filteredPagesData = _.pickBy(
                    rawPagesData,
                    (value, pageId) => pageIdsToFetch.includes(pageId) || pageId === 'masterPage'
                )
                const store = {...serverStoreBase, pagesData: filteredPagesData}
                const changes = convertStore(store, logger)
                changes.forEach((p, v) => {
                    documentManager.dal.set(p, v)
                })
                await runFixers(store)
            }
        })()

        return {store: fixedStore, fetchPagesToDal}
    }

    return await main()
}

const initializeWithPreloadedStore = async (
    cachedStore: DalStore,
    {
        documentManager,
        partialPages,
        rendererModelBuilder,
        documentServicesModel,
        serviceTopology,
        logger,
        trackingFn = stubTrackingFn
    }: InitFromCacheParams
): Promise<InitResult> => {
    const options = {
        paramsOverrides: {}
    }
    const interactionStartedWithOptions = (name: string) => logger.interactionStarted(name, options)
    const interactionEndedWithOptions = (name: string) => logger.interactionEnded(name, options)
    async function trackingAndInteraction<T>(name: string, fn: (...args: any[]) => Promise<T>): Promise<T> {
        interactionStartedWithOptions(name)
        const returnValue: T = await trackingFn(name, async () => await fn())
        interactionEndedWithOptions(name)

        return returnValue
    }
    documentManager.dal.mergeToApprovedStore(createStore(cachedStore), 'create-store')
    const {continuousSave} = documentManager.extensionAPI as CSaveApi
    const {snapshots} = documentManager.extensionAPI as SnapshotExtApi

    snapshots.takeSnapshot(SNAPSHOTS.DAL_INITIAL)
    await trackingAndInteraction(INTERACTIONS.INIT_CSAVE, async () => continuousSave.initCSave(partialPages))
    await trackingAndInteraction(INTERACTIONS.INIT_FINAL_COMMIT, async () =>
        documentManager.dal.commitTransaction('mainInitialization', true)
    )

    const fetchPagesToDal = () => {
        throw new Error('fetchPagesToDal not implemented in server cache mode')
    }

    const rendererModel = await rendererModelBuilder.getRendererModel()

    return {
        fetchPagesToDal,
        store: {
            rendererModel,
            documentServicesModel,
            serviceTopology,
            pagesData: {},
            routers: rendererModel.routers,
            pagesPlatformApplications: rendererModel.pagesPlatformApplications,
            origin: ''
        }
    }
}

export {initialize, initializeWithPreloadedStore}
