import Long from 'long'
import type {ContinuousSaveServer} from './csaveTypes'
import type {Channel, WixInstanceProvider} from '../channelUtils/duplexer'
import {MockCSDuplexer} from './mockCSChannel'
import type {
    CreateRevisionRes,
    CreateTransactionRequest,
    CreateTransactionResponse,
    GetDocumentResponse,
    GetTransactionResponse,
    GetTransactionsResponse,
    SaveRequest
} from './serverProtocol'

const emptyInitialStore: GetDocumentResponse = {
    actions: []
}

export const nextTransactionId = (current: string): string => {
    const prev = Long.fromString(current) ?? Long.fromNumber(0)
    const plusOne = prev.add(1)
    return plusOne.toString()
}

class DocumentServer {
    private _last: Long = Long.fromString('0')

    get last(): string {
        return this._last.toString()
    }

    set last(val: string) {
        this._last = Long.fromString(val)
    }

    public getLastPlusOne(): string {
        return nextTransactionId(this.last)
    }
}

export class TestServer implements ContinuousSaveServer {
    public duplexer = new MockCSDuplexer()
    protected server = new DocumentServer()
    private nextTransaction?: GetTransactionResponse
    private nextTransactions?: GetTransactionsResponse = {
        transactions: []
    }
    private instanceProvider: WixInstanceProvider | null = null

    setNextTransaction(value?: GetTransactionResponse): void {
        this.nextTransaction = value
    }

    setNextTransactions(value?: GetTransactionsResponse): void {
        this.nextTransactions = value
    }

    constructor(private initialStore: GetDocumentResponse = emptyInitialStore, lastTxId: string = '0') {
        this.server.last = lastTxId
    }

    async onChannelReady(): Promise<void> {}

    setLast(val: string) {
        this.server.last = val
    }

    getLast(): string | undefined {
        return this.server.last
    }

    nextTxId(): string {
        return this.server.getLastPlusOne()
    }

    async createRevision(): Promise<CreateRevisionRes> {
        return {actions: [], siteRevision: {revision: 0, version: 0}}
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async save(payload: CreateTransactionRequest): Promise<CreateTransactionResponse> {
        const transactionId = this.server.getLastPlusOne()
        return {transactionId}
    }

    setInstanceProvider(provider: WixInstanceProvider) {
        this.instanceProvider = provider
    }

    getInstance(): string | null {
        // @ts-ignore
        return this.instanceProvider?.() ?? null
    }

    createDuplexer(): Channel {
        return this.duplexer
    }

    async deleteTransactions(): Promise<void> {}

    setInitialStore(store: GetDocumentResponse) {
        this.initialStore = store
    }

    async getStore(): Promise<GetDocumentResponse> {
        return this.initialStore
    }

    async getTransactions(): Promise<GetTransactionsResponse> {
        if (!this.nextTransactions) {
            throw new Error('No payload set')
        }
        return this.nextTransactions
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async asyncSave(payload: SaveRequest): Promise<void> {}

    async getTransaction(): Promise<GetTransactionResponse> {
        if (this.nextTransaction) {
            return this.nextTransaction
        }
        throw new Error('No payload set')
    }
}
