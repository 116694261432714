import type {Pointer, InnerPointer, PointerKeys, PointerAdditionalProperties} from '@wix/document-services-types'
import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'

const {getRepeaterTemplateId, isRepeatedComponent} = displayedOnlyStructureUtil

const getPointer = (id: string, type: string, additionalProperties?: Partial<PointerAdditionalProperties>): Pointer => {
    if (!id && !type) {
        console.log('illegal pointer creation !')
    }

    if (additionalProperties) {
        return {
            ...additionalProperties,
            id,
            type
        }
    }

    return {
        id,
        type
    }
}

const getPointerFromPointer = (newId: string, pointer: Pointer): Pointer => ({...pointer, id: newId})

/**
 * @param {string} id
 * @param {string} type
 * @returns {Pointer}
 */
const getPagePointer = (id: string, type: string): Pointer => ({id, type, pageId: id})

const getInnerPointer = (pointer: Pointer, innerPath: string | string[]): Pointer => {
    if (_.isString(innerPath)) {
        innerPath = innerPath.split('.')
    }

    const newPointer: Pointer = {
        id: pointer.id,
        type: pointer.type,
        noRefFallbacks: pointer.noRefFallbacks,
        innerPath: pointer.innerPath ? (pointer.innerPath as string[]).concat(innerPath) : innerPath
    }

    if (pointer.scope) {
        newPointer.scope = pointer.scope
    }

    return newPointer
}

const getInnerValue = (pointer: Pointer, rootValue: any): any => {
    if (_.isNil(rootValue) || !pointer.innerPath) {
        return rootValue
    }
    return _.get(rootValue, pointer.innerPath)
}

const getOriginalPointerFromInner = (pointer: Pointer): Pointer => getPointer(pointer.id, pointer.type)

const pointerKeys: PointerKeys = [
    'id',
    'type',
    'innerPath',
    'pageId',
    'useLanguage',
    'multilingual',
    'noRefFallbacks',
    'doNotSync',
    'variants',
    'scope'
]
const pointerKeysSet: Set<string> = new Set(pointerKeys)

const isPointer = (obj: any, isStrict: boolean = true) => {
    if (!obj?.id || !obj.type) {
        return false
    }

    if (!isStrict) {
        return true
    }

    return Object.keys(obj).every(key => pointerKeysSet.has(key))
}

type OptionalPointer = Pointer | null | undefined

const isSameComponent = (p1: OptionalPointer, p2: OptionalPointer, scopesHybridModeDisabled: boolean): boolean => {
    if (!p1 || !p2 || p1.type !== p2.type || p1.id !== p2.id || !_.isEqual(p1.innerPath, p2.innerPath)) {
        return false
    }

    if (!scopesHybridModeDisabled) {
        return true
    }

    if (!p1.scope && !p2.scope) {
        return true
    }

    return isSameComponent(p1.scope, p2.scope, scopesHybridModeDisabled)
}

const isSamePointer = (p1: OptionalPointer, p2: OptionalPointer, scopesHybridModeDisabled: boolean): boolean => {
    if (!p1 || !p2) {
        return false
    }

    if (scopesHybridModeDisabled) {
        return _.isEqual(p1, p2)
    }

    for (const pointerKey of pointerKeys) {
        if (pointerKey !== 'scope' && !_.isEqual(p1[pointerKey], p2[pointerKey])) {
            return false
        }
    }
    return isSameComponent(p1, p2, scopesHybridModeDisabled)
}

const getInnerPointerPathRoot = ({innerPath}: Pointer) => (innerPath ? innerPath[0] : null)

/**
 * @param {Pointer} pointer
 * @return {Pointer}
 */
const stripInnerPath = (pointer: Pointer): Pointer => {
    if (pointer.innerPath) {
        const ptr: Pointer = {id: pointer.id, type: pointer.type}
        for (const key in pointer) {
            if (key !== 'innerPath' && !ptr.hasOwnProperty(key)) {
                ptr[key] = pointer[key]
            }
        }
        return ptr
    }
    return pointer
}

const normalizeInnerPath = (innerPath?: string[] | string): string[] => {
    if (innerPath === undefined) {
        return []
    }
    if (Array.isArray(innerPath)) {
        return innerPath
    }
    return ([] as string[]).concat(innerPath)
}

/**
 * A utility for getting the inner path. This is to avoid multiple places that deal with normalization
 * @param pointer
 * @returns {string[]}
 */
const getInnerPath = (pointer: Pointer): string[] => normalizeInnerPath(pointer.innerPath)

/**
 * Checks if the pointer has an innerPath
 * @param pointer
 * @returns {boolean}
 */
const hasInnerPath = (pointer: Pointer): pointer is InnerPointer => getInnerPath(pointer).length > 0

/**
 * @param {Pointer} basePointer
 * @returns {Pointer}
 */
const getRepeatedItemPointerIfNeeded = (basePointer: Pointer): Pointer =>
    isRepeatedComponent(basePointer.id)
        ? getPointerFromPointer(getRepeaterTemplateId(basePointer.id), basePointer)
        : basePointer

/**
 * @param {Pointer} basePointer
 * @returns {Pointer}
 */
const signaturePath = ['metaData', 'sig']
const getSignaturePointer = (basePointer: Pointer): Pointer => getInnerPointer(basePointer, signaturePath)

const basedOnSignaturePath = ['metaData', 'basedOnSignature']
const getBasedOnSignaturePointer = (basePointer: Pointer): Pointer => getInnerPointer(basePointer, basedOnSignaturePath)

const getOverrideComponentPointer = (id: string, viewMode: string, queryName: string): Pointer | null => {
    if (!id.endsWith(queryName)) {
        return null
    }
    return getPointer(id.replace(new RegExp(`-${queryName}$`), ''), viewMode)
}

export {
    getPointer,
    getPagePointer,
    getInnerPointer,
    getInnerValue,
    getOriginalPointerFromInner,
    isPointer,
    isSameComponent,
    isSamePointer,
    getInnerPointerPathRoot,
    stripInnerPath,
    getInnerPath,
    hasInnerPath,
    normalizeInnerPath,
    getPointerFromPointer,
    getRepeatedItemPointerIfNeeded,
    getSignaturePointer,
    getBasedOnSignaturePointer,
    getOverrideComponentPointer
}
