import type {CreateExtArgs, DAL, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {Pointer, Pointers, WixCodeConnectionItem, IConnectionItem} from '@wix/document-services-types'
import _ from 'lodash'
import type {TPAExtensionAPI} from './tpa'
import type {ComponentsMetadataAPI} from './componentsMetadata/componentsMetadata'
import {getComponentIncludingDisplayOnly} from '../utils/dalUtils'
import {COMP_TYPES, DATA_TYPES} from '../constants/constants'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {DataModelExtensionAPI} from './dataModel'
import type {RelationshipsAPI} from './relationships'
const INVALID_ROLE = '*'
import {isRefPointer} from '../utils/refStructureUtils'

export type ValidateConnection = (
    connectionItem: ConnectionItemForValidation,
    otherConnectionItems: ConnectionItemForValidation[],
    componentRef: Pointer,
    controllerRef: Pointer
) => void

export type GetConnections = (compRef: Pointer) => IConnectionItem[] | null
export type UpdateConnectionsItem = (componentPointer: Pointer, connectionsItem: IConnectionItem[]) => string
export type CreateWixCodeConnectionItem = (nickname: string) => WixCodeConnectionItem
export interface ConnectionItemForValidation {
    isPrimary: boolean
    role: string
}

export interface ConnectionsAPI extends ExtensionAPI {
    connections: {
        validateConnection: ValidateConnection
        get: GetConnections
        updateConnectionsItem: UpdateConnectionsItem
        createWixCodeConnectionItem: CreateWixCodeConnectionItem
    }
}

const createExtension = (): Extension => {
    const validateConnection = (
        dal: DAL,
        pointers: Pointers,
        tpa: TPAExtensionAPI,
        componentsMetaData: ComponentsMetadataAPI,
        connectionItem: ConnectionItemForValidation,
        otherConnectionItems: ConnectionItemForValidation[],
        componentRef: Pointer,
        controllerRef: Pointer
    ): void => {
        const isOOIController = (compType: string) => {
            return tpa.tpa.isTpaByCompType(compType)
        }

        const controllerCompType = getComponentIncludingDisplayOnly(dal, controllerRef).componentType
        const connectedComponentType = getComponentIncludingDisplayOnly(dal, componentRef).componentType

        if (controllerCompType === COMP_TYPES.APP_WIDGET_TYPE) {
            if (!pointers.structure.isDescendant(componentRef, controllerRef)) {
                throw new Error('cannot connect to app widget from the outside')
            }
        } else if (controllerCompType !== COMP_TYPES.CONTROLLER_TYPE && !isOOIController(controllerCompType)) {
            throw new Error('controllerRef component type is invalid - should be a controller or current context')
        }

        if (isRefPointer(controllerRef)) {
            throw new Error('controllerRef component cannot be inflated component')
        }
        if (connectionItem.isPrimary && connectedComponentType === COMP_TYPES.CONTROLLER_TYPE) {
            throw new Error('cannot connect to another app controller with a primary connection')
        }
        if (connectionItem.role === INVALID_ROLE) {
            throw new Error('invalid connection role - cannot be *')
        }

        if (!componentsMetaData.componentsMetadata.canConnectToCode(componentRef)) {
            throw new Error('cannot connect to this component type')
        }

        if (connectionItem.isPrimary && _.some(otherConnectionItems, {isPrimary: true})) {
            throw new Error('Primary connection is already connected to the component')
        }
    }

    const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): ConnectionsAPI => {
        const tpa = extensionAPI as TPAExtensionAPI
        const componentsMetaData = extensionAPI as ComponentsMetadataAPI
        const getControllerRefFromId = (controllerDataId: string, compPointer: Pointer) => {
            const {relationships} = extensionAPI as RelationshipsAPI
            const pagePointer = pointers.structure.getPageOfComponent(compPointer)
            const dataPointer = pointers.data.getDataItem(controllerDataId, pagePointer.id)
            const owningReferences = relationships.getOwningReferencesToPointer(dataPointer, compPointer.type)
            if (owningReferences.length) {
                const component = owningReferences[0]

                if (
                    pointers.components.isSameComponent(pointers.structure.getPageOfComponent(component), pagePointer)
                ) {
                    return component
                }
            }
            return null
        }
        const getConnections = (componentPointer: Pointer): IConnectionItem[] | null => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const connectionsData = dataModel.components.getItem(componentPointer, 'connections')
            const connectionsItems = deepClone(_.get(connectionsData, ['items']))
            if (_.isEmpty(connectionsItems)) {
                return []
            }
            return _.map(connectionsItems, connectionItem => {
                if (connectionItem.type === 'WixCodeConnectionItem') {
                    return connectionItem
                }

                const controllerRef = getControllerRefFromId(connectionItem.controllerId, componentPointer)
                return _.assign({}, _.omit(connectionItem, 'controllerId'), {controllerRef})
            })
        }
        const createConnectionsItem = (connections: IConnectionItem[]) => {
            const connectionsListDataItem = dal.schema.createItemAccordingToSchema(
                'ConnectionList',
                DATA_TYPES.connections
            )
            connectionsListDataItem.items = connections
            return connectionsListDataItem
        }

        const updateConnectionsItem = (componentPointer: Pointer, connectionsItem: IConnectionItem[]): string => {
            const {relationships} = extensionAPI as RelationshipsAPI
            const {dataModel} = extensionAPI as DataModelExtensionAPI

            const component = dal.get(componentPointer)
            let connectionsId = relationships.getIdFromRef(_.get(component, ['connectionQuery']))
            const pageId = pointers.structure.getPageOfComponent(componentPointer).id

            const doesComponentHaveConnectionsData = Boolean(connectionsId)
            const itemToUpdate = createConnectionsItem(connectionsItem)
            const connectionsPointer = dataModel.addItem(itemToUpdate, DATA_TYPES.connections, pageId, connectionsId)
            connectionsId = connectionsPointer.id
            if (!doesComponentHaveConnectionsData) {
                dataModel.components.linkComponentToItemByTypeDesktopAndMobile(
                    componentPointer,
                    connectionsId,
                    DATA_TYPES.connections
                )
            }
            return connectionsId
        }

        const createWixCodeConnectionItem = (nickname: string): WixCodeConnectionItem => {
            return {
                type: 'WixCodeConnectionItem',
                role: nickname
            }
        }
        return {
            connections: {
                get: getConnections,
                updateConnectionsItem,
                createWixCodeConnectionItem,
                validateConnection: (
                    connectionItem: ConnectionItemForValidation,
                    otherConnectionItems: ConnectionItemForValidation[],
                    componentRef: Pointer,
                    controllerRef: Pointer
                ): void =>
                    validateConnection(
                        dal,
                        pointers,
                        tpa,
                        componentsMetaData,
                        connectionItem,
                        otherConnectionItems,
                        componentRef,
                        controllerRef
                    )
            }
        }
    }

    return {
        name: 'connections',
        dependencies: new Set(['structure', 'tpa', 'componentsMetadata']),
        createExtensionAPI
    }
}

export {createExtension}
