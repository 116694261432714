import {pointerUtils} from '@wix/document-manager-core'
import type {ViewerManager} from '@wix/viewer-manager-adapter'
import _ from 'lodash'
import {getAdditionalComps} from './lockedCompsForEnforceAnchors'
import type {SetOperationsQueueBatch} from './SOQBatch'
import {ENFORCE_TYPES} from './SOQConstants'
import type {QItemParams} from './SOQItem'

const {getPointer} = pointerUtils

function getBatchedLockedComp(batch: SetOperationsQueueBatch): any[] {
    return _.reduce(
        batch.activeItems,
        (acc: any[], item: QItemParams) => {
            if (item.lockedComp) {
                acc.push(item.lockedComp)
            }
            return acc
        },
        []
    )
}

function handleAnchorFlags(batchItems: QItemParams[], viewerManager: ViewerManager) {
    const firstItemThatCaresAboutLayout = _.find(batchItems, item => item.enforceType !== ENFORCE_TYPES.DONT_CARE)

    const typeToEnforce = _.get(firstItemThatCaresAboutLayout, ['enforceType'], ENFORCE_TYPES.DONT_CARE)
    const isGenerating = typeToEnforce !== ENFORCE_TYPES.FULL
    const isEnforcing = typeToEnforce !== ENFORCE_TYPES.NONE
    const shouldForceAnchorsGeneration = _.some(batchItems, {forceAnchorsGeneration: true})
    if (shouldForceAnchorsGeneration) {
        viewerManager.viewerSiteAPI.forceGenerateAnchors()
    }
    viewerManager.viewerSiteAPI.setIsGeneratingAnchors(isGenerating)
    viewerManager.viewerSiteAPI.setIsEnforcingAnchors(isEnforcing)
}

export async function flushBatchAnchorsLayout(
    batch: SetOperationsQueueBatch,
    viewerManager: ViewerManager,
    flushBatch: (batch: SetOperationsQueueBatch) => Promise<void>
) {
    if (viewerManager.viewerSiteAPI.getLayoutMechanism() !== 'anchors') {
        await flushBatch(batch)
        return
    }
    const lockedComps = getBatchedLockedComp(batch)
    const hasLockedComps = !_.isEmpty(lockedComps)
    const viewMode = viewerManager.viewerSiteAPI.getViewMode()
    if (hasLockedComps) {
        const lock = lockedComps.map(compId => getAdditionalComps(getPointer(compId, viewMode), viewerManager.dal))
        // @ts-ignore
        const lockedCompsForEnforceAnchors = _.assign(...lock)

        viewerManager.viewerSiteAPI.setLockedCompsForEnforceAnchors(lockedCompsForEnforceAnchors)
    }

    // @ts-ignore
    handleAnchorFlags(batch.activeItems, viewerManager)

    const shouldForceFullLayout = _.some(batch.activeItems, {needsLayoutOnAllComps: true})
    if (shouldForceFullLayout) {
        viewerManager.viewerSiteAPI.setFullLayoutNeeded(true)
    }
    await flushBatch(batch)
    viewerManager.viewerSiteAPI.setLockedCompsForEnforceAnchors({})
    viewerManager.viewerSiteAPI.setIsGeneratingAnchors(true)
    viewerManager.viewerSiteAPI.setIsEnforcingAnchors(true)
}
