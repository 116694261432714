import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../component/component'
import structure from '../structure/structure'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import multiComponentsUtilsValidations from './multiComponentsUtilsValidations'
import constants from '../constants/constants'

function getSnugLayoutFromLayoutsArray(layouts) {
    let mostLeft,
        mostRight,
        mostTop,
        mostBottom,
        mostTopBounding,
        mostRightBounding,
        mostLeftBounding,
        mostBottomBounding
    mostLeft = mostTop = mostTopBounding = mostLeftBounding = Number.MAX_VALUE
    mostRight = mostBottom = mostBottomBounding = mostRightBounding = -Number.MAX_VALUE

    _.forEach(layouts, function (compLayout) {
        mostLeft = Math.min(mostLeft, compLayout.x)
        mostRight = Math.max(mostRight, compLayout.x + compLayout.width)
        mostTop = Math.min(mostTop, compLayout.y)
        mostBottom = Math.max(mostBottom, compLayout.y + compLayout.height)

        mostLeftBounding = Math.min(mostLeftBounding, compLayout.bounding.x)
        mostRightBounding = Math.max(mostRightBounding, compLayout.bounding.x + compLayout.bounding.width)
        mostTopBounding = Math.min(mostTopBounding, compLayout.bounding.y)
        mostBottomBounding = Math.max(mostBottomBounding, compLayout.bounding.y + compLayout.bounding.height)
    })

    return {
        bounding: {
            x: mostLeftBounding,
            y: mostTopBounding,
            width: mostRightBounding - mostLeftBounding,
            height: mostBottomBounding - mostTopBounding
        },
        x: mostLeftBounding,
        y: mostTopBounding,
        width: mostRightBounding - mostLeftBounding,
        height: mostBottomBounding - mostTopBounding,
        rotationInDegrees: 0
    }
}

function getSnugFromPositionAndSizeArray(compsPositionAndSize) {
    let mostLeft, mostRight, mostTop, mostBottom

    mostLeft = mostTop = Number.MAX_VALUE
    mostRight = mostBottom = -Number.MAX_VALUE

    _.forEach(compsPositionAndSize, function (compPositionAndSize) {
        mostLeft = Math.min(mostLeft, compPositionAndSize.x)
        mostRight = Math.max(mostRight, compPositionAndSize.x + compPositionAndSize.width)
        mostTop = Math.min(mostTop, compPositionAndSize.y)
        mostBottom = Math.max(mostBottom, compPositionAndSize.y + compPositionAndSize.height)
    })

    return {
        x: mostLeft,
        y: mostTop,
        width: mostRight - mostLeft,
        height: mostBottom - mostTop
    }
}

function getSnugPositionAndSize(ps: PS, compPointers: Pointer[]) {
    if (_.isEmpty(compPointers)) {
        return undefined
    }
    const compsPositionAndSize = _.map(compPointers, compPointer => structure.getPositionAndSize(ps, compPointer))
    return getSnugFromPositionAndSizeArray(compsPositionAndSize)
}

function getSnugLayout(ps: PS, compPointers?: Pointer[]) {
    if (_.isEmpty(compPointers)) {
        return undefined
    }
    const layouts = _.map(compPointers, compPointer => component.layout.get(ps, compPointer))
    return getSnugLayoutFromLayoutsArray(layouts)
}

function getSnugLayoutRelativeToScreen(ps: PS, compPointers?: Pointer[]) {
    if (_.isEmpty(compPointers)) {
        return undefined
    }
    const layouts = _.map(compPointers, compPointer => structure.getCompLayoutRelativeToScreen(ps, compPointer))
    return getSnugLayoutFromLayoutsArray(layouts)
}

function getSnugLayoutRelativeToScreenConsideringScroll(ps: PS, compPointers: Pointer[], ignorePlugins) {
    if (_.isEmpty(compPointers)) {
        return undefined
    }
    const layouts = _.map(compPointers, compPointer =>
        structure.getCompLayoutRelativeToScreenConsideringScroll(ps, compPointer, ignorePlugins)
    )
    return getSnugLayoutFromLayoutsArray(layouts)
}

function getSnugLayoutRelativeToStructure(ps: PS, compPointers?: Pointer[]) {
    if (_.isEmpty(compPointers)) {
        return undefined
    }
    const layouts = _.map(compPointers, compPointer => structure.getCompLayoutRelativeToStructure(ps, compPointer))
    return getSnugLayoutFromLayoutsArray(layouts)
}

function isMinBy(axis, ps: PS, compPointer: Pointer, compPointerArray) {
    if (!compPointer) {
        return false
    }
    const compCoord = component.layout.get(ps, compPointer).bounding[axis]
    if (_.isUndefined(compCoord)) {
        return false
    }

    compPointerArray = compPointerArray || component.getSiblings(ps, compPointer)

    return _.every(compPointerArray, function (otherCompPointer) {
        if (compPointer.id === otherCompPointer.id) {
            return true
        }
        const otherCoord = component.layout.get(ps, otherCompPointer).bounding[axis]
        if (_.isUndefined(otherCoord)) {
            return true
        }
        return compCoord <= otherCoord
    })
}

const isTopMost = _.partial(isMinBy, 'y')
const isLeftMost = _.partial(isMinBy, 'x')

function getFullWidthLayoutRelativeToScreenForAlignmentCalculations(ps: PS, compPointer: Pointer) {
    const compLayout = structure.getCompLayoutRelativeToScreen(ps, compPointer)
    //we use pages container so that we have a valid x relative to the screen.
    //we can also use getSiteWidth and getSiteX, but getSiteX returns a negative number, so we would need to use Math.abs(siteX) which isn't necessarily better..
    const relativeToScreenSiteLayout = structure.getCompLayoutRelativeToScreen(
        ps,
        ps.pointers.components.getPagesContainer(constants.VIEW_MODES.DESKTOP)
    )
    return {
        x: relativeToScreenSiteLayout.x,
        width: relativeToScreenSiteLayout.width,
        y: compLayout.y,
        height: compLayout.height,
        bounding: {
            x: relativeToScreenSiteLayout.x,
            width: relativeToScreenSiteLayout.width,
            y: compLayout.y,
            height: compLayout.height
        }
    }
}

function getSnugLayoutRelativeToScreenForAlignmentCalculations(ps: PS, compPointers: Pointer[]) {
    if (_.isEmpty(compPointers)) {
        return undefined
    }

    const layouts = _.map(compPointers, function (compPointer) {
        if (structure.isHorizontallyStretchedToScreen(ps, compPointer)) {
            return getFullWidthLayoutRelativeToScreenForAlignmentCalculations(ps, compPointer)
        }
        return structure.getCompLayoutRelativeToScreen(ps, compPointer)
    })

    return getSnugLayoutFromLayoutsArray(layouts)
}

function align(ps: PS, compPointerArray, alignment) {
    if (multiComponentsUtilsValidations.canAlign(ps, compPointerArray, alignment)) {
        const compsToAlign = multiComponentsUtilsValidations.getFilteredComponentsToApplyAction(
            ps,
            compPointerArray,
            alignment
        )
        const snugLayoutRelativeToScreen = getSnugLayoutRelativeToScreenForAlignmentCalculations(ps, compPointerArray)
        alignComponentsToOuterLayout(ps, compsToAlign, snugLayoutRelativeToScreen, alignment, false)
    }
}

function alignComponentsToOuterLayout(ps: PS, compPointerArray, outerLayout, alignment, relativeToOuterLayout) {
    _.forEach(compPointerArray, function (compPointer) {
        const compLayoutRelativeToScreen = structure.getCompLayoutRelativeToScreen(ps, compPointer, true)
        const compContainer = component.getContainer(ps, compPointer)
        const compContainerLayoutRelativeToScreen = structure.getCompLayoutRelativeToScreen(ps, compContainer, true)

        const updateProps: any = {}
        switch (alignment) {
            case constants.COMP_ALIGNMENT_OPTIONS.LEFT:
                updateProps.x = 0
                break
            case constants.COMP_ALIGNMENT_OPTIONS.RIGHT:
                updateProps.x = outerLayout.width - compLayoutRelativeToScreen.bounding.width
                break
            case constants.COMP_ALIGNMENT_OPTIONS.TOP:
                updateProps.y = 0
                break
            case constants.COMP_ALIGNMENT_OPTIONS.BOTTOM:
                updateProps.y = outerLayout.height - compLayoutRelativeToScreen.bounding.height
                break
            case constants.COMP_ALIGNMENT_OPTIONS.CENTER:
                updateProps.x = 0.5 * (outerLayout.width - compLayoutRelativeToScreen.bounding.width)
                break
            case constants.COMP_ALIGNMENT_OPTIONS.MIDDLE:
                updateProps.y = 0.5 * (outerLayout.height - compLayoutRelativeToScreen.bounding.height)
                break
            default:
                break
        }

        if (!relativeToOuterLayout) {
            if (updateProps.x !== undefined) {
                updateProps.x += outerLayout.x - compContainerLayoutRelativeToScreen.x
            }
            if (updateProps.y !== undefined) {
                updateProps.y += outerLayout.y - compContainerLayoutRelativeToScreen.y
            }
        }

        const props = _.keys(updateProps)
        _.forEach(props, function (prop) {
            updateProps[prop] = getLayoutValueFromBoundingValue(compLayoutRelativeToScreen, prop, updateProps[prop])
        })

        structure.updateCompLayout(ps, compPointer, updateProps)
    })
}

function matchSize(ps: PS, compPointerArray, matchSizeValue) {
    if (multiComponentsUtilsValidations.canMatchSize(ps, compPointerArray, matchSizeValue)) {
        const compsToMatch = multiComponentsUtilsValidations.getFilteredComponentsToApplyAction(
            ps,
            compPointerArray,
            matchSizeValue
        )
        const compLayoutsArr = _.map(compsToMatch, function (compPointer) {
            return component.layout.get(ps, compPointer)
        })
        const averageWidth = _.sumBy(compLayoutsArr, 'width') / compLayoutsArr.length
        const averageHeight = _.sumBy(compLayoutsArr, 'height') / compLayoutsArr.length

        if (averageWidth && (matchSizeValue === 'width' || matchSizeValue === 'heightAndWidth')) {
            _.forEach(compsToMatch, matchComponentSize.bind(this, ps, 'width', averageWidth))
        }

        if (averageHeight && (matchSizeValue === 'height' || matchSizeValue === 'heightAndWidth')) {
            _.forEach(compsToMatch, matchComponentSize.bind(this, ps, 'height', averageHeight))
        }
    }
}

/**
 * Match a component layout to the average sizes passed to the function.
 * Originally this function did not support keeping a component aspect ratio
 * if this component declared it keeps aspect ratio.
 * Current implementation will also change the corresponding dimension if aspect ratio
 * should be kept (width or height)
 * @param {ps} ps
 * @param {string} dimension width or height
 * @param {number} average
 * @param {Pointer} compPointer
 */
function matchComponentSize(ps: PS, dimension: string, average: number, compPointer: Pointer) {
    const isProportionalResize = componentsMetaData.public.resizeOnlyProportionally(ps, compPointer)
    const proportionsStructure = structure.getProportionStructure(ps, compPointer)
    const newLayout = {}
    newLayout[dimension] = average
    if (isProportionalResize) {
        structure.updateAndPreserveProportions(ps, newLayout as any, proportionsStructure, true)
    } else {
        structure.updateCompLayout(ps, compPointer, newLayout)
    }
}

function getLayoutValueFromBoundingValue(compLayout, key: string, boundingValue) {
    return compLayout[key] + boundingValue - compLayout.bounding[key]
}

function distribute(ps: PS, compPointerArray, distribution) {
    let compLayoutsRelativeToScreen, snugLayoutRelativeToScreen
    if (multiComponentsUtilsValidations.canDistribute(ps, compPointerArray, distribution)) {
        const compsToApplyDistribute = multiComponentsUtilsValidations.getFilteredComponentsToApplyAction(
            ps,
            compPointerArray,
            distribution
        )
        let spaceBetweenComps, sortedCompLayoutRelativeToScreenMap
        const compLayoutRelativeToScreenMap = _.map(compsToApplyDistribute, function (compPointer) {
            const compContainer = component.getContainer(ps, compPointer)
            return {
                compPointer,
                layoutRelativeToScreen: structure.getCompLayoutRelativeToScreen(ps, compPointer, true),
                compContainerLayoutRelativeToScreen: structure.getCompLayoutRelativeToScreen(ps, compContainer, true)
            }
        })

        if (distribution === 'horizontal' || distribution === 'verticalAndHorizontal') {
            compLayoutsRelativeToScreen = _.map(compLayoutRelativeToScreenMap, 'layoutRelativeToScreen')
            snugLayoutRelativeToScreen = getSnugLayoutFromLayoutsArray(compLayoutsRelativeToScreen)
            const totalCompsWidth = _.sumBy(compLayoutsRelativeToScreen, 'bounding.width')
            spaceBetweenComps =
                (snugLayoutRelativeToScreen.width - totalCompsWidth) / (compLayoutsRelativeToScreen.length - 1)
            let nextXRelativeToSnug = 0
            sortedCompLayoutRelativeToScreenMap = _.sortBy(
                compLayoutRelativeToScreenMap,
                'layoutRelativeToScreen.bounding.x'
            )

            _.forEach(sortedCompLayoutRelativeToScreenMap, function (compObject) {
                const newBoundingX =
                    nextXRelativeToSnug +
                    (snugLayoutRelativeToScreen.x - compObject.compContainerLayoutRelativeToScreen.x)
                nextXRelativeToSnug += compObject.layoutRelativeToScreen.bounding.width + spaceBetweenComps
                const newLayoutX = getLayoutValueFromBoundingValue(compObject.layoutRelativeToScreen, 'x', newBoundingX)
                structure.updateCompLayout(ps, compObject.compPointer, {x: newLayoutX})
            })
        }

        if (distribution === 'vertical' || distribution === 'verticalAndHorizontal') {
            compLayoutsRelativeToScreen = _.map(compLayoutRelativeToScreenMap, 'layoutRelativeToScreen')
            snugLayoutRelativeToScreen = getSnugLayoutFromLayoutsArray(compLayoutsRelativeToScreen)
            const totalCompsHeight = _.sumBy(compLayoutRelativeToScreenMap, 'layoutRelativeToScreen.bounding.height')
            spaceBetweenComps =
                (snugLayoutRelativeToScreen.height - totalCompsHeight) / (compLayoutsRelativeToScreen.length - 1)
            let nextYRelativeToSnug = 0
            sortedCompLayoutRelativeToScreenMap = _.sortBy(
                compLayoutRelativeToScreenMap,
                'layoutRelativeToScreen.bounding.y'
            )

            _.forEach(sortedCompLayoutRelativeToScreenMap, function (compObject) {
                const newBoundingY =
                    nextYRelativeToSnug +
                    (snugLayoutRelativeToScreen.y - compObject.compContainerLayoutRelativeToScreen.y)
                nextYRelativeToSnug += compObject.layoutRelativeToScreen.bounding.height + spaceBetweenComps
                const newLayoutY = getLayoutValueFromBoundingValue(compObject.layoutRelativeToScreen, 'y', newBoundingY)
                structure.updateCompLayout(ps, compObject.compPointer, {y: newLayoutY})
            })
        }
    }
}

function sortCompsByZOrder(ps: PS, compPointerArray, isDescending?: boolean) {
    if (compPointerArray.length === 0) {
        return []
    }

    const container = component.getContainer(ps, compPointerArray[0])
    const allChildrenSortedAsc = component.getChildren(ps, container)

    const collectComponentsByZOrder = function (sortedComps, currentComp) {
        if (_.some(compPointerArray, currentComp)) {
            sortedComps.push(currentComp)
        }

        return sortedComps
    }

    if (isDescending) {
        return _.reduceRight(allChildrenSortedAsc, collectComponentsByZOrder, [])
    }

    return _.reduce(allChildrenSortedAsc, collectComponentsByZOrder, [])
}

export default {
    getSnugPositionAndSize,
    getSnugLayout,
    getSnugLayoutRelativeToStructure,
    getSnugLayoutRelativeToScreen,
    getSnugLayoutRelativeToScreenConsideringScroll,
    isTopMost,
    isLeftMost,
    alignComponentsToOuterLayout,
    sortComponentsByZOrder: sortCompsByZOrder,
    distribute,
    matchSize,
    align
}
