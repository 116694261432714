import _ from 'lodash'
import appStudioPresets from './appStudioPresets'
import appStudioDataModel from './appStudioDataModel'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import type {Pointer, PS} from '@wix/document-services-types'

const getOriginalPageIdFromIdMap = (ps: PS, newPageId: string, oldToNewIdMap) => {
    const isPage = (compId: string) => {
        const compRef = componentDetectorAPI.getComponentById(ps, compId)
        return compRef && ps.pointers.components.isPage(compRef)
    }
    return _.findKey(oldToNewIdMap, (newId, oldId) => newId === newPageId && isPage(oldId))
}

const createPresetsForDuplicatedWidget = (ps: PS, pagePointer: Pointer, pageDefinition, optionalCustomId, mappers) => {
    const originalPageId = getOriginalPageIdFromIdMap(ps, pagePointer.id, mappers?.oldToNewIdMap)
    const originalWidget = originalPageId && appStudioDataModel.findWidgetByPageId(ps, originalPageId)

    if (originalWidget) {
        const originalPresets = appStudioPresets.getWidgetPresets(ps, originalWidget.pointer)
        const newPresets = _.map(originalPresets, originalPreset => {
            const originalVariantId = appStudioPresets.getPresetVariantId(ps, originalPreset.pointer)
            const newVariantId = mappers.oldToNewIdMap[originalVariantId]

            const newPresetPointer = appStudioDataModel.getNewDataItemPointer(ps)
            const newPresetData = appStudioPresets.createDuplicatedPresetData(
                ps,
                newPresetPointer,
                originalPreset.pointer,
                originalPreset.name,
                newVariantId
            )
            appStudioDataModel.setData(ps, newPresetPointer, newPresetData)

            return newPresetPointer
        })
        if (!_.isEmpty(newPresets)) {
            const widgetPointer = _.get(appStudioDataModel.findWidgetByPageId(ps, pagePointer.id), 'pointer')
            appStudioPresets.addPresetsToWidgetDataItem(ps, newPresets, widgetPointer)
        }
    }
}

export default {
    createPresetsForDuplicatedWidget
}
