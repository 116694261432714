import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'

/******************************* Public Functions  *************************************/

function getAllRouters(ps: PS) {
    return ps.extensionAPI.routers.getAllRouters()
}

function getRoutersForApp(ps: PS, appDefinitionId) {
    return _.filter(getAllRouters(ps), {appDefinitionId})
}

function getRouterByRef(ps: PS, routerPtr: Pointer) {
    const routerData = ps.dal.get(routerPtr)
    if (routerData?.config) {
        routerData.config = JSON.parse(routerData.config)
        routerData.id = getRouterIdByPrefix(ps, routerData.prefix)
    }
    return routerData || null
}

function getRouterByPage(ps: PS, pagePtr: Pointer) {
    const pageId = _.get(ps.dal.get(pagePtr), 'id')
    const allRouters = getAllRouters(ps)
    const routerId = _.findKey(allRouters, router => router.pages && _.includes(_.values(router.pages), pageId))
    return routerId && ps.pointers.routers.getRouterPointer(routerId)
}

function getRouterIdByPrefix(ps: PS, routerPrefix: string) {
    const allRouters = getAllRouters(ps)
    return _.findKey(allRouters, {prefix: routerPrefix})
}

function getRouterByPrefix(ps: PS, routerPrefix: string) {
    const routerId = getRouterIdByPrefix(ps, routerPrefix)
    return routerId && ps.pointers.routers.getRouterPointer(routerId)
}

function getRouterById(ps: PS, routerId) {
    const routerPointer = ps.pointers.routers.getRouterPointer(routerId)
    return ps.dal.get(routerPointer)
}

function getRouterDataForPageIfExist(ps: PS, pageId: string) {
    pageId = _.startsWith(pageId, '#') ? pageId.substr(1) : pageId
    const allRouters = getAllRouters(ps)
    const routerId = _.findKey(allRouters, router => router.pages && _.includes(_.values(router.pages), pageId))

    return routerId ? _.assign(getRouterById(ps, routerId), {routerId}) : null
}

export default {
    get: {
        all: getAllRouters,
        byApp: getRoutersForApp,
        byRef: getRouterByRef,
        byId: getRouterById,
        byPrefix: getRouterByPrefix,
        byPage: getRouterByPage
    },
    getRouterDataForPageIfExist
}
