import {log} from '@wix/santa-core-utils'
import ajaxLibrary from './src/core/ajaxLibrary'
import compAlignmentUtils from './src/core/compAlignmentUtils'
import coreTpaUtils from './src/core/coreTpaUtils'
import flatStructureUtil from './src/core/flatStructureUtil'
import languages from './src/core/languages'
import layoutUtils from './src/core/layoutUtils'
import mobxDataHandlers from './src/core/mobxDataHandlers'
import multilingual from './src/core/multilingual'
import positionAndSize from './src/core/positionAndSize'
import sessionFreezer from './src/core/SessionFreezer'
import SiteDataPrivates from './src/core/SiteDataPrivates'
import warmupMenuUtils from './src/core/warmupMenuUtils'
import siteDataUtils from './src/dal/siteDataUtils'
import DALFactory from './src/dal/DALFactory'
import {loggingUtils} from './src/loggingUtils/loggingUtils'
import pointers from './src/pointers/pointers'
import siteStructureUtils from './src/siteData/siteStructureUtils'
import wixUrlParser from './src/wixUrlParser/wixUrlParser'
import dataRefsMap from './src/siteData/dataRefsMap'
import tpaWarmup from './src/tpa/tpaWarmup'
import requestsUtil from './src/core/requestsUtil'

export * from './src/core/ajaxLibrary'

const dataRefs = dataRefsMap

export {
    requestsUtil,
    log,
    flatStructureUtil,
    layoutUtils,
    dataRefs,
    ajaxLibrary,
    compAlignmentUtils,
    coreTpaUtils,
    DALFactory,
    languages,
    loggingUtils,
    mobxDataHandlers,
    multilingual,
    pointers,
    positionAndSize,
    sessionFreezer,
    SiteDataPrivates,
    siteDataUtils,
    siteStructureUtils,
    warmupMenuUtils,
    wixUrlParser,
    tpaWarmup
}
