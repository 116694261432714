import _ from 'lodash'
import componentStylesAndSkinsAPI from '../../../component/componentStylesAndSkinsAPI'
import theme from '../../../theme/theme'
import constants from '../../../constants/constants'
import mobileUtil from '../../../mobileUtilities/mobileUtilities'
import documentServicesSchemas from 'document-services-schemas'
import type {Pointer, PS} from '@wix/document-services-types'

const pageSystemStyles =
    documentServicesSchemas.services.schemasService.getDefinition('mobile.core.components.Page').styles

const setDefaultStyle = (ps: PS, pagePtr: Pointer) => {
    const styleId = _.keys(pageSystemStyles).sort()[0]
    const stylePointer = ps.pointers.data.getThemeItem(styleId, constants.MASTER_PAGE_ID)
    if (!ps.dal.isExist(stylePointer)) {
        const skin = pageSystemStyles[styleId]
        theme.styles.createAndAddStyleItem(
            ps,
            {styleType: constants.STYLES.TYPES.SYSTEM, skin},
            styleId,
            constants.MASTER_PAGE_ID
        )
    }
    componentStylesAndSkinsAPI.style.setId(ps, pagePtr, styleId)
}

const isStyleExist = (ps: PS, styleId: string, pageId: string) =>
    ps.dal.isExist(ps.pointers.data.getThemeItem(styleId, pageId)) ||
    ps.dal.isExist(ps.pointers.data.getThemeItem(styleId, constants.MASTER_PAGE_ID))

function exec(ps: PS) {
    const allPageIds = ps.siteAPI.getAllPagesIds(true)
    const viewModes = mobileUtil.getSupportedViewModes(ps)
    _.forEach(allPageIds, pageId => {
        _.forEach(viewModes, viewMode => {
            const pagePtr = ps.pointers.components.getPage(pageId, viewMode)
            const pageStyleId = componentStylesAndSkinsAPI.style.getId(ps, pagePtr)
            if (pageStyleId && !isStyleExist(ps, pageStyleId, pageId)) {
                setDefaultStyle(ps, pagePtr)
            }
        })
    })
}

export default {
    exec,
    name: 'addMissingPageStyleFixer',
    version: 1
}
