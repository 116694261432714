import type {Pointer} from '@wix/document-services-types'
import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'

import type {DataModelExtensionAPI} from './dataModel'

export interface InternalRefApi extends ExtensionAPI {
    getReferredComponent(ptr: Pointer): null | any
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({extensionAPI, dal}: CreateExtArgs): ExtensionAPI => {
        const dataModel = () => (extensionAPI as DataModelExtensionAPI).dataModel

        const getReferredComponent = (ptr: Pointer) => {
            const component = dal.get(ptr)
            if (component.type !== 'RefComponent' || !component.dataQuery) {
                return null
            }
            const data = dataModel().components.getItem(ptr, 'data')
            if (data.type !== 'InternalRef' || !data.rootCompId) {
                return null
            }

            return dal.get({id: data.rootCompId, type: 'DESKTOP'})
        }

        return {internalRef: {getReferredComponent}}
    }
    return {
        name: 'internalRef',
        createExtensionAPI
    }
}

export {createExtension}
