/* eslint-disable promise/prefer-await-to-then */
import {getReportableFromError} from '@wix/document-manager-utils'
import type {Callback1, PS} from '@wix/document-services-types'
import {ajaxLibrary} from '@wix/santa-ds-libs/src/warmupUtils'
import experiment from 'experiment-amd'
import _ from 'lodash'
import dsConstants from '../../constants/constants'
import editorServerFacade from '../../editorServerFacade/editorServerFacade'
import constants from '../../platform/common/constants'
import platform from '../../platform/platform'
import platformStateService from '../../platform/services/platformStateService'
import clientSpecMapMetaData from '../../siteMetadata/clientSpecMap'
import metaData from '../../siteMetadata/siteMetadata'
import callbackUtils from '../../utils/callbackUtils'
import {contextAdapter} from '../../utils/contextAdapter'
import {getMetaSiteId} from '../../utils/dalUtil'
import * as visitableData from '../../utils/visitableData'
import ProvisionUrlBuilder from '../utils/ProvisionUrlBuilder'
import provisionUtils from '../utils/provisionUtils'
import queue from '../utils/queue'
import tpaUtils from '../utils/tpaUtils'
import clientSpecMapService from './clientSpecMapService'
import installedTpaAppsOnSiteService from './installedTpaAppsOnSiteService'
import pendingAppsService from './pendingAppsService'

const {getOnSuccessWithFallback} = callbackUtils
const {createQueue} = queue

type TemplateExecutor = (data: Record<string, any>) => string

const settleTemplate = _.template(
    '<%= provisionServerBaseUrl %>/editor/settle/<%= metaSiteId %>?context=<%= context %>&editorSessionId=<%= editorSessionId %>'
)

const provisionAppFromSourceTemplateUrlTemplate = _.template(
    '<%= provisionServerBaseUrl %>/editor/provision-from-source-template/<%= metaSiteId %>?appDefId=<%= appDefinitionId %>&sourceTemplateId=<%= sourceTemplateId %>'
)

const context = {
    save: 'save',
    load: 'load',
    firstSave: 'firstSave'
}

const provisionAppFromSourceTemplate = function (
    ps: PS,
    appDefinitionId: string,
    sourceTemplateId: string,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) {
    if (!tpaUtils.isSiteSaved(ps)) {
        onError({success: false})
    } else {
        provisionAppFromSourceTemplateImp(ps, appDefinitionId, sourceTemplateId, onSuccess, onError)
    }
}

const provisionAppFromSourceTemplateImp = function (
    ps: PS,
    appDefinitionId: string,
    sourceTemplateId: string,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) {
    const metaSiteId = metaData.getProperty(ps, metaData.PROPERTY_NAMES.META_SITE_ID)
    const pointer = ps.pointers.general.getServiceTopology()
    const provisionServerTopology = ps.dal.get(ps.pointers.getInnerPointer(pointer, 'appStoreUrl'))

    const baseUrl = provisionAppFromSourceTemplateUrlTemplate({
        provisionServerBaseUrl: provisionServerTopology,
        metaSiteId,
        appDefinitionId,
        sourceTemplateId
    })
    // @ts-expect-error
    const url = new ProvisionUrlBuilder(baseUrl).addAcceptJson().build()

    doRequest(
        ps,
        url,
        'POST',
        {},
        getOnSuccessWithFallback('provisionAppFromSourceTemplateImp', onError, response => {
            ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
                if (tpaUtils.isTpaByAppType(response.payload.type)) {
                    provisionUtils.cacheAppMarketDataAfterProvision(ps, response.payload)
                }
            })
            onSuccess(response.payload)
        }),
        onError
    )
}

const getSettleActionsForFullSave = (
    {currentPagesVisitable, clientSpecMap, routersConfigMap, semanticAppVersions, appsState},
    shouldAvoidRevoking = false
) => {
    const filteredClientSpecMap = clientSpecMapService.filterApps(clientSpecMap)
    const actions = getSettleActionsFromAllPages(
        currentPagesVisitable,
        filteredClientSpecMap,
        routersConfigMap,
        semanticAppVersions,
        appsState,
        shouldAvoidRevoking
    )
    contextAdapter.utils.fedopsLogger.interactionStarted(
        dsConstants.PLATFORM_INTERACTIONS.SETTLE_ACTIONS,
        getInteractionsParams(actions)
    )
    return actions
}

const getProvisionAction = (clientSpecMap, appDefinitionId: string, version, sourceTemplateId: string) => {
    const existingApp = _.find(clientSpecMap, {appDefinitionId})

    if (experiment.isOpen('dm_provisionFromTemplateToSettle') && sourceTemplateId) {
        return {
            appDefId: appDefinitionId,
            appVersion: version,
            sourceTemplateId,
            type: 'provisionFromSourceTemplate'
        }
    }
    return {
        appDefId: appDefinitionId,
        applicationId: existingApp?.applicationId || getNewApplicationIdStartingFrom1k(clientSpecMap),
        appVersion: version,
        type: 'provision'
    }
}

const getProvisionActions = (clientSpecMap, apps /** {appDefinitionId, version(optional)} */) =>
    apps.map(app => getProvisionAction(clientSpecMap, app.appDefinitionId, app.version, app.sourceTemplateId))

const postSettleActions = (ps: PS, data) => {
    if (!_.isNil(ps)) {
        ps.extensionAPI.rendererModel.askRemoteEditorsToRefreshClientSpecMap()

        if (data?.actions) {
            _.forEach(data.actions, ({appDefId, type}) => {
                platformStateService.clearAppPendingAction(ps, appDefId)

                if (type === 'provision' || type === 'add') {
                    platformStateService.setAppAsUsed(ps, appDefId)
                }
            })
        }
    }
}

const makeSettleRequestWithPS = (
    ps: PS,
    urlData,
    data,
    saveContext,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) => {
    if (experiment.isOpen('dm_settleThroughProxy')) {
        editorServerFacade.sendWithPs(
            ps,
            editorServerFacade.ENDPOINTS.SETTLE_AND_CONDUCT,
            {settle: data},
            getOnSuccessWithFallback('makeSettleRequestWithPS', onError, response => {
                postSettleActions(ps, data)
                const platformAppsExperiments = {}
                _.forEach(response.experimentsPerAppDefId, (val, key) =>
                    _.set(platformAppsExperiments, key, val.appExperiments)
                )
                onSuccess({clientSpecMap: response.clientSpecMap, platformAppsExperiments})
            }),
            error => onError(error)
        )
    } else {
        const url = buildBaseUrl(urlData, saveContext)

        doRequest(ps, url, 'POST', data, onSuccess, onError)
    }
}

const makeSettleRequestWithPSAsync = (ps: PS, urlData, data, saveContext) =>
    new Promise((resolve, reject) => {
        makeSettleRequestWithPS(ps, urlData, data, saveContext, resolve, reject)
    })

const settleQueue = createQueue(makeSettleRequestWithPSAsync)
const queueMakingSettleRequestWithPSAsync = settleQueue.run

const makeSettleRequestWithImmutable = (currentImmutable, data, onSuccess: Callback1<any>, onError: Callback1<any>) => {
    editorServerFacade.sendWithImmutable(
        currentImmutable,
        editorServerFacade.ENDPOINTS.SETTLE,
        data,
        getOnSuccessWithFallback('makeSettleRequestWithImmutable', onError, response => {
            onSuccess({...response, payload: {clientSpecMap: response.payload}})
        }),
        error => onError(error)
    )
}

const makeSettleRequestWithImmutableSnapshot = (
    immutableSnapshot,
    data,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) => {
    editorServerFacade.sendWithSnapshotDal(
        immutableSnapshot,
        editorServerFacade.ENDPOINTS.SETTLE,
        data,
        getOnSuccessWithFallback('makeSettleRequestWithImmutableSnapshot', onError, response => {
            onSuccess({...response, payload: {clientSpecMap: response.payload}})
        }),
        error => onError(error)
    )
}

export interface App {
    applicationId?: string | number
    appDefinitionId?: string
    sourceTemplateId?: string
    version?: string
    appVersion?: string
}

const provision = (ps: PS, apps: App[], onSuccess: Function, onError: (e: any) => void) => {
    if (_.isEmpty(apps)) {
        onError('provision must be called with at least one app')
        return
    }

    const branchId = ps.extensionAPI.siteAPI.getBranchId()
    const clientSpecMap = clientSpecMapMetaData.getAppsData(ps)
    const actions = getProvisionActions(clientSpecMap, apps)
    const data = _.omitBy(
        {
            actions,
            siteRevision: ps.extensionAPI.siteAPI.getSiteRevision(),
            maybeBranchId: branchId
        },
        _.isNil
    )

    const urlData = {
        appStoreUrl: ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'appStoreUrl')),
        metaSiteId: getMetaSiteId(ps),
        editorSessionId: ps.siteAPI.getEditorSessionId()
    }

    queueMakingSettleRequestWithPSAsync(ps, urlData, data, context.firstSave)
        .then(
            getOnSuccessWithFallback('appStoreService provision', onError, response => {
                if (experiment.isOpen('dm_settleThroughProxy')) {
                    platform.addPlatformAppsExperiments(ps, response.platformAppsExperiments)
                    onSuccess(response)
                } else if (_.get(response, 'success')) {
                    onSuccess(response.payload.clientSpecMap)
                } else {
                    onError(response)
                }
            })
        )
        .catch(onError)
}

const provisionAsync = (ps: PS, apps: App[]): Promise<any> =>
    new Promise<any>((resolve, reject) => {
        provision(ps, apps, resolve, reject)
    })

const update = async (ps: PS, apps: App[]) => {
    if (_.isEmpty(apps)) {
        throw new Error('update must be called with at least one app')
    }

    const branchId = ps.extensionAPI.siteAPI.getBranchId()
    const clientSpecMap = clientSpecMapMetaData.getAppsData(ps)

    const data = _.omitBy(
        {
            actions: apps.map(app => {
                const appData = _.find(clientSpecMap, {applicationId: app.applicationId})

                return {
                    type: 'update',
                    appDefId: appData.appDefinitionId,
                    applicationId: appData.applicationId,
                    instanceId: appData.instanceId,
                    appVersion: app.appVersion
                }
            }),
            siteRevision: ps.extensionAPI.siteAPI.getSiteRevision(),
            maybeBranchId: branchId
        },
        _.isNil
    )

    const urlData = {
        appStoreUrl: ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'appStoreUrl')),
        metaSiteId: getMetaSiteId(ps),
        editorSessionId: ps.siteAPI.getEditorSessionId()
    }

    return queueMakingSettleRequestWithPSAsync(ps, urlData, data, context.firstSave).then(response => {
        return response.clientSpecMap
    })
}

const settleOnFirstSave = function (appServiceData, revision, urlData, onSuccess, onError) {
    const {branchId} = appServiceData

    const data = _.omitBy(
        {
            actions: getSettleActionsForFullSave(appServiceData, false),
            siteRevision: revision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    queueMakingSettleRequestWithPSAsync(null, urlData, data, context.firstSave).then(onSuccess, onError)
}

const settleOnFirstSaveWithImmutable = function (
    currentImmutable,
    appServiceData,
    revision: string,
    urlData,
    onSuccess,
    onError
) {
    const {branchId} = appServiceData

    const data = _.omitBy(
        {
            actions: getSettleActionsForFullSave(appServiceData, false),
            siteRevision: revision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    makeSettleRequestWithImmutable(currentImmutable, data, onSuccess, onError)
}

const settleOnFirstSaveWithImmutableSnapshot = function (
    currentImmutableSnapshot,
    appServiceData,
    revision: string,
    urlData,
    onSuccess,
    onError
) {
    const {branchId} = appServiceData

    const data = _.omitBy(
        {
            actions: getSettleActionsForFullSave(appServiceData, false),
            siteRevision: revision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    makeSettleRequestWithImmutableSnapshot(currentImmutableSnapshot, data, onSuccess, onError)
}

const getInteractionsParams = actions => ({
    paramsOverrides: {actions: _.map(actions, _.partialRight(_.pick, ['appDefId', 'type']))}
})

const getSettleActionsForSave = (
    {
        clientSpecMap,
        isMasterPageUpdated,
        updatedPagesVisitable,
        currentPagesVisitable,
        lastPagesVisitable,
        deletedPagesVisitable,
        semanticAppVerions,
        routerConfigMap,
        appsState
    },
    shouldAvoidRevoking?
) => {
    const filteredClientSpecMap = clientSpecMapService.filterApps(clientSpecMap)
    const isPlatformOnlyAppUninstalled = installedTpaAppsOnSiteService.isPlatformOnlyAppUninstalled(
        updatedPagesVisitable,
        clientSpecMap,
        routerConfigMap,
        appsState
    )
    const shouldTriggerFullSave = _.some(clientSpecMap, {shouldTriggerFullSave: true})
    const wereTpaCompsUninstalled = installedTpaAppsOnSiteService.areTpaCompsWereUnInstalled(
        lastPagesVisitable,
        updatedPagesVisitable,
        deletedPagesVisitable,
        clientSpecMap,
        isMasterPageUpdated,
        appsState
    )
    const shouldGetDataFromAllPages = wereTpaCompsUninstalled || shouldTriggerFullSave || isPlatformOnlyAppUninstalled
    const actions = shouldGetDataFromAllPages
        ? getSettleActionsFromAllPages(
              currentPagesVisitable,
              filteredClientSpecMap,
              routerConfigMap,
              semanticAppVerions,
              appsState,
              shouldAvoidRevoking
          )
        : getSettleActionsFromUpdatedPages(
              updatedPagesVisitable,
              filteredClientSpecMap,
              routerConfigMap,
              semanticAppVerions,
              appsState
          )
    contextAdapter.utils.fedopsLogger.interactionStarted(
        dsConstants.PLATFORM_INTERACTIONS.SETTLE_ACTIONS,
        getInteractionsParams(actions)
    )
    return actions
}

const settleOnSave = function (appServiceData, revision, urlData, shouldAvoidRevoking, onSuccess, onError) {
    const {branchId} = appServiceData

    const data = _.omitBy(
        {
            actions: getSettleActionsForSave(appServiceData, shouldAvoidRevoking),
            siteRevision: revision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    if (!_.isEmpty(data.actions)) {
        queueMakingSettleRequestWithPSAsync(null, urlData, data, context.save).then(
            getOnSuccessWithFallback('settleOnSave', onError, onSuccess),
            onError
        )
    } else {
        onSuccess()
    }
}

const settleOnSaveWithImmutable = function (
    currentImmutable,
    appServiceData,
    revision,
    urlData,
    shouldAvoidRevoking,
    onSuccess,
    onError
) {
    const {branchId} = appServiceData

    const data = _.omitBy(
        {
            actions: getSettleActionsForSave(appServiceData, shouldAvoidRevoking),
            siteRevision: revision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    if (!_.isEmpty(data.actions)) {
        makeSettleRequestWithImmutable(currentImmutable, data, onSuccess, onError)
    } else {
        onSuccess()
    }
}

const settleOnSaveWithImmutableSnapshot = function (
    currentImmutableSnapshot,
    appServiceData,
    revision,
    urlData,
    shouldAvoidRevoking,
    onSuccess,
    onError
) {
    const {branchId} = appServiceData

    const data = _.omitBy(
        {
            actions: getSettleActionsForSave(appServiceData, shouldAvoidRevoking),
            siteRevision: revision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    if (!_.isEmpty(data.actions)) {
        makeSettleRequestWithImmutableSnapshot(currentImmutableSnapshot, data, onSuccess, onError)
    } else {
        onSuccess()
    }
}

const getProvisionActionsForAllPages = (clientSpecMap, semanticAppVersions = {}) => {
    return getProvisionActions(
        clientSpecMap,
        _.map(_.values(installedTpaAppsOnSiteService.getAppsToProvision(clientSpecMap)), ({appDefinitionId}) => ({
            appDefinitionId,
            version: semanticAppVersions[appDefinitionId]
        }))
    )
}

const getAppsToAddFromUpdatedPages = (
    appsToGrant,
    appIdsInstalledOnUpdatedPages,
    clientSpecMap,
    semanticAppVersions = {},
    appsToProvision = []
) => {
    return _(appsToGrant)
        .filter(
            appData =>
                (_.includes(appIdsInstalledOnUpdatedPages, _.toString(appData.applicationId)) ||
                    clientSpecMapService.isDemoAppAfterProvision(appData)) ??
                clientSpecMapService.hasEditorPlatformPart(appData)
        )
        .reject(({appDefinitionId}) => _.some(appsToProvision, {appDefId: appDefinitionId}))
        .map(({appDefinitionId, applicationId, instanceId, version, appFields}) => {
            const appVersion = appFields?.installedVersion || semanticAppVersions[appDefinitionId] || version
            return _.omitBy(
                {
                    type: 'add',
                    appDefId: appDefinitionId,
                    applicationId: _.toString(applicationId),
                    instanceId,
                    appVersion
                },
                _.isUndefined
            )
        })
        .value()
}

const getSettleActionsFromUpdatedPages = (
    updatedPagesVisitable,
    clientSpecMap,
    routerConfigMap,
    semanticAppVerions,
    appsState
) => {
    const installedAppIds = installedTpaAppsOnSiteService.getAllAppIdsInstalledOnPages(
        updatedPagesVisitable,
        clientSpecMap,
        routerConfigMap
    )
    const appsToGrant = _.concat(
        _.filter(clientSpecMap, 'notProvisioned'),
        pendingAppsService.getAppsToAdd(),
        installedTpaAppsOnSiteService.getAppsToGrantPermissions(clientSpecMap, installedAppIds, appsState)
    )
    const appsToProvision = getProvisionActionsForAllPages(clientSpecMap, semanticAppVerions)
    const appsToAdd = getAppsToAddFromUpdatedPages(
        appsToGrant,
        installedAppIds,
        clientSpecMap,
        semanticAppVerions,
        appsToProvision
    )
    const appsToDismiss = getAppsToDismiss()
    const appsToUpdate = getAppsToUpdate(clientSpecMap, appsToGrant, null, appsState)

    return _.concat(appsToAdd, appsToProvision, appsToDismiss, appsToUpdate)
}

const settleOnLoad = function (ps: PS, shouldAvoidRevoking?, callback?) {
    const onSuccess = getOnSuccessWithFallback('settleOnLoad', callback, function (response) {
        if (response) {
            platform.addPlatformAppsExperiments(ps, response.platformAppsExperiments)
            _.forEach(response.clientSpecMap, appData => clientSpecMapService.registerAppData(ps, appData))
        }
        if (response?.success) {
            _.forEach(response.payload.clientSpecMap, appData => clientSpecMapService.registerAppData(ps, appData))
        }
        if (callback) {
            callback()
        }
    })

    const visitableDataAllPages = visitableData.createFromPrivateServices(ps)
    const clientSpecMap = clientSpecMapService.filterApps(ps.dal.get(ps.pointers.general.getClientSpecMap()))
    const routerConfigMap = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())
    const siteRevision = ps.extensionAPI.siteAPI.getSiteRevision()
    const urlData = {
        appStoreUrl: ps.dal.get(ps.pointers.getInnerPointer(ps.pointers.general.getServiceTopology(), 'appStoreUrl')),
        metaSiteId: getMetaSiteId(ps),
        editorSessionId: ps.siteAPI.getEditorSessionId()
    }
    settleImplOnLoad(
        ps,
        visitableDataAllPages,
        clientSpecMap,
        routerConfigMap,
        siteRevision,
        urlData,
        shouldAvoidRevoking,
        onSuccess
    )
}

const buildBaseUrl = function (
    {appStoreUrl, metaSiteId, editorSessionId}: {appStoreUrl: string; metaSiteId: string; editorSessionId: string},
    settleContext
) {
    const baseUrl = buildSettleUrl(appStoreUrl, settleTemplate, metaSiteId, editorSessionId, settleContext)
    // @ts-expect-error
    return new ProvisionUrlBuilder(baseUrl).addAcceptJson().build()
}

/**
 * @param clientSpecMap
 * @param {any[]} [appsToGrant]
 * @param {any[]|null} [appsToRevoke]
 * @param {any} [appsState]
 * @returns {*}
 */
const getAppsToUpdate = (clientSpecMap, appsToGrant: any[] = [], appsToRevoke = [], appsState = {}) =>
    _(clientSpecMap)
        .filter(({applicationId, appDefinitionId}) => {
            const appState: any = _.get(appsState, appDefinitionId, {})
            return (
                appState.pendingAction === constants.APP_ACTION_TYPES.UPDATE &&
                !_.some(appsToGrant, {applicationId}) &&
                !_.some(appsToRevoke, {applicationId})
            )
        })
        .map(clientSpec => ({
            type: 'update',
            appDefId: clientSpec.appDefinitionId,
            applicationId: _.toString(clientSpec.applicationId),
            instanceId: clientSpec.instanceId,
            appVersion: clientSpec.version
        }))
        .value()

const getAppsToAddFromAllPages = (
    appsToGrant,
    appsToRevoke,
    clientSpecMap,
    semanticAppVersions = {},
    appsToProvision = []
) =>
    _(appsToGrant)
        .reject(
            ({applicationId, appDefinitionId}) =>
                _.some(appsToRevoke, {applicationId}) || _.some(appsToProvision, {appDefId: appDefinitionId})
        )
        .map(({appDefinitionId, applicationId, instanceId, version, appFields}) => {
            const appVersion = appFields?.installedVersion || semanticAppVersions[appDefinitionId] || version
            return _.omitBy(
                {
                    type: 'add',
                    appDefId: appDefinitionId,
                    applicationId: _.toString(applicationId),
                    instanceId,
                    appVersion
                },
                _.isUndefined
            )
        })
        .value()

const getAppsToRemove = appsToRevoke =>
    _.map(appsToRevoke, ({applicationId}) => ({
        type: 'remove',
        applicationId: _.toString(applicationId)
    }))

const getAppsToDismiss = () =>
    _.map(pendingAppsService.getAppsToDismiss(), appDefId => ({
        type: 'dismiss',
        appDefId
    }))

const settleImplOnLoad = (
    ps: PS,
    visitableDataAllPages,
    clientSpecMap,
    routerConfigMap,
    siteRevision: string,
    urlData,
    shouldAvoidRevoking,
    callback
) => {
    const branchId = ps.extensionAPI.siteAPI.getBranchId()
    const {grant: appsToGrant, revoke: appsToRevoke} = installedTpaAppsOnSiteService.getAppsToGrantAndRevoke(
        clientSpecMap,
        visitableDataAllPages,
        {excludeHybrid: true, shouldAvoidRevoking},
        routerConfigMap,
        {},
        // @ts-expect-error
        platformStateService.getAppsState(ps)
    )

    const appsToAdd = getAppsToAddFromAllPages(appsToGrant, appsToRevoke, clientSpecMap)
    const appsToRemove = getAppsToRemove(appsToRevoke)
    const appsToDismiss = getAppsToDismiss()

    const appsToUpdate = getAppsToUpdate(clientSpecMap, appsToGrant, appsToRevoke, {})

    const data = _.omitBy(
        {
            actions: _.concat(appsToAdd, appsToRemove, appsToDismiss, appsToUpdate),
            siteRevision,
            maybeBranchId: branchId
        },
        _.isNil
    )

    if (!_.isEmpty(data.actions)) {
        queueMakingSettleRequestWithPSAsync(ps, urlData, data, context.load)
            .then(callback)
            .catch(e => {
                ps.extensionAPI.logger.captureError(
                    getReportableFromError(e, {
                        errorType: 'settleImplOnLoadError',
                        message: `Failed settleImplOnLoad`,
                        tags: {
                            provisioningOnSuccess: true
                        }
                    })
                )

                console.error(e)
                callback()
            })
    } else {
        callback()
    }
}

const getSettleActionsFromAllPages = (
    currentPagesVisitable,
    clientSpecMap,
    routerConfigMap,
    semanticAppVersions,
    appsState,
    shouldAvoidRevoking
) => {
    const appsToGrantAndRevoke = installedTpaAppsOnSiteService.getAppsToGrantAndRevoke(
        clientSpecMap,
        currentPagesVisitable,
        {shouldAvoidRevoking},
        routerConfigMap,
        appsState
    )
    const appsToGrant = _.concat(
        _.filter(clientSpecMap, 'notProvisioned'),
        pendingAppsService.getAppsToAdd(),
        appsToGrantAndRevoke.grant
    )
    const appsToRevoke = appsToGrantAndRevoke.revoke

    const appsToProvision = getProvisionActionsForAllPages(clientSpecMap, semanticAppVersions)
    const appsToAdd = getAppsToAddFromAllPages(
        appsToGrant,
        appsToRevoke,
        clientSpecMap,
        semanticAppVersions,
        appsToProvision
    )
    const appsToRemove = getAppsToRemove(appsToRevoke)
    const appsToDismiss = getAppsToDismiss()
    const appsToUpdate = getAppsToUpdate(clientSpecMap, appsToGrant, appsToRevoke, appsState)

    return _.concat(appsToAdd, appsToProvision, appsToRemove, appsToDismiss, appsToUpdate)
}

const getNewApplicationIdStartingFrom1k = function (clientSpecMap) {
    const currentLargestId = clientSpecMapService.getLargestApplicationId(clientSpecMap)
    const newGeneratedApplicationId = provisionUtils.generateAppFlowsLargestAppId(currentLargestId)
    return newGeneratedApplicationId
}

const buildSettleUrl = function (
    provisionBaseUrl: string,
    template: TemplateExecutor,
    metaSiteId: string,
    editorSessionId: string,
    settleContext
) {
    return template({
        provisionServerBaseUrl: provisionBaseUrl,
        metaSiteId,
        editorSessionId,
        context: settleContext
    })
}

const doRequest = function (
    ps: PS,
    url: string,
    type: string,
    data,
    onSuccess: Callback1<any>,
    onError: Callback1<any>
) {
    const params: any = {
        type,
        url,
        dataType: 'json',
        contentType: 'application/json',
        success: getOnSuccessWithFallback('doRequest', onError, response => {
            postSettleActions(ps, data)
            onSuccess(response)
        }),
        error: onError
    }

    if (data && !_.isEmpty(data)) {
        params.data = JSON.stringify(data)
    }

    if (type === 'POST') {
        params.xhrFields = {
            withCredentials: true
        }
    }

    ajaxLibrary.ajax(params)
}

export default {
    provision,
    provisionAsync,
    update,
    settleOnSave,
    settleOnSaveWithImmutable,
    settleOnSaveWithImmutableSnapshot,
    settleOnFirstSave,
    settleOnFirstSaveWithImmutable,
    settleOnFirstSaveWithImmutableSnapshot,
    getSettleActionsForFullSave,
    getSettleActionsForSave,
    settleOnLoad,
    waitForSettleQueue: settleQueue.wait,
    provisionAppFromSourceTemplate
}
