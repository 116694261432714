import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import documentModeInfo from '../../documentMode/documentModeInfo'
import metaDataUtils from '../metaDataUtils'
function getWidgetData(ps: PS, compData) {
    if (!_.has(compData, 'applicationId') || !_.has(compData, 'widgetId')) {
        return {}
    }
    const clientSpecMapPtr = ps.pointers.general.getClientSpecMap()
    const csm = ps.dal.getNoClone(clientSpecMapPtr)
    const result = _.get(csm, [compData.applicationId, 'widgets', compData.widgetId]) || {}
    return _.cloneDeep(result)
}

const getFromStageData = (ps: PS, compRef, stageDataKey, defaultValue) =>
    metaDataUtils.getFromStageData(ps, stageDataKey, compRef, defaultValue)

export default {
    isRepeatable: false,
    getWidgetData,
    rotatable(ps: PS, compRef) {
        return getFromStageData(ps, compRef, 'rotatable', false)
    },
    canBeFixedPosition(ps: PS, compRef) {
        return getFromStageData(ps, compRef, 'pinnable', true)
    },
    removable(ps: PS, compRef) {
        return getFromStageData(ps, compRef, 'removable', true)
    },
    duplicatable(ps: PS, compRef) {
        return getFromStageData(ps, compRef, 'duplicatable', !documentModeInfo.isMobileView(ps))
    },
    resizable(ps: PS, compRef) {
        return getFromStageData(ps, compRef, 'resizable', true)
    },
    canReparent(ps: PS, compRef) {
        return getFromStageData(ps, compRef, 'canReparent', true)
    }
}
