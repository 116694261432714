import platform from '../../platform/platform'
import fileSystemAPI from './fileSystemAPI'
import constants from '../utils/constants'

function getPagesFolderDescriptor(ps) {
    return fileSystemAPI.getVirtualDescriptor(ps, 'public/pages', true)
}

function getPageFileDescriptor(ps, pageId) {
    const pageFilePath = `public/pages/${pageId}.js`
    const pageFileDescriptor = fileSystemAPI.getVirtualDescriptor(ps, pageFilePath, false)
    return pageFileDescriptor
}

async function duplicatePageCode(ps, newPageId, originalPageId) {
    if (!platform.pageHasPlatformApp(ps, originalPageId, constants.WIX_CODE_APP_ID)) {
        return
    }
    const srcDescriptor = getPageFileDescriptor(ps, originalPageId)
    const targetFolderDescriptor = getPagesFolderDescriptor(ps)
    const newItemName = `${newPageId}.js`
    return fileSystemAPI.copyFile(ps, srcDescriptor, targetFolderDescriptor, newItemName)
}

export default {
    duplicatePageCode
}
