import _ from 'lodash'
import schemaUtils from './schemaUtils'
import {ReportableError} from '@wix/document-manager-utils'
import * as santaCoreUtils from '@wix/santa-core-utils'
import {getIfExists} from '../../utils/dalUtil'
import type {PS} from '@wix/document-services-types'

function isUndoableFile(filePath) {
    return schemaUtils.isSchemaFile(filePath)
}

function assembleUndoableFile(filePath, loadedContent, modifiedContent, undoableModifiedContent) {
    if (_.isUndefined(loadedContent)) {
        throw new ReportableError({
            message: `Cannot read undoable file which has not been loaded [${filePath}]`,
            errorType: 'undoableFileNotLoaded',
            tags: {}
        })
    }
    if (schemaUtils.isSchemaFile(filePath)) {
        return schemaUtils.assembleSchema(loadedContent, modifiedContent, undoableModifiedContent)
    }
}

function generateUndoableId(filePath) {
    return santaCoreUtils.guidUtils.getUniqueId(filePath, '-')
}

function setUndoableContent(ps: PS, filePath, content) {
    const filePathIdPointer = ps.pointers.wixCode.getFilePathId(filePath)
    let filePathId = getIfExists(ps, filePathIdPointer, null)
    if (filePathId === null) {
        filePathId = generateUndoableId(filePath)
        ps.dal.set(filePathIdPointer, filePathId)
    }

    const undoableContentPointer = ps.pointers.wixCode.getUndoableModifiedFileContent(filePathId)
    ps.dal.set(undoableContentPointer, content)
}

function getUndoableContent(ps: PS, filePath) {
    const filePathIdPointer = ps.pointers.wixCode.getFilePathId(filePath)
    const filePathId = getIfExists(ps, filePathIdPointer, null)
    if (filePathId === null) {
        return null
    }
    const undoableContentPointer = ps.pointers.wixCode.getUndoableModifiedFileContent(filePathId)
    return getIfExists(ps, undoableContentPointer, null)
}

function getUndoableContentByPath(undoableContentByIdMap, undoablePathIdMap) {
    return _.mapValues(undoablePathIdMap, id => undoableContentByIdMap[id])
}

export default {
    isUndoableFile,
    assembleUndoableFile,
    setUndoableContent,
    getUndoableContent,
    getUndoableContentByPath
}
