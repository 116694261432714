import {responsiveLayout, componentScroll} from '@wix/document-services-implementation'
import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        initMethod: responsiveLayout.init,
        methods: {
            components: {
                scroll: {
                    onComponentsScroll: publicMethodUtils.defineAction(componentScroll.onComponentsScroll)
                },
                responsiveLayout: {
                    update: publicMethodUtils.defineDataManipulationAction(responsiveLayout.update),
                    get: publicMethodUtils.defineGetter(responsiveLayout.get),
                    measure: publicMethodUtils.defineGetter(responsiveLayout.measure),
                    isNewLayoutMigration: publicMethodUtils.defineGetter(responsiveLayout.isNewLayoutMigration),
                    pin: publicMethodUtils.defineDataManipulationAction(responsiveLayout.pin),
                    unpin: publicMethodUtils.defineDataManipulationAction(responsiveLayout.unpin),
                    scoped: {
                        remove: publicMethodUtils.defineDataManipulationAction(responsiveLayout.removeScopedLayout)
                    }
                }
            },
            responsiveLayout: {
                update: publicMethodUtils.defineDataManipulationAction(responsiveLayout.update),
                get: publicMethodUtils.defineGetter(responsiveLayout.get),
                measure: publicMethodUtils.defineGetter(responsiveLayout.measure),
                isNewLayoutMigration: publicMethodUtils.defineGetter(responsiveLayout.isNewLayoutMigration),
                pin: publicMethodUtils.defineDataManipulationAction(responsiveLayout.pin),
                unpin: publicMethodUtils.defineDataManipulationAction(responsiveLayout.unpin),
                runtime: {
                    measure: {
                        getBoundingBox: publicMethodUtils.defineGetter(responsiveLayout.getBoundingBox),
                        getNonRotatedBoundingBox: publicMethodUtils.defineGetter(
                            responsiveLayout.getNonRotatedBoundingBox
                        ),
                        getRelativeToViewportBoundingBox: publicMethodUtils.defineGetter(
                            responsiveLayout.getRelativeToViewportBoundingBox
                        ),
                        getPadding: publicMethodUtils.defineGetter(responsiveLayout.getPadding),
                        getScrollHeight: publicMethodUtils.defineGetter(responsiveLayout.getScrollHeight),
                        getClientHeight: publicMethodUtils.defineGetter(responsiveLayout.getClientHeight),
                        getScrollWidth: publicMethodUtils.defineGetter(responsiveLayout.getScrollWidth),
                        getClientWidth: publicMethodUtils.defineGetter(responsiveLayout.getClientWidth),
                        getGridMeasures: publicMethodUtils.defineGetter(responsiveLayout.getGridMeasures)
                    },
                    // new APIs
                    detachComponents: publicMethodUtils.defineAction(responsiveLayout.detachComponents),
                    reattachComponents: publicMethodUtils.defineAction(responsiveLayout.reattachComponents),
                    //
                    detachLayout: publicMethodUtils.defineAction(responsiveLayout.detachLayout),
                    updateDetachedLayout: publicMethodUtils.defineAction(responsiveLayout.updateDetachedLayout),
                    updateDetachedRotation: publicMethodUtils.defineAction(responsiveLayout.updateDetachedRotation),
                    updateDetachedTransformation: publicMethodUtils.defineAction(
                        responsiveLayout.updateDetachedTransformation
                    ),
                    reattachLayout: publicMethodUtils.defineAction(responsiveLayout.reattachLayout),
                    gridItem: {
                        detach: publicMethodUtils.defineAction(responsiveLayout.detachGridItem),
                        updateDetached: publicMethodUtils.defineAction(responsiveLayout.updateDetachedGridItem),
                        reattach: publicMethodUtils.defineAction(responsiveLayout.reattachGridItem)
                    },
                    stackItem: {
                        detach: publicMethodUtils.defineAction(responsiveLayout.detachStackItem),
                        updateDetached: publicMethodUtils.defineAction(responsiveLayout.updateDetachedStackItem),
                        updateDetachedOrder: publicMethodUtils.defineAction(
                            responsiveLayout.updateDetachedStackItemOrder
                        ),
                        reattach: publicMethodUtils.defineAction(responsiveLayout.reattachStackItem)
                    }
                }
            }
        }
    }
}
