import type {SnapshotDal} from '@wix/document-manager-core'
import type {PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import appStudioSaveTask from '../../appStudio/appStudioSaveTask'
import createReloadSitePropertyInfoTask from '../../siteMetadata/saveTasks/reloadSitePropertyInfoTask'
import createSaveEventDispatcher from '../../tpa/saveTasks/saveEventDispatcher'
import createSaveUnprovisionedApps from '../../tpa/saveTasks/saveUnprovisionedApps'
import createSaveUnprovisionedAppsForAppFlows from '../../tpa/saveTasks/saveUnprovisionedAppsForAppFlows'
import wixappsBlogSavePlugin from '../../wixapps/services/save/wixappsBlogSavePlugin'
import savePublishTaskFactory from '../../wixapps/services/savePublishTaskFactory'
import createMarkAppImmutableTask from '../../wixCode/saveTasks/markAppImmutable'
import createSaveCodeTask from '../../wixCode/saveTasks/saveCode'
import saveDocument from '../saveTasks/saveDocument'

export interface SaveTaskRegistry {
    getSaveTasksConfig(ps: PS): SaveTaskRegistryResult
}

export interface SaveTaskRegistryResult {
    requiredTasks: SaveTaskDefinition[]
    primaryTask: SaveTaskDefinition
    secondaryTasks: SaveTaskDefinition[]
}

export interface SaveTaskDefinition {
    shouldRun?(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        methodName: string,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI,
        pointers
    ): boolean

    partialSave(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        resolve,
        reject,
        bi,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI
    ): void
    fullSave(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        resolve,
        reject,
        bi,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI
    ): void
    firstSave(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        resolve,
        reject,
        bi,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI
    ): void
    saveAsTemplate(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        resolve,
        reject,
        bi,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI
    ): void
    autosave?(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        resolve,
        reject,
        bi,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI
    ): void
    publish(currentSnapshot, resolve, reject, bi, options?, currentSnapshotDal?: SnapshotDal, boundExtensionsAPI?): void
    getTaskName(): string
    getSnapshotTags(methodName: string): string[]
    getCurrentState?(): unknown
    getLastState?(): unknown
    getCurrentState?(): unknown
    onTaskSuccess?(result): void
    takeSnapshot?(): void
    rollback?(): void

    promises?: any
    fullSaveAsync?: any
}

function getSaveTasksConfig(ps: PS): SaveTaskRegistryResult {
    return {
        requiredTasks: [createSaveCodeTask(), appStudioSaveTask.createPreSave(ps)],
        primaryTask: saveDocument,
        secondaryTasks: [
            ...(experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled') ? [] : [createMarkAppImmutableTask()]),
            savePublishTaskFactory.create(ps),
            createSaveUnprovisionedApps(),
            createSaveUnprovisionedAppsForAppFlows(),
            createSaveEventDispatcher(),
            createReloadSitePropertyInfoTask(),
            wixappsBlogSavePlugin.create(ps)
        ]
    }
}

export default {
    getSaveTasksConfig
}
