import pMap from 'p-map'
import type {FetchFn} from '@wix/document-manager-extensions/src/utils/fetchUtils'
import type {PageList} from '@wix/document-services-types'
import type {FetchPagesFacade} from '../types'
export type PageLoadedCallback = (pageJson: any, pageId: string) => void

export class DefaultFetchPagesFacade implements FetchPagesFacade {
    fetchPages(fetchFn: FetchFn, pageList: PageList, concurrency: number = 100): Promise<Record<string, any>> {
        return fetchPages(fetchFn, pageList, concurrency)
    }
}

// todo: PageList is not actually a page list.. its a piece of metadata containing info of how to fetch a site pages
async function fetchPages(
    fetchFn: FetchFn,
    pageList: PageList,
    concurrency: number = 100
): Promise<Record<string, any>> {
    const urlFallbacksTemplates = pageList.topology.map(
        urlDescriptor => `${urlDescriptor.baseUrl}${urlDescriptor.parts}`
    )
    const entries = await fetchPageEntries(fetchFn, pageList, urlFallbacksTemplates, concurrency)
    return Object.fromEntries(entries.map(({pageId, page}) => [pageId, page]))
}

async function fetchPageEntries(
    fetchFn: FetchFn,
    pageList: PageList,
    urlFallbacksTemplates: string[],
    concurrency: number
) {
    const allPages = [...pageList.pages, {pageId: 'masterPage', pageJsonFileName: pageList.masterPageJsonFileName}]
    return await pMap(
        allPages,
        ({pageJsonFileName, pageId}) => fetchPageJson(fetchFn, urlFallbacksTemplates, pageId, pageJsonFileName),
        {concurrency}
    )
}

async function fetchPageJson(
    fetchFn: FetchFn,
    urlFallbacksTemplates: string[],
    pageId: string,
    pageJsonFileName: string
): Promise<any> {
    for (const urlTemplate of urlFallbacksTemplates) {
        const url = urlTemplate.replace('{filename}', pageJsonFileName)

        const response = await fetchFn(url)
        if (!response.ok) {
            console.warn(`HTTP: failed to fetch page ${url} - ${response.status}: ${response.statusText}`)
            continue
        }
        const page = await response.json()
        return {pageId, page}
    }
    throw new Error(
        `Failed to fetch page using all fallback URLs. pageId: ${pageId}, pageJsonFileName: ${pageJsonFileName}`
    )
}

export {fetchPages}
