import type {Pointer, PossibleViewModes, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import constants from '../constants/constants'
import dsUtils from '../utils/utils'

const isMobileSupported = (ps: PS) => (ps ? ps.runtimeConfig.supportMobile : true)
const {stripHashIfExists} = dsUtils
const {COMP_DATA_QUERY_KEYS_WITH_STYLE, VIEW_MODES, DATA_TYPES, DATA_TYPES_VALUES_WITH_HASH} = constants

const syncMobileAndDesktopByDataType = (
    ps: PS,
    sourceComponentPointer: Pointer,
    dataType: string,
    id: string,
    isFull
) => {
    const itemIdWithHashIfNeeded = DATA_TYPES_VALUES_WITH_HASH[dataType] ? `#${id}` : id
    if (!isMobileSupported(ps)) {
        return
    }

    const dataTypeQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[dataType]
    const dal = isFull ? ps.dal.full : ps.dal
    const pointers = isFull ? ps.pointers.full : ps.pointers

    const targetTypePointer =
        sourceComponentPointer.type === VIEW_MODES.DESKTOP
            ? pointers.components.getMobilePointer(sourceComponentPointer)
            : pointers.components.getDesktopPointer(sourceComponentPointer)

    if (!dal.isExist(targetTypePointer)) {
        return
    }

    const targetDataTypeIdPointer = ps.pointers.getInnerPointer(targetTypePointer, dataTypeQueryKey)
    dal.set(targetDataTypeIdPointer, itemIdWithHashIfNeeded)
}

const syncMobileAndDesktopStyleId = (ps: PS, sourceComponentPointer: Pointer, styleId: string, isFull) =>
    syncMobileAndDesktopByDataType(ps, sourceComponentPointer, DATA_TYPES.theme, styleId, isFull)

const syncMobileAndDesktopSkin = (ps: PS, compPtr: Pointer, skin) => {
    if (!isMobileSupported(ps)) {
        return
    }

    const mobileCompPtr = ps.pointers.components.getMobilePointer(compPtr)
    const compSkinPointer = ps.pointers.getInnerPointer(mobileCompPtr, 'skin')
    if (ps.dal.get(compSkinPointer)) {
        ps.dal.set(compSkinPointer, skin)
    }
}

const deleteForkedData = (ps: PS, desktopPointer: Pointer, componentData, dataModel) => {
    if (!isMobileSupported(ps)) {
        return
    }
    const mobilePointer = ps.pointers.components.getMobilePointer(desktopPointer)

    if (ps.dal.isExist(mobilePointer)) {
        const arePropsForked = componentData.isMobileComponentPropertiesSplit(ps, mobilePointer)
        if (arePropsForked) {
            dataModel.deletePropertiesItem(ps, mobilePointer)
        }
    }
}

const updateMobilePropertyIfNeeded = (ps: PS, pointer: Pointer, propertyItem, updatePropertyFn) => {
    if (!isMobileSupported(ps)) {
        return false
    }
    if (!ps.pointers.components.isMobile(pointer)) {
        const mobilePtr = ps.pointers.components.getMobilePointer(pointer)
        updatePropertyFn(ps, mobilePtr, propertyItem)
    }
}

const removeMobilePropertyOverrideIfNeeded = (ps: PS, compPointer: Pointer, removePropertyFn) => {
    if (!isMobileSupported(ps)) {
        return false
    }
    if (!ps.pointers.components.isMobile(compPointer)) {
        const mobilePtr = ps.pointers.components.getMobilePointer(compPointer)
        removePropertyFn(ps, mobilePtr)
    }
}

const serializeMobileStructure = (
    ps: PS,
    componentPointer: Pointer,
    compStructure,
    pageId: string,
    flatMobileStructuresMap,
    structureEnricher,
    flags,
    resolveDataItems,
    serializeComponentStructureAndData,
    {mobileConversionFacade, dataModel}
) => {
    if (!isMobileSupported(ps)) {
        return
    }

    const propertyQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.prop]
    const mobileStructurePropertyQuery = _.get(compStructure, ['mobileStructure', propertyQueryKey])
    if (mobileStructurePropertyQuery) {
        resolveMobilePropertiesOnStructure(ps, compStructure, pageId, dataModel, mobileStructurePropertyQuery)
        return
    }

    if (ps.pointers.components.isPage(componentPointer)) {
        return
    }
    //otherwise, check if the comp being serialized has a mobileStructure preset saved for later when switching to mobile mode, and if so then take it
    const mobileStructure = flatMobileStructuresMap[componentPointer.id]
    if (mobileStructure && !mobileStructure.isCustomPreset) {
        resolveDataItems(ps, mobileStructure, undefined, flags, pageId, structureEnricher)
        compStructure.mobileStructure = {
            layout: _.cloneDeep(mobileStructure.layout),
            props: _.cloneDeep(mobileStructure.props)
        }
        return
    }
    if (flags.shouldAddMobilePresets) {
        mobileConversionFacade.createMobilePreset(ps, pageId, componentPointer, compStructure)
    }
}

function resolveMobilePropertiesOnStructure(
    ps: PS,
    structure,
    pageId: string,
    dataModel,
    mobileStructurePropertyQuery
) {
    const propertyQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.prop]
    if (mobileStructurePropertyQuery !== _.get(structure, [propertyQueryKey])) {
        const sanitiziedPropertyQuery = stripHashIfExists(mobileStructurePropertyQuery)
        const propsPointer = ps.pointers.data.getPropertyItem(sanitiziedPropertyQuery, pageId)
        structure.mobileStructure.props = dataModel.serializeDataItem(ps, DATA_TYPES.prop, propsPointer, false)
        delete structure.mobileStructure.propertyQuery
    }
}

const linkMobileComponentToDesktopDesignItem = (ps: PS, compPointer: Pointer, designId: string) => {
    if (!isMobileSupported(ps)) {
        return
    }

    const designQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.design]

    const getCompDesktopDesignQueries = () => {
        const overridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
        const overrides = ps.dal.full.isExist(overridesPointer) && ps.dal.full.get(overridesPointer)
        const queriesFromOverrides = _.map(overrides, designQueryKey)
        const designQueryPointer = ps.pointers.getInnerPointer(compPointer, designQueryKey)
        let structureDesignQuery
        if (santaCoreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(compPointer.id)) {
            structureDesignQuery = ps.dal.get(designQueryPointer)
        } else {
            structureDesignQuery = ps.dal.full.get(designQueryPointer)
        }
        return queriesFromOverrides.concat(structureDesignQuery)
    }

    if (compPointer.type === VIEW_MODES.MOBILE) {
        return
    }
    const compDesktopDesignQueries = getCompDesktopDesignQueries()

    const mobileCompPointer = ps.pointers.components.getMobilePointer(compPointer)
    if (ps.dal.isExist(mobileCompPointer)) {
        const mobileDesignPointer = ps.pointers.getInnerPointer(mobileCompPointer, designQueryKey)
        const mobileDesignQuery = ps.dal.get(mobileDesignPointer)
        if (!_.includes(compDesktopDesignQueries, mobileDesignQuery)) {
            ps.dal.set(mobileDesignPointer, `#${designId}`)
        }
    }
}

const removeComponentByDesktopComp = (ps: PS, desktopPointer: Pointer, isFull) => {
    if (!isMobileSupported(ps)) {
        return
    }

    const pointers = isFull ? ps.pointers.full : ps.pointers
    const dal = isFull ? ps.dal.full : ps.dal

    const mobileComponentPointer = pointers.components.getMobilePointer(desktopPointer)
    if (dal.isExist(mobileComponentPointer)) {
        dal.remove(mobileComponentPointer)
    }
}

const removeMobileCropOverrideIfNeeded = (ps: PS, compRef, imageSourceWasChanged, {dataModel, component}) => {
    if (!isMobileSupported(ps)) {
        return
    }
    const mobileCompRef = ps.pointers.components.getMobilePointer(compRef)

    if (!imageSourceWasChanged || !component.isExist(ps, mobileCompRef)) {
        return
    }

    const mobileCompProperties = dataModel.getPropertiesItem(ps, mobileCompRef)
    dataModel.setPropertiesItem(ps, mobileCompRef, _.assign(mobileCompProperties, {overrideCrop: null}))
}

const syncDockData = (ps: PS, desktopRootComp, desktopDockData, structure) => {
    if (!isMobileSupported(ps)) {
        return
    }
    structure.setDock(ps, ps.pointers.components.getMobilePointer(desktopRootComp), desktopDockData)
}

const prepareComponentStructureForMobileAlgorithm = (ps: PS, componentModes, compPointer: Pointer) => {
    if (!isMobileSupported(ps)) {
        return
    }

    const designQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[DATA_TYPES.design]
    const compMobileMode = componentModes.getCompMobileMode(ps, compPointer)
    componentModes.activateComponentMode(ps, compPointer, compMobileMode.modeId)

    const mobileCompPointer = ps.pointers.components.getMobilePointer(compPointer)
    if (ps.dal.isExist(mobileCompPointer)) {
        const designQueryInDesktop = ps.dal.get(ps.pointers.getInnerPointer(compPointer, designQueryKey))
        if (designQueryInDesktop) {
            ps.dal.set(ps.pointers.getInnerPointer(mobileCompPointer, designQueryKey), designQueryInDesktop)
        }
    }
}

const setMobileHiddenComponentListIfNeeded = (
    ps: PS,
    originalPageId: string,
    newPageId: string,
    originalToNewIdMap,
    page,
    mobileActions?
) => {
    if (!isMobileSupported(ps)) {
        return
    }

    if (page.mobileComponents) {
        const originalPageHiddenCompIds = mobileActions.hiddenComponents.get(ps, originalPageId)
        const newPageHiddenCompIds = _(originalToNewIdMap).pick(originalPageHiddenCompIds).values().value()
        mobileActions.hiddenComponents.set(ps, newPageId, newPageHiddenCompIds)
    }
}

const serializeMobileChildren = (
    ps: PS,
    compStructure,
    pageId: string,
    flags,
    flatMobileStructuresMap,
    structureEnricher,
    useOriginalLanguage,
    variantsCollection,
    serializeComponentStructureAndData
) => {
    if (!isMobileSupported(ps)) {
        return
    }

    if (ps.pointers.page.isExists(compStructure.id)) {
        const mobilePagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
        const mobileChildrenPointers = ps.pointers.full.components.getChildren(mobilePagePointer)
        compStructure.mobileComponents = _.map(mobileChildrenPointers, function (mobileChildPointer) {
            return serializeComponentStructureAndData(
                ps,
                mobileChildPointer,
                null,
                flatMobileStructuresMap,
                structureEnricher,
                flags,
                useOriginalLanguage,
                variantsCollection
            )
        })
    }
}

const initMobileComponents = (ps: PS, pageCompPointer: Pointer) => {
    if (!isMobileSupported(ps)) {
        return
    }
    const mobileComponentsPointer = ps.pointers.getInnerPointer(pageCompPointer, 'mobileComponents')
    if (ps.dal.full.isExist(mobileComponentsPointer)) {
        return
    }
    ps.dal.full.set(mobileComponentsPointer, [])
}

const setMobileViewMode = (ps: PS, hooks) => {
    if (isMobileSupported(ps)) {
        hooks.executeHook(hooks.HOOKS.SWITCH_VIEW_MODE.MOBILE, null, [ps])
    }
}

const getChildComponent = (ps: PS, componentStructure) =>
    isMobileSupported(ps) && componentStructure.mobileComponents && !_.isEmpty(componentStructure.mobileComponents)
        ? componentStructure.mobileComponents
        : []

const resetMobileComponentsForClonedComp = (ps: PS, serializedComp, clonedSerializedComp) => {
    if (serializedComp.mobileComponents) {
        clonedSerializedComp.mobileComponents = []
    }
}

const setMobileComponentChildrenDefinition = (
    ps: PS,
    serializedComp,
    pageId: string,
    mappers,
    modeIdsInHierarchy,
    activeModes,
    setComponentChildrenDefinition
) => {
    if (!isMobileSupported(ps)) {
        return
    }

    const mobileChildren = serializedComp.mobileComponents
    if (mobileChildren) {
        const mobilePageComponentPointer = ps.pointers.components.getPage(pageId, VIEW_MODES.MOBILE)
        setComponentChildrenDefinition(
            ps,
            pageId,
            mobileChildren,
            null,
            mobilePageComponentPointer,
            mappers,
            modeIdsInHierarchy,
            activeModes
        )
    }
}

const getViewMode = (ps: PS, viewMode: PossibleViewModes, documentModeInfo): PossibleViewModes => {
    if (!isMobileSupported(ps)) {
        return VIEW_MODES.DESKTOP
    }
    return viewMode || documentModeInfo.getViewMode(ps)
}

const getSupportedViewModes = (ps: PS): PossibleViewModes[] =>
    !isMobileSupported(ps) ? [VIEW_MODES.DESKTOP] : [VIEW_MODES.DESKTOP, VIEW_MODES.MOBILE]

export default {
    isMobileSupported,
    syncMobileAndDesktopStyleId,
    syncMobileAndDesktopSkin,
    syncMobileAndDesktopByDataType,
    deleteForkedData,
    updateMobilePropertyIfNeeded,
    removeMobilePropertyOverrideIfNeeded,
    serializeMobileStructure,
    linkMobileComponentToDesktopDesignItem,
    removeComponentByDesktopComp,
    syncDockData,
    prepareComponentStructureForMobileAlgorithm,
    setMobileHiddenComponentListIfNeeded,
    serializeMobileChildren,
    getChildComponent,
    resetMobileComponentsForClonedComp,
    setMobileViewMode,
    setMobileComponentChildrenDefinition,
    getViewMode,
    getSupportedViewModes,
    wPhoto: {
        removeMobileCropOverrideIfNeeded
    },
    page: {
        initMobileComponents
    }
}
