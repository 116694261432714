import type {
    CreateExtensionArgument,
    DalValue,
    DmApis,
    Extension,
    ExtensionAPI,
    ValidatorResult,
    WhitelistCheckResult
} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {PageAPI, PageExtensionAPI} from './page'
import type {ParsedSchemaValidationErrorMessage} from './schema/schema'

export type GetErrorContext = () => any
type WhitelistCheckWithContext = (
    pointer: Pointer,
    value: DalValue,
    validatorResult: ValidatorResult,
    errorContext: Record<string, any>,
    origin: string
) => WhitelistCheckResult

const includesApi = (errorContext: Record<string, any>, apis: string[]) =>
    _.some(errorContext.SOQ?.items, ({methodName}) => apis.includes(methodName))

export const NOT_LISTED = 'NOT_LISTED'
export const WARNING_ONLY = 'WARNING_ONLY'
const shouldValidateTheRefForPartialPageLoading = (pageApi: PageAPI, value: any, path: string[]) =>
    pageApi.isPartiallyLoaded() && _.includes(path, 'pageBackgrounds')

const createWhitelists = (extensionAPI: ExtensionAPI): Record<string, WhitelistCheckWithContext> => {
    return {
        missingReferenceError: (pointer, value, validatorResult, errorContext, origin) => {
            const namespace = pointer.type
            const {offendingData, path} = validatorResult.extras!
            const {page: pageApi} = extensionAPI as PageExtensionAPI
            if (shouldValidateTheRefForPartialPageLoading(pageApi, value, path)) {
                /*handle case in partial page loading where we load just master page but page data contains bg for non loaded page (page background in on the page)*/
                return {issue: 'DM-6005', isWhiteListed: true}
            }

            if (includesApi(errorContext, ['transactionRejected_afterRender'])) {
                /* https://jira.wixpress.com/browse/DM-5257 */
                return {issue: 'DM-5257', isWhiteListed: true}
            }

            if (includesApi(errorContext, ['Undo', 'Redo'])) {
                /* https://jira.wixpress.com/browse/DM-5258 */
                return {issue: 'DM-5258', isWhiteListed: true}
            }

            if (includesApi(errorContext, ['components.layout.update']) && origin === 'Editor1.4') {
                /* https://jira.wixpress.com/browse/DM-5259 */
                return {issue: 'DM-5259', isWhiteListed: true}
            }

            if (includesApi(errorContext, ['components.layout.update']) && origin === 'onboarding') {
                /* https://jira.wixpress.com/browse/DM-5260 */
                return {issue: 'DM-5260', isWhiteListed: true}
            }

            if (origin === 'onboarding' && includesApi(errorContext, ['components.remove'])) {
                /* https://jira.wixpress.com/browse/DM-5261 */
                return {issue: 'DM-5261', isWhiteListed: true}
            }

            if (
                origin === 'onboarding' &&
                namespace === 'MOBILE' &&
                offendingData?.componentType === 'wysiwyg.viewer.components.StripColumnsContainer'
            ) {
                /* https://jira.wixpress.com/browse/DM-6139 */
                return {issue: 'DM-6139', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        DuplicateReferenceError: pointer => {
            const {type: namespace} = pointer
            if (namespace === 'MOBILE') {
                /* https://jira.wixpress.com/browse/DM-5614 */
                return {issue: 'DM-5614', isWhiteListed: true}
            }

            if (namespace === 'multilingualTranslations') {
                /* https://jira.wixpress.com/browse/DM-6052 */
                return {issue: 'DM-6052', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        wrongPageRefError: pointer => {
            if (pointer.type === 'multilingualTranslations') {
                /* https://jira.wixpress.com/browse/DM-6052 */
                return {issue: 'DM-6052', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        invalidComp_design: (pointer, value, validatorResult, errorContext, origin) => {
            const namespace = pointer.type
            const {offendingData} = validatorResult.extras!
            if (
                origin === 'onboarding' &&
                namespace === 'MOBILE' &&
                offendingData?.componentType === 'wysiwyg.viewer.components.StripColumnsContainer'
            ) {
                /* https://jira.wixpress.com/browse/DM-6139 */
                return {issue: 'DM-6139', isWhiteListed: true}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        },
        schemaValidationError: (pointer, value, validatorResult) => {
            if (value.type === 'SingleLayoutData') {
                const validationMessages = JSON.parse(validatorResult.message) as ParsedSchemaValidationErrorMessage
                // mark as not listed if not in known conditions
                const isWhiteListed = _.every(validationMessages.errors, message => {
                    const isCorrespondingProperty = ['componentLayout', 'containerLayout', 'itemLayout'].some(
                        property => message.dataPath.includes(property)
                    )
                    const failedToMatchAnyOf = message.message === 'should match some schema in anyOf'

                    return isCorrespondingProperty || failedToMatchAnyOf
                })

                // @see {@link https://jira.wixpress.com/browse/DM-7151}
                return {issue: 'DM-7151', isWhiteListed}
            }

            return {issue: NOT_LISTED, isWhiteListed: false}
        }
    }
}

export interface ValidationWhitelistExtensionAPI extends ExtensionAPI {
    validationWhitelist: {
        registerContextProvider(name: string, contextProvider: GetErrorContext): void
    }
}

const createExtension = ({dsConfig, experimentInstance}: CreateExtensionArgument): Extension => {
    const contextProviders: Record<string, GetErrorContext> = {}
    const createExtensionAPI = (): ValidationWhitelistExtensionAPI => {
        const registerContextProvider = (name: string, contextProvider: GetErrorContext) => {
            contextProviders[name] = contextProvider
        }
        return {
            validationWhitelist: {
                registerContextProvider
            }
        }
    }

    const initialize = async ({dal, extensionAPI}: DmApis) => {
        if (experimentInstance.isOpen('dm_disableValidationWhitelist')) {
            // This experiment is for the sake of tests only
            // Tests should always fail for strict-mode violations
            return
        }
        const whitelists: Record<string, WhitelistCheckWithContext> = createWhitelists(extensionAPI)
        dal.registrar.registerValidationWhitelistCheck(
            (pointer: Pointer, value: DalValue, validatorResult: ValidatorResult) => {
                if (dsConfig.neverFailStrictValidations) {
                    // added to exclude onboarding from strict-mode
                    return {issue: WARNING_ONLY, isWhiteListed: true}
                } else if (whitelists[validatorResult.type]) {
                    const errorContext = _.mapValues(contextProviders, fn => fn())
                    return whitelists[validatorResult.type](
                        pointer,
                        value,
                        validatorResult,
                        errorContext,
                        dsConfig.origin
                    )
                }
                return {issue: NOT_LISTED, isWhiteListed: false}
            }
        )
    }

    return {
        name: 'validationWhitelist',
        createExtensionAPI,
        initialize
    }
}

export {createExtension}
