import _ from 'lodash'
import relationsUtils from '../variants/relationsUtils'
import dsUtils from '../utils/utils'
import namespaces from '../namespaces/namespaces'
import triggers from '../triggers/triggers'
import constants from '../constants/constants'
import variants from '../variants/variants'
import refComponent from '../refComponent/refComponent'
import reactionsUtils from './reactionsUtils'
import {pointerUtils} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import type {DsItem, Pointer, PS} from '@wix/document-services-types'
import dataSerialization from '../dataModel/dataSerialization'
import variantsUtils from '../variants/variantsUtils'
import {guidUtils} from '@wix/santa-core-utils'

const {REACTIONS, VARIANTS, DATA_TYPES} = constants
const REACTIONS_TYPE = REACTIONS.TYPE
const REACTIONS_NAMESPACE = DATA_TYPES.reactions
const reactionTypesWithState = _.map(REACTIONS.TYPES_WITH_STATE)
const validReactionDataTypes = _.map(REACTIONS.VALID_TYPES)
const reactionTypesWithoutState = _.difference(validReactionDataTypes, reactionTypesWithState)

const {stripHashIfExists} = dsUtils

const convertReactionDataToRef = (reactionDataItem: DsItem): Record<string, any> => {
    const {type, state, effect, presetKey} = reactionDataItem
    if (state) {
        return {type, state: `#${state.id}`, presetKey}
    } else if (effect) {
        return {type, effect: `#${effect.id}`}
    }
    return {type}
}

const getReactionBasedOnType = (
    ps: PS,
    reactionData: DsItem,
    result: Record<string, any>,
    pageId: string
): Record<string, any> => {
    const {data} = ps.pointers
    if (reactionData.state) {
        result.state = data.getVariantsDataItem(stripHashIfExists(reactionData.state), pageId)
        result.presetKey = reactionData.presetKey
    } else if (reactionData.effect) {
        result.effect = data.getEffectsDataItem(stripHashIfExists(reactionData.effect), pageId)
    }
    return result
}

const add = (ps: PS, targetCompWithVariants, triggerPointer, reactionsDataItem) => {
    const reactionsRef = getReactionsRef(ps)
    addWithRef(ps, reactionsRef, targetCompWithVariants, triggerPointer, reactionsDataItem)
}

const addWithRef = (ps: PS, reactionsRef: Pointer, targetCompWithVariants, triggerPointer, reactionsDataItem) => {
    validateReaction(ps, reactionsDataItem, targetCompWithVariants)
    validateTrigger(ps, triggerPointer, targetCompWithVariants)

    const validatedReactionsDataItem = convertReactionDataToRef(reactionsDataItem)
    const validatedCompWithVariants = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)
    const pagePointer = ps.pointers.components.getPageOfComponent(targetCompWithVariants)
    const pageId = pagePointer?.id
    const newReactionsItemId = dataSerialization.addSerializedItemToPage(
        ps,
        pageId,
        validatedReactionsDataItem,
        reactionsRef.id,
        REACTIONS_NAMESPACE
    )

    let reactionsListPointer = variantsUtils.getComponentDataPointerConsideringVariants(
        ps,
        validatedCompWithVariants,
        REACTIONS_NAMESPACE
    )

    if (!ps.dal.isExist(reactionsListPointer)) {
        const newListId = namespaces.updateNamespaceData(
            ps,
            pointerUtils.getRepeatedItemPointerIfNeeded(validatedCompWithVariants),
            REACTIONS_NAMESPACE,
            {
                type: REACTIONS_TYPE,
                values: []
            }
        )
        reactionsListPointer = ps.pointers.getPointer(newListId, REACTIONS_NAMESPACE)
    }
    const reactionsList = ps.dal.get(reactionsListPointer)
    ps.dal.set(reactionsListPointer, {
        ...reactionsList,
        values: reactionsList.values.concat(`#${newReactionsItemId}`)
    })
}

const validateReaction = (ps: PS, reactionsDataItem, targetCompWithVariants) => {
    const {type, state} = reactionsDataItem

    if (!validReactionDataTypes.includes(type)) {
        throw new ReportableError({
            message: 'Invalid reaction type found in one or more items of the reaction data',
            errorType: 'reactionValidation'
        })
    } else if (reactionTypesWithoutState.includes(type)) {
        if (state) {
            throw new ReportableError({
                message: "You can't provide a state with this reaction type",
                errorType: 'reactionValidation'
            })
        }
    } else if (reactionTypesWithState.includes(type)) {
        if (!state) {
            throw new ReportableError({
                message: 'You must provide a state as part of the reaction',
                errorType: 'reactionValidation'
            })
        }

        if (!ps.dal.isExist(state)) {
            throw new ReportableError({message: 'The provided state does not exist', errorType: 'reactionValidation'})
        }

        const {type: stateType, componentId} = ps.dal.get(state)

        if (stateType !== VARIANTS.TYPES.STATE) {
            throw new ReportableError({message: 'Invalid state in reactions data', errorType: 'reactionValidation'})
        }

        const templateCompPointer = refComponent.getTemplateCompPointer(ps, targetCompWithVariants)
        const ownPointer = templateCompPointer ? templateCompPointer : targetCompWithVariants
        const ownCompId = pointerUtils.getRepeatedItemPointerIfNeeded(ownPointer).id

        if (componentId !== ownCompId) {
            throw new ReportableError({
                message: "Cannot add a reaction to a component whose state doesn't belong to it",
                errorType: 'reactionValidation'
            })
        }
    }
}

const validateTrigger = (ps: PS, triggerPointer, compWithVariants) => {
    if (!ps.dal.isExist(triggerPointer) || !variants.getData(ps, triggerPointer)) {
        throw new ReportableError({message: 'Invalid trigger pointer', errorType: 'triggerValidation'})
    }

    const triggerPageId = ps.pointers.data.getPageIdOfData(triggerPointer)
    const targetCompPageId = ps.pointers.components.getPageOfComponent(compWithVariants).id

    if (triggerPageId !== targetCompPageId) {
        throw new ReportableError({
            message: 'Target component and trigger must belong to the same page',
            errorType: 'triggerValidation'
        })
    }
}

const update = (ps: PS, targetCompWithVariants, triggerPointer, reactionPointer, reactionDataItem) => {
    validateReaction(ps, reactionDataItem, targetCompWithVariants)
    validateTrigger(ps, triggerPointer, targetCompWithVariants)

    const reactionDataToUpdate = convertReactionDataToRef(reactionDataItem)
    const compWithVariants = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)
    const compReactionsData = namespaces.getNamespaceData(ps, compWithVariants, REACTIONS_NAMESPACE)

    if (!compReactionsData) {
        throw new ReportableError({message: 'No reactions found for the given component', errorType: 'reactionsUpdate'})
    } else if (!compReactionsData.values.some(reaction => reaction.id === reactionPointer.id)) {
        throw new ReportableError({
            message: "The provided reaction doesn't exist on the given component",
            errorType: 'reactionsUpdate'
        })
    }

    ps.dal.set(reactionPointer, {...reactionDataToUpdate, id: reactionPointer.id})
}

const disable = (ps: PS, targetCompWithVariants, triggerPointer) => {
    removeAll(ps, targetCompWithVariants, triggerPointer)

    const comp = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)
    namespaces.updateNamespaceData(ps, comp, REACTIONS_NAMESPACE, {type: REACTIONS_TYPE, values: []})
}

const get = (ps: PS, triggeringCompWithVariants) => {
    if (!ps.dal.isExist(triggeringCompWithVariants)) {
        return undefined
    }

    const allComponentTriggers = triggers.getAllTriggers(ps, triggeringCompWithVariants)
    if (!allComponentTriggers.length) {
        return undefined
    }

    const allTriggers = allComponentTriggers.map(triggerPtr => {
        const allVariantsArray = _.unionWith(triggeringCompWithVariants.variants, [triggerPtr], _.isEqual)
        const triggerType = variants.getData(ps, triggerPtr).trigger
        const pageId = ps.pointers.data.getPageIdOfData(triggerPtr)
        const relationPointers = _.uniqBy(
            relationsUtils.getRelationsByVariantsAndPredicate(ps, allVariantsArray, REACTIONS_NAMESPACE),
            pointer => pointer.id
        )

        if (!relationPointers.length) {
            return undefined
        }

        return relationPointers.map(relationPtr => {
            const relationData = ps.dal.get(relationPtr)
            const relatedComp = relationsUtils.getComponentFromRelation(ps, relationData, pageId)
            relationsUtils.scopedValuePointer(ps, REACTIONS_NAMESPACE, relationPtr)
            const scopedDataPointer = relationsUtils.scopedValuePointer(ps, REACTIONS_NAMESPACE, relationPtr)
            const scopedReactionsList = ps.dal.get(scopedDataPointer)

            return scopedReactionsList.values
                .map(reactionId => ps.pointers.data.getReactionsDataItem(dsUtils.stripHashIfExists(reactionId), pageId))
                .map(reactionPointer => {
                    const reactionData = ps.dal.get(reactionPointer)
                    const result: any = {
                        type: reactionData.type,
                        component: relatedComp,
                        pointer: reactionPointer,
                        triggerType
                    }
                    return getReactionBasedOnType(ps, reactionData, result, pageId)
                })
        })
    })

    return _.compact(allTriggers).length === 0
        ? undefined
        : _.flatten(allTriggers.map(triggerArray => _.flatten(triggerArray)))
}

const removeAll = (ps: PS, targetCompWithVariants, triggerPointer) => {
    validateTrigger(ps, triggerPointer, targetCompWithVariants)

    const compWithVariants = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)

    reactionsUtils.removeAllReactionsDataFromComp(ps, compWithVariants)
}

const doesComponentHaveReaction = (ps: PS, compPointerWithVariants, reactionPointer) => {
    const scopedReactions = namespaces.getNamespaceData(ps, compPointerWithVariants, REACTIONS_NAMESPACE)
    return (
        ps.dal.isExist(reactionPointer) && scopedReactions?.values.some(reaction => reaction.id === reactionPointer.id)
    )
}

const remove = (ps: PS, targetCompWithVariants, triggerPointer, reactionPointer) => {
    validateTrigger(ps, triggerPointer, targetCompWithVariants)
    const compWithVariants = variants.getPointerWithVariants(ps, targetCompWithVariants, triggerPointer)
    if (doesComponentHaveReaction(ps, compWithVariants, reactionPointer)) {
        const pageId = ps.pointers.components.getPageOfComponent(compWithVariants).id
        reactionsUtils.removeReaction(ps, reactionPointer, pageId)
    }
}

const getReactionsRef = (ps: PS) => {
    return ps.pointers.getPointer(guidUtils.getUniqueId(REACTIONS_NAMESPACE, '-'), REACTIONS_NAMESPACE)
}

export default {
    add,
    addWithRef,
    update,
    disable,
    get,
    removeAll,
    remove,
    getReactionsRef
}
