import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../dataModel/dataModel'
import constants from '../platform/common/constants'
import connectionsDataGetter from '../connections/connectionsDataGetter'
import appControllerStageData from './appControllerStageData'
import appControllerDataItem from './appControllerDataItem'
import appControllerState from './appControllerState'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import notificationService from '../platform/services/notificationService'

function getSettings(ps: PS, controllerRef: Pointer) {
    const controllerData = appControllerDataItem.getControllerDataItem(ps, controllerRef)
    return controllerData.settings ? JSON.parse(controllerData.settings) : {}
}

function getSettingsIn(ps: PS, controllerRef: Pointer, path: string) {
    const settings = getSettings(ps, controllerRef)
    return _.get(settings, path)
}

function getName(ps: PS, controllerRef: Pointer) {
    return _.get(appControllerDataItem.getControllerDataItem(ps, controllerRef), ['name'])
}

function setSettings(ps: PS, controllerRef: Pointer, settingsItem, useOriginalLanguage: boolean = false) {
    let stringifiedSettings
    try {
        stringifiedSettings = JSON.stringify(settingsItem)
    } catch (e) {
        throw new Error('Invalid settings item - should be JSON stringifiable')
    }
    const previousData = getSettings(ps, controllerRef)
    appControllerDataItem.setControllerDataItem(ps, controllerRef, {settings: stringifiedSettings}, useOriginalLanguage)
    notifyApplicationAboutControllerDataChanged(ps, controllerRef, previousData)
}

function notifyApplicationAboutControllerDataChanged(ps: PS, controllerRef: Pointer, previousData) {
    const {applicationId: appDefinitionId} = appControllerDataItem.getControllerDataItem(ps, controllerRef)

    notificationService.notifyApplication(
        ps,
        appDefinitionId,
        platformEvents.factory.componentDataChanged({
            compRef: controllerRef,
            previousData
        })
    )
}

function setSettingsIn(ps: PS, controllerRef: Pointer, path: string, settingsItem) {
    const settings = getSettings(ps, controllerRef)
    _.set(settings, path, settingsItem)
    setSettings(ps, controllerRef, settings)
}

function setName(ps: PS, controllerRef: Pointer, controllerName: string) {
    appControllerDataItem.setControllerDataItem(ps, controllerRef, {name: controllerName})
}

function getControllerStageDataByControllerRef(ps: PS, controllerRef: Pointer) {
    return appControllerStageData.getControllerStageDataByControllerRef(ps, controllerRef)
}

function getControllerRoleStageDataByControllerRefAndRole(
    ps: PS,
    controllerRef: Pointer,
    role: string,
    subRole?: string
) {
    return appControllerStageData.getControllerRoleStageDataByControllerRefAndRole(ps, controllerRef, role, subRole)
}

/**
 * @param {ps} ps
 * @param {Pointer} compPointer
 * @param {Object} options
 * options.usePrimaryRoleOnly - If provided, only primary role of the connection is considered. defaults to false.
 * @returns {*}
 */
function getConnectedComponentStageData(
    ps: PS,
    compPointer: Pointer,
    {usePrimaryRoleOnly = false}: {usePrimaryRoleOnly?: boolean} = {}
) {
    const connection = connectionsDataGetter.getPrimaryConnection(ps, compPointer)
    if (!connection) {
        return
    }

    const role = _.get(connection, ['role'], constants.Controller.WILDCARD_ROLE)
    const subRole = usePrimaryRoleOnly ? null : _.get(connection, ['subRole'])
    return getControllerRoleStageDataByControllerRefAndRole(ps, (connection as any).controllerRef, role, subRole)
}

function setExternalId(ps: PS, controllerRef: Pointer, externalId?: string) {
    externalId = externalId ?? ''
    appControllerDataItem.setControllerDataItem(ps, controllerRef, {externalId})
}

function getExternalId(ps: PS, controllerRef: Pointer) {
    return _.get(appControllerDataItem.getControllerDataItem(ps, controllerRef), ['externalId'])
}

/**
 * Get controllerRef of connection item
 * @param {ps} ps
 * @param connectionItem
 * @param compRef - The connected component (or refComponent in case of closed inner widget)
 */
function getControllerRefByConnectionItem(ps: PS, connectionItem, compRef: Pointer) {
    const controllerDataId = _.get(connectionItem, ['controllerId'])
    const pagePointer = ps.pointers.components.getPageOfComponent(compRef)
    return dataModel.getControllerRefFromId(ps, controllerDataId, pagePointer)
}

export default {
    setSettings,
    getSettings,
    setSettingsIn,
    getSettingsIn,
    getName,
    setName,
    setState: appControllerState.setState,
    /**
     * @param {ps} ps
     * @param controllerRef
     * @returns {any|string}
     */
    getState(ps: PS, controllerRef) {
        return appControllerState.getState(ps, controllerRef.id)
    },
    getControllerStageDataByControllerRef,
    getControllerRoleStageDataByControllerRefAndRole,
    getControllerRoleStageDataByStateRefAndType: appControllerStageData.getControllerRoleStageDataByStateRefAndType,
    getConnectedComponentStageData,
    getControllerRefByConnectionItem,
    setExternalId,
    getExternalId,
    // privateAPI
    getControllerStageData: appControllerStageData.getControllerStageData,
    hasAppManifestByControllerRef: appControllerStageData.hasAppManifestByControllerRef
}
