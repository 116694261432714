import type {Pointer, PS} from '@wix/document-services-types'

export type NicknameContextProvider = (ps: PS, compPointer: Pointer) => null | Pointer

const createRegistrar = () => {
    let nicknameContextProvider: NicknameContextProvider | undefined

    const setContextProvider = (provider: NicknameContextProvider) => {
        nicknameContextProvider = provider
    }

    const getNicknameContext = (ps: PS, compPointer: Pointer) =>
        nicknameContextProvider ? nicknameContextProvider(ps, compPointer) : null

    return {
        setContextProvider,
        getNicknameContext
    }
}

export default {
    createRegistrar
}
