import _ from 'lodash'
import type {SnapshotDal} from '@wix/document-manager-core'

function getAppVersion(immutableSiteData) {
    return immutableSiteData.getIn(['wixapps', 'appbuilder', 'descriptor', 'applicationInstanceVersion'])
}

function getAppInstanceIdFromSnapshotDal(snapshotDal: SnapshotDal) {
    const clientSpecMap = snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
    const appbuilderService = _.find(clientSpecMap, e => e.type === 'appbuilder')
    return _.get(appbuilderService, ['instanceId'])
}

function getAppInstanceIdFromImmutable(immutableSiteData) {
    const appbuilderService = immutableSiteData
        .getIn(['rendererModel', 'clientSpecMap'])
        .find(service => service.get('type') === 'appbuilder')
    return appbuilderService?.get('instanceId')
}

function getAppInstance(siteData) {
    return {
        applicationInstanceId: getAppInstanceIdFromImmutable(siteData),
        applicationInstanceVersion: getAppVersion(siteData)
    }
}

export default {
    getAppInstance,
    getAppInstanceIdFromSnapshotDal,
    getAppInstanceIdFromImmutable
}
