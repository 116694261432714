import type {CompRef, PS} from '@wix/document-services-types'
import variantsUtils from '../variants/variantsUtils'
import experiment from 'experiment-amd'
import constants from '../constants/constants'
import dsUtils from '../utils/utils'
const {DATA_TYPES} = constants

const ERRORS = {
    designOverVariants: componentPointer =>
        `design over variants should not be used, please contact DM group, component pointer: ${componentPointer}`,
    designOverVariantsWithModes: componentPointer =>
        `variant designs should not be used in modes, component pointer: ${componentPointer}`
}

export const COMPONENTS_TYPES_WHITE_LIST_FOR_DESIGN_IN_VARIANTS = {
    'responsive.components.Section': true,
    'wysiwyg.viewer.components.StripColumnsContainer': true,
    'wysiwyg.viewer.components.ClassicSection': true
}

export const isCompTypeIsValidForDesignInVariants = (compType: string) =>
    experiment.isOpen('dm_designOverVariants') && COMPONENTS_TYPES_WHITE_LIST_FOR_DESIGN_IN_VARIANTS[compType]

export const shouldUseDesignInVariants = (ps: PS, componentPointer: CompRef) => {
    const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(ps, componentPointer, DATA_TYPES.design)
    const actualComponentPointer = dsUtils.replaceRuntimeRefWithOriginal(ps, componentPointer)
    const componentType = dsUtils.getComponentType(ps, actualComponentPointer)
    if (shouldConsiderVariants && !isCompTypeIsValidForDesignInVariants(componentType)) {
        throw new Error(ERRORS.designOverVariants(componentPointer))
    }

    return shouldConsiderVariants
}
