function pluginFn(siteAPI, compLayoutRelativeToStructure, compProperties, compPointer) {
    const isVertical = compProperties?.verticalText
    return isVertical
        ? {
              width: siteAPI.getSiteMeasureMap().minWidth[compPointer.id]
          }
        : {
              height: siteAPI.getSiteMeasureMap().minHeight[compPointer.id]
          }
}

export default pluginFn
