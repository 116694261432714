import _ from 'lodash'
import documentModeInfo from '../../documentMode/documentModeInfo'

const BLOG_APP_PAGE_ID = {
    SINGLE_POST: '7326bfbb-4b10-4a8e-84c1-73f776051e10',
    FEED: '79f391eb-7dfc-4adf-be6e-64434c4838d9'
}

/**
 * @param {ps} ps
 * @param pageId
 * @returns {boolean}
 */
function isBlogPage(ps, pageId) {
    const viewMode = documentModeInfo.getViewMode(ps)
    const pageCompPointer = ps.pointers.components.getPage(pageId, viewMode)
    const compTypePointer = ps.pointers.getInnerPointer(pageCompPointer, 'componentType')
    const componentType = ps.dal.get(compTypePointer)

    return componentType === 'wixapps.integration.components.AppPage'
}

function isAppPageWithId(ps, pageId: string, appPageId: string) {
    const pageDataItem: any = _(pageId).thru(ps.pointers.data.getDataItem).thru(ps.dal.get).value()
    return (
        pageDataItem &&
        pageDataItem.type === 'AppPage' &&
        pageDataItem.appPageType === 'AppPage' &&
        pageDataItem.appPageId === appPageId
    )
}

function isBlogSinglePostPage(ps, pageId) {
    return isAppPageWithId(ps, pageId, BLOG_APP_PAGE_ID.SINGLE_POST)
}

function isBlogFeedPage(ps, pageId) {
    return isAppPageWithId(ps, pageId, BLOG_APP_PAGE_ID.FEED)
}

export default {
    isSinglePost: isBlogSinglePostPage,
    isFeed: isBlogFeedPage,
    isBlogPage
}
