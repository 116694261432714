import type {RefArray, BreakpointRelation, VariantRelation} from '@wix/document-services-types'
import {REF_ARRAY_DATA_TYPE, RELATION_DATA_TYPES} from '../constants/constants'
import {removePrefix} from '@wix/document-manager-utils'
import _ from 'lodash'

const REF_PREFIX = '#'
const stripHashIfExists = (query: string) => removePrefix(query, REF_PREFIX)
const {VARIANTS, BREAKPOINTS} = RELATION_DATA_TYPES

const refArray = {
    /**
     * Returns values property of provided object
     *
     * @param {Object} obj
     * @returns {Array}
     */
    extractValues: (obj: RefArray): string[] => _.get(obj, ['values'], []),
    /**
     * Returns values key of provided object stripping hashes(#)
     * @param {Object} obj
     * @returns {string[]}
     */
    extractValuesWithoutHash: (obj: RefArray): string[] => _.map(refArray.extractValues(obj), stripHashIfExists),
    /**
     * Returns reference array object
     * @param {Array} values
     * @returns {{values: Array, type: string}}
     */
    create: (values: any[] = []): Partial<RefArray> => ({
        type: REF_ARRAY_DATA_TYPE,
        values: _.map(values, v => (_.isString(v) ? `#${stripHashIfExists(v)}` : v))
    }),
    /**
     * returns new object, based on obj with provided values property
     *
     * @param {Object} obj
     * @param {Array} values
     * @returns {Object}
     */
    update: (obj: RefArray, values: any[]) => ({
        ...obj,
        values: _.map(values, v => (_.isString(v) ? `#${stripHashIfExists(v)}` : v))
    }),
    /**
     * Check if object has reference type
     *
     * @param {Object} obj
     * @returns {boolean}
     */
    isRefArray: (obj: RefArray): boolean => obj && obj.type === REF_ARRAY_DATA_TYPE
}

const breakpointRelation = {
    extractBreakpoint: (obj: BreakpointRelation) => stripHashIfExists(obj?.breakpoint),
    extractRef: (obj: BreakpointRelation) => obj?.ref,
    extractRefWithoutHash: (obj: BreakpointRelation) => stripHashIfExists(obj?.ref),
    create: (breakpoint: string, ref: string) => ({
        type: BREAKPOINTS,
        breakpoint: `#${stripHashIfExists(breakpoint)}`,
        ref
    }),
    isBreakpointRelation: (obj: BreakpointRelation) => obj?.type === BREAKPOINTS
}
const variantRelation = {
    create: (variants: string[], from: string, to: string) => ({
        type: VARIANTS,
        variants: _.map(variants, v => (_.isString(v) ? `#${removePrefix(v, REF_PREFIX)}` : v)),
        from: `#${from}`,
        to: _.isString(to) ? `#${to}` : to
    }),
    extractVariants: (obj: VariantRelation): string[] | boolean =>
        obj?.variants ? _.map(obj.variants, stripHashIfExists) : false,
    extractTo: (obj: VariantRelation): string => stripHashIfExists(obj?.to),
    extractFrom: (obj: VariantRelation): string => stripHashIfExists(obj?.from),
    isVariantRelation: (obj: VariantRelation): boolean => (obj?.type ? obj.type === VARIANTS : false)
}

export {refArray, variantRelation, breakpointRelation, stripHashIfExists}
