import type {SnapshotDal} from '@wix/document-manager-core'
import {timeout} from '@wix/document-manager-utils'
import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import editorServerFacade from '../editorServerFacade/editorServerFacade'
import {contextAdapter} from '../utils/contextAdapter'

const GET_BRANCHES_REQUEST_TIMEOUT = 1500

const getBranchesList = async ({
    ps = null,
    immutable = null,
    snapshotDal = null
}: {ps?: PS; immutable?; snapshotDal?: SnapshotDal} = {}) => {
    contextAdapter.utils.fedopsLogger.interactionStarted('LIST_BRANCHES_REQUEST')

    try {
        let branchesList
        if (ps) {
            branchesList = await editorServerFacade.sendWithPsAsync(
                ps,
                editorServerFacade.ENDPOINTS.LIST_BRANCHES,
                null
            )
        } else if (immutable) {
            branchesList = await editorServerFacade.sendWithImmutableAsync(
                immutable,
                editorServerFacade.ENDPOINTS.LIST_BRANCHES,
                null
            )
        } else if (snapshotDal) {
            branchesList = await editorServerFacade.sendWithSnapshotDalAsync(
                snapshotDal,
                editorServerFacade.ENDPOINTS.LIST_BRANCHES,
                null
            )
        }
        return branchesList
    } finally {
        contextAdapter.utils.fedopsLogger.interactionEnded('LIST_BRANCHES_REQUEST', {})
    }
}

const getBranchId = ({ps, immutable, snapshotDal}: {ps?: PS; immutable?; snapshotDal?: SnapshotDal}) => {
    try {
        if (ps) {
            return ps.extensionAPI.siteAPI.getBranchId()
        } else if (immutable) {
            return immutable.getIn(['documentServicesModel', 'branchId'])
        } else if (snapshotDal) {
            return snapshotDal.getValue({type: 'documentServicesModel', id: 'branchId'})
        }
    } catch (e) {
        return null
    }
}

const doesHaveBranches = async ({
    ps = null,
    immutable = null,
    snapshotDal = null
}: {ps?: PS; immutable?; snapshotDal?: SnapshotDal} = {}) => {
    const branchId = getBranchId({ps, immutable, snapshotDal})

    if (_.isNil(branchId)) {
        return false
    }

    try {
        return await timeout(
            getBranchesList({ps, immutable, snapshotDal}).then(branchesList => branchesList.branches.length > 1),
            GET_BRANCHES_REQUEST_TIMEOUT
        )
    } catch (e) {
        return true
    }
}

export {doesHaveBranches}
