/**
 * Created by Dan_Shappir on 7/13/15.
 */
import _ from 'lodash'

const create = () => {
    if (typeof window !== 'undefined' && !window.queryUtil?.isParameterTrue('suppressbi')) {
        let newrelic
        try {
            // @ts-ignore
            newrelic = window.parent.newrelic // Use parent for preview in editor
        } catch (e) {
            // @ts-ignore
            newrelic = window.newrelic
        }
        if (newrelic) {
            const timeSinceNavigate = window.performance?.now ? () => Math.round(window.performance.now()) : () => 0

            return {
                setCustomAttribute: newrelic.setCustomAttribute.bind(newrelic),
                addPageAction(name, attributes) {
                    const tsn = timeSinceNavigate()
                    attributes = _.assign({}, attributes, {timeSinceNavigate: tsn})
                    return newrelic.addPageAction(name, attributes)
                },
                finished() {
                    const tsn = timeSinceNavigate()
                    if (tsn) {
                        this.setCustomAttribute('timeSinceNavigate', timeSinceNavigate())
                    }
                    return newrelic.finished.apply(newrelic, arguments)
                }
            }
        }
    }
    return {
        setCustomAttribute: _.noop,
        addPageAction: _.noop,
        finished: _.noop
    }
}

const exportValue = create()

export default exportValue
