import type {Pointer, PS} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import * as santaCoreUtils from '@wix/santa-core-utils'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import _ from 'lodash'
import componentModes from '../../component/componentModes'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import constants from '../../constants/constants'

const isDesktopOnlyComponent = (ps: PS, pageId: string, comp) =>
    !!componentsMetaData.public.getMobileConversionConfigByName(ps, comp, 'desktopOnly', pageId)

function isMobileOnlyComponent(ps: PS, pagePointer: Pointer, component) {
    const componentPointer = ps.pointers.full.components.getComponent(component.id, pagePointer)
    return componentsMetaData.public.isMobileOnly(ps, componentPointer)
}

function getHiddenAndShownComponents(ps: PS, pageId: string) {
    const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
    const pageActiveModesMap = componentModes.getMobileActiveModesMap(ps, pageId)
    const activeModesMap = _.set({}, pageId, pageActiveModesMap)
    const pageStructure = ps.siteAPI.getDeepPageStructure
        ? ps.siteAPI.getDeepPageStructure(pageId)
        : ps.dal.full.get(pagePointer)
    const page = coreUtils.fullToDisplayedJson.getDisplayedJson(pageStructure, activeModesMap, pageId).structure
    if (!page.mobileComponents) {
        return
    }
    const components = santaCoreUtils.dataUtils.getAllCompsInStructure(
        page,
        false,
        _.negate(isDesktopOnlyComponent.bind(null, ps, pageId))
    )
    const mobileComponents = santaCoreUtils.dataUtils.getAllCompsInStructure(
        page,
        true,
        _.negate(isMobileOnlyComponent.bind(null, ps, pagePointer))
    )
    return {
        hiddenComponents: mobileCore.structuresComparer.getComponentIdsAddedToDesktopStructure(
            components,
            mobileComponents
        ),
        shownComponents: _.intersection(_.keys(mobileComponents), _.keys(components))
    }
}

function hasMobileStructure(ps: PS) {
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const mobileChildren = ps.pointers.components.getChildren(masterPagePointer)
    return !_.isEmpty(mobileChildren)
}

export default {
    getHiddenAndShownComponents,
    hasMobileStructure
}
