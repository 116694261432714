import _ from 'lodash'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'

const MULTILINGUAL_DATA_TYPE = 'multilingual'
const MULTILINGUAL_DATA_TYPE_TRANSLATIONS = 'multilingualTranslations'
const MULTILINGUAL_DATA_ROOT_PATH = ['rendererModel', 'sitePropertiesInfo', 'multilingualInfo']

pointerGeneratorsRegistry.registerPointerType(MULTILINGUAL_DATA_TYPE, _.constant(null), _.constant(true))
pointerGeneratorsRegistry.registerPointerType(
    MULTILINGUAL_DATA_TYPE_TRANSLATIONS,
    _.constant(null),
    _.constant(true),
    false,
    true
)

const propMap = propName => (getItemAt, cache) =>
    cache.getPointer(propName, MULTILINGUAL_DATA_TYPE, MULTILINGUAL_DATA_ROOT_PATH.concat(propName))

pointerGeneratorsRegistry.registerDataAccessPointersGenerator(MULTILINGUAL_DATA_TYPE, {
    translationLanguages: propMap('translationLanguages'),
    originalLanguage: propMap('originalLanguage'),
    currentLanguageCode: (getItemAt, cache) =>
        cache.getPointer('currentLanguageCode', MULTILINGUAL_DATA_TYPE, [MULTILINGUAL_DATA_TYPE, 'currentLanguageCode'])
})

pointerGeneratorsRegistry.registerDataAccessPointersGenerator(MULTILINGUAL_DATA_TYPE_TRANSLATIONS, {
    translationDataItem: (getItemAt, cache, pageId, languageCode, componentDataId) =>
        cache.getPointer(
            `${pageId}_${languageCode}_${componentDataId}_translationDataItem`,
            MULTILINGUAL_DATA_TYPE_TRANSLATIONS,
            ['pagesData', pageId, 'translations', languageCode, 'data', 'document_data', componentDataId]
        )
})
