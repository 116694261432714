import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import documentServicesSchemas from 'document-services-schemas'

const {schemasService} = documentServicesSchemas.services

//temporarily taken from componentValidations.js and slightly modified
function compDataTypeFitsCompType(componentType: string, componentData) {
    const compDefinitions = schemasService.getDefinition(componentType)

    if (_.isArray(compDefinitions.dataTypes)) {
        if (_.isPlainObject(componentData)) {
            return componentData.type && _.includes(compDefinitions.dataTypes, componentData.type)
        } else if (_.isString(componentData)) {
            return _.includes(compDefinitions.dataTypes, componentData)
        }
        return _.includes(compDefinitions.dataTypes, '')
    }

    return !componentData
}

function validateCompData(ps: PS, compPointer: Pointer) {
    const componentType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
    const dataItemPointer = dataModel.getDataItemPointer(ps, compPointer)
    const data = dataItemPointer && ps.dal.get(dataItemPointer)
    if (!compDataTypeFitsCompType(componentType, data)) {
        throw new Error(
            `Component data type [${data}` && `${data.type}] is not valid for component type [${componentType}]`
        )
    }
    if (data) {
        //if we don't have data, and no error was thrown, there's nothing else to validate
        documentServicesSchemas.services.dataValidators.validateDataBySchema(data, 'data')
    }
}

export default {
    validateCompData
}
