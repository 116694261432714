import {
    pointerUtils,
    type Extension,
    type InitializeExtArgs,
    type CreateExtArgs,
    type ExtensionAPI
} from '@wix/document-manager-core'
import _ from 'lodash'
import {isPopup} from '../page/popupUtils'
import type {PageExtensionAPI} from '../page'
import {getTranslationLanguageCodes} from '../page/language'
import {
    getAllSyncableMenuPointers,
    updatePageItemFromTranslatedMenu,
    getAllBasicMenuItemsForMenu,
    menuHookChangePageData,
    addMenuItemWithPageLink
} from './menuUtils'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {DataModelExtensionAPI} from '../dataModel'
import {CUSTOM_MENUS, DATA_TYPES, MASTER_PAGE_ID} from '../../constants/constants'
import type {MenuData, MenuItem} from '@wix/document-services-types'

export interface MenuAPI {
    createMenu(newMenuId: string, menuData: Record<string, any>, languageCode?: string): string
    syncMenusOnAddPage(pageId: string, shouldCreatePageMenuItem?: boolean): void
    getAll(languageCode?: string, useOriginalLanguageFallback?: boolean): MenuData[]
    getById(menuId: string, languageCode?: string, useOriginalLanguageFallback?: boolean): any
    updateMenu(item: Record<string, any>, menuId: string, languageCode?: string): void
}
export type MenuExtensionAPI = ExtensionAPI & {
    menus: MenuAPI
}

const createExtension = (): Extension => {
    function mutateItems(mutators: Function[], item: MenuItem) {
        _.forEach(mutators, mutator => {
            mutator(item)
        })
        if (item.items) {
            _.forEach(item.items, subItem => {
                mutateItems(mutators, subItem)
            })
        }
    }

    const withoutId = _.partialRight(_.unset, 'id')
    const withoutLinkId = _.partialRight(_.unset, ['link', 'id'])
    const asBasicMenuItem = _.partialRight(_.set, 'type', 'BasicMenuItem')

    function normalizeMenuItems(menuData: any) {
        _.forEach(menuData.items, item => {
            mutateItems([withoutId, withoutLinkId, asBasicMenuItem], item)
        })
    }

    const updateMenuDataOnPageChanged = (
        extArgs: InitializeExtArgs,
        pageId: string,
        changedData: Record<string, any>,
        useLanguage: string,
        applyChangeToAllLanguages = false
    ) => {
        const {pointers, extensionAPI} = extArgs
        const {page} = extensionAPI as PageExtensionAPI
        if (!isPopup(extArgs, pageId)) {
            const pageData = page.data.get(pageId, useLanguage)
            const translationLanguages = getTranslationLanguageCodes(extArgs)
            const allSyncableMenus = getAllSyncableMenuPointers(extArgs)
            if (applyChangeToAllLanguages) {
                _.flatMap(translationLanguages, lang =>
                    updatePageItemFromTranslatedMenu(
                        extArgs,
                        allSyncableMenus,
                        lang,
                        pageId,
                        useLanguage,
                        changedData,
                        pageData
                    )
                )
            }
            const allBasicMenuItems = _.flatMap(allSyncableMenus, menuPointer =>
                getAllBasicMenuItemsForMenu(extArgs, menuPointer, useLanguage)
            )
            _.forEach(allBasicMenuItems, basicMenuItemData => {
                const basicMenuItemPointer = pointers.data.getDataItemFromMaster(basicMenuItemData.id)
                if (basicMenuItemData.link) {
                    const linkData = basicMenuItemData.link
                    if (linkData.type === 'PageLink' && linkData.pageId === `#${pageId}`) {
                        menuHookChangePageData(extArgs, basicMenuItemPointer, changedData, useLanguage, pageData)
                    }
                }
            })
        }
    }

    const addPageMenuItem = (
        extArgs: InitializeExtArgs,
        pageId: string,
        label: string,
        hideItem: boolean,
        hideItemMobile: boolean
    ) => {
        const {pointers, dal} = extArgs
        const translationLanguages = getTranslationLanguageCodes(extArgs)
        _.forEach(getAllSyncableMenuPointers(extArgs), function (menuPointer) {
            const menuId = menuPointer.id
            const menuItemId = addMenuItemWithPageLink(extArgs, menuId, pageId, label, hideItem, hideItemMobile)
            const menuItem = translationLanguages.length
                ? dal.get(pointers.data.getDataItemFromMaster(menuItemId))
                : null
            _.forEach(translationLanguages, lang => {
                const translatedMenuPointer = pointers.multilingualTranslations.translationDataItem(
                    MASTER_PAGE_ID,
                    lang,
                    menuId
                )
                if (dal.has(translatedMenuPointer)) {
                    const existingDataItem = deepClone(dal.get(translatedMenuPointer))
                    const existingItems = _.get(existingDataItem, ['items'])
                    existingItems.push(`#${menuItemId}`)
                    dal.set(translatedMenuPointer, existingDataItem)
                    dal.set(
                        pointers.multilingualTranslations.translationDataItem(MASTER_PAGE_ID, lang, menuItemId),
                        menuItem
                    )
                }
            })
        })
    }

    const menuHookAddPage = (extArgs: InitializeExtArgs, pageId: string) => {
        const {pointers, dal} = extArgs
        const pageDataPointer = pointers.data.getDataItemFromMaster(pageId)
        const pageData = dal.get(pageDataPointer)

        addPageMenuItem(extArgs, pageData.id, pageData.title, pageData.hidePage, pageData.mobileHidePage)
        return {success: true}
    }

    const syncMenusOnAddPage = (extArgs: InitializeExtArgs, pageId: string, shouldCreatePageMenuItem = true) => {
        if (!isPopup(extArgs, pageId) && shouldCreatePageMenuItem) {
            menuHookAddPage(extArgs, pageId)
        }
    }
    const getAllMenus = ({extensionAPI}: CreateExtArgs) => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const customMenusData = dataModel.getItem(CUSTOM_MENUS, DATA_TYPES.data, MASTER_PAGE_ID)
        return customMenusData.menus
    }

    const getMenuById = (
        {extensionAPI}: CreateExtArgs,
        menuId: string,
        languageCode?: string,
        useOriginalLanguageFallback?: boolean
    ) => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        return dataModel.getItem(menuId, DATA_TYPES.data, MASTER_PAGE_ID, languageCode, useOriginalLanguageFallback)
    }
    const validateMenuExists = ({pointers, dal}: CreateExtArgs, menuId: string) => {
        const menuDataPointer = pointers.data.getDataItemFromMaster(menuId)

        if (!menuId || !dal.has(menuDataPointer)) {
            throw new Error(`Menu with id ${menuId} does not exist.`)
        }
    }

    const updateItem = (extArgs: CreateExtArgs, item: Record<string, any>, menuId: string, languageCode?: string) => {
        validateMenuExists(extArgs, menuId)
        const {dataModel} = extArgs.extensionAPI as DataModelExtensionAPI
        return dataModel.addItem(item, DATA_TYPES.data, MASTER_PAGE_ID, menuId, languageCode)
    }

    const initialize = async (extArgs: InitializeExtArgs) => {
        const {eventEmitter, EVENTS} = extArgs
        eventEmitter.on(
            EVENTS.PAGE.DATA_UPDATE,
            (
                pageId: string,
                data: Record<string, any>,
                languageCodeToUse: string,
                applyChangeToAllLanguages: boolean
            ) => {
                updateMenuDataOnPageChanged(extArgs, pageId, data, languageCodeToUse, applyChangeToAllLanguages)
            }
        )
        eventEmitter.on(EVENTS.PAGE.PAGE_ADDED, (pageId: string, shouldCreatePageMenuItem) => {
            syncMenusOnAddPage(extArgs, pageId, shouldCreatePageMenuItem)
        })
    }

    const createExtensionAPI = (extArgs: CreateExtArgs): MenuExtensionAPI => ({
        menus: {
            createMenu(newMenuId: string, menuData: Record<string, any>, languageCode?: string) {
                const {dataModel} = extArgs.extensionAPI as DataModelExtensionAPI
                if (getMenuById(extArgs, newMenuId, languageCode)) {
                    throw new Error(`Menu with id ${newMenuId} already exist.`)
                }

                normalizeMenuItems(menuData)

                dataModel.addItem(menuData, DATA_TYPES.data, MASTER_PAGE_ID, newMenuId, languageCode)
                const customMenusArrayPointer = pointerUtils.getInnerPointer(
                    extArgs.pointers.data.getDataItem('CUSTOM_MENUS', 'masterPage'),
                    'menus'
                )
                const customMenusArray = extArgs.dal.get(customMenusArrayPointer)
                extArgs.dal.set(customMenusArrayPointer, [...customMenusArray, `#${newMenuId}`])
                return newMenuId
            },
            syncMenusOnAddPage: (pageId, shouldCreatePageMenuItem) =>
                syncMenusOnAddPage(extArgs, pageId, shouldCreatePageMenuItem),
            getAll: () => getAllMenus(extArgs),
            getById: (menuId: string, languageCode?: string, useOriginalLanguageFallback?: boolean) =>
                getMenuById(extArgs, menuId, languageCode, useOriginalLanguageFallback),
            updateMenu: (menu: Record<string, any>, menuId: string, languageCode?: string) =>
                updateItem(extArgs, menu, menuId, languageCode)
        }
    })
    return {
        name: 'menu',
        initialize,
        createExtensionAPI,
        dependencies: new Set(['structure', 'relationships', 'data', 'page'])
    }
}

export {createExtension}
