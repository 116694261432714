import _ from 'lodash'

const ACTION_TO_TEXT_GAP = 34

function calcQuickActionBarLeft(measureMap, compWidth, props, skinExports) {
    if (props.alignment === 'right' && _.has(skinExports, 'qab-right')) {
        return measureMap.width.screen - compWidth - skinExports['qab-right']
    }

    if (_.has(skinExports, 'qab-left')) {
        return skinExports['qab-left']
    }

    return (measureMap.width.screen - compWidth) / 2
}

function calcQuickActionBarTop(measureMap, compHeight, skinExports) {
    return measureMap.clientHeight - compHeight - skinExports['qab-bottom']
}

function calcQuickActionBarWidth(measureMap, layoutWidth, props, compPointer, skinExports) {
    const hasAdditionalWidth =
        !props.hideText && skinExports['text-overflow'] && measureMap.width[`${compPointer.id}maxTextWidth`] > 0

    if (hasAdditionalWidth) {
        return layoutWidth + ACTION_TO_TEXT_GAP + measureMap.width[`${compPointer.id}maxTextWidth`]
    }

    return layoutWidth
}

function quickActionBarRelativeToScreenPluginFn(siteAPI, layout, compProperties, compPointer, skinExports) {
    const measureMap = siteAPI.getSiteMeasureMap()
    const compWidth = calcQuickActionBarWidth(measureMap, layout.width, compProperties, compPointer, skinExports)
    const compHeight = measureMap.height[`${compPointer.id}actionsContainer`]
    const siteX = (measureMap.clientWidth - measureMap.width.screen) / 2
    return {
        x: siteX + calcQuickActionBarLeft(measureMap, compWidth, compProperties, skinExports),
        y: calcQuickActionBarTop(measureMap, compHeight, skinExports),
        width: compWidth,
        height: compHeight
    }
}

export default quickActionBarRelativeToScreenPluginFn
