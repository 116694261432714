import {CreateExtArgs, DAL, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import {schemas} from '@wix/document-services-json-schemas'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {
    ALLOWED_MOBILE_COMPONENTS,
    DEAD_MOBILE_COMPONENT_TYPE,
    METADATA_TYPES,
    VIEW_MODES
} from '../../constants/constants'
import {getComponentIncludingDisplayOnly, getComponentType} from '../../utils/dalUtils'
import {DEFAULTS} from './defaults'
import {metadataMap} from './metadataMap'

const CONTAINER_TYPES = _.keyBy(['Container', 'RefComponent', 'Page'])
export interface ComponentsMetadataAPI extends ExtensionAPI {
    componentsMetadata: {
        canConnectToCode(componentPointer: Pointer): boolean
        isContainer(componentType: string): boolean
        sticksToBottom(componentType: string): boolean
        getDefaultNickname(componentPointer: Pointer): string
        isMobileOnly(componentPointer: Pointer): boolean
        shouldAutoSetNickname(componentPointer: Pointer): boolean
        isNativeMobileOnlyByPointer(componentPointer: Pointer): boolean
    }
}
const getCompOrDefaultMetaData = (compType: string, metadataKey: string) => {
    const metadataValue = _.get(metadataMap, [compType, metadataKey])
    return _.isUndefined(metadataValue) ? DEFAULTS[metadataKey] : metadataValue
}

const createExtension = (): Extension => {
    const getMetaData = (
        createExtArgs: CreateExtArgs,
        metaDataKey: string,
        componentPointer: Pointer,
        ...additionalArguments: any[]
    ) => {
        const {pointers, dal} = createExtArgs
        const compType = getComponentType(pointers, dal, componentPointer)
        let metaDataValue = getCompOrDefaultMetaData(compType, metaDataKey)
        const args = [componentPointer, ...additionalArguments] // remove metaDataKey from arguments, leave additional trailing optional params]

        if (_.isFunction(metaDataValue)) {
            const argsForMetaData = [createExtArgs].concat(args)
            metaDataValue = metaDataValue.apply(this, argsForMetaData)
        }
        return metaDataValue
    }

    const canConnectToCode = (dal: DAL, componentPointer: Pointer): boolean => {
        const {componentType} = getComponentIncludingDisplayOnly(dal, componentPointer)
        return getCompOrDefaultMetaData(componentType, METADATA_TYPES.CAN_CONNECT_TO_CODE)
    }

    const shouldAutoSetNickname = (dal: DAL, componentPointer: Pointer): boolean => {
        const {componentType} = getComponentIncludingDisplayOnly(dal, componentPointer)
        return getCompOrDefaultMetaData(componentType, METADATA_TYPES.SHOULD_AUTO_SET_NICKNAME)
    }

    const isNativeMobileOnlyByPointer = (dal: DAL, componentPointer: Pointer): boolean => {
        const {componentType} = getComponentIncludingDisplayOnly(dal, componentPointer)
        return !!getCompOrDefaultMetaData(componentType, METADATA_TYPES.MOBILE_ONLY)
    }

    const isMobileOnly = (createExtArgs: CreateExtArgs, componentPointer: Pointer) => {
        const {dal, pointers} = createExtArgs
        componentPointer = pointerUtils.getRepeatedItemPointerIfNeeded(componentPointer)
        if (!componentPointer || componentPointer.type !== VIEW_MODES.MOBILE) {
            return false
        }

        const mobileOnlyTypes = _.keys(ALLOWED_MOBILE_COMPONENTS)
        const compType = _.get(dal.get(componentPointer), ['componentType'])
        if (_.includes(mobileOnlyTypes, compType)) {
            return isNativeMobileOnlyByPointer(dal, componentPointer)
        }
        const desktopPointer = pointers.structure.getDesktopPointer(componentPointer)
        const existOnlyInMobile = dal.has(componentPointer) && !dal.has(desktopPointer)
        if (!existOnlyInMobile) {
            return false
        }
        const isPlaceholder = compType === DEAD_MOBILE_COMPONENT_TYPE
        if (isPlaceholder) {
            return false
        }
        return true
    }

    const isContainer = (componentType: string) => {
        return CONTAINER_TYPES.hasOwnProperty(schemas.default.allComponentsDefinitionsMap[componentType]?.type)
    }

    const sticksToBottom = (componentType: string) => componentType === 'responsive.components.FooterSection'

    const getDefaultNickname = (createExtArgs: CreateExtArgs, componentPointer: Pointer) => {
        const {dal} = createExtArgs
        const shouldAddPrefix =
            isMobileOnly(createExtArgs, componentPointer) && !isNativeMobileOnlyByPointer(dal, componentPointer)
        const prefix = shouldAddPrefix ? 'mobile ' : ''
        const defaultNickname = getMetaData(createExtArgs, METADATA_TYPES.NICKNAME, componentPointer)

        return _.camelCase(`${prefix}${defaultNickname}`)
    }

    const createExtensionAPI = (createExtArgs: CreateExtArgs): ComponentsMetadataAPI => {
        const {dal} = createExtArgs
        return {
            componentsMetadata: {
                canConnectToCode: (componentPointer: Pointer) => canConnectToCode(dal, componentPointer),
                isContainer,
                shouldAutoSetNickname: (componentPointer: Pointer) => shouldAutoSetNickname(dal, componentPointer),
                sticksToBottom,
                isMobileOnly: (componentPointer: Pointer) => isMobileOnly(createExtArgs, componentPointer),
                isNativeMobileOnlyByPointer: (componentPointer: Pointer) =>
                    isNativeMobileOnlyByPointer(dal, componentPointer),
                getDefaultNickname: (componentPointer: Pointer) => getDefaultNickname(createExtArgs, componentPointer)
            }
        }
    }

    return {
        name: 'componentsMetadata',
        dependencies: new Set(),
        createExtensionAPI
    }
}

export {createExtension}
