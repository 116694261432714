import type {ApplicationId, CompStructure, PagesStructure, PS} from '@wix/document-services-types'
import _ from 'lodash'
import tpa from 'tpa'
import tpaConstants from './constants'
import pageData from '../page/pageData'
import blankPageStructure from '../page/blankPageStructure'
import clientSpecMapService from './services/clientSpecMapService'
import theme from '../theme/theme'
import component from '../component/component'

const getSectionStructure = function (
    ps: PS,
    definitionData,
    sectionId: string | number,
    tpaPageId: string | number,
    hidePage?: boolean,
    indexable?: boolean,
    landingPageParams: any = {},
    pageUriSEO?: string,
    requireLogin?: boolean,
    managingAppDefId?: string
) {
    const appId = definitionData.applicationId
    //TODO - get this from definitionData
    const defSize = {
        w: 980,
        h: 500
    }

    const pageStructure: PagesStructure = {
        componentType: 'mobile.core.components.Page',
        type: 'Page',
        styleId: 'p2',
        skin: _.get(ps, 'runtimeConfig.tpa.section.defaults.skin', 'skins.core.InlineSkin'),
        layout: {
            x: 0,
            y: 0,
            width: defSize.w,
            height: defSize.h,
            anchors: []
        },
        data: {
            type: 'Page',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            } as any,
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO,
            hidePage: _.isBoolean(hidePage) ? hidePage : undefined,
            // @ts-expect-error
            mobileHidePage: null,
            underConstruction: false,
            tpaApplicationId: appId,
            indexable: _.isBoolean(indexable) ? indexable : true,
            tpaPageId: tpaPageId as string,
            managingAppDefId,
            pageBackgrounds: getPageBackgrounds(ps, definitionData),
            isLandingPage: _.isBoolean(landingPageParams.desktop) ? landingPageParams.desktop : false,
            isMobileLandingPage: _.isBoolean(landingPageParams.mobile) ? landingPageParams.mobile : false
        },
        components: []
    }
    if (requireLogin) {
        pageStructure.data.pageSecurity = {
            requireLogin: true,
            dialogLanguage: 'en'
        }
    }

    return pageStructure
}

const getPageBackgrounds = function (ps: PS, definitionData) {
    if (_.get(definitionData, 'appPage.hideFromMenu')) {
        return blankPageStructure.getBlankBackground()
    }
    return _.get(pageData.getPageDataWithoutIds(ps, ps.siteAPI.getPrimaryPageId()), 'pageBackgrounds')
}

function getLandingPageParams(widgetData) {
    return {
        desktop: widgetData.appPage.fullPage || _.get(widgetData, 'componentFields.fullPageDesktopOnly'),
        mobile: widgetData.appPage.landingPageInMobile
    }
}

const getSubSectionStructure = function (
    ps: PS,
    widgetData,
    sectionId: string,
    pageUriSEO: string,
    requireLogin?: boolean,
    isHidden?: boolean
) {
    const landingPageParams = getLandingPageParams(widgetData)
    const hidePage = clientSpecMapService.computeHidePage(widgetData, isHidden, true)
    const structure = getSectionStructure(
        ps,
        widgetData,
        sectionId,
        widgetData.appPage.id,
        hidePage,
        widgetData.appPage.indexable,
        landingPageParams,
        pageUriSEO,
        requireLogin
    )
    structure.data.title = widgetData.appPage.name
    return structure
}

const getMultiSectionStructure = function (
    ps: PS,
    widgetData,
    sectionId: string,
    pageUriSEO: string,
    requireLogin?: boolean,
    isHidden?: boolean
) {
    const landingPageParams = getLandingPageParams(widgetData)
    const tpaPageId = widgetData.appPage.id + tpa.common.utils.Constants.MULTI_SECTION_DELIMITER + sectionId
    const hidePage = clientSpecMapService.computeHidePage(widgetData, isHidden, false)
    return getSectionStructure(
        ps,
        widgetData,
        sectionId,
        tpaPageId,
        hidePage,
        widgetData.appPage.indexable,
        landingPageParams,
        pageUriSEO,
        requireLogin
    )
}

const getWidgetStructure = function (
    applicationId: ApplicationId,
    appDefinitionId: string,
    widgetId: string,
    layout,
    styleId?: string
) {
    return {
        layout: {
            width: layout?.width,
            height: layout?.height,
            x: layout?.x,
            y: layout?.y,
            anchors: []
        },
        data: {
            widgetId,
            applicationId: applicationId.toString(),
            appDefinitionId,
            type: tpaConstants.DATA_TYPE.TPA_WIDGET,
            metaData: {
                isHidden: false,
                isPreset: true,
                schemaVersion: '1.0'
            }
        },
        type: 'Component',
        skin: tpaConstants.SKINS.TPA_WIDGET,
        componentType: tpaConstants.COMP_TYPES.TPA_WIDGET,
        style: styleId || tpaConstants.STYLE.TPA_WIDGET
    }
}

const getGluedWidgetStructure = function (
    applicationId: ApplicationId,
    appDefinitionId: string,
    widgetData,
    layout,
    styleId?: string
) {
    return {
        layout: {
            width: layout?.width || 300,
            height: layout?.height || 200,
            x: layout?.x || 300,
            y: layout?.y || 120,
            anchors: [],
            fixedPosition: true
        },
        data: {
            widgetId: widgetData.widgetId,
            applicationId: applicationId.toString(),
            appDefinitionId,
            type: tpaConstants.DATA_TYPE.TPA_WIDGET,
            metaData: {
                isHidden: false,
                isPreset: true,
                schemaVersion: '1.0'
            }
        },
        type: 'Component',
        skin: tpaConstants.SKINS.TPA_WIDGET,
        componentType: tpaConstants.COMP_TYPES.TPA_GLUED_WIDGET,
        style: styleId || tpaConstants.STYLE.TPA_GLUED_WIDGET,
        props: {
            placement: widgetData.gluedOptions.placement,
            verticalMargin: widgetData.gluedOptions.verticalMargin,
            horizontalMargin: widgetData.gluedOptions.horizontalMargin,
            type: 'TPAGluedProperties',
            metaData: {
                schemaVersion: '1.0'
            }
        }
    }
}
const convertStyleIdToStyleDef = function (ps: PS, compDef: CompStructure) {
    const {style} = compDef
    if (_.isString(style)) {
        const allStyles = theme.styles.getAllFromAllPages(ps)
        const styleDef = allStyles[style]
        if (!styleDef) {
            const definition = component.getDefinition(ps, compDef.componentType)
            compDef.style = _(definition.styles).keys().head()
            return
        }
        if (styleDef.styleType === 'custom') {
            compDef.style = _.omit(styleDef, ['id']) as any
        }
    }
}

export default {
    getSectionStructure,
    getWidgetStructure,
    getGluedWidgetStructure,
    /**
     * Structure for hidden sub sections
     */
    getSubSectionStructure,
    /**
     * Structure for multi main section
     */
    getMultiSectionStructure,
    convertStyleIdToStyleDef
}
