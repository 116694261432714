import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentDetectorAPI from '../../../componentDetectorAPI/componentDetectorAPI'
import componentStyleAndSkinAPI from '../../../component/componentStylesAndSkinsAPI'
import theme from '../../../theme/theme'
import constants from '../../../constants/constants'
import isSystemStyle from '../../../theme/isSystemStyle'
import {componentTypeAliases} from '@wix/document-services-json-schemas'
import styleFixerUtils from './utils'
import bi from '../../../bi/bi'
import biErrors from '../../../bi/errors.json'

const {
    markSiteAsRunDuplicateStylesFixer,
    getStylesMigrationVersion,
    setStylesMigrationVersion,
    getComponentType,
    getDefaultSystemStyleForComp
} = styleFixerUtils

const hasCustomStyle = (ps: PS) => compPointer => {
    const styleIdPointer = ps.pointers.getInnerPointer(compPointer, 'styleId')
    const compStyleId = ps.dal.full.get(styleIdPointer)
    // for performance reasons we only check the component.styleId here
    // wixapps styles will return true here and will be filtered later
    return compStyleId && !isSystemStyle(compStyleId)
}

const reportComponentWithoutStylesAndSkins = (ps: PS, compPointer, componentType) => {
    const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
    bi.error(ps, biErrors.COMPONENT_WITHOUT_STYLES_AND_SKINS, {
        componentType,
        pageId
    })
}
const isSkinValidForComponentType = (ps: PS, componentType: string, skin) => {
    const skins = theme.skins.getComponentSkins(ps, componentType)
    return _.includes(skins, skin)
}

// try to guess the best default style for a component in the following order:
// - look for system styles in componentDefinitionMap
// - look for skins in  componentDefinitionMap
// - remove the styleId + report BI (no component should ever get here)
const setComponentDefaultSystemStyle = (ps: PS, compPointer) => {
    const componentType = getComponentType(ps, compPointer)
    const newStyleId = getDefaultSystemStyleForComp(ps, compPointer)
    if (newStyleId) {
        componentStyleAndSkinAPI.style.internal.setId(ps, compPointer, newStyleId, _.noop, true)
        return newStyleId
    }
    const defaultSkin = _.head(theme.skins.getComponentSkins(ps, componentType))
    if (defaultSkin) {
        const styleToAdd = componentStyleAndSkinAPI.style.internal.createComponentStyleDef(
            ps,
            defaultSkin,
            componentType
        )
        return componentStyleAndSkinAPI.style.internal.fork(ps, compPointer, styleToAdd, true)
    }
    ps.dal.remove(ps.pointers.getInnerPointer(compPointer, 'styleId'))
    reportComponentWithoutStylesAndSkins(ps, compPointer, componentType)
}

const fixComponentClassName = (ps: PS, compPointer, componentType, style, shouldFork) => {
    const newStyle = _.defaults(
        {
            componentClassName: componentType
        },
        style,
        {style: {properties: {}}}
    )
    if (shouldFork) {
        return componentStyleAndSkinAPI.style.internal.fork(ps, compPointer, newStyle, true)
    }
    return componentStyleAndSkinAPI.style.internal.update(ps, compPointer, newStyle, null, true)
}

const forkComponentStyle = (ps: PS, compPointer, styleToMigrate) => {
    const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
    const originStyleDef = theme.styles.get(ps, styleToMigrate, pageId)
    return componentStyleAndSkinAPI.style.internal.fork(ps, compPointer, originStyleDef, true)
}

const getCompStyle = (ps: PS, compPointer: Pointer) => {
    const pageId = ps.pointers.components.getPageOfComponent(compPointer).id
    const compStyleId = ps.dal.full.get(ps.pointers.getInnerPointer(compPointer, 'styleId'))
    return theme.styles.internal.get(ps, compStyleId, pageId, true)
}

/**
 * @param ps
 * @return {Pointer[]} pointers to components with custom styles
 */
const getCompsWithCustomStyle = (ps: PS) =>
    _.uniqBy(
        componentDetectorAPI.getAllComponentsFromFull(ps, null, hasCustomStyle(ps), constants.VIEW_MODES.DESKTOP),
        'id'
    )

const fixComponentStyleOrCollect = (ps: PS) => (acc, compPointer) => {
    const style = getCompStyle(ps, compPointer)

    // fix component with missing style
    if (!style) {
        setComponentDefaultSystemStyle(ps, compPointer)
        return acc
    }

    // wixapps uses top level styles that are not system styles
    if (style.type !== constants.STYLES.COMPONENT_STYLE) {
        return acc
    }

    const componentType = getComponentType(ps, compPointer)
    if (componentTypeAliases.getAlias(style.componentClassName) !== componentType) {
        // we can try to fix styles that have the wrong componentClassName
        // if their skin is valid for that componentType

        // e.g. some Repeaters are added from the add-panel with an style that has a
        // componentClassName = "mobile.core.components.Container" :: invalid for Repeater
        // skin = "wysiwyg.viewer.skins.area.DefaultAreaSkin" :: valid for Repeater
        // we can just fix the componentClassName
        if (isSkinValidForComponentType(ps, componentType, style.skin)) {
            acc[style.id] = acc[style.id] || []
            const shouldFork = acc[style.id].length > 0
            if (!shouldFork) {
                //first time we're here, lets add it. Next time, we will just fork this component when we fix it
                acc[style.id].push(compPointer)
            }
            fixComponentClassName(ps, compPointer, componentType, style, shouldFork)
            return acc
        }

        // as last resort we create a default style for the component
        setComponentDefaultSystemStyle(ps, compPointer)
        return acc
    }

    // At this point, style exists, is a ComponentStyle, with a valid componentClassName

    // collect all styles and components so we can fix duplications later
    acc[style.id] = acc[style.id] || []
    acc[style.id].push(compPointer)
    return acc
}

const fixDuplicateOrMissingStyles = (ps: PS) => (compPointers: Pointer[], styleId: string) => {
    // when multiple components point to the same style we fork all the styles and recreate them
    // in the page of the component
    if (compPointers.length > 1) {
        return _.forEach(compPointers, compPointer => {
            // fix component with duplicate styles
            forkComponentStyle(ps, compPointer, styleId)
        })
    }
    const compPointer = _.head(compPointers)
    const compPageId = ps.pointers.components.getPageOfComponent(compPointer).id
    // a component might be pointing to a single style that is not is its page
    const stylePointer = ps.pointers.data.getThemeItem(styleId, compPageId)
    // santa and bolt look for the item in all pages if it wasn't in the given page
    // so this will return the actual pageId where the data is
    const stylePageId = ps.pointers.data.getPageIdOfData(stylePointer)
    if (compPageId !== stylePageId) {
        forkComponentStyle(ps, compPointer, styleId)
    }
}

export default {
    // a bug in ds made so we were able to add component with styles that already exist and not
    // create a new style item every time.
    // this caused some components to share the same styleId (in the same page or in different pages)
    // this fixer tries to fix the document so that each component will have its own styleId
    exec(ps: PS) {
        const versionBeforeFix = getStylesMigrationVersion(ps)
        try {
            markSiteAsRunDuplicateStylesFixer(ps)

            const compsWithPossiblyCustomStyle = getCompsWithCustomStyle(ps)
            const styleIdToComponents = _.reduce(compsWithPossiblyCustomStyle, fixComponentStyleOrCollect(ps), {})

            _(styleIdToComponents).forEach(fixDuplicateOrMissingStyles(ps))
        } catch (e) {
            setStylesMigrationVersion(ps, versionBeforeFix)
            const err = new Error('Failed to migrate site duplicate styles')
            ps.extensionAPI.logger.captureError(err, {
                tags: {
                    duplicateCustomStylesFixer: true
                },
                extras: {
                    originalError: e
                }
            })
        }
    },
    name: 'duplicateCustomStylesDataFixer',
    version: 1
}
