import _ from 'lodash'
import {namespaceMapping} from '@wix/document-services-json-schemas'

const {
    NAMESPACE_MAPPING,
    VIEWER_PAGE_DATA_TYPES,
    DATA_TYPES,
    VIEWER_DATA_TYPES,
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    COMP_DATA_QUERY_KEYS
} = namespaceMapping

const PAGE_DATA_TYPES = _.omit(NAMESPACE_MAPPING, 'default')
const PAGE_SCHEMA = {
    structure: 'structure',
    data: 'data',
    translations: 'translations',
    title: 'title',
    pageUriSEO: 'pageUriSEO'
}

const SCOPE = 'scope'

const MULTILINGUAL_TYPES = {
    multilingualTranslations: 'multilingualTranslations'
}

const DM_POINTER_TYPES = {
    pageDM: 'pageDM',
    deletedPagesMap: 'deletedPagesMap'
}

const VIEWER_DATA_TYPE_TO_POINTER_TYPE = _.transform(
    VIEWER_PAGE_DATA_TYPES,
    (acc: any, v: string, k: string) => {
        acc[v] = k
    },
    {}
)

const PAGE_DATA_TYPE_TO_POINTER_TYPE = _.transform(
    PAGE_DATA_TYPES,
    (acc: any, v: string, k: string) => {
        acc[v] = k
    },
    {}
)

const VIEW_MODES = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE'
} as const

const ALLOWED_MOBILE_COMPONENTS = {
    'wysiwyg.viewer.components.mobile.TinyMenu': 'wysiwyg.viewer.components.mobile.TinyMenu',
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton':
        'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton',
    'wysiwyg.viewer.components.BackToTopButton': 'wysiwyg.viewer.components.BackToTopButton',
    'wysiwyg.viewer.components.mobile.ExitMobileModeButton': 'wysiwyg.viewer.components.mobile.ExitMobileModeButton',
    'wysiwyg.viewer.components.QuickActionBar': 'wysiwyg.viewer.components.QuickActionBar',
    'wysiwyg.viewer.components.QuickActionBarItem': 'wysiwyg.viewer.components.QuickActionBarItem',
    'wysiwyg.viewer.components.MenuToggle': 'wysiwyg.viewer.components.MenuToggle',
    'wysiwyg.viewer.components.MenuContainer': 'wysiwyg.viewer.components.MenuContainer',
    'wysiwyg.viewer.components.ExpandableMenu': 'wysiwyg.viewer.components.ExpandableMenu'
}

const COMP_IDS = {
    PAGE_GROUP: 'SITE_PAGES',
    PAGES_CONTAINER: 'PAGES_CONTAINER',
    HEADER: 'SITE_HEADER',
    FOOTER: 'SITE_FOOTER',
    BACKGROUND: 'SITE_BACKGROUND',
    QUICK_ACTION_BAR: 'QUICK_ACTION_BAR',
    WIX_ADS: 'WIX_ADS',
    WINDOW: 'WINDOW'
}

const LANDING_PAGES_COMP_IDS = {
    PAGES_CONTAINER: 'PAGES_CONTAINER',
    QUICK_ACTION_BAR: 'QUICK_ACTION_BAR'
}

const MASTER_PAGE_ID = 'masterPage'

const SNAPSHOTS = {
    DAL_INITIAL: 'initialState',
    BEFORE_AUTOSAVE_APPLY: 'before-autosave-apply',
    MOBILE_MERGE: 'mobileMerge'
}

const CHILDREN_PROPERTY_NAMES = ['mobileComponents', 'components', 'children']

const RELATION_DATA_TYPES = {
    VARIANTS: 'VariantRelation',
    BREAKPOINTS: 'BreakpointRelation'
}

const REF_ARRAY_DATA_TYPE = 'RefArray'

const REPEATER_STRUCTURE_TYPE = 'RepeaterContainer'

const UNDOABLE_TYPES = {
    ..._.keyBy([
        'save',
        'wixCodeUndoable',
        'platform',
        'rendererModel',
        'deletedPagesMap',
        'pagesPlatformApplications',
        'appsState'
    ]),
    ...PAGE_DATA_TYPES,
    ...VIEW_MODES,
    ...MULTILINGUAL_TYPES
}

const NON_UNDOABLE_KEYS = {
    save: _.keyBy(['publishSaveInitiator', 'silentSaveInitiator']),
    rendererModel: _.keyBy([
        'accessiblePagesToken',
        'clientSpecMap',
        'geo',
        'locale',
        'mediaAuthToken',
        'metaSiteId',
        'pageList',
        'pageListTopology',
        'passwordProtectedPages',
        'platformAppsOnPage',
        'premiumFeatures',
        'previewMode',
        'runningExperiments',
        'seo',
        'siteInfo',
        'siteMediaToken',
        'sitePropertiesInfo',
        'urlFormatModel',
        'useSandboxInHTMLComp',
        'userId',
        'wixCodeModel'
    ])
}

export const MOBILE_ONLY_COMPS = {
    TINY_MENU: 'TINY_MENU',
    MENU_AS_CONTAINER: 'MENU_AS_CONTAINER',
    MENU_AS_CONTAINER_TOGGLE: 'MENU_AS_CONTAINER_TOGGLE',
    BACK_TO_TOP_BUTTON: 'BACK_TO_TOP_BUTTON',
    MENU_AS_CONTAINER_EXPANDABLE_MENU: 'MENU_AS_CONTAINER_EXPANDABLE_MENU',
    QUICK_ACTION_BAR: 'QUICK_ACTION_BAR'
}

const WIX_CODE_SHARED = {
    SHARED_PATH_CHANGES_MAP: 'sharedPathChangesMap'
}

const PAGE_TYPES = {
    Page: 'Page',
    AppPage: 'AppPage'
}

const BASE_PROPS_SCHEMA_TYPE = 'DefaultProperties'

const DATA_TYPES_VALUES_MAP = _.mapKeys(DATA_TYPES, _.identity)
const DATA_TYPES_VALUES_WITH_HASH = _.pick(DATA_TYPES_VALUES_MAP, [
    DATA_TYPES.design,
    DATA_TYPES.layout,
    DATA_TYPES.data,
    DATA_TYPES.breakpoints
])
const PERMISSIONS = {
    SAVE: 'html-editor.save'
}

export const METADATA_TYPES = {
    CAN_CONNECT_TO_CODE: 'canConnectToCode',
    MOBILE_ONLY: 'mobileOnly',
    NICKNAME: 'nickname',
    SHOULD_AUTO_SET_NICKNAME: 'shouldAutoSetNickname'
}

export const COMP_TYPES = {
    CONTROLLER_TYPE: 'platform.components.AppController',
    APP_WIDGET_TYPE: 'platform.components.AppWidget',
    REF_TYPE: 'wysiwyg.viewer.components.RefComponent'
}
export const VARIANTS = {
    VARIANT_TYPES: {
        BREAKPOINT: 'BreakpointRange',
        STATE: 'State',
        HOVER: 'Hover',
        TRIGGER: 'Trigger',
        MOBILE: 'Mobile',
        VARIANTS_LIST: 'VariantsList'
    },
    TRIGGER_TYPES: ['click', 'tap', 'mouse-in', 'mouse-out', 'hover', 'viewport-enter', 'viewport-leave'],
    VARIANTS_QUERY: 'variantsQuery',
    MOBILE_VARIANT_ID: 'MOBILE-VARIANT'
}

export const DATA_ITEM_TYPES = {
    PRESET_DATA: 'PresetData'
}
const DEAD_MOBILE_COMPONENT_TYPE = 'to.be.removed.by.MobileMerge'
const CONTROLLER_TYPE = 'platform.components.AppController'
const APP_WIDGET_TYPE = 'platform.components.AppWidget'
const CONTROLLER_DATA = {
    DEFAULT_STATE: 'default',
    CONNECTIONS: 'connections',
    WILDCARD_ROLE: '*'
}
const CUSTOM_MENUS = 'CUSTOM_MENUS'

const NICKNAMES = {
    VALIDATIONS: {
        VALID: 'VALID',
        ALREADY_EXISTS: 'ALREADY_EXISTS',
        TOO_SHORT: 'TOO_SHORT',
        TOO_LONG: 'TOO_LONG',
        INVALID_NAME: 'INVALID_NAME'
    }
}

export {
    BASE_PROPS_SCHEMA_TYPE,
    DM_POINTER_TYPES,
    PAGE_DATA_TYPES,
    VIEWER_DATA_TYPE_TO_POINTER_TYPE,
    PAGE_DATA_TYPE_TO_POINTER_TYPE,
    VIEWER_DATA_TYPES,
    VIEWER_PAGE_DATA_TYPES,
    DATA_TYPES,
    VIEW_MODES,
    COMP_IDS,
    LANDING_PAGES_COMP_IDS,
    MASTER_PAGE_ID,
    SNAPSHOTS,
    MULTILINGUAL_TYPES,
    CHILDREN_PROPERTY_NAMES,
    PAGE_SCHEMA,
    REPEATER_STRUCTURE_TYPE,
    RELATION_DATA_TYPES,
    REF_ARRAY_DATA_TYPE,
    UNDOABLE_TYPES,
    NON_UNDOABLE_KEYS,
    WIX_CODE_SHARED,
    PAGE_TYPES,
    COMP_DATA_QUERY_KEYS,
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPES_VALUES_WITH_HASH,
    PERMISSIONS,
    ALLOWED_MOBILE_COMPONENTS,
    DEAD_MOBILE_COMPONENT_TYPE,
    DATA_TYPES_VALUES_MAP,
    CONTROLLER_DATA,
    CONTROLLER_TYPE,
    APP_WIDGET_TYPE,
    SCOPE,
    NICKNAMES,
    CUSTOM_MENUS
}
