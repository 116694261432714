const extensionsList: string[] = [
    'schema',
    'logger',
    'environment',
    'relationships',
    'dataModel',
    'snapshots',
    'documentServicesModel',
    'data',
    'dataAccess',
    'rendererModel',
    'structure',
    'menu',
    'page',
    'multilingual',
    'platform',
    'tpa',
    'saveState',
    'wixCodeSharedFileState',
    'platformSharedState',
    'mobile',
    'livePreviewSharedState',
    'validationWhitelist',
    'dataFixerVersioning',
    'validations',
    'variables',
    'slots',
    'componentsMetadata',
    'connections',
    'routers',
    'theme',
    'components',
    'gridLayout',
    'defaultDefinitions',
    'internalRef',
    'variants',
    'refOverrides',
    'appController',
    'mobilePresets',
    'patterns',
    'wixCode',
    'nicknames',
    'mobileConversion',
    'effects',
    'importExport',
    'features',
    'actionsAndBehaviors',
    'structureMetaData',
    'remoteStructureMetaData',
    'blocks',
    'schemaValidator'
]

export default {
    extensionsList
}
