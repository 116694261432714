import {removePrefix, ReportableError} from '@wix/document-manager-utils'
import type {Pointer, PS} from '@wix/document-services-types'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import _ from 'lodash'
import constants from '../constants/constants'
import mlUtils from './multilingual'

const masterPage = 'wysiwyg.viewer.components.WSiteStructure'
const repeaterType = 'wysiwyg.viewer.components.Repeater'

const REF_PREFIX = '#'

const SPECIAL_COMP_TYPES = {
    masterPage
}

const {DATA_TYPES, COMP_DATA_QUERY_KEYS} = constants

function pointerToString(componentPointer: Pointer) {
    if (!componentPointer) {
        return '(null)'
    }
    let s = `type: ${componentPointer.type} id: ${componentPointer.id}`
    if ('innerPath' in componentPointer) {
        s += ` inner: ${componentPointer.innerPath}`
    }
    return s
}

let prevPointer: Pointer
let prevType

function getComponentType(ps: PS, componentPointer: Pointer): string | undefined {
    if (componentPointer) {
        const specialCompType = SPECIAL_COMP_TYPES[componentPointer.id]
        if (specialCompType) {
            return specialCompType
        }

        if (componentPointer === prevPointer) {
            return prevType
        }

        const typePointer = ps.pointers.getInnerPointer(componentPointer, 'componentType')
        const result = ps.dal.full.get(typePointer) || ps.dal.get(typePointer)
        if (result || ps.dal.full.isExist(componentPointer)) {
            prevPointer = componentPointer
            prevType = result
            return result
        }
    }
    throw new ReportableError({
        errorType: 'nonExistingCompPointer',
        message: `non existing component pointer: ${pointerToString(componentPointer)}`
    })
}

function isLivePreviewActive(ps: PS) {
    return ps.config.origin !== 'appBuilder'
}

function replaceRuntimeRefWithOriginal(ps: PS, compPointer: Pointer) {
    if (!ps || !compPointer || !isLivePreviewActive(ps)) {
        return compPointer
    }
    const itemId = displayedOnlyStructureUtil.getRepeaterItemId(compPointer.id)
    if (itemId) {
        if (isRepeaterRuntimeItem(ps, itemId, compPointer)) {
            const templateId = displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id)
            return {...compPointer, id: templateId}
        }
    }
    return compPointer
}

function getCompData(ps: PS, compPointer: Pointer) {
    const dataQueryPtr = ps.pointers.getInnerPointer(compPointer, COMP_DATA_QUERY_KEYS[DATA_TYPES.data])
    const dataQuery = stripHashIfExists(ps.dal.get(dataQueryPtr))

    if (dataQuery) {
        const originalLanguage = mlUtils.getLanguageByUseOriginal(ps, true)

        const pagePointer = ps.pointers.full.components.getPageOfComponent(compPointer)

        const dataItemPtr = ps.pointers.data.getItem(DATA_TYPES.data, dataQuery, pagePointer.id)
        //This is a temp fix for DM-3181 should be removed when issue is really fixed
        dataItemPtr.useLanguage = originalLanguage

        return ps.dal.full.get(dataItemPtr)
    }
}

function isRepeaterRuntimeItem(ps: PS, itemId: string, compPointer: Pointer): boolean {
    const repeaterPointer = ps.pointers.components.getAncestorByPredicate(
        compPointer,
        parentPointer => getComponentType(ps, parentPointer) === repeaterType
    )

    if (!repeaterPointer) {
        return false
    }

    const staticItems = _.get(getCompData(ps, repeaterPointer), ['items'], [])

    return !_.includes(staticItems, itemId)
}

/**
 * @param {string} input
 * @param {boolean} strict - if true, will return null incase input is not a string (default true)
 * @return {string|null}
 */
function stripHashIfExists(input: string, strict: boolean = true): string | null {
    const defaultValue = strict ? null : input
    return _.isString(input) ? removePrefix(input, REF_PREFIX) : defaultValue
}

const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

function isGuid(value: string) {
    return guidRegex.test(value)
}

export interface SuccessResult {
    success: true
}

export interface ErrorResult {
    success: false
    error: string
}

export type Result = SuccessResult | ErrorResult

export const isError = (r: Result): r is ErrorResult => r.success === false

export type YesNoDC = 'yes' | 'no' | "don't care"

export default {
    YES: 'yes' as YesNoDC,
    NO: 'no' as YesNoDC,
    DONT_CARE: "don't care" as YesNoDC,

    stripHashIfExists,
    getComponentType,
    replaceRuntimeRefWithOriginal,
    isGuid
}
