import type {CreateExtension, DmApis, PostTransactionOperation} from '@wix/document-manager-core'
import type {DocumentServicesModelExtApi} from '../documentServicesModel'
import type {CSaveApi} from './continuousSave'

const createExtension: CreateExtension = () => {
    let currentRevision: number
    return {
        name: 'revisionManager',
        dependencies: new Set(['documentServicesModel', 'continuousSave']),
        async initialize(dmApis: DmApis): Promise<void> {
            const {siteAPI} = dmApis.extensionAPI as DocumentServicesModelExtApi
            currentRevision = siteAPI.getSiteRevision()
        },
        createPostTransactionOperations(dmApis: DmApis): Record<string, PostTransactionOperation> {
            return {
                revisionChange: () => {
                    const {siteAPI} = dmApis.extensionAPI as DocumentServicesModelExtApi
                    const newRevision = siteAPI.getSiteRevision()
                    if (newRevision !== currentRevision) {
                        currentRevision = newRevision
                        const {continuousSave} = dmApis.extensionAPI as CSaveApi
                        continuousSave.onRevisionChange()
                    }
                }
            }
        }
    }
}

export {createExtension}
