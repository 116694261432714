import generatedEditorSkinsData from 'editorSkinsData'
import experiment from 'experiment-amd'
import _ from 'lodash'
import {getComponentsEditorParams as getEditorElementsComponentsEditorParams} from '@wix/editor-elements-preview-utils/componentsEditorParams'

let componentsEditorParams: Record<string, any> = null

export const getComponentsEditorParams = () => {
    if (!componentsEditorParams) {
        componentsEditorParams = _.merge(
            generatedEditorSkinsData,
            getEditorElementsComponentsEditorParams({experiment})
        )
    }

    return componentsEditorParams
}
