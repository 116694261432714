import _ from 'lodash'
import tpaBi from './common/tpaBi'
import tpaPostMessageCommon from './common/tpaPostMessageCommon'
import * as utils from '@wix/santa-ds-libs/src/utils'
import tpaNavigationService from './services/tpaNavigationService'
import tpaComponents from './tpaComponents/tpaComponents'

export default {
    bi: tpaComponents.bi,
    gluedWidgetMeasuringUtils: tpaComponents.gluedWidgetMeasuringUtils,
    common: _.assign({}, tpaComponents.common, {
        tpaPostMessageCommon,
        styleUtils: utils.styleUtils,
        bi: tpaBi
    }),
    services: {
        tpaNavigationService
    },
    tpaStyleUtils: utils.styleUtils
}
