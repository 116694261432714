import * as _ from 'lodash'
import * as conversionUtils from './conversionUtils'
import {conversionConfig} from './conversionConfig'
import * as mobileMerge from './mobileMerge/mobileMerge'
import {syncPage} from './mobileMerge/mobileSync'
import * as mergeUtils from './mobileMerge/mergeUtils'
import {rescaleComponent} from './structureConverter/structureConverter'
import {preProcessStructure} from './structurePreprocessor'
import {postProcessStructure, removeConversionDataRecursively} from './structurePostprocessor'
import {analyzeStructure} from './analyzer/structureAnalyzer'
import {convertMasterPage, fixMasterPageLayout, fixMasterPageLayoutAndOrder} from './masterPageConverter'
import {ConversionSettings, DeepStructure} from '../types'
import {getChildren} from './conversionUtils'

export function synchronizePage(desktopPage: DeepStructure, mobilePage: DeepStructure): DeepStructure[] {
    syncPage(desktopPage, mobilePage)
    mergeUtils.synchronizeDataQueries(desktopPage, mobilePage)
    if (mobilePage.id === 'masterPage') {
        const mobileComps = conversionUtils.getChildren(mobilePage)
        const desktopComps = conversionUtils.getChildren(desktopPage)
        _.forEach(mobileComps, comp => _.set(comp, 'conversionData', _.get(_.find(desktopComps, {id: comp.id}), 'conversionData')))
        fixMasterPageLayout(mobileComps)
    }
    removeConversionDataRecursively(<DeepStructure>mobilePage)
    return <DeepStructure[]>conversionUtils.getChildren(mobilePage)
}

export function convertPage(page: DeepStructure, settings: ConversionSettings): DeepStructure[] {
    const components = conversionUtils.getChildren(page)
    preProcessStructure(page, components, settings)
    analyzeStructure(page, settings)
    if (conversionUtils.isMasterPage(page)) {
        convertMasterPage(<DeepStructure>page)
    } else {
        rescaleComponent(<DeepStructure>page, conversionConfig.MOBILE_WIDTH)
    }
    postProcessStructure(<DeepStructure>page, components)
    removeConversionDataRecursively(page)
    return <DeepStructure[]>conversionUtils.getChildren(page)
}

type SetCompProperty = (component: DeepStructure) => void
class StructureManager {
    private readonly parentsMap: Record<string, string>
    private setToComponent: SetCompProperty
    constructor() {
        this.parentsMap = {}
    }
    initialize(setToComponent: SetCompProperty) {
        this.setToComponent = setToComponent
    }
    addToStructure(container: DeepStructure | undefined, component: DeepStructure) {
        this.setToComponent(component)
        this.parentsMap[component.id] = container?.id
        _.forEach(getChildren(component), child => this.addToStructure(component, child))
    }

    cleanComponent(component: DeepStructure) {
        const [realChildren, duplicateChildren] = _.partition(getChildren(component), child => this.parentsMap[child.id] === component.id)
        conversionUtils.removeChildrenFrom(component, duplicateChildren)
        _.forEach(realChildren, child => this.cleanComponent(child))
    }

    getRealChildren(component: DeepStructure) {
        const children = getChildren(component)
        _.forEach(children, child => this.cleanComponent(child))
        return children
    }
}

const createStructureManager = mobileStructure => {
    const structureManager = new StructureManager()
    structureManager.initialize(component => _.set(component, ['conversionData', 'structureManager'], structureManager))
    structureManager.addToStructure(undefined, mobileStructure)
    return structureManager
}

export function mergePage(desktopStructure: DeepStructure, mobileStructure: DeepStructure, settings: ConversionSettings): DeepStructure[] {
    const structureManager = createStructureManager(mobileStructure)
    mergeUtils.synchronizeDataQueries(desktopStructure, mobileStructure)
    mobileMerge.mergePage(desktopStructure, mobileStructure, settings)
    if (mobileStructure.id === 'masterPage') {
        const comps = conversionUtils.getChildren(mobileStructure)
        const desktopComps = conversionUtils.getChildren(desktopStructure)
        _.forEach(comps, comp => _.set(comp, 'conversionData', _.get(_.find(desktopComps, {id: comp.id}), 'conversionData')))
        if (settings.fixMasterPageStructureOrder) {
            fixMasterPageLayoutAndOrder(comps)
        } else {
            fixMasterPageLayout(comps)
        }
    }

    removeConversionDataRecursively(mobileStructure)
    return structureManager.getRealChildren(mobileStructure)
}

export function inspectStructure(structure: DeepStructure, settings: ConversionSettings): void {
    analyzeStructure(structure, settings)
}
