import type {PageList} from '@wix/document-services-types'

export const buildPageList = (pageList: PageList, partialPages: string[]): PageList => {
    if (partialPages.length <= 0) {
        return pageList
    }

    const set = new Set(partialPages)
    return {
        ...pageList,
        pages: pageList.pages.filter(rmPage => set.has(rmPage.pageId))
    }
}
