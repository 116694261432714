import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as mobileCore from '@wix/mobile-conversion'
import imageClientApi from '@wix/image-client-api/dist/imageClientApi'
import dsUtils from '../utils/utils'
import popupUtils from '../page/popupUtils'
import documentServicesSchemas from 'document-services-schemas'
import legacyFullWidthContainers from './legacyFullWidthContainers'
import appControllerStageData from '../appControllerData/appControllerStageData'
import design from '../variants/design'
import constants from '../constants/constants'

const masterPage = 'wysiwyg.viewer.components.WSiteStructure'

const SITE_SEGMENTS = [
    'wysiwyg.viewer.components.SiteSegmentContainer',
    'wysiwyg.viewer.components.FooterContainer',
    'wysiwyg.viewer.components.HeaderContainer',
    'wysiwyg.viewer.components.PagesContainer'
]

const NON_CONTAINABLE_FULL_WIDTH = {
    'wysiwyg.viewer.components.ScreenWidthContainer': true,
    'wysiwyg.viewer.components.StripContainerSlideShow': true,
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': true,
    'wysiwyg.viewer.components.StateStrip': true,
    'wysiwyg.viewer.components.StateStripState': true
}

const HEADER_FOOTER_PAGE_AND_MASTERPAGE = [
    'wysiwyg.viewer.components.FooterContainer',
    'wysiwyg.viewer.components.HeaderContainer',
    'mobile.core.components.Page',
    'core.components.Page',
    'wixapps.integration.components.AppPage',
    masterPage
]

const COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE = [
    'wysiwyg.viewer.components.BoxSlideShowSlide',
    'wysiwyg.viewer.components.BoxSlideShow',
    'wysiwyg.viewer.components.StateBoxState',
    'wysiwyg.viewer.components.StateBoxFormState',
    'wysiwyg.viewer.components.StateBox',
    'wysiwyg.viewer.components.StateStripState',
    'wysiwyg.viewer.components.StateStrip',
    'wysiwyg.viewer.components.StripContainerSlideShowSlide',
    'wysiwyg.viewer.components.StripContainerSlideShow',
    'wysiwyg.viewer.components.StripColumnsContainer',
    'wysiwyg.viewer.components.HoverBox',
    'wysiwyg.common.components.anchor.viewer.Anchor',
    'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu',
    'wysiwyg.viewer.components.tpapps.TPAWidget',
    'wysiwyg.viewer.components.tpapps.TPASection',
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    'wixapps.integration.components.AppPart',
    'wixapps.integration.components.AppPart2'
]

const CONTAINERS_ALLOWD_TO_HAVE_BREAKPOINTS = [
    'core.components.Page',
    'mobile.core.components.Page',
    'wixapps.integration.components.AppPage',
    'responsive.components.Section',
    'responsive.components.HeaderSection',
    'responsive.components.FooterSection',
    'responsive.components.MembersAreaSection',
    'wysiwyg.viewer.components.PopupContainer',
    'wysiwyg.viewer.components.tpapps.TPAWidget',
    'wysiwyg.viewer.components.tpapps.TPASection',
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    'wysiwyg.viewer.components.MenuContainer'
]

const SITE_SEGMENT_CONTAINERS = ['wysiwyg.viewer.components.ClassicSection']

const CONTAINERS_SUITABLE_FOR_NON_RENDERING_STATE = ['wysiwyg.viewer.components.FormContainer']

function sanitizeQuery(dataQuery: string): string {
    return _.isString(dataQuery) && dataQuery.startsWith('#') ? dataQuery.slice(1) : dataQuery
}

const metaDataUtils = {
    COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE,

    isSiteStructure(componentType: string) {
        return componentType === masterPage
    },

    isSiteSegment(componentType: string) {
        return _.includes(SITE_SEGMENTS, componentType)
    },

    isContainer(compType: string) {
        return documentServicesSchemas.services.isContainer(compType)
    },

    isLegacyFullWidthContainer(ps: PS, compPtr: Pointer) {
        const compType = dsUtils.getComponentType(ps, compPtr)
        const isLegacyFullWidthContainer = legacyFullWidthContainers[compType]

        if (_.isFunction(isLegacyFullWidthContainer)) {
            return isLegacyFullWidthContainer(ps, compPtr)
        }

        return !!isLegacyFullWidthContainer
    },

    isLegacyFullWidthContainerByType(compType: string) {
        return !!(legacyFullWidthContainers[compType] && !_.isFunction(legacyFullWidthContainers[compType]))
    },

    isHeaderOrFooterOrPageOrMasterPage(componentType: string) {
        return _.includes(HEADER_FOOTER_PAGE_AND_MASTERPAGE, componentType)
    },

    isAllowedToHaveBreakpoints(componentType: string) {
        return (
            this.isHeaderOrFooterOrPageOrMasterPage(componentType) ||
            _.includes(CONTAINERS_ALLOWD_TO_HAVE_BREAKPOINTS, componentType)
        )
    },

    isSiteSegmentContainer(componentType: string) {
        return (
            this.isHeaderOrFooterOrPageOrMasterPage(componentType) || _.includes(SITE_SEGMENT_CONTAINERS, componentType)
        )
    },

    isComponentSuitableForNonRenderingState(componentType: string) {
        return !_.includes(COMPONENTS_NOT_SUITABLE_FOR_NON_RENDERING_STATE, componentType)
    },

    isContainerSuitableForNonRenderingState(componentType: string) {
        return _.includes(CONTAINERS_SUITABLE_FOR_NON_RENDERING_STATE, componentType)
    },

    isPopupPageOrPopupContainer(ps: PS, compPointer: Pointer) {
        return (
            popupUtils.isPopup(ps, compPointer.id) ||
            dsUtils.getComponentType(ps, compPointer) === 'wysiwyg.viewer.components.PopupContainer'
        )
    },

    isNonContainableFullWidth(componentType: string) {
        return !!(NON_CONTAINABLE_FULL_WIDTH[componentType] && !_.isFunction(NON_CONTAINABLE_FULL_WIDTH[componentType]))
    },

    getComponentType: dsUtils.getComponentType,

    getComponentNickname(ps: PS, compPointer: Pointer) {
        const componentType = ps.dal.full.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
        if (!componentType) {
            return ''
        }

        const baseNicknameFromDefinitionsMap = _.get(
            documentServicesSchemas.services.schemasService.getDefinition(componentType),
            'nickname'
        )

        if (baseNicknameFromDefinitionsMap) {
            return baseNicknameFromDefinitionsMap
        }
        return _.camelCase(_.last(componentType.split('.')))
    },

    getChildrenTypesDeep(descendants = [], ps?: PS): string[] {
        if (!descendants.length) {
            return []
        }

        return _.flatMap(descendants, descendant => {
            if (_.isString(descendant)) {
                const descendantPointer = ps.pointers.getPointer(descendant, constants.VIEW_MODES.DESKTOP)
                descendant = ps?.dal.get(descendantPointer)
            }
            const {componentType} = descendant
            const childrenTypesDeep = metaDataUtils.getChildrenTypesDeep(descendant.components, ps)
            return [componentType].concat(childrenTypesDeep)
        })
    },

    containableByFullWidthPopup(ps: PS, comp, containerPointer: Pointer) {
        const pagePointer = ps.pointers.components.getPageOfComponent(containerPointer)

        return !(popupUtils.isPopup(ps, pagePointer.id) && !popupUtils.isPopupFullWidth(ps, pagePointer))
    },

    notContainableByPopup(ps: PS, comp, containerPointer: Pointer) {
        const pagePointer = ps.pointers.components.getPageOfComponent(containerPointer)

        return !popupUtils.isPopup(ps, pagePointer.id)
    },

    isMaintainSvgAspectRatio(ps: PS, compPtr: Pointer) {
        const compStructure = ps.dal.get(compPtr)
        const page = ps.pointers.components.getPageOfComponent(compPtr)
        const compPropsPointer = ps.pointers.data.getPropertyItem(
            dsUtils.stripHashIfExists(compStructure.propertyQuery),
            page.id
        )
        const compProps = ps.dal.get(compPropsPointer)

        return compProps?.displayMode === 'fit'
    },

    isMaintainWPhotoAspectRatio(ps: PS, comp, pageId: string) {
        const {STRETCH, LEGACY_FULL} = imageClientApi.fittingTypes
        const propsPointer = ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(comp.propertyQuery), pageId)
        const {autoFill, displayMode} = ps.dal.get(propsPointer) || {}

        return !autoFill && displayMode !== STRETCH && displayMode !== LEGACY_FULL
    },

    isMaintainVideoBoxAspectRatio(compDesign) {
        const mediaFeatures = _.get(compDesign, ['background', 'mediaRef', 'mediaFeatures'], [])

        return _.includes(mediaFeatures, 'alpha')
    },

    isMembersMenu(ps: PS, compStructure, pageId: string) {
        const {dataQuery} = compStructure
        const dataItemPointer = ps.pointers.data.getDataItem(sanitizeQuery(dataQuery), pageId)
        const dataItem = ps.dal.get(dataItemPointer) || {}
        return dataItem.menuRef === '#MEMBERS_SUB_MENU'
    },

    getVideoBoxAspectRatio(compDesign) {
        const qualities = _.get(compDesign, ['background', 'mediaRef', 'qualities'], [])
        const {width, height} = (_.maxBy(qualities, 'width') ?? {}) as any
        return width / height
    },
    getFromStageData(ps: PS, stageDataKey: string, compRef: Pointer, defaultValue?) {
        const hasAppManifest = appControllerStageData.hasAppManifestByControllerRef(ps, compRef)
        if (hasAppManifest) {
            const valueFromStageData = _.get(
                appControllerStageData.getControllerStageDataByControllerRef(ps, compRef),
                ['behavior', stageDataKey]
            )
            return !_.isNil(valueFromStageData) ? valueFromStageData : defaultValue
        }
        return defaultValue
    },

    isTogglingShowOnAllPages(ps: PS, compRef: Pointer, potentialContainerPointer: Pointer) {
        if (_.get(compRef, 'id') && _.get(potentialContainerPointer, 'id')) {
            return (
                (ps.pointers.components.isInMasterPage(compRef) &&
                    !ps.pointers.components.isInMasterPage(potentialContainerPointer)) ||
                (!ps.pointers.components.isInMasterPage(compRef) &&
                    ps.pointers.components.isInMasterPage(potentialContainerPointer))
            )
        }
    },
    getMobileFixedSizesForColumn(ps: PS, comp, pageId: string) {
        const width = mobileCore.conversionConfig.MOBILE_WIDTH
        const compDesign = design.getComponentDefaultDesign(ps, comp, pageId)
        let media = _.get(compDesign, ['background', 'mediaRef'])

        media = (media && (media.posterImageRef || media)) || {}
        return media.height && media.width ? {width, height: (width * media.height) / media.width} : null
    },
    getControllerLayoutLimits(ps: PS, compPointer: Pointer) {
        const controllerStageData = appControllerStageData.getControllerStageDataByControllerRef(ps, compPointer)
        const isMobileView = compPointer.type === constants.VIEW_MODES.MOBILE
        return controllerStageData?.layoutLimits?.[isMobileView ? 'mobile' : 'desktop']
    }
}

export default metaDataUtils
