import _ from 'lodash'
import experiment from 'experiment-amd'
import undoRedoUtils from '../utils/undoRedo'
import wixCodeMonitoring from './wixCodeMonitoringWrapper'
import constants from '../utils/constants'
import saveCodeTaskConsts from '../saveTasks/saveCodeConsts'
import type {SnapshotDal} from '@wix/document-manager-core'
import type {Pointer, PS} from '@wix/document-services-types'

function readPointerIfExists(ps: PS, pointer: Pointer, defaultValue) {
    return ps.dal.isExist(pointer) ? ps.dal.get(pointer) : defaultValue
}

function movePointer(ps: PS, sourcePointer: Pointer, targetPointer: Pointer) {
    if (ps.dal.isExist(sourcePointer)) {
        ps.dal.set(targetPointer, ps.dal.get(sourcePointer))
        ps.dal.remove(sourcePointer)
    }
}

function deletePointer(ps: PS, pointer: Pointer) {
    if (ps.dal.isExist(pointer)) {
        ps.dal.remove(pointer)
    }
}

function belongsToFolder(folderPath: string) {
    const ensuredFolderPath = folderPath.replace(/\/*$/, '/')
    return function (itemPath) {
        return _.startsWith(itemPath, ensuredFolderPath)
    }
}

function writeFile(ps: PS, filePath: string, newContent) {
    if (_.isUndefined(newContent)) {
        const traceEnd = wixCodeMonitoring.trace(ps, {action: 'filesDAL.writeFile', message: {filePath}})
        traceEnd({message: new Error('about to set file content to undefined'), level: wixCodeMonitoring.levels.ERROR})
    }

    const modifiedContentPointer = ps.pointers.wixCode.getModifiedFileContent(filePath)
    const isNewFile = !isFileExists(ps, filePath)
    ps.dal.set(modifiedContentPointer, newContent)
    removeDuplication(ps, filePath)

    if (undoRedoUtils.isUndoableFile(filePath)) {
        undoRedoUtils.setUndoableContent(ps, filePath, newContent)
        if (isNewFile && experiment.isOpen('dm_updateLoadedFilesCacheSyncForSchemas')) {
            loadFileContent(ps, filePath, newContent)
        }
    }
}

function loadFileContent(ps: PS, filePath, content) {
    if (_.isUndefined(content)) {
        const traceEnd = wixCodeMonitoring.trace(ps, {action: 'filesDAL.loadFileContent', message: {filePath}})
        traceEnd({message: new Error('about to set file content to undefined'), level: wixCodeMonitoring.levels.ERROR})
    }

    const loadedContentPointer = ps.pointers.wixCode.getLoadedFileContent(filePath)
    ps.dal.set(loadedContentPointer, content)
}

function readUndoableFile(ps: PS, filePath) {
    const loadedContent = readPointerIfExists(ps, ps.pointers.wixCode.getLoadedFileContent(filePath), null)
    const nonUndoableModifiedContent = readPointerIfExists(
        ps,
        ps.pointers.wixCode.getModifiedFileContent(filePath),
        null
    )
    const undoableModifiedContent = undoRedoUtils.getUndoableContent(ps, filePath)

    if (loadedContent === null && nonUndoableModifiedContent === null && undoableModifiedContent === null) {
        return null
    }

    return undoRedoUtils.assembleUndoableFile(
        filePath,
        loadedContent,
        nonUndoableModifiedContent,
        undoableModifiedContent
    )
}

function readFile(ps: PS, filePath) {
    if (undoRedoUtils.isUndoableFile(filePath)) {
        return readUndoableFile(ps, filePath)
    }

    const contentPointer = _.find(
        [
            // order matters
            ps.pointers.wixCode.getModifiedFileContent(filePath),
            ps.pointers.wixCode.getLoadedFileContent(filePath)
        ],
        ps.dal.isExist
    )

    return contentPointer ? ps.dal.get(contentPointer) : null
}

function removeDuplication(ps: PS, filePath) {
    const duplicatesPointer = ps.pointers.wixCode.getDuplicatedFilesInfoMap()
    const duplicatesMap = ps.dal.get(duplicatesPointer)
    if (duplicatesMap?.[filePath]) {
        ps.dal.set(duplicatesPointer, _.omit(duplicatesMap, [filePath]))
    }
}

function getSourceFilePath(ps: PS, filePath) {
    const duplicatesPointer = ps.pointers.wixCode.getDuplicatedFilesInfoMap()
    const duplicatesMap = ps.dal.get(duplicatesPointer)

    while (duplicatesMap[filePath]) {
        filePath = duplicatesMap[filePath]
    }

    return filePath
}

function markFileForDuplication(ps: PS, sourceFilePath, targetFilePath) {
    const duplicatesPointer = ps.pointers.wixCode.getDuplicatedFilesInfoMap()
    const duplicatesMap = ps.dal.get(duplicatesPointer)

    duplicatesMap[targetFilePath] = sourceFilePath

    ps.dal.set(duplicatesPointer, duplicatesMap)
}

function updateDuplicates(ps: PS, filePath, content) {
    const duplicatesPointer = ps.pointers.wixCode.getDuplicatedFilesInfoMap()
    const duplicatesMap = ps.dal.get(duplicatesPointer)
    const pathsToUpdate = _.transform(
        duplicatesMap,
        function (result, sourceFilePath, targetFilePath) {
            if (targetFilePath === filePath && !isFileExists(ps, sourceFilePath)) {
                result.push(sourceFilePath)
            } else if (sourceFilePath === filePath && !isFileExists(ps, targetFilePath)) {
                result.push(targetFilePath)
            }
        },
        []
    )

    _.forEach(pathsToUpdate, function (path) {
        loadFileContent(ps, path, content)
    })

    _.forEach(pathsToUpdate, function (path) {
        updateDuplicates(ps, path, content)
    })
}

function moveFile(ps: PS, currentFilePath, targetFilePath) {
    if (isFileExists(ps, targetFilePath)) {
        throw new Error(`target file [${targetFilePath}] already exists`)
    }

    _.forEach(
        [
            ps.pointers.wixCode.getLoadedFileContent,
            ps.pointers.wixCode.getModifiedFileContent,
            ps.pointers.wixCode.getFilePathId
        ],
        function (pointerGetter) {
            movePointer(ps, pointerGetter(currentFilePath), pointerGetter(targetFilePath))
        }
    )
}

function deleteFile(ps: PS, filePath) {
    deletePointer(ps, ps.pointers.wixCode.getLoadedFileContent(filePath))
    deletePointer(ps, ps.pointers.wixCode.getModifiedFileContent(filePath))
    deletePointer(ps, ps.pointers.wixCode.getFilePathId(filePath))
    removeDuplication(ps, filePath)
}

function deleteFolder(ps: PS, folderPath) {
    _.forEach(
        [
            ps.pointers.wixCode.getLoadedFileContentMap(),
            ps.pointers.wixCode.getModifiedFileContentMap(),
            ps.pointers.wixCode.getFilePathToIdMap(),
            ps.pointers.wixCode.getAreChildrenLoadedMap(),
            ps.pointers.wixCode.getLoadedChildrenMap()
        ],
        function (fileMapPointer) {
            const existingFileMap = ps.dal.get(fileMapPointer)
            const filePathsToDelete = _(existingFileMap).keys().filter(belongsToFolder(folderPath)).value()
            const mapWithoutFolderChildren = _.omit(existingFileMap, filePathsToDelete)
            ps.dal.set(fileMapPointer, mapWithoutFolderChildren)
        }
    )
}

function isFileExists(ps: PS, filePath) {
    return _.some(
        [ps.pointers.wixCode.getLoadedFileContent(filePath), ps.pointers.wixCode.getModifiedFileContent(filePath)],
        ps.dal.isExist
    )
}

function isFileReadable(ps: PS, filePath) {
    if (undoRedoUtils.isUndoableFile(filePath)) {
        return ps.dal.isExist(ps.pointers.wixCode.getLoadedFileContent(filePath))
    }

    return _.some(
        [ps.pointers.wixCode.getLoadedFileContent(filePath), ps.pointers.wixCode.getModifiedFileContent(filePath)],
        ps.dal.isExist
    )
}

function isChildrenExists(ps: PS, parentFolder) {
    return ps.dal.isExist(ps.pointers.wixCode.getLoadedChildren(parentFolder))
}

function getChildren(ps: PS, parentFolder) {
    const childrenPointer = ps.pointers.wixCode.getLoadedChildren(parentFolder)
    return childrenPointer ? ps.dal.get(childrenPointer) : null
}

function applyAddChild(children, itemDescriptor) {
    return children.concat([itemDescriptor])
}

function applyRemoveChild(children, itemDescriptor) {
    return _.reject(children, {name: itemDescriptor.name, directory: itemDescriptor.directory})
}

function addChild(ps: PS, parentFolderPath, itemDescriptor) {
    const childrenPointer = ps.pointers.wixCode.getLoadedChildren(parentFolderPath)
    const prevChildren = ps.dal.get(childrenPointer) || []
    const existingItem = _.find(prevChildren, {name: itemDescriptor.name, directory: itemDescriptor.directory})

    if (!existingItem) {
        const nextChildren = sortChildren(applyAddChild(prevChildren, itemDescriptor))
        ps.dal.set(childrenPointer, nextChildren)
    }
}

function removeChild(ps: PS, parentFolderPath, itemDescriptor) {
    const childrenPointer = ps.pointers.wixCode.getLoadedChildren(parentFolderPath)
    const prevChildren = ps.dal.get(childrenPointer) || []
    const nextChildren = applyRemoveChild(prevChildren, itemDescriptor)
    ps.dal.set(childrenPointer, nextChildren)
}

function hasKnownChild(ps: PS, parentFolderPath, itemDescriptor) {
    const childrenPointer = ps.pointers.wixCode.getLoadedChildren(parentFolderPath)
    const children = ps.dal.get(childrenPointer) || []
    const requestedItem = _.find(children, {name: itemDescriptor.name, directory: itemDescriptor.directory})
    return !!requestedItem
}

function sortChildren(children) {
    return _.orderBy(children, ['directory', 'name'], ['desc', 'asc'])
}

function loadChildren(ps: PS, parentFolderPath, loadedChildren) {
    const childrenPointer = ps.pointers.wixCode.getLoadedChildren(parentFolderPath)
    const prevChildren = ps.dal.get(childrenPointer) || []
    const childrenToSet = sortChildren(mergeChildren(prevChildren, loadedChildren))
    ps.dal.set(childrenPointer, childrenToSet)
    ps.dal.set(ps.pointers.wixCode.getAreChildrenLoaded(parentFolderPath), true)
}

function mergeChildren(cachedChildren, loadedChildren) {
    return _.unionBy(cachedChildren, loadedChildren, 'name')
}

function areChildrenLoaded(ps: PS, folderPath) {
    return !!ps.dal.get(ps.pointers.wixCode.getAreChildrenLoaded(folderPath))
}

function _getFromSnapshot(snapshot, path) {
    const value = snapshot?.getIn(path)
    return value ? value.toJS() : null
}

function _getModifiedFileContents(loadedContents, modifiedContents, undoableModifiedContents) {
    return _.mapValues(modifiedContents, function (value, filePath) {
        return undoRedoUtils.isUndoableFile(filePath)
            ? undoRedoUtils.assembleUndoableFile(
                  filePath,
                  loadedContents[filePath],
                  modifiedContents[filePath],
                  undoableModifiedContents[filePath]
              )
            : modifiedContents[filePath]
    })
}

function _getModifiedFileContentsInSnapshotDataProvider(dataProvider) {
    if (!dataProvider) {
        return null
    }
    const loadedContents = dataProvider.wixCodeNonUndoable(['loadedFileContents'])
    const modifiedContents = dataProvider.wixCodeNonUndoable(['modifiedFileContents'])
    const undoableModifiedContentsById = dataProvider.wixCodeUndoable(['modifiedFileContents'])
    const filePathToIdMap = dataProvider.wixCodeNonUndoable(['filePathToIdMap'])
    const undoableModifiedContents = undoRedoUtils.getUndoableContentByPath(
        undoableModifiedContentsById,
        filePathToIdMap
    )
    return _getModifiedFileContents(loadedContents, modifiedContents, undoableModifiedContents)
}

function _getModifiedFileContentsInSnapshot(snapshot) {
    if (!snapshot) {
        return null
    }
    const loadedContents = _getFromSnapshot(snapshot, constants.paths.LOADED_FILE_CONTENTS)
    const modifiedContents = _getFromSnapshot(snapshot, constants.paths.MODIFIED_FILE_CONTENTS)
    const undoableModifiedContentsById = _getFromSnapshot(snapshot, constants.paths.UNDOABLE_MODIFIED_FILE_CONTENTS)
    const filePathToIdMap = _getFromSnapshot(snapshot, constants.paths.FILE_PATH_TO_ID_MAP)
    const undoableModifiedContents = undoRedoUtils.getUndoableContentByPath(
        undoableModifiedContentsById,
        filePathToIdMap
    )
    return _getModifiedFileContents(loadedContents, modifiedContents, undoableModifiedContents)
}

function _getModifiedFileContentsFromDal(ps: PS) {
    const loadedContents = readPointerIfExists(ps, ps.pointers.wixCode.getLoadedFileContentMap(), {})
    const modifiedContents = readPointerIfExists(ps, ps.pointers.wixCode.getModifiedFileContentMap(), {})
    const undoableModifiedContentsById = readPointerIfExists(
        ps,
        ps.pointers.wixCode.getUndoableModifiedFileContentMap(),
        {}
    )
    const filePathToIdMap = readPointerIfExists(ps, ps.pointers.wixCode.getFilePathToIdMap(), {})
    const undoableModifiedContents = undoRedoUtils.getUndoableContentByPath(
        undoableModifiedContentsById,
        filePathToIdMap
    )
    return _getModifiedFileContents(loadedContents, modifiedContents, undoableModifiedContents)
}

function _getModifiedFileContentsFromSnapshotDal(pointers, snapshotDal) {
    const loadedContents = snapshotDal.getValue(pointers.wixCode.getLoadedFileContentMap())
    const modifiedContents = snapshotDal.getValue(pointers.wixCode.getModifiedFileContentMap())
    const undoableModifiedContentsById = snapshotDal.getValue(pointers.wixCode.getUndoableModifiedFileContentMap())
    const filePathToIdMap = snapshotDal.getValue(pointers.wixCode.getFilePathToIdMap())
    const undoableModifiedContents = undoRedoUtils.getUndoableContentByPath(
        undoableModifiedContentsById,
        filePathToIdMap
    )
    return _getModifiedFileContents(loadedContents, modifiedContents, undoableModifiedContents)
}

function _getToSave(prevModifiedFiles, currentModifiedFiles) {
    return _.transform(
        currentModifiedFiles,
        function (acc, content, fileId) {
            const prevContent = _.get(prevModifiedFiles, fileId)
            if (content !== prevContent) {
                acc.push({fileId, content})
            }
        },
        []
    )
}

function _getToCopy(prevDuplicatesMap, currentDuplicatesMap) {
    return _.transform(
        currentDuplicatesMap,
        function (acc, srcFileId, destFileId) {
            if (_.get(prevDuplicatesMap, destFileId)) {
                return // saved in earlier run
            }
            // calculate original source
            let fileId = srcFileId
            while (currentDuplicatesMap[fileId]) {
                fileId = currentDuplicatesMap[fileId]
            }
            acc.push({destFileId, srcFileId: fileId})
        },
        []
    )
}

function getChangesBetweenSnapshotDataProviders(lastDataProvider, currentDataProvider) {
    const modifiedFilesInLastSnapshot = _getModifiedFileContentsInSnapshotDataProvider(lastDataProvider)
    const modifiedFilesInCurrentSnapshot = _getModifiedFileContentsInSnapshotDataProvider(currentDataProvider)

    const prevDuplicatesMap = lastDataProvider?.wixCodeNonUndoable(['duplicatedFilesInfo']) || {}
    const currentDuplicatesMap = currentDataProvider.wixCodeNonUndoable(['duplicatedFilesInfo'])

    const directoryFlagByDeletedPath = currentDataProvider.wixCodeNonUndoable(['directoryFlagByDeletedPath']) || {}

    return {
        toSave: _getToSave(modifiedFilesInLastSnapshot, modifiedFilesInCurrentSnapshot),
        toCopy: _getToCopy(prevDuplicatesMap, currentDuplicatesMap),
        toDelete: directoryFlagByDeletedPath
    }
}

function getChangesBetweenSnapshots(lastSnapshot, currentSnapshot) {
    const modifiedFilesInLastSnapshot = _getModifiedFileContentsInSnapshot(lastSnapshot)
    const modifiedFilesInCurrentSnapshot = _getModifiedFileContentsInSnapshot(currentSnapshot)

    const prevDuplicatesMap = lastSnapshot?.getIn(constants.paths.DUPLICATED_FILES_INFO)?.toJS() || {}
    const currentDuplicatesMap = currentSnapshot.getIn(constants.paths.DUPLICATED_FILES_INFO)?.toJS() || {}

    const directoryFlagByDeletedPath =
        currentSnapshot.getIn(constants.paths.DIRECTORY_FLAG_BY_DELETED_PATH)?.toJS() || {}

    return {
        toSave: _getToSave(modifiedFilesInLastSnapshot, modifiedFilesInCurrentSnapshot),
        toCopy: _getToCopy(prevDuplicatesMap, currentDuplicatesMap),
        toDelete: directoryFlagByDeletedPath
    }
}

function getChangesBetweenSnapshotDals(pointers, lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal) {
    const modifiedFilesInLastSnapshot = _getModifiedFileContentsFromSnapshotDal(pointers, lastSnapshotDal)
    const modifiedFilesInCurrentSnapshot = _getModifiedFileContentsFromSnapshotDal(pointers, currentSnapshotDal)

    const prevDuplicatesMap = lastSnapshotDal?.getValue(pointers.wixCode.getDuplicatedFilesInfoMap()) || {}
    const currentDuplicatesMap = currentSnapshotDal.getValue(pointers.wixCode.getDuplicatedFilesInfoMap()) || {}

    const directoryFlagByDeletedPath =
        currentSnapshotDal.getValue(pointers.wixCode.getDirectoryFlagByDeletedPathMap()) || {}

    return {
        toSave: _getToSave(modifiedFilesInLastSnapshot, modifiedFilesInCurrentSnapshot),
        toCopy: _getToCopy(prevDuplicatesMap, currentDuplicatesMap),
        toDelete: directoryFlagByDeletedPath
    }
}

function getChangesBetweenLastSnapshotAndCurrentStateTakenFromPS(lastSnapshot, ps: PS) {
    const modifiedFilesInLastSnapshot = _getModifiedFileContentsInSnapshot(lastSnapshot)
    const modifiedFilesInCurrentSnapshot = _getModifiedFileContentsFromDal(ps)

    const prevDuplicatesMap = lastSnapshot?.getIn(constants.paths.DUPLICATED_FILES_INFO)
        ? lastSnapshot.getIn(constants.paths.DUPLICATED_FILES_INFO).toJS()
        : {}
    const currentDuplicatesMap = ps.dal.get(ps.pointers.wixCode.getDuplicatedFilesInfoMap())

    const directoryFlagByDeletedPath = readPointerIfExists(
        ps,
        ps.pointers.wixCode.getDirectoryFlagByDeletedPathMap(),
        {}
    )

    return {
        toSave: _getToSave(modifiedFilesInLastSnapshot, modifiedFilesInCurrentSnapshot),
        toCopy: _getToCopy(prevDuplicatesMap, currentDuplicatesMap),
        toDelete: directoryFlagByDeletedPath
    }
}

function getChanges(ps: PS) {
    const tagName = saveCodeTaskConsts.getTaskName() + saveCodeTaskConsts.getSnapshotTags()
    const lastSnapshot = ps.dal.full.immutable.getLastSnapshotByTagName(tagName)
    return getChangesBetweenLastSnapshotAndCurrentStateTakenFromPS(lastSnapshot, ps)
}

function isChangesEmpty(changes) {
    return _.isEmpty(changes.toSave) && _.isEmpty(changes.toCopy) && _.isEmpty(changes.toDelete)
}

function markPathAsDeleted(ps: PS, path, isDirectory) {
    const pointer = ps.pointers.wixCode.getDirectoryFlagByDeletedPathMap()
    ps.dal.set(pointer, _.assign({}, ps.dal.get(pointer), {[path]: isDirectory}))
}

function clearDeletedPath(ps: PS, path) {
    const pointer = ps.pointers.wixCode.getDirectoryFlagByDeletedPathMap()
    ps.dal.set(pointer, _.omit(ps.dal.get(pointer), path))
}

function clearCache(ps: PS, paths) {
    paths.forEach(path => {
        deleteFile(ps, path)
        deleteFolder(ps, path)
    })
}

export default {
    writeFile,
    loadFileContent,
    readFile,
    getSourceFilePath,
    markFileForDuplication,
    updateDuplicates,
    moveFile,
    deleteFile,
    deleteFolder,
    isFileReadable,
    isFileExists,
    addChild,
    removeChild,
    hasKnownChild,
    isChildrenExists,
    areChildrenLoaded,
    getChildren,
    loadChildren,

    getChanges,
    getChangesBetweenSnapshots,
    getChangesBetweenSnapshotDals,
    getChangesBetweenSnapshotDataProviders,
    getChangesBetweenLastSnapshotAndCurrentStateTakenFromPS,
    isChangesEmpty,

    markPathAsDeleted,
    clearDeletedPath,
    clearCache
}
