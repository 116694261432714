import type {DocumentManager} from '@wix/document-manager-core'
import {forIn} from 'lodash'
import type {Experiment} from '@wix/document-services-types'

const NEW_TO_OLD_COLORS_INDEXES_MAP = {
    MAIN_1: [36, 11],
    MAIN_2: [37, 15],
    SHADE_1: [38, 12],
    SHADE_2: [39, 13],
    SHADE_3: [40, 14],
    SECONDARY_1: [41, 18],
    SECONDARY_2: [42, 15],
    SECONDARY_3: [43, 13],
    SECONDARY_4: [44, 11],
    BACKGROUND: [11, 11],
    SECONDARY_BACKGROUND: [12, 12],
    SECONDARY_TEXT: [14, 14],
    PRIMARY_TEXT: [15, 15],
    LINK: [18, 18],
    TITLE: [45, 15],
    SUBTITLE: [46, 15],
    LINE: [47, 12],
    PRIMARY_BUTTON_FILL: [48, 18],
    PRIMARY_BUTTON_BORDER: [49, 18],
    PRIMARY_BUTTON_TEXT: [50, 11],
    PRIMARY_BUTTON_FILL_HOVER: [51, 11],
    PRIMARY_BUTTON_BORDER_HOVER: [52, 18],
    PRIMARY_BUTTON_TEXT_HOVER: [53, 18],
    PRIMARY_BUTTON_FILL_DISABLED: [54, 13],
    PRIMARY_BUTTON_BORDER_DISABLED: [55, 13],
    PRIMARY_BUTTON_TEXT_DISABLED: [56, 11],
    SECONDARY_BUTTON_FILL: [57, 11],
    SECONDARY_BUTTON_BORDER: [58, 18],
    SECONDARY_BUTTON_TEXT: [59, 11],
    SECONDARY_BUTTON_FILL_HOVER: [60, 18],
    SECONDARY_BUTTON_BORDER_HOVER: [61, 18],
    SECONDARY_BUTTON_TEXT_HOVER: [62, 11],
    SECONDARY_BUTTON_FILL_DISABLED: [63, 11],
    SECONDARY_BUTTON_BORDER_DISABLED: [64, 13],
    SECONDARY_BUTTON_TEXT_DISABLED: [65, 13]
}
const NEW_COLOR_PALETTE_LENGTH = 66

const THEME_DATA_ID = 'THEME_DATA'

const expandColorsToRedesignedColorPalette = (colors: string[]) => {
    const newPalette = [...colors]

    forIn(NEW_TO_OLD_COLORS_INDEXES_MAP, indexes => {
        const [newIndex, oldIndex] = indexes
        newPalette[newIndex] = newPalette[newIndex] ? newPalette[newIndex] : colors[oldIndex]
    })

    return newPalette
}

const migrateSite = (dm: DocumentManager, experimentInstance: Experiment) => {
    const pointer = dm.pointers.data.getThemeItem(THEME_DATA_ID, 'masterPage')
    const colorPointer = dm.pointers.getInnerPointer(pointer, 'color')
    const colorPalette = dm.dal.get(colorPointer)

    if (!experimentInstance.isOpen('dm_expandColorPalette')) {
        if (colorPalette && colorPalette.length === NEW_COLOR_PALETTE_LENGTH) {
            const t = [...colorPalette.slice(0, 36)]
            dm.dal.set(colorPointer, t)
        }
        return
    }

    if (colorPalette.length === NEW_COLOR_PALETTE_LENGTH) {
        return
    }

    const expandColorPalette = expandColorsToRedesignedColorPalette(colorPalette)

    dm.dal.set(colorPointer, expandColorPalette)
}

const name = 'colorPaletteMigrator'
const version = 0
const experimentalVersions = [{version: 1, experiment: 'dm_expandColorPalette'}]

export {migrateSite, name, version, NEW_TO_OLD_COLORS_INDEXES_MAP, NEW_COLOR_PALETTE_LENGTH, experimentalVersions}
