import platform from '../../platform/platform'
import fileSystemAPI from './fileSystemAPI'
import constants from '../utils/constants'

function getFileId(ps, pageId) {
    const pagesRoot = fileSystemAPI.getRoots().pages.location
    return `${pagesRoot}${pageId}${constants.JS_EXTENSION}`
}

function hasCode(ps, pageId) {
    return platform.pageHasPlatformApp(ps, pageId, constants.WIX_CODE_APP_ID)
}

function readCode(ps, pageId) {
    const fileId = getFileId(ps, pageId)
    const descriptor = fileSystemAPI.getVirtualDescriptor(ps, fileId, false)
    return fileSystemAPI.readFile(ps, descriptor)
}

export default {
    getFileId,
    hasCode,
    readCode
}
