export default {
    isRepeatable: false,
    minimalChildrenNumber: 1,

    shouldKeepChildrenInPlace: false,
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: false,

    defaultMobileProperties: {
        flexibleBoxHeight: false,
        shouldHideOverflowContent: true,
        navigationButtonMargin: 22,
        navigationButtonSize: 15,
        navigationDotsGap: 15,
        navigationDotsMargin: 24,
        navigationDotsSize: 6
    },

    mobileConversionConfig: {
        structuralContainer: true,
        nestOverlayingSiblings: false,
        filterChildrenWhenHidden: true,
        marginX: 0
    }

    //because of the support of master components inside the BoxSlideShow that can be of any type we can not restrict the child types
    //allowedChildTypes: slideShowCommon.getMatchingChildSlideTypes('wysiwyg.viewer.components.BoxSlideShow')
}
