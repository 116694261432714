import {
    CreateExtArgs,
    deepCompare,
    Extension,
    ExtensionAPI,
    Null,
    pointerUtils,
    SnapshotDal,
    store
} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {deepClone} from '@wix/wix-immutable-proxy'
import {SNAPSHOTS} from '../constants/constants'
import {
    getPartialSiteDataImmutable,
    getSiteDataImmutable,
    getSiteDataJson
} from '../utils/siteDataImmutableFromSnapshot'
import type {DocumentServicesModelExtApi} from './documentServicesModel'
import {guidUtils} from '@wix/santa-core-utils'
const {getGUID} = guidUtils
const {createStore} = store
const {stripInnerPath, hasInnerPath} = pointerUtils

const ROGUE_SNAPSHOT_PREFIX = 'rogue'
const CHARACTERS_IN_LAST_GUID_SECTION = 12
const generatePrefixedSnapshotId = (prefix: string) =>
    `${prefix}-${getGUID().slice(-1 * CHARACTERS_IN_LAST_GUID_SECTION)}`

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, extensionAPI, coreConfig}: CreateExtArgs): SnapshotExtApi => {
        const {tagManager} = dal
        const {logger} = coreConfig

        const getCurrentSnapshot = (): SnapshotDal => dal.commitTransaction('getCurrentSnap')

        const getLastSnapshot = (): SnapshotDal => dal.getLastSnapshot()!

        const tagSnapshot = (tag: string, snapshot: SnapshotDal): number => tagManager.addSnapshot(tag, snapshot)

        const approveCurrentSnapshot = (): void => {
            const {siteAPI} = extensionAPI as DocumentServicesModelExtApi
            const neverSaved = siteAPI.getNeverSaved()
            if (neverSaved) {
                dal.approve(dal.commitTransaction('approveCurrentSnap').id)
            } else {
                throw new Error('Cannot approve snapshot on a saved site')
            }
        }

        const takeSnapshot = (tag: string) => dal.takeSnapshot(tag)

        const takeLastApprovedSnapshot = (tag: string) => dal.takeLastApprovedSnapshot(tag)

        const removeLastSnapshot = (tag: string) => tagManager.removeLastSnapshot(tag)

        const createStoreWithChanges = (snapshotDal: SnapshotDal, changes: Change[]) => {
            const transactionStore = createStore()
            changes.forEach(({pointer, value}: Change) => {
                if (!pointer) {
                    return
                }
                if (hasInnerPath(pointer)) {
                    let currentVal = transactionStore.get(pointer)
                    // The value must only be taken from snapshotDal once, so that if there are multiple inner path
                    // changes on the same root pointer, subsequent changes will not overwrite the previous ones.
                    if (!currentVal) {
                        currentVal = deepClone(snapshotDal.getValue(stripInnerPath(pointer)))
                        transactionStore.set(pointer, currentVal)
                    }
                    _.setWith(currentVal, pointer.innerPath, value, Object)
                } else {
                    transactionStore.set(pointer, value)
                }
            })
            return transactionStore
        }

        const createWithChanges = (snapshotDal: SnapshotDal, changes: Change[]) =>
            new SnapshotDal(
                snapshotDal,
                createStoreWithChanges(snapshotDal, changes),
                generatePrefixedSnapshotId(ROGUE_SNAPSHOT_PREFIX)
            )

        const duplicateLastSnapshot = (tag: string, changes: Change[]): number => {
            logger.interactionStarted('duplicateLastSnapshot')
            const lastSnapshot = tagManager.getLastSnapshot(tag) ?? tagManager.getSnapshot(SNAPSHOTS.DAL_INITIAL, 0)
            const changesStore = createStoreWithChanges(lastSnapshot, changes)
            const newSnapshot = dal.rebase(changesStore, lastSnapshot.id, tag)
            const snapshot = tagManager.addSnapshot(tag, newSnapshot)
            logger.interactionEnded('duplicateLastSnapshot')

            return snapshot
        }

        const getSnapshotByTagAndIndex = (tag: string, index: number) => tagManager.getSnapshot(tag, index)

        const getLastSnapshotByTagName = (tag: string) => tagManager.getLastSnapshot(tag) ?? null

        const getInitialSnapshot = () => tagManager.getSnapshot(SNAPSHOTS.DAL_INITIAL, 0)

        /**
         * Returns true if the current pointer value is different from the last value for the specified tag
         * @param {string} tag
         * @param {Pointer} pointer
         * @returns {boolean}
         */
        const hasPointerValueChanged = (tag: string, pointer: Pointer): boolean => {
            const currentValue = getCurrentSnapshot().getValue(pointer)
            const lastValue = tagManager.getLastSnapshot(tag)?.getValue(pointer)
            return !deepCompare(currentValue, lastValue)
        }

        /**
         * Returns all changes (including unsnapshotted changes) since the last snapshot for the specified tag
         * @param fromSnapshot
         * @returns {SnapshotDal}
         */
        const getChangesFromSnapshot = (fromSnapshot: SnapshotDal): SnapshotDal =>
            getCurrentSnapshot().diff(fromSnapshot)

        return {
            snapshots: {
                getLastSnapshot,
                getCurrentSnapshot,
                approveCurrentSnapshot,
                tagSnapshot,
                takeSnapshot,
                takeLastApprovedSnapshot,
                removeLastSnapshot,
                duplicateLastSnapshot,
                getSnapshotByTagAndIndex,
                getLastSnapshotByTagName,
                getInitialSnapshot,
                hasPointerValueChanged,
                getChangesFromSnapshot,
                createWithChanges
            },
            siteDataImmutableFromSnapshot: {
                getSiteDataImmutable,
                getPartialSiteDataImmutable,
                getSiteDataJson
            }
        }
    }

    return {
        name: 'snapshots',
        createExtensionAPI
    }
}

export interface Change {
    pointer?: Pointer
    value: any
    path?: any
}

export interface SnapshotApi {
    getCurrentSnapshot(): SnapshotDal
    getLastSnapshot(): SnapshotDal | null
    /** fast forward to current snapshot */
    approveCurrentSnapshot(): void
    tagSnapshot(tag: string, snapshot: SnapshotDal): number
    takeSnapshot(tag: string): number
    takeLastApprovedSnapshot(tag: string): void
    removeLastSnapshot(tag: string): void
    duplicateLastSnapshot(tag: string, changes: Change[]): number
    getSnapshotByTagAndIndex(tag: string, index: number): SnapshotDal
    getLastSnapshotByTagName(tag: string): Null<SnapshotDal>
    getInitialSnapshot(): SnapshotDal
    hasPointerValueChanged?(tag: string, pointer: Pointer): boolean
    getChangesFromSnapshot?(fromSnapshot: SnapshotDal): SnapshotDal
    createWithChanges(snapshot: SnapshotDal, changes: Change[]): SnapshotDal
}

export interface SnapshotExtApi extends ExtensionAPI {
    // @ts-expect-error
    snapshots: SnapshotApi
}

export {createExtension}
