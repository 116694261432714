import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import constants from '../constants/constants'
import namespaces from '../namespaces/namespaces'
import component from '../component/component'
import structure from '../structure/structure'
import documentServicesSchemas from 'document-services-schemas'
import dsUtils from '../utils/utils'
import hooks from '../hooks/hooks'
import dataModel from '../dataModel/dataModel'
import refComponent from '../refComponent/refComponent'
import experiment from 'experiment-amd'
import type {Pointer, PS} from '@wix/document-services-types'

const {schemasService} = documentServicesSchemas.services
const {getRootRefHostCompPointer} = refComponent
const SLOTS_NAMESPACE = constants.DATA_TYPES.slots
const {stripHashIfExists} = dsUtils
const {isRefPointer} = santaCoreUtils.displayedOnlyStructureUtil

/**
 * Checks whether the given compPointer is a DynamicSlots or not
 *
 * @param {ps} ps
 * @param {Pointer} compPointer
 * @returns {Boolean}
 */
const isDynamic = (ps: PS, compPointer: Pointer): boolean =>
    getCompDefinition(ps, compPointer).slotsDataType === 'DynamicSlots'

/**
 * Gets the component definition for the comp pointer
 *
 * @param {ps} ps
 * @param {Pointer} compPointer
 * @returns {object} The component's definition
 */
const getCompDefinition = (ps: PS, compPointer: Pointer): any => {
    const {componentType} = ps.dal.get(compPointer)
    return schemasService.getDefinition(componentType)
}

/**
 * Gets the slots and their data for the given slotted component
 *
 * @param {ps} ps
 * @param {Pointer} slottedComponentPointer
 * @returns {object} An object where the keys are the slot names, and the values are the components in those slots
 */
const getSlotProperties = (ps: PS, slottedComponentPointer: Pointer): any => {
    const {slotsDataType} = getCompDefinition(ps, slottedComponentPointer)
    return _.get(schemasService.getSchema('slots', slotsDataType), ['properties', 'slots', 'properties'])
}

const removeFromQuery = (ps: PS, slottedCompPointer: Pointer, slotName: string): void => {
    const slotsQueryPointer = dataModel.getSlotsItemPointer(ps, slottedCompPointer)
    const slotsData = ps.dal.get(slotsQueryPointer)
    delete slotsData.slots[slotName]
    ps.dal.set(slotsQueryPointer, slotsData)
}

const verifySlotName = (ps: PS, compWithSlot: Pointer, slotName: string) => {
    const slotProperties = getSlotProperties(ps, compWithSlot)
    const allowedSlotNames: string[] = _.keys(slotProperties)
    const isDynamicSlots = isDynamic(ps, compWithSlot)

    if (!allowedSlotNames.includes(slotName) && !isDynamicSlots) {
        throw new Error(`Slot "${slotName}" is not a valid slot name`)
    }
}

const getOwnerOfComponentInSlot = (ps: PS, slottedComponent: Pointer): Pointer =>
    isRefPointer(slottedComponent) ? getRootRefHostCompPointer(ps, slottedComponent) : slottedComponent

/**
 * Verifies that we can populate a slot with a given comp definition
 *
 * @param {ps} ps
 * @param {String} slotName
 * @param {Pointer} slottedComponent
 */
const verifyCanPopulate = (ps: PS, slotName: string, slottedComponent: Pointer): void => {
    verifySlotName(ps, slottedComponent, slotName)

    const currentSlots = getSlotNames(ps, slottedComponent)
    if (currentSlots.includes(slotName)) {
        throw new Error(`Slot "${slotName}" already exists`)
    }
}

const findSlotByValue = (ps: PS, parentPointer: Pointer, compPointer: Pointer): string => {
    const slotsData = getSlotsData(ps, parentPointer)
    return _.keys(slotsData).find(key => slotsData[key].id === compPointer.id)
}

const removeFromSlot = (ps: PS, compToAddPointer: Pointer): void => {
    const parentPointer = ps.pointers.components.getParent(compToAddPointer)
    const originalSlotName = findSlotByValue(ps, parentPointer, compToAddPointer)

    if (originalSlotName) {
        removeFromQuery(ps, parentPointer, originalSlotName)
    }
}

const updateSlot = (ps: PS, slottedComponent: Pointer, slotName: string, compToAddPointer: Pointer): void => {
    verifyCanPopulate(ps, slotName, slottedComponent)
    const {slotsDataType} = getCompDefinition(ps, slottedComponent)
    const updatedData = namespaces.getNamespaceData(ps, slottedComponent, SLOTS_NAMESPACE)

    if (updatedData?.slots) {
        updatedData.slots[slotName] = compToAddPointer.id
        namespaces.updateNamespaceData(ps, slottedComponent, SLOTS_NAMESPACE, updatedData)
    } else {
        namespaces.updateNamespaceData(ps, slottedComponent, SLOTS_NAMESPACE, {
            type: slotsDataType,
            slots: {[slotName]: compToAddPointer.id}
        })
    }
}

/**
 * Populates a specified `slotName` with the slot definition
 *
 * @param {ps} ps
 * @param {Pointer} componentWithSlots The component that has the slots (should have a slotsDataType in the component definition)
 * @param {string} slotName The name of the slot to add to
 * @param {object} componentDefinition The component definition (similar as with ds.components.add())
 */
const populate = (ps: PS, componentWithSlots: Pointer, slotName: string, componentDefinition: any): Pointer => {
    const ownerOfComponentInSlot = getOwnerOfComponentInSlot(ps, componentWithSlots)
    const componentToAddPointer = component.getComponentToAddRef(ps, ownerOfComponentInSlot, componentDefinition)
    component.addComponentInternal(ps, componentToAddPointer, ownerOfComponentInSlot, componentDefinition)
    updateSlot(ps, componentWithSlots, slotName, componentToAddPointer)

    hooks.executeHook(hooks.HOOKS.SLOTS.AFTER_POPULATE, null, [
        ps,
        ownerOfComponentInSlot,
        componentWithSlots,
        componentToAddPointer
    ])

    return componentToAddPointer
}

/**
 * Moves a component to another component's slot
 *
 * @param {ps} ps
 * @param {Pointer} componentWithSlots The component with slot
 * @param {string} slotName The slot name to move to.
 * @param {Pointer} compToMove The component to be placed in the slot
 */
const moveToSlot = (ps: PS, componentWithSlots: Pointer, slotName: string, compToMove: Pointer): Pointer => {
    if (compToMove.type === constants.VIEW_MODES.MOBILE) {
        throw new Error('Only DESKTOP components can move into or between slots')
    }

    const oldParent = ps.pointers.components.getParent(compToMove)
    const ownerOfComponentInSlot = getOwnerOfComponentInSlot(ps, componentWithSlots)

    removeFromSlot(ps, compToMove)
    updateSlot(ps, componentWithSlots, slotName, compToMove)
    structure.addCompToContainer(ps, compToMove, ownerOfComponentInSlot)

    hooks.executeHook(hooks.HOOKS.SLOTS.AFTER_MOVE, null, [ps, componentWithSlots, compToMove, oldParent])
    return compToMove
}

/**
 * Returns all the components' slot names
 */
const getSlotNames = (ps: PS, componentPointer: Pointer): string[] => _.keys(getSlotsData(ps, componentPointer))

/**
 * Removes a slot from a component
 */
const remove = (ps: PS, slottedCompPointer: Pointer, slotName: string): void => {
    removeInternal(ps, slottedCompPointer, slotName, false)
}

const removeInternal = (
    ps: PS,
    slottedCompPointer: Pointer,
    slotName: string,
    invokedFromHook: boolean = false
): void => {
    if (experiment.isOpen('dm_useSlotsExt')) {
        ps.extensionAPI.slots.remove(slottedCompPointer, slotName, invokedFromHook)
    } else {
        verifySlotName(ps, slottedCompPointer, slotName)
        const compPointer = _.get(getSlotsData(ps, slottedCompPointer), slotName)
        if (!compPointer) {
            return
        }

        if (ps.dal.full.isExist(compPointer) && !invokedFromHook) {
            component.remove(ps, compPointer)
        }

        removeFromQuery(ps, slottedCompPointer, slotName)
    }
}

/**
 * Retrieves the slots data of a given slotted component
 *
 * @param {ps} ps
 * @param {Pointer} slottedComponentPointer
 * @returns {object} Slot names and their component pointers
 */
const getSlotsData = (ps: PS, slottedComponentPointer: Pointer): any => {
    if (experiment.isOpen('dm_useSlotsExt')) {
        return ps.extensionAPI.slots.getSlotsData(slottedComponentPointer)
    }
    const slotsData = namespaces.getNamespaceData(ps, slottedComponentPointer, SLOTS_NAMESPACE)
    return _.mapValues(slotsData?.slots, compId =>
        ps.pointers.getPointer(stripHashIfExists(compId), slottedComponentPointer.type)
    )
}

export default {
    populate,
    moveToSlot,
    remove,
    removeInternal,
    getSlotNames,
    getSlotsData
}
