'use strict'

module.exports = {
    /**
     * Implicitly sets page top to 0
     * @param pageJson
     */
    name: 'pageTopFixer',
    version: 3,
    exec(pageJson) {
        pageJson.structure = pageJson.structure || {}
        pageJson.structure.layout = pageJson.structure.layout || {}
        pageJson.structure.layout.y = 0
    }
}
