import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import pageData from '../../page/pageData'

function generateRouterId(allRouters) {
    let routerId = santaCoreUtils.guidUtils.getUniqueId('routers', '-')

    while (_(allRouters).keys().includes(routerId)) {
        routerId = santaCoreUtils.guidUtils.getUniqueId('routers', '-')
    }
    return routerId
}

function getAllRouterPrefixes(ps: PS) {
    const routersPointer = ps.pointers.routers.getRoutersConfigMapPointer()
    return _.map(ps.dal.get(routersPointer), 'prefix')
}

function isPrefixExist(ps: PS, prefix: string) {
    const allRoutersPrefixes = getAllRouterPrefixes(ps)
    return _.includes(allRoutersPrefixes, prefix)
}

const isPageUriSEOExist = (ps: PS, prefix: string) => {
    const allPageIds = pageData.getPagesList(ps, true)
    const allPageUriSEO = _.map(allPageIds, pageId => pageData.getPageUriSEO(ps, pageId))

    return _.includes(allPageUriSEO, prefix)
}

const validateNewRouter = (ps: PS, router) => {
    if (!router.prefix) {
        throw new Error('Router not valid - Missing prefix.')
    }

    if (!router.appDefinitionId) {
        throw new Error('Router not valid - Missing appDefinitionId.')
    }
    if (!_.isNil(router.hide) && !_.isBoolean(router.hide)) {
        throw new Error('Router not valid - hide parameter need to be boolean')
    }

    if (router.pages) {
        throw new Error('Router not valid - pages should not be on the router object')
    }

    validateUpdatedRouter(ps, router)
}

const validateUpdatedRouter = (ps: PS, updateRouterData) => {
    if (isPrefixExist(ps, updateRouterData.prefix)) {
        throw new Error(`Router not valid - Prefix: ${updateRouterData.prefix}, already exist`)
    }

    if (isPageUriSEOExist(ps, updateRouterData.prefix)) {
        throw new Error(`Router not valid - Page Uri SEO: ${updateRouterData.prefix}, already exist.`)
    }
}

export default {
    generateRouterId,
    validateUpdatedRouter,
    validateNewRouter
}
