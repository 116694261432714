import pathUtils from '../utils/pathUtils'

function wasPartLoadedSuccessfully(ps, partName) {
    const metadata = ps.wixappsDAL.getByPath(pathUtils.getAppPart2MetadataPath(partName))
    return metadata && metadata.loading === false && !metadata.error
}

function wasRepoLoadedSuccessfully(ps) {
    const metadata = ps.wixappsDAL.getByPath(pathUtils.getAppbuilderMetadataPath())
    return metadata && metadata.loading === false && !metadata.error
}

function isPartErroneous(ps, partName) {
    const metadata = ps.wixappsDAL.getByPath(pathUtils.getAppPart2MetadataPath(partName)) || {}
    return !!metadata.error
}

export default {
    wasPartLoadedSuccessfully,
    isPartErroneous,
    wasRepoLoadedSuccessfully
}
