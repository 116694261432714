import items from './services/items'
import types from './services/types'
import lists from './services/lists'
import selection from './services/selection'
import listTemplates from './services/listTemplates'
import appbuilder from './services/appBuilder'
import classics from './services/classics'
import clientSpecMap from './services/clientSpecMap'
import metadata from './services/metadata'
import initializer from './utils/initializer'
import blogUtils from './services/blogUtils'
import gcFixer from '../documentServicesDataFixer/fixers/styles/garbageCollectionFixer'
import appPart2StylesCollector from './utils/stylesCollectors/appPart2StylesCollector'
import appPartStylesCollector from './utils/stylesCollectors/appPartStylesCollector'

// custom style collectors registration
const collectors = [appPart2StylesCollector, appPartStylesCollector]
collectors.forEach(({componentsClasses, collect}) => {
    componentsClasses.forEach(componentClass => gcFixer.registerCustomStyleCollector(componentClass, collect))
})

export default {
    items,
    types,
    lists,
    selection,
    listTemplates,
    appbuilder,
    classics,
    clientSpecMap,
    metadata,
    initializer,
    blogUtils
}
