import type {CreateExtArgs} from '@wix/document-manager-core'
import type {RelationshipsAPI} from '../relationships'
import {constants} from '@wix/document-manager-utils'
import _ from 'lodash'
import type {Pointer} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '../dataModel'
import {translateIfNeeded} from '../page/language'
import {deepClone} from '@wix/wix-immutable-proxy'
import {DATA_TYPES} from '../../constants/constants'

const getAllMenuPointers = ({extensionAPI, pointers, dal}: CreateExtArgs) => {
    const {relationships} = extensionAPI as RelationshipsAPI
    const customMenusDataItemPointer = pointers.data.getDataItemFromMaster('CUSTOM_MENUS')
    const customMenusDataQueries = dal.get(customMenusDataItemPointer).menus
    return _.map(customMenusDataQueries, dq => pointers.data.getDataItemFromMaster(relationships.getIdFromRef(dq)))
}

const getMenusByFilter = (dataAccessArgs: CreateExtArgs, filterObj: Record<string, any>) => {
    const allMenuPointers = getAllMenuPointers(dataAccessArgs)
    return _(allMenuPointers)
        .map(ptr => dataAccessArgs.dal.get(ptr))
        .filter(filterObj)
        .map(menu => dataAccessArgs.pointers.data.getDataItemFromMaster(menu.id))
        .value()
}
const getItemWithMetaData = ({pointers, dal}: CreateExtArgs, lang: string, id: string, parent: string | null) => {
    let pointer = pointers.multilingualTranslations.translationDataItem('masterPage', lang, id)
    const isTranslatedItem = dal.has(pointer)
    pointer = isTranslatedItem ? pointer : pointers.data.getDataItemFromMaster(id)
    const value = dal.get(pointer)
    return {
        id,
        parent,
        pointer,
        isTranslatedItem,
        value
    }
}

const getFlatMenuWithMetaData = ({extensionAPI, pointers, dal}: CreateExtArgs, menuId: string, lang: string) => {
    const menuDataMap = {}
    const {relationships} = extensionAPI as RelationshipsAPI
    const collectMenuInfo = (parent: string | null) => (itemQuery: string) => {
        const itemId = relationships.getIdFromRef(itemQuery)
        menuDataMap[itemId] = getItemWithMetaData({pointers, dal} as CreateExtArgs, lang, itemId, parent)
        const {link, items} = menuDataMap[itemId].value
        if (link) {
            const linkId = relationships.getIdFromRef(link)
            menuDataMap[itemId].link = getItemWithMetaData({pointers, dal} as CreateExtArgs, lang, linkId, itemId)
        }
        items.forEach(collectMenuInfo(itemId))
    }

    collectMenuInfo(null)(menuId)
    return menuDataMap
}

export const menuHookChangePageData = (
    {dal, pointers}: CreateExtArgs,
    basicMenuItemPointer: Pointer,
    changedData: Record<string, any>,
    useLanguage: string,
    pageData: Record<string, any>
) => {
    const translateIfNeededBasicMenuItemPointer = translateIfNeeded(dal, pointers, basicMenuItemPointer, useLanguage)
    const translatedItem = dal.get(translateIfNeededBasicMenuItemPointer)
    const mainLangItem = dal.get(basicMenuItemPointer)
    const BMI = _.cloneDeep(translatedItem ?? mainLangItem)
    const modifiedMenuItemData = _.reduce(
        changedData,
        function (res: Record<string, any>, value, key) {
            switch (key) {
                case 'title':
                    res.label = value
                    break
                case 'hidePage':
                    res.isVisible = !value
                    if (_.isNil(pageData.mobileHidePage)) {
                        res.isVisibleMobile = !value
                    }
                    break
                case 'mobileHidePage':
                    res.isVisibleMobile = !value
                    break
            }
            return res
        },
        {}
    )

    dal.set(translateIfNeededBasicMenuItemPointer, _.assign(BMI, modifiedMenuItemData))
}

export const getAllSyncableMenuPointers = (dataAccessArgs: CreateExtArgs) => {
    const syncableMenuPointers = getMenusByFilter(dataAccessArgs, {syncWithPages: true})
    return [dataAccessArgs.pointers.data.getDataItemFromMaster(constants.CUSTOM_MAIN_MENU)].concat(syncableMenuPointers)
}

export const getAllBasicMenuItemsForMenu = (
    {extensionAPI}: CreateExtArgs,
    menuPointer: Pointer,
    useLanguage: string
) => {
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const menuData = dataModel.getItem(menuPointer.id, 'data', 'masterPage', useLanguage, true)
    return _(menuData.items).flatMap('items').concat(menuData.items).compact().value()
}

export const updatePageItemFromTranslatedMenu = (
    dataAccessArgs: CreateExtArgs,
    allSyncableMenus: Pointer[],
    lang: string,
    pageId: string,
    useLanguage: string,
    changedData: Record<string, any>,
    pageData: Record<string, any>
) => {
    _.forEach(allSyncableMenus, menuPointer => {
        const menuId = menuPointer.id
        const menuData = getFlatMenuWithMetaData(dataAccessArgs, menuId, lang)
        // eslint-disable-next-line lodash/matches-shorthand
        const itemsToUpdate = _.pickBy(menuData, _.matches({link: {value: {type: 'PageLink', pageId: `#${pageId}`}}}))
        _.forEach(itemsToUpdate, ({pointer}) => {
            menuHookChangePageData(dataAccessArgs, pointer, changedData, lang, pageData)
        })
    })
}
function isCustomMenu({pointers, dal}: CreateExtArgs, menuId: string) {
    return dal.getWithPath(pointers.data.getDataItem(menuId, 'masterPage'), ['type']) === 'CustomMenu'
}

function isReparentingIntoASubItem(extArgs: CreateExtArgs, parentId: string, menuIdToPlaceItemIn?: string) {
    const {dal} = extArgs
    if (isCustomMenu(extArgs, parentId)) {
        return false
    }

    menuIdToPlaceItemIn = menuIdToPlaceItemIn ?? 'CUSTOM_MAIN_MENU'
    const menuData = dal.get(getMenuDataItemPointer(extArgs, menuIdToPlaceItemIn))
    return !_.includes(menuData.items, `#${parentId}`)
}

function getMenuDataItemPointer(extArgs: CreateExtArgs, dataItemId: string) {
    const {extensionAPI, pointers} = extArgs
    const {relationships} = extensionAPI as RelationshipsAPI
    dataItemId = relationships.getIdFromRef(dataItemId)
    return pointers.data.getDataItem(dataItemId, 'masterPage')
}

/*
 This functions throws error if one of the following scenarios happen:
 1. parentId refers to a non existing data item
 2. parentId refers to a data item of type other than BasicMenuItem or CustomMenu
 3. parentId refers to a data item which is already a sub item of another item
 4. itemId refers to a data item with sub items, and user tries to move it to item other than top level (CUSTOM_MAIN_MENU)
 */
function validateParent(extArgs: CreateExtArgs, parentId: string, itemId: string, menuIdToPlaceItemIn?: string) {
    const {extensionAPI, dal} = extArgs
    const {relationships} = extensionAPI as RelationshipsAPI
    parentId = parentId ? relationships.getIdFromRef(parentId) : 'CUSTOM_MAIN_MENU'
    itemId = itemId && relationships.getIdFromRef(itemId)

    if (itemId && itemId === parentId) {
        throw new Error(`menu item ${itemId} cannot be a parent for itself`)
    }

    const parentItemPointer = getMenuDataItemPointer(extArgs, parentId)

    if (!dal.has(parentItemPointer)) {
        throw new Error(`Parent "${parentId}" does not exist`)
    }

    const parentType = dal.getWithPath(parentItemPointer, ['type'])

    if (parentType !== 'BasicMenuItem' && parentType !== 'CustomMenu') {
        throw new Error(
            `Parent of type "${parentType}" is not a legal parent. Please provide parent of types "BasicMenuItem" or "CustomMenu" only`
        )
    }

    if (isReparentingIntoASubItem(extArgs, parentId, menuIdToPlaceItemIn)) {
        throw new Error('Cannot add/move items into a sub item')
    }

    if (itemId) {
        const itemPointer = getMenuDataItemPointer(extArgs, itemId)
        const subItems = dal.getWithPath(itemPointer, ['items'])

        if (subItems && subItems.length > 0 && !_.includes(['CUSTOM_MAIN_MENU', menuIdToPlaceItemIn], parentId)) {
            throw new Error(`Cannot move item with sub items (#${itemId}) to anything other than  #CUSTOM_MAIN_MENU`)
        }
    }
}

const addMenuDataItem = (
    extArgs: CreateExtArgs,
    pageId: string,
    dataItemId: string,
    label: string,
    link: string,
    hideItem: boolean,
    hideItemMobile: boolean
) => {
    const {extensionAPI, dal} = extArgs
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const dataItem = dal.schema.createItemAccordingToSchema('BasicMenuItem', DATA_TYPES.data)
    dataModel.addItem(
        _.assign(dataItem, {
            id: dataItemId,
            label,
            isVisible: !hideItem,
            isVisibleMobile: !(typeof hideItemMobile === 'boolean' ? hideItemMobile : hideItem),
            link: link ? `#${link}` : undefined
        }),
        'data',
        'masterPage',
        dataItemId
    )

    return dataItemId
}

function addMenuItem(
    extArgs: CreateExtArgs,
    pageId: string,
    dataId: string,
    label: string,
    linkId: string,
    parentItemId: string,
    hideItem: boolean,
    hideItemMobile: boolean,
    menuIdToPlaceItemIn?: string
) {
    const {pointers, dal} = extArgs
    validateParent(extArgs, parentItemId, dataId, menuIdToPlaceItemIn)
    addMenuDataItem(extArgs, pageId, dataId, label, linkId, hideItem, hideItemMobile)
    let parentPointer = getMenuDataItemPointer(extArgs, parentItemId)

    const originalLang = dal.getWithPath(pointers.multilingual.originalLanguage(), ['languageCode'])
    parentPointer = translateIfNeeded(dal, pointers, parentPointer, originalLang)
    const parentVal = deepClone(dal.get(parentPointer))

    if (!parentVal.items) {
        parentVal.items = [`#${dataId}`]
    } else {
        parentVal.items.push(`#${dataId}`)
    }
    dal.set(parentPointer, parentVal)
}

function addLinkDataItemAndReturnId(extArgs: CreateExtArgs, pageId: string, linkData: Record<string, any>) {
    const {extensionAPI, pointers, dal} = extArgs
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const dataItemId = dataModel.generateUniqueIdByType('data', pageId, dal, pointers)
    dataModel.addItem(_.assign(linkData, {id: dataItemId}), 'data', 'masterPage', dataItemId)
    return dataItemId
}

function addLinkItem(
    extArgs: CreateExtArgs,
    pageId: string,
    dataId: string,
    linkData: Record<string, any>,
    label: string,
    parentItemId: string,
    hideItem: boolean,
    hideItemMobile: boolean,
    menuIdToPlaceItemIn?: string
) {
    const linkId = addLinkDataItemAndReturnId(extArgs, pageId, linkData)
    addMenuItem(extArgs, pageId, dataId, label, linkId, parentItemId, hideItem, hideItemMobile, menuIdToPlaceItemIn)
}

export const addMenuItemWithPageLink = (
    extArgs: CreateExtArgs,
    menuId: string,
    pageId: string,
    label: string,
    hideItem: boolean,
    hideItemMobile: boolean
) => {
    const {extensionAPI, pointers, dal} = extArgs
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const dataId = dataModel.generateUniqueIdByType('data', pageId, dal, pointers)
    const pageLinkRawData = dal.schema.createItemAccordingToSchema('PageLink', DATA_TYPES.data)
    addLinkItem(
        extArgs,
        pageId,
        dataId,
        _.assign(pageLinkRawData, {pageId: `#${pageId}`}),
        label,
        menuId,
        hideItem,
        hideItemMobile
    )
    return dataId
}
