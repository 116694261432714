import _ from 'lodash'
import * as mobileCore from '@wix/mobile-conversion'
import * as santaCoreUtils from '@wix/santa-core-utils'
import theme from '../../../theme/theme'
import dsUtils from '../../../utils/utils'
import type {PS, Size} from '@wix/document-services-types'

const DEFAULT_FONT_SIZE = 14
const DEFAULT_TEXT_LENGTH = 10
const MAX_MOBILE_FONT_SIZE = 24

const heightMap = {
    breakPoints: [16, 19, 24],
    value: [42, 48, 54]
}

const widthMap = {
    breakPoints: [7, 14, 20, 30, 100],
    value: [120, 160, 200, 240, 280]
}

const getSizeByValue = (value: number, sizeBreakPoints): number => {
    const {breakPoints} = sizeBreakPoints
    const normalizedValue = Math.min(value, _.last(breakPoints))
    const breakPointIndex = _.findIndex(breakPoints, breakPoint => normalizedValue <= breakPoint)
    return sizeBreakPoints.value[breakPointIndex]
}

const getButtonSize = (fontSize: number, textLength: number): Size => {
    const height = getSizeByValue(fontSize, heightMap)
    const width = getSizeByValue(textLength, widthMap)
    return {height, width}
}

function getDesktopFontSize(ps: PS, styleId: string, pageId: string) {
    if (!styleId) {
        return DEFAULT_FONT_SIZE
    }
    const componentStyle = theme.styles.get(ps, styleId, pageId)
    const fontStyle = _.get(componentStyle, ['style', 'properties', 'fnt'], '')
    const fontSizePixels = _.get(fontStyle.match(/\d+px/), '0', `${DEFAULT_FONT_SIZE} + 'px'`)
    return +_.get(fontSizePixels.match(/\d+/), '0', DEFAULT_FONT_SIZE)
}

function getButtonTextLength(ps: PS, buttonDataId: string, pageId: string): number {
    if (!buttonDataId) {
        return DEFAULT_TEXT_LENGTH
    }
    const buttonDataPointer = ps.pointers.data.getDataItem(dsUtils.stripHashIfExists(buttonDataId), pageId)
    const buttonText = _.get(ps.dal.get(buttonDataPointer), 'label', '')
    return buttonText.length || DEFAULT_TEXT_LENGTH
}

function getMobileScale(mobileFontSize: number, desktopFontSize: number): number {
    const sizeAfterLegacyImplicitConversionInViewer = santaCoreUtils.mobileUtils.convertFontSizeToMobile(
        desktopFontSize,
        1
    )
    return mobileFontSize / sizeAfterLegacyImplicitConversionInViewer
}

function getButtonConversionData(ps: PS, component, pageId: string) {
    const desktopFontSize = getDesktopFontSize(ps, component.styleId, pageId) //this should consider refArray JIRA DM-4440
    const buttonTextLength = getButtonTextLength(ps, component.dataQuery, pageId)
    const mobileFontSize = Math.min(
        mobileCore.textUtils.convertFontSizeByTextLength(desktopFontSize, buttonTextLength),
        MAX_MOBILE_FONT_SIZE
    )

    const buttonConversionData: {
        fixedSize?
        mobileScale?
    } = {}
    buttonConversionData.fixedSize = getButtonSize(mobileFontSize, buttonTextLength)
    buttonConversionData.mobileScale = getMobileScale(mobileFontSize, desktopFontSize)
    return buttonConversionData
}

export default {
    getButtonConversionData
}
