import _ from 'lodash'
import immutable from 'immutable'
import {pickBy, SnapshotDal} from '@wix/document-manager-core'
import {getTranslationsPath, getTranslationInfoFromKey} from './translationUtils'
import {PAGE_DATA_TYPES} from '../constants/constants'
import {deepClone} from '@wix/wix-immutable-proxy'

const emptyImmutableMap = () => immutable.fromJS({})
const immutableFromJS = (js: object) => immutable.fromJS(js)
const setValueByPath = (obj: any, path: string[], value: any): any => _.setWith(obj, path, value, Object)

const sanitizedMetaData = (value: any) => {
    if (value?.metaData) {
        value.metaData = _.omit(value.metaData, ['basedOnSignature', 'pageId'])
    }
}

const cloneWithSanitizedMetaData = (value: any) => {
    const clonedValue = deepClone(value)
    sanitizedMetaData(clonedValue)
    return clonedValue
}

const immutableDataByPage = (snapshot: SnapshotDal, pagesData: any) => {
    const result = {}
    Object.keys(pagesData).forEach(pageId => {
        const dataByPage = {}
        result[pageId] = dataByPage
        Object.values(PAGE_DATA_TYPES).forEach((k: string) => {
            dataByPage[k] = {}
        })
    })
    for (const [pageDataTypeKey, pageDataTypeValue] of Object.entries(PAGE_DATA_TYPES)) {
        const typeValues = snapshot.toJSForType(pageDataTypeKey)
        Object.values(typeValues).forEach((value: any) => {
            const {pageId} = value.metaData
            const valueWithoutPageId = cloneWithSanitizedMetaData(value)
            setValueByPath(result, [pageId, pageDataTypeValue, value.id], valueWithoutPageId)
        })
    }
    return result
}

const getTranslationsByPage = (flatTranslations: any) => {
    const translationsByPage = {}
    if (flatTranslations) {
        Object.entries(flatTranslations).forEach(([key, value]: any[]) => {
            const {pageId} = value.metaData
            const valueWithoutPageId = cloneWithSanitizedMetaData(value)
            const [, languageCode, dataItemId] = getTranslationInfoFromKey(key)
            const translationPath = getTranslationsPath(pageId, languageCode, dataItemId)
            setValueByPath(translationsByPage, translationPath, valueWithoutPageId)
        })
    }
    return translationsByPage
}

const getComponentsRecursively = (compId: string, flatStructure: any) => {
    const comp = cloneWithSanitizedMetaData(flatStructure[compId])
    delete comp.parent
    const children = comp.components
    if (children) {
        // the filter is to remove any children that don't exist (DM-3175)
        comp.components = children
            .filter((childId: string) => flatStructure[childId])
            .map((childId: string) => getComponentsRecursively(childId, flatStructure))
    }
    return comp
}

const immutableStructureByPage = (flatStructure: any, mobileStructure: any, pageId: string) => {
    const mobileComponents = mobileStructure![pageId]
        ? getComponentsRecursively(pageId, mobileStructure).components
        : []
    const rootComp = getComponentsRecursively(pageId, flatStructure)
    const rootCompForDeepStructure = {
        ...rootComp
    }
    rootCompForDeepStructure.mobileComponents = mobileComponents
    if (pageId === 'masterPage') {
        rootCompForDeepStructure.children = rootComp.components
        delete rootCompForDeepStructure.components
        return rootCompForDeepStructure
    }
    return rootCompForDeepStructure
}

const pageTypes = ['Page', 'Document']

const addIfValue = (object: object, key: string, value: any) => {
    if (value) {
        object[key] = value
    }
}

const getPagesData = (snapshot: SnapshotDal, onlyForSpecificPageIds?: string[]) => {
    const flatTranslations = snapshot.toJSForType('multilingualTranslations')
    const desktopStructure = snapshot.toJSForType('DESKTOP')
    const mobileStructure = snapshot.toJSForType('MOBILE')

    const pagesData = onlyForSpecificPageIds
        ? _.keyBy(onlyForSpecificPageIds)
        : pickBy(desktopStructure, comp => pageTypes.includes(comp.type))

    const dataByPage = immutableDataByPage(snapshot, pagesData)
    const translationsByPage = getTranslationsByPage(flatTranslations)

    const result = {}
    Object.keys(pagesData).forEach(pageId => {
        const object = {}
        result[pageId] = object
        const pageTranslations = translationsByPage[pageId]
        addIfValue(object, 'structure', immutableStructureByPage(desktopStructure, mobileStructure, pageId))
        addIfValue(object, 'data', dataByPage[pageId])
        addIfValue(object, 'translations', pageTranslations?.translations)
    })
    return result
}

const getDocumentServicesModel = (snapshot: SnapshotDal) => {
    const docServicesModel = snapshot.toJSForType('documentServicesModel')
    const saveObject = snapshot.toJSForType('save')
    const docServicesModelForSnapshot = deepClone(docServicesModel)
    docServicesModelForSnapshot.silentSaveInitiator = saveObject.silentSaveInitiator
    docServicesModelForSnapshot.publishSaveInitiator = saveObject.publishSaveInitiator
    docServicesModelForSnapshot.version = docServicesModel.versionInfo.version
    docServicesModelForSnapshot.revision = docServicesModel.versionInfo.revision
    delete docServicesModelForSnapshot.versionInfo
    sanitizedMetaData(docServicesModelForSnapshot.metaSiteData)
    return docServicesModelForSnapshot
}

const FORBIDDEN_PAGEURI_SEO_PATH = ['urlFormatModel', 'forbiddenPageUriSEOs']

const getRendererModel = (snapshot: SnapshotDal) => {
    const rendererModelObj = deepClone(snapshot.toJSForType('rendererModel'))
    const value = _.get(rendererModelObj, FORBIDDEN_PAGEURI_SEO_PATH)
    setValueByPath(rendererModelObj, ['urlFormatModel', 'forbiddenPageUriSEOs'], _.keys(value))

    const passwordProtectedPages = rendererModelObj?.passwordProtectedPages?.pages
        ? rendererModelObj.passwordProtectedPages.pages
        : rendererModelObj.passwordProtectedPages
    setValueByPath(rendererModelObj, ['passwordProtectedPages'], passwordProtectedPages)

    const pageToHashedPassword = rendererModelObj?.pageToHashedPassword?.pages
        ? rendererModelObj.pageToHashedPassword.pages
        : rendererModelObj.pageToHashedPassword
    setValueByPath(rendererModelObj, ['pageToHashedPassword'], pageToHashedPassword)

    sanitizedMetaData(rendererModelObj.siteMetaData)
    sanitizedMetaData(rendererModelObj.wixCodeModel)
    return rendererModelObj
}

const getRouters = (snapshot: SnapshotDal) =>
    cloneWithSanitizedMetaData(snapshot.getValue({type: 'rendererModel', id: 'routers'}))

const siteDataConfig = [
    {
        path: ['orphanPermanentDataNodes'],
        getter: (snapshot: SnapshotDal) => snapshot.getValue({type: 'save', id: 'orphanPermanentDataNodes'})
    },
    {
        path: ['serviceTopology'],
        getter: (snapshot: SnapshotDal) => snapshot.getValue({type: 'serviceTopology', id: 'serviceTopology'})
    },
    {path: ['documentServicesModel'], getter: (snapshot: SnapshotDal) => getDocumentServicesModel(snapshot)},
    {path: ['rendererModel'], getter: (snapshot: SnapshotDal) => getRendererModel(snapshot)},
    {path: ['routers'], getter: (snapshot: SnapshotDal) => getRouters(snapshot)},
    {
        path: ['pagesPlatformApplications'],
        getter: (snapshot: SnapshotDal) =>
            snapshot.getValue({type: 'pagesPlatformApplications', id: 'pagesPlatformApplications'})
    },
    {
        path: ['wixCode', 'nonUndoable'],
        getter: (snapshot: SnapshotDal) =>
            snapshot.getValue({type: 'wixCode', id: 'wixCode', innerPath: ['nonUndoable']})
    },
    {
        path: ['wixCode', 'undoable'],
        getter: (snapshot: SnapshotDal) =>
            snapshot.getValue({type: 'wixCodeUndoable', id: 'wixCode', innerPath: ['undoable']})
    },
    {
        path: ['semanticAppVersions'],
        getter: (snapshot: SnapshotDal) => snapshot.getValue({type: 'semanticAppVersions', id: 'semanticAppVersions'})
    },
    {
        path: ['pagesData'],
        getter: (snapshot: SnapshotDal, onlyForSpecificPageIds?: string[]) =>
            getPagesData(snapshot, onlyForSpecificPageIds)
    }
]

interface SiteDataImmutableOptions {
    withPagesData: boolean
}
const partialSiteConfig = siteDataConfig.filter(
    e => !['wixCode', 'serviceTopology', 'semanticAppVersions'].includes(e.path[0])
)
const siteDataConfigWithoutPagesData = siteDataConfig.filter(e => e.path[0] !== 'pagesData')

const getSiteDataImmutableFromSnapshot = (
    dataConfig: any[],
    snapshot: SnapshotDal,
    onlyForSpecificPageIds?: string[]
) => {
    if (!snapshot) {
        return null
    }
    return emptyImmutableMap().withMutations((siteDataSnapshot: any) =>
        dataConfig.reduce(
            (acc, {path, getter}) => acc.setIn(path, immutableFromJS(getter(snapshot, onlyForSpecificPageIds))),
            siteDataSnapshot
        )
    )
}

const getSiteDataImmutable = (
    snapshot: SnapshotDal,
    editorOrigin: string,
    {withPagesData}: SiteDataImmutableOptions = {withPagesData: false}
) => {
    const config = (withPagesData ? siteDataConfig : siteDataConfigWithoutPagesData).concat({
        path: ['origin'],
        getter: () => editorOrigin
    })
    return getSiteDataImmutableFromSnapshot(config, snapshot)
}

const getSiteDataJson = (
    snapshot: SnapshotDal | null,
    editorOrigin: string,
    {withPagesData}: SiteDataImmutableOptions = {withPagesData: false}
) => {
    if (!snapshot) {
        return null
    }
    const config = withPagesData ? siteDataConfig : siteDataConfigWithoutPagesData
    return config.reduce((acc, {path, getter}) => setValueByPath(acc, path, getter(snapshot)), {origin: editorOrigin})
}

const getPartialSiteDataImmutable = (snapshot: SnapshotDal, onlyForSpecificPageIds: string[]) =>
    getSiteDataImmutableFromSnapshot(partialSiteConfig, snapshot, onlyForSpecificPageIds)

export {getSiteDataImmutable, getPartialSiteDataImmutable, getSiteDataJson}
