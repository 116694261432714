import fieldBoxProxyUtils from '@wix/santa-ds-libs/src/wixappsBuilder/src/util/fieldBoxProxyUtils'
import appbuilderUrlUtils from '@wix/santa-ds-libs/src/wixappsBuilder/src/util/appbuilderUrlUtils'

/**
 * @class wixappsBuilder
 */
export default {
    fieldBoxProxyUtils,
    resolveImageData: appbuilderUrlUtils.resolveImageData
}
