import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'

export default {
    rotatable: true,
    layoutLimits(ps, compPointer) {
        const measureMap = ps.siteAPI.getSiteMeasureMap()

        return {
            minWidth: _.get(measureMap, ['minWidth', compPointer.id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH)
        }
    }
}
