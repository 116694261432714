import _ from 'lodash'
import {ReportableError} from './ReportableError'

export type Method = 'GET' | 'POST' | 'DELETE'

export const GET = 'GET'

interface HTTPStatus {
    status: number
    statusText: string
    redirected: boolean
    type: string
    url: string
}

export const searchRequestId = (response: Response | null): string | null | undefined => {
    const headers = response?.headers
    const headerKeys = headers?.keys?.()
    const keys: string[] = headerKeys ? [...headerKeys] : []
    const id = _(keys)
        .invokeMap('toLowerCase')
        .find((k: string) => /.*request[-_]?id.*/.test(k) && headers?.get(k))

    if (id) {
        return headers?.get(id)
    }
    return 'no-request-id'
}

const getHTTPStatus = (response: Response): HTTPStatus =>
    _.pick(response, ['status', 'statusText', 'redirected', 'type', 'url'])

export const getHttpResponseExtras = (response: Response) => ({
    ...getHTTPStatus(response),
    requestId: searchRequestId(response)
})

/**
 * an error wrapping a non 200 (ok) response
 */
export class HttpRequestError extends ReportableError {
    public readonly status: number

    constructor(response: Response, errorType: string = 'httpError', message?: string) {
        super({
            errorType,
            message: message ?? response.statusText,
            extras: {
                ...getHttpResponseExtras(response)
            }
        })
        this.status = response.status
        Object.setPrototypeOf(this, HttpRequestError.prototype)
    }
}

export class ServerError<T> extends ReportableError {
    public readonly result: T
    public readonly status: number

    constructor(message: string, response: Response, result: T) {
        super({
            errorType: 'fetchJsonWithAuthorization',
            message,
            extras: {
                ...getHttpResponseExtras(response),
                result: JSON.stringify(result)
            }
        })
        Object.setPrototypeOf(this, ServerError.prototype)
        this.status = response.status
        this.result = result
    }
}
