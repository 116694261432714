import _ from 'lodash'
import constants from '../../constants/constants'
import actionsAndBehaviors from '../../actionsAndBehaviors/actionsAndBehaviors'

function getViewMode(isMobile) {
    return isMobile ? constants.VIEW_MODES.MOBILE : constants.VIEW_MODES.DESKTOP
}

function handleHeaderLayoutChange(ps, compPointer, newLayout) {
    const headerStructure = ps.dal.get(compPointer)
    const oldLayout = headerStructure.layout
    const headerFixedPositionChanged =
        !_.isUndefined(newLayout.fixedPosition) && oldLayout.fixedPosition !== newLayout.fixedPosition
    if (headerFixedPositionChanged && newLayout.fixedPosition === false) {
        const isMobile = compPointer.type === constants.VIEW_MODES.MOBILE
        const filterScrubBehaviors = {type: 'scrub', viewMode: getViewMode(isMobile)}
        actionsAndBehaviors.removeComponentsBehaviorsWithFilter(ps, compPointer, filterScrubBehaviors)
    }
}

export default {
    handleHeaderLayoutChange
}
