import {CreateExtArgs, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {COMP_TYPES, DATA_TYPES, MASTER_PAGE_ID} from '../../constants/constants'
import type {DataModelExtensionAPI} from '../dataModel'
import _ from 'lodash'
import {getComponentType} from '../../utils/dalUtils'
import {getLanguageByUseOriginal} from '../page/language'
import type {NicknamesExtensionAPI} from '../nicknames'
import {constants} from '../..'

const {getPointer} = pointerUtils

const WIDGETS_CACHE = {}
const VARIATIONS_CACHE = {}

export const getData = (createExtArgs: CreateExtArgs, pointer: Pointer) => {
    const originalLanguage = getLanguageByUseOriginal(createExtArgs, true)
    const nonTranslatablePointer = {...pointer, useLanguage: originalLanguage}

    return createExtArgs.dal.get(nonTranslatablePointer)
}

export const getBlocksData = ({dal, extensionAPI}: CreateExtArgs) => {
    const masterPageData = dal.get(getPointer(MASTER_PAGE_ID, DATA_TYPES.data))
    const appStudioDataItemId = masterPageData.appStudioData
    if (!appStudioDataItemId) {
        return null
    }

    const {dataModel} = extensionAPI as DataModelExtensionAPI
    return dataModel.getItem(_.trimStart(appStudioDataItemId, '#'), DATA_TYPES.data, MASTER_PAGE_ID)
}

export const getWidgetPointerByWidgetId = ({pointers}: CreateExtArgs, widgetId: string) =>
    pointers.data.getDataItemFromMaster(widgetId)

export const getAllWidgets = (createExtArgs: CreateExtArgs) => {
    const appStudioData = getBlocksData(createExtArgs) || {}

    return _.map(appStudioData.widgets, function (widget) {
        const widgetPointer = getWidgetPointerByWidgetId(createExtArgs, widget.id)
        const widgetDataItem = getData(createExtArgs, widgetPointer)
        return {
            pointer: widgetPointer,
            name: widgetDataItem.name,
            panels: widgetDataItem.panels,
            variations: widgetDataItem.variations
        }
    })
}

const findVariationByPageId = (createExtArgs: CreateExtArgs, pageId: string) => {
    const {pointers, dal} = createExtArgs

    const allWidgets = getAllWidgets(createExtArgs)
    let variationId
    const widget = allWidgets.find((currentWidget: any) => {
        variationId = currentWidget.variations.find((currentVariationId: string) => {
            const pointer = pointers.data.getDataItemFromMaster(_.trimStart(currentVariationId, '#'))
            const variationData = dal.get(pointer)
            return variationData?.rootCompId === `#${pageId}`
        })
        return variationId
    })
    return {
        variationId,
        widget
    }
}

const findWidgetByPageId = (createExtArgs: CreateExtArgs, pageId: string): any => {
    const allWidgets = getAllWidgets(createExtArgs)

    const widget = _.find(allWidgets, currentWidget => {
        const widgetData = getData(createExtArgs, currentWidget.pointer)
        return widgetData && widgetData.rootCompId === `#${pageId}`
    })

    return widget ?? findVariationByPageId(createExtArgs, pageId)?.widget
}

const getWidgetByRootCompId = (createExtArgs: CreateExtArgs, rootCompId: string) =>
    findWidgetByPageId(createExtArgs, rootCompId)?.pointer

const getWidgetPointerByRefComp = (createExtArgs: CreateExtArgs, refComp: Pointer) => {
    const {dataModel} = createExtArgs.extensionAPI as DataModelExtensionAPI

    const {pageId: widgetPageId, type} = dataModel.components.getItem(refComp, DATA_TYPES.data)
    if (type === 'InternalRef') {
        return getWidgetByRootCompId(createExtArgs, widgetPageId)
    }
}

export const getRootCompIdByPointer = (createExtArgs: CreateExtArgs, pointer: Pointer): string | undefined => {
    const widgetData = getData(createExtArgs, pointer)

    if (widgetData?.rootCompId) {
        return _.replace(widgetData.rootCompId, '#', '')
    }
}

const getPageByWidgetPointer = (createExtArgs: CreateExtArgs, pointer: Pointer) => {
    const pageId = getRootCompIdByPointer(createExtArgs, pointer)

    return getPointer(pageId!, constants.VIEW_MODES.DESKTOP)
}

const isVariationPage = (createExtArgs: CreateExtArgs, pageId: string) => {
    if (!createExtArgs.pointers.page.isExists(pageId)) {
        return false
    }

    if (_.isUndefined(VARIATIONS_CACHE[pageId])) {
        VARIATIONS_CACHE[pageId] = Boolean(findVariationByPageId(createExtArgs, pageId)?.variationId)
    }

    return VARIATIONS_CACHE[pageId]
}

const isWidgetPage = (createExtArgs: CreateExtArgs, pageId: string) => {
    if (!createExtArgs.pointers.page.isExists(pageId)) {
        return false
    }

    if (_.isUndefined(WIDGETS_CACHE[pageId])) {
        WIDGETS_CACHE[pageId] = Boolean(findWidgetByPageId(createExtArgs, pageId))
    }
    return WIDGETS_CACHE[pageId] || isVariationPage(createExtArgs, pageId)
}

const getAllPanels = (createExtArgs: CreateExtArgs) => {
    const blocksData = getBlocksData(createExtArgs) || {}
    // eslint-disable-next-line lodash/prefer-flat-map
    return _(blocksData.widgets)
        .map('panels')
        .flatten()
        .map(panel => ({
            pointer: panel && createExtArgs.pointers.data.getDataItemFromMaster(panel.id),
            ...panel
        }))
        .value()
}

const findPanelByPageId = (createExtArgs: CreateExtArgs, pageId: string) => {
    const allPanels = getAllPanels(createExtArgs)
    const result = _.find(allPanels, panelData => {
        // MAKE SURE THIS IS ACTUALLY PANEL DATA AND NOT SOMETHING MINIMAL
        return panelData && panelData.rootCompId === `#${pageId}`
    })
    return result
}

const isPanelPage = (createExtArgs: CreateExtArgs, pageId: string) => {
    if (!createExtArgs.pointers.page.isExists(pageId)) {
        return false
    }

    return Boolean(findPanelByPageId(createExtArgs, pageId))
}

const isRootWidgetByComponentType = ({pointers, dal}: CreateExtArgs, compRef: Pointer) =>
    getComponentType(pointers, dal, compRef) === COMP_TYPES.APP_WIDGET_TYPE

const findRootWidgetByComponentType = (createExtArgs: CreateExtArgs, compRef: Pointer, pageId: string): Pointer => {
    const [firstChild] = createExtArgs.pointers.structure.getChildren(compRef)
    if (!firstChild || isRootWidgetByComponentType(createExtArgs, firstChild)) {
        return firstChild
    }
    return findRootWidgetByComponentType(createExtArgs, firstChild, pageId)
}

export const getRootWidgetByPage = (createExtArgs: CreateExtArgs, pagePointer: Pointer) => {
    if (!isWidgetPage(createExtArgs, pagePointer.id) && !isPanelPage(createExtArgs, pagePointer.id)) {
        return
    }
    return findRootWidgetByComponentType(createExtArgs, pagePointer, pagePointer.id)
}

const getAppWidgetRefFromPointer = (createExtArgs: CreateExtArgs, widgetPointer: Pointer) => {
    const pageRef = getPageByWidgetPointer(createExtArgs, widgetPointer)
    return getRootWidgetByPage(createExtArgs, pageRef)
}

const getFirstLevelRefChildren = (createExtArgs: CreateExtArgs, widgetPointer: Pointer) => {
    const widgetRef = getAppWidgetRefFromPointer(createExtArgs, widgetPointer)
    const children = createExtArgs.pointers.structure.getChildrenRecursively(widgetRef!)
    return _.filter(
        children,
        child =>
            getComponentType(createExtArgs.pointers, createExtArgs.dal, child) ===
            'wysiwyg.viewer.components.RefComponent'
    )
}

const getContainedWidgets = (createExtArgs: CreateExtArgs, widgetPointer: Pointer, widgetsMap: Record<string, any>) => {
    const refChildren = getFirstLevelRefChildren(createExtArgs, widgetPointer)
    let containedWidgets: string[] = []

    _.forEach(refChildren, ref => {
        const widgetChildPointer = getWidgetPointerByRefComp(createExtArgs, ref)
        if (widgetChildPointer) {
            if (!widgetsMap[widgetChildPointer.id]) {
                widgetsMap[widgetChildPointer.id] = getContainedWidgets(createExtArgs, widgetChildPointer, widgetsMap)
            }
            containedWidgets = _.concat(containedWidgets, widgetChildPointer.id, widgetsMap[widgetChildPointer.id])
        }
    })
    return _.uniq(containedWidgets)
}

export const getContainingWidgetsMap = (createExtArgs: CreateExtArgs) => {
    const widgets = _.map(getAllWidgets(createExtArgs), 'pointer')
    const widgetsMap = {}
    _.forEach(widgets, widgetPointer => {
        if (!widgetsMap[widgetPointer.id]) {
            widgetsMap[widgetPointer.id] = getContainedWidgets(createExtArgs, widgetPointer, widgetsMap)
        }
    })
    return widgetsMap
}

const getComponentNicknameContext = (createExtArgs: CreateExtArgs, compPointer: Pointer) => {
    const {pointers} = createExtArgs
    if (compPointer) {
        const pagePointer = pointers.structure.isPage(compPointer)
            ? compPointer
            : pointers.structure.getPageOfComponent(compPointer)

        if (!pagePointer) {
            return null
        }

        if (pointers.structure.isMasterPage(pagePointer)) {
            return null
        }

        if (pagePointer) {
            return getRootWidgetByPage(createExtArgs, pagePointer)
        }
    }

    return null
}

export const getBlocksComponentNickname = (createExtArgs: CreateExtArgs, compPointer: Pointer): string | undefined => {
    const {nicknames} = createExtArgs.extensionAPI as NicknamesExtensionAPI
    const nicknameContext = getComponentNicknameContext(createExtArgs, compPointer)

    return nicknames.getComponentNickname(compPointer, nicknameContext!)?.[compPointer.id]
}

export const getAllSerializedCustomDefinitions = (createExtArgs: CreateExtArgs) => {
    const appStudioData = getBlocksData(createExtArgs) || {}

    return _.map(appStudioData.customDefinitions, 'structure')
}
