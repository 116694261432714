import type {PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import themeConstants from '../common/constants'
import themeDAL from '../common/themeDAL'
import themeColors from '../colors/colors'
import experiment from 'experiment-amd'

const {PROPERTY_TYPE} = themeConstants
const {textThemeToFontString} = santaCoreUtils.fonts

function getTextThemes(ps: PS) {
    return themeDAL.getCollection(ps, PROPERTY_TYPE.TEXT_THEME)
}

function getAll(ps: PS) {
    if (experiment.isOpen('dm_moveTextThemeGetUpdateToExt')) {
        return ps.extensionAPI.theme.getTextTheme()
    }
    const textThemes = getTextThemes(ps)
    return santaCoreUtils.fonts.getTextThemesMap(textThemes)
}

function get(ps: PS, index) {
    return themeDAL.getCollectionItem(ps, PROPERTY_TYPE.TEXT_THEME, index)
}

/**
 * We update both font and textTheme, since there are APIs in core-utils that uses font
 * so they won't return an old font string.
 */
function set(ps: PS, index, val) {
    const fontString = textThemeToFontString(val)
    themeDAL.setCollectionItem(ps, PROPERTY_TYPE.TEXT_THEME, index, val)
    themeDAL.setCollectionItem(ps, PROPERTY_TYPE.FONT, index, fontString)
}

function getStyles(ps: PS) {
    const colors = themeColors.getAll(ps)
    const textThemes = getTextThemes(ps)

    return santaCoreUtils.fonts.getTextThemesCss(textThemes, colors)
}

export default {
    set,
    get,
    getAll,
    getStyles
}
