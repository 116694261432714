import baseConfig from './baseConfig'
import {clientCsaveExtensionsWithoutBrowserRendererModel} from './csaveConfigs'

export default {
    shouldRender: true, //maybe false in tests?
    runStylesGC: false,
    supportMobile: true,
    postUpdateOperation: false,
    disableAutoSave: true,
    modulesList: [
        'atomicScopesPublicAPI',
        'accountPublicAPI',
        'accessibilityPublicAPI',
        'structurePublicAPI',
        'dataModelPublicAPI',
        'featuresPublicAPI',
        'slotsPublicAPI',
        'refComponentPublicAPI',
        'statesPublicAPI',
        'triggersPublicAPI',
        'reactionsPublicAPI',
        'documentModePublicAPI',
        'feedbackPublicAPI',
        'renderPluginsPublicApi',
        'savePublicAPI',
        'componentPublicAPI',
        'componentStylePublicAPI',
        'smartBoxesPublicAPI',
        'componentDetectorPublicAPI',
        'siteMetadataPublicAPI',
        'themePublicAPI',
        'pagePublicAPI',
        'pagesGroupPublicAPI',
        'siteSegmentsPublicAPI',
        'componentsMetaDataPublicAPI',
        'appControllerDataPublicAPI',
        'connectionsPublicAPI',
        'platformPublicAPI',
        'tpaPublicAPI',
        'routersPublicAPI',
        'autosavePublicAPI',
        'biPublicAPI',
        'variantsPublicAPI',
        'environmentPublicAPI',
        'actionsAndBehaviorsPublicAPI',
        'debugPublicAPI',
        'fontsPublicAPI',
        'browserThemeColorPublicAPI',
        'mobilePublicAPI',
        'menuPublicAPI',
        'errorsPublicAPI',
        'inlinePopupPublicAPI',
        'multilingualPublicAPI',
        'partnersPublicAPI',
        'variablesPublicAPI',
        'scopesPublicAPI',
        'patternsPublicAPI',
        'keyframeEffectPublicAPI',
        'effectsPublicAPI',
        'deprecatedOldBadPerformancePublicAPI',
        'classicResponsiveLayoutPublicAPI'
    ],
    extensionsList: [
        ...baseConfig.extensionsList,
        'undoRedo',
        'serviceTopology',
        'metadata',
        'save',
        'lifecycle',
        'layoutCircuitBreaker',
        'viewerRuntime',
        'contactFormMetaData',
        'appBuilder',
        'documentToViewer',
        'wixapps',
        'accountInfo',
        'commonConfig',
        'seo',
        ...clientCsaveExtensionsWithoutBrowserRendererModel,
        'componentsByType',
        'partners',
        'views',
        'platformWithViewer',
        'componentsRegistry'
    ]
}
