import _ from 'lodash'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import dataModel from '../../dataModel/dataModel'
import siteMetadata from '../../siteMetadata/siteMetadata'
import blogPaginationCustomizationsGetter from './blogPaginationCustomizationsGetter'
import * as coreUtils from '@wix/santa-core-utils'
import type {Pointer, PS} from '@wix/document-services-types'

export default {
    switchOnBlogPaginationIfSiteIsNew(ps: PS) {
        if (siteIsNew(ps)) {
            addPaginationCustomizationsToFeeds(ps)
            addPaginationCustomizationsToCustomFeeds(ps)
        }
    }
}

function siteIsNew(ps: PS) {
    return siteMetadata.generalInfo.isFirstSave(ps)
}

function addPaginationCustomizationsToFeeds(ps: PS) {
    addPaginationCustomizationsToAppPartsByName(ps, coreUtils.blogAppPartNames.FEED)
}

function addPaginationCustomizationsToCustomFeeds(ps: PS) {
    addPaginationCustomizationsToAppPartsByName(ps, coreUtils.blogAppPartNames.CUSTOM_FEED)
}

function addPaginationCustomizationsToAppPartsByName(ps: PS, name: string) {
    _.forEach(getAppPartPointersByName(ps, name), _.partial(addPaginationCustomizationsToNamedAppPart, ps, name))
}

function addPaginationCustomizationsToNamedAppPart(ps: PS, name: string, pointer: Pointer) {
    const data = dataModel.getDataItem(ps, pointer)
    data.appLogicCustomizations = data.appLogicCustomizations.concat(
        blogPaginationCustomizationsGetter.getBlogPaginationCustomizationsByAppPartName(name)
    )
    dataModel.updateDataItem(ps, pointer, data)
}

function getAppPartPointersByName(ps: PS, name: string) {
    return _.filter(getAppPartPointers(ps), function (pointer) {
        const data = dataModel.getDataItem(ps, pointer)
        return data.appPartName === name
    })
}

function getAppPartPointers(ps: PS) {
    return componentDetectorAPI.getComponentByType(ps, 'wixapps.integration.components.AppPart')
}
