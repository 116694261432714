import type {
    DocumentServicesModel,
    QueryUtils,
    RendererModelFromServer,
    WixBiSession
} from '@wix/document-services-types'
import type {Logger} from '@wix/web-bi-logger/dist/src/types'
import {getBILoggerFactory} from './biLoggerFactory'
import {guidUtils, biLoggerSanitizer, cookieUtils} from '@wix/santa-core-utils'
import _ from 'lodash'

interface ReportDef {
    src: number
    endpoint: string
    adapter?: string
    callCount?: number
    callLimit?: number
    errorName?: string
    severity?: string
    params: Record<string, any>
    reportType?: string
    errorCode?: string
    errc?: string
}

type Params = Record<string, any>

const passedCallLimit = (reportDef: ReportDef): boolean => {
    if (!reportDef) {
        return false
    }
    reportDef.callCount = reportDef.callCount ?? 0
    reportDef.callCount++
    return !!reportDef.callLimit && reportDef.callCount > reportDef.callLimit
}

export const shouldSuppressBI = (queryUtil: QueryUtils) => queryUtil.getParameterByName('suppressbi') === 'true'

const shouldSendReport = (reportDef: ReportDef, isWixSite: boolean, queryUtil: QueryUtils): boolean =>
    !shouldSuppressBI(queryUtil) && !passedCallLimit(reportDef)

const viewerSessionId: Record<string, string> = {}

const eventMap = {
    eventId: 'evid',
    evid: 'evid',
    src: 'src',
    ds_origin: 'ds_origin',
    viewerName: 'viewerName',
    viewerVersion: 'viewerVersion',
    total_js_heap_size: 'total_js_heap_size',
    used_js_heap_size: 'used_js_heap_size',
    js_heap_size_limit: 'js_heap_size_limit'
}

const defaultOptions: Op = {
    endpoint: '',
    params: {}
}

const getVisitorId = (siteId: string, wixBiSession: WixBiSession) =>
    viewerSessionId[siteId] ?? wixBiSession?.viewerSessionId

const errorSeverityMap = {
    recoverable: 10,
    warning: 20,
    error: 30,
    fatal: 40
}

const errorMap = {
    errorName: 'errn',
    errorCode: 'errc',
    errc: 'errc',
    src: 'src',
    severity: 'sev',
    sev: 'sev',
    packageName: 'errscp'
}

function getErrorSeverity(severity: string | number | undefined): number {
    return _.isString(severity) ? errorSeverityMap[severity] : severity
}

const extractReportParamsAccordingToMap = (reportDef: ReportDef, reportMap: Record<string, any>) =>
    _.transform(
        reportDef,
        (accum, val, key) => {
            const mapped = reportMap[key]
            if (mapped) {
                accum[mapped] = val
            }
        },
        {}
    )

const encodeString = (v: any) => (_.isString(v) ? encodeURIComponent(v) : v)

const getParamsFromSite = (siteId: string) => {
    viewerSessionId[siteId] = viewerSessionId[siteId] || guidUtils.getGUID() // FIXME(ssr-guid) - needs to be taken from siteData
    return {site_id: siteId}
}

const extractDefaultErrorDefParams = (reportDef: ReportDef, params: Params, visitorId: string | undefined) => {
    const paramsToReturn: Params = {}
    if (reportDef.src === 44 || (reportDef.src === 42 && visitorId)) {
        paramsToReturn.visitor_id = visitorId
    }

    const dsOrigin = _.get(params, ['dsOrigin'])
    if (dsOrigin) {
        paramsToReturn.dsOrigin = dsOrigin
        if (params.esi) {
            paramsToReturn.esi = params.esi
        }
    }

    return _.assign(paramsToReturn, {
        errn: reportDef.errorName,
        evid: 10,
        sev: getErrorSeverity(reportDef.severity),
        cat: 1,
        iss: 1,
        ut: cookieUtils.getCookie('userType')
    })
}

const sanitizePIIForBi = (paramToSanitize: Record<string, any>) => {
    if (_.isString(paramToSanitize)) {
        return biLoggerSanitizer.sanitizePII(paramToSanitize)
    }
    return paramToSanitize
}

const extractAdditionalParams = (reportDefParams: Record<string, any>, params: Params) => {
    const additionalParams =
        (_.isArray(reportDefParams) && _.pick(params, reportDefParams)) ||
        (_.isObject(reportDefParams) && _.mapValues(reportDefParams, v => params[v])) ||
        params

    return _(additionalParams)
        .mapValues(v => sanitizePIIForBi(v))
        .mapValues(encodeString)
        .value()
}

const getTruncateDescription = (params: Params) => {
    if (_.has(params, ['description'])) {
        return {desc: JSON.stringify(params.description).slice(0, 512)}
    }
    return undefined
}

const extractErrorParams = (reportDef: ReportDef, params: Params, visitorId: string | undefined) =>
    _.merge(
        {
            src: 44,
            sev: 30,
            errn: 'error_name_not_found'
        },
        extractReportParamsAccordingToMap(reportDef, errorMap),
        extractDefaultErrorDefParams(reportDef, params, visitorId),
        extractAdditionalParams(reportDef.params, params),
        getTruncateDescription(params)
    )

const extractEventParams = (reportDef: ReportDef, params: Params) =>
    _.merge(
        {src: 42},
        extractReportParamsAccordingToMap(reportDef, eventMap),
        extractAdditionalParams(reportDef.params, params)
    )

const extractParams = (
    reportType: string,
    reportDef: ReportDef,
    params: Params,
    siteId: string,
    wixBiSession: WixBiSession
): Record<string, any> => {
    let resultParams
    const paramsFromSite = getParamsFromSite(siteId)

    switch (reportType) {
        case 'error':
            const visitorId = getVisitorId(siteId, wixBiSession)
            resultParams = extractErrorParams(reportDef, params, visitorId)
            break
        case 'event':
            resultParams = extractEventParams(reportDef, params)
            break
    }

    return _.merge(resultParams, paramsFromSite)
}

interface Op {
    endpoint: string
    params: Record<string, any>
}

export const createWixBIOptions = (
    reportDef: ReportDef,
    params: Params,
    siteId: string,
    wixBiSession: WixBiSession
): Op => {
    const reportType = reportDef.reportType ?? (reportDef.errorCode || reportDef.errc ? 'error' : 'event')
    return {
        endpoint: reportDef.adapter ?? reportDef.endpoint ?? (reportType === 'error' ? 'trg' : 'ugc-viewer'),
        params: extractParams(reportType, reportDef, params, siteId, wixBiSession)
    }
}

const getCurrentTimeStamp = (wixBiSession: WixBiSession, options: Op) => {
    // using window as fallback
    let start = options.endpoint === 'editor' ? window.parent?.mainLoaded : null
    start = wixBiSession.initialTimestamp ?? wixBiSession.mainLoaded ?? start ?? 0
    return _.now() - start
}

const report = (options: Op, wixBiSession: WixBiSession, biLogger: Logger) => {
    _.defaults(options, defaultOptions)
    const params = _.defaults(options.params, {
        ts: getCurrentTimeStamp(wixBiSession, options),
        rid: wixBiSession.requestId
    })
    return biLogger.log(params, {endpoint: options.endpoint})
}

export const createBILogger = (
    rendererModel: RendererModelFromServer,
    documentServicesModel: DocumentServicesModel,
    dmBase: string,
    rawUrl: string,
    wixBiSession: WixBiSession,
    queryUtil: QueryUtils
) => {
    const biFactory = getBILoggerFactory(rendererModel, documentServicesModel, dmBase, queryUtil, wixBiSession)
    const logger = biFactory.logger({})
    const {siteId} = rendererModel.siteInfo
    const isWixSite = true // not relevant

    const log = (reportDef: ReportDef, params: Params) => {
        if (wixBiSession.forceReportForTests || shouldSendReport(reportDef, isWixSite, queryUtil)) {
            const options = createWixBIOptions(reportDef, params, siteId, wixBiSession)
            if (wixBiSession.reportForTests) {
                wixBiSession.reportForTests(options, wixBiSession)
                return {reported: true}
            }
            const promise = report(options, wixBiSession, logger)
            return {reported: true, promise}
        }
        return {reported: false}
    }

    return {log}
}
