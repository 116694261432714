import type {PS, UrlFormat} from '@wix/document-services-types'
import _ from 'lodash'
import {urlUtils, siteConstants} from '@wix/santa-core-utils'
import dataManipulation from './dataManipulation'
import sitePropertiesInfo from './sitePropertiesInfo'

function getUserInfo(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.USER_INFO)
}

function getUserId(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.USER_ID)
}

function getPublicUrl(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.PUBLIC_URL)
}

function setPublicUrl(ps: PS, publicUrl: string) {
    dataManipulation.setProperty(ps, dataManipulation.PROPERTY_NAMES.PUBLIC_URL, publicUrl)
}

function getSiteId(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SITE_ID)
}

function getDocumentType(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.DOCUMENT_TYPE)
}

function getMetaSiteId(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.META_SITE_ID)
}

function getSiteOriginalTemplateId(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.ORIGINAL_TEMPLATE_ID)
}

function getLanguage(ps: PS) {
    //todo Shimi_Liderman 1/27/15 11:18 REMOVE HARD-CODED VALUE WHEN editorModel is provided
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.LANGUAGE_CODE) || 'en'
}

function getCurrency(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.CURRENCY)
}

function getTimeZone(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.TIME_ZONE)
}

function getLocale(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.LOCALE)
}

function getRegionalLanguage(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.REGIONAL_LANGUAGE)
}

function getGeo(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.GEO)
}

function getUrlFormat(ps: PS) {
    const urlFormatPointer = ps.pointers.general.getUrlFormat()
    const urlFormat = ps.dal.get(urlFormatPointer)
    return urlFormat || siteConstants.URL_FORMATS.HASH_BANG
}

function isUsingUrlFormat(ps: PS, urlFormat: UrlFormat) {
    return getUrlFormat(ps) === urlFormat
}

function isUsingSlashUrlFormat(ps: PS) {
    return isUsingUrlFormat(ps, siteConstants.URL_FORMATS.SLASH as UrlFormat)
}

function getPossibleUrlFormats() {
    return _.clone(siteConstants.URL_FORMATS)
}

function isFirstSave(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.NEVER_SAVED) || false
}

function isDraft(ps: PS): boolean {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.IS_DRAFT) || false
}

function isTemplate(ps: PS) {
    return getSiteId(ps) === getSiteOriginalTemplateId(ps)
}

function isSitePublished(ps: PS) {
    const property = dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.IS_PUBLISHED)
    return property !== null ? property : true
}

function pendingApps(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.PENDING_APPS)
}

function getUserPermissions(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.PERMISSIONS)
}

function isOwner(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.IS_OWNER)
}

function getSiteToken(ps: PS) {
    // should remove when merging specs.media.MediaManager3
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SITE_TOKEN)
}

function getMediaSiteUploadToken(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.MEDIA_SITE_UPLOAD_TOKEN)
}

function getMediaUserUploadToken(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.MEDIA_USER_UPLOAD_TOKEN)
}

function getMediaAuthToken(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.MEDIA_AUTH_TOKEN)
}

function isSiteFromOnBoarding(ps: PS) {
    const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
    const onBoardingSpec = _.find(clientSpecMap, {type: 'onboarding'})
    const isOnboardingInUse = _.get(onBoardingSpec, 'inUse', false) === true

    const useOnboradingMetaSiteFlag = dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.USE_ONBOARDING)

    return isOnboardingInUse && (_.isUndefined(useOnboradingMetaSiteFlag) || useOnboradingMetaSiteFlag)
}

function setUseOnBoarding(ps: PS, useOnboarding: boolean) {
    dataManipulation.setProperty(ps, dataManipulation.PROPERTY_NAMES.USE_ONBOARDING, useOnboarding)
}

function isHttpsEnabled(ps: PS) {
    return !!dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.IS_HTTPS_ENABLED)
}

function getCurrentRevisionPublicUrl(ps: PS) {
    let url = dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.PUBLIC_URL)
    const siteRevision = dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SITE_REVISION)
    const branchId = dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.BRANCH_ID)
    url = urlUtils.setUrlParam(url, 'siteRevision', siteRevision)
    if (branchId) {
        url = urlUtils.setUrlParam(url, 'branchId', branchId)
    }
    return url
}

function getRevision(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SITE_REVISION)
}

function getBranchId(ps: PS) {
    return dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.BRANCH_ID)
}

function getArtifactsUrl(ps: PS, artifactsNames) {
    if (!_.isArray(artifactsNames) || !_.every(artifactsNames, _.isString)) {
        throw new Error('invalid argument artifactsName should be array of strings')
    }
    const serviceTopologyPointer = ps.pointers.general.getServiceTopology()
    const scriptsLocationMapPointer = ps.pointers.getInnerPointer(serviceTopologyPointer, ['scriptsLocationMap'])
    const scriptsLocationMap = ps.dal.get(scriptsLocationMapPointer)

    return _.pick(scriptsLocationMap, artifactsNames)
}

const getArtifactOverrides = function (ps: PS) {
    const currentUrl = ps.siteAPI.getCurrentUrl()
    return urlUtils.getArtifactOverrides(currentUrl.query) || {}
}

/** @class documentServices.generalInfo */
export default {
    /**
     * do rest call to SiteProperyInfo server to get updated data
     */
    sitePropertiesInfo: {
        reload: sitePropertiesInfo.fetchSitePropertiesInfoWithPS
    },
    /**
     * Retrieves artifacts url from script location map
     *
     * @returns {Object} the artifact url {name: {String}, url: {String}}
     */
    getArtifactsUrl,

    /**
     * Retrieves artifact overrides from the site's URL
     *
     * @returns {Object} the map where a key is an artifact override param name and a value is an artifact override URL
     */
    getArtifactOverrides,
    /**
     * Retrieves the site owner's user information
     *
     * @returns {Object} the site's owner user information {name: {String}, email: {String}}
     */
    getUserInfo,

    /**
     * Retrieves the https status of the site
     *
     * @returns {boolean}
     */
    isHttpsEnabled,

    /**
     * Retrieves the site's public URL
     *
     * @returns {string} the site's public URL
     */
    getPublicUrl,

    /**
     * Set the site's public URL
     * @param publicUrl the updated public URL
     */
    setPublicUrl,

    /**
     * Retrieves the site's ID
     *
     * @function
     * @returns {string} the site's ID
     */
    getSiteId,

    /**
     * Retrieves the site's MetaSite ID
     *
     * @function
     * @returns {string} the site's MetaSite ID
     */
    getMetaSiteId,

    /**
     * Retrieve the ID of the template from which the site was created
     *
     * @function
     * @returns {string} the original template ID
     */
    getSiteOriginalTemplateId,

    /**
     * Retrieves the editor's language code, i.e. "en" for "English"
     *
     * @returns {string} the editor's language code
     */
    getLanguage,

    /**
     * Retrieves the site's currency, i.e. "ILS" for "Israel"
     *
     * @returns {string} the site's currency
     */
    getCurrency,

    /**
     * Retrieves the site's timeZone, i.e. "+2" for "Israel"
     *
     * @returns {string} the site's timeZone
     */
    getTimeZone,

    /**
     * Retrieves a locale of the site, i.e. "en-US" for "USA"
     *
     * @returns {string} the locale of the site
     */
    getLocale,

    /**
     * Retrieves a language from the Regional Settings available on rendererModel.sitePropertiesInfo, i.e. "zh" for "Chinese"
     *
     * @returns {string} the regional language of the site
     */
    getRegionalLanguage,

    /**
     * Retrieves the site's owner GEO location identifier i.e. "ISR" for "Israel"
     *
     * @returns {string} the site's language code
     */
    getGeo,

    /**
     * Checks if the site was never saved
     *
     * @returns {boolean} true if the site was never saved before, false otherwise
     */
    isFirstSave,

    /**
     * Checks if the site is template
     *
     * @returns {boolean} true if the site is template, false otherwise
     */
    isTemplate,

    /**
     * Retrieves the document type - template/site
     *
     * @returns {string} document type (template/site)
     */
    getDocumentType,

    /**
     * Checks if the site is published
     *
     * @returns {boolean} true if the site is published, false otherwise.
     */
    isSitePublished,

    /**
     * Retrieves the site's url schema
     *
     * @returns {string} usually "hashBang" or "slash"
     */
    getUrlFormat,

    /**
     * Tests the site's url schema with user input
     *
     * @returns {boolean}
     */
    isUsingUrlFormat,
    /**
     * Tests if site's url schema is "slash"
     *
     * @returns {boolean}
     */
    isUsingSlashUrlFormat,

    /**
     * Get an enum with possible url schemas
     *
     * @returns {enum}
     */
    getPossibleUrlFormats,

    pendingApps,

    /**
     * Returns true if the site was generated in ADI (On Boarding)
     * and wasn't saved in Editor1.4 before
     *
     * @returns {boolean}
     */
    isSiteFromOnBoarding,

    /**
     * Set useOnboarding meta-site flag
     *
     * @param {boolean} useOnboarding
     */
    setUseOnBoarding,

    /**
     * Retrieves the current editor session user's permission information
     *
     * @returns {Object} the current editor session user's permission
     */
    getUserPermissions,

    /**
     * Returns true if the user is the owner of the site, false otherwise
     *
     * @returns {boolean}
     */
    isOwner,

    /**
     * Get the site token
     *
     * @returns {string}
     */
    getSiteToken,
    /**
     * Get the media manager site upload token for opening media manager
     *
     * @returns {string}
     */
    getMediaSiteUploadToken,
    /**
     * Get the media manager user upload token for fonts upload
     *
     * @returns {string}
     */
    getMediaUserUploadToken,
    /**
     * Get the media (files.wix.com) auth token
     *
     * @returns {string}
     */
    getMediaAuthToken,
    /**
     * Retrieves the site owner's user id
     *
     * @returns {String} the site's owner user id
     */
    getUserId,
    /**
     * Retrieves the current site revision's public url
     *
     * @returns {String} the current site revision's public url
     */
    getCurrentRevisionPublicUrl,
    /**
     * Returns the revision of the site
     */
    getRevision,
    /**
     * Returns if the site is in draftMode
     */
    isDraft,
    /**
     * Returns the branchId of the site
     */
    getBranchId
}
