import type {CoreLogger, DocumentManager, ExtensionAPI, ExtensionPublicAPI} from '@wix/document-manager-core'
import type {
    EnvironmentExtensionAPI,
    InnerViewerExtensionAPI,
    LayoutCBApi,
    ViewsExtensionAPI
} from '@wix/document-manager-extensions'
import {constants, Host} from '@wix/document-manager-host-common'
import type {DSConfig, Experiment, PS, RuntimeConfig, SiteAPI, TraceLog} from '@wix/document-services-types'
import type {ViewerManager} from '@wix/viewer-manager-adapter'
import _ from 'lodash'
import {createSiteData} from './dsSiteData'
import {createDal, GSDAL} from './generalSuperDal'
import {createSetOperationsQueue} from './SOQ/SOQ'

const {STYLES} = constants

const enableStylePerPage = (documentManager: DocumentManager) => {
    const stylesPerPage = documentManager.dal.get({
        type: 'data',
        id: 'masterPage',
        innerPath: ['styleSettings', 'stylesPerPage']
    })
    const initialNumPages = documentManager.dal.get({
        type: 'rendererModel',
        id: 'pageList',
        innerPath: ['pages']
    }).length

    if (stylesPerPage && parseFloat(stylesPerPage) >= parseFloat(STYLES.STYLES_PER_PAGE_VER)) {
        return true
    }

    return initialNumPages <= 500
}

const getRuntimeConfig = (
    documentManager: DocumentManager,
    {origin, supportMobile, isReadOnly, supportsPlatformInitialization}: DSConfig,
    experimentInstance: Experiment,
    {viewerName, viewerVersion}: {viewerName: string; viewerVersion: string}
): RuntimeConfig => ({
    origin: origin as string,
    isReadOnly,
    stylesPerPage: enableStylePerPage(documentManager),
    shouldThrowOnDeprecation: false,
    nextHistorySnapshotId: null as unknown as string,
    firstSaveExtraPayload: {},
    trace: {
        isTracing: false,
        log: [] as TraceLog[],
        readLog: [] as TraceLog[],
        shouldLogConsoleTraces: false
    },
    supportsPlatformInitialization,
    disableWixCodeContinuousSave: false,
    supportMobile: _.isUndefined(supportMobile) ? true : supportMobile,
    tpa: {
        section: {
            defaults: {
                skin: undefined as any,
                isContainable: undefined as any
            }
        }
    },
    viewerName,
    viewerVersion
})

interface CreatePSArgs {
    host: Host
    viewerManager: ViewerManager
}

export interface PrivateServices extends PS {
    logger: CoreLogger
    extensionAPI: ExtensionAPI
    publicAPI: ExtensionPublicAPI
}

/**
 * Creates an instance of private services, which hides the documentManager and viewerManager
 */
export const createPrivateServices = ({host, viewerManager}: CreatePSArgs): PrivateServices => {
    const {documentManager} = host
    const {experimentInstance, logger} = documentManager
    const runtimeConfig = getRuntimeConfig(documentManager, host.config, experimentInstance, viewerManager.viewerConfig)
    const dal: GSDAL = createDal(documentManager, viewerManager, host.config)

    const {pointers, extensionAPI, publicAPI} = documentManager
    const {siteAPI, services, viewer}: {siteAPI: SiteAPI; services: any; viewer: InnerViewerExtensionAPI} =
        extensionAPI as any
    const {layoutCircuitBreaker} = host.documentManager.extensionAPI as LayoutCBApi
    const {socketAPI} = host.documentManager.serviceAPIs
    const setOperationsQueue = createSetOperationsQueue(
        viewerManager,
        dal,
        runtimeConfig,
        layoutCircuitBreaker,
        logger,
        host.documentManager.events,
        (host.documentManager.extensionAPI as EnvironmentExtensionAPI).environment.isDebugMode(),
        host.documentManager.experimentInstance,
        viewer
    )

    const {views} = extensionAPI as ViewsExtensionAPI
    const triggerSiteUpdated = setOperationsQueue.triggerSiteUpdated.bind(setOperationsQueue)
    siteAPI.registerToRenderDone(triggerSiteUpdated)
    views?.registerViewsUpdateCallback(triggerSiteUpdated)

    return {
        dal,
        pointers,
        siteAPI,
        extensionAPI,
        publicAPI,
        logger,
        setOperationsQueue,
        viewerActions: viewerManager.actions,
        siteDataAPI: {
            // @ts-ignore
            siteData: createSiteData(viewerManager, {dal, pointers, siteAPI, extensionAPI})
        },
        config: host.config,
        runtimeConfig,
        socketAPI,
        ...services
    }
}
