const pageStructure = {
    skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
    breakpoints: {
        type: 'BreakpointsData',
        values: []
    },
    style: {
        id: '',
        type: 'StylesInBreakpoint',
        skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
        stylesInBreakpoints: [
            {
                type: 'ComponentStyle',
                id: '',
                style: {
                    properties: {
                        'alpha-bg': '1',
                        bg: 'color_11'
                    },
                    propertiesSource: {
                        'alpha-bg': 'value',
                        bg: 'theme'
                    },
                    groups: {}
                },
                componentClassName: '',
                pageId: '',
                compId: '',
                styleType: 'custom',
                skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG'
            }
        ]
    },
    layoutResponsive: {
        type: 'LayoutData',
        componentLayouts: [
            {
                type: 'ComponentLayout',
                height: {
                    type: 'auto'
                },
                width: {
                    type: 'percentage',
                    value: 100
                }
            }
        ],
        itemLayouts: [],
        containerLayouts: [
            {
                type: 'GridContainerLayout',
                rows: [
                    {
                        type: 'auto'
                    }
                ],
                columns: [
                    {
                        type: 'MinMaxSize',
                        min: {
                            type: 'px',
                            value: 0
                        },
                        max: {
                            type: 'fr',
                            value: 1
                        }
                    }
                ]
            }
        ]
    }
}

const getPageStructure = () => pageStructure
const getPanelStructure = () => ({
    componentType: 'mobile.core.components.Container',
    type: 'Container',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
    components: [],
    layouts: {
        type: 'SingleLayoutData',
        componentLayout: {
            minHeight: {
                type: 'px',
                value: 243
            },
            hidden: false,
            height: {
                type: 'auto'
            },
            type: 'ComponentLayout',
            width: {
                type: 'percentage',
                value: 100
            },
            minWidth: {
                type: 'px',
                value: 0
            }
        },
        containerLayout: {
            type: 'GridContainerLayout',
            columns: [
                {
                    type: 'fr',
                    value: 1
                }
            ],
            rows: [
                {
                    type: 'fr',
                    value: 1
                }
            ]
        },
        itemLayout: {
            alignSelf: 'stretch',
            margins: {
                left: {
                    type: 'px',
                    value: 0
                },
                right: {
                    type: 'px',
                    value: 0
                },
                top: {
                    type: 'px',
                    value: 0
                },
                bottom: {
                    type: 'px',
                    value: 0
                }
            },
            gridArea: {
                rowStart: 1,
                rowEnd: 2,
                columnStart: 1,
                columnEnd: 2
            },
            justifySelf: 'stretch',
            type: 'GridItemLayout'
        }
    },
    style: 'c1',
    activeModes: {}
})

export default {
    getPageStructure,
    getPanelStructure
}
