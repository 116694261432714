import adiGroupFixer from './fixers/adiGroupFixer'
import fiveGridLineFullWidthFixer from './fixers/fiveGridLineFullWidthFixer'
import brokenAnchorsFixer from './fixers/brokenAnchorsFixer'
import brokenLoginBarFixer from './fixers/brokenLoginBarFixer'
import orphanSlideDataFixer from './fixers/orphanSlideDataFixer'
import siteMembersDataFixer from './fixers/siteMembersDataFixer'
import textComponentDataFixer from './fixers/textComponentDataFixer'
import VectorImageDataFixer from './fixers/VectorImageDataFixer'
import visualFocusFixer from './fixers/visualFocusFixer'
import mobileAnimationsDataFixer from './fixers/mobileAnimationsDataFixer'
import stylesDataFixer from './fixers/styles/stylesDataFixer'
import menuReferencesFixer from './fixers/menuReferencesFixer'
import soapOrderFixer from './fixers/soapOrderFixer'
import meshLayoutReadySiteDataFixer from './fixers/languageSelectorFixer'
import routersDataFixer from './fixers/meshLayoutReadySiteDataFixer'
import sospDataFixer from './fixers/routersDataFixer'
import languageSelectorFixer from './fixers/sospDataFixer'
import menuContainerDataFixer from './fixers/menuContainerDataFixer'
import addMissingPageStyleFixer from './fixers/styles/addMissingPageStyleFixer'
import formsControllerTypeDataFixer from './fixers/formsControllerTypeDataFixer'
import badPagesDataItemsDataFixer from './fixers/badPagesDataItemsDataFixer'
import customMenuPermanentDataNodesFixer from './fixers/customMenuPermanentDataNodesFixer'
import duplicatedMobileHintsDataFixer from './fixers/duplicatedMobileHintsDataFixer'
import hoverboxSplitOverrideFixer from './fixers/hoverboxSplitOverrideFixer'
import duplicateDesignDataFixer from './fixers/duplicateDesignDataFixer'
import pageBackgroundTranslationFixer from './fixers/pageBackgroundTranslationFixer'
import unknownTypeVariantsFixer from './fixers/unknownTypeVariantsFixer'
import oldBlogDeprecationFixer from './fixers/oldBlogDeprecationFixer'
import dynamicContactFormDeprecationFixer from './fixers/dynamicContactFormDeprecationFixer'
import constants from '../constants/constants'

const dsDataFixersFedOpsTags = constants.INTERACTIONS.DOCUMENT_SERVICES_DATA_FIXERS

export default [
    {
        fixer: fiveGridLineFullWidthFixer,
        config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.FIVE_GRID_LINE_FULL_WIDTH}
    },
    {fixer: adiGroupFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ADI_GROUP}},
    {fixer: brokenAnchorsFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.BROKEN_ANCHORS}},
    {fixer: brokenLoginBarFixer, config: {fixMobileOnly: false, fedopsTag: 'broken-members-login-bar'}},
    {fixer: orphanSlideDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ORPHAN_SLIDER}},
    {fixer: siteMembersDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.SITE_MEMBERS}},
    {fixer: textComponentDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.TEXT_COMPONENET}},
    {fixer: VectorImageDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.VECTOR_IMAGE}},
    {fixer: visualFocusFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.VISUAL_FOCUS}},
    {
        fixer: mobileAnimationsDataFixer,
        config: {fixMobileOnly: true, fedopsTag: dsDataFixersFedOpsTags.MOBILE_ANIMATION}
    },
    {fixer: stylesDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.STYLES}},
    {
        fixer: menuReferencesFixer,
        config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.DUPLICATE_ITEMS_REF_IN_MENU}
    },
    {fixer: soapOrderFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.SOAP_ORDER}},
    {
        fixer: meshLayoutReadySiteDataFixer,
        config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.MESH_LAYOUT_READY_SITE}
    },
    {fixer: routersDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ROUTERS}},
    {fixer: sospDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.SOSP}},
    {fixer: languageSelectorFixer, config: {fixMobileOnly: false, fedopsTag: 'language-selector-fixer'}},
    {fixer: menuContainerDataFixer, config: {fixMobileOnly: true, fedopsTag: dsDataFixersFedOpsTags.MENU_CONTAINER}},
    {
        fixer: addMissingPageStyleFixer,
        config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ADD_MISSING_PAGE_STYLE}
    },
    {fixer: badPagesDataItemsDataFixer, config: {fixMobileOnly: false, fedopsTag: 'bad-pages-data-items-fixer'}},
    {
        fixer: customMenuPermanentDataNodesFixer,
        config: {fixMobileOnly: false, fedopsTag: 'custom-menu-permanent-data-nodes-fixer'}
    },
    {fixer: formsControllerTypeDataFixer, config: {fixMobileOnly: false, fedopsTag: dsDataFixersFedOpsTags.ALL}},
    {
        fixer: duplicatedMobileHintsDataFixer,
        config: {fixMobileOnly: false, fedopsTag: 'duplicated-mobile-hints-data-fixer'}
    },
    {fixer: hoverboxSplitOverrideFixer, config: {fixMobileOnly: false, fedopsTag: 'hoverbox-design-fixer'}},
    {fixer: duplicateDesignDataFixer, config: {fixMobileOnly: false, fedopsTag: 'duplicate-design-data-fixer'}},
    {
        fixer: pageBackgroundTranslationFixer,
        config: {fixMobileOnly: false, fedopsTag: 'page-background-translation-fixer'}
    },
    {fixer: unknownTypeVariantsFixer, config: {fixMobileOnly: false, fedopsTag: 'unknown-type-variants-fixer'}},
    {fixer: oldBlogDeprecationFixer, config: {fixMobileOnly: false, fedopsTag: 'old-blog-deprecation-fixer'}},
    {
        fixer: dynamicContactFormDeprecationFixer,
        config: {fixMobileOnly: false, fedopsTag: 'old-contact-form-deprecation-fixer'}
    }
]
