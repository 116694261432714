import _ from 'lodash'
import type {ExtensionAPI} from '@wix/document-manager-core'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, VIEW_MODES} from '../../constants/constants'
import type {ComponentsAPI as StructureAPI} from '../structure'
import type {DataModelExtensionAPI} from '../dataModel'
import type {MenuData, Pointer, Pointers} from '@wix/document-services-types'
import unset from 'lodash/fp/unset'
import type {NicknamesExtensionAPI} from '../nicknames'
import {getBaseComponentIdFromRefferredId} from '../../utils/refStructureUtils'
import {getIdFromRef} from '../../utils/dataUtils'
import {ReportableError} from '@wix/document-manager-utils'

export type Comp = Record<string, any>

export type CompOp = (comp: Comp) => Comp

export interface PageExport {
    page: Record<string, any>
    components: Record<string, any>
}

export interface CustomMenuExportData extends MenuData {}

export interface SiteExport {
    pages: string[]
    appState: Record<string, string>
    menus: Record<string, CustomMenuExportData>
}

export const errorTypes = {
    IMPORT_MENU_VALIDATION_ERROR: 'ImportMenuValidationError',
    INVALID_COMPONENT: 'InvalidComponentData',
    INVALID_PAGE: 'InvalidImportedPageData'
}

const _replaceAllStrings = <T>(obj: T, replacer: (s: string, key: string | null) => string, key: string | null): T => {
    if (_.isPlainObject(obj)) {
        return _.mapValues(obj as Record<string, any>, (v, k) => _replaceAllStrings(v, replacer, k)) as T
    }
    if (_.isArray(obj)) {
        return obj.map(v => _replaceAllStrings(v, replacer, null)) as T
    }
    if (_.isString(obj)) {
        return replacer(obj, key) as T
    }
    return obj
}

export const replaceAllStrings = <T>(obj: T, replacer: (s: string, key: string | null) => string): T => {
    return _replaceAllStrings(obj, replacer, null)
}

export const mapPageExport =
    (f: CompOp) =>
    ({page, components}: PageExport): PageExport => ({
        page: f(page),
        components: _.mapValues(components, f)
    })

export const mapPageExportWithKeys =
    (f: (c: Comp, key: string) => Comp) =>
    ({page, components}: PageExport, pageId: string): PageExport => ({
        page: f(page, pageId),
        components: _.mapValues(components, f)
    })

export const replaceReferencesInComp =
    (idsToNicknames: Record<string, string>) =>
    (comp: Comp): Comp =>
        replaceAllStrings(comp, (s, k) => {
            const nick = idsToNicknames[s]
            return nick && k !== 'id' && k !== 'pageUriSEO' ? nick : s
        })

const sanitizeTPAData: CompOp = unset(['data', 'applicationId'])
const removeHashes: CompOp = comp => replaceAllStrings(comp, getIdFromRef)

const normalizeConnections: CompOp = comp => {
    const items = _.get(comp, ['connections', 'items'])
    if (!items) {
        return comp
    }
    return {
        ...comp,
        connections: items
    }
}

export const compToPointer = (comp: Comp): Pointer => {
    if (!comp.id) {
        throw new ReportableError({
            errorType: errorTypes.INVALID_COMPONENT,
            message: 'Cannot create pointer without an id'
        })
    }
    return {id: comp.id, type: VIEW_MODES.DESKTOP}
}

const getExtendedProtoComp =
    (extensionApi: ExtensionAPI) =>
    (comp: Comp): Comp => {
        const pointer = compToPointer(comp)
        const {getItem} = (extensionApi as DataModelExtensionAPI).dataModel.components
        const items = _.mapValues(COMP_DATA_QUERY_KEYS_WITH_STYLE, (v, ns) => getItem(pointer, ns))
        return {
            ...comp,
            ...items,
            layout: items.layout ?? comp.layout,
            pageId: comp.metaData?.pageId
        }
    }

export const isPage =
    (pointers: Pointers) =>
    (comp: Comp): boolean =>
        !!pointers.structure.isPage(compToPointer(comp))

const getNickname = (pointers: Pointers, extensionApi: ExtensionAPI, comp: Comp): string | null => {
    if (isPage(pointers)(comp)) {
        return comp.data.pageUriSEO
    }
    const pointer = compToPointer(comp)
    const nickname = (extensionApi as NicknamesExtensionAPI).nicknames.getComponentNickname(pointer)
    const inflatedKey =
        comp.type === 'RefComponent'
            ? Object.keys(nickname).find(key => getBaseComponentIdFromRefferredId(key) === pointer.id)
            : pointer.id

    if (!inflatedKey) {
        return null
    }

    return nickname?.[inflatedKey] ?? null
}

const insertNickname =
    (pointers: Pointers, extensionApi: ExtensionAPI) =>
    (comp: Comp): Comp => {
        const nick = getNickname(pointers, extensionApi, comp)

        if (nick) {
            return {...comp, nickname: nick}
        }

        return comp
    }

const getAllDesktopComps = (extensionApi: ExtensionAPI): Record<string, Comp> =>
    (extensionApi.components as StructureAPI).getAllDesktopComponents()

const normalizeComp = (extensionApi: ExtensionAPI, pointers: Pointers): ((comp: Comp) => Comp) =>
    _.flow([
        getExtendedProtoComp(extensionApi),
        normalizeConnections,
        insertNickname(pointers, extensionApi),
        sanitizeTPAData,
        removeHashes
    ])

const getComps = (extensionApi: ExtensionAPI, pointers: Pointers, filter: (c: Comp) => boolean): Comp[] =>
    _(getAllDesktopComps(extensionApi)).values().filter(filter).map(normalizeComp(extensionApi, pointers)).value()

export const getCompsInPage = (pageId: string, extensionApi: ExtensionAPI, pointers: Pointers): Comp[] =>
    getComps(extensionApi, pointers, comp => comp.metaData.pageId === pageId)

export const collectIdsToNicknamesMap = (comps: Comp[]): Record<string, string> =>
    _(comps)
        .map(c => [c.id, c.nickname])
        .fromPairs()
        .omitBy(_.isNil)
        .value()

const getAllPages = (extensionApi: ExtensionAPI, pointers: Pointers): Comp[] =>
    getComps(extensionApi, pointers, isPage(pointers))

export const getAllPageNicknames = (extensionApi: ExtensionAPI, pointers: Pointers): Record<string, string> =>
    collectIdsToNicknamesMap(getAllPages(extensionApi, pointers))
