import {ReportableError} from '@wix/document-manager-utils'
import type {ComponentSchemasDefinition} from '@wix/document-services-types'
import documentServicesSchemas from 'document-services-schemas'
import metaDataRegistrar from '../componentsMetaData/metaDataRegistrar'
import componentHooksRegistrar from '../hooks/componentHooksRegistrar'
import {contextAdapter} from '../utils/contextAdapter'

const DEFAULT_OPTIONS = {
    registerComponentDefinitionAndSchemas: (compType: string, c: ComponentSchemasDefinition, allowOverrides: boolean) =>
        documentServicesSchemas.services.schemasService.registerComponentDefinitionAndSchemas(
            compType,
            c,
            allowOverrides
        ), //wrapper function due to spies in dsCompRegistrar.spec
    registerSchemas: true
}

function informNoComponentType() {
    contextAdapter.utils.fedopsLogger.captureError(
        new ReportableError({
            errorType: 'invalidComponentRegistration',
            message: 'Could not dynamically register a component without componentType'
        }),
        {tags: {dsComponentRegistration: true}}
    )
}

function informUnauthorizedComponentOverride(componentType) {
    contextAdapter.utils.fedopsLogger.captureError(
        new ReportableError({
            errorType: 'invalidComponentRegistration',
            message: `Could not dynamically register ${componentType} as it is not externalizable`
        }),
        {tags: {dsComponentRegistration: true}}
    )
}

const getRegistrarOptions = ps => ({
    ...DEFAULT_OPTIONS,
    registerSchemas: ps.config.schemaDevMode
})

function registerComponent(ps, component) {
    registerComponentWithOptions(component, getRegistrarOptions(ps))
}

function registerComponentWithOptions(
    {componentType, componentDefinition, hooks, metaData, dataSchema, propertiesSchema},
    options = DEFAULT_OPTIONS
) {
    const {registerComponentDefinitionAndSchemas, registerSchemas} = {...DEFAULT_OPTIONS, ...options}

    if (!componentType) {
        informNoComponentType()
        return
    }

    const currentSchema = documentServicesSchemas.services.schemasService.getDefinition(componentType)

    if (currentSchema && !currentSchema.externalizable) {
        informUnauthorizedComponentOverride(componentType)
        return
    }

    if (metaData) {
        contextAdapter.utils.fedopsLogger.breadcrumb(`registering metaData for component of type ${componentType}`)
        metaDataRegistrar.unregisterMetaData(componentType)
        metaDataRegistrar.registerExternalMetaData(componentType, metaData)
    }

    if (hooks) {
        contextAdapter.utils.fedopsLogger.breadcrumb(`registering hooks for component of type ${componentType}`)
        componentHooksRegistrar.registerExternalHooks(componentType, hooks)
    }

    if (componentDefinition?.[componentType].skins) {
        const {skins, responsiveSkins} = componentDefinition[componentType]
        contextAdapter.utils.fedopsLogger.breadcrumb(
            `registering skinByComponent type for component of type ${componentType}`
        )
        documentServicesSchemas.services.registerSkinsByCompType(componentType, {skins, responsiveSkins})
    }
    if (registerSchemas) {
        registerComponentDefinitionAndSchemas(
            componentType,
            {
                componentDefinition,
                dataSchemas: dataSchema,
                propertiesSchemas: propertiesSchema,
                componentType: undefined
            },
            registerSchemas
        )
    }
}

async function loadAndRegisterComponent(componentType, loader, options) {
    const comp = await loader()
    registerComponentWithOptions({componentType, ...comp}, options)
}

async function registerComponentsFromExternalRegistryWithOptions(
    registry,
    {libraries, mode},
    options: {registerSchemas?: boolean} = {registerSchemas: false}
) {
    const optionsWithDefaults = {...DEFAULT_OPTIONS, ...options}

    const asyncLoadOps = []
    registry({
        mode,
        libraries,
        registerComponent: (componentType, loader) => {
            const loadOp = loadAndRegisterComponent(componentType, loader, optionsWithDefaults)
            asyncLoadOps.push(loadOp)
        }
    })

    await Promise.all(asyncLoadOps)
}

/** Register all components of an external registry
 *
 * The registry should comply with the interface specified in @wix/editor-elements-registry/2.0/documentManagement
 * Namely, the registry should provide a `default` method which accepts an object with a `registerComponent` method.
 * The registry will call `registerComponent` for each component it holds. It will pass as params the name
 * of the components and an async loader function that resolves to the component specification.
 * The component specification is the same object that `registerComponent` expects.
 *
 * @param registry
 * @param registryOptions
 * @returns {Promise<void>}
 */
async function registerComponentsFromExternalRegistry(registry, registryOptions) {
    await registerComponentsFromExternalRegistryWithOptions(registry, registryOptions, DEFAULT_OPTIONS)
}

export default {
    getRegistrarOptions,
    registerComponent,
    registerComponentWithOptions,
    registerComponentsFromExternalRegistry,
    registerComponentsFromExternalRegistryWithOptions
}
