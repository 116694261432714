/*eslint max-statements:0*/
import type {Layout, Pointer, PS} from '@wix/document-services-types'
import {
    constants as coreConst,
    displayedOnlyStructureUtil,
    dockUtils,
    layoutUtils as coreLayoutUtils,
    siteConstants
} from '@wix/santa-core-utils'
import _ from 'lodash'
import actionsAndBehaviors from '../actionsAndBehaviors/actionsAndBehaviors'
import biEvents from '../bi/events.json'
import componentModes from '../component/componentModes'
import componentStructureInfo from '../component/componentStructureInfo'
import sospModes from '../component/sospModes'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import hooks from '../hooks/hooks'
import layoutPlugins from '../layoutPlugins/layoutPlugins'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import modesUtils from '../modes/modesUtils'
import repeaterUtils from '../utils/repeater'
import dsUtils from '../utils/utils'
import design from '../variants/design'
import variantsUtils from '../variants/variantsUtils'
import layoutUtils from './layoutUtils'
import siteGapMap from './siteGapMap'
import structureUtils from './structureUtils'
import arrangement from './utils/arrangement'
import componentLayout from './utils/componentLayout'
import layoutConstraintsUtils from './utils/layoutConstraintsUtils'
import layoutSettingsUtils from './utils/layoutSettingsUtils'
import layoutValidation from './utils/layoutValidation'
import type {FTDExtApi} from '@wix/document-manager-extensions'

export interface ProportionStructure {
    component: any
    proportions: Layout
    minLayout: Layout
    maxLayout: Layout
    children: ProportionStructure[]
}

const {COMP_DATA_QUERY_KEYS_WITH_STYLE, COMP_DATA_QUERY_KEYS, DATA_TYPES} = constants
const {getRepeaterTemplateId, isDisplayedOnlyComponent, getRepeaterItemId, getUniqueDisplayedId, getOriginalStructure} =
    displayedOnlyStructureUtil
const VALID_PROPS_FOR_LAYOUT_UPDATE = [
    'x',
    'y',
    'width',
    'height',
    'rotationInDegrees',
    'scale',
    'fixedPosition',
    'aspectRatio'
]
const LAYOUT_UPDATE_PROPS_TO_IGNORE = ['bounding', 'anchors']
const ANY = 'ANY'
const simpleLayout = {
    x: ANY,
    y: ANY,
    width: ANY,
    height: ANY,
    anchors: ANY,
    bounding: ANY,
    rotationInDegrees: 0,
    scale: 1,
    fixedPosition: false
}

function validateLayoutForLayoutUpdate(compLayout) {
    const unsupportedProperties = _.omit(compLayout, VALID_PROPS_FOR_LAYOUT_UPDATE)
    if (!_.isEmpty(unsupportedProperties)) {
        throw new Error('updateCompLayout: new layout properties are not supported')
    }
}

function getSanitizedLayoutForUpdate(compLayout: Layout): Layout {
    return _.omit(compLayout, LAYOUT_UPDATE_PROPS_TO_IGNORE) as any
}

function isSimpleLayout(layout) {
    if (!layout) {
        return true
    }
    let isSimple = true
    _.forOwn(layout, function (value, key) {
        const valueInSimple = simpleLayout[key]
        /*eslint eqeqeq:0*/ //I don't know if fixedPosition can be null as well...
        if (_.isUndefined(valueInSimple) || (valueInSimple !== ANY && value != valueInSimple)) {
            isSimple = false
            return false
        }
    })
    return isSimple
}

function getCoordinatesRelativeToContainer(ps: PS, compPointer, containerPointer) {
    const compLayout = layoutUtils.getCompLayoutRelativeToStructure(ps, compPointer)
    const pageLayout = layoutUtils.getCompLayoutRelativeToStructure(ps, containerPointer)

    const relativeToContainerPosition = _.pick(compLayout, ['x', 'y'])
    relativeToContainerPosition.y -= pageLayout.y

    return relativeToContainerPosition
}

function reparentComponentToPage(ps: PS, compPointer, keepPosition?) {
    const currentContainer = ps.pointers.components.getParent(compPointer)
    if (!ps.pointers.components.isPage(currentContainer)) {
        let newContainerPointer = componentStructureInfo.getPage(ps, compPointer)
        newContainerPointer = componentStructureInfo.getContainerToAddComponentTo(ps, newContainerPointer)
        const relativeToPageCoordinates = keepPosition
            ? getCoordinatesRelativeToContainer(ps, compPointer, newContainerPointer)
            : null

        addCompToContainer(ps, compPointer, newContainerPointer)

        if (keepPosition) {
            updateCompLayout(ps, compPointer, relativeToPageCoordinates)
        }
    }
}

function adjustLayoutForFixedPositionUpdate(ps: PS, compPointer, isFixed, compLayout) {
    const adjustedLayout: any = {fixedPosition: isFixed}

    if (isFixed) {
        const compLayoutRelativeToScreen = layoutUtils.getCompLayoutRelativeToScreen(ps, compPointer)

        _.assign(adjustedLayout, _.pick(compLayoutRelativeToScreen, ['x', 'y']))
        adjustedLayout.y -= getScroll(ps).y
    } else {
        const parentPointer = ps.pointers.components.getParent(compPointer)
        const parentLayoutRelativeToScreen = layoutUtils.getCompLayoutRelativeToScreen(ps, parentPointer, true)

        _.assign(adjustedLayout, {
            y: compLayout.y - parentLayoutRelativeToScreen.y + getScroll(ps).y,
            x: compLayout.x - parentLayoutRelativeToScreen.x
        })
    }

    updateCompLayout(ps, compPointer, adjustedLayout)
}

function updateAspectRatio(ps: PS, compPointer, layout?) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    layout = layout || ps.dal.get(layoutPointer)

    const positionAndSize = structureUtils.getPositionAndSize(ps, compPointer, _.omit(layout, 'aspectRatio'))
    const newAspectRatio = coreLayoutUtils.calcAspectRatio(positionAndSize.width, positionAndSize.height)
    const aspectRatioPointer = ps.pointers.getInnerPointer(layoutPointer, 'aspectRatio')
    ps.dal.set(aspectRatioPointer, newAspectRatio)
}

function removeAspectRatio(ps: PS, compPointer) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const layout = ps.dal.get(layoutPointer)

    if (!_.isUndefined(layout.aspectRatio)) {
        delete layout.aspectRatio
        ps.dal.set(layoutPointer, layout)
    }
}

function isAspectRatioOn(ps: PS, compPointer) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const layout = ps.dal.get(layoutPointer)

    return coreLayoutUtils.isAspectRatioOn(layout)
}

const isMasterPage = (ps: PS, compPointer) =>
    ps.pointers.components.isSameComponent(compPointer, ps.pointers.components.getMasterPage(compPointer.type))

const isSiteShowOnAllPagesZindexMigrated = (ps: PS) =>
    _.get(getLayoutSettings(ps), 'soapCompsAroundPagesContainer', false)

const getLayoutSettings = (ps: PS) => layoutSettingsUtils.getLayoutSettings(ps)

const fixCompIndexAfterToggleOffFixedPosition = (ps: PS, compPointer) => {
    const parentPointer = ps.pointers.components.getParent(compPointer)

    if (isMasterPage(ps, parentPointer) && isSiteShowOnAllPagesZindexMigrated(ps)) {
        arrangement.fixIndexForMasterPageChild(ps, compPointer)
    }
}

function updateFixedPosition(ps: PS, compPointer, isFixed) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const layout = ps.dal.get(layoutPointer)

    if (isFixed === layout.fixedPosition) {
        return
    }

    adjustLayoutForFixedPositionUpdate(ps, compPointer, isFixed, layout)

    if (isFixed) {
        reparentComponentToPage(ps, compPointer)
    } else {
        fixCompIndexAfterToggleOffFixedPosition(ps, compPointer)
    }
}

function didCompResizeFromLeft(oldPositionAndSize, updatedLayout) {
    return updatedLayout.width !== oldPositionAndSize.width && updatedLayout.x !== oldPositionAndSize.x
}

function didCompResizeFromTop(oldPositionAndSize, updatedLayout) {
    return updatedLayout.height !== oldPositionAndSize.height && updatedLayout.y !== oldPositionAndSize.y
}

function moveChildrenToKeepThemInPlace(ps: PS, compPointer, oldCompLayout, newCompLayout) {
    const childrenPointers = ps.pointers.components.getChildren(compPointer)

    const diffX = (newCompLayout.x || 0) - (oldCompLayout.x || 0)
    const diffY = (newCompLayout.y || 0) - (oldCompLayout.y || 0)
    _.forEach(childrenPointers, function (childPointer) {
        const childLayoutPointer = ps.pointers.getInnerPointer(childPointer, 'layout')
        const childLayout = ps.dal.get(childLayoutPointer)
        if (!structureUtils.isHorizontallyDocked(childLayout)) {
            childLayout.x -= diffX
        }
        if (!structureUtils.isVerticallyDocked(childLayout)) {
            childLayout.y -= diffY
        }
        ps.dal.merge(childLayoutPointer, childLayout)
    })
}

function getUpdateCompLayoutCallbackForHooks(isCallerFuncUpdateCompLayoutAndAdjustLayout) {
    const updateFunc = isCallerFuncUpdateCompLayoutAndAdjustLayout ? updateCompLayoutAndAdjustLayout : updateCompLayout

    return function updateCompLayoutCallbackForHooks(ps, compPtr, newLayout) {
        updateFunc(ps, compPtr, newLayout, true)
    }
}

const shouldEnforceKeepChildrenInPlace = (ps: PS) =>
    ps.dal.get(ps.pointers.general.getRenderFlag('enforceShouldKeepChildrenInPlace'))
const shouldPreserveCompLayoutOnReparent = (ps: PS) =>
    ps.dal.get(ps.pointers.general.getRenderFlag('preserveCompLayoutOnReparent'))

/**
 * updates the component layout and the anchors. The layout object can be a subset of {x, y, width, height, rotationInDegrees}
 * @param ps
 * @param compPointer
 * @param newLayout
 * @param isCallerFuncUpdateCompLayoutAndAdjustLayout
 * @param isTriggeredByHook
 */
function changeCompPositionAndSize(
    ps: PS,
    compPointer,
    newLayout: Layout,
    isCallerFuncUpdateCompLayoutAndAdjustLayout,
    isTriggeredByHook
) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const previousLayout = ps.dal.get(layoutPointer)
    const compType = componentStructureInfo.getType(ps, compPointer)
    const updateCompLayoutCallbackForHooks = getUpdateCompLayoutCallbackForHooks(
        isCallerFuncUpdateCompLayoutAndAdjustLayout
    )

    hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, compType, [
        ps,
        compPointer,
        newLayout,
        updateCompLayoutCallbackForHooks,
        isTriggeredByHook,
        previousLayout
    ])

    const updatedLayout = layoutValidation.getValidLayoutToUpdate(ps, compPointer, newLayout)

    layoutConstraintsUtils.constrainByDimensionsLimits(ps, compPointer, updatedLayout)

    if (
        componentsMetaData.public.isContainer(ps, compPointer) &&
        componentsMetaData.public.isEnforcingContainerChildLimitations(ps, compPointer)
    ) {
        const isEnforcingByWidth = componentsMetaData.public.isEnforcingContainerChildLimitationsByWidth(
            ps,
            compPointer
        )
        const isEnforcingByHeight = componentsMetaData.public.isEnforcingContainerChildLimitationsByHeight(
            ps,
            compPointer
        )

        layoutConstraintsUtils.constrainByChildrenLayout(
            ps,
            compPointer,
            updatedLayout,
            !isEnforcingByWidth,
            !isEnforcingByHeight
        )
    }
    layoutConstraintsUtils.constrainBySpecificType(ps, compPointer, updatedLayout)
    layoutConstraintsUtils.constrainsByContainer(ps, compPointer, updatedLayout, previousLayout)

    const currentPositionAndSize = structureUtils.getPositionAndSize(ps, compPointer)

    ps.dal.set(layoutPointer, updatedLayout)

    if (_.has(updatedLayout, 'aspectRatio')) {
        updateAspectRatio(ps, compPointer, updatedLayout)
    }

    if (componentsMetaData.getShouldKeepChildrenInPlace(ps, compPointer) && shouldEnforceKeepChildrenInPlace(ps)) {
        const newPositionAndSize = structureUtils.getPositionAndSize(ps, compPointer)

        const didResizeFromLeft = didCompResizeFromLeft(currentPositionAndSize, updatedLayout)
        const didResizeFromTop = didCompResizeFromTop(currentPositionAndSize, updatedLayout)
        if (didResizeFromLeft || didResizeFromTop) {
            moveChildrenToKeepThemInPlace(ps, compPointer, currentPositionAndSize, newPositionAndSize)
        }
    }

    hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, compType, [
        ps,
        compPointer,
        updatedLayout,
        updateCompLayoutCallbackForHooks,
        isTriggeredByHook,
        previousLayout
    ])
}

function changeCompPositionAndSizeAndPreserveProportion(ps: PS, compPointer, newLayout: Layout) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const previousLayout = ps.dal.get(layoutPointer)
    newLayout = getLayoutForUpdateOnCurrentSchema(ps, compPointer, newLayout)
    const compType = componentStructureInfo.getType(ps, compPointer)
    const updateCompLayoutCallbackForHooks = getUpdateCompLayoutCallbackForHooks(false)
    hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, compType, [
        ps,
        compPointer,
        newLayout,
        updateCompLayoutCallbackForHooks,
        false,
        previousLayout
    ])

    const updatedLayout = layoutValidation.getValidLayoutToUpdate(ps, compPointer, newLayout)

    ps.dal.set(layoutPointer, updatedLayout)

    hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, compType, [
        ps,
        compPointer,
        updatedLayout,
        updateCompLayout,
        false,
        previousLayout
    ])
}

/**
 * Update component layout and preserve its aspect ratio and child parent proportions
 * @param {ps} ps
 * @param newLayout
 * @param proportionStructure
 * @param isRoot
 */
function updateCompLayoutAndPreserveProportions(
    ps: PS,
    newLayout: Layout,
    proportionStructure: ProportionStructure,
    isRoot = false
) {
    const ignoreChildren = componentsMetaData.public.isIgnoreChildrenOnProportionalResize(
        ps,
        proportionStructure.component
    )
    const enforceMax = componentsMetaData.public.enforceMaxDimensionsOnProportionalResize(
        ps,
        proportionStructure.component
    )
    let structureChildren = proportionStructure.children
    const previousLayout = ps.dal.get(ps.pointers.getInnerPointer(proportionStructure.component, 'layout'))
    layoutConstraintsUtils.constrainProportionalResize(ps, proportionStructure, newLayout, isRoot, enforceMax)
    layoutConstraintsUtils.constrainsByContainer(ps, proportionStructure.component, newLayout, previousLayout, true)
    if (_.isArray(ignoreChildren)) {
        structureChildren = structureUtils.getChildrenToPreserveProportionsByType(
            ps,
            componentsMetaData,
            structureChildren,
            ignoreChildren
        )
    }
    if (ignoreChildren !== true && !_.isEmpty(structureChildren)) {
        _.forEach(structureChildren, function updateChildLayoutAndPreserveProportions(childProportionStructure) {
            const newChildLayout = calcLayoutFromLayoutAndRatios(newLayout, childProportionStructure.proportions)
            updateCompLayoutAndPreserveProportions(ps, newChildLayout, childProportionStructure, false)
        })
    }
    newLayout = _.pick(newLayout, ['x', 'y', 'width', 'height'])
    changeCompPositionAndSizeAndPreserveProportion(ps, proportionStructure.component, newLayout)
}

function getChildParentLayoutRatio(childLayout: Layout, parentLayout: Layout) {
    return {
        x: childLayout.x / parentLayout.width,
        y: childLayout.y / parentLayout.height,
        width: childLayout.width / parentLayout.width,
        height: childLayout.height / parentLayout.height
    }
}

function calcLayoutFromLayoutAndRatios(layout: Layout, layoutRatios) {
    let calculatedLayout = {
        x: layout.width * layoutRatios.x,
        y: layout.height * layoutRatios.y,
        width: layout.width * layoutRatios.width,
        height: layout.height * layoutRatios.height
    }

    // @ts-expect-error
    calculatedLayout = _(calculatedLayout).pickBy(_.isFinite).mapValues(Math.round).value()
    return calculatedLayout
}

function getLayoutForUpdateOnCurrentSchema(ps: PS, compPointer: Pointer, newLayout: Layout) {
    if (isDocked(ps, compPointer)) {
        const updatedLayout = componentLayout.applyPositionAndSizeOnCurrentLayoutSchema(ps, compPointer, newLayout)
        return _.assign(updatedLayout, newLayout)
    }
    return newLayout
}

function validateAndUpdateLayout(
    ps: PS,
    compPointer: Pointer,
    newLayout: Layout,
    isCallerFuncUpdateCompLayoutAndAdjustLayout,
    isTriggeredByHook
) {
    const sanitizedLayout = getSanitizedLayoutForUpdate(newLayout)
    if (!_.isEmpty(sanitizedLayout)) {
        validateLayoutForLayoutUpdate(sanitizedLayout)
        changeCompPositionAndSize(
            ps,
            compPointer,
            getLayoutForUpdateOnCurrentSchema(ps, compPointer, sanitizedLayout),
            isCallerFuncUpdateCompLayoutAndAdjustLayout,
            isTriggeredByHook
        )
    }
}

function updateCompLayout(ps: PS, compPointer, newLayout, isTriggeredByHook?) {
    validateAndUpdateLayout(ps, compPointer, newLayout, false, isTriggeredByHook)
}

function syncTemplateLayout(ps: PS, compPointer: Pointer) {
    ;(ps.extensionAPI as FTDExtApi).repeaters.syncTemplateLayout(compPointer)
}

function updateCompLayoutAndAdjustLayout(ps: PS, compPointer, newLayout, isTriggeredByHook?) {
    validateAndUpdateLayout(ps, compPointer, newLayout, true, isTriggeredByHook)
}

function isReparentingOutsideOfDisplayedOnlyContainer(compPointer, newContainerPointer) {
    const isContainerDisplayedOnly = isDisplayedOnlyComponent(newContainerPointer.id)
    const isCompDisplayedOnly = isDisplayedOnlyComponent(compPointer.id)
    return !isContainerDisplayedOnly && isCompDisplayedOnly
}

function isReparentingIntoDisplayedOnlyContainer(compPointer, newContainerPointer) {
    const isContainerDisplayedOnly = isDisplayedOnlyComponent(newContainerPointer.id)
    const isCompDisplayedOnly = isDisplayedOnlyComponent(compPointer.id)
    return isContainerDisplayedOnly && !isCompDisplayedOnly
}

function isReparentingToOtherRepeater(ps: PS, compPointer, newContainerPointer) {
    const isContainerDisplayedOnly = isDisplayedOnlyComponent(newContainerPointer.id)
    const isCompDisplayedOnly = isDisplayedOnlyComponent(compPointer.id)
    if (isContainerDisplayedOnly && isCompDisplayedOnly) {
        const compRepeaterAncestor = componentStructureInfo.getAncestorByPredicate(ps, compPointer, comp =>
            repeaterUtils.isRepeater(ps, comp)
        )
        const newContainerRepeaterAncestor = componentStructureInfo.getAncestorByPredicate(
            ps,
            newContainerPointer,
            comp => repeaterUtils.isRepeater(ps, comp)
        )
        return !ps.pointers.components.isSameComponent(compRepeaterAncestor, newContainerRepeaterAncestor)
    }
    return false
}

function getNewComponentPointerAfterReparent(ps: PS, compPointer, newContainerPointer) {
    const pointerToReturn = _.clone(compPointer)

    if (isReparentingToOtherRepeater(ps, compPointer, newContainerPointer)) {
        const origId = getRepeaterTemplateId(compPointer.id)
        const itemId = getRepeaterItemId(newContainerPointer.id)
        pointerToReturn.id = getUniqueDisplayedId(origId, itemId)
    } else if (isReparentingOutsideOfDisplayedOnlyContainer(compPointer, newContainerPointer)) {
        pointerToReturn.id = getRepeaterTemplateId(compPointer.id)
    } else if (isReparentingIntoDisplayedOnlyContainer(compPointer, newContainerPointer)) {
        const itemId = getRepeaterItemId(newContainerPointer.id)
        pointerToReturn.id = getUniqueDisplayedId(compPointer.id, itemId)
    }

    const viewMode = ps.pointers.components.getViewMode(newContainerPointer)
    return ps.pointers.components.getUnattached(pointerToReturn.id, viewMode)
}

function isScopeChange(ps: PS, componentPointer, newContainerPointer) {
    const oldContainerPointer = ps.pointers.components.getParent(componentPointer)

    return (
        (ps.pointers.components.isPage(oldContainerPointer) &&
            ps.pointers.components.isMasterPage(newContainerPointer)) ||
        (ps.pointers.components.isPage(newContainerPointer) && ps.pointers.components.isMasterPage(oldContainerPointer))
    )
}

function updateFixedPositionForReparent(ps: PS, componentPointer, newContainerPointer) {
    if (
        layoutUtils.isFixedPosition(ps, componentPointer) &&
        !isScopeChange(ps, componentPointer, newContainerPointer)
    ) {
        const fixedPositionPointer = ps.pointers.getInnerPointer(componentPointer, 'layout.fixedPosition')
        ps.dal.set(fixedPositionPointer, false)
    }
}

function getBehaviorsToRemove(ps: PS, componentPointer) {
    const compBehaviors = actionsAndBehaviors.getComponentBehaviors(ps, componentPointer)
    const actionsToRemove = ['modeChange', 'modeIn', 'modeOut']
    return _.filter(compBehaviors, behavior => _.includes(actionsToRemove, behavior.action))
}

function resetModes(ps: PS, containerPointer, componentPointer) {
    const containerModes = componentModes.getComponentModes(ps, containerPointer)
    if (!containerModes || containerModes.length === 0) {
        const behaviorsToRemove = getBehaviorsToRemove(ps, componentPointer)
        _.forEach(behaviorsToRemove, function (behavior) {
            actionsAndBehaviors.removeComponentSingleBehavior(ps, componentPointer, behavior.name, behavior.action)
        })
    }

    const compPage = ps.pointers.full.components.getPageOfComponent(componentPointer)
    const containerPage = ps.pointers.full.components.getPageOfComponent(containerPointer)
    if (!_.isEqual(compPage, containerPage)) {
        deactivateAllComponentAndChildrenModes(ps, componentPointer)
    }
}

function deactivateAllComponentAndChildrenModes(ps: PS, componentPointer) {
    let componentsToCheck = [componentPointer]
    while (!_.isEmpty(componentsToCheck)) {
        const compPointer = componentsToCheck.pop()
        deactivateAllCompModes(ps, compPointer)
        const compChildren = ps.pointers.full.components.getChildren(compPointer)
        componentsToCheck = componentsToCheck.concat(compChildren)
    }
}

function deactivateAllCompModes(ps: PS, compPointer) {
    const compModes = componentModes.getComponentModes(ps, compPointer)
    _.forEach(compModes, function (mode) {
        ps.siteAPI.deactivateMode(compPointer, mode.modeId)
    })
}

function setContainer(
    ps: PS,
    pointerToReturn,
    componentPointer,
    newContainerPointer,
    variantsReplacementOperations = []
) {
    validateSetContainer(ps, componentPointer, newContainerPointer)
    const actualNewContainerPointer = componentStructureInfo.getContainerToAddComponentTo(ps, newContainerPointer)
    adjustCompLayoutToNewContainer(ps, componentPointer, actualNewContainerPointer)
    updateFixedPositionForReparent(ps, componentPointer, actualNewContainerPointer)
    const oldParentPointer = ps.pointers.components.getParent(componentPointer)

    const compType = componentStructureInfo.getType(ps, componentPointer)
    hooks.executeHook(hooks.HOOKS.CHANGE_PARENT.BEFORE, compType, [ps, componentPointer, newContainerPointer])

    resetModes(ps, newContainerPointer, componentPointer)
    variantsUtils.removeVariantsOverridesIfNeeded(ps, componentPointer, {
        newParentPointer: newContainerPointer,
        variantsReplacementOperations
    })
    hooks.executeHook(hooks.HOOKS.VARIANTS.CHANGE_PARENT_AFTER, compType, [ps, componentPointer, newContainerPointer])

    addCompToContainer(ps, componentPointer, actualNewContainerPointer, undefined, pointerToReturn)
    hooks.executeHook(hooks.HOOKS.CHANGE_PARENT.AFTER, compType, [
        ps,
        componentPointer,
        newContainerPointer,
        oldParentPointer
    ])

    return pointerToReturn
}

const updateComponentRelations = (ps: PS, componentPointer, variantsReplacementOperations = []) => {
    variantsUtils.removeVariantsOverridesIfNeeded(ps, componentPointer, {variantsReplacementOperations})
}

const getSetContainerInteractionParams = (ps: PS, pointerToReturn, componentPointer, newContainerPointer) => {
    return {component: componentPointer, newContainer: newContainerPointer}
}

function isShowOnSomePages(ps: PS, compPointer) {
    return Boolean(sospModes.getShowOnSomePagesRoot(ps, compPointer))
}

/**
 * Checks if component is set to show on all pages (=in master page)
 * @member documentServices.components
 * @param {ps} ps
 * @param {Pointer} compPointer
 * @returns {boolean} true iff component is shown on all pages
 */
function isShowOnAllPages(ps: PS, compPointer) {
    return ps.pointers.components.isInMasterPage(compPointer) && !isShowOnSomePages(ps, compPointer)
}

const getSetContainerValidationMessage = (ps: PS, compPointer, newContainerPointer) => {
    if (!ps.dal.isExist(compPointer) || !ps.dal.isExist(newContainerPointer)) {
        return 'invalid component ref or new parent container ref'
    }

    if (!componentsMetaData.public.isContainable(ps, compPointer, newContainerPointer)) {
        return `component isn't containable in container: ${newContainerPointer.id}`
    }

    if (!canContainMoreChildren(ps, newContainerPointer)) {
        return 'maximum number of child components reached'
    }

    return ''
}

const canSetContainer = (ps: PS, compPointer, newContainerPointer) =>
    _.isEmpty(getSetContainerValidationMessage(ps, compPointer, newContainerPointer))

function validateSetContainer(ps: PS, compPointer, newContainerPointer) {
    const setContainerValidationMessage = getSetContainerValidationMessage(ps, compPointer, newContainerPointer)

    if (!_.isEmpty(setContainerValidationMessage)) {
        throw new Error(setContainerValidationMessage)
    }

    return true
}

function canContainMoreChildren(ps: PS, containerPointer) {
    return componentsMetaData.public.allowedToContainMoreChildren(ps, containerPointer)
}

function getCoordsWhenReparentingToGrandFather(ps: PS, layoutPointer, oldParent) {
    const layout = ps.dal.get(layoutPointer)
    const parentLayout = ps.dal.get(ps.pointers.getInnerPointer(oldParent, 'layout'))
    return {
        x: layout.x + parentLayout.x,
        y: layout.y + parentLayout.y
    }
}

function getCoordsWhenReparenting(ps: PS, compPointer, newParentPointer) {
    const compRelativeToScreenLayout = layoutUtils.getCompLayoutRelativeToScreenConsideringScroll(ps, compPointer, true)
    const parentRelativeToScreenLayout = layoutUtils.getCompLayoutRelativeToScreenConsideringScroll(
        ps,
        newParentPointer,
        true
    )

    return {
        x: compRelativeToScreenLayout.x - parentRelativeToScreenLayout.x,
        y: compRelativeToScreenLayout.y - parentRelativeToScreenLayout.y
    }
}

function adjustCompLayoutToNewContainer(ps: PS, compPointer, newParentPointer) {
    if (
        shouldPreserveCompLayoutOnReparent(ps) ||
        (layoutUtils.isFixedPosition(ps, compPointer) && isScopeChange(ps, compPointer, newParentPointer))
    ) {
        return
    }

    const isCompHorizontallyStretched = isHorizontallyStretchedToScreen(ps, compPointer)
    if (!isCompHorizontallyStretched) {
        unDock(ps, compPointer)
    }

    const oldParent = ps.pointers.components.getParent(compPointer)
    const oldGrandfather = ps.pointers.components.getParent(oldParent)

    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')

    const adjustedCoords = ps.pointers.components.isSameComponent(oldGrandfather, newParentPointer)
        ? getCoordsWhenReparentingToGrandFather(ps, layoutPointer, oldParent)
        : getCoordsWhenReparenting(ps, compPointer, newParentPointer)

    if (isCompHorizontallyStretched) {
        delete adjustedCoords.x
    }

    ps.dal.merge(layoutPointer, adjustedCoords)
}

function removeDisplayedOnlyDataItems(ps: PS, compPointer) {
    const compAndChildren = ps.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(compPointer)
    const allDisplayedComps = _(compAndChildren)
        .map(ps.pointers.components.getAllDisplayedOnlyComponents)
        .flattenDeep()
        .value()

    // remove with removeAllOverrides()
    _.forEach(allDisplayedComps, function (displayedCompPointer) {
        dataModel.deleteDataItem(ps, displayedCompPointer)
        dataModel.deleteDesignItem(ps, displayedCompPointer)
    })
}

function updateOriginalDataItem(ps: PS, compPointer, displayedOnlyCompStructure, pagePointer) {
    if (displayedOnlyCompStructure.dataQuery) {
        const displayedOnlyCompData = dataModel.getDataItem(ps, compPointer, true)
        const originalDataQuery = dsUtils.stripHashIfExists(getRepeaterTemplateId(displayedOnlyCompStructure.dataQuery))
        const dataPointer = ps.pointers.data.getDataItem(originalDataQuery, pagePointer.id)
        dataModel.removeItemRecursivelyByType(ps, dataPointer)
        dataModel.addSerializedDataItemToPage(ps, pagePointer.id, displayedOnlyCompData, originalDataQuery)
    }

    if (displayedOnlyCompStructure.designQuery) {
        const displayedOnlyCompDesign = design.getDesignItem(ps, compPointer, true)
        const originalDesignQuery = dsUtils.stripHashIfExists(
            getRepeaterTemplateId(displayedOnlyCompStructure.designQuery)
        )
        const designPointer = ps.pointers.data.getDesignItem(originalDesignQuery, pagePointer.id)
        dataModel.removeItemRecursivelyByType(ps, designPointer)
        dataModel.addSerializedDesignItemToPage(ps, pagePointer.id, displayedOnlyCompDesign, originalDesignQuery)
    }
}

function addCompToContainer(
    ps: PS,
    compPointer: Pointer,
    newParentPointer: Pointer,
    optionalIndex?: number,
    optionalNewCompPointer?
) {
    const newCompPointer = optionalNewCompPointer || compPointer
    const oldPagePointer = ps.pointers.components.getPageOfComponent(compPointer)
    const newPagePointer = ps.pointers.components.getPageOfComponent(newParentPointer)

    if (oldPagePointer.id !== newPagePointer.id) {
        moveDataAndPropertiesToNewPage(ps, compPointer, newPagePointer, oldPagePointer)
    }

    const displayedChildrenPointers = ps.pointers.components.getChildrenRecursively(compPointer)
    const fullCompStructure = ps.dal.full.get(compPointer)
    let displayedCompStructure = ps.dal.get(compPointer)
    const reparentingOutsideOfDisplayedOnlyContainer = isReparentingOutsideOfDisplayedOnlyContainer(
        compPointer,
        newParentPointer
    )
    const reparentingIntoDisplayedOnlyContainer = isReparentingIntoDisplayedOnlyContainer(compPointer, newParentPointer)
    const reparantingFromDisplayedOnlyContainerToDisplayedOnlyContainer = isReparentingToOtherRepeater(
        ps,
        compPointer,
        newParentPointer
    )

    if (reparantingFromDisplayedOnlyContainerToDisplayedOnlyContainer) {
        updateOriginalDataItem(ps, compPointer, displayedCompStructure, newPagePointer)
        removeDisplayedOnlyDataItems(ps, compPointer)
        repeaterUtils.addDisplayedDataItemsForAllChildren(
            ps,
            compPointer,
            newParentPointer,
            newPagePointer.id,
            displayedChildrenPointers
        )
    } else if (reparentingOutsideOfDisplayedOnlyContainer) {
        updateOriginalDataItem(ps, compPointer, displayedCompStructure, newPagePointer)
        removeDisplayedOnlyDataItems(ps, compPointer)
    } else if (reparentingIntoDisplayedOnlyContainer) {
        repeaterUtils.addDisplayedDataItemsForAllChildren(
            ps,
            compPointer,
            newParentPointer,
            newPagePointer.id,
            displayedChildrenPointers
        )
    }

    const newCompIndex = arrangement.getNewIndex(ps, compPointer, newParentPointer, optionalIndex)
    ps.dal.full.remove(compPointer)
    if (
        reparentingOutsideOfDisplayedOnlyContainer ||
        reparentingIntoDisplayedOnlyContainer ||
        reparantingFromDisplayedOnlyContainerToDisplayedOnlyContainer
    ) {
        mobileUtil.removeComponentByDesktopComp(ps, compPointer, true)
    }

    const childrenPointer = ps.pointers.full.components.getChildrenContainer(newParentPointer)
    const containerPointer = ps.pointers.getOriginalPointerFromInner(childrenPointer)
    const newFullId = getRepeaterTemplateId(newCompPointer.id)
    //fix for repeates in bolt - DM-1358, setContainer fixes the layout of the template so we need to get the updatedLayout from the full
    displayedCompStructure = getOriginalStructure(fullCompStructure)

    const structureToPush = modesUtils.adjustModesToNewContainer(
        ps.pointers,
        ps.dal.fullJsonDal,
        ps.dal.displayedJsonDal,
        containerPointer,
        _.assign(
            fullCompStructure,
            _.omit(displayedCompStructure, ['components', 'modes', 'parent', 'id', 'componentType', 'skin']),
            {id: newFullId}
        )
    )

    ps.dal.full.push(childrenPointer, structureToPush, newCompPointer, newCompIndex)
}

const moveVariantsToNewPage = (ps: PS, compPointer: Pointer, newPagePointer) => {
    const variantPointers = dataModel.getComponentVariantsData(ps, {compPointer})
    _.forEach(variantPointers, variantPointer => {
        const itemPointerWithoutRefFallbacks = ps.pointers.referredStructure.getPointerWithoutFallbacks(variantPointer)
        moveDataItemToAnotherPage(ps, itemPointerWithoutRefFallbacks, newPagePointer.id, DATA_TYPES.variants)
    })
}

function moveDataAndPropertiesToNewPage(ps: PS, compPointer: Pointer, newPagePointer, oldPagePointer) {
    const DATA_QUERY_KEYS = ps.runtimeConfig.stylesPerPage ? COMP_DATA_QUERY_KEYS_WITH_STYLE : COMP_DATA_QUERY_KEYS

    function moveDataItemToNewPage(structurePointer, dataType) {
        if (dataType === DATA_TYPES.variants) {
            moveVariantsToNewPage(ps, compPointer, newPagePointer)
        }

        const dataQueryKey = DATA_QUERY_KEYS[dataType]

        const queryPointer = ps.pointers.getInnerPointer(structurePointer, dataQueryKey)
        if (dataQueryKey && ps.dal.full.isExist(queryPointer)) {
            const getter = isDisplayedOnlyComponent(queryPointer.id) ? ps.dal.get : ps.dal.full.get
            const itemId = dsUtils.stripHashIfExists(getter(queryPointer))

            const itemPointer = ps.pointers.data.getItem(dataType, itemId, oldPagePointer.id)
            const itemPointerWithoutRefFallbacks = ps.pointers.referredStructure.getPointerWithoutFallbacks(itemPointer)

            itemPointerWithoutRefFallbacks.useLanguage = _.get(
                ps.dal.get(ps.pointers.multilingual.originalLanguage()),
                'languageCode'
            )

            moveDataItemToAnotherPage(ps, itemPointerWithoutRefFallbacks, newPagePointer.id, dataType)
        }
    }

    _.forEach(DATA_TYPES, function (dataItemType) {
        moveDataItemToNewPage(compPointer, dataItemType)
    })

    const translationsPointer = ps.pointers.page.getPageTranslations(oldPagePointer.id)
    const translationData = ps.dal.full.get(translationsPointer)
    const originalDataQueryPointer = ps.pointers.getInnerPointer(compPointer, 'dataQuery')
    if (ps.dal.full.isExist(originalDataQueryPointer)) {
        const componentDataId = dsUtils.stripHashIfExists(ps.dal.full.get(originalDataQueryPointer))

        _.forEach(translationData, (translation, languageCode) => {
            const translationDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(
                oldPagePointer.id,
                languageCode,
                componentDataId
            )
            if (ps.dal.full.isExist(translationDataItemPointer)) {
                const translationDataItem = ps.dal.full.get(translationDataItemPointer)
                const newPageDataItemPointer = ps.pointers.multilingualTranslations.translationDataItem(
                    newPagePointer.id,
                    languageCode,
                    componentDataId
                )
                ps.dal.fullJsonDal.remove(translationDataItemPointer)
                ps.dal.fullJsonDal.set(newPageDataItemPointer, translationDataItem)
            }
        })
    }

    function moveOverridesDataItemsToNewPage() {
        const componentOverridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
        if (componentOverridesPointer && ps.dal.full.isExist(componentOverridesPointer)) {
            const componentOverrides = ps.dal.full.get(componentOverridesPointer)
            _.forEach(componentOverrides, function (override, index) {
                const overridePointer = ps.pointers.getInnerPointer(componentOverridesPointer, index)
                moveDataItemToNewPage(overridePointer, DATA_TYPES.design)
                moveDataItemToNewPage(overridePointer, DATA_TYPES.prop)
            })
        }
    }

    moveOverridesDataItemsToNewPage()

    const fullChildrenPointers = ps.pointers.full.components.getChildren(compPointer)
    const displayedChildrenPointers = ps.pointers.components.getChildren(compPointer)
    const fullAndDisplayedOnlyChildrenPointers = _.unionBy(
        fullChildrenPointers,
        displayedChildrenPointers,
        (pointer: any) => pointer.id
    )
    _.forEach(fullAndDisplayedOnlyChildrenPointers, function (containedCompPointer) {
        moveDataAndPropertiesToNewPage(ps, containedCompPointer, newPagePointer, oldPagePointer)
    })
}

function moveDataItemToAnotherPage(ps: PS, dataPointer, newPageId, dataType) {
    const dataItem = dataPointer && ps.dal.full.get(dataPointer)

    //TODO: what will happen if cannot move data..?
    if (dataItem && structureUtils.canMoveDataItemToAnotherPage(dataItem, dataType)) {
        //removing the data item itself should be after traversing the children, otherwise we want find the children'
        const oldPageId = ps.pointers.data.getPageIdOfData(dataPointer)
        dataModel.executeForDataItemRefs(dataType, dataItem, function (dataId) {
            if (!_.isString(dataId)) {
                return
            }
            const childDataPointer = ps.pointers.data.getItem(dataType, dsUtils.stripHashIfExists(dataId), oldPageId)
            moveDataItemToAnotherPage(ps, childDataPointer, newPageId, dataType)
        })

        ps.dal.full.remove(dataPointer)
        const newDataPointer = ps.pointers.data.getItem(dataType, dataPointer.id, newPageId)
        ps.dal.full.set(newDataPointer, dataItem)
    }
}

function isAncestorFixedPosition(ps: PS, compPointer) {
    let parentCompPointer = compPointer
    do {
        parentCompPointer = ps.pointers.components.getParent(parentCompPointer)
        if (!parentCompPointer) {
            return false
        }
    } while (!layoutUtils.isFixedPosition(ps, parentCompPointer))
    return true
}

function isScrollingEnabled(ps: PS) {
    return !ps.siteAPI.isSiteScrollingBlocked()
}

function isDocked(ps: PS, compPointer) {
    return !!getDock(ps, compPointer)
}

function isDockedToScreen(ps: PS, compPointer) {
    return coreLayoutUtils.isDockToScreen(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
}

function isRotated(ps: PS, compPointer) {
    const compRotationPointer = ps.pointers.getInnerPointer(compPointer, ['layout', 'rotationInDegrees'])
    return ps.dal.get(compRotationPointer) !== 0
}

function isHorizontallyStretchedToScreen(ps: PS, compPointer) {
    return dockUtils.isHorizontalDockToScreen(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
}

function isHorizontallyStretchedToScreenByStructure(ps: PS, compStructure) {
    return dockUtils.isHorizontalDockToScreen(compStructure.layout)
}

function getEffectiveTextDimensions(ps: PS, compPointer) {
    const textRuntimeOverallBorders = ps.pointers.general.getTextRuntimeOverallBorders()
    const textRuntimeOverallBordersPointer = ps.pointers.getInnerPointer(textRuntimeOverallBorders, compPointer.id)
    if (textRuntimeOverallBordersPointer) {
        return ps.dal.get(textRuntimeOverallBordersPointer)
    }
    return undefined
}

function getDeprecatedMinDimensions() {
    return {width: 0, height: 0}
}

function getSiteWidth(ps: PS) {
    return ps.siteAPI.getSiteWidth()
}

function getSiteHeight(ps: PS) {
    const measureMap = ps.siteAPI.getSiteMeasureMap()

    if (measureMap) {
        const currentPopupId = ps.siteAPI.getCurrentPopupId()

        if (currentPopupId) {
            return measureMap.height[currentPopupId]
        }

        const renderFlagPointer = ps.pointers.general.getRenderFlag('extraSiteHeight')

        return measureMap.height[siteConstants.MASTER_PAGE_ID] + (ps.dal.get(renderFlagPointer) || 0)
    }

    return 0
}

function getDock(ps: PS, compPointer) {
    const dockedLayoutPointer = ps.pointers.getInnerPointer(compPointer, ['layout', 'docked'])
    return ps.dal.get(dockedLayoutPointer)
}

function getDockedStylePublic(ps: PS, dockedLayout) {
    return dockUtils.applyDockedStyleWithMargins(
        dockedLayout,
        {
            width: dockedLayout.width,
            height: dockedLayout.height
        },
        ps.siteAPI.getPageMargins(),
        ps.siteAPI.getScreenWidth(),
        ps.siteAPI.getSiteWidth(),
        ps.siteAPI.getSiteX()
    )
}

function unDockAndUpdateAnchors(ps: PS, compPointer) {
    unDock(ps, compPointer)
}

function unDock(ps: PS, compPointer) {
    if (!isDocked(ps, compPointer)) {
        return
    }

    const positionAndSize = componentLayout.getPositionAndSize(ps, compPointer)
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const layout = ps.dal.get(layoutPointer)

    _.assign(layout, positionAndSize)
    delete layout.docked

    ps.dal.set(layoutPointer, layout)
}

function updateDock(ps: PS, compPointer, dockData) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const previousLayout = ps.dal.get(layoutPointer)
    const layout = layoutValidation.getValidLayoutToUpdate(ps, compPointer, {docked: dockData})
    const compType = componentStructureInfo.getType(ps, compPointer)

    hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_BEFORE, compType, [
        ps,
        compPointer,
        layout,
        updateCompLayout,
        false,
        previousLayout
    ])
    ps.dal.set(layoutPointer, layout)

    if (isAspectRatioOn(ps, compPointer)) {
        updateAspectRatio(ps, compPointer)
    }

    hooks.executeHook(hooks.HOOKS.LAYOUT.UPDATE_AFTER, compType, [
        ps,
        compPointer,
        layout,
        updateCompLayout,
        false,
        previousLayout
    ])
}

function setDock(ps: PS, compPointer, dockData) {
    unDock(ps, compPointer)
    updateDock(ps, compPointer, dockData)
}

/**
 * @param {ps} ps
 * @param compPointer
 * @returns {{component: *, children: *, proportions: *}}
 */
function getRatioStructure(ps: PS, compPointer) {
    const compChildren = ps.pointers.components.getChildren(compPointer)
    const compContainer = ps.pointers.components.getParent(compPointer)
    const containerLayout = structureUtils.getComponentLayout(ps, compContainer)
    const compLayout = structureUtils.getComponentLayout(ps, compPointer)
    const layoutProportions = getChildParentLayoutRatio(compLayout, containerLayout)

    return {
        component: compPointer,
        proportions: layoutProportions,
        children: _.map(compChildren, childPointer => getRatioStructure(ps, childPointer))
    }
}

function getProportionStructure(ps: PS, compPointer, resizeDirection?): ProportionStructure {
    const proportionStructure = getRatioStructure(ps, compPointer)
    layoutConstraintsUtils.addCompMinLayout(ps, proportionStructure, resizeDirection)
    return proportionStructure
}

function initialize(ps: PS) {
    layoutPlugins.initialize(ps)

    const gapMap = siteGapMap.createInitialGapMap(ps)
    const siteHasGaps = _.some(gapMap, gapVal => gapVal > 0)

    if (siteHasGaps) {
        ps.siteAPI.reportBI(biEvents.SITE_GAPS_MAP, {
            above_header: gapMap.aboveHeader,
            above_page: gapMap.abovePagesContainer,
            above_footer: gapMap.aboveFooter
        })
    }
}

function isHorizontallyStretched(ps: PS, compPointer: Pointer) {
    return dockUtils.isHorizontallyStretched(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
}

function isVerticallyStretched(ps: PS, compPointer: Pointer) {
    return dockUtils.isVerticallyStretched(ps.dal.get(ps.pointers.getInnerPointer(compPointer, ['layout'])))
}

function isSiteLayoutMesh(ps: PS) {
    return getLayoutSettings(ps).mechanism === coreConst.LAYOUT_MECHANISMS.MESH
}

function migrateToMeshLayout(ps: PS) {
    const layoutSettingsPointer = ps.pointers.getInnerPointer(
        ps.pointers.data.getDataItemFromMaster('masterPage'),
        'layoutSettings'
    )
    const layoutSettings = ps.dal.get(layoutSettingsPointer)

    ps.dal.set(
        layoutSettingsPointer,
        _.assign({}, layoutSettings, {
            mechanism: coreConst.LAYOUT_MECHANISMS.MESH
        })
    )
}

function setScroll(ps: PS, x: number, y: number) {
    return ps.siteAPI.setScroll(x, y)
}

function getScroll(ps: PS, ignorePopups?: boolean) {
    return ps.siteAPI.getScroll(ignorePopups)
}

function setScrollAndScale(
    ps: PS,
    x: number,
    y: number,
    scale: number,
    duration: number,
    originLeft: number,
    callback: Function
) {
    const renderFlagPointer = ps.pointers.general.getRenderFlag('siteScale')
    ps.dal.set(renderFlagPointer, scale)
    return ps.siteAPI.setScrollAndScale(x, y, scale, duration, originLeft, callback)
}

function animateScroll(ps: PS, x: number, y: number, duration: number, callback) {
    return ps.siteAPI.animateScroll(x, y, duration, callback)
}

export default {
    initialize,
    getDockedStyle: getDockedStylePublic,

    /**
     * Removes docked property from component layout
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     */
    unDock: unDockAndUpdateAnchors,

    /**
     * Updates docked value in layout
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @param {Object} a the docked data to be updated.
     */
    updateDock,

    /**
     * Sets (overriding) docked value in layout
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @param {Object} a the docked data to be set.
     */
    setDock,

    /**
     * Gets docked value for component
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @returns {Object} the docked data.
     *      @example
     *      documentServices.components.getDock(componentPointer)
     */
    getDock,

    /**
     * Returns true if the component is docked left and right in unit vw
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @returns {Boolean} component is stretched to screen.
     *      @example
     *      documentServices.components.getDock(componentPointer)
     */
    isHorizontallyStretchedToScreen,

    /**
     * Returns true if the component is docked left and right in unit vw
     *
     * @member documentServices.components.layout
     * @param {Object} compStructure The component's structure.
     * @returns {Boolean} component is stretched to screen.
     *      @example
     *      documentServices.components.getDock(componentStructure)
     */
    isHorizontallyStretchedToScreenByStructure,

    getPositionAndSize: structureUtils.getPositionAndSize,

    updateCompLayout,

    getProportionStructure,

    updateCompLayoutAndAdjustLayout,

    updateAndPreserveProportions: updateCompLayoutAndPreserveProportions,

    canSetContainer,

    /**
     * Set the component container to be the one referenced by newContainerRef
     * @member documentServices.components
     * @param {AbstractComponent} compPointer
     * @param {AbstractComponent} newContainerRef
     */
    setContainer,
    updateComponentRelations,
    getSetContainerInteractionParams,

    /**
     * @ignore
     */
    getNewComponentPointerAfterReparent,

    /**
     * Check if a component is shown on all pages
     *
     * @function
     * @memberof documentServices.structure.structure
     * @param {AbstractComponent} compPointer pointer to the component to check.
     * @returns {boolean}
     *      @example
     *      documentServices.components.isShowOnAllPages(componentPointer)
     *
     */
    isShowOnAllPages,

    isShowOnSomePages,

    /**
     * Calculates the coordinates of a component in the viewer relative to the masterPage
     * This is the site, not the window.
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @returns {Object} a coordinates object of the corresponding Component, with x and y properties.
     *      @example
     *      const compAbsPosition = documentServices.components.layout.getCompLayoutRelativeToStructure(componentPointer)
     *
     */
    getCompLayoutRelativeToStructure: layoutUtils.getCompLayoutRelativeToStructure,

    /**
     * Calculates the coordinates of a component in the viewer relative to the masterPage
     * This is the site, not the window.
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @returns {Object} a Layout object of the corresponding Component.
     *      @example
     *      const compLayoutRelativeToScreen = documentServices.components.layout.getCompLayoutRelativeToScreen(componentPointer)
     *
     */
    getCompLayoutRelativeToScreen: layoutUtils.getCompLayoutRelativeToScreen,

    getCompLayoutRelativeToScreenConsideringScroll: layoutUtils.getCompLayoutRelativeToScreenConsideringScroll,

    addCompToContainer,

    /**
     * Determines if a component has fixed position or not.
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @returns {boolean} true iff the component has fixed position
     *      @example
     *      const compAbsPosition = documentServices.components.layout.isFixedPosition(componentPointer)
     *
     */
    isFixedPosition: layoutUtils.isFixedPosition,

    isAncestorFixedPosition,

    /**
     * Determines if a component is currently rendered in fix position
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compLayout pointer to the component.
     * @returns {boolean} true iff the component is currently rendered in fix position
     *      @example
     *      const compAbsPosition = documentServices.components.layout.isRenderedInFixedPosition(componentPointer)
     *
     */
    isRenderedInFixedPosition: layoutUtils.isRenderedInFixedPosition,

    isAncestorRenderedInFixedPosition: layoutUtils.isAncestorRenderedInFixedPosition,

    getClosestAncestorRenderedInFixedPosition: layoutUtils.getClosestAncestorRenderedInFixedPosition,

    isShowOnFixedPosition: layoutUtils.isShowOnFixedPosition,

    isDocked,

    isDockedToScreen,

    isRotated,

    /**
     * Returns the minimum width / height of a component
     *
     * @member documentServices.components.layout
     * @param {AbstractComponent} compPointer Pointer to the component.
     * @returns {{width: number, height: number}} an object that represents the minimum dimensions for the given component
     *      @example
     *      var minDimensions = documentServices.components.layout.getMinDimensions(componentPointer)
     * @deprecated
     */
    getDeprecatedMinDimensions,
    getSiteHeight,
    getSiteWidth,
    setScroll,
    isScrollingEnabled,
    getScroll,
    setScrollAndScale,
    animateScroll,
    updateFixedPosition,
    reparentComponentToPage,
    getEffectiveTextDimensions,
    updateAspectRatio,
    removeAspectRatio,
    getSiteX: layoutUtils.getSiteX,
    isAspectRatioOn,
    adjustCompLayoutToNewContainer,
    isHorizontallyStretched,
    isVerticallyStretched,
    isSimpleLayout,
    isSiteLayoutMesh,
    migrateToMeshLayout,
    syncTemplateLayout
}
