import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import 'experiment'

const isPageExist = (ps: PS, pageId: string) =>
    ps.dal.full.isExist(
        // @ts-expect-error BUG
        ps.pointers.components.getPage(pageId, 'DESKTOP') ||
            ps.dal.full.isExist(ps.pointers.components.getPage(pageId, 'MOBILE'))
    )
export default {
    exec(ps: PS) {
        const allPageDataItemsInMasterPage = ps.pointers.data.getDataItemsWithPredicate({type: 'Page'}, 'masterPage')
        const corruptedPagesDataItems = allPageDataItemsInMasterPage.filter(
            pageDataPointer => !isPageExist(ps, _.get(pageDataPointer, 'id'))
        )
        _.forEach(corruptedPagesDataItems, badPageDataPointer => ps.dal.full.remove(badPageDataPointer))
    },
    name: 'badPagesDataItemsDataFixer',
    version: 1
}
