import _ from 'lodash'
import {ajaxLibrary} from '@wix/santa-ds-libs/src/warmupUtils'
import platformInit from '@wix/santa-ds-libs/src/platformInit'
import onRoutingResponse from './onRoutingResponse'
import experiment from 'experiment'
import {cookieUtils, logWixCodeConsoleMessage} from '@wix/santa-core-utils'

const MEMBERS_APP_DEFINITION_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'

const REQUEST_TYPES = {
    PAGES: 'PAGES',
    SITE_MAP: 'SITE_MAP',
    SITE_MAP_COUNT: 'SITE_MAP_COUNT'
}

const REQUEST_ROUTES = {
    PAGES: '/pages',
    SITE_MAP: '/sitemap',
    SITE_MAP_COUNT: '/sitemapCount'
}

const getRoutersPathConfig = instanceId => ({
    editor: {
        'wix-code': `https://${instanceId}.dev.wix-code.com/routers/custom`,
        dataBinding: '/_api/dynamic-pages-router/v1'
    },
    viewer: {
        'wix-code': '/_api/wix-code-public-dispatcher/routers/custom',
        dataBinding: '/_api/dynamic-pages-router/v1'
    }
})

function getWixCodeRouterPath({appDefinitionId, instanceId, isEditor, siteData}) {
    const resolvedRoutersPaths = getRoutersPathConfig(instanceId)
    const routersPathsByViewMode = isEditor ? resolvedRoutersPaths.editor : resolvedRoutersPaths.viewer
    const routerPath = routersPathsByViewMode[appDefinitionId]

    if (!isEditor) {
        const baseUrl = _.trimEnd(siteData.getExternalBaseUrl(), '/')
        return `${baseUrl}${routerPath}`
    }

    return routerPath
}

function getSantaMembersRouterPath(currentUrl, isEditor, routerPath, isWixSite, isPremiumDomain) {
    if (!isEditor) {
        //request to members must contain the site name when in a user site
        let siteName
        if (isWixSite) {
            //wix site url different - the first to slugs are the site name
            // www.wix.com/app-builder/home/...  ==> siteName=app-builder/home
            siteName = currentUrl.path.replace(/.*?\/([^\/]*\/[^\/]*).*/, '$1')
        } else if (isPremiumDomain) {
            return routerPath
        } else {
            //freemium site
            siteName = currentUrl.path.replace(/.*?\/([^\/]*).*/, '$1')
        }

        return `/${siteName}${routerPath}`
    }

    return routerPath
}

function getRouterPath(currentUrl, clientSpecMap, appDefinitionId, isEditor, isWixSite, isPremiumDomain) {
    const clientSpecMapForApplication = _.find(clientSpecMap, {appDefinitionId})
    const routerPath = clientSpecMapForApplication.appFields.platform.routerServiceUrl

    if (appDefinitionId === MEMBERS_APP_DEFINITION_ID) {
        return getSantaMembersRouterPath(currentUrl, isEditor, routerPath, isWixSite, isPremiumDomain)
    }

    return routerPath
}

function getBackendRequestUrl(routerPath, currentUrl, requestType) {
    const domainForRouter = currentUrl.host

    const requestRoute = REQUEST_ROUTES[requestType]

    if (routerPath.startsWith('/')) {
        return `${currentUrl.protocol}//${domainForRouter}${routerPath}${requestRoute}`
    }
    return `${routerPath}${requestRoute}`
}

function getUrlParams(clientSpecMap, appDefinitionId, isEditor) {
    const viewMode = `viewMode=${isEditor ? 'editor' : 'site'}`
    const params = [viewMode]

    const appSpec = platformInit.specMapUtils.getAppSpec(clientSpecMap, appDefinitionId)
    if (appSpec) {
        params.push(`instance=${appSpec.instance}`)
    }

    return `?${params.join('&')}`
}

function getBackendRequestObject(pageRoles, suffix, routerDefinition, publicBaseUrl, formFactor, queryParams) {
    const routerConfig = _.isString(routerDefinition.config)
        ? JSON.parse(routerDefinition.config)
        : routerDefinition.config
    const routerSuffix = `/${suffix}`.replace(/^\/\//, '/')
    let fullUrl = `${publicBaseUrl}/${routerDefinition.prefix}${routerSuffix}`
    if (queryParams) {
        fullUrl = `${fullUrl}?${queryParams}`
    }
    //todo - temporarly send the /<prefix>/<suffix> in the fullUrl parameter, just until the router backend will be fixed to use the right parameters
    const result = {
        fullUrl,
        routerPrefix: `/${routerDefinition.prefix}`,
        routerSuffix,
        requestInfo: {
            formFactor
        },
        config: routerConfig,
        pageRoles
    }

    return result
}

function getCSRFTokenValue() {
    return cookieUtils.getCookie('XSRF-TOKEN')
}

function getWixCodeUrlParams(instance, gridAppId, isEditor, appDefinitionId) {
    const viewMode = `viewMode=${isEditor ? 'editor' : 'site'}`
    const _instance = `instance=${instance}`
    const _gridAppId = `gridAppId=${gridAppId}`

    const params = appDefinitionId === 'dataBinding' ? [viewMode, _gridAppId] : [viewMode, _instance, _gridAppId]

    return `?${params.join('&')}`
}

function getWixCodeAjaxParameters({paramObj, requestType, siteData}) {
    const {
        currentUrl,
        clientSpecMap,
        isEditor,
        routerDefinition: {appDefinitionId},
        rendererModel_wixCodeModel_appData_codeAppId
    } = paramObj

    // @ts-ignore
    const {instanceId, instance} = platformInit.specMapUtils.getAppSpec(clientSpecMap)
    const routerPath = getWixCodeRouterPath({appDefinitionId, instanceId, isEditor, siteData})
    const urlParams = getWixCodeUrlParams(
        instance,
        rendererModel_wixCodeModel_appData_codeAppId,
        isEditor,
        appDefinitionId
    )
    const url = getBackendRequestUrl(routerPath, currentUrl, requestType) + urlParams

    const successCallback = routerResponse => {
        if (_.get(routerResponse, ['result', '_elementoryError'])) {
            logWixCodeConsoleMessage(`Internal server error:${routerResponse.result.stack}`)
        }
    }

    const extraAjaxParams = {
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true
    }

    if (appDefinitionId === 'dataBinding') {
        _.assign(extraAjaxParams, {
            headers: {Authorization: experiment.isOpen('dm_ajaxAuthPhase2') ? undefined : instance},
            appIdAutoAuth: experiment.isOpen('dm_ajaxAuthPhase2') ? appDefinitionId : undefined
        })
    }

    return {
        url,
        successCallback,
        extraAjaxParams
    }
}

function getAjaxParametersForRouterRequest({paramObj, requestType, siteData}) {
    const {appDefinitionId} = paramObj.routerDefinition

    if (/(wix-code|dataBinding)/.test(appDefinitionId)) {
        return getWixCodeAjaxParameters({paramObj, requestType, siteData})
    }

    const {currentUrl, clientSpecMap, isEditor, isWixSite, isPremiumDomain} = paramObj

    const routerPath = getRouterPath(currentUrl, clientSpecMap, appDefinitionId, isEditor, isWixSite, isPremiumDomain)
    const urlParams = getUrlParams(clientSpecMap, appDefinitionId, isEditor)
    const url = getBackendRequestUrl(routerPath, currentUrl, requestType) + urlParams

    return {
        url
    }
}

function getFromBackEnd(paramObj, requestType, onSuccess, onError, siteData) {
    // @ts-ignore
    const {url, successCallback, extraAjaxParams} = getAjaxParametersForRouterRequest({paramObj, requestType, siteData})

    const ajaxParams: Record<string, any> = {
        type: 'POST',
        dataType: 'json',
        url,
        data: getBackendRequestObject(
            paramObj.pageRoles,
            paramObj.suffix,
            paramObj.routerDefinition,
            paramObj.publicBaseUrl,
            paramObj.formFactor,
            paramObj.queryParams
        ),
        success(routerResponse) {
            if (successCallback) {
                successCallback(routerResponse)
            }

            onSuccess(routerResponse)
        },
        error: onError
    }

    _.assign(ajaxParams, extraAjaxParams)
    _.assign(ajaxParams.headers, {'X-XSRF-TOKEN': getCSRFTokenValue()})

    ajaxLibrary.ajax(ajaxParams)
}

function makeParamObjFromPs(ps, routerDefinition, pageData) {
    const pages = _.map(pageData.getPagesList(ps, true), _pageId => ({
        pageId: _pageId,
        title: pageData.getPageData(ps, _pageId).title
    }))
    const paramObj = {
        isEditor: true,
        currentUrl: ps.siteAPI.getCurrentUrl(),
        clientSpecMap: ps.siteAPI.getClientSpecMap(),
        rendererModel_wixCodeModel_appData_codeAppId: ps.dal.get(ps.pointers.wixCode.getGridAppId()),
        routerDefinition,
        suffix: '',
        pageRoles: _.reduce(
            routerDefinition.pages,
            function (result, value, key) {
                result[key] = {
                    id: value,
                    title: _.find(pages, {pageId: value}).title
                }
                return result
            },
            {}
        ),
        publicBaseUrl: ps.siteAPI.getPublicBaseUrl()
    }
    return paramObj
}

function cleanPrefixesFromSiteMap(siteMapEntries, prefix) {
    const reg = new RegExp(`.*?\/${prefix}`)
    _.forEach(siteMapEntries, function (entry) {
        if (entry?.url) {
            entry.url = entry.url.replace(reg, '')
            if (entry.url.charAt(0) === '/' && entry.url.length > 1) {
                entry.url = entry.url.substring(1)
            }
        }
    })
}

function getInnerRoutesSiteMap(paramObj, onSuccess, onError, siteData) {
    getInnerRoutesSiteMapByRequestType(paramObj, REQUEST_TYPES.SITE_MAP, onSuccess, onError, siteData)
}

function getInnerRoutesSiteMapCount(paramObj, onSuccess, onError, siteData) {
    getInnerRoutesSiteMapByRequestType(paramObj, REQUEST_TYPES.SITE_MAP_COUNT, onSuccess, onError, siteData)
}

function getInnerRoutesSiteMapByRequestType(paramObj, requestType, onSuccess, onError, siteData) {
    function onSiteMapResponse(routerResponse) {
        if (routerResponse.exception) {
            onError(routerResponse.result)
            return
        }
        const siteMap = routerResponse.result
        cleanPrefixesFromSiteMap(siteMap, paramObj.routerDefinition.prefix)
        onSuccess(routerResponse.result)
    }

    getFromBackEnd(paramObj, requestType, onSiteMapResponse, onError, siteData)
}

function getPage(paramObj, onSuccess, onError, siteData) {
    const _onPageResponse = _.partial(
        onRoutingResponse.checkResponse,
        paramObj.routerDefinition,
        paramObj.suffix,
        paramObj.isPreview,
        paramObj.isEditorPreview,
        // @ts-ignore
        paramObj.primaryPageId,
        // @ts-ignore
        paramObj.publicBaseUrl,
        // @ts-ignore
        onSuccess
    )
    getFromBackEnd(paramObj, REQUEST_TYPES.PAGES, _onPageResponse, onError, siteData)
}

export default {
    getPage,
    getInnerRoutesSiteMap,
    getInnerRoutesSiteMapCount,
    makeParamObjFromPs
}
