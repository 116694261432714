/**
 * Created by eitanr on 6/24/14.
 */
import layout from '../util/layout'
import imageClientLib from '@wix/image-client-api/dist/imageClientApi'
import $ from 'zepto'
import _ from 'lodash'
import imageLayout from './imageLayout'
import {matrixScalingCalculations, galleriesCommonLayout} from '@wix/santa-core-utils'

function getSizeAfterScaling(
    additionalHeight,
    galleryHeight,
    displayerData,
    displayerMeasure,
    {imageMode, aspectRatio}
) {
    const totalItemContainerHeight = Math.floor(galleryHeight + additionalHeight)

    return matrixScalingCalculations.getSizeAfterScaling({
        itemHeight: totalItemContainerHeight,
        itemWidth: Math.floor(totalItemContainerHeight * aspectRatio),
        displayerData,
        imageMode,
        heightDiff: displayerMeasure.heightDiff,
        widthDiff: displayerMeasure.widthDiff,
        bottomGap: displayerMeasure.bottomGap
    })
}

/**
 *
 * @param compId
 * @param galleryHeight
 * @param additionalHeight
 * @param props
 * @param imageItems
 * @param displayerNode
 * @param index
 * @returns {{node: *, data: {imageIndex: *, width: *, height: *, uri: *}}}
 */
function getDisplayerCustomData(
    compId: string,
    galleryHeight: number,
    additionalHeight: number,
    props,
    imageItems,
    displayerNode,
    index?
) {
    const $displayer = $(displayerNode)

    const displayerData = {
        imageIndex: $displayer.data('image-index'),
        //todo: CLNT-5323 , wixapp sildergallery temporary workaround
        //todo: remove (width/height/uri) when wixapps sliderGalleryProxy will implement structureInfo-> dataItem-> items for the images
        width: $displayer.data('displayer-width'),
        height: $displayer.data('displayer-height'),
        uri: $displayer.data('displayer-uri')
    }

    const displayerMeasure = {
        heightDiff: parseInt($displayer.data('height-diff'), 10) || 0,
        widthDiff: parseInt($displayer.data('width-diff'), 10) || 0,
        bottomGap: parseInt($displayer.data('bottom-gap'), 10) || 0
    }

    let imageData = imageItems?.[displayerData.imageIndex] ? imageItems[displayerData.imageIndex] : displayerData
    imageData = _.assign(imageData, {displayMode: imageClientLib.fittingTypes.SCALE_TO_FILL})

    const sizeAfterScaling = getSizeAfterScaling(additionalHeight, galleryHeight, imageData, displayerMeasure, props)
    const containerSize = galleriesCommonLayout.getContainerSize(sizeAfterScaling.imageWrapperSize, $displayer)

    return {
        imageData,
        imageNodeId: `${compId}displayer${index}image`,
        imageWrapperId: `${compId}displayer${index}imageWrapper`,
        containerSize,
        sizeAfterScaling
    }
}

function measureSliderGalleryLayout(id: string, measureMap, nodesMap, structureInfo) {
    const imageMode = $(nodesMap[id]).data('imageMode') || 'clipImage'
    const aspectRatio = +$(nodesMap[id]).data('aspectRatio') || 1
    const itemsContainer = nodesMap[`${id}images`]
    const displayers = $(itemsContainer).children()
    const imageItems = _.get(structureInfo, 'dataItem.items')

    const additionalHeight = parseInt($(nodesMap[id]).data('additional-height'), 10) || 0
    measureMap.custom[id] = {
        displayers: _.map(
            displayers,
            _.partial(
                getDisplayerCustomData,
                id,
                measureMap.height[id],
                additionalHeight,
                {aspectRatio, imageMode},
                // @ts-ignore
                imageItems
            )
        ),
        additionalHeight
    }
}

function patchSliderGalleryLayout(id: string, patchers, measureMap, structureInfo, siteData) {
    const galleryHeight = measureMap.height[id]
    patchers.css(id, {
        height: galleryHeight
    })
    const galleryCustomMeasure = measureMap.custom[id]
    _.forEach(galleryCustomMeasure.displayers, function (displayer) {
        galleriesCommonLayout.updateImageWrapperSizes(patchers, displayer.imageWrapperId, displayer.sizeAfterScaling)
        imageLayout.patchNodeImage(
            displayer.imageNodeId,
            patchers,
            measureMap,
            siteData,
            displayer.imageData,
            displayer.containerSize
        )
    })
}

function getChildrenIdToMeasure(id: string) {
    const res: any[] = [['images'], ['itemsContainer']]

    const displayers = $(`#${id}images`).children()
    _.forEach(displayers, function (displayer, index) {
        const refId = `displayer${index}`
        const childImageComponent = {pathArray: [refId, 'image'], type: 'core.components.Image'}
        const imageWrapper = [refId, 'imageWrapper']
        res.push(childImageComponent, imageWrapper)
    })
    return res
}

layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.SliderGallery', getChildrenIdToMeasure)
layout.registerCustomMeasure('wysiwyg.viewer.components.SliderGallery', measureSliderGalleryLayout)
layout.registerPatcher('wysiwyg.viewer.components.SliderGallery', patchSliderGalleryLayout)

export default {getChildrenIdToMeasure, measureSliderGalleryLayout, patchSliderGalleryLayout}
