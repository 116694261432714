import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import experiment from 'experiment-amd'
import routers from '../routers/routers'
import page from '../page/page'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import component from '../component/component'
import clientSpecMapService from '../tpa/services/clientSpecMapService'
import menuAPI from '../menu/menuAPI'
import pagesGroup from '../pagesGroup/pagesGroup'
import tpa from '../tpa/tpa'
import tpaDeleteService from '../tpa/services/tpaDeleteService'
import connections from '../connections/connections'
import menuUtils from '../menu/menuUtils'
import workerService from './services/workerService'
import platform from './platform'
import appComponents from './appComponents'
import wixCode from '../wixCode/wixCode'
import constants from './common/constants'
import permissionsUtils from '../tpa/utils/permissionsUtils'
import platformStateService from './services/platformStateService'

const excludeApps = ['dataBinding', 'wix-code']

async function uninstallApp(ps: PS, appDataToDelete, applicationId, onSuccess = _.noop, onError = _.noop) {
    try {
        await tpa.app.delete(ps, appDataToDelete, applicationId)
        const appData = clientSpecMapService.getAppData(ps, applicationId)
        const appDefinitionId = _.get(appData, 'appDefinitionId')
        await deletePlatformPart(ps, appData)
        if (clientSpecMapService.hasEditorPlatformPart(clientSpecMapService.getAppData(ps, applicationId))) {
            await platform.remove(ps, applicationId)
        }
        onSuccess(appDefinitionId)
    } catch (e) {
        onError('Uninstall App Failed', e)
    }
}

async function uninstall(
    ps: PS,
    appDeletionData,
    appDefIds,
    onSuccess = _.noop,
    onError = _.noop,
    progressCallback = _.noop
) {
    const promises = []
    for (const data of appDeletionData) {
        _.forEach(data.appIdsToDelete, applicationId => {
            promises.push(uninstallApp(ps, data, applicationId, progressCallback, onError))
        })
    }

    promises.push(ps.setOperationsQueue.waitForChangesAppliedAsync())
    Promise.all(promises).then(() => onSuccess(), onError) // eslint-disable-line promise/prefer-await-to-then
}

async function deletePlatformPart(ps: PS, appData) {
    const {appDefinitionId, applicationId} = appData

    deletePagesGroup(ps, appDefinitionId, applicationId)
    deleteRouters(ps, appDefinitionId)
    deletePages(ps, appDefinitionId)
    deleteComponents(ps, appDefinitionId)
    deleteMenus(ps, applicationId)

    const shouldAvoidRevoking = await permissionsUtils.shouldAvoidRevoking(
        {ps},
        {intent: constants.Intents.USER_ACTION}
    )
    if (!shouldAvoidRevoking) {
        platformStateService.setAppPendingAction(ps, appDefinitionId, constants.APP_ACTION_TYPES.REMOVE)
    }

    if (experiment.isOpen('dm_uninstallCodePackages')) {
        await deleteCodePackages(ps, appData)
    }
}

async function deleteCodePackages(ps: PS, appData) {
    if (appComponents.hasCodePackage(appData)) {
        return wixCode.codePackages.uninstallCodeReusePkg(ps, appData.appDefinitionId)
    }
}

function deleteMenus(ps: PS, applicationId) {
    const appMenus = menuUtils.getMenusByFilter(ps, {appId: applicationId})
    _.forEach(appMenus, menu => menuAPI.remove(ps, menu.id))
}

function deletePages(ps: PS, appDefinitionId) {
    const pages = page.getPagesDataItems(ps).filter(pageData => pageData.managingAppDefId === appDefinitionId)
    _.forEach(pages, pageData => {
        const pagePointer = page.getPage(ps, pageData.id)
        if (ps.dal.isExist(pagePointer)) {
            page.remove(ps, pageData.id)
        }
    })
}

function deleteRouters(ps: PS, appDefinitionId) {
    const appRouters = _.filter(routers.get.all(ps), router => router.appDefinitionId === appDefinitionId)

    _.forEach(appRouters, routerRef => {
        const routerPointer = routers.getRouterRef.byPrefix(ps, routerRef.prefix)
        _.forEach(routerRef.pages, routerPage => {
            if (ps.dal.isExist(page.getPage(ps, routerPage))) {
                routers.pages.removePageFromRouter(ps, routerPointer, routerPage)
                page.remove(ps, routerPage)
            }
        })
        routers.remove(ps, routerPointer)
    })
}

function deletePagesGroup(ps: PS, appDefinitionId: string, applicationId) {
    const pageGroup = pagesGroup.getPagesGroupByAppId(ps, applicationId)
    pagesGroup.removePagesGroup(ps, pageGroup)
}

function deleteComponents(ps: PS, appDefinitionId: string) {
    const controlledComponents = _.filter(componentDetectorAPI.getAllComponents(ps), comp => {
        const compData = component.data.get(ps, comp)
        const applicationId = _.get(compData, 'applicationId')
        const appDefId = _.get(compData, 'appDefinitionId')
        return (applicationId || appDefId) === appDefinitionId
    })

    _.forEach(controlledComponents, comp => {
        if (ps.dal.isExist(comp)) {
            const controllerConnections = connections.getControllerConnectionsByAncestor(ps, comp)
            _.forEach(controllerConnections, componentRef => {
                component.remove(ps, componentRef)
            })

            if (ps.dal.isExist(comp)) {
                component.remove(ps, comp)
            }
        }
    })
}

const getAppPagesToDelete = function (ps: PS, applicationId: string, onError) {
    try {
        const appsPagesToDelete: any = tpaDeleteService.notifyAppsToDelete(ps, applicationId, true)
        appsPagesToDelete.isPlatformRemoval = true
        return appsPagesToDelete
    } catch (e) {
        onError(e)
    }
}

const getAppsPagesToDelete = function (ps: PS, appDeleteData, appsData, applicationId: string, onError) {
    const appPagesToDelete = getAppPagesToDelete(ps, applicationId, onError)
    if (appPagesToDelete) {
        appDeleteData.push(appPagesToDelete)
    }
}

const notifyBeforeApplicationDelete = async function (ps: PS, appDefId: string[] | string, onSuccess?, onError?) {
    const appDefIds = _.castArray(appDefId)
    const appDeleteData = []
    const appsData = appDefIds.reduce((res, appDefinitionId) => {
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        if (clientSpecMapService.isAppActive(ps, appData)) {
            res.push(appData)
        }
        return res
    }, [])
    for (const appData of appsData) {
        if (_.includes(excludeApps, appData.appDefinitionId)) {
            throw new Error(`It is not allowed to delete (${appDefId}) app.`)
        }
        const applicationId = _.get(appData, 'applicationId')
        if (clientSpecMapService.hasEditorPlatformPart(clientSpecMapService.getAppData(ps, applicationId))) {
            try {
                await workerService.notifyBeforeRemoveApp(ps, appData.appDefinitionId)
            } catch (e) {
                throw new Error('Uninstall App Failed.')
            }
            getAppsPagesToDelete(ps, appDeleteData, appsData, applicationId, onError)
        } else {
            getAppsPagesToDelete(ps, appDeleteData, appsData, applicationId, onError)
        }
    }
    ps.setOperationsQueue.asyncPreDataManipulationComplete(appDeleteData)
}

export default {
    uninstall,
    notifyBeforeApplicationDelete
}
