import type {PS} from '@wix/document-services-types'
import type {SaveTaskDefinition} from '../saveAPI/lib/registry'
import generalInfo from '../siteMetadata/generalInfo'
import wixBlocksService from './services/wixBlocksService'
import isAppStudio from './isAppStudio'
import getAppStudioData from './getAppStudioData'
import {getMetaSiteId} from '../utils/dalUtil'

async function onPreSave(ps: PS) {
    const isDraft = generalInfo.isDraft(ps)
    if (!isDraft) {
        return
    }

    const appStudioData = getAppStudioData(ps)

    const {appDefId} = await wixBlocksService.unDraftSite(ps, {
        metaSiteId: getMetaSiteId(ps),
        appName: ps.dal.get(ps.pointers.appBuilder.getDraftAppNamePointer()) || appStudioData.name
    })
    const devSiteAppDefIdPointer = ps.pointers.appBuilder.getDevSiteAppDefIdPointer()
    ps.dal.set(devSiteAppDefIdPointer, appDefId)
}

function createPreSaveTask(ps: PS): SaveTaskDefinition {
    const runPreSaveTask = (lastSnapshot, currentSnapshot, resolve, reject) => {
        // eslint-disable-next-line promise/prefer-await-to-then
        onPreSave(ps).then(resolve, reject)
    }

    const noopTask = (lastSnapshot, currentSnapshot, resolve) => {
        resolve()
    }

    const runTask = isAppStudio(ps) ? runPreSaveTask : noopTask

    const task = {
        partialSave: runTask,
        fullSave: runTask,
        firstSave: runTask,
        saveAsTemplate: runTask,
        autosave: runTask,
        publish: (currentData, resolve) => {
            resolve()
        },
        getTaskName: () => 'appStudioTask',
        getSnapshotTags: () => ['primary']
    }

    return task
}

export default {
    createPreSave: createPreSaveTask
}
