import * as multilingualReferenceFixer from './multilingualReferenceFixer'
import * as removeBrokenQueries from './removeBrokenQueries'
import * as removeBrokenMemberPages from './removeBrokenMemberPages'
import * as removeBrokenUnitSizesFromLayout from './removeBrokenUnitSizesFromLayout'
import * as removeBrokenStyleRelations from './removeBrokenVariantRelations'
import * as removeBrokenTpaSharedState from './removeBrokenTpaSharedState'
import * as fixBrokenConnections from './fixBrokenConnections'
import * as addAppDefinitionIdToTpaPages from './addAppDefinitionIdToTpaPages'
import * as removeFromConnectionBrokenControllers from './removeFromConnectionBrokenControllers'
import * as removeMetaDataFromMaps from './removeMetaDataFromMaps'
import * as wrapMapsForSignatures from './wrapMapsForSignatures'
import * as removeCorruptedLinksFromStyledText from './removeCorruptedLinksFromStyledText'
import * as customMenusCollectionFixer from './customMenusCollectionFixer'
import * as migrateStylableMenuToContainer from './migrateStylableMenuToContainer'
import * as migrateTPAWidgetToContainer from './migrateTPAWidgetToContainer'
import * as migrateTPASectionToContainer from './migrateTPASectionToContainer'
import * as fixMobileComponentsWithWrongParent from './fixMobileComponentsWithWrongPage'
import * as migrateMobileVariant from './migrateMobileVariant'
import * as runComponentSpecificMigrations from './componentMigrations/runComponentSpecificMigrations'
import * as childRefFixer from './childRefFixer'
import * as duplicateAndCrossPageRefFixer from './duplicateAndCrossPageRefFixer/duplicateAndCrossPageRefFixer'
import * as fixBrokenLayout from './fixBrokenLayout'
import * as fixBreakpointsVariantsQuery from './fixBreakpointsVariantsQuery'
import * as fixInvalidMediaContainerWithDividers from './fixInvalidMediaContainerWithDividers'
import * as fixBrokenMenusRefs from './fixBrokenMenuRefs'
import * as fixBrokenStylableMenuSubSubStyles from './fixBrokenStylableMenuSubSubStyles'
import * as colorPaletteMigrator from './colorPaletteMigrator'
import * as fixBrokenScopedLayout from './fixBrokenScopedLayout'
import * as addKeyframeEffectListToMasterPage from './addKeyframeEffectListToMasterPage'
import * as removeBrokenFixerVersion from './removeBrokenFixerVersion'

import type {PageMigrator, MasterPageMigrator, SiteMigrator} from '../dataMigrationRunner'

const defaultRunAlways: PageMigrator[] = [
    removeBrokenQueries,
    removeBrokenMemberPages,
    removeBrokenUnitSizesFromLayout,
    removeBrokenStyleRelations,
    removeFromConnectionBrokenControllers,
    removeCorruptedLinksFromStyledText,
    runComponentSpecificMigrations,
    migrateStylableMenuToContainer,
    migrateTPAWidgetToContainer,
    migrateTPASectionToContainer,
    fixMobileComponentsWithWrongParent,
    fixBrokenLayout,
    fixBrokenStylableMenuSubSubStyles,
    duplicateAndCrossPageRefFixer,
    customMenusCollectionFixer,
    fixBrokenScopedLayout,
    fixInvalidMediaContainerWithDividers
]

const defaultRunOnce: PageMigrator[] = [fixBrokenConnections, fixBreakpointsVariantsQuery, removeBrokenFixerVersion]

const defaultMasterPageMigrations: MasterPageMigrator[] = [
    addAppDefinitionIdToTpaPages,
    removeBrokenTpaSharedState,
    fixBrokenMenusRefs,
    removeMetaDataFromMaps,
    wrapMapsForSignatures,
    childRefFixer,
    addKeyframeEffectListToMasterPage
]

const defaultRunOncePerSite: SiteMigrator[] = [multilingualReferenceFixer, colorPaletteMigrator, migrateMobileVariant]

export {defaultRunAlways, defaultRunOnce, defaultMasterPageMigrations, defaultRunOncePerSite}
