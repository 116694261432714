import {pointerUtils} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import type {
    CompRef,
    Pointer,
    PossibleViewModes,
    PS,
    VariableConnections,
    VariantPointer
} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import dataIds from '../dataModel/dataIds'
import dataModel from '../dataModel/dataModel'
import dataSerialization from '../dataModel/dataSerialization'
import hooks from '../hooks/hooks'
import dsUtils from '../utils/utils'
import variants from '../variants/variants'
import variantsUtils from '../variants/variantsUtils'
import variableUtils from './variablesUtils'

const {getPointer} = pointerUtils
const {DATA_TYPES, VARIABLE_CONNECTIONS, NAMESPACES_SUPPORTING_VARIABLES} = constants
const VARIABLES_NAMESPACE = DATA_TYPES.variables

const createVariablesError = (message: string) => {
    return new ReportableError({
        message,
        errorType: 'variablesError'
    })
}

const validateCompPointer = (ps: PS, componentPointer: Pointer) => {
    if (!componentPointer || !ps.dal.isExist(componentPointer)) {
        throw createVariablesError('The component pointer is not passed or it does not exist')
    }
}
const validateCompAndVariablePointers = (ps: PS, componentPointer: Pointer, variablePointer: Pointer) => {
    validateCompPointer(ps, componentPointer)
    if (!variablePointer || !ps.dal.isExist(variablePointer)) {
        throw createVariablesError('The variable pointer is not passed or it does not exist')
    }
    const currentVariableIds = variableUtils.getComponentVariablesIds(ps, componentPointer)
    if (!_.includes(currentVariableIds, variablePointer.id)) {
        throw createVariablesError(`The component ${componentPointer.id} didn't define ${variablePointer.id} variable`)
    }
}
const validateVariants = (ps: PS, compPointerWithVariants: VariantPointer) => {
    _.forEach(compPointerWithVariants.variants, variant => {
        if (!ps.dal.isExist(variant)) {
            throw createVariablesError(`Passing component with non-existing variant ${variant.id}`)
        }
    })
}

const isVariableInUse = (ps: PS, variablePointer: Pointer) => {
    const references = ps.extensionAPI.relationships.getReferencesToPointer(variablePointer)
    return references.some(({type}) => type !== VARIABLES_NAMESPACE)
}

const validateVariableNotInUse = (ps: PS, variablePointer: Pointer) => {
    if (isVariableInUse(ps, variablePointer)) {
        throw createVariablesError(`The variable is in use`)
    }
}

const getVariableToAddRef = () => ({
    id: dataIds.generateNewId(VARIABLES_NAMESPACE),
    type: VARIABLES_NAMESPACE
})

const getComponentVariablesList = (ps: PS, componentPointer: Pointer) => {
    validateCompPointer(ps, componentPointer)
    return variableUtils
        .getComponentVariablesIds(ps, componentPointer)
        .map(variableId => ps.pointers.getPointer(variableId, VARIABLES_NAMESPACE))
}

const getVariable = (ps: PS, componentPointer: Pointer, variablePointer: Pointer) => {
    validateCompAndVariablePointers(ps, componentPointer, variablePointer)
    const variableDataItem = ps.dal.get(variablePointer)
    const variableDefaultValueDataItem = variantsUtils.getDataConsideringVariants(
        ps,
        variablePointer,
        'value',
        VARIABLES_NAMESPACE
    )
    const variableValueType = getVariableValueType(ps, variableDataItem.type)
    return {
        name: variableDataItem.name,
        type: variableDataItem.type,
        value: {type: variableValueType, value: variableDefaultValueDataItem.value}
    }
}

const addVariable = (ps: PS, variableToAddRef: VariantPointer, componentPointer: Pointer, variableData) => {
    validateCompPointer(ps, componentPointer)
    if (!variableData) {
        throw createVariablesError('The variableData is missing')
    }
    if (!variableData.type) {
        throw createVariablesError('The variableData is missing variable type')
    }
    if (!variableData.value) {
        throw createVariablesError('The variableData is missing default value for the variant')
    }

    const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
    const pageId = pagePointer?.id

    //create variable
    const variableID = dataSerialization.addSerializedItemToPage(
        ps,
        pageId,
        {
            name: variableData.name,
            type: variableData.type
        },
        variableToAddRef.id,
        VARIABLES_NAMESPACE
    )
    //add default value
    variantsUtils.updateDataConsideringVariants(ps, variableToAddRef, 'value', variableData.value, VARIABLES_NAMESPACE)

    const variablesListPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, VARIABLES_NAMESPACE)
    const doesVariablesListExists = ps.dal.isExist(variablesListPointer)
    if (!doesVariablesListExists) {
        //create variables list and link comp to it
        const variablesListID = dataModel.addDeserializedItemToPage(ps, pageId, VARIABLES_NAMESPACE, {
            type: 'VariablesList',
            variables: [`#${variableID}`]
        })
        dataModel.linkComponentToItemByType(ps, componentPointer, variablesListID, VARIABLES_NAMESPACE)
    } else {
        //add variable to existing variables list of the component
        const variablesListDataItem = ps.dal.get(variablesListPointer)
        ps.dal.set(variablesListPointer, {
            ...variablesListDataItem,
            variables: [...variablesListDataItem.variables, `#${variableID}`]
        })
    }
}

const removeVariable = (ps: PS, componentPointer: Pointer, variablePointer: Pointer) => {
    validateCompAndVariablePointers(ps, componentPointer, variablePointer)
    validateVariableNotInUse(ps, variablePointer)

    //remove all the values of the variable
    variantsUtils.removeDataConsideringVariants(ps, variablePointer, 'value', VARIABLES_NAMESPACE)

    //remove variable from variables list
    const variablesListPointer = dataModel.getComponentDataPointerByType(ps, componentPointer, VARIABLES_NAMESPACE)
    const variablesListDataItem = ps.dal.get(variablesListPointer)
    ps.dal.set(variablesListPointer, {
        ...variablesListDataItem,
        variables: _.without(variablesListDataItem.variables, `#${variablePointer.id}`)
    })

    //remove the variable
    ps.dal.remove(variablePointer)
}

const getVariableValueType = (ps: PS, variableType: string) => {
    const referenceFieldsInfo = ps.extensionAPI.schemaAPI.extractReferenceFieldsInfoForSchema(
        VARIABLES_NAMESPACE,
        variableType
    )
    const reference = _.find(referenceFieldsInfo, ref => _.isEqual(ref.path, ['value']) && !_.isEmpty(ref.refTypes))
    if (!reference) {
        throw createVariablesError(`${variableType} should define the variable value type in the schema`)
    }
    return reference.refTypes[0]
}

const updateVariableDefinition = (ps: PS, componentPointer: Pointer, variablePointer: Pointer, variableDefinition) => {
    validateCompAndVariablePointers(ps, componentPointer, variablePointer)
    const currentVariable = ps.dal.get(variablePointer)
    if (variableDefinition.type && currentVariable.type !== variableDefinition.type) {
        throw createVariablesError('Variable type cannot be changed')
    }

    if (variableDefinition.name) {
        ps.dal.set(variablePointer, {...currentVariable, name: variableDefinition.name})
    }

    if (variableDefinition.value) {
        variantsUtils.updateDataConsideringVariants(
            ps,
            variablePointer,
            'value',
            variableDefinition.value,
            VARIABLES_NAMESPACE
        )
    }
}

const getVariableData = (ps: PS, compPointerWithVariants: VariantPointer, variablePointer: Pointer) => {
    validateCompAndVariablePointers(ps, compPointerWithVariants, variablePointer)
    validateVariants(ps, compPointerWithVariants)
    const variablePointerWithVariants = compPointerWithVariants.variants
        ? variants.getPointerWithVariants(ps, variablePointer, compPointerWithVariants.variants)
        : variablePointer
    const variableDataItemInVariant = variantsUtils.getDataConsideringVariants(
        ps,
        variablePointerWithVariants,
        'value',
        VARIABLES_NAMESPACE
    )
    if (!variableDataItemInVariant) {
        return null
    }
    const variableDefinition = ps.dal.get(variablePointer)
    const variableValueType = getVariableValueType(ps, variableDefinition.type)
    return {
        type: variableValueType,
        value: variableDataItemInVariant.value
    }
}

const removeVariableData = (ps: PS, compPointerWithVariants: VariantPointer, variablePointer: Pointer) => {
    validateCompAndVariablePointers(ps, compPointerWithVariants, variablePointer)
    if (_.isEmpty(compPointerWithVariants.variants)) {
        throw createVariablesError(
            'Cannot remove the default variable value, please use the components.variables.remove function for that'
        )
    }
    validateVariants(ps, compPointerWithVariants)
    const variablePointerWithVariants = variants.getPointerWithVariants(
        ps,
        variablePointer,
        compPointerWithVariants.variants
    )
    variantsUtils.removeDataConsideringVariants(ps, variablePointerWithVariants, 'value', VARIABLES_NAMESPACE)
}

const updateVariableData = (
    ps: PS,
    compPointerWithVariants: VariantPointer,
    variablePointer: Pointer,
    variableData
) => {
    validateCompAndVariablePointers(ps, compPointerWithVariants, variablePointer)
    validateVariants(ps, compPointerWithVariants)
    const variablePointerWithVariants = compPointerWithVariants.variants
        ? variants.getPointerWithVariants(ps, variablePointer, compPointerWithVariants.variants)
        : variablePointer
    variantsUtils.updateDataConsideringVariants(
        ps,
        variablePointerWithVariants,
        'value',
        variableData,
        VARIABLES_NAMESPACE
    )
}

const getComponentsUsingVariable = (
    ps: PS,
    variablePointer: Pointer,
    viewMode: PossibleViewModes = constants.VIEW_MODES.DESKTOP
) => ps.extensionAPI.variables.getComponentsUsingVariable(variablePointer, viewMode)

function serializeVariableConnectionsInDataItem(ps: PS, dataItem) {
    const {variableConnections} = dataItem ?? {}
    if (!_.isArray(variableConnections)) return

    delete dataItem.variableConnections

    for (const {path, variableId} of variableConnections) {
        const splittedPath = path.split('/')
        splittedPath.splice(splittedPath.length - 1, 0, VARIABLE_CONNECTIONS)
        _.set(dataItem, splittedPath, variableId)
    }
}

const findAndRemoveInnerVariableConnections = (prop, rootVariableConnections, oldToNewIdMap, path = []) => {
    if (_.isArray(prop))
        prop.forEach((p, i) =>
            findAndRemoveInnerVariableConnections(p, rootVariableConnections, oldToNewIdMap, [...path, i])
        )
    if (!_.isPlainObject(prop)) return

    const {variableConnections} = prop
    if (_.isPlainObject(variableConnections)) {
        delete prop.variableConnections
        _.forOwn(variableConnections, (variableId, property) => {
            const newVariableId = oldToNewIdMap ? oldToNewIdMap[variableId] : variableId
            if (newVariableId) {
                rootVariableConnections.push({path: [...path, property].join('/'), variableId: newVariableId})
            }
        })
    }

    _.forOwn(prop, (p, key) =>
        findAndRemoveInnerVariableConnections(p, rootVariableConnections, oldToNewIdMap, [...path, key])
    )
}

function deserializeVariablesConnections(
    ps: PS,
    deserializedDataItem,
    itemType: string,
    itemId: string,
    oldToNewIdMap
) {
    if (!NAMESPACES_SUPPORTING_VARIABLES.has(itemType)) return deserializedDataItem

    const rootVariableConnections = []
    findAndRemoveInnerVariableConnections(deserializedDataItem, rootVariableConnections, oldToNewIdMap)

    const variableConnectionsPointer =
        itemId && ps.pointers.getPointer(itemId, itemType, {innerPath: ['variableConnections']})
    if (rootVariableConnections.length > 0 || ps.dal.isExist(variableConnectionsPointer)) {
        deserializedDataItem.variableConnections = rootVariableConnections
    }

    return deserializedDataItem
}

function removeOutOfScopeVariables(ps: PS, compPointer: Pointer) {
    const ancestorsVariableIds = variableUtils.collectAncestorsVariablesIds(ps, compPointer)
    removeOutOfScopeVariablesRecursively(ps, compPointer, ancestorsVariableIds)
}

function removeOutOfScopeVariablesRecursively(ps: PS, compPointer: Pointer, scopedVariableIds) {
    NAMESPACES_SUPPORTING_VARIABLES.forEach(namespace =>
        variableUtils.removeOutOfScopeVariablesFromRefArray(ps, compPointer, namespace, scopedVariableIds)
    )
    const componentChildren = ps.pointers.full.components.getChildren(compPointer)
    scopedVariableIds.push(variableUtils.getComponentVariablesIds(ps, compPointer))
    _.forEach(componentChildren, child => removeOutOfScopeVariablesRecursively(ps, child, scopedVariableIds))
}

const getVariableConnections = (ps: PS, obj) => {
    const {variableConnections} = obj ?? {}
    if (!variableConnections) return {}

    return _.mapValues(variableConnections, variableConnection => {
        if (!_.isString(variableConnection)) {
            throw createVariablesError('Invalid variable connections')
        }

        return {
            id: dsUtils.stripHashIfExists(variableConnection),
            type: 'variables'
        }
    })
}

const getComponentVariableConnections = (ps: PS, componentPointer: CompRef): VariableConnections => {
    const variableConnections = {}
    for (const namespace of NAMESPACES_SUPPORTING_VARIABLES) {
        const dataItemPointer = variantsUtils.getComponentDataPointerConsideringVariants(
            ps,
            componentPointer,
            namespace
        )
        const dataItem = dataItemPointer && ps.dal.get(dataItemPointer)
        const connections = dataItem?.variableConnections
        if (connections) {
            variableConnections[namespace] = {}
            for (const {path, variableId} of connections) {
                variableConnections[namespace][path] = getPointer(variableId, VARIABLES_NAMESPACE)
            }
        }
    }

    return variableConnections
}

const connect = (ps: PS, obj, property, variablePointer: Pointer) => {
    if (_.isNil(variablePointer) || !ps.dal.isExist(variablePointer)) {
        throw createVariablesError('The variable pointer is not passed or it does not exist')
    }

    if (!_.has(obj, property)) {
        throw createVariablesError('Property does not exist on object')
    }

    if (_.isArray(obj)) {
        return _.set(obj, [property, 'variableConnections', ''], variablePointer.id)
    }

    return _.set(obj, ['variableConnections', property], variablePointer.id)
}

const disconnect = (ps: PS, obj, property) => {
    if (_.isArray(obj)) {
        if (_.has(obj, [property, 'variableConnections'])) delete obj[property].variableConnections['']
        return obj
    }

    if (_.has(obj, ['variableConnections', property])) delete obj.variableConnections[property]
    return obj
}
hooks.registerHook(hooks.HOOKS.SERIALIZE.DATA_ITEM_AFTER, serializeVariableConnectionsInDataItem)
hooks.registerHook(hooks.HOOKS.DESERIALIZE.AFTER, deserializeVariablesConnections)
hooks.registerHook(hooks.HOOKS.CHANGE_PARENT.AFTER, removeOutOfScopeVariables)

export default {
    getVariableToAddRef,
    getComponentVariablesList,
    getVariable,
    addVariable,
    removeVariable,
    updateVariableDefinition,
    getVariableData,
    removeVariableData,
    updateVariableData,
    getComponentsUsingVariable,
    getVariableConnections,
    getComponentVariableConnections,
    connect,
    disconnect
}
