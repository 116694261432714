import _ from 'lodash'
import type {ComponentLayoutObject, GridItemLayout, Pointer} from '@wix/document-services-types'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {GridLayoutAPI} from '../gridLayout'
import type {ExtensionAPI} from '@wix/document-manager-core'

const section = 'responsive.components.Section'
const refComponent = 'wysiwyg.viewer.components.RefComponent'
const page = 'mobile.core.components.Page'

const ITEM_LAYOUT_TYPES = {
    STRETCH_ITEM_LAYOUT: {
        type: 'GridItemLayout',
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        gridArea: {
            columnStart: 1,
            columnEnd: 2
        }
    },
    NO_ITEM_LAYOUT: {}
}

const CONTAINER_LAYOUT_TYPES = {
    FRACTION: {
        rows: [
            {
                type: 'fr',
                value: 1
            }
        ],
        columns: [
            {
                type: 'fr',
                value: 1
            }
        ],
        type: 'GridContainerLayout'
    },
    MIN_MAX_SIZE: {
        columns: [
            {
                max: {type: 'fr', value: 1},
                min: {type: 'px', value: 0},
                type: 'MinMaxSize'
            }
        ],
        rows: [{type: 'auto'}, {type: 'auto'}, {type: 'auto'}],
        type: 'GridContainerLayout'
    }
}

const COMPONENT_LAYOUT_TYPES = {
    HEIGHT_AUTO: {
        height: {type: 'auto'},
        type: 'ComponentLayout'
    },
    MIN_HEIGHT: {
        height: {type: 'auto'},
        minHeight: {type: 'px', value: 100},
        type: 'ComponentLayout'
    },
    HEIGHT_WIDTH_AUTO: {
        height: {type: 'auto'},
        width: {type: 'auto'},
        type: 'ComponentLayout'
    }
}

const COMPONENT_TYPE_TO_LAYOUT_MAP = {
    [section]: {
        COMPONENT_LAYOUT_TYPES: COMPONENT_LAYOUT_TYPES.MIN_HEIGHT,
        CONTAINER_LAYOUT_TYPES: CONTAINER_LAYOUT_TYPES.FRACTION,
        ITEM_LAYOUT_TYPES: ITEM_LAYOUT_TYPES.STRETCH_ITEM_LAYOUT
    },
    [refComponent]: {
        COMPONENT_LAYOUT_TYPES: COMPONENT_LAYOUT_TYPES.HEIGHT_WIDTH_AUTO,
        CONTAINER_LAYOUT_TYPES: CONTAINER_LAYOUT_TYPES.FRACTION,
        ITEM_LAYOUT_TYPES: ITEM_LAYOUT_TYPES.STRETCH_ITEM_LAYOUT
    },
    [page]: {
        COMPONENT_LAYOUT_TYPES: COMPONENT_LAYOUT_TYPES.HEIGHT_AUTO,
        CONTAINER_LAYOUT_TYPES: CONTAINER_LAYOUT_TYPES.MIN_MAX_SIZE,
        ITEM_LAYOUT_TYPES: ITEM_LAYOUT_TYPES.NO_ITEM_LAYOUT
    }
}

const layoutDefinition = {
    x: 0,
    y: 0,
    width: 100,
    height: 100
}

const createLayoutsDefinition = (
    extensionAPI: ExtensionAPI,
    componentType: string,
    parentPointer: Pointer
): Partial<ComponentLayoutObject> => {
    const gridLayout = () => extensionAPI.gridLayout as GridLayoutAPI
    const layout: Partial<ComponentLayoutObject> = {
        componentLayout: deepClone(COMPONENT_TYPE_TO_LAYOUT_MAP[componentType].COMPONENT_LAYOUT_TYPES),
        containerLayout: deepClone(COMPONENT_TYPE_TO_LAYOUT_MAP[componentType].CONTAINER_LAYOUT_TYPES),
        itemLayout: deepClone(COMPONENT_TYPE_TO_LAYOUT_MAP[componentType].ITEM_LAYOUT_TYPES)
    }

    if ((layout.itemLayout as GridItemLayout)?.gridArea) {
        const {rowStart, rowEnd} = gridLayout().findNextRowInGrid(parentPointer)
        _.assign((layout.itemLayout as GridItemLayout)?.gridArea, {rowStart, rowEnd})
    }
    return layout
}

export {createLayoutsDefinition, layoutDefinition}
