import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import wixCodeUtils from '../utils/utils'
import fileDescriptorUtils from '../utils/fileDescriptorUtils'
import errors from '../utils/errors'
import constants from '../utils/constants'

const CONTENT_TYPE_JSON = 'application/json;charset=UTF-8'
const CONTENT_TYPE_PLAIN_TEXT = 'text/plain;charset=UTF-8'

const PUBLIC_FOLDER_NAME = 'public'
const BACKEND_FOLDER_NAME = 'backend'
const SCHEMAS_FOLDER_NAME = '.schemas'
const PATH_SEPARATOR = '/'

const LONG_LOCATION_FORMAT_REGEX = /^\/file\/[^\/]+\/(.+)$/

function ensureLongLocationFormat(location, codeAppInfo) {
    const result = location.match(LONG_LOCATION_FORMAT_REGEX)
    return result ? location : `/file/${codeAppInfo.appId}/${location}`
}

function ensureShortLocationFormat(location) {
    const result = location.match(LONG_LOCATION_FORMAT_REGEX)
    return result ? result[1] : location
}

function ensureDescriptorWithShortLocationFormat(descriptor) {
    return _.assign({}, descriptor, {location: ensureShortLocationFormat(descriptor.location)})
}

function isValidName(str) {
    return _.isString(str) && str.length > 0
}

function isValidContent(content) {
    return _.isString(content)
}

function isValidPartialChange(partialChange) {
    // @ts-expect-error
    return _.isObject(partialChange) && partialChange.diff && _.isArray(partialChange.diff)
}

function buildParametersString(urlParams) {
    return urlParams ? `?${santaCoreUtils.urlUtils.toQueryString(urlParams)}` : ''
}

function getBaseUrl(codeAppInfo) {
    return `${codeAppInfo.baseUrl}/v1`
}

function getFileUrl(codeAppInfo, location, urlParams) {
    return getBaseUrl(codeAppInfo) + ensureLongLocationFormat(location, codeAppInfo) + buildParametersString(urlParams)
}

function getFindUrl(codeAppInfo, location, urlParams) {
    return `${getBaseUrl(codeAppInfo)}/find${ensureLongLocationFormat(location, codeAppInfo)}${buildParametersString(
        urlParams
    )}`
}

function getBulkUrl(codeAppInfo, urlParams) {
    return `${codeAppInfo.baseUrl}/files/${codeAppInfo.appId}${buildParametersString(urlParams)}`
}

function stripLastPathSeperatorIfExists(relativeLocation) {
    return relativeLocation.replace(new RegExp(`${PATH_SEPARATOR}*$`), '')
}

function sendRequest(request, codeAppInfo) {
    request.headers = _.assign(
        {
            'X-Wix-Si': codeAppInfo.signedInstance
        },
        request.headers
    )

    _.assign(request, {
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true
    })

    return wixCodeUtils.sendRequestObj(request)
}

function sendFileRequest(request, codeAppInfo, location, additionalParams?) {
    request.url = getFileUrl(codeAppInfo, location, additionalParams)
    return sendRequest(request, codeAppInfo)
}

function sendFindRequest(request, codeAppInfo, location, additionalParams) {
    request.url = getFindUrl(codeAppInfo, location, additionalParams)
    return sendRequest(request, codeAppInfo)
}

function sendGetBulkRequest(request, codeAppInfo, additionalParams?) {
    request.url = getBulkUrl(codeAppInfo, additionalParams)
    return sendRequest(request, codeAppInfo)
}

async function doCreateFileOrFolder(codeAppInfo, itemName, parentFolder, isDirectory) {
    if (!fileDescriptorUtils.isFolder(parentFolder)) {
        throw new errors.ArgumentError(
            'parentFolder',
            'fileSystemService.doCreateFileOrFolder',
            parentFolder,
            'folder object'
        )
    }

    if (!isValidName(itemName)) {
        throw new errors.ArgumentError('itemName', 'fileSystemService.doCreateFileOrFolder', itemName)
    }

    const headers = {
        'X-Create-Options': 'no-overwrite'
    }
    const payload = {
        name: itemName,
        localTimeStamp: 0,
        directory: isDirectory
    }
    const request = {
        type: wixCodeUtils.requestTypes.POST,
        contentType: CONTENT_TYPE_JSON,
        headers,
        timeout: 15000,
        data: JSON.stringify(payload)
    }

    const response = await sendFileRequest(request, codeAppInfo, parentFolder.location)
    return ensureDescriptorWithShortLocationFormat(response)
}

async function doCopyOrMove(codeAppInfo, operation, fileSystemItem, targetFolder, newName) {
    if (!fileDescriptorUtils.isFileSystemItem(fileSystemItem)) {
        throw new errors.ArgumentError('fileSystemItem', 'fileSystemService.doCopyOrMove', fileSystemItem)
    }
    if (!fileDescriptorUtils.isFolder(targetFolder)) {
        throw new errors.ArgumentError('targetFolder', 'fileSystemService.doCopyOrMove', targetFolder, 'folder object')
    }

    if (!newName) {
        const parts = fileSystemItem.location.split(PATH_SEPARATOR)
        //if we rename a folder - last "cell" in the parts array will be empty so we pop twice...
        newName = parts.pop() || parts.pop()
    }

    const headers = {
        'X-Create-Options': `no-overwrite,${operation}`
    }

    const payload = {
        name: newName,
        location: ensureLongLocationFormat(fileSystemItem.location, codeAppInfo) // TBD: change to short format after server changes its expectation
    }

    const request = {
        type: wixCodeUtils.requestTypes.POST,
        contentType: CONTENT_TYPE_JSON,
        headers,
        timeout: 15000,
        data: JSON.stringify(payload)
    }

    const descriptor = await sendFileRequest(request, codeAppInfo, targetFolder.location)
    return ensureDescriptorWithShortLocationFormat(descriptor)
}

async function createFolder(extensionsAPI, codeAppInfo, folderName, parentFolder) {
    const serverResponse = await doCreateFileOrFolder(codeAppInfo, folderName, parentFolder, true)
    notifyLocalPathsChanged(extensionsAPI, [parentFolder.location])
    return serverResponse
}

async function deleteItem(extensionsAPI, codeAppInfo, itemToDelete) {
    if (!fileDescriptorUtils.isFileSystemItem(itemToDelete)) {
        throw new errors.ArgumentError('location', 'fileSystemService.deleteItem', itemToDelete)
    }

    const request = {
        type: wixCodeUtils.requestTypes.DELETE,
        headers: {
            Accept: '*/*'
        },
        dataType: 'text', // makes zepto not evaluate our prefetched script
        timeout: 15000
    }
    const serverResponse = await sendFileRequest(request, codeAppInfo, itemToDelete.location)
    notifyLocalPathsChanged(extensionsAPI, [itemToDelete.location, parentPath(itemToDelete.location)])
    return serverResponse
}

async function copy(extensionsAPI, codeAppInfo, itemToCopy, targetFolder, newName?) {
    const serverResponse = await doCopyOrMove(codeAppInfo, 'copy', itemToCopy, targetFolder, newName)
    notifyLocalPathsChanged(extensionsAPI, [targetFolder.location])
    return serverResponse
}

async function move(extensionsAPI, codeAppInfo, itemToMove, targetFolder, newName?) {
    const serverResponse = await doCopyOrMove(codeAppInfo, 'move', itemToMove, targetFolder, newName)
    notifyLocalPathsChanged(extensionsAPI, [itemToMove.location, targetFolder.location])
    return serverResponse
}

async function getChildren(codeAppInfo, parentFolder) {
    if (!fileDescriptorUtils.isFolder(parentFolder)) {
        throw new errors.ArgumentError('parentFolder', 'fileSystemService.getChildren', parentFolder, 'folder object')
    }
    const params = {depth: 1}
    const request = {type: wixCodeUtils.requestTypes.GET}
    const response: any = await sendFileRequest(request, codeAppInfo, parentFolder.location, params)
    return _.map(response.children || [], ensureDescriptorWithShortLocationFormat)
}

async function getMetadata(codeAppInfo, fileSystemItem) {
    if (!fileDescriptorUtils.isFileSystemItem(fileSystemItem)) {
        throw new errors.ArgumentError(
            'fileSystemItem',
            'fileSystemService.getMetadata',
            fileSystemItem,
            'file system object'
        )
    }
    const params = {parts: 'meta'}
    const request = {type: wixCodeUtils.requestTypes.GET}
    const response = await sendFileRequest(request, codeAppInfo, fileSystemItem.location, params)
    return ensureDescriptorWithShortLocationFormat(response)
}

function readAllFiles(codeAppInfo) {
    const request = {
        type: wixCodeUtils.requestTypes.GET,
        headers: {
            Accept: 'application/json'
        }
    }
    return sendGetBulkRequest(request, codeAppInfo)
}

async function readFile(codeAppInfo, file) {
    //TODO: handle acceptPatch
    if (!fileDescriptorUtils.isFileSystemItem(file)) {
        throw new errors.ArgumentError('filePath', 'fileSystemService.readFile', file, 'file object')
    }

    const request = {
        type: wixCodeUtils.requestTypes.GET,
        headers: {
            Accept: 'text/plain'
        },
        contentType: 'text/plain',
        dataType: 'text'
    }

    return sendFileRequest(request, codeAppInfo, file.location)
}

async function overrideFileContent(codeAppInfo, file, content) {
    if (!isValidContent(content)) {
        throw new errors.ArgumentError('content', 'fileSystemService.overrideFileContent', content, 'string')
    }
    const headers = {'If-Match': file.eTag}

    const request = {
        type: wixCodeUtils.requestTypes.PUT,
        headers,
        contentType: CONTENT_TYPE_PLAIN_TEXT,
        log: false,
        data: content
    }

    const response = await sendFileRequest(request, codeAppInfo, file.location)
    return ensureDescriptorWithShortLocationFormat(response)
}

async function patchFileContent(codeAppInfo, file, partialChange) {
    if (!isValidPartialChange(partialChange)) {
        throw new errors.ArgumentError(
            'partialChange',
            'fileSystemService.patchFileContent',
            partialChange,
            'partial change with diff object'
        )
    }
    const headers = {
        'X-HTTP-Method-Override': 'PATCH',
        'If-Match': file.eTag
    }

    const request = {
        type: wixCodeUtils.requestTypes.POST,
        headers,
        contentType: CONTENT_TYPE_PLAIN_TEXT,
        log: false,
        data: JSON.stringify(partialChange)
    }
    const response = await sendFileRequest(request, codeAppInfo, file.location)
    return ensureDescriptorWithShortLocationFormat(response)
}

async function writeFile(extensionsAPI, codeAppInfo, file, content?) {
    if (!fileDescriptorUtils.isFileSystemItem(file)) {
        throw new errors.ArgumentError('filePath', 'fileSystemService.writeFile', file, 'file object')
    }
    if (!isValidContent(content) && !isValidPartialChange(content)) {
        throw new errors.ArgumentError(
            'content',
            'fileSystemService.writeFile',
            content,
            'string or partial-change object'
        )
    }
    // FIXME
    const serverResponse =
        (await typeof content) === 'string'
            ? overrideFileContent(codeAppInfo, file, content)
            : patchFileContent(codeAppInfo, file, content)
    notifyLocalPathsChanged(extensionsAPI, [
        file.location,
        parentPath(file.location) // because writeFile is also used for creating new files
    ])
    return serverResponse
}

async function bulkWrite(extensionsAPI, codeAppInfo, files) {
    const payload = {
        updates: files.map(file => ({
            path: file.fileId,
            content: file.content
        }))
    }
    const request = {
        url: `${getBaseUrl(codeAppInfo)}/files/${codeAppInfo.appId}`,
        type: wixCodeUtils.requestTypes.PUT,
        contentType: CONTENT_TYPE_JSON,
        data: JSON.stringify(payload),
        headers: {
            Accept: '*/*'
        },
        dataType: 'text'
    }
    const serverResponse = await sendRequest(request, codeAppInfo)
    notifyLocalPathsChanged(extensionsAPI, [
        ...files.map(file => file.fileId),
        ...files.map(file => parentPath(file.fileId))
    ])
    return serverResponse
}

function getVirtualDescriptor(givenPath, isDirectory) {
    const path = stripLastPathSeperatorIfExists(givenPath)
    return {
        virtual: true,
        localTimeStamp: 0,
        eTag: '"virtual"',
        name: path.split(PATH_SEPARATOR).pop(),
        length: 0,
        directory: Boolean(isDirectory),
        location: path + (isDirectory ? PATH_SEPARATOR : ''),
        attributes: {
            readOnly: false
        }
    }
}

function getRoots() {
    return {
        schemas: getVirtualDescriptor(SCHEMAS_FOLDER_NAME, true),
        public: getVirtualDescriptor(PUBLIC_FOLDER_NAME, true),
        pages: getVirtualDescriptor(`${PUBLIC_FOLDER_NAME}/${constants.PAGES_ROOT}`, true),
        backend: getVirtualDescriptor(BACKEND_FOLDER_NAME, true)
    }
}

async function findByExtension(codeAppInfo, parentFolder, extension?) {
    function extractItems(response) {
        return _.map(response.items || [], ensureDescriptorWithShortLocationFormat)
    }

    if (!fileDescriptorUtils.isFolder(parentFolder)) {
        throw new errors.ArgumentError(
            'parentFolder',
            'fileSystemService.findByExtension',
            parentFolder,
            'folder object'
        )
    }
    if (_.isEmpty(extension)) {
        throw new errors.ArgumentError('extension', 'fileSystemService.findByExtension', extension, 'empty extension')
    }

    const params = {
        extension: _.startsWith(extension, '.') ? extension : `.${extension}`
    }

    const request = {
        type: wixCodeUtils.requestTypes.GET
    }

    const response = await sendFindRequest(request, codeAppInfo, parentFolder.location, params)
    return extractItems(response)
}

function notifyLocalPathsChanged(extensionsAPI, fileAndFolderPathsThatHaveChanged) {
    extensionsAPI.wixCodeSharedFileState.notifyLocalPathsChanged(
        fileAndFolderPathsThatHaveChanged.map(ensureShortLocationFormat)
    )
}

function parentPath(childPath) {
    return (
        stripLastPathSeperatorIfExists(childPath).split(PATH_SEPARATOR).slice(0, -1).join(PATH_SEPARATOR) +
        PATH_SEPARATOR
    )
}

export default {
    getRoots,
    createFolder,
    deleteItem,
    copy,
    move,
    getChildren,
    getMetadata,
    readAllFiles,
    readFile,
    writeFile,
    bulkWrite,
    getVirtualDescriptor,
    findByExtension,
    notifyLocalPathsChanged
}
