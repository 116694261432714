import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {DATA_TYPES, MULTILINGUAL_TYPES, PAGE_DATA_TYPES, PAGE_SCHEMA, VIEW_MODES} from '../constants/constants'
import {getDeepStructureForComp} from './structureUtils'
import {getTranslationsByPage} from './translationUtils'

const PAGE_PART_SEPARATOR = '|'

type Query = (ns: string, filter: any) => any

const getPageFlatStructure = (query: Query, pageFilter: any) => {
    const desktopComps = query(VIEW_MODES.DESKTOP, pageFilter)
    const mobileComps = query(VIEW_MODES.MOBILE, pageFilter)

    return {
        DESKTOP: desktopComps,
        MOBILE: mobileComps
    }
}

const getPageDeepStructure = (query: Query, pageFilter: any, pageId: string) => {
    const desktopComps = query(VIEW_MODES.DESKTOP, pageFilter)
    const mobileComps = query(VIEW_MODES.MOBILE, pageFilter)

    const mobilePageChildren = _.get(mobileComps, [pageId, 'components'], [])

    return _.merge({}, getDeepStructureForComp(pageId, desktopComps), {
        mobileComponents: mobilePageChildren.map((child: string) => getDeepStructureForComp(child, mobileComps))
    })
}

const getPageStructure = (query: Query, pageFilter: any, pageId: string, isFull: boolean) =>
    isFull ? getPageDeepStructure(query, pageFilter, pageId) : getPageFlatStructure(query, pageFilter)

const getPageDataType = (query: Query, pageFilter: any, type: string) => query(type, pageFilter)

const getPageData = (query: Query, pageFilter: any) =>
    _.reduce(
        PAGE_DATA_TYPES,
        (res, val, key) => {
            res[val] = getPageDataType(query, pageFilter, key)
            return res
        },
        {}
    )

const getPageTranslations = (query: Query, pageFilter: any, pageId: string) =>
    _.get(getTranslationsByPage(query(MULTILINGUAL_TYPES.multilingualTranslations, pageFilter))[pageId], [
        'translations'
    ]) || {}

const pageGetter = ({queryFilterGetters, query, get}: Args, pointer: Pointer, isFull: boolean) => {
    const [pageId, part] = pointer.id.split(PAGE_PART_SEPARATOR)
    const pageFilter = queryFilterGetters.getPageCompFilter(pageId)

    if (part) {
        // optimization to only get part of the page
        if (part === PAGE_SCHEMA.translations) {
            return getPageTranslations(query, pageFilter, pageId)
        }

        return getPageDataType(query, pageFilter, part)
    }

    // Getting a full page
    const pageData = get({id: pageId, type: DATA_TYPES.data})
    return {
        [PAGE_SCHEMA.pageUriSEO]: _.get(pageData, PAGE_SCHEMA.pageUriSEO),
        [PAGE_SCHEMA.title]: _.get(pageData, PAGE_SCHEMA.title),
        [PAGE_SCHEMA.data]: getPageData(query, pageFilter),
        [PAGE_SCHEMA.structure]: getPageStructure(query, pageFilter, pageId, isFull),
        [PAGE_SCHEMA.translations]: getPageTranslations(query, pageFilter, pageId)
    }
}

interface Args {
    queryFilterGetters: any
    query: Query
    get(p: Pointer): any
}

export const pageGetterFromFull = ({queryFilterGetters, query, get}: Args, pointer: Pointer) =>
    pageGetter({queryFilterGetters, query, get}, pointer, true)

export const pageGetterFromDisplayed = ({queryFilterGetters, query, get}: Args, pointer: Pointer) =>
    pageGetter({queryFilterGetters, query, get}, pointer, false)

export const getPagePartKey = (pageId: string, part: string) => `${pageId}${PAGE_PART_SEPARATOR}${part}`
