import type {DocumentManager} from '@wix/document-manager-core'
import {deepClone} from '@wix/wix-immutable-proxy'
import type {Pointer, Experiment} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from '@wix/document-manager-extensions/src/extensions/dataModel'
import type {RelationshipsAPI} from '@wix/document-manager-extensions'
import {MASTER_PAGE_ID, VARIANTS, DATA_TYPES, VIEW_MODES} from '../../constants/constants'
import {getPageList} from './utils/migrationUtil'

const experimentName = 'dm_oneMobileVariant'
const {MOBILE, VARIANTS_LIST} = VARIANTS.VARIANT_TYPES
const {MOBILE_VARIANT_ID, VARIANTS_QUERY} = VARIANTS

const getMobileVariants = (documentManager: DocumentManager, pageId: string) => {
    return documentManager.pointers.data.getVariantItemsWithPredicate(
        (variantItem: any) => variantItem.type === MOBILE && variantItem.id !== MOBILE_VARIANT_ID,
        pageId
    )
}

const addMobileVariantOnce = (documentManager: DocumentManager) => {
    const {dataModel} = documentManager.extensionAPI as DataModelExtensionAPI
    const mobilePointer = documentManager.pointers.data.getVariantsDataItem(MOBILE_VARIANT_ID, MASTER_PAGE_ID)
    if (documentManager.dal.get(mobilePointer)) {
        return
    }
    const variantsListPtr = dataModel.addItem(
        {
            type: VARIANTS_LIST,
            componentId: MASTER_PAGE_ID,
            values: [{type: MOBILE, componentId: MASTER_PAGE_ID, id: MOBILE_VARIANT_ID}]
        },
        DATA_TYPES.variants,
        MASTER_PAGE_ID
    )

    const masterPage = documentManager.pointers.structure.getMasterPage(VIEW_MODES.DESKTOP)
    dataModel.components.linkComponentToItemByTypeDesktopAndMobile(
        masterPage,
        variantsListPtr.id,
        DATA_TYPES.variants,
        VARIANTS_QUERY
    )
}

const migrateSite = (documentManager: DocumentManager, experimentInstance: Experiment) => {
    if (!experimentInstance.isOpen(experimentName)) {
        return
    }

    const pageIds = getPageList(documentManager)
    const pageMobileVariants = pageIds.map(pageId => getMobileVariants(documentManager, pageId)).flat()

    if (pageMobileVariants.length) {
        addMobileVariantOnce(documentManager)
        const {relationships} = documentManager.extensionAPI as RelationshipsAPI

        pageMobileVariants.forEach((mobileVariant: Pointer) => {
            const relations = relationships.getReferencesToPointer(mobileVariant)
            relations.forEach(relationPointer => {
                const relationData = deepClone(documentManager.dal.get(relationPointer))
                relationData.variants = relationData.variants.map((variantId: string) => {
                    return variantId === `#${mobileVariant.id}` ? `#${MOBILE_VARIANT_ID}` : variantId
                })
                documentManager.dal.set(relationPointer, relationData)
            })
            documentManager.dal.set(mobileVariant, undefined)
        })
    }
}

const name = 'migrateMobileVariant'
const version = 0
const experimentalVersions = [{version: 1, experiment: experimentName}]
export {migrateSite, name, version, experimentalVersions}
