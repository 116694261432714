import type {PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'

function getScale(ps, comp) {
    const layoutPointer = ps.pointers.getInnerPointer(comp, 'layout.scale')
    return ps.dal.get(layoutPointer) || 1
}

export default {
    rotatable: true,
    styleCanBeApplied: true,
    layoutLimits(ps: PS, compPointer, newLayout?) {
        const measureMap = ps.siteAPI.getSiteMeasureMap()
        const limits = {
            minWidth: _.get(measureMap, ['minWidth', compPointer.id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH),
            minHeight: _.get(
                measureMap,
                ['minHeight', compPointer.id],
                santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT
            )
        }

        if (newLayout?.scale) {
            const currentScale = getScale(ps, compPointer)
            if (currentScale !== newLayout.scale) {
                const ratio = newLayout.scale / currentScale
                return _.mapValues(limits, limit => limit * ratio)
            }
        }
        return limits
    },
    disableable: true
}
