import _ from 'lodash'
import experiment from 'experiment-amd'
import dataModel from '../../dataModel/dataModel'
import constants from '../../constants/constants'

const getPageComponentPointer = (ps, pagePointer) =>
    ps.pointers.components.getPage(pagePointer.id, constants.VIEW_MODES.DESKTOP)
const getCompsDataAndPointers = ps => {
    const allPagesPointers = ps.pointers.page.getNonDeletedPagesPointers(true)
    const pageCompsPointers = allPagesPointers.map(pagePointer => getPageComponentPointer(ps, pagePointer))
    const allCompsInfo = _.flatMap(
        pageCompsPointers
            .flatMap(parentPointer => ps.pointers.full.components.getChildrenRecursively(parentPointer))
            .map(compPointer => ({
                compData: ps.dal.full.get(compPointer),
                compPointer
            }))
    )
    return allCompsInfo
}
const fixCompWithInvalidMobileHintsQuery = (ps, comp) => {
    ps.dal.full.set(comp.compPointer, _.omit(comp.compData, 'mobileHintsQuery'))
}
const fixCompWithDuplicatedMobileHintsQuery = (ps, comp, duplicatedMobileHintsItem) => {
    ps.dal.full.set(comp.compPointer, _.omit(comp.compData, 'mobileHintsQuery'))
    dataModel.updateMobileHintsItem(ps, comp.compPointer, _.omit(duplicatedMobileHintsItem, 'id'))
}

export default {
    exec(ps) {
        if (experiment.isOpen('dm_duplicatedMobileHintsDataFixer')) {
            const allCompsInfo: any[] = getCompsDataAndPointers(ps)
            const compsWithMobileHintsQueries: any[] = allCompsInfo.filter(
                comp => !_.isUndefined(_.get(comp.compData, 'mobileHintsQuery'))
            )
            const mobileHintsQueryToCompId = {}
            for (const comp of compsWithMobileHintsQueries) {
                const mobileHintsItem = dataModel.getMobileHintsItem(ps, comp.compPointer)
                const compMobileHintsQuery = comp.compData.mobileHintsQuery
                if (_.isNull(mobileHintsItem) || _.isUndefined(mobileHintsItem)) {
                    fixCompWithInvalidMobileHintsQuery(ps, comp)
                } else if (!_.isUndefined(mobileHintsQueryToCompId[compMobileHintsQuery])) {
                    fixCompWithDuplicatedMobileHintsQuery(ps, comp, mobileHintsItem)
                } else {
                    mobileHintsQueryToCompId[compMobileHintsQuery] = comp.compData.id
                }
            }
        }
    },
    name: 'duplicatedMobileHintsDataFixer',
    version: 1
}
