import type {Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {ScopeMetaDataTemplate} from '@wix/document-services-types'

export interface RemoteStructureMetaDataAPI extends ExtensionAPI {
    remoteStructureMetaData: {
        getMetaDataTemplate(appId: string, widgetId: string): ScopeMetaDataTemplate | undefined
    }
}

/**
 * This is extension will use site assets client to fetch the widget meta data template
 */
const createExtension = (): Extension => {
    const createExtensionAPI = (): RemoteStructureMetaDataAPI => {
        function getMetaDataTemplate(): ScopeMetaDataTemplate | undefined {
            return undefined
        }

        return {
            remoteStructureMetaData: {
                getMetaDataTemplate
            }
        }
    }

    return {
        name: 'remoteStructureMetaData',
        createExtensionAPI,
        dependencies: new Set()
    }
}

export {createExtension}
