import {DmApis, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import type {CompRef, Pointer, PossibleViewModes, ScopeMetaData, ScopePointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {CreateViewerExtensionArgument} from '../types'
import {getScopedPointer} from '../utils/scopesUtils'
import type {ScopesExtensionAPI} from './scopes'
import {ReportableError} from '@wix/document-manager-utils'
import {getIdFromRef} from '../utils/dataUtils'
import type {StructureMetaDataExtensionAPI} from './structureMetaData'
import {getPlatformData} from '../utils/structureMetaDataUtils'
import type {RemoteStructureMetaDataAPI} from './remoteStructureMetaData'

const SCOPE_TYPES = {
    REMOTE_REF: 'REMOTE_REF',
    INTERNAL_REF: 'INTERNAL_REF'
} as const

type ScopeType = keyof typeof SCOPE_TYPES

export interface ScopesMetaDataExtensionAPI extends ExtensionAPI {
    scopesMetaData: {
        load(): Promise<void>
        get(pointer: ScopePointer, viewMode?: PossibleViewModes): ScopeMetaData
    }
}

const createExtension = ({viewerManager}: CreateViewerExtensionArgument): Extension => {
    let scopesMetaDataLoaded = false

    const load = async () => {
        scopesMetaDataLoaded = true
    }

    const validateMetadataLoaded = (): void => {
        if (!scopesMetaDataLoaded) {
            throw new ReportableError({
                errorType: 'SCOPES_METADATA_NOT_LOADED',
                message: 'Metadata is not loaded'
            })
        }
    }

    const getFromViewer = (pointer: Pointer): any => {
        return viewerManager.dal.get(pointer)
    }

    const createExtensionAPI = ({extensionAPI, pointers}: DmApis): ScopesMetaDataExtensionAPI => {
        const {scopes} = extensionAPI as ScopesExtensionAPI

        const getData = (compPointer: Pointer) => {
            const {dataQuery} = getFromViewer(compPointer) ?? {}
            if (!dataQuery) {
                return
            }

            const dataId = getIdFromRef(dataQuery)
            const dataPointer = pointers.data.getDataItem(dataId)

            return getFromViewer(dataPointer)
        }

        function getInternalMetaData(scopeOwner: CompRef, scopePointer: ScopePointer): ScopeMetaData {
            const data = getData(scopeOwner)

            const {rootCompId} = data
            const root = pointerUtils.getPointerFromPointer(rootCompId, scopeOwner) as CompRef

            return (extensionAPI as StructureMetaDataExtensionAPI).structureMetaData.getMetaData(
                root,
                scopeOwner,
                scopePointer
            )
        }

        function getRemoteMetaData(scopeOwner: CompRef, scopePointer: ScopePointer): ScopeMetaData {
            const data = getData(scopeOwner)

            const {appDefinitionId, widgetId} = data

            const metaDataTemplate = (
                extensionAPI as RemoteStructureMetaDataAPI
            ).remoteStructureMetaData.getMetaDataTemplate(appDefinitionId, widgetId)

            if (!metaDataTemplate) {
                return getMetaDataFromViewer(scopeOwner, scopePointer)
            }

            return (extensionAPI as StructureMetaDataExtensionAPI).structureMetaData.getMetaDataFromTemplate(
                metaDataTemplate,
                scopeOwner,
                scopePointer
            )
        }

        function getMetaDataFromViewer(scopeOwner: CompRef, scopePointer: ScopePointer) {
            const refComp = getFromViewer(scopeOwner)
            const rootCompId = refComp.components[0]
            const rootPointer = getScopedPointer(rootCompId, scopeOwner.type, _.cloneDeep(scopePointer)) as CompRef
            const rootType = getFromViewer(rootPointer).componentType
            const data = getData(rootPointer)

            const platform = (
                extensionAPI as StructureMetaDataExtensionAPI
            ).structureMetaData.getPlatformMetaDataFromTemplate(rootPointer, getPlatformData(data))

            return {
                rootPointer,
                rootType,
                platform
            }
        }

        function getScopeType(scopePointer: ScopePointer, viewMode: PossibleViewModes): ScopeType {
            const scopeOwner = scopes.getScopeOwner(scopePointer, viewMode)
            const data = getData(scopeOwner)

            if (!data) {
                throw new ReportableError({
                    errorType: 'SCOPES_METADATA_MISSING_SCOPE_OWNER_DATA',
                    message: 'Unable to get scope owner data'
                })
            }

            return data.type === 'WidgetRef' ? SCOPE_TYPES.REMOTE_REF : SCOPE_TYPES.INTERNAL_REF
        }

        const get = (scopePointer: ScopePointer, viewMode: PossibleViewModes = 'DESKTOP'): ScopeMetaData => {
            validateMetadataLoaded()

            const scopeOwner = scopes.getScopeOwner(scopePointer, viewMode)
            const scopeType = getScopeType(scopePointer, viewMode)

            switch (scopeType) {
                case SCOPE_TYPES.REMOTE_REF:
                    return getRemoteMetaData(scopeOwner, scopePointer)
                case SCOPE_TYPES.INTERNAL_REF:
                    return getInternalMetaData(scopeOwner, scopePointer)
            }
        }

        return {
            scopesMetaData: {
                load,
                get
            }
        }
    }

    return {
        name: 'scopesMetaData',
        dependencies: new Set(['data', 'scopes', 'structureMetaData']),
        createExtensionAPI
    }
}

export {createExtension}
