import _ from 'lodash'
import {getDefaultViewerMock, getDefaultViewerStore} from '@wix/viewer-mock'
import {deepClone} from '@wix/wix-immutable-proxy'
import {
    host as dmHost,
    Host,
    initialize,
    ConfigName,
    InitConfig,
    DefaultRendererModelBuilderForHost
} from '@wix/document-manager-host-common'
import miniSiteModels from './models.json'
import {createPrivateServices} from '../privateServices'
import {createViewerManagerAndExtendHost} from '../adapter'
import * as dsInterface from '@wix/document-services'
import {MockCEditTestServer, constants, PageAPI, RMApi} from '@wix/document-manager-extensions'
import {newSchemaService} from '@wix/document-services-json-schemas'
const {modelsInitializer, mainInitialization} = initialize
import {store, DocumentManager, CoreLogger} from '@wix/document-manager-core'
import type {
    Experiment,
    Pointer,
    MiniSiteManager,
    DocumentServicesObject,
    ClientSpecMap
} from '@wix/document-services-types'
import type {AdapterLogger} from '../adapterLogger'

const {VIEWER_PAGE_DATA_TYPES, VIEW_MODES} = constants
const {createStore} = store

const getMockedViewer = (overrides: any = {}, viewerParams: any = {}) => {
    const viewerStore = getDefaultViewerStore(overrides, viewerParams.withDefaults)
    return getDefaultViewerMock(viewerStore, overrides, viewerParams.withDefaults)
}

const getDataAndStructureFromPages = (dm: any, pageIds: string[]) => {
    const pageAPI = dm.extensionAPI.page as PageAPI
    const structure = pageIds.reduce((acc, pageId) => {
        return Object.assign(acc, dm.dal.query(VIEW_MODES.DESKTOP, pageAPI.getPageIndexId(pageId)))
    }, {})

    const dataPageIds = pageIds.concat(['masterPage'])
    const data = dataPageIds.reduce((acc, pageId) => {
        const pageData = _(VIEWER_PAGE_DATA_TYPES)
            .mapValues((v, nsName) => dm.dal.query(nsName, pageAPI.getPageIndexId(pageId)))
            .pickBy(_.identity)
            .mapKeys((v, k) => VIEWER_PAGE_DATA_TYPES[k])
            .value()

        return _.merge(acc, pageData)
    }, {})
    return {data, structure}
}
const getDefaultViewerOverrides = (dm: any) => deepClone(getDataAndStructureFromPages(dm, ['c1dmp']))

const inheritModelsFromHostDM = (dm: DocumentManager, editedDocumentDM: DocumentManager) => {
    const themePointer = editedDocumentDM.pointers.data.getThemeItem('THEME_DATA', 'masterPage')
    dm.dal.set(themePointer, editedDocumentDM.dal.get(themePointer))
}

export interface MiniSiteManagerCreationArgs {
    logger: AdapterLogger
    experimentInstance: Experiment
    configName: ConfigName
    editedDocumentDM: DocumentManager
    modules: any //the actual public api modules namespaces, i.e. {initMethod, methods: {components: {...}} etc
    getViewerFragments(hostReact: any, serviceTopology: any, viewerDirectProps: any): any
}

const getEmptyLogger = (): CoreLogger => ({
    captureError: () => {},
    interactionEnded: () => {},
    interactionStarted: () => {},
    breadcrumb: () => {},
    flush: async () => {},
    reportBI: () => {}
})

export const createMiniSiteManagerAPI = ({
    logger, //currently not used, but we might need it later on
    experimentInstance,
    modules,
    configName,
    editedDocumentDM,
    getViewerFragments
}: MiniSiteManagerCreationArgs): MiniSiteManager => {
    let hostReact: any

    const environmentContext = {
        fetchFn: fetch, //we have readonly config so no POST will happen. If an API needs to read for some reason, we will let it
        serverFacade: new MockCEditTestServer()
    }

    const rendererModelFromHostDM = _.get(window, ['rendererModel'])
    //set initial CSM to what's in the dal, since it is possible something updated while loading (like databinding fake app)
    rendererModelFromHostDM.clientSpecMap = editedDocumentDM.dal.get(
        editedDocumentDM.pointers.general.getClientSpecMap()
    )
    const models = {..._.pick(window, ['serviceTopology', 'documentServicesModel'])}

    const minisiteLogger = getEmptyLogger()

    const miniSiteHost: Host = dmHost.createHost({
        models,
        experimentInstance,
        logger: minisiteLogger,
        schemaService: newSchemaService.staticInstance,
        config: {
            configName,
            dsOrigin: 'miniSite',
            autosaveRestore: 'false',
            supportsPlatformInitialization: false,
            isReadOnly: true
        },
        environmentContext,
        initFunc: mainInitialization.initialize,
        pageList: rendererModelFromHostDM.pageList,
        rendererModelBuilder: new DefaultRendererModelBuilderForHost(rendererModelFromHostDM)
    })

    const dm = miniSiteHost.documentManager
    const initialStore = createStore()
    _.forOwn(miniSiteModels, (typeMap, type) => {
        _.forOwn(typeMap, (data, id) => {
            const pointer = dm.pointers.getPointer(id, type)
            initialStore.set(pointer, data)
        })
    })

    modelsInitializer.initialize(
        {
            documentManager: dm,
            ...models,
            rendererModel: rendererModelFromHostDM
        } as InitConfig,
        initialStore
    )
    dm.dal.mergeToApprovedStore(initialStore)

    inheritModelsFromHostDM(dm, editedDocumentDM)
    const mockViewer = getMockedViewer(getDefaultViewerOverrides(dm), {
        experimentInstance: dm.experimentInstance,
        withDefaults: {
            defaultPageId: 'c1dmp'
        }
    })

    const {host, viewerManager} = createViewerManagerAndExtendHost({hostWithDM: miniSiteHost, viewer: mockViewer})

    const ps = createPrivateServices({host, viewerManager})

    const adapter = {
        ps,
        host,
        config: host.config,
        documentServicesDataFixer: {
            fix: _.noop
        }
    }

    const {rendererModel: editedDocumentRendererModelExtension} = editedDocumentDM.extensionAPI as RMApi
    if (experimentInstance.isOpen('dm_miniSitesCSM')) {
        editedDocumentRendererModelExtension.registerToClientSpecMapUpdate((updatedClientSpecMap: ClientSpecMap) => {
            dm.dal.set(dm.pointers.general.getClientSpecMap(), updatedClientSpecMap)
        })
    }
    const setHostReact = (reactFromHost: any) => {
        hostReact = reactFromHost
    }

    const createPreviewElements = async (compPointers: Pointer[]) => {
        const pageIds: string[] = _.uniq(
            compPointers.map(pointer => ps.pointers.components.getPageOfComponent(pointer).id)
        )
        const rootCompIds = _.map(compPointers, 'id')
        const {data, structure} = getDataAndStructureFromPages(dm, pageIds)
        const renderFlags = dm.dal.get(dm.pointers.general.getRenderFlags())
        //need to clone props, bc thunderbolt renderer mutates our metaData.pageId
        const viewerDirectProps = deepClone({
            structure,
            data,
            renderFlags,
            onReady: _.noop,
            rootCompIds: rootCompIds ?? pageIds,
            rootStyleIds: []
        })
        if (hostReact) {
            if (experimentInstance.isOpen('dm_miniSitesCSM')) {
                //will fix types when merging experiment. We are changing the interface with the viewer, they will accept both types for now
                //@ts-ignore
                viewerDirectProps.clientSpecMap = deepClone(dm.dal.get(dm.pointers.general.getClientSpecMap()))
                //@ts-ignore
                return getViewerFragments(hostReact, viewerDirectProps)
            }
            return getViewerFragments(hostReact, window.serviceTopology, viewerDirectProps)
        }
    }

    let ds
    // eslint-disable-next-line prefer-const
    let readyPromise: Promise<DocumentServicesObject>
    const miniSiteManager: MiniSiteManager = {
        ds,

        setHostReact,

        createPreviewElements,
        // @ts-ignore
        waitForLoad: () => readyPromise
    }

    //@ts-ignore
    readyPromise = dsInterface.createDocumentServices(adapter, modules, logger).then(miniSiteDs => {
        ds = miniSiteManager.ds = miniSiteDs
        return ds
    })

    return miniSiteManager
}
