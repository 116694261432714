import _ from 'lodash'
import experiment from 'experiment-amd'
import constants from '../../constants/constants'

function getLayoutLimits(ps, repeaterPointer) {
    const itemPointer = _.head(ps.pointers.full.components.getChildren(repeaterPointer))
    const itemLayout = itemPointer && ps.dal.full.get(ps.pointers.getInnerPointer(itemPointer, ['layout']))
    return {
        minWidth: _.get(itemLayout, 'width')
    }
}

export default {
    enforceContainerChildLimitsByWidth: false,
    layoutLimits: getLayoutLimits,
    canBeStretched: true,
    isRepeater: true,
    get isRepeatable() {
        return experiment.isOpen('dm_widgetInRepeater')
    },
    resizableSides: [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT],
    mobileConversionConfig: {
        filterChildrenWhenHidden: true,
        stretchHorizontally: true
    }
}
