import type {PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import pageData from '../page/pageData'

function getAppStudioData(ps: PS) {
    const masterPageData = pageData.getPageData(ps, santaCoreUtils.siteConstants.MASTER_PAGE_ID, true) || {}

    if (!masterPageData.appStudioData) {
        return undefined
    }

    if (!masterPageData.appStudioData.dependencies) {
        return {
            ...masterPageData.appStudioData,
            dependencies: []
        }
    }

    return masterPageData.appStudioData
}

export default getAppStudioData
