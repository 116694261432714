import type {EnforceType} from '@wix/document-services-types'

export const CONTINUOUS_ACTION_TYPES = {
    START: 'start',
    ON: 'on',
    END: 'end'
}

export const IS_UPDATING_ANCHORS = {
    YES: 'yes',
    NO: 'no',
    DONT_CARE: "don't care"
}

export const ENFORCE_TYPES: Record<EnforceType, EnforceType> = {
    NONE: 'NONE',
    INNER: 'INNER',
    FULL: 'FULL',
    DONT_CARE: 'DONT_CARE',
    GENERATE_AND_ENFORCE: 'GENERATE_AND_ENFORCE'
}
