import shapeMeasuring from './shapeMeasuring'
import textMeasuring from './textMeasuring'
import './groupMeasuring'
import './contentAreaMeasuring'
import './verticalLineMeasuring'
import './appWidgetMeasuring'
import './pagePlugins'

export default {
    initialize(ps) {
        textMeasuring.initialize(ps)
        shapeMeasuring.initialize(ps)
    }
}
