import $ from 'zepto'
import _ from 'lodash'
import anchors from './anchors'
import layoutAlgorithm from './layoutAlgorithm'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'
import {dataUtils, siteConstants, arrayUtils} from '@wix/santa-core-utils'
import createDOMPatchers from './createDOMPatchers'
import singleCompLayout from './singleCompLayout'
import iframesPatcher from './iframesPatcher'
import getAddressLinksPatcher from './getAddressLinksPatcher'
// @ts-ignore
import experiment from 'experiment'
/**
 * Created with IntelliJ IDEA.
 * User: avim
 * Date: 5/18/14
 * Time: 1:57 PM
 * To change this template use File | Settings | File Templates.
 */

const cachedNodesMap = {}

const classBasedLayoutInnerCompsFirst = {}
const classBasedMeasureChildrenFirst = {}

const PERFORMANCE_NAME = warmupUtils.loggingUtils.performanceMetrics.RE_LAYOUT

const isBolt = () => typeof window === 'object' && _.get(window, 'wixBiSession.renderType') === 'bolt'

function getComponentAdditionalChildren(structureHierarchy, isMobileView) {
    const compStructure = _.last(structureHierarchy)
    const children = dataUtils.getChildrenData(compStructure, isMobileView)

    return _.transform(
        children,
        function (relatedCompsMap, childStructure) {
            relatedCompsMap[childStructure.id] = true
        },
        {}
    )
}

function getStripAdditionalComponents(structureHierarchy, isMobileView) {
    const getAdditionalComps = struct => {
        if (struct.componentType === 'wysiwyg.viewer.components.StripColumnsContainer') {
            return getChildren(struct /*, isMobileView*/)
        }
        if (struct.componentType === 'wysiwyg.viewer.components.Group') {
            return dataUtils.getChildrenData(struct, isMobileView)
        }
        return []
    }

    const getChildren = structure =>
        _.flatMap(dataUtils.getChildrenData(structure, isMobileView), childStructure => [
            childStructure,
            ..._.flatMap(dataUtils.getChildrenData(childStructure, isMobileView), grandchildStructure => [
                grandchildStructure,
                ...getAdditionalComps(grandchildStructure)
            ])
        ])

    const compStructure = _.last(structureHierarchy)
    return getChildren(compStructure).reduce((relatedCompsMap, childStructure) => {
        relatedCompsMap[childStructure.id] = true
        return relatedCompsMap
    }, {})
}

function getComponentAdditionalParent(structureHierarchy) {
    const parentStructure = structureHierarchy[structureHierarchy.length - 2]

    return _.set({}, parentStructure.id, true)
}

const classBasedRelatedCompsToRelayout = {
    'wysiwyg.viewer.components.Group': getComponentAdditionalChildren,
    'wysiwyg.viewer.components.BoxSlideShow': getComponentAdditionalChildren,
    'wysiwyg.viewer.components.StripContainerSlideShow': getComponentAdditionalChildren,
    'wysiwyg.viewer.components.StripColumnsContainer': getStripAdditionalComponents,
    'wysiwyg.viewer.components.Column'(structureHierarchy, isMobileView) {
        const parentStructure = structureHierarchy[structureHierarchy.length - 2]
        const siblingsStructures = dataUtils.getChildrenData(parentStructure, isMobileView)

        const relatedComps = [parentStructure].concat(siblingsStructures)

        return _.transform(
            relatedComps,
            function (relatedCompsMap, relatedCompStructure) {
                relatedCompsMap[relatedCompStructure.id] = true
            },
            {}
        )
    },
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': getComponentAdditionalParent,
    'wysiwyg.viewer.components.BoxSlideShowSlide': getComponentAdditionalParent
}

function enforceRange(value, min, max) {
    return value && Math.max(min, Math.min(max, value))
}

/**
 * @typedef {{
 *      dataItem: data.compDataItem,
 *      layout: compStructure.layout,
 *      id: string,
 *      type: string
 * }} layout.structureInfo
 */

function shouldSkipComponent(compsToMeasure, structureInfo) {
    return !!(compsToMeasure && !compsToMeasure[structureInfo.id])
}

function getComponentTypeForLayout(structure) {
    return structure.componentType || structure.documentType
}

/**
 *
 * @param compStructure
 * @param layoutAPI
 * @param pageId
 * @returns {layout.structureInfo}
 */
function getComponentStructureInfo(compStructure, layoutAPI, pageId?) {
    const layout = _.clone(compStructure.layout)

    const structureInfo: Record<string, any> = {
        dataItem: compStructure.dataItem || null,
        layout,
        id: compStructure.id,
        type: getComponentTypeForLayout(compStructure),
        structure: compStructure,
        rootId: pageId
    }
    if (structureInfo.layout) {
        if (_.isFinite(structureInfo.layout.height)) {
            structureInfo.layout.height = enforceRange(
                structureInfo.layout.height,
                siteConstants.COMP_SIZE.MIN_HEIGHT,
                siteConstants.COMP_SIZE.MAX_HEIGHT
            )
        }
        if (_.isFinite(structureInfo.layout.width)) {
            structureInfo.layout.width = enforceRange(
                structureInfo.layout.width,
                siteConstants.COMP_SIZE.MIN_WIDTH,
                siteConstants.COMP_SIZE.MAX_WIDTH
            )
        }
    }

    if (compStructure.dataQuery && !structureInfo.dataItem) {
        structureInfo.dataItem = layoutAPI.getDataByQuery(compStructure.dataQuery, pageId)
    }

    if (compStructure.designQuery) {
        structureInfo.designDataItem = layoutAPI.getDataByQuery(
            compStructure.designQuery,
            pageId,
            layoutAPI.dataTypes.DESIGN
        )
    }

    return structureInfo
}

function isCollapsed(compId, measureMap) {
    return Boolean(measureMap.collapsed[compId])
}

function fix(patchers, nodesMap, changedCompsStructures, measureMap, pageId, layoutAPI) {
    const createPatcher =
        (...args) =>
        structureInfo =>
            // @ts-ignore
            singleCompLayout.patchComponent(structureInfo, ...args)

    const patchComponent = createPatcher(patchers, nodesMap, measureMap, layoutAPI)

    const getComponentStructureInfoByCompId = compId =>
        getComponentStructureInfo(changedCompsStructures[compId], layoutAPI, pageId)

    _(changedCompsStructures)
        .keys()
        .filter(compId => !isCollapsed(compId, measureMap) && nodesMap[compId])
        .map(getComponentStructureInfoByCompId)
        .forEach(patchComponent)
}

function patchAllStructures(structuresDesc, measureMap, nodesMap, changedCompsStructures, layoutAPI) {
    const patchers = createDOMPatchers(nodesMap)

    _.forEach(structuresDesc, (structureDesc, name) => {
        fix(patchers, nodesMap, changedCompsStructures[name], measureMap, structureDesc.pageId, layoutAPI)
    })
}

function measureStructure(structure, getDomNodeFunc, measureMap, nodesMap, pageId, compsToMeasure, layoutAPI) {
    const $domNode = $(getDomNodeFunc(structure.id))
    if ($domNode.attr('data-leaving')) {
        return
    }
    if (classBasedMeasureChildrenFirst[structure.componentType]) {
        measureStructureChildrenFirst(
            structure,
            getDomNodeFunc,
            measureMap,
            nodesMap,
            pageId,
            compsToMeasure,
            layoutAPI
        )
    } else {
        measureStructureChildrenLast(structure, getDomNodeFunc, measureMap, nodesMap, pageId, compsToMeasure, layoutAPI)
    }
    measureMap.isHidden[structure.id] = !!$domNode.attr('data-hidden')
}

function measureStructureChildrenFirst(
    structure,
    getDomNodeFunc,
    measureMap,
    nodesMap,
    pageId,
    compsToMeasure,
    layoutAPI
) {
    const structureInfo = getComponentStructureInfo(structure, layoutAPI, pageId)
    addCompNodeToNodesMap(structure, getDomNodeFunc, nodesMap)
    measureContainerChildren(structureInfo, getDomNodeFunc, measureMap, nodesMap, pageId, compsToMeasure, layoutAPI)
    measureComponentItself(structureInfo, getDomNodeFunc, measureMap, nodesMap, structure, compsToMeasure, layoutAPI)
}

function measureStructureChildrenLast(
    structure,
    getDomNodeFunc,
    measureMap,
    nodesMap,
    pageId,
    compsToMeasure,
    layoutAPI
) {
    const structureInfo = getComponentStructureInfo(structure, layoutAPI, pageId)
    addCompNodeToNodesMap(structure, getDomNodeFunc, nodesMap)
    measureComponentItself(structureInfo, getDomNodeFunc, measureMap, nodesMap, structure, compsToMeasure, layoutAPI)
    measureContainerChildren(structureInfo, getDomNodeFunc, measureMap, nodesMap, pageId, compsToMeasure, layoutAPI)
}

function addCompNodeToNodesMap(structure, getDomNodeFunc, nodesMap) {
    const domNode = getDomNodeFunc(structure.id)
    if (domNode) {
        nodesMap[structure.id] = domNode //we need this before the childrenMeasure, but measureComponent also needs to do this independently...
    }
}

function measureContainerChildren(
    structureInfo,
    getDomNodeFunc,
    measureMap,
    nodesMap,
    pageId,
    compsToMeasure,
    layoutAPI
) {
    const children = dataUtils.getChildrenData(structureInfo.structure, layoutAPI.isMobileView())
    _.forEach(children, function (child) {
        measureStructure(child, getDomNodeFunc, measureMap, nodesMap, pageId, compsToMeasure, layoutAPI)
    })
}

function measureComponentItself(
    structureInfo,
    getDomNodeFunc,
    measureMap,
    nodesMap,
    structure,
    compsToMeasure,
    layoutAPI
) {
    if (shouldSkipComponent(compsToMeasure, structureInfo)) {
        return
    }
    const $domNode = $(getDomNodeFunc(structure.id))
    measureMap.collapsed[structure.id] = !!$domNode.attr('data-collapsed')
    singleCompLayout.measureComponentChildren(structureInfo, getDomNodeFunc, measureMap, nodesMap, layoutAPI)
    singleCompLayout.measureComponent(structure, structureInfo, getDomNodeFunc, measureMap, nodesMap, layoutAPI)
}

function measureAllStructures(structuresDesc, measureMap, nodesMap, compsToMeasure, layoutAPI) {
    _.forOwn(structuresDesc, function (structureDesc) {
        measureStructure(
            structureDesc.structure,
            structureDesc.getDomNodeFunc,
            measureMap,
            nodesMap,
            structureDesc.pageId,
            compsToMeasure,
            layoutAPI
        )
    })
}

function calculateAbsolutePosition(
    structure,
    measureMap,
    nodesMap,
    isMobileView,
    baseTop,
    baseLeft,
    areChildrenShownFixed?
) {
    const compId = structure.id
    areChildrenShownFixed = areChildrenShownFixed || measureMap.fixed[compId]

    if (compId && nodesMap[compId]) {
        const compTop = measureMap.top[compId] || 0
        const compLeft = nodesMap[compId].offsetLeft || 0
        if (measureMap.fixed[compId]) {
            baseTop = compTop
            baseLeft = compLeft
        } else {
            baseTop += compTop
            baseLeft += compLeft
        }

        measureMap.absoluteTop[compId] = baseTop
        measureMap.absoluteLeft[compId] = baseLeft
        if (areChildrenShownFixed) {
            measureMap.shownInFixed[compId] = true
        }
    }
    const children = dataUtils.getChildrenData(structure, isMobileView)

    _.forEach(children, function (child) {
        calculateAbsolutePosition(child, measureMap, nodesMap, isMobileView, baseTop, baseLeft, areChildrenShownFixed)
    })
}

function calculateAbsolutePositionAllStructures(structuresDesc, measureMap, nodesMap, isMobileView) {
    const structureNamesSortSoInnerIsLast = _.sortBy(_.keys(structuresDesc), function (name) {
        return name === 'inner' ? 1 : 0
    })

    _.forEach(structureNamesSortSoInnerIsLast, function (name) {
        calculateAbsolutePosition(
            structuresDesc[name].structure,
            measureMap,
            nodesMap,
            isMobileView,
            name === 'inner' ? measureMap.absoluteTop.SITE_PAGES : 0,
            name === 'inner' ? measureMap.absoluteLeft.SITE_PAGES : 0
        )
    })
}

/**
 *
 * @param structure
 * @param getDomNodeFunc
 * @param isMobileView
 * @param components
 * @param structureAnchors
 * @param compsToRelayoutMap
 * @param parentId
 * @returns {boolean} comp or descendants need relayout
 */
function addCompsThatNeedLayoutOfInnerComps(
    structure,
    getDomNodeFunc,
    isMobileView: boolean,
    components,
    structureAnchors,
    compsToRelayoutMap,
    parentId: string
) {
    const domNode = structure.id && getDomNodeFunc(structure.id)
    if (domNode && singleCompLayout.isComponentDead(domNode)) {
        return false
    }

    const compNeedsRelayout = !compsToRelayoutMap || !!compsToRelayoutMap[structure.id]

    const children = dataUtils.getChildrenData(structure, isMobileView)
    const descendantsNeedRelayout = _.reduce(
        children,
        function (needsRelayout, child) {
            return (
                addCompsThatNeedLayoutOfInnerComps(
                    child,
                    getDomNodeFunc,
                    isMobileView,
                    components,
                    structureAnchors,
                    compsToRelayoutMap,
                    structure.id
                ) || needsRelayout
            )
        },
        false
    )

    const compOrDescendantsNeedRelayout = compNeedsRelayout || descendantsNeedRelayout

    if (domNode && classBasedLayoutInnerCompsFirst[structure.componentType] && compOrDescendantsNeedRelayout) {
        components.push({
            anchorsMap: structureAnchors,
            structure,
            getDomNodeFunc,
            domNode,
            parentId
        })
    }

    return compOrDescendantsNeedRelayout
}

function runLayoutOfInnerComps(
    components,
    measureMap,
    patchers,
    nodesMap,
    lockedCompsMap,
    skipEnforceAnchors,
    compsToRelayoutMap,
    layoutAPI
) {
    const compsToLayoutAgain = []
    const changedComps = {}
    const isMobileView = layoutAPI.isMobileView()

    _.forEach(components, function (compInfo) {
        const structureInfo = getComponentStructureInfo(compInfo.structure, layoutAPI)
        const measureResult = classBasedLayoutInnerCompsFirst[structureInfo.structure.componentType].measure(
            structureInfo,
            compInfo.domNode,
            measureMap,
            nodesMap,
            layoutAPI,
            function (structure) {
                measureStructure(
                    structure,
                    compInfo.getDomNodeFunc,
                    measureMap,
                    nodesMap,
                    structureInfo.rootId,
                    compsToRelayoutMap,
                    layoutAPI
                )
            },
            function (structure) {
                // This enforceAnchors call handles columns and children of columns. It does not need ignore bottomBottom anchors flag, since the columns and strip containers were created in santa-editor which does not create bottomBottom anchors.
                // Only the old editor creates bottomBottom anchors
                return anchors.enforceAnchors(
                    structure,
                    measureMap,
                    compInfo.anchorsMap,
                    isMobileView,
                    skipEnforceAnchors,
                    lockedCompsMap,
                    compsToRelayoutMap
                )
            }
        )

        if (!measureResult) {
            return
        }

        if (measureResult.needsAdditionalInnerLayout) {
            compsToLayoutAgain.push(compInfo)
        }

        if (measureResult.changedCompsMap) {
            _.assign(changedComps, measureResult.changedCompsMap)
        }

        if (measureResult.needsParent && compsToRelayoutMap) {
            //to solve the SCC1->C1->SCC2->C2 bug when C2 has premeasure, C1 will too, and SCC2 is not in the compsToRelayoutMap, so we have C2 MEASURE without SCC2 MEASURE during the premeasure phase.
            compsToRelayoutMap[compInfo.parentId] = true
        }
    })

    _.forEach(components, function (compInfo) {
        const structureInfo = getComponentStructureInfo(compInfo.structure, layoutAPI)
        if (!compInfo.skipPatch) {
            classBasedLayoutInnerCompsFirst[structureInfo.structure.componentType].patch(
                structureInfo,
                measureMap,
                patchers,
                nodesMap,
                layoutAPI
            )
        }
    })

    return {
        compsToInnerLayoutAgain: compsToLayoutAgain,
        changedComps
    }
}

function runLayoutOfInnerCompsAllStructures(
    structuresDesc,
    measureMap,
    nodesMap,
    lockedCompsMap,
    skipEnforceAnchors,
    compsToRelayoutMap,
    layoutAPI
) {
    const viewMode = layoutAPI.getViewMode()
    let changedComps = {}
    const compsWithInner = []
    _.forOwn(structuresDesc, function (structureDesc) {
        const structureId = _.get(structureDesc, 'structure.id')
        const structureAnchors = _.get(layoutAPI.anchorsMap, [structureId, viewMode])
        addCompsThatNeedLayoutOfInnerComps(
            structureDesc.structure,
            structureDesc.getDomNodeFunc,
            layoutAPI.isMobileView(),
            compsWithInner,
            structureAnchors,
            compsToRelayoutMap,
            null
        )
    })

    let compsThatStillNeedInnerLayout = compsWithInner
    let counter = 0
    const patchers = createDOMPatchers(nodesMap)

    while (compsThatStillNeedInnerLayout.length && counter < 3) {
        const innerLayoutResult = runLayoutOfInnerComps(
            compsThatStillNeedInnerLayout,
            measureMap,
            patchers,
            nodesMap,
            lockedCompsMap,
            skipEnforceAnchors,
            compsToRelayoutMap,
            layoutAPI
        )
        compsThatStillNeedInnerLayout = innerLayoutResult.compsToInnerLayoutAgain
        changedComps = _.assign(changedComps, innerLayoutResult.changedComps)
        counter++
    }

    return changedComps
}

/**
 * @name layout.measureMap
 * @type {{height: Object.<string, number>, width: Object.<string, number>, innerWidth: Object.<string, number>, custom: Object.<string, Object>, containerHeightMargin: Object.<string, number>, minHeight: Object.<string, number>, top: Object.<string, number>}}
 */
let siteRoot

function measureSite(layoutAPI, measureMap) {
    siteRoot = $('#SITE_ROOT')
    const siteOffset = siteRoot.offset() || {top: 0}
    const {clientHeight} = window.document.documentElement
    const deviceScreen = layoutAPI.mobile.getScreenDimensions()

    measureMap.clientWidth = layoutAPI.getBodyClientWidth()
    measureMap.clientHeight = clientHeight
    measureMap.width.screen = layoutAPI.getScreenWidth()
    measureMap.width.site = layoutAPI.getSiteWidth()
    measureMap.height.screen = measureMap.clientHeight
    measureMap.innerHeight.screen = window.innerHeight
    measureMap.innerWidth.screen = window.innerWidth
    measureMap.height.device = deviceScreen.height
    measureMap.width.device = deviceScreen.width
    measureMap.availHeight.device = deviceScreen.availHeight
    measureMap.availWidth.device = deviceScreen.availWidth
    measureMap.devicePixelRatio = layoutAPI.mobile.getDevicePixelRatio()
    measureMap.siteOffsetTop = siteOffset.top
    measureMap.siteMarginBottom = _.parseInt(siteRoot.css('padding-bottom'), 10) || 0
    measureMap.custom.wixTopAdHeight = layoutAPI.getWixTopAdHeight() // insure wixAd measure before other measures.
}

function getEmptyMeasureMap() {
    const measureMap = {
        pageBottomByComponents: {},
        collapsed: {},
        isHidden: {},
        height: {},
        width: {},
        availHeight: {},
        availWidth: {},
        innerWidth: {},
        innerHeight: {},
        custom: {},
        containerHeightMargin: {},
        minHeight: {},
        minWidth: {},
        top: {},
        left: {},
        absoluteTop: {},
        absoluteLeft: {},
        fixed: {},
        shownInFixed: {},
        zIndex: {},
        isDeadComp: {},
        clientWidth: 0,
        skipPatch: {},
        shrinkableContainer: {},
        injectedAnchors: {}
    }

    return measureMap
}

function reuseMeasureMap(measureMap, compsToReset) {
    _.forOwn(compsToReset, function (v, compId) {
        delete measureMap.top[compId]
        delete measureMap.height[compId]
        delete measureMap.zIndex[compId]
    })

    return measureMap
}

function reuseNodesMap(siteId, compsToReset) {
    const cachedMap = cachedNodesMap[siteId] || {}
    return _.omitBy(cachedMap, _.keys(compsToReset))
}

function shouldRunLayout(specificCompsToReLayout, layoutAPI, structureDesc) {
    const SPECIFIC_COMPS_TO_MEASURE_OPTIMIZATION_THRESHOLD = 10
    const viewMode = layoutAPI.getViewMode()
    const compIds = _.keys(specificCompsToReLayout)

    if (!specificCompsToReLayout || compIds.length >= SPECIFIC_COMPS_TO_MEASURE_OPTIMIZATION_THRESHOLD) {
        return true
    }

    const compStructures = _.mapValues(specificCompsToReLayout, (val, compId) =>
        dataUtils.findCompInStructure(structureDesc.structure, viewMode, ({id}) => compId === id)
    )
    const hasCompWithCustomMeasureOrPatcher = () =>
        _.some(
            compStructures,
            ({componentType}) =>
                singleCompLayout.componentHasCustomMeasure(componentType) ||
                singleCompLayout.componentHasCustomPatcher(componentType)
        )

    if (!_.every(compStructures) || hasCompWithCustomMeasureOrPatcher()) {
        return true
    }

    const prevMeasureMap = layoutAPI.measureMap
    const measureMap = getEmptyMeasureMap()
    const {getDomNodeFunc} = structureDesc
    const {pageId} = structureDesc
    const nodesMap = reuseNodesMap(layoutAPI.siteId, specificCompsToReLayout)

    const measureComponent = compId => {
        const structure = compStructures[compId]
        const structureInfo = getComponentStructureInfo(structure, layoutAPI, pageId)
        addCompNodeToNodesMap(structure, getDomNodeFunc, nodesMap)
        measureComponentItself(
            structureInfo,
            getDomNodeFunc,
            measureMap,
            nodesMap,
            structure,
            {[compId]: true},
            layoutAPI
        )
    }

    const areMeasuredCompsEqual = (previousMeasureMap, currentMeasureMap) => {
        const attributesToCompare = ['top', 'height', 'width', 'left']
        const res = _.every(compIds, compId =>
            _.every(attributesToCompare, attr => previousMeasureMap[attr][compId] === currentMeasureMap[attr][compId])
        )
        return res
    }

    _.forEach(specificCompsToReLayout, (val, compId) => {
        measureComponent(compId)
    })

    return !areMeasuredCompsEqual(prevMeasureMap, measureMap)
}

function getAdditionalCompsToReLayoutForComp(compStructureHierarchy, isMobileView: boolean) {
    let result = null
    const compStructure: any = _.last(compStructureHierarchy)

    if (compStructure && compStructureHierarchy.length > 1) {
        const parentStructure = _.get(compStructureHierarchy, compStructureHierarchy.length - 2)
        if (parentStructure.componentType === 'wysiwyg.viewer.components.Group') {
            result = {}
            result[parentStructure.id] = true
        }
    }

    if (compStructure && classBasedRelatedCompsToRelayout[compStructure.componentType]) {
        return _.defaults(
            {},
            result,
            classBasedRelatedCompsToRelayout[compStructure.componentType](compStructureHierarchy, isMobileView)
        )
    }

    return result
}

function getAdditionalCompsToReLayout(structureHierarchy, renderedCompsMap, isMobileView: boolean) {
    const structure: any = _.last(structureHierarchy)

    const additionalComps = {}
    if (renderedCompsMap[structure.id]) {
        _.assign(additionalComps, getAdditionalCompsToReLayoutForComp(structureHierarchy, isMobileView))
    }

    const compChildren = dataUtils.getChildrenData(structure, isMobileView)
    _.forEach(compChildren, function (childStructure) {
        _.assign(
            additionalComps,
            getAdditionalCompsToReLayout(structureHierarchy.concat([childStructure]), renderedCompsMap, isMobileView)
        )
    })

    return additionalComps
}

function getAdditionalCompsToReLayoutAllStructures(renderedCompsMap, structuresDesc, isMobileView: boolean) {
    const additionalComps = {}
    _.forOwn(structuresDesc, function (structureDesc) {
        _.assign(
            additionalComps,
            getAdditionalCompsToReLayout([structureDesc.structure], renderedCompsMap, isMobileView)
        )
    })
    return additionalComps
}

function getCompsToReLayout(
    structuresDesc,
    specificCompsToReLayout,
    innerLayoutModifiedComps,
    renderedRootIds,
    isMobileView: boolean
) {
    const compsToReLayout = {}

    _.assign(
        compsToReLayout,
        specificCompsToReLayout,
        innerLayoutModifiedComps,
        anchors.HARD_WIRED_COMPS_TO_RELAYOUT,
        arrayUtils.toTrueObj(renderedRootIds)
    )

    _.assign(compsToReLayout, getAdditionalCompsToReLayoutAllStructures(compsToReLayout, structuresDesc, isMobileView))

    return compsToReLayout
}

function getReLayoutedCompsMap(changedCompsStructures) {
    const changedCompsIds = _(changedCompsStructures).values().flatMap(_.keys).value()
    return arrayUtils.toTrueObj(changedCompsIds)
}

function getFlatStructures(structuresDesc, measureMap, isMobile: boolean) {
    const createFlatStructureMap = ({structure}) => {
        const reducer = (acc, comp) => {
            if (!_.has(measureMap.height, comp.id)) {
                return acc
            }

            return _.assign(
                {
                    [comp.id]: comp
                },
                acc,
                _.reduce(dataUtils.getChildrenData(comp, isMobile), reducer, {})
            )
        }

        return reducer({}, structure)
    }

    return _.mapValues(structuresDesc, createFlatStructureMap)
}

function reLayout(
    structuresDesc,
    noEnforceAnchors,
    lockedCompsMap,
    specificCompsToReLayout,
    layoutAPI,
    layoutDoneCallback = _.noop
) {
    let measureMap
    let nodesMap
    let addressLinksPatcher
    let flatStructuresToPatch
    let shouldPerformLayout = true

    if (layoutAPI.renderFlags.enablePageAutoGrowForDetachedComponents === false) {
        lockedCompsMap = lockedCompsMap || {}
        lockedCompsMap.SITE_FOOTER = true
        lockedCompsMap.PAGES_CONTAINER = true
    }

    layoutAPI.measure(() => {
        warmupUtils.loggingUtils.performance.start(PERFORMANCE_NAME)

        // https://jira.wixpress.com/browse/WEED-14770
        // https://gist.github.com/paulirish/5d52fb081b3570c81e3a
        // force browser reflow by getting body.offsetWidth
        window.document.body.offsetWidth // eslint-disable-line @typescript-eslint/no-unused-expressions

        const isMobileView = layoutAPI.isMobileView()

        if (experiment.isOpen('bv_forceMesh') || (layoutAPI.isMesh && layoutAPI.isViewerMode() && isBolt())) {
            shouldPerformLayout = shouldRunLayout(specificCompsToReLayout, layoutAPI, structuresDesc.inner)
            if (!shouldPerformLayout) {
                return
            }
            specificCompsToReLayout = null
        }

        delete structuresDesc.undefined

        nodesMap = reuseNodesMap(layoutAPI.siteId, specificCompsToReLayout)

        measureMap = specificCompsToReLayout
            ? reuseMeasureMap(
                  layoutAPI.measureMap,
                  _.assign(specificCompsToReLayout, anchors.HARD_WIRED_COMPS_TO_RELAYOUT)
              )
            : getEmptyMeasureMap()

        measureSite(layoutAPI, measureMap)
        const innerLayoutModifiedComps = runLayoutOfInnerCompsAllStructures(
            structuresDesc,
            measureMap,
            nodesMap,
            lockedCompsMap,
            noEnforceAnchors,
            specificCompsToReLayout,
            layoutAPI
        )

        if (specificCompsToReLayout) {
            specificCompsToReLayout = getCompsToReLayout(
                structuresDesc,
                specificCompsToReLayout,
                innerLayoutModifiedComps,
                layoutAPI.getAllRenderedRootIds(),
                isMobileView
            )
        }

        measureAllStructures(structuresDesc, measureMap, nodesMap, specificCompsToReLayout, layoutAPI)
        addMeasuresForExternalComponents(measureMap, structuresDesc /*, nodesMap*/)
        addressLinksPatcher = getAddressLinksPatcher(layoutAPI)
        const shouldEnforce = layoutAPI.getLayoutMechanism() === siteConstants.LAYOUT_MECHANISMS.ANCHORS
        flatStructuresToPatch = shouldEnforce
            ? layoutAlgorithm.enforceAllStructures(
                  structuresDesc,
                  measureMap,
                  layoutAPI,
                  noEnforceAnchors,
                  lockedCompsMap,
                  specificCompsToReLayout
              )
            : getFlatStructures(structuresDesc, measureMap, isMobileView)

        calculateAbsolutePositionAllStructures(structuresDesc, measureMap, nodesMap, isMobileView)
    })
    if (!shouldPerformLayout) {
        const result = {measureMap, reLayoutedCompsMap: {}}
        iframesPatcher.setIframesSrc(layoutAPI) // updates iframes even when there's no need for a full relayout (BOLT-2006)
        layoutDoneCallback(result)
        return result
    }
    return layoutAPI.mutate(() => {
        patchAllStructures(structuresDesc, measureMap, nodesMap, flatStructuresToPatch, layoutAPI)

        layoutAPI.imageLoader.loadAllImages(layoutAPI)

        layoutAPI.measureMap = measureMap
        iframesPatcher.setIframesSrc(layoutAPI)
        addressLinksPatcher()

        cachedNodesMap[layoutAPI.siteId] = nodesMap

        warmupUtils.loggingUtils.performance.finish(PERFORMANCE_NAME, layoutAPI.isViewerMode())

        const result = {
            measureMap,
            reLayoutedCompsMap: getReLayoutedCompsMap(flatStructuresToPatch)
        }

        layoutDoneCallback(result)

        return result
    })
}

function addMeasuresForExternalComponents(measureMap, structuresDesc) {
    const pagesStructures = _.filter(_.values(structuresDesc), isPageStructureDesc)
    _.forEach(pagesStructures, pageStructure => {
        const pageId = pageStructure.pageId || pageStructure.inner.pageId
        measureMap.shrinkableContainer[pageId] = true
        measureMap.left[pageId] = 0 // TODO - 12/02/2018 NIRMO - need to find a better way to OVERRIDE the left value for page/popup AFTER structure measure in singleCompLayout :(
    })
}

const isPageStructureDesc = structureDesc =>
    !_.isEmpty(_.get(structureDesc, 'inner.pageId')) || !_.isEmpty(structureDesc.pageId)

function enforceAndPatch(structuresDesc, layoutAPI) {
    const {measureMap} = layoutAPI
    const enforcedComponents = layoutAlgorithm.enforceAllStructures(
        structuresDesc,
        measureMap,
        layoutAPI,
        false,
        null,
        {}
    )
    const nodesMap = cachedNodesMap[layoutAPI.siteId]

    calculateAbsolutePositionAllStructures(structuresDesc, measureMap, nodesMap, layoutAPI.isMobileView())
    patchAllStructures(structuresDesc, measureMap, nodesMap, enforcedComponents, layoutAPI)

    return getReLayoutedCompsMap(enforcedComponents)
}

export interface LayoutPath {
    pathArray: string[]
    type: string
}

export type MeasurePathArray = (string | string[] | LayoutPath)[] | Function

/**
 * @class layout.layout
 */
export default {
    registerLayoutInnerCompsFirst(className, measureFunction, patchFunction) {
        classBasedLayoutInnerCompsFirst[className] = {
            measure: measureFunction,
            patch: patchFunction
        }
    },
    registerMeasureChildrenFirst(className: string, value: boolean) {
        classBasedMeasureChildrenFirst[className] = value
    },

    /**
     * Allows to plugin a patching method to component that needs it
     * @param {string} className The component class name
     * @param {function(string, Object.<string, Element>,  layout.measureMap, layout.structureInfo, core.SiteData)} patcher The patching method
     */
    registerPatcher(
        className: string,
        patcher: (a: string, b: Record<string, Element>, c: any, d: any, e: any) => void
    ) {
        singleCompLayout.registerPatcher(className, patcher)
    },

    /**
     * @param {string} className
     * @param {function(string, Object.<string, Element>, layout.measureMap, layout.structureInfo, core.SiteData)[]}patchersArray
     */
    registerPatchers(className: string, patchersArray) {
        singleCompLayout.registerPatchers(className, patchersArray)
    },

    /**
     * the fix will run after the measure but before enforce anchors.
     * use this if you need to update the comp size according to some inner element size (example in site-button)
     * @param {String} className
     * @param {function(string, layout.measureMap, Object.<string, Element>, core.SiteData, layout.structureInfo)} mapFix
     * a function that runs during measure, you can change only the measure map there
     */
    registerCustomMeasure(className: string, mapFix) {
        singleCompLayout.registerCustomMeasure(className, mapFix)
    },

    registerCustomLayoutFunction(className: string, mapFix) {
        singleCompLayout.registerCustomLayoutFunction(className, mapFix)
    },

    /**
     * @param {string} className
     * @param {function(domNode)} domMutations function - either returns a data object with expected chagnes
     * to be patched - [{node: domNode, type: 'css'/'attr', changes: changes object}]
     * or a callback to be called in the patching phase that receives $ as a parameter
     */
    registerCustomDomChangesFunction: singleCompLayout.registerCustomDomChangesFunction,
    /**
     * this is used in DocumentServices (now only text)
     * @param {string} className
     * @param {function(string, layout.measureMap, Object.<string, Element>, core.SiteData, layout.structureInfo)} measureFunction
     * @param {string} optionalMultiMeasurerId is a unique string to be passed in case you want to hang more than one additional measurer on the same className
     */
    registerAdditionalMeasureFunction(className, measureFunction, optionalMultiMeasurerId) {
        singleCompLayout.registerAdditionalMeasureFunction(className, measureFunction, optionalMultiMeasurerId)
    },

    /**
     * Allows to request to be measured during layout
     * @param className The component class name
     */
    registerRequestToMeasureDom(className) {
        singleCompLayout.registerRequestToMeasureDom(className)
    },

    registerPureDomWidthMeasure: singleCompLayout.registerPureDomWidthMeasure,

    registerPureDomHeightMeasure: singleCompLayout.registerPureDomHeightMeasure,

    unregisterPureDomHeightMeasure: singleCompLayout.unregisterPureDomHeightMeasure,

    /**
     * Allows to request to measure children during layout
     * @param className The component class name
     * @param {(Array.<String>|function)} pathArray An array of children paths (array of strings) to be measured.
     *  This can also be a callback method that returns the pathArray
     */
    registerRequestToMeasureChildren(className: string, pathArray: MeasurePathArray) {
        singleCompLayout.registerRequestToMeasureChildren(className, pathArray)
    },

    registerShapesMeasureFunction(compList, measureFunction) {
        _.forEach(compList, className => singleCompLayout.registerAdditionalMeasureFunction(className, measureFunction))
    },

    reportPresetIframes: iframesPatcher.reportPresetIframes,

    reLayout,

    enforceAndPatch,

    //used in tests
    enforceAnchors: anchors.enforceAnchors
}
