import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'

const SITE_REGION_CONTAINER = 'wysiwyg.viewer.components.SiteRegionContainer'

const MASTER_PAGES_SECTIONS = {
    MOBILE: [
        'wysiwyg.viewer.components.HeaderContainer',
        'wysiwyg.viewer.components.SiteRegionContainer',
        'wysiwyg.viewer.components.PagesContainer',
        'wysiwyg.viewer.components.FooterContainer'
    ],
    DESKTOP: [
        'wysiwyg.viewer.components.HeaderContainer',
        'wysiwyg.viewer.components.PagesContainer',
        'wysiwyg.viewer.components.FooterContainer'
    ]
}

function isMasterPageSection(isMobileView, compStructure) {
    const masterPagesSections = isMobileView ? MASTER_PAGES_SECTIONS.MOBILE : MASTER_PAGES_SECTIONS.DESKTOP

    return _.includes(masterPagesSections, compStructure.componentType)
}

function getMasterPageSectionOrder(isMobileView, compStructure) {
    const masterPagesSections = isMobileView ? MASTER_PAGES_SECTIONS.MOBILE : MASTER_PAGES_SECTIONS.DESKTOP

    return _.indexOf(masterPagesSections, compStructure.componentType)
}

function getMasterPageSections(masterPageStructure, isMobileView) {
    const masterPageChildren = warmupUtilsLib.dataUtils.getChildrenData(masterPageStructure, isMobileView)

    return _(masterPageChildren)
        .filter(isMasterPageSection.bind(null, isMobileView))
        .sortBy(getMasterPageSectionOrder.bind(null, isMobileView))
        .value()
}

function getMasterPageChildrenSegregateByIsSection(masterPageStructure, isMobileView) {
    const masterPageChildren = warmupUtilsLib.dataUtils.getChildrenData(masterPageStructure, isMobileView)
    const segregatedChildren = _.partition(masterPageChildren, isMasterPageSection.bind(null, isMobileView))

    segregatedChildren[0] = _.sortBy(segregatedChildren[0], getMasterPageSectionOrder.bind(null, isMobileView))

    return segregatedChildren
}

function isSiteRegionContainer(component) {
    return component.componentType === SITE_REGION_CONTAINER
}

function hasSiteRegionContainer(masterPageStructure, isMobileView) {
    const masterPageChildren = warmupUtilsLib.dataUtils.getChildrenData(masterPageStructure, isMobileView)

    return _.some(masterPageChildren, isSiteRegionContainer)
}

function isMasterPagesSectionsTight(fullJsonMasterPage, isMobileView) {
    const masterPageSections = getMasterPageSections(fullJsonMasterPage, isMobileView)
    let i

    for (i = 0; i < masterPageSections.length - 1; i++) {
        const currentSection = masterPageSections[i]
        const nextSection = masterPageSections[i + 1]
        const currentSectionBottom = currentSection.layout.y + currentSection.layout.height
        const nextSectionTop = nextSection.layout.y

        if (currentSectionBottom !== nextSectionTop) {
            return false
        }
    }

    return true
}

function shouldApplyMobileTightLayout(fullJsonMasterPage) {
    return hasSiteRegionContainer(fullJsonMasterPage, true) || isMasterPagesSectionsTight(fullJsonMasterPage, true)
}

export default {
    getMasterPageSections,
    getMasterPageChildrenSegregateByIsSection,
    shouldApplyMobileTightLayout,
    MASTER_PAGES_SECTIONS
}
