const DEFINITIONS_TYPES = {
    GETTER: 'GETTER',
    ACTION: 'ACTION',
    DATA_MANIPULATION_ACTION: 'DATA_MANIPULATION_ACTION'
} as const

const METHOD_TYPES = {
    READ: 'read',
    ACTION: 'actions'
} as const

const LOG_TYPES = {
    DATA_MANIPULATION_ACTION: 'Q OPERATION ADDED',
    ACTION: 'IMMEDIATE ACTION'
} as const

const SUPPORT_SCOPES_APIS = new Set([
    'scopes.metaData.loadMetaData',
    'scopes.metaData.getMetaData',
    'components.scopes.load',
    'components.scopes.unload',
    'components.scopes.isLoaded',
    'components.scopes.extractScopeFromPointer',
    'components.scopes.getDefinedScopes',
    'components.scopes.hasDefinedScopes',
    'components.scopes.getRootComponent',
    'components.scopes.getScopeOwner',
    'components.scopes.getTemplateCompPointer',
    'components.scopes.getTemplateRoot',
    'components.scopes.getScopesList',
    'components.scopes.getComponentInScope',
    'components.getAllComponents',
    'components.getAllComponentsFromFull',
    'components.getChildren',
    'components.getChildrenFromFull',
    'components.getContainer',
    'components.getAncestors',
    'components.getRepeatedComponents',
    'components.get.byXYRelativeToStructure',
    'components.get.byXYRelativeToScreen',
    'components.get.getComponentsAtXYConsideringFrame',
    'components.get.byType',
    'components.get.byAncestor'
])

const KNOWN_AFFECTED_BY_SCOPES_APIS = new Set([
    'deprecatedOldBadPerformanceApis.components.getAllComponents',
    'deprecatedOldBadPerformanceApis.components.getAllComponentsFromFull',
    'deprecatedOldBadPerformanceApis.components.getChildren',
    'deprecatedOldBadPerformanceApis.components.getChildrenFromFull',
    'deprecatedOldBadPerformanceApis.components.getContainer',
    'deprecatedOldBadPerformanceApis.components.getAncestors',
    'deprecatedOldBadPerformanceApis.components.getRepeatedComponents',
    'deprecatedOldBadPerformanceApis.components.get.byXYRelativeToStructure',
    'deprecatedOldBadPerformanceApis.components.get.byXYRelativeToScreen',
    'deprecatedOldBadPerformanceApis.components.get.getComponentsAtXYConsideringFrame',
    'deprecatedOldBadPerformanceApis.components.get.byType',
    'deprecatedOldBadPerformanceApis.components.get.byAncestor',
    'appStudio.buildWithOptions',
    'appStudio.widgets.getRootCompId',
    'appStudio.widgets.getRootWidgetByPage',
    'appStudio.widgets.presets.getWidgetPresets',
    'appStudioWidgets.changeVariation',
    'appStudioWidgets.getOverridesWithPrimaryRole',
    'appStudioWidgets.presets.get',
    'appStudioWidgets.props.set',
    'components.add',
    'components.alignment.alignComponents',
    'components.alignment.alignInContainer',
    'components.arrangement.canMoveBackward',
    'components.arrangement.canMoveForward',
    'components.arrangement.getCompIndex',
    'components.code.getNickname',
    'components.code.setNickname',
    'components.data.get',
    'components.data.update',
    'components.design.get',
    'components.design.update',
    'components.duplicate',
    'components.get.byId',
    'components.getA11ySchema',
    'components.getTpaChildren',
    'components.is.a11yConfigurable',
    'components.is.alignable',
    'components.is.containable',
    'components.is.containableByStructure',
    'components.is.duplicatable',
    'components.is.groupable',
    'components.is.groupedComponent',
    'components.is.horizontallyMovable',
    'components.is.horizontallyResizable',
    'components.is.movable',
    'components.is.removable',
    'components.is.resizable',
    'components.is.resizeOnlyProportionally',
    'components.is.verticallyMovable',
    'components.is.verticallyResizable',
    'components.isDescendantOfComp',
    'components.isShowOnAllPages',
    'components.isShowOnSomePages',
    'components.layout.canBeFixedPosition',
    'components.layout.canBeStretched',
    'components.layout.getClosestAncestorRenderedInFixedPosition',
    'components.layout.getProportionStructure',
    'components.layout.getRelativeToScreen',
    'components.layout.getRelativeToStructure',
    'components.layout.getResizableSides',
    'components.layout.getSnugLayoutRelativeToStructure',
    'components.layout.isShowOnFixedPosition',
    'components.layout.runtime.reattachLayoutAndUpdate',
    'components.layout.update',
    'components.layout.updateAndPreserveProportions',
    'components.layout.updateFixedPosition',
    'components.modes.getFirstAncestorActiveModes',
    'components.modes.getFirstAncestorWithActiveModes',
    'components.multiComponents.distribute',
    'components.properties.get',
    'components.properties.mobile.fork',
    'components.properties.update',
    'components.refComponents.getRefHostCompPointer',
    'components.refComponents.openReferredComponent',
    'components.refComponents.unGhostifyComponent',
    'components.responsiveLayout.pin',
    'components.responsiveLayout.unpin',
    'components.serialize',
    'components.setContainer',
    'components.slots.populate',
    'components.slots.remove',
    'documentMode.setComponentViewMode',
    'layouters.canBeMasterChild',
    'layouters.getParentCompWithOverflowHidden',
    'mobile.hiddenComponents.get',
    'mobile.hiddenComponents.hide',
    'mobile.hiddenComponents.show',
    'mobileConversion.resetMobileLayoutOnAllPages',
    'mobileConversion.runMobileMergeOnAllPagesWithoutLayoutOverwrite',
    'mobileConversion.runMobileMergeOnPageById',
    'mobileConversion.syncMobileSite',
    'pages.anchors.getPageAnchors',
    'pages.duplicate',
    'pages.isDuplicable',
    'pages.serialize',
    'platform.controllers.connections.connect',
    'platform.controllers.connections.disconnect',
    'platform.controllers.connections.get',
    'platform.controllers.connections.getConnectedComponents',
    'platform.controllers.connections.getControllerConnections',
    'platform.controllers.connections.getPrimaryConnection',
    'platform.controllers.getConnectedComponentStageData',
    'platform.controllers.getName',
    'platform.controllers.getStageData',
    'platform.controllers.settings.get',
    'platform.controllers.settings.getIn',
    'platform.controllers.settings.set',
    'platform.controllers.settings.setExternalId',
    'platform.livePreview.refresh',
    'publish',
    'save',
    'tpa.app.getAllCompsByApplicationId',
    'tpa.app.getFirstAppCompPageId',
    'tpa.app.getRenderedReactCompsByApplicationId',
    'tpa.app.isLastAppComp',
    'tpa.app.isMultiSectionInstalled',
    'tpa.app.shouldDeleteWholeApp',
    'tpa.change.trigger.settingsUpdated',
    'tpa.getPremiumTpaChildren',
    'tpa.getPremiumTpaChildrenOnPage',
    'tpa.getTpaPointersRecursive',
    'tpa.handlers',
    'tpa.section.getSectionRefByPageId',
    'tpa.widget.getPagesToBeDeleted',
    'viewMode.set',
    'wixCode.getCompSdkType'
])

export {DEFINITIONS_TYPES, METHOD_TYPES, LOG_TYPES, SUPPORT_SCOPES_APIS, KNOWN_AFFECTED_BY_SCOPES_APIS}
