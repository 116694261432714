import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'

const {constants} = warmupUtilsLib
const {PAGE_DATA_DATA_TYPES} = constants
const POINTER_TYPE = 'referredStructure'
const REF_DELIMETER = '_r_'

pointerGeneratorsRegistry.registerPointerType(POINTER_TYPE, _.constant(null), _.constant(true))

const getPageIdOfComponent = (cache, compPointer) => {
    const compPath = cache.getPath(compPointer)
    if (!compPath) {
        return null
    }

    const pageId = compPath[1]
    return pageId
}

const getPageDataPath = (pageId, itemType) => ['pagesData', pageId, 'data', itemType]

const getOverridePrefix = id => `${id}${REF_DELIMETER}`

const getOverridesByType = (getItemAt, pageId, compPtr, itemType) => {
    const overridePrefix = getOverridePrefix(compPtr.id)
    const items = getItemAt(getPageDataPath(pageId, PAGE_DATA_DATA_TYPES[itemType]))
    return _(items)
        .keys()
        .filter(id => _.startsWith(id, overridePrefix))
        .map(id => ({id, type: itemType}))
        .value()
}

const getterFunctions = {
    getAllOverrides(getItemAt, cache, compPtr) {
        const pageId = getPageIdOfComponent(cache, compPtr)

        return _(PAGE_DATA_DATA_TYPES)
            .keys()
            .reduce(
                (result, dataType) => _.concat(result, getOverridesByType(getItemAt, pageId, compPtr, dataType)),
                []
            )
    },
    getConnectionOverrides() {
        return []
    },
    getPointerWithoutFallbacks(getItemAt, cache, pointer) {
        return pointer
    },
    getInternallyReferredComponents: _.noop,
    getGhostRefComponents: _.noop
}

pointerGeneratorsRegistry.registerDataAccessPointersGenerator(POINTER_TYPE, getterFunctions)
