import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import metaDataUtils from '../metaDataUtils'
import constants from '../../tpa/constants'
import baseTpaMetaData from './baseTpaMetaData'
import dsUtils from '../../utils/utils'

function getWidgetData(ps, compData) {
    if (!_.has(compData, 'applicationId')) {
        return {}
    }
    const clientSpecMapPtr = ps.pointers.general.getClientSpecMap()
    const csm = ps.dal.getNoClone(clientSpecMapPtr)
    const appData = _.get(csm, compData.applicationId)
    const result = _.get(appData, ['widgets', compData.widgetId])
    return _.cloneDeep(result)
}

function getCompData(component, ps, pageId) {
    const dataQuery = dsUtils.stripHashIfExists(component.dataQuery)
    const dataPointer = ps.pointers.data.getDataItem(dataQuery, pageId)
    return ps.dal.get(dataPointer)
}

export default {
    removable: false,
    duplicatable: false,
    isRepeatable: false,
    isPublicContainer: false,
    layoutLimits: metaDataUtils.getControllerLayoutLimits,
    mobileConversionConfig: {
        nestOverlayingSiblings: false,
        convertFixedPositionToAbsolute: true,
        stretchHorizontally(ps, component, pageId) {
            const compData = getCompData(component, ps, pageId)
            const widgetData = getWidgetData(ps, compData)
            return _.get(widgetData, 'shouldBeStretchedByDefaultMobile') === true
        },
        minHeight(ps, component, pageId) {
            const compData = getCompData(component, ps, pageId)
            const widgetData = getWidgetData(ps, compData)
            return _.get(widgetData, 'componentFields.minHeightInMobile')
        }
    },
    crossSiteDuplicatable: false,
    containable(ps, compPointer, containerPointer) {
        const isContainable = _.get(ps.runtimeConfig, ['tpa', 'section', 'defaults', 'isContainable'], false)
        if (!isContainable) {
            return false
        }

        const targetPagePointer = ps.pointers.components.getPageOfComponent(containerPointer)
        if (targetPagePointer.id === 'masterPage') {
            return false
        }

        const targetPageChildrenComps = ps.pointers.components.getChildren(targetPagePointer)
        const hasTPASection = _.some(targetPageChildrenComps, function (childComp) {
            if (_.isEqual(childComp, compPointer)) {
                return false
            }

            const componentType = metaDataUtils.getComponentType(ps, childComp)
            return (
                componentType === constants.COMP_TYPES.TPA_SECTION ||
                componentType === constants.COMP_TYPES.TPA_MULTI_SECTION
            )
        })

        const pageData = dataModel.getDataItem(ps, targetPagePointer)
        const isCompOnAppPage =
            pageData.tpaApplicationId &&
            dataModel.getDataItem(ps, compPointer).applicationId === pageData.tpaApplicationId.toString()

        return !hasTPASection && isCompOnAppPage
    },
    canBeStretched(ps, compPointer) {
        const compData = dataModel.getDataItem(ps, compPointer)
        const widgetData = getWidgetData(ps, compData)
        return _.get(widgetData, 'canBeStretched') === true
    },
    getWidgetData,
    rotatable(ps, compPointer) {
        return baseTpaMetaData.rotatable(ps, compPointer)
    },
    canBeFixedPosition(ps, compPointer) {
        return baseTpaMetaData.canBeFixedPosition(ps, compPointer)
    },
    resizable(ps, compRef) {
        return baseTpaMetaData.resizable(ps, compRef)
    },
    canReparent(ps, compRef) {
        return baseTpaMetaData.canReparent(ps, compRef)
    }
}
