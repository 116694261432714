import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import clientSpecMapService from './clientSpecMapService'
import dataManipulation from '../../siteMetadata/dataManipulation'

let dismissAppDefIds: string[] = []
let appsDataToAdd = []
let newPendingApps = []

const getPendingApps = function (ps: PS) {
    return clientSpecMapService.getAppsDataWithPredicate(ps, csm =>
        _.filter(csm, function (appData) {
            return isPending(ps, appData) && appWasNotDismissed(appData) && appWasNotAddedFromPendingList(appData)
        })
    )
}

const appWasNotDismissed = function (appData) {
    if (_.has(appData, 'appDefId')) {
        return !_.includes(dismissAppDefIds, appData.appDefId)
    }
    return !_.includes(dismissAppDefIds, appData.appDefinitionId)
}

const appWasNotAddedFromPendingList = function (appData) {
    if (_.has(appData, 'appDefId')) {
        return !_.some(appsDataToAdd, {appDefinitionId: appData.appDefId})
    }
    return !_.some(appsDataToAdd, {appDefinitionId: appData.appDefinitionId})
}

const getPendingAppsCount = function (ps: PS) {
    const pending = getPendingApps(ps)

    return _.size(pending)
}

const isPendingAppData = appData => {
    return appData.requiresEditorComponent && clientSpecMapService.isHybridAppFromAppData(appData)
}

const isPending = function (ps: PS, appData) {
    if (ps && appData) {
        return appData.requiresEditorComponent && clientSpecMapService.isHybridApp(ps, appData.applicationId)
    } else if (_.isUndefined(ps) && appData) {
        return isPendingAppData(appData)
    }
    return false
}

const isPremiumPendingApp = function (ps, applicationId) {
    const premiumPendingApps = dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.PENDING_APPS) || []
    return _.some(
        premiumPendingApps,
        app => _.has(app, 'appDefId') && app.reason === 'Premium' && app.applicationId === applicationId
    )
}

const getPremiumPendingApps = function (ps: PS) {
    const appsData = clientSpecMapService.getAppsData(ps)
    const appDefsInSpecMap = _.map(appsData, 'appDefinitionId')
    const premiumPendingApps = dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.PENDING_APPS) || []
    const premiumPendingAppsAfterValidation = _.filter(
        premiumPendingApps,
        app => _.has(app, 'appDefId') && _.has(app, 'vendorProductId')
    )

    return _.filter(premiumPendingAppsAfterValidation, app => !_.includes(appDefsInSpecMap, app.appDefId))
}

const getPendingAppsFromSiteMetaData = function (ps: PS) {
    const pendingAppsFromMetaSite = dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.PENDING_APPS) || []
    const allPendingApps = pendingAppsFromMetaSite.slice(0).concat(newPendingApps)
    const uniqueAppList = _.uniqBy(allPendingApps, 'appDefId')

    return _.filter(uniqueAppList, function (appData) {
        return appWasNotDismissed(appData) && appWasNotAddedFromPendingList(appData)
    })
}

const dismiss = function (ps: PS, appDefId) {
    if (_.some(appsDataToAdd, {appDefinitionId: appDefId})) {
        return
    }
    if (!_.includes(dismissAppDefIds, appDefId)) {
        dismissAppDefIds.push(appDefId)
    }
    newPendingApps = _.reject(newPendingApps, {appDefId})
}

const add = function (appData) {
    appsDataToAdd.push(appData)
    if (_.includes(dismissAppDefIds, appData.appDefinitionId)) {
        dismissAppDefIds = _.without(dismissAppDefIds, appData.appDefinitionId)
    }
}

const addPendingDashboardApp = function (ps: PS, appDefId) {
    if (_.isEmpty(appDefId)) {
        return
    }
    const app = {
        appDefId
    }
    newPendingApps.push(app)

    dismissAppDefIds = _.without(dismissAppDefIds, appDefId)
}

const getAppsToDismiss = function () {
    return dismissAppDefIds
}

const getAppsToAdd = function () {
    return appsDataToAdd
}

const onSave = function () {
    dismissAppDefIds = []
    appsDataToAdd = []
}

export default {
    //@deprecated
    getPendingApps,
    getPendingAppsFromSiteMetaData,
    getPendingAppsCount,
    getPremiumPendingApps,
    isPending,
    isPendingAppData,
    dismiss,
    add,
    addPendingDashboardApp,
    getAppsToDismiss,
    getAppsToAdd,
    isPremiumPendingApp,
    onSave
}
