import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {CompRef, ScopeMetaDataTemplate} from '@wix/document-services-types'
import _ from 'lodash'
import {VIEW_MODES} from '../../constants/constants'
import type {StructureMetaDataExtensionAPI} from '../structureMetaData'
import {
    getAllWidgets,
    getBlocksData,
    getData,
    getRootCompIdByPointer,
    getContainingWidgetsMap,
    getWidgetPointerByWidgetId,
    getRootWidgetByPage
} from './blocksDataModel'
import {getWidgetManifest} from './manifestUtils'
import type {WidgetInfo} from './types'
import {createPublicDescriptor} from './widgetDescriptors'

export interface BlocksAPI {
    getWidgetsMapForBuild(): Record<string, WidgetInfo>
    getBlocksData(): any
    getWidgetMetaDataTemplate(appDefinitionId: string, widgetPageId: string): ScopeMetaDataTemplate | undefined
}

export type BlocksExtensionAPI = ExtensionAPI & {
    blocks: BlocksAPI
}

const getMetaDataTemplateWithAppId = (
    metaDataTemplate: ScopeMetaDataTemplate,
    appDefinitionId: string
): ScopeMetaDataTemplate => ({
    ...metaDataTemplate,
    platform: metaDataTemplate.platform
        ? {
              ...metaDataTemplate.platform,
              appDefinitionId,
              controllerType: `${appDefinitionId}-${metaDataTemplate.platform.controllerType}`
          }
        : undefined
})

const createExtension = (): Extension => {
    const createExtensionAPI = (createExtArgs: CreateExtArgs): BlocksExtensionAPI => {
        const getWidgetsMapForBuild = () => {
            const allWidgets = getAllWidgets(createExtArgs)
            const containingWidgetsMap = _.mapValues(getContainingWidgetsMap(createExtArgs), (widgetIds: string[]) =>
                widgetIds.map(widgetId => {
                    const pointer = getWidgetPointerByWidgetId(createExtArgs, widgetId)
                    return getData(createExtArgs, pointer).devCenterWidgetId
                })
            )
            return _.reduce(
                allWidgets,
                (accumulator, {pointer, variations, name}) => {
                    const widgetRootCompId = getRootCompIdByPointer(createExtArgs, pointer)
                    const variationRootCompIds = _.map(variations, variationId => {
                        const variationPointer = createExtArgs.pointers.data.getDataItemFromMaster(
                            _.replace(variationId, '#', '')
                        )
                        return getRootCompIdByPointer(createExtArgs, variationPointer)
                    })
                    const manifestInfo = getWidgetManifest(createExtArgs, pointer)
                    const widgetPublicDescriptor = createPublicDescriptor(createExtArgs, pointer)
                    accumulator[widgetRootCompId!] = {
                        widgetId: widgetRootCompId,
                        variations: variationRootCompIds,
                        manifestInfo,
                        widgetPublicDescriptor,
                        name,
                        nestedWidgetsIds: containingWidgetsMap[pointer.id] || []
                    }
                    return accumulator
                },
                {}
            )
        }

        const getWidgetMetaDataTemplate = (
            appDefinitionId: string,
            widgetPageId: string
        ): ScopeMetaDataTemplate | undefined => {
            const {pointers, extensionAPI} = createExtArgs

            const pagePointer = pointers.structure.getPage(widgetPageId, VIEW_MODES.DESKTOP)
            const rootWidget = getRootWidgetByPage(createExtArgs, pagePointer)

            if (!rootWidget) {
                return
            }

            const metaDataTemplate = (
                extensionAPI as StructureMetaDataExtensionAPI
            ).structureMetaData.getMetaDataTemplate(rootWidget as CompRef)

            return getMetaDataTemplateWithAppId(metaDataTemplate, appDefinitionId)
        }

        return {
            blocks: {
                getBlocksData: () => getBlocksData(createExtArgs),
                getWidgetsMapForBuild,
                getWidgetMetaDataTemplate
            }
        }
    }

    return {
        name: 'blocks',
        createExtensionAPI,
        dependencies: new Set(['structure', 'dataModel', 'page', 'nicknames', 'features', 'actionsAndBehaviors'])
    }
}

export {createExtension}
