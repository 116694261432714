const typesWithBreakpointsByDefault = {
    'mobile.core.components.Page': true,
    'responsive.components.HeaderSection': true,
    'responsive.components.FooterSection': true,
    'wysiwyg.viewer.components.MenuContainer': true
}

const defaultBreakpoints = [
    {
        max: 750,
        min: 320,
        type: 'BreakpointRange'
    },
    {
        max: 1000,
        min: 320,
        type: 'BreakpointRange'
    }
]

const createBreakpointsDefinition = (componentType: string) => {
    if (typesWithBreakpointsByDefault[componentType]) {
        return defaultBreakpoints
    }
}

export {createBreakpointsDefinition}
