import dataModel from '../../dataModel/dataModel'
import constants from '../../constants/constants'

export default {
    isRepeatable: false,
    resizableSides(ps, componentPointer) {
        const compData = dataModel.getDataItem(ps, componentPointer)
        const compact = !compData.showStream

        const resizableSides = compact
            ? [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT]
            : [
                  constants.RESIZE_SIDES.TOP,
                  constants.RESIZE_SIDES.LEFT,
                  constants.RESIZE_SIDES.BOTTOM,
                  constants.RESIZE_SIDES.RIGHT
              ]
        return resizableSides
    },
    layoutLimits: {
        minWidth: 280,
        minHeight: 575,
        maxWidth: 500
    }
}
