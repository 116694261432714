import constants from '../../constants/constants'
import metaDataUtils from '../metaDataUtils'

export default {
    isRepeatable: false,
    mobileConversionConfig: {
        isScreenWidth: true
    },
    moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],
    resizableSides: [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM],
    fullWidth: true,
    fullWidthByStructure: true,
    canBeFixedPosition: false,
    containableByStructure: metaDataUtils.containableByFullWidthPopup
}
