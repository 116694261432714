import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import experiment from 'experiment'
import hooks from '../hooks'
import dsUtils from '../../utils/utils'
import createCompDriver from './createCompDriver'
import componentsHooksBundle from 'wix-ui-santa/componentsHooks.bundle'

let externalHooksMap = componentsHooksBundle.default

const EXTERNAL_HOOKS_DEFINITIONS = {
    beforeUpdateProperties: {
        hookName: hooks.HOOKS.PROPERTIES.UPDATE_BEFORE,
        pluginDataType: 'properties'
    },
    beforeUpdateData: {
        hookName: hooks.HOOKS.DATA.UPDATE_BEFORE,
        pluginDataType: 'data'
    },
    beforeUpdateLayout: {
        hookName: hooks.HOOKS.LAYOUT.UPDATE_BEFORE,
        pluginDataType: 'layout'
    }
}

const mutate = objectToBeMutated => objectMutations => _.merge(objectToBeMutated, objectMutations)

const linkExternalPlugin = (hookName: string, pluginDataType) => (ps: PS, compPointer: Pointer, objectToBeMutated) => {
    // @ts-expect-error ?
    if (ps.config.schemaDevMode) {
        //in schemaDevMode, hooks are registered in runtime for each component separately
        return
    }
    const compType = dsUtils.getComponentType(ps, compPointer)
    const externalPlugin = experiment.isOpen('dm_deprecateWixUiSanta')
        ? {}
        : _.get(externalHooksMap, [compType, hookName])

    if (_.isFunction(externalPlugin)) {
        const compDriver = createCompDriver(ps, compPointer, {
            overrides: {
                [pluginDataType]: {
                    update: mutate(objectToBeMutated) // mutate original object, based on hooks implementation
                }
            },
            read: true,
            write: true
        })

        externalPlugin(compDriver, _.cloneDeep(objectToBeMutated))
    }
}

const createHook = (pluginDataType, externalPluginCallback) => (ps: PS, compPointer: Pointer, objectToBeMutated) => {
    if (!_.isFunction(externalPluginCallback)) {
        return
    }
    const compDriver = createCompDriver(ps, compPointer, {
        overrides: {
            [pluginDataType]: {
                update: mutate(objectToBeMutated) // mutate original object, based on hooks implementation
            }
        },
        read: true,
        write: true
    })
    externalPluginCallback(compDriver, _.cloneDeep(objectToBeMutated))
}

const createHookDefinition = (externalHookName: string, externalCallback) => {
    const {hookName, pluginDataType} = EXTERNAL_HOOKS_DEFINITIONS[externalHookName]
    if (!hookName) {
        return
    }
    const hook = createHook(pluginDataType, externalCallback)

    return {
        hookName,
        hook
    }
}

export default {
    overrideExternalHooksMap(v) {
        externalHooksMap = v
    },
    resetExternalHooksMap() {
        externalHooksMap = componentsHooksBundle.default
    },
    definitions: EXTERNAL_HOOKS_DEFINITIONS,
    // NEW IMPLEMENTATION - TO BE FULLY MERGED
    createHookDefinition,

    // when not in devMode, this will run:
    beforeUpdateData: linkExternalPlugin('beforeUpdateData', 'data'),
    beforeUpdateLayout: linkExternalPlugin('beforeUpdateLayout', 'layout'),
    beforeUpdateProperties: linkExternalPlugin('beforeUpdateProperties', 'properties')
}
