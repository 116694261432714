import {ExtensionAPI, pointerUtils, SnapshotDal} from '@wix/document-manager-core'
import * as documentServicesJsonSchemas from '@wix/document-services-json-schemas'
import documentServicesSchemas from 'document-services-schemas'
import experiment from 'experiment-amd'
import _ from 'lodash'
import constants from '../constants/constants'
import cloneWithoutAdditionalProperties from './cloneWithoutAdditionalProperties'

const {getPointer} = pointerUtils

const {
    namespaceMapping: {NAMESPACE_MAPPING}
} = documentServicesJsonSchemas
const {
    services: {schemasService}
} = documentServicesSchemas
const {DESKTOP} = constants.VIEW_MODES

const isNodeRefOverride = (nodeId: string) => _.includes(nodeId, '_r_')
//temporary until we are ready to send the rest of the permanent nodes
const isRemovableNode = (namespace: string, value: {type: string}, id: string) =>
    isNodeRefOverride(id) ||
    (schemasService.isPermanentDataType(namespace, value.type) &&
        (id === 'SITE_STRUCTURE' || value.type === 'AtomicScope'))

const isPageDataItem = (dataMapName: string, value: any) => dataMapName === 'document_data' && value.type === 'Page'

export default (
    diff: Record<string, Record<string, any>>,
    lastSnapshot: SnapshotDal,
    currentSnapshot: SnapshotDal,
    boundExtensionsAPI?: ExtensionAPI
) => {
    const changedData: any = {}
    const deletedData = {}
    const deletedDataForSave = {}
    const changedDataPageIds = new Set<string>()
    const deletedDataPageIds = new Set<string>()
    const deletedDataPageIdsForSave = new Set<string>()
    const shouldFilterOrphanedPageDataItems = experiment.isOpen('dm_dontSendOrphanedPageDataNodes')
    const doesPageExistMap = {}
    const ignoredDeletions: {namespace: string; id: string}[] = []

    _.forEach(NAMESPACE_MAPPING, (dataMapName, namespace) => {
        changedData[dataMapName] = {}
        deletedData[dataMapName] = {}
        deletedDataForSave[dataMapName] = {}

        _.forEach(diff[namespace], (value, id) => {
            if (value === undefined) {
                const prevValue = lastSnapshot.getValue({type: namespace, id})
                const isRemovableByDefault = isRemovableNode(namespace, prevValue, id)
                const isReferencedFn = _.get(boundExtensionsAPI, ['relationships', 'isReferenced'])
                const shouldSendDeletedItem =
                    // pay attention that this is potentially may be a source of bug,
                    // because we check for references on current dm state and not on snapshot
                    (_.isFunction(isReferencedFn) && !isReferencedFn(getPointer(id, namespace))) || isRemovableByDefault
                deletedData[dataMapName][id] = cloneWithoutAdditionalProperties(id, prevValue)
                const {pageId} = prevValue.metaData
                deletedDataPageIds.add(pageId)

                if (shouldSendDeletedItem) {
                    deletedDataForSave[dataMapName][id] = deletedData[dataMapName][id]
                    deletedDataPageIdsForSave.add(pageId)
                } else {
                    ignoredDeletions.push({namespace, id})
                }
            } else {
                const {pageId} = value.metaData
                if (!_.has(doesPageExistMap, pageId)) {
                    //avoid recursively checking snapshots every time
                    doesPageExistMap[pageId] = currentSnapshot.exists({type: DESKTOP, id: pageId})
                }

                const isOrphan = doesPageExistMap[pageId] === false
                if (!isOrphan || !shouldFilterOrphanedPageDataItems) {
                    changedData[dataMapName][id] = cloneWithoutAdditionalProperties(id, value)
                    changedDataPageIds.add(pageId)
                }

                if (isPageDataItem(dataMapName, value)) {
                    changedDataPageIds.add(value.id)
                }
            }
        })
    })

    return {
        changedData,
        deletedData: _.omitBy(deletedData, _.isEmpty),
        deletedDataForSave: _.omitBy(deletedDataForSave, _.isEmpty),
        deletedDataPageIdsForSave: Array.from(deletedDataPageIdsForSave),
        changedDataPageIds: Array.from(changedDataPageIds),
        deletedDataPageIds: Array.from(deletedDataPageIds),
        ignoredDeletions
    }
}
