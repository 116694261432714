import consts from '../../constants/constants'
import dsUtils from '../../utils/utils'

const HeightLayout = Object.freeze({
    MANUAL: 'manual',
    AUTO: 'auto'
})

export default {
    defaultMobileProperties: {
        columnLayout: 'manual'
    },
    mobileConversionConfig: {
        fixedSize(ps, component) {
            return {height: component.layout.height}
        }
    },
    resizableSides(ps, compPointer) {
        const sides = [consts.RESIZE_SIDES.LEFT, consts.RESIZE_SIDES.RIGHT],
            compStructure = ps.dal.get(compPointer),
            page = ps.pointers.components.getPageOfComponent(compPointer),
            pageId = page?.id,
            compPropsPointer = ps.pointers.data.getPropertyItem(
                dsUtils.stripHashIfExists(compStructure.propertyQuery),
                pageId
            ),
            compProps = ps.dal.get(compPropsPointer)

        if (compProps.heightLayout !== HeightLayout.AUTO) {
            sides.push(consts.RESIZE_SIDES.TOP, consts.RESIZE_SIDES.BOTTOM)
        }
        return sides
    }
}
