import {ReportableError} from '@wix/document-manager-utils'
import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import {stringUtils, constants} from '@wix/santa-core-utils'
import * as wixImmutableProxy from '@wix/wix-immutable-proxy'
import dsUtils from '../utils/utils'
import common from './common'
import dataIds from './dataIds'
import draft from './draftData'
import mlUtils from '../utils/multilingual'
import hooks from '../hooks/hooks'
import documentServicesSchemas from 'document-services-schemas'

const {deepClone, referenceCompare} = wixImmutableProxy
const {dataValidators, schemasService} = documentServicesSchemas.services
const {DATA_TYPES} = constants
const IS_PRESET_PATH = ['metaData', 'isPreset']

function addSerializedDataItemToPage(
    ps: PS,
    pageId: string,
    dataItem,
    customId?: string,
    useLanguage?: string
): string {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.data, useLanguage)
}

function addSerializedPropertyItemToPage(ps: PS, pageId: string, dataItem, customId: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.prop)
}

function addSerializedPatternsItemToPage(ps: PS, pageId: string, dataItem, customId: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, 'patterns')
}

function addSerializedStyleItemToPage(ps: PS, pageId: string = 'masterPage', dataItem?, customId?: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.theme)
}

function addSerializedDesignItemToPage(ps: PS, pageId: string, dataItem, customId?: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.design)
}

function addSerializedBehaviorsItemToPage(ps: PS, pageId: string, dataItem, customId: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.behaviors)
}

function addSerializedFeatureItemToPage(ps: PS, pageId: string, dataItem, featureName: string, customId: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, featureName)
}

function addSerializedAnchorDataItemToPage(ps: PS, pageId: string, dataItem, customId: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.anchors)
}

function addSerializedBreakpointItemToPage(ps: PS, pageId: string, dataItem, customId: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.breakpoints)
}

function addSerializedVariantItemToPage(ps: PS, pageId: string, dataItem, customId: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.variants)
}

function addSerializedConnectionsItemToPage(ps: PS, pageId: string, dataItem, customId: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.connections)
}

function addSerializedMobileHintsItemToPage(ps: PS, pageId: string, dataItem, customId: string) {
    return deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, DATA_TYPES.mobileHints)
}

function addDeserializedStyleItemToPage(ps: PS, pageId: string, dataItem, customId: string) {
    return addDeserializedItemToPage(ps, pageId, DATA_TYPES.theme, dataItem, customId)
}

function deserializeDataItemAndAddToDAL(
    ps: PS,
    pageId: string,
    serializedDataItem,
    dataItemId: string,
    itemType: string,
    useLanguage?: string,
    oldToNewIdMap?
): string {
    const deserializedItems = []
    const serializedDataItemAfterPlugin = hooks.executeHookAndUpdateValue(
        ps,
        hooks.HOOKS.DESERIALIZE.BEFORE,
        undefined,
        [itemType, dataItemId],
        serializedDataItem
    )
    const deserializedItemId = deserializeDataItemOriginal(
        ps,
        pageId,
        serializedDataItemAfterPlugin,
        dataItemId,
        itemType,
        deserializedItems,
        useLanguage,
        oldToNewIdMap
    )
    const rootToLeavesOrderedDeserializedDTOs = deserializedItems.reverse()

    setDeserializedItemsToDAL(ps, rootToLeavesOrderedDeserializedDTOs)
    return deserializedItemId
}

function setDeserializedItemsToDAL(ps: PS, deserializedItemDTOs) {
    let compPointer = null
    _.forEach(deserializedItemDTOs, deserializedItemDTO => {
        const {pointer, item} = deserializedItemDTO
        if (!compPointer && pointer.component) {
            compPointer = pointer.component
        }
        pointer.component = compPointer
        ps.dal.set(pointer, item)
    })
}

/**
 * @param {ps} ps
 * @param {string} pageId
 * @param serializedDataItem
 * @param {string} dataItemId
 * @param {string} itemType
 * @param deserializedItems
 * @param {string} [useLanguage] languageCode
 * @param [oldToNewIdMap]
 * @returns {string}
 */
function deserializeDataItemOriginal(
    ps: PS,
    pageId: string,
    serializedDataItem,
    dataItemId: string,
    itemType: string,
    deserializedItems,
    useLanguage?: string,
    oldToNewIdMap?
) {
    let deserializedDataItem = deserializeDataItemInner(
        ps,
        pageId,
        serializedDataItem,
        dataItemId,
        itemType,
        deserializeDataItemOriginal,
        deserializedItems,
        useLanguage,
        oldToNewIdMap
    )

    deserializedDataItem = hooks.executeHookAndUpdateValue(
        ps,
        hooks.HOOKS.DESERIALIZE.AFTER,
        undefined,
        [itemType, dataItemId, oldToNewIdMap],
        deserializedDataItem
    )

    if (_.has(deserializedDataItem, IS_PRESET_PATH)) {
        _.set(deserializedDataItem, IS_PRESET_PATH, false)
    }
    const itemPointer = ps.pointers.data.getItem(itemType, deserializedDataItem.id, pageId)
    itemPointer.useLanguage = useLanguage || ps.dal.get(ps.pointers.multilingual.currentLanguageCode())
    dataValidators.validateDataBySchema(deserializedDataItem, itemType)
    deserializedItems.push({pointer: itemPointer, item: deserializedDataItem})
    return deserializedDataItem.id
}

/**
 * @param {ps} ps
 * @param {any} pageId
 * @param serializedDataItem
 * @param {string} dataItemId
 * @param {string} itemType
 * @param [handleRefItem]
 * @param [deserializedItems]
 * @param [oldToNewIdMap]
 * @param {string} [useLanguage=undefined] language code
 * @return {{id: *}}
 */
function deserializeDataItemInner(
    ps: PS,
    pageId: string,
    serializedDataItem,
    dataItemId: string,
    itemType: string,
    handleRefItem?,
    deserializedItems?,
    useLanguage?: string,
    oldToNewIdMap?
) {
    const itemId = dataItemId || dataIds.generateNewId(itemType)
    let dataItemInDAL = {}
    let deserializedDataItem = {id: itemId}
    const updatedItemPointer = ps.pointers.data.getItem(itemType, itemId, pageId)
    if (ps.dal.isExist(updatedItemPointer)) {
        dataItemInDAL = ps.dal.full.get(updatedItemPointer)
    }

    let translationInDAL = {}
    const translationPointer = {...updatedItemPointer, useLanguage}
    if (ps.dal.isExist(translationPointer)) {
        translationInDAL = ps.dal.full.get(translationPointer)
    }
    serializedDataItem.type = serializedDataItem.type || _.get(dataItemInDAL, 'type')
    const schemaName = serializedDataItem.type
    const hasSchema = schemasService.hasSchemaForDataType(itemType, schemaName)
    if (!hasSchema) {
        throw new ReportableError({
            errorType: 'missingSchemaError',
            message: `missing schema (schemaType: ${itemType} schemaName: ${schemaName}) for the given data item`
        })
    }

    function deserializeProp(key, shouldReuseIds, value) {
        return `#${handleRefItem(
            ps,
            pageId,
            value,
            shouldReuseIds ? value.id : undefined,
            itemType,
            deserializedItems,
            useLanguage,
            oldToNewIdMap
        )}`
    }

    const shouldReuse = (refs, id) =>
        refs.has(id) || !ps.dal.full.isExist(ps.pointers.data.getItem(itemType, id, pageId))

    if (handleRefItem) {
        _.forOwn(serializedDataItem, function (value, key) {
            if (_.isPlainObject(value) && common.isOfType(itemType, schemaName, key, 'ref')) {
                const refs = new Set([
                    dsUtils.stripHashIfExists(dataItemInDAL[key]),
                    dsUtils.stripHashIfExists(translationInDAL[key])
                ])
                deserializedDataItem[key] = deserializeProp(key, shouldReuse(refs, value.id), value)
            } else if (common.isOfType(itemType, schemaName, key, 'refList')) {
                const refs = new Set(
                    _.flatMap([dataItemInDAL[key], translationInDAL[key]], refList =>
                        _.map(refList, ref => dsUtils.stripHashIfExists(ref))
                    )
                )
                deserializedDataItem[key] = _.map(value, v => deserializeProp(key, shouldReuse(refs, v.id), v))
            } else if (key !== 'id') {
                deserializedDataItem[key] = value
            }
        })
    } else {
        deserializedDataItem = _.defaults(deserializedDataItem, serializedDataItem)
    }

    if (shouldMergeDataItems(dataItemInDAL, deserializedDataItem)) {
        deserializedDataItem = _.assign(dataItemInDAL, deserializedDataItem)
    }

    common.addDefaultMetaData(deserializedDataItem, itemType)
    const updatedItem = _.omitBy(deserializedDataItem, _.isNil)
    draft.addDraftAnnotations(itemType, updatedItem)
    return updatedItem
}

function addDeserializedItemToPage(ps: PS, pageId: string, itemType: string, dataItem, customId?: string) {
    const deserializedDataItem = deserializeDataItemInner(ps, pageId, dataItem, customId ?? dataItem.id, itemType)
    if (_.has(deserializedDataItem, IS_PRESET_PATH)) {
        _.set(deserializedDataItem, IS_PRESET_PATH, false)
    }
    const itemPointer = ps.pointers.data.getItem(itemType, deserializedDataItem.id, pageId)
    dataValidators.validateDataBySchema(deserializedDataItem, itemType)
    setDeserializedItemsToDAL(ps, [
        {
            pointer: itemPointer,
            item: deserializedDataItem
        }
    ])
    return deserializedDataItem.id
}

function shouldMergeDataItems(existingDataItem, newDataItem) {
    return !existingDataItem.type || !newDataItem.type || _.isEqual(existingDataItem.type, newDataItem.type)
}

function deserializeDataItem(ps: PS, serializedDataItem, itemType: string) {
    const handleRef = (_ps, _pageId, value) => `#${value.id || dataIds.generateNewId(itemType)}`
    return deserializeDataItemInner(ps, null, serializedDataItem, serializedDataItem.id, itemType, handleRef)
}

function serializeDataItem(
    ps: PS,
    dataType: string,
    dataItemPointer: Pointer,
    deleteId?: boolean,
    useOriginalLanguage: boolean = false
) {
    const useLanguage = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
    return serializeDataItemInLang(ps, dataType, dataItemPointer, deleteId, useLanguage)
}

// this is possibly bad for tests, but seems to be a performance improvement that was done for production purposes and was in extensionsAPI
// this can be implemented better by creating an actual extensionAPI function (not pointer function) which will return the (cached in extension) original language
// I did not handle this as part of deleting the extensionsAPI module, I just moved the code here (which should never have been there to begin with)
let originalLanguagePointer: Pointer

const getItemWithMultilingualOverridesInLang = (ps: PS, dataItemPointer: Pointer, useLanguage: string) => {
    originalLanguagePointer = originalLanguagePointer || ps.pointers.multilingual.originalLanguage()
    const originalLanguageCode = _.get(ps.dal.get(originalLanguagePointer), 'languageCode')
    dataItemPointer = {
        ...dataItemPointer,
        useLanguage: originalLanguageCode
    }
    const dataItem = ps.dal.full.getNoClone(dataItemPointer)
    if (dataItem && useLanguage !== originalLanguageCode) {
        dataItemPointer.useLanguage = useLanguage
        const translatedDataItem = ps.dal.full.getNoClone(dataItemPointer)
        if (!referenceCompare(translatedDataItem, dataItem)) {
            return _.defaults(_.omitBy(deepClone(translatedDataItem), _.isNil), deepClone(dataItem))
        }
    }
    return deepClone(dataItem)
}

/**
 *
 * @param {ps} ps
 * @param {string} dataType
 * @param {Pointer} dataItemPointer
 * @param {boolean} deleteId
 * @param {string|undefined} [useLanguage]
 * @returns {any}
 */
function serializeDataItemInLang(
    ps: PS,
    dataType: string,
    dataItemPointer: Pointer,
    deleteId: boolean,
    useLanguage: string
) {
    const serializedItem = getItemWithMultilingualOverridesInLang(ps, dataItemPointer, useLanguage)
    if (!serializedItem) {
        return undefined
    }

    const pageId = ps.pointers.data.getPageIdOfData(dataItemPointer)

    function serializeRef(ref) {
        const pointer = getRefPointer(ps, ref, pageId, dataType)
        return serializeDataItemInLang(ps, dataType, pointer, deleteId, useLanguage)
    }

    function serializeRefList(refList) {
        return _.compact(_.map(refList, ref => serializeRef(ref)))
    }

    if (deleteId) {
        delete serializedItem.id
    }

    const schemaName = serializedItem.type

    _.forOwn(serializedItem, function (value, key) {
        const isRef = common.isOfType(dataType, schemaName, key, 'ref')
        if (value && isRef) {
            serializedItem[key] = serializeRef(value /*, dataItemPointer.type*/)
        } else {
            const isRefList = common.isOfType(dataType, schemaName, key, 'refList')
            if (value && isRefList) {
                serializedItem[key] = serializeRefList(value)
            }
        }
    })

    hooks.executeHook(hooks.HOOKS.SERIALIZE.DATA_ITEM_AFTER, undefined, [ps, serializedItem])

    draft.removeDraftAnnotations(serializedItem)
    return serializedItem
}

const getIdFromValue = (value: string) =>
    _.isString(value) && stringUtils.startsWith(value, '#') && dsUtils.stripHashIfExists(value)

function getRefPointer(ps: PS, value: Pointer, pageId: string, dataType: string) {
    const itemId = _.isObject(value) ? value.id : getIdFromValue(value)
    return ps.pointers.data.getItem(dataType, itemId, pageId)
}

const addSerializedItemToPage = (
    ps: PS,
    pageId: string,
    dataItem: any,
    customId: string,
    dataType: string,
    oldToNewIdMap?: any
) => deserializeDataItemAndAddToDAL(ps, pageId, dataItem, customId, dataType, undefined, oldToNewIdMap)

export default {
    addSerializedItemToPage,
    addSerializedStyleItemToPage,
    addSerializedDataItemToPage,
    addSerializedPropertyItemToPage,
    addSerializedPatternsItemToPage,
    addSerializedDesignItemToPage,
    addSerializedBehaviorsItemToPage,
    addSerializedFeatureItemToPage,
    addSerializedAnchorDataItemToPage,
    addSerializedBreakpointItemToPage,
    addSerializedVariantItemToPage,
    addSerializedConnectionsItemToPage,
    addSerializedMobileHintsItemToPage,

    addDeserializedItemToPage,
    addDeserializedStyleItemToPage,

    deserializeDataItem,
    serializeDataItem,
    serializeDataItemInLang
}
