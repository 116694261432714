import DataAccessPointers from './DataAccessPointers'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'

const pointers = new DataAccessPointers()

const getterFunctions = {
    getModes(getItemAtPath, cache, pointer) {
        return pointers.getInnerPointer(pointer, ['modes'])
    },

    getModesDefinitions(getItemAtPath, cache, pointer) {
        return pointers.getInnerPointer(pointer, ['modes', 'definitions'])
    },

    getModesOverrides(getItemAtPath, cache, pointer) {
        return pointers.getInnerPointer(pointer, ['modes', 'overrides'])
    }
}

pointerGeneratorsRegistry.registerDataAccessPointersGenerator('componentStructure', getterFunctions)
