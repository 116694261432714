import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import type {Experiment} from '@wix/document-services-types'
import {cleanBrokenReferences} from './utils/variantRelationUtils'
import {cleanRef} from './utils/migrationUtil'

const experimentName = 'dm_fixBrokenScopedLayout'

const migratePage = (documentManager: DocumentManager, pageId: string, experimentInstance: Experiment) => {
    if (!experimentInstance.isOpen(experimentName)) {
        return
    }
    const valuesTorefMap = documentManager.pointers.data
        .getLayoutItemsWithPredicate(layoutItem => layoutItem.type === 'VariantRelation', pageId)
        .map(pointer => documentManager.dal.get(pointer))
        .filter(
            relation =>
                relation.variants.length > 1 &&
                relation.variants.every(
                    (ref: string) =>
                        documentManager.dal.get(documentManager.pointers.getPointer(cleanRef(ref), 'variants')).type ===
                        'BreakpointRange'
                )
        )
        .reduce((refMap, relation) => ({...refMap, [relation.id]: relation}), {})
    cleanBrokenReferences(documentManager, 'layout', pageId, valuesTorefMap, _.stubTrue)
}

const name = 'fixBrokenScopedLayout'
const version = 1

export {migratePage, name, version}
export const experimentalVersions = [{version: 1, experiment: experimentName}]
