import {DocumentDataTypes, Extension, PointerMethods, pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils

const pointerType = 'commonConfig'

const createPointersMethods = (): PointerMethods => {
    const getCommonConfig = () => getPointer('commonConfig', 'commonConfig')
    return {
        general: {
            getCommonConfig
        }
    }
}

const getDocumentDataTypes = (): DocumentDataTypes => ({[pointerType]: {}})

const createExtension = (): Extension => ({
    name: 'commonConfig',
    createPointersMethods,
    getDocumentDataTypes
})

export {createExtension}
