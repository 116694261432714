import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'

const jsonUpdaterPlugins = {}
const layouterComps = {}

warmupUtils.sessionFreezer.freeze(jsonUpdaterPlugins)
warmupUtils.sessionFreezer.freeze(layouterComps)

function registerPlugin(compType, callback) {
    jsonUpdaterPlugins[compType] = callback
}

function getCompPlugin(compType) {
    return jsonUpdaterPlugins[compType]
}

function registerLayouterComp(compType, layouter) {
    layouterComps[compType] = layouter
}

function getCompLayouter(compType) {
    return layouterComps[compType]
}

export default {
    registerLayouterComp,
    getCompLayouter,
    getCompPlugin,
    registerPlugin
}
