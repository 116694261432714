import _ from 'lodash'
import wixappsCore from 'wixappsCore'
import dataModel from '../../dataModel/dataModel'
import {linkTypes} from '@wix/santa-ds-libs/src/coreUtils'
import dsUtils from '../../utils/utils'

function convertLinkToWixappsData(wLink) {
    const rawData = {linkId: wLink.id}
    switch (wLink.type) {
        case linkTypes.PAGE_LINK:
            _.assign(rawData, {
                _type: 'wix:PageLink',
                pageId: _.isPlainObject(wLink.pageId) ? wLink.pageId.id : wLink.pageId
            })
            break
        case linkTypes.DYNAMIC_PAGE_LINK:
            const anchorId = _.isPlainObject(wLink.anchorDataId) ? wLink.anchorDataId.id : wLink.anchorDataId
            _.assign(rawData, {
                _type: 'wix:DynamicPageLink',
                routerId: wLink.routerId,
                innerRoute: wLink.innerRoute,
                anchorDataId: anchorId || ''
            })
            break
        case linkTypes.EXTERNAL_LINK:
            let parseUrl = wLink.url.split('://')
            if (parseUrl.length === 1) {
                parseUrl = ['http'].concat(parseUrl)
            }

            _.assign(rawData, {
                _type: 'wix:ExternalLink',
                target: wLink.target,
                protocol: parseUrl[0],
                address: parseUrl[1]
            })
            break
        case linkTypes.DOCUMENT_LINK:
            _.assign(rawData, {
                _type: 'wix:DocLink',
                docId: wLink.docId,
                docName: wLink.name
            })
            break
        case linkTypes.PHONE_LINK:
            _.assign(rawData, {
                _type: 'wix:PhoneLink',
                phoneNumber: wLink.phoneNumber
            })
            break
        case linkTypes.EMAIL_LINK:
            _.assign(rawData, {
                _type: 'wix:MailLink',
                email: wLink.recipient,
                subject: wLink.subject
            })
            break
        case linkTypes.ANCHOR_LINK:
            _.assign(rawData, {
                _type: 'wix:AnchorLink',
                anchorName: wLink.anchorName,
                anchorDataId: _.isPlainObject(wLink.anchorDataId) ? wLink.anchorDataId.id : wLink.anchorDataId,
                pageId: _.isPlainObject(wLink.pageId) ? wLink.pageId.id : wLink.pageId
            })
            break
    }
    return rawData
}

export default {
    convertWLinkToWixappsData: convertLinkToWixappsData,
    convertWixappsDataToWLink(ps, data) {
        return wixappsCore.linksConverter(data, function (dataItemId, pageId) {
            dataItemId = _.isString(dataItemId) && dsUtils.stripHashIfExists(dataItemId)
            pageId = _.isString(pageId) && dsUtils.stripHashIfExists(pageId)
            return dataModel.getDataItemById(ps, dataItemId, pageId)
        })
    }
}
