import * as _ from 'lodash'
import {DeepStructure} from '../../types'

import {conversionConfig} from '../conversionConfig'
import * as conversionUtils from '../conversionUtils'

const BACKGROUND_TO_HAMBURGER_COLOR_MAP = {
    color_11: 'tmFull1',
    color_27: 'tmFull1',
    color_32: 'tmFull1',
    color_15: 'tmFull2',
    color_18: 'tmFull2',
    color_25: 'tmFull2'
}

const DEFAULT_COMP_DATA = {
    type: 'Component',
    id: 'TINY_MENU',
    componentType: 'wysiwyg.viewer.components.mobile.TinyMenu',
    skin: 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin',
    layout: _.merge(conversionUtils.getTinyMenuDefaultPosition(), {
        height: conversionConfig.TINY_MENU_SIZE,
        width: conversionConfig.TINY_MENU_SIZE
    }),
    dataQuery: '#TINY_MENU',
    styleId: 'tmFull1'
}

const DEFAULT_CONTAINER_ID = 'SITE_HEADER'

const ADDITIONAL_STYLES = {
    tmFull1: {
        skin: 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin',
        style: {
            properties: {
                borderwidth: '0',
                bg: 'color_11',
                'alpha-bg': 0,
                txt: 'color_11',
                separatorHeight: '0',
                textAlignment: 'center',
                txts: 'color_18',
                iconcolor: 'color_15',
                iconcolorSelected: 'color_11'
            }
        }
    },
    tmFull2: {
        skin: 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin',
        style: {
            properties: {
                borderwidth: '0',
                bg: 'color_11',
                'alpha-bg': 0,
                txt: 'color_11',
                separatorHeight: '0',
                textAlignment: 'center',
                txts: 'color_18',
                iconcolor: 'color_11',
                iconcolorSelected: 'color_11'
            }
        }
    },
    tm2: {
        skin: 'wysiwyg.viewer.skins.mobile.TinyMenuSkin',
        style: {
            properties: {
                bg: 'color_11',
                bgh: 'color_13',
                bgs: 'color_18',
                txts: 'color_15',
                txt: 'color_15'
            }
        }
    }
}

export const getAdditionalStyles = () => ADDITIONAL_STYLES

export function addToStructure(masterPage: DeepStructure): void {
    const header = <DeepStructure>conversionUtils.getComponentByIdFromStructure(DEFAULT_CONTAINER_ID, masterPage)
    const styleId = _.get(BACKGROUND_TO_HAMBURGER_COLOR_MAP, <string>_.get(header.conversionData, 'backgroundColor'), 'tmFull1')
    const compToAdd = _.defaults({styleId}, DEFAULT_COMP_DATA) as DeepStructure
    conversionUtils.addComponentsTo(header, [compToAdd])
    conversionUtils.ensureContainerTightlyWrapsChildren(header, header.components, true)
}
