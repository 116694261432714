import wixCodeLifecycleService from '../../wixCode/services/wixCodeLifecycleService'
import component from '../../component/component'
import componentCode from '../../component/componentCode'
import type {Pointer, PossibleViewModes, PS} from '@wix/document-services-types'
import constants from '../../constants/constants'

function generateNicknamesForPageOfComponent(ps: PS, compToAddPointer: Pointer) {
    if (wixCodeLifecycleService.isProvisioned(ps)) {
        const pagePointer = component.isPageComponent(ps, compToAddPointer)
            ? compToAddPointer
            : component.getPage(ps, compToAddPointer)
        const viewMode = ps.pointers.components.getViewMode(pagePointer)
        componentCode.generateNicknamesForPages(ps, [pagePointer.id], viewMode)
    }
}

function generateNicknamesForComponents(ps: PS, compsToGenerateNickname: Pointer[], pagePointer: Pointer) {
    const viewMode: PossibleViewModes = ps.pointers.components.getViewMode(pagePointer)
    componentCode.generateNicknamesForComponents(ps, compsToGenerateNickname, pagePointer, viewMode)
}

function generateNicknamesForPages(ps: PS, pages: Pointer[]) {
    if (wixCodeLifecycleService.isProvisioned(ps)) {
        componentCode.generateNicknamesForPages(
            ps,
            pages.map(page => page.id),
            constants.VIEW_MODES.MOBILE
        )
    }
}

function deleteNicknameFromComponentIfInvalid(ps: PS, compPointer: Pointer, containerPointer: Pointer) {
    if (wixCodeLifecycleService.isProvisioned(ps) && component.getType(ps, compPointer) !== 'Page') {
        componentCode.removeNicknameFromComponentIfInvalid(ps, compPointer, containerPointer)
    }
}

function updateConnectionsIfNeeded(ps: PS, compPointer: Pointer, containerPointer: Pointer, compDefinitionPrototype) {
    if (
        wixCodeLifecycleService.isProvisioned(ps) &&
        compDefinitionPrototype.type !== 'Page' &&
        compDefinitionPrototype.connections
    ) {
        componentCode.updateConnectionsIfNeeded(ps, compPointer, containerPointer, compDefinitionPrototype)
    }
}

function updateNicknameContextByNewContainer(
    ps: PS,
    compPointer: Pointer,
    componentDefinition,
    newContainerPointer: Pointer
) {
    if (wixCodeLifecycleService.isProvisioned(ps) && componentDefinition.type !== 'Page') {
        componentCode.updateNicknameContextByNewContainer(ps, compPointer, componentDefinition, newContainerPointer)
    }
}

function setOriginalContextToSerializedComponent(ps: PS, compPointer: Pointer, customStructureData) {
    componentCode.setOriginalContextToSerializedComponent(ps, compPointer, customStructureData)
}

export default {
    generateNicknamesForPages,
    generateNicknamesForComponents,
    generateNicknamesForPageOfComponent,
    updateConnectionsIfNeeded,
    deleteNicknameFromComponentIfInvalid,
    updateNicknameContextByNewContainer,
    setOriginalContextToSerializedComponent
}
