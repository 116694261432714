import {DeepStructure} from '../../types'
import * as conversionUtils from '../conversionUtils'

const DEFAULT_COMP_DATA = {
    type: 'Component',
    id: 'BACK_TO_TOP_BUTTON',
    componentType: 'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton',
    skin: 'wysiwyg.viewer.skins.backtotopbutton.BackToTopButtonSkin',
    layout: {
        height: 0,
        width: 0,
        x: 0,
        y: 0
    },
    dataQuery: null,
    styleId: ''
} as DeepStructure

export const addToStructure = (masterPage: DeepStructure): void => conversionUtils.addComponentsTo(masterPage, [DEFAULT_COMP_DATA])
