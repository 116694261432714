import _ from 'lodash'
import {DmApis, DalValue, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import type {KeyframeEffectData, Pointer} from '@wix/document-services-types'
import type {DataModelExtensionAPI} from './dataModel'
import {DATA_TYPES, MASTER_PAGE_ID} from '../constants/constants'
import {getIdFromRef, createInnerRef} from '../utils/dataUtils'

const EFFECTS = DATA_TYPES.effects
const keyframeEffectListPointer = {id: 'KEYFRAME_EFFECT_LIST', type: EFFECTS}
const {getPointer, getInnerPointer} = pointerUtils

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, extensionAPI}: DmApis): ExtensionAPI => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI

        const getKeyframeEffectList = (): DalValue => {
            return dal.get(keyframeEffectListPointer)
        }

        const getAllKeyframeEffectPointers = (): Pointer[] => {
            const idsList = getKeyframeEffectList()?.values ?? []
            return _.map(idsList, id => getPointer(getIdFromRef(id), EFFECTS))
        }

        const addKeyframeEffect = (keyframeEffectPointer: Pointer, keyframeEffectData: KeyframeEffectData): Pointer => {
            dataModel.addItem(keyframeEffectData, EFFECTS, MASTER_PAGE_ID, keyframeEffectPointer.id)
            if (!dal.has(keyframeEffectListPointer)) {
                dataModel.addItem(
                    {
                        type: 'KeyframeEffectList',
                        values: []
                    },
                    EFFECTS,
                    MASTER_PAGE_ID,
                    'KEYFRAME_EFFECT_LIST'
                )
            }
            const keyframeEffectListDataItem = getKeyframeEffectList()
            dal.set(getInnerPointer(keyframeEffectListPointer, 'values'), [
                ...keyframeEffectListDataItem.values,
                createInnerRef(keyframeEffectPointer.id)
            ])
            return keyframeEffectPointer
        }

        const getKeyframeEffect = (pointer: Pointer): DalValue => {
            return dataModel.getItem(pointer.id, EFFECTS, MASTER_PAGE_ID)
        }

        const getAllEffectsPointers = (componentPointer: Pointer): Pointer[] => {
            const effectsList = dataModel.components.getItem(componentPointer, EFFECTS).values
            return _.map(effectsList, ({id}) => getPointer(getIdFromRef(id), EFFECTS))
        }

        const updateKeyframeEffect = (
            keyframeEffectPointer: Pointer,
            newKeyframeEffectData: KeyframeEffectData
        ): void => {
            dataModel.addItem(newKeyframeEffectData, EFFECTS, MASTER_PAGE_ID, keyframeEffectPointer.id)
        }

        const removeKeyframeEffect = (keyframeEffectPointer: Pointer): void => {
            dataModel.removeItemRecursively(keyframeEffectPointer)
            const keyframeEffectListDataItem = getKeyframeEffectList()
            const keyframeEffectId = `#${keyframeEffectPointer.id}`
            const updatedKeyframeEffectList = _.reject(keyframeEffectListDataItem.values, id => id === keyframeEffectId)
            dal.set(getInnerPointer(keyframeEffectListPointer, ['values']), updatedKeyframeEffectList)
        }

        return {
            effects: {
                list: getAllEffectsPointers
            },
            keyframeEffect: {
                add: addKeyframeEffect,
                get: getKeyframeEffect,
                list: getAllKeyframeEffectPointers,
                update: updateKeyframeEffect,
                remove: removeKeyframeEffect
            }
        }
    }

    const createPublicAPI = ({extensionAPI}: DmApis) => {
        const {keyframeEffect} = extensionAPI as EffectsExtensionAPI
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        return {
            keyframeEffect: {
                add: keyframeEffect.add,
                get: keyframeEffect.get,
                list: keyframeEffect.list,
                update: keyframeEffect.update,
                remove: keyframeEffect.remove,
                getKeyframeEffectToAddRef: () => getPointer(dataModel.generateItemIdWithPrefix(EFFECTS), EFFECTS)
            }
        }
    }

    return {
        name: 'effects',
        dependencies: new Set(['dataModel']),
        createExtensionAPI,
        createPublicAPI
    }
}

export interface EffectsExtensionAPI extends ExtensionAPI {
    effects: {
        list(componentPointer: Pointer): Pointer[]
    }
    keyframeEffect: {
        add(pointer: Pointer, keyframeEffectData: KeyframeEffectData): Pointer
        get(pointer: Pointer): DalValue
        list(): Pointer[]
        update(pointer: Pointer, keyframeEffectData: KeyframeEffectData): void
        remove(pointer: Pointer): void
    }
}

export {createExtension}
