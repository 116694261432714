import _ from 'lodash'
import {siteConstants} from '@wix/santa-core-utils'
import {cookieUtils} from '@wix/santa-ds-libs/src/utils'
import siteMetadata from '../siteMetadata/siteMetadata'
import clientSpecMap from '../siteMetadata/clientSpecMap'
import dataModel from '../dataModel/dataModel'
import {ReportableError} from '@wix/document-manager-utils'
import type {Pointer, PS} from '@wix/document-services-types'
import {ajaxLibrary, AjaxOp} from '@wix/santa-ds-libs/src/warmupUtils'

const VENDOR_ID_TO_MODEL_KEY = {
    google: 'socialLoginGoogleEnabled',
    facebook: 'socialLoginFacebookEnabled'
}

const SIGNUP_POLICIES_TO_MODEL_KEY = {
    termsOfUse: 'termsOfUse',
    privacyPolicy: 'privacyPolicy',
    codeOfConduct: 'codeOfConduct'
}

const PRIVACY_NOTE_TYPE = {
    checkbox: 'CHECKBOX',
    note: 'NOTE'
}

const PRIVACY_NOTE_TYPE_KEY = 'privacyNoteType'

const COMMUNITY_SETTINGS_MODEL_KEY = 'joinCommunityCheckedByDefault'

const CUSTOM_SIGNUP_SETTINGS = 'customSignUpPageId'

const CUSTOM_SIGN_IN_SETTINGS = 'customSignInPageId'

const CUSTOM_NO_PERMISSIONS_PAGE = 'customNoPermissionsPageId'

function getMemberSettingsEndpoint(ps: PS) {
    const metaSiteId = siteMetadata.generalInfo.getMetaSiteId(ps)
    return `/_api/member-permissions-server/metasite/${metaSiteId}/siteMemberSettings`
}

function getSiteMembersApp(ps: PS): any {
    return _.head(clientSpecMap.filterAppsDataByType(ps, 'sitemembers'))
}

function getSiteMembersSettingsPointer(ps: PS): Pointer {
    const siteStructureDataPointer = ps.pointers.data.getDataItemFromMaster(siteConstants.MASTER_PAGE_ID)
    return ps.pointers.getInnerPointer(siteStructureDataPointer, 'smSettings')
}

const ajax = (ps: PS, options: AjaxOp) => {
    const onError = options.error
    options.error = (e: any = {}) => {
        if (onError) {
            // @ts-expect-error
            onError(e)
        }
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                message: 'ajaxLibrary.ajax failed',
                errorType: 'HttpError',
                tags: {method: 'ajaxLibrary.ajax'},
                extras: {
                    status: e.status,
                    statusText: e.statusText,
                    url: options.url
                }
            })
        )
    }
    options.headers = {
        'X-XSRF-TOKEN': cookieUtils.getCookie('XSRF-TOKEN')
    }
    options.appIdAutoAuth = '-666'
    ajaxLibrary.ajax(options)
}

function setAutoApproval(ps: PS, autoApproval, onSuccess?, onError?) {
    const onAjaxSuccess = function () {
        const siteMembersApp = getSiteMembersApp(ps)
        siteMembersApp.collectionType = autoApproval ? 'Open' : 'ApplyForMembership'
        clientSpecMap.registerAppData(ps, siteMembersApp)
        if (_.isFunction(onSuccess)) {
            onSuccess()
        }
    }

    ajax(ps, {
        type: 'POST',
        url: getMemberSettingsEndpoint(ps),
        dataType: 'json',
        data: {siteMemberSettings: {autoApprove: autoApproval}},
        success: onAjaxSuccess,
        error: onError || _.noop
    })
}

function isAutoApproval(ps: PS) {
    const siteMembersApp = getSiteMembersApp(ps)
    return siteMembersApp.collectionType === 'Open'
}

function setSmSettingsData(ps: PS, data) {
    const masterPagePointer = ps.pointers.data.getDataItemFromMaster(siteConstants.MASTER_PAGE_ID)
    const masterPageData = ps.dal.get(masterPagePointer)
    masterPageData.smSettings = _.merge(masterPageData.smSettings, data)
    dataModel.addSerializedDataItemToPage(ps, 'masterPage', masterPageData, 'masterPage')
}

function setLoginDialogFirst(ps: PS, loginDialogFirst) {
    setSmSettingsData(ps, {smFirstDialogLogin: !!loginDialogFirst})
}

function isLoginDialogFirst(ps: PS) {
    const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
    return !!smSettings?.smFirstDialogLogin
}

function setSocialLoginVendorStatus(ps: PS, vendor, enabled) {
    const key = VENDOR_ID_TO_MODEL_KEY[vendor]
    if (key) {
        setSmSettingsData(ps, _.set({}, key, !!enabled))
    }
}

function isSocialLoginEnabled(ps: PS, vendor) {
    const key = VENDOR_ID_TO_MODEL_KEY[vendor]
    const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
    return !!_.get(smSettings, key)
}

function setBackendLoginOnlyStatus(ps: PS, backendLoginOnlyStatus, onSuccess?, onError?) {
    ajax(ps, {
        type: 'POST',
        url: getMemberSettingsEndpoint(ps),
        dataType: 'json',
        data: {siteMemberSettings: {shouldSupportBackendOnly: backendLoginOnlyStatus}},
        success: onSuccess || _.noop,
        error: onError || _.noop
    })
}

function isBackendLoginOnlyEnabled(ps: PS, onSuccess?, onError?) {
    ajax(ps, {
        type: 'GET',
        url: getMemberSettingsEndpoint(ps),
        dataType: 'json',
        success: ({shouldSupportBackendOnly}) => _.isFunction(onSuccess) && onSuccess(shouldSupportBackendOnly),
        error: onError || _.noop
    })
}

function getPrivacyNoteType(ps: PS) {
    if (isJoinCommunityEnabled(ps)) {
        const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
        const privacyNoteType = _.get(smSettings, PRIVACY_NOTE_TYPE_KEY)
        return privacyNoteType || PRIVACY_NOTE_TYPE.checkbox
    }
    return PRIVACY_NOTE_TYPE.checkbox
}

function setPrivacyNoteType(ps: PS, privacyNoteType) {
    setSmSettingsData(ps, _.set({}, PRIVACY_NOTE_TYPE_KEY, privacyNoteType))
}

function setJoinCommunityStatus(ps: PS, enabled) {
    setSmSettingsData(ps, _.set({}, COMMUNITY_SETTINGS_MODEL_KEY, !!enabled))
}

function isJoinCommunityEnabled(ps: PS) {
    const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
    return !(_.get(smSettings, COMMUNITY_SETTINGS_MODEL_KEY) === false)
}

function isSignupPoliciesFieldEnabled(ps: PS, policy) {
    const key = SIGNUP_POLICIES_TO_MODEL_KEY[policy]
    const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
    return !!_.get(smSettings, [key, 'enabled'])
}

function setSignupPoliciesFieldStatus(ps: PS, policy, enabled) {
    const key = SIGNUP_POLICIES_TO_MODEL_KEY[policy]
    if (key) {
        setSmSettingsData(ps, _.set({}, [key, 'enabled'], !!enabled))
    }
}

function setSignupPoliciesLink(ps: PS, policy, link) {
    const key = SIGNUP_POLICIES_TO_MODEL_KEY[policy]
    if (key) {
        const linkData = dataModel.addLink(ps, link.type, link)
        setSmSettingsData(ps, _.set({}, [key, 'link'], `#${linkData}`))
    }
}

function getSignupPoliciesLink(ps: PS, policy) {
    const key = SIGNUP_POLICIES_TO_MODEL_KEY[policy]
    const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
    return _.get(smSettings, [key, 'link'], null)
}

function setCustomSignupPageId(ps: PS, pageId: string) {
    setSmSettingsData(ps, _.set({}, CUSTOM_SIGNUP_SETTINGS, pageId))
}

function getCustomSignupPageId(ps: PS) {
    const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
    return _.get(smSettings, CUSTOM_SIGNUP_SETTINGS, null)
}

function setCustomSignInPageId(ps: PS, pageId: string) {
    setSmSettingsData(ps, _.set({}, CUSTOM_SIGN_IN_SETTINGS, pageId))
}

function getCustomSignInPageId(ps: PS) {
    const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
    return _.get(smSettings, CUSTOM_SIGN_IN_SETTINGS, null)
}

function setCustomNoPermissionsPageId(ps: PS, pageId: string) {
    setSmSettingsData(ps, _.set({}, CUSTOM_NO_PERMISSIONS_PAGE, pageId))
}

function getCustomNoPermissionsPageId(ps: PS) {
    const smSettings = ps.dal.get(getSiteMembersSettingsPointer(ps))
    return _.get(smSettings, CUSTOM_NO_PERMISSIONS_PAGE, null)
}

const showSignUpDialog = async (ps: PS) => ps.siteAPI.showSignUpDialog()
const showLoginDialog = async (ps: PS) => ps.siteAPI.showLoginDialog()
const hideAuthDialog = async (ps: PS) => ps.siteAPI.hideAuthDialog()

export default {
    setAutoApproval,
    isAutoApproval,
    setLoginDialogFirst,
    isLoginDialogFirst,
    setSocialLoginVendorStatus,
    isSocialLoginEnabled,
    setBackendLoginOnlyStatus,
    isBackendLoginOnlyEnabled,
    getPrivacyNoteType,
    setPrivacyNoteType,
    setJoinCommunityStatus,
    isJoinCommunityEnabled,
    isSignupPoliciesFieldEnabled,
    setSignupPoliciesFieldStatus,
    showSignUpDialog,
    showLoginDialog,
    hideAuthDialog,
    getSignupPoliciesLink,
    setSignupPoliciesLink,
    setCustomSignupPageId,
    getCustomSignupPageId,
    setCustomSignInPageId,
    getCustomSignInPageId,
    setCustomNoPermissionsPageId,
    getCustomNoPermissionsPageId
}
