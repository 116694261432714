import type {PS} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import _ from 'lodash'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import constants from '../../constants/constants'

const MOBILE_ONLY_COMPS_TO_BE_MOCKED_IN_DESKTOP_IDS = ['MENU_AS_CONTAINER']
const MOBILE_ONLY_COMPS_TO_BE_MOCKED_IN_DESKTOP_TYPES = ['wysiwyg.viewer.components.MenuContainer']

function isMobileOnlyComponentToBeMockedInDesktopId(compId: string) {
    return _.includes(MOBILE_ONLY_COMPS_TO_BE_MOCKED_IN_DESKTOP_IDS, compId)
}

function isMobileOnlyComponentToBeMockedInDesktop(comp) {
    return _.includes(MOBILE_ONLY_COMPS_TO_BE_MOCKED_IN_DESKTOP_TYPES, _.get(comp, 'componentType'))
}

/**
 * @param ps
 * @param compId
 * @returns {boolean} true if the component was added to mobile structure directly from mobile view
 */
const isMobileOnlyPageComponent = (ps: PS, compId: string) => {
    // component is not necessarily in masterPage, but getComponent needs a page pointer.
    // the fact that this API receives only a compId from public api is problematic in itself
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const mobileCompPointer = ps.pointers.components.getComponent(compId, masterPagePointer)
    return componentsMetaData.public.isMobileOnly(ps, mobileCompPointer)
}

/**
 * Checks if a compId is a mobile only component or not
 *
 * @param {ps} ps
 * @param {string} compId
 * @returns {boolean} true if the component is mobile only, false otherwise
 */
function isMobileOnlyComponent(ps: PS, compId: string) {
    if (!_.isString(compId)) {
        throw new Error(`Incorrect parameter! Expected a compId (string), but got ${typeof compId}`)
    }
    return isNativeMobileOnlyComponent(ps, compId) || isMobileOnlyPageComponent(ps, compId)
}

/**
 * Checks if a compId is a mobile only component or not
 *
 * @param {ps} ps
 * @param {string} compId
 * @returns {boolean} true if the component is mobile only, false otherwise
 */
function isNativeMobileOnlyComponent(ps: PS, compId: string) {
    if (!_.isString(compId)) {
        throw new Error(`Incorrect parameter! Expected a compId (string), but got ${typeof compId}`)
    }
    return (
        mobileCore.mobileOnlyComponents.isMobileOnlyComponentId(compId) ||
        isMobileOnlyComponentToBeMockedInDesktopId(compId)
    )
}

function cloneMobileMenuContainerToDesktop(desktopPage, mobileMenuContainer) {
    const clonedMenuContainer = _.cloneDeep(mobileMenuContainer)
    clonedMenuContainer.components = []
    mobileCore.conversionUtils.addComponentsTo(desktopPage, [clonedMenuContainer])
    return clonedMenuContainer
}

function extractComponentsFromHeaderStructureByType(desktopPage, compTypes) {
    const desktopHeader = mobileCore.conversionUtils.getComponentByIdFromStructure('SITE_HEADER', desktopPage)
    if (!desktopHeader) {
        return
    }
    return mobileCore.conversionUtils.extractComponentsFromStructureByType(desktopHeader, compTypes)
}

function addMockedMenuContainerToDesktop(desktopPage, mobilePage) {
    const mobileMenuContainer = _.find(mobileCore.conversionUtils.getChildren(mobilePage), {id: 'MENU_AS_CONTAINER'})
    const clonedMenuContainer = cloneMobileMenuContainerToDesktop(desktopPage, mobileMenuContainer)
    const compTypesToReparentToMenuContainer = [
        'wysiwyg.viewer.components.LoginSocialBar',
        'wysiwyg.viewer.components.LanguageSelector'
    ]
    const extractedCompsFromHeader = extractComponentsFromHeaderStructureByType(
        desktopPage,
        compTypesToReparentToMenuContainer
    )

    if (!_.isEmpty(extractedCompsFromHeader)) {
        mobileCore.conversionUtils.addComponentsTo(clonedMenuContainer, extractedCompsFromHeader)
    }
}

function reparentMobileMenuContainerFromHeaderToMasterPage(mobilePage) {
    const extractedMenuFromHeader = extractComponentsFromHeaderStructureByType(mobilePage, [
        'wysiwyg.viewer.components.MenuContainer'
    ])
    mobileCore.conversionUtils.addComponentsTo(mobilePage, extractedMenuFromHeader)
}

function handleMenuContainerBeforeMobileAlgo(desktopPage, mobilePage) {
    const mobileMenuContainer = mobileCore.conversionUtils.getComponentByIdFromStructure(
        'MENU_AS_CONTAINER',
        mobilePage
    )
    if (!mobileMenuContainer) {
        return
    }
    reparentMobileMenuContainerFromHeaderToMasterPage(mobilePage)
    addMockedMenuContainerToDesktop(desktopPage, mobilePage)
}

function reparentMobileMenuContainerFromMasterPageToHeader(mobilePage) {
    const extractedMobileMenuContainer = mobileCore.conversionUtils.extractComponentsFromStructureByType(mobilePage, [
        'wysiwyg.viewer.components.MenuContainer'
    ])
    const mobileHeader = mobileCore.conversionUtils.getComponentByIdFromStructure('SITE_HEADER', mobilePage)
    mobileCore.conversionUtils.addComponentsTo(mobileHeader, extractedMobileMenuContainer)
}

function syncMenuContainers(desktopMenuContainer, mobileMenuContainer) {
    mobileMenuContainer.layout = {...desktopMenuContainer.layout}
}

function handleMenuContainerAfterMobileAlgo(desktopPage, mobilePage, isImprovedMergeFlowEnabled) {
    const desktopMenuContainer = mobileCore.conversionUtils.getComponentByIdFromStructure(
        'MENU_AS_CONTAINER',
        desktopPage
    )
    if (!desktopMenuContainer) {
        return
    }
    if (isImprovedMergeFlowEnabled) {
        const mobileMenuContainer = mobileCore.conversionUtils.getComponentByIdFromStructure(
            'MENU_AS_CONTAINER',
            mobilePage
        )
        mobileCore.conversionUtils.removeChildrenFrom(desktopPage, [desktopMenuContainer])
        syncMenuContainers(desktopMenuContainer, mobileMenuContainer)
    }

    reparentMobileMenuContainerFromMasterPageToHeader(mobilePage)
}

function handleComponentsBeforeMobileAlgo(desktopPage, mobilePage) {
    handleMenuContainerBeforeMobileAlgo(desktopPage, mobilePage)
}

function handleComponentsAfterMobileAlgo(desktopPage, mobilePage, isImprovedMergeFlowEnabled?: boolean) {
    handleMenuContainerAfterMobileAlgo(desktopPage, mobilePage, isImprovedMergeFlowEnabled)
}

/**
 * @param ps
 * @param {Object} comp
 * @returns {boolean|*} true
 */
const isNeedConversionData = (ps: PS, comp) =>
    isMobileOnlyComponentToBeMockedInDesktop(comp) || isMobileOnlyPageComponent(ps, comp.id)

export default {
    isMobileOnlyComponentToBeMockedInDesktop,
    isMobileOnlyComponent,
    isNativeMobileOnlyComponent,
    isNeedConversionData,
    handleComponentsBeforeMobileAlgo,
    handleComponentsAfterMobileAlgo
}
