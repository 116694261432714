import _ from 'lodash'
import routers from '../../routers/routers'
import page from '../../page/page'

function removeNonExistingPagesFromRouter(ps) {
    const pageIds = page.getPageIdList(ps)
    const appRouters = routers.get.all(ps)

    _.forEach(appRouters, router => {
        const routerRef = routers.getRouterRef.byPrefix(ps, router.prefix)
        _.forEach(router.pages, pageId => {
            if (!_.includes(pageIds, pageId)) {
                routers.pages.removePageFromRouter(ps, routerRef, pageId)
            }
        })
    })
}

export default {
    exec(ps) {
        removeNonExistingPagesFromRouter(ps)
    },
    name: 'routersDataFixer',
    version: 1
}
