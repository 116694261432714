import _ from 'lodash'
import experiment from 'experiment-amd'
import bi from '../../bi/bi'
import biEvents from '../../bi/events.json'
import type {PS} from '@wix/document-services-types'

const METHODS_WITH_BAD_PERFORMANCE_APIS = new Set([
    'document.controllers.listAllControllers',
    'document.controllers.listConnectableControllers',
    'document.components.getAllComponents',
    'document.components.refComponents.getAllAppRefComponents',
    'document.components.refComponents.getCollapsedRefComponents',
    'document.components.getChildren',
    'document.components.getAncestors',
    'document.pages.popupPages.addConnected',
    'document.pages.getHomePage'
])

function reportAPICallBI(ps: PS, message) {
    if (experiment.isOpen('dm_platformSdkApiCallBI')) {
        const callPath = message.call.split('.')
        const module = callPath.slice(0, -1).join('.')
        const func = _.last(callPath)
        const args = message.args.map(JSON.stringify).join(',')
        bi.event(ps, biEvents.PLATFORM_API_CALL, {
            app_name: message.args[0].appDefinitionId,
            module,
            function: func,
            arguments: args,
            pageId: ps.siteAPI.getPrimaryPageId()
        })
    }
}

function reportAPICallBIHybridModeMethods(ps: PS, message) {
    if (experiment.isOpen('dm_platformSdkHybridApiCallBI')) {
        if (METHODS_WITH_BAD_PERFORMANCE_APIS.has(message.call)) {
            const callPath = message.call.split('.')
            const module = callPath.slice(0, -1).join('.')
            const func = callPath[callPath.length - 1]
            const args = message.args.map(JSON.stringify).join(',')
            bi.event(ps, biEvents.PLATFORM_API_CALL_HYBRID_METHODS, {
                appDefinitionId: message.args[0]?.value?.appDefinitionId,
                module,
                function: func,
                arguments: args,
                pageId: ps.siteAPI.getPrimaryPageId()
            })
        }
    }
}

function reportAPICallFedOps(ps: PS, message) {
    if (experiment.isOpen('dm_platformSdkApiCallBI')) {
        const appDefinitionId = _.get(message, ['args', 0, 'value', 'appDefinitionId'])

        /*
           "interactionEnded" BI event is in use here only as a "container" to track which EditorSDK methods are called.
           "interactionStarted" event was added just for consistency because interaction should be described by a pair of these events
         */
        ps.extensionAPI.logger.interactionStarted(message.call, {extras: {appDefinitionId}})
        ps.extensionAPI.logger.interactionEnded(message.call, {extras: {appDefinitionId}})
    }
}

export default {
    reportAPICallBI,
    reportAPICallBIHybridModeMethods,
    reportAPICallFedOps
}
