import dataModel from '../../dataModel/dataModel'
import structure from '../../structure/structure'

function replaceFullScreenPropertyWithDocking(ps, compPointer) {
    const propertiesItem = dataModel.getPropertiesItem(ps, compPointer)
    if (propertiesItem?.fullScreenModeOn) {
        structure.setDock(ps, compPointer, {left: {vw: 0}, right: {vw: 0}})
        dataModel.updatePropertiesItem(ps, compPointer, {fullScreenModeOn: false})
    }
}

export default {
    replaceFullScreenPropertyWithDocking
}
