import type {Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import _ from 'lodash'

export default {
    isRepeatable: false,
    rotatable: true,
    a11yConfigurable: false,
    layoutLimits(ps: PS, compPointer: Pointer) {
        const measureMap = ps.siteAPI.getSiteMeasureMap()

        return {
            minWidth: _.get(measureMap, ['minWidth', compPointer.id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH)
        }
    }
}
