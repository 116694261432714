import {
    CreateExtArgs,
    DAL,
    DalValue,
    DmApis,
    Extension,
    ExtensionAPI,
    pointerUtils,
    ValidateValue
} from '@wix/document-manager-core'
import {schemas} from '@wix/document-services-json-schemas'
import type {Pointer} from '@wix/document-services-types'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import _ from 'lodash'
import {DATA_TYPES, VIEW_MODES} from '../constants/constants'
import type {ComponentsAPI} from './components'
import type {DataModelExtensionAPI} from './dataModel'
import type {RelationshipsAPI} from './relationships'

const {getPointer, getOverrideComponentPointer} = pointerUtils
const {DESKTOP, MOBILE} = VIEW_MODES
const NO_MATCH: string[] = []

export const EVENTS = {
    SLOTS: {
        AFTER_POPULATE: 'SLOTS_AFTER_POPULATE'
    }
}

export interface SlotsExtensionAPI extends ExtensionAPI {
    slots: {
        getPluginParent(childPointer: Pointer): Pointer | null
        populate(
            slottedComponent: Pointer,
            slotName: string,
            componentDefinition: any,
            newCompPointerOverride?: Pointer
        ): Pointer
        getPopulatedSlotNames(componentPointer: Pointer): string[]
        getSlotsData(componentPointer: Pointer): Record<string, Pointer>
        remove(slottedComponent: Pointer, slotName: string, dontDeleteComponent?: boolean): void
    }
}

const createExtension = (): Extension => {
    const createFilters = () => ({
        getSlotsFilter: (namespace: string, value: any): string[] => {
            if (namespace !== DATA_TYPES.slots || !value) {
                return NO_MATCH
            }
            if (value.slots) {
                return _.values(value.slots)
            }
            return NO_MATCH
        }
    })

    const createExtensionAPI = ({dal, extensionAPI, eventEmitter}: CreateExtArgs): SlotsExtensionAPI => {
        const {components} = extensionAPI as {components: ComponentsAPI}
        const {dataModel} = extensionAPI as DataModelExtensionAPI

        const getPluginParent = (compPointer: Pointer): Pointer | null => {
            if (!compPointer?.id) {
                return null
            }

            const slotsDataItems = dal.queryKeys(
                DATA_TYPES.slots,
                dal.queryFilterGetters.getSlotsFilter(compPointer.id)
            )
            if (slotsDataItems.length === 0) {
                return null
            }

            const slotDataPointer = slotsDataItems[0]
            if (!displayedOnlyStructureUtil.isReferredId(slotDataPointer)) {
                return null
            }

            return getOverrideComponentPointer(slotDataPointer, compPointer.type, 'slotsQuery')
        }

        const getOwnerOfComponentInSlot = (slottedComponent: Pointer): Pointer => {
            if (displayedOnlyStructureUtil.isRefPointer(slottedComponent)) {
                const rootRefHostCompId = displayedOnlyStructureUtil.getRootRefHostCompId(slottedComponent.id)
                return getPointer(rootRefHostCompId, VIEW_MODES.DESKTOP)
            }
            return slottedComponent
        }

        const getCompDefinition = (compPointer: Pointer) => {
            const {componentType} = dal.get(compPointer)
            return schemas.default.allComponentsDefinitionsMap[componentType]
        }

        const getSlotProperties = (slottedComponentPointer: Pointer) => {
            const {slotsDataType} = getCompDefinition(slottedComponentPointer)
            //@ts-ignore
            return schemas.default.slots[slotsDataType].properties.slots.properties
        }

        const isDynamic = (compPointer: Pointer) => getCompDefinition(compPointer).slotsDataType === 'DynamicSlots'

        const verifySlotName = (slottedComponent: Pointer, slotName: string) => {
            const slotProperties = getSlotProperties(slottedComponent)
            const allowedSlotNames = _.keys(slotProperties)
            const isDynamicSlots = isDynamic(slottedComponent)

            if (!allowedSlotNames.includes(slotName) && !isDynamicSlots) {
                throw new Error(`Slot "${slotName}" is not a valid slot name`)
            }
        }

        const getSlotsData = (slottedComponentPointer: Pointer): Record<string, Pointer> => {
            const slotsData = dataModel.components.getItem(slottedComponentPointer, DATA_TYPES.slots)
            return _.mapValues(slotsData?.slots, compId =>
                getPointer(_.trimStart(compId, '#'), slottedComponentPointer.type)
            )
        }

        const getPopulatedSlotNames = (componentPointer: Pointer) => _.keys(getSlotsData(componentPointer))

        const verifyCanPopulate = (slotName: string, slottedComponent: Pointer) => {
            verifySlotName(slottedComponent, slotName)

            const currentSlots = getPopulatedSlotNames(slottedComponent)
            if (currentSlots.includes(slotName)) {
                throw new Error(`Slot "${slotName}" already exists`)
            }
        }

        const updateSlot = (slottedComponent: Pointer, slotName: string, compToAddPointer: Pointer) => {
            verifyCanPopulate(slotName, slottedComponent)
            const {slotsDataType} = getCompDefinition(slottedComponent)
            const updatedData = dataModel.components.getItem(slottedComponent, DATA_TYPES.slots)

            if (updatedData?.slots) {
                updatedData.slots[slotName] = compToAddPointer.id
                dataModel.components.addItem(slottedComponent, DATA_TYPES.slots, updatedData)
            } else {
                dataModel.components.addItem(slottedComponent, DATA_TYPES.slots, {
                    type: slotsDataType,
                    slots: {[slotName]: compToAddPointer.id}
                })
            }
        }

        const populate = (
            slottedComponent: Pointer,
            slotName: string,
            componentDefinition: any,
            newCompPointerOverride?: Pointer
        ): Pointer => {
            const ownerOfComponentInSlot = getOwnerOfComponentInSlot(slottedComponent)
            const addedComponentPointer = components.addComponent(
                ownerOfComponentInSlot,
                componentDefinition,
                newCompPointerOverride
            )
            updateSlot(slottedComponent, slotName, addedComponentPointer)

            eventEmitter.emit(
                EVENTS.SLOTS.AFTER_POPULATE,
                slottedComponent,
                ownerOfComponentInSlot,
                addedComponentPointer
            )

            return addedComponentPointer
        }

        const removeFromQuery = (slottedComponent: Pointer, slotName: string) => {
            const slotsData = dataModel.components.getItem(slottedComponent, DATA_TYPES.slots)
            delete slotsData.slots[slotName]
            dal.set(getPointer(slotsData.id, DATA_TYPES.slots, {innerPath: ['slots']}), slotsData.slots)
        }

        const remove = (slottedComponent: Pointer, slotName: string, dontDeleteComponent?: boolean) => {
            verifySlotName(slottedComponent, slotName)

            const compPointer = _.get(getSlotsData(slottedComponent), slotName)
            if (!compPointer) {
                return
            }

            if (dal.has(compPointer) && !dontDeleteComponent) {
                components.removeComponent(compPointer)
            }

            removeFromQuery(slottedComponent, slotName)
        }

        return {
            slots: {
                getPluginParent,
                populate,
                getPopulatedSlotNames,
                getSlotsData,
                remove
            }
        }
    }

    const getReferringSlot = (dal: DAL, refCompChildId: string) => {
        return dal.getIndexPointers(dal.queryFilterGetters.getSlotsFilter(refCompChildId), DATA_TYPES.slots)
    }

    const belongsToOverride = (dal: DAL, refCompChildId: string, refHostId: string): boolean => {
        const slotsOverrideId = getReferringSlot(dal, refCompChildId)
        if (slotsOverrideId.length === 1) {
            return displayedOnlyStructureUtil.getRootRefHostCompId(slotsOverrideId[0].id) === refHostId
        }
        return false
    }

    const createValidator = ({dal, extensionAPI}: DmApis): Record<string, ValidateValue> => {
        const getSlotOwners = (slotsQueryPointer: Pointer): Pointer[] => {
            const {relationships} = extensionAPI as RelationshipsAPI
            if (displayedOnlyStructureUtil.isRefPointer(slotsQueryPointer)) {
                const refComponentId = displayedOnlyStructureUtil.getRootRefHostCompId(slotsQueryPointer.id)

                return _.filter(
                    [getPointer(refComponentId, DESKTOP), getPointer(refComponentId, MOBILE)],
                    refCompPointer => {
                        const plugin = getPointer(slotsQueryPointer.id, refCompPointer.type)
                        return dal.has(refCompPointer) && dal.has(plugin)
                    }
                )
            }

            return relationships.getOwningReferencesToPointer(slotsQueryPointer)
        }

        return {
            invalidRefComponentChildren: (pointer: Pointer, value: DalValue) => {
                if (!_.includes(Object.values(VIEW_MODES), pointer.type)) {
                    return
                }

                const {type: dalValueType} = value ?? {}

                if (dalValueType === 'RefComponent' && value.components?.length) {
                    const refComponentChildren = value.components.filter(
                        (compId: string) => !compId.startsWith('dead-')
                    )
                    const invalidRefCompChildren = _.reject(refComponentChildren, child =>
                        belongsToOverride(dal, child, value.id)
                    )
                    if (!_.isEmpty(invalidRefCompChildren)) {
                        return [
                            {
                                shouldFail: true,
                                type: 'invalidRefComponentChildren',
                                message: 'Invalid components inside refComponent',
                                extras: {
                                    invalidRefCompChildren
                                }
                            }
                        ]
                    }
                }
            },
            invalidSlotsReference: (pointer: Pointer, value: DalValue) => {
                if (pointer.type === DATA_TYPES.slots && !_.isEmpty(value?.slots)) {
                    const referencedCompsIds = value.slots as Record<string, string>
                    const slotOwners = getSlotOwners(pointer)
                    const missingCompsIds = _.pickBy(referencedCompsIds, refId =>
                        _.some(slotOwners, ownerPointer => {
                            const owner = dal.get(ownerPointer)
                            return !_.includes(owner.components, refId)
                        })
                    )
                    if (!_.isEmpty(missingCompsIds)) {
                        return [
                            {
                                shouldFail: true,
                                type: 'invalidSlotsReference',
                                message: 'Invalid slots reference to one or more of its components',
                                extras: {
                                    missingCompsIds
                                }
                            }
                        ]
                    }
                }
            }
        }
    }

    return {
        name: 'slots',
        createFilters,
        createValidator,
        createExtensionAPI
    }
}
export {createExtension}
