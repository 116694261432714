import type {PS} from '@wix/document-services-types'
import {fontUtils} from '@wix/santa-ds-libs/src/coreUtils'
import theme from '../theme/theme'
import generalInfo from '../siteMetadata/generalInfo'

/**
 * @param ps
 * returns ['googleApiUrl', 'languageStaticsUrl', 'helveticaStaticsUrl']
 * @returns {Record<string, any>}
 */
function getCssUrls(ps: PS) {
    const documentType = generalInfo.getDocumentType(ps)
    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
    return fontUtils.getCssUrls(documentType, serviceTopology)
}

/**
 * @param ps
 * returns [{lang: 'latin', fonts:[{fontMetaData}]}]
 * @returns {{lang:string, fonts: [{fontMetaData}]}}
 */
function getSiteFontsOptions(ps: PS) {
    const documentType = generalInfo.getDocumentType(ps)
    const characterSets = theme.fonts.getCharacterSet(ps)
    return fontUtils.getCurrentSelectablefontsWithParams(documentType, characterSets)
}

function getAllFontsOptions(ps: PS) {
    const POSSIBLE_CHARACTERS_SETS = ['latin-ext', 'cyrillic', 'japanese', 'korean', 'arabic', 'hebrew', 'latin']
    const documentType = generalInfo.getDocumentType(ps)
    return fontUtils.getCurrentSelectablefontsWithParams(documentType, POSSIBLE_CHARACTERS_SETS)
}

export default {
    css: {
        getCssUrls
    },
    getSiteFontsOptions,
    getAllFontsOptions
}
