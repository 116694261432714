import _ from 'lodash'
import siteMetadata from '../../siteMetadata/siteMetadata'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import componentStructureInfo from '../../component/componentStructureInfo'
import component from '../../component/component'
import notificationService, {NotifyApplication} from '../services/notificationService'
import platformEventsService from '../services/platformEventsService'
import dataModel from '../../dataModel/dataModel'
import constants from '../common/constants'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'
import connections from '../../connections/connections'
import dsUtils from '../../utils/utils'
import tpaUtils from '../../tpa/utils/tpaUtils'
import experiment from 'experiment-amd'
import platformAppDataGetter from '../services/platformAppDataGetter'
import type {AppDefinitionId, EditorClientSpecMapEntry, Pointer, PS} from '@wix/document-services-types'

function notifyOnFirstSaved(
    ps: PS,
    appsToNotify: EditorClientSpecMapEntry[],
    notifyApplicationFunc: NotifyApplication
) {
    const metaSiteId = siteMetadata.generalInfo.getMetaSiteId(ps)
    _.forEach(appsToNotify, ({appDefinitionId, instance, instanceId}) => {
        notifyApplicationFunc(
            ps,
            appDefinitionId,
            platformEvents.factory.siteWasFirstSaved({
                metaSiteId,
                instance,
                instanceId
            })
        )
    })

    ps.siteAPI.updateBiData({metaSiteId})
}

function removeGhostStructureForApp(ps: PS, {appDefinitionId}) {
    ps.siteDataAPI.siteData.removeGhostStructureData(appDefinitionId)
}

function notifyAddToAppWidget(ps: PS, componentPointer: Pointer, newContainerPointer, oldParentPointer) {
    if (!newContainerPointer || ps.pointers.components.isPage(newContainerPointer)) {
        return
    }
    const closestAppWidget = componentStructureInfo.getAncestorByPredicate(
        ps,
        newContainerPointer,
        parentPointer => component.getType(ps, parentPointer) === constants.CONTROLLER_TYPES.APP_WIDGET
    )
    if (closestAppWidget) {
        if (oldParentPointer && componentDetectorAPI.isDescendantOfComp(ps, oldParentPointer, closestAppWidget)) {
            return
        }
        const fullData = dataModel.getDataItem(ps, closestAppWidget) || {}
        if (fullData.applicationId) {
            const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, fullData.applicationId)
            if (appData?.applicationId) {
                const event = platformEvents.factory.componentAddedToApp({
                    widgetRef: closestAppWidget,
                    compRef: componentPointer
                })
                notificationService.notifyApplication(ps, appData.appDefinitionId, event)
            }
        }
    }
}

function notifyComponentAddedToStage(
    notifyApplicationCb,
    ps: PS,
    componentPointer: Pointer,
    newContainerPointer: Pointer,
    oldParentPointer: Pointer
) {
    if (experiment.isOpen('dm_moveComponentAddedToStageToDm')) {
        if (!componentPointer || ps.pointers.components.isPage(componentPointer)) {
            return
        }
        const event = Object.assign(
            {eventOrigin: 'DM'},
            platformEvents.factory.componentAddedToStage({
                compRef: componentPointer,
                componentType: component.getType(ps, componentPointer)
            })
        )
        const compData = component.data.get(ps, componentPointer)
        const appsToNotify = new Set(
            _.compact(
                _.concat(
                    connections
                        .getAppsConnectedToComponent(ps, componentPointer)
                        .concat([compData?.appDefinitionId, compData?.applicationId])
                        .map(appDefinitionId =>
                            _.get(
                                clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId),
                                'applicationId'
                            )
                        ),
                    clientSpecMapService.getApplicationIdFromAppDefinitionIdBulk(
                        ps,
                        platformEventsService.getAppsRegisteredToEventType(event.eventType)
                    )
                )
            )
        )
        appsToNotify.forEach(applicationId => notifyApplicationCb(ps, applicationId, event))
    }
    notifyAddToAppWidget(ps, componentPointer, newContainerPointer, oldParentPointer)
}

function getComponentAddedToStageHook(notifyApplicationCb: NotifyApplication) {
    return _.partial(notifyComponentAddedToStage, notifyApplicationCb)
}

function notifyWidgetAddedToStage(ps: PS, compToAddPointer: Pointer, clonedSerializedComp) {
    const compData = clonedSerializedComp.data || {}
    const {originCompId, componentType} = clonedSerializedComp
    const {appDefinitionId, applicationId, widgetId} = compData
    let appDefId
    if (connections.isOOIController(componentType)) {
        appDefId = _.get(clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId), 'appDefinitionId')
    } else if (connections.isAppWidgetType(componentType)) {
        appDefId = _.get(clientSpecMapService.getAppDataByAppDefinitionId(ps, applicationId), 'appDefinitionId')
    }

    if (appDefId) {
        notificationService.notifyApplication(
            ps,
            appDefId,
            platformEvents.factory.widgetAdded({
                componentRef: compToAddPointer,
                originalComponentId: originCompId,
                widgetId
            })
        )
    }
}

const COMPS_TO_ADD_ORIGIN = [
    'wysiwyg.viewer.components.tpapps.TPAWidget',
    'wysiwyg.viewer.components.tpapps.TPASection',
    'platform.components.AppWidget'
]

function addOriginCompIdToWidget(ps: PS, componentPointer: Pointer, compStructure) {
    if (COMPS_TO_ADD_ORIGIN.includes(compStructure.componentType)) {
        compStructure.originCompId = componentPointer.id
    }
}

function getAppDefinitionId(ps: PS, componentPointer: Pointer): AppDefinitionId {
    const {applicationId, appDefinitionId} = dataModel.getDataItem(ps, componentPointer) || {}
    const componentType = dsUtils.getComponentType(ps, componentPointer)
    const appDefId = tpaUtils.isTpaByCompType(componentType) ? appDefinitionId : applicationId
    return appDefId
}

function notifyComponentDisconnected(ps: PS, componentPointer: Pointer, controllerRef: Pointer) {
    const appDefinitionId = getAppDefinitionId(ps, componentPointer)
    if (appDefinitionId) {
        const event = platformEvents.factory.componentDisconnected({
            controllerRef,
            compRef: componentPointer
        })
        notificationService.notifyApplication(ps, appDefinitionId, event)
    }
}

function notifyComponentConnected(ps: PS, componentPointer: Pointer, controllerRef: Pointer) {
    const appDefinitionId = getAppDefinitionId(ps, componentPointer)
    if (appDefinitionId) {
        const event = platformEvents.factory.componentConnected({
            controllerRef,
            compRef: componentPointer
        })
        notificationService.notifyApplication(ps, appDefinitionId, event)
    }
}

function addAppsControllers(ps: PS, dataItem): Record<AppDefinitionId, any> {
    const appsToNotify: Record<AppDefinitionId, any> = {}
    const {applicationId, appDefinitionId} = dataItem || {}
    let appData: any
    switch (dataItem?.type) {
        case 'AppController':
            appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, applicationId as AppDefinitionId)
            break
        case 'WidgetRef':
            appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
            break
        default:
            appData = clientSpecMapService.getAppData(ps, applicationId)
    }
    if (clientSpecMapService.hasEditorPlatformPart(appData)) {
        appsToNotify[appData.appDefinitionId] = appsToNotify[appData.appDefinitionId] || []
    }
    return appsToNotify
}

function addConnectedComponentsApps(ps: PS, componentConnections): Record<AppDefinitionId, any> {
    return _.reduce(
        componentConnections,
        function (appsToNotifyAcc, connection) {
            const controllerData = dataModel.getDataItem(ps, connection.controllerRef)
            if (controllerData) {
                const appDefId = controllerData.applicationId
                const appData = platformAppDataGetter.getAppDataByAppDefId(ps, appDefId)
                if (appData) {
                    appsToNotifyAcc[appDefId] = appsToNotifyAcc[appDefId] || []
                    appsToNotifyAcc[appDefId].push(connection)
                }
            }

            return appsToNotifyAcc
        },
        {}
    )
}

function getOnDeleteHook(notifyApplicationCb: NotifyApplication) {
    return _.partial(notifyConnectedAppOnDelete, notifyApplicationCb)
}

function notifyConnectedAppOnDelete(
    notifyApplicationCb: NotifyApplication,
    ps: PS,
    compPointer: Pointer,
    deletingParent,
    removeArgs,
    deletedParentFromFull,
    dataItem,
    deletedCompParentPointer: Pointer,
    componentConnections
) {
    const appsToNotify = {
        ...addAppsControllers(ps, dataItem),
        ...addConnectedComponentsApps(ps, componentConnections)
    }

    _.forEach(appsToNotify, function (appConnections, appDefinitionId) {
        notifyApplicationCb(
            ps,
            appDefinitionId,
            platformEvents.factory.componentDeleted({
                componentRef: compPointer,
                connections: appConnections
            }),
            true
        )
    })
}

export default {
    notifyOnFirstSaved,
    removeGhostStructureForApp,
    notifyAddToAppWidget,
    getComponentAddedToStageHook,
    notifyWidgetAddedToStage,
    notifyComponentDisconnected,
    notifyComponentConnected,
    getOnDeleteHook,
    addOriginCompIdToWidget
}
