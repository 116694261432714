import type {Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import documentServicesSchemas from 'document-services-schemas'
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'

const {schemasService} = documentServicesSchemas.services

/**
 * Validate the compProps of a component type for type correctness and schema correctness.
 * @param componentType The type of the component the compProps are going to be used with
 * @param {object} compProps The compProps that are going to be set
 */
function validateProperties(componentType: string, compProps?) {
    const compDefinition = schemasService.getDefinition(componentType)
    if (!compDefinition) {
        throw new Error(`${componentType} has no component definition.`)
    }
    if (!compDefinition.propertyTypes && !compDefinition.propertyType && !compProps) {
        return
    }

    const types = compDefinition.propertyTypes
        ? compDefinition.propertyTypes
        : [compDefinition.propertyType || santaCoreUtils.constants.BASE_PROPS_SCHEMA_TYPE]
    if (_.includes(types, '') || (_.isString(compProps) && _.includes(types, compProps))) {
        return
    }

    const msg = `${componentType} must get a compProps of one of the types [${types}]`
    if (!compProps) {
        throw new Error(msg)
    }

    if (!_.includes(types, compProps.type)) {
        throw new Error(`${msg} but got ${compProps.type}`)
    }
    documentServicesSchemas.services.dataValidators.validateDataBySchema(compProps, 'props')
}

function validateCompProps(ps: PS, compPointer: Pointer) {
    const componentType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
    const propertyItemPointer = dataModel.getPropertyItemPointer(ps, compPointer)
    const properties = propertyItemPointer && ps.dal.get(propertyItemPointer)
    validateProperties(componentType, properties)
}

export default {
    validateProperties,
    validateCompProps
}
