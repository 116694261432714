import layout from 'layout'
import _ from 'lodash'

function measureVerticalLine(id, measureMap, nodesMap) {
    const el = nodesMap[id]
    const elLeftValue = parseInt(el.style.left, 10)
    measureMap.width[id] = parseInt(el.style.width, 10)
    measureMap.left[id] = _.isFinite(elLeftValue) ? elLeftValue : measureMap.left[id]
}

layout.registerAdditionalMeasureFunction('wysiwyg.viewer.components.VerticalLine', measureVerticalLine)
