import type {Callback, Pointer, PS} from '@wix/document-services-types'
import * as santaCoreUtils from '@wix/santa-core-utils'
import documentServicesSchemas from 'document-services-schemas'
import _ from 'lodash'
import componentStructureInfo from '../component/componentStructureInfo'
import dataIds from '../dataModel/dataIds'
import dataModel from '../dataModel/dataModel'
import documentModeInfo from '../documentMode/documentModeInfo'
import pageData from '../page/pageData'
import constants from '../platform/common/constants'
import mlUtils from '../utils/multilingual'
import utils from '../utils/utils'
import getAppStudioData from './getAppStudioData'

let WIDGETS_CACHE = {}
let VARIATIONS_CACHE = {}

function cleanCache() {
    WIDGETS_CACHE = {}
    VARIATIONS_CACHE = {}
}

const {dataValidators} = documentServicesSchemas.services
const {DATA_TYPES} = santaCoreUtils.constants

const getWidgetPointerByWidgetId = (ps: PS, widgetId: string) => ps.pointers.data.getDataItemFromMaster(widgetId)

const getDefinitionPointerByDefinitionId = (ps: PS, definitionId: string) =>
    ps.pointers.data.getDataItemFromMaster(definitionId)

function getNewDataId() {
    return dataIds.generateNewId(DATA_TYPES.data)
}

const getData = (ps: PS, pointer: Pointer) => {
    const nonTranslatablePointer = mlUtils.getNonTranslatablePointer(ps, pointer)
    return ps.dal.get(nonTranslatablePointer)
}

function getNewDataItemPointer(ps: PS) {
    const dataId = getNewDataId()!
    return ps.pointers.data.getDataItemFromMaster(dataId)
}

function serializeWidget(ps: PS, widgetPointer: Pointer) {
    const widgetData = getData(ps, widgetPointer)
    const data: any = _.pick(widgetData, [
        'name',
        'widgetApi',
        'rootCompId',
        'devCenterWidgetId',
        'defaultSize',
        'kind'
    ])

    data.panels = _(widgetData.panels || [])
        .map(panelId => utils.stripHashIfExists(panelId))
        .map(panelId => {
            const panelData = dataModel.getDataItemById(ps, panelId)

            if (!panelData) {
                throw new Error('Invalid data. Panel was not found in dataModel')
            }
            return _.pick(panelData, ['rootCompId', 'height', 'helpId', 'kind', 'name', 'pageUrl', 'title'])
        })
        .keyBy(({rootCompId}) => utils.stripHashIfExists(rootCompId))
        .value()

    data.variations = _.map(widgetData.variations, variationId => {
        const pointer = ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(variationId))
        const variationData = ps.dal.get(pointer)
        return _.pick(variationData, ['rootCompId', 'name'])
    })
    data.presets = _.map(widgetData.presets, presetId => {
        const pointer = ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(presetId))
        const presetData = getData(ps, pointer)
        return _.pick(presetData, ['presetId', 'name', 'defaultSize'])
    })
    return data
}

function getWidgetPanelsPointers(ps: PS, widgetPointer: Pointer) {
    const widgetData = getData(ps, widgetPointer)
    return _.map(widgetData.panels || [], panelId =>
        ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(panelId))
    )
}

function getWidgetPanelsData(ps: PS, widgetPointer: Pointer) {
    const widgetData = getData(ps, widgetPointer)
    return widgetData.panels.map(panelId => dataModel.getDataItemById(ps, utils.stripHashIfExists(panelId)))
}

function getAllPanels(ps: PS) {
    const appStudioData = getAppStudioData(ps) || {}
    return _(appStudioData.widgets)
        .map(widget => widget.panels)
        .flatten()
        .map(panel => ({
            pointer: panel && ps.pointers.data.getDataItemFromMaster(panel.id),
            ...panel
        }))
        .value()
}

function getAllSerializedWidgets(ps: PS) {
    return _.map(getAllWidgets(ps), widget => serializeWidget(ps, widget.pointer))
}

export interface WidgetInfo {
    pointer: Pointer
    name: string
    panels?: any
    variations?: any
}

function getAllWidgets(ps: PS): WidgetInfo[] {
    const appStudioData = getAppStudioData(ps) || {}

    return _.map(appStudioData.widgets, function (widget) {
        const widgetPointer = getWidgetPointerByWidgetId(ps, widget.id)
        const widgetDataItem = getData(ps, widgetPointer)
        return {
            pointer: widgetPointer,
            name: widgetDataItem.name,
            panels: widgetDataItem.panels,
            variations: widgetDataItem.variations
        }
    })
}

function findWidgetByPageId(ps: PS, pageId: string): any {
    const allWidgets = getAllWidgets(ps)

    const widget = _.find(allWidgets, currentWidget => {
        const widgetData = getData(ps, currentWidget.pointer)
        return widgetData && widgetData.rootCompId === `#${pageId}`
    })

    return widget ?? _.get(findVariationByPageId(ps, pageId), 'widget')
}

function findPanelByPageId(ps: PS, pageId: string) {
    const allPanels = getAllPanels(ps)
    const result = _.find(allPanels, panel => {
        const panelData = getData(ps, panel.pointer)
        return panelData && panelData.rootCompId === `#${pageId}`
    })
    return result
}

function isWidgetPage(ps: PS, pageId: string) {
    if (!pageData.doesPageExist(ps, pageId)) {
        return false
    }

    if (_.isUndefined(WIDGETS_CACHE[pageId])) {
        WIDGETS_CACHE[pageId] = Boolean(findWidgetByPageId(ps, pageId))
    }
    return WIDGETS_CACHE[pageId] || isVariationPage(ps, pageId)
}

function isPanelPage(ps: PS, pageId: string) {
    if (!pageData.doesPageExist(ps, pageId)) {
        return false
    }
    return Boolean(findPanelByPageId(ps, pageId))
}

function findVariationByPageId(ps: PS, pageId: string) {
    const allWidgets = getAllWidgets(ps)
    let variationId
    const widget = _.find(allWidgets, currentWidget => {
        variationId = _.find(currentWidget.variations, currentVariationId => {
            const pointer = ps.pointers.data.getDataItemFromMaster(utils.stripHashIfExists(currentVariationId))
            const variationData = ps.dal.get(pointer)
            return _.get(variationData, 'rootCompId') === `#${pageId}`
        })
        return variationId
    })
    return {
        variationId,
        widget
    }
}

function isVariationPage(ps: PS, pageId: string) {
    if (!pageData.doesPageExist(ps, pageId)) {
        return false
    }

    if (_.isUndefined(VARIATIONS_CACHE[pageId])) {
        VARIATIONS_CACHE[pageId] = Boolean(_.get(findVariationByPageId(ps, pageId), 'variationId'))
    }

    return VARIATIONS_CACHE[pageId]
}

function getAllSerializedCustomDefinitions(ps: PS) {
    const appStudioData = getAppStudioData(ps) || {}

    return _.map(appStudioData.customDefinitions, 'structure')
}

function getAllCustomDefinitions(ps: PS) {
    const appStudioData = getAppStudioData(ps) || {}

    return _.map(appStudioData.customDefinitions, function (definition) {
        const pointer = getDefinitionPointerByDefinitionId(ps, definition.id)
        const definitionData = definition.structure
        const definitionName = _.head(_.keys(definitionData))
        const definitionTitle = definitionData[definitionName].title
        return {
            pointer,
            name: definitionName,
            title: definitionTitle,
            type: definitionData[definitionName].$ref || definitionData[definitionName].type
        }
    })
}

function getSerializedCustomDefinition(ps: PS, pointer: Pointer) {
    return _.get(ps.dal.get(pointer), 'structure')
}

function getAppStudioMetaData(ps: PS) {
    const appStudioData = getAppStudioData(ps)
    return appStudioData ? _.pick(appStudioData, ['name', 'description']) : undefined
}

function getWidgetDevCenterId(ps: PS, widgetPointer: Pointer) {
    const widgetData = getData(ps, widgetPointer)
    return widgetData.devCenterWidgetId
}

function setWidgetDevCenterId(ps: PS, widgetPointer: Pointer, devCenterWidgetId: string) {
    const widgetData = getData(ps, widgetPointer)
    widgetData.devCenterWidgetId = devCenterWidgetId
    setWidgetData(ps, widgetPointer, widgetData)
}

const findRootWidgetByComponentType = (ps: PS, compRef: Pointer, pageId: string): Pointer => {
    const [firstChild] = componentStructureInfo.getChildren(ps, compRef)
    if (!firstChild || isRootWidgetByComponentType(ps, firstChild)) {
        return firstChild
    }
    return findRootWidgetByComponentType(ps, firstChild, pageId)
}

function getDefaultSize(ps: PS, widgetPointer: Pointer) {
    const widgetData = getData(ps, widgetPointer)
    return widgetData.defaultSize
}

function setDefaultSize(ps: PS, widgetPointer: Pointer, size, callback?: Callback) {
    const widgetData = getData(ps, widgetPointer)
    widgetData.defaultSize = size
    setWidgetData(ps, widgetPointer, widgetData)
    if (callback) {
        callback()
    }
}

const getRootWidgetByPage = (ps: PS, pagePointer: Pointer) => {
    if (!isWidgetPage(ps, pagePointer.id) && !isPanelPage(ps, pagePointer.id)) {
        return
    }
    return findRootWidgetByComponentType(ps, pagePointer, pagePointer.id)
}

function updateAppStudioMetaData(ps: PS, appStudioData) {
    const currentAppStudioData = getAppStudioData(ps)

    if (_.isUndefined(currentAppStudioData)) {
        throw new Error('appStudio: there is no app studio to update')
    }

    const newAppStudioData = _.assign({}, currentAppStudioData, appStudioData)
    updateAppStudioOnMasterPage(ps, newAppStudioData)
}

function updateAppStudioOnMasterPage(ps: PS, newAppStudio) {
    const masterPagePointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
    const masterPageData = getData(ps, masterPagePointer)
    masterPageData.appStudioData = newAppStudio
    const useLanguage = mlUtils.getLanguageByUseOriginal(ps, true)
    dataModel.addSerializedDataItemToPage(
        ps,
        santaCoreUtils.siteConstants.MASTER_PAGE_ID,
        masterPageData,
        santaCoreUtils.siteConstants.MASTER_PAGE_ID,
        useLanguage
    )
}

/**
 * Get a map of all widgets each pointing from widgetPointerId to an array of contained widgets' widgetPointerIds
 */
function getContainingWidgetsMap(ps: PS) {
    const widgets = _.map(getAllWidgets(ps), 'pointer')
    const widgetsMap = {}
    _.forEach(widgets, widgetPointer => {
        if (!widgetsMap[widgetPointer.id]) {
            widgetsMap[widgetPointer.id] = getContainedWidgets(ps, widgetPointer, widgetsMap)
        }
    })
    return widgetsMap
}

/**
 * Get all widgets contained in widget (widgetPointer)
 * @param ps
 * @param widgetPointer - containing widget
 * @param widgetsMap - a map of all widgets, each pointing from a widgetPointerId to an array of contained widgets - each represented by widgetPointerId
 */
function getContainedWidgets(ps: PS, widgetPointer: Pointer, widgetsMap) {
    const refChildren = getFirstLevelRefChildren(ps, widgetPointer)
    let containedWidgets: string[] = []

    _.forEach(refChildren, ref => {
        const widgetChildPointer = getWidgetPointerByRefComp(ps, ref)
        if (widgetChildPointer) {
            if (!widgetsMap[widgetChildPointer.id]) {
                widgetsMap[widgetChildPointer.id] = getContainedWidgets(ps, widgetChildPointer, widgetsMap)
            }
            containedWidgets = _.concat(containedWidgets, widgetChildPointer.id, widgetsMap[widgetChildPointer.id])
        }
    })
    return _.uniq(containedWidgets)
}

/**
 * Get first layer of widgets contained in widget pointer
 * @param ps
 * @param widgetPointer - Containing widget
 * @return array of compRefs all of type  wysiwyg.viewer.components.RefComponent
 */
function getFirstLevelRefChildren(ps: PS, widgetPointer: Pointer) {
    const widgetRef = getAppWidgetRefFromPointer(ps, widgetPointer)
    const children = componentStructureInfo.getChildrenFromFull(ps, widgetRef, true)
    return _.filter(
        children,
        child => componentStructureInfo.getType(ps, child) === 'wysiwyg.viewer.components.RefComponent'
    )
}

/**
 * Get appWidgetCompRef from its widgetPointer
 * @param ps
 * @param widgetPointer
 * @return appWidget compRef
 */
function getAppWidgetRefFromPointer(ps: PS, widgetPointer: Pointer) {
    const pageRef = getPageByWidgetPointer(ps, widgetPointer)
    return getRootWidgetByPage(ps, pageRef)
}

const getRootContainerByWidgetPointer = (ps: PS, widgetPointer: Pointer) => {
    const pageRef = getPageByWidgetPointer(ps, widgetPointer)
    return _.head(componentStructureInfo.getChildren(ps, pageRef))
}

const isRootWidgetByComponentType = (ps: PS, compRef) =>
    componentStructureInfo.getType(ps, compRef) === constants.CONTROLLER_TYPES.APP_WIDGET

function getRootCompIdByPointer(ps: PS, pointer: Pointer) {
    const widgetData = getData(ps, pointer)

    if (widgetData?.rootCompId) {
        return _.replace(widgetData.rootCompId, '#', '')
    }
}

function getPageByWidgetPointer(ps: PS, pointer: Pointer) {
    const pageId = getRootCompIdByPointer(ps, pointer)
    return ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
}

/**
 * Get widgetPointer by refCompRef
 * @param ps
 * @param refComp - ref of component of type wysiwyg.viewer.components.RefComponent
 * @return widgetPointer
 */
function getWidgetPointerByRefComp(ps: PS, refComp) {
    const {pageId: widgetPageId, type} = dataModel.getDataItem(ps, refComp)
    if (type === 'InternalRef') {
        return getWidgetByRootCompId(ps, widgetPageId)
    }
}

function getWidgetByRootCompId(ps: PS, rootCompId: string) {
    const widget = findWidgetByPageId(ps, rootCompId)
    return widget?.pointer
}

const updateWidgetContainedWidgets = (ps: PS) => {
    const containedWidgetsMap = getContainingWidgetsMap(ps)
    _.forEach(containedWidgetsMap, (containedWidgets, containingWidgetPointerId) => {
        const widgetPointer = ps.pointers.data.getDataItemFromMaster(containingWidgetPointerId)
        const widgetData = getData(ps, widgetPointer)
        widgetData.containedWidgets = _.map(containedWidgets, widgetPointerId => `#${widgetPointerId}`)
        setWidgetData(ps, widgetPointer, widgetData)
    })
}

function updateWidgetApi(ps: PS, widgetPointer: Pointer, widgetData, apiPart, newData) {
    widgetData.widgetApi[apiPart] = newData
    setWidgetData(ps, widgetPointer, widgetData)
}

function setWidgetData(ps: PS, widgetPointer: Pointer, newData) {
    dataValidators.validateDataBySchema(newData, 'data')
    setData(ps, widgetPointer, newData)
}

const setData = (ps: PS, pointer: Pointer, data) => {
    const nonTranslatablePointer = mlUtils.getNonTranslatablePointer(ps, pointer)
    return ps.dal.set(nonTranslatablePointer, data)
}

const mergeData = (ps: PS, pointer: Pointer, data) => {
    const nonTranslatablePointer = mlUtils.getNonTranslatablePointer(ps, pointer)
    return ps.dal.merge(nonTranslatablePointer, data)
}

export default {
    getNewDataId,
    getNewDataItemPointer,
    getAppWidgetRefFromPointer,
    getWidgetPanelsPointers,
    getAllPanels,
    setWidgetData,
    getData,
    setData,
    mergeData,
    isWidgetPage,
    updateWidgetContainedWidgets,
    updateWidgetApi,
    getWidgetPointerByRefComp,
    getRootWidgetByPage,
    getRootContainerByWidgetPointer,
    getFirstLevelRefChildren,
    getWidgetByRootCompId,
    getRootCompIdByPointer,
    getPageByWidgetPointer,
    updateAppStudioOnMasterPage,
    updateAppStudioMetaData,
    getContainedWidgets,
    getContainingWidgetsMap,
    isVariationPage,
    getWidgetPointerByWidgetId,
    findWidgetByPageId,
    findVariationByPageId,
    getAllWidgets,
    getAppStudioData,
    getAppStudioMetaData,
    serializeWidget,
    getAllSerializedWidgets,
    getAllCustomDefinitions,
    getAllSerializedCustomDefinitions,
    getSerializedCustomDefinition,
    getWidgetDevCenterId,
    setWidgetDevCenterId,
    setDefaultSize,
    getDefaultSize,
    getWidgetPanelsData,

    //only used in tests
    cleanCache
}
