import type {Pointer, PS, UrlFormat} from '@wix/document-services-types'
import {socialAPI} from '@wix/santa-ds-libs/src/utils'
import _ from 'lodash'
import dataModel from '../../../dataModel/dataModel'
import page from '../../../page/page'
import generalInfo from '../../../siteMetadata/generalInfo'

function updateComponentData(ps: PS, compPointer: Pointer, compData) {
    const args = [ps, compPointer, compData]
    const params = {
        methodName: 'components.data.update'
    }
    ps.setOperationsQueue.runSetOperation(dataModel.updateDataItem, args, params)
}

function updateUrlFormatIfNeeded(ps: PS, compPointer: Pointer, urlFormat: string) {
    const compData = dataModel.getDataItem(ps, compPointer)

    if (compData && compData.urlFormat !== urlFormat) {
        compData.urlFormat = urlFormat
        updateComponentData(ps, compPointer, compData)
    }
}

function enableHttps(ps: PS, compPointer: Pointer) {
    const compData = dataModel.getDataItem(ps, compPointer)
    if (compData) {
        compData.isHttpsEnabled = true
        updateComponentData(ps, compPointer, compData)
    }
}

function upgradeUrl(ps: PS, compPointer: Pointer, socialType?, options?) {
    const urlFormats = generalInfo.getPossibleUrlFormats()
    const hashBangUrl = page.getSocialUrl(ps, urlFormats.HASH_BANG, options?.forceMainPage)

    if (generalInfo.isHttpsEnabled(ps)) {
        enableHttps(ps, compPointer)
    }

    if (generalInfo.isUsingUrlFormat(ps, urlFormats.HASH_BANG as UrlFormat)) {
        updateUrlFormatIfNeeded(ps, compPointer, urlFormats.HASH_BANG)
    } else if (socialType) {
        _.get(socialAPI, socialType)(hashBangUrl, isActiveSocialLink => {
            updateUrlFormatIfNeeded(ps, compPointer, isActiveSocialLink ? urlFormats.HASH_BANG : urlFormats.SLASH)
        })
    }
}

export default {
    upgradeUrl
}
