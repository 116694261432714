import type {Pointer, PS} from '@wix/document-services-types'
import constants from '../constants/constants'
import {pointerUtils} from '@wix/document-manager-core'
import dsUtils from '@wix/document-services-implementation/src/utils/utils'
import variants from '../variants/variants'
import dataModel from '../dataModel/dataModel'
import namespaces from '../namespaces/namespaces'

const {patterns: PATTERNS_NAMESPACE, variants: VARIANTS_NAMESPACE} = constants.DATA_TYPES
const {REPEATER_PATTERN, REPEATER_PATTERN_LIST} = constants.VARIANTS.TYPES
const {VARIANTS_QUERY} = constants.VARIANTS
const PATTERNS_DATA_TYPES = {
    NTH_CHILD_TYPE: 'NthChild',
    PATTERN_TYPE: 'Pattern'
}

export interface Rule {
    offset: number
    repeat: number
    variant: Pointer
}

export interface NthChild {
    type: string
    offset: number
    repeat: number
    variant: string
}

export interface Pattern {
    type: string
    items: NthChild[]
}

export interface RepeaterPatternVariant {
    id?: string
    componentId?: string
    type?: string
    tag: string
}

const transformRuleToNthChild = ({offset, repeat, variant}: Rule): NthChild => ({
    type: PATTERNS_DATA_TYPES.NTH_CHILD_TYPE,
    offset,
    repeat,
    variant: `#${variant.id}`
})

const transformNthChildToRule = ({repeat, offset, variant}): Rule => ({
    repeat,
    offset,
    variant: pointerUtils.getPointer(dsUtils.stripHashIfExists(variant), constants.DATA_TYPES.variants)
})

const apply = (ps: PS, compPointer: Pointer, rules: Rule[]): void => {
    const pattern: Pattern = {
        type: PATTERNS_DATA_TYPES.PATTERN_TYPE,
        items: rules.map(transformRuleToNthChild)
    }

    namespaces.updateNamespaceData(ps, compPointer, PATTERNS_NAMESPACE, pattern)
}

const get = (ps: PS, compPointer: Pointer): Rule[] => {
    const pattern = namespaces.getNamespaceData(ps, compPointer, PATTERNS_NAMESPACE)

    return (pattern?.items ?? []).map(transformNthChildToRule)
}

const getRepeaterPatternVariantToAddRef = (ps: PS, compPointer: Pointer) =>
    variants.getVariantToAddRef(ps, compPointer, REPEATER_PATTERN)

const createRepeaterPatternVariantData = (
    variantPointer: Pointer,
    compPointer: Pointer,
    patternData: RepeaterPatternVariant
): RepeaterPatternVariant => ({
    id: variantPointer.id,
    type: REPEATER_PATTERN,
    componentId: compPointer.id,
    tag: patternData.tag
})

const createRepeaterPatternVariant = (
    ps: PS,
    variantToAddRef: Pointer,
    compPointer: Pointer,
    patternData: RepeaterPatternVariant
): void => {
    const variantToAdd: RepeaterPatternVariant = createRepeaterPatternVariantData(
        variantToAddRef,
        compPointer,
        patternData
    )
    const variantsListId = dataModel.getComponentDataItemId(ps, compPointer, VARIANTS_QUERY)
    const variantsListPointer = ps.pointers.getPointer(variantsListId, VARIANTS_NAMESPACE)
    const currentVariantsList = (variantsListPointer && variants.getData(ps, variantsListPointer)) || {values: []}
    currentVariantsList.values.push(variantToAdd)
    const compVariantsListId = variants.createVariantsList(
        ps,
        variantsListId,
        compPointer,
        REPEATER_PATTERN_LIST,
        currentVariantsList
    )

    if (!variantsListId && compVariantsListId) {
        dataModel.linkComponentToItem(ps, compPointer, compVariantsListId, VARIANTS_QUERY)
    }
}

const getVariantsForItem = (ps: PS, itemPointer: Pointer): Pointer[] =>
    ps.extensionAPI.patterns.getVariantsForItem(itemPointer)

const getRepeaterPatternVariants = (ps: PS, compPointer: Pointer): Pointer[] =>
    dataModel.getVariantsDataByVariantType(ps, REPEATER_PATTERN, {compPointer})

const remove = (ps: PS, compPointer: Pointer): void => {
    const repeaterPatternsToRemove = getRepeaterPatternVariants(ps, compPointer)
    repeaterPatternsToRemove.forEach(repeaterPatternPointer => variants.remove(ps, repeaterPatternPointer))
    dataModel.removePattern(ps, compPointer)
}

export default {
    apply,
    get,
    createRepeaterPatternVariant,
    getRepeaterPatternVariantToAddRef,
    getVariantsForItem,
    remove,
    getRepeaterPatternVariants
}
