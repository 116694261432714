import constants from '../../constants/constants'
import metaDataUtils from '../metaDataUtils'

export default {
    isRepeatable: false,
    isTightContainer: true,
    minimalChildrenNumber: 1,
    shouldKeepChildrenInPlace: false,
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: false,
    moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],
    resizableSides: [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM],
    fullWidth: true,
    fullWidthByStructure: true,
    containableByStructure: metaDataUtils.containableByFullWidthPopup,
    mobileConversionConfig: {
        structuralContainer: true,
        nestOverlayingSiblings: false,
        isScreenWidth: true,
        filterChildrenWhenHidden: true,
        marginX: 0,
        preserveAspectRatio: false
    }

    //because of the support of master components inside the StateStrip that can be of any type we can not restrict the child types
    //allowedChildTypes: slideShowCommon.getMatchingChildSlideTypes('wysiwyg.viewer.components.StateStrip')
}
