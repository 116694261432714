import _ from 'lodash'
import experiment from 'experiment'
import META_DATA_TYPES from './metaDataTypes'
import componentsMetaDataBundle from 'wix-ui-santa/componentsMetaData.bundle'

const EXTERNAL_META_DATA_ALLOWED_TYPES = [
    META_DATA_TYPES.DISABLEABLE,
    META_DATA_TYPES.ROTATABLE,
    META_DATA_TYPES.MOVE_DIRECTIONS,
    META_DATA_TYPES.RESIZABLE_SIDES,
    META_DATA_TYPES.FIXED_POSITION,
    META_DATA_TYPES.LAYOUT_LIMITS,
    META_DATA_TYPES.MOBILE_CONVERSION_CONFIG,
    META_DATA_TYPES.DEFAULT_MOBILE_PROPERTIES,
    META_DATA_TYPES.IS_REPEATABLE,
    META_DATA_TYPES.STYLE_CAN_BE_APPLIED,
    META_DATA_TYPES.CAN_BE_STRETCHED,
    META_DATA_TYPES.ALLOWED_CHILD_TYPES,
    META_DATA_TYPES.ALLOWED_PARENT_TYPES,
    META_DATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_WIDTH,
    META_DATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_HEIGHT,
    META_DATA_TYPES.IS_PUBLIC_CONTAINER,
    META_DATA_TYPES.A11Y_CONFIGURABLE
]

const externalComponentMetaDataOverrides = {
    isExternal: true
}

const externaComponentMetaDataDefaults = {
    isRepeatable: false
}

const getMetaDataToRegister = metaData =>
    _(metaData)
        .pick(EXTERNAL_META_DATA_ALLOWED_TYPES)
        .defaults(externaComponentMetaDataDefaults)
        .assign(externalComponentMetaDataOverrides)
        .value()

const getMetaDataMap = () =>
    experiment.isOpen('dm_deprecateWixUiSanta')
        ? {}
        : _.mapValues(componentsMetaDataBundle.default, getMetaDataToRegister)

export default {
    getMetaDataToRegister,
    getMetaDataMap,
    EXTERNAL_META_DATA_ALLOWED_TYPES
}
