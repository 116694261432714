//HTMLSRCR-1183 -- see todo.md
import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import hooks from '../hooks/hooks'
import mainMenu from '../menu/mainMenu'
import page from '../page/page'
import pageData from '../page/pageData'
import platform from '../platform/platform'
import wixCode from '../wixCode/wixCode'
import routersGetters from './routersGetters'
import constants from './utils/constants'
import routersBackendRequests from './utils/routersBackendRequests'
import routersUtils from './utils/routersUtils'
import {asArray} from '@wix/document-manager-utils'

const {ROUTER_TYPE} = constants

/*****************************************  Private Functions  ****************************************************/
function getMenuItemIfSubPage(menuItems, pageId: string) {
    let res: {
        isSubPage: boolean
        menuItemId?: string
    } = {isSubPage: false}
    _.forEach(menuItems, menuItem => {
        const subItems = menuItem.items || []
        _.forEach(subItems, subItem => {
            const subItemPageId = subItem.link?.pageId?.slice(1) ?? ''
            if (subItemPageId === pageId) {
                res = {
                    isSubPage: true,
                    menuItemId: subItem.id
                }
            }
        })
    })
    return res
}

function getSubItems(menuItems, pageId: string) {
    let subItems = []
    _.forEach(menuItems, menuItem => {
        const menuItemPageId = _.get(menuItem, 'link.pageId')
        if (menuItemPageId && menuItemPageId.slice(1) === pageId) {
            subItems = menuItem.items
        }
    })
    return subItems
}

function makeSubPageMainPage(ps: PS, pageId: string) {
    const menuItems = mainMenu.getMenu(ps)
    const subPage = getMenuItemIfSubPage(menuItems, pageId)
    if (subPage.isSubPage) {
        mainMenu.moveItem(ps, subPage.menuItemId, null, menuItems.length)
    }
}

function removeSubPageForPage(ps: PS, pageId: string) {
    const menuItems = mainMenu.getMenu(ps)
    const subPages = getSubItems(menuItems, pageId)
    _.forEach(subPages, (subPage, index) => {
        mainMenu.moveItem(ps, subPage.id, null, menuItems.length + index)
    })
}

function initRoutersConfigMapIfNeeded(ps: PS) {
    const allRouters = routersGetters.get.all(ps)

    if (_.isEmpty(allRouters)) {
        const routersPointer = ps.pointers.routers.getRoutersPointer()
        ps.dal.set(routersPointer, {
            configMap: {}
        })
    }
}

function generateRouterPages(pageRoles, pageId: string) {
    return _.transform(
        pageRoles,
        (acc, role) => {
            acc[role] = pageId
        },
        {}
    )
}

function getPageRoles(routerPages, pageId: string) {
    const pageRoles = _.invertBy(routerPages)
    return pageRoles[pageId]
}

function getPageVariations(pages, roleVariations): {pageVariations?: Record<string, string[]>} {
    if (!roleVariations) {
        return {}
    }

    return {
        pageVariations: Object.keys(roleVariations).reduce((acc, roleName) => {
            acc[pages[roleName]] = roleVariations[roleName].reduce((list, replacerRole) => {
                if (replacerRole !== roleName) {
                    list.push(pages[replacerRole])
                }
                return list
            }, [])

            return acc
        }, {})
    }
}

/*****************************************  Public Functions  ****************************************************/

function getRouterPointer(ps: PS) {
    const allRouters = routersGetters.get.all(ps)
    const routerId = routersUtils.generateRouterId(allRouters)
    initRoutersConfigMapIfNeeded(ps) // todo: change to getConfigMap - get or create config map if neede
    const routersConfigMapPointer = ps.pointers.routers.getRoutersConfigMapPointer()
    ps.pointers.getInnerPointer(routersConfigMapPointer, routerId)
    return ps.pointers.routers.getRouterPointer(routerId)
}

/*newRouterPointer - received automatically from getRouterPointer*/
function addRouter(ps: PS, routerRef, newRouter) {
    const routerToAdd = _.clone(newRouter)
    //init config field to be an object is undefined
    if (routerToAdd.config) {
        if (_.isObject(routerToAdd.config)) {
            routerToAdd.config = JSON.stringify(routerToAdd.config)
        }
    } else {
        routerToAdd.config = JSON.stringify({})
    }
    routersUtils.validateNewRouter(ps, routerToAdd)
    ps.dal.set(routerRef, routerToAdd)
    hooks.executeHook(hooks.HOOKS.ROUTER.AFTER_ADD, ROUTER_TYPE, [ps, routerRef])
    return routerRef
}

function removeRouter(ps: PS, routerRef) {
    const allRouters = routersGetters.get.all(ps)
    const routerData = routersGetters.get.byRef(ps, routerRef)
    const routerId = _.findKey(allRouters, {prefix: routerData.prefix})

    hooks.executeHook(hooks.HOOKS.ROUTER.BEFORE_REMOVE, ROUTER_TYPE, [ps, routerRef])

    _.forEach(routerData.pages, function (pageId) {
        const pageRef = page.getReference(ps, pageId)
        disconnectPageFromRouter(ps, routerRef, pageRef)
    })

    delete allRouters[routerId]
    const routersConfigMapPointer = ps.pointers.routers.getRoutersConfigMapPointer()
    ps.dal.set(routersConfigMapPointer, allRouters)
}

function updateRouter(ps: PS, routerPtr: Pointer, updateData) {
    ps.extensionAPI.routers.updateRouter(routerPtr, updateData)
}

function getRouterSiteMap(ps: PS, routerId, callback) {
    const routerDefinition = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())[routerId]
    const routerBackEndParamObj = routersBackendRequests.makeParamObjFromPs(ps, routerDefinition)
    // eslint-disable-next-line promise/prefer-await-to-then
    wixCode.fileSystem.flush(ps, {origin: wixCode.fileSystem.FLUSH_ORIGINS.ROUTERS_PAGE}).then(function () {
        routersBackendRequests.getInnerRoutesSiteMap(
            routerBackEndParamObj,
            function (siteMap) {
                callback(siteMap)
            },
            function () {
                callback()
            }
        )
    })
}

function getPageFromInnerRoute(ps: PS, routerId, innerRoute, callback) {
    getRouterSiteMap(ps, routerId, siteMap => {
        if (siteMap) {
            const currRoute = _.find(siteMap, {url: innerRoute})
            callback(currRoute ? currRoute.pageName : null)
        } else {
            callback()
        }
    })
}

function getRouterInnerRoutes(ps: PS, routerId, pageId: string, callback) {
    getRouterSiteMap(ps, routerId, siteMap => callback(siteMap ? _.filter(siteMap, {pageName: pageId}) : undefined))
}

function getRouterInnerRoutesCount(ps: PS, routerId, pageId: string, callback) {
    const routerDefinition = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())[routerId]
    const routerBackEndParamObj = routersBackendRequests.makeParamObjFromPs(ps, routerDefinition)
    // eslint-disable-next-line promise/prefer-await-to-then
    wixCode.fileSystem.flush(ps, {origin: wixCode.fileSystem.FLUSH_ORIGINS.ROUTERS_ROUTES_COUNT}).then(function () {
        routersBackendRequests.getInnerRoutesSiteMapCount(
            routerBackEndParamObj,
            function (siteMapCount) {
                callback(siteMapCount)
            },
            function () {
                callback()
            }
        )
    })
}

function getTpaInnerRoutes(ps: PS, appDefinitionId, subPage, callback) {
    routersBackendRequests.getTpaRoutesFromSiteStructure(
        ps,
        appDefinitionId,
        subPage,
        function (response) {
            const results = _.get(response, 'results', [])
            callback(results)
        },
        function () {
            callback([])
        }
    )
}

function getCurrentInnerRoute(ps: PS) {
    const currentPageId = ps.siteAPI.getPrimaryPageId()
    const routerData = routersGetters.getRouterDataForPageIfExist(ps, currentPageId)
    if (!routerData) {
        return {isDynamic: false}
    }
    const pageInfo = ps.siteAPI.getRootNavigationInfo()
    if (pageInfo.routerDefinition) {
        if (!pageInfo.pageAdditionalData) {
            return {isDynamic: true, routerId: routerData.routerId}
        }
        const pageSuffix = pageInfo.pageAdditionalData.split('/')
        const innerRoute = _.drop(pageSuffix, 1).join('/')
        return {isDynamic: true, innerRoute, routerId: routerData.routerId}
    }
    return {isDynamic: false}
}

/****************************************** pages Functions ****************************/

function getPageToAddPointer(ps: PS) {
    return page.getPageIdToAdd(ps)
}

function addNewRouterPage(ps: PS, newItemPageRef, routerPtr: Pointer, pageTitle: string, pageRoles, serializedPage?) {
    page.add(ps, newItemPageRef, pageTitle, serializedPage)
    connectPageToRouter(ps, routerPtr, newItemPageRef, pageRoles)
    return newItemPageRef
}

function findRouteByPageId(routerData, pageId: string) {
    const pageRole = _.findKey(routerData.pages, id => id === pageId)
    const {patterns} = JSON.parse(routerData.config)
    return _.findKey(patterns, definition => definition.pageRole === pageRole)
}

function getIsDynamicPageIndexable(routerData, route) {
    const config = JSON.parse(routerData.config)
    const robotsMetaTag = _.get(config.patterns[route].seoMetaTags, 'robots')
    return robotsMetaTag !== 'noindex'
}

function safelyGetIsDynamicPageIndexable(routerData, pageId: string) {
    try {
        const route = findRouteByPageId(routerData, pageId)
        return getIsDynamicPageIndexable(routerData, route)
    } catch (error) {
        return true
    }
}

function connectPageToRouter(ps: PS, routerPtr: Pointer, pagePtr: Pointer, pageRoles) {
    pageRoles = asArray(pageRoles)
    const currentPageId = _.get(ps.dal.get(pagePtr), 'id')
    //hide the dynamic page from the menu and site map
    const currentPageData = pageData.getPageData(ps, currentPageId)
    const updatedData = {hidePage: true, indexable: false}
    /* seeting just if boolean since if undefined that when the page is back to static it will keep original definition*/
    if (_.isBoolean(currentPageData.mobileHidePage)) {
        _.assign(updatedData, {mobileHidePage: true})
    }
    pageData.setPageData(ps, currentPageId, updatedData, false, true)

    const routerData = ps.dal.get(routerPtr)
    const routerInUse = routersGetters.get.byPage(ps, pagePtr)

    //todo: move to routersValidationsUtil
    if (routerInUse && !ps.pointers.isSamePointer(routerPtr, routerInUse)) {
        throw new Error('page already exist on another router')
    } else if (page.homePage.get(ps) === pagePtr.id) {
        throw new Error("home page can't become dynamic page")
    }

    if (routerInUse) {
        routerData.pages = _(routerData.pages)
            .omitBy(pageId => pageId === currentPageId)
            .assign(generateRouterPages(pageRoles, currentPageId))
            .value()
    } else {
        //todo - if pages is empty obj by default so use assign instead of merge
        routerData.pages = _.merge(
            routerData.pages || {},
            _.omitBy(generateRouterPages(pageRoles, currentPageId), _.isUndefined)
        )
        //routerData.pages = _.merge(routerData.pages || {}, _.compact(generateRouterPages(pageRoles, currentPageId)));
    }
    makeSubPageMainPage(ps, pagePtr.id)
    removeSubPageForPage(ps, pagePtr.id)
    ps.dal.set(routerPtr, routerData)
}

function disconnectPageFromRouter(ps: PS, routerPtr: Pointer, pagePtr: Pointer) {
    const pageId = _.get(ps.dal.get(pagePtr), 'id')
    const routerData = ps.dal.get(routerPtr)
    const isPageBelongsRouter = routerData.pages && _.includes(_.values(routerData.pages), pageId)

    //hide the dynamic page from the menu and site map
    if (!isPageBelongsRouter) {
        throw new Error('the page is not connected to this router')
    }
    removePageFromRouter(ps, routerPtr, pageId)
    const currPageData = pageData.getPageData(ps, pageId)
    const staticPageUriSeo = pageData.getValidPageUriSEO(ps, pageId, currPageData.title)
    if (ps.siteAPI.getPrimaryPageId() === pagePtr.id) {
        page.navigateTo(ps, pagePtr.id)
    }

    const indexable = safelyGetIsDynamicPageIndexable(routerData, pageId)

    pageData.setPageData(
        ps,
        pageId,
        {
            pageUriSEO: staticPageUriSeo,
            title: currPageData.title,
            hidePage: false,
            indexable
        },
        false,
        true
    )
}

function removePageFromRouter(ps: PS, routerPtr: Pointer, pageId: string) {
    const routerData = ps.dal.get(routerPtr)
    const pageRoles = getPageRoles(routerData.pages, pageId)
    if (pageRoles) {
        if (routerData.roleVariations) {
            routerData.roleVariations = removePageFromRoleVariations(ps, routerData, _.last(pageRoles))
        }

        routerData.pages = _.omit(routerData.pages, pageRoles)
        ps.dal.set(routerPtr, routerData)

        // If page have variations it should disconnect each of them from the router
        pageRoles.forEach?.(pageRole => {
            if (routerData.roleVariations?.[pageRole]) {
                routerData.roleVariations[pageRole].forEach(variationRole => {
                    const pageRef = page.getReference(ps, routerData.pages[variationRole])
                    disconnectPageFromRouter(ps, routerPtr, pageRef)
                })
            }
        })
    }
}

function removePageFromRoleVariations(ps: PS, routerData, pageRoleId: string) {
    return Object.keys(routerData.roleVariations).reduce((acc, roleId) => {
        let variationsArray = routerData.roleVariations[roleId]
        if (_.last(variationsArray) === pageRoleId) {
            variationsArray.pop()
            const lastVariation = _.last(variationsArray) as string
            if (lastVariation) {
                pageData.setPageData(ps, routerData.pages[lastVariation], {hidePage: false}, false, true)
            }
        } else {
            variationsArray = variationsArray.filter(role => role !== pageRoleId)
        }

        if (variationsArray.length > 1 || (!variationsArray.includes(roleId) && variationsArray.length === 1)) {
            acc[roleId] = variationsArray
        } else if (routerData.pages[roleId]) {
            pageData.setPageData(ps, routerData.pages[roleId], {hidePage: false}, false, true)
        }

        return acc
    }, {})
}

function movePageToNewRouter(ps: PS, pagePtr: Pointer, originRouterPtr: Pointer, desRouterPtr: Pointer) {
    const pageRouterPtr = routersGetters.get.byPage(ps, pagePtr)

    if (!pageRouterPtr) {
        throw new Error('cannot move a static page')
    }

    if (!ps.pointers.isSamePointer(originRouterPtr, pageRouterPtr)) {
        throw new Error('page is not related to the origin router')
    }

    const originRouterData = ps.dal.get(originRouterPtr)
    const desRouterData = ps.dal.get(desRouterPtr)

    if (originRouterData && desRouterData && originRouterData.appDefinitionId !== desRouterData.appDefinitionId) {
        throw new Error('cannot move a page that not related to app')
    }

    if (ps.pointers.isSamePointer(originRouterPtr, desRouterPtr)) {
        return
    }

    const pageRoles = getPageRoles(originRouterData.pages, pagePtr.id)
    disconnectPageFromRouter(ps, originRouterPtr, pagePtr)
    connectPageToRouter(ps, desRouterPtr, pagePtr, pageRoles)
}

function canBeDynamic(ps: PS, currentPageData, homePageId: string, pageRef) {
    return (
        currentPageData.type === 'Page' && //it is a page (and not AppPage for example)
        !(currentPageData.tpaApplicationId > 0) && //page is not a TPA page
        !routersGetters.get.byPage(ps, pageRef) && //page is not a dynamic page
        homePageId !== currentPageData.id //page is not the home page
    )
}

function listConnectablePages(ps: PS) {
    const pageList = pageData.getPagesDataItems(ps)
    const homePageId = page.homePage.get(ps)

    return _.reduce(
        pageList,
        function (res, currentPageData) {
            const pageRef = page.getReference(ps, currentPageData.id)
            if (canBeDynamic(ps, currentPageData, homePageId, pageRef)) {
                res.push({pageRef, pageData: currentPageData})
            }
            return res
        },
        []
    )
}

function isConnectablePage(ps: PS, pageId: string) {
    const homePageId = page.homePage.get(ps)
    const thePageData = pageData.getPageData(ps, pageId)
    if (!thePageData) {
        return false
    }
    const pageRef = page.getReference(ps, pageId)
    return canBeDynamic(ps, thePageData, homePageId, pageRef)
}

function getDynamicPagesList(ps: PS) {
    return _.map(
        routersGetters.get.all(ps),
        // @ts-expect-error
        ({appDefinitionId, pages, prefix, group, roleVariations, hide = false} = {}) => {
            const {appDefinitionName} = platform.getAppDataByAppDefId(ps, appDefinitionId) || {}
            return {
                appDefinitionId,
                appDefinitionName,
                prefix,
                group,
                pages: _(pages)
                    .values()
                    .uniq()
                    .map(pageId => pageData.getPageData(ps, pageId))
                    .value(),
                hide,
                ...getPageVariations(pages, roleVariations)
            }
        }
    )
}

function buildIsValidPrefixReturnVal(isValid, errorCode, metadata?) {
    return {
        valid: isValid,
        message: errorCode,
        errorCode,
        metadata
    }
}

function getRouterAppDefinitionId(ps: PS, prefix: string) {
    const routerPointer = routersGetters.get.byPrefix(ps, prefix)
    if (routerPointer) {
        const routerData = ps.dal.get(routerPointer)
        return routerData.appDefinitionId
    }
}

function isValidPrefix(ps: PS, applicationId: number | string, prefix?: string) {
    if (!prefix) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_CAN_NOT_BE_EMPTY)
    }

    const appDefinitionId = _.get(platform.getAppDataByApplicationId(ps, applicationId), 'appDefinitionId')
    const routerAppDefinitionId = getRouterAppDefinitionId(ps, prefix)
    if (routerAppDefinitionId) {
        //existing prefix
        if (routerAppDefinitionId === appDefinitionId) {
            return buildIsValidPrefixReturnVal(true, '')
        }
        const routerAppDefinitionName = _.get(
            platform.getAppDataByAppDefId(ps, routerAppDefinitionId),
            'appDefinitionName'
        )
        return buildIsValidPrefixReturnVal(
            false,
            constants.InvalidPrefixReason.PREFIX_IS_IN_USE_BY_ANOTHER_APPLICATION,
            {
                appDefinitionName: routerAppDefinitionName
            }
        )
    }

    if (pageData.isPageUriSeoTooLong(prefix)) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_TOO_LONG)
    }
    if (pageData.isDuplicatePageUriSeo(ps, null, prefix)) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_DUPLICATE_OF_URI_SEO, {
            pageTitle: pageData.getDuplicatePageTitle(ps, null, prefix)
        })
    }
    if (pageData.hasIllegalChars(prefix)) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_CONTAINS_INVALID_CHARACTERS)
    }

    const forbiddenWordsPointer = ps.pointers.general.getForbiddenPageUriSEOs()
    const forbiddenWordsArr = ps.dal.get(forbiddenWordsPointer) || {}
    if (forbiddenWordsArr[prefix]) {
        return buildIsValidPrefixReturnVal(false, constants.InvalidPrefixReason.PREFIX_IS_FORBIDDEN_WORD)
    }
    return buildIsValidPrefixReturnVal(true, '')
}

function reloadRouterData(ps: PS) {
    const currentNavigationInfo = ps.siteAPI.getRootNavigationInfo()
    if (!currentNavigationInfo?.routerDefinition) {
        return
    }

    const navInfo = {
        innerRoute: currentNavigationInfo.innerRoute,
        routerDefinition: currentNavigationInfo.routerDefinition,
        pageAdditionalData: currentNavigationInfo.pageAdditionalData
    }
    ps.siteAPI.navigateToPage(navInfo)
    ps.siteAPI.registerNavigationComplete(() => {
        hooks.executeHook(hooks.HOOKS.ROUTER.DATA_RELOADED, null, [ps])
    })
}

function subscribeToConcurrentRoutersInvalidation(ps: PS, listener) {
    hooks.unregisterHooks([hooks.HOOKS.ROUTER.CONCURRENT_ROUTER_INVALIDATION])
    hooks.registerHook(hooks.HOOKS.ROUTER.CONCURRENT_ROUTER_INVALIDATION, listener)
}

function notifyConcurrentRoutersInvalidation(ps: PS, currentRouteInfo, newSelectedRoute) {
    ps.extensionAPI.livePreviewSharedState.notifyRoutersConcurrentInvalidationStateChanged(
        currentRouteInfo,
        newSelectedRoute
    )
}

const initialize = (ps: PS) => {
    ps.extensionAPI.livePreviewSharedState.subscribeToConcurrentRoutersInvalidation(
        (originEditorCurrentRouteInfo, selectedRoute) => {
            const routerIdChanged = originEditorCurrentRouteInfo.routerId
            const currentRouteInfo = getCurrentInnerRoute(ps)
            const routerId = _.get(currentRouteInfo, ['routerId'])
            if (routerId === routerIdChanged) {
                hooks.executeHook(hooks.HOOKS.ROUTER.CONCURRENT_ROUTER_INVALIDATION, undefined, [
                    _.isEqual(currentRouteInfo, originEditorCurrentRouteInfo) ? selectedRoute : null
                ])
            }
        }
    )
}

export default {
    initialize,
    add: addRouter,
    remove: removeRouter,
    get: {
        all: routersGetters.get.all,
        byApp: routersGetters.get.byApp,
        byRef: routersGetters.get.byRef,
        byId: routersGetters.get.byId
    },
    getRouterRef: {
        byPrefix: routersGetters.get.byPrefix,
        byPage: routersGetters.get.byPage
    },
    update: updateRouter,
    getRouterDataForPageIfExist: routersGetters.getRouterDataForPageIfExist,
    getPageFromInnerRoute,
    getRouterInnerRoutes,
    getRouterInnerRoutesCount,
    getRouterSiteMap,
    getTpaInnerRoutes,
    getCurrentInnerRoute,
    isValidPrefix,
    pages: {
        add: addNewRouterPage,
        connect: connectPageToRouter,
        disconnect: disconnectPageFromRouter,
        removePageFromRouter,
        listConnectablePages,
        isConnectablePage,
        getDynamicPagesList,
        move: movePageToNewRouter
    },
    getRouterPointer,
    getPageToAddPointer,
    reloadRouterData,
    subscribeToConcurrentRoutersInvalidation,
    notifyConcurrentRoutersInvalidation
}
