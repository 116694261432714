import structure from '../../structure/structure'
import menuContainer from '../../mobileConversion/modules/menuContainer/menuContainer'
import constants from '../../constants/constants'
import _ from 'lodash'

const moveMenuContainerToHeader = (ps, menuContainerPointer) => {
    const mobileHeaderPointer = ps.pointers.components.getHeader(constants.VIEW_MODES.MOBILE)
    structure.addCompToContainer(ps, menuContainerPointer, mobileHeaderPointer)
}

const resetToDefaultMenuContainer = (ps, menuTogglePointer) => {
    ps.dal.remove(menuTogglePointer)
    menuContainer.upgrade(ps)
}

const fixDocking = (ps, pointer) => {
    if (!pointer) {
        return
    }

    const menuContainerFromDal = ps.dal.full.get(pointer)
    const fixedMenuContainerFromDal = _.cloneDeep(menuContainerFromDal)
    const bottomDocking = _.get(fixedMenuContainerFromDal, ['layout', 'docked', 'bottom', 'px'])
    const topDocking = _.get(fixedMenuContainerFromDal, ['layout', 'docked', 'top', 'px'])

    if (bottomDocking) {
        _.set(fixedMenuContainerFromDal, ['layout', 'docked', 'bottom', 'px'], 0)
    }

    if (topDocking) {
        _.set(fixedMenuContainerFromDal, ['layout', 'docked', 'top', 'px'], 0)
    }

    if (!_.isEqual(fixedMenuContainerFromDal, menuContainerFromDal)) {
        ps.dal.full.set(pointer, fixedMenuContainerFromDal)
    }
}

function exec(ps) {
    const viewMode = constants.VIEW_MODES.MOBILE
    const mobileMasterPagePointer = ps.pointers.components.getMasterPage(viewMode)
    const menuContainerPointer = menuContainer.get(ps)
    const menuTogglePointer = menuContainer.toggle.get(ps)
    const isMenuContainerDirectChildOfMasterPage =
        menuContainerPointer &&
        ps.pointers.components.isSameComponent(
            ps.pointers.components.getParent(menuContainerPointer),
            mobileMasterPagePointer
        )

    if (isMenuContainerDirectChildOfMasterPage) {
        // this violates the rule that pinned components are always direct children of the page
        // we currently have a hook to enforce that, but made an exception for MENU_AS_CONTAINER to remain
        // backwards compatible with this logic, even though we don't understand it
        moveMenuContainerToHeader(ps, menuContainerPointer)
    } else if (menuTogglePointer && !menuContainerPointer) {
        resetToDefaultMenuContainer(ps, menuTogglePointer)
    }

    fixDocking(ps, menuContainerPointer)
}

export default {
    exec,
    name: 'menuContainerDataFixer',
    version: 1
}
