import type {SaveTaskDefinition} from '../../saveAPI/lib/registry'
import tpaEventHandlersService from '../services/tpaEventHandlersService'

const TASK_NAME = 'saveEventDispatcher'

const triggerSaveEvent = function () {
    tpaEventHandlersService.siteSaved()
}

const createTask = (): SaveTaskDefinition => ({
    partialSave(lastSavedData, currentData, resolve) {
        resolve()
        triggerSaveEvent()
    },
    fullSave(lastSavedData, currentData, resolve) {
        resolve()
        triggerSaveEvent()
    },
    firstSave(lastSavedData, currentData, resolve) {
        resolve()
        triggerSaveEvent()
    },
    saveAsTemplate(lastSavedData, currentData, resolve) {
        resolve()
    },
    publish(currentData, resolve) {
        resolve()
    },
    autosave(lastSnapshot, currentSnapshot, resolve) {
        tpaEventHandlersService.siteSaved()
        resolve()
    },
    getTaskName() {
        return TASK_NAME
    },
    getSnapshotTags() {
        return ['primary']
    }
})

export default () => createTask()
