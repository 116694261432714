import type {PS} from '@wix/document-services-types'
import component from '../../component/component'
import dataModel from '../../dataModel/dataModel'

export default (ps: PS, compPointer) => ({
    layout: {
        get: () => component.layout.get(ps, compPointer)
    },
    data: {
        get: () => dataModel.getDataItem(ps, compPointer)
    },
    properties: {
        get: () => component.properties.get(ps, compPointer)
    },
    isMobile: () => ps.pointers.components.isMobile(compPointer)
})
