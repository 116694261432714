import {
    asyncDefaultAttempt,
    GET,
    getHttpResponseExtras,
    getReportableFromError,
    HttpRequestError,
    Method,
    ReportableError
} from '@wix/document-manager-utils'
import _ from 'lodash'
import type {AdapterLogger} from '../adapter/adapterLogger'

export async function postJson(url: string, instance: string, data: any): Promise<any> {
    return fetchJsonWithAuthorization(url, instance, 'POST', data)
}

export const checkResponseForError = (response: Response, errorType: string = 'httpError', message?: string) => {
    if (!response.ok) {
        throw new HttpRequestError(response, errorType, message)
    }
}

export class ServerError extends ReportableError {
    readonly details: ServerErrorDetails
    readonly status: number

    constructor(message: string, response: Response, result: Record<string, any>) {
        super({
            errorType: 'fetchJsonWithAuthorization',
            message,
            extras: {
                ...getHttpResponseExtras(response),
                result: JSON.stringify(result)
            }
        })
        this.status = response.status
        this.details = result.details
    }
}

const substr = (str: string, end: number) => (str ?? '').substring(0, end)

export class ServerTextError extends ReportableError {
    readonly status: number

    constructor(body: string, response: Response) {
        super({
            errorType: 'fetchJsonWithAuthorization',
            message: substr(body, 20),
            extras: {
                ...getHttpResponseExtras(response),
                result: substr(body, 200)
            }
        })
        this.status = response.status
    }
}

export interface ServerErrorDetails {
    applicationError?: {
        code: string
        description: string
    }
}

export interface ServerErrorData {
    message: string
    details: ServerErrorDetails
}

const ContentType = 'Content-Type'
const ContentTypeJson = 'application/json'

const isJson = (response: Response) => {
    const contentType = response.headers.get(ContentType) ?? ''
    return contentType.startsWith(ContentTypeJson)
}

export async function fetchJsonWithAuthorization<T = any, R = any>(
    url: string,
    instance: string,
    method: Method,
    data?: R,
    logger?: AdapterLogger
): Promise<T> {
    const body: string | undefined = JSON.stringify(data)
    const requestOptions: RequestInit = {
        method,
        headers: {
            Authorization: instance,
            [ContentType]: ContentTypeJson
        },
        body,
        redirect: 'follow' as RequestRedirect
    }
    let response: Response
    try {
        response = await fetch(url, requestOptions)
    } catch (e: any) {
        logger?.captureError(
            getReportableFromError(e, {
                errorType: 'fetchError',
                message: 'fetch fetchJsonWithAuthorization',
                tags: {op: 'fetchJsonWithAuthorization', url, method, bodySize: body?.length}
            })
        )
        throw e
    }
    const toJson = () => response.json()
    if (response.ok) {
        return await toJson()
    }

    if (isJson(response)) {
        const defaultError = {message: 'Unable to parse error json'}
        const result = (await asyncDefaultAttempt(defaultError, toJson)) ?? defaultError
        throw new ServerError(result.message, response, result)
    }
    const text = await response.text()
    throw new ServerTextError(text, response)
}

export async function deleteJson(url: string, instance: string, data: any, logger?: AdapterLogger): Promise<any> {
    return fetchJsonWithAuthorization(url, instance, 'DELETE', data, logger)
}

export async function fetchJson<T = any>(url: string): Promise<T> {
    const response = await fetch(url, {method: GET})
    return await response.json()
}

export async function getJson<T = any>(url: string, instance: string, logger?: AdapterLogger): Promise<T> {
    return fetchJsonWithAuthorization<T>(url, instance, GET, logger)
}

export type QueryValue = boolean | string | number | undefined | null

export type Query = Record<string, QueryValue>

export const toQueryString = (queryParams: Query): string =>
    _(queryParams)
        .pickBy()
        .map((value, key) => `${key}=${value}`)
        .join('&')

export async function getJsonWithParams<T = any>(url: string, queryParams: Query, instance: string): Promise<T> {
    const queryString = toQueryString(queryParams)
    return getJson(`${url}?${queryString}`, instance)
}

export async function postJsonWithAuth<T = any, R = any>(url: string, instance: string, data: R): Promise<T> {
    return postJson(url, instance, data)
}
