import _ from 'lodash'
import type {DocumentManager} from '@wix/document-manager-core'
import {constants} from '@wix/document-manager-extensions'
import {DATA_TYPES} from '@wix/document-manager-extensions/dist/src/constants/constants'
import type {DataModelExtensionAPI} from '@wix/document-manager-extensions/dist/src/extensions/dataModel'
const fixerVersionsNamespace = DATA_TYPES.fixerVersions

const removeFixerVersionFromComponent = ({dal, pointers}: DocumentManager, pageId: string) => {
    _.forEach(constants.VIEW_MODES, viewMode => {
        const fixerVersionsQueryPointer = pointers.page.getFixerVersionsQueryPointer(pageId, viewMode)
        if (dal.has(fixerVersionsQueryPointer)) {
            dal.remove(fixerVersionsQueryPointer)
        }
    })
}
const cleanBrokenFixerVersions = (documentManager: DocumentManager, pageId: string) => {
    const {logger, pointers, dal, extensionAPI} = documentManager
    const fixerVersionsQueryPointer = pointers.page.getFixerVersionsQueryPointer(pageId)
    const fixerVersionsQuery = dal.get(fixerVersionsQueryPointer)
    if (fixerVersionsQuery) {
        const {dataModel} = extensionAPI as DataModelExtensionAPI
        const pageFixerVersions = dataModel.getItem(fixerVersionsQuery, fixerVersionsNamespace, pageId)
        if (!pageFixerVersions) {
            removeFixerVersionFromComponent(documentManager, pageId)
            logger.interactionStarted('removeInvalidFixerVersion', {
                extras: {pageId}
            })
        }
    }
}

const migratePage = (documentManager: DocumentManager, pageId: string) => {
    cleanBrokenFixerVersions(documentManager, pageId)
}

const name = 'removeBrokenFixerVersion'
const version = 0

export {migratePage, name, version}
