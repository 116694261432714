import _ from 'lodash'
import pathUtils from './pathUtils'

function normalizeDate(ps, now) {
    const path = pathUtils.getOffsetFromServerTimePath()
    const offset = ps.wixappsDAL.getByPath(path)
    return new Date(now - offset).toISOString()
}

export default {
    getCurrentTime(ps) {
        return normalizeDate(ps, _.now())
    },
    normalizeDate
}
