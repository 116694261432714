import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {AnyItemLayout, Component, GridItemLayout, Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {ComponentsMetadataAPI} from './componentsMetadata/componentsMetadata'
import type {DataModelExtensionAPI} from './dataModel'
import type {InternalRefApi} from './internalRef'

interface MappedLayoutComponent {
    itemLayout: AnyItemLayout
    referredComponent: Component
    componentPointer: Pointer
    layoutPointer: Pointer
}

export interface GridLayoutAPI extends ExtensionAPI {
    findNextRowInGrid(parentPointer: Pointer): {rowStart: number; rowEnd: number}
    shiftItemsToBeUnderTarget(parentPointer: Pointer, targetPointer: Pointer): void
}

interface UnderComponentResult {
    targetSpan: number
    components: UnderComponentResultComponent[]
}
interface UnderComponentResultComponent {
    grid: {rowStart: number; columnStart: number; rowEnd: number; columnEnd: number}
    ptr: Pointer
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, extensionAPI}: CreateExtArgs): ExtensionAPI => {
        const internalRef = () => extensionAPI.internalRef as InternalRefApi
        const dataModel = () => (extensionAPI as DataModelExtensionAPI).dataModel
        const componentMetaData = () => (extensionAPI as ComponentsMetadataAPI).componentsMetadata

        const componentLayoutMapper = (id: string): MappedLayoutComponent | null => {
            const componentPointer = {type: 'DESKTOP', id}
            const referredComponent = internalRef().getReferredComponent(componentPointer)

            const refLayout = dataModel().components.getItem(componentPointer, 'layout')
            if (!refLayout?.values?.length) {
                return null
            }

            const layout = refLayout.values[0]
            const layoutPointer = {type: 'layout', id: layout.id}

            if (layout.itemLayout) {
                return {
                    itemLayout: layout.itemLayout,
                    referredComponent,
                    componentPointer,
                    layoutPointer
                }
            }

            return null
        }
        const getGridItems = (parentPointer: Pointer): GridItemLayout[] => {
            const parent = dal.get(parentPointer)
            return parent.components
                .map(componentLayoutMapper)
                .filter((layoutComponent: MappedLayoutComponent | null) => {
                    return (
                        layoutComponent !== null &&
                        (!layoutComponent.referredComponent ||
                            !componentMetaData().sticksToBottom(layoutComponent.referredComponent.componentType)) &&
                        layoutComponent.itemLayout !== null &&
                        layoutComponent.itemLayout.type === 'GridItemLayout'
                    )
                })
                .map((layoutComponent: MappedLayoutComponent) => {
                    return layoutComponent.itemLayout
                })
        }

        const findItemsToShift = (parentPointer: Pointer, targetPointer: Pointer): UnderComponentResult => {
            const parent = dal.get(parentPointer)
            const target = componentLayoutMapper(targetPointer.id)
            const targetLayout = target?.itemLayout as GridItemLayout
            const {rowStart, rowEnd} = targetLayout.gridArea
            const components = parent.components
                .map(componentLayoutMapper)
                .filter((layoutComponent: MappedLayoutComponent | null) => {
                    return (
                        (layoutComponent?.componentPointer.id ?? '') !== targetPointer.id &&
                        layoutComponent !== null &&
                        layoutComponent.itemLayout !== null &&
                        layoutComponent.itemLayout.type === 'GridItemLayout' &&
                        layoutComponent.itemLayout.gridArea.rowStart >= rowStart
                    )
                })
                .map((layoutComponent: MappedLayoutComponent) => {
                    return {
                        grid: (layoutComponent.itemLayout as GridItemLayout).gridArea,
                        ptr: layoutComponent.layoutPointer
                    }
                })

            return {targetSpan: rowEnd - rowStart, components}
        }

        const findNextRowInGrid = (parentPointer: Pointer, rowSpan = 1) => {
            let rowStart = 1
            const layouts = getGridItems(parentPointer)
            if (layouts.length > 0) {
                const lowestLayout: GridItemLayout | undefined = _.maxBy(layouts, 'gridArea.rowEnd')
                rowStart = lowestLayout?.gridArea.rowEnd ?? 1
            }
            const rowEnd = rowStart + rowSpan
            return {rowStart, rowEnd}
        }

        const shiftItemsToBeUnderTarget = (parentPointer: Pointer, targetPointer: Pointer) => {
            const elementsUnderTarget = findItemsToShift(parentPointer, targetPointer)
            elementsUnderTarget.components.forEach((component: UnderComponentResultComponent) => {
                const gridPointer = {...component.ptr, innerPath: ['itemLayout', 'gridArea']}
                const grid = _.cloneDeep(dal.get(gridPointer))
                grid.rowEnd += elementsUnderTarget.targetSpan
                grid.rowStart += elementsUnderTarget.targetSpan
                dal.set(gridPointer, grid)
            })
        }

        return {
            gridLayout: {
                findNextRowInGrid,
                shiftItemsToBeUnderTarget
            }
        }
    }

    return {
        name: 'gridLayout',
        createExtensionAPI
    }
}

export {createExtension}
