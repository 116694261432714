import type {Method} from '@wix/document-manager-utils'
import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import {contextAdapter} from '../../utils/contextAdapter'
import clientSpecMapService from '../../tpa/services/clientSpecMapService'
import platformConstants from '../common/constants'
import experiment from 'experiment-amd'

const ERRORS = {
    mandatoryParam: _.template('param <%=paramName %> is mandatory'),
    appIsNotInstalled: _.template('app <%=appDefId %>  should be provisioned before cloning the data'),
    noEndPoint: _.template('app <%=appDefId %> is not integrated with cloning data from template'),
    failedFetchData: 'failed to fetch data'
}

const sendHttpRequest = async (url: string, requestType: Method, jsonData, bodyData) =>
    new Promise((resolve, reject) => {
        const onSuccess = res => resolve(res)
        const onError = (failedReq, textStatus, errorMessage) => reject(errorMessage)
        contextAdapter.actions.sendHttpRequest(url, requestType, jsonData, bodyData, onSuccess, onError)
    })

const waitForAjaxToBeResolved = async (url: string, requestType: Method, signedInstance, data) => {
    const jsonData: any = {
        dataType: 'json',
        headers: {Authorization: experiment.isOpen('dm_ajaxAuthPhase2') ? undefined : signedInstance}
    }
    if (requestType === 'POST') {
        jsonData.xhrFields = {
            withCredentials: true
        }
    }
    if (experiment.isOpen('dm_ajaxAuthPhase2')) {
        jsonData.appIdAutoAuth = platformConstants.APPS.META_SITE.applicationId
    }
    const bodyData = requestType === 'POST' ? data : null
    return await sendHttpRequest(url, requestType, jsonData, bodyData)
}

const cloneAppData = async (ps: PS, cloneAppDataUrl: string, options) =>
    await waitForAjaxToBeResolved(
        cloneAppDataUrl,
        'POST',
        clientSpecMapService.getAppData(ps, platformConstants.APPS.META_SITE.applicationId).instance,
        options
    )

const getCloneAppDataUrl = (ps: PS, appData) => {
    const appComp = _.find(appData.components, {type: 'PLATFORM'})
    return _.get(appComp, ['data', 'cloneAppDataUrl'])
}

const CLONE_TYPES = {
    COMP: 'COMP',
    APP: 'APP'
}

const canCloneData = (ps: PS, appDefinitionId: string, cloneType, options: any = {}) => {
    if (!appDefinitionId) {
        throw new Error(ERRORS.mandatoryParam({paramName: 'appDefinitionId'}))
    }
    if (!options.origin_instance_id) {
        throw new Error(ERRORS.mandatoryParam({paramName: 'origin_instance_id'}))
    }
    if (!options.origin_comp_id && cloneType === CLONE_TYPES.COMP) {
        throw new Error(ERRORS.mandatoryParam({paramName: 'origin_comp_id'}))
    }
    if (!options.target_comp_id && cloneType === CLONE_TYPES.COMP) {
        throw new Error(ERRORS.mandatoryParam({paramName: 'target_comp_id'}))
    }
    const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (cloneType === CLONE_TYPES.COMP && !existingAppData) {
        throw new Error(ERRORS.appIsNotInstalled({appDefId: appDefinitionId}))
    }
}

const onError = (ps: PS) => {
    const errorDescriptor = {
        message: ERRORS.failedFetchData,
        tags: {fetchDataFailed: true}
    }
    ps.extensionAPI.logger.captureError(new Error(errorDescriptor.message), errorDescriptor.tags)
    return false
}

const copyDataFromOriginTemplateByComp = async (ps: PS, appDefinitionId, options?) =>
    copyDataFromOriginTemplateByCloneType(ps, appDefinitionId, options, CLONE_TYPES.COMP)

const copyDataFromOriginTemplateByApp = async (ps: PS, appDefinitionId: string, appData?, options?) =>
    copyDataFromOriginTemplateByCloneType(ps, appDefinitionId, options, CLONE_TYPES.APP, appData)

const copyDataFromOriginTemplateByCloneType = async (
    ps: PS,
    appDefinitionId: string,
    options,
    cloneType?,
    _appData = undefined
) => {
    canCloneData(ps, appDefinitionId, cloneType, options)
    const appData = _appData ?? clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    const cloneAppDataUrl = getCloneAppDataUrl(ps, appData)

    if (!cloneAppDataUrl) {
        throw new Error(ERRORS.noEndPoint({appDefId: appDefinitionId}))
    }
    try {
        return await cloneAppData(ps, cloneAppDataUrl, options)
    } catch (e) {
        return onError(ps)
    }
}

export default {
    ERRORS,
    copyDataFromOriginTemplateByApp,
    copyDataFromOriginTemplateByComp
}
