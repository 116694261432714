import {CoreLogger, DAL, debug, DocumentManager} from '@wix/document-manager-core'
import {PageAPI, RelationshipsAPI, translationUtils} from '@wix/document-manager-extensions'
import type {Pointer, ResolvedReference} from '@wix/document-services-types'
import _ from 'lodash'
import {
    handleCollectedReferencesFirstPass,
    handleCollectedReferencesSecondPass
} from './collectedReferences/collectedReferences'
import type {DataItem, FormattedTranslationData, FormattedTranslationWithReferenceAnalysis, VisitedMap} from './types'

const MULTILINGUAL_TRANSLATIONS = 'multilingualTranslations'
const log = debug('mlRefFixer')

const fixMissingMultilingualRef = (dal: DAL, mlKey: string, dataItem: DataItem, ref: ResolvedReference) => {
    const innerReferencePointer = {id: mlKey, type: MULTILINGUAL_TRANSLATIONS, innerPath: ref.refInfo.path as string[]}
    const innerValue = dal.get(innerReferencePointer)

    if (dataItem.type === 'StyledText') {
        removeLinkFromStyledText(dataItem, ref.id, dal, innerReferencePointer)
    }
    if (_.isArray(innerValue)) {
        const newInnerValue = _.without(innerValue, `#${ref.id}`)
        dal.set(innerReferencePointer, newInnerValue)
    } else {
        const valueFromOriginalLanguage = dal.get({
            id: dataItem.id,
            type: ref.referencedMap,
            innerPath: ref.refInfo.path as string[]
        })
        dal.set(innerReferencePointer, valueFromOriginalLanguage)
    }
}

function removeLinkFromStyledText(dataItem: DataItem, idToRemove: string, dal: DAL, pointer: Pointer) {
    if (_.isString(dataItem.text) && dataItem.text.includes(idToRemove)) {
        //eliminate the link, keep the text
        const re = new RegExp(`<a dataquery="#${idToRemove}">(.*?)<\/a>`, 'gm')
        const newText = dataItem.text.replace(re, '$1')
        dal.set(_.assign({}, pointer, {innerPath: ['text']}), newText)
    }
}

export function captureError(logger: CoreLogger, message: string, type: string, action: string) {
    log.info(message)
    logger.interactionStarted('mlRefFixer', {
        tags: {checkType: type, action},
        extras: {message}
    })
}

function handleMultilingualMissingNodes(
    documentManager: DocumentManager,
    ref: ResolvedReference,
    dataItem: DataItem,
    mlKey: string,
    languageCode: string
) {
    const {dal, logger} = documentManager
    const existsOnTranslation = dal.has({
        id: translationUtils.getTranslationItemKey(dataItem.metaData.pageId, languageCode, ref.id),
        type: MULTILINGUAL_TRANSLATIONS
    })

    const logMessage = `${existsOnTranslation ? 'Skip f' : 'F'}ixing - Missing reference: ${ref.id} from: ${
        dataItem.id
    } ${dataItem.type} ${mlKey}, reference: ${JSON.stringify(ref)}`
    captureError(logger, logMessage, 'BrokenReferences', 'fix')
    if (!existsOnTranslation) {
        fixMissingMultilingualRef(dal, mlKey, dataItem, ref)
    }
}

function handleRedundantMultilingualNodes(
    documentManager: DocumentManager,
    formattedTranslationDataMap: Record<string, FormattedTranslationData>,
    itemTranslations: Record<string, FormattedTranslationData[]>
) {
    const {dal, logger} = documentManager
    const sameDataItemOnMoreThanOnePage = _(formattedTranslationDataMap)
        .values()
        .groupBy('dataItemId')
        .pickBy(v => _.uniqBy(v, 'pageId').length > 1)
        .value()
    //after finding which dataItems exist in more than one page, read the dataItem pageId in the original language and delete all data items from other pages
    _.forEach(sameDataItemOnMoreThanOnePage, (translatedDataItems, dataItemId) => {
        const orig = dal.get({id: dataItemId, type: 'data'})
        const rightPageId = orig?.metaData?.pageId
        _(translatedDataItems)
            .omitBy({pageId: rightPageId})
            .forEach(redundantTranslatedEntry => {
                const logMessage = `Fixing - deleted redundant entry: ${redundantTranslatedEntry.mlKey}`
                captureError(logger, logMessage, 'RedundantNodes', 'fix')
                dal.remove({id: redundantTranslatedEntry.mlKey, type: MULTILINGUAL_TRANSLATIONS})
                delete formattedTranslationDataMap[redundantTranslatedEntry.mlKey]
                _.remove(
                    itemTranslations[dataItemId],
                    formattedTranslation => formattedTranslation.mlKey === redundantTranslatedEntry.mlKey
                )
            })
    })
}

function getFormattedTranslationsData(documentManager: DocumentManager, dal: DAL) {
    const allPagesIndex = (documentManager.extensionAPI.page as PageAPI).getAllPagesIndexId()
    const translationData: Record<string, DataItem> = dal.query(MULTILINGUAL_TRANSLATIONS, allPagesIndex)

    const formattedTranslationDataMap: Record<string, FormattedTranslationData> = {}
    const itemTranslations: Record<string, FormattedTranslationData[]> = {}
    const result = {formattedTranslationDataMap, itemsTranslations: itemTranslations}
    _.transform(
        translationData,
        (acc, mlDataItem, mlKey) => {
            const [pageId, languageCode, dataItemId] = translationUtils.getTranslationInfoFromKey(mlKey)
            const formattedTranslationData = {mlDataItem, pageId, languageCode, dataItemId, mlKey}
            acc.formattedTranslationDataMap[mlKey] = formattedTranslationData
            const translations = _.get(acc.itemsTranslations, dataItemId, [] as FormattedTranslationData[])
            translations.push(formattedTranslationData)
            _.set(acc.itemsTranslations, dataItemId, translations)
        },
        result
    )

    return result
}

function handleMissingNodesAndCollectedReferences(
    formattedTranslationDataMap: Record<string, FormattedTranslationData>,
    documentManager: DocumentManager,
    itemsTranslations: Record<string, FormattedTranslationData[]>
) {
    const dataItemsWithRefs: FormattedTranslationWithReferenceAnalysis[] = _.map(formattedTranslationDataMap, v => ({
        ...v,
        refs: (documentManager.extensionAPI as RelationshipsAPI).relationships.extractReferences('data', v.mlDataItem)
    }))

    const visitedMap: VisitedMap = {}
    const {dal} = documentManager
    dataItemsWithRefs.forEach(({mlDataItem, pageId, languageCode, mlKey, refs}) => {
        _.forEach(refs, ref => {
            const referencedNodePointer = {id: ref.id, type: ref.referencedMap}
            const referencedNode = dal.get(referencedNodePointer)

            const isMissing = !referencedNode
            if (isMissing) {
                handleMultilingualMissingNodes(documentManager, ref, mlDataItem, mlKey, languageCode)
            } else {
                handleCollectedReferencesFirstPass(
                    documentManager,
                    visitedMap,
                    mlKey,
                    languageCode,
                    mlDataItem,
                    pageId,
                    ref,
                    referencedNode
                )
            }
        })
    })

    handleCollectedReferencesSecondPass(documentManager, visitedMap, itemsTranslations)
}

const migrateSite = (documentManager: DocumentManager) => {
    if ((documentManager.extensionAPI.page as PageAPI).isPartiallyLoaded()) {
        return
    }

    const {dal} = documentManager

    const {itemsTranslations, formattedTranslationDataMap} = getFormattedTranslationsData(documentManager, dal)

    handleRedundantMultilingualNodes(documentManager, formattedTranslationDataMap, itemsTranslations)

    handleMissingNodesAndCollectedReferences(formattedTranslationDataMap, documentManager, itemsTranslations)
}

const name = 'multilingualReferenceFixer'
const version = 1
const disableVersioning = true

export {migrateSite, name, version, disableVersioning}
