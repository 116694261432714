import type {Pointer, PS} from '@wix/document-services-types'
import consts from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import metadata from '../../wixapps/services/metadata'
import documentModeInfo from '../../documentMode/documentModeInfo'
import metaDataUtils from '../metaDataUtils'

function getPartName(ps: PS, compPointer: Pointer) {
    const compData = dataModel.getDataItem(ps, compPointer)
    if (!compData) {
        throw new Error(`Can't find data for component id ${compPointer.id}`)
    }

    return compData.appPartName
}

function isDuplicatable(ps: PS, compRef) {
    return !documentModeInfo.isMobileView(ps) && metadata.wasPartLoadedSuccessfully(ps, getPartName(ps, compRef))
}

//TODO: handle single item page when we'll have it
export default {
    isRepeatable: false,
    resizableSides: [consts.RESIZE_SIDES.LEFT, consts.RESIZE_SIDES.RIGHT],
    removable: true,
    duplicatable: isDuplicatable,
    crossSiteDuplicatable: isDuplicatable,
    containableByStructure: metaDataUtils.notContainableByPopup,
    usingLegacyAppPartSchema: true
}
