import _ from 'lodash'
import {constants} from '@wix/santa-core-utils'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'
import featuresTypesMap from '../siteData/featuresTypesMap'

const masterPageId = 'masterPage'

const types = _.assign({}, constants.DATA_TYPES, featuresTypesMap)
const typeToKeyMap = _.assign({}, constants.PAGE_DATA_DATA_TYPES, featuresTypesMap)

function checkIdentity() {
    return true
}

function findDataItem(type, currentRootIds, getItemInPath, dataPointer) {
    const typeKey = getTypeKey(type)
    const pagesData = getItemInPath(['pagesData'])

    return _.reduce(
        pagesData,
        function (currentPath, page, pageId) {
            const newPath = getPageDataPath(type, pageId, typeKey).concat(dataPointer.id)
            if (!currentPath && getItemInPath(newPath)) {
                return newPath
            }

            return currentPath
        },
        null
    )
}

function getTypeKey(type: string) {
    const typeKey = typeToKeyMap[type]
    if (typeKey) {
        return typeKey
    }

    throw new Error(`there is no such data type ${type}`)
}

function getPageDataPath(type: string, pageId: string, typeKey?: string) {
    return ['pagesData', pageId, 'data', typeKey ?? getTypeKey(type)]
}

// registering the pointer type finding items methods, to a registry for the pointersCache to use it when resolving.
_.forEach(types, function (type) {
    pointerGeneratorsRegistry.registerPointerType(type, findDataItem.bind(null, type), checkIdentity, false, true)
})

const getterFunctions = {
    getDataItem(getItemAtPath, cache, dataItemId, pageId) {
        return this.getItem(getItemAtPath, cache, types.data, dataItemId, pageId)
    },

    getDataItemWithPredicate(getItemAtPath, cache, predicate, pageId) {
        return _.head(this.getDataItemsWithPredicate(getItemAtPath, cache, predicate, pageId))
    },

    getDataItemsWithPredicate(getItemAtPath, cache, predicate, pageId) {
        const pId = pageId || 'masterPage'
        const path = getPageDataPath(types.data, pId)
        let items = getItemAtPath(path)
        items = items.toJS ? items.toJS() : items
        return _.map(
            _.filter(items, predicate),
            function (item) {
                return this.getDataItem(getItemAtPath, cache, item.id, pId)
            }.bind(this)
        )
    },

    getDesignItemsWithPredicate(getItemAtPath, cache, predicate, pageId) {
        const pId = pageId || 'masterPage'
        const path = getPageDataPath(types.design, pId)
        let items = getItemAtPath(path)
        items = items.toJS ? items.toJS() : items
        return _.map(
            _.filter(items, predicate),
            function (item) {
                return this.getDesignItem(getItemAtPath, cache, item.id, pId)
            }.bind(this)
        )
    },

    getDesignItem(getItemAtPath, cache, designItemId?, pageId?) {
        return this.getItem(getItemAtPath, cache, types.design, designItemId, pageId)
    },

    getPropertyItem(getItemAtPath, cache, propertyItemId, pageId) {
        return this.getItem(getItemAtPath, cache, types.prop, propertyItemId, pageId)
    },

    getBehaviorsItem(getItemAtPath, cache, behaviorsItemId, pageId) {
        return this.getItem(getItemAtPath, cache, types.behaviors, behaviorsItemId, pageId)
    },

    getConnectionsItem(getItemAtPath, cache, connectionItemId, pageId) {
        return this.getItem(getItemAtPath, cache, types.connections, connectionItemId, pageId)
    },

    getMobileHintsItem(getItemAtPath, cache, mobileHintsItemId, pageId) {
        return this.getItem(getItemAtPath, cache, types.mobileHints, mobileHintsItemId, pageId)
    },

    getThemeItem(getItemAtPath, cache, id, pageId = masterPageId) {
        if (!cache.isMoveStyleExperimentOpen()) {
            pageId = masterPageId
        }
        return this.getItem(getItemAtPath, cache, types.theme, id, pageId)
    },

    getItem(getItemAtPath, cache, type: string, id: string, pageId: string) {
        const path = getPageDataPath(type, pageId)
        path.push(id)
        return cache.getPointer(id, type, path)
    },

    getPageDataMap(getItemAtPath, cache, type: string, pageId: string) {
        const path = getPageDataPath(type, pageId)
        return cache.getPointer(`${type} map`, type, path)
    },

    getDataItemFromMaster(getItemAtPath, cache, id: string) {
        return this.getDataItem(getItemAtPath, cache, id, masterPageId)
    },

    getPageIdOfData(getItemAtPath, cache, pointer) {
        const path = cache.getPath(pointer)
        return path[1]
    },

    //TODO: maybe it should be part of the getItem method
    validatePath(getItemAtPath, cache, pointer) {
        return !!cache.getPath(pointer)
    },

    getVariantDataItemsByComponentId() {
        return []
    }
}

pointerGeneratorsRegistry.registerDataAccessPointersGenerator('data', getterFunctions)
