import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import type {DalItem} from '@wix/document-manager-core'
import {PAGE_TYPES} from '../constants/constants'

const isPage = (item?: DalItem) => _.includes(PAGE_TYPES, item?.type)

const getDeepStructureForComp = (compId: string, flatStructure: Record<string, any>): any => {
    const comp = flatStructure[compId]
    const key = compId === 'masterPage' ? 'children' : 'components'
    const keysToRemove = compId === 'masterPage' ? ['metaData', 'components', 'parent'] : ['metaData', 'parent']

    if (!comp.components) {
        return _.omit(comp, keysToRemove)
    }
    return {
        ..._.omit(comp, keysToRemove),
        [key]: _.map(comp.components, x => getDeepStructureForComp(x, flatStructure))
    }
}

const structureByPage = (flatStructure: Record<string, any>, mobileStructure: any, pageList: any) =>
    _.mapValues(pageList, structure => {
        const mobileComponents = _.get(mobileStructure, [structure.id, 'components'], [])

        return {
            structure: _.merge(getDeepStructureForComp(structure.id, flatStructure), {
                mobileComponents: mobileComponents.map((chComponent: string) =>
                    getDeepStructureForComp(chComponent, mobileStructure)
                )
            })
        }
    })

const getChildrenRecursivelyRightLeftRootImpl = (
    getChildPointers: (p: Pointer) => Pointer[],
    pointer: Pointer,
    result: Pointer[]
) => {
    const children = getChildPointers(pointer)
    if (Array.isArray(children)) {
        for (const p of children.reverse()) {
            getChildrenRecursivelyRightLeftRootImpl(getChildPointers, p, result)
        }
    }
    result.push(pointer)
}

const getChildrenRecursivelyRightLeftRoot = (getChildPointers: (p: Pointer) => Pointer[], pointer: Pointer) => {
    const result: Pointer[] = []
    getChildrenRecursivelyRightLeftRootImpl(getChildPointers, pointer, result)
    return result
}

export {getChildrenRecursivelyRightLeftRoot, getDeepStructureForComp, structureByPage, isPage}
