const BASE_PATH = ['wixCode']
const NON_UNDOABLE_BASE_PATH = BASE_PATH.concat('nonUndoable')
const UNDOABLE_BASE_PATH = BASE_PATH.concat('undoable')

const WIX_CODE_APP_ID = 'wixCode'
const WIX_CODE_MODEL_PATH = ['rendererModel', 'wixCodeModel']
const WIX_CODE_APP_DATA_PATH = WIX_CODE_MODEL_PATH.concat('appData')
const REVISION_GRID_APP_ID_PATH = WIX_CODE_APP_DATA_PATH.concat('codeAppId')
const IS_APP_READ_ONLY_PATH = NON_UNDOABLE_BASE_PATH.concat('isAppReadOnly')

const paths = {
    BASE: BASE_PATH,
    NON_UNDOABLE_BASE_PATH,
    UNDOABLE_BASE_PATH,

    DUPLICATED_FILES_INFO: NON_UNDOABLE_BASE_PATH.concat('duplicatedFilesInfo'),
    LOADED_FILE_CONTENTS: NON_UNDOABLE_BASE_PATH.concat('loadedFileContents'),
    MODIFIED_FILE_CONTENTS: NON_UNDOABLE_BASE_PATH.concat('modifiedFileContents'),
    UNDOABLE_MODIFIED_FILE_CONTENTS: UNDOABLE_BASE_PATH.concat('modifiedFileContents'),
    FILE_PATH_TO_ID_MAP: NON_UNDOABLE_BASE_PATH.concat('filePathToIdMap'),

    LOADED_CHILDREN: NON_UNDOABLE_BASE_PATH.concat('loadedChildren'),
    ARE_CHILDREN_LOADED: NON_UNDOABLE_BASE_PATH.concat('areChildrenLoaded'),

    WIX_CODE_MODEL: WIX_CODE_MODEL_PATH,
    WIX_CODE_APP_DATA: WIX_CODE_APP_DATA_PATH,
    REVISION_GRID_APP_ID: REVISION_GRID_APP_ID_PATH,
    IS_APP_READ_ONLY: IS_APP_READ_ONLY_PATH,

    DIRECTORY_FLAG_BY_DELETED_PATH: NON_UNDOABLE_BASE_PATH.concat('directoryFlagByDeletedPath'),

    OPEN_WIX_CODE_APP_ID: ['documentServicesModel', 'openWixCodeAppId'],
    ISOLATED_CODE_APP_ID: BASE_PATH.concat('ISOLATED_GRID_APP'),

    WIX_CODE_WAIT_FOR_APPROVAL: ['wixCodeWaitForApproval']
}

export default {
    paths,
    WIX_CODE_APP_ID,
    WIX_CODE_SERVICE_URL_KEY: 'wixCodeServiceUrl',
    WIX_CODE_SPEC_TYPE: 'siteextension',
    PAGES_ROOT: 'pages',
    JS_EXTENSION: '.js'
}
