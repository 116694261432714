/* eslint-disable prefer-rest-params */
import type {
    DocumentServicesObject,
    PS,
    ExtensionPublicMethodOpts,
    PublicMethodDefinition,
    PublicMethodUtils,
    PublicMethodOpts,
    DSConfig
} from '@wix/document-services-types'
import {createPromiseFromAsyncPartOfPublicMethod} from './publicMethodsUtils'
import {
    DEFINITIONS_TYPES,
    METHOD_TYPES,
    LOG_TYPES,
    SUPPORT_SCOPES_APIS,
    KNOWN_AFFECTED_BY_SCOPES_APIS
} from './constants'
import * as _ from 'lodash'
import {wSpy} from '@wix/santa-core-utils'
import setOperationQueueUtils from './setOperationQueueUtils'
import * as dsQTrace from './dsQTrace'
import {deepClone} from '@wix/wix-immutable-proxy'

interface RunAndGetHandleParams {
    documentServices: DocumentServicesObject
    ps: PS
    apiDefinition: PublicMethodDefinition
    setOperationParams: any
    args: any[]
}

export const createPublicMethodUtils = (): PublicMethodUtils => {
    const startInteraction = (ps: PS, interaction: string, options: Record<string, any>) => {
        ps.extensionAPI.logger.interactionStarted(interaction, options)
    }
    const endInteraction = (ps: PS, interaction: string, options: Record<string, any>) => {
        ps.extensionAPI.logger.interactionEnded(interaction, options)
    }

    const getArgValue = (arg: any): string | undefined => {
        if (_.isFunction(arg)) {
            return 'function'
        }
        try {
            const value: string | undefined = JSON.stringify(arg) // just verifying that this is a serializable structure
            if (value?.length > 200) {
                return `${value.substr(0, 200)}...`
            }
            return value
        } catch (e: any) {
            return 'error'
        }
    }

    const defaultApiParams = (...args: any[]) => {
        const argsWithNoPs = (args || []).slice(1)

        return {
            args: argsWithNoPs.map(getArgValue)
        }
    }

    const getInteractionParams = (
        isStarted: boolean,
        currentContext: any,
        callsContext: string[],
        identifier: number,
        apiDefinition: any,
        args: any
    ) => {
        const getApiParamsFunction = apiDefinition.getInteractionParams ?? (isStarted ? defaultApiParams : _.noop)
        const apiParams = getApiParamsFunction(...args)
        const contextValue = currentContext ? {context: currentContext} : {}
        return {
            extras: _.merge(
                {
                    randIdentifier: identifier
                },
                apiParams,
                contextValue
            ),
            context: {
                calls: callsContext
            }
        }
    }

    const DEFAULT_OPTIONS_BY_DEFINITION_TYPE: {
        GETTER: PublicMethodOpts
        ACTION: PublicMethodOpts
        DATA_MANIPULATION_ACTION: PublicMethodOpts
    } = {
        GETTER: {},
        ACTION: {
            isUpdatingData: true
        },
        DATA_MANIPULATION_ACTION: {
            getReturnValue: undefined,
            asyncPreDataManipulation: undefined,
            isUpdatingAnchors: setOperationQueueUtils.IS_UPDATING_ANCHORS.NO,
            shouldLockComp: false,
            noRefresh: false,
            noBatching: false,
            noBatchingAfter: false,
            isAsyncOperation: false,
            waitingForTransition: false,
            nonUndoable: false,
            getInteractionParams: undefined,
            disableLogInteraction: false
        }
    }

    const logDocumentServicesOperation = (_type: string, rec: any[]) => wSpy.log(`ds_${_type}`, rec)

    function validateOptionsByType(options: PublicMethodOpts | undefined, definitionType: string) {
        const supportedOptions = _.keys(DEFAULT_OPTIONS_BY_DEFINITION_TYPE[definitionType])
        const hasNotSupportedProp = _.some(options, (value, propName) => !_.includes(supportedOptions, propName))
        if (hasNotSupportedProp) {
            throw new Error(`${definitionType} options are not valid`)
        }
    }

    function defineMethod(
        methodType: string,
        method: Function,
        options: PublicMethodOpts | undefined,
        definitionType: string
    ): PublicMethodDefinition {
        const methodDefinitionKey = 'method'
        if (!_.isFunction(method)) {
            throw new Error(`${methodDefinitionKey} Function is required`)
        }

        validateOptionsByType(options, definitionType)

        const definition = _.set(
            {
                isPublicAPIDefinition: true,
                methodType,
                type: definitionType
            },
            methodDefinitionKey,
            method
        )

        return _.assign(
            definition,
            DEFAULT_OPTIONS_BY_DEFINITION_TYPE[definitionType],
            options
        ) as PublicMethodDefinition
    }

    const defineGetter = (method: Function, options?: PublicMethodOpts) =>
        defineMethod(METHOD_TYPES.READ, method, options, DEFINITIONS_TYPES.GETTER)
    const defineAction = (method: Function, options?: PublicMethodOpts) =>
        defineMethod(METHOD_TYPES.ACTION, method, options, DEFINITIONS_TYPES.ACTION)
    const defineDataManipulationAction = (method: Function, options?: PublicMethodOpts) =>
        defineMethod(METHOD_TYPES.ACTION, method, options, DEFINITIONS_TYPES.DATA_MANIPULATION_ACTION)

    const runMethodInTransaction = (ps: PS, apiDefinition: PublicMethodDefinition, args: any[]) => {
        apiDefinition.method(...args)
        ps.setOperationsQueue.executeOperationCallbacksInTransaction()
    }

    const runAsyncMethodInTransaction = async (ps: PS, apiDefinition: PublicMethodDefinition, args: any[]) => {
        const [, ...argsWithoutPS] = args
        const asyncResultPromise = createPromiseFromAsyncPartOfPublicMethod(ps, apiDefinition, argsWithoutPS)
        const asyncResult = await asyncResultPromise
        const argsForMethod = !_.isNil(asyncResult) ? [ps, asyncResult, ...argsWithoutPS] : args
        runMethodInTransaction(ps, apiDefinition, argsForMethod)
    }

    const isAsync = (apiDefinition: PublicMethodDefinition, args: any[]): boolean =>
        !!apiDefinition.asyncPreDataManipulation &&
        !!apiDefinition.isAsyncOperation &&
        ((_.isFunction(apiDefinition.isAsyncOperation) && apiDefinition.isAsyncOperation(...args)) ||
            apiDefinition.isAsyncOperation === true)

    const runMethodAndGetHandleInTransaction = (ps: PS, apiDefinition: PublicMethodDefinition, args: any[]) => {
        if (isAsync(apiDefinition, args)) {
            const asyncMethodPromise = runAsyncMethodInTransaction(ps, apiDefinition, args)
            ps.setOperationsQueue.registerToWaitForChangesAppliedInTransaction(asyncMethodPromise)
        } else {
            runMethodInTransaction(ps, apiDefinition, args)
        }
        return -1
    }

    function runMethodAndGetHandle({
        documentServices,
        ps,
        apiDefinition,
        setOperationParams,
        args
    }: RunAndGetHandleParams) {
        if (documentServices.transactions.isRunning()) {
            return runMethodAndGetHandleInTransaction(ps, apiDefinition, args)
        }
        return ps.setOperationsQueue.runSetOperation(apiDefinition.method, args, setOperationParams)
    }

    function waitForChangesIfNotInTransaction(ds: DocumentServicesObject, ps: PS, action: () => void): void {
        if (ds.transactions.isRunning()) {
            action()
        } else {
            ps.setOperationsQueue.waitForChangesApplied(action)
        }
    }

    function getDataManipulation(
        apiDefinition: PublicMethodDefinition,
        documentServices: DocumentServicesObject,
        ps: PS,
        methodPath: string,
        documentServicesConfigs: DSConfig
    ) {
        return function () {
            const newArgs = _.toArray(arguments)
            newArgs.unshift(ps)

            let returnValue = null
            if (apiDefinition.getReturnValue) {
                returnValue = apiDefinition.getReturnValue.apply(null, newArgs)
                newArgs.splice(1, 0, returnValue)
            }

            logDocumentServicesOperation(apiDefinition.type, [methodPath, apiDefinition, ...arguments])

            const currentContext = ps.setOperationsQueue.getCurrentContext()
            const callsContext = ps.setOperationsQueue.getCallsContext()
            const setOperationParams = setOperationQueueUtils.getDataManipulationParams(
                ps,
                apiDefinition,
                methodPath,
                currentContext,
                newArgs
            )

            let interactionName: string
            const shouldLogInteraction = !apiDefinition.disableLogInteraction
            const identifier = _.random(10000)

            if (shouldLogInteraction) {
                interactionName = `api.${methodPath}`
                const interactionParams = getInteractionParams(
                    true,
                    currentContext,
                    callsContext,
                    identifier,
                    apiDefinition,
                    newArgs
                )
                startInteraction(ps, interactionName, interactionParams)
            }

            const handle = runMethodAndGetHandle({
                documentServices,
                ps,
                apiDefinition,
                setOperationParams,
                args: newArgs
            })

            if (shouldLogInteraction) {
                const interactionParams = getInteractionParams(
                    false,
                    currentContext,
                    callsContext,
                    identifier,
                    apiDefinition,
                    newArgs
                )
                waitForChangesIfNotInTransaction(documentServices, ps, () =>
                    endInteraction(ps, interactionName, interactionParams)
                )
            }

            if (apiDefinition.nonUndoable && !documentServicesConfigs.noUndo) {
                ps.setOperationsQueue.runSetOperation(() => documentServices.history.clear())
            }

            if (dsQTrace.isTracing(ps)) {
                if (dsQTrace.shouldLogConsoleTrace(ps)) {
                    /*eslint no-console:0*/
                    if (console.trace) {
                        console.trace()
                    }
                }
                dsQTrace.logTrace(ps, LOG_TYPES.DATA_MANIPULATION_ACTION, {
                    handle,
                    methodName: methodPath,
                    args: arguments
                })
            }

            return returnValue
        }
    }

    function getImmediateAction(apiDefinition: PublicMethodDefinition, ps: PS, methodPath: string) {
        return (...args: any[]) => {
            logDocumentServicesOperation(apiDefinition.type, [methodPath, apiDefinition, ...args])
            const currentContext = ps.setOperationsQueue.getCurrentContext()
            const setOperationsParams = setOperationQueueUtils.getImmediateActionParams(
                ps,
                methodPath,
                currentContext,
                !!apiDefinition.isUpdatingData
            )
            const result = ps.setOperationsQueue.runImmediateSetOperation
                ? ps.setOperationsQueue.runImmediateSetOperation(
                      apiDefinition.method,
                      setOperationsParams,
                      [ps].concat(args)
                  )
                : apiDefinition.method(ps, ...args)

            if (dsQTrace.isTracing(ps)) {
                if (dsQTrace.shouldLogConsoleTrace(ps)) {
                    /*eslint no-console:0*/
                    if (console.trace) {
                        console.trace()
                    }
                }
                dsQTrace.logTrace(ps, LOG_TYPES.ACTION, {
                    result,
                    methodName: methodPath,
                    args
                })
            }

            return result
        }
    }

    function getRead(apiDefinition: PublicMethodDefinition, ps: PS, methodPath: string) {
        return (...args: any[]) => {
            logDocumentServicesOperation(apiDefinition.type, [methodPath, apiDefinition, ...args])
            if (dsQTrace.isTracing(ps)) {
                const start = window.performance ? window.performance.now() : _.now()
                const result = apiDefinition.method(ps, ...args)
                const end = window.performance ? window.performance.now() : _.now()
                dsQTrace.logReadTrace(ps, {
                    duration: end - start,
                    result,
                    methodName: methodPath,
                    args
                })
                return result
            }

            return apiDefinition.method(ps, ...args)
        }
    }

    const getWrappedApiDefinitionMethod = (ps: PS, apiDefinition: PublicMethodDefinition, methodPath: string) => {
        const {isAsyncOperation, method} = apiDefinition

        if (!ps.extensionAPI?.scopes || isAsyncOperation || SUPPORT_SCOPES_APIS.has(methodPath)) {
            return method
        }

        if (KNOWN_AFFECTED_BY_SCOPES_APIS.has(methodPath)) {
            return ps.extensionAPI.scopes.wrapMethodWithDisableScopes(apiDefinition.method)
        }

        return ps.extensionAPI.scopes.wrapReportablePublicApiMethod(apiDefinition, methodPath)
    }

    function resolvePublicAPIDefinition(
        documentServices: DocumentServicesObject,
        documentServicesConfigs: DSConfig,
        ps: PS,
        apiDefinition: PublicMethodDefinition,
        methodPath: string
    ) {
        apiDefinition = {...apiDefinition, method: getWrappedApiDefinitionMethod(ps, apiDefinition, methodPath)}

        if (apiDefinition.type === DEFINITIONS_TYPES.DATA_MANIPULATION_ACTION) {
            return getDataManipulation(apiDefinition, documentServices, ps, methodPath, documentServicesConfigs)
        }

        if (apiDefinition.type === DEFINITIONS_TYPES.ACTION) {
            return getImmediateAction(apiDefinition, ps, methodPath)
        }

        return getRead(apiDefinition, ps, methodPath)
    }

    const isPublicAPIDefinition = (methodDefinition: PublicMethodDefinition): boolean =>
        _.get(methodDefinition, ['isPublicAPIDefinition'], false)

    const dataManipulationWithFlags =
        (flags: {isUpdatingAnchors: string}) =>
        (method: Function, opts: PublicMethodOpts = {}): PublicMethodDefinition =>
            defineDataManipulationAction(method, Object.assign(opts, flags))

    const dontCare = dataManipulationWithFlags({
        isUpdatingAnchors: setOperationQueueUtils.IS_UPDATING_ANCHORS.DONT_CARE
    })

    const enforcingOnly = dataManipulationWithFlags({
        isUpdatingAnchors: setOperationQueueUtils.IS_UPDATING_ANCHORS.NO
    })

    const updatingOnly = dataManipulationWithFlags({
        isUpdatingAnchors: setOperationQueueUtils.IS_UPDATING_ANCHORS.YES
    })

    const wrap = (methodDef: PublicMethodDefinition, wrapper: Function) => {
        const {method} = methodDef
        if (!_.isFunction(method)) {
            throw new Error('You can only wrap a method definition') //TODO: perhaps in future, use defineConst + proxy in order to support consts
        }

        return {
            ...methodDef,
            method: (...args: any[]) => wrapper(() => methodDef.method(...args))
        }
    }

    const deprecate = (
        publicAPIDefinition: PublicMethodDefinition,
        message: string,
        deprecationBILimit: number = 20
    ): PublicMethodDefinition => {
        if (!isPublicAPIDefinition(publicAPIDefinition)) {
            throw new Error('You can only deprecate a publicAPI definition, such as a defined getter or action')
        }
        if (!_.isString(message) || !message.length) {
            throw new Error('You must provide a deprecation message')
        }
        return {
            ...publicAPIDefinition,
            deprecated: true,
            deprecationOptions: {
                message,
                limit: deprecationBILimit
            }
        }
    }

    const isDeprecated = (publicAPIDefinition: PublicMethodDefinition) =>
        _.get(publicAPIDefinition, ['deprecated'], false)

    const getAndValidatePublicAPIMethod = (ps: PS, methodPath: string) => {
        const method = _.get(ps.publicAPI, methodPath)
        if (!method) {
            throw new Error(
                `Method ${methodPath} must be resolved through ps.publicAPI.${methodPath} before it can be used`
            )
        }
        return method
    }

    const getExtensionActionDefinition = (methodPath: string, options?: ExtensionPublicMethodOpts) => {
        const publicMethod = (ps: PS, ...args: any[]) => {
            const method = getAndValidatePublicAPIMethod(ps, methodPath)
            return method(...args)
        }
        const publicMethodOpts = _.omit(options, [
            'getReturnValueMethodPath',
            'getInteractionParamsMethodPath'
        ]) as PublicMethodOpts

        if (options?.getReturnValueMethodPath) {
            publicMethodOpts.getReturnValue = (ps: PS, ...args: any[]) => {
                const method = getAndValidatePublicAPIMethod(ps, options.getReturnValueMethodPath!)
                return deepClone(method(...args))
            }
        }
        if (options?.getInteractionParamsMethodPath) {
            publicMethodOpts.getInteractionParams = (ps: PS, ...args: any[]) => {
                const method = getAndValidatePublicAPIMethod(ps, options.getInteractionParamsMethodPath!)
                return method(...args)
            }
        }
        return {method: publicMethod, publicMethodOpts}
    }

    const defineExtensionDataManipulation = (
        methodPath: string,
        options?: ExtensionPublicMethodOpts
    ): PublicMethodDefinition => {
        const {method, publicMethodOpts} = getExtensionActionDefinition(methodPath, options)
        return defineMethod(METHOD_TYPES.ACTION, method, publicMethodOpts, DEFINITIONS_TYPES.DATA_MANIPULATION_ACTION)
    }

    const defineExtensionImmediateAction = (
        methodPath: string,
        options?: ExtensionPublicMethodOpts
    ): PublicMethodDefinition => {
        const {method, publicMethodOpts} = getExtensionActionDefinition(methodPath, options)
        return defineMethod(METHOD_TYPES.ACTION, method, publicMethodOpts, DEFINITIONS_TYPES.ACTION)
    }

    const defineExtensionGetter = (methodPath: string, options?: ExtensionPublicMethodOpts): PublicMethodDefinition => {
        const publicMethod = (ps: PS, ...args: any[]) => {
            const method = getAndValidatePublicAPIMethod(ps, methodPath)
            return deepClone(method(...args))
        }
        return defineMethod(METHOD_TYPES.READ, publicMethod, options as PublicMethodOpts, DEFINITIONS_TYPES.GETTER)
    }

    return {
        METHOD_TYPES,
        DEFINITIONS_TYPES,
        defineGetter,
        defineAction,
        defineDataManipulationAction,
        resolvePublicAPIDefinition,
        isPublicAPIDefinition,
        deprecate,
        isDeprecated,
        wrap,
        actions: {
            dataManipulation: defineDataManipulationAction,
            dontCare,
            enforcingOnly,
            updatingOnly,
            immediate: defineAction
        },
        extensionPublicAPI: {
            dataManipulation: defineExtensionDataManipulation,
            immediate: defineExtensionImmediateAction,
            getter: defineExtensionGetter
        }
    }
}
