import {
    CoreConfig,
    CreateExtArgs,
    CreateExtensionArgument,
    DAL,
    DalItem,
    DalValue,
    DmApis,
    DocumentDataTypes,
    Extension,
    ExtensionAPI,
    IndexKey,
    Namespace,
    PointerMethods,
    pointerUtils,
    SnapshotDal
} from '@wix/document-manager-core'
import {addPageData} from './page/pageData'
import type {
    Component,
    CompStructure,
    MetaData,
    PagesData,
    Pointer,
    PossibleViewModes,
    ResolvedReference
} from '@wix/document-services-types'
import _ from 'lodash'
import reject from 'lodash/fp/reject'
import update from 'lodash/fp/update'
import {
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPES,
    PAGE_DATA_TYPES,
    DM_POINTER_TYPES,
    MASTER_PAGE_ID,
    PAGE_SCHEMA,
    PAGE_TYPES,
    VIEW_MODES
} from '../constants/constants'
import {getPagePartKey, pageGetterFromFull} from '../utils/pageUtils'
import {createDefaultMetaData, generateItemIdWithPrefix, generateUniqueIdByType} from '../utils/dataUtils'
import {getTranslationInfoFromKey} from '../utils/translationUtils'
import type {ValidateValue} from '@wix/document-manager-core/src/dal/validation/dalValidation'
import type {RelationshipsAPI} from './relationships'
import type {MultilingualTranslationsAPI} from './multilingual'
import type {DefaultDefinitionsAPI} from './defaultDefinitions/defaultDefinitions'
import type {ComponentsAPI} from './components'
import type {SnapshotExtApi} from './snapshots'
import {constants} from '..'
import type {DataModelAPI} from './dataModel'
import {stripHashIfExists} from '../utils/refArrayUtils'
import type {RoutersAPI} from './routers'

export const PAGE_EVENTS = {
    PAGE: {
        DATA_UPDATE: 'PAGE_DATA_UPDATE',
        PAGE_ADDED: 'PAGE_ADDED'
    }
}
const {getPointer, getInnerPointer} = pointerUtils
const pagePointerType = DM_POINTER_TYPES.pageDM
const deletedPagesMapPointerType = DM_POINTER_TYPES.deletedPagesMap
const ALL_PAGES_INDEX_ID = 'all'
const MASTER_PAGE_TYPE = 'Document'
const includeMasterPageFilterId = 'includeMasterPage'
const excludeMasterPageFilterId = 'excludeMasterPage'
const NON_PAGE_IDS = ['SITE_STRUCTURE']
const SCHEMA_LOOKUP_INDEX = 'schemaLookupIndex'
const NO_MATCH: string[] = []
interface PageDataLanguages {
    pageId: string
    languageCode: string
}

const getIdsOfAllPages = (dal: DAL, includeMasterPage: boolean) => {
    const filterId = includeMasterPage ? includeMasterPageFilterId : excludeMasterPageFilterId
    const pages = dal.query(DATA_TYPES.data, dal.queryFilterGetters.getPageFilter(filterId))
    return _.map(pages, 'id')
}

const getAllCompsOnPage = (dal: DAL, pageId: string, viewMode: PossibleViewModes = 'DESKTOP') => {
    const indexKey = dal.queryFilterGetters.getPageCompFilter(pageId)
    return _.cloneDeep(dal.getIndexed(indexKey)[viewMode]) || {}
}

const isExistsBuilder = (dal: DAL) => (pageId: string) => {
    const data = dal.get(getPointer(pageId, DATA_TYPES.data))
    return data && ['Page', 'AppPage', 'Document'].includes(data.type)
}

const getPageAndSchemaIndex = (schemaTypeOrComponentType: string, namespace: string, pageId: string) =>
    `${namespace}^${schemaTypeOrComponentType}^${pageId}`

const createPointersMethods = ({dal}: DmApis): PointerMethods => {
    const getAllPagesIds = (includeMasterPage: boolean): string[] => getIdsOfAllPages(dal, includeMasterPage)
    const isExists = isExistsBuilder(dal)
    const getPageData = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.data), pagePointerType) : null
    const getPageDesignData = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.design), pagePointerType) : null
    const getPageProperties = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.prop), pagePointerType) : null
    const getPageBehaviorsData = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.behaviors), pagePointerType) : null
    const getPageBreakpointsData = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, DATA_TYPES.breakpoints), pagePointerType) : null
    const getPageTranslations = (pageId: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, PAGE_SCHEMA.translations), pagePointerType) : null
    const getComponentsMapPointer = (pageId: string, viewMode: string) =>
        isExists(pageId) ? getPointer(getPagePartKey(pageId, viewMode), pagePointerType) : null

    const isPointerPageType = (pointer: Pointer) => pointer && pointer.type === pagePointerType
    const getNewPagePointer = (pageId: string) => getPointer(pageId, pagePointerType)
    const getPagePointer = (pageId: string) => (isExists(pageId) ? getNewPagePointer(pageId) : null)
    const getNonDeletedPagesPointers = (includeMaster: boolean) =>
        _.map(getAllPagesIds(includeMaster), pageId => getPointer(pageId, pagePointerType))
    const getDeletedPagesMapPointer = () => getPointer('deletedPagesMap', deletedPagesMapPointerType)
    const getInnerBackgroundRefPointer = (pageDataPointer: Pointer, viewMode: string) =>
        getInnerPointer(pageDataPointer, ['pageBackgrounds', viewMode, 'ref'])
    const getPointersByPageAndSchemaType = (schemaTypeOrComponentType: string, namespace: string, pageId: string) => {
        const indexKey: IndexKey = dal.queryFilterGetters[SCHEMA_LOOKUP_INDEX](
            getPageAndSchemaIndex(schemaTypeOrComponentType, namespace, pageId)
        )
        return dal.getIndexPointers(indexKey, namespace)
    }
    const getFixerVersionsQueryPointer = (pageId: string, viewMode: string = VIEW_MODES.DESKTOP) =>
        getPointer(pageId, viewMode, {innerPath: [COMP_DATA_QUERY_KEYS_WITH_STYLE.fixerVersions]})
    const getFixerVersionsPointer = (fixerVersionsQuery: string) =>
        getPointer(fixerVersionsQuery, DATA_TYPES.fixerVersions)
    const getHeaderPointer = () =>
        _.head(getPointersByPageAndSchemaType('responsive.components.HeaderSection', 'DESKTOP', 'masterPage'))
    const getFooterPointer = () =>
        _.head(getPointersByPageAndSchemaType('responsive.components.FooterSection', 'DESKTOP', 'masterPage'))

    const page = {
        getPageData,
        getPageDesignData,
        getPageProperties,
        isPointerPageType,
        getNewPagePointer,
        getPagePointer,
        getPageTranslations,
        getPageBehaviorsData,
        getPageBreakpointsData,
        isExists,
        getNonDeletedPagesPointers,
        getComponentsMapPointer,
        getDeletedPagesMapPointer,
        getInnerBackgroundRefPointer,
        getPointersByPageAndSchemaType,
        getFixerVersionsQueryPointer,
        getFixerVersionsPointer,
        getHeaderPointer,
        getFooterPointer
    }

    return {
        // @ts-expect-error
        page,
        general: {
            getDeletedPagesMapPointer
        }
    }
}

const createGetters = () => ({[pagePointerType]: pageGetterFromFull})

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [pagePointerType]: {},
    [deletedPagesMapPointerType]: {}
})

const initialState = {
    [deletedPagesMapPointerType]: {
        [deletedPagesMapPointerType]: {}
    }
}

export type IsChangedFunc = (item: DalValue, namespace: string, fromSnapshot: SnapshotDal) => boolean

const createExtensionAPI = (extArgs: CreateExtArgs): PageExtensionAPI => {
    const {dal, pointers, extensionAPI, eventEmitter} = extArgs
    const defaultDefinitions = () => extensionAPI.defaultDefinitions as DefaultDefinitionsAPI
    const componentsAPI = () => extensionAPI.components as ComponentsAPI
    const {snapshots} = extensionAPI as SnapshotExtApi
    const isExists = isExistsBuilder(dal)
    const hasPageBeenLoaded = (pageId: string) => dal.has(pointers.structure.getPage(pageId, 'DESKTOP'))

    const getTranslatedDataItemFromMaster = (id: string, languageCode?: string) => {
        const originalPageDataItemPointer = pointers.data.getDataItemFromMaster(id)
        return pointers.data.getTranslatedData(originalPageDataItemPointer, languageCode)
    }

    const updatePageData = (
        pagePointer: Pointer,
        data: Record<string, any>,
        applyChangeToAllLanguages = false,
        languageCode?: string
    ) => {
        addPageData(extArgs, pagePointer, data, languageCode)
        eventEmitter.emit(PAGE_EVENTS.PAGE.DATA_UPDATE, pagePointer.id, data, languageCode, applyChangeToAllLanguages)
    }

    const getPageData = (pageId: string, languageCode?: string) => {
        const translatedPageDataItemPointer = getTranslatedDataItemFromMaster(pageId, languageCode)
        return dal.get(translatedPageDataItemPointer)
    }

    const buildDataPropertyGetter =
        (path: string | string[]) =>
        (pageId: string, options: PageOptions = {}) => {
            const pageDataItemPointer = getTranslatedDataItemFromMaster(pageId, options.languageCode)

            return dal.get(getInnerPointer(pageDataItemPointer, ([] as string[]).concat(path)))
        }

    const buildDataPropertySetter =
        (path: string | string[]) =>
        (pageId: string, value: any, options: PageOptions = {}) => {
            if (!isExists(pageId)) {
                throw new Error(`Can not set data for page '${pageId}' which doesn't exist`)
            }

            const pageDataItemPointer = getTranslatedDataItemFromMaster(pageId, options.languageCode)

            const innerDataPointer = getInnerPointer(pageDataItemPointer, ([] as string[]).concat(path))
            dal.set(innerDataPointer, value)
        }

    const buildDataRefPropertySetter =
        (path: string | string[]) =>
        (pageId: string, value: any, options: PageOptions = {}) => {
            if (!isExists(pageId)) {
                throw new Error(`Can not set data for page '${pageId}' which doesn't exist`)
            }

            if (!value) {
                buildDataPropertySetter(path)(pageId, undefined, options)
                return
            }

            const id = generateUniqueIdByType(DATA_TYPES.data, MASTER_PAGE_ID, dal, pointers)
            const translatedItemPointer = getTranslatedDataItemFromMaster(id, options.languageCode)
            const metaData = createDefaultMetaData({pageId: MASTER_PAGE_ID})

            const dataItemValue = {id: translatedItemPointer.id, metaData, ...value}
            dal.set(translatedItemPointer, dataItemValue)
            buildDataPropertySetter(path)(pageId, `#${translatedItemPointer.id}`, options)
        }

    const buildDataRefPropertyGetter =
        (path: string | string[]) =>
        (pageId: string, options: PageOptions = {}) => {
            const refId = buildDataPropertyGetter(path)(pageId, options)
            const {relationships} = extensionAPI as RelationshipsAPI

            if (refId) {
                const refIdWithoutTranslation = _.last(
                    getTranslationInfoFromKey(relationships.getIdFromRef(refId))
                ) as string
                const translatedItemPointer = getTranslatedDataItemFromMaster(
                    refIdWithoutTranslation,
                    options.languageCode
                )
                return dal.get(translatedItemPointer)
            }

            return refId
        }

    const getHomepageId = () =>
        dal.get(getInnerPointer(pointers.data.getDataItemFromMaster(MASTER_PAGE_ID), ['mainPageId']))

    const getPageUriSEO = (pageId: string) => {
        const pageDataItemPointer = pointers.data.getDataItemFromMaster(pageId)
        const pageUriSEOPointer = getInnerPointer(pageDataItemPointer, 'pageUriSEO')

        return dal.get(pageUriSEOPointer)
    }

    const getAllPagesIds = (includeMasterPage: boolean): string[] => getIdsOfAllPages(dal, includeMasterPage)

    const getMainPageId = () => {
        const {relationships} = extensionAPI as RelationshipsAPI
        const masterPageDataPointer = pointers.data.getDataItemFromMaster('masterPage')
        const masterPageData = dal.get(masterPageDataPointer)
        const {mainPage} = masterPageData
        return masterPageData.mainPageId || _.isString(mainPage) ? relationships.getIdFromRef(mainPage) : mainPage?.id
    }

    const getPageUrl = (pageInfo: PageInfo, urlFormat?: string, baseUrl?: string) => {
        const actualBaseUrl = baseUrl ?? dal.get(pointers.documentServicesModel.getPublicUrl())
        const {pageId} = pageInfo
        const mainPageId = getHomepageId()

        if (!pageId || pageId === mainPageId) {
            return actualBaseUrl
        }

        const pageUriSEO = getPageUriSEO(pageId)
        const appendix = actualBaseUrl.endsWith('/') ? pageUriSEO : `/${pageUriSEO}`

        //Assuming urlFormat is always slash at this point
        return `${actualBaseUrl}${appendix}`
    }

    const getAllPagesIndexId = () => dal.queryFilterGetters.getPageCompFilter(ALL_PAGES_INDEX_ID)

    const getPageIndexId = (pageId: string | null) =>
        pageId ? dal.queryFilterGetters.getPageCompFilter(pageId) : getAllPagesIndexId()

    const pageTypes = ['Page', 'Document']
    const isPage = (value: DalItem) => pageTypes.includes(value.type!)
    const removeMobileStructure = (pageId: string) => {
        const indexKey = dal.queryFilterGetters.getPageCompFilter(pageId)
        _.forEach(dal.query('MOBILE', indexKey), (value, id) => {
            if (!isPage(value)) {
                dal.remove(getPointer(id, 'MOBILE'))
            }
        })
    }

    const getPageDataTranslations = () => {
        const multilingualAPI = extensionAPI.multilingualTranslations as MultilingualTranslationsAPI
        const pageIds = getAllPagesIds(false)
        const translationPointers = _.flatMap(pageIds, multilingualAPI.getTranslationsById)
        return _.map(translationPointers, ({id}) => {
            const [, languageCode, pageId] = getTranslationInfoFromKey(id)
            return {languageCode, pageId}
        })
    }

    const isPartiallyLoaded = () => {
        const pageIds = getAllPagesIds(false)

        for (const pageId of pageIds) {
            if (!hasPageBeenLoaded(pageId)) {
                return true
            }
        }

        return false
    }

    const getLoadedPages = () => getAllPagesIds(true).filter(hasPageBeenLoaded)

    const addHeaderRefComponent = (pagePointer: Pointer): Pointer | undefined => {
        const headerSectionPointer = pointers.page.getHeaderPointer()
        if (!headerSectionPointer) {
            return
        }
        return componentsAPI().addComponent(pagePointer, {
            componentType: 'wysiwyg.viewer.components.RefComponent',
            data: {
                type: 'InternalRef',
                rootCompId: headerSectionPointer.id
            }
        })
    }

    const addFooterRefComponent = (pagePointer: Pointer): Pointer | undefined => {
        const footerSectionPointer = pointers.page.getFooterPointer()
        if (!footerSectionPointer) {
            return
        }
        return componentsAPI().addComponent(pagePointer, {
            componentType: 'wysiwyg.viewer.components.RefComponent',
            data: {
                type: 'InternalRef',
                rootCompId: footerSectionPointer.id
            }
        })
    }

    const addBlankPage = (pageStructureDefinition: CompStructure): Pointer => {
        const pageDefinition = defaultDefinitions().createComponentDefinition(pageStructureDefinition)
        const pageId = generateItemIdWithPrefix('page')
        const blankPagePointer = pointerUtils.getPointer(pageId, 'DESKTOP')

        componentsAPI().setComponent(pageDefinition, pageId, blankPagePointer)
        componentsAPI().addLayoutsAsResponsiveLayout(blankPagePointer, pageDefinition.layouts)
        componentsAPI().addBreakpointVariants(blankPagePointer, pageDefinition.breakpointVariants)
        addPageData(extArgs, blankPagePointer, pageDefinition.data)

        return blankPagePointer
    }

    const addPage = (pageData: PagesData): Pointer => {
        const pageStructureDefinition = {
            componentType: 'mobile.core.components.Page',
            data: pageData
        } as CompStructure
        const pagePointer = addBlankPage(pageStructureDefinition)
        addHeaderRefComponent(pagePointer)
        addFooterRefComponent(pagePointer)
        eventEmitter.emit(PAGE_EVENTS.PAGE.PAGE_ADDED, pagePointer.id, true)
        return pagePointer
    }

    const getOwnersOfItemsBasedOnQueryName = (queryName: string, pageId: string, viewMode: string): Pointer[] => {
        const {relationships} = extensionAPI as RelationshipsAPI
        const pageCompFilter = dal.queryFilterGetters.getPageCompFilter(pageId)
        const allQueryNameItemsInPage = dal.queryKeys(queryName, pageCompFilter)
        return _.flatMap(allQueryNameItemsInPage, id =>
            relationships.getOwningReferencesToPointer(getPointer(id, queryName), viewMode)
        )
    }

    const NAMESPACES_FOR_PAGES = _.keys(PAGE_DATA_TYPES).concat(_.values(VIEW_MODES))

    const getChangedPagesSinceLastSnapshot = (tag: string, isChangedFunc?: IsChangedFunc) => {
        //TODO-extensionsAPI: move test from generalSuperDal.spec to page.spec and remove function from there
        const fromSnapshot = snapshots.getLastSnapshotByTagName(tag) ?? snapshots.getInitialSnapshot()
        const diff = snapshots.getChangesFromSnapshot?.(fromSnapshot)
        const pageIdSet: Set<string> = new Set<string>()
        const addToChangedPages = (item: DalValue) => {
            const pageId = item.metaData?.pageId
            if (pageId) {
                pageIdSet.add(pageId)
            }
        }

        for (const type of NAMESPACES_FOR_PAGES) {
            const typeValues = diff?.[type]
            if (typeValues) {
                for (const id of Object.keys(typeValues)) {
                    const item = typeValues[id]
                    const wasDeleted = item === undefined
                    if (wasDeleted) {
                        const prevItem = fromSnapshot.getValue({type, id})
                        addToChangedPages(prevItem)
                    } else if (isChangedFunc) {
                        const isChanged = isChangedFunc(item, type, fromSnapshot)
                        if (isChanged) {
                            addToChangedPages(item)
                        }
                    } else {
                        addToChangedPages(item)
                    }
                }
            }
        }
        return [...pageIdSet.values()]
    }

    const getNonDeletedChangedPagePointersSinceLastSnapshot = (tag: string, isChangedFunc?: IsChangedFunc) => {
        const nonDeletedPagePointers = pointers.page.getNonDeletedPagesPointers(true)
        const changedPageIds = getChangedPagesSinceLastSnapshot(tag, isChangedFunc)
        return nonDeletedPagePointers.filter(pointer => changedPageIds.includes(pointer.id))
    }

    const touchHomePageToPreventConcurrentHomepageDeletion = (): void => {
        // Create a conflict between page deletion and setting the home page
        // See https://jira.wixpress.com/browse/DM-4220
        // This may be removed if and when the deletion of dal items takes part in the conflict resolution mechanism
        // of the concurrent editing effort
        const homePagePointer = pointers.data.getDataItem('masterPage')
        dal.touch(homePagePointer)
    }

    const removePageFromPagesGroup = (pageId: string): void => {
        const collectionPtr = pointers.data.getDataItem('PAGES_GROUP_COLLECTION', 'masterPage')
        if (!collectionPtr) {
            return
        }
        const groupsPtr = getInnerPointer(collectionPtr, 'groups')
        const groupIds: string[] = dal.get(groupsPtr) ?? []
        const groupPointers = groupIds.map(id => pointers.data.getDataItem(stripHashIfExists(id), 'masterPage'))
        const isPageId = (s: string): boolean => stripHashIfExists(s) === stripHashIfExists(pageId)
        groupPointers.forEach(ptr => {
            dal.modify(ptr, update(['pages'], reject(isPageId)))
        })
    }

    const markPageAsDeleted = (pageId: string): void => {
        const deletedPagesMapPointer = pointers.general.getDeletedPagesMapPointer()
        const pageMap = dal.get(deletedPagesMapPointer) ?? {}
        dal.set(deletedPagesMapPointer, {
            ...pageMap,
            [pageId]: true
        })
    }

    const removePage = (pageId: string): void => {
        const pageComponentPointer = pointers.structure.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        removePageFromPagesGroup(pageId)
        touchHomePageToPreventConcurrentHomepageDeletion()
        const fixerVersions = (extensionAPI.dataModel as unknown as DataModelAPI).components.getItem(
            pageComponentPointer,
            DATA_TYPES.fixerVersions
        )
        if (fixerVersions) {
            dal.remove(getPointer(fixerVersions.id, DATA_TYPES.fixerVersions))
        }
        ;(extensionAPI.components as ComponentsAPI).removeComponent(pageComponentPointer)
        ;(extensionAPI.routers as RoutersAPI['routers']).removePageFromRoutersConfigMap(pageId)
        markPageAsDeleted(pageId)
    }

    const getParentPage = (pageId: string): string | undefined => {
        return buildDataPropertyGetter('parentPageId')(pageId)
    }

    return {
        page: {
            // getters
            getAdvancedSeoData: buildDataPropertyGetter('advancedSeoData'),
            getDescriptionSEO: buildDataPropertyGetter('descriptionSEO'),
            getPageTitleSEO: buildDataPropertyGetter('pageTitleSEO'),
            // wrapped to avoid adding unexpected possibility to get multilingual for this
            getIndexable: (pageId: string) => buildDataPropertyGetter('indexable')(pageId),
            getMetaKeywordsSEO: buildDataPropertyGetter('metaKeywordsSEO'),
            getPageUriSEO: buildDataPropertyGetter('pageUriSEO'),
            getOgImageRef: buildDataRefPropertyGetter('ogImageRef'),
            // setters
            setAdvancedSeoData: buildDataPropertySetter('advancedSeoData'),
            setDescriptionSEO: buildDataPropertySetter('descriptionSEO'),
            setPageTitleSEO: buildDataPropertySetter('pageTitleSEO'),
            setOgImageRef: buildDataRefPropertySetter('ogImageRef'),
            // wrapped to avoid adding unexpected possibility to set multilingual for this
            setIndexable: (pageId: string, value: boolean) => buildDataPropertySetter('indexable')(pageId, value),
            setMetaKeywordsSEO: buildDataPropertySetter('metaKeywordsSEO'),
            setPageUriSEO: buildDataPropertySetter('pageUriSEO'),
            getAllPagesIds,
            getMainPageId,
            getAllPagesIndexId,
            getPageIndexId,
            removeMobileStructure,
            isPartiallyLoaded,
            getLoadedPages,
            hasPageBeenLoaded,
            getAllCompsOnPage: (pageId: string, viewMode: PossibleViewModes) =>
                getAllCompsOnPage(dal, pageId, viewMode),
            addPage,
            data: {
                update: updatePageData,
                get: getPageData
            },
            hierarchy: {
                getParentPage
            },
            getOwnersOfItemsBasedOnQueryName,
            getChangedPagesSinceLastSnapshot,
            getNonDeletedChangedPagePointersSinceLastSnapshot,
            removePage
        },
        siteAPI: {
            getPageUrl,
            getAllPagesIds,
            isPageContainsComponentType: (pageId, componentType) => {
                const pageCompFilter = dal.queryFilterGetters.getPageCompFilter(pageId)
                return !!dal.find(DATA_TYPES.data, pageCompFilter, value => value.type === componentType)
            },
            getPageDataTranslations
        }
    }
}
const isPage = (type: string | undefined) => (type ? !!PAGE_TYPES[type] : false)

const createFilters = () => ({
    getPageFilter: (namespace: string, value: DalItem): string[] => {
        const type = value?.type
        const id = value?.id ?? ''
        if (!type || namespace !== 'data' || NON_PAGE_IDS.includes(id)) {
            return NO_MATCH
        }

        if (isPage(type)) {
            return [includeMasterPageFilterId, excludeMasterPageFilterId]
        }
        if (type === MASTER_PAGE_TYPE) {
            return [includeMasterPageFilterId]
        }

        return NO_MATCH
    },
    getPageCompFilter: (namespace: string, value: any): string[] => {
        const pageId = value?.metaData?.pageId
        return pageId ? [pageId, ALL_PAGES_INDEX_ID] : NO_MATCH
    },
    [SCHEMA_LOOKUP_INDEX]: (namespace: Namespace, value: DalValue): string[] => {
        if (!value?.type || !value.metaData?.pageId) {
            return NO_MATCH
        }
        if (value?.componentType) {
            return [getPageAndSchemaIndex(value.componentType, namespace, value.metaData.pageId)]
        }
        return [getPageAndSchemaIndex(value.type, namespace, value.metaData?.pageId)]
    }
})

const shouldValidateCrossPageRefs = (coreConfig: CoreConfig): boolean =>
    coreConfig.strictModeFailDefault || coreConfig.experimentInstance.isOpen('dm_crossPageRefFail')

export interface PageOptions {
    languageCode?: string
}

export interface OgImage {
    width: number
    height: number
    uri: string
    type?: 'Image'
    alt?: string
    title?: string
    link?: string
    originalImageDataRef?: string
    metaData?: MetaData
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type PageAPI = {
    getAdvancedSeoData(pageId: string, options?: PageOptions): string
    getDescriptionSEO(pageId: string, options?: PageOptions): string
    getPageTitleSEO(pageId: string, options?: PageOptions): string
    getIndexable(pageId: string): boolean
    getMetaKeywordsSEO(pageId: string, options?: PageOptions): string
    getPageUriSEO(pageId: string, options?: PageOptions): string
    setAdvancedSeoData(pageId: string, value: string, options?: PageOptions): void
    setDescriptionSEO(pageId: string, value: string, options?: PageOptions): void
    setPageTitleSEO(pageId: string, value: string, options?: PageOptions): void
    setIndexable(pageId: string, value: boolean): void
    setMetaKeywordsSEO(pageId: string, value: string, options?: PageOptions): void
    setPageUriSEO(pageId: string, value: string, options?: PageOptions): void
    getAllPagesIds(includeMasterPage: boolean): string[]
    getOgImageRef(pageId: string, options?: PageOptions): OgImage
    setOgImageRef(pageId: string, value: OgImage, options?: PageOptions): void
    getMainPageId(): string
    getAllPagesIndexId(): IndexKey
    getPageIndexId(pageId: string | null): IndexKey
    getAllCompsOnPage(pageId: string, viewMode: PossibleViewModes): Record<string, Component>
    removeMobileStructure(pageId: string): void
    isPartiallyLoaded(): boolean
    hasPageBeenLoaded(pageId: string): boolean
    getLoadedPages(): string[]
    addPage(pageData: PagesData): Pointer
    getOwnersOfItemsBasedOnQueryName(queryName: string, pageId: string, viewMode: string): Pointer[]
    getChangedPagesSinceLastSnapshot(tag: string, isChangedFunc?: IsChangedFunc): string[]
    getNonDeletedChangedPagePointersSinceLastSnapshot(tag: string, isChangedFunc?: IsChangedFunc): Pointer[]
    removePage(pageId: string): void
    data: {
        update(
            pagePointer: Pointer,
            data: Record<string, any>,
            applyChangeToAllLanguages?: boolean,
            language?: string
        ): void
        get(pageId: string, languageCode?: string): Record<string, any>
    }
    hierarchy: {
        getParentPage(pageId: string): string | undefined
    }
}

interface PageInfo {
    pageId: string
}

export interface PageExtensionAPI extends ExtensionAPI {
    page: PageAPI
    siteAPI: {
        getPageUrl(pageInfo: PageInfo, urlFormat?: string, baseUrl?: string): string
        getAllPagesIds(includeMasterPage: boolean): string[]
        isPageContainsComponentType(pageId: string, componentType: string): boolean
        getPageDataTranslations(): PageDataLanguages[]
    }
}

const createExtension = (arg?: CreateExtensionArgument): Extension => {
    const isBlocksEditor = arg?.dsConfig?.origin === 'responsiveBlocks'

    const createValidator = (apis: DmApis): Record<string, ValidateValue> => {
        const {dal, extensionAPI, coreConfig} = apis
        const failCrossPageRefs = shouldValidateCrossPageRefs(coreConfig)

        return {
            validatePageId: (pointer, value) => {
                const {id, type: dalValueType, metaData} = value ?? {}
                const {type: pointerType} = pointer
                if (pointerType === DATA_TYPES.data && isPage(dalValueType) && metaData.pageId !== MASTER_PAGE_ID) {
                    return [
                        {
                            shouldFail: true,
                            type: 'invalidMetadataPageId',
                            message: `metadata.pageId of page ${id} should be masterPage and not ${metaData.pageId}`,
                            extras: {id}
                        }
                    ]
                }
            },
            validateReverseSamePageRef: (pointer: Pointer, value: DalValue) => {
                const {relationships} = extensionAPI as RelationshipsAPI
                const pageId = value?.metaData?.pageId
                if (!pageId) {
                    return undefined
                }

                const referrers: Pointer[] = relationships.getReferencesToPointer(pointer)
                return _.compact(
                    referrers.map((parentRef: Pointer) => {
                        const parentValue = dal.get(parentRef)
                        const parentPageId = parentValue.metaData?.pageId
                        if (!parentPageId || parentPageId === pageId) {
                            return undefined
                        }
                        if (pageId === 'masterPage') {
                            return undefined
                        }
                        if (isPage(parentValue.type) && pageId === parentValue.id) {
                            // in case of page data, allow the reference to be on the page
                            return undefined
                        }
                        // In the blocks editor, when creating and editing widgets, refComponents are created that reference components in other pages.
                        // This is a valid flow in their widget creation process, so we exclude this rule for them.
                        if (
                            isBlocksEditor &&
                            parentValue.type === 'InternalRef' &&
                            parentValue.rootCompId === value.id
                        ) {
                            return undefined
                        }
                        return {
                            shouldFail: failCrossPageRefs,
                            type: 'ReverseSamePageRefError',
                            message: `${JSON.stringify(pointer)} in page "${pageId}" is referenced by ${JSON.stringify(
                                parentRef
                            )} in "${parentPageId}"`,
                            extras: {
                                from: parentRef,
                                to: pointer,
                                fromPageId: parentPageId,
                                toPageId: pageId,
                                parentValue,
                                value
                            }
                        }
                    })
                )
            },
            validateUniquePageUri: (pointer: Pointer, value: DalValue) => {
                if (pointer.type !== 'data' || !value || value.type !== 'Page') {
                    return undefined
                }

                const uri = value.pageUriSEO
                const allPageIds = getIdsOfAllPages(dal, false)
                const duplicate = _.find(allPageIds, pageId => {
                    if (pageId === pointer.id) {
                        return false
                    }
                    const pagePointer = getPointer(pageId, DATA_TYPES.data)
                    const pageData = dal.get(pagePointer)

                    return pageData.pageUriSEO === uri
                })

                if (duplicate) {
                    return [
                        {
                            shouldFail: true,
                            type: 'duplicatePageUriError',
                            message: `URI ${uri} is duplicate between ${pointer.id} and ${duplicate}`,
                            extras: {
                                uri,
                                duplicate
                            }
                        }
                    ]
                }

                return undefined
            }
        }
    }

    const validateSamePageRef = (
        pointer: Pointer,
        value: DalItem,
        referred: DalItem,
        reference: ResolvedReference,
        shouldFail: boolean
    ) => {
        const originPageId = value.metaData?.pageId
        if (!originPageId) {
            return undefined
        }

        const referredPageId = referred.metaData?.pageId
        if (referredPageId === originPageId || referredPageId === 'masterPage') {
            return undefined
        }

        if (isPage(value.type) && referredPageId === value.id) {
            // in case of page data, allow the reference to be on the page
            return undefined
        }

        // In the blocks editor, when creating and editing widgets, refComponents are created that reference components in other pages.
        // This is a valid flow in their widget creation process, so we exclude this rule for them.
        if (isBlocksEditor) {
            if (value.type === 'InternalRef' && value.rootCompId === reference.id) {
                return undefined
            }
        }

        return [
            {
                shouldFail,
                type: 'wrongPageRefError',
                message: `${value.id} on page ${originPageId} referenced ${reference.id} on page ${referredPageId}`,
                extras: {
                    referred,
                    reference
                }
            }
        ]
    }

    const initialize = async ({extensionAPI, coreConfig}: DmApis) => {
        const {relationships} = extensionAPI as RelationshipsAPI
        const failCrossPageRefs = shouldValidateCrossPageRefs(coreConfig)
        relationships.registerCustomRefValidation((...args) => validateSamePageRef(...args, failCrossPageRefs))
    }

    return {
        EVENTS: PAGE_EVENTS,
        name: 'page',
        dependencies: new Set(['serviceTopology', 'data', 'relationships', 'multilingual', 'documentServicesModel']),
        createExtensionAPI,
        createPointersMethods,
        getDocumentDataTypes,
        createGetters,
        createFilters,
        initialState,
        createValidator,
        initialize
    }
}

export {createExtension}
