import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import pathUtils from '../utils/pathUtils'
import types from './types'

const ERROR_TYPE_DOES_NOT_EXIST = 'Type does not exist'
const ERROR_SELECTOR_DOES_NOT_EXIST = 'Selector does not exist'

const dataSelectorsTranslationMap = {
    'IB.ManualSelectedList': 'ManualSelectedList'
}

function throwError(errorMessage) {
    throw new Error(errorMessage)
}

function getDataSelector(ps, dataSelectorId) {
    return ps.wixappsDAL.getByPath(pathUtils.getDataSelectorPath(dataSelectorId))
}

function isArrayOfStrings(itemIds) {
    return _.isArray(itemIds) && _.every(itemIds, item => _.isString(item))
}

function filterNonExistingItems(ps, type, itemIds) {
    const existingItemsOfType = ps.wixappsDAL.getByPath(pathUtils.getBaseItemsPath(type)) || {}
    return _.intersection(itemIds, _.map(existingItemsOfType, '_iid'))
}

/**
 * Get a data selector definition by its ID
 * @param {ps} ps Private Services
 * @param {string} dataSelectorId
 * @returns {Object} The requested data selector definition
 */
function getSelector(ps, dataSelectorId: string) {
    const dataSelector = getDataSelector(ps, dataSelectorId)
    if (dataSelector) {
        const returnValue = _.omit(dataSelector, ['dataProviderId', 'forType', 'logicalTypeName'])
        returnValue.type = dataSelectorsTranslationMap[dataSelector.logicalTypeName]
        return returnValue
    }
}

/**
 * Set a selector as manual with given item IDs
 * @param {ps} ps Private Serices
 * @param {string} dataSelectorId
 * @param {string[]} itemIds
 * @throws Throws an error if the data selector does not exist
 */
function setManualSelector(ps, dataSelectorId, itemIds) {
    if (!isArrayOfStrings(itemIds)) {
        throw new Error('itemsId must be an array of strings.')
    }
    const dataSelector = getDataSelector(ps, dataSelectorId) || throwError(ERROR_SELECTOR_DOES_NOT_EXIST)
    dataSelector.itemIds = filterNonExistingItems(ps, dataSelector.forType, itemIds)
    dataSelector.logicalTypeName = 'IB.ManualSelectedList'
    ps.wixappsDAL.setByPath(pathUtils.getDataSelectorPath(dataSelectorId), dataSelector) // TODO: merge instead of set ?
}

/**
 * Create a new data selector with a given type
 * @param {ps} ps Private Services
 * @param {string} typeId
 * @returns {string} ID of the created data selector
 * @throws Throws an error if the type does not exist
 */
function createSelector(ps, typeId) {
    if (!types.getType(ps, typeId)) {
        throwError(ERROR_TYPE_DOES_NOT_EXIST)
    }
    const dataSelector = {
        id: `dataSelector_${santaCoreUtils.guidUtils.getUniqueId(undefined, undefined)}`,
        forType: typeId
    }
    ps.wixappsDAL.setByPath(pathUtils.getDataSelectorPath(dataSelector.id), dataSelector)
    return dataSelector.id
}

/**
 * Deletes an item from all of the manual data selectors
 * @param {ps} ps Private Services
 * @param {string} itemId
 */
function deleteItemFromAllManualDataSelectors(ps, itemId) {
    const dataSelectors = ps.wixappsDAL.getByPath(pathUtils.getBaseDataSelectorsPath())
    _(dataSelectors)
        .filter({logicalTypeName: 'IB.ManualSelectedList'})
        .forEach(function (selector) {
            const newItemIds = _.reject(selector.itemIds, id => id === itemId)
            if (newItemIds.length !== selector.itemIds.length) {
                setManualSelector(ps, selector.id, newItemIds)
            }
        })
}

export default {
    getSelector,
    setManualSelector,
    createSelector,
    deleteItemFromAllManualDataSelectors
}
