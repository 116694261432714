import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import styleUtils from './styles/utils'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import theme from '../../theme/theme'
import componentStylesAndSkinsAPI from '../../component/componentStylesAndSkinsAPI'
import design from '../../variants/design'

const compDef = santaCoreUtils.vectorImageConversionUtils.vectorImageDefaults

function transformDataToDesign(ps, compPointer, styleAndColors) {
    const compDesign = _.assign(design.getDesignItem(ps, compPointer), {type: 'VectorImageDesignData'}, styleAndColors)
    const compData = _.omit(dataModel.getDataItem(ps, compPointer), _.keys(styleAndColors))

    design.updateDesignItem(ps, compPointer, compDesign)
    // we must delete old data before updating with the modified,
    // updateDataItem is merging the given object into the existing data
    dataModel.deleteDataItem(ps, compPointer)
    dataModel.updateDataItem(ps, compPointer, compData)
}

function transformStyleToDesignData(ps, compPointer, compStyle) {
    const compDesign = design.getDesignItem(ps, compPointer) || {}
    // @ts-expect-error
    santaCoreUtils.vectorImageConversionUtils.convertStyleToDesignData(compDesign, compStyle)
    design.updateDesignItem(ps, compPointer, compDesign)
}

function fixComponentModes(ps, compPointer, stylesToDelete) {
    const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
    const overridesPointer = ps.pointers.componentStructure.getModesOverrides(compPointer)
    const modesOverrides = ps.dal.full.isExist(overridesPointer) && ps.dal.full.get(overridesPointer)
    let updateModesFlag = false
    _.forEach(modesOverrides, function (override) {
        if (override.styleId) {
            const overrideCompStyle = theme.styles.get(ps, override.styleId, pageId)
            if (!_.isEmpty(_.get(overrideCompStyle, ['style', 'properties']))) {
                const overrideCompDesignId = dataModel.generateNewDesignItemId()
                const overrideCompDesign: any = {}
                const overrideCompDesignPointer = ps.pointers.data.getDesignItem(overrideCompDesignId, pageId)

                // @ts-expect-error
                santaCoreUtils.vectorImageConversionUtils.convertStyleToDesignData(
                    overrideCompDesign,
                    overrideCompStyle
                )
                overrideCompDesign.id = overrideCompDesignId
                override.designQuery = `#${overrideCompDesignId}`

                ps.dal.full.set(overrideCompDesignPointer, overrideCompDesign)

                stylesToDelete.push({styleId: override.styleId, pageId})
                _.unset(override, 'styleId')
                updateModesFlag = true
            }
        }
    })

    if (updateModesFlag) {
        ps.dal.full.set(overridesPointer, modesOverrides)
    }
}

function getAllCompsFromFull(ps) {
    return componentDetectorAPI.getAllComponentsFromFull(ps, null, function (pointer) {
        return (
            ps.dal.full.get(ps.pointers.getInnerPointer(pointer, 'componentType')) ===
            'wysiwyg.viewer.components.VectorImage'
        )
    })
}

function getAllCompsFromDisplayed(ps) {
    return componentDetectorAPI.getAllComponents(
        ps,
        null,
        pointer =>
            ps.dal.get(ps.pointers.getInnerPointer(pointer, 'componentType')) ===
            'wysiwyg.viewer.components.VectorImage'
    )
}

function convertStyle(ps, compStyle, compData, compPointer, pageId, stylesToDelete) {
    // If style is not empty, we need to convert from style (means component was converted by viewer data fixer)
    // Else, we still check that the design info is not in data nad convert it if needed
    // TODO: This is temporary so we won't break our test sites. Remove after we are sure we don't need it any more.
    if (_.isEmpty(compStyle.style.properties)) {
        const styleAndColors = _.pick(compData, ['overrideColors', 'shapeStyle'])
        if (!_.isEmpty(styleAndColors)) {
            _.defaults(styleAndColors, {overrideColors: null})
            transformDataToDesign(ps, compPointer, styleAndColors)
        }
    } else {
        stylesToDelete.push({styleId: compStyle.id, pageId})
        transformStyleToDesignData(ps, compPointer, compStyle)
    }
}

const createAndTransformStyle = (ps, compPointer, newComponentStyle) => {
    const newCompStyle = {
        type: newComponentStyle,
        style: {
            properties: compDef.style,
            propertiesSource: {}
        }
    }
    santaCoreUtils.vectorImageConversionUtils.convertStyle(newCompStyle, compDef)
    componentStylesAndSkinsAPI.style.internal.update(ps, compPointer, newCompStyle, _.noop, true)

    transformStyleToDesignData(ps, compPointer, newCompStyle)
}

export default {
    /**
     * fix VectorImage , convert the style to design data item, (desktop, mobile, component override)
     * @param {ps} ps
     */
    exec(ps) {
        const {
            STYLES: {TOP_LEVEL_STYLE, COMPONENT_STYLE}
        } = constants
        const allVectorImageComps = _.unionBy(getAllCompsFromFull(ps), getAllCompsFromDisplayed(ps), 'id')
        const newComponentStyle = styleUtils.isSiteMigrated(ps) ? COMPONENT_STYLE : TOP_LEVEL_STYLE
        const stylesToDelete = []

        // For each VectorImage
        _.forEach(allVectorImageComps, function (compPointer) {
            const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
            const compStructure = ps.dal.full.get(compPointer)
            const compStyle = compStructure && theme.styles.get(ps, compStructure.styleId, pageId)

            if (!compStyle) {
                createAndTransformStyle(ps, compPointer, newComponentStyle)
            } else {
                const compData = dataModel.getDataItem(ps, compPointer)

                const style = componentStylesAndSkinsAPI.style.get(ps, compPointer)
                // check this in case we have empty refArray
                if (style) {
                    convertStyle(ps, style, compData, compPointer, pageId, stylesToDelete)
                }
            }

            //fix component modes
            fixComponentModes(ps, compPointer, stylesToDelete)
        })

        _.forEach(_.uniq(stylesToDelete), function (styleIdAndPageId) {
            const {styleId, pageId} = styleIdAndPageId

            const compStyle = theme.styles.get(ps, styleId, pageId)

            compStyle.style.properties = {}
            compStyle.style.propertiesSource = {}

            theme.styles.update(ps, styleId, compStyle, pageId)
        })
    },
    name: 'VectorImageDataFixer',
    version: 1
}
