'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

/**
 * @exports utils/dataFixer/plugins/fiveGridLineLayoutFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'linkRefDataFixer',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        /***************************************************************************************************
         * This plugin is part of the link refactor (merged experiment: linkrefdata).                      *
         * It does 3 things:                                                                               *
         * 1. Convert link data items to new ones (with new schemas)                                       *
         * 2. Covert FlashComponent & SiteButton to new schemas (with new links like #1)                   *
         * 3. Fixes Image schema to support the new links and updates schema version (for server support)  *
         ***************************************************************************************************/

        //Handle cases that the DataFixer is running but the DataManager is not available yet.
        //This DataFixer plugin will be called again when the data DataManager is ready
        //(skipped JSONs will be fixed later)
        //                if(!window.W.Data) {
        //                    return pageJson;
        //                }

        const schemasNames = {
                toFix: ['FlashComponent', 'SiteButton', 'Image'],
                newNames: ['LinkableFlashComponent', 'LinkableButton', 'Image'], //Image keeps the schema name and updates the schema version
                originalLink: ['TextLink', 'Link']
            },
            linkDataAttributes = ['href', 'text', 'target', 'icon', 'linkType'],
            linkTargets = {
                oldValues: ['same', 'other'],
                newValues: ['_self', '_blank']
            }

        function filterSchemasToFix(items) {
            return _.filter(items, dataItem => {
                const isImage = dataItem.type === schemasNames.toFix[2]
                const isOldImageSchema = !isImage || dataItem.metaData.schemaVersion !== '2.0'

                return !dataItem.link && _.includes(schemasNames.toFix, dataItem.type) && isOldImageSchema
            })
        }

        function filterLinkItemsToFix(items) {
            return _.filter(
                items,
                dataItem => dataItem.linkType && _.includes(schemasNames.originalLink, dataItem.type)
            )
        }

        function createItemFromNewSchema(dataItem, uniqueIdGenerator) {
            const schemaIndex = _.indexOf(schemasNames.toFix, dataItem.type)
            const newSchemaName = schemasNames.newNames[schemaIndex]
            //Copy schema specific attributes from original data item (without old link attributes)
            /** @type * */
            const newDataItem = _.defaults({type: newSchemaName}, _.omit(dataItem, linkDataAttributes))

            //Create a new link data item and add reference to it in the new data item
            const linkItem = createNewLinkItem(dataItem, true, uniqueIdGenerator)
            if (linkItem) {
                newDataItem.link = createLinkRef(linkItem.id)
                newLinkDataItems[linkItem.id] = linkItem //Save it to be added later to DataManager
            }

            //If the schema name wasn't changes (Image) - update the schema version
            if (dataItem.type === newDataItem.type) {
                newDataItem.metaData.schemaVersion = '2.0'
            }

            return newDataItem
        }

        function createNewLinkItem(dataItem, shouldGetUniqueId, uniqueIdGenerator) {
            const linkType = dataItem.linkType || ''
            const lowerCaseLinkType = linkType.toLowerCase()
            const id = shouldGetUniqueId ? uniqueIdGenerator.getUniqueId() : dataItem.id

            switch (lowerCaseLinkType) {
                case 'page':
                    return createPageLink(id, dataItem)
                case 'website':
                    return createExternalLink(id, dataItem)
                case 'email':
                    return createEmailLink(id, dataItem)
                case 'document':
                    return createDocumentLink(id, dataItem)
                case 'login':
                    return createLoginToWixLink(id, dataItem)
                case 'admin_login':
                    return null //This link type is deprecated. AdminLoginButton component doesn't use it anymore
                case 'free_link':
                    return null //Nothing to do with this link (used by eCom, wixapps handles them)
                case '':
                    return null //Nothing to do (there is no link)
                default:
                    return null
            }
        }

        function createExternalLink(uniqueLinkId, dataItem) {
            const linkItem = {}
            linkItem.id = uniqueLinkId
            linkItem.type = 'ExternalLink'
            linkItem.target = getLinkTarget(dataItem.target)
            const url = dataItem.href
            if (!url) {
                return null
            }
            linkItem.url = url
            return linkItem
        }

        function createPageLink(uniqueLinkId, dataItem) {
            const linkItem = {}
            linkItem.id = uniqueLinkId
            linkItem.type = 'PageLink'
            let url = dataItem.href
            if (!url) {
                return null
            }
            let indexOfPageId = url.lastIndexOf('/')
            if (indexOfPageId < 0) {
                indexOfPageId = url.lastIndexOf('|') //Legacy sites
            }
            url = url.substr(indexOfPageId + 1)
            linkItem.pageId = createLinkRef(url)
            return linkItem
        }

        function createDocumentLink(uniqueLinkId, dataItem) {
            const linkItem = {}
            let docId
            linkItem.type = 'DocumentLink'
            linkItem.id = uniqueLinkId
            const docURL = dataItem.href
            if (!docURL) {
                return null
            }
            docId = docURL.substr(docURL.lastIndexOf('/') + 1)

            const queryParamStartIndex = docId.indexOf('?dn=')
            if (queryParamStartIndex !== -1) {
                docId = docId.substring(0, queryParamStartIndex)
            }
            linkItem.docId = docId

            linkItem.name = dataItem.text
            return linkItem
        }

        function createEmailLink(uniqueLinkId, dataItem) {
            const linkItem = {}
            linkItem.id = uniqueLinkId
            linkItem.type = 'EmailLink'
            let url = dataItem.href
            if (!url || !url.toLowerCase) {
                return null
            }
            if (coreUtils.stringUtils.startsWith(url, 'mailto:', true)) {
                url = url.substr('mailto:'.length)
            }
            const emailAndParams = url.split('?')
            linkItem.recipient = emailAndParams[0]
            if (emailAndParams[1]) {
                const params = parseUrlParams(emailAndParams[1])
                if (params.subject) {
                    linkItem.subject = params.subject
                }
                if (params.body) {
                    linkItem.body = params.body
                }
            }

            return linkItem
        }

        function createLoginToWixLink(uniqueLinkId, dataItem) {
            const linkItem = {}
            linkItem.id = uniqueLinkId
            linkItem.type = 'LoginToWixLink'

            let loginParams = dataItem.text
            if (loginParams && coreUtils.stringUtils.startsWith(loginParams, '{')) {
                loginParams = JSON.parse(loginParams)
                linkItem.postLoginUrl = loginParams.postLoginUrl
                linkItem.postSignupUrl = loginParams.postSignupUrl
                linkItem.dialog = loginParams.type
            }

            return linkItem
        }

        function createLinkRef(linkId) {
            if (!linkId) {
                return null
            }
            if (linkId && coreUtils.stringUtils.startsWith(linkId, '#')) {
                return linkId
            }
            return `#${linkId}`
        }

        function getLinkTarget(oldTarget) {
            let newTarget
            const oldTargetsIndex = _.indexOf(linkTargets.oldValues, oldTarget)

            if (_.includes(linkTargets.newValues, oldTarget)) {
                newTarget = oldTarget
            } else if (oldTargetsIndex !== -1) {
                newTarget = linkTargets.newValues[oldTargetsIndex] //The the corresponding new target value
            } else {
                newTarget = linkTargets.newValues[1] //fallback - _blank target
            }

            return newTarget
        }

        function parseUrlParams(paramsStr) {
            const mailParamsObj = {}
            const keyValueRegEx = /([^&=]+)=([^&]*)/g
            let tmp

            //Extract all key-value pairs (format: key=value) from the mail parameters string
            while ((tmp = keyValueRegEx.exec(paramsStr))) {
                mailParamsObj[tmp[1]] = tmp[2]
            }

            return mailParamsObj
        }

        const {data} = pageJson
        const dataItems = data.document_data
        const newLinkDataItems = {}
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        let dataItemsToFix

        //Fix (convert) schemas to 'linkable' schemas
        dataItemsToFix = filterSchemasToFix(dataItems)
        _.forEach(dataItemsToFix, dataItem => {
            const newDataItem = createItemFromNewSchema(dataItem, uniqueIdGenerator)
            if (newDataItem) {
                dataItems[dataItem.id] = newDataItem //Override the existing data item with the new one (same ID)
                //                        markDataItemAsDirty(dataItem.id);
            }
        })

        //Fix (convert) links data items (create new link data items with same id and replace the original)
        dataItemsToFix = filterLinkItemsToFix(dataItems)
        _.forEach(dataItemsToFix, dataItem => {
            const newLinkDataItem = createNewLinkItem(dataItem, false, uniqueIdGenerator)
            if (newLinkDataItem) {
                dataItems[dataItem.id] = newLinkDataItem

                //Don't mark the converted links as dirty because they are not referenced and will be deleted
                //by the server GC in the next save.
            }
        })

        //NOTE: when we start to work with 'dirty' data items, we should probably mark the newLinkDataItems as dirty later, so that they will be saved in the document data
        _.assign(dataItems, newLinkDataItems)
    }
}
