import * as _ from 'lodash'
import {setComponentsOrder} from './sweepSortAnalyzer'
import * as layoutRules from './layoutRules'
import * as conversionUtils from '../conversionUtils'
import * as virtualGroupHandler from '../virtualGroupHandler'
import * as proportionalGroupAnalyzer from './proportionalGroupAnalyzer'
import * as semanticGroupsAnalyzer from './semanticGroupsAnalyzer'
import * as verticalDividersConversion from '../verticalDividersConversion'
import {getOrderedComponents, shouldUseNaturalOrder, hasMobileHintsPresets} from './analyzerUtils'
import {ConversionSettings, DeepStructure} from '../../types'

function defineSemanticRules(parent: DeepStructure): void {
    if (hasMobileHintsPresets(parent)) {
        return
    }
    semanticGroupsAnalyzer.analyze(parent)
    const orderedComponents = getOrderedComponents(parent)
    _.forEach(orderedComponents, defineSemanticRules)
}

function sortChildren(parent: DeepStructure, settings: ConversionSettings): void {
    const comps = conversionUtils.getChildren(parent) as DeepStructure[]

    if (!comps) {
        return
    }

    const naturalComponentsOrder = _.map(comps, 'id') as string[]
    const recommendedComponentsOrder = parent.conversionData.mobileHints?.recommendedComponentsOrder

    if (recommendedComponentsOrder) {
        const intersectedOrder = _.intersection(recommendedComponentsOrder, naturalComponentsOrder) as string[]
        _.set(parent, ['conversionData', 'componentsOrder'], intersectedOrder)
    } else {
        if (shouldUseNaturalOrder(parent)) {
            _.set(parent, ['conversionData', 'componentsOrder'], naturalComponentsOrder)
        } else {
            setComponentsOrder(parent, comps, settings)
        }
        layoutRules.analyze(parent, getOrderedComponents(parent), settings)
    }
    _.forEach(getOrderedComponents(parent), comp => sortChildren(comp, settings))
}

export function analyzeStructure(parent: DeepStructure, settings: ConversionSettings): void {
    _.defaults(parent, {conversionData: {}})

    const children = <DeepStructure[]>conversionUtils.getChildren(parent)
    if (!children) {
        return
    }
    if (settings.detectSemanticGroups) {
        virtualGroupHandler.reset()
        proportionalGroupAnalyzer.analyzeProportionalGroups(parent, children)
    }
    sortChildren(parent, settings)
    if (settings.applySemanticRules) {
        defineSemanticRules(parent as DeepStructure)
    }
    layoutRules.analyzeGroupedComponents(children)
    const hideNonDividers = !settings.keepNotRecommendedMobileComponents

    verticalDividersConversion.preprocess(parent, hideNonDividers)
}
