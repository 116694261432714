import type {BootstrapConfig} from '../types'
import _ from 'lodash'
import {default as configs, ConfigName} from './configs'
import {ReportableError} from '@wix/document-manager-utils'
import type {DSConfig} from '@wix/document-services-types'

const NO_ORIGIN = 'none'

const defaultOptions: DSConfig = {
    origin: NO_ORIGIN,
    modulesList: [],
    extensionsList: [],
    shouldRender: true,
    isReadOnly: false,
    noUndo: false,
    disableSave: false,
    disableAutoSave: false,
    disableCSaveValidationOnInitialization: false,
    supportsPlatformInitialization: true,
    closeSectionGaps: false,
    runStylesGC: false,
    disableMobileConversion: false,
    selectiveCompsDS: false,
    supportMobile: true,
    initialSeed: false,
    localServerPort: undefined,
    disableCommonConfig: false,
    schemaDevMode: false,
    autosaveRestore: 'true',
    dontCollectFixerVersionData: false,
    keepMultiLingualModelsFromServer: false,
    lazyLoadPages: false
}

const optionalParams = [
    'signatureSeed',
    'isDebugMode',
    'undoRedoConfig',
    'untilTransactionId',
    'isReadOnly',
    'enableScopes',
    'enableRepeatersInScopes',
    'doNotSyncRepeatedLayout'
]

const getDsConfig = (bootstrapConfig: BootstrapConfig, overrides: Partial<DSConfig> = {}): DSConfig => {
    if (!bootstrapConfig.configName || !configs[bootstrapConfig.configName]) {
        throw new ReportableError({
            message: `The value for configName (${
                bootstrapConfig.configName ?? ''
            }) does not map to a valid configuration`,
            errorType: 'incorrectConfigurationParamError'
        })
    }

    const configName = bootstrapConfig.configName as ConfigName

    const configOptions = _(bootstrapConfig)
        .pick([...optionalParams, ..._.keys(defaultOptions)])
        .assign({
            origin: bootstrapConfig.dsOrigin
        })
        .defaults(configs[configName!], defaultOptions)
        .assign(overrides)
        .value()

    if (!configOptions.origin || configOptions.origin === NO_ORIGIN) {
        throw new Error(
            'You must define dsOrigin parameter in order to use documentServices - please speak to html-server team for a key'
        )
    }

    if (bootstrapConfig.firstSaveExtraPayload) {
        configOptions.firstSaveExtraPayload = JSON.parse(bootstrapConfig.firstSaveExtraPayload)
    }

    if (typeof localStorage !== 'undefined' && localStorage.getItem('dm-debug')) {
        configOptions.isDebugMode = true
    }

    return {
        configName,
        ...configOptions
    }
}

export {getDsConfig}
