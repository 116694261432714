import {Notifier, ReportableError} from '@wix/document-manager-utils'
import type {Pointer} from '@wix/document-services-types'
import {
    createImmutableProxy,
    createImmutableProxyForMap,
    createImmutableProxyForMapOfMaps,
    deepClone
} from '@wix/wix-immutable-proxy'
import type {EventEmitter} from 'events'
import _ from 'lodash'
import type {CoreConfig} from '../documentManagerCore'
import type {
    DocumentDataTypes,
    FilterGetter,
    IdGenerator,
    IndexKey,
    KeyValPredicate,
    Null,
    PostSetOperation,
    PostTransactionOperation,
    PostTransactionSideEffect,
    Transaction,
    ValidationWhitelistCheck
} from '../types'
import {debug} from '../utils/debug'
import {deepCompare} from '../utils/deepCompare'
import {deepCompareIgnoreSignature} from '../utils/deepCompareIgnoreSignature'
import {map_findBy, map_pickBy} from '../utils/pickBy'
import {
    getInnerPath,
    getInnerPointer,
    getInnerValue,
    getPointer,
    hasInnerPath,
    stripInnerPath
} from '../utils/pointerUtils'
import {createUniqueIdGenerator} from '../utils/uniqueIdGenerator'
import {createQueryIndex, IndexedValues, QueryIndex, QueryNamespace, ValueToIndexIds} from './queryIndex/queryIndex'
import {createDalSchema, DalSchema} from './schema/dalSchema'
import {isConflicting as isConflictingValue} from './signatures/isConflicting'
import {createSnapshotChain, SnapshotDal} from './snapshot/snapshotDal'
import {createList, SnapshotList} from './snapshot/snapshotList'
import {createTagManager, TagManager} from './snapshot/tagManager'
import {createStore, createStoreFromJS, DalItem, DalJsStore, DalStore, DalValue, DmStore} from './store'
import {createValidator, ValidateValue} from './validation/dalValidation'

export type SetterSet = (pointer: Pointer, value: any) => void
export type Getter = (pointer: Pointer) => DalValue
export type CustomGetter = (dal: PDAL, rootPointer: Pointer) => DalValue
export type QueryFilterGetters = Record<string, FilterGetter>
export type GetIndexed = (indexKey: IndexKey) => IndexedValues
export type DalValueChangeCallback = (pointer: Pointer, oldValue: DalValue, newValue: DalValue) => void

type PDAL = Pick<DAL, 'queryFilterGetters' | 'query' | 'get'>

export const TransactionEvents = {
    TRANSACTION_REJECTED: 'TRANSACTION_REJECTED',
    TRANSACTION_BY_OTHER: 'TRANSACTION_BY_OTHER',
    LOCAL_TRANSACTION_APPROVED: 'LOCAL_TRANSACTION_APPROVED'
} as const

export type TransactionEvent = keyof typeof TransactionEvents

export interface CreateArgs {
    coreConfig: CoreConfig
    postSetOperations: PostSetOperation[]
    dmTypes: DocumentDataTypes
    customGetters: Record<string, CustomGetter>
    eventEmitter: EventEmitter
    initialStore?: DalStore
}

interface OpenTransaction {
    id: string
    store: DmStore
    attempt: number
}

// These error constructors explicitly set the prototype since it's the recommended workaround for a TypeScript issue:
// See https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
export class CommitsDisabledError extends ReportableError {
    constructor() {
        super({
            errorType: 'CommitsDisabledError',
            message:
                'commitTransaction was called when commits were disabled. Perhaps disableCommits was called without a subsequent call to enableCommits?'
        })
        Object.setPrototypeOf(this, CommitsDisabledError.prototype)
    }
}

export class TransactionRejectionError extends Error {
    constructor(id: string) {
        super(`Transaction was rejected by server, transaction id ${id}`)
        Object.setPrototypeOf(this, TransactionRejectionError.prototype)
    }
}

export class CannotApproveError extends ReportableError {
    constructor(tx: SnapshotDal | null, id: string, args: any, txCompareIds: SnapshotDal | null) {
        super({
            errorType: 'cannotApproveError',
            message: `Cannot approve, ${id} is not a pending transaction`,
            extras: {
                id,
                ...args,
                unlinked: !tx,
                foundByCompareIds: !!txCompareIds,
                content: _.keys(tx?.getStore().asJson())
            }
        })
        Object.setPrototypeOf(this, CannotApproveError.prototype)
    }
}

/**
 * document manager DAL
 */
export interface DAL {
    readonly queryFilterGetters: Record<string, FilterGetter>
    readonly tagManager: TagManager
    readonly registrar: {
        registerFilter(name: string, filter: ValueToIndexIds): void
        registerValidator(name: string, validate: ValidateValue): void
        registerRebaseValidator(name: string, validate: ValidateValue): void
        registerValidationWhitelistCheck(whitelistCheck: ValidationWhitelistCheck): void
        registerPostTransactionOperation(name: string, operation: PostTransactionOperation): void
    }

    // for debug / test
    readonly _store: DmStore
    readonly _queryIndex: QueryIndex
    readonly _tentativeStore: DmStore
    readonly _snapshots: SnapshotList
    readonly _currentTransaction: OpenTransaction
    _getApprovedStoreAsJson(): DalJsStore
    _getTentativeStoreAsJson(): DalJsStore
    _getCommittedStoreAsJson(): DalJsStore
    _getMergedStoreAsJson(): DalJsStore
    _getAllTags(): Record<string, string[]>

    /** mark items as approved and move them from tentative store to the store */
    approve(pid: string): void
    reject(pid: string): void

    commitTransaction(committer?: string, skipValidations?: boolean): SnapshotDal
    find(namespace: string, indexKey: IndexKey, predicate: KeyValPredicate): any
    getIndexed(indexKey: IndexKey): IndexedValues
    get(pointer: Pointer): DalValue
    getRegisteredTypes(): DocumentDataTypes
    getWithPath(pointer: Pointer, innerPath: string | string[], defaultValue?: any): any
    has(pointer: Pointer): boolean
    isDirty(pointer: Pointer): boolean
    query(namespace: string, indexKey: IndexKey, optionalPredicate?: KeyValPredicate): Record<string, DalValue>
    queryKeys(namespace: string, indexKey: IndexKey, optionalPredicate?: KeyValPredicate): any
    getIndexPointers(indexKey: IndexKey, namespace?: string): Pointer[]
    rebase(change: DmStore, position: string, label?: string): SnapshotDal
    rebaseForeignChange(change: DmStore, position: string, correlationId?: string): SnapshotDal
    remove(pointer: Pointer): void
    set(pointer: Pointer, value: DalValue): void
    setIfChanged(pointer: Pointer, value: DalValue): void

    /** Modify a dal value using the given function
     *
     * This is syntactic sugar, instead of:
     * ```
     * const v1 = dal.get(pointer)
     * const v2 = doSomething(v1)
     * dal.set(pointer, v2)
     * ```
     * You can use: `dal.modify(pointer, doSomething)`
     */
    modify<A, B = A>(pointer: Pointer, f: (a: A) => B): void
    touch(pointer: Pointer): void
    getLastSnapshot(): SnapshotDal | null
    initLazyInitiators(): void
    takeSnapshot(tag: string): number
    takeLastApprovedSnapshot(tag: string): void
    validate(tags?: Record<string, any>): void
    validatePendingCommit(tags?: Record<string, any>): void
    createValidationBaseline(): void
    sign(rootPointer: Pointer, value: DalValue, signOver?: Null<SnapshotDal>): DalValue
    getTentativeAndAcceptedAsTransaction(): Transaction
    getCurrentOpenTransaction(): Transaction
    mergeToApprovedStore(changes: DmStore, id?: string): SnapshotDal
    mergeToApprovedStoreAsync(changes: DmStore, id?: string): Promise<SnapshotDal>
    getLastApprovedSnapshot(): SnapshotDal
    dropUncommittedTransaction(reason?: string): void
    enableCommits(): void
    disableCommits(): void
    readonly schema: DalSchema
    hasSignature(pointer: Pointer): boolean
    registerForChangesCallback(callback: DalValueChangeCallback): void
    unregisterForChangesCallback(callback: DalValueChangeCallback): void
}

const timeout = 1000 * 60
const EMPTY_MAP = new Map()

const pointersInNamespace = (namespace: QueryNamespace | undefined, pointerType: string): Pointer[] =>
    namespace ? [...namespace.keys()].map(id => getPointer(id, pointerType)) : []

export const createDal = ({
    coreConfig,
    postSetOperations,
    dmTypes,
    customGetters,
    eventEmitter,
    initialStore
}: CreateArgs): DAL => {
    const {experimentInstance, schemaService} = coreConfig
    const debugLogger = debug('dal')
    const tagManager: TagManager = createTagManager()
    const store: DmStore = createStore()
    const createOpenTxId: IdGenerator = createUniqueIdGenerator(3)
    let tentativeStore: DmStore = createStore()
    let currentOpenTransaction: OpenTransaction
    /** last approved snapshot */
    let lastApproved: Null<SnapshotDal> = null
    const snapshots = createList()
    const schema = createDalSchema({
        schemaService,
        experiments: {}
    })
    const postTransactionOperations: Record<string, PostTransactionOperation> = {}
    const validator = createValidator(coreConfig)
    const rebaseValidator = createValidator(coreConfig)

    const registerValidationWhitelistCheck = (whitelistCheck: ValidationWhitelistCheck) => {
        validator.registerWhitelistCheck(whitelistCheck)
    }

    const queryFilterGetters: Record<string, FilterGetter> = {}
    const queryIndex = createQueryIndex(experimentInstance)
    const createSignature: IdGenerator = createUniqueIdGenerator(3, coreConfig.signatureSeed)
    let commitsEnabled = true
    const approvalAndRejectionNotifier = new Notifier<string, void>(undefined, timeout)
    const updateCallbacks: DalValueChangeCallback[] = []

    const isRegisteredNamespace = (type: string): boolean => !!dmTypes[type]

    const getRegisteredTypes = (): DocumentDataTypes => dmTypes

    const verifyQueryParams = (namespace: string): void => {
        if (!isRegisteredNamespace(namespace)) {
            console.log(`Namespace ${namespace} is not registered`)
            throw new Error(`Namespace ${namespace} is not registered`)
        }
    }

    const queryRaw = (
        namespace: string,
        indexKey: IndexKey,
        optionalPredicate?: KeyValPredicate
    ): QueryNamespace | undefined => {
        const namespaces = queryIndex.getIndexedValues(indexKey)
        let queryNamespace = namespaces.get(namespace)

        if (optionalPredicate && queryNamespace) {
            queryNamespace = map_pickBy(queryNamespace, optionalPredicate)
        }

        return queryNamespace
    }

    const query = (namespace: string, indexKey: IndexKey, optionalPredicate?: KeyValPredicate): Record<string, any> => {
        const result = queryRaw(namespace, indexKey, optionalPredicate)

        return createImmutableProxyForMap(result ?? EMPTY_MAP)
    }

    const queryKeys = (namespace: string, indexKey: IndexKey, optionalPredicate?: KeyValPredicate): string[] => {
        const result = queryRaw(namespace, indexKey, optionalPredicate)
        return result ? [...result.keys()] : []
    }

    /**
     * Returns all the pointers in the index referred to by the specified index key and optionally only in the given namespace
     * @param indexKey
     * @param namespace
     * @returns An array of the pointers in the specified index
     */
    const getIndexPointers = (indexKey: IndexKey, namespace?: string): Pointer[] => {
        const namespaces = queryIndex.getIndexedValues(indexKey)

        if (namespace) {
            const namespaceValues = namespaces.get(namespace)

            return pointersInNamespace(namespaceValues, namespace)
        }

        return [...namespaces.entries()].flatMap(([ns, namespaceValues]) => pointersInNamespace(namespaceValues, ns))
    }

    const find = (namespace: string, indexKey: IndexKey, predicate: KeyValPredicate): DalItem | undefined => {
        verifyQueryParams(namespace)

        const namespaces = queryIndex.getIndexedValues(indexKey)
        const queryResult = namespaces.get(namespace)
        const findResult = map_findBy(queryResult, predicate)

        return createImmutableProxy(findResult)
    }

    const getIndexed = (indexKey: IndexKey): IndexedValues => {
        const namespaces = queryIndex.getIndexedValues(indexKey)

        return createImmutableProxyForMapOfMaps(namespaces ?? EMPTY_MAP) as IndexedValues
    }

    const getFromStores = (stores: DmStore[], rootPointer: Pointer): DalValue => {
        const foundStore = stores.find((s: DmStore) => s.has(rootPointer))
        return foundStore ? foundStore.get(rootPointer) : undefined
    }

    const getMergedStoreValue = (pointer: Pointer): any =>
        getFromStores([currentOpenTransaction.store, tentativeStore, store], pointer)

    const customGetterArgument = {
        queryFilterGetters,
        query,
        get: (ptr: Pointer) => createImmutableProxy(getMergedStoreValue(ptr))
    }

    const getCommittedValue = (pointer: Pointer): DalValue => getFromStores([tentativeStore, store], pointer)
    const getApprovedValue = (pointer: Pointer): DalValue => getFromStores([store], pointer)

    const getRootValueFromCustomGetterOrStore = (rootPointer: Pointer): DalValue => {
        //either using an extension defined getter or the default store
        const customGetter = customGetters[rootPointer.type]
        if (customGetter) {
            return customGetter(customGetterArgument, rootPointer)
        }
        return getCommittedValue(rootPointer)
    }

    const getRawValue = (pointer: Pointer): DalValue => {
        const transactionStore = currentOpenTransaction.store
        const rootPointer = stripInnerPath(pointer)
        let rootValue
        if (transactionStore.has(rootPointer)) {
            rootValue = transactionStore.get(rootPointer)
        } else {
            rootValue = getRootValueFromCustomGetterOrStore(rootPointer)
        }
        return getInnerValue(pointer, rootValue)
    }

    const get = (pointer: Pointer): DalValue => createImmutableProxy(getRawValue(pointer))

    const getWithPath = (pointer: Pointer, innerPath: string[] | string): DalValue =>
        get(getInnerPointer(pointer, innerPath))

    const has = (pointer: Pointer): boolean => !_.isNil(getRawValue(pointer))

    const createNewOpenTransaction = (): void => {
        currentOpenTransaction = {
            attempt: 0,
            id: createOpenTxId(),
            store: createStore()
        }
    }

    const callPostTransactionOperationsAndAccumulateAsyncSideEffects = (
        transaction: Transaction
    ): null | PostTransactionSideEffect => {
        const asyncSideEffects = _(postTransactionOperations)
            .map(cb => cb(transaction))
            .compact()
            .value() as PostTransactionSideEffect[]

        const hasSideEffects = !_.isEmpty(asyncSideEffects)
        if (hasSideEffects) {
            return async () => {
                await Promise.all(asyncSideEffects.map(p => p()))
            }
        }
        return null
    }

    const report = (reportStore: DmStore, event: TransactionEvent): void => {
        if (reportStore.isEmpty()) {
            return
        }
        const transaction = {
            id: 'post-transaction-report',
            items: reportStore.getValues()
        }
        const postTransactionSideEffectsFunction =
            callPostTransactionOperationsAndAccumulateAsyncSideEffects(transaction)
        eventEmitter.emit(event, postTransactionSideEffectsFunction)
    }

    const hasSignature = (pointer: Pointer): boolean => {
        const idsWithSignature = dmTypes[pointer.type]?.idsWithSignature
        if (idsWithSignature) {
            return idsWithSignature.has(pointer.id)
        }
        return dmTypes[pointer.type]?.hasSignature ?? false
    }

    const sign = (rootPointer: Pointer, value: DalValue | undefined, signOver?: Null<SnapshotDal>): DalValue => {
        if (value && hasSignature(rootPointer)) {
            const previousValue = signOver ? signOver.getValue(rootPointer) : getCommittedValue(rootPointer)
            value.metaData = Object.assign(value.metaData || {}, {
                sig: createSignature(),
                basedOnSignature: previousValue ? previousValue.metaData?.sig ?? null : undefined
            })
        }
        return value
    }

    /**
     * @param {Pointer} pointer
     * @returns {boolean}
     */
    const isDirty = (pointer: Pointer): boolean => currentOpenTransaction.store.has(pointer)

    /**
     * Returns true if any of the values in the store have a signature
     * that conflicts with that of the getter value for the same pointer
     * @param transactionStore
     * @param getter
     */
    const isConflicting = (transactionStore: DmStore, getter: Getter): boolean => {
        if (!coreConfig.checkConflicts) {
            return false
        }
        return transactionStore.some(
            (pointer, value) => hasSignature(pointer) && isConflictingValue(value, getter(pointer))
        )
    }

    const isConflictingWithMergedValue = (transactionStore: DmStore): boolean =>
        isConflicting(transactionStore, getMergedStoreValue)
    const isConflictingWithCommittedValue = (transactionStore: DmStore): boolean =>
        isConflicting(transactionStore, getCommittedValue)
    const isConflictingWithApprovedValue = (transactionStore: DmStore): boolean =>
        isConflicting(transactionStore, getApprovedValue)

    /** Update the query index of the `pointer` to its value in the merged store
     *
     * The index must always reflect the overall current state of the system.
     * Externally that would be the result from `get`, internally that would be the merged store.
     *
     * In other words, it's illegal to update the index to a value other than the one in the merged store.
     * To enforce this invariant, this function accepts only a pointer, not a value. It then fetches the value
     * from the merged store.
     */
    const updateSingleIndexValue = (pointer: Pointer): void => {
        const newValue = getMergedStoreValue(pointer)
        const previousValue = queryIndex.updateIndex(pointer, newValue)
        updateCallbacks.forEach((callback: DalValueChangeCallback) => {
            callback(pointer, previousValue, newValue)
        })
    }

    const updateIndex = (pointers: Pointer[]): void => {
        pointers.forEach(updateSingleIndexValue)
    }

    const runPostSetOperations = (pointer: Pointer, value: DalValue) => {
        try {
            _.forEach(postSetOperations, postSet => postSet(pointer, value))
        } catch (error) {
            coreConfig.logger.captureError(error as Error, {
                tags: {postSetOp: true},
                extras: {
                    pointer,
                    value
                }
            })
            throw error
        }
    }

    const signAndUpdateTransactionAndIndex = (rootPointer: Pointer, value: DalValue) => {
        sign(rootPointer, value)
        currentOpenTransaction.store.set(rootPointer, value)
        updateSingleIndexValue(rootPointer)
        runPostSetOperations(rootPointer, value)
    }

    const set = (pointer: Pointer, value: DalValue): void => {
        const innerPath = getInnerPath(pointer)
        const rootPointer = stripInnerPath(pointer)
        let newValue = deepClone(value)
        if (innerPath.length > 0) {
            const rootValue = deepClone(getRawValue(rootPointer)) || {}
            newValue = _.setWith(rootValue, innerPath, newValue, Object)
        }
        signAndUpdateTransactionAndIndex(rootPointer, newValue)
    }

    const getterForSetAndTouch = experimentInstance.isOpen('dm_useCustomGettersForSetAndTouch')
        ? getRawValue
        : getMergedStoreValue

    const isChanged = (pointer: Pointer, value: DalValue): boolean => {
        const currentValue = getterForSetAndTouch(pointer)
        if (hasInnerPath(pointer)) {
            const currentInnerValue = getInnerValue(pointer, currentValue)
            return !deepCompare(currentInnerValue, value)
        }

        return !deepCompareIgnoreSignature(currentValue, value)
    }

    const setIfChanged = (pointer: Pointer, value: DalValue): void => {
        if (isChanged(pointer, value)) {
            set(pointer, value)
        }
    }

    const touch = (pointer: Pointer) => set(pointer, getterForSetAndTouch(pointer))

    /**
     * @param {Pointer} pointer
     */
    const remove = (pointer: Pointer): void => {
        const innerPath = getInnerPath(pointer)
        const rootPointer = stripInnerPath(pointer)
        let newValue
        if (innerPath.length > 0) {
            newValue = deepClone(getRawValue(rootPointer))
            _.unset(newValue, innerPath)
        }
        signAndUpdateTransactionAndIndex(rootPointer, newValue)
    }

    const validatePendingCommit = (tags?: Record<string, any>) =>
        validator.validateStore(currentOpenTransaction.store, true, tags)

    /**
     * Add the current open transaction to the snapshot chain and merge it to the tentative store.
     * Notify listeners of the commit
     * @param committer
     * @param skipValidations
     */
    const commitTransaction = (committer?: string, skipValidations?: boolean): SnapshotDal => {
        if (!commitsEnabled) {
            coreConfig.logger.captureError(new CommitsDisabledError())
            return snapshots.last()!
        }
        const currentOpenTransactionStore = currentOpenTransaction.store
        if (currentOpenTransactionStore.isEmpty()) {
            return snapshots.last()!
        }

        const attempt = ++currentOpenTransaction.attempt
        if (!skipValidations) {
            validatePendingCommit({committer, attempt, openTxId: currentOpenTransaction.id})
        }

        const pointersModifiedInTheTransaction = currentOpenTransactionStore.keys()
        createNewOpenTransaction()

        if (isConflictingWithCommittedValue(currentOpenTransactionStore)) {
            updateIndex(pointersModifiedInTheTransaction)
            return snapshots.last()!
        }

        tentativeStore.merge(currentOpenTransactionStore)
        const snapshotId = snapshots.add(currentOpenTransactionStore)

        report(currentOpenTransactionStore, TransactionEvents.LOCAL_TRANSACTION_APPROVED)
        return snapshotId
    }

    const isSameValue = (a: DalValue, b: DalValue) => {
        const sigA = a?.metaData?.sig
        const sigB = b?.metaData?.sig
        return sigA || sigB ? sigA === sigB : deepCompare(a, b)
    }

    const collectChanges = (oldTentativeStore: DmStore, originalChange?: DmStore): DmStore => {
        const changes = createStore()

        oldTentativeStore.forEach((pointer, otherValue) => {
            const dalValue = getCommittedValue(pointer)
            if (!isSameValue(dalValue, otherValue)) {
                changes.set(pointer, dalValue)
            }
        })

        if (originalChange) {
            originalChange.forEach((pointer, value) => {
                if (!oldTentativeStore.has(pointer)) {
                    changes.set(pointer, value)
                }
            })
        }

        return changes
    }

    const getMergedStore = (includeCurrentTransaction: boolean): DmStore => {
        const mergedStore = createStore()
        mergedStore.merge(store)
        mergedStore.merge(tentativeStore)
        if (includeCurrentTransaction) {
            mergedStore.merge(currentOpenTransaction.store)
        }

        return mergedStore
    }

    /** Run validators on the pointers that exist in `storeToValidate`
     *
     * The validators will be called only on these pointers but remember that they already
     * have access to the entire DAL (since they're part of the extensions).
     * That means that to ensure correctness this function needs to run on a valid DAL with an
     * updated index.
     * In addition, extensions that hold internal state usually update said state in postTransactionOperations.
     * If validators rely on that state and postTransactionOperations weren't called, this function may behave poorly.
     */
    const hasRebaseValidationErrors = (storeToValidate: DmStore): boolean => {
        if (coreConfig.checkConflicts) {
            try {
                rebaseValidator.validateStore(storeToValidate, true)
                return false
            } catch (e) {
                return true
            }
        }

        return false
    }

    const rebuildOpenStore = (snaps: SnapshotDal[]): void => {
        const keys = currentOpenTransaction.store.keys()
        createNewOpenTransaction()
        snaps.forEach(snap => {
            const snapStore = snap.getStore()
            currentOpenTransaction.store.merge(snapStore)
            keys.push(...snapStore.keys())
        })
        updateIndex(keys)
    }

    const removeTentativeSnapshotWithoutUpdatingTheIndex = (snapshot: SnapshotDal): void => {
        const {id} = snapshot
        approvalAndRejectionNotifier.reject(id, new TransactionRejectionError(id))
        snapshots.remove(snapshot)
        tagManager.remove(snapshot)
    }

    const resetToApprovedState = (): void => {
        const pointersToUpdate = _.concat(tentativeStore.keys(), currentOpenTransaction.store.keys())
        tentativeStore = createStore()
        createNewOpenTransaction()
        updateIndex(pointersToUpdate)
    }

    /** Rebuild the tentative store with conflicting snapshots removed.
     *
     * Conflicting snapshots are snapshots with a broken signature chain to the committed value or snapshots that
     * lead to an invalid store when applied to the approved state.
     */
    const removeConflicts = () => {
        /* To rebase we reset the dal to the approved state since it's the last "consensus" point from the server,
         * it cannot change.
         * We then re-apply the tentative transactions, which weren't approved by the server yet, one-by-one.
         * If a tentative transaction conflicts with previous values we remove it. There is no need to send it to the
         * server since we already know it's conflicting and will be rejected.
         * We refer to that situation as a "local rejection"
         */
        const openTransaction = currentOpenTransaction
        const droppedSnapshots: SnapshotDal[] = []
        const pendingSnapshots = createSnapshotChain(lastApproved, snapshots.last(), 'removeConflicts')
        resetToApprovedState()

        for (const [i, snapshot] of pendingSnapshots.entries()) {
            /* There's no need to apply the snapshot in order to check for signature conflicts.
             * But validators work on the DAL as a whole, so we have to apply the snapshot, validate and then
             * "unapply" it if a validation conflict occurred.
             * To "unapply" we rebuild the tentative store but we only use the snapshots that weren't
             * already locally rejected.
             */
            const snapshotStore = snapshot.getStore()
            if (isConflictingWithMergedValue(snapshotStore)) {
                droppedSnapshots.push(snapshot)
                removeTentativeSnapshotWithoutUpdatingTheIndex(snapshot)
            } else {
                currentOpenTransaction.store.merge(snapshotStore)
                updateIndex(snapshotStore.keys())
                if (hasRebaseValidationErrors(snapshotStore)) {
                    debugLogger.info(
                        `dmDal rebase is removing a snapshot due to validation conflict. Snapshot id: ${snapshot.id}`
                    )
                    droppedSnapshots.push(snapshot)
                    removeTentativeSnapshotWithoutUpdatingTheIndex(snapshot)
                    const validPendingSnapshotsUpToThisPoint = _(pendingSnapshots)
                        .take(i)
                        .without(...droppedSnapshots)
                        .value()
                    rebuildOpenStore(validPendingSnapshotsUpToThisPoint)
                }
            }
        }
        tentativeStore.merge(currentOpenTransaction.store)

        currentOpenTransaction = openTransaction
        updateIndex(openTransaction.store.keys())
    }

    /**
     * Build a new tentative store with all conflicting snapshots removed
     * Aggregate all the changes between the old tentative store and new one, update
     * the query index with these changes and notify listeners of the changes
     * @param {TransactionEvent} event - event type to report
     * @param {DmStore} originalChange - change of data that is not included in the tentative store
     */
    const removeConflictsAndReport = (event: TransactionEvent, originalChange?: DmStore) => {
        const inconsistentTentativeStore = tentativeStore

        // remove invalidated snapshots
        removeConflicts()
        const changes: DmStore = collectChanges(inconsistentTentativeStore, originalChange)

        // notify listeners
        report(changes, event)
    }

    /**
     * apply function on all the snapshots from but not including fromSnapshot, up to and including toSnapshot
     * @param {SnapshotDal} from
     * @param {SnapshotDal} to
     * @param {Function} fn
     */
    const applyOnSnapshotsRange = (from: SnapshotDal, to: SnapshotDal, fn: _.ListIterator<SnapshotDal, any>) => {
        const snapshotsToApply = createSnapshotChain(from, to, 'applyOnSnapshotsRange')
        _(snapshotsToApply).reverse().forEach(fn)
    }

    const createSnapshotTrace = () => {
        const snapshotChain = _(createSnapshotChain(null, snapshots.last(), 'createSnapshotTrace'))
            .slice(-30)
            .map('id')
            .value()
        return {
            lastApproved: lastApproved?.id,
            snapshotChain,
            currentTags: tagManager._getAllTags()
        }
    }

    const createCannotApproveError = (id: string) => {
        const tx = snapshots.findById(id)
        const txCompareIds = snapshots.findByIdLimitCompareIds(id, lastApproved!)
        return new CannotApproveError(tx, id, createSnapshotTrace(), txCompareIds)
    }

    /**
     * Merge all the changes since the last approved snapshot up to the new approved
     * snapshot into the main store and update the approved snapshot pointer.
     * Rebuild the tentative store with the remaining unapproved changes.
     * @param id id of the approved snapshot
     */
    const approve = (id: string) => {
        const tx = snapshots.findByIdLimit(id, lastApproved!)
        if (!tx) {
            throw createCannotApproveError(id)
        }

        const diff: DmStore = createStoreFromJS(tx.mutableDiff(lastApproved))
        store.merge(diff)

        const newTentativeStore = createStoreFromJS(snapshots.last()?.mutableDiff(tx))
        tentativeStore = newTentativeStore
        applyOnSnapshotsRange(lastApproved!, tx, (snapshot: SnapshotDal) =>
            approvalAndRejectionNotifier.resolve(snapshot.id)
        )
        lastApproved = tx
    }

    /**
     * Delete all the tentative snapshots from the snapshot with the given id to the last
     * approved snapshot and rebuild the tentative store with the remaining tentative snapshots
     * @param id - id of the rejected snapshot
     */
    const reject = (id: string) => {
        const tx = snapshots.findByIdLimit(id, lastApproved!)
        if (!tx) {
            // Possibly sent and later identified as conflict, not necessarily a bug
            return
        }

        applyOnSnapshotsRange(lastApproved!, tx, (snapshot: SnapshotDal) => {
            approvalAndRejectionNotifier.reject(snapshot.id, new TransactionRejectionError(id))
            snapshots.remove(snapshot)
            tagManager.remove(snapshot)
        })

        removeConflictsAndReport(TransactionEvents.TRANSACTION_REJECTED)
    }

    const createCannotRebaseError = (position: string, id: string | undefined) =>
        new ReportableError({
            errorType: 'cannotRebaseError',
            message: `Cannot rebase, ${position} does not come before pending transaction`,
            extras: {
                position,
                id
            }
        })

    const createRebaseUnlinkedError = (position: string, id: string | undefined) =>
        new ReportableError({
            errorType: 'rebaseUnlinkedError',
            message: `Cannot rebase, ${position} is unlinked`,
            extras: {
                position,
                id,
                ...createSnapshotTrace()
            }
        })

    const createRemovedApprovedError = (position: string, id: string | undefined) =>
        new ReportableError({
            errorType: 'removedApprovedSnapshotError',
            message: 'We just removed the approved snapshot due to a conflict',
            extras: {
                position,
                id,
                ...createSnapshotTrace()
            }
        })

    const removeConflictsReportAndVerify = (change: DmStore, position: string, event: TransactionEvent, id: string) => {
        removeConflictsAndReport(event, change)
        if (!snapshots.findById(id)) {
            throw createRemovedApprovedError(position, id)
        }
    }

    const rebaseApprovedHistory = (change: DmStore, position: string, event: TransactionEvent, id?: string) => {
        const tx = snapshots.findById(position)
        if (!tx) {
            throw createRebaseUnlinkedError(position, id)
        }

        if (isConflictingWithApprovedValue(change)) {
            throw createRemovedApprovedError(position, id)
        }

        coreConfig.logger.captureError(createCannotRebaseError(position, id))

        const approvedLater: DmStore = _.reduce(
            createSnapshotChain(tx, lastApproved, 'rebaseApprovedHistory'),
            (mergedStore: DmStore, snapshotInRange: SnapshotDal) => {
                mergedStore.merge(snapshotInRange.getStore())
                return mergedStore
            },
            createStore()
        )

        const relevantChange: DmStore = createStore()
        change.forEach((pointer, value) => {
            if (!approvedLater.has(pointer)) {
                relevantChange.set(pointer, value)
            }
        })

        store.merge(relevantChange)
        const newSnapshot = snapshots.insert(change, position, id)
        updateIndex(relevantChange.keys())
        removeConflictsReportAndVerify(relevantChange, position, event, newSnapshot.id)
        return newSnapshot
    }

    /**
     * Insert the given snapshot into the snapshot chain at the specified position
     *
     * @param change
     * @param position
     * @param event
     * @param id
     */
    const rebase = (change: DmStore, position: string, event: TransactionEvent, id: string) => {
        const tx = snapshots.findByIdLimitInclusive(position, lastApproved!)
        if (!tx) {
            return rebaseApprovedHistory(change, position, event, id)
        }

        const newSnapshot = snapshots.insert(change, position, id)
        removeConflictsReportAndVerify(change, position, event, newSnapshot.id)
        return newSnapshot
    }

    /**
     * Rebase the given snapshot and mark the new snapshot as foreign
     * @param change
     * @param position
     * @param correlationId
     */
    const rebaseForeignChange = (change: DmStore, position: string, correlationId?: string) => {
        const id = correlationId ?? snapshots.generateId('unknown-foreign')
        const newSnapshot = rebase(change, position, TransactionEvents.TRANSACTION_BY_OTHER, id)
        newSnapshot.setAsForeign()
        return newSnapshot
    }

    const getLastApprovedSnapshot = () => {
        if (!lastApproved) {
            throw new Error('Empty DAL does not have a last approved snapshot')
        }
        return lastApproved
    }

    const takeSnapshot = (tag: string): number => {
        const snapshot = commitTransaction('takeSnapshot')
        return tagManager.addSnapshot(tag, snapshot)
    }

    const getLastSnapshot = (): SnapshotDal | null => snapshots.last()

    const takeLastApprovedSnapshot = (tag: string): void => {
        tagManager.addSnapshot(tag, getLastApprovedSnapshot())
    }

    const registerFilter = (name: string, filter: ValueToIndexIds): void => {
        const filterFactory = queryIndex.createFilterFactory(name, filter)
        queryFilterGetters[filterFactory.indexName] = filterFactory.getFilter
    }

    const registerValidator = (name: string, validateValue: ValidateValue): void => {
        validator.registerValidator(name, validateValue)
    }

    const registerRebaseValidator = (name: string, validateValue: ValidateValue): void => {
        rebaseValidator.registerValidator(name, validateValue)
    }

    const registerPostTransactionOperation = (name: string, operation: PostTransactionOperation): void => {
        postTransactionOperations[name] = operation
    }

    const createMergeToApprovedError = (changes: DmStore, id?: string) =>
        new ReportableError({
            errorType: 'cannotMergeToApprovedError',
            message: `MergeToApprovedStore called for ${id} outside initialization flow`,
            extras: {
                id,
                ...createSnapshotTrace(),
                content: _.keys(changes.asJson())
            }
        })

    const getMergeToApprovedStoreSnapshotId = (changes: DmStore, label?: string): string => {
        const id = snapshots.generateId(label)
        if (!tentativeStore.isEmpty() || !currentOpenTransaction.store.isEmpty()) {
            throw createMergeToApprovedError(changes, id)
        }

        return id
    }

    const mergeSnapshotToApprovedStore = (clonedChanges: DmStore, snapshotId: string): SnapshotDal => {
        const newApprovedSnapshot = lastApproved
            ? snapshots.insert(clonedChanges, lastApproved.id, snapshotId)
            : snapshots.add(clonedChanges, snapshotId)

        lastApproved = newApprovedSnapshot
        store.merge(clonedChanges)
        updateIndex(clonedChanges.keys())
        return newApprovedSnapshot
    }

    /**
     * Copies changes into the dal store. Called during DS initialization
     * @param changes - the store to copy into the dal store
     * @param label
     */
    const mergeToApprovedStore = (changes: DmStore, label?: string): SnapshotDal => {
        const id = getMergeToApprovedStoreSnapshotId(changes, label)
        const clonedChanges = changes.clone()

        return mergeSnapshotToApprovedStore(clonedChanges, id)
    }

    /**
     * Copies changes into the dal store. Called during DS initialization
     * @param changes - the store to copy into the dal store
     * @param label
     */
    const mergeToApprovedStoreAsync = async (changes: DmStore, label?: string): Promise<SnapshotDal> => {
        const id = getMergeToApprovedStoreSnapshotId(changes, label)
        const clonedChanges = await changes.cloneAsync()

        return mergeSnapshotToApprovedStore(clonedChanges, id)
    }

    const validate = (tags?: Record<string, any>) => {
        const tentativeAndAccepted = getMergedStore(false)
        validator.validateStore(tentativeAndAccepted, false, tags)
    }

    const createValidationBaseline = () => {
        const tentativeAndAccepted = getMergedStore(false)
        validator.createBaseline(tentativeAndAccepted)
    }

    const getCurrentOpenTransaction = (): Transaction =>
        createImmutableProxy({
            id: 'openTransaction',
            items: currentOpenTransaction.store.getValues()
        })

    const getTentativeAndAcceptedAsTransaction = (): Transaction => {
        const tentativeAndAccepted = getMergedStore(false)
        const transaction = {
            id: 'tentativeAndAccepted',
            items: tentativeAndAccepted.getValues()
        }
        return createImmutableProxy(transaction)
    }

    const registrar = {
        registerFilter,
        registerValidator,
        registerRebaseValidator,
        registerValidationWhitelistCheck,
        registerPostTransactionOperation
    }

    const dropUncommittedTransaction = (reason?: string) => {
        coreConfig.logger.interactionStarted('dropUncommittedTransaction', {
            tags: {
                attempt: currentOpenTransaction.attempt,
                id: currentOpenTransaction.id
            },
            extras: {
                reason
            }
        })
        const pointersToUpdate = currentOpenTransaction.store.keys()
        createNewOpenTransaction()
        updateIndex(pointersToUpdate)
    }

    const enableCommits = () => {
        commitsEnabled = true
    }

    const disableCommits = () => {
        commitsEnabled = false
    }

    const _getMergedStoreAsJson = (includeCurrentTransaction: boolean): DalJsStore => {
        const mergedStore = getMergedStore(includeCurrentTransaction)
        return mergedStore.asJson()
    }

    createNewOpenTransaction()

    const registerForChangesCallback = (callback: DalValueChangeCallback) => {
        updateCallbacks.push(callback)
    }

    const unregisterForChangesCallback = (callback: DalValueChangeCallback) => {
        _.remove(updateCallbacks, e => e === callback)
    }

    const initLazyInitiators = () => {
        queryIndex.forceFiltersInitialization()
    }

    if (initialStore) {
        mergeToApprovedStore(createStore(initialStore))
    }

    const modify: DAL['modify'] = (pointer, f) => set(pointer, f(get(pointer)))

    return {
        approve,
        reject,
        get,
        set: setIfChanged,
        modify,
        has,
        remove,
        isDirty,
        query,
        queryKeys,
        getIndexPointers,
        find,
        getIndexed,
        commitTransaction,
        getWithPath,
        setIfChanged,
        touch,
        queryFilterGetters,
        registrar,
        initLazyInitiators,
        rebase: (change, position, label) =>
            rebase(change, position, TransactionEvents.LOCAL_TRANSACTION_APPROVED, snapshots.generateId(label)),
        rebaseForeignChange,
        getLastSnapshot,
        takeSnapshot,
        takeLastApprovedSnapshot,
        tagManager,
        getRegisteredTypes,
        validate,
        validatePendingCommit,
        createValidationBaseline,
        sign,
        getTentativeAndAcceptedAsTransaction,
        getCurrentOpenTransaction,
        mergeToApprovedStore,
        mergeToApprovedStoreAsync,
        getLastApprovedSnapshot,
        dropUncommittedTransaction,
        enableCommits,
        disableCommits,
        schema,
        hasSignature,
        registerForChangesCallback,
        unregisterForChangesCallback,

        // do not use private members in your code, they are only for debugging
        /** @private */
        get _snapshots() {
            return snapshots
        },
        get _store() {
            return store
        },
        get _tentativeStore() {
            return tentativeStore
        },

        _getApprovedStoreAsJson: (): DalJsStore => store.asJson(),
        _getTentativeStoreAsJson: (): DalJsStore => tentativeStore.asJson(),
        _getCommittedStoreAsJson: (): DalJsStore => _getMergedStoreAsJson(false),
        _getMergedStoreAsJson: (): DalJsStore => _getMergedStoreAsJson(true),

        /** @private */
        get _queryIndex() {
            return queryIndex
        },

        /** @private */
        get _currentTransaction() {
            return currentOpenTransaction
        },
        _getAllTags: () => tagManager._getAllTags()
    }
}
