import type {Pointer, PS} from '@wix/document-services-types'
import metaDataUtils from '../../componentsMetaData/metaDataUtils'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import * as santaCoreUtils from '@wix/santa-core-utils'

const repeaterType = 'wysiwyg.viewer.components.Repeater'

function setMovableSidesForRepeaterItems(ps: PS, metaDataValue, compPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === repeaterType) {
        return []
    }
    return metaDataValue
}

function disableRemovalForRepeaterItems(ps: PS, metaDataValue, compPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === repeaterType) {
        return false
    }
    return metaDataValue
}

function disableDuplicationOfRepeaterItem(ps: PS, metaDataValue, compPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === repeaterType) {
        return false
    }
    return metaDataValue
}

function setResizableSidesForRepeaterItems(ps: PS, metaDataValue, compPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(compPointer)
    if (parentPointer && metaDataUtils.getComponentType(ps, parentPointer) === repeaterType) {
        const compPropertiesItem = dataModel.getPropertiesItem(ps, parentPointer)
        return compPropertiesItem &&
            compPropertiesItem.alignment === santaCoreUtils.constants.COMP_LAYOUT_OPTIONS.REPEATER.ALIGNMENTS.RIGHT
            ? [constants.RESIZE_SIDES.BOTTOM, constants.RESIZE_SIDES.LEFT]
            : [constants.RESIZE_SIDES.BOTTOM, constants.RESIZE_SIDES.RIGHT]
    }
    return metaDataValue
}

export default {
    setMovableSidesForRepeaterItems,
    disableRemovalForRepeaterItems,
    disableDuplicationOfRepeaterItem,
    setResizableSidesForRepeaterItems
}
