import _ from 'lodash'
import {urlUtils, siteConstants, errorPages} from '@wix/santa-core-utils'
import urlParserUtils from '../utils/urlParserUtils'
import siteDataUtils from '../../dal/siteDataUtils'
import routerUtils from '../../core/routerUtils'

const LIGHT_BOX = 'lightbox'
const RESERVED_QUERY_WORDS = [LIGHT_BOX]
const BLOG_SINGLE_POST_APP_ID = '7326bfbb-4b10-4a8e-84c1-73f776051e10'
const BLOG_POSTS_LIST_APP_ID = '79f391eb-7dfc-4adf-be6e-64434c4838d9'
const PERMALINK_PREFIX = '_p'
const URL_SEGMENTS_BEFORE_INNER_URL = {
    PREVIEW: 7,
    SITE_HISTORY: 8,
    WIX_INTERNAL: 2,
    WIX_INTERNAL_SINGLE_PATH: 1,
    FREE_SITE: 1,
    PREMIUM: 0,
    REVIEW: 3,
    BEAKER: 3,
    IX: 2
}
const GOOGLE_CACHE_HOSTNAME = 'webcache.googleusercontent.com'

function isPage(siteData, pageName) {
    return _.includes(siteData.allPageIds, getPageIdByUriSeo(siteData, pageName))
}

function isDynamicRouterByPrefix(siteData, prefix) {
    return !!getDynamicRouterByPrefix(siteData, prefix)
}

function isSinglePathURL(siteData, candidateUrlObj) {
    const urlPathAsArray = _.reject(_.split(candidateUrlObj.path, '/'), _.isEmpty) || []
    return (
        isPage(siteData, urlPathAsArray[0]) &&
        !isPage(siteData, urlPathAsArray[1]) &&
        isDynamicRouterByPrefix(siteData, urlPathAsArray[1])
    )
}

function isFreeOrWixSite(siteData) {
    const {host, path} = urlUtils.parseUrl(siteData.externalBaseUrl)
    return (
        _.endsWith(host, `.${siteConstants.FREE_DOMAIN.WIXSITE}`) ||
        _.endsWith(host, `.${siteConstants.FREE_DOMAIN.EDITORX}`) ||
        _.endsWith(host, '.editorx.com') ||
        (_.endsWith(host, `.${siteData.serviceTopology.baseDomain}`) && path.length > 1)
    )
}

function isPremiumDomainConnected(siteData) {
    return siteData.isPremiumDomain && !isFreeOrWixSite(siteData)
}

function isWixSiteTemplateAddress(siteData) {
    const fullUrl = _.isObject(siteData.currentUrl)
        ? siteData.currentUrl.full
        : urlUtils.parseUrl(siteData.currentUrl).full
    return (
        siteData.isTemplate &&
        _.startsWith(withoutProtocol(fullUrl), withoutProtocol(siteData.serviceTopology.basePublicUrl))
    )
}

function getPathPartsCount(siteData, options) {
    // @ts-ignore
    if (typeof window === 'object' && window.ixIntegration) {
        return URL_SEGMENTS_BEFORE_INNER_URL.IX
    }
    // @ts-ignore
    if (typeof window === 'object' && window.karmaIntegration) {
        return URL_SEGMENTS_BEFORE_INNER_URL.BEAKER
    }

    if (siteData.isFeedbackEndpoint) {
        return URL_SEGMENTS_BEFORE_INNER_URL.REVIEW
    }

    if (siteData.isSiteHistoryEndpoint) {
        return URL_SEGMENTS_BEFORE_INNER_URL.SITE_HISTORY
    }

    if (!siteData.isViewerMode) {
        return URL_SEGMENTS_BEFORE_INNER_URL.PREVIEW
    }

    if (isPremiumDomainConnected(siteData)) {
        return URL_SEGMENTS_BEFORE_INNER_URL.PREMIUM
    }

    if (siteData.isWixSite || isWixSiteTemplateAddress(siteData)) {
        return options.singlePathURL
            ? URL_SEGMENTS_BEFORE_INNER_URL.WIX_INTERNAL_SINGLE_PATH
            : URL_SEGMENTS_BEFORE_INNER_URL.WIX_INTERNAL
    }

    return URL_SEGMENTS_BEFORE_INNER_URL.FREE_SITE
}

function encodeEachUriComponent(uriParts) {
    return _.map(uriParts, encodeURIComponent)
}

function decodeEachUriComponent(uriParts) {
    return _.map(uriParts, decodeURIComponent)
}

function getPageIdByUriSeoFromData(siteData, pageUriSeo) {
    const pageData = _.find(siteData.pagesDataItemsMap, {pageUriSEO: pageUriSeo})
    return pageData?.id
}

function getCustomNotFoundPageId(siteData) {
    return siteData.rendererModel.customNotFoundPageId
}

function getDecodedUriComponent(stringToDecode) {
    try {
        return decodeURIComponent(stringToDecode)
    } catch (ex) {
        return ''
    }
}

function getPageIdByUriSeo(siteData, pageUriSeo) {
    const mapFromPageUriSeoToPageId = siteDataUtils.getMapFromPageUriSeoToPageId(siteData)
    const decodedPageUriSeo = getDecodedUriComponent(pageUriSeo)
    const pageIdFromMap =
        _.get(mapFromPageUriSeoToPageId, pageUriSeo) || _.get(mapFromPageUriSeoToPageId, decodedPageUriSeo)
    const notFoundPageId = getCustomNotFoundPageId(siteData) || errorPages.IDS.NOT_FOUND

    let resultPageId = pageIdFromMap || getPageIdByUriSeoFromData(siteData, pageUriSeo) || notFoundPageId
    if (routerUtils.isDynamicPage(siteData.routersConfigMap, resultPageId)) {
        resultPageId = notFoundPageId
    }

    return resultPageId
}

function getRelevantPathParts(siteData, path) {
    const baseUrlParts = urlUtils.parseUrl(siteData.externalBaseUrl)
    const drop = baseUrlParts.path.replace(/\/$/, '').split('/').length

    return path ? _.drop(path.split('/'), drop) : []
}

function isPermalinkPrefix(prefix) {
    return prefix === PERMALINK_PREFIX
}

function isTpaPage(siteData, pageId) {
    const pageDataItem = siteData.pagesDataItemsMap[pageId]
    return pageDataItem && pageDataItem.type === 'Page' && pageDataItem.tpaApplicationId > 0
}

function isListBuilderPage(siteData, pageId) {
    const pageDataItem = siteData.pagesDataItemsMap[pageId]
    return pageDataItem && pageDataItem.type === 'AppPage' && pageDataItem.appPageType === 'AppBuilderPage'
}

function isBlogSinglePostPage(siteData, pageId) {
    const pageDataItem = siteData.pagesDataItemsMap[pageId]
    return (
        pageDataItem &&
        pageDataItem.type === 'AppPage' &&
        pageDataItem.appPageType === 'AppPage' &&
        pageDataItem.appPageId === BLOG_SINGLE_POST_APP_ID
    )
}

function isBlogPostsListPage(siteData, pageId) {
    const pageDataItem = siteData.pagesDataItemsMap[pageId]
    return (
        pageDataItem &&
        pageDataItem.type === 'AppPage' &&
        pageDataItem.appPageType === 'AppPage' &&
        pageDataItem.appPageId === BLOG_POSTS_LIST_APP_ID
    )
}

function isBlogPage(siteData, pageId) {
    return isBlogPostsListPage(siteData, pageId) || isBlogSinglePostPage(siteData, pageId)
}

function withoutProtocol(url) {
    return url?.replace(/^https?:\/\//, '')
}

function getCandidataUrlFallbackForWixSite(siteData, candidateUrl): string | undefined {
    if (siteData.isWixSite) {
        const pathAsArray = _(candidateUrl).split('/').compact().value()
        const secondPath = _.isArray(pathAsArray) && pathAsArray.pop()
        if (isPage(siteData, secondPath)) {
            return pathAsArray.join('/')
        }
        return ''
    }
}

function isUrlOnSameWixSite(siteData, candidateUrlObj): boolean {
    const singlePathURL = isSinglePathURL(siteData, candidateUrlObj)
    const candidateSiteUrlBase = withoutProtocol(
        urlUtils.getBaseUrlWithPath(candidateUrlObj, getPathPartsCount(siteData, {singlePathURL}))
    )
    const candidataUrlFallbackForWixSite = getCandidataUrlFallbackForWixSite(siteData, candidateSiteUrlBase)

    return (
        urlUtils.isSame(candidateSiteUrlBase, withoutProtocol(siteData.externalBaseUrl)) ||
        urlUtils.isSame(candidateSiteUrlBase, withoutProtocol(siteData.unicodeExternalBaseUrl)) ||
        urlUtils.isSame(candidataUrlFallbackForWixSite, withoutProtocol(siteData.externalBaseUrl)) ||
        urlUtils.isHostnameYandexWebvisor(candidateSiteUrlBase) ||
        candidateSiteUrlBase.startsWith('s3.amazonaws.com/wix-')
    )
}

function getDynamicRouterByPrefix(siteData, firstPathPart) {
    if (!firstPathPart) {
        return
    }
    const routerData = _.find(siteData.routersConfigMap, {prefix: firstPathPart})
    if (!routerData) {
        return
    }
    return _.assign(routerData, {routerId: _.findKey(siteData.routersConfigMap, {prefix: firstPathPart})})
}

function getCachedUrlParts(urlParts) {
    const cacheQuery = urlParts.query.q
    const match = /:([^:]+)\+$/.exec(cacheQuery)
    return (match?.[1] && urlUtils.parseUrl(match[1])) || urlParts
}

function parseUrl(siteData, url) {
    let urlParts: any = _.isObject(url) ? url : urlUtils.parseUrl(url)
    if (urlParts.hostname === GOOGLE_CACHE_HOSTNAME) {
        urlParts = getCachedUrlParts(urlParts)
    }
    if (!isUrlOnSameWixSite(siteData, urlParts)) {
        return null
    }
    const pathParts = getRelevantPathParts(siteData, urlParts.path)
    const firstPathPart = _.head(pathParts)
    const remainingPathParts = _.drop(pathParts, 1)
    const result: Record<string, any> = {
        format: siteConstants.URL_FORMATS.SLASH
    }
    const dynamicRouter = getDynamicRouterByPrefix(siteData, firstPathPart)
    if (dynamicRouter) {
        result.routerDefinition = dynamicRouter
        result.pageAdditionalData = `${firstPathPart}/${remainingPathParts.join('/')}`
    }

    if (result.routerDefinition) {
        result.innerRoute = remainingPathParts.join('/') || '/' //todo - check if it includes the query params, if not send them as well
        const cachedResultForUrl = siteData.pageResponseForUrl
        if (cachedResultForUrl?.pageId) {
            result.pageId = cachedResultForUrl.pageId
            result.title = cachedResultForUrl.title
        }
    } else if (firstPathPart) {
        if (isPermalinkPrefix(firstPathPart)) {
            result.pageItemId = _.head(remainingPathParts)
            result.pageId = siteData.currentUrlPageId || siteData.mainPageId
        } else {
            result.pageId = getPageIdByUriSeo(siteData, firstPathPart)
            if (isListBuilderPage(siteData, result.pageId)) {
                result.title = pathParts[2]
            } else {
                result.title = firstPathPart
            }
        }
    } else {
        result.pageId = siteData.mainPageId
        result.title = getPageUriSeo(siteData, result.pageId)
    }

    if (urlParts.query[LIGHT_BOX]) {
        result.pageItemId = urlParts.query[LIGHT_BOX]
        result.imageZoom = true
    }
    const queryObj: any = _.omit(urlParts.query, RESERVED_QUERY_WORDS)
    if (urlParts.query && !_.isEmpty(queryObj)) {
        result.queryParams = queryObj
    }

    if (pathParts.length > 1 && !result.routerDefinition) {
        //todo - check if we are comming back to here when the real page is loading
        //if not maybe we are missing something - related to SE-16613
        //we probably need some kind of page id provider that is not using the url
        if (isListBuilderPage(siteData, result.pageId)) {
            result.pageAdditionalData = _.head(remainingPathParts)
        } else if (isPermalinkPrefix(firstPathPart)) {
            result.pageAdditionalData = _.drop(remainingPathParts, 1).join('/')
        } else if (isTpaPage(siteData, result.pageId)) {
            result.tpaInnerRoute = decodeEachUriComponent(remainingPathParts).join('/')
        } else {
            result.pageAdditionalData = remainingPathParts.join('/')
        }
    }
    return result
}

function getPageUriSeo(siteData, pageId: string) {
    const pageDataItem = siteData.pagesDataItemsMap[pageId]
    return _.get(pageDataItem, ['pageUriSEO']) || siteConstants.DEFAULT_PAGE_URI_SEO
}

function getQueryParams(siteData) {
    const {names = new Set(), matchers = []} = urlParserUtils.queryParamsWhitelist

    // current page query params should not persist when navigating
    // take all whitelisted and also check for partials
    return _.pickBy(
        siteData.currentUrl.query,
        (value, key) => names.has(key) || _.some(matchers, matcher => key.match(matcher))
    )
}

function getCurrentInnerRoute(siteData) {
    const pageAdditionalData = _.get(siteData, ['rootNavigationInfo', 'pageAdditionalData'])
    if (!pageAdditionalData) {
        return
    }
    const pageSuffix = pageAdditionalData.split('/')
    return _.drop(pageSuffix, 1).join('/')
}

function isDynamicPageInfo(pageInfo) {
    return pageInfo.routerId || pageInfo.routerDefinition
}

function getUrl(
    siteData,
    pageInfo,
    forceAddPageInfo,
    cleanQuery,
    baseUrl: string,
    rawSiteData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dismissQueryParamList = [],
    ignorePageUriSeo = false
) {
    const navigationQuery = pageInfo.queryParams || pageInfo.query || {}
    const currentQueryParams = getQueryParams(siteData)
    const queryParams = _.assign({}, currentQueryParams, navigationQuery)

    let url = baseUrl || siteData.externalBaseUrl
    url = url.replace(/\/$/, '')
    const permalink = siteData.isPermalink(pageInfo.pageItemId)

    if (isDynamicPageInfo(pageInfo)) {
        if (pageInfo.innerRoute === 'CURRENT_INNER_ROUTE') {
            pageInfo.innerRoute = getCurrentInnerRoute(siteData) ?? pageInfo.innerRoute
        }
        const routerInfoPrefix = pageInfo.routerId
            ? _.get(siteData.routersConfigMap, `${pageInfo.routerId}.prefix`)
            : pageInfo.routerDefinition.prefix
        url += `/${routerInfoPrefix}`
        if (pageInfo.innerRoute && pageInfo.innerRoute !== '/') {
            url += `/${pageInfo.innerRoute}`
        }
    } else if (
        pageInfo.pageId &&
        !permalink &&
        (pageInfo.pageId !== siteData.mainPageId ||
            forceAddPageInfo ||
            pageInfo.pageAdditionalData ||
            pageInfo.tpaInnerRoute)
    ) {
        if (!ignorePageUriSeo && !_.get(pageInfo, ['ignorePageUriSeo'], false)) {
            url += `/${getPageUriSeo(siteData, pageInfo.pageId)}`
        }
    }

    if (isListBuilderPage(siteData, pageInfo.pageId) && pageInfo.pageAdditionalData) {
        url += `/${pageInfo.pageAdditionalData}${pageInfo.title ? `/${pageInfo.title}` : ''}`
    } else if (isTpaPage(siteData, pageInfo.pageId) && pageInfo.tpaInnerRoute && !isDynamicPageInfo(pageInfo)) {
        url += `/${encodeEachUriComponent(pageInfo.tpaInnerRoute.split('/')).join('/')}`
    } else if (isBlogPage(siteData, pageInfo.pageId) && pageInfo.pageAdditionalData) {
        const customUrlMapping = rawSiteData?.getCustomUrlMapping?.()
        const postPermalink = _.findKey(customUrlMapping, {id: pageInfo.pageAdditionalData})
        if (postPermalink) {
            url += `/${postPermalink}`
        } else {
            url += `/${pageInfo.pageAdditionalData}`
        }
    }

    if (pageInfo.pageItemId) {
        if (permalink) {
            if (pageInfo.pageAdditionalData) {
                url += `/${PERMALINK_PREFIX}/${pageInfo.pageItemId}/${pageInfo.pageAdditionalData}`
            } else {
                //todo console something
                //todo console something
            }
        } else if (pageInfo.imageZoom) {
            queryParams[LIGHT_BOX] = pageInfo.pageItemId
        }
    }

    if (!cleanQuery && !_.isEmpty(queryParams) && siteData.currentUrl.hostname !== GOOGLE_CACHE_HOSTNAME) {
        url += `?${urlUtils.toQueryString(queryParams)}`
    }

    return url
}

function parseUrlWithResolvedSiteData(siteData, url) {
    const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData)

    return parseUrl(resolvedSiteData, url)
}

function getUrlWithResolvedSiteData(
    siteData,
    pageInfo,
    forceAddPageInfo,
    cleanQuery,
    baseUrl,
    urlMapping,
    rawSiteData,
    dismissQueryParamList,
    ignorePageUriSeo
) {
    const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData)

    return getUrl(
        resolvedSiteData,
        pageInfo,
        forceAddPageInfo,
        cleanQuery,
        baseUrl,
        rawSiteData,
        dismissQueryParamList,
        ignorePageUriSeo
    )
}

function isUrlToCurrentSite(siteData, url): boolean {
    const urlParts = urlUtils.parseUrl(url)
    const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData)
    return isUrlOnSameWixSite(resolvedSiteData, urlParts)
}

export default {
    parseUrl: parseUrlWithResolvedSiteData,
    getUrl: getUrlWithResolvedSiteData,
    isUrlToCurrentSite
}
