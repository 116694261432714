import type {PublicMethodUtils} from '@wix/document-services-types'
import {dataModel, componentsDataModel} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            components: {
                data: {
                    update: publicMethodUtils.defineDataManipulationAction(componentsDataModel.updateDataItem, {
                        disableLogInteraction: true
                    }),
                    updateInLang: publicMethodUtils.defineDataManipulationAction(dataModel.updateDataItemInLang),
                    get: publicMethodUtils.defineGetter(componentsDataModel.getDataItem),
                    remove: publicMethodUtils.defineDataManipulationAction(dataModel.removeComponentDataItem),
                    /**
                     * Returns runtime data item if exists, otherwise returns regular data item.
                     * @member documentServices.components.data
                     * @param {Object} componentPointer
                     * @returns {Object} Runtime / regular data item.
                     */
                    getRuntime: publicMethodUtils.defineGetter(dataModel.getRuntimeDataItem),
                    hasRuntimeChanges: publicMethodUtils.defineGetter(dataModel.hasRuntimeChanges)
                }
            },
            data: {
                /**
                 * Creates a link and adds it to the data of the Master Page.
                 * @member documentServices.data
                 * @param {string} linkType the type of the link to create.
                 * @param {Object} optionalLinkData optional data to set upon creation.
                 * @returns {Object} a reference to the Link Data Item.
                 */
                addLink: publicMethodUtils.defineAction(dataModel.addLink),
                /**
                 * Retrieves a data item according to its unique id, and the page containing it (assuming the page was loaded)
                 * if no page was specified, the searched page will be master page
                 * @member documentServices.data
                 * @param {string} dataItemId
                 * @param {string} [pageId] optional parameter
                 * @returns {Object} data item with the given id or null if doesn't exist
                 */
                getById: publicMethodUtils.defineGetter(dataModel.getDataItemById),
                /**
                 * Creates a Data Item corresponding a data type.
                 * @member documentServices.data
                 * @param {String} dataType a type of data to create corresponding data item. {String}
                 * @returns {Object}
                 */
                createItem: publicMethodUtils.defineGetter(dataModel.createDataItemByType),
                /***
                 * @member documentServices.data
                 * @param {String} dataSchemaType
                 * @returns {Object}
                 */
                getSchema: publicMethodUtils.defineGetter(dataModel.getDataSchemaByType),
                isItemValid: publicMethodUtils.defineGetter(dataModel.isDataItemValid)
            },
            properties: {
                /**
                 * Creates a Properties Item according to a given type.
                 * @member documentServices.properties
                 * @param {String} propertiesType
                 * @returns {Object}
                 */
                createItem: publicMethodUtils.defineGetter(dataModel.createPropertiesItemByType),
                /**
                 * Retrieves a properties item according to its unique id, and the page containing it (assuming the page was loaded)
                 * if no page was specified, the searched page will be master page
                 * @member documentServices.properties
                 * @param {string} dataItemId
                 * @param {string} [pageId] optional parameter
                 * @returns {Object} properties item with the given id or null if doesn't exist
                 */
                getById: publicMethodUtils.defineGetter(dataModel.getPropertiesItemById),
                /**
                 * @member documentServices.properties
                 * @param {String} propertiesSchemaType
                 * @returns {Object}
                 */
                getSchema: publicMethodUtils.defineGetter(dataModel.getPropertiesSchemaByType),
                isItemValid: publicMethodUtils.defineGetter(dataModel.isPropertiesItemValid)
            },
            design: {
                createItem: publicMethodUtils.defineGetter(dataModel.createDesignItemByType),
                getById: publicMethodUtils.defineGetter(dataModel.getDataItemById)
            }
        }
    }
}
