import {
    CreateExtArgs,
    DAL,
    DalItem,
    DalValue,
    DmApis,
    Extension,
    ExtensionAPI,
    IndexKey,
    Namespace,
    pointerUtils,
    ValidatorMap
} from '@wix/document-manager-core'
import type {ValidateValue, ValidatorResult} from '@wix/document-manager-core/src/dal/validation/dalValidation'
import type {Pointer, ResolvedReference} from '@wix/document-services-types'
import _ from 'lodash'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, DATA_TYPES, MULTILINGUAL_TYPES, VIEW_MODES} from '../constants/constants'
import {getIdFromRef} from '../utils/dataUtils'
import {isComponentDefinitionValid, validateCompNamespaceType} from '../utils/schemaUtils'

const {getPointer} = pointerUtils

const multilingualNS = MULTILINGUAL_TYPES.multilingualTranslations
const PAGE_TYPES = _.keyBy(['Page', 'Document'])
const REVERSE_LOOKUP_INDEX = 'reverseLookup'

const getIndexId = (prefix: string, namespace: string, id: string) => `${prefix}^${namespace}^${id}`

interface ValidationFlags {
    failBrokenRefs: boolean
    failDuplicateRefs: boolean
}

const isPermanentDataNode = (dal: DAL, referredValue: DalValue, namespace: string) => {
    const schemaType = dal.schema.getSchemaType(namespace, referredValue)
    return dal.schema.isPermanentDataType(namespace, schemaType)
}

const isOwningReference = (dal: DAL, namespace: string, value: DalValue, reference: ResolvedReference) => {
    const {
        id,
        refInfo: {shouldCollect, isRefOwner}
    } = reference
    if (!id || !shouldCollect || isRefOwner === false || !value) {
        return false
    }

    if (namespace === multilingualNS && ['BasicMenuItem', 'CustomMenu'].includes(value.type)) {
        return false
    }

    return true
}

interface CustomFilter {
    getIndexKey(namespace: string, id: string): string
    predicate(dal: DAL, namespace: string, value: DalValue, ref: ResolvedReference): boolean
}

const reverseReferencesFilter: CustomFilter = {
    getIndexKey(namespace: string, id: string) {
        const REFERENCES = 'ref'
        return getIndexId(REFERENCES, namespace, id)
    },
    predicate(dal: DAL, namespace: string, value: DalValue, ref: ResolvedReference) {
        return !!(ref.id && ref.refInfo.shouldValidate)
    }
}

const owningReferencesFilter: CustomFilter = {
    getIndexKey(namespace: string, id: string) {
        const OWNING_REFERENCES = 'own'
        return getIndexId(OWNING_REFERENCES, namespace, id)
    },
    predicate(dal: DAL, namespace: string, value: DalValue, ref: ResolvedReference) {
        return isOwningReference(dal, namespace, value, ref)
    }
}

const customRefFilters: CustomFilter[] = [reverseReferencesFilter, owningReferencesFilter]

const createFilters = ({dal}: DmApis) => ({
    [REVERSE_LOOKUP_INDEX]: (namespace: Namespace, value: DalValue): string[] => {
        const refs: readonly ResolvedReference[] = dal.schema.getReferences(namespace, value)
        return refs.reduce((keys: string[], ref: ResolvedReference) => {
            customRefFilters.forEach((f: CustomFilter) => {
                if (f.predicate(dal, namespace, value, ref)) {
                    keys.push(f.getIndexKey(ref.referencedMap, ref.id))
                }
            })
            return keys
        }, [])
    }
})

const getReferredPointers = (
    dal: DAL,
    namespace: string,
    value: DalValue,
    shouldValidate: boolean = true
): Pointer[] => {
    const references = dal.schema.getReferences(namespace, value)

    return _(references)
        .map((reference: ResolvedReference) => {
            if (reference.id) {
                //ignoring empty values
                if (!shouldValidate || reference.refInfo.shouldValidate) {
                    return getPointer(reference.id, reference.referencedMap)
                }
            }

            return undefined
        })
        .compact()
        .value()
}

const getReferencesToPointer = (dal: DAL, pointer: Pointer, namespace?: string): Pointer[] => {
    const indexId = reverseReferencesFilter.getIndexKey(pointer.type, pointer.id)
    const indexKey: IndexKey = dal.queryFilterGetters[REVERSE_LOOKUP_INDEX](indexId)
    return dal.getIndexPointers(indexKey, namespace)
}

const getOwningReferencesToPointer = (dal: DAL, pointer: Pointer, namespace?: string): Pointer[] => {
    const indexId = owningReferencesFilter.getIndexKey(pointer.type, pointer.id)
    const indexKey: IndexKey = dal.queryFilterGetters[REVERSE_LOOKUP_INDEX](indexId)
    return dal.getIndexPointers(indexKey, namespace)
}

const getReferencesToPointerIndex = (dal: DAL, pointer: Pointer, namespace?: string) => {
    const indexId = reverseReferencesFilter.getIndexKey(pointer.type, pointer.id)
    const indexKey: IndexKey = dal.queryFilterGetters[REVERSE_LOOKUP_INDEX](indexId)
    const values = dal.getIndexed(indexKey)
    return namespace ? values[namespace] : values
}

const isReferenced = (dal: DAL, pointer: Pointer, namespace?: string): boolean => {
    const indexId = reverseReferencesFilter.getIndexKey(pointer.type, pointer.id)
    const indexKey: IndexKey = dal.queryFilterGetters[REVERSE_LOOKUP_INDEX](indexId)

    if (namespace) {
        const indexResult = dal.query(namespace, indexKey)
        return !_.isEmpty(indexResult)
    }

    const indexResult = dal.getIndexed(indexKey)

    return _.some(indexResult, namespaceValues => !_.isEmpty(namespaceValues))
}

export type CustomRefValidation = (
    pointer: Pointer,
    value: DalItem,
    referred: DalItem,
    reference: ResolvedReference
) => ValidatorResult[] | undefined

const COMP_QUERIES_TO_VALIDATE: Record<string, string> = _.pick(COMP_DATA_QUERY_KEYS_WITH_STYLE, [
    DATA_TYPES.data,
    DATA_TYPES.design,
    DATA_TYPES.prop
])
const NAMESPACE_TO_VALIDATE = _.mapValues(COMP_QUERIES_TO_VALIDATE, (v, k) => k)
const createExtension = (): Extension => {
    const customRefValidations: CustomRefValidation[] = []

    const isGCRoot = (dal: DAL, pointer: Pointer) => {
        const value = dal.get(pointer)
        const isStructure = !!VIEW_MODES[pointer.type]
        const isPageType = !!PAGE_TYPES[value.type]
        if (isStructure && isPageType) {
            return true
        }
        if (isPermanentDataNode(dal, value, pointer.type)) {
            return true
        }
        return false
    }

    const createReferenceValidators = ({dal}: DmApis, flags: ValidationFlags): Record<string, ValidateValue> => {
        const MULTILINGUAL_DATA_NS = [multilingualNS, DATA_TYPES.data]
        const getValueId = (pointer: Pointer) => dal.get(pointer)?.id

        const duplicatedPointers = (pointer: Pointer, owningReference: Pointer) => {
            if (pointer.type === owningReference.type) {
                if (pointer.type === multilingualNS) {
                    // Allow multiple references from multilingual to refer to the same node
                    return getValueId(pointer) !== getValueId(owningReference)
                }

                return owningReference.id !== pointer.id
            }

            const multilingualVsData =
                MULTILINGUAL_DATA_NS.includes(pointer.type) && MULTILINGUAL_DATA_NS.includes(owningReference.type)

            if (multilingualVsData) {
                // Allow references from multilingual and data with the same id to refer to the same node
                if (pointer.type === multilingualNS) {
                    return getValueId(pointer) !== owningReference.id
                }

                return getValueId(owningReference) !== pointer.id
            }

            // Allow the same component in DESKTOP and MOBILE to reference the same child node
            // Allow different components in DESKTOP and MOBILE to contain the same child component (reparenting is allowed)
            // TODO - Don't allow different components in DESKTOP and MOBILE to reference the same data component
            const desktopVsMobile = VIEW_MODES[pointer.type] && VIEW_MODES[owningReference.type]

            return !desktopVsMobile
        }

        // Ensure that there is only one owning reference to all child references
        const uniqueReferencesValidate: CustomRefValidation = (
            pointer: Pointer,
            value: DalItem,
            referredValue: DalItem,
            reference: ResolvedReference
        ) => {
            if (
                !isOwningReference(dal, pointer.type, value, reference) ||
                isPermanentDataNode(dal, referredValue, reference.referencedMap)
            ) {
                return
            }

            const {id, referencedMap: namespace} = reference
            const childPointer = getPointer(id, namespace)
            const owningReferencesToChild: Pointer[] = getOwningReferencesToPointer(dal, childPointer)
            const duplicates = owningReferencesToChild.filter((owningReference: Pointer) =>
                duplicatedPointers(pointer, owningReference)
            )

            if (duplicates.length > 0) {
                return [
                    {
                        shouldFail: flags.failDuplicateRefs,
                        type: 'DuplicateReferenceError',
                        message: `Duplicate owning references to ${JSON.stringify(childPointer)}`,
                        extras: {
                            from: pointer,
                            to: childPointer,
                            duplicates
                        }
                    }
                ]
            }
        }

        return {
            // Check if a value has any missing references
            validateReferences: (pointer: Pointer, value: DalValue) => {
                if (_.isNil(value)) {
                    return
                }

                const {schema} = dal
                const namespace = pointer.type
                const references = schema.getReferences(namespace, value)
                return _(
                    references.map((reference: ResolvedReference) => {
                        const {shouldValidate, refTypes, isRelationalSplit, path} = reference.refInfo
                        if (reference.id && shouldValidate) {
                            //ignoring empty values
                            const refPointer = getPointer(reference.id, reference.referencedMap)
                            const referred = dal.get(refPointer)
                            if (!referred) {
                                return {
                                    shouldFail: flags.failBrokenRefs,
                                    type: 'missingReferenceError',
                                    message: `missing referred value for ${JSON.stringify(
                                        refPointer
                                    )} from: ${JSON.stringify(pointer)}`,
                                    extras: {
                                        path,
                                        referred: refPointer
                                    }
                                }
                            }

                            const refSchemaType = schema.getSchemaType(reference.referencedMap, referred)
                            if (
                                !(isRelationalSplit && refSchemaType === 'RefArray') &&
                                refTypes.length &&
                                !refTypes.includes(refSchemaType)
                            ) {
                                return {
                                    shouldFail: flags.failBrokenRefs,
                                    type: 'invalidReferenceTypeError',
                                    message: `${refSchemaType} is not a valid reference type for ${JSON.stringify(
                                        refPointer
                                    )} from: ${JSON.stringify(pointer)}`,
                                    extras: {
                                        refSchemaType,
                                        from: pointer,
                                        to: refPointer
                                    }
                                }
                            }

                            return _.flatten(
                                [...customRefValidations, uniqueReferencesValidate].map(validate =>
                                    validate(pointer, value, referred, reference)
                                )
                            )
                        }
                        return false
                    })
                )
                    .flatten()
                    .compact()
                    .value()
            },

            // Check if a value about to be deleted is referenced
            validateReverseReferences: (pointer: Pointer, value: DalValue) => {
                if (!_.isNil(value)) {
                    return
                }

                const references = getReferencesToPointer(dal, pointer)
                const referenceCount = references.length
                if (referenceCount === 0) {
                    return
                }

                const isGarbage = _.every(
                    references,
                    ref => getReferencesToPointer(dal, ref).length === 0 && !isGCRoot(dal, ref)
                )
                const firstFiveRefs = JSON.stringify(references.slice(0, 5))

                return [
                    {
                        shouldFail: flags.failBrokenRefs && !isGarbage,
                        type: 'existingReferencesError',
                        message: `Cannot delete ${JSON.stringify(
                            pointer
                        )} because of the following references to it: ${firstFiveRefs}`,
                        extras: {
                            deletedPointer: pointer,
                            referenceCount,
                            referrer: dal.get(references[0]),
                            referrerNamespace: references[0].type
                        },
                        tags: {
                            isGarbage
                        }
                    }
                ]
            }
        }
    }

    const validateCompNSConnection = (dal: DAL, component: any, item: any, namespace: string, shouldFail: boolean) => {
        const {componentType} = component
        const {type: itemType = ''} = item ?? {}
        const validCompNamespaceType = validateCompNamespaceType(dal, componentType, itemType, namespace)
        if (!validCompNamespaceType.isValid) {
            return {
                shouldFail,
                type: `invalidComp_${namespace}`,
                extras: {
                    componentType,
                    itemType
                },
                message: validCompNamespaceType.message ?? ''
            }
        }
    }

    const getComponentNamespacedItem = ({dal, pointers}: DmApis, compPointer: Pointer, namespace: string) => {
        const queryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[namespace]
        const comp = dal.get(compPointer)
        if (comp[queryKey]) {
            const itemId = getIdFromRef(comp[queryKey])
            const itemPointer = pointers.data.getItem(namespace, itemId, comp.metaData?.pageId)
            return dal.get(itemPointer)
        }
    }

    const isDraftItem = (dmApis: DmApis, compPointer: Pointer, ns: string) => {
        const item = getComponentNamespacedItem(dmApis, compPointer, ns)
        return item && dmApis.dal.schema.isDraftItem(item)
    }

    const createCompNSTypeValidator = (dmApis: DmApis, shouldFail: boolean) => (pointer: Pointer, value: DalValue) => {
        const {dal, pointers} = dmApis
        const isComponentPointer = _.includes(VIEW_MODES, pointer.type)
        if (isComponentPointer && value) {
            if (!isComponentDefinitionValid(dal, pointer, value)) {
                if (_.some(COMP_QUERIES_TO_VALIDATE, (query, ns) => isDraftItem(dmApis, pointer, ns))) {
                    return
                }
                const {componentType} = value
                return [
                    {
                        shouldFail: true,
                        type: 'compMissingSchemaDefinition',
                        message: `${componentType} has no component definition`,
                        extras: {
                            componentType
                        }
                    }
                ]
            }
            return _.compact(
                _.map(COMP_QUERIES_TO_VALIDATE, (queryKey, namespace) => {
                    const itemRef = value[queryKey]
                    const hasQuery = _.isString(itemRef)
                    let item
                    if (hasQuery) {
                        const itemPointer = pointers.data.getItem(
                            namespace,
                            getIdFromRef(itemRef),
                            value.metaData?.pageId
                        )
                        item = dal.get(itemPointer)
                    }
                    if (hasQuery && !item) {
                        //this is a missing ref case, validated in another validator
                        return
                    }
                    //if no query, we still validate, as the item might be required according to component definition
                    return validateCompNSConnection(dal, value, item, namespace, shouldFail)
                })
            )
        }
        if (NAMESPACE_TO_VALIDATE[pointer.type]) {
            if (!value || dal.schema.isDraftItem(value)) {
                return //we dont validate draft items, as we dont have the component definitions
            }
            const owners = getOwningReferencesToPointer(dal, pointer)
            const compOwner = _.find(owners, {type: VIEW_MODES.DESKTOP}) ?? _.find(owners, {type: VIEW_MODES.MOBILE})
            if (compOwner) {
                const comp = dal.get(compOwner)
                if (!comp) {
                    return //this means the component was removed in this transaction. This is validiated elsewhere
                }
                if (comp[COMP_QUERIES_TO_VALIDATE[pointer.type]] !== `#${pointer.id}`) {
                    return //component was migrated, old pointer still has 'new comp' as owner in index. dont need to validate this.
                }
                const error = validateCompNSConnection(dal, comp, value, pointer.type, shouldFail)
                if (error) {
                    return [error]
                }
            }
        }
    }

    const createValidator = (apis: DmApis) => {
        const {coreConfig} = apis
        const validators: ValidatorMap = {}
        const isCompValidatorExperimentOpen = coreConfig.experimentInstance.isOpen(
            'dm_componentNamespacesTypesValidator'
        )
        if (isCompValidatorExperimentOpen) {
            const shouldFail = coreConfig.experimentInstance.isOpen('dm_componentNamespacesTypesValidatorFail')
            validators.validateComponentNamespacesTypes = createCompNSTypeValidator(apis, shouldFail)
        }

        const isOpen = (experiment: string) => coreConfig.experimentInstance.isOpen(experiment)
        const failDefault = coreConfig.strictModeFailDefault
        const failBrokenRefs = failDefault || isOpen('dm_shouldBrokenRefFail')
        const failDuplicateRefs = failDefault || isOpen('dm_duplicateRefFail')
        const flags: ValidationFlags = {
            failBrokenRefs,
            failDuplicateRefs
        }

        const referenceValidators = createReferenceValidators(apis, flags)
        _.assign(validators, referenceValidators)

        return validators
    }

    const createExtensionAPI = ({dal}: CreateExtArgs): RelationshipsAPI => ({
        relationships: {
            getIdFromRef,
            extractReferences: dal.schema.getReferences,
            isReferenced: (pointer: Pointer, namespace?: string) => isReferenced(dal, pointer, namespace),
            getReferencesToPointer: (pointer: Pointer, namespace?: string) =>
                getReferencesToPointer(dal, pointer, namespace),
            getOwningReferencesToPointer: (pointer: Pointer, namespace?: string) =>
                getOwningReferencesToPointer(dal, pointer, namespace),
            registerCustomRefValidation: (validator: CustomRefValidation) => customRefValidations.push(validator),
            getReferredPointers: (pointer, shouldValidate) =>
                getReferredPointers(dal, pointer.type, dal.get(pointer), shouldValidate),
            getReferencesToPointerIndex: (pointer: Pointer, namespace?: string) =>
                getReferencesToPointerIndex(dal, pointer, namespace)
        }
    })

    return {
        name: 'relationships',
        dependencies: new Set([]),
        createExtensionAPI,
        createFilters,
        createValidator,
        createRebaseValidator: (dmApis: DmApis) =>
            createReferenceValidators(dmApis, {
                failBrokenRefs: true,
                failDuplicateRefs: true
            })
    }
}

export interface RelationshipsAPI extends ExtensionAPI {
    relationships: {
        getIdFromRef(ref: string): string
        extractReferences(namespace: string, value: DalValue): readonly ResolvedReference[]
        isReferenced(pointer: Pointer, namespace?: string): boolean
        getReferencesToPointer(pointer: Pointer, namespace?: string): Pointer[]
        getOwningReferencesToPointer(pointer: Pointer, namespace?: string): Pointer[]
        getReferredPointers(pointer: Pointer, shouldValidate?: boolean): Pointer[]
        registerCustomRefValidation(validator: CustomRefValidation): void
        getReferencesToPointerIndex(pointer: Pointer, namespace?: string): Record<string, any>
    }
}
export {createExtension}
