import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import popupUtils from '../page/popupUtils'
import menuUtils from './menuUtils'

function setItemData(ps: PS, itemId: string, data) {
    const itemPointer = menuUtils.getMenuDataItemPointer(ps, itemId)
    const currentData = ps.dal.get(itemPointer)

    if (!currentData) {
        throw new Error(`Cannot update item with "${itemId}", it does not exist`)
    }

    dataModel.setDataItemByPointer(ps, itemPointer, _.assign(currentData, data), 'data')
}

/**
 * Set menu item's visibility
 *
 * @param ps
 * @param itemId item id to change
 * @param hideItem should this menu item be hidden
 * @param hideItemMobile should this menu item be hidden on mobile devices
 */
function setItemVisibility(ps: PS, itemId: string, hideItem: boolean, hideItemMobile: boolean) {
    setItemData(ps, itemId, {isVisible: !hideItem, isVisibleMobile: !hideItemMobile})
}

/**
 * Set menu item's label
 *
 * @param {ps} ps
 * @param {string} itemId item id to change
 * @param {string} label the label to set
 */
function setItemLabel(ps: PS, itemId: string, label: string) {
    setItemData(ps, itemId, {label})
}

/**
 * Determine if a menu item can be explicitly removed
 *
 * @param {ps} ps
 * @param {string} itemId
 * @returns {boolean} true if link item or header item, false if page item
 */
function isItemRemovable(ps: PS, itemId: string) {
    return getItemType(ps, itemId) !== constants.MENU_ITEM_TYPES.PAGE
}

function isPageLink(ps: PS, linkData) {
    const pageId = linkData.pageId && menuUtils.sanitizeHash(linkData.pageId)
    return linkData.type === 'PageLink' && !popupUtils.isPopup(ps, pageId)
}

function updateNonPageLinkItem(ps: PS, itemId: string, linkData) {
    if (isPageLink(ps, linkData)) {
        throw new Error('Explicitly setting a LinkItem of type "PageLink" is not allowed')
    }

    const linkId = menuUtils.getLinkIdByMenuItemId(ps, itemId)
    if (!linkId) {
        throw new Error(`Cannot update item with "${itemId}", link does not exist`)
    }
    const linkItemPointer = menuUtils.getMenuDataItemPointer(ps, linkId)

    dataModel.setDataItemByPointer(
        ps,
        linkItemPointer,
        _.assign(linkData, {id: menuUtils.sanitizeHash(linkId)}),
        'data'
    )
}

function getItemType(ps: PS, itemId: string) {
    const linkedItemId = menuUtils.getLinkIdByMenuItemId(ps, itemId)

    if (!linkedItemId) {
        return constants.MENU_ITEM_TYPES.HEADER
    }

    const linkedItemPointer = menuUtils.getMenuDataItemPointer(ps, linkedItemId)
    const linkedItemType = ps.dal.get(ps.pointers.getInnerPointer(linkedItemPointer, 'type'))

    if (linkedItemType === 'PageLink') {
        return constants.MENU_ITEM_TYPES.PAGE
    }

    return constants.MENU_ITEM_TYPES.LINK
}

export default {
    /**
     * Set menu item's visibility
     *
     * @param {string} itemId item id to change
     * @param {boolean} hideItem should this menu item be hidden
     * @param {boolean} hideItemMobile should this menu item be hidden on mobile devices
     */
    setItemVisibility,
    /**
     * Determine if a menu item can be explicitly removed
     *
     * @param {string} itemId
     * @returns {boolean} true if link item or header item, false if page item
     */
    isItemRemovable,
    /**
     * Set menu item's label
     *
     * @param {string} itemId item id to change
     * @param {string} label the label to set
     */
    setItemLabel,
    /**
     * Returns the item type
     *
     * @param {string} itemId
     * @returns {string} one of [header, link, page]
     */
    getItemType,
    /**
     * Updates link of existing link menu item
     */
    updateLinkItem: updateNonPageLinkItem,
    isPageLink
}
