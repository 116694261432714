import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import siteMetadataPropertiesInfo from '../pathHelpers/siteMetadataPropertiesInfo'
import {log} from '@wix/santa-core-utils'

const PROPERTY_INFO = siteMetadataPropertiesInfo
// the paths are NOT exposed to the consumer
const PROPERTY_NAMES = _.mapValues(PROPERTY_INFO, (info, prop) => prop)

function getRevertibleMetaDataInfo() {
    return _.filter(PROPERTY_INFO, {revertible: true})
}

function getNonRevertibleMetaDataInfo() {
    return _.filter(PROPERTY_INFO, {revertible: false})
}

/**
 * get a siteMetaData param value
 * @param ps
 * @param param one of the keys in the PROPERTY_NAMES exported enum
 * @return {*} the value of that param from the data
 */
function getProperty(ps: PS, param: string) {
    const pointer = ps.pointers.metadata.getSiteMetaDataPointer(param)
    if (!pointer) {
        log.error('SiteMetaData getProperty was called with an invalid metadata property')
        return null
    }
    return ps.dal.get(pointer)
}

/**
 * set a site metadata parameter
 * @param ps
 * @param param a key from the exported PROPERTY_NAMES enum
 * @param value the value to be set for the parameter
 */
function setProperty(ps: PS, param: string, value: any) {
    const pointer = ps.pointers.metadata.getSiteMetaDataPointer(param)
    if (!pointer) {
        log.error('SiteMetaData setProperty was called with an invalid metadata property')
        return
    }
    ps.dal.set(pointer, value)
}

export default {
    getRevertibleMetaDataInfo,
    getNonRevertibleMetaDataInfo,
    getProperty,
    setProperty,
    PROPERTY_NAMES
}
