import _ from 'lodash'

const DEFAULT_BTT_OVERRIDE_COLORS = {
    color1: 'color_11',
    color2: 'color_15',
    color3: 'color_13'
}

const LEGACY_MIGRATION_OVERRIDE_COLORS = {
    color1: '#000000',
    color2: '#FFFFFF'
}

const getBackToTopButtonDefinition = (isLegacyMigration, dockedOverrides = {}) => ({
    layout: {
        fixedPosition: true,
        width: 44,
        height: 44,
        scale: 1,
        rotationInDegrees: 0,
        docked: _.defaults({}, dockedOverrides, {
            bottom: {
                px: 20
            },
            right: {
                px: 20
            }
        }),
        y: 522,
        x: 262
    },
    componentType: 'wysiwyg.viewer.components.BackToTopButton',
    type: 'Component',
    skin: 'skins.viewer.VectorImageSkin',
    data: {
        type: 'VectorImage',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        },
        svgId: isLegacyMigration
            ? 'a3c153_64191a64b70a446fb44c9be3fce6df77.svg'
            : 'a3c153_8f424995247e4e4b9083564be3aa7c01.svg',
        link: {
            type: 'AnchorLink',
            anchorDataId: 'SCROLL_TO_TOP',
            pageId: '#masterPage',
            anchorName: 'Top of Page',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            }
        }
    },
    props: {
        type: 'VectorImageProperties',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            autoGenerated: false
        },
        displayMode: 'fit'
    },
    behaviors: {
        type: 'ObsoleteBehaviorsList',
        items: '[{"name":"FadeIn","delay":0,"duration":0.5,"action":"backToTopIn","targetId":"","viewMode":"MOBILE","params":{}}]',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        }
    },
    design: {
        type: 'VectorImageDesignData',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        },
        overrideColors: isLegacyMigration ? LEGACY_MIGRATION_OVERRIDE_COLORS : DEFAULT_BTT_OVERRIDE_COLORS,
        shapeStyle: {
            opacity: isLegacyMigration ? 0.6 : 1
        }
    },
    style: {
        type: 'ComponentStyle',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        },
        style: {
            groups: {},
            properties: {},
            propertiesSource: {}
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin'
    },
    connections: {
        type: 'ConnectionList',
        items: [
            {
                type: 'WixCodeConnectionItem',
                role: 'backToTop'
            }
        ],
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        }
    },
    activeModes: {}
})

export default {
    getBackToTopButtonDefinition
}
