import type {SnapshotDal} from '@wix/document-manager-core'
import experiment from 'experiment-amd'
import wixCodeClientSpecMapUtils from '../utils/clientSpecMapUtils'
import wixCodeConstants from '../utils/constants'
import wixCodeErrors from '../utils/errors'
import utils from '../utils/utils'
import filesDAL from './filesDAL'
import wixCodeMonitoring from './wixCodeMonitoringWrapper'
import markAppImmutable from './markAppImmutable'

const getGridAppForRevision = async ({
    wixCodeApp,
    revisionGridAppId,
    openGridAppId,
    serviceTopology,
    isFirstSave,
    metasiteId,
    isAppReadOnly,
    traceFunc,
    bi
}) => {
    if ((!revisionGridAppId && !openGridAppId) || !wixCodeApp) {
        return null
    }

    if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
        const wixCodeServiceUrl = serviceTopology[wixCodeConstants.WIX_CODE_SERVICE_URL_KEY]

        if (isFirstSave) {
            try {
                await markAppImmutable.runUsingSnapshot({
                    traceFunc,
                    isAppReadOnly,
                    wixCodeApp,
                    baseUrl: wixCodeServiceUrl,
                    metasiteId,
                    gridAppId: revisionGridAppId
                })
            } catch (serverError) {
                const error = new wixCodeErrors.WixCodeMarkImmutableError({serverError})
                wixCodeErrors.sendBiError(bi, error)
                throw error
            }
            return revisionGridAppId
        }

        return
    }

    return revisionGridAppId
}

const runUsingSnapshotDal = async (snapshotDal: SnapshotDal, bi) => {
    const wixCodeApp = wixCodeClientSpecMapUtils.getExistingWixCodeAppFromSnapshot(snapshotDal)
    const openGridAppId = utils.extractFromSnapshotDal(snapshotDal, wixCodeConstants.paths.OPEN_WIX_CODE_APP_ID)
    const revisionGridAppId = utils.extractFromSnapshotDal(snapshotDal, wixCodeConstants.paths.REVISION_GRID_APP_ID)

    const isAppReadOnly = snapshotDal.getValue({
        type: 'wixCode',
        id: 'wixCode',
        innerPath: ['nonUndoable', 'isAppReadOnly']
    })
    const serviceTopology = snapshotDal.getValue({
        type: 'serviceTopology',
        id: 'serviceTopology'
    })
    const isFirstSave = snapshotDal.getValue({
        type: 'documentServicesModel',
        id: 'neverSaved'
    })
    const metasiteId = snapshotDal.getValue({
        type: 'rendererModel',
        id: 'metaSiteId'
    })
    const traceFunc = params => wixCodeMonitoring.traceWithSnapshot(null, params, snapshotDal)

    return getGridAppForRevision({
        wixCodeApp,
        revisionGridAppId,
        openGridAppId,
        serviceTopology,
        isFirstSave,
        metasiteId,
        isAppReadOnly,
        traceFunc,
        bi
    })
}
const runUsingSnapshot = async (snapshot, bi) => {
    const wixCodeApp = wixCodeClientSpecMapUtils.getExistingWixCodeAppFromSnapshot(snapshot)
    const revisionGridAppId = snapshot.getIn(wixCodeConstants.paths.REVISION_GRID_APP_ID)
    const openGridAppId = snapshot.getIn(wixCodeConstants.paths.OPEN_WIX_CODE_APP_ID)
    const isAppReadOnly = snapshot.getIn(wixCodeConstants.paths.IS_APP_READ_ONLY)
    const serviceTopology = snapshot.getIn('serviceTopology')
    const metasiteId = snapshot.getIn(['rendererModel', 'metaSiteId'])
    const isFirstSave = snapshot.getIn(['documentServicesModel', 'neverSaved'])

    const traceFunc = params => wixCodeMonitoring.traceWithSnapshot(snapshot, params)
    return getGridAppForRevision({
        wixCodeApp,
        revisionGridAppId,
        openGridAppId,
        serviceTopology,
        isFirstSave,
        metasiteId,
        isAppReadOnly,
        traceFunc,
        bi
    })
}

const shouldGridAppChangeBySnapshot = (lastSnapshot, currentSnapshot) => {
    const changes = filesDAL.getChangesBetweenSnapshots(lastSnapshot, currentSnapshot)
    return !filesDAL.isChangesEmpty(changes)
}

const shouldGridAppChangeBySnapshotDal = (pointers, lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal) => {
    const changes = filesDAL.getChangesBetweenSnapshotDals(pointers, lastSnapshotDal, currentSnapshotDal)
    return !filesDAL.isChangesEmpty(changes)
}

export default {
    runUsingSnapshotDal,
    runUsingSnapshot,
    shouldGridAppChangeBySnapshot,
    shouldGridAppChangeBySnapshotDal
}
