export type QueueFunction = (() => void) | (() => Promise<void>)

export interface AsyncQueue {
    add(processItem: QueueFunction): Promise<void>

    /**
     * Returns a promise that can be awaited for
     */
    toBeEmpty(): Promise<void> | null

    getSize(): number
}

const createQueue = (): AsyncQueue => {
    const queue: QueueFunction[] = []
    let running: Promise<void> | null = null

    const run = async () => {
        while (queue.length > 0) {
            const processItem = queue.shift()
            await processItem?.()
        }
        running = null
    }

    const add = (processItem: QueueFunction): Promise<void> => {
        queue.push(processItem)
        if (!running) {
            running = run()
        }

        return running
    }

    const getSize = () => queue.length

    const toBeEmpty = () => running

    return {
        add,
        toBeEmpty,
        getSize
    }
}

export {createQueue}
