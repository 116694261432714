import _ from 'lodash'
import type {DalValue, DocumentManager, DAL} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'

const wrapAsPages = (dal: DAL, pointer: Pointer, value: DalValue) => {
    if (value && !value.pages) {
        dal.set(pointer, {pages: value})
    }
}
const itemsToWrap = [
    {
        pointer: {type: 'rendererModel', id: 'passwordProtectedPages'},
        wrap: wrapAsPages
    },
    {
        pointer: {type: 'rendererModel', id: 'pageToHashedPassword'},
        wrap: wrapAsPages
    }
]

// TODO - migrateSite when experiment is closed and api is available
const migrateMasterPage = (documentManager: DocumentManager) => {
    _.forEach(itemsToWrap, ({pointer, wrap}) => {
        const value = documentManager.dal.get(pointer)
        wrap(documentManager.dal, pointer, value)
    })
}

const name = 'wrapMapsForSignatures'
const version = 1

export {migrateMasterPage, name, version}
