import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {Pointer, RoutersDefinition} from '@wix/document-services-types'
import _ from 'lodash'
import type {PageExtensionAPI} from './page'

export interface RouterUpdateData {
    prefix?: string
    config?: any
    hide?: boolean
    roleVariations?: Record<string, string[]>
}

export interface RoutersAPI extends ExtensionAPI {
    routers: {
        getAllRouters(): {[routerId: string]: RoutersDefinition}
        updateRouter(routerPtr: Pointer, updateData: RouterUpdateData): void
        isDynamicPage(pageId: string): boolean
        getRouterByPage(pageId: string): RoutersDefinition | undefined
        removePageFromRoutersConfigMap(pageId: string): void
    }
}

const EVENTS = {
    ROUTERS: {
        BEFORE_UPDATE: 'ROUTER_BEFORE_UPDATE'
    }
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers, extensionAPI, eventEmitter}: CreateExtArgs): RoutersAPI => {
        const {page} = extensionAPI as PageExtensionAPI

        const getAllRouterPrefixes = () => {
            const routersPointer = pointers.routers.getRoutersConfigMapPointer()
            return _.map(dal.get(routersPointer), 'prefix')
        }

        const isPrefixExist = (prefix: string) => {
            const allRoutersPrefixes = getAllRouterPrefixes()
            return _.includes(allRoutersPrefixes, prefix)
        }

        const isPageUriSEOExist = (prefix: string) => {
            const allPageIds = page.getAllPagesIds(true)
            const allPageUriSEO = _.map(allPageIds, function (pageId) {
                return page.getPageUriSEO(pageId)
            })

            return _.includes(allPageUriSEO, prefix)
        }

        const validateUpdatedRouter = (updateRouterData: RouterUpdateData) => {
            if (isPrefixExist(updateRouterData.prefix!)) {
                throw new Error(`Router not valid - Prefix: ${updateRouterData.prefix}, already exist`)
            }

            if (isPageUriSEOExist(updateRouterData.prefix!)) {
                throw new Error(`Router not valid - Page Uri SEO: ${updateRouterData.prefix}, already exist.`)
            }
        }

        const updateRouter = (routerPtr: Pointer, updateData: RouterUpdateData) => {
            const routerData = _.cloneDeep(dal.get(routerPtr))
            if (updateData.prefix && updateData.prefix !== routerData.prefix) {
                validateUpdatedRouter(updateData)
                routerData.prefix = updateData.prefix
            }

            if (updateData.config) {
                if (_.isObject(updateData.config)) {
                    routerData.config = JSON.stringify(updateData.config)
                } else {
                    routerData.config = updateData.config
                }
            }
            if (_.isBoolean(updateData.hide) && updateData.hide !== routerData.hide) {
                routerData.hide = updateData.hide
            }

            if (updateData.roleVariations) {
                Object.keys(updateData.roleVariations).forEach(roleId => {
                    if (!routerData.pages[roleId]) {
                        throw new Error(`roleId: '${roleId}' not exist in router`)
                    }

                    if (
                        (updateData.roleVariations?.[roleId] ?? []).find(
                            variationRole => !routerData.pages[variationRole]
                        )
                    ) {
                        throw new Error(`one or more of the variations of roleId: '${roleId}' are not exist in router`)
                    }
                })

                routerData.roleVariations = updateData.roleVariations
            }

            eventEmitter.emit(EVENTS.ROUTERS.BEFORE_UPDATE, routerPtr, routerData)
            dal.set(routerPtr, routerData)
        }

        const getAllRouters = () => {
            const routersConfigMapPointer = pointers.routers.getRoutersConfigMapPointer()
            return _.cloneDeep(dal.get(routersConfigMapPointer))
        }
        const isDynamicPage = (pageId: string) => {
            const routersConfigMapPointer = pointers.routers.getRoutersConfigMapPointer()
            const routers = dal.get(routersConfigMapPointer)
            return _.some(routers, routerData => _.includes(_.values(routerData.pages), pageId))
        }

        const getRouterByPage = (pageId: string) => {
            const routersConfigMapPointer = pointers.routers.getRoutersConfigMapPointer()
            const allRouters = dal.get(routersConfigMapPointer)
            const routerId = _.findKey(allRouters, function (router) {
                return router.pages && _.includes(_.values(router.pages), pageId)
            })
            const routerPointer = routerId && pointers.routers.getRouterPointer(routerId)
            return routerPointer && _.clone(dal.get(routerPointer))
        }

        const removePageFromRoutersConfigMap = (pageId: string): void => {
            const configMapPointer = pointers.routers.getRoutersConfigMapPointer()
            type ConfigMap = Record<string, {pages: Record<string, string>}>
            dal.modify<ConfigMap>(configMapPointer, map =>
                _.mapValues(map, router => ({
                    ...router,
                    pages: _.omitBy(router.pages, value => value === pageId)
                }))
            )
        }

        return {
            routers: {
                updateRouter,
                getAllRouters,
                isDynamicPage,
                getRouterByPage,
                removePageFromRoutersConfigMap
            }
        }
    }

    return {
        name: 'routers',
        dependencies: new Set(['rendererModel', 'page']),
        createExtensionAPI,
        EVENTS
    }
}

export {createExtension}
