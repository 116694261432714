import _ from 'lodash'
import layout from './util/layout'
import rootLayoutUtils from './util/rootLayoutUtils'
import screenWidthContainerLayout from './specificComponents/screenWidthLayouts/screenWidthContainerLayout'
import singleCompLayout from './util/singleCompLayout'
import matrixGalleryLayout from './specificComponents/matrixGalleryLayout'
import paginatedGridGalleryLayout from './specificComponents/paginatedGridGalleryLayout'
import richtextLayout from './specificComponents/richtextLayout'
import slideShowLayout from './specificComponents/slideShowLayout'
import sliderGalleryLayout from './specificComponents/sliderGalleryLayout'
import wPhotoLayout from './specificComponents/wPhotoLayout'
import imageLayout from './specificComponents/imageLayout'
import createDOMPatchers from './util/createDOMPatchers'
import spaceCalculationsRegardingWixAds from './util/spaceCalculationsRegardingWixAds'
import menuLayout from './specificComponents/menuLayout'
import verticalMenuLayout from './specificComponents/verticalMenuLayout'

verticalMenuLayout.register(layout)
menuLayout.register(layout)

export default _.assign(layout, {
    singleCompLayout,
    rootLayoutUtils,
    spaceCalculationsRegardingWixAds,
    createDOMPatchers,
    specificComponents: {
        screenWidthContainerLayout,
        matrixGalleryLayout,
        paginatedGridGalleryLayout,
        richtextLayout,
        slideShowLayout,
        sliderGalleryLayout,
        wPhotoLayout,
        imageLayout,
        menuLayout: {
            dropDown: {
                measure: menuLayout.measure,
                patch: menuLayout.patch
            },
            vertical: {
                measure: verticalMenuLayout.measure,
                patch: verticalMenuLayout.patch
            }
        }
    }
})
