export default {
    WidgetInstallationTypes: {
        OPEN: 'open',
        CLOSED: 'closed'
    },
    ROLE_PATH: {
        ROOT: '_ROOT_',
        DELIMITER: '_ROLE_',
        REPEATED_COMP_DELIMITER: '_ITEM_'
    },
    LIVE_PREVIEW_REFRESH_SOURCE: 'AFTER_PROP_UPDATE'
}
