import type {SnapshotDal} from '@wix/document-manager-core'
import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import {urlUtils} from '@wix/santa-core-utils'
import clientSpecMap from '../siteMetadata/clientSpecMap'
import dataManipulation from '../siteMetadata/dataManipulation'
import platformConstants from '../platform/common/constants'
import {contextAdapter} from '../utils/contextAdapter'
import {deepClone} from '@wix/wix-immutable-proxy'

const createUrlTemplate =
    (topologyEntry: string, path: string, withSiteId?: boolean) =>
    ({serviceTopology, siteId}) =>
        `${serviceTopology[topologyEntry]}${path}${withSiteId ? `/${siteId}` : ''}`

const MAPPINGS = {
    PARTIAL_SAVE: {
        urlBuilder: createUrlTemplate('editorServerRoot', '/api/partially_update')
    },
    OVERRIDE_SAVE: {
        urlBuilder: createUrlTemplate('editorServerRoot', '/api/new_override_save')
    },
    VALIDATE: {
        urlBuilder: createUrlTemplate('editorServerRoot', '/api/validate_site')
    },
    FIRST_SAVE: {
        urlBuilder: createUrlTemplate('editorServerRoot', '/api/first_save')
    },
    SAVE_AS_TEMPLATE: {
        urlBuilder: createUrlTemplate('editorServerRoot', '/api/save_as_template', true)
    },
    PUBLISH: {
        urlBuilder: createUrlTemplate('editorServerRoot', '/api/publish', true)
    },
    PUBLISH_RC: {
        urlBuilder: createUrlTemplate('editorRootUrl', '/html/v2/editor/ap1/publish_site_rc')
    },
    PUBLISH_TEST_SITE: {
        urlBuilder: ({serviceTopology, metaSiteId}) =>
            `${serviceTopology.editorServerRoot}/${'_api/release-manager-server/v1/revisions/'}${metaSiteId}/create-rc`
    },
    PUBLISH_WITH_OVERRIDES: {
        urlBuilder: ({serviceTopology}) => `${serviceTopology.editorRootUrl}/_api/editor-deployments/v1/deployments`
    },
    PUBLISHED_SITE_DETAILS: {
        urlBuilder: ({serviceTopology}) =>
            `${serviceTopology.editorRootUrl}/_api/wix-public-html-info-webapp/v1/published-site-details`,
        requestType: 'GET'
    },
    PASSWORD_PROTECTION: {
        urlBuilder: createUrlTemplate('editorServerRoot', '/api/page_protection/new'),
        forceHTTPS: true
    },
    PASSWORD_PROTECTION_DUPLICATE: {
        urlBuilder: createUrlTemplate('editorServerRoot', '/api/page_protection/duplicate'),
        forceHTTPS: true
    },
    GET_FREE_SITE_NAME: {
        urlBuilder: createUrlTemplate('htmlEditorRootUrl', '/meta-site-proxy/get-free-site-name'),
        requestType: 'GET',
        ignoreAuthorizationHeader: true
    },
    IS_SITE_NAME_FREE: {
        urlBuilder: createUrlTemplate('htmlEditorRootUrl', '/meta-site-proxy/is-site-name-free'),
        requestType: 'GET',
        ignoreAuthorizationHeader: true
    },
    GET_APPS: {
        urlBuilder: createUrlTemplate('editorRootUrl', '/_api/app-service/v1/apps'),
        requestType: 'GET',
        ignoreAuthorizationHeader: true
    },
    GET_APPS_NEW: {
        urlBuilder: createUrlTemplate('editorRootUrl', '/apps-service/v1/apps'),
        requestType: 'POST'
    },
    SITE_PROPERTIES_INFO: {
        urlBuilder: () => '/_api/site-properties-service/properties',
        requestType: 'GET'
    },
    SETTLE: {
        urlBuilder: createUrlTemplate('htmlEditorRootUrl', '/settle'),
        requestType: 'POST'
    },
    SETTLE_AND_CONDUCT: {
        urlBuilder: createUrlTemplate('editorRootUrl', 'html/v1/modifySiteApps'),
        requestType: 'POST'
    },
    LIST_BRANCHES: {
        urlBuilder: createUrlTemplate('editorRootUrl', '/_api/site-branches-registry/v1/site-branches'),
        requestType: 'GET'
    }
}

const ENDPOINTS = _.mapValues(MAPPINGS, (v, k) => k)

/**
 *
 * @param {string} endpoint
 * @param {ServerRequestContextOptions} serverRequestContextOptions
 * @param {object} additionalQueryParams additional query parameters
 * @return {string}
 */
const getURL = (endpoint: string, serverRequestContextOptions, additionalQueryParams) => {
    const {serviceTopology, siteId, metaSiteId, editorSessionId} = serverRequestContextOptions
    const {urlBuilder, queryParams = [], forceHTTPS} = MAPPINGS[endpoint]
    let url = urlBuilder({
        serviceTopology,
        siteId,
        metaSiteId
    })
    if (forceHTTPS) {
        url = url.replace('http://', 'https://')
    }

    const serverContextQueryParams = _.without(queryParams, _.keys(additionalQueryParams)).reduce((acc, param) => {
        // @ts-expect-error
        acc[param] = serverRequestContextOptions[param]
        return acc
    }, {})

    const queryString = urlUtils.toQueryString({
        metaSiteId,
        editorSessionId,
        esi: editorSessionId,
        ...serverContextQueryParams,
        ...additionalQueryParams
    })

    return `${url}?${queryString}`
}

const buildEditorServerHeaders = ({editorSessionId, origin, signedInstance}) => ({
    Authorization: signedInstance,
    'X-Wix-Editor-Version': 'new',
    'X-Wix-DS-Origin': origin,
    'X-Editor-Session-Id': editorSessionId
})

interface ServerRequestContextOptions {
    serviceTopology: any
    signedInstance: string
    siteVersion: string
    origin: string
    siteId: string
    metaSiteId: string
    editorSessionId: string
}

/**
 *
 * @param serverRequestContextOptions
 * @param endpoint
 * @param data?
 * @param {function} onSuccess
 * @param {function} onError
 */
const send = (
    serverRequestContextOptions: ServerRequestContextOptions,
    endpoint: string,
    data?: any,
    onSuccess?,
    onError?
) => {
    try {
        const {editorSessionId, origin, signedInstance} = serverRequestContextOptions
        const {requestType = 'POST', ignoreAuthorizationHeader = false} = MAPPINGS[endpoint]

        const additionalQueryParams = requestType === 'GET' ? data : {}
        const url = getURL(endpoint, serverRequestContextOptions, additionalQueryParams)

        // @ts-expect-error
        contextAdapter.utils.fedopsLogger.breadcrumb('server request', {endpoint, url})

        const headers = buildEditorServerHeaders({editorSessionId, origin, signedInstance})
        const jsonData: any = {
            dataType: 'json',
            // @ts-expect-error
            headers: ignoreAuthorizationHeader ? _.without(headers, ['Authorization']) : headers
        }
        if (!ignoreAuthorizationHeader) {
            delete jsonData.headers.Authorization
            jsonData.appIdAutoAuth = platformConstants.APPS.META_SITE.applicationId
        }
        if (requestType === 'POST') {
            jsonData.xhrFields = {
                withCredentials: true
            }
        }

        const bodyData = requestType === 'POST' ? data : null

        contextAdapter.actions.sendHttpRequest(url, requestType, jsonData, bodyData, onSuccess, onError)
    } catch (err) {
        onError(err)
        throw err
    }
}

const sendAsync = (serverRequestContextOptions: ServerRequestContextOptions, endpoint: string, data: any) =>
    new Promise((res, rej) => send(serverRequestContextOptions, endpoint, data, res, rej))

const getEditorServerOptionsFromPS = (ps: PS): ServerRequestContextOptions => ({
    serviceTopology: ps.dal.get(ps.pointers.general.getServiceTopology()),
    metaSiteId: dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.META_SITE_ID),
    siteId: dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SITE_ID),
    siteVersion: dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.SITE_VERSION),
    editorSessionId: dataManipulation.getProperty(ps, dataManipulation.PROPERTY_NAMES.EDITOR_SESSION_ID),
    origin: ps.config.origin,
    signedInstance: clientSpecMap.getAppData(ps, platformConstants.APPS.META_SITE.applicationId).instance
})

const getEditorServerOptionsFromSnapshot = (snapshot: any): ServerRequestContextOptions => ({
    serviceTopology: snapshot.get('serviceTopology').toJS(),
    metaSiteId: snapshot.getIn(['rendererModel', 'metaSiteId']),
    siteId: snapshot.getIn(['rendererModel', 'siteInfo', 'siteId']),
    siteVersion: snapshot.getIn(['documentServicesModel', 'version']),
    editorSessionId: snapshot.getIn(['documentServicesModel', 'editorSessionId']),
    origin: snapshot.get('origin'),
    signedInstance: snapshot.getIn(['rendererModel', 'clientSpecMap', '-666', 'instance'])
})

const getEditorServerOptionsFromSnapshotDal = (
    snapshotDal: SnapshotDal,
    editorOrigin: string
): ServerRequestContextOptions => ({
    serviceTopology: deepClone(snapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'})),
    metaSiteId: snapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'}),
    siteId: snapshotDal.getValue({type: 'rendererModel', id: 'siteInfo', innerPath: 'siteId'}),
    siteVersion: snapshotDal.getValue({type: 'documentServicesModel', id: 'version'}),
    editorSessionId: snapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'}),
    origin: editorOrigin,
    signedInstance: snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap', innerPath: ['-666', 'instance']})
})

const sendWithPs = (ps: PS, endpoint: string, data, onSuccess, onError) =>
    send(getEditorServerOptionsFromPS(ps), endpoint, data, onSuccess, onError)

const sendWithPsAsync = (ps: PS, endpoint: string, data) =>
    new Promise((res, rej) => sendWithPs(ps, endpoint, data, res, rej))

const sendWithImmutable = (snapshot, endpoint: string, data, onSuccess, onError) => {
    const serverOptions = getEditorServerOptionsFromSnapshot(snapshot)
    return send(serverOptions, endpoint, data, onSuccess, onError)
}

const sendWithImmutableAsync = (snapshot, endpoint: string, data) =>
    new Promise((res, rej) => sendWithImmutable(snapshot, endpoint, data, res, rej))

const sendWithSnapshotDal = (
    snapshotDal: SnapshotDal,
    endpoint: string,
    data,
    onSuccess,
    onError,
    editorOrigin?: string
) => {
    const serverOptions = getEditorServerOptionsFromSnapshotDal(snapshotDal, editorOrigin)
    return send(serverOptions, endpoint, data, onSuccess, onError)
}

const sendWithSnapshotDalAsync = async (snapshotDal: SnapshotDal, endpoint: string, data, editorOrigin?: string) => {
    const serverOptions = getEditorServerOptionsFromSnapshotDal(snapshotDal, editorOrigin)
    return await sendAsync(serverOptions, endpoint, data)
}

export default {
    ENDPOINTS,
    sendWithPs,
    sendWithPsAsync,
    sendWithImmutable,
    sendWithImmutableAsync,
    sendWithSnapshotDal,
    sendWithSnapshotDalAsync
}
