import type {CompRef, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as jsonSchemas from '@wix/document-services-json-schemas'
import dataModel from '../dataModel/dataModel'
import dataSerialization from '../dataModel/dataSerialization'
import variantsUtils from '../variants/variantsUtils'
import {updateConsideringOverrides} from '../overrides/overrides'

const {
    namespaceMapping: {getNamespaceConfig}
} = jsonSchemas

/**
 *
 * @param ps
 * @param compPointer
 * @param namespace
 * @param dataItem
 * @returns the dataItemId of the updated data
 */
function updateNamespaceData(ps: PS, compPointer: Pointer, namespace: string, dataItem: any): string {
    const config = getNamespaceConfig(namespace)
    if (config.supportsVariants) {
        const type = dataItem.type || namespace
        const dataItemWithType = _.assign(dataItem, {type})
        return updateConsideringOverrides(ps, compPointer as CompRef, dataItemWithType, namespace)
    }
    return updateSingleNamespaceData(ps, compPointer, namespace, dataItem, config.query)
}

/**
 *
 * @param ps
 * @param compPointer
 * @param namespace
 * @param dataItem
 * @param queryName
 * @returns The dataItemId of the updated dataItem
 */
function updateSingleNamespaceData(
    ps: PS,
    compPointer: Pointer,
    namespace: string,
    dataItem: any,
    queryName: string
): string {
    dataItem.type = dataItem.type || namespace
    let dataItemId = dataModel.getComponentDataItemId(ps, compPointer, queryName)

    const pageId = ps.pointers.full.components.getPageOfComponent(compPointer).id
    const doesDataItemExists = Boolean(dataItemId)

    dataItemId = dataSerialization.addSerializedFeatureItemToPage(ps, pageId, dataItem, namespace, dataItemId)
    if (!doesDataItemExists) {
        dataModel.linkComponentToItemByType(ps, compPointer, dataItemId, namespace)
    }

    return dataItemId
}

/**
 * Get the components' namespace data
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {String} namespace
 * @returns
 */
function getNamespaceData(ps: PS, componentPointer: Pointer, namespace: string) {
    const config = getNamespaceConfig(namespace)
    if (config.supportsVariants) {
        return variantsUtils.getComponentDataConsideringVariants(ps, componentPointer, namespace)
    }
    return dataModel.getComponentDataItemByType(ps, componentPointer, namespace)
}

function removeComponentNamespaceData(ps: PS, componentPointer: Pointer, namespace: string) {
    const config = getNamespaceConfig(namespace)
    if (config.supportsVariants) {
        variantsUtils.removeComponentDataConsideringVariants(ps, componentPointer, namespace)
    } else {
        removeComponentSingleNamespaceData(ps, componentPointer, namespace)
    }
}

function removeComponentSingleNamespaceData(ps: PS, componentPointer: Pointer, namespace: string) {
    const desktopComponentPointer = ps.pointers.full.components.getDesktopPointer(componentPointer)
    const mobileComponentPointer = ps.pointers.full.components.getMobilePointer(componentPointer)
    const {query} = getNamespaceConfig(namespace)

    _.forEach([desktopComponentPointer, mobileComponentPointer], compPointer => {
        if (ps.dal.full.isExist(compPointer)) {
            const queryItemPointer = ps.pointers.getInnerPointer(compPointer, query)

            const itemToRemove = dataModel.getComponentDataPointerByType(ps, compPointer, namespace)
            if (ps.dal.full.isExist(itemToRemove)) {
                ps.dal.full.remove(itemToRemove)
            }

            ps.dal.full.remove(queryItemPointer)
        }
    })
}

export default {
    getNamespaceData,
    updateNamespaceData,
    removeComponentNamespaceData
}
