import type {SnapshotDal} from '@wix/document-manager-core'
import type {BIEvents} from '@wix/document-services-types'
import type {SaveTaskDefinition} from '../../saveAPI/lib/registry'
import sitePropertiesInfo from '../sitePropertiesInfo'

const TASK_NAME = 'reloadSiteProperyInfoAfterFirstSave'

const {fetchSitePropertiesInfoWithSnapshotDal} = sitePropertiesInfo

const onSuccess = (resolve, response) => {
    resolve({
        changes: [
            {
                path: ['rendererModel', 'sitePropertiesInfo', 'language'],
                value: response.language
            },
            {
                path: ['rendererModel', 'sitePropertiesInfo', 'currency'],
                value: response.paymentCurrency
            },
            {
                path: ['rendererModel', 'sitePropertiesInfo', 'timeZone'],
                value: response.timeZone
            }
        ]
    })
}

const createReloadSitePropertiesInfo =
    () =>
    (
        lastSnapshot,
        currentSnapshot,
        resolve,
        reject,
        bi: BIEvents,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal
    ) => {
        const orError = resolve
        const {editorOrigin} = options
        fetchSitePropertiesInfoWithSnapshotDal(currentSnapshotDal, onSuccess.bind(null, resolve), orError, editorOrigin)
    }

const createTask = (): SaveTaskDefinition => {
    const reloadSitePropertiesInfo = createReloadSitePropertiesInfo()

    return {
        partialSave(lastSavedData, currentData, resolve) {
            resolve()
        },
        fullSave(lastSavedData, currentData, resolve) {
            resolve()
        },
        firstSave: reloadSitePropertiesInfo,
        saveAsTemplate(lastSavedData, currentData, resolve) {
            resolve()
        },
        publish(currentData, resolve) {
            resolve()
        },
        autosave(lastSnapshot, currentSnapshot, resolve) {
            resolve()
        },
        getTaskName() {
            return TASK_NAME
        },
        getSnapshotTags(methodName) {
            switch (methodName) {
                case 'autosave':
                    return ['autosave']
                default:
                    return ['primary']
            }
        }
    }
}

export default () => createTask()
