import type {MobileHints, Pointer, PS} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import type {MobilePreset} from '@wix/mobile-conversion/src/types'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import _ from 'lodash'
import componentModes from '../../../component/componentModes'
import constants from '../../../constants/constants'
import dataModel from '../../../dataModel/dataModel'
import structureApi from '../../../structure/structure'
import mobileHintsConstants from './mobilePresetsConstants'

const {HINTS_PROPERTIES, OFFSET_DELTA_TO_RESET_COORDINATES, SIZE_DELTA_TO_RESET} = mobileHintsConstants

const hasSizeData = (mobileHints: MobileHints) =>
    mobileHints && _.some(HINTS_PROPERTIES.SIZE_DATA, prop => _.has(mobileHints, prop))
const hasOffsetData = (mobileHints: MobileHints) =>
    mobileHints && _.some(HINTS_PROPERTIES.OFFSET_DATA, prop => _.has(mobileHints, prop))
const hasGeneralData = (mobileHints: MobileHints) =>
    mobileHints && _.some(HINTS_PROPERTIES.GENERAL_PRESET_DATA, prop => _.has(mobileHints, prop))
const isMobileHintsPreset = (mobileHints: MobileHints) => hasSizeData(mobileHints) || hasOffsetData(mobileHints)
const getOrderIndex = (ps: PS, pageId: string, component) =>
    _.get(dataModel.getMobileHintsItemById(ps, component.mobileHintsQuery, pageId), 'orderIndex')
const isOffsetChanged = (newValue, oldValue) => Math.abs(newValue - oldValue) > OFFSET_DELTA_TO_RESET_COORDINATES
const isSizeChanged = (newValue, oldValue) => Math.abs(newValue - oldValue) > SIZE_DELTA_TO_RESET
const shouldRemoveOffset = (newLayout, previousLayout) =>
    isOffsetChanged(newLayout.x, previousLayout.x) || isOffsetChanged(newLayout.y, previousLayout.y)
const shouldRemovePresetSize = (newLayout, previousLayout) =>
    isSizeChanged(newLayout.width, previousLayout.width) || isSizeChanged(newLayout.height, previousLayout.height)
const getComponentStructure = (ps: PS, fullComponentStructure, pageId: string) => {
    const pageActiveModesMap = _.set({}, pageId, componentModes.getMobileActiveModesMap(ps, pageId))
    return coreUtils.fullToDisplayedJson.getDisplayedJson(fullComponentStructure, pageActiveModesMap, pageId).structure
}
const getComponentsMap = (structure, map: Record<string, any> = {}): Record<string, any> => {
    map[structure.id] = structure
    _.forEach(structure.components, childStructure => getComponentsMap(childStructure, map))
    return map
}

const getCommonMobileHints = (preset: MobilePreset): Partial<MobileHints> => {
    const originalCompId = _.get(preset, ['metaData', 'originalCompId'])
    const author = 'studio'
    const {
        layout: {width: recommendedWidth, height: recommendedHeight, scale: recommendedScale}
    } = preset
    const mobileHints: Partial<MobileHints> = {
        recommendedWidth,
        recommendedHeight,
        recommendedScale,
        author
    }
    if (originalCompId) {
        mobileHints.originalCompId = originalCompId
    }
    return mobileHints
}

const filterChildren = (ps: PS, children: Pointer[], mobilePresetsMap: Map<string, any>) =>
    children.reduce((arr, pointer) => {
        if (structureApi.isFixedPosition(ps, pointer)) {
            return arr
        }
        const preset = mobilePresetsMap.get(pointer.id)
        if (!preset) {
            return arr
        }
        return [...arr, preset]
    }, [])

const convertMobilePresetToMobileHints = (
    ps: PS,
    rootPointer: Pointer,
    mobilePresetsMap: Map<string, any>,
    mobileHintsMap: Map<string, any>
) => {
    const children = ps.pointers.components.getChildren(rootPointer)

    if (children) {
        const childrenPresets = filterChildren(ps, children, mobilePresetsMap)
        const sortedPresetsWithOffsetOrigins = mobileCore.mobileHints.sortPresets(childrenPresets)

        sortedPresetsWithOffsetOrigins.forEach(({preset, offsetOrigin}, orderIndex) => {
            const previousPreset = _.get(sortedPresetsWithOffsetOrigins, [orderIndex - 1, 'preset'], null)
            const offsetPresets = mobileCore.mobileHints.calculateOffsetsMobileHints(
                previousPreset,
                preset,
                offsetOrigin
            )

            const mobileHints = {
                ...getCommonMobileHints(preset),
                ...offsetPresets,
                orderIndex
            }
            mobileHintsMap.set(preset.id, mobileHints)
        })
        children.forEach(child => convertMobilePresetToMobileHints(ps, child, mobilePresetsMap, mobileHintsMap))
    }
}

const convertMobilePresetsToMobileHints = (ps: PS, rootPointer: Pointer, mobilePresetsMap) => {
    const mobileHintsMap = new Map<string, any>()
    convertMobilePresetToMobileHints(ps, rootPointer, mobilePresetsMap, mobileHintsMap)
    return mobileHintsMap
}

const shouldMarkAsDirty = (newLayout, previousLayout) =>
    isOffsetChanged(newLayout.x, previousLayout.x) ||
    isOffsetChanged(newLayout.y, previousLayout.y) ||
    isSizeChanged(newLayout.width, previousLayout.width) ||
    isSizeChanged(newLayout.height, previousLayout.height)

const isDeadComponentById = (id: string) => {
    const regEx = new RegExp(`^${constants.DOM_ID_PREFIX.DEAD_MOBILE_COMP}`)
    return regEx.test(id)
}

export {
    hasGeneralData,
    hasSizeData,
    hasOffsetData,
    isMobileHintsPreset,
    getOrderIndex,
    getComponentStructure,
    getComponentsMap,
    shouldRemoveOffset,
    shouldRemovePresetSize,
    convertMobilePresetsToMobileHints,
    shouldMarkAsDirty,
    isDeadComponentById
}
