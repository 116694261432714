import type {SnapshotDal} from '@wix/document-manager-core'
import _ from 'lodash'
import clientSpecMapChanger from './clientSpecMapChanger'

const isConcurrencyError = metaSiteResponse =>
    metaSiteResponse.success === false && metaSiteResponse.errorCode === -40103

const run = function (func, changers = []) {
    function concurrentRunner(
        lastImmutableSnapshot,
        currentImmutableSnapshot,
        resolve,
        reject,
        bi,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal
    ) {
        let tries = 1
        const concurrentResolver = function (metaSiteResponse) {
            if (!metaSiteResponse) {
                resolve()
                return
            }

            if (metaSiteResponse.success === true) {
                resolve({
                    changes: _([clientSpecMapChanger, ...changers])
                        .map(changer => changer(currentImmutableSnapshot, metaSiteResponse, currentSnapshotDal))
                        .flatten()
                        .value()
                })
            } else if (isConcurrencyError(metaSiteResponse) && tries === 1) {
                tries += 1
                func(
                    lastImmutableSnapshot,
                    currentImmutableSnapshot,
                    concurrentResolver,
                    reject,
                    bi,
                    options,
                    lastSnapshotDal,
                    currentSnapshotDal
                )
            } else {
                resolve()
            }
        }

        func(
            lastImmutableSnapshot,
            currentImmutableSnapshot,
            concurrentResolver,
            reject,
            bi,
            options,
            lastSnapshotDal,
            currentSnapshotDal
        )
    }

    return function (
        lastImmutableSnapshot,
        currentImmutableSnapshot,
        resolve,
        reject,
        bi,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal
    ) {
        concurrentRunner(
            lastImmutableSnapshot,
            currentImmutableSnapshot,
            resolve,
            reject,
            bi,
            options,
            lastSnapshotDal,
            currentSnapshotDal
        )
    }
}

export default run
