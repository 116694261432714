import _ from 'lodash'
import type {ExtensionAPI, DAL} from '@wix/document-manager-core'
import {SiteExport, getAllPageNicknames, CustomMenuExportData, errorTypes} from './utils'
import type {
    Link,
    MenuData,
    MenuItem,
    Pointers,
    PageLink,
    AnchorLink,
    TpaPageLink,
    DocumentLink,
    DynamicPageLink
} from '@wix/document-services-types'
import type {PageAPI} from '../page'
import type {MenuExtensionAPI} from '../menu/menu'
import type {RoutersAPI} from '../routers'
import {getIdFromRef} from '../../utils/dataUtils'
import {ReportableError} from '@wix/document-manager-utils'
const getMenusData = (extensionApi: ExtensionAPI): MenuData[] => {
    return (extensionApi as MenuExtensionAPI).menus.getAll()
}

export const exportSite = (extensionApi: ExtensionAPI, dal: DAL, pointers: Pointers): SiteExport => {
    const nicknames = getAllPageNicknames(extensionApi, pointers)
    const getNick = (id: string) => nicknames[id] ?? id
    const ids = (extensionApi.page as PageAPI).getAllPagesIds(false)
    const appState = dal.get(pointers.platform.getAppStatePointer())
    const menus = getMenusData(extensionApi)
    return {
        pages: ids.map(getNick),
        appState: _.mapKeys(appState, (state, id) => getNick(id)),
        menus: _.keyBy(menus, 'id')
    }
}

const validatePageId = (linkId: string, pageId: string, pages: string[]): void => {
    const unhashedPageId = getIdFromRef(pageId)
    if (!pages.includes(unhashedPageId)) {
        throw new ReportableError({
            errorType: errorTypes.IMPORT_MENU_VALIDATION_ERROR,
            message: `Non-existing pageId="${unhashedPageId}" referenced from link with id="${linkId}"`
        })
    }
}

const validateLink = (extensionApi: ExtensionAPI, dal: DAL, pointers: Pointers, link: Link, pages: string[]): void => {
    const typesWithPageId = ['PageLink', 'AnchorLink', 'TpaPageLink']
    if (typesWithPageId.includes(link.type)) {
        const {pageId} = link as PageLink | AnchorLink | TpaPageLink

        validatePageId(link.id!, pageId, pages)
    }
    if (link.type === 'TpaPageLink') {
        const {appDefinitionId, id} = link as TpaPageLink
        const clientSpecMap = dal.get(pointers.rendererModel.getClientSpecMap())
        const hasInstalledApplication = !_.isEmpty(_.find(clientSpecMap, {appDefinitionId}))
        if (!hasInstalledApplication) {
            throw new ReportableError({
                errorType: errorTypes.IMPORT_MENU_VALIDATION_ERROR,
                message: `Menu can not contain TpaPageLink id="${id}" that is associated with uninstalled application appDefinitionId="${appDefinitionId}"`
            })
        }
    }
    if (link.type === 'DynamicPageLink') {
        const {routerId, id} = link as DynamicPageLink
        const allRouters = (extensionApi as RoutersAPI).routers.getAllRouters()

        if (!allRouters[routerId]) {
            throw new ReportableError({
                errorType: errorTypes.IMPORT_MENU_VALIDATION_ERROR,
                message: `Menu can not contain dynamic page link id="${id}", it relies on non-existing router id=${routerId}`
            })
        }
    }
    if (link.type === 'DocumentLink') {
        // we can't re-upload document, so just check if such link exists
        const {docId, name, id} = link as DocumentLink

        const documentDataItem = pointers.data.getDataItemsWithPredicate({docId, name}, 'masterPage')
        if (documentDataItem.length === 0) {
            throw new ReportableError({
                errorType: errorTypes.IMPORT_MENU_VALIDATION_ERROR,
                message: `Menu can not contain link id="${id}" to non-existing document docId="${docId}"`
            })
        }
    }
}

const validateMenuDataItems = (
    extensionApi: ExtensionAPI,
    dal: DAL,
    pointers: Pointers,
    items: MenuItem[],
    pages: string[]
): void => {
    items.forEach(item => {
        if (item.link) {
            validateLink(extensionApi, dal, pointers, item.link, pages)
        }
        validateMenuDataItems(extensionApi, dal, pointers, item.items, pages)
    })
}

const validateMenuData = (
    extensionApi: ExtensionAPI,
    dal: DAL,
    pointers: Pointers,
    menuData: CustomMenuExportData[],
    pages: string[]
): void => {
    menuData.forEach(menu => {
        validateMenuDataItems(extensionApi, dal, pointers, menu.items, pages)
    })
}

export const importSite = (data: SiteExport, extensionApi: ExtensionAPI, dal: DAL, pointers: Pointers): void => {
    const currentExport = exportSite(extensionApi, dal, pointers)
    const currentMenuIds = _.keys(currentExport.menus)
    const currentPages = currentExport.pages
    const importedPages = data.pages
    const pagesToDelete = _.difference(currentPages, importedPages)

    const nicksToIds = _.invert(getAllPageNicknames(extensionApi, pointers))
    const allPagesIds = _(importedPages)
        .union(currentPages)
        .map(pageNick => nicksToIds[pageNick])
        .value()
    const appState = _.mapKeys(data.appState, (v, nickOrId) => nicksToIds[nickOrId] ?? nickOrId)

    validateMenuData(extensionApi, dal, pointers, _.values(data.menus), allPagesIds)

    pagesToDelete.forEach(nick => {
        const id = nicksToIds[nick]
        ;(extensionApi.page as PageAPI).removePage(id)
    })

    dal.set(pointers.platform.getAppStatePointer(), appState)

    _.forEach(data.menus, menuData => {
        if (!currentMenuIds.includes(menuData.id)) {
            ;(extensionApi as MenuExtensionAPI).menus.createMenu(menuData.id, menuData)
            return
        }
        ;(extensionApi as MenuExtensionAPI).menus.updateMenu(menuData, menuData.id)
    })
}
