import type {CoreLogger} from '@wix/document-manager-core'
import _ from 'lodash'

type Method = 'GET' | 'POST' | 'DELETE'

interface ErrorDescriptor {
    tags?: any
    message: string
}

export type FetchFn = (input: RequestInfo, init?: RequestInit) => Promise<Response>

export async function fetch<T = any>(url: string, method: Method, fetchFn: FetchFn): Promise<T> {
    const requestOptions = {
        method,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetchFn(url, requestOptions)
    const result = await response.json()
    if (response.ok) {
        return result
    }
    throw new Error(result.message)
}

export async function tryFetch<T = any>(
    url: string,
    method: Method,
    fetchFn: FetchFn,
    logger: CoreLogger,
    errorDescriptor: ErrorDescriptor
): Promise<T | void> {
    try {
        return fetch(url, method, fetchFn)
    } catch (err) {
        const e = err as Error
        if (_.isFunction(logger.captureError)) {
            const errorMsg = _.isString(errorDescriptor?.message) ? errorDescriptor?.message : e.message
            e.message = errorMsg
            const tags = _.get(errorDescriptor, ['tags'], {})
            logger.captureError(e, tags)
        } else {
            throw e
        }
    }
}
