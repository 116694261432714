import _ from 'lodash'
import {sessionFreezer} from '@wix/santa-ds-libs/src/warmupUtils'

const viewDefCacheByComponentId = {}
sessionFreezer.freeze(viewDefCacheByComponentId)

function getViewDefKey(view: string, type: string, format: string): string {
    return [view, type, format].join()
}

export default {
    /**
     * @param {string} componentId
     * @param {string} view
     * @param {string} type
     * @param {string} format
     * @param {!Object} viewDef
     */
    setComponentViewDef(componentId, view, type, format, viewDef) {
        const viewDefKey = getViewDefKey(view, type, format)
        _.set(viewDefCacheByComponentId, [componentId, viewDefKey], viewDef)
    },

    /**
     * @param {string} componentId
     * @param {string} view
     * @param {string} type
     * @param {string} format
     * @returns {!Object|undefined}
     */
    getComponentViewDef(componentId, view, type, format) {
        const viewDefKey = getViewDefKey(view, type, format)
        return _.get(viewDefCacheByComponentId, [componentId, viewDefKey])
    },

    /**
     * @param {string} componentId
     */
    removeComponentViewDefs(componentId) {
        delete viewDefCacheByComponentId[componentId]
    }
}
