import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import dsUtils from '../../utils/utils'

export default {
    resizableSides: [],
    defaultMobileProperties: {
        iconSize: 35,
        spacing: 15
    },

    mobileConversionConfig: {
        fixedSize(ps, component, pageId) {
            const properties = ps.dal.get(
                ps.pointers.data.getPropertyItem(dsUtils.stripHashIfExists(component.propertyQuery), pageId)
            )
            const data = dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(component.dataQuery), pageId)
            const numberOfLinks = _.get(data, ['items', 'length'], 0)
            const iconSize = _.get(properties, 'iconSize', 0)
            const spacing = _.get(properties, 'spacing', 0)
            const orientation = _.get(properties, 'orientation', 'HORIZ')
            const mainAxis = Math.max(iconSize * numberOfLinks + spacing * (numberOfLinks - 1), 0) // eslint-disable-line no-mixed-operators
            const crossAxis = iconSize
            const horizontal = orientation === 'HORIZ'
            return {width: horizontal ? mainAxis : crossAxis, height: horizontal ? crossAxis : mainAxis}
        }
    },

    heightAuto: true,
    widthAuto: true
}
