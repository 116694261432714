import _ from 'lodash'
import taskRunner from '../../saveAPI/lib/saveRunner'
import pathUtils from '../utils/pathUtils'
import savePublishTaskFactory from './savePublishTaskFactory'
import bi from '../../bi/bi'
import type {PS} from '@wix/document-services-types'

const getSaveOnlyWixappsRegistry = (ps: PS) => ({
    primaryTask: savePublishTaskFactory.create(ps),
    secondaryTasks: []
})

function getBiCallbacks(ps: PS) {
    return {
        event: _.partial(bi.event, ps),
        error: _.partial(bi.error, ps)
    }
}

function reload(ps: PS): void {
    ps.wixappsDAL.removeByPath(pathUtils.getAppbuilderPath())
    let acc: string[] = []
    _.forEach(pathUtils.getAppbuilderMetadataPath('requestedPartNames'), function (pathPart) {
        const newVal = pathPart !== 'requestedPartNames' ? {} : []
        acc = acc.concat(pathPart)
        ps.wixappsDAL.setByPath(acc, newVal)
    })
    ps.wixappsDAL.takeSnapshot()
}

function save(ps: PS, onSuccess, onError, isFullSave: boolean) {
    ps.setOperationsQueue.flushQueueAndExecute(function () {
        const args = [getSaveOnlyWixappsRegistry(ps), ps.dal, onSuccess, onError, getBiCallbacks(ps)]

        if (isFullSave) {
            taskRunner.runFullSaveTasks(...args)
        } else {
            taskRunner.runPartialSaveTasks(...args)
        }
    })
}

export default {
    reload,
    save
}
