import errors from './bi/errors'
import events from './bi/events'
import supportedSiteEvents from './common/supportedSiteEvents'
import TPABaseUrlBuilder from './common/TPABaseUrlBuilder'
import tpaMetaData from './common/tpaMetaData'
import TPAUrlBuilder from './common/TPAUrlBuilder'
import gluedWidgetMeasuringUtils from './utils/gluedWidgetMeasuringUtils'
import tpaUtils from './utils/tpaUtils'

export default {
    gluedWidgetMeasuringUtils,
    common: {
        metaData: tpaMetaData,
        TPAUrlBuilder,
        TPABaseUrlBuilder,
        utils: tpaUtils,
        supportedSiteEvents
    },
    bi: {
        errors,
        events
    }
}
