import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import baseInputMetadata from './baseInputMetadata'

const datePickerMetadata = {
    layoutLimits(ps, compPointer) {
        const measureMap = ps.siteAPI.getSiteMeasureMap() || {}
        const {id} = compPointer

        return {
            minWidth: _.get(measureMap, ['minWidth', id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH),
            minHeight: _.get(measureMap, ['minHeight', id], santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT)
        }
    }
}

export default _.merge(datePickerMetadata, baseInputMetadata)
