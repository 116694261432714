import _ from 'lodash'
import omit from 'lodash/fp/omit'
import concat from 'lodash/fp/concat'
import without from 'lodash/fp/without'
import type {DAL, ExtensionAPI} from '@wix/document-manager-core'
import type {PagesData, Pointer, Pointers, ConnectionList} from '@wix/document-services-types'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, VIEW_MODES} from '../../constants/constants'
import {
    collectIdsToNicknamesMap,
    Comp,
    CompOp,
    errorTypes,
    getCompsInPage,
    isPage,
    mapPageExportWithKeys,
    PageExport,
    replaceReferencesInComp
} from './utils'
import type {DataModelExtensionAPI} from '../dataModel'
import type {ComponentsAPI} from '../components'
import {generateItemIdWithPrefix} from '../../utils/dataUtils'
import type {ConnectionsAPI} from '../connections'
import type {PageAPI} from '../page'
import type {ComponentsMetadataAPI} from '../componentsMetadata/componentsMetadata'
import {ReportableError} from '@wix/document-manager-utils'

type CompUpdate = Partial<Record<keyof typeof COMP_DATA_QUERY_KEYS_WITH_STYLE, any>> & {DESKTOP: any}

interface ReParent {
    compId: string
    fromId: string
    toId: string
}

interface ImportUpdates {
    pageId: string
    compUpdates: CompUpdate[]
    compIdsToRemove: string[]
    reParents: ReParent[]
}

const DEFAULT_PAGE_ADDITION_DATA: PagesData = {
    type: 'Page',
    title: 'some title',
    isPopup: false,
    hideTitle: false,
    icon: '',
    descriptionSEO: '',
    metaKeywordsSEO: '',
    pageTitleSEO: '',
    pageUriSEO: '',
    hidePage: false,
    isMobileLandingPage: false,
    underConstruction: false,
    pageSecurity: {
        requireLogin: false
    },
    indexable: false,
    isLandingPage: false,
    translationData: {
        uriSEOTranslated: false
    }
}

const removeNamespaces: CompOp = omit(_.keys(COMP_DATA_QUERY_KEYS_WITH_STYLE))

const removeNickname: CompOp = omit(['nickname'])

const unNormalizeConnections: (extensionApi: ExtensionAPI, pointers: Pointers) => CompOp =
    (extensionApi, pointers) => comp => {
        const items = _.get(comp, ['connections'], [])
        const pointer = {id: comp.id, type: VIEW_MODES.DESKTOP}
        let possibleRefId: string | null = null

        if (comp.nickname && !isPage(pointers)(comp)) {
            if (comp.type === 'RefComponent') {
                const existingConnectionList = (extensionApi as DataModelExtensionAPI).dataModel.components.getItem(
                    pointer,
                    'connections'
                )
                if (existingConnectionList) {
                    possibleRefId = existingConnectionList.id
                }
            }

            items.push((extensionApi as ConnectionsAPI).connections.createWixCodeConnectionItem(comp.nickname))
        }

        if (items.length === 0) {
            return comp
        }

        const connections: Partial<ConnectionList> = {
            type: 'ConnectionList',
            items
        }

        if (possibleRefId) {
            connections.id = possibleRefId
        }

        return {
            ...comp,
            connections
        }
    }

function setCompStructure(
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI,
    pointer: Pointer,
    structure: Record<string, any>,
    pageId: string
) {
    const childrenProperty = pointers.structure.isMasterPage(pointer) ? 'children' : 'components'
    const dataFromDal = _.pick(dal.get(pointer), [childrenProperty, ..._.values(COMP_DATA_QUERY_KEYS_WITH_STYLE)])

    if (
        (extensionAPI as ComponentsMetadataAPI).componentsMetadata.isContainer(structure.componentType) &&
        !dataFromDal[childrenProperty]
    ) {
        dataFromDal[childrenProperty] = []
    }

    dal.set(pointer, {
        ...dataFromDal,
        ...structure,
        metaData: {pageId}
    })
}

/** Does this layout data belong in the structure, like in classic, or in the namespaces, like in responsive? */
const isStructureLayout = (data: any): boolean => !data.type

const namespacesFromComp = (comp: Comp): Partial<Record<keyof typeof COMP_DATA_QUERY_KEYS_WITH_STYLE, any>> =>
    _(COMP_DATA_QUERY_KEYS_WITH_STYLE)
        .mapValues((query, namespace) => comp[namespace])
        .omitBy(_.isNil)
        .value()

const compToCompUpdate = (comp: Comp): CompUpdate => ({
    DESKTOP: removeNamespaces(comp),
    ...namespacesFromComp(comp)
})

const giveBackId =
    (nicknamesToIds: Record<string, string>) =>
    (comp: Comp, key: string): Comp => ({
        ...comp,
        id: nicknamesToIds[comp.nickname] ?? key
    })

function replaceReferencesInCompUpdate(nicknamesToIds: Record<string, string>) {
    return function (update: CompUpdate) {
        return _.mapValues(update, (v, ns) => (ns === 'connections' ? v : replaceReferencesInComp(nicknamesToIds)(v)))
    }
}

const enrichTPADataWithApplicationId = (dal: DAL, pointers: Pointers) => (comp: CompUpdate) => {
    if (!comp.data || !comp?.data?.appDefinitionId) {
        return comp
    }

    const csmEntryPointer = pointers.rendererModel.getClientSpecMapEntryByAppDefId(comp.data.appDefinitionId)
    const csmEntry = dal.get(csmEntryPointer)

    return {
        ...comp,
        data: {
            ...comp.data,
            applicationId: csmEntry.applicationId
        }
    }
}

const computeCompUpdates = (
    withIds: PageExport,
    nicknamesToIds: Record<string, string>,
    dal: DAL,
    pointers: Pointers,
    extensionAPI: ExtensionAPI
): CompUpdate[] => {
    const userComps: Comp[] = [withIds.page, ..._.values(withIds.components)]
    return userComps
        .map(unNormalizeConnections(extensionAPI, pointers))
        .map(removeNickname)
        .map(compToCompUpdate)
        .map(replaceReferencesInCompUpdate(nicknamesToIds))
        .map(enrichTPADataWithApplicationId(dal, pointers))
}

const computeCompIdsToRemove = (currentPageCompsInDal: Comp[], pageId: string, withIds: PageExport) => {
    const currentCompIdsInDal = _(currentPageCompsInDal).map('id').without(pageId).value()
    const compIdsFromUser = _(withIds.components).values().map('id').value()
    return _.difference(currentCompIdsInDal, compIdsFromUser)
}

function getNicknamesToIdsMap(pageComps: Comp[], userData: PageExport): Record<string, string> {
    const existingNicknamesToIds = _.invert(collectIdsToNicknamesMap(pageComps))
    const newCompNicknames = _(userData.components)
        .values()
        .map('nickname')
        .reject(n => existingNicknamesToIds[n])
        .value()
    const newNicknamesToIds = _(newCompNicknames)
        .keyBy(_.identity)
        .mapValues(() => generateItemIdWithPrefix('comp'))
        .value()
    return {
        ...existingNicknamesToIds,
        ...newNicknamesToIds
    }
}

const computeParentMap = (comps: Comp[]): Record<string, string> =>
    _(comps).keyBy('id').mapValues('parent').value() as unknown as Record<string, string>

const getReParents = (updates: CompUpdate[], parentMap: Record<string, string>) =>
    updates
        .map(u => ({
            compId: u.DESKTOP.id,
            fromId: parentMap[u.DESKTOP.id],
            toId: u.DESKTOP.parent
        }))
        .filter(r => r.fromId !== r.toId)

const computeImportUpdates = (
    pageId: string,
    userData: PageExport,
    extensionApi: ExtensionAPI,
    dal: DAL,
    pointers: Pointers
): ImportUpdates => {
    const pageComps = getCompsInPage(pageId, extensionApi, pointers)
    const parentMap = computeParentMap(pageComps)
    const nicknamesToIds = getNicknamesToIdsMap(pageComps, userData)
    const giveId = giveBackId(nicknamesToIds)
    const withIds = mapPageExportWithKeys(giveId)(userData, pageId)
    const compUpdates = computeCompUpdates(withIds, nicknamesToIds, dal, pointers, extensionApi)
    const reParents: ReParent[] = getReParents(compUpdates, parentMap)
    const compIdsToRemove = computeCompIdsToRemove(pageComps, pageId, withIds)

    return {
        pageId,
        compUpdates,
        compIdsToRemove,
        reParents
    }
}

const performImportUpdates = (
    {compUpdates, compIdsToRemove, pageId, reParents}: ImportUpdates,
    extensionApi: ExtensionAPI,
    dal: DAL,
    pointers: Pointers
): void => {
    const {addItem} = (extensionApi as DataModelExtensionAPI).dataModel.components
    const {removeComponent} = extensionApi.components as ComponentsAPI

    compUpdates.forEach(update => {
        const compPointer = {id: update.DESKTOP.id, type: 'DESKTOP'}
        setCompStructure(dal, pointers, extensionApi, compPointer, update.DESKTOP, pageId)
        _(update)
            .omit('DESKTOP')
            .forEach((value, namespace) => {
                if (namespace === 'layout') {
                    if (isStructureLayout(value)) {
                        dal.set({...compPointer, innerPath: ['layout']}, value)
                    } else {
                        dal.set({...compPointer, innerPath: ['layout']}, {})
                        addItem(compPointer, namespace, value)
                    }
                } else if (namespace === 'connections' && update.DESKTOP.type === 'RefComponent') {
                    // we can perform operation only if such ref component already present
                    addItem(compPointer, namespace, value, value.id)
                } else {
                    addItem(compPointer, namespace, value)
                }
            })
    })
    reParents.forEach(({fromId, toId, compId}) => {
        if (fromId) {
            const originalParentPtr = {id: fromId, type: 'DESKTOP', innerPath: ['components']}
            dal.modify(originalParentPtr, without([compId]))
        }
        const newParentPtr = {id: toId, type: 'DESKTOP', innerPath: ['components']}
        dal.modify(newParentPtr, concat(compId))
    })
    compIdsToRemove.forEach(id => {
        removeComponent({id, type: 'DESKTOP'})
    })
}

export const importPage = (
    pageId: string,
    data: PageExport,
    extensionApi: ExtensionAPI,
    dal: DAL,
    pointers: Pointers
): void => {
    const updates = computeImportUpdates(pageId, data, extensionApi, dal, pointers)
    performImportUpdates(updates, extensionApi, dal, pointers)
}

const validatePageHasNickname: (exportData: PageExport) => void = exportData => {
    if (!exportData.page.nickname || !_.isString(exportData.page.nickname)) {
        throw new ReportableError({
            errorType: errorTypes.INVALID_PAGE,
            message: `Nickname for page was not provided.`
        })
    }
}

export const addPage = (data: PageExport, extensionApi: ExtensionAPI, dal: DAL, pointers: Pointers): string => {
    validatePageHasNickname(data)
    const pagePointer = (extensionApi.page as PageAPI).addPage({
        ...DEFAULT_PAGE_ADDITION_DATA,
        pageUriSEO: data.page.nickname
    })
    const {id} = pagePointer
    importPage(id, data, extensionApi, dal, pointers)
    return id
}
