import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import handlers from '../handlers/tpaHandlers'
import clientSpecMapService from './clientSpecMapService'
import tpaEventHandlersService from './tpaEventHandlersService'
import tpaUtils from '../utils/tpaUtils'
import tpaPostMessageCommon, {IntentType} from './tpaPostMessageCommon'
import {contextAdapter} from '../../utils/contextAdapter'

const documentServicesHandlersThatAreCalledFromAppIframe = {
    isSupported: true,
    registerEventListener: true,
    getInstalledInstance: true,
    resizeComponent: true,
    revalidateSession: true
}

const tpaEditorPreviewHandlers = {
    openSettingsDialog: true,
    isApplicationInstalled: true,
    isFullWidth: true,
    onReady: true,
    isCustomApplicationPermissionsGranted: true,
    isGroupApplicationPermissionsGranted: true
}

const documentServicesHandlersThatAvailableOnlyInEditor = {
    setValue: true,
    removeValue: true,
    addApplication: true
}

const superAppsOnlyHandlers = {
    getInstalledInstance: true,
    addApplication: true
}

const shouldHandleMessage = function (ps: PS, msg: TpaMsg) {
    const documentServicesHandlersThatOverridesViewerHandlers = _.get(
        contextAdapter,
        'documentServicesHandlersThatOverridesViewerHandlers'
    )
    const editorPreviewHandler = tpaEditorPreviewHandlers[msg.type]
    const documentServicesHandlers = documentServicesHandlersThatAreCalledFromAppIframe[msg.type]
    const documentServicesHandlersThatOverrideViewer = documentServicesHandlersThatOverridesViewerHandlers[msg.type]
    const fromSettings = isFromSettings(msg)
    const componentViewModePointer = ps.pointers.general.getRenderFlag('componentViewMode')
    const componentViewMode = ps.dal.get(componentViewModePointer)
    const documentServicesHandlersAvailableInEditor =
        documentServicesHandlersThatAvailableOnlyInEditor[msg.type] && componentViewMode === 'editor'
    const isAppAllowedMethod =
        !superAppsOnlyHandlers[msg.type] || clientSpecMapService.isSuperAppByCompId(ps, msg.compId)

    return (
        (documentServicesHandlersThatOverrideViewer ||
            documentServicesHandlers ||
            editorPreviewHandler ||
            fromSettings ||
            documentServicesHandlersAvailableInEditor) &&
        isAppAllowedMethod &&
        handlers[msg.type]
    )
}

const isFromSettings = (msg: TpaMsg) => msg.originFrame === 'editor'

export interface TpaMsg {
    data?: {
        eventKey?: string
        appDefinitionId?: string
        scope?: string
        keys?: any
        key?: any
        value?: any
    }
    originFrame?: string
    callId: number
    compId: string
    deviceType: string
    intent: IntentType
    type: string
}

const callHandler = function (ps: PS, msg: TpaMsg, response?) {
    const msgType = tpaPostMessageCommon.fixOldPingPongMessageType(msg.type)

    if (shouldHandleMessage(ps, msg)) {
        handlers[msgType].apply(this, [ps, msg, response])
        if (isEventSupported(msg)) {
            const origin = isFromSettings(msg) ? 'editor' : 'preview'
            tpaUtils.sendBIEvent(ps, msg, origin)
        }
    }
}

const isEventSupported = function (msg: TpaMsg) {
    if (msg.type === 'registerEventListener') {
        return _.includes(tpaEventHandlersService.supportedEvents, msg.data.eventKey)
    }
    return true
}

const init = function (ps: PS) {
    if (typeof window !== 'undefined') {
        window.addEventListener(
            'message',
            tpaPostMessageCommon.handleTPAMessage.bind(this, ps, null, callHandler),
            false
        )
        ps.siteAPI.flushDsBeforeLoadMessagesQueue(event =>
            tpaPostMessageCommon.handleTPAMessage.call(null, ps, null, callHandler, event, tpaEditorPreviewHandlers)
        )
    }
}

export default {
    init,
    callHandler
}
