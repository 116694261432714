import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import layout from 'layout'

function measureColumn(id, measureMap, nodesMap, siteData) {
    const node = nodesMap[id]
    const {left, width} = node.getBoundingClientRect()

    const marker = santaCoreUtils.contentAreaUtil.getContentAreaMarkerChildOnNode(node)
    if (!marker) {
        return
    }

    _.merge(measureMap, {
        left: {[id]: Math.floor(marker.getBoundingClientRect().left + siteData.getSiteX())},
        width: {[id]: marker.offsetWidth},
        relativeToScreenOverrides: {[id]: {x: left, width}}
    })
}

function measureSlide(id, measureMap, nodesMap, siteData) {
    return measureColumn(id, measureMap, nodesMap, siteData)
}

function measureSectionContentArea(id, measureMap, nodesMap, siteData) {
    return measureColumn(id, measureMap, nodesMap, siteData)
}

function measureStripColumnsContainer(id, measureMap, nodesMap) {
    const node = nodesMap[id]
    const {left, width} = node.getBoundingClientRect()

    const marker = santaCoreUtils.contentAreaUtil.getContentAreaMarkerChildOnNode(node)
    if (!marker) {
        return
    }

    _.merge(measureMap, {
        left: {[id]: 0},
        width: {[id]: marker.offsetWidth},
        relativeToScreenOverrides: {[id]: {x: left, width}}
    })
}

function measureFullWidthContainer(id, measureMap, nodesMap) {
    const node = nodesMap[id]
    const marker = santaCoreUtils.contentAreaUtil.getContentAreaMarkerForElement(node)
    if (!marker) {
        return
    }

    _.merge(measureMap, {
        left: {[id]: 0},
        width: {[id]: marker.offsetWidth},
        relativeToScreenOverrides: {
            [id]: {x: node.offsetLeft, width: node.offsetWidth}
        }
    })
}

layout.registerAdditionalMeasureFunction(
    'wysiwyg.viewer.components.StripColumnsContainer',
    measureStripColumnsContainer
)
layout.registerAdditionalMeasureFunction('wysiwyg.viewer.components.Column', measureColumn)
layout.registerAdditionalMeasureFunction('wysiwyg.viewer.components.StripContainerSlideShow', measureFullWidthContainer)
layout.registerAdditionalMeasureFunction('wysiwyg.viewer.components.StripContainerSlideShowSlide', measureSlide)
layout.registerAdditionalMeasureFunction('wysiwyg.viewer.components.ClassicSection', measureSectionContentArea)
