'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

const VIEW_NAMES_BY_APP_PART_NAME = {}
VIEW_NAMES_BY_APP_PART_NAME[coreUtils.blogAppPartNames.FEED] = [
    'MediaBottomPage',
    'MediaLeftPage',
    'MediaRightPage',
    'MediaTopPage',
    'MediaZigzagPage'
]
VIEW_NAMES_BY_APP_PART_NAME[coreUtils.blogAppPartNames.POSTS_LIST] = ['PostsList', 'PostsListMediaLeft']
VIEW_NAMES_BY_APP_PART_NAME[coreUtils.blogAppPartNames.TICKER] = ['TickerMediaBottom', 'TickerMediaTop']

module.exports = {
    name: 'blogDateFormatCustomizationFormatFixer',
    version: 1,
    exec(pageJson) {
        _(pageJson.data.document_data)
            .filter(
                compData =>
                    compData.type === 'AppPart' &&
                    _.includes(
                        [
                            coreUtils.blogAppPartNames.FEED,
                            coreUtils.blogAppPartNames.POSTS_LIST,
                            coreUtils.blogAppPartNames.TICKER
                        ],
                        compData.appPartName
                    )
            )
            .forEach(compData => {
                _(compData.appLogicCustomizations)
                    .filter(
                        customization =>
                            customization.fieldId === 'date' && //eslint-disable-line lodash/prefer-matches
                            customization.forType === 'Post' &&
                            !customization.format &&
                            customization.key === 'comp.format' &&
                            _.includes(VIEW_NAMES_BY_APP_PART_NAME[compData.appPartName], customization.view)
                    )
                    .forEach(customization => {
                        customization.format = '*'
                    })
            })
    }
}
