import type {SnapshotDal} from '@wix/document-manager-core'
import _ from 'lodash'
import type {SaveTaskDefinition} from '../../saveAPI/lib/registry'
import constants from '../utils/constants'
import clientSpecMapUtils from '../utils/clientSpecMapUtils'
import markAppImmutable from '../services/markAppImmutable'
import wixCodeMonitoring from '../services/wixCodeMonitoringWrapper'
import type {BICallbacks} from '../../saveAPI/createSaveAPI'

const dataProviderFromSnapshotDal = (snapshotDal: SnapshotDal) => {
    const [type, id] = constants.paths.REVISION_GRID_APP_ID
    const gridAppIdPointer = {type, id}
    const data = {
        clientSpecMap: snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'}),
        isAppReadOnly: _.get(
            snapshotDal.getValue({type: 'wixCode', id: 'wixCode'}),
            constants.paths.IS_APP_READ_ONLY.slice(1)
        ),
        getBaseUrl(key) {
            return snapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'})[key]
        },
        metasiteId: snapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'}),
        gridAppId: _.get(snapshotDal.getValue(gridAppIdPointer), constants.paths.REVISION_GRID_APP_ID.slice(2))
    }
    return data
}

const getDataProvider = (currentSnapshot, currentSnapshotDal: SnapshotDal) => {
    const dataProvider = dataProviderFromSnapshotDal(currentSnapshotDal)

    const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromDataProvider(dataProvider)
    const wixCodeInstanceId = wixCodeApp ? wixCodeApp.instanceId : ''
    return Object.assign(dataProvider, {
        wixCodeApp,
        wixCodeInstanceId,
        baseUrl: dataProvider.getBaseUrl(constants.WIX_CODE_SERVICE_URL_KEY),
        traceFunc: params => wixCodeMonitoring.traceWithSnapshot(currentSnapshot, params, currentSnapshotDal)
    })
}

const createRunTask =
    () =>
    (
        lastSnapshot,
        currentSnapshot,
        resolve,
        reject,
        bi: BICallbacks,
        options,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal
    ) => {
        const currentDataProvider = getDataProvider(currentSnapshot, currentSnapshotDal)

        const runUsingSnapshot = async () => {
            if (!currentDataProvider.wixCodeApp) {
                return
            }
            await markAppImmutable.runUsingSnapshot(currentDataProvider)
            if (!currentDataProvider.isAppReadOnly) {
                return {changes: [{path: constants.paths.IS_APP_READ_ONLY, value: true}]}
            }
        }

        runUsingSnapshot().then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
    }

const createTask = (): SaveTaskDefinition => {
    const runTask = createRunTask()
    const task = {
        partialSave: runTask,
        fullSave: runTask,
        firstSave: runTask,
        saveAsTemplate: runTask,
        autosave: (lastSavedState, currentState, resolve) => {
            resolve()
        },
        publish(currentData, resolve) {
            resolve()
        },
        getTaskName() {
            return 'markAppImmutable'
        },
        getSnapshotTags() {
            return ['primary']
        }
    }
    return task
}

const taskWithSnapshotDal = createTask()
export default () => taskWithSnapshotDal
