import {areSomeOpen, debug} from '@wix/document-manager-core'
import type {Experiment, DSConfig} from '@wix/document-services-types'
import {getDsConfig} from './configs/getDsConfig'
import type {BootstrapConfig} from './types'
import _ from 'lodash'

const log = debug('csave')

export const editorX = 'editor_x'
export const classic = 'Editor1.4'
export const responsiveBlocks = 'responsiveBlocks'
export const presetPreview = 'preset_preview'
export const appBuilder = 'appBuilder'
export const onBoarding = 'onboarding'
export const expertsFeedback = 'experts_feedback'
export const logoMaker = 'Logo'
export const testOrigin = 'initialize.spec'

const ALWAYS_OPEN = true

type OriginStatus = string[] | boolean

const originBasedExperiments: Record<string, Record<string, OriginStatus>> = {
    cedit: {
        [editorX]: ALWAYS_OPEN,
        [classic]: ['dm_ceditSingleUserForClassic'],
        [responsiveBlocks]: ['dm_ceditResponsiveBlocks'],
        [testOrigin]: ALWAYS_OPEN
    },
    createRevision: {
        [classic]: ['ds_csaveCreateRevision'],
        [editorX]: ALWAYS_OPEN,
        [testOrigin]: ALWAYS_OPEN
    },
    immutableDomain: {
        [classic]: ALWAYS_OPEN,
        [editorX]: ALWAYS_OPEN,
        [responsiveBlocks]: ALWAYS_OPEN,
        [presetPreview]: ALWAYS_OPEN,
        [logoMaker]: ['specs.logo-builder.client.ImmutableDomain'],
        [onBoarding]: ALWAYS_OPEN,
        [expertsFeedback]: ALWAYS_OPEN,
        [appBuilder]: ALWAYS_OPEN,
        [testOrigin]: ALWAYS_OPEN
    }
}

export const isOpenForOrigin = (experiment: Experiment, names: string[], origin: string, origins: string[]) =>
    areSomeOpen(experiment, names) && origins.includes(origin)

const isOpenByOrigin = (origin: string, experiment: Experiment, openByOrigin: Record<string, OriginStatus>) => {
    if (origin in openByOrigin) {
        const openBy = openByOrigin[origin]
        if (_.isBoolean(openBy)) {
            return openBy
        }
        return _.isEmpty(openBy) || areSomeOpen(experiment, openBy)
    }
    return false
}

export const isCEditOpen = (origin: string, experiment: Experiment) =>
    isOpenByOrigin(origin, experiment, originBasedExperiments.cedit)

export const isCreateRevisionOpen = (origin: string, experiment: Experiment) =>
    isOpenByOrigin(origin, experiment, originBasedExperiments.createRevision)

export const isImmutableDomainOpen = (origin: string, experiment: Experiment) =>
    isOpenByOrigin(origin, experiment, originBasedExperiments.immutableDomain)

export const isCSaveOpen = () => true

const isTrue = (s?: string | boolean) => s === true || s === 'true'

const stateString = (b?: boolean) => (b ? 'on' : 'off')

const readOnlyEndpoints = ['renderer/external_preview', 'renderer/revisions']

const isReadOnlyEndpoint = (pathname?: string) => readOnlyEndpoints.some(endpoint => pathname?.includes(endpoint))

export const createConfig = (config: BootstrapConfig, experimentInstance: Experiment): DSConfig => {
    const isReadOnly = isTrue(config.isReadOnly) || isReadOnlyEndpoint(config.pathname)
    const disableSave = isTrue(config.disableSave)
    const enableScopes = isTrue(config.enableScopes)
    const enableRepeatersInScopes = isTrue(config.enableRepeatersInScopes)
    const overrides: Partial<DSConfig> = {
        isReadOnly,
        disableSave: disableSave || isReadOnly,
        enableScopes,
        enableRepeatersInScopes,
        cedit: isCEditOpen(config.dsOrigin, experimentInstance),
        createRevision: isCreateRevisionOpen(config.dsOrigin, experimentInstance),
        immutableDomain: isImmutableDomainOpen(config.dsOrigin, experimentInstance)
    }
    const disableAutoSave =
        isTrue(config.disableAutoSave) || (isTrue(config.isqa) && !isTrue(config.forceAutosave)) || isReadOnly

    if (disableAutoSave) {
        overrides.disableAutoSave = disableAutoSave
    }

    if (isReadOnlyEndpoint(config.pathname)) {
        overrides.isReadOnly = true
    }

    const dsConf = getDsConfig(config, overrides)
    if (!isReadOnly && dsConf.continuousSave !== false) {
        dsConf.continuousSave = true
    }
    log.info(`For origin ${config.dsOrigin} continuousSave is ${stateString(overrides.continuousSave)}`)
    log.info(`cedit is ${stateString(overrides.cedit)}`)
    log.info(`createRevision is ${stateString(overrides.createRevision)}`)
    log.info(`autosave is ${dsConf.disableAutoSave ? 'disabled' : 'enabled'}`)
    log.info(`scopes is ${dsConf.enableScopes ? 'disabled' : 'enabled'}`)
    log.info(`repeaters in scopes is ${dsConf.enableRepeatersInScopes ? 'disabled' : 'enabled'}`)
    return dsConf
}
