import {CreateExtArgs, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import _ from 'lodash'
import type {
    Component,
    ComponentLayoutObject,
    CompStructure,
    Pointer,
    StyleRefOrStyleRefs
} from '@wix/document-services-types'
import {generateItemIdWithPrefix} from '../utils/dataUtils'
import type {ComponentsMetadataAPI} from './componentsMetadata/componentsMetadata'
import type {GridLayoutAPI} from './gridLayout'
import type {DefaultDefinitionsAPI} from './defaultDefinitions/defaultDefinitions'
import type {DataModelExtensionAPI} from './dataModel'
import type {ThemeAPI} from './theme/theme'
import {getComponentType} from '../utils/dalUtils'
import {constants} from '..'

export interface ComponentsAPI extends ExtensionAPI {
    addComponent(parentPointer: Pointer, componentStructure: CompStructure, compPointerOverride?: Pointer): Pointer
    setComponent(componentDefinition: CompStructure, pageId: string, componentPointer: Pointer): void
    addLayoutsAsResponsiveLayout(componentPointer: Pointer, layouts?: Partial<ComponentLayoutObject>): void
    addBreakpointVariants(componentPointer: Pointer, breakpointsDefinition?: any): void
    removeComponent(componentPointer: Pointer): void
}

export interface ComponentsExtensionAPI extends ExtensionAPI {
    components: ComponentsAPI
}
export const EVENTS = {
    COMPONENTS: {
        BEFORE_REMOVE: 'COMPONENT_BEFORE_REMOVE',
        AFTER_REMOVE: 'COMPONENT_AFTER_REMOVE',
        AFTER_ADD: 'COMPONENT_AFTER_ADD'
    }
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers, extensionAPI, eventEmitter}: CreateExtArgs): ExtensionAPI => {
        const defaultDefinitions = () => extensionAPI.defaultDefinitions as DefaultDefinitionsAPI
        const componentMetaData = () => (extensionAPI as ComponentsMetadataAPI).componentsMetadata
        const gridLayout = () => extensionAPI.gridLayout as GridLayoutAPI
        const dataModel = () => (extensionAPI as DataModelExtensionAPI).dataModel
        const theme = () => extensionAPI.theme as ThemeAPI

        const addData = (componentPointer: Pointer, pageId: string, dataDefinition?: object) => {
            if (dataDefinition) {
                dataModel().components.addItem(componentPointer, 'data', dataDefinition)
            }
        }

        const addDesign = (componentPointer: Pointer, pageId: string, designDefinition?: object) => {
            if (designDefinition) {
                dataModel().components.addItem(componentPointer, 'design', designDefinition)
            }
        }

        const addProperties = (componentPointer: Pointer, pageId: string, propertiesDefinition?: object) => {
            if (propertiesDefinition) {
                dataModel().components.addItem(componentPointer, 'props', propertiesDefinition)
            }
        }

        const addPresets = (componentPointer: Pointer, presetsDefinition?: object) => {
            if (presetsDefinition) {
                dataModel().components.addItem(componentPointer, constants.DATA_TYPES.presets, presetsDefinition)
            }
        }

        const addStyles = (componentPointer: Pointer, stylesDefinition?: object) => {
            if (stylesDefinition) {
                dataModel().components.addItem(componentPointer, constants.DATA_TYPES.theme, stylesDefinition)
            }
        }

        const addMobileHints = (componentPointer: Pointer, mobileHintsDefintion?: object) => {
            if (mobileHintsDefintion) {
                dataModel().components.addItem(componentPointer, constants.DATA_TYPES.mobileHints, mobileHintsDefintion)
            }
        }

        const createDalComponent = (componentDefinition: CompStructure, pageId: string, componentPointer: Pointer) => {
            const component = {
                layout: componentDefinition.layout ? componentDefinition.layout : {},
                metaData: {pageId},
                type: componentDefinition.type ?? 'Component',
                id: componentPointer.id,
                componentType: componentDefinition.componentType
            } as Component

            if (componentDefinition.styleId) {
                component.styleId = componentDefinition.styleId
            }

            if (componentMetaData().isContainer(componentDefinition.componentType)) {
                component.components = []
            }
            return component
        }

        const addComponentToParent = (parentPointer: Pointer, componentPointer: Pointer) => {
            const componentsPtr = pointerUtils.getInnerPointer(parentPointer, 'components')
            const components = _.cloneDeep(dal.get(componentsPtr))
            components.push(componentPointer.id)
            dal.set(componentsPtr, components)
            dal.set({...componentPointer, innerPath: ['parent']}, parentPointer.id)
        }

        const addLayoutsAsResponsiveLayout = (componentPointer: Pointer, layouts?: Partial<ComponentLayoutObject>) => {
            const layout = {...layouts, variableConnections: [], type: 'SingleLayoutData'}
            const refArray = defaultDefinitions().createRefArrayDefinition([layout])
            dataModel().components.addItem(componentPointer, 'layout', refArray)
        }

        const addBreakpointVariants = (componentPointer: Pointer, breakpointsDefinition?: any) => {
            const breakpoints = {
                values: breakpointsDefinition,
                type: 'BreakpointsData',
                componentId: componentPointer.id
            }
            dataModel().components.addItem(componentPointer, 'variants', breakpoints)
        }

        const setComponent = (componentDefinition: CompStructure, pageId: string, componentPointer: Pointer) => {
            const component = createDalComponent(componentDefinition, pageId, componentPointer)
            dal.set(componentPointer, component)
        }

        const addSystemStyle = (componentDefinition: CompStructure, componentPointer: Pointer) => {
            if (componentDefinition.styleId) {
                theme().ensureDefaultStyleItemExists(componentDefinition.componentType, componentDefinition.styleId)
                const stylePointer = theme().cloneStyle(componentDefinition.styleId)
                const style = dal.get(stylePointer)
                dal.set({...componentPointer, innerPath: ['styleId']}, stylePointer.id)
                dal.set({...componentPointer, innerPath: ['skin']}, style.skin)
            }
        }

        const addComponent = (
            parentPointer: Pointer,
            compDefinition: CompStructure,
            compPointerOverride?: Pointer
        ): Pointer => {
            const componentDefinition = defaultDefinitions().createComponentDefinition(compDefinition, {parentPointer})
            const parentComponent = dal.get(parentPointer)
            const componentPointer = compPointerOverride
                ? compPointerOverride
                : pointerUtils.getPointer(generateItemIdWithPrefix('comp'), 'DESKTOP')
            const pagePointer = pointers.structure.getPageOfComponent(parentPointer)
            const pageId = pagePointer.id
            setComponent(componentDefinition, pageId, componentPointer)
            addLayoutsAsResponsiveLayout(componentPointer, componentDefinition.layouts)
            addData(componentPointer, pageId, componentDefinition.data)
            addDesign(componentPointer, pageId, componentDefinition.design)
            addProperties(componentPointer, pageId, componentDefinition.props)
            addComponentToParent(parentPointer, componentPointer)
            if (!componentDefinition.style) {
                addSystemStyle(componentDefinition, componentPointer)
            } else {
                addStyles(componentPointer, componentDefinition.style as StyleRefOrStyleRefs)
            }
            addPresets(componentPointer, componentDefinition.presets)
            addMobileHints(componentPointer, componentDefinition.mobileHints)

            if (parentComponent.type === 'Page') {
                gridLayout().shiftItemsToBeUnderTarget(parentPointer, componentPointer)
            }
            eventEmitter.emit(EVENTS.COMPONENTS.AFTER_ADD, componentPointer, pagePointer)
            return componentPointer
        }

        const removeComponentFromParent = (parentPointer: Pointer, removedComponentId: string) => {
            const componentsPtr = pointerUtils.getInnerPointer(parentPointer, 'components')
            const components = dal.get(componentsPtr).filter((id: string) => id !== removedComponentId)
            dal.set(componentsPtr, components)
        }

        const removeComponentFromParentIfParentExists = (parentPointer: Pointer | null, removedComponentId: string) => {
            if (!_.isNil(parentPointer)) {
                removeComponentFromParent(parentPointer, removedComponentId)
            }
        }

        const removeComponent = (componentPointer: Pointer) => {
            if (componentPointer.type !== constants.VIEW_MODES.DESKTOP) {
                throw Error('removing components from non desktop view mode is not supported')
            }
            const compType = getComponentType(pointers, dal, componentPointer)
            const compChildren = dal.get(pointerUtils.getInnerPointer(componentPointer, 'components'))

            for (const childId of compChildren ?? []) {
                removeComponent(pointerUtils.getPointer(childId, componentPointer.type))
            }

            eventEmitter.emit(EVENTS.COMPONENTS.BEFORE_REMOVE, componentPointer)

            const mobilePointer = pointerUtils.getPointer(componentPointer.id, constants.VIEW_MODES.MOBILE)
            if (dal.has(mobilePointer)) {
                const mobileParent = pointers.structure.getParent(mobilePointer)
                dal.remove(mobilePointer)
                removeComponentFromParentIfParentExists(mobileParent, mobilePointer.id)
            }

            const compParent = pointers.structure.getParent(componentPointer)

            dataModel().removeItemRecursively(componentPointer)
            removeComponentFromParentIfParentExists(compParent, componentPointer.id)

            eventEmitter.emit(EVENTS.COMPONENTS.AFTER_REMOVE, componentPointer, compType)
        }

        return {
            components: {
                addComponent,
                setComponent,
                addLayoutsAsResponsiveLayout,
                addBreakpointVariants,
                removeComponent
            }
        }
    }

    return {
        name: 'components',
        EVENTS,
        createExtensionAPI
    }
}

export {createExtension}
