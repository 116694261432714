import {breakpoints} from '@wix/document-services-implementation'
import type {PublicMethodUtils} from '@wix/document-services-types'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        initMethod: breakpoints.init,
        methods: {
            breakpoints: {
                update: publicMethodUtils.defineDataManipulationAction(breakpoints.update),
                get: publicMethodUtils.defineGetter(breakpoints.get),
                remove: publicMethodUtils.defineDataManipulationAction(breakpoints.remove),
                wasBreakpointsMigratedToVariants: publicMethodUtils.defineGetter(
                    breakpoints.wasBreakpointsMigratedToVariants
                )
            }
        }
    }
}
