const constants = {
    URLS: {
        MAX_LENGTH: 100
    },
    URL_FORMATS: {
        SLASH: 'slash',
        HASH_BANG: 'hashBang'
    },
    DEVICES: ['desktop', 'mobile'],
    MASTER_PAGE_ID: 'masterPage',
    SAFE_PADDING_FOR_URI_LENGTH: 5,
    CUSTOM_MAIN_MENU: 'CUSTOM_MAIN_MENU',
    HINTS_PROPERTIES: {
        GENERAL_HINTS_PROPERTIES: ['id', 'metaData', 'type'],
        GENERAL_PRESET_DATA: ['author'],
        SIZE_DATA: ['recommendedHeight', 'recommendedWidth', 'recommendedScale', 'originalCompId'],
        OFFSET_DATA: ['offsetX', 'offsetY', 'offsetOrigin', 'orderIndex']
    }
}

export {constants}
