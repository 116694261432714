import type {Pointer, PS} from '@wix/document-services-types'
import componentStylesAndSkinsAPI from '../../component/componentStylesAndSkinsAPI'

function runStyleValidations(styleId: string, styleValue) {
    if (styleId) {
        if (!styleValue) {
            throw new Error(`There is no existing style for styleId [${styleId}]`)
        }
        if (typeof styleValue !== 'object') {
            throw new Error(`The given style for for styleId [${styleId}] is not an object`)
        }
        if (!styleValue.skin) {
            throw new Error(`The given style for for styleId [${styleId}] does not contain a skin`)
        }
        if (!styleValue.type) {
            throw new Error(`The given style for for styleId [${styleId}] does not contain a type`)
        }
    }
}

function validateComponentStyle(ps: PS, compPointer: Pointer) {
    const styleId = componentStylesAndSkinsAPI.style.getId(ps, compPointer)
    const styleItem = componentStylesAndSkinsAPI.style.get(ps, compPointer)
    runStyleValidations(styleId, styleItem)
}

export default {
    validateComponentStyle
}
