/**
 * Created by eitanr on 6/24/14.
 */
import layout from '../util/layout'
import $ from 'zepto'
import _ from 'lodash'
import imageClientLib from '@wix/image-client-api/dist/imageClientApi'
import imageLayout from './imageLayout'
import warmupUtilsLib from '@wix/santa-core-utils'

const {matrixCalculations} = warmupUtilsLib
const {galleriesCommonLayout} = warmupUtilsLib
const {matrixScalingCalculations} = warmupUtilsLib

const MIN_HEIGHT = 70
const MIN_WIDTH = 45

function getSizeAfterScaling(displayerData, itemHeight, itemWidth, props, $node) {
    return matrixScalingCalculations.getSizeAfterScaling({
        itemHeight,
        itemWidth,
        displayerData,
        imageMode: props.imageMode,
        heightDiff: parseInt($node.data('height-diff'), 10) || 0,
        widthDiff: parseInt($node.data('width-diff'), 10) || 0,
        bottomGap: parseInt($node.data('bottom-gap'), 10) || 0
    })
}

function measureDisplayer(compId, itemWidth, itemHeight, props, imageItems, displayer, index) {
    const $displayer = $(displayer)
    const imageIndex = $displayer.data('image-index')
    const imageData = _.defaults({displayMode: imageClientLib.fittingTypes.SCALE_TO_FILL}, imageItems[imageIndex])

    const sizeAfterScaling = getSizeAfterScaling(imageData, itemHeight, itemWidth, props, $displayer)
    const containerSize = galleriesCommonLayout.getContainerSize(sizeAfterScaling.imageWrapperSize, $displayer)
    const displayerPosition = matrixCalculations.getItemPosition(
        index,
        itemWidth,
        itemHeight,
        props.margin,
        props.numCols
    )

    return {
        id: displayer.id,
        imageData,
        imageNodeId: `${displayer.id}image`,
        imageWrapperId: `${displayer.id}imageWrapper`,
        containerSize,
        sizeAfterScaling,
        position: displayerPosition
    }
}

function measureMatrixGallery(id, measureMap, nodesMap, structureInfo) {
    galleriesCommonLayout.measureFlexibleHeightGallery(id, measureMap, nodesMap)
    const $node = $(nodesMap[id])
    const itemsContainer = nodesMap[`${id}itemsContainer`]
    const displayers = $(itemsContainer).children()
    const imageItems = structureInfo.dataItem.items
    const numCols = parseInt($node.data('numCols'), 10)
    const margin = parseInt($node.data('margin'), 10)
    const imageMode = $node.data('imageMode')

    measureMap.width[id] = Math.max(MIN_WIDTH, measureMap.width[id])
    measureMap.height[id] = Math.max(MIN_HEIGHT, measureMap.height[id])
    const heightDiff = parseInt($node.data('height-diff'), 10) || 0
    const widthDiff = parseInt($node.data('width-diff'), 10) || 0
    const numPresentedRows = parseInt($node.data('presented-row'), 10) || 0

    const itemWidth = matrixCalculations.getItemWidth(margin, numCols, measureMap.width[id], widthDiff)
    const itemHeight = matrixCalculations.getItemHeight(margin, measureMap.height[id], numPresentedRows, heightDiff)

    measureMap.custom[id] = {
        itemsContainer: {height: measureMap.height[id] - heightDiff},
        showMore: {height: _.get(nodesMap[`${id}showMore`], 'offsetHeight', 0)},
        displayers: _.map(
            displayers,
            // @ts-ignore
            _.partial(measureDisplayer, id, itemWidth, itemHeight, {imageMode, numCols, margin}, imageItems)
        )
    }
}

function patchMatrixGallery(id, patchers, measureMap, structureInfo, siteData) {
    const customMeasure = measureMap.custom[id]

    _.forEach(customMeasure.displayers, function (displayer) {
        const {sizeAfterScaling} = displayer
        patchers.css(displayer.id, {
            height: sizeAfterScaling.displayerSize.height,
            width: sizeAfterScaling.displayerSize.width,
            left: displayer.position.left,
            top: displayer.position.top
        })

        galleriesCommonLayout.updateImageWrapperSizes(patchers, displayer.imageWrapperId, sizeAfterScaling)

        imageLayout.patchNodeImage(
            displayer.imageNodeId,
            patchers,
            measureMap,
            siteData,
            displayer.imageData,
            displayer.containerSize
        )
    })
}

function getChildrenIdToMeasure(id, nodesMap, structureInfo) {
    const res: any[] = [['showMore'], ['itemsContainer']]
    _.forEach(structureInfo.dataItem.items, function (displayer) {
        const childImageComponent = {pathArray: [displayer.id, 'image'], type: 'core.components.Image'}
        const imageWrapper = [displayer.id, 'imageWrapper']
        const displayerPath = [displayer.id]
        res.push(childImageComponent, imageWrapper, displayerPath)
    })
    return res
}

layout.registerRequestToMeasureDom('wysiwyg.viewer.components.MatrixGallery')
layout.registerCustomMeasure('wysiwyg.viewer.components.MatrixGallery', measureMatrixGallery)
layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.MatrixGallery', getChildrenIdToMeasure)
layout.registerPatcher('wysiwyg.viewer.components.MatrixGallery', patchMatrixGallery)

export default {measureMatrixGallery, getChildrenIdToMeasure, patchMatrixGallery}
