const DEFAULT_RETRIES = 3
const DEFAULT_WAIT = 0

const delay = (timeInMs: number) => new Promise(result => setTimeout(result, timeInMs))

export const taskWithRetries = async <T>(
    task: () => Promise<T>,
    shouldRetry: (error: Error) => boolean = () => true,
    retries: number = DEFAULT_RETRIES,
    waitInterval: number = DEFAULT_WAIT
): Promise<T> => {
    try {
        return await task()
    } catch (error) {
        if (retries <= 0 || !shouldRetry(error as Error)) {
            throw error
        }

        await delay(waitInterval)

        return await taskWithRetries(task, shouldRetry, retries - 1, waitInterval)
    }
}
