/**
 * Created by eitanr on 6/24/14.
 */
import _ from 'lodash'
import layout from '../util/layout'

const richTextCompName = 'wysiwyg.viewer.components.WRichText'

function measureWRichText(id, measureMap, nodesMap) {
    const el = nodesMap[id]
    const isPacked = el.dataset.packed === 'true'
    const isVerticalText = el.dataset.verticalText === 'true'
    const sizeKey = isVerticalText ? 'width' : 'height'
    const minSizeKey = isVerticalText ? 'minWidth' : 'minHeight'

    const savedMinSize = _.toNumber(el.dataset[minSizeKey]) || 0
    const contentSize = isVerticalText ? el.offsetWidth : el.offsetHeight
    const actualMinSize = Math.max(savedMinSize, contentSize)

    measureMap[minSizeKey][id] = actualMinSize

    if (isPacked || savedMinSize) {
        measureMap[sizeKey][id] = actualMinSize
    }
}

function patchWRichText(id, patchers, measureMap) {
    const minWidth = measureMap.minWidth[id]
    const isVerticalText = minWidth && minWidth !== 0

    const style = isVerticalText ? {width: ''} : {height: ''}

    patchers.css(id, style)
}

layout.registerRequestToMeasureDom(richTextCompName)
layout.registerCustomMeasure(richTextCompName, measureWRichText)
layout.registerPatcher(richTextCompName, patchWRichText)

export default {richTextCompName, measureWRichText, patchWRichText}
