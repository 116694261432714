import type {PS, ValueOf} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import component from '../../component/component'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import clientSpecMapService from './clientSpecMapService'
import tpaUtils from '../utils/tpaUtils'
import experiment from 'experiment-amd'
import type {TpaMsg} from './tpaPostMessageService'

const Intents = {
    TPA_MESSAGE: 'TPA',
    TPA_MESSAGE_PREVIEW: 'TPA_PREVIEW',
    TPA_MESSAGE2: 'TPA2',
    TPA_NATIVE_MESSAGE: 'TPA_NATIVE',
    TPA_RESPONSE: 'TPA_RESPONSE',
    TPA_PREVIEW_RESPONSE: 'TPA_PREVIEW_RESPONSE',
    PINGPONG_PREFIX: 'pingpong:'
} as const

export type IntentType = ValueOf<typeof Intents>

const callPostMessage = function (source, data, targetOrigin?: string) {
    let msgStr = ''
    try {
        msgStr = JSON.stringify(data)
    } catch (e) {
        return
    }

    if (!source.postMessage) {
        source = source.contentWindow
    }
    source.postMessage(msgStr, targetOrigin || '*')
}

const generateResponseFunction = function (source, msg: TpaMsg, intent?: IntentType) {
    return function (data) {
        try {
            callPostMessage(source, {
                intent: intent || Intents.TPA_RESPONSE,
                callId: msg.callId,
                type: msg.type,
                res: data,
                status: true
            })
        } catch (e) {
            // empty
        }
    }
}

const isTPAMessage = function isTPAMessage(msgIntent: IntentType) {
    return (
        msgIntent === Intents.TPA_MESSAGE ||
        msgIntent === Intents.TPA_MESSAGE2 ||
        msgIntent === Intents.TPA_NATIVE_MESSAGE
    )
}

const isTPAPreviewMessage = function isTPAPreviewMessage(msgIntent: IntentType) {
    return msgIntent === Intents.TPA_MESSAGE_PREVIEW
}

const isTPANativeMessage = function isTPANativeMessage(msgIntent: IntentType) {
    return msgIntent === Intents.TPA_NATIVE_MESSAGE
}

const fixOldPingPongMessageType = function (msgType: string): string {
    return msgType.replace(Intents.PINGPONG_PREFIX, '')
}

const parseMessage = function (event) {
    let msg: TpaMsg
    try {
        if (event.data) {
            msg = JSON.parse(event.data)
        } else if (event.originalEvent?.data) {
            event = event.originalEvent
            msg = JSON.parse(event.data)
        }
    } catch (e) {
        //linty linter linted something
    }
    return msg
}

const getWorkerUrl = (ps: PS, workerCompId: string) => {
    const splits = workerCompId.split('_')
    const applicationId = splits[1]
    return clientSpecMapService.getAppWorkerUrl(ps, applicationId)
}

const getModalOrPopupUrl = (ps: PS, modalOrPopupId: string) => {
    const modalPopupCompData =
        tpaUtils.getModalCompData(ps, modalOrPopupId) || tpaUtils.getPopupCompData(ps, modalOrPopupId)
    return _.get(modalPopupCompData, 'url')
}

// NOTE: tpaWidgetUrlOverride query param is considered as a source of truth for widgetUrl if defined
const TPA_WIDGET_URL_OVERRIDE_PARAM = 'tpaWidgetUrlOverride'

function getWidgetUrlOverridesMap(ps: PS, paramName: string) {
    const tpaWidgetUrlOverrideString = ps.siteAPI.getQueryParam(paramName)
    return tpaWidgetUrlOverrideString ? santaCoreUtils.urlUtils.getOverridesMap(tpaWidgetUrlOverrideString) : null
}

const getWidgetOrSectionUrl = (ps: PS, {appDefinitionId, widgetId}) => {
    const tpaWidgetUrlOverrideMap = getWidgetUrlOverridesMap(ps, TPA_WIDGET_URL_OVERRIDE_PARAM)
    if (tpaWidgetUrlOverrideMap?.[widgetId]) {
        return tpaWidgetUrlOverrideMap[widgetId]
    }
    const widgets = _.get(clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId), 'widgets', {})
    return _.get(widgets, [widgetId, 'widgetUrl'], '')
}

const verifyOrigin = (ps: PS, msg: TpaMsg, eventOrigin: string) => {
    if (!experiment.isOpen('ds_tpaPostMessagesOriginValidation')) {
        return true
    }

    let originUrl = null

    if (msg?.compId) {
        const compPointer = componentDetectorAPI.getComponentById(ps, msg.compId)
        const compData = compPointer && ps.dal.isExist(compPointer) && component.data.get(ps, compPointer)

        if (compData) {
            const tpaWidgetOrSection = tpaUtils.isTpaByDataType(compData.type)

            if (tpaWidgetOrSection && compData.widgetId) {
                originUrl = getWidgetOrSectionUrl(ps, compData)
            }
        } else {
            const TPA_WORKER_ID_PREFIX = 'tpaWorker_'
            const isTPAWorkerId = id => id.indexOf(TPA_WORKER_ID_PREFIX) === 0

            if (isTPAWorkerId(msg.compId)) {
                originUrl = getWorkerUrl(ps, msg.compId)
            } else {
                originUrl = getModalOrPopupUrl(ps, msg.compId)
            }
        }
    }

    return _.startsWith(originUrl, eventOrigin)
}

const handleTPAMessage = function (
    ps: PS,
    siteAPI,
    callHandler: (ps: PS, msg: TpaMsg, res, event) => void,
    event,
    tpaHandlersToExclude = {}
) {
    const msg = parseMessage(event)
    if (msg && isTPAMessage(msg.intent) && !tpaHandlersToExclude[msg.type]) {
        const isVerifiedOrigin = verifyOrigin(ps, msg, event.origin)
        if (isVerifiedOrigin || isTPANativeMessage(msg.intent)) {
            callHandler(ps, msg, generateResponseFunction(event.source, msg), event)
        }
    } else if (msg && isTPAPreviewMessage(msg.intent)) {
        const isVerifiedOrigin = verifyOrigin(ps, msg, event.origin)
        if (isVerifiedOrigin) {
            callHandler(ps, msg, generateResponseFunction(event.source, msg, Intents.TPA_PREVIEW_RESPONSE), event)
        }
    }
}

export default {
    Intents,
    callPostMessage,
    generateResponseFunction,
    isTPAMessage,
    fixOldPingPongMessageType,
    handleTPAMessage
}
