import _ from 'lodash'
import constants from '../../constants/constants'
import inlinePopupToggleMetaData from './inlinePopupToggleMetaData'

function isContainable(ps, comp, containerPointer) {
    const masterPagePointer = ps.pointers.components.getMasterPage(constants.VIEW_MODES.MOBILE)
    const menuContainer = ps.pointers.components.getComponent(
        constants.MOBILE_ONLY_COMPONENTS.MENU_AS_CONTAINER,
        masterPagePointer
    )
    return containerPointer && !ps.pointers.components.isSameComponent(containerPointer, menuContainer)
}

export default _.defaultsDeep(
    {
        containable: isContainable,
        duplicatable: false,
        styleCanBeApplied: true
    },
    inlinePopupToggleMetaData
)
