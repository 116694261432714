import _ from 'lodash'

const movePageDataItemToMasterPageWithoutRefs = (masterPage: any, page: any, lang: string) => {
    const translatedDataPath = ['translations', lang, 'data', 'document_data', page.structure.id]
    const pageDataItem = _.get(page, translatedDataPath)
    if (pageDataItem) {
        _.unset(page, translatedDataPath)
        _.set(masterPage, translatedDataPath, pageDataItem)
    }
}

const movePageDataToMaster = (page: any, masterPage: any) => {
    if (!page.structure) {
        return
    }

    if (page.translations) {
        Object.keys(page.translations).forEach(lang => {
            movePageDataItemToMasterPageWithoutRefs(masterPage, page, lang)
        })
    }
}

export {movePageDataToMaster}
