import type {CreateExtArgs, CreateExtension, Extension, DeepFunctionMap} from '@wix/document-manager-core'
import type {PageExport, SiteExport} from './utils'
import {addPage, importPage} from './import'
import {exportPage} from './export'
import {exportSite, importSite} from './site'

interface ImportExportApi extends DeepFunctionMap {
    exportPage(pageId: string): PageExport
    importPage(pageId: string, pageData: PageExport): void
    addPage(data: PageExport): string

    exportSite(): SiteExport
    importSite(data: SiteExport): void
}

const createExtension: CreateExtension = (): Extension => {
    return {
        name: 'importExport',
        createExtensionAPI({extensionAPI, pointers, dal}: CreateExtArgs): {importExport: ImportExportApi} {
            return {
                importExport: {
                    exportPage(pageId: string) {
                        return exportPage(pageId, extensionAPI, pointers)
                    },
                    importPage(pageId: string, pageData: PageExport) {
                        importPage(pageId, pageData, extensionAPI, dal, pointers)
                    },
                    exportSite(): SiteExport {
                        return exportSite(extensionAPI, dal, pointers)
                    },
                    importSite(data: SiteExport): void {
                        importSite(data, extensionAPI, dal, pointers)
                    },
                    addPage(data: PageExport): string {
                        return addPage(data, extensionAPI, dal, pointers)
                    }
                }
            }
        }
    }
}

export {ImportExportApi, PageExport, createExtension}
