import _ from 'lodash'
import type {DalItem, DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {cleanRef, createRef} from '../utils/migrationUtil'
import {constants} from '@wix/document-manager-extensions'
import {ReportableError} from '@wix/document-manager-utils'
import {getSchemaKey} from './utils'

const getHtmlEncodedLink = (linkId: string) => `dataquery="${createRef(linkId, constants.DATA_TYPES.data)}"`

const updateStyledText = (
    documentManager: DocumentManager,
    referrer: DalItem,
    referrerPointer: Pointer,
    oldId: string,
    newId: string
) => {
    const {dal, logger} = documentManager
    if (referrer.text.includes(getHtmlEncodedLink(oldId))) {
        dal.set(
            referrerPointer,
            _.merge(_.cloneDeep(referrer), {
                text: referrer.text.replace(new RegExp(getHtmlEncodedLink(oldId), 'g'), getHtmlEncodedLink(newId)),
                linkList: _(referrer.linkList)
                    .without(createRef(oldId, constants.DATA_TYPES.data))
                    .concat([createRef(newId, constants.DATA_TYPES.data)])
                    .value()
            })
        )
    } else {
        logger.captureError(
            new ReportableError({
                errorType: 'unfixedTextLink',
                message: `StyledText ${referrer.id} is unfixed for link ${oldId}`,
                extras: {
                    styledText: referrer
                }
            })
        )
    }
}

const referrerKeyUpdate = (
    key: string,
    referenceNamespace: string,
    documentManager: DocumentManager,
    referrer: DalItem,
    referrerPointer: Pointer,
    oldId: string,
    newId: string
) => {
    if (referrer[key] && cleanRef(referrer[key]) === oldId) {
        documentManager.dal.set(
            referrerPointer,
            _.merge(_.cloneDeep(referrer), {[key]: createRef(newId, referenceNamespace)})
        )
    }
}

const referrerMultipleKeysUpdate = (
    keys: string[],
    referenceNamespace: string,
    documentManager: DocumentManager,
    referrer: DalItem,
    referrerPointer: Pointer,
    oldId: string,
    newId: string
) => {
    _.forEach(keys, key => {
        referrerKeyUpdate(key, referenceNamespace, documentManager, referrer, referrerPointer, oldId, newId)
    })
}

const referrerListUpdate = (
    key: string,
    referenceNamespace: string,
    documentManager: DocumentManager,
    referrer: DalItem,
    referrerPointer: Pointer,
    oldId: string,
    newId: string
) => {
    if (referrer[key]) {
        const list = referrer[key].map((ref: string) =>
            cleanRef(ref) === oldId ? createRef(newId, referenceNamespace) : ref
        )
        documentManager.dal.set(referrerPointer, _.merge(_.cloneDeep(referrer), {[key]: list}))
    }
}

const updateQueryOnAllViewModes = (
    key: string,
    referenceNamespace: string,
    documentManager: DocumentManager,
    referrer: DalItem,
    referrerPointer: Pointer,
    oldId: string,
    newId: string
) => {
    const {dal, pointers} = documentManager
    const desktopCompPointer = pointers.structure.getDesktopPointer(referrerPointer)
    const desktopComp = dal.get(desktopCompPointer)
    if (desktopComp) {
        referrerKeyUpdate(key, referenceNamespace, documentManager, desktopComp, desktopCompPointer, oldId, newId)
    }

    const mobileCompPointer = pointers.structure.getMobilePointer(referrerPointer)
    const mobileComp = dal.get(mobileCompPointer)
    if (mobileComp) {
        referrerKeyUpdate(key, referenceNamespace, documentManager, mobileComp, mobileCompPointer, oldId, newId)
    }
}

type UpdateArgs = [DocumentManager, DalItem, Pointer, string, string]

const imageRules = {
    namespace: constants.DATA_TYPES.data,
    schema: 'Image',
    referrerUpdatesMap: {
        [getSchemaKey('data', 'BackgroundMedia')]: (...args: UpdateArgs) =>
            referrerMultipleKeysUpdate(['mediaRef', 'imageOverlay'], constants.DATA_TYPES.data, ...args),
        [getSchemaKey('data', 'ImageList')]: (...args: UpdateArgs) =>
            referrerListUpdate('items', constants.DATA_TYPES.data, ...args),
        [getSchemaKey('data', 'WixVideo')]: (...args: UpdateArgs) =>
            referrerKeyUpdate('posterImageRef', constants.DATA_TYPES.data, ...args),
        [getSchemaKey('DESKTOP', 'Component', 'wysiwyg.viewer.components.WPhoto')]: (...args: UpdateArgs) =>
            updateQueryOnAllViewModes('dataQuery', constants.DATA_TYPES.data, ...args)
    }
}

const colorLayersRule = {
    namespace: constants.DATA_TYPES.design,
    schema: 'SolidColorLayer',
    referrerUpdatesMap: {
        [getSchemaKey('design', 'BackgroundMedia')]: (...args: UpdateArgs) =>
            referrerListUpdate('colorLayers', constants.DATA_TYPES.design, ...args)
    }
}

const linkRules = _.map(['PageLink', 'ExternalLink', 'AnchorLink', 'DocumentLink', 'EmailLink'], refSchema => ({
    namespace: constants.DATA_TYPES.data,
    schema: refSchema,
    referrerUpdatesMap: {
        [getSchemaKey('data', 'LinkableButton')]: (...args: UpdateArgs) =>
            referrerKeyUpdate('link', constants.DATA_TYPES.data, ...args),
        [getSchemaKey('data', 'StylableButton')]: (...args: UpdateArgs) =>
            referrerKeyUpdate('link', constants.DATA_TYPES.data, ...args),
        [getSchemaKey('data', 'BasicMenuItem')]: (...args: UpdateArgs) =>
            referrerKeyUpdate('link', constants.DATA_TYPES.data, ...args),
        [getSchemaKey('data', 'VectorImage')]: (...args: UpdateArgs) =>
            referrerKeyUpdate('link', constants.DATA_TYPES.data, ...args),
        [getSchemaKey('data', 'CheckboxInput')]: (...args: UpdateArgs) =>
            referrerKeyUpdate('link', constants.DATA_TYPES.data, ...args),
        [getSchemaKey('data', 'Image')]: (...args: UpdateArgs) =>
            referrerKeyUpdate('link', constants.DATA_TYPES.data, ...args),
        [getSchemaKey('data', 'StyledText')]: updateStyledText
    }
}))

const connectionRule = {
    namespace: constants.DATA_TYPES.connections,
    schema: 'ConnectionList',
    referrerUpdatesMap: {
        [getSchemaKey('DESKTOP')]: (...args: UpdateArgs) =>
            updateQueryOnAllViewModes('connectionQuery', constants.DATA_TYPES.connections, ...args)
    }
}

const mobileHintsRule = {
    namespace: constants.DATA_TYPES.mobileHints,
    schema: 'MobileHints',
    referrerUpdatesMap: {
        [getSchemaKey('DESKTOP')]: (...args: UpdateArgs) =>
            updateQueryOnAllViewModes('mobileHintsQuery', constants.DATA_TYPES.mobileHints, ...args)
    }
}

export const getFixerRules = () => {
    const rules = [imageRules, connectionRule, mobileHintsRule, colorLayersRule, ...linkRules]
    return rules
}
