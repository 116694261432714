import {ReportableError} from '@wix/document-manager-utils'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {constants} from '@wix/document-manager-extensions'
const {PAGE_DATA_TYPE_TO_POINTER_TYPE, MULTILINGUAL_TYPES} = constants
import {pointerUtils, DocumentManager} from '@wix/document-manager-core'
const {getInnerPath} = pointerUtils

export interface PathMapper {
    getPointerByPath(path: string[]): Pointer | undefined
    getImmutablePath(path: string[]): string[] | undefined
}

interface DynamicPointer {
    initPath: string
    get(pathPart: string): Pointer
}

export const createPathMapper = (documentManager: DocumentManager): PathMapper => {
    const deprecatedPaths = new Set<string>(['documentServicesModel,version', 'documentServicesModel,revision'])
    const pathToPointerMap = {
        'rendererModel,premiumFeatures': documentManager.pointers.rendererModel.getPremiumFeatures(),
        'rendererModel,siteInfo,siteId': documentManager.pointers.rendererModel.getSiteId(),
        'rendererModel,metaSiteId': documentManager.pointers.rendererModel.getMetaSiteId(),
        'rendererModel,siteInfo,documentType': documentManager.pointers.rendererModel.getDocumentType(),
        'rendererModel,mediaAuthToken': documentManager.pointers.rendererModel.getMediaAuthToken(),
        'rendererModel,clientSpecMap': documentManager.pointers.rendererModel.getClientSpecMap(),
        'rendererModel,clientSpecMapCacheKiller': documentManager.pointers.rendererModel.getClientSpecMapCacheKiller(),
        'rendererModel,sitePropertiesInfo,language': documentManager.pointers.general.getRegionalLanguage(),
        'rendererModel,sitePropertiesInfo,currency': documentManager.pointers.general.getCurrency(),
        'rendererModel,sitePropertiesInfo,timeZone': documentManager.pointers.general.getTimeZone(),
        'rendererModel,sitePropertiesInfo,siteDisplayName': documentManager.pointers.rendererModel.getSiteDisplayName(),
        'rendererModel,userId': documentManager.pointers.rendererModel.getUserId(),
        'rendererModel,siteInfo,siteTitleSEO': documentManager.pointers.rendererModel.getSiteTitleSEO(),
        'documentServicesModel,versionInfo,version': documentManager.pointers.documentServicesModel.getSiteVersion(),
        'documentServicesModel,versionInfo,revision': documentManager.pointers.save.getSiteRevision(),
        'documentServicesModel,neverSaved': documentManager.pointers.save.getNeverSaved(),
        'documentServicesModel,siteName': documentManager.pointers.documentServicesModel.getSiteName(),
        'documentServicesModel,metaSiteData': documentManager.pointers.documentServicesModel.getMetaSiteData(),
        'documentServicesModel,publicUrl': documentManager.pointers.documentServicesModel.getPublicUrl(),
        'documentServicesModel,usedMetaSiteNames':
            documentManager.pointers.documentServicesModel.getUsedMetaSiteNames(),
        'documentServicesModel,autoSaveInfo': documentManager.pointers.autoSave.getAutosaveInfo(),
        'documentServicesModel,mediaManagerInfo,siteUploadToken':
            documentManager.pointers.documentServicesModel.getMediaSiteUploadToken(),
        'documentServicesModel,permissionsInfo': documentManager.pointers.documentServicesModel.getPermissionsInfo(),
        'documentServicesModel,isPublished': documentManager.pointers.documentServicesModel.isPublished(),
        'documentServicesModel,hasSites': documentManager.pointers.documentServicesModel.hasSites(),
        'documentServicesModel,isDraft': documentManager.pointers.documentServicesModel.getIsDraft(),
        'pagesPlatformApplications,wixCode':
            documentManager.pointers.platform.getPagesPlatformApplicationPointer('wixCode'),
        orphanPermanentDataNodes: documentManager.pointers.save.getOrphanPermanentDataNodes(),
        devSiteAppDefId: documentManager.pointers.appBuilder.getDevSiteAppDefIdPointer(),
        semanticAppVersions: documentManager.pointers.platform.getSemanticAppVersionsPointer(),
        wixCodeWaitForApproval: documentManager.pointers.wixCode.getWaitForApproval?.(),
        'documentServicesModel,openWixCodeAppId': documentManager.pointers.wixCode.getOpenWixCodeAppId()
    }

    const dynamicPointers: DynamicPointer[] = [
        {
            initPath: 'wixCode,nonUndoable,bundleCacheKillers,',
            get: pathPart => documentManager.pointers.wixCode.getBundleCacheKiller(pathPart)
        },
        {
            initPath: 'wixCode,undoable,modifiedFileContents,',
            get: pathPart => documentManager.pointers.wixCode.getUndoableModifiedFileContent(pathPart)
        },
        {
            initPath: 'documentServicesModel,autoSaveInfo,',
            get: pathPart => documentManager.pointers.autoSave.getAutoSaveInnerPointer(pathPart)
        },
        {
            initPath: 'rendererModel,wixCodeModel,appData,',
            get: pathPart =>
                pointerUtils.getInnerPointer(documentManager.pointers.wixCode.getWixCodeAppData(), pathPart)
        }
    ]

    // eslint-disable-next-line lodash/prefer-matches
    const isPageDataPath = (path: string[]) => path.length === 5 && path[0] === 'pagesData' && path[2] === 'data'

    const getPageDataPointer = (path: string[]) => {
        const pageId = path[1]
        const dataMap = path[3]
        const isMultilingualTranslationsDataMap = dataMap === MULTILINGUAL_TYPES.multilingualTranslations
        const itemType = isMultilingualTranslationsDataMap ? dataMap : PAGE_DATA_TYPE_TO_POINTER_TYPE[dataMap]
        const itemId = path[4]

        const dataPointer = documentManager.pointers.data.getItem(itemType, itemId, pageId)
        const dataItem = documentManager.dal.get(dataPointer)
        const currentPageId = _.get(dataItem, ['metaData', 'pageId'])
        if (currentPageId && currentPageId !== pageId) {
            return undefined
        }
        return dataPointer
    }

    const isWixCodeUndoablePointer = (path: string[]) => path.length > 2 && _.isMatch(path, ['wixCode', 'nonUndoable'])

    const getWixCodeUndoablePointer = (path: string[]) => {
        const pathWithNoPrefix = _.drop(path)
        return documentManager.pointers.wixCode.getUndoablePointerByPath(pathWithNoPrefix)
    }

    const getPointerByPath = (path: string[]): Pointer | undefined => {
        // Specific types
        if (isWixCodeUndoablePointer(path)) {
            return getWixCodeUndoablePointer(path)
        }
        if (isPageDataPath(path)) {
            return getPageDataPointer(path)
        }
        if (deprecatedPaths.has(path.toString())) {
            throw new ReportableError({
                message: `The path [${path.toString()}] is deprecated`,
                errorType: 'deprecatedPath'
            })
        }

        // Static
        const pathString = path.toString()
        const pointer = pathToPointerMap[pathString]
        if (pointer) {
            return pointer
        }

        //Dynamic
        const dynamicPointer = _.find<DynamicPointer>(dynamicPointers, ({initPath}) => pathString.startsWith(initPath))
        if (dynamicPointer) {
            return dynamicPointer.get(pathString.split(dynamicPointer.initPath)[1])
        }

        return undefined
    }

    const getImmutablePath = (path: string[]): string[] | undefined => {
        const pointer = getPointerByPath(path)
        if (!pointer) {
            return undefined
        }
        const innerPath = getInnerPath(pointer)
        return [pointer.type, pointer.id].concat(innerPath)
    }

    return {
        getPointerByPath,
        getImmutablePath
    }
}
