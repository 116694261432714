'use strict'

const _ = require('lodash')

const CHECKOUT_PAGE_WIDGET_ID = '14fd5970-8072-c276-1246-058b79e70c1a'
const MOBILE_CHECKOUT_WIDGET_LAYOUT = {
    width: 320,
    height: 5,
    x: 0,
    y: 0,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false
}

const findComponentByDataQuery = (components, dataQuery) => _.find(components, {dataQuery})

const deleteMobileHintData = (pageJson, mobileHintId) => {
    const mobileHintsData = _.get(pageJson, ['data', 'mobile_hints'])
    if (mobileHintsData) {
        delete mobileHintsData[mobileHintId]
    }
}

const copyCheckoutWidgetToMobile = (mobileComponents, desktopCheckoutWidget) => {
    let mobileCheckoutWidget = findComponentByDataQuery(mobileComponents, desktopCheckoutWidget.dataQuery)
    if (!mobileCheckoutWidget && mobileComponents) {
        mobileCheckoutWidget = _.clone(desktopCheckoutWidget)
        mobileCheckoutWidget.layout = MOBILE_CHECKOUT_WIDGET_LAYOUT
        mobileComponents.push(mobileCheckoutWidget)
    }
}

const fixCheckoutWidgetComponent = pageJson => compData => {
    const desktopCheckoutWidget = findComponentByDataQuery(
        _.get(pageJson, ['structure', 'components']),
        `#${compData.id}`
    )
    if (!desktopCheckoutWidget) {
        return
    }
    deleteMobileHintData(pageJson, desktopCheckoutWidget.mobileHintsQuery)
    delete desktopCheckoutWidget.mobileHintsQuery
    copyCheckoutWidgetToMobile(_.get(pageJson, ['structure', 'mobileComponents']), desktopCheckoutWidget)
}

module.exports = {
    name: 'tpaCheckoutPageDataFixer',
    version: 1,
    exec(pageJson) {
        const pageData = _.get(pageJson, ['data', 'document_data'])
        _(pageData).filter({widgetId: CHECKOUT_PAGE_WIDGET_ID}).forEach(fixCheckoutWidgetComponent(pageJson))
    }
}
