const getComponentStylableName = (compType: string) => compType.split('.').pop()

const STYLABLE_SKIN_NAME = 'wixui.skins.Skinless'
const createEmptyStylableStylesheet = (compType: string) => {
    const compName = getComponentStylableName(compType)
    return `:import {
    -st-from: 'wix-ui-santa/index.st.css';
    -st-named: ${compName};
}
.root{
    -st-extends: ${compName};
}`
}

const getStylableSkinName = () => STYLABLE_SKIN_NAME

const createEmptyStylableStyleItem = (componentType: string) => ({
    skin: getStylableSkinName(),
    style: {
        properties: {
            '$st-css': createEmptyStylableStylesheet(componentType)
        }
    }
})

export default {
    createEmptyStylableStyleItem,
    createEmptyStylableStylesheet,
    getComponentStylableName,
    getStylableSkinName
}
