import {componentTypeAliases} from '@wix/document-services-json-schemas'
import type {Pointer, PS} from '@wix/document-services-types'
import documentServicesSchemas from 'document-services-schemas'
import _ from 'lodash'
import componentStylesAndSkinsAPI from '../../component/componentStylesAndSkinsAPI'
import deprecatedSkinsByComponentType from '../../theme/skins/deprecatedSkinsByComponentType'

/**
 * This returns all valid viewer-skins, including the deprecated ones which might need lazy migration in the editor and cant be migrated via dataFixer
 * If everything could have been migrated via dataFixer, we would not need to get the deprecated skins
 * @param componentType
 * @returns {*}
 */
function getValidSkinsForComponent(componentType: string) {
    const compTypeOrAlias = componentTypeAliases.getAlias(componentType)
    const supportedSkins = documentServicesSchemas.services.getComponentSkins(compTypeOrAlias) ?? []
    const deprecatedSkins = deprecatedSkinsByComponentType[compTypeOrAlias] || []
    return supportedSkins.concat(deprecatedSkins)
}

const COMPONENTS_WITH_DYNAMIC_SKINS = {
    'wysiwyg.viewer.components.svgshape.SvgShape': true,
    'wysiwyg.viewer.components.PopupCloseIconButton': true
}

const COMPONENTS_WITH_BORROWED_SKINS = {
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': {
        'wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin': true
    }
}

function validateComponentSkin(ps: PS, compPointer: Pointer) {
    const skinName = componentStylesAndSkinsAPI.skin.get(ps, compPointer)
    const componentType = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
    const validSkinsForComp = getValidSkinsForComponent(componentType)
    const isSkinValid =
        COMPONENTS_WITH_DYNAMIC_SKINS[componentType] ||
        _.get(COMPONENTS_WITH_BORROWED_SKINS, [componentType, skinName], false) ||
        _.includes(validSkinsForComp, skinName)
    if (!isSkinValid) {
        throw new Error(`The skin [${skinName}] is not valid for component type [${componentType}]`)
    }
}

export default {
    validateComponentSkin
}
