import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import bi from '../../bi/bi'
import biErrors from '../../bi/errors'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import mobileOnlyComponents from './mobileOnlyComponents'
import structuresComparer from './structuresComparer'

const getCompPointer = (ps: PS, compId: string, pageId: string, viewMode: string = constants.VIEW_MODES.DESKTOP) =>
    ps.pointers.full.components.getComponent(compId, ps.pointers.components.getPage(pageId, viewMode))
const isHiddenable = (ps: PS, comp) => componentsMetaData.public.isHiddenable(ps, comp)
const isHiddenAccordingToMobileHints = (ps: PS, desktopCompPointer: Pointer) =>
    _.get(dataModel.getMobileHintsItem(ps, desktopCompPointer), 'hidden', false)
const isCompInDisplayedDAL = (ps: PS, compPointer: Pointer) => ps.dal.displayedJsonDal.isExist(compPointer)
const getPagesList = (ps: PS) => _.map(ps.pointers.page.getNonDeletedPagesPointers(true), 'id')

// show => parent pointer is desktop, hide => parent pointer is mobile
function updatePropertyOfChildren(ps: PS, property, parentPointer: Pointer, settings, pageId: string) {
    const childrenPointers = ps.pointers.full.components.getChildren(parentPointer)
    _.forEach(childrenPointers, childPointer => {
        const desktopChildPointer = getCompPointer(ps, childPointer.id, pageId)
        updateProperty(ps, property, desktopChildPointer, settings, pageId)
    })
}

function updateProperty(ps: PS, property, desktopCompPointer: Pointer, settings: any = {}, pageId?: string) {
    if (!desktopCompPointer || mobileOnlyComponents.isMobileOnlyComponent(ps, desktopCompPointer.id)) {
        return
    }
    pageId = pageId ?? ps.pointers.full.components.getPageOfComponent(desktopCompPointer).id

    const canHiddenPropertyBeChanged = !property.hidden || isHiddenable(ps, desktopCompPointer)
    const isHiddenPropertyDifferent = isHiddenAccordingToMobileHints(ps, desktopCompPointer) !== property.hidden

    if (canHiddenPropertyBeChanged && isHiddenPropertyDifferent) {
        dataModel.updateMobileHintsItem(ps, desktopCompPointer, property)
    }
    if (settings.updateChildren) {
        const compPointer =
            settings.childrenSourceView === constants.VIEW_MODES.MOBILE
                ? getCompPointer(ps, desktopCompPointer.id, pageId, constants.VIEW_MODES.MOBILE)
                : desktopCompPointer
        updatePropertyOfChildren(ps, property, compPointer, settings, pageId)
    }
}

/**
 *  There are some mysterious disappearing of PAGES_CONTAINER, SITE_PAGES, SITE_HEADER, SITE_FOOTER from the mobile structure.
 *  This method fix them and sent BI event for error frequency tracking.
 */
function dirtyDataFixerForNelaRelease(ps: PS, pageId: string, mobileHintsMap) {
    if (pageId !== constants.MASTER_PAGE_ID) {
        return
    }
    const neverShouldBeHiddenIds = ['SITE_FOOTER', 'PAGES_CONTAINER', 'SITE_PAGES', 'SITE_HEADER']
    const corruptedIds = _.remove(mobileHintsMap.hiddenComponents, hiddenComponentId =>
        _.includes(neverShouldBeHiddenIds, hiddenComponentId)
    )
    if (!_.isEmpty(corruptedIds)) {
        // return corrupted elements to be visible
        mobileHintsMap.shownComponents.push(...corruptedIds)
        const {stack} = new Error()
        bi.error(ps, biErrors.MOBILE_STRUCTURE_MANDATORY_ELEMENT_MISSING, {
            missingComponents: corruptedIds.toString(),
            stack
        })
    }
}

const updateComponentHiddenPropertyInDisplayDAL = (ps: PS, compId: string, pageId: string, hidden) => {
    const desktopCompPointer = getCompPointer(ps, compId, pageId)
    const isInDisplayedDAL = isCompInDisplayedDAL(ps, desktopCompPointer)
    if (isInDisplayedDAL) {
        updateProperty(ps, {hidden}, desktopCompPointer)
    }
}

function updateHiddenComponentsOnPage(ps: PS, pageId: string) {
    const mobileHintsMap = structuresComparer.getHiddenAndShownComponents(ps, pageId)
    if (!mobileHintsMap) {
        return
    }
    dirtyDataFixerForNelaRelease(ps, pageId, mobileHintsMap)

    _.forEach(mobileHintsMap.hiddenComponents, compId =>
        updateComponentHiddenPropertyInDisplayDAL(ps, compId, pageId, true)
    )
    _.forEach(mobileHintsMap.shownComponents, compId =>
        updateComponentHiddenPropertyInDisplayDAL(ps, compId, pageId, false)
    )
}

function updateHiddenComponents(ps: PS, pageList?) {
    if (!structuresComparer.hasMobileStructure(ps)) {
        return
    }
    pageList = pageList || getPagesList(ps)
    _.forEach(pageList, pageId => updateHiddenComponentsOnPage(ps, pageId))
}

function isMobileHintsInitializedForPage(ps: PS, pageId?: string) {
    const pageComponentPointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    const mobileHintsPointer = ps.pointers.getInnerPointer(pageComponentPointer, 'mobileHintsQuery')
    const pageHasMobileHints = !!ps.dal.get(mobileHintsPointer)
    return pageHasMobileHints
}

function isAutosaveDataPatchApplied(ps: PS) {
    const changesAppliedPointer = ps.pointers.general.getAutoSaveInnerPointer('changesApplied') || false
    return changesAppliedPointer && ps.dal.get(changesAppliedPointer)
}

function initializeMobileHints(ps: PS) {
    if (!structuresComparer.hasMobileStructure(ps)) {
        return
    }
    const mobileStructureIsOutOfSync = isAutosaveDataPatchApplied(ps)

    const pageList = getPagesList(ps)
    _.forEach(pageList, pageId => {
        const mobileHintsInitializedForPage = isMobileHintsInitializedForPage(ps, pageId)
        if (mobileStructureIsOutOfSync && mobileHintsInitializedForPage) {
            return
        }
        updateHiddenComponentsOnPage(ps, pageId)
        markPageAsInitialized(ps, pageId)
    })
}

function markPageAsInitialized(ps: PS, pageId: string) {
    if (isMobileHintsInitializedForPage(ps, pageId)) {
        return
    }
    const pageComponentPointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    dataModel.updateMobileHintsItem(ps, pageComponentPointer, {hidden: false})
}

function initialize(ps: PS) {
    initializeMobileHints(ps)
}

const getMobileHintsItem = (ps: PS, comp, pageId: string) => {
    const mobileHintsItemId = _.get(comp, 'mobileHintsQuery', '')
    const mobileHintsItem = dataModel.getMobileHintsItemById(ps, mobileHintsItemId, pageId)
    if (!mobileHintsItem) {
        return {hidden: false}
    }
    if (mobileHintsItem.hidden === undefined) {
        mobileHintsItem.hidden = false
    }
    return mobileHintsItem
}

const markComponentAsDirtyForForceReRender = (ps: PS, desktopCompPointer: Pointer) => {
    if (ps.dal.full.isExist(desktopCompPointer)) {
        const pageId = ps.pointers.components.getPageOfComponent(desktopCompPointer).id
        updateProperty(ps, {shouldBeForceConverted: true}, desktopCompPointer, {}, pageId)
    }
}

export default {
    initialize,
    updateProperty,
    updateHiddenComponentsOnPage,
    updateHiddenComponents,
    markPageAsInitialized,
    getMobileHintsItem,
    markComponentAsDirtyForForceReRender,
    testAPI: {
        initializeMobileHints
    }
}
