import _ from 'lodash'
import type {CoreLogger, LogParams} from '@wix/document-manager-core'
import type {BIEvt, FedopsLogger} from '@wix/document-services-types'

const MAX_CONTEXT_LENGTH = 2000

type GetContext = () => any

type ContextRegistry = Record<string, GetContext>

const getSafeContext = (context: any) => {
    try {
        const value = JSON.stringify(context) // just verifying that this is a serializable structure
        if (value.length > MAX_CONTEXT_LENGTH) {
            return `badContext: Context is bigger than ${MAX_CONTEXT_LENGTH} characters: ${value.substr(0, 50)}`
        }
        return context
    } catch (e: any) {
        return `badContext ${e?.message}`
    }
}

export interface AdapterLoggerOptions {
    defaultTags?: Record<string, string | boolean | undefined>
    defaultOverrides?: Record<string, string | undefined>
    shouldSendStackToBi?: boolean
}

const EVENT_KEYS = ['tags', 'extras', 'isDraft']
const PROPS_TO_PROMOTE_FROM_EXTRAS = ['component_type']

type ReportBI = (event: BIEvt, params: Record<string, any>) => void

export interface AdapterLogger extends CoreLogger {
    registerSendBiFunction(func: ReportBI): void
    registerInteractionContextProvider(name: string, provider: GetContext): void
    registerErrorContextProvider(name: string, provider: GetContext): void
}

interface ErrorParams {
    errorType: string
    tags: Record<string, any>
    extras: Record<string, any>
    defaultOverrides?: Record<string, string>
    context?: Record<string, any>
}

const createAdapterLogger = (fedopsLogger: FedopsLogger, options: AdapterLoggerOptions): AdapterLogger => {
    let reportBIFunc: ReportBI
    const interactionContextProviders: ContextRegistry = {}
    const errorContextProviders: ContextRegistry = {}

    const registerSendBiFunction = (func: ReportBI) => {
        reportBIFunc = func
    }

    const registerInteractionContextProvider = (name: string, provider: GetContext) => {
        interactionContextProviders[name] = provider
    }

    const registerErrorContextProvider = (name: string, provider: GetContext) => {
        errorContextProviders[name] = provider
    }

    const reportBI = (event: BIEvt, params: Record<string, any>) => reportBIFunc?.(event, params)

    const defaultTags = (): Record<string, any> => options.defaultTags ?? {}

    const defaultOverrides = (): Record<string, any> => options.defaultOverrides ?? {}

    const defaultParamsOverrides = (): Record<string, any> => ({
        paramsOverrides: {
            tags: defaultTags()
        }
    })

    const getContext = (providers: ContextRegistry, contextParam?: Record<string, any>) => {
        const contextFromProviders = _.mapValues(providers, (func: GetContext) => getSafeContext(func()))
        const contextWithParams = _.merge({}, contextFromProviders, contextParam)

        if (_.size(contextWithParams) === 0) {
            return undefined
        }

        try {
            return JSON.stringify(contextWithParams)
        } catch (e: any) {
            return {badFullContext: e?.message}
        }
    }

    // This function is aware of ReportableError, but not enforcing it for backward compatibility
    const getErrorParams = (error: Error, op?: LogParams): ErrorParams => ({
        errorType: _.get(error, ['errorType'], 'unknown'),
        tags: _.assign({}, defaultTags(), _.get(error, ['tags']), op?.tags),
        extras: _.assign({}, _.get(error, ['extras']), op?.extras),
        context: op?.context
    })

    const extractScriptAndPosition = (line: string) => {
        const parts = line?.split('/') ?? []

        return parts[parts.length - 1]
    }

    const compressStack = (e: any) => {
        const stack = e.stack ?? ''
        const versionMatch = stack.match(/management\/([\d.]+)\/tb-main/)
        if (!versionMatch) {
            return ''
        }

        const version = versionMatch[0].replace('management/', '').replace('/tb-main', '')
        const lineMatches = (stack.match(/\/.+js:\d+:\d+/g) ?? []) as string[]
        const positions = lineMatches
            .map(extractScriptAndPosition)
            .map(position => position.replace('tb-main-internal.min.js:', '*'))
            .slice(0, 10)

        const compressed = `${version}|${positions.toString()}`

        return compressed
    }

    const safeStack = (e: any) => {
        try {
            return compressStack(e)
        } catch (err) {
            return `Failed compressing stack: ${(err as Error)?.message}`
        }
    }

    const sendInteractionError = (error: Error, params: ErrorParams) => {
        const errorContext = getContext(errorContextProviders, params?.context)
        const payload = {
            paramsOverrides: {
                evid: 36,
                errorInfo: error.message,
                errorType: params.errorType,
                errorTags: params.tags,
                errorExtra: params.extras,
                errorStack: options.shouldSendStackToBi ? safeStack(error) : undefined,
                errorContext,
                ...defaultOverrides()
            }
        }
        fedopsLogger.interactionStarted('error', payload)
    }

    const sendSentryError = (error: Error, params: ErrorParams): void => {
        const paramsWithErrorTypeTag = {
            ...params,
            tags: _.assign({}, params.tags, {errorType: params.errorType}),
            groupErrorsBy: 'values'
        }
        fedopsLogger.captureError(error, paramsWithErrorTypeTag)
    }

    const captureError = (error: Error, op?: LogParams): void => {
        const params = getErrorParams(error, op)
        sendSentryError(error, params)
        sendInteractionError(error, params)
    }

    type InteractionFunc = (name: string, options?: Record<string, any>) => void

    const propagateFromExtras = (params?: Record<string, any>) => ({
        paramsOverrides: _.pick(params?.extras, PROPS_TO_PROMOTE_FROM_EXTRAS)
    })

    const interactionWithParams = (func: InteractionFunc, name: string, params?: LogParams) => {
        const paramsWithTagsAndExtras = _.merge(
            {},
            defaultParamsOverrides(),
            propagateFromExtras(params),
            {
                paramsOverrides: _.pick(params, EVENT_KEYS)
            },
            {
                paramsOverrides: {context: getContext(interactionContextProviders, params?.context)}
            },
            {
                paramsOverrides: defaultOverrides()
            },
            params
        )

        func(name, paramsWithTagsAndExtras)
    }

    return {
        captureError,
        interactionStarted: (name: string, params?: LogParams) =>
            interactionWithParams(fedopsLogger.interactionStarted, name, params),
        interactionEnded: (name: string, params?: LogParams) =>
            interactionWithParams(fedopsLogger.interactionEnded, name, params),
        breadcrumb: fedopsLogger.breadcrumb,
        flush: fedopsLogger.flushAllFedOpsLoggers,
        reportBI,
        registerSendBiFunction,
        registerInteractionContextProvider,
        registerErrorContextProvider
    }
}

export {createAdapterLogger}
