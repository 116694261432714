import type {MobileHints, PS} from '@wix/document-services-types'
import constants from '../../../constants/constants'
import dataModel from '../../../dataModel/dataModel'
import '../../../siteMetadata/generalInfo'
import {contextAdapter} from '../../../utils/contextAdapter'
import mobilePresetsAPI from '../mobilePresets/mobilePresetsAPI'
import mobilePresetsConstants from '../mobilePresets/mobilePresetsConstants'
import * as mobileConversionUtils from '../utils'

const {convertPagePresetToMobileHints} = mobilePresetsAPI
const {
    MOBILE_HINTS_AUTHORS,
    FEDOPS: {MOBILE_HINTS_MIGRATION}
} = mobilePresetsConstants

const migrateMobileStructureToMobileHints = (ps: PS, pageId: string) => {
    convertPagePresetToMobileHints(ps, pageId)
}

const logStart = () => contextAdapter.utils.fedopsLogger.interactionStarted(MOBILE_HINTS_MIGRATION)
const logEnd = () => contextAdapter.utils.fedopsLogger.interactionEnded(MOBILE_HINTS_MIGRATION)

const runMigration = (ps: PS, pageId: string) => {
    logStart()
    migrateMobileStructureToMobileHints(ps, pageId)
    logEnd()
}

const isMigrated = (mobileHintsItem: MobileHints) =>
    mobileHintsItem && mobileHintsItem.author === MOBILE_HINTS_AUTHORS.STUDIO

/**
 * This method use to save existing user's mobile pages when new merge flow is enabled
 * @param ps
 * @param pageId
 */
const migratePage = (ps: PS, pageId: string) => {
    runMigration(ps, pageId)
}

const isAbleToRunMigration = (ps: PS, pageId: string) => {
    const mobileHintsItem = dataModel.getMobileHintsItem(ps, {id: pageId, type: constants.VIEW_MODES.DESKTOP})
    return mobileConversionUtils.shouldEnableImprovedMergeFlow(ps) && !isMigrated(mobileHintsItem)
}

export default {
    migratePage,
    isAbleToRunMigration
}
