import {DAL, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
const {isRefPointer, getReferredCompId} = displayedOnlyStructureUtil
import {PAGE_DATA_TYPES, COMP_DATA_QUERY_KEYS_WITH_STYLE} from '../constants/constants'

const {getPointer} = pointerUtils

const REF_SUFFIX = '-ref'

const RefDelimiter = '_r_'
const GhostRefCompType = 'GHOST_REF_COMP'

const MOBILE_SPLIT_SUFFIX = '-mobile'

const extractBaseComponentId = (dataItemPointer: Pointer) => {
    const dataQuery = COMP_DATA_QUERY_KEYS_WITH_STYLE[dataItemPointer.type]
    if (_.isNil(dataQuery)) {
        throw new Error('Unkown data type')
    }

    return _.replace(dataItemPointer.id, new RegExp(`-${dataQuery}(${MOBILE_SPLIT_SUFFIX})?`), '')
}

const isRefHostType = (type: string): boolean => type === 'wysiwyg.viewer.components.RefComponent'

const isRefHost = (pointer: Pointer, dal: DAL): boolean => isRefHostType(dal.getWithPath(pointer, ['componentType']))

const STRUCTURE_TYPE_TO_REF_TYPE_MAP = {
    DESKTOP: `DESKTOP${REF_SUFFIX}`,
    MOBILE: `MOBILE${REF_SUFFIX}`
}

const DATA_TYPE_TO_REF_TYPE_MAP = _.mapValues(PAGE_DATA_TYPES, (v, k) => `${k}${REF_SUFFIX}`)

const TYPE_TO_REF_TYPE_MAP = {...STRUCTURE_TYPE_TO_REF_TYPE_MAP, ...DATA_TYPE_TO_REF_TYPE_MAP}

const structureRefTypes = _.values(STRUCTURE_TYPE_TO_REF_TYPE_MAP)
const dataRefTypes = _.values(DATA_TYPE_TO_REF_TYPE_MAP)

const getRefPointerType = (pointer: Pointer): Pointer => {
    const refType = TYPE_TO_REF_TYPE_MAP[pointer.type]

    if (refType) {
        return {...pointer, type: refType}
    }

    return pointer
}

export interface PointerNoRef extends Pointer {
    noRefFallbacks: boolean
}

const getPointerWithoutFallbacksFromPointer = (pointer: Pointer): PointerNoRef => ({...pointer, noRefFallbacks: true})

const getPointerWithoutFallbacks = (id: string, type: string): PointerNoRef =>
    getPointerWithoutFallbacksFromPointer(getPointer(id, type))

const getOverridePrefix = (id: string): string => `${id}_r_`

const getBaseComponentIdFromRefferredId = (id: string) => {
    return id.split(RefDelimiter)[0]
}

export {
    REF_SUFFIX,
    RefDelimiter,
    GhostRefCompType,
    getBaseComponentIdFromRefferredId,
    isRefHostType,
    isRefHost,
    getRefPointerType,
    isRefPointer,
    getReferredCompId,
    structureRefTypes,
    getPointerWithoutFallbacksFromPointer,
    getPointerWithoutFallbacks,
    getOverridePrefix,
    dataRefTypes,
    extractBaseComponentId
}
