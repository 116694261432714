import type {Callback, Callback1} from '@wix/document-services-types'
import _ from 'lodash'

const deleteAppCallbacks: Record<string, Callback[]> = {}
const addAppCallbacks: Record<string, Callback1<any>[]> = {}

const registerOnAppDeleted = function (appDefId: string, callback: Callback) {
    if (_.isUndefined(deleteAppCallbacks[appDefId])) {
        deleteAppCallbacks[appDefId] = []
    }
    deleteAppCallbacks[appDefId].push(callback)
}

const clearOnAppDeleted = function (appDefId: string) {
    if (!_.isUndefined(deleteAppCallbacks[appDefId])) {
        deleteAppCallbacks[appDefId] = []
    }
}

const registerOnAppInstalled = function (appDefId: string, callback: Callback1<any>) {
    if (_.isUndefined(addAppCallbacks[appDefId])) {
        addAppCallbacks[appDefId] = []
    }
    addAppCallbacks[appDefId].push(callback)
}

const invokeDeleteAppCallbacks = function (appDefinitionId: string) {
    if (deleteAppCallbacks[appDefinitionId]) {
        _.forEach(deleteAppCallbacks[appDefinitionId], function (cb) {
            cb()
        })
    }
}

const invokeAddAppCallbacks = function (appDefinitionId: string, options) {
    if (addAppCallbacks[appDefinitionId]) {
        _.forEach(addAppCallbacks[appDefinitionId], function (cb) {
            cb(options)
        })
    }
}

export default {
    registerOnAppDeleted,
    registerOnAppInstalled,
    clearOnAppDeleted,
    invokeDeleteAppCallbacks,
    invokeAddAppCallbacks
}
