import type {PublicMethodUtils} from '@wix/document-services-types'
import {renderPlugins} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    const {
        defineGetter,
        actions: {dontCare, immediate}
    } = publicMethodUtils

    return {
        methods: {
            renderPlugins: {
                allowSiteOverflow: dontCare(renderPlugins.allowSiteOverflow),
                hideComp: dontCare(renderPlugins.hideComp),
                showComp: dontCare(renderPlugins.showComp),
                isCompHidden: defineGetter(renderPlugins.isCompHidden),
                setCompsToShowOnTop: dontCare(renderPlugins.setCompsToShowOnTop, {
                    disableLogInteraction: true
                }),
                setCompsToShowWithOpacity: dontCare(renderPlugins.setCompsToShowWithOpacity, {
                    disableLogInteraction: true
                }),
                setHideTextComponent: dontCare(renderPlugins.setHideTextComponent),
                setExtraSiteHeight: dontCare(renderPlugins.setExtraSiteHeight),
                setPreviewTooltipCallback: dontCare(renderPlugins.setPreviewTooltipCallback),
                setErrorPagesPopUp: dontCare(renderPlugins.setErrorPagesPopUp),
                setBlockingLayer: dontCare(renderPlugins.setBlockingLayer),
                setBlockingPopupLayer: dontCare(renderPlugins.setBlockingPopupLayer),
                setPremiumNavigationHandler: immediate(renderPlugins.setPremiumNavigationHandler)
            }
        }
    }
}
