import _ from 'lodash'
import metaDataUtils from '../metaDataUtils'

function isCompTypeAllowedInHoverbox(compType) {
    return (
        metaDataUtils.isContainerSuitableForNonRenderingState(compType) ||
        (!metaDataUtils.isContainer(compType) && metaDataUtils.isComponentSuitableForNonRenderingState(compType))
    )
}

/**
 *
 * @param {ps} ps
 * @param {Pointer} potentialChild
 */
function allChildrenAllowedInHoverBox(ps, potentialChild) {
    const recusiveChildrenPointers = ps.pointers.components.getChildrenRecursively(potentialChild)
    return _.every(recusiveChildrenPointers.concat(potentialChild), function (componentPointer) {
        const compType = metaDataUtils.getComponentType(ps, componentPointer)
        return isCompTypeAllowedInHoverbox(compType)
    })
}

/**
 *
 * @param potentialChildStructure
 */
function allChildrenStructureAllowedInHoverbox(potentialChildStructure) {
    if (!potentialChildStructure.components) {
        return isCompTypeAllowedInHoverbox(potentialChildStructure.componentType)
    }

    const childrenTypes = metaDataUtils.getChildrenTypesDeep([potentialChildStructure])
    return _.every(childrenTypes, isCompTypeAllowedInHoverbox)
}

export default {
    /**
     * @param isByStructure
     * @param {ps} ps
     * @param {Pointer} compPointer
     * @param potentialChildStructure
     * @returns {*}
     */
    isRepeatable: false,
    canContainByStructure(ps, compPointer, potentialChildStructure) {
        return allChildrenStructureAllowedInHoverbox(potentialChildStructure)
    },

    canContain(ps, componentPointer, potentialChild) {
        return allChildrenAllowedInHoverBox(ps, potentialChild)
    },

    mobileConversionConfig: {
        nestOverlayingSiblings: false,
        createMobilePresets: false,
        filterChildrenWhenHidden: true
    }
}
