import {newSchemaService} from '@wix/document-services-json-schemas'
import type {Experiment, Pointer} from '@wix/document-services-types'
import {EventEmitter} from 'events'
import _ from 'lodash'
import type {CoreLogger, DocumentDataTypes, PostSetOperation, PostTransactionOperation, SnapshotDal} from '../../src'
import {createDal, CustomGetter, DAL} from '../../src/dal/documentManagerDal'
import {createStore, createStoreFromJS, DalStore, DalValue, DmStore} from '../../src/dal/store'
import {getInnerPointer, getPointer, getSignaturePointer} from '../../src/utils/pointerUtils'
import {createTestExperimentInstance} from './experiments'
import {createTestLogger} from './testLogger'

const defaultType = 't'

const createChangesForRebase = (dal: DAL, snapshot: SnapshotDal, values: any) =>
    createStoreFromJS(
        _([])
            .concat(values)
            .reduce((js, value: any) => {
                const dataType = value.dmType || defaultType
                const pointer = getPointer(value.id, dataType)
                if (!_.has(js, dataType)) {
                    js[dataType] = {}
                }
                js[dataType][pointer.id] = value.isDeleted ? undefined : dal.sign(pointer, value, snapshot)
                return js
            }, {})
    )

const customSet = (setter: (pointer: Pointer, value: DalValue) => void, ...args: any[]) => {
    args.forEach(value => {
        const pointer = getPointer(value.id, value.dmType || defaultType)
        const valueWithType = {type: 'testData', ...value}
        setter(pointer, value.isDeleted ? undefined : valueWithType)
    })
}

const setItems = (dal: DAL, ...args: any[]) =>
    customSet((pointer, value) => (value !== undefined ? dal.set(pointer, value) : dal.remove(pointer)), ...args)

const setAndCommit = (dal: DAL, ...args: any[]): SnapshotDal => {
    setItems(dal, ...args)
    return dal.commitTransaction('testDal')
}

export const createStoreWithChanges = (...args: any[]): DmStore => {
    const changes = createStore()
    customSet((pointer, value) => changes.set(pointer, value), ...args)
    return changes
}

const mergeToApprovedStore = (dal: DAL, ...args: any[]): SnapshotDal => {
    const changes = createStoreWithChanges(...args)
    return dal.mergeToApprovedStore(changes)
}

const initializeDal = (
    dal: DAL,
    {
        approvedItems,
        tentativeItems,
        openTransactionItems
    }: {approvedItems?: any; tentativeItems?: any; openTransactionItems?: any}
) => {
    const approvedSnapshots = _.map(approvedItems, values => mergeToApprovedStore(dal, ...[].concat(values)))
    const tentativeSnapshots = _.map(tentativeItems, values => setAndCommit(dal, ...[].concat(values)))
    setItems(dal, ..._([]).concat(openTransactionItems).compact().value())
    return [...approvedSnapshots, ...tentativeSnapshots]
}

const defaultPointer = (id: string, innerPath?: string[]) =>
    innerPath ? getInnerPointer(getPointer(id, defaultType), innerPath) : getPointer(id, defaultType)
const getTypeFromTentativeStore = (dal: DAL, type?: string) => dal._tentativeStore.namespaceAsJson(type ?? defaultType)
const getTypeFromApprovedStore = (dal: DAL, type?: string) => dal._store.namespaceAsJson(type ?? defaultType)
const getTypeFromCurrentOpenTransaction = (dal: DAL, type?: string) =>
    dal._currentTransaction.store.namespaceAsJson(type ?? defaultType)
const getTentativeStore = (dal: DAL) => dal._tentativeStore.asJson()
const getApprovedStore = (dal: DAL) => dal._store.asJson()
const getCurrentOpenTransaction = (dal: DAL) => dal._currentTransaction.store.asJson()
const getSignatureForApprovedItem = (dal: DAL, id: string) =>
    dal.getLastApprovedSnapshot().getValue(getSignaturePointer(getPointer(id, defaultType)))

const dalUtils = {
    createChangesForRebase,
    setItems,
    setAndCommit,
    mergeToApprovedStore,
    initializeDal,
    defaultPointer,
    getTypeFromTentativeStore,
    getTypeFromApprovedStore,
    getTypeFromCurrentOpenTransaction,
    getTentativeStore,
    getApprovedStore,
    getCurrentOpenTransaction,
    getSignatureForApprovedItem
}

interface TestDalArgs {
    experimentInstance?: Experiment
    logger?: CoreLogger
    postTransactionOperations?: Record<string, PostTransactionOperation>
    dmTypes?: DocumentDataTypes
    customGetters?: Record<string, CustomGetter>
    experimentOverrides?: Record<string, string>
    eventEmitter?: EventEmitter
    configOverrides?: Record<string, any>
    postSetOperations?: PostSetOperation[]
    initialStore?: DalStore
}

const createTestDal = ({
    dmTypes,
    customGetters,
    experimentInstance,
    logger,
    postSetOperations,
    experimentOverrides,
    eventEmitter,
    configOverrides,
    initialStore
}: TestDalArgs = {}) => {
    const experiment = experimentInstance ?? createTestExperimentInstance(experimentOverrides)
    return createDal({
        coreConfig: {
            experimentInstance: experiment,
            logger: logger ?? createTestLogger(),
            schemaService: newSchemaService.staticInstance,
            signatureSeed: 'sig',
            strictModeFailDefault: true,
            checkConflicts: true,
            ...configOverrides
        },
        postSetOperations: postSetOperations ?? [],
        dmTypes: _.assign({t: 't'}, dmTypes),
        customGetters: _.assign({}, customGetters),
        eventEmitter: eventEmitter ?? new EventEmitter(),
        initialStore
    })
}

export {createTestDal, defaultType, dalUtils}
