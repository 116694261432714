import proxyFactory from '@wix/santa-ds-libs/src/wixappsCore/src/core/proxyFactory'
import styleData from '@wix/santa-ds-libs/src/wixappsCore/src/core/styleData'
import linksConverter from '@wix/santa-ds-libs/src/wixappsCore/src/core/linksConverter'
import viewsUtils from '@wix/santa-ds-libs/src/wixappsCore/src/util/viewsUtils'
import richTextUtils from '@wix/santa-ds-libs/src/wixappsCore/src/util/richTextUtils'
import styleMapping from '@wix/santa-ds-libs/src/wixappsCore/src/util/styleMapping'

/**
 * @class wixappsCore
 */
export default {
    proxyFactory,
    linksConverter,
    viewsUtils,
    styleData,
    richTextUtils,
    styleMapping
}
