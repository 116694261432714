import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import * as jsonSchemas from '@wix/document-services-json-schemas'
import type {DataModelExtensionAPI} from './dataModel'

type FeatureData = any

export interface FeaturesAPI {
    component: {
        get(compPointer: Pointer, featureName: string): FeatureData
    }
}

export type FeaturesExtensionAPI = ExtensionAPI & {
    features: FeaturesAPI
}

export const FEATURE_TYPES = {
    COMPONENT: 'Component',
    PAGE: 'Page',
    SITE: 'Site',
    REPEATER: 'Repeater'
}

const validPageLevelTypes = ['Page', 'Document']

const {
    namespaceMapping: {featuresData, getNamespaceConfig}
} = jsonSchemas

const createExtension = (): Extension => {
    const createExtensionAPI = ({dal, pointers, extensionAPI}: CreateExtArgs): FeaturesExtensionAPI => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI

        const validateFeatureType = (compPointer: Pointer, namespace: string, expectedFeatureType: string) => {
            if (!featuresData[namespace]) {
                throw new Error(`feature ${namespace} doesn't exist`)
            }

            const {featureType} = featuresData[namespace]

            if (featureType !== expectedFeatureType) {
                throw new Error(`feature ${namespace} cannot be used through ${expectedFeatureType} feature APIs`)
            }
            if (featureType === FEATURE_TYPES.COMPONENT) {
                // all components can use a Component feature
                return
            }
            const type = dal.get(pointers.getInnerPointer(compPointer, ['type']))

            if (featureType === FEATURE_TYPES.PAGE && !validPageLevelTypes.includes(type)) {
                throw new Error(`feature ${namespace} cannot be used for a non-page component`)
            }

            if (featureType === FEATURE_TYPES.SITE && type !== 'Document') {
                throw new Error(`feature ${namespace} is a site feature, and cannot be used for a page or component`)
            }

            if (featureType === FEATURE_TYPES.REPEATER && type !== 'RepeaterContainer') {
                throw new Error(
                    `feature ${namespace} is a repeater feature, and cannot be used for any other type of component`
                )
            }
        }

        const getNamespaceData = (componentPointer: Pointer, namespace: string) => {
            const config = getNamespaceConfig(namespace)
            if (config.supportsVariants) {
                return dataModel.components.getItemWithVariants(componentPointer, namespace)
            }
            return dataModel.components.getItem(componentPointer, namespace)
        }

        const getFeatureData = (featureType: string, compPointer: Pointer, featureName: string): FeatureData => {
            validateFeatureType(compPointer, featureName, featureType)
            return getNamespaceData(compPointer, featureName)
        }

        return {
            features: {
                component: {
                    get: (compPointer: Pointer, featureName: string) =>
                        getFeatureData(FEATURE_TYPES.COMPONENT, compPointer, featureName)
                }
            }
        }
    }

    return {
        name: 'features',
        createExtensionAPI,
        dependencies: new Set(['dataModel'])
    }
}

export {createExtension}
