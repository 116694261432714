import type {Pointer, PS} from '@wix/document-services-types'
import {coreTpaUtils} from '@wix/santa-ds-libs/src/warmupUtils'
import _ from 'lodash'
import tpa from 'tpa'
import dataModel from '../../dataModel/dataModel'
import generalInfo from '../../siteMetadata/generalInfo'
import dsUtils from '../../utils/utils'
import tpaConstants from '../constants'

const isSiteSaved = function (ps: PS) {
    return !generalInfo.isFirstSave(ps)
}

const isTpaByCompType = function (compType: string) {
    return _.includes(tpaConstants.COMP_TYPES, compType) || _.includes(tpaConstants.TPA_COMP_TYPES, compType)
}

const isTpaComp = (ps: PS, compPointer: Pointer) => {
    const componentType = dsUtils.getComponentType(ps, compPointer)
    return isTpaByCompType(componentType)
}

const isTpaByDataType = function (dataType: string) {
    return _.includes(tpaConstants.DATA_TYPE, dataType)
}

const sendBIEvent = function (ps: PS, msg, origin) {
    const viewMode = ps.pointers.components.getViewMode(ps.siteAPI.getFocusedRootId() as any)
    const pagePointer = ps.pointers.components.getPage(ps.siteAPI.getFocusedRootId(), viewMode)
    const compPointer = ps.pointers.components.getComponent(msg.compId, pagePointer)
    const compData = dataModel.getDataItem(ps, compPointer)
    const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
    tpa.common.bi.sendBIEvent(msg, origin, compData, clientSpecMap, ps.siteAPI.reportBI.bind(ps.siteAPI))
}

const getModalCompData = function (ps: PS, compId: string) {
    const modalData = ps.siteAPI.getModalCompData()
    return compId === _.get(modalData, ['modalStructure', 'id']) ? modalData.modalData : null
}

const getPopupCompData = function (ps: PS, compId: string) {
    const popupsData = ps.siteAPI.getPopupsCompData()
    const popupData = _.find(popupsData, ['structure.id', compId])
    return popupData ? popupData.popupData : null
}

const notifyTPAAPICalledFromPanel = (ps: PS, appDefinitionId: string) => {
    ps.extensionAPI.platformSharedState.notifyPlatformAPIWasCalled(appDefinitionId, {})
}

export default {
    isSiteSaved,
    isTpaComp,
    isTpaByCompType,
    isTpaByAppType: coreTpaUtils.isTpaByAppType,
    isTpaByDataType,
    sendBIEvent,
    getModalCompData,
    getPopupCompData,
    notifyTPAAPICalledFromPanel
}
