import type {Layout, PS} from '@wix/document-services-types'
import _ from 'lodash'
import imageClientLib from '@wix/image-client-api/dist/imageClientApi'
import component from '../../component/component'
import componentStylesAndSkinsAPI from '../../component/componentStylesAndSkinsAPI'
import {imageUtils} from '@wix/santa-core-utils'

function parseSkinParams(ps: PS, compPointer, skinExports) {
    const paddingLeft =
        parseInt(componentStylesAndSkinsAPI.skin.getCompSkinParamValue(ps, compPointer, 'contentPaddingLeft'), 10) || 0
    const paddingRight =
        parseInt(componentStylesAndSkinsAPI.skin.getCompSkinParamValue(ps, compPointer, 'contentPaddingRight'), 10) || 0
    const paddingTop =
        parseInt(componentStylesAndSkinsAPI.skin.getCompSkinParamValue(ps, compPointer, 'contentPaddingTop'), 10) || 0
    const paddingBottom =
        parseInt(componentStylesAndSkinsAPI.skin.getCompSkinParamValue(ps, compPointer, 'contentPaddingBottom'), 10) ||
        0
    const parsedParams: any = {}
    const contentPaddingLeft = paddingLeft + parseInt(skinExports.contentPaddingLeft || 0, 10)
    const contentPaddingRight = paddingRight + parseInt(skinExports.contentPaddingRight || 0, 10)
    const contentPaddingTop = paddingTop + parseInt(skinExports.contentPaddingTop || 0, 10)
    const contentPaddingBottom = paddingBottom + parseInt(skinExports.contentPaddingBottom || 0, 10)

    parsedParams.contentPaddingHorizontal = contentPaddingLeft + contentPaddingRight
    parsedParams.contentPaddingVertical = contentPaddingTop + contentPaddingBottom

    return parsedParams
}

function getImageCompSizeBySkinParams(photoOriginalSize, parsedSkinParams) {
    const width = photoOriginalSize.width - parsedSkinParams.contentPaddingHorizontal,
        height = photoOriginalSize.height - parsedSkinParams.contentPaddingVertical
    return {
        width: width > 0 ? width : 16,
        height: height > 0 ? height : 16
    }
}

function isImageSizeChanged(ps: PS, compPointer, newLayout) {
    if (_.isUndefined(newLayout.height) && _.isUndefined(newLayout.width)) {
        return false
    }
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const currentLayout = ps.dal.get(layoutPointer)
    return (
        (!_.isUndefined(newLayout.height) && newLayout.height !== currentLayout.height) ||
        (!_.isUndefined(newLayout.width) && newLayout.width !== currentLayout.width)
    )
}

export default function (ps: PS, compPointer, newLayout: Layout) {
    if (!isImageSizeChanged(ps, compPointer, newLayout)) {
        return
    }
    const compProperties = component.properties.get(ps, compPointer)
    const compData = component.data.get(ps, compPointer)
    const compLayout = component.layout.get(ps, compPointer)
    if (compProperties.displayMode === 'fitWidth') {
        const componentOriginalSize = {
            width: newLayout.width ? newLayout.width : compLayout.width,
            height: newLayout.height ? newLayout.height : compLayout.height
        }
        const imageOriginalSize = {width: compData.width, height: compData.height}
        const parsedSkinParams: any = parseSkinParams(
            ps,
            compPointer,
            componentStylesAndSkinsAPI.skin.getComponentSkinExports
        )
        if (newLayout.height) {
            const resFitHeight = imageUtils.getContainerSize(
                imageClientLib,
                getImageCompSizeBySkinParams(componentOriginalSize, parsedSkinParams),
                imageOriginalSize,
                imageClientLib.fittingTypes.LEGACY_FIT_HEIGHT
            )
            newLayout.width = resFitHeight.width + parsedSkinParams.contentPaddingHorizontal
        } else {
            const resFitWidth = imageUtils.getContainerSize(
                imageClientLib,
                getImageCompSizeBySkinParams(componentOriginalSize, parsedSkinParams),
                imageOriginalSize,
                imageClientLib.fittingTypes.LEGACY_FIT_WIDTH
            )
            newLayout.height = resFitWidth.height + parsedSkinParams.contentPaddingVertical
        }
    }
}
