import styleMap from './styleMap.json'

function getDefaultSkinName(compName) {
    return styleMap[compName]?.default?.skin
}

function getSkinAndStyle(allTheme, proxyName, styleNS, styleId, skin) {
    if (skin && styleId && allTheme[styleId]) {
        return {skin, styleId}
    } else if (skin) {
        return {skin}
    }

    const styleObj = styleMap[proxyName][styleNS] || styleMap[proxyName].default
    styleId = styleId || styleObj.style

    if (styleId && allTheme[styleId]) {
        return {
            styleId,
            skin: allTheme[styleId].skin
        }
    }

    return {
        styleId: styleObj.style,
        skin: styleObj.skin
    }
}

/**
 * @class wixappsCore.styleData
 */
export default {
    getDefaultSkinName,
    getSkinAndStyle
}
