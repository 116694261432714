import type {Pointer, PS, Rect} from '@wix/document-services-types'
import * as mobileCore from '@wix/mobile-conversion'
import _ from 'lodash'
import component from '../../component/component'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import structure from '../../structure/structure'

const getMetaData = (ps: PS, comp, pageId: string, attributeName: string) =>
    componentsMetaData.public.getMobileConversionConfigByName(ps, ps.dal.get(comp), attributeName, pageId)
const getAffectedComps = (ps: PS, freedRect, comps) =>
    _.filter(comps, p => getBottomY(component.layout.get(ps, p)) > freedRect.y)
const translateY = (ps: PS, comp, yOffset) =>
    structure.updateCompLayout(ps, comp, {y: component.layout.get(ps, comp).y + yOffset})
const getBottomY = layout => layout.y + layout.height
const getYProjection = layout => [layout.y, getBottomY(layout)]
const getYOverlappingComps = (ps: PS, rect, comps) =>
    _.filter(
        comps,
        p =>
            mobileCore.conversionUtils.getRangesOverlap(
                getYProjection(component.layout.get(ps, p)),
                getYProjection(rect)
            ) > 0
    )

function translateAffectedComps(ps: PS, freedRect, affectedComps) {
    const overlappingComps = getYOverlappingComps(ps, freedRect, affectedComps)
    const yOffsets = calculateYOffsets(ps, freedRect, overlappingComps)
    if (yOffsets.overlappingSiblingsOffset > 0) {
        _.forEach(overlappingComps, comp => translateY(ps, comp, -yOffsets.overlappingSiblingsOffset))
    }
    const nonOverlappingComps = _.difference(affectedComps, overlappingComps)
    if (yOffsets.lowerSiblingsOffset > 0) {
        _.forEach(nonOverlappingComps, comp => translateY(ps, comp, -yOffsets.lowerSiblingsOffset))
    }
    return yOffsets.lowerSiblingsOffset
}

function calculateYOffsets(ps: PS, freedRect: Rect, overlappingComps: Pointer[]) {
    if (_.isEmpty(overlappingComps)) {
        return {overlappingSiblingsOffset: 0, lowerSiblingsOffset: freedRect.height}
    }
    const topComp = _.minBy(overlappingComps, compPointer => component.layout.get(ps, compPointer).y)
    const topYDistanceToTopComp = component.layout.get(ps, topComp).y - freedRect.y
    const bottomComp = _.maxBy(overlappingComps, siblingPointer => getBottomY(component.layout.get(ps, siblingPointer)))
    const bottomYDistanceToBottomComp = getBottomY(freedRect) - getBottomY(component.layout.get(ps, bottomComp))
    if (topYDistanceToTopComp > 0 && bottomYDistanceToBottomComp > 0) {
        return {
            overlappingSiblingsOffset: topYDistanceToTopComp,
            lowerSiblingsOffset: topYDistanceToTopComp + bottomYDistanceToBottomComp
        }
    }
    if (topYDistanceToTopComp > 0 && bottomYDistanceToBottomComp <= 0) {
        return {overlappingSiblingsOffset: topYDistanceToTopComp, lowerSiblingsOffset: topYDistanceToTopComp}
    }
    if (topYDistanceToTopComp <= 0 && bottomYDistanceToBottomComp > 0) {
        return {overlappingSiblingsOffset: 0, lowerSiblingsOffset: bottomYDistanceToBottomComp}
    }
    return {overlappingSiblingsOffset: 0, lowerSiblingsOffset: 0}
}

function translateCompsToFillFreedRect(ps: PS, reducedComp, pageId: string) {
    const yOffset = translateAffectedComps(ps, reducedComp.freedRect, reducedComp.affectedComps)
    if (yOffset === 0) {
        return
    }
    const parentLayout = component.layout.get(ps, reducedComp.parentPointer)
    const minHeight = getMetaData(ps, reducedComp.parentPointer, pageId, 'minHeight') || 0
    const newParentHeight = _.max([parentLayout.height - yOffset, minHeight])
    structure.updateCompLayout(ps, reducedComp.parentPointer, {height: newParentHeight})
    const topParent = component.getContainer(ps, reducedComp.parentPointer)
    if (!topParent) {
        return
    }
    const newFreedRect = _.assign(parentLayout, {
        y: parentLayout.y + newParentHeight,
        height: parentLayout.height - newParentHeight
    })
    const reducedParent = reduceComp(ps, reducedComp.parentPointer, newFreedRect)
    translateCompsToFillFreedRect(ps, reducedParent, pageId)
}

function reduceComp(ps: PS, compPointer: Pointer, freedRect?) {
    freedRect = freedRect || component.layout.get(ps, compPointer)
    return {
        freedRect,
        parentPointer: component.getContainer(ps, compPointer),
        affectedComps: getAffectedComps(ps, freedRect, component.getSiblings(ps, compPointer))
    }
}

function hideMobileComponent(ps: PS, compPointer: Pointer, pageId: string, setting = {updateLayout: true}) {
    if (!setting.updateLayout) {
        component.deleteComponent(ps, compPointer, false)
        return
    }
    const reducedComp = reduceComp(ps, compPointer)
    component.deleteComponent(ps, compPointer, false)
    translateCompsToFillFreedRect(ps, reducedComp, pageId)
}

export default {hideMobileComponent}
