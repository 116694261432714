import type {Pointer, CompRef, PS} from '@wix/document-services-types'
import componentStructureInfo from '../component/componentStructureInfo'
import page from '../page/page'
import {getConsideringOverrides, updateConsideringOverrides} from '../overrides/overrides'
import constants from '../constants/constants'
const {DATA_TYPES} = constants

const updateDataItem = (ps: PS, componentPointer: Pointer, dataItem, useOriginalLanguage: boolean) => {
    if (componentStructureInfo.isPageComponent(ps, componentPointer)) {
        page.data.update(ps, componentPointer.id, dataItem, useOriginalLanguage)
    } else {
        updateConsideringOverrides(ps, componentPointer as CompRef, dataItem, DATA_TYPES.data, useOriginalLanguage)
    }
}

const getDataItem = (
    ps: PS,
    componentPointer: CompRef,
    deleteId: boolean = false,
    useOriginalLanguage: boolean = false
) => {
    return getConsideringOverrides(ps, componentPointer, DATA_TYPES.data, deleteId, useOriginalLanguage)
}

export default {
    updateDataItem,
    getDataItem
}
