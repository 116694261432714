import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import componentLayout from './componentLayout'

const LAYOUT_PROPS = [
    'x',
    'y',
    'width',
    'height',
    'rotationInDegrees',
    'scale',
    'fixedPosition',
    'anchors',
    'docked',
    'aspectRatio'
]

function validate(fields, isValid, type, layout) {
    _(layout)
        .pick(fields)
        .forEach(function (value, key) {
            if (!isValid(value)) {
                throw new Error(`${key} is not a ${type}`)
            }
        })
}

const validateNumbersInLayout = _.partial(
    validate,
    ['x', 'y', 'width', 'height', 'rotationInDegrees', 'scale'],
    v => !isNaN(v),
    'number'
)
const validateBooleansInLayout = _.partial(validate, ['fixedPosition'], v => typeof v === 'boolean', 'boolean')
const validateObjectsInLayout = _.partial(validate, ['docked'], _.isPlainObject, 'object')
const validateArraysInLayout = _.partial(validate, ['anchors'], _.isArray, 'array')

function validateOnlySupportedProperties(layout) {
    const unsupportedProperties = _.omit(layout, LAYOUT_PROPS)
    if (!_.isEmpty(unsupportedProperties)) {
        throw new Error(`Properties are not supported in layout:${_.keys(unsupportedProperties)}`)
    }
}

function validateLayoutSchema(layout) {
    if (layout) {
        validateNumbersInLayout(layout)
        validateBooleansInLayout(layout)
        validateObjectsInLayout(layout)
        validateArraysInLayout(layout)
        validateOnlySupportedProperties(layout)
    }

    if (!_.isUndefined(layout.rotationInDegrees)) {
        layout.rotationInDegrees = santaCoreUtils.math.parseDegrees(layout.rotationInDegrees)
    }
}

function getChangedLayout(currentLayout, newLayout) {
    return _.reduce(
        newLayout,
        function (result, val, key) {
            if (_.isPlainObject(newLayout[key])) {
                result[key] = getChangedLayout(currentLayout, newLayout[key])
            } else if (!_.isEqual(newLayout[key], currentLayout[key])) {
                result[key] = val
            }
            return result
        },
        {}
    )
}

function notifyComponentWarnings(ps, compPointer, warnings, currentLayout, requestedLayoutChanges) {
    const layoutWarning = _.curry(layoutChangeWarning)(currentLayout, requestedLayoutChanges)
    santaCoreUtils.log.warn(
        layoutChangesIgnoredMessage({
            id: compPointer.id,
            componentType: ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'componentType')),
            layoutChanges: _(warnings).map(layoutWarning).join(', ')
        })
    )
}

const layoutChangesIgnoredMessage = _.template(
    'The following layout changes were ignored for comp {id: <%= id %>, componentType: <%= componentType %>}, due to failure of layout validations: \n<%= layoutChanges %>'
)

const COMP_LAYOUT_CHANGE_INVALID = {
    WIDTH: 'width',
    HEIGHT: 'height',
    X: 'x',
    Y: 'y',
    ROTATION: 'rotationInDegrees',
    FIXED_POSITION: 'fixedPosition'
}

function layoutChangeWarning(currentLayout, changedLayout, property) {
    return `${property} change: ${currentLayout[property]} -> ${changedLayout[property]}`
}

function validateLayoutRestrictions(ps, compPointer, newLayout, currentLayout) {
    const changedLayout = getChangedLayout(currentLayout, newLayout)
    const warnings = []

    // @ts-expect-error
    if (!_.isUndefined(changedLayout.width) && !componentsMetaData.public.isHorizontallyResizable(ps, compPointer)) {
        delete newLayout.width
        warnings.push(COMP_LAYOUT_CHANGE_INVALID.WIDTH)
    }
    // @ts-expect-error
    if (!_.isUndefined(changedLayout.height) && !componentsMetaData.public.isVerticallyResizable(ps, compPointer)) {
        delete newLayout.height
        warnings.push(COMP_LAYOUT_CHANGE_INVALID.HEIGHT)
    }
    // @ts-expect-error
    if (!_.isUndefined(changedLayout.x) && !componentsMetaData.public.isHorizontallyMovable(ps, compPointer)) {
        delete newLayout.x
        warnings.push(COMP_LAYOUT_CHANGE_INVALID.X)
    }
    // @ts-expect-error
    if (!_.isUndefined(changedLayout.y) && !componentsMetaData.public.isVerticallyMovable(ps, compPointer)) {
        // @ts-expect-error
        if (_.isUndefined(changedLayout.isFixedPosition)) {
            delete newLayout.y
            warnings.push(COMP_LAYOUT_CHANGE_INVALID.Y)
        }
    }

    // @ts-expect-error
    if (!_.isUndefined(changedLayout.rotationInDegrees) && !componentsMetaData.public.isRotatable(ps, compPointer)) {
        delete newLayout.rotationInDegrees
        warnings.push(COMP_LAYOUT_CHANGE_INVALID.ROTATION)
    }

    // @ts-expect-error
    if (!_.isUndefined(changedLayout.fixedPosition) && !componentsMetaData.public.canBeFixedPosition(ps, compPointer)) {
        delete newLayout.fixedPosition
        warnings.push(COMP_LAYOUT_CHANGE_INVALID.FIXED_POSITION)
    }

    if (warnings.length) {
        notifyComponentWarnings(ps, compPointer, warnings, currentLayout, changedLayout)
    }
}

function resolveLayoutConflicts(mergedLayout, newLayout, positionAndSize) {
    if (newLayout.docked) {
        if (santaCoreUtils.layoutUtils.isHorizontallyDocked(mergedLayout)) {
            if (newLayout.docked.hCenter) {
                delete mergedLayout.docked.left
                delete mergedLayout.docked.right
            } else if (newLayout.docked.left || newLayout.docked.right) {
                delete mergedLayout.docked.hCenter
            }

            mergedLayout.width = positionAndSize.width
        }
        if (santaCoreUtils.layoutUtils.isVerticallyDocked(mergedLayout)) {
            if (newLayout.docked.vCenter) {
                delete mergedLayout.docked.top
                delete mergedLayout.docked.bottom
            } else if (newLayout.docked.top || newLayout.docked.bottom) {
                delete mergedLayout.docked.vCenter
            }
            mergedLayout.height = positionAndSize.height
        }
    }
}

function validateMergedLayout(mergedLayout) {
    if (santaCoreUtils.layoutUtils.isDockToScreen(mergedLayout) && mergedLayout.fixedPosition) {
        throw new Error('Dock to screen component cannot be fixed position')
    }
}

function getValidLayoutToUpdate(ps: PS, compPointer, newLayout) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const currentLayout = ps.dal.get(layoutPointer)

    const layoutToBeMerged = _.pick(newLayout, LAYOUT_PROPS)

    validateNewDockValues(newLayout)

    if (layoutToBeMerged.docked) {
        layoutToBeMerged.docked = getMergedDockValuesToUpdate(currentLayout.docked, newLayout.docked)
    }

    validateLayoutSchema(layoutToBeMerged)
    validateLayoutRestrictions(ps, compPointer, layoutToBeMerged, currentLayout)

    const layoutDiff = getChangedLayout(currentLayout, newLayout)
    const mergedLayout = _.assign({}, currentLayout, layoutToBeMerged)
    const positionAndSize = componentLayout.getPositionAndSize(ps, compPointer, mergedLayout)
    resolveLayoutConflicts(mergedLayout, layoutDiff, positionAndSize)
    validateMergedLayout(mergedLayout)

    return mergedLayout
}

function validateNewDockValues(layout) {
    if (layout.docked) {
        if (layout.docked.hCenter && (layout.docked.left || layout.docked.right)) {
            throw new Error('cannot set both hCenter and left OR right')
        }
        if (layout.docked.vCenter && (layout.docked.top || layout.docked.bottom)) {
            throw new Error('cannot set both vCenter and top OR bottom')
        }
    }
}

function getMergedDockValuesToUpdate(currentDock, newDock) {
    if (currentDock) {
        return _.merge({}, currentDock, newDock)
    }
    return newDock
}

function validateLayout(ps: PS, compPointer) {
    const layout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
    validateLayoutSchema(layout)
}

export default {
    getValidLayoutToUpdate,
    validateLayout
}
