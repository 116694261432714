import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import dsUtils from '../utils/utils'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import constants from './common/constants'

const sessionStateTypes = new Set(constants.SESSION_STATE_TYPES)

function updateSessionState(ps: PS, stateMap) {
    _.forEach(stateMap, (state, compId) => {
        const compRef = componentDetectorAPI.getComponentById(ps, compId)
        const compType = dsUtils.getComponentType(ps, compRef)
        if (!sessionStateTypes.has(compType)) {
            throw new Error(`Component of type ${compType} does not support session state`)
        }

        ps.dal.full.set(ps.pointers.getInnerPointer(ps.pointers.platform.getAppStatePointer(), [compId]), state)
    })
}

function getSessionState(ps: PS, compRef) {
    return ps.dal.full.get(ps.pointers.getInnerPointer(ps.pointers.platform.getAppStatePointer(), [compRef.id]))
}

export default {
    getSessionState,
    updateSessionState
}
