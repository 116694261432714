import immutable from 'immutable'

const isImmutable = (val: any) => val?.toJS

export const createImmutableDAL = (store: any) => {
    let lastState: any, initialState: any, uncommittedSnapshot: any //eslint-disable-line one-var
    let currentState = immutable.fromJS(store)

    return {
        getByPath(path: string[]) {
            const val = currentState.getIn(path)
            if (isImmutable(val)) {
                return val.toJS()
            }
            return val
        },
        setByPath(path: string, value: any) {
            currentState = currentState.setIn(path, immutable.fromJS(value))
        },
        getKeysByPath(path: string) {
            return currentState.getIn(path).keySeq().toArray()
        },
        isPathExist(path: string) {
            return currentState.hasIn(path)
        },
        removeByPath(path: string) {
            currentState = currentState.removeIn(path)
        },
        pushByPath(path: string, value: any) {
            currentState = currentState.updateIn(path, (list: any) => list.push(immutable.fromJS(value)))
        },
        takeSnapshot() {
            if (!initialState) {
                initialState = currentState
            }
            uncommittedSnapshot = lastState
            lastState = currentState
        },
        getSnapshots() {
            if (!initialState) {
                return {}
            }
            return {
                initial: initialState,
                last: lastState,
                current: currentState
            }
        },
        rollbackSnapshot() {
            if (uncommittedSnapshot) {
                lastState = uncommittedSnapshot
                uncommittedSnapshot = undefined
            }
        },
        commitSnapshot() {
            if (uncommittedSnapshot) {
                uncommittedSnapshot = undefined
            }
        }
    }
}
