import {constants} from '@wix/santa-core-utils'
import _ from 'lodash'
import mobileConversionFacade from '../mobileConversion/mobileConversionFacade'
import experiment from 'experiment-amd'
import type {PS, Pointer} from '@wix/document-services-types'
import {getReportableFromError, ReportableError} from '@wix/document-manager-utils'

function createCompPriorityFn(compOrder) {
    const componentsPriorityMap = _.invert(compOrder)

    return function (comp) {
        const compId = _.get(comp, ['id'], comp)
        const compPriority = componentsPriorityMap[compId]
        return compPriority ? Number(compPriority) : -1
    }
}

/**
 * @param {Object} compIdsOrder
 * @returns {function(*, *): number}
 */
function createSortingPredicat(compIdsOrder) {
    const getCompPriority = createCompPriorityFn(compIdsOrder)

    return function (comp1, comp2) {
        const comp1Priority = getCompPriority(comp1)
        const comp2Priority = getCompPriority(comp2)

        return comp1Priority - comp2Priority
    }
}

function reorderChildrenOfComponent(ps: PS, componentPointer: Pointer, childComponentsOrder: object) {
    const childrenContainerPointer = ps.pointers.full.components.getChildrenContainer(componentPointer)
    const children = ps.dal.full.get(childrenContainerPointer)

    if (!children) {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                message: `Missing component: ${JSON.stringify(componentPointer)}`,
                errorType: 'reorderingMissingChildrenError',
                extras: {
                    componentPointer
                }
            })
        )

        return
    }

    if (!(ps.dal.full.get(componentPointer).componentType === 'wysiwyg.viewer.components.HoverBox')) {
        children.sort(createSortingPredicat(childComponentsOrder))
    }

    ps.dal.full.set(childrenContainerPointer, children)
}

function runSeparatly(func: Function, resultOfPrevFunction: any) {
    return new Promise<any>(function (resolve, reject) {
        // @ts-expect-error
        _.delay(function () {
            let funcResult
            try {
                funcResult = func(resultOfPrevFunction)
                resolve(funcResult)
            } catch (e) {
                reject(e)
            }
        })
    })
}

function flowThrough(listOfFuncs: Function[]) {
    return _.reduce(
        listOfFuncs,
        function (previousPromise: Promise<any>, atomicFunction: Function) {
            // eslint-disable-next-line promise/prefer-await-to-then
            return previousPromise.then(resultOfPrevFunction => runSeparatly(atomicFunction, resultOfPrevFunction))
        },
        Promise.resolve()
    )
}

function reorderPageContainer(ps: PS, pageId: string, containerId: string, containerOrder: object, viewMode: string) {
    const pagePointer = ps.pointers.components.getPage(pageId, viewMode)
    const componentPointer = ps.pointers.full.components.getComponent(containerId, pagePointer)

    if (!componentPointer || !ps.dal.full.isExist(componentPointer)) {
        ps.extensionAPI.logger.captureError(
            new ReportableError({
                message: `Missing component: ${JSON.stringify(componentPointer)}`,
                errorType: 'reorderingMissingComponentError',
                extras: {
                    componentPointer
                }
            })
        )

        return
    }

    reorderChildrenOfComponent(ps, componentPointer, containerOrder)
}

function reorderAllContainersOnPage(ps: PS, pageId: string, orderOfContainers, viewMode: string): Promise<unknown[]> {
    return Promise.all(
        _.transform(
            orderOfContainers,
            function (promises, containerOrder, containerId) {
                // @ts-expect-error
                const reorderContainerPromise = runSeparatly(
                    // @ts-expect-error
                    _.partial(reorderPageContainer, ps, pageId, containerId, containerOrder, viewMode)
                )

                promises.push(reorderContainerPromise)
                return promises
            },
            []
        )
    )
}

function reorderOnePage(ps: PS, viewMode: string, pageId: string) {
    return flowThrough([
        function getComponentsOrder() {
            try {
                return mobileConversionFacade.getComponentsOrder(ps, pageId, viewMode)
            } catch (e) {
                const err = getReportableFromError(e, {
                    message: `Failed getting component order`,
                    errorType: 'getComponentsOrderError'
                })
                ps.extensionAPI.logger.captureError(err)

                throw err
            }
        },
        function orderPageChildrenComponents(componentsOrder) {
            if (pageId !== 'masterPage') {
                const pagePointer = ps.pointers.components.getPage(pageId, viewMode)
                reorderChildrenOfComponent(ps, pagePointer, componentsOrder[pageId])
            }
            return _.omit(componentsOrder, pageId)
        },
        function orderPageContainersChildrenComponents(orderOfContainers) {
            return reorderAllContainersOnPage(ps, pageId, orderOfContainers, viewMode)
        }
    ])
}

const reorderComponents = async (ps: PS, pagesToReorder?) => {
    const desktopJobs = _.map(pagesToReorder, _.partial(reorderOnePage, ps, constants.VIEW_MODES.DESKTOP)) || []
    const mobileJobs = _.map(pagesToReorder, _.partial(reorderOnePage, ps, constants.VIEW_MODES.MOBILE)) || []

    const reorderPromise = Promise.all(desktopJobs.concat(mobileJobs))

    if (experiment.isOpen('dm_saveWaitForReorder')) {
        try {
            await reorderPromise
        } catch (e) {
            // Letting the save continue under the assumption that it is better than failing it
            const err = getReportableFromError(e, {
                message: `Failed reordering`,
                errorType: 'reorderingError'
            })
            ps.extensionAPI.logger.captureError(err)
        }
    }
}

export default {
    reorderComponents
}
