import santaComponents from '@wix/santa-components'
import _ from 'lodash'
import {sessionFreezer, log} from '@wix/santa-ds-libs/src/warmupUtils'

const proxyDefinitions = {}

sessionFreezer.freeze(proxyDefinitions)

function register(name, proxyDef) {
    proxyDef.displayName = `${name}Proxy`
    proxyDefinitions[name] = proxyDef
}

function extend(name, proxyDefinitionExtension) {
    if (!proxyDefinitions.hasOwnProperty(name)) {
        log.error(`Trying to extend component [${name}] but the component is not defined`)
        return
    }

    const proxyDefinition = proxyDefinitions[name]
    proxyDefinition.mixins = [proxyDefinitionExtension].concat(proxyDefinition.mixins || [])
}

const missingProxy = function (proxyName) {
    return {
        displayName: proxyName,
        render() {
            return santaComponents.utils.createReactElement('div', null, proxyName)
        }
    }
}
const missingProxyList = [
    'Label',
    'ClippedParagraph',
    'ClippedParagraph2',
    'Date',
    'DateEdit',
    'TimeEdit',
    'InlineText',
    'InlineTextInput',
    'TextInput',
    'ErasableTextInput',
    'NumberInput',
    'NumericStepper',
    'CheckBox',
    'CheckBoxViewProxy',
    'RichTextEditor',
    'RichTextEditorInline',
    'Price',
    'EnumSelector',
    'Image',
    'Video',
    'VideoSelector',
    'VideoThumb',
    'ImageSelector',
    'ImageInput',
    'VerticalList',
    'FlowList',
    'MultiColumn',
    'VerticalListEditor',
    'DraggableListItem',
    'Box',
    'VBox',
    'HBox',
    'Field',
    'FieldBox',
    'TextField',
    'DataEditField',
    'Stack',
    'Css',
    'InlineSpacer',
    'HorizontalLine',
    'VerticalLine',
    'Switch',
    'SwitchBox',
    'OptionalArea',
    'SuperFlow',
    'Button',
    'Button2',
    'Table',
    'Deck',
    'TabMenu',
    'Icon',
    'StringArrayInput',
    'Toggle',
    'MusicPlayer',
    'AudioInput',
    'InlineSvg',
    'Link',
    'LinkSelector',
    'OptionsList',
    'SelectOptionsList',
    'OptionsListInput',
    'ComboBox',
    'RadioGroup',
    'CheckBoxGroup',
    'TextArea',
    'Area',
    'Container',
    'ZoomLink',
    'AppLink',
    'ZoomLayout',
    'FixedRatioLayout',
    'GoogleMap',
    'LocationSelector',
    'TagInput',
    'DragAndDropVList',
    'List2',
    'PaginatedList',
    'UnstyledLabel',
    'TooltipIcon',
    'Help',
    'IFrame',
    'ImageButton',
    'TPAGallery',
    'MediaRichTextEditorInline',
    'MediaLabel',
    'MediaThumb',
    'Gallery',
    'GridGallery',
    'SliderGallery',
    'ColumnGallery',
    'PaginatedColumnGallery'
]
_.forEach(missingProxyList, function (proxyName) {
    register(proxyName, missingProxy(proxyName))
})

export default {
    /**
     * Registers a proxy definition in the proxy factory
     * @param name The name of the proxy
     * @param proxyDefinition The js object (dictionary) that defines the proxy. It will instantiate
     * when used for the first time. In order to change in runtime, use the invalidate method.
     */
    register,

    /**
     * Allows extending the definition of a proxy class by extension packages. This
     * is used for enrichment of proxies for automation qa, editor decorations, etc.
     * @param name The name of the proxy to extend
     * @param proxyDefinitionExtension The overriding methods and properties of the proxy
     */
    extend
}
