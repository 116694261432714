import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import experiment from 'experiment-amd'
import hooks from '../hooks/hooks'
import pathUtils from './utils/pathUtils'
import wixCodeLifecycleService from './services/wixCodeLifecycleService'
import userCodeCacheKillerService from './services/userCodeCacheKillerService'
import wixCodeMonitoring from './services/wixCodeMonitoringWrapper'
import fileSystemAPI from './services/fileSystemAPI'
import codePackageAPI from './services/codePackageAPI'
import pagesAPI from './services/pagesAPI'
import pageCodeDuplicator from './services/pageCodeDuplicator'
import codeIntelligenceService from './services/codeIntelligenceService'
import platform from '../platform/platform'
import wixCodeConstants from './utils/constants'
import clientSpecMap from '../siteMetadata/clientSpecMap'
import bi from '../bi/bi'
import biEvents from '../bi/events.json'
import wixCode from '@wix/santa-ds-libs/src/wixCode'
import * as santaCoreUtils from '@wix/santa-core-utils'
import clientSpecMapUtils from './utils/clientSpecMapUtils'
import generalInfo from '../siteMetadata/generalInfo'
import saveAPI from '../saveAPI/saveAPI'
import componentCode from '../component/componentCode'
import disabledWixCodeSave from './services/disabledWixCodeSave'
import wixCodeErrors from './utils/errors'
import {ReportableError} from '@wix/document-manager-utils'

function getClientSpec(ps: PS) {
    return _.head(clientSpecMap.filterAppsDataByType(ps, wixCodeConstants.WIX_CODE_SPEC_TYPE))
}

const {joinURL} = santaCoreUtils.urlUtils

function reportEditorLoadedWithApps(ps: PS) {
    const appNames = _(clientSpecMap.getAppsDataWithPredicate(ps, csm => _.filter(csm, 'platformApp')))
        .map('displayName')
        .value()

    if (!_.isEmpty(appNames)) {
        bi.event(ps, biEvents.EDITOR_PLATFORM_APPS_LOADED, {app_list: appNames, number_of_apps: appNames.length})
    }
}

const debouncedFlush = _.debounce(fileSystemAPI.flush, 1000)
const onFileOrFolderChanged = (ps: PS) => {
    if (!ps.runtimeConfig.disableWixCodeContinuousSave) {
        debouncedFlush(ps, {origin: fileSystemAPI.FLUSH_ORIGINS.COUNTINUOUS_SAVE})
    }
}

const reportIfMissingOpenGridApp = (ps: PS) => {
    const isFirstSave = generalInfo.isFirstSave(ps)
    const isProvisioned = !!clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
    if (!isFirstSave && isProvisioned) {
        const lastSavedGridAppId = ps.dal.get(ps.pointers.wixCode.getRevisionGridAppId())
        const openGridAppId = ps.dal.get(ps.pointers.wixCode.getOpenWixCodeAppId())
        if (_.isEmpty(openGridAppId) && !_.isEmpty(lastSavedGridAppId)) {
            ps.logger.captureError(
                new wixCodeErrors.WixCodeMissingOpenGridAppError({openGridAppId, lastSavedGridAppId}),
                {
                    extras: {
                        openGridAppId,
                        lastSavedGridAppId,
                        isOpenGridAppExperimentOpen: experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled'),
                        isFirstSave,
                        isTemplate: generalInfo.isTemplate(ps),
                        isDraft: generalInfo.isDraft(ps)
                    },
                    level: 'error'
                }
            )
        }
    }
}

function initializeWixCode(ps: PS) {
    if (!ps.runtimeConfig.supportsPlatformInitialization) {
        return
    }
    pathUtils.initPaths(ps)

    userCodeCacheKillerService.init(ps)

    if (wixCodeLifecycleService.isProvisioned(ps)) {
        performAfterProvisionActions(ps)
        fileSystemAPI.prefetchUserCode(ps)
    }

    const isReadOnlyPointer = ps.pointers.wixCode.getIsAppReadOnly()
    // if grid-app was "autosaved" then we assume the app is WRITEABLE - readOnly: false
    // if not then we assume that it is NOT WRITEABLE (i.e the user intended to lock it) - readOnly: true
    ps.dal.set(isReadOnlyPointer, !wasGridAppAutosaved())

    ps.extensionAPI.wixCode.syncGridAppToViewer()

    if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
        reportIfMissingOpenGridApp(ps)
    }

    reportEditorLoadedWithApps(ps)
    ps.extensionAPI.wixCodeSharedFileState.subscribeToPathChanges(changedPaths =>
        fileSystemAPI.handleExternalChange(ps, changedPaths)
    )

    hooks.registerHook(hooks.HOOKS.WIX_CODE.FILE_OR_FOLDER_CHANGED, onFileOrFolderChanged)
    ps.extensionAPI.wixCode.updateWixCodeLocalProvisionState(wixCodeLifecycleService.isProvisioned(ps))
    ps.extensionAPI.wixCode.updateWixCodeModelLocalState(!!ps.extensionAPI.wixCode.getEditedGridAppId())
    ps.extensionAPI.wixCode.updateSiteExtensionState(!!clientSpecMapUtils.getExistingWixCodeAppFromPS(ps))
    ps.extensionAPI.wixCode.subscribeToWixCodeProvision(() => {
        performAfterProvisionActions(ps)
        reloadAppsContainer(ps)
    })

    function wasGridAppAutosaved() {
        try {
            if (ps.siteAPI.wasCodeAppIdValueChangedSinceLastSnapshot) {
                return ps.siteAPI.wasCodeAppIdValueChangedSinceLastSnapshot(
                    'before-autosave-apply',
                    ps.pointers.wixCode.getRevisionGridAppId()
                )
            }
            if (ps.dal.wasPointerValueChangedSinceLastSnapshot) {
                return ps.dal.wasPointerValueChangedSinceLastSnapshot(
                    'before-autosave-apply',
                    ps.pointers.wixCode.getRevisionGridAppId()
                )
            }
            // @ts-expect-error
            return ps.dal.wasValueChangedSinceLastSnapshot(
                'before-autosave-apply',
                ps.pointers.wixCode.getRevisionGridAppId()
            )
        } catch (e) {
            // throws if no autosave
            return false
        }
    }
}

function performAfterProvisionActions(ps: PS) {
    fileSystemAPI.handleSchemaInvalidationActions()
    // TODO: replace once platform apps provision flow has been decided
    _tempInitDataBindingApp(ps)
    _tempInitWixCodeEditorApp(ps)
}

function reloadAppsContainer(ps: PS) {
    ps.siteAPI.reloadAppsContainer()
}

const saveSite = async (ps: PS): Promise<void> => {
    const isFirstSave = generalInfo.isFirstSave(ps)
    const isQA = ps.siteAPI.isQaMode()

    if (isFirstSave || isQA) {
        return
    }
    await saveAPI.promises.save(ps, false)
}

function initializeWixCodeWidget(ps: PS) {
    performAfterProvisionActions(ps)
    reloadAppsContainer(ps)
}

async function provisionAsync(ps: PS) {
    if (!wixCodeLifecycleService.isProvisioned(ps)) {
        disabledWixCodeSave.ensureWixCodeSaveAllowed(ps)
    }
    const traceEnd = wixCodeMonitoring.trace(ps, {action: 'provision'})
    try {
        if (generalInfo.isFirstSave(ps)) {
            throw new Error('Cannot provision wix-code on a template')
        }
        const provisionResult = await wixCodeLifecycleService.provision(ps)
        componentCode.generateNicknamesForSite(ps)
        initializeWixCodeWidget(ps)
        ps.extensionAPI.wixCode.updateWixCodeLocalProvisionState(true)
        ps.setOperationsQueue.asyncPreDataManipulationComplete()
        await saveSite(ps)
        traceEnd({message: provisionResult})
        return provisionResult
    } catch (error: any) {
        ps.extensionAPI.wixCode.updateWixCodeLocalProvisionState(false)
        ps.setOperationsQueue.asyncPreDataManipulationComplete(
            null,
            new ReportableError({
                errorType: 'WixCodeProvisionFailed',
                message: error.message
            })
        )
        traceEnd({level: wixCodeMonitoring.levels.ERROR, message: error})
        throw error
    }
}

function provision(ps: PS, callbacks) {
    callbacks = _.defaults({}, callbacks, {onSuccess: _.noop, onError: _.noop})
    provisionAsync(ps).then(callbacks.onSuccess, callbacks.onError) // eslint-disable-line promise/prefer-await-to-then
}

function getFocusedRootRef(ps: PS, compPointers) {
    const currentRootId = ps.siteAPI.getCurrentUrlPageId()
    const viewModes = santaCoreUtils.constants.VIEW_MODES
    const viewMode = ps.siteAPI.isMobileView() ? viewModes.MOBILE : viewModes.DESKTOP
    return compPointers.getPage(currentRootId, viewMode)
}

function getWidgetRef(ps: PS, compRef) {
    const compPointers = ps.pointers.components
    const compRootRef = compPointers.getPageOfComponent(compRef)
    if (!compRootRef) {
        return null
    }
    if (compPointers.isMasterPage(compRootRef)) {
        return getFocusedRootRef(ps, compPointers)
    }
    return compRootRef
}

function _tempInitDataBindingApp(ps: PS) {
    // TODO: replace once platform apps provision flow has been decided
    platform.initApp(ps, _tempGetAppAppDef(ps, 'dbsm-viewer-app', 'dbsm-editor-app', 'dataBinding', true))
}

function _tempInitWixCodeEditorApp(ps: PS) {
    // TODO: replace once platform apps provision flow has been decided
    platform.initApp(ps, _tempGetAppAppDef(ps, '', 'wix-code-editor-app', 'wix-code', false))
}

function _tempGetAppAppDef(ps: PS, viewerAppName, editorAppName, appDefId, verbose) {
    // TODO: replace once platform apps provision flow has been decided
    function parseAppSources(appSources) {
        return _(appSources || '')
            .split(',')
            .invokeMap('split', ':')
            .fromPairs()
            .value()
    }

    function getArtifactUrl(serviceTopology, artifactName, version) {
        const artifactPath = joinURL(serviceTopology.scriptsDomainUrl, 'services', artifactName)
        if (version) {
            return joinURL(artifactPath, version)
        }

        return serviceTopology.scriptsLocationMap[artifactName]
    }

    if (wixCodeLifecycleService.isProvisioned(ps)) {
        const pointer = ps.pointers.general.getServiceTopology()
        const serviceTopology = ps.dal.get(pointer)
        const currentUrl = ps.siteAPI.getCurrentUrl()
        const viewerAppVersion = parseAppSources(_.get(currentUrl, ['query', 'viewerPlatformAppSources']))[appDefId]
        const editorAppVersion = parseAppSources(_.get(currentUrl, ['query', 'editorPlatformAppSources']))[appDefId]
        const appData: any = {
            appDefinitionId: appDefId
        }
        if (viewerAppName) {
            _.set(
                appData,
                ['appFields', 'platform', 'viewerScriptUrl'],
                joinURL(
                    getArtifactUrl(serviceTopology, viewerAppName, viewerAppVersion),
                    `/app${verbose ? '.verbose' : ''}.js`
                )
            )
        }
        if (editorAppName) {
            _.set(
                appData,
                'appFields.platform.editorScriptUrl',
                joinURL(getArtifactUrl(serviceTopology, editorAppName, editorAppVersion), '/editorAppModule.js')
            )
            appData.editorArtifact = editorAppName
        }
        return appData
    }
}

function duplicatePageCode(ps: PS, newPageId, originalPageId) {
    if (wixCodeLifecycleService.isProvisioned(ps)) {
        pageCodeDuplicator.duplicatePageCode(ps, newPageId, originalPageId)
    }
}

hooks.registerHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, reloadAppsContainer)
hooks.registerHook(hooks.HOOKS.PLATFORM.APP_UPDATED, reloadAppsContainer)

function isUserCodeUrl(ps: PS, url: string) {
    const wixCodeSpec = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
    return wixCode.wixCodeUserScriptsService.isUserCodeUrl(url, wixCodeSpec, ps)
}

function getSourceMapUrl(ps: PS, userCodeUrl: string) {
    const gridAppId = ps.extensionAPI.wixCode.getEditedGridAppId()
    const wixCodeSpec = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
    return wixCode.wixCodeUserScriptsService.getSourceMapUrl(userCodeUrl, gridAppId, wixCodeSpec, ps)
}

const setIsolatedGridApp = (ps: PS, gridAppId: string) => {
    ps.dal.set(ps.pointers.wixCode.getIsolatedGridApp(), gridAppId)
}

const getIsolatedGridApp = (ps: PS) => ps.dal.get(ps.pointers.wixCode.getIsolatedGridApp())
const getEditedGridApp = (ps: PS) => ps.extensionAPI.wixCode.getEditedGridAppId()

export default {
    initializeWixCode,
    provision,
    provisionAsync,
    isProvisioned: wixCodeLifecycleService.isProvisioned,
    getClientSpec,
    getWidgetRef,
    duplicatePageCode,
    setIsolatedGridApp,
    getIsolatedGridApp,
    getEditedGridApp,
    getCompSdkType: codeIntelligenceService.getCompSdkType,
    log: {
        levels: wixCodeMonitoring.levels,
        trace: wixCodeMonitoring.trace
    },
    codeIntelligence: codeIntelligenceService,
    fileSystem: fileSystemAPI,
    codePackages: codePackageAPI,
    pages: pagesAPI,
    userScripts: {
        isUserCodeUrl,
        getSourceMapUrl
    }
}
