import documentMode from '../../documentMode/documentMode'

function removeComponentHook(privateServices, compRef) {
    const previewState = documentMode.getComponentPreviewState(privateServices) || {}
    if (previewState[compRef.id]) {
        documentMode.setComponentPreviewState(privateServices, compRef.id, null)
    }
}

export default {
    removeComponentHook
}
