import type {PS, Rect, Experiment} from '@wix/document-services-types'
import _ from 'lodash'
import {repeaterUtils, variantsUtils, refStructureUtils} from '@wix/document-manager-extensions'
import type {LayoutDoneCbArgs} from '@wix/viewer-manager-interface'
const {isRepeatedComponent, isRepeaterMasterItem, getRepeaterTemplateId} = repeaterUtils
const {hasActiveVariants} = variantsUtils
import {pointerUtils, CoreLogger} from '@wix/document-manager-core'

const {getPointer} = pointerUtils
const {isRefPointer} = refStructureUtils

interface CompMeasure {
    height: number
    width: number
    relativeToContainerLeft?: number
    relativeToContainerTop: number
}

const VALID_PX_THRESHOLD = 0.5
const isNumber = _.isFinite
const isNumberOrUndefined = (n: number | undefined) => _.isUndefined(n) || _.isFinite(n)
const isPositive = (n: number) => isNumber(n) && n >= 0
const areAllNumbers = (...args: any[]) => _.every(args, isNumber)
const areAllPositive = (...args: any[]) => _.every(args, isPositive)

const areValidMeasures = (
    compId: string,
    {height, width, relativeToContainerLeft, relativeToContainerTop}: CompMeasure
) => areAllPositive(height, width) && isNumber(relativeToContainerTop) && isNumberOrUndefined(relativeToContainerLeft)

const fixMeasure = (n: number, pred: (n: number) => boolean, fallback: number) => (pred(n) ? n : fallback)
const fixMeasures = (compId: string, compMeasure: CompMeasure) => {
    const fixedMeasures = {
        ...compMeasure,
        height: fixMeasure(compMeasure.height, isPositive, 0),
        width: fixMeasure(compMeasure.width, isPositive, 0),
        relativeToContainerTop: fixMeasure(compMeasure.relativeToContainerTop, isNumber, 0)
    }

    if (!_.isUndefined(compMeasure.relativeToContainerLeft)) {
        fixedMeasures.relativeToContainerLeft = fixMeasure(compMeasure.relativeToContainerLeft!, isNumber, 0)
    }

    return fixedMeasures
}

const fixLayoutValues = (compId: string, compMeasure: CompMeasure) =>
    areValidMeasures(compId, compMeasure) ? compMeasure : fixMeasures(compId, compMeasure)

function hasValuesChanged(origObject: any, newObject: any) {
    if (!origObject) {
        return true
    }

    return _.some(newObject, (value, key) => {
        if (key !== 'x') {
            return !_.isEqual(origObject[key], value)
        }
        return Math.abs(origObject.x - value) > VALID_PX_THRESHOLD
    })
}

const updateWithMeasuredValues = (
    {dal, config, pointers}: any,
    experimentInstance: Experiment,
    {layoutedCompsMeasureMap, structure, isMobile}: any
) => {
    const viewMode = isMobile ? 'MOBILE' : 'DESKTOP'
    const updatedComps = {}
    _(layoutedCompsMeasureMap)
        .pickBy(
            (compMeasure, compId) =>
                !experimentInstance.isOpen('dm_postUpdateLayoutIgnoreRemoteComps') ||
                !isRefPointer(getPointer(compId, viewMode))
        )
        .pickBy(compMeasure => areAllNumbers(compMeasure.height, compMeasure.relativeToContainerTop, compMeasure.width))
        .pickBy((compMeasure, compId) => {
            const notRepeatedComp = !isRepeatedComponent(compId)
            const notWithActiveVariant = !hasActiveVariants({dal, pointers}, compId)
            const shouldUpdateRepeated =
                !config?.doNotSyncRepeatedLayout &&
                notWithActiveVariant &&
                isRepeaterMasterItem(
                    {
                        dal,
                        pointers
                    },
                    getPointer(compId, viewMode)
                )
            return notRepeatedComp || shouldUpdateRepeated
        })
        .mapValues((compMeasure, compId) => fixLayoutValues(compId, compMeasure))
        .mapValues((compMeasure: CompMeasure, compId) => {
            const newLayout: Partial<Rect> = {
                width: compMeasure.width,
                height: compMeasure.height,
                y: compMeasure.relativeToContainerTop
            }
            const isFixed = _.get(structure, [compId, 'layout', 'fixedPosition'], false)
            if (!_.isUndefined(compMeasure.relativeToContainerLeft) && !(isMobile && isFixed)) {
                newLayout.x = compMeasure.relativeToContainerLeft
            }
            return newLayout
        })
        .pickBy((newLayout, compId) => {
            const compIdToGet = isRepeatedComponent(compId) ? compId : getRepeaterTemplateId(compId)
            return hasValuesChanged(structure[compIdToGet].layout, newLayout)
        })
        .forEach((newLayout, compId) => {
            const pointer = getPointer(compId, viewMode, {innerPath: ['layout']})
            if (dal.isExist(pointer)) {
                dal.merge(pointer, newLayout)
                updatedComps[compId] = {
                    compId,
                    componentType: structure[compId].componentType,
                    viewMode: viewMode.toLowerCase(),
                    pageId: _.get(structure[compId], ['metaData', 'pageId']),
                    newLayout
                }
            }
        })

    return updatedComps
}

const shouldRunPostUpdate = ({dal, pointers}: any, postUpdateConfig: any) =>
    postUpdateConfig && dal.get(pointers.general.getRenderFlag('shouldUpdateJsonFromMeasureMap'))

export function runPostUpdateOperation(
    ps: PS,
    postUpdateOperationConfig: any,
    runMigrators: any,
    layoutCircuitBreaker: any,
    experimentInstance: Experiment,
    logger: CoreLogger,
    didLayoutArgs: LayoutDoneCbArgs
) {
    if (!shouldRunPostUpdate(ps, postUpdateOperationConfig)) {
        return
    }

    if (!layoutCircuitBreaker.isPostLayoutOperationAllowed()) {
        logger.interactionStarted('LAYOUT_CIRCUIT_BREAKER_ERROR', {
            extras: {callbackData: _.pick(didLayoutArgs, ['layoutedCompsMeasureMap', 'isMobile'])}
        })
        layoutCircuitBreaker.reportCircuitUpdates(logger)
        return
    }

    const {structure, layoutedCompsMeasureMap, isMobile} = didLayoutArgs
    const updatedComps = updateWithMeasuredValues(ps, experimentInstance, {
        layoutedCompsMeasureMap,
        structure,
        isMobile
    })

    const viewMode = isMobile ? 'MOBILE' : 'DESKTOP'
    const layoutedComponentPointers = _(layoutedCompsMeasureMap)
        .pickBy((v, k) => structure[k])
        .mapValues((v, k) => ({
            pointer: getPointer(k, viewMode),
            componentType: structure[k].componentType,
            pageId: _.get(structure[k], ['metaData', 'pageId'])
        }))
        .value()

    runMigrators(layoutedComponentPointers)

    ps.dal.commitTransaction('postUpdateOp')
    layoutCircuitBreaker.increasePostLayoutCount(updatedComps)
}
