import type {PS} from '@wix/document-services-types'
import _ from 'lodash'

const EXCEPTIONAL_ANCHOR = {
    targetComponent: 'PAGES_CONTAINER',
    type: 'BOTTOM_TOP'
}

const isCompMigratedRec = (ps: PS, compPointer) =>
    isJsonAnchorsRemoved(ps, compPointer) &&
    _.every(ps.pointers.components.getChildren(compPointer), _.partial(isCompMigratedRec, ps))

const getJsonAnchors = (ps: PS, compPointer) =>
    ps.dal.full.get(ps.pointers.getInnerPointer(compPointer, ['layout', 'anchors']))

const isJsonAnchorsRemoved = (ps: PS, compPointer) => _.isEmpty(getJsonAnchors(ps, compPointer))

const isExceptionalAnchor = anchorToTest =>
    _.isEqual(EXCEPTIONAL_ANCHOR, _.pick(anchorToTest, ['targetComponent', 'type']))

const isMasterPage = pageCompPointer => pageCompPointer.id === 'masterPage'

const isPageAnchorsRemoved = (ps: PS, pageCompPointer) => {
    const pageHasAnchors = !isJsonAnchorsRemoved(ps, pageCompPointer)

    if (pageHasAnchors) {
        const pageAnchors = getJsonAnchors(ps, pageCompPointer)
        if (!isMasterPage(pageCompPointer) || _.size(pageAnchors) > 1 || !isExceptionalAnchor(_.head(pageAnchors))) {
            return false
        }
    }

    return _.every(ps.pointers.components.getChildren(pageCompPointer), _.partial(isCompMigratedRec, ps))
}

export default isPageAnchorsRemoved
