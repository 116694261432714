import type {Pointer} from '@wix/document-services-types'

export const findCompBFS = (
    root: Pointer,
    getChildren: (p: Pointer) => Pointer[],
    predicate: (p: Pointer) => boolean
): Pointer | undefined | null => {
    const components = getChildren(root)
    let comp: Pointer | undefined
    let nextLevel: Pointer[]
    while (components.length) {
        comp = components.shift()
        if (predicate(comp as Pointer)) {
            return comp
        }
        nextLevel = getChildren(comp as Pointer)
        nextLevel.forEach(child => components.push(child))
    }
    return null
}
