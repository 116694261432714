import _ from 'lodash'
import type {DataModelExtensionAPI} from '../dataModel'
import {constants} from '@wix/document-manager-utils'
import {convertPageNameToUrl, isPageUriSeoTooLong, uriHasIllegalCharacters} from './pageUtils'
import {getIdFromRef} from '../../utils/dataUtils'
import {patchPageTranslations} from './language'
import {isPopup} from './popupUtils'
import type {CreateExtArgs} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {DATA_TYPES} from '../../constants/constants'
import {deepClone} from '@wix/wix-immutable-proxy'

const doesRouterPrefixExist = ({pointers, dal}: CreateExtArgs, routerPrefix: string) => {
    const routersConfigMapPointer = pointers.routers.getRoutersConfigMapPointer()
    const allRouters = dal.get(routersConfigMapPointer)
    const routerRef = _.findKey(allRouters, {prefix: routerPrefix})
    return !!routerRef
}

const getFilteredPagesList = (dataAccessArgs: CreateExtArgs, includeMasterPage: boolean, getOnlyPopups: boolean) => {
    const allPagesPointers = dataAccessArgs.pointers.page.getNonDeletedPagesPointers(includeMasterPage)
    const whatIsPage = (pageId: string) => {
        const isPopupPage = isPopup(dataAccessArgs, pageId)
        return getOnlyPopups ? isPopupPage : !isPopupPage
    }

    return _(allPagesPointers).map('id').filter(whatIsPage).value()
}

const getPagesAndPopupsList = ({pointers}: CreateExtArgs, includeMasterPage: boolean) => {
    const allPagesPointers = pointers.page.getNonDeletedPagesPointers(includeMasterPage)
    return _.map(allPagesPointers, 'id')
}

const getPagesList = (dataAccessArgs: CreateExtArgs, includeMasterPage: boolean, includingPopups: boolean) => {
    if (includingPopups) {
        return getPagesAndPopupsList(dataAccessArgs, includeMasterPage)
    }
    return getFilteredPagesList(dataAccessArgs, includeMasterPage, false)
}

const getPageInnerItem = (dataAccessArgs: CreateExtArgs, pageId: string, key: string) => {
    const pageDataItemPointer = dataAccessArgs.pointers.data.getDataItemFromMaster(pageId)
    const pageData = dataAccessArgs.dal.get(pageDataItemPointer)
    return _.get(pageData, key)
}
const getPageUriSEO = (dataAccessArgs: CreateExtArgs, pageId: string) => {
    return getPageInnerItem(dataAccessArgs, pageId, 'pageUriSEO')
}

const isDuplicatePageUriSeo = (dataAccessArgs: CreateExtArgs, excludePageId: string, pageUriSEO: string) => {
    if (doesRouterPrefixExist(dataAccessArgs, pageUriSEO)) {
        return true
    }

    const pageIds = getPagesList(dataAccessArgs, false, true)
    return _(pageIds)
        .pull(excludePageId)
        .some(pageId => getPageUriSEO(dataAccessArgs, pageId) === pageUriSEO)
}
const getPairsCheckValue = (pairs: any, pageUriSEO: string) => {
    return (
        _.chain(pairs)
            .find(pair => pair[0](pageUriSEO))
            // @ts-expect-error
            .get(['1'], '')
            .value()
    )
}

const getForbiddenPageUriSEOs = ({dal, pointers}: CreateExtArgs) => {
    const forbiddenWordsPointer = pointers.general.getForbiddenPageUriSEOs()
    return dal.get(forbiddenWordsPointer) || {}
}

const isForbiddenPageUriSeo = (dataAccessArgs: CreateExtArgs, pageId: string, pageUriSEO: string) => {
    const forbiddenWords = getForbiddenPageUriSEOs(dataAccessArgs)
    const currentPageUriSEO = getPageUriSEO(dataAccessArgs, pageId)
    if (currentPageUriSEO === pageUriSEO) {
        return false
    }
    return forbiddenWords.hasOwnProperty(pageUriSEO.toLowerCase())
}
const hasIllegalChars = (pageUriSEO: string) => {
    return uriHasIllegalCharacters(pageUriSEO)
}

const getPageUriSeoFormatErrorMessage = (pageUriSEO: string) => {
    return getPairsCheckValue(
        [
            [isPageUriSeoTooLong, `pageUriSEO is invalid: over ${constants.URLS.MAX_LENGTH} chars`],
            [hasIllegalChars, 'pageUriSEO is invalid: must only be alphanumeric or hyphen']
        ],
        pageUriSEO
    )
}

const getPageUriSeoContentErrorMessage = (dataAccessArgs: CreateExtArgs, pageId: string, pageUriSEO: string) => {
    return getPairsCheckValue(
        [
            [isDuplicatePageUriSeo.bind(null, dataAccessArgs, pageId), 'pageUriSEO is invalid: not unique across site'],
            [isForbiddenPageUriSeo.bind(null, dataAccessArgs, pageId), 'pageUriSEO is invalid: reserved word']
        ],
        pageUriSEO
    )
}

const getValidPageUriSEO = (dataAccessArgs: CreateExtArgs, pageId: string, initialPageUriSEO: string) => {
    initialPageUriSEO = convertPageNameToUrl(initialPageUriSEO)
    if (isPageUriSeoTooLong(initialPageUriSEO, constants.SAFE_PADDING_FOR_URI_LENGTH)) {
        initialPageUriSEO = initialPageUriSEO.slice(
            0,
            constants.URLS.MAX_LENGTH - constants.SAFE_PADDING_FOR_URI_LENGTH
        ) //allow extra space for duplicates so we can append an index
    }
    let pageUriSEO = initialPageUriSEO
    for (let index = 1; getPageUriSeoContentErrorMessage(dataAccessArgs, pageId, pageUriSEO); index++) {
        pageUriSEO = `${initialPageUriSEO}-${index}`
    }
    return pageUriSEO
}

const getPageUriSeoErrorMessage = (dataAccessArgs: CreateExtArgs, pageId: string, pageUriSEO: string) => {
    const urlFormatPointer = dataAccessArgs.pointers.general.getUrlFormat()
    const urlFormat = dataAccessArgs.dal.get(urlFormatPointer)

    if (urlFormat === constants.URL_FORMATS.HASH_BANG) {
        return ''
    }

    return (
        getPageUriSeoContentErrorMessage(dataAccessArgs, pageId, pageUriSEO) ||
        getPageUriSeoFormatErrorMessage(pageUriSEO)
    )
}

const validatePageUriSeoAllowed = (dataAccessArgs: CreateExtArgs, pageId: string, pageUriSEO: string) => {
    const error = getPageUriSeoErrorMessage(dataAccessArgs, pageId, pageUriSEO)

    if (!_.isEmpty(error)) {
        throw new Error(error)
    }
}

const updatePageBackgrounds = (
    {extensionAPI}: CreateExtArgs,
    pagePointer: Pointer,
    _data: Record<string, any>,
    languageCode?: string
) => {
    const data = deepClone(_data)
    const pageId = pagePointer.id
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    const pageDataItem = dataModel.components.getItem(pagePointer, DATA_TYPES.data, languageCode)

    if (data.pageBackgrounds) {
        _.forEach(constants.DEVICES, function (device) {
            const deviceBackground = data.pageBackgrounds[device]
            if (deviceBackground?.ref) {
                let refId

                if (pageDataItem) {
                    const currentRefId = _.get(pageDataItem, ['pageBackgrounds', device, 'ref', 'id'])
                    const newRefId = _.get(data, ['pageBackgrounds', device, 'ref', 'id'])

                    // Saving homepage data has quirks, it has a duplicated data item on both page and masterPage structures.
                    // Basically - We need to trick the server to save the data on both page and masterPage structures
                    // or we get data divergence between to dataItems with the same id but different contents.
                    // see https://jira.wixpress.com/browse/SE-6816
                    refId = newRefId ? newRefId : currentRefId && getIdFromRef(currentRefId)
                } else {
                    refId = `${pageId}_${device}_bg`
                }

                const pointer = dataModel.addItem(deviceBackground.ref, DATA_TYPES.data, pageId, refId, languageCode)
                deviceBackground.ref = `#${refId || pointer.id}`
            } else if (pageDataItem) {
                const currentRefId = _.get(pageDataItem, ['pageBackgrounds', device, 'ref', 'id'])
                if (currentRefId) {
                    _.set(pageDataItem, ['pageBackgrounds', device, 'ref'], `#${currentRefId}`)
                }
            }
        })
        data.pageBackgrounds = _.assign(pageDataItem?.pageBackgrounds || {}, data.pageBackgrounds)
    }
    return data
}

const getValidatedAndUpdatedPageData = (
    dataAccessArgs: CreateExtArgs,
    pagePointer: Pointer,
    data: Record<string, any>,
    languageCode?: string
) => {
    const pageId = pagePointer.id
    if (_.isString(data.pageUriSEO) && _.isEmpty(data.pageUriSEO)) {
        data.pageUriSEO = getValidPageUriSEO(dataAccessArgs, pageId, data.title) //convertPageNameToUrl handles blank titles
    }
    if (data.pageUriSEO) {
        validatePageUriSeoAllowed(dataAccessArgs, pageId, data.pageUriSEO)
        if (!_.get(data, ['translationData', 'uriSEOTranslated'])) {
            data.translationData = {
                uriSEOTranslated: false
            }
        }
    }
    return updatePageBackgrounds(dataAccessArgs, pagePointer, data, languageCode)
}

const addPageData = (
    dataAccessArgs: CreateExtArgs,
    pagePointer: Pointer,
    data: Record<string, any>,
    languageCodeToUse?: string
) => {
    const pageId = pagePointer.id
    data = getValidatedAndUpdatedPageData(dataAccessArgs, pagePointer, data, languageCodeToUse)
    if (data.pageUriSEO) {
        patchPageTranslations(dataAccessArgs, pageId, {pageUriSEO: data.pageUriSEO})
    }
    const {extensionAPI} = dataAccessArgs
    const {dataModel} = extensionAPI as DataModelExtensionAPI
    dataModel.components.addItem(pagePointer, 'data', data, languageCodeToUse)
}

export {addPageData}
