import type {Callback, Callback1, DynamicPageLink, PageLink, Pointer, PS} from '@wix/document-services-types'
import {guidUtils, log, siteConstants} from '@wix/santa-core-utils'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import {loggingUtils} from '@wix/santa-ds-libs/src/warmupUtils'
import _ from 'lodash'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import actionsAndBehaviors from '../actionsAndBehaviors/actionsAndBehaviors'
import component from '../component/component'
import componentStylesAndSkinsAPI from '../component/componentStylesAndSkinsAPI'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import documentModeInfo from '../documentMode/documentModeInfo'
import environment from '../environment/environment'
import hooks from '../hooks/hooks'
import mobileActions from '../mobileConversion/mobileActions'
import mobileConversion from '../mobileConversion/mobileConversionFacade'
import mobileHints from '../mobileConversion/modules/mobileHints'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import multilingual from '../multilingual/multilingual'
import pagesGroup from '../pagesGroup/pagesGroup'
import notificationService from '../platform/services/notificationService'
import routersGetters from '../routers/routersGetters'
import passwordProtected from '../siteMetadata/passwordProtected'
import tpaConstants from '../tpa/constants'
import clientSpecMapService from '../tpa/services/clientSpecMapService'
import mlUtils from '../utils/multilingual'
import dsUtils from '../utils/utils'
import blankPageStructure from './blankPageStructure'
import pageData from './pageData'
import pageProperties from './pageProperties'
import pageUtils from './pageUtils'
import popupUtils from './popupUtils'

const TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP = 'wysiwyg.viewer.components.PopupContainer'
const {TPA_WIDGET} = tpaConstants.COMP_TYPES
const PAGE_TYPES = ['mobile.core.components.Page', 'wixapps.integration.components.AppPage']
function initialize() {
    PAGE_TYPES.forEach(pageCompType => {
        hooks.registerHook(hooks.HOOKS.ADD.AFTER, mapHiddenAnchorsForAnchorsMenu, pageCompType)
        hooks.registerHook(hooks.HOOKS.REMOVE.BEFORE, cleanupPageSpecificStuff, pageCompType)
    })
}

const cleanupPageSpecificStuff = (ps: PS, pageComponentPointer: Pointer) => {
    pageData.removePageBackgrounds(ps, pageComponentPointer)
    dataModel.removeFixerVersions(ps, pageComponentPointer)
}

function mapHiddenAnchorsForAnchorsMenu(
    ps: PS,
    currentPagePointer: Pointer,
    compDefinition,
    optionalCustomId: string,
    mappers
) {
    const verticalAnchorComp = 'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu'
    const anchorsMenusPointers = componentDetectorAPI.getComponentByType(ps, verticalAnchorComp, currentPagePointer)
    const pageId = currentPagePointer.id
    _.forEach(anchorsMenusPointers, function (anchorsMenuPointer) {
        updateAnchorsMenuDataFromDuplicatedPage(ps, anchorsMenuPointer, pageId, mappers?.oldToNewIdMap)
    })
}

function updateAnchorsMenuDataFromDuplicatedPage(
    ps: PS,
    anchorsMenuPointer: Pointer,
    targetPageId: string,
    oldToNewIdMap
) {
    const sourcePageId = _.findKey(oldToNewIdMap, val => val === targetPageId)
    const menuDataItem = component.data.get(ps, anchorsMenuPointer)
    let newHiddenAnchorIds
    if (sourcePageId) {
        newHiddenAnchorIds = getNewHiddenAnchorIds(menuDataItem.hiddenAnchorIds[sourcePageId], oldToNewIdMap)
    } else {
        newHiddenAnchorIds = []
    }
    menuDataItem.hiddenAnchorIds[targetPageId] = newHiddenAnchorIds
    component.data.update(ps, anchorsMenuPointer, menuDataItem)
}

function getNewHiddenAnchorIds(oldHiddenAnchorIds, oldToNewIdMap) {
    return _.compact(
        _.map(oldHiddenAnchorIds, hiddenAnchorId => oldToNewIdMap[hiddenAnchorId]).concat(
            _.includes(oldHiddenAnchorIds, 'PAGE_TOP_ANCHOR') ? 'PAGE_TOP_ANCHOR' : null
        )
    )
}

initialize()

function getPageAnchors(ps: PS, pageId: string, pageTopLabel: string) {
    const pageDataPointers = ps.pointers.data.getDataItemsWithPredicate({type: 'Anchor'}, pageId)
    const pageAnchorDataItems = pageDataPointers.map(ps.dal.get)
    const pageTopAnchor = coreUtils.scrollAnchors.getPageTopAnchor(pageId, pageTopLabel)
    pageAnchorDataItems.push(pageTopAnchor)

    //TODO: should work on mobile comps..
    const pagePointer = ps.pointers.components.getPage(pageId, 'DESKTOP')
    const measureMap = ps.siteAPI.getSiteMeasureMap()

    return _.sortBy(pageAnchorDataItems, anchorDataItem => {
        const anchorCompRef = ps.pointers.components.getComponent(anchorDataItem.compId, pagePointer)
        const anchorTopPosition = anchorCompRef && measureMap.absoluteTop[anchorCompRef.id]

        return anchorTopPosition ?? coreUtils.scrollAnchors.SCROLL_PAGE_TOP_Y_POS
    })
}

function sanitizeHash(str: string) {
    if (!_.isString(str)) {
        throw Error(`pageId to remove should be a string ${str}`)
    }
    return dsUtils.stripHashIfExists(str)
}

function getPageIdToAdd(ps: PS) {
    const allPageIds = ps.siteAPI.getAllPagesIds(true)
    const deletedPagesMapPointer = ps.pointers.general.getDeletedPagesMapPointer()
    const usedPageIds = allPageIds.concat(ps.dal.getKeys(deletedPagesMapPointer))
    const newPageId = guidUtils.generateNewPageId(usedPageIds)

    return ps.pointers.components.getNewPage(newPageId)
}

function getPopupIdToAdd(ps: PS) {
    const allPageIds: string[] = ps.siteAPI.getAllPagesIds(true)
    const deletedPagesMapPointer = ps.pointers.general.getDeletedPagesMapPointer()
    const usedPageIds = allPageIds.concat(ps.dal.getKeys(deletedPagesMapPointer))
    const popupIds: string[] = _.filter(allPageIds, p => popupUtils.isPopup(ps, p)) as string[]
    const newPopupId = guidUtils.generateNewPopupId(usedPageIds, popupIds)

    return ps.pointers.components.getNewPage(newPopupId)
}

function setPrimaryPageStyle(ps: PS, page) {
    const primaryPageComp = ps.pointers.components.getPage(ps.siteAPI.getPrimaryPageId(), constants.VIEW_MODES.DESKTOP)
    const style = componentStylesAndSkinsAPI.style.get(ps, primaryPageComp)
    const skin = componentStylesAndSkinsAPI.skin.get(ps, primaryPageComp)
    page.style = style
    page.skin = skin
}

/**
 * Add a page to the site
 *
 * @param ps
 * @param pageComponentPointer
 * @param pageTitle page title. Defaults to title from structure
 * @param {object=} serializedPage page structure. Defaults to blank page
 * @param [shouldAddMenuItem=true]
 */
function addPage(ps: PS, pageComponentPointer: Pointer, pageTitle?: string, serializedPage?, shouldAddMenuItem = true) {
    addPageInternal(ps, pageComponentPointer, pageTitle, serializedPage, shouldAddMenuItem)
}

function addPageInternal(
    ps: PS,
    pageComponentPointer: Pointer,
    pageTitle: string,
    serializedPage,
    shouldAddMenuItem = true,
    oldToNewMapId?
) {
    const newPageId = pageComponentPointer.id
    let page
    const defaultValues = blankPageStructure.getBlankPageStructure(ps, newPageId)
    setPrimaryPageStyle(ps, defaultValues)

    if (serializedPage) {
        page = _.cloneDeep(serializedPage)
        _.defaultsDeep(page, defaultValues)
    }
    page = page || defaultValues
    page.data.title = pageTitle || page.data.title

    const pageObject = getPageStructure(ps, page.data.title, page.data.pageUriSEO)
    const pagePointer = ps.pointers.page.getNewPagePointer(newPageId)
    ps.dal.full.set(pagePointer, pageObject)

    const pageDataItem = page.data
    pageDataItem.id = newPageId
    pageData.addPageData(ps, newPageId, pageDataItem, shouldAddMenuItem)

    const isResponsive = environment.isResponsiveDocument(ps)

    const originalToNewCompIdMap = page.mobileComponents || isResponsive ? {} : undefined
    component.setComponent(ps, pageComponentPointer, null, page, {
        customId: newPageId,
        isPage: true,
        mappers: {
            oldToNewIdMap: oldToNewMapId || originalToNewCompIdMap
        }
    })

    mobileHints.markPageAsInitialized(ps, newPageId)
    hooks.executeHook(hooks.HOOKS.ADD_PAGE.AFTER, page.componentType, [ps, pageComponentPointer])

    const pageCompPointer = ps.pointers.components.getDesktopPointer(pagePointer)
    hooks.executeHook(hooks.HOOKS.ADD_ROOT.AFTER, page.componentType, [ps, pageCompPointer])
    mobileUtil.setMobileHiddenComponentListIfNeeded(
        ps,
        page.id,
        pageComponentPointer.id,
        originalToNewCompIdMap,
        mobileActions
    )
}

function checkStructureHasMainContainer(serializedPage) {
    if (!_.find(serializedPage.components, {componentType: TYPE_OF_MAIN_CONTAINER_INSIDE_POPUP})) {
        throw new Error("Can't create a popup page. Main container inside the popup was not found")
    }
}

function setPopupContainerDefaults(serializedPopupPage) {
    const popupContainerDefaults = blankPageStructure.getBlankPopupPageContainerStructure()
    const serializedPopupContainer = _.find(serializedPopupPage.components, {
        componentType: 'wysiwyg.viewer.components.PopupContainer'
    })
    if (serializedPopupContainer) {
        _.defaultsDeep(serializedPopupContainer, popupContainerDefaults)
    } else {
        serializedPopupPage.components = _.concat([popupContainerDefaults], serializedPopupPage.components ?? [])
    }
}

/**
 * Add a popup page to the site.
 *
 * @param {ps} ps
 * @param {Pointer} pageComponentPointer
 * @param {String=} pageTitle page title. Defaults to title from structure
 * @param {object=} serializedPage page structure. Defaults to blank popup
 */
function addPopup(ps: PS, pageComponentPointer: Pointer, pageTitle?: string, serializedPage?) {
    let pageStructure
    const defaultValues = blankPageStructure.getBlankPopupPageStructure()

    if (serializedPage) {
        pageStructure = _.cloneDeep(serializedPage)
        _.defaultsDeep(pageStructure, _.omit(defaultValues, 'components'))
        setPopupContainerDefaults(pageStructure)
        checkStructureHasMainContainer(pageStructure)
    }
    pageStructure = pageStructure || defaultValues
    pageStructure.data.title = pageTitle ?? pageStructure.data.title

    const pageUriSEO =
        pageStructure.data.pageUriSEO || siteConstants.DEFAULT_POPUP_URI_SEO_PREFIX + pageComponentPointer.id
    pageStructure.data.pageUriSEO = pageData.getValidPageUriSEO(ps, '', pageUriSEO)

    addPage(ps, pageComponentPointer, pageTitle, pageStructure)

    setOpenPopupBehavior(ps, pageComponentPointer)
}

function setOpenPopupBehavior(ps: PS, popupPointer: Pointer) {
    const primaryPagePointer = getPageComponentPointer(ps, ps.siteAPI.getPrimaryPageId())
    const masterPagePointer = getPageComponentPointer(ps, constants.MASTER_PAGE_ID)
    const actionDefinition = actionsAndBehaviors.getActionDefinition(ps, 'load')
    const behaviorDefinition = actionsAndBehaviors.getBehaviorDefinition(ps, 'openPopup')

    if (actionsAndBehaviors.hasBehavior(ps, primaryPagePointer, actionDefinition, null, behaviorDefinition)) {
        return
    }

    if (actionsAndBehaviors.hasBehavior(ps, masterPagePointer, actionDefinition, null, behaviorDefinition)) {
        return
    }

    actionsAndBehaviors.updateBehavior(ps, primaryPagePointer, actionDefinition, popupPointer, behaviorDefinition)
}

function removePageFromPagesGroup(ps: PS, pageId: string) {
    const pagesGroupPointer = pagesGroup.getPagesGroupByPageId(ps, pageId)

    if (pagesGroupPointer) {
        pagesGroup.removePageFromPagesGroup(ps, pagesGroupPointer, pageId)
    }
}

function touchHomePageToPreventConcurrentHomepageDeletion(ps: PS) {
    // Create a conflict between page deletion and setting the home page
    // See https://jira.wixpress.com/browse/DM-4220
    // This may be removed if and when the deletion of dal items takes part in the conflict resolution mechanism
    // of the concurrent editing effort
    const homePagePointer = ps.pointers.data.getDataItem('masterPage')
    ps.dal.touch(homePagePointer)
}

function removePage(ps: PS, _pageId: string, completeCallback?) {
    completeCallback = completeCallback || _.noop
    const pageId = sanitizeHash(_pageId)
    const pageComponentPointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    removePageFromPagesGroup(ps, pageId)
    touchHomePageToPreventConcurrentHomepageDeletion(ps)
    component.deleteComponent(ps, pageComponentPointer)
    markPageAsDeleted(ps, pageId)
    completeCallback(ps)
}

function validatePageRemovalInternal(ps: PS, _pageId: string) {
    const pageId = sanitizeHash(_pageId)
    const canDeletePageInfo = isPageRemovableWithDescription(ps, pageId)
    if (!canDeletePageInfo.success) {
        throw new Error(canDeletePageInfo.description)
    }

    const pageComponentPointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    const tpaCompRefs = component.isExist(ps, pageComponentPointer)
        ? component.getAllTpaComps(ps, pageComponentPointer)
        : false

    component.validateRemovalInternal(ps, pageComponentPointer, _.noop, tpaCompRefs)
    // @ts-expect-error
    return _.filter(tpaCompRefs, tpaCompRef => component.getType(ps, tpaCompRef) === TPA_WIDGET)
}

function markPageAsDeleted(ps: PS, pageId: string) {
    const deletedPagesMapPointer = ps.pointers.general.getDeletedPagesMapPointer()
    const pageMap = {}
    pageMap[pageId] = true
    ps.dal.merge(deletedPagesMapPointer, pageMap)
}

function serializePage(ps: PS, pageId: string, maintainIdentifiers?) {
    const pageComponentPointer = getPageComponentPointer(ps, pageId)
    const pageDataItemPointer = ps.pointers.data.getDataItemFromMaster(pageId)
    const ignoreChildren = false
    maintainIdentifiers = maintainIdentifiers || false
    const flatMobileStructuresMap = false
    const serializedPage = component.serialize(
        ps,
        pageComponentPointer,
        pageDataItemPointer,
        ignoreChildren,
        maintainIdentifiers,
        flatMobileStructuresMap,
        undefined,
        true
    )
    serializedPage.data = pageData.getPageDataWithoutIds(ps, pageId, true)
    delete serializedPage.referredVariants
    delete serializedPage.activeVariants
    return serializedPage
}

function isPageContainsCompWithType(ps: PS, pageId: string, types) {
    const pageComponentPointer = getPageComponentPointer(ps, pageId)
    return component.isContainsCompWithType(ps, pageComponentPointer, types)
}

function isPageContainsTPAMultiSectionComp(ps: PS, pageId: string) {
    if (ps.siteAPI.isPageContainsComponentType) {
        return ps.siteAPI.isPageContainsComponentType(pageId, tpaConstants.DATA_TYPE.TPA_MULTI_SECTION)
    }
    return isPageContainsCompWithType(ps, pageId, tpaConstants.COMP_TYPES.TPA_MULTI_SECTION)
}

function duplicatePage(
    ps: PS,
    newPagePointer: Pointer,
    _pageId: string,
    shouldAddMenuItem = true,
    shouldDuplicatePageCode = true
) {
    const pageId = sanitizeHash(_pageId)
    const pagePointer = ps.pointers.page.getPagePointer(pageId)
    if (!pagePointer) {
        throw new Error(`page does not exist ${_pageId}`)
    }

    const canDuplicateInfo = isPageDuplicatableWithDescription(ps, _pageId)
    if (!canDuplicateInfo.success) {
        throw new Error(canDuplicateInfo.description)
    }

    const title = ps.dal.get(ps.pointers.getInnerPointer(pagePointer, 'title'))
    mobileConversion.runPartialConversionAllPages(ps)
    const maintainIdentifiers = true
    const serializedPage = serializePage(ps, pageId, maintainIdentifiers)

    serializedPage.data.pageUriSEO = pageData.getValidPageUriSEO(ps, '', serializedPage.data.pageUriSEO)
    hooks.executeHook(hooks.HOOKS.DUPLICATE_ROOT.BEFORE, serializedPage.componentType, [ps, serializedPage])
    addPage(ps, newPagePointer, title, serializedPage, shouldAddMenuItem)
    pageData.copyTranslationData(ps, pageId, newPagePointer.id, popupUtils.isPopup(ps, pageId))
    if (hasPassword(ps, _pageId)) {
        duplicatePagePassword(ps, newPagePointer.id, _pageId)
    }

    hooks.executeHook(hooks.HOOKS.DUPLICATE_ROOT.AFTER, serializedPage.componentType, [
        ps,
        newPagePointer.id,
        pageId,
        shouldDuplicatePageCode
    ])
}

function ensurePageInfo(pageIdOrPageInfo) {
    if (typeof pageIdOrPageInfo === 'string') {
        return {pageId: pageIdOrPageInfo}
    }

    return pageIdOrPageInfo
}

interface PageInfoSimple {
    pageId: string
}
interface PageInfoLink {
    pageId: string
    title?: string
}

interface TpaDynamicPageInfo {
    pageId: string
    tpaInnerRoute: string
}

interface DynamicPageInfo {
    routerDefinition?: any
    innerRoute: string
    pageAdditionalData: any
}

function convertToPageInfo(
    ps: PS,
    pageIdOrLink: string | PageLink | DynamicPageLink
): Partial<PageInfoSimple & PageInfoLink & DynamicPageInfo & TpaDynamicPageInfo> {
    if (_.isString(pageIdOrLink)) {
        return {pageId: pageIdOrLink}
    }
    if (pageIdOrLink.type === 'PageLink') {
        const pageDataItem = pageData.getPageData(ps, pageIdOrLink.pageId)
        return {
            pageId: pageDataItem.id,
            title: pageDataItem.pageUriSEO
        }
    }
    if (pageIdOrLink.type === 'DynamicPageLink') {
        const {innerRoute, isTpaRoute, routerId} = pageIdOrLink
        if (isTpaRoute) {
            return {
                pageId: routerId,
                tpaInnerRoute: innerRoute
            }
        }

        const routerDefinition = routersGetters.get.byId(ps, routerId)
        if (!routerDefinition) {
            return
        }
        routerDefinition.routerId = routerId
        return {
            routerDefinition,
            innerRoute,
            pageAdditionalData:
                innerRoute && innerRoute !== '/' ? `${routerDefinition.prefix}/${innerRoute}` : routerDefinition.prefix
        }
    }
}

function isDynamicPageChanged(ps: PS, pageInfo) {
    const currentNavigationInfo = ps.siteAPI.getRootNavigationInfo()
    return (
        !_.isEqual(pageInfo.routerDefinition, currentNavigationInfo.routerDefinition) ||
        pageInfo.innerRoute !== currentNavigationInfo.innerRoute
    )
}

function willNavigateNavigateToPage(ps: PS, pageIdOrPageInfo) {
    const pageInfo = ensurePageInfo(pageIdOrPageInfo)
    return (
        (pageInfo.pageId && ps.siteAPI.getFocusedRootId() !== pageInfo.pageId) ||
        isDynamicPageChanged(ps, pageIdOrPageInfo)
    )
}

function getRootNavigationInfo(ps: PS) {
    return ps.siteAPI.getRootNavigationInfo()
}

function navigateToPage(
    ps: PS,
    pageIdOrPageLink: string | PageLink | DynamicPageLink,
    pageNavigationEndedCallback?: Callback,
    onErrorCallback?: Callback1<any>
) {
    let pageInfo = convertToPageInfo(ps, pageIdOrPageLink)
    if (!pageInfo) {
        return
    }

    const afterPageNavigation = () => {
        if (pageNavigationEndedCallback) {
            pageNavigationEndedCallback()
        }
    }

    const afterPageNavigationError = (errorInfo: any) => {
        ps.siteAPI.unregisterNavigationError('navigateToPageHandler')
        onErrorCallback(errorInfo)
    }

    if (pageInfo.pageId && !pageInfo.routerDefinition) {
        const routerData = routersGetters.getRouterDataForPageIfExist(ps, pageInfo.pageId)
        if (routerData) {
            pageInfo = _.assign(pageInfo, {
                routerDefinition: routerData
            })
        }
    }

    if (!willNavigateNavigateToPage(ps, pageInfo)) {
        afterPageNavigation()
        return
    }

    if (onErrorCallback) {
        ps.siteAPI.registerNavigationError('navigateToPageHandler', afterPageNavigationError)
    }

    const currentNavigationInfo = ps.siteAPI.getRootNavigationInfo()

    ps.setOperationsQueue.waitForChangesApplied(() => {
        ps.siteAPI.unregisterNavigationError('navigateToPageHandler')
        afterPageNavigation()
        hooks.executeHook(hooks.HOOKS.PAGE.AFTER_NAVIGATE_TO_PAGE_DONE, pageIdOrPageLink as string, [
            ps,
            pageInfo,
            currentNavigationInfo
        ])
    })

    ps.siteAPI.navigateToPage(pageInfo)
    hooks.executeHook(hooks.HOOKS.PAGE.AFTER_NAVIGATE_TO_PAGE, pageIdOrPageLink as string, [ps])
}

function refreshPage(ps: PS) {
    ps.siteAPI.refreshPage()
}

function willNavigateNavigateAndScroll(ps: PS, pageId: string, anchorDataId: string) {
    const isSpecialAnchor = coreUtils.scrollAnchors.isSpecialAnchor(anchorDataId)
    return !isSpecialAnchor && willNavigateNavigateToPage(ps, pageId)
}

function navigateToPageAndScrollToAnchor(ps: PS, pageId: string, anchorDataId: string, progressCallback: Callback) {
    const isSpecialAnchor = coreUtils.scrollAnchors.isSpecialAnchor(anchorDataId)
    const callback = ps.siteAPI.scrollToAnchor.bind(ps.siteAPI, anchorDataId, progressCallback)

    if (isSpecialAnchor) {
        callback()
    } else {
        navigateToPage(ps, pageId, callback)
    }
}

/**
 * @param ps
 * @param title
 * @param pageUriSEO
 * @returns {{pageUriSEO: *, data: {design_data: {}, document_data: {}, theme_data: {}, component_properties: {}, connections_data: {}, mobile_hints: {}, behaviors_data: {}}, title: *, structure: {}}}
 */
function getPageStructure(ps: PS, title: string, pageUriSEO: string) {
    return {
        data: {
            component_properties: {},
            document_data: {},
            theme_data: {},
            behaviors_data: {},
            connections_data: {},
            mobile_hints: {},
            design_data: {}
        },
        pageUriSEO,
        structure: {},
        title,
        translations: multilingual.generatePageTranslationsData(ps)
    }
}

function setHomepageId(ps: PS, pageId: string) {
    pageId = sanitizeHash(pageId)

    if (!ps.pointers.page.isExists(pageId)) {
        throw Error(`pageId ${pageId} does not exist. cannot set as home page.`)
    }
    if (popupUtils.isPopup(ps, pageId)) {
        throw Error("Can't set popup page as home page.")
    }

    const siteStructureDataPointer = ps.pointers.data.getDataItemFromMaster(constants.MASTER_PAGE_ID)
    const homePagePointer = ps.pointers.getInnerPointer(siteStructureDataPointer, 'mainPage')
    const homePageIdPointer = ps.pointers.getInnerPointer(siteStructureDataPointer, 'mainPageId')

    ps.dal.set(homePagePointer, `#${pageId}`)
    ps.dal.set(homePageIdPointer, pageId)
}

function getPageLayout(ps: PS, pageId: string) {
    const pageComponentPointer = getPageComponentPointer(ps, pageId)
    const pageLayout = component.layout.get(ps, pageComponentPointer)
    const realPageWidth = ps.siteAPI.getSiteWidth()
    pageLayout.width = realPageWidth
    return pageLayout
}

function isPageRemovableWithDescription(ps: PS, _pageId: string) {
    const pageId = sanitizeHash(_pageId)

    if (pageUtils.isMasterPage(ps, pageId)) {
        return {
            success: false,
            description: 'It is not allowed to remove masterPage'
        }
    }
    if (!pageData.doesPageExist(ps, pageId)) {
        return {
            success: false,
            description: `Page with id "${pageId}" does not exist`
        }
    }
    if (pageUtils.isHomepage(ps, pageId)) {
        return {
            success: false,
            description: `It is not allowed to delete homePage (${pageId}), please set a new page as homePage (page.setAsHomepage) and try again`
        }
    }
    if (ps.siteAPI.getPrimaryPageId() === pageId) {
        return {
            success: false,
            description: `It is not allowed to delete current page (${_pageId}), please navigate to another page (page.navigateTo) and try again`
        }
    }
    if (ps.siteAPI.getCurrentPopupId() === pageId) {
        return {
            success: false,
            description: `It is not allowed to delete open popup (${pageId}), please close the popoup nand try again`
        }
    }
    return {
        success: true,
        description: ''
    }
}

function isPageRemovable(ps: PS, pageId: string) {
    return isPageRemovableWithDescription(ps, pageId).success
}

function isPageDuplicatableWithDescription(ps: PS, _pageId: string) {
    const pageId = sanitizeHash(_pageId)
    const data = pageData.getPageData(ps, pageId)
    const isTpaSection = isPageContainsCompWithType(ps, pageId, [
        tpaConstants.COMP_TYPES.TPA_MULTI_SECTION,
        tpaConstants.COMP_TYPES.TPA_SECTION
    ])
    //TODO: Change to internal method of DocumentServices
    const isBlog =
        data?.appInnerID &&
        clientSpecMapService.getAppData(ps, data.appInnerID) &&
        clientSpecMapService.getAppData(ps, data.appInnerID).packageName === 'blog'

    if (pageUtils.isMasterPage(ps, pageId)) {
        return {
            success: false,
            description: 'It is not allowed to duplicate masterPage'
        }
    }
    if (!pageData.doesPageExist(ps, pageId)) {
        return {
            success: false,
            description: `Page with id "${pageId}" does not exist`
        }
    }
    if (isTpaSection) {
        return {
            success: false,
            description: 'It is not allowed to duplicate TPA pages'
        }
    }
    if (isBlog) {
        return {
            success: false,
            description: 'It is not allowed to duplicate Blog pages'
        }
    }

    if (_.get(data, 'type') === 'AppPage') {
        return {
            success: false,
            description: 'It is not allowed to duplicate Old blog page'
        }
    }

    return {
        success: true,
        description: ''
    }
}

function isPageDuplicatable(ps: PS, pageId: string) {
    return isPageDuplicatableWithDescription(ps, pageId).success
}

function connectPageToThemeStyle(ps: PS, pageId: string, styleId: string) {
    const pageComponentPointer = getPageComponentPointer(ps, pageId)
    componentStylesAndSkinsAPI.style.connectToThemeStyle(ps, pageComponentPointer, styleId)
}

function getPageStyleId(ps: PS, pageId: string) {
    const pageComponentPointer = getPageComponentPointer(ps, pageId)
    return componentStylesAndSkinsAPI.style.getId(ps, pageComponentPointer)
}

function getPageComponentPointer(ps: PS, pageId: string) {
    return ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
}

function getSocialUrl(ps: PS, urlFormat: string, forceMainPage: boolean) {
    const publicUrlPointer = ps.pointers.general.getPublicUrl()
    const publicUrl = ps.dal.get(publicUrlPointer)

    if (forceMainPage) {
        return pageUtils.getMainPageUrl(ps, urlFormat, publicUrl)
    }
    return pageUtils.getCurrentUrl(ps, urlFormat, publicUrl)
}

function getPageUrl(ps: PS, pageId: string, baseUrl: string, urlFormat: string) {
    const pageUriSEO = pageData.getPageUriSEO(ps, pageId)

    baseUrl = baseUrl || ps.dal.get(ps.pointers.general.getPublicUrl())

    return pageUtils.getPageUrl(ps, {pageId, title: pageUriSEO}, urlFormat, baseUrl)
}

function getPageTitle(ps: PS, pageId: string) {
    const dataItemPointer = ps.pointers.data.getDataItemFromMaster(pageId)
    const titlePointer = ps.pointers.getInnerPointer(dataItemPointer, 'title')
    return ps.dal.get(titlePointer) || ''
}

function registerDynamicPagesNavError(ps: PS) {
    const renderFlagPointer = ps.pointers.renderFlags.getRenderFlag('componentViewMode')

    if (ps.siteAPI.registerToDynamicPagesNavigationError) {
        ps.siteAPI.registerToDynamicPagesNavigationError(errorInfo => {
            if (ps.dal.get(renderFlagPointer) !== 'editor') {
                const errorPagesPopUpPointer = ps.pointers.runtime.getErrorPagesPopUp()
                const errorPagesPopUpFn = ps.dal.get(errorPagesPopUpPointer)
                if (errorPagesPopUpFn) {
                    errorPagesPopUpFn(errorInfo)
                }
            }
        })
    }
}

function initializePage(ps: PS) {
    try {
        loggingUtils.performance.start(loggingUtils.performanceMetrics.PAGE.INITIALIZE)
        ps.extensionAPI.logger.interactionStarted(constants.INTERACTIONS.PAGE.INITIALIZE)

        const allPagesIds = pageData.getPagesList(ps)
        _.forEach(allPagesIds, function (pageId) {
            const data = pageData.getPageData(ps, pageId)
            if (isSecuredPage(data)) {
                updateRenderedModelWithPagePassword(ps, data)
                deletePagePassword(ps, data)
            }
            fixHiddenTPAPageIndexableValue(ps, data)
        })

        registerDynamicPagesNavError(ps)

        ps.extensionAPI.logger.interactionEnded(constants.INTERACTIONS.PAGE.INITIALIZE)
        loggingUtils.performance.finish(loggingUtils.performanceMetrics.PAGE.INITIALIZE)
    } catch (e: any) {
        ps.extensionAPI.logger.captureError(e, {tags: {pageInitializationFailed: true}})
    }
}

function fixHiddenTPAPageIndexableValue(ps: PS, data) {
    if (data.tpaApplicationId > 0 && data.tpaPageId) {
        const appData = clientSpecMapService.getAppData(ps, data.tpaApplicationId)
        const widgetData = clientSpecMapService.getWidgetDataFromTPAPageId(ps, appData.appDefinitionId, data.tpaPageId)
        if (
            _.isBoolean(_.get(widgetData, 'appPage.indexable')) &&
            _.get(widgetData, 'appPage.indexable') !== data.indexable &&
            data.indexable
        ) {
            pageData.setPageData(ps, data.id, {
                indexable: _.get(widgetData, 'appPage.indexable')
            })
        }
    }
}

function deletePagePassword(ps: PS, data) {
    pageData.setPageData(ps, data.id, {
        pageSecurity: {
            requireLogin: false,
            dialogLanguage: data.pageSecurity.dialogLanguage
        }
    })
}

function isSecuredPage(data) {
    return !!_.get(data, 'pageSecurity.passwordDigest')
}

function updateRenderedModelWithPagePassword(ps: PS, data) {
    passwordProtected.setPagePassword(ps, data.id, {
        value: data.pageSecurity.passwordDigest,
        isHashed: true
    })
}

function updatePassword(ps: PS, pageId: string, password) {
    if (_.isObject(password)) {
        log.warnDeprecation('pages.permissions.updatePassword: Pass password as a string and not an object')
        passwordProtected.setPagePassword(ps, pageId, password)
    } else {
        passwordProtected.setPagePassword(ps, pageId, {
            value: password,
            isHashed: false
        })
    }
}

function removePassword(ps: PS, pageId: string) {
    passwordProtected.setPageToNoRestriction(ps, pageId)
}

function hasPassword(ps: PS, pageId: string) {
    return passwordProtected.isPageProtected(ps, pageId)
}

function duplicatePagePassword(ps: PS, pageId: string, sourcePageId: string, successCallback?, errorCallback?) {
    return passwordProtected.duplicatePagePassword(ps, pageId, sourcePageId, successCallback, errorCallback)
}

function isPagesProtectionOnServerOn() {
    log.warnDeprecation(
        'pages.permissions.isPagesProtectionOnServerOn: Use true instead of calling this function, because it always returns true'
    )
    return passwordProtected.isPagesProtectionOnServerOn()
}

/**
 * @param ps
 * @param [pageId] id of the page
 * @returns the bounding minimum height that the page can be, according to height and position of its children
 */
function getPageBottomByComponents(ps: PS, pageId?: string): number {
    return ps.siteAPI.getSiteMeasureMap().pageBottomByComponents[pageId]
}

function scrollToComponent(ps: PS, compId: string, callbacks) {
    ps.siteAPI.scrollToComponent(compId, callbacks)
}

function shouldDelayDeletion(ps: PS, pageId: string) {
    const pagePointer = ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
    return component.shouldDelayDeletion(ps, pagePointer)
}

function updatePageData(ps: PS, pageId: string, data, useOriginalLanguage = false, applyChangeToAllLanguages = false) {
    const pagePointer = ps.pointers.components.getPage(pageId, documentModeInfo.getViewMode(ps))
    const {managingAppDefId, title} = pageData.getPageData(ps, pageId)
    hooks.executeHook(hooks.HOOKS.DATA.UPDATE_BEFORE, 'mobile.core.components.Page', [ps, pagePointer, data])

    const langCode = mlUtils.getLanguageByUseOriginal(ps, useOriginalLanguage)
    ps.extensionAPI.page.data.update(pagePointer, data, applyChangeToAllLanguages, langCode)

    const updatedTitle = _.get(data, 'title')
    if (updatedTitle && title !== updatedTitle) {
        const routerData = routersGetters.getRouterDataForPageIfExist(ps, pageId)
        if (managingAppDefId || routerData) {
            const appDefinitionId = _.get(routerData, 'appDefinitionId', managingAppDefId)
            notificationService.notifyApplication(
                ps,
                appDefinitionId,
                platformEvents.factory.pageRenamed({title: updatedTitle, pageRef: pagePointer})
            )
        }
    }
}

function getPagesStatus(ps: PS, specificPageIds) {
    const pageUpdate = ps.extensionAPI.views.getAll('pageUpdate', specificPageIds)
    const pagePublish = ps.extensionAPI.views.getAll('pagePublish', specificPageIds)
    const pagesStatus = _.merge(
        _.mapValues(pageUpdate, value =>
            _.pick(value, ['lastUpdatedDate', 'lastUpdatedTransactionId', 'lastTransactionId'])
        ),
        _.mapValues(pagePublish, value => _.pick(value, ['lastPublishedDate', 'lastPublishedTransactionId']))
    )
    return specificPageIds ? _.pickBy(pagesStatus) : pagesStatus
}

/** @class documentServices.pages */
const pages = {
    shouldDelayDeletion,
    initialize: initializePage,
    getPageBottomByComponents,
    getPageIdToAdd,
    /*manipulations*/
    /**
     * Add a page to the site
     * @param {String} [pageTitle] page title. Defaults to title from structure
     * @param {Object} [serializedPage] page structure. Defaults to blank page
     */
    add: addPage,
    addPageInternal,
    /**
     * Duplicate an existing page
     *
     * @param {String} pageId
     */
    duplicate: duplicatePage,
    /**
     * Remove a page from the site
     *
     * @param {String} pageId
     */
    remove: removePage,
    validatePageRemovalInternal,
    /**
     * Changes the site current page according to the passed page ID
     * @param {String} pageId the ID of the page to navigate to
     * @param {Function} callback a function to be called upon page navigation finished
     */
    navigateTo: navigateToPage,
    /**
     * Reload the content of the current page
     */
    refresh: refreshPage,
    /**
     * Scroll comp page to the comp position
     * @param {String} compId the ID of the component to scroll to
     * @param {Object} scroll animation hooks
     */
    scrollToComponent,
    /**
     * Same as navigateTo, but also scrolls to anchor's location
     * @param {String} anchor id to scroll to
     */
    navigateToPageAndScrollToAnchor,
    willNavigateNavigateAndScroll,
    willNavigateNavigateToPage,
    /**
     * Determines if it's allowed to remove a certain page from site
     *
     * @param {String} pageId
     */
    isRemovable: isPageRemovable,
    /**
     * Determines if it's allowed to duplicate a certain page in the site
     *
     * @param {String} pageId
     * @param {Function} callback a callbacks that executes with an object {result: boolean, reason: String}
     */
    isDuplicable: isPageDuplicatable,
    isLandingPage: pageUtils.isLandingPage,

    /**
     * returns true if one of the page components' type is included in the supplied types parameter.
     *
     * @param {String} pageId
     * @param {String|Array} types component types to check against.
     */
    isPageContainsCompWithType,

    /**
     * returns true if the page contains a TPA MultiSection component.
     *
     * @param {String} pageId
     */
    isPageContainsTPAMultiSectionComp,

    getSocialUrl,
    getPageUrl,
    getPageTitle,
    getPage: getPageComponentPointer,
    /**
     * Return a list of all page ids in the site (both loaded and non loaded)
     *
     * @returns {String[]} page id array
     */
    getPageIdList: pageData.getPagesList,

    /**
     * Return the data of all the pages
     * @returns {Object} page id to page data map
     */
    getPagesDataItems: pageData.getPagesDataItems,

    /**
     * Gets the pa  ge's layout object
     * @param pageId
     */
    getLayout: getPageLayout,
    /**
     * @typedef {{
     *          pageId: string,
     *          knownPath: [string[]]
     *          }} PageReference
     */
    /**
     * Returns an object representing the page.<br>
     * This is useful when relating to page as a container.<br>
     * For example - when adding a component to a page, when changing a component container to page etc...
     * @param {String} pageId
     * @returns {PageReference}
     */
    getReference: getPageComponentPointer,
    serializePage,
    getRootNavigationInfo,
    /** @class documentServices.homePage*/
    homePage: {
        /**
         * Changes the site home page according to the passed page ID
         * @param {String} pageId of the page to set as home page
         */
        set: setHomepageId,
        /**
         * Gets the current home page id
         * @returns {String} home page id
         */
        get: pageUtils.getHomepageId
    },
    /** @class documentServices.pages.data*/
    data: {
        /**
         * Set page's data item
         *
         * @param {String} pageId
         * @param {Object} data object
         */
        set: pageData.setPageData,
        /**
         * Get page's data item
         *
         * @param {String} pageId
         * @returns {object} page data object
         */
        get: pageData.getPageData,
        update: updatePageData
    },
    /** @class documentServices.pages.style*/
    style: {
        /**
         * connect page to theme style
         * @param {String} pageId
         * @param {String} styleId
         */
        connectToThemeStyle: connectPageToThemeStyle,

        /**
         * get the page's style id
         * @param {String} pageId
         * @returns {String} page's style id
         */
        getId: getPageStyleId
    },
    background: {
        /**
         * Get a page background data item
         * @param {string} pageId
         * @param {string} [device=desktop] desktop or mobile
         * @returns {BackgroundMediaDataItem}
         */
        get: pageData.getBgDataItem,
        /**
         * Update a page background
         * @param {string} pagId
         * @param {BackgroundMediaDataItem} bgData The data which defines the page background
         * @param {string} [device=desktop] desktop or mobile
         * @param {ImageDataItem|VideoDataItem} [mediaItemData] An optional media data which is referenced by the background
         * @param {ImageDataItem} [overlayImageData] An optional image data of the background overlay which is referenced by the background
         */
        update: pageData.updateBgDataItem
    },

    /**
     * @param {String} pageId is a page ID.
     * @param {String} [pageTopLabel] a name for the page top anchor, otherwise will be empty.
     * @returns {Array} An array of Anchor Data Items for a given Page sorted by their absolute Y position. First Anchor is always Page Top.
     */
    getPageAnchors,
    permissions: {
        hasPassword,
        updatePassword,
        removePassword,
        isPagesProtectionOnServerOn,
        duplicatePagePassword
    },
    properties: {
        get: pageProperties.getPageProperties,
        update: pageProperties.updatePageProperties
    },

    /**
     * @param {Array} optional, specific page ids for status query, when not provided return status for all pages
     * @returns {Map} of pages publish status
     */
    getPagesStatus,

    popupPages: {
        open: navigateToPage,

        close(ps: PS, navigationCallback?: Callback) {
            const primaryPageId = ps.siteAPI.getPrimaryPageId()
            if (primaryPageId) {
                pages.navigateTo(ps, primaryPageId, navigationCallback)
            }
        },

        getCurrentPopupId(ps: PS) {
            return ps.siteAPI.getCurrentPopupId()
        },

        add: addPopup,

        getPopupIdToAdd,

        getDataList: pageData.getPopupsDataItems,

        isPopup(ps: PS, pageId: string) {
            return popupUtils.isPopup(ps, pageId)
        },

        isPopupOpened(ps: PS) {
            return ps.siteAPI.isPopupOpened()
        }
    }
}

export default pages
