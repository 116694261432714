import * as _ from 'lodash'
import {isMobileOnly} from '../mobileOnlyComponents/mobileOnlyComponentsUtils'
import * as conversionUtils from '../conversionUtils'
import {isExistsBeforeMerge, isModifiedComponent} from '../conversionUtils'
import {ComponentsDiff, ConversionSettings, ObjMap, DeepStructure} from '../../types'
import {getYGapsBetweenBlocks} from '../utils/blocksUtils'
import {NON_SPLITTABLE_QUERIES} from '../constants'

export function getReplacedCompIds(desktopComponents, mobileComponents) {
    return _.reduce(
        mobileComponents,
        (result, mobileComp: any, compId) => {
            if (desktopComponents[compId] && mobileComp.componentType && desktopComponents[compId].componentType !== mobileComp.componentType) {
                result.push(compId)
            }
            return result
        },
        []
    )
}

export function getComponentIdsAddedToWebStructure(desktopComponents: ObjMap<DeepStructure>, mobileComponents: ObjMap<DeepStructure>): string[] {
    const componentsAppearingInWebButNotInMobile = _.difference(_.keys(desktopComponents), _.keys(mobileComponents))
    const replacedComps = getReplacedCompIds(desktopComponents, mobileComponents)
    return [...componentsAppearingInWebButNotInMobile, ...replacedComps]
}

export function deletePagesMissingInDesktop(desktopPages: ObjMap<DeepStructure>, mobilePages: ObjMap<DeepStructure>): void {
    const missingInDesktop = _.difference(_.keys(mobilePages), _.keys(desktopPages))
    _.forEach(missingInDesktop, pageId => delete mobilePages[pageId])
}

export function synchronizeDataQueries(desktopPage: DeepStructure, mobilePage: DeepStructure): void {
    _.forEach(conversionUtils.getAllCompsInStructure(mobilePage), mobileComponent => {
        const desktopComponent = <DeepStructure>conversionUtils.getComponentByIdFromStructure(mobileComponent.id, desktopPage)

        if (!desktopComponent) {
            return
        }

        _.forEach(NON_SPLITTABLE_QUERIES, query => {
            // eslint-disable-next-line no-prototype-builtins
            if (!desktopComponent.hasOwnProperty(query)) {
                delete mobileComponent[query]
            } else {
                mobileComponent[query] = desktopComponent[query]
            }
        })
    })
}

function getImplicitlyHiddenDescendants(allDesktopComponents: ObjMap<DeepStructure>, hiddenComponentIds: string[]): string[] {
    if (_.isEmpty(hiddenComponentIds)) {
        return []
    }

    const allExplicitlyHiddenParents = <string[]>_(hiddenComponentIds)
        .filter(compId => _.get(allDesktopComponents, [compId, 'conversionData', 'filterChildrenWhenHidden']) === true)
        .uniq()
        .value()

    const hiddenSlideShowComps = _(allExplicitlyHiddenParents)
        .filter(compId => _.get(allDesktopComponents[compId], ['conversionData', 'structuralContainer'], false))
        .flatMap(compId => _.map(conversionUtils.getAllCompsInStructure(allDesktopComponents[compId], false) || [], 'id'))
        .compact()
        .value()

    const implicitlyHiddenColumns = _(allExplicitlyHiddenParents)
        .filter(compId => conversionUtils.isColumnsContainerComponent(<DeepStructure>allDesktopComponents[compId]))
        .flatMap(compId => _.get(allDesktopComponents[compId], 'components', []))
        .map('id')
        .compact()
        .value()

    const hiddenComps = _.union(hiddenSlideShowComps, implicitlyHiddenColumns)
    return <string[]>_.difference(hiddenComps, hiddenComponentIds)
}

const getExplicityHiddenComponentsIds = (allDesktopComponents): string[] =>
    _(allDesktopComponents).filter(['conversionData.mobileHints.hidden', true]).map('id').value()

const getImplicityModifiedChildren = (children: DeepStructure[], acc: string[]) => {
    if (children && children.length > 0) {
        for (const child of children) {
            acc.push(child.id)
            getImplicityModifiedChildren(conversionUtils.getChildren(child), acc)
        }
    }
}

const getImplicityModifiedComponents = (allDesktopComponents: ObjMap<DeepStructure>, explicityModifiedComponentIds: string[]) => {
    if (_.isEmpty(explicityModifiedComponentIds)) {
        return []
    }
    const explicityModifiedComponents = explicityModifiedComponentIds.map(id => allDesktopComponents[id]).filter(comp => comp !== undefined)

    const modifiedCompIds = []
    getImplicityModifiedChildren(explicityModifiedComponents, modifiedCompIds)

    return _.uniq(modifiedCompIds)
}

function getExplicityModifiedComponentIds(allDesktopComponents: ObjMap<DeepStructure>) {
    return _(allDesktopComponents).filter(isModifiedComponent).map('id').value()
}

const getMobileComponentIds = (allMobileComponents: ObjMap<DeepStructure>): {mobileOnlyComponentIds: string[]; mobileComponentIds: string[]} => {
    const mobileOnlyComponentIds = []
    const mobileComponentIds = []
    Object.values(allMobileComponents).forEach(component => {
        if (isMobileOnly(component as DeepStructure)) {
            mobileOnlyComponentIds.push(component.id)
        } else {
            mobileComponentIds.push(component.id)
        }
    })
    return {
        mobileComponentIds,
        mobileOnlyComponentIds
    }
}

const shouldBeReRendered = (comp: DeepStructure) => _.get(comp, ['conversionData', 'mobileHints', 'shouldBeForceConverted']) === true
const getParentIdFromMobileHints = (comp: DeepStructure) => _.get(comp, ['conversionData', 'mobileHints', 'parent'])

const setReparented = (comp: DeepStructure) => _.set(comp, ['conversionData', 'mobileHints', 'reparented'], true)

const markExplicityReparentedComponentAndGetIds = (allDesktopComponents, allMobileComponents) => {
    const filteredComp = _.filter(allDesktopComponents, comp => {
        const mobileComponent = allMobileComponents[comp.id]
        if (!mobileComponent || !shouldBeReRendered(comp)) {
            return false
        }
        const desktopParentId = getParentIdFromMobileHints(comp)
        const mobileParentId = getParentIdFromMobileHints(mobileComponent)

        return desktopParentId !== mobileParentId
    })
    filteredComp.forEach(comp => {
        setReparented(comp)
        _.set(comp, ['conversionData', 'mobileHints', 'rootReparented'], true)
    })
    return filteredComp.map(comp => comp.id)
}

const getForcedComponents = (allDesktopComponents: ObjMap<DeepStructure>, allMobileComponents: ObjMap<DeepStructure>) => {
    return _(allDesktopComponents)
        .filter(comp => {
            const mobileComponent = allMobileComponents[comp.id]
            if (!mobileComponent) {
                return false
            }

            const isForcedForReRender = shouldBeReRendered(comp)
            return isForcedForReRender
        })
        .map('id')
        .value()
}

export function getComponentsDiff(
    allDesktopComponents: ObjMap<DeepStructure>,
    allMobileComponents: ObjMap<DeepStructure>,
    settings: ConversionSettings
): ComponentsDiff {
    const explicitlyHiddenComponent = getExplicityHiddenComponentsIds(allDesktopComponents)
    const implicitlyHiddenDescendantsOfExplicitlyHiddenComponents = getImplicitlyHiddenDescendants(allDesktopComponents, explicitlyHiddenComponent)
    const allHiddenComponentIds = [...explicitlyHiddenComponent, ...implicitlyHiddenDescendantsOfExplicitlyHiddenComponents]

    const {mobileComponentIds, mobileOnlyComponentIds} = getMobileComponentIds(allMobileComponents)
    const hiddenComponentsToDelete = [..._.intersection(mobileComponentIds, allHiddenComponentIds)]

    const desktopComponentIds = _.map(allDesktopComponents, 'id')
    const componentsDeletedFromDesktop = _.difference(mobileComponentIds, desktopComponentIds)
    const replacedComponents = getReplacedCompIds(allDesktopComponents, allMobileComponents)

    let idsOfComponentsToDelete = _.pullAllBy(
        _.uniq([...replacedComponents, ...componentsDeletedFromDesktop, ...hiddenComponentsToDelete]),
        mobileOnlyComponentIds
    )
    let idsOfComponentsToAdd =
        _.pullAllBy(
            _.difference(getComponentIdsAddedToWebStructure(allDesktopComponents, allMobileComponents), allHiddenComponentIds),
            mobileOnlyComponentIds
        ) || []

    let idsOfComponentForForceReRender = []
    if (settings.enableImprovedMergeFlow) {
        idsOfComponentForForceReRender = getForcedComponents(allDesktopComponents, allMobileComponents as ObjMap<DeepStructure>)

        const allExplicityModifiedComponentsIds = getExplicityModifiedComponentIds(allDesktopComponents)

        const existingBeforeMergeComponentIds = getExistingBeforeMergeComponentIds(allMobileComponents)

        const newlyAddedComponents = getNewlyAddedComponentIds(allDesktopComponents, existingBeforeMergeComponentIds)

        const implicityModifiedComponents = _.pullAll(
            getImplicityModifiedComponents(allDesktopComponents, allExplicityModifiedComponentsIds),
            newlyAddedComponents
        )

        idsOfComponentsToDelete = _.pullAll(_.uniq([...idsOfComponentsToDelete, ...idsOfComponentForForceReRender]), implicityModifiedComponents)
        idsOfComponentsToAdd = _.pullAll(_.uniq([...idsOfComponentsToAdd, ...idsOfComponentForForceReRender]), implicityModifiedComponents)
    }

    const idsOfComponentToReparent = markExplicityReparentedComponentAndGetIds(allDesktopComponents, allMobileComponents)

    idsOfComponentsToDelete = _.uniq([...idsOfComponentsToDelete, ...idsOfComponentToReparent])
    idsOfComponentsToAdd = _.uniq([...idsOfComponentsToAdd, ...idsOfComponentToReparent])

    return {idsOfComponentsToDelete, idsOfComponentsToAdd}
}

function getExistingBeforeMergeComponentIds(mobileComponents) {
    return _(mobileComponents).filter(isExistsBeforeMerge).map('id').value()
}
/**
 * Function set mobileComponent as preset to desktop structure,
 * to keep it's mobile structure when it reparented
 * @param desktopComponents
 * @param mobileComponents
 */
export const preprocessReparentedComponentsBeforeMerge = (desktopComponents: ObjMap<DeepStructure>, mobileComponents: ObjMap<DeepStructure>) => {
    const rootReparentedComponents = _.filter(
        desktopComponents,
        comp => conversionUtils.isReparentedComponent(comp) && _.get(comp, ['conversionData', 'mobileHints', 'rootReparented'])
    )
    rootReparentedComponents.forEach(comp => {
        comp.conversionData.preset = mobileComponents[comp.id]
        comp.conversionData.shouldApplyPreset = true
    })
}

export const setParentToComponentConversionData = (parent: DeepStructure) => {
    const children = conversionUtils.getChildren(parent)
    if (children && children.length > 0) {
        children.forEach(comp => {
            _.set(comp, ['conversionData', 'mobileHints', 'parent'], parent.id)
            setParentToComponentConversionData(comp)
        })
    }
}

export const setMobilePreset = (desktopComponent: DeepStructure, mobileComponent: DeepStructure) => {
    desktopComponent.conversionData.preset = _.cloneDeep(mobileComponent)
    desktopComponent.conversionData.shouldApplyPreset = true
}

export const setIsTightContainerIfClassicSection = (component: DeepStructure, mobileComponent: DeepStructure) => {
    const shouldSkip = !conversionUtils.isClassicSectionComponent(component) && !conversionUtils.isPageComponent(component)

    if (shouldSkip) {
        return
    }
    const children = conversionUtils.getChildren(component)
    if (_.isEmpty(children)) {
        return
    }

    children.forEach(comp => {
        if (conversionUtils.shouldStretchToScreenWidth(comp)) {
            /** For screen width containers (strip) there should not be any gaps between */
            comp.conversionData.tightWithPreviousSibling = true
        }
    })
    component.conversionData.isTightContainer = children.every(comp => conversionUtils.shouldStretchToScreenWidth(comp))
    component.conversionData.hasTightBottomMargin = mobileComponent.conversionData.hasTightBottomMargin = conversionUtils.shouldStretchToScreenWidth(
        _.last(children)
    )
    component.conversionData.hasTightTopMargin = mobileComponent.conversionData.hasTightTopMargin = conversionUtils.shouldStretchToScreenWidth(_.head(children))
}

const setPreviousMobileLayout = (mobileComponent: DeepStructure) => {
    mobileComponent.conversionData.mobileLayoutBeforeConversion = _.clone(mobileComponent.layout)
}

export function preprocessExistingComponentsBeforeMerge(
    mobileComponent: DeepStructure,
    allDesktopComponents: ObjMap<DeepStructure>,
    settings: ConversionSettings
) {
    if (!mobileComponent.conversionData) {
        mobileComponent.conversionData = {}
    }
    mobileComponent.conversionData.existsBeforeMerge = true

    if (settings.enableImprovedMergeFlow) {
        setPreviousMobileLayout(mobileComponent)
    }

    if (allDesktopComponents[mobileComponent.id]) {
        const desktopComponent = allDesktopComponents[mobileComponent.id]
        desktopComponent.conversionData.existsBeforeMerge = true

        if (settings.enableImprovedMergeFlow) {
            setIsTightContainerIfClassicSection(desktopComponent, mobileComponent)
        }
    }

    const children = conversionUtils.getChildren(mobileComponent)
    if (children && children.length > 0) {
        const order = []
        children.forEach(child => {
            order.push(child.id)
            preprocessExistingComponentsBeforeMerge(child, allDesktopComponents, settings)
        })
        mobileComponent.conversionData.originalMobileComponentsOrder = order
    }
}

export const markParentOfMobileOnlyComponentAndGetList = (
    mobileOnlyComponentIds: string[],
    mobileStructure: DeepStructure,
    allDesktopComponents: ObjMap<DeepStructure>
): string[] => {
    const listOfParents: Set<string> = mobileOnlyComponentIds.reduce((desktopParents, compId) => {
        const parent = conversionUtils.getParent(compId, mobileStructure)
        if (!parent) return
        if (conversionUtils.isMasterPage(parent)) {
            return desktopParents
        }
        /**
         * Desktop parent contains conversion data
         */
        const desktopParent = allDesktopComponents[parent.id]
        if (!desktopParent) return desktopParents
        desktopParent.conversionData.isDirectParentOfMobileOnly = true
        desktopParents.add(desktopParent.id)
        return desktopParents
    }, new Set<string>())
    return [...listOfParents]
}

export const getNewlyAddedComponentIds = (desktopComponents: ObjMap<DeepStructure>, existingBeforeMergeComponentIds: string[]) =>
    _.difference(_.map(desktopComponents, 'id'), existingBeforeMergeComponentIds)

export const getPresetComponentIds = (allDesktopComponents: ObjMap<DeepStructure>, allMobileComponents: ObjMap<DeepStructure>) => {
    const idsToExclude = []
    _.forOwn(allDesktopComponents, (comp: DeepStructure, id: string) => {
        if (_.get(comp, ['conversionData', 'mobileHints', 'author'], '') === 'studio' && allMobileComponents[id] && !conversionUtils.isPageComponent(comp)) {
            idsToExclude.push(id)
        }
    })
    return idsToExclude
}

export function addChildrenOfDeletedContainersToAddList(
    componentsToDeleteFromCurrentComponent: DeepStructure[],
    idsOfComponentsToAdd: string[],
    idsOfComponentsToDelete: string[]
) {
    _(componentsToDeleteFromCurrentComponent)
        .filter(curComponentToDelete => conversionUtils.isContainerComponent(curComponentToDelete) || conversionUtils.isPageComponent(curComponentToDelete))
        .forEach(curComponentToDelete => {
            _(curComponentToDelete.components)
                .map('id')
                .filter(curChildIdOfComponentToDelete => !_.includes(idsOfComponentsToDelete, curChildIdOfComponentToDelete))
                .forEach(curChildIdOfComponentToDelete => {
                    idsOfComponentsToAdd.push(curChildIdOfComponentToDelete)
                })
        })
}

/**
 * Function runs after conversion finished to adjust height of
 * containers to their children if it has wrong one
 * @param DeepStructure mobileSiteStructure
 */
export const insureTightlyWrapsForContainersChildren = (structure: DeepStructure, settings: ConversionSettings) => {
    if (shouldEnableFitContainerToChildrenHeightRecoursively(settings)) {
        const children = conversionUtils.getChildren(structure)

        if (children) {
            children.forEach(child => {
                if (conversionUtils.isContainerComponent(child)) {
                    insureTightlyWrapsForContainersChildren(child, settings)
                }
            })
        }
        const enforceShrinkEvenWithNoChildren = false
        conversionUtils.ensureContainerTightlyWrapsChildren(structure, children, enforceShrinkEvenWithNoChildren)
        return
    }
    if (conversionUtils.isContainerComponent(structure) || conversionUtils.isPageComponent(structure)) {
        conversionUtils.ensureContainerTightlyWrapsChildren(structure, conversionUtils.getChildren(structure), true)
    }
}

const isOverlappedOnMobileByDesign = (component: DeepStructure, previousComponent: DeepStructure, diffY: number): boolean => {
    const conversionDataCurrent = component.conversionData
    const conversionDataPrevious = previousComponent.conversionData

    const isExistingPreset = conversionDataCurrent.existsBeforeMerge && conversionDataCurrent.mobileHints?.author === 'studio'
    const heightOfPreviousIsSame = conversionDataPrevious.mobileLayoutBeforeConversion?.height === previousComponent.layout.height
    const haveOverlap = diffY > 0

    return (isExistingPreset || isModifiedComponent(component)) && heightOfPreviousIsSame && haveOverlap
}

export const insureOverlapedRootContainers = (mobileSiteStructure: DeepStructure, enableImprovedMergeFlow: boolean) => {
    if (conversionUtils.isContainerComponent(mobileSiteStructure) || conversionUtils.isPageComponent(mobileSiteStructure)) {
        const components = conversionUtils.getChildren(mobileSiteStructure)
        if (components) {
            for (let index = 1; index < components.length; index += 1) {
                const component = components[index]

                const previousComponent = components[index - 1]
                const previousComponentBottomY = previousComponent.layout.y + previousComponent.layout.height
                const currentComponentTopY = component.layout.y
                const diffY = previousComponentBottomY - currentComponentTopY

                if (isOverlappedOnMobileByDesign(component, previousComponent, diffY)) {
                    /** Some components overlapped by design on mobile so we don't want to break it
                     */
                    continue
                }

                if (diffY > 0) {
                    component.layout.y = previousComponentBottomY + getYGapsBetweenBlocks(previousComponent, enableImprovedMergeFlow)
                } else {
                    /** It means there are gaps between components */
                    component.layout.y = currentComponentTopY + diffY + getYGapsBetweenBlocks(previousComponent, enableImprovedMergeFlow)
                }
            }
        }
    }
}

const setChildren = (structure, children) => {
    if (!children) {
        return
    }
    structure.components = children
}

/**
 * Function check and fix orders of reattached components
 * Example: there is a strip with preset on page you modified one column,
 * new merge will re render this one, but will put new one in the end of list,
 * with wrong order so this function is sort components as they should be
 * @param mobileStructure
 */
export const insureOrders = (mobileStructure: DeepStructure) => {
    const children = conversionUtils.getChildren(mobileStructure)
    if (children && children.length > 0 && mobileStructure.conversionData.originalMobileComponentsOrder) {
        const orderedChildren = _.sortBy(children, comp => mobileStructure.conversionData.originalMobileComponentsOrder.indexOf(comp.id))
        orderedChildren.forEach(insureOrders)
        setChildren(mobileStructure, orderedChildren)
    }
}

export const insureOrdersByY = (mobileStructure: DeepStructure) => {
    const children = conversionUtils.getChildren(mobileStructure)
    if (children && children.length > 0) {
        const orderedChildren = _.sortBy(children, child => child && child.layout.y)
        setChildren(mobileStructure, orderedChildren)
    }
}
/**
 * It moves moc components to desktop structure just to restore it in
 * future and keep MOC layout if parent was modified
 * @param desktopComponents
 * @param mobileComponents
 * @param pageId
 */
export const moveMOCtoDesktopStructure = (desktopComponents: ObjMap<DeepStructure>, mobileComponents: ObjMap<DeepStructure>, pageId: string) => {
    _.forOwn(mobileComponents, comp => {
        const parentId = getParentIdFromMobileHints(comp)
        if (isMobileOnly(comp) && parentId !== pageId && comp.componentType !== 'MENU_AS_CONTAINER') {
            if (desktopComponents[parentId] !== undefined) {
                desktopComponents[parentId].components.push(_.clone(comp))
            }
        }
    })
}

export const isComponentHidden = (component: DeepStructure) => {
    return _.get(component, ['conversionData', 'mobileHints', 'hidden'])
}

export const getFlatStructure = structure => {
    const accures = []

    const scan = (structure, accures) => {
        const children = conversionUtils.getChildren(structure)
        if (!children) {
            return
        }
        children.forEach(comp => {
            accures.push(comp)
            scan(comp, accures)
        })
    }
    scan(structure, accures)
    return accures
}

const findDuplicates = (structure: DeepStructure): string[] => {
    const duplicates = []

    const flattenAllComponents = getFlatStructure(structure)
    const countOfAppears = {}

    for (const comp of flattenAllComponents) {
        if (!countOfAppears[comp.id]) {
            countOfAppears[comp.id] = 1
        } else {
            duplicates.push(comp.id)
        }
    }
    return duplicates
}

export const removeDuplicates = (duplicates: string[], structure: DeepStructure) => {
    const removed = []

    const setRemovedChildrenToRemovedArray = (comp, arr) => {
        const components = conversionUtils.getChildren(comp)

        if (components) {
            components.forEach(comp => {
                arr.push(comp.id)
                setRemovedChildrenToRemovedArray(comp, arr)
            })
        }
    }
    for (const id of duplicates) {
        if (removed.includes(id)) {
            continue
        }
        const component = conversionUtils.getComponentByIdFromStructure(id, structure)

        /**
         * In case when parent of component was deleted
         */
        if (component === null) {
            continue
        }
        const parentId = conversionUtils.getParentIdFromMobileHints(component as any)
        const parent = conversionUtils.getComponentByIdFromStructure(parentId, structure)
        if (!parent) {
            continue
        }
        removed.push(id)
        setRemovedChildrenToRemovedArray(component, removed)
        conversionUtils.removeChildrenFromById(parent, [id])
    }
}

export const removeDuplicatesAfterMigration = (futureMobileStructure: DeepStructure) => {
    if (!conversionUtils.isPageComponent(futureMobileStructure)) {
        return
    }
    const duplicates = findDuplicates(futureMobileStructure)
    removeDuplicates(duplicates, futureMobileStructure)
}

export const getDesktopComponents = structure => {
    return conversionUtils.getAllCompsInStructure(structure, false, _.negate(conversionUtils.isDesktopOnlyComponent))
}

export const getMobileComponents = structure => {
    return conversionUtils.getAllCompsInStructure(structure)
}

export const shouldEnableFitContainerToChildrenHeightRecoursively = (settings: ConversionSettings) =>
    settings.fitContainerToChildrenHeightRecoursively && settings.enableImprovedMergeFlow
