import $ from 'zepto'
import _ from 'lodash'
import warmupUtilsLib from '@wix/santa-core-utils'

function patchCss(nodesMap, id, css) {
    const node = nodesMap[id]
    if (node) {
        $(node).css(css)
    } else {
        warmupUtilsLib.log.error(
            `Cannot find node with id {${id}} to patch css. Either the node doesn't exist, or it was never measured, or it was measured under a different id.`
        )
    }
}

function patchAttributes(nodesMap, id, attributes) {
    const node = nodesMap[id]
    if (node) {
        $(node).attr(attributes)
    } else {
        warmupUtilsLib.log.error(
            `Cannot find node with id {${id}} to patch attributes. Either the node doesn't exist, or it was never measured, or it was measured under a different id.`
        )
    }
}

function patchAttributesNS(nodesMap, id, attributesDefs) {
    const node = nodesMap[id]
    if (node) {
        _.forOwn(attributesDefs, definition => {
            node.setAttributeNS(definition.ns, definition.attribute, definition.value)
        })
    } else {
        warmupUtilsLib.log.error(
            `Cannot find node with id {${id}} to patch attributesNS. Either the node doesn't exist, or it was never measured, or it was measured under a different id.`
        )
    }
}

function patchEvents(nodesMap, id, events) {
    const node = nodesMap[id]
    if (node) {
        _.forEach(_.keys(events), event => {
            node[event] = events[event]
        })
    } else {
        warmupUtilsLib.log.error(
            `Cannot find node with id {${id}} to patch events. Either the node doesn't exist, or it was never measured, or it was measured under a different id.`
        )
    }
}

function patchData(nodesMap, id, data) {
    const node = nodesMap[id]
    if (node) {
        const $node = $(node)
        _.forOwn(data, (dataVal, dataKey) => {
            $node.data(dataKey, dataVal)
        })
    } else {
        warmupUtilsLib.log.error(
            `Cannot find node with id {${id}} to patch data. Either the node doesn't exist, or it was never measured, or it was measured under a different id.`
        )
    }
}

/**
 * @typedef {function(string, object)} layout_patcher
 */

/**
 * @typedef {{
 *  css: layout_patcher,
 *  attr: layout_patcher,
 *  attrNS: layout_patcher,
 *  data: layout_patcher,
 *  events: layout_patcher
 * }} patchers
 */

/**
 *
 * @param nodesMap
 * @returns {patchers}
 */
function createPatchers(nodesMap) {
    return {
        css: _.partial(patchCss, nodesMap),
        attr: _.partial(patchAttributes, nodesMap),
        attrNS: _.partial(patchAttributesNS, nodesMap),
        data: _.partial(patchData, nodesMap),
        events: _.partial(patchEvents, nodesMap)
    }
}

export default createPatchers
