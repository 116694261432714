import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import documentModeInfo from '../documentMode/documentModeInfo'
import constants from '../constants/constants'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import layoutSettingsUtils from '../structure/utils/layoutSettingsUtils'

function getViewMode(ps: PS, viewMode) {
    return mobileUtil.getViewMode(ps, viewMode, documentModeInfo)
}

const DEFAULT_GAP_DATA = {
    pagesToFooterGap: 0,
    headerToPagesGap: 0
}
const SUPPORTED_GAPS = _.keys(DEFAULT_GAP_DATA)

function getGapValues(layoutSettings) {
    return _(layoutSettings).pick(SUPPORTED_GAPS).defaults(DEFAULT_GAP_DATA).value()
}

function sanitizeGapsData(layoutSettings, newGapData) {
    const existingGaps = getGapValues(layoutSettings)

    return _(newGapData)
        .pick(SUPPORTED_GAPS)
        .mapValues((value, gapName) => Math.min(existingGaps[gapName], Math.max(value, 0)))
        .defaults(existingGaps)
        .pickBy(_.identity)
        .value()
}

export default {
    getGaps(ps: PS) {
        const layoutSettings = layoutSettingsUtils.getLayoutSettings(ps)

        return layoutSettings ? _.pick(layoutSettings, SUPPORTED_GAPS) : {}
    },
    updateGaps(ps: PS, gapData) {
        const layoutSettingsPointer = ps.pointers.getInnerPointer(
            ps.pointers.data.getDataItemFromMaster('masterPage'),
            'layoutSettings'
        )
        const layoutSettings = ps.dal.get(layoutSettingsPointer)

        if (!layoutSettings) {
            return
        }

        const gapsToUpdate = sanitizeGapsData(layoutSettings, gapData)
        // @ts-expect-error
        const newLayoutSettings = _(layoutSettings).omit(SUPPORTED_GAPS).assign(gapsToUpdate).value()
        ps.dal.set(layoutSettingsPointer, newLayoutSettings)
    },
    isUsingSectionsLayout(ps: PS) {
        const layoutSettings = layoutSettingsUtils.getLayoutSettings(ps)
        return _.get(layoutSettings, 'useDesktopSectionsLayout', false)
    },
    getFooter(ps: PS, viewMode) {
        return ps.pointers.components.getFooter(getViewMode(ps, viewMode))
    },
    getHeader(ps: PS, viewMode) {
        return ps.pointers.components.getHeader(getViewMode(ps, viewMode))
    },
    getPagesContainer(ps: PS, viewMode) {
        return ps.pointers.components.getPagesContainer(getViewMode(ps, viewMode))
    },
    getSiteStructure(ps: PS, viewMode) {
        return ps.pointers.components.getMasterPage(getViewMode(ps, viewMode))
    },
    getSiteStructureId() {
        return constants.MASTER_PAGE_ID
    }
}
