import _ from 'lodash'
const getPointerMap = (pointers = {}) => ({
    CLIENT_SPEC_MAP: _.invoke(pointers, ['rendererModel', 'getClientSpecMap']),
    SITE_META_DATA: _.invoke(pointers, ['rendererModel', 'getSiteMetaData']),
    META_SITE_ID: _.invoke(pointers, ['rendererModel', 'getMetaSiteId']),
    SITE_ID: _.invoke(pointers, ['rendererModel', 'getSiteId']),
    GEO: _.invoke(pointers, ['rendererModel', 'getGeo']),
    LANGUAGE_CODE: _.invoke(pointers, ['rendererModel', 'getLanguageCode']),
    DOCUMENT_TYPE: _.invoke(pointers, ['rendererModel', 'getDocumentType']),
    PREMIUM_FEATURES: _.invoke(pointers, ['rendererModel', 'getPremiumFeatures']),
    USER_ID: _.invoke(pointers, ['rendererModel', 'getUserId']),
    PASSWORD_PROTECTED_PAGES: _.invoke(pointers, ['rendererModel', 'getPasswordProtectedPages']),
    CURRENCY: _.invoke(pointers, ['rendererModel', 'getCurrency']),
    TIME_ZONE: _.invoke(pointers, ['rendererModel', 'getTimeZone']),
    LOCALE: _.invoke(pointers, ['rendererModel', 'getLocale']),
    REGIONAL_LANGUAGE: _.invoke(pointers, ['rendererModel', 'getRegionalLanguage']),
    APPLICATION_TYPE: _.invoke(pointers, ['rendererModel', 'getApplicationType']),
    MULTILINGUAL: _.invoke(pointers, ['rendererModel', 'getMultilingualInfo']),
    SESSION_PAGES_TO_HASH_PASSWORD: _.invoke(pointers, ['rendererModel', 'getPageToHashedPassword']),
    SITE_DISPLAY_NAME: _.invoke(pointers, ['rendererModel', 'getSiteDisplayName']),
    ORIGINAL_LANGUAGE: _.invoke(pointers, ['multilingual', 'originalLanguage']),
    CURRENT_LANGUAGE_CODE: _.invoke(pointers, ['multilingual', 'currentLanguageCode']),
    TRANSLATION_LANGUAGES: _.invoke(pointers, ['multilingual', 'translationLanguages']),
    EDITOR_SESSION_ID: _.invoke(pointers, ['documentServicesModel', 'getEditorSessionId']),
    SITE_NAME: _.invoke(pointers, ['documentServicesModel', 'getSiteName']),
    MEDIA_USER_UPLOAD_TOKEN: _.invoke(pointers, ['documentServicesModel', 'getMediaUserUploadToken']),
    MEDIA_SITE_UPLOAD_TOKEN: _.invoke(pointers, ['documentServicesModel', 'getMediaSiteUploadToken']),
    MEDIA_AUTH_TOKEN: _.invoke(pointers, ['rendererModel', 'getMediaAuthToken']),
    IS_HTTPS_ENABLED: _.invoke(pointers, ['documentServicesModel', 'isHttpsEnabled']),
    USE_ONBOARDING: _.invoke(pointers, ['documentServicesModel', 'getUseOnboarding']),
    USED_META_SITE_NAMES: _.invoke(pointers, ['documentServicesModel', 'getUsedMetaSiteNames']),
    IS_PUBLISHED: _.invoke(pointers, ['documentServicesModel', 'isPublished']),
    HAS_SITES: _.invoke(pointers, ['documentServicesModel', 'hasSites']),
    PUBLIC_URL: _.invoke(pointers, ['documentServicesModel', 'getPublicUrl']),
    IS_OWNER: _.invoke(pointers, ['documentServicesModel', 'isOwner']),
    PERMISSIONS: _.invoke(pointers, ['documentServicesModel', 'getPermissions']),
    SITE_TOKEN: _.invoke(pointers, ['documentServicesModel', 'getSiteToken']),
    USER_INFO: _.invoke(pointers, ['documentServicesModel', 'getUserInfo']),
    ORIGINAL_TEMPLATE_ID: _.invoke(pointers, ['documentServicesModel', 'getOriginalTemplateId']),
    PENDING_APPS: _.invoke(pointers, ['documentServicesModel', 'getPendingApps']),
    CUSTOM_HEAD_TAGS: _.invoke(pointers, ['documentServicesModel', 'getCustomHeadTags']),
    META_SITE_DATA: _.invoke(pointers, ['documentServicesModel', 'getMetaSiteData']),
    SITE_VERSION: _.invoke(pointers, ['documentServicesModel', 'getSiteVersion']),
    NEVER_SAVED: _.invoke(pointers, ['save', 'getNeverSaved']),
    IS_DRAFT: _.invoke(pointers, ['documentServicesModel', 'getIsDraft']),
    BRANCH_ID: _.invoke(pointers, ['documentServicesModel', 'getBranchId']),
    SITE_REVISION: _.invoke(pointers, ['save', 'getSiteRevision']),
    SE_INDEXABLE: _.invoke(pointers, ['seo', 'getIndexing']),
    FAVICON: _.invoke(pointers, ['seo', 'getFavicon']),
    SUPPRESS_COOKIES: _.invoke(pointers, ['seo', 'getSuppressTrackingCookies']),
    SOCIAL_THUMBNAIL: _.invoke(pointers, ['seo', 'getThumbnail']),
    SEO_TITLE: _.invoke(pointers, ['seo', 'getTitle']),
    META_TAGS: _.invoke(pointers, ['seo', 'getMetaTags']),
    EXTERNAL_URI_MAPPINGS: _.invoke(pointers, ['seo', 'getRedirectUrls'])
})

const PROPERTY_NAMES = _.mapValues(getPointerMap(), (info, prop) => prop)

export {getPointerMap, PROPERTY_NAMES}
