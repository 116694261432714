function color(str) {
    const isColor = str.charAt(0) === '#'
    const attr = {
        name: isColor ? 'style' : 'class',
        value: isColor ? `color:${str};` : str
    }
    return {
        name: 'span',
        attributes: [attr]
    }
}

function backgroundColor(str) {
    const isColor = str.charAt(0) === '#'
    const attr = {
        name: isColor ? 'style' : 'class',
        value: isColor ? `background-color:${str};` : str
    }
    return {
        name: 'span',
        attributes: [attr]
    }
}

function bold(val) {
    return {
        name: 'strong',
        attributes: [
            {
                name: 'style',
                value: val ? 'font-weight: bold;' : 'font-weight: normal;'
            }
        ]
    }
}

function italic(val) {
    return {
        name: 'em',
        attributes: [
            {
                name: 'style',
                value: val ? 'font-style: italic;' : 'font-style: normal;'
            }
        ]
    }
}

function lineThrough(val) {
    return {
        name: 'strike',
        attributes: [
            {
                name: 'style',
                value: val ? 'text-decoration: line-through;' : 'text-decoration: none;'
            }
        ]
    }
}

function underline(val) {
    return {
        name: 'u',
        attributes: [
            {
                name: 'style',
                value: val ? 'text-decoration: underline;' : 'text-decoration: none;'
            }
        ]
    }
}

export default {
    color,
    backgroundColor,
    bold,
    italic,
    lineThrough,
    underline
}
