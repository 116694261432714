import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'
import PointersCache from './pointersCache'
import DataAccessPointers from './DataAccessPointers'
import './aspectsPointers'
import './componentPointers'
import './componentStructurePointers'
import './compsChangedByRelayoutPointers'
import './dataPointers'
import './svgShapePointers'
import './displayedOnlyComponentsPointers'
import './generalPointers'
import './platformPointers'
import './activeModesPointers'
import './layoutPointers'
import './pagePointers'
import './compBehavioursPointers'
import './wixappsPointers'
import './multilingualPointers'
import './referredStructurePointers'
import './partnersPointers'
import './blocksPointers'

export default {
    pointerGeneratorsRegistry,
    PointersCache,
    DataAccessPointers
}
