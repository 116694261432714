import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentsMetaData from '../../../componentsMetaData/componentsMetaData'
import dataModel from '../../../dataModel/dataModel'
import mobileUtil from '../../../mobileUtilities/mobileUtilities'
import mobileHintsAPI from './mobilePresetsAPI'
import mobileHintPresetsHooks from './mobilePresetsHooks'

const {applyPresetsToConversionData, createDuplicateMobilePreset: createMobilePreset} = mobileHintsAPI

function isHidden(ps: PS, componentPointer: Pointer): boolean {
    const mobileHints = dataModel.getMobileHintsItem(ps, componentPointer)
    return mobileHints?.hidden
}

function shouldCreateMobilePreset(ps: PS, componentPointer: Pointer) {
    if (!mobileUtil.isMobileSupported(ps)) {
        return false
    }
    const pageId = _.get(ps.pointers.full.components.getPageOfComponent(componentPointer), 'id')
    if (!ps.dal.full.isExist(componentPointer) || isHidden(ps, componentPointer)) {
        return false
    }
    const desktopComponent = ps.dal.full.get(componentPointer)
    const createMobilePresets = componentsMetaData.public.getMobileConversionConfigByName(
        ps,
        desktopComponent,
        'createMobilePresets',
        pageId
    )
    if (createMobilePresets === false) {
        return false
    }
    const mobileComponentPointer = ps.pointers.components.getMobilePointer(componentPointer)
    return !desktopComponentHasChanges(ps, componentPointer, mobileComponentPointer)
}

function desktopComponentHasChanges(ps: PS, desktopPointer: Pointer, mobilePointer: Pointer) {
    const desktopChildren: Pointer[] = ps.pointers.structure.getChildren(desktopPointer)
    const desktopCompsPresentInMobile: Pointer[] = _.reject(desktopChildren, child => isHidden(ps, child))
    const mobileChildren = ps.pointers.structure.getChildren(mobilePointer)
    const mobileCompsPresentInDesktop = _.reject(mobileChildren, child =>
        componentsMetaData.public.isMobileOnly(ps, child)
    )
    const xor = _.xor(_.map(desktopCompsPresentInMobile, 'id'), _.map(mobileCompsPresentInDesktop, 'id'))
    if (!_.isEmpty(xor)) {
        return true
    }

    return _.some(desktopCompsPresentInMobile, desktopChild => {
        const mobileChild = ps.pointers.components.getMobilePointer(desktopChild)
        return desktopComponentHasChanges(ps, desktopChild, mobileChild)
    })
}

const initialize = () => {
    mobileHintPresetsHooks.initialize()
}

export default {
    initialize,
    createMobilePreset,
    applyPresetsToConversionData,
    shouldCreateMobilePreset
}
