import metaDataUtils from '../metaDataUtils'

const {isMembersMenu} = metaDataUtils

export default {
    isRepeatable: false,
    canBeStretched: true,
    styleCanBeApplied: true,
    mobileConversionConfig: {
        hideByDefault(ps, compStructure, pageId) {
            return isMembersMenu(ps, compStructure, pageId) ? false : undefined
        },
        isScreenWidth(ps, compStructure, pageId) {
            return isMembersMenu(ps, compStructure, pageId) ? true : undefined
        },
        desktopOnly(ps, compStructure, pageId) {
            return !isMembersMenu(ps, compStructure, pageId)
        },
        fixedSize(ps, compStructure, pageId) {
            return isMembersMenu(ps, compStructure, pageId) ? {height: 48} : undefined
        }
    }
}
