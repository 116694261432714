import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import {ajaxLibrary} from '@wix/santa-ds-libs/src/warmupUtils'
import ProvisionUrlBuilder from '../utils/ProvisionUrlBuilder'
import clientSpecMapService from './clientSpecMapService'
import appStoreService from './appStoreService'
import metaData from '../../siteMetadata/siteMetadata'
import clientSpecMapMD from '../../siteMetadata/clientSpecMap'
import tpaUtils from '../utils/tpaUtils'
import provisionUtils from '../utils/provisionUtils'

//site not save
const demoPreSaveProvision = _.template(
    '<%= provisionServerBaseUrl %>/appStore/demo/pre-save-provision/<%= metaSiteId %>/<%= applicationId %>'
)
const preSaveProvision = _.template('<%= provisionServerBaseUrl %>/appStore/pre-save-provision/<%= appDefinitionId %>')

//site first save
const completeSaveProvision = _.template(
    '<%= provisionServerBaseUrl %>/appStore/post-save-complete-provision?metaSiteId=<%= metaSiteId %>&editorSessionId=<%= editorSessionId %>'
)

//site already saved i.e, real provision
const postSaveProvision = _.template('<%= provisionServerBaseUrl %>/appStore/provision/<%= appDefinitionId %>')

//client spec map
const clientSpecMapTemplate = _.template(
    '<%= provisionServerBaseUrl %>/appStore/spec/<%= metaSiteId %>/<%= applicationId %>'
)

const disableWixAppEndpointTemplate = _.template(
    '<%= provisionServerBaseUrl %>/appStore/disable/<%= metaSiteId %>/<%= appDefinitionId %>'
)
const restoreWixAppEndpointTemplate = _.template(
    '<%= provisionServerBaseUrl %>/appStore/restore/<%= metaSiteId %>/<%= appDefinitionId %>'
)

const onProvisionCallback = function (ps: PS, onSuccess, result) {
    const resultAppDefinitionData = result.payload
    const resultHasNoApplicationId = _.includes([undefined, -1], resultAppDefinitionData.applicationId)

    if (resultHasNoApplicationId) {
        const clientSpecMap = clientSpecMapMD.getAppsData(ps)
        const currentLargestId = clientSpecMapService.getLargestApplicationId(clientSpecMap)
        resultAppDefinitionData.applicationId = currentLargestId + 1
        resultAppDefinitionData.notProvisioned = true
    } else {
        const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(
            ps,
            resultAppDefinitionData.appDefinitionId
        )
        const isConvertedFromTemplate =
            !tpaUtils.isSiteSaved(ps) &&
            existingAppData &&
            existingAppData.demoMode &&
            !resultAppDefinitionData.demoMode
        if (isConvertedFromTemplate) {
            resultAppDefinitionData.notProvisioned = true
        }
    }

    clientSpecMapService.registerAppData(ps, resultAppDefinitionData)
    ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
        if (tpaUtils.isTpaByAppType(resultAppDefinitionData.type)) {
            provisionUtils.cacheAppMarketDataAfterProvision(ps, resultAppDefinitionData)
        }
    })
    onSuccess(resultAppDefinitionData)
}

const buildProvisionUrl = function (ps: PS, template, appDefinitionId, applicationId?: string, metaSiteId?: string) {
    const provisionServerTopology = getProvisionBaseURLFromTopology(ps)

    return template({
        provisionServerBaseUrl: provisionServerTopology,
        appDefinitionId,
        applicationId,
        metaSiteId
    })
}

const getProvisionBaseURLFromTopology = function (ps: PS) {
    const pointer = ps.pointers.general.getServiceTopology()
    return ps.dal.get(ps.pointers.getInnerPointer(pointer, 'appStoreUrl'))
}

const provisionAppAfterSave = function (ps: PS, appDefinitionId, onSuccess, onError?) {
    const baseUrl = buildProvisionUrl(ps, postSaveProvision, appDefinitionId)
    const metaSiteId = getMetaSiteId(ps)
    // @ts-expect-error
    const url = new ProvisionUrlBuilder(baseUrl)
        .addMetaSiteId(metaSiteId)
        //.addEditorSessionId() //TODO
        .addAcceptJson()
        .build()

    doRequest(ps, url, 'POST', {}, onSuccess, onError)
}

//Demo provision post save is a normal post save provision
const provisionAppDemoAfterSave = function (ps: PS, appDefinitionId, onSuccess, onError?) {
    provisionAppAfterSave(ps, appDefinitionId, onSuccess, onError)
}

const provisionAppDemoBeforeSave = function (ps: PS, applicationId, onSuccess, onError?) {
    const metaSiteId = getMetaSiteId(ps)
    const baseUrl = buildProvisionUrl(ps, demoPreSaveProvision, null, applicationId, metaSiteId)
    // @ts-expect-error
    const url = new ProvisionUrlBuilder(baseUrl).addAcceptJson().build()
    doRequest(ps, url, 'POST', {}, onSuccess, onError)
}

const provisionAppBeforeSave = function (ps: PS, appDefinitionId, onSuccess, onError?) {
    const baseUrl = buildProvisionUrl(ps, preSaveProvision, appDefinitionId)
    // @ts-expect-error
    const url = new ProvisionUrlBuilder(baseUrl).addAcceptJson().build()
    doRequest(ps, url, 'POST', {}, onSuccess, onError)
}

const restoreWixappOnServer = (ps: PS, wixappDefinitionId, onSuccess, onError) => {
    const provisionServerTopology = getProvisionBaseURLFromTopology(ps)

    const enableWixappUrl = restoreWixAppEndpointTemplate({
        appDefinitionId: wixappDefinitionId,
        metaSiteId: getMetaSiteId(ps),
        provisionServerBaseUrl: provisionServerTopology
    })

    doRequest(null, enableWixappUrl, 'POST', {}, onSuccess, onError)
}

const disableWixappOnServer = function (ps: PS, wixappDefinitionId, onSuccess, onError) {
    const provisionServerTopology = getProvisionBaseURLFromTopology(ps)

    const disableWixappsUrl = disableWixAppEndpointTemplate({
        appDefinitionId: wixappDefinitionId,
        metaSiteId: getMetaSiteId(ps),
        provisionServerBaseUrl: provisionServerTopology
    })

    doRequest(null, disableWixappsUrl, 'POST', {}, onSuccess, onError)
}

const getMetaSiteId = function (ps: PS) {
    return metaData.getProperty(ps, metaData.PROPERTY_NAMES.META_SITE_ID)
}

const refreshSpecMap = function (ps: PS, applicationId, metaSiteId: string, onSuccess, onError) {
    const baseUrl = buildProvisionUrl(ps, clientSpecMapTemplate, null, applicationId, metaSiteId)
    // @ts-expect-error
    const url = new ProvisionUrlBuilder(baseUrl).addAcceptJson().build()
    doRequest(ps, url, 'GET', undefined, onSuccess, onError)
}

const completeProvisionAppsAfterSave = function (
    provisionBaseUrl: string,
    metaSiteId: string,
    editorSessionId: string,
    clientSpecMap,
    onSuccess?,
    onError?
) {
    const baseUrl = completeSaveProvision({
        provisionServerBaseUrl: provisionBaseUrl,
        metaSiteId,
        editorSessionId
    })

    // @ts-expect-error
    const url = new ProvisionUrlBuilder(baseUrl).addAcceptJson().build()

    let appsDataToSend = _.filter(clientSpecMap, 'notProvisioned')

    appsDataToSend = _.filter(appsDataToSend, function (app) {
        return !tpaUtils.isTpaByAppType(app.type)
    })

    const apps = _.map(appsDataToSend, function (appData) {
        return {
            appDefinitionId: appData.appDefinitionId,
            applicationId: appData.applicationId.toString(),
            instanceId: appData.instanceId
        }
    })

    if (!_.isEmpty(apps)) {
        const data = {
            apps,
            metaSiteId
        }

        doRequest(undefined, url, 'POST', data, onSuccess, onError)
    } else {
        onSuccess()
    }
}

const provisionAppFromSourceTemplate = function (ps: PS, appDefinitionId, sourceTemplateId, onSuccess, onError) {
    appStoreService.provisionAppFromSourceTemplate(ps, appDefinitionId, sourceTemplateId, onSuccess, onError)
}

const doRequest = function (ps: PS, url: string, type, data, onSuccess, onError) {
    ajaxLibrary.ajax({
        type: type || 'GET',
        url,
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json',
        success(response) {
            if (ps) {
                onProvisionCallback(ps, onSuccess, response)
            } else {
                onSuccess(response)
            }
        },
        error: onError
    })
}

export default {
    restoreWixappOnServer,
    disableWixappOnServer,
    provisionAppAfterSave,
    provisionAppBeforeSave,
    provisionAppDemoAfterSave,
    provisionAppDemoBeforeSave,
    completeProvisionAppsAfterSave,
    refreshSpecMap,
    provisionAppFromSourceTemplate
}
