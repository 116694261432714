import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import experiment from 'experiment-amd'
import dsCompRegistrar from '../dsCompRegistrar/dsCompRegistrar'
import editorElementsRegistry, {getComponentsLibraries} from '@wix/editor-elements-registry/documentManagement'
import type {PS} from '@wix/document-services-types'

const loadExternalComponentsFromEditorElements = (ps: PS) => {
    const ptr = ps.pointers.general.getServiceTopology()
    const serviceTopology = ps.dal.get(ptr)
    const currentUrl = ps.siteAPI.getCurrentUrl()
    const libraries = getComponentsLibraries({
        serviceTopology,
        url: currentUrl.full,
        useNewStatics: experiment.isOpen('specs.editor-elements.useNewStatics'),
        experimentalMobileLibrary: experiment.isOpen('specs.mobile-elements.useRegistry')
    })
    const registryOptions = {libraries, dev: ps.siteAPI.isDebugMode(), mode: 'eager'}
    const dmCompRegistrarOptions = dsCompRegistrar.getRegistrarOptions(ps)
    if (!_.isEmpty(libraries)) {
        return dsCompRegistrar.registerComponentsFromExternalRegistryWithOptions(
            editorElementsRegistry,
            registryOptions,
            dmCompRegistrarOptions
        )
    }
}

const loadExternalComponentsForDS_new = async (ps: PS) => {
    await ps.extensionAPI.componentsRegistry.waitRegistryLoaded()
    const components = ps.extensionAPI.componentsRegistry.getComponents()

    Object.entries(components).forEach(([componentType, componentModel]) => {
        dsCompRegistrar.registerComponent(ps, {componentType, ...(componentModel as object)})
    })
}

const loadExternalComponentsForDS = async (ps: PS) => {
    try {
        if (experiment.isOpen('dm_useComponentRegistry')) {
            await loadExternalComponentsForDS_new(ps)
        } else {
            await loadExternalComponentsFromEditorElements(ps)
        }
    } catch (err) {
        santaCoreUtils.log.error('Could not load external components from external component registry', err)
    }
}

export default {
    loadExternalComponentsForDS
}
