import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import dataModel from '../dataModel/dataModel'

function getDevicePropName(ps: PS, pointer: Pointer) {
    return ps.pointers.components.getViewMode(pointer).toLowerCase()
}

function getPageProperties(ps: PS, pagePointer: Pointer) {
    return _.get(dataModel.getPropertiesItem(ps, pagePointer), getDevicePropName(ps, pagePointer))
}

function mergePageProps(ps: PS, pagePointer: Pointer, chunkToMerge) {
    const allPageProps =
        dataModel.getPropertiesItem(ps, pagePointer) || dataModel.createPropertiesItemByType(ps, 'PageProperties')
    const path = getDevicePropName(ps, pagePointer)
    let mergedChunk

    if (_.isPlainObject(chunkToMerge)) {
        const chunkFromDAL = _.get(allPageProps, path, {})
        mergedChunk = _.assign(chunkFromDAL, chunkToMerge)
    } else {
        mergedChunk = chunkToMerge
    }
    _.set(allPageProps, path, mergedChunk)

    return allPageProps
}

function updatePageProperties(ps: PS, pagePointer: Pointer, props) {
    const mergedProps = mergePageProps(ps, pagePointer, props)
    dataModel.updatePropertiesItem(ps, pagePointer, mergedProps)
}

export default {
    getPageProperties,
    updatePageProperties
}
