import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import siteMetadataAPI from '../siteMetadata/siteMetadata'

function getSiteMetaData(ps: PS) {
    return siteMetadataAPI.getProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA)
}

function getBrowserThemeColor(ps: PS) {
    return getSiteMetaData(ps).browserThemeColor
}

function setBrowserThemeColorMetaData(ps: PS, value) {
    const siteMetaData = getSiteMetaData(ps)
    siteMetaData.browserThemeColor = value
    siteMetadataAPI.setProperty(ps, siteMetadataAPI.PROPERTY_NAMES.SITE_META_DATA, siteMetaData)
}

function validateColorHex(color: string) {
    if (!/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color)) {
        throw new Error('invalid color, should be an hex code')
    }
}

function setBrowserThemeColor(ps: PS, color: string) {
    validateColorHex(color)
    setBrowserThemeColorMetaData(ps, color)
}

function isBrowserThemeColorSet(ps: PS) {
    return !_.isEmpty(getBrowserThemeColor(ps))
}

export default {
    getBrowserThemeColor,
    setBrowserThemeColor,
    resetBrowserThemeColor: (ps: PS) => setBrowserThemeColorMetaData(ps, null),
    isBrowserThemeColorSet
}
