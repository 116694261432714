import experiment from 'experiment-amd'
import dsUtils from '../utils/utils'
import tpaUtils from '../tpa/utils/tpaUtils'
import type {Pointer, PS} from '@wix/document-services-types'

const CONTROLLER_TYPE = 'platform.components.AppController'
const APP_WIDGET_TYPE = 'platform.components.AppWidget'

function isOOIController(ps: PS, componentType) {
    if (experiment.isOpen('dm_moveControllerStageDataToExt')) {
        return ps.extensionAPI.appController.isOOIController(componentType)
    }
    return tpaUtils.isTpaByCompType(componentType)
}

function verifyController(ps: PS, controllerRef: Pointer) {
    if (experiment.isOpen('dm_moveControllerStageDataToExt')) {
        return ps.extensionAPI.appController.verifyController(controllerRef)
    }
    const componentType = dsUtils.getComponentType(ps, controllerRef)
    if (componentType !== CONTROLLER_TYPE && componentType !== APP_WIDGET_TYPE && !isOOIController(ps, componentType)) {
        throw new Error('controllerRef component type is invalid - should be an AppController or AppWidget')
    }
}

export default {
    isOOIController,
    verifyController
}
