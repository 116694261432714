import type {DocumentManager} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {DATA_TYPES} from '../../constants/constants'
import {cleanRef} from './utils/migrationUtil'

const checkAndFixMenuItem = (documentManager: DocumentManager, pointer: Pointer) => {
    const {dal, pointers, logger} = documentManager

    const menuItem = dal.get(pointer)
    const items: string[] = menuItem?.items || []
    const fixedItems = items.filter((ref: string) => {
        const cleanedRef = cleanRef(ref)
        const dataPointer = pointers.data.getDataByQueryPointer(cleanedRef, 'masterPage', DATA_TYPES.data)

        return dal.has(dataPointer)
    })

    if (items.length !== fixedItems.length) {
        logger.interactionEnded('fixBrokenMenuRefs', {extras: {menuItem, namespace: pointer.type}})
        dal.set(pointer, {...menuItem, items: fixedItems})
    }
}

const fixForNamespaceAndType = (documentManager: DocumentManager, namespace: string, type: string) => {
    const menuItemPointers = documentManager.pointers.page.getPointersByPageAndSchemaType(type, namespace, 'masterPage')
    menuItemPointers.forEach((pointer: Pointer) => checkAndFixMenuItem(documentManager, pointer))
}

const migrateMasterPage = (documentManager: DocumentManager) => {
    fixForNamespaceAndType(documentManager, DATA_TYPES.data, 'CustomMenu')
    fixForNamespaceAndType(documentManager, DATA_TYPES.data, 'BasicMenuItem')
    fixForNamespaceAndType(documentManager, 'multilingualTranslations', 'CustomMenu')
    fixForNamespaceAndType(documentManager, 'multilingualTranslations', 'BasicMenuItem')
}

const name = 'fixBrokenMenusRefs'
const version = 1

export {migrateMasterPage, name, version}
