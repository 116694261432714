import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'

// {
//   "horizontalAlignment": [
//     "left",
//     "right",
//     "center"
//   ],
//   "verticalAlignment": [
//     "top",
//     "bottom",
//     "center"
//   ],
//   "alignmentType": [
//     "nineGrid",
//     "fullHeight",
//     "fullWidth"
//   ],
//   "horizontalOffset": 0,
//   "verticalOffset": 0
// }

const alignmentTypes = {
    vertical: {
        names: ['top', 'center', 'bottom'],
        offset: 'verticalOffset',
        field: 'verticalAlignment'
    },
    horizontal: {
        names: ['left', 'center', 'right'],
        offset: 'horizontalOffset',
        field: 'horizontalAlignment'
    }
}

function alignmentObj(offsets, alignment) {
    const minOffset = _.minBy(offsets, Math.abs)
    const minOffsetIndex = offsets.indexOf(minOffset)
    const offsetType = alignment.names[minOffsetIndex]
    const res = {}
    res[alignment.field] = offsetType
    res[alignment.offset] = minOffset
    return res
}

function getVerticalAlignment(top, height, screenHeight) {
    const center = top + height / 2 // eslint-disable-line no-mixed-operators
    const offsets = [
        Math.round(top),
        Math.round(center - screenHeight / 2), // eslint-disable-line no-mixed-operators
        Math.round(screenHeight - (top + height))
    ]
    return alignmentObj(offsets, alignmentTypes.vertical)
}

function getHorizontalAlignment(left, width, siteWidth, screenWidth) {
    const center = left + width / 2 // eslint-disable-line no-mixed-operators
    const margin = Math.round((screenWidth - siteWidth) / 2)
    const offsets = [
        Math.max(Math.round(left + margin), 0),
        Math.round(center - siteWidth / 2), // eslint-disable-line no-mixed-operators
        Math.max(Math.round(screenWidth - (left + width + margin)), 0)
    ]
    return alignmentObj(offsets, alignmentTypes.horizontal)
}

export default function (ps: PS, compPointer, newLayout) {
    const currLayout = component.layout.get(ps, compPointer)
    if (newLayout.width !== currLayout.width || newLayout.height !== currLayout.height) {
        return
    }
    const currProps = component.properties.get(ps, compPointer)
    const screenSize = ps.siteAPI.getScreenSize()
    const siteWidth = ps.siteAPI.getSiteWidth()
    if (currProps.alignmentType === 'nineGrid' || currProps.alignmentType === 'fullWidth') {
        _.assign(currProps, getVerticalAlignment(newLayout.y, newLayout.height, screenSize.height))
    }
    if (currProps.alignmentType === 'nineGrid' || currProps.alignmentType === 'fullHeight') {
        _.assign(currProps, getHorizontalAlignment(newLayout.x, newLayout.width, siteWidth, screenSize.width))
    }
    component.properties.update(ps, compPointer, currProps)
}
