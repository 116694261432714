import constants from '../../constants/constants'
import metaDataUtils from '../metaDataUtils'

function isDirectChildOfRepeater(ps, component, pageId) {
    const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
    const compPointer = ps.pointers.full.components.getComponent(component.id, pagePointer)
    const parentPointer = ps.pointers.full.components.getParent(compPointer)
    const parentCompType = parentPointer ? metaDataUtils.getComponentType(ps, parentPointer) : null

    return parentCompType === 'wysiwyg.viewer.components.Repeater'
}

export default {
    mobileConversionConfig: {
        stretchHorizontally: isDirectChildOfRepeater
    }
}
