import dataModel from '../dataModel/dataModel'
import common from '../dataModel/common'
import documentServicesSchemas from 'document-services-schemas'
import {constants} from '@wix/santa-core-utils'
import type {PS, Pointer} from '@wix/document-services-types'

const {data: DATA_TYPE} = constants.DATA_TYPES
const {schemasService} = documentServicesSchemas.services

const getA11ySchema = (ps: PS, compPointer: Pointer): null | any => {
    const data = dataModel.getDataItem(ps, compPointer)
    const dataType = data ? data.type : dataModel.getFirstValidDataType(ps, compPointer)
    const schema = schemasService.getSchema(DATA_TYPE, dataType)
    const a11yDefinition = common.getPropertyDefinition(schema, 'a11y', DATA_TYPE)
    if (a11yDefinition) {
        const a11Type = a11yDefinition.refTypes[0]
        const a11TypeSchema = schemasService.getSchema(DATA_TYPE, a11Type)
        return a11TypeSchema
            ? {
                  properties: a11TypeSchema.properties,
                  type: a11Type
              }
            : null
    }
    return null
}

export default {
    getA11ySchema
}
