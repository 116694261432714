function parseQueryParams(url: string) {
    const hashes = url.slice(url.indexOf('?') + 1).split('&')
    const params = {}
    hashes.forEach(hash => {
        const [key, val] = hash.split('=')
        params[key] = decodeURIComponent(val)
    })

    return params
}

export default {
    parseQueryParams
}
