import type {Callback, Callback1, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import theme from '../../theme/theme'

const supportedEvents = [
    'COMPONENT_DISCONNECT',
    'COMPONENT_DELETED',
    'EDIT_MODE_CHANGE',
    'SITE_PUBLISHED',
    'SETTINGS_UPDATED',
    'WINDOW_PLACEMENT_CHANGED',
    'THEME_CHANGE',
    'DEVICE_TYPE_CHANGED',
    'STYLE_PARAMS_CHANGE',
    'SITE_SAVED',
    'ON_MESSAGE_RESPONSE',
    'PUBLIC_DATA_CHANGED',
    'REVISION_CHANGED'
]

let editModeChangeCallbacks: Record<string, Callback1<string>> = {}
let editorEventCallbacks: Record<string, Callback1<string>> = {}
let settingsUpdatedCallbacks: Record<string, Callback1<string>> = {}
let windowPlacementChangedCallbacks: Record<string, Callback1<string>> = {}
let sitePublishedCallbacks: Record<string, Callback> = {}
let deviceTypeChangeCallbacks: Record<string, Callback1<string>> = {}
let themeChangeCallbacks: Record<string, string> = {}
let siteSavedCallbacks: Record<string, Callback> = {}
let deleteHandlers: Record<string, Callback> = {}
let disconnectHandlers: Record<string, Callback> = {}
let historyChangeHandlers: Record<string, Callback> = {}
const publicDataChangedCallbacks: Record<number, Record<string, Callback1<any>>> = {}
const compLoadedCallbacks: Callback1<{compPointer: Pointer}>[] = []
const compsLoaded: Pointer[] = []

const registerDeleteCompHandler = function (compId: string, callback: Callback) {
    deleteHandlers[compId] = callback
}

const registerDisconnectCompHandler = function (compId: string, callback: Callback) {
    disconnectHandlers[compId] = callback
}

const registerHistoryChangeHandler = function (compId: string, callback: Callback) {
    historyChangeHandlers[compId] = callback
}

const executeDeleteHandler = function (compId: string) {
    if (isDeleteHandlerExists(compId)) {
        deleteHandlers[compId]()
        delete deleteHandlers[compId]
    }
}

const executeDisconnectHandler = function (compId: string) {
    if (isDisconnectHandlerExists(compId)) {
        disconnectHandlers[compId]()
        delete disconnectHandlers[compId]
    }
}

const executeHistoryChangeHandler = function (compId: string) {
    if (isHistoryChangeHandlerExists(compId)) {
        historyChangeHandlers[compId]()
    }
}

const isDeleteHandlerExists = function (compId: string) {
    return !_.isUndefined(deleteHandlers[compId])
}

const isDisconnectHandlerExists = function (compId: string) {
    return !_.isUndefined(disconnectHandlers[compId])
}

const isHistoryChangeHandlerExists = function (compId: string) {
    return !_.isUndefined(historyChangeHandlers[compId])
}

const unRegisterHandlers = function (ps: PS, compId: string) {
    editModeChangeCallbacks = _.omit(editModeChangeCallbacks, compId)
    editorEventCallbacks = _.omit(editorEventCallbacks, compId)
    settingsUpdatedCallbacks = _.omit(settingsUpdatedCallbacks, compId)
    windowPlacementChangedCallbacks = _.omit(windowPlacementChangedCallbacks, compId)
    sitePublishedCallbacks = _.omit(sitePublishedCallbacks, compId)
    deviceTypeChangeCallbacks = _.omit(deviceTypeChangeCallbacks, compId)
    siteSavedCallbacks = _.omit(siteSavedCallbacks, compId)
    deleteHandlers = _.omit(deleteHandlers, compId)
    disconnectHandlers = _.omit(disconnectHandlers, compId)
    historyChangeHandlers = _.omit(historyChangeHandlers, compId)

    if (!_.isUndefined(themeChangeCallbacks[compId])) {
        theme.events.onChange.removeListener(ps, themeChangeCallbacks[compId])
        themeChangeCallbacks = _.omit(themeChangeCallbacks, compId)
    }
}

const registerEditModeChangeHandler = function (compId: string, callback: Callback1<string>) {
    editModeChangeCallbacks[compId] = callback
}

const registerEditorEventHandler = function (compId: string, callback: Callback1<string>) {
    editorEventCallbacks[compId] = callback
}

const registerSitePublishedHandler = function (compId: string, callback: Callback) {
    sitePublishedCallbacks[compId] = callback
}

const registerThemeChangeHandler = function (ps: PS, compId: string, callback) {
    if (_.isUndefined(themeChangeCallbacks[compId])) {
        themeChangeCallbacks[compId] = theme.events.onChange.addListener(ps, callback)
    }
}

const registerPublicDataChangedHandler = function (
    compId: string,
    applicationId: number | string,
    callback: Callback1<any>
) {
    if (!publicDataChangedCallbacks[applicationId]) {
        publicDataChangedCallbacks[applicationId] = {}
    }
    const callbacksForApp = publicDataChangedCallbacks[applicationId]
    callbacksForApp[compId] = callback
}

const registerSettingsUpdatedHandler = function (compId: string, callback: Callback1<string>) {
    settingsUpdatedCallbacks[compId] = callback
}

const registerWindowPlacementChangedHandler = function (compId: string, callback: Callback1<string>) {
    windowPlacementChangedCallbacks[compId] = callback
}

const registerDeviceTypeChangeHandler = function (compId: string, callback: Callback1<string>) {
    deviceTypeChangeCallbacks[compId] = callback
}

const registerSiteSavedHandler = function (compId: string, callback: Callback) {
    siteSavedCallbacks[compId] = callback
}

const editModeChange = function (editorMode: string) {
    _.forEach(editModeChangeCallbacks, callback => {
        callback(editorMode)
    })
}

const triggerEditorEvent = function (msg) {
    _.forEach(editorEventCallbacks, callback => {
        callback(msg)
    })
}

const sitePublished = function () {
    _.forEach(sitePublishedCallbacks, callback => {
        callback()
    })
}

const siteSaved = function () {
    _.forEach(siteSavedCallbacks, callback => {
        callback()
    })
}

const deviceTypeChange = function (deviceType: string) {
    _.forEach(deviceTypeChangeCallbacks, callback => {
        callback(deviceType)
    })
}

const triggerOnWindowPlacementChanged = function (msg) {
    const {placement} = msg.data
    const {compId} = msg
    const callback = windowPlacementChangedCallbacks[compId]
    if (callback) {
        callback(placement)
    }
}

const callSettingsUpdateCallback = function (ps: PS, compId: string, message, appDefinitionId: string) {
    const callback = settingsUpdatedCallbacks[compId]
    if (callback) {
        callback(message)
    }

    if (ps.siteAPI.triggerOnAppSettingsUpdate) {
        ps.siteAPI.triggerOnAppSettingsUpdate({compId, appDefinitionId, updates: message})
    }
}

const callPublicDataChangedCallbackForAllAppRegisteredComps = function (applicationId: number, data) {
    const callbacks = publicDataChangedCallbacks[applicationId]
    _.forEach(callbacks, function (callback) {
        callback(data)
    })
}

const callPublicDataChangedCallback = function (compId: string, applicationId: number, data) {
    const callbacks = publicDataChangedCallbacks[applicationId]
    const callback = _.get(callbacks, compId)
    if (callback) {
        callback(data)
    }
}

const callCompLoaded = function (compPointer: Pointer) {
    compsLoaded.push(compPointer)
    _.forEach(compLoadedCallbacks, callback => {
        callback({
            compPointer
        })
    })
}

const registerCompLoaded = function (callback: Callback1<{compPointer: Pointer}>) {
    _.forEach(compsLoaded, compPointer => {
        callback({
            compPointer
        })
    })
    compLoadedCallbacks.push(callback)
}

export default {
    supportedEvents,
    unRegisterHandlers,
    registerEditModeChangeHandler,
    registerSettingsUpdatedHandler,
    registerWindowPlacementChangedHandler,
    registerSitePublishedHandler,
    registerDeviceTypeChangeHandler,
    registerSiteSavedHandler,
    registerCompLoaded,
    registerHistoryChangeHandler,
    callCompLoaded,
    editModeChange,
    sitePublished,
    siteSaved,
    deviceTypeChange,
    triggerOnWindowPlacementChanged,
    registerThemeChangeHandler,
    callSettingsUpdateCallback,
    registerDeleteCompHandler,
    registerDisconnectCompHandler,
    executeDeleteHandler,
    executeDisconnectHandler,
    executeHistoryChangeHandler,
    isDisconnectHandlerExists,
    isDeleteHandlerExists,
    registerPublicDataChangedHandler,
    callPublicDataChangedCallback,
    callPublicDataChangedCallbackForAllAppRegisteredComps,
    registerEditorEventHandler,
    triggerEditorEvent
}
