'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

const isHoverBox = comp => comp.componentType === 'wysiwyg.viewer.components.HoverBox'

const getModesData = (pageJson, comp) => {
    const definitions = _.get(comp, ['modes', 'definitions'])
    const overrides = _.get(comp, ['modes', 'overrides'])
    const mobileDisplayedModeId = _.get(pageJson, [
        'data',
        'component_properties',
        comp.propertyQuery,
        'mobileDisplayedModeId'
    ])
    return {
        comp,
        overrides,
        definitions,
        mobileDisplayedModeId
    }
}
const {HOVER} = coreUtils.siteConstants.COMP_MODES_TYPES

/**
 * This function goes over hoverBox mobile design query, and replaces with desktop overrides if it's not one of them.
 * The hoverBox default design query is not being used in the viewer (only the "modes.overrides" design queries).
 * In mobile, only the default design query matter, so it must be one of desktop "modes.overrides".
 *
 * @param pageJson - page JSON
 * @param compId - compId string
 * @param mobileHoverBox - comp from JSON
 * @param desktopModesData - "modes" property on hoverBox
 * @param currentMobileMode - The mode being displayed in mobile (HOVER / DEFAULT)
 */
const fixHoverBoxMobileDesignQuery = (pageJson, compId, mobileHoverBox, desktopModesData, currentMobileMode) => {
    const mobileDesignQuery = mobileHoverBox.designQuery
    const desktopOverrides = desktopModesData.overrides
    if (
        !_.isEmpty(desktopOverrides) &&
        desktopOverrides.every(hoverBoxOverride => _.get(hoverBoxOverride, 'designQuery') !== mobileDesignQuery)
    ) {
        const desktopDefinitionToTake = _.find(desktopModesData.definitions, {type: currentMobileMode.type})
        const desktopDefinitionModeId = _.get(desktopDefinitionToTake, 'modeId')
        if (desktopDefinitionModeId) {
            const overrideToTake = _.find(desktopOverrides, override =>
                override.modeIds.includes(desktopDefinitionModeId)
            )
            const designQueryToUpdate = _.get(overrideToTake, 'designQuery')
            if (designQueryToUpdate) {
                _.set(mobileHoverBox, 'designQuery', designQueryToUpdate)
            }
        }
    }
}

/**
 * @exports utils/dataFixer/plugins/hoverBoxPropertyDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'hoverBoxPropertyDataFixer',
    version: 1,
    exec(pageJson) {
        const desktopHoverBoxes = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false, isHoverBox)
        const mobileHoverBoxes = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, true, isHoverBox)

        _.forEach(desktopHoverBoxes, (desktopHoverBox, compId) => {
            const mobileHoverBox = mobileHoverBoxes[compId]
            if (!mobileHoverBox) {
                return
            }
            const desktopModesData = getModesData(pageJson, desktopHoverBox)
            const mobileModesData = getModesData(pageJson, mobileHoverBox)

            const currentMobileMode = _.find(mobileModesData.definitions, {
                modeId: mobileModesData.mobileDisplayedModeId
            }) || {type: HOVER}
            const updatedMode =
                _.find(desktopModesData.definitions, {type: currentMobileMode.type}) ||
                _.find(desktopModesData.definitions, {type: HOVER})
            const comps = [desktopModesData, mobileModesData]

            comps.forEach(({comp}) => {
                _.set(
                    pageJson,
                    ['data', 'component_properties', comp.propertyQuery, 'mobileDisplayedModeId'],
                    updatedMode.modeId
                )
            })
            fixHoverBoxMobileDesignQuery(pageJson, compId, mobileHoverBox, desktopModesData, currentMobileMode)
            _.set(mobileHoverBox, ['modes', 'definitions'], desktopModesData.definitions)
        })
    }
}
