/* eslint prefer-rest-params:0 */
import {pointerUtils} from '@wix/document-manager-core'
import {mockConvertAbsoluteLayoutToResponsive} from '@wix/document-manager-extensions/src/utils/responsiveLayoutUtils'
import {ReportableError} from '@wix/document-manager-utils'
import * as jsonSchemas from '@wix/document-services-json-schemas'
import type {Callback, CompRef, CompStructure, Layout, Pointer, PS} from '@wix/document-services-types'
import {displayedOnlyStructureUtil, guidUtils, log, objectUtils} from '@wix/santa-core-utils'
import {fullToDisplayedJson} from '@wix/santa-ds-libs/src/coreUtils'
import documentServicesSchemas from 'document-services-schemas'
import experiment from 'experiment-amd'
import _ from 'lodash'
import a11yAPI from '../accessibility/componentA11yAPI'
import bi from '../bi/bi'
import biErrors from '../bi/errors'
import componentsMetaData from '../componentsMetaData/componentsMetaData'
import connectionsDataGetter from '../connections/connectionsDataGetter'
import constants from '../constants/constants'
import dataModel from '../dataModel/dataModel'
import hooks from '../hooks/hooks'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import {isCompTypeIsValidForDesignInVariants, shouldUseDesignInVariants} from '../overrides/designOverVariants'
import language from '../siteMetadata/language'
import structure from '../structure/structure'
import structureUtils from '../structure/structureUtils'
import arrangement from '../structure/utils/arrangement'
import layoutConstraintsUtils from '../structure/utils/layoutConstraintsUtils'
import isSystemStyle from '../theme/isSystemStyle'
import theme from '../theme/theme'
import tpaEventHandlersService from '../tpa/services/tpaEventHandlersService'
import tpaUtils from '../tpa/utils/tpaUtils'
import dsUtils, {isError} from '../utils/utils'
import variableUtils from '../variables/variablesUtils'
import design from '../variants/design'
import {replaceVariantsOnSerialized} from '../variants/variantsReplacementUtils'
import variantsUtils from '../variants/variantsUtils'
import componentData from './componentData'
import componentModes from './componentModes'
import componentSerialization from './componentSerialization'
import componentStructureInfo from './componentStructureInfo'
import componentStylesAndSkinsAPI from './componentStylesAndSkinsAPI'
import componentValidations from './componentValidations'
import nicknameContextRegistrar from './nicknameContextRegistrar'
import sanitizeSerializedComponent from './sanitizeSerializedComponent'

const {
    namespaceMapping: {getNamespaceConfig, featureNamespaces}
} = jsonSchemas
const MOBILE_COMP_DATA_PREFIX = constants.DOM_ID_PREFIX.MOBILE

const {
    DATA_TYPES,
    SERIALIZED_DATA_KEYS,
    SERIALIZED_SCOPED_DATA_KEYS,
    COMP_DATA_QUERY_KEYS_WITH_STYLE,
    DATA_TYPES_VALUES_WITH_HASH,
    VARIANTS
} = constants

const SERIALIZED_PROPERTIES_TO_OMIT = [
    'custom',
    'activeModes',
    'layoutResponsive',
    'variants',
    'overrideOwner',
    'templateId',
    ..._.values(SERIALIZED_DATA_KEYS),
    ..._.values(SERIALIZED_SCOPED_DATA_KEYS)
]

const {stripHashIfExists} = dsUtils

interface Mappers {
    oldToNewIdMap: any
    variantMapper?: any
}

interface Optionals {
    mappers?: Mappers
    customId?: string
    isPage?: boolean
    modeIdsInHierarchy?: any
    isAncestorsChecked?: any
}

function initialize() {
    nicknameContextRegistrar.setContextProvider(null)
}

function createCompRef(ps: PS, containerReference: Pointer, optionalCustomId: string): Pointer {
    const id = optionalCustomId || generateNewComponentId()
    const viewMode = ps.pointers.components.getViewMode(containerReference)
    if (displayedOnlyStructureUtil.isRepeatedComponent(containerReference.id)) {
        const itemId = displayedOnlyStructureUtil.getRepeaterItemId(containerReference.id)
        return ps.pointers.components.getUnattached(
            displayedOnlyStructureUtil.getUniqueDisplayedId(id, itemId),
            viewMode
        )
    }
    return ps.pointers.components.getUnattached(id, viewMode)
}

function getComponentToAddRef(ps: PS, containerReference: Pointer, componentDefinition?, optionalCustomId?: string) {
    return createCompRef(ps, containerReference, optionalCustomId)
}

const getAddComponentInteractionParams = (
    ps: PS,
    componentToAddPointer: Pointer,
    containerPointer: Pointer,
    componentDefinition
) => ({
    component_type: componentDefinition.componentType
})

/**
 * @param {ps} ps
 * @param {Pointer} componentToAddPointer - passed automatically from Site
 * @param {Pointer} containerPointer
 * @param {{componentType: String, styleId: String, data: String|Object, properties: String|Object}} componentDefinition
 * @param {string} [optionalCustomId]
 * @param {number} [optionalIndex]
 * @param {Object} [optionalOldToNewMap]
 * @param variantsReplacementOperations
 * @returns {*}
 */
function addComponentToContainer(
    ps: PS,
    componentToAddPointer: Pointer,
    containerPointer: Pointer,
    componentDefinition: any,
    optionalCustomId?: string,
    optionalIndex?: number,
    optionalOldToNewMap?: any,
    variantsReplacementOperations = []
): any {
    if (componentToAddPointer.type !== containerPointer.type) {
        throw new Error(
            `Component ${componentToAddPointer} and Container ${componentToAddPointer} have different types`
        )
    }
    const compType = _.get(componentDefinition, 'componentType')
    const containerType = _.get(ps.dal.get(containerPointer), 'componentType')
    ps.extensionAPI.logger.breadcrumb(
        `Adding comp ${compType}, id: "${componentToAddPointer.id}" , to container: ${containerType}, id: "${containerPointer.id}"`
    )

    if (componentToAddPointer.id && ps.dal.isExist(componentToAddPointer)) {
        throw new Error(
            `Failed to add component to container "${containerPointer.id}" because component "${componentToAddPointer.id}" already exists.`
        )
    }

    addComponent(
        ps,
        componentToAddPointer,
        containerPointer,
        componentDefinition,
        optionalCustomId,
        optionalIndex,
        optionalOldToNewMap,
        variantsReplacementOperations
    )
}

function addComponentInternal(
    ps: PS,
    componentToAddRef,
    containerReference,
    componentDefinition,
    optionalCustomId?: string,
    optionalIndex?: number,
    optionalOldToNewMap?,
    optionalContainerToAddTo?,
    variantsReplacementOperations = []
) {
    const mappers: Mappers = {
        oldToNewIdMap: optionalOldToNewMap || {},
        variantMapper: variantsReplacementOperations
    }

    const optionals: Optionals = {
        mappers,
        isPage: false,
        customId: optionalCustomId
    }

    hooks.executeHook(hooks.HOOKS.ADD_ROOT.BEFORE, componentDefinition.componentType, [
        ps,
        componentToAddRef,
        containerReference,
        componentDefinition,
        optionalCustomId,
        optionalIndex
    ])

    setComponent(ps, componentToAddRef, containerReference, componentDefinition, optionals)

    //TODO: we can change it to just change the definition, if we can extract the getAdjustedLayout from the updateAdjustedLayout..
    if (optionalContainerToAddTo && !_.isEqual(optionalContainerToAddTo, containerReference)) {
        structure.adjustCompLayoutToNewContainer(ps, componentToAddRef, optionalContainerToAddTo)
        structure.addCompToContainer(ps, componentToAddRef, optionalContainerToAddTo, optionalIndex)
    } else if (_.isFinite(optionalIndex)) {
        arrangement.moveToIndex(ps, componentToAddRef, optionalIndex)
    }

    hooks.executeHook(hooks.HOOKS.ADD_ROOT.AFTER, componentDefinition.componentType, [
        ps,
        componentToAddRef,
        containerReference
    ])
}

/**
 * @param {ps} ps
 * @param {Pointer} componentToAddRef  - passed automatically from Site
 * @param {Pointer} containerReference
 * @param {Object} componentDefinition - {componentType: String, styleId: String, data: String|Object, properties: String|Object}
 * @param {string} [optionalCustomId]
 * @param {number} [optionalIndex]
 * @param {Object} [optionalOldToNewMap]
 * @param variantsReplacementOperations
 * @returns {*}
 */
function addComponent(
    ps: PS,
    componentToAddRef: Pointer,
    containerReference: Pointer,
    componentDefinition: any,
    optionalCustomId?: string,
    optionalIndex?: number,
    optionalOldToNewMap?: any,
    variantsReplacementOperations = []
) {
    const containerToAddTo = componentStructureInfo.getContainerToAddComponentTo(ps, containerReference)
    const validationResult = componentValidations.validateComponentToAdd(
        ps,
        componentToAddRef,
        componentDefinition,
        containerToAddTo,
        optionalIndex
    )

    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }

    addComponentInternal(
        ps,
        componentToAddRef,
        containerReference,
        componentDefinition,
        optionalCustomId,
        optionalIndex,
        optionalOldToNewMap,
        containerToAddTo,
        variantsReplacementOperations
    )
}

function addComponentDefinitionAnchor(ps: PS, anchorData, pageId: string, customId: string) {
    if (!anchorData) {
        return null
    }
    return dataModel.addSerializedAnchorDataItemToPage(ps, pageId, anchorData, customId)
}

function addVariantItemToPage(ps: PS, variants, pageId: string, customId: string) {
    if (!variants) {
        return null
    }
    return dataModel.addSerializedVariantItemToPage(ps, pageId, variants, customId)
}

function addComponentDefinitionDataByType(
    ps: PS,
    data,
    itemType: string,
    pageId: string,
    customId?: string,
    oldToNewIdMap?
) {
    if (!data) {
        return null
    }
    if (dataModel.doesItemTypeSupportsRepeatedItem(itemType)) {
        return addRepeatedSupportedNameSpaceDefinition(ps, data, pageId, customId, itemType)
    }
    return dataModel.addSerializedItemToPage(ps, pageId, data, customId, itemType, oldToNewIdMap)
}

function addComponentDefinitionBehaviors(ps: PS, behaviors, pageId: string, customId: string) {
    if (!behaviors) {
        return null
    }
    return dataModel.addSerializedBehaviorsItemToPage(ps, pageId, behaviors, customId)
}

function addComponentMobileHintsDefinition(ps: PS, mobileHints, pageId: string, customId: string) {
    if (!mobileHints) {
        return null
    }
    return dataModel.addSerializedMobileHintsItemToPage(ps, pageId, mobileHints, customId)
}

function addComponentDefinitionConnections(ps: PS, serializedConnections, pageId: string, customId: string) {
    if (!serializedConnections) {
        return null
    }
    return dataModel.addSerializedConnectionsItemToPage(ps, pageId, serializedConnections, customId)
}

function addRepeatedSupportedNameSpaceDefinition(
    ps: PS,
    item,
    pageId: string,
    customId: string,
    itemType: string
): string | null {
    let itemQuery: string | null = null
    if (item) {
        if (_.isObject(item)) {
            // @ts-expect-error
            if (item.type === 'RepeatedData') {
                // @ts-expect-error
                itemQuery = dataModel.addSerializedItemToPage(ps, pageId, item.original, customId, itemType)
                // @ts-expect-error
                _.forEach(item.overrides, function (overriddenItem, itemId) {
                    const uniqueId = displayedOnlyStructureUtil.getUniqueDisplayedId(itemQuery, itemId)
                    dataModel.addSerializedItemToPage(ps, pageId, overriddenItem, uniqueId, itemType)
                })
            } else {
                itemQuery = dataModel.addSerializedItemToPage(ps, pageId, item, customId, itemType)
            }
        } else if (_.isString(item)) {
            const defaultDataItem = dataModel.createDataItemByType(ps, item)
            itemQuery = dataModel.addSerializedItemToPage(ps, pageId, defaultDataItem, customId, itemType)
        }
    }
    return itemQuery
}

function updateLayoutPropsForDockedComponent(componentLayout) {
    if (componentLayout.docked.right || componentLayout.docked.left || componentLayout.docked.hCenter) {
        if (componentLayout.docked.right && componentLayout.docked.left) {
            delete componentLayout.width
        }

        delete componentLayout.x
    }
    if (componentLayout.docked.top || componentLayout.docked.bottom || componentLayout.docked.vCenter) {
        if (componentLayout.docked.top && componentLayout.docked.bottom) {
            delete componentLayout.height
        }

        delete componentLayout.y
    }
}

function validateCompConnections(ps: PS, componentDefinition) {
    const connectionItems = _.get(componentDefinition, ['connections', 'items'], [])
    _.forEach(connectionItems, function (connectionItem) {
        const validationResult = componentValidations.validateComponentConnection(ps, connectionItem)
        if (isError(validationResult)) {
            throw new Error(validationResult.error)
        }
    })
}

function sanitizeCompLayout(ps: PS, componentDefinition) {
    componentDefinition.layout = componentDefinition.layout || {}
    const defaultLayout = {
        x: 0,
        y: 0,
        fixedPosition: false,
        width: 160,
        height: 90,
        scale: 1.0,
        rotationInDegrees: 0.0
    }
    const sanitizedComponentDefLayout: any = {}
    const allowedLayoutParams = componentValidations.ALLOWED_LAYOUT_PARAMS

    _.forEach(allowedLayoutParams, function (layoutParam) {
        const value = componentDefinition.layout[layoutParam]
        if (value) {
            const validationResult = componentValidations.validateLayoutParam(ps, layoutParam, value)
            if (isError(validationResult)) {
                throw new Error(validationResult.error)
            }
            sanitizedComponentDefLayout[layoutParam] = value
        }
    })
    delete sanitizedComponentDefLayout.anchors

    componentDefinition.layout = _.assign(defaultLayout, sanitizedComponentDefLayout)

    if (componentDefinition.layout.docked) {
        updateLayoutPropsForDockedComponent(componentDefinition.layout)
    }
}

function omitPropertiesFromSerializedComp(compDefinition) {
    return _.omit(compDefinition, SERIALIZED_PROPERTIES_TO_OMIT)
}

/**
 * @param {ps} ps
 * @param compToAddPointer
 * @param containerPointer if is null it means that we add a page
 * @param serializedComponent
 * @param optionals
 * @returns {*}
 */
function setComponent(
    ps: PS,
    compToAddPointer: Pointer,
    containerPointer: Pointer,
    serializedComponent,
    optionals: Optionals = {}
) {
    const {mappers, isPage, customId, modeIdsInHierarchy, isAncestorsChecked} = optionals

    function updateDisplayedOnlyComponentsDefs(pageId: string, structureToAdd) {
        const addComponents = ps.pointers.components.getAllDisplayedOnlyComponents(compToAddPointer)
        const shouldUpdateDisplayedOnlyComps =
            !_.isEmpty(addComponents) && displayedOnlyStructureUtil.isRepeatedComponent(compToAddPointer.id)
        if (shouldUpdateDisplayedOnlyComps && (structureToAdd.dataQuery || structureToAdd.designQuery)) {
            _.forEach(addComponents, function (displayedComponent) {
                const itemId = displayedOnlyStructureUtil.getRepeaterItemId(displayedComponent.id)
                if (structureToAdd.dataQuery) {
                    const uniqueDataQueryForItem = displayedOnlyStructureUtil.getUniqueDisplayedId(
                        dsUtils.stripHashIfExists(structureToAdd.dataQuery),
                        itemId
                    )
                    if (!ps.dal.full.isExist(ps.pointers.data.getDataItem(uniqueDataQueryForItem, pageId))) {
                        updateComponentDataDefinition(
                            ps,
                            pageId,
                            uniqueDataQueryForItem,
                            isPage,
                            mappers,
                            _.clone(serializedComp)
                        )
                    }
                }
                if (structureToAdd.designQuery) {
                    const uniqueDesignQueryForItem = displayedOnlyStructureUtil.getUniqueDisplayedId(
                        dsUtils.stripHashIfExists(structureToAdd.designQuery),
                        itemId
                    )
                    if (!ps.dal.full.isExist(ps.pointers.data.getDesignItem(uniqueDesignQueryForItem, pageId))) {
                        updateComponentDesignDefinition(
                            ps,
                            pageId,
                            uniqueDesignQueryForItem,
                            mappers,
                            _.clone(serializedComp)
                        )
                    }
                }
            })
        }
    }

    const requiredDefaults = _.pick(
        componentStructureInfo.buildDefaultComponentStructure(ps, serializedComponent.componentType),
        ['data', 'props', 'design', 'style']
    )
    const serializedComp = _.defaults(serializedComponent, requiredDefaults)
    sanitizeSerializedComponent(serializedComp)

    hooks.executeHook(hooks.HOOKS.ADD.BEFORE, serializedComp.componentType, [
        ps,
        compToAddPointer,
        containerPointer,
        serializedComponent,
        customId,
        isPage,
        mappers,
        modeIdsInHierarchy,
        isAncestorsChecked
    ])
    const validationResult = componentValidations.validateComponentToSet(
        ps,
        compToAddPointer,
        serializedComp,
        customId,
        containerPointer,
        isPage
    )
    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }

    if (serializedComp.layout) {
        delete serializedComp.layout.anchors
    }

    let clonedSerializedComp = objectUtils.cloneDeep(serializedComp)

    if (mappers?.oldToNewIdMap && serializedComp.id) {
        mappers.oldToNewIdMap[serializedComp.id] = compToAddPointer.id
    }
    clonedSerializedComp.id = displayedOnlyStructureUtil.getRepeaterTemplateId(compToAddPointer.id)

    updateDefinitionTypeByComponentType(clonedSerializedComp)
    sanitizeCompLayout(ps, clonedSerializedComp)
    validateCompConnections(ps, clonedSerializedComp)

    optionals.modeIdsInHierarchy = optionals.modeIdsInHierarchy || {
        ancestorsModes: collectAncestorsModeIds(ps, containerPointer, {}),
        oldModeIdsToNew: {}
    }
    optionals.modeIdsInHierarchy.ancestorsModes = _.merge(
        optionals.modeIdsInHierarchy.ancestorsModes,
        getComponentModeIdsFromStructure(clonedSerializedComp)
    )
    clonedSerializedComp = sanitizeComponentModes(
        ps,
        clonedSerializedComp,
        compToAddPointer,
        optionals.modeIdsInHierarchy
    )
    updateComponentModesIdsInStructure(clonedSerializedComp, optionals.modeIdsInHierarchy.oldModeIdsToNew)

    const pageId = isPage ? compToAddPointer.id : ps.pointers.full.components.getPageOfComponent(containerPointer).id
    updateVariantsInStructure(ps, clonedSerializedComp, containerPointer, pageId, mappers, isAncestorsChecked)
    updateComponentVariablesDefinition(ps, clonedSerializedComp, containerPointer, pageId, mappers, isAncestorsChecked)
    deserializeComponentData(ps, clonedSerializedComp, pageId, customId, isPage, mappers, optionals.modeIdsInHierarchy)

    const children = serializedComp.components
    if (children) {
        clonedSerializedComp.components = []
    }

    mobileUtil.resetMobileComponentsForClonedComp(ps, serializedComp, clonedSerializedComp)

    const structureToAdd = omitPropertiesFromSerializedComp(clonedSerializedComp)
    if (!isPage) {
        const childrenPointer = ps.pointers.full.components.getChildrenContainer(containerPointer)
        const newCompIndex = arrangement.getNewIndexForNewComp(ps, structureToAdd, containerPointer)
        ps.dal.full.push(childrenPointer, structureToAdd, compToAddPointer, newCompIndex)
        updateDisplayedOnlyComponentsDefs(pageId, structureToAdd)
    } else {
        ps.dal.full.set(compToAddPointer, structureToAdd)
    }

    if (clonedSerializedComp.translations) {
        const siteTranslations = _.map(language.getFull(ps), lang => lang.languageCode)
        const relevantLanguages = _.intersection(Object.keys(clonedSerializedComp.translations), siteTranslations)

        relevantLanguages.forEach(langCode => {
            dataModel.updateDataItemInLang(ps, compToAddPointer, clonedSerializedComp.translations[langCode], langCode)
        })
    }

    const {slots} = clonedSerializedComp
    setComponentChildrenDefinition(
        ps,
        pageId,
        children,
        slots,
        compToAddPointer,
        mappers,
        optionals.modeIdsInHierarchy,
        clonedSerializedComp.activeModes
    )
    mobileUtil.setMobileComponentChildrenDefinition(
        ps,
        serializedComp,
        pageId,
        mappers,
        optionals.modeIdsInHierarchy,
        clonedSerializedComp.activeModes,
        setComponentChildrenDefinition
    )

    // send the actual compDefinition, instead of its prototype
    const afterArgs = [ps, compToAddPointer, clonedSerializedComp, customId, mappers, containerPointer]
    hooks.executeHook(hooks.HOOKS.ADD.AFTER, clonedSerializedComp.componentType, afterArgs)

    return compToAddPointer
}

function updateComponentModesIdsInStructure(serializedComp, oldModeIdsToNew) {
    _.defaults(oldModeIdsToNew, createNewModeIds(serializedComp))
    updateCompDefinitionModes(serializedComp, oldModeIdsToNew)
    updateCompOverrideModes(serializedComp, oldModeIdsToNew)
    updateCompPropertiesModes(serializedComp, oldModeIdsToNew)
}

function deserializeComponentData(
    ps: PS,
    serializedComp,
    pageId: string,
    optionalCustomId: string,
    isPage: boolean,
    mappers: Mappers,
    modeIdsInHierarchy
) {
    updateComponentBreakpointDefinition(ps, pageId, optionalCustomId, mappers, serializedComp)
    updateComponentDataDefinition(ps, pageId, optionalCustomId, isPage, mappers, serializedComp)
    updateComponentPropsDefinition(ps, pageId, optionalCustomId, mappers, serializedComp)
    updateComponentDesignDefinition(ps, pageId, optionalCustomId, mappers, serializedComp)
    updateComponentBehaviorsDefinition(ps, pageId, optionalCustomId, mappers, modeIdsInHierarchy, serializedComp)
    updateComponentMobileHintsDefinition(ps, pageId, optionalCustomId, mappers, serializedComp)
    updateComponentLayoutDefinition(ps, pageId, optionalCustomId, mappers, serializedComp)
    updateComponentAnchorDefinition(ps, pageId, optionalCustomId, mappers, serializedComp)
    setComponentDefinitionStyle(ps, pageId, mappers, serializedComp)
    updateComponentTransformationsDefinition(ps, pageId, mappers, serializedComp)
    updateComponentReactionsDefinition(ps, pageId, mappers, serializedComp)
    updateComponentPatternsDefinition(ps, pageId, optionalCustomId, mappers, serializedComp)
    updateVariantsArrayDefinitionByType(ps, pageId, optionalCustomId, mappers, serializedComp, DATA_TYPES.states)
    updateVariantsArrayDefinitionByType(ps, pageId, optionalCustomId, mappers, serializedComp, DATA_TYPES.triggers)
    updateComponentTransitionsDefinition(ps, pageId, mappers, serializedComp)
    updateComponentFeaturesDefinitions(ps, pageId, optionalCustomId, mappers, serializedComp)
    deserializeComponentOverridesData(ps, serializedComp, pageId, optionalCustomId, mappers)
    updateComponentConnectionsDefinition(serializedComp, ps, pageId, optionalCustomId, mappers)
}

/**
 * Check if compStructure contains modes that are still relevant under the new container.
 * If no relevant modes, return the displayed json of the component, under the modes that were active when the component was serialized
 * If there are relevant modes, remove only those that are no longer relevant.
 */
function sanitizeComponentModes(ps: PS, compStructure, compToAddPointer: Pointer, modeIdsInHierarchy) {
    // all potential active modes and component's definition modes Ids.
    const potentialModesToAffectComponentInContainer = modeIdsInHierarchy.ancestorsModes

    const shouldCreateDisplayedStructure = _.isEmpty(potentialModesToAffectComponentInContainer)
    if (shouldCreateDisplayedStructure) {
        return createDisplayedComponentStructure(ps, compToAddPointer, compStructure)
    }
    return removeAllUnusedOverrides(compStructure, potentialModesToAffectComponentInContainer)
}

function getComponentModeIdsFromStructure(compStructure) {
    const modeIdsMap = {}
    _.forEach(_.get(compStructure, ['modes', 'definitions']), modeDefinition => {
        modeIdsMap[modeDefinition.modeId] = true
    })
    return modeIdsMap
}

function createDisplayedComponentStructure(ps: PS, compToAddPointer: Pointer, compStructure: CompStructure) {
    const {activeModes} = compStructure
    const displayedComp = fullToDisplayedJson.applyModesOnSerializedStructure(compStructure, activeModes)
    if (!_.isEmpty(activeModes)) {
        updateLayoutXYOnStructure(displayedComp, compStructure.layout)
    }
    return displayedComp
}

function updateLayoutXYOnStructure(structureToUpdate, layout) {
    structureToUpdate.layout.x = _.get(layout, 'x') || _.get(structureToUpdate, ['layout', 'x'])
    structureToUpdate.layout.y = _.get(layout, 'y') || _.get(structureToUpdate, ['layout', 'y'])
}

function collectAncestorsModeIds(ps: PS, componentPointer: Pointer, newModeIdsToOld) {
    let collectedModeIds: string[] = []
    while (componentPointer) {
        const ancestorModeIds = getOldComponentModesIds(ps, componentPointer, newModeIdsToOld)
        collectedModeIds = collectedModeIds.concat(ancestorModeIds)
        componentPointer = ps.pointers.full.components.getParent(componentPointer)
    }

    return _(collectedModeIds).groupBy(_.identity).mapValues(Boolean).value()
}

function getOldComponentModesIds(ps: PS, component: Pointer, newModeIdsToOld): string[] {
    const componentModesIds: string[] = []
    const compModes = componentModes.getComponentModes(ps, component)
    _.forEach(compModes, function (compMode) {
        const oldComponentModeID = newModeIdsToOld[compMode.modeId]
        if (oldComponentModeID) {
            componentModesIds.push(oldComponentModeID)
        } else {
            componentModesIds.push(compMode.modeId)
        }
    })
    return componentModesIds
}

function removeAllUnusedOverrides(compDefinition, modesToMaintain) {
    if (compDefinition && modesToMaintain) {
        let compOverrides = _.get(compDefinition, ['modes', 'overrides'])
        if (compOverrides) {
            compOverrides = _.filter(compOverrides, function (override) {
                return _.every(override.modeIds, modeId => modesToMaintain[modeId])
            })
            compDefinition.modes.overrides = compOverrides
        }
    }
    return compDefinition
}

const otherTPACompsToConvert = [
    'wysiwyg.viewer.components.tpapps.TPASection',
    'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget'
]

function updateDefinitionTypeByComponentType(serializedComp) {
    function convertToContainerConditionally(compToConvert, shouldConvert = false) {
        compToConvert.type = shouldConvert ? 'Container' : 'Component'
        compToConvert.components = shouldConvert && !compToConvert.components ? [] : compToConvert.components
    }

    const {schemasService} = documentServicesSchemas.services
    const {componentType} = serializedComp

    if (componentType === 'wysiwyg.viewer.components.tpapps.TPAWidget') {
        convertToContainerConditionally(serializedComp, true)
        return
    }

    if (otherTPACompsToConvert.includes(componentType)) {
        convertToContainerConditionally(serializedComp, experiment.isOpen('dm_convertTPACompsToContainers'))
        return
    }

    if (schemasService.isRepeater(componentType)) {
        serializedComp.type = 'RepeaterContainer'
    } else if (schemasService.isPage(componentType)) {
        serializedComp.type = 'Page'
    } else if (schemasService.isRefComponent(componentType)) {
        serializedComp.type = 'RefComponent'
    } else if (schemasService.isContainer(componentType)) {
        serializedComp.type = 'Container'
        serializedComp.components = serializedComp.components || []
    } else {
        serializedComp.type = 'Component'
    }
}

function setComponentDefinitionProps(ps: PS, props, pageId: string, customId: string) {
    let propertyQuery = null
    if (props) {
        if (_.isObject(props)) {
            propertyQuery = dataModel.addSerializedPropertyItemToPage(ps, pageId, props, customId)
        } else if (_.isString(props)) {
            const defaultPropsItem = dataModel.createPropertiesItemByType(ps, props)
            propertyQuery = dataModel.addSerializedPropertyItemToPage(ps, pageId, defaultPropsItem, customId)
        }
    }
    return propertyQuery
}

const migrateStylesInBreakpointsToScopedStyles = serializedComp => {
    const scopedStyles = {}
    if (_.get(serializedComp, ['style', 'type']) === 'StylesInBreakpoint') {
        _.forEach(serializedComp.style.stylesInBreakpoints, styleItem => {
            if (styleItem.breakpoint) {
                const breakpointId = dsUtils.stripHashIfExists(styleItem.breakpoint)
                scopedStyles[breakpointId] = _.omit(styleItem, 'breakpoint')
            }
        })
        serializedComp[SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.theme]] = scopedStyles
        serializedComp[SERIALIZED_DATA_KEYS[DATA_TYPES.theme]] = _.find(
            serializedComp.style.stylesInBreakpoints,
            styleItem => !styleItem.breakpoint
        )
    }
}

function setComponentDefinitionStyle(ps: PS, pageId: string, mappers: Mappers, serializedComp) {
    migrateStylesInBreakpointsToScopedStyles(serializedComp)
    updateComponentScopedValuesDefinition(ps, pageId, mappers, serializedComp, DATA_TYPES.theme)
}

function addSingleStyleOrSystemStyleToPage(ps: PS, pageId: string, componentTypeForSystemStyle, styleOrSystemStyle) {
    let styleId = styleOrSystemStyle

    if (_.isObject(styleOrSystemStyle)) {
        let pageIdToAddStyle = pageId

        // @ts-expect-error
        if (styleOrSystemStyle.type !== 'StylesInBreakpoint') {
            // @ts-expect-error
            styleId = styleOrSystemStyle.id
            if (styleId && isSystemStyle(styleId)) {
                if (!ps.dal.isExist(ps.pointers.data.getThemeItem(styleId, 'masterPage'))) {
                    _.set(styleOrSystemStyle, 'type', constants.STYLES.TOP_LEVEL_STYLE)
                    _.set(styleOrSystemStyle, 'styleType', 'system')
                    _.set(styleOrSystemStyle, 'componentClassName', componentTypeForSystemStyle)
                    dataModel.addSerializedStyleItemToPage(ps, 'masterPage', styleOrSystemStyle, styleId)
                }
                return styleId
            }
            // @ts-expect-error
            const isComponentStyle = ps.runtimeConfig.stylesPerPage && styleOrSystemStyle.styleType !== 'system'
            pageIdToAddStyle = isComponentStyle ? pageId : 'masterPage'
            _.set(
                styleOrSystemStyle,
                'type',
                isComponentStyle ? constants.STYLES.COMPONENT_STYLE : constants.STYLES.TOP_LEVEL_STYLE
            )
            _.set(styleOrSystemStyle, 'componentClassName', componentTypeForSystemStyle)
        } else {
            bi.error(ps, biErrors.COMPONENT_WITH_STYLES_IN_BREAKPOINTS, {
                compType: componentTypeForSystemStyle,
                styleId,
                pageId
            })
        }
        return dataModel.addSerializedStyleItemToPage(ps, pageIdToAddStyle, styleOrSystemStyle, undefined)
    }
    const isSystemStyleId = isSystemStyle(styleId)
    const existingStyle = theme.styles.get(ps, styleId)
    if (!isSystemStyleId) {
        const isADI = _.get(ps.config, ['origin']) === 'onboarding'
        if (!isADI) {
            bi.error(ps, biErrors.ADD_COMPONENT_WITH_CUSTOM_STYLE_ID, {
                compType: componentTypeForSystemStyle,
                styleId,
                existingStyle
            })
        }
        log.warnDeprecation(
            'You tried to create a component with an unknown system style Id. It should be either called with a string for system style, or an object for custom style'
        )
        if (existingStyle) {
            styleId = theme.styles.internal.fork(ps, existingStyle, pageId, true)
        } else {
            const styleIds = componentStylesAndSkinsAPI.style.getThemeStyleIds(componentTypeForSystemStyle)
            if (styleIds.length) {
                styleId = styleIds[0]
            }
        }
    } else if (!existingStyle) {
        componentStylesAndSkinsAPI.style.createSystemStyle(ps, styleId, componentTypeForSystemStyle)
    }
    return styleId
}

/**
 * deserialize scoped styles, if its scoped by variants it will create all relations and ref arrays and connect them to the new variant
 * that was created on updateVariantsInStructure.
 * @param {ps} ps ps
 * @param {string} pageId
 * @param {string} componentTypeForSystemStyle
 * @param {object} serializedComp
 */
function setStructureDefinitionStyle(ps: PS, pageId: string, componentTypeForSystemStyle: string, serializedComp: any) {
    if (serializedComp.style) {
        serializedComp.styleId = addSingleStyleOrSystemStyleToPage(
            ps,
            pageId,
            componentTypeForSystemStyle,
            serializedComp.style
        )
    }
}

function setComponentChildrenDefinition(
    ps: PS,
    pageId: string,
    children,
    slots,
    containerRef,
    mappers: Mappers,
    oldModeIdsToNew,
    activeModes
) {
    if (slots) {
        updateComponentSlotsDefinition(ps, pageId, slots, containerRef, mappers)
    }

    if (children && containerRef) {
        _.forEach(children, function (componentDef) {
            const newId = mappers?.oldToNewIdMap?.[componentDef.id]
            const childCompRef = getComponentToAddRef(ps, containerRef, componentDef, newId)
            componentDef.activeModes = activeModes
            setComponent(ps, childCompRef, containerRef, componentDef, {
                customId: newId,
                isPage: false,
                mappers,
                modeIdsInHierarchy: oldModeIdsToNew,
                isAncestorsChecked: true
            })
        })
    }
}

function updateComponentDataDefinition(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    isPage: boolean,
    mappers: Mappers,
    compDefinition
) {
    if (isPage) {
        compDefinition.dataQuery = `#${pageId}`
    } else {
        const {oldToNewIdMap} = mappers ?? {}
        optionalCustomId = getCustomId(oldToNewIdMap, compDefinition.data, optionalCustomId)
        const dataQuery = addRepeatedSupportedNameSpaceDefinition(
            ps,
            compDefinition.data,
            pageId,
            optionalCustomId,
            DATA_TYPES.data
        )
        if (dataQuery) {
            compDefinition.dataQuery = `#${dataQuery}`
            addIdToMap(compDefinition.data, dataQuery, oldToNewIdMap)
        }
    }
}

function shouldUseDesignOverVariants(ps: PS, serializedCompOrModeOverride) {
    const scopedDesigns = _.get(serializedCompOrModeOverride, SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.design])

    if (experiment.isOpen('dm_mergeDesignOverVariants')) {
        return (
            isCompTypeIsValidForDesignInVariants(serializedCompOrModeOverride.componentType) &&
            !_.isEmpty(scopedDesigns)
        )
    }

    return experiment.isOpen('dm_designOverVariants') || !_.isEmpty(scopedDesigns)
}

function updateComponentDesignDefinition(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    serializedCompOrModeOverride
) {
    if (shouldUseDesignOverVariants(ps, serializedCompOrModeOverride)) {
        updateComponentScopedValuesDefinition(ps, pageId, mappers, serializedCompOrModeOverride, DATA_TYPES.design)
    } else {
        const {oldToNewIdMap} = mappers ?? {}
        optionalCustomId = getCustomId(oldToNewIdMap, serializedCompOrModeOverride.design, optionalCustomId)
        const designQuery = addRepeatedSupportedNameSpaceDefinition(
            ps,
            serializedCompOrModeOverride.design,
            pageId,
            optionalCustomId,
            DATA_TYPES.design
        )
        if (designQuery) {
            serializedCompOrModeOverride.designQuery = `#${designQuery}`
            addIdToMap(serializedCompOrModeOverride.design, designQuery, oldToNewIdMap)
        }
    }
}

function updateComponentAnchorDefinition(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    serializedCompOrModeOverride
) {
    const {oldToNewIdMap} = mappers ?? {}
    optionalCustomId = getCustomId(oldToNewIdMap, serializedCompOrModeOverride.anchor, optionalCustomId)
    const anchorQuery = addComponentDefinitionAnchor(ps, serializedCompOrModeOverride.anchor, pageId, optionalCustomId)

    if (anchorQuery) {
        serializedCompOrModeOverride.anchorQuery = `${anchorQuery}`
        addIdToMap(serializedCompOrModeOverride.anchor, anchorQuery, oldToNewIdMap)
    }
}

const addSingleItem = (ps: PS, pageId: string, serializedComp, itemType: string, item, oldToNewIdMap) =>
    itemType === DATA_TYPES.theme
        ? addSingleStyleOrSystemStyleToPage(ps, pageId, serializedComp.componentType, item)
        : addComponentDefinitionDataByType(ps, item, itemType, pageId, undefined, oldToNewIdMap)

const updateVariantRelation = (
    ps: PS,
    pageId: string,
    mappers: Mappers,
    serializedComp,
    itemType: string,
    scopedValue,
    variants
) => {
    const {oldToNewIdMap} = mappers ?? {}
    const updatedVariants = variants.split(',').map(oldVariant => oldToNewIdMap[oldVariant])
    const newVariants = updatedVariants.filter(Boolean)

    if (newVariants.length !== updatedVariants.length) {
        return
    }

    const scopedValueId = addSingleItem(ps, pageId, serializedComp, itemType, scopedValue, oldToNewIdMap)
    const variantRelation = dataModel.variantRelation.create(ps, newVariants, serializedComp.id, scopedValueId)

    return dataModel.addDeserializedItemToPage(ps, pageId, itemType, variantRelation)
}

const updateScopedValues = (
    ps: PS,
    pageId: string,
    mappers: Mappers,
    serializedComp,
    itemType: string,
    refValues,
    path = SERIALIZED_SCOPED_DATA_KEYS[itemType]
) => {
    const scopedValues = _.get(serializedComp, path)

    if (scopedValues) {
        const relationsIds = _(scopedValues)
            .map((scopedValue, key) =>
                updateVariantRelation(ps, pageId, mappers, serializedComp, itemType, scopedValue, key)
            )
            .compact()
            .value()
        refValues.splice(refValues.length, 0, ...relationsIds)
    }
}

const updateNonScopedValue = (
    ps: PS,
    pageId: string,
    mappers: Mappers,
    serializedComp,
    itemType: string,
    refValues,
    path = SERIALIZED_DATA_KEYS[itemType]
) => {
    const nonScopedValue = _.get(serializedComp, path)
    const {oldToNewIdMap} = mappers ?? {}
    if (nonScopedValue) {
        const nonScopedId = addSingleItem(ps, pageId, serializedComp, itemType, nonScopedValue, oldToNewIdMap)
        refValues.unshift(nonScopedId)
    }
}

const getIdentifierForVariantsQuery = (ps: PS, serializedCompOrModeOverride, itemType: string) => {
    if (serializedCompOrModeOverride.id) {
        return `${serializedCompOrModeOverride.id},${itemType}`
    } else if (serializedCompOrModeOverride.overrideOwner) {
        return `${serializedCompOrModeOverride.overrideOwner},${itemType},override`
    }
    const message = `undefined,${itemType}`
    const err = new ReportableError({
        message,
        errorType: 'VariantsQueryIdentifier',
        extras: serializedCompOrModeOverride
    })
    ps.extensionAPI.logger.captureError(err)
    return message
}

const createAndAddRefArray = (ps: PS, refValues, pageId: string, itemType: string) => {
    const refArray = dataModel.refArray.create(ps, refValues)
    return dataModel.addDeserializedItemToPage(ps, pageId, itemType, refArray)
}

const setCompQueryValue = (serializedComp, id: string, itemType: string) => {
    const compDataQueryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]
    serializedComp[compDataQueryKey] = DATA_TYPES_VALUES_WITH_HASH[itemType] ? `#${id}` : id
}

const linkRefArrayOrNonScopedValue = (
    ps: PS,
    pageId: string,
    mappers: Mappers,
    serializedCompOrModeOverride,
    itemType: string,
    refValues
) => {
    const {oldToNewIdMap} = mappers ?? {}
    const isThereRefArrayOrStyleObjectToLink = !_.isEmpty(refValues)
    if (isThereRefArrayOrStyleObjectToLink) {
        const scopedSerializeKey = SERIALIZED_SCOPED_DATA_KEYS[itemType]
        const shouldLinkNonScopedValue =
            !serializedCompOrModeOverride[scopedSerializeKey] &&
            [DATA_TYPES.theme, DATA_TYPES.design].includes(itemType)

        const dataQueryId = shouldLinkNonScopedValue
            ? refValues[0]
            : createAndAddRefArray(ps, refValues, pageId, itemType)

        addIdToMap(
            {id: getIdentifierForVariantsQuery(ps, serializedCompOrModeOverride, itemType)},
            dataQueryId,
            oldToNewIdMap
        )
        setCompQueryValue(serializedCompOrModeOverride, dataQueryId, itemType)
    }
}

const fixRefArrayValues = (
    serializedCompOrModeOverride,
    itemType: string,
    mappers: Mappers = {oldToNewIdMap: {}},
    nonScopedPath = SERIALIZED_DATA_KEYS[itemType],
    scopedPath = SERIALIZED_SCOPED_DATA_KEYS[itemType]
) => {
    const {variantMapper} = mappers

    if (!Array.isArray(variantMapper) || variantMapper.length === 0) {
        return
    }

    const refArrayValues = {
        ..._.get(serializedCompOrModeOverride, scopedPath),
        '': _.get(serializedCompOrModeOverride, nonScopedPath)
    }

    const {defaultValue, scopedValues} = replaceVariantsOnSerialized(refArrayValues, variantMapper)

    if (Object.keys(scopedValues).length > 0) {
        _.set(serializedCompOrModeOverride, scopedPath, scopedValues)
    } else {
        _.unset(serializedCompOrModeOverride, scopedPath)
    }

    if (defaultValue) {
        _.set(serializedCompOrModeOverride, nonScopedPath, defaultValue)
    }
}

/**
 * deserialize scoped values like (transition, transformations..) if its non scoped value its will deserialize it under non scoped key
 * f.i transitions: {...}
 * if the comp have scoped value f.i scopedTransitions each key is combination of variant id's
 * it will deserialize the value under the key only if all of the variants in the key are mentioned on oldToNewIdMap map
 *
 * @param {ps} ps ps
 * @param pageId
 * @param mappers
 * @param serializedCompOrModeOverride
 * @param itemType
 */
function updateComponentScopedValuesDefinition(
    ps: PS,
    pageId: string,
    mappers: Mappers,
    serializedCompOrModeOverride,
    itemType: string
) {
    const refValues = []
    const {oldToNewIdMap} = mappers ?? {}
    const dataQueryId = getCustomId(oldToNewIdMap, {
        id: getIdentifierForVariantsQuery(ps, serializedCompOrModeOverride, itemType)
    })

    if (dataQueryId) {
        setCompQueryValue(serializedCompOrModeOverride, dataQueryId, itemType)
        return
    }

    fixRefArrayValues(serializedCompOrModeOverride, itemType, mappers)
    updateNonScopedValue(ps, pageId, mappers, serializedCompOrModeOverride, itemType, refValues)
    updateScopedValues(ps, pageId, mappers, serializedCompOrModeOverride, itemType, refValues)
    linkRefArrayOrNonScopedValue(ps, pageId, mappers, serializedCompOrModeOverride, itemType, refValues)
}

function updateComponentNonRootScopedValuesDefinition(
    ps: PS,
    pageId: string,
    serializedComp,
    itemType: string,
    mappers: Mappers
) {
    const item = serializedComp[itemType]
    if (!_.isObject(item)) return

    updateComponentNonRootScopedValuesDefinitionRecursively(ps, item, itemType, pageId, mappers)
}

function updateComponentNonRootScopedValuesDefinitionRecursively(
    ps: PS,
    item,
    itemType: string,
    pageId: string,
    mappers: Mappers
) {
    const referenceFieldsInfo =
        item.type && ps.extensionAPI.schemaAPI.extractReferenceFieldsInfoForSchema(itemType, item.type)
    if (!referenceFieldsInfo?.length) return
    for (const info of referenceFieldsInfo) {
        updateFieldNonRootScopedValuesDefinition(ps, item, info, itemType, pageId, mappers)
    }
}

function updateFieldNonRootScopedValuesDefinition(
    ps: PS,
    item,
    info,
    itemType: string,
    pageId: string,
    mappers: Mappers
) {
    const {isRelationalSplit, path} = info
    const field = _.get(item, path)
    if (!_.isObject(field) && !_.isArray(field)) return

    if (isRelationalSplit) {
        const refValues = []

        fixRefArrayValues(item, itemType, mappers, [...path, 'default'], [...path, 'scoped'])
        updateNonScopedValue(ps, pageId, mappers, item, itemType, refValues, [...path, 'default'])
        updateScopedValues(ps, pageId, mappers, item, itemType, refValues, [...path, 'scoped'])

        const refArrayId = createAndAddRefArray(ps, refValues, pageId, itemType)
        _.set(item, path, `#${refArrayId}`)
    } else {
        _.forEach(field, childField =>
            updateComponentNonRootScopedValuesDefinitionRecursively(ps, childField, itemType, pageId, mappers)
        )
    }
}

function updateComponentTransformationsDefinition(
    ps: PS,
    pageId: string,
    mappers: Mappers,
    serializedCompOrModeOverride
) {
    updateComponentScopedValuesDefinition(ps, pageId, mappers, serializedCompOrModeOverride, DATA_TYPES.transformations)
}

function updateComponentReactionsDefinition(ps: PS, pageId: string, mappers: Mappers, serializedComponent) {
    const key = SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.reactions]
    const {oldToNewIdMap} = mappers ?? {}

    _.forEach(serializedComponent[key], reaction => {
        reaction.values.map(reactionData => {
            const oldId = dsUtils.stripHashIfExists(reactionData.state)
            if (reactionData.state) {
                reactionData.state = `#${getCustomId(oldToNewIdMap, {id: oldId})}`
            }
            return reactionData
        })
    })

    updateComponentScopedValuesDefinition(ps, pageId, mappers, serializedComponent, DATA_TYPES.reactions)
}

function updateComponentSlotsDefinition(ps: PS, pageId: string, slots, containerRef, mappers: Mappers) {
    const {type, slots: slotComponents} = slots
    const addedSlotComponents = _.mapValues(slotComponents, childSlotDefinition => {
        const compPointer = getComponentToAddRef(ps, containerRef, childSlotDefinition)
        setComponent(ps, compPointer, containerRef, childSlotDefinition, {mappers})
        return compPointer.id
    })

    const slotsData: any = {slots: {...addedSlotComponents}, type}
    const slotsDataId = dataModel.addDeserializedItemToPage(ps, pageId, DATA_TYPES.slots, slotsData)
    slotsData.id = slotsDataId
    addIdToMap(slotsData, slotsDataId, mappers.oldToNewIdMap)

    ps.dal.set(ps.pointers.getInnerPointer(containerRef, ['slotsQuery']), slotsDataId)
}

function updateVariantsArrayDefinitionByType(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    serializedComponent,
    namespace
) {
    const key = SERIALIZED_DATA_KEYS[namespace]
    const {oldToNewIdMap} = mappers ?? {}
    const {query} = getNamespaceConfig(namespace)

    if (!serializedComponent[key]) {
        return
    }

    optionalCustomId = getCustomId(oldToNewIdMap, serializedComponent[key], optionalCustomId)
    const values = _(serializedComponent[key].values)
        .map(id => {
            const oldId = dsUtils.stripHashIfExists(id)
            return oldToNewIdMap[oldId] && `#${oldToNewIdMap[oldId]}`
        })
        .compact()
        .value()

    if (!values.length) {
        return
    }

    const toUpdate = {...serializedComponent[key], values}
    const dataItemId = addComponentDefinitionDataByType(ps, toUpdate, namespace, pageId, optionalCustomId)

    if (dataItemId) {
        serializedComponent[query] = dataItemId
        addIdToMap(serializedComponent[key], dataItemId, oldToNewIdMap)
    }
}

function updateComponentTransitionsDefinition(ps: PS, pageId: string, mappers: Mappers, serializedCompOrModeOverride) {
    updateComponentScopedValuesDefinition(ps, pageId, mappers, serializedCompOrModeOverride, DATA_TYPES.transitions)
}

function updateComponentFeaturesDefinitions(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    serializedCompOrModeOverride
) {
    const {oldToNewIdMap} = mappers ?? {}

    _.forEach(featureNamespaces, featureNamespace => {
        const {query, supportsVariants} = getNamespaceConfig(featureNamespace)
        if (supportsVariants) {
            updateComponentScopedValuesDefinition(
                ps,
                pageId,
                mappers,
                serializedCompOrModeOverride,
                DATA_TYPES[featureNamespace]
            )
        } else {
            optionalCustomId = getCustomId(
                oldToNewIdMap,
                serializedCompOrModeOverride[featureNamespace],
                optionalCustomId
            )
            const feature = addComponentDefinitionDataByType(
                ps,
                serializedCompOrModeOverride[featureNamespace],
                featureNamespace,
                pageId,
                optionalCustomId
            )
            if (feature && query) {
                serializedCompOrModeOverride[query] = feature
                addIdToMap(serializedCompOrModeOverride[featureNamespace], feature, oldToNewIdMap)
            }
        }
    })
}

function migrateLayoutResponsiveToScopedLayouts(ps: PS, serializedCompOrModeOverride) {
    const layouts = dataModel.createLayoutObject()
    const scopedLayouts = {}
    if (serializedCompOrModeOverride.layoutResponsive) {
        _.forEach(constants.LAYOUT_TYPES.SINGLE_LAYOUT_KEYS, key => {
            _.forEach(serializedCompOrModeOverride.layoutResponsive[`${key}s`], val => {
                if (val.breakpoint) {
                    const breakpointId = dsUtils.stripHashIfExists(val.breakpoint)
                    if (scopedLayouts[breakpointId]) {
                        scopedLayouts[breakpointId][key] = _.isEmpty(scopedLayouts[breakpointId][key])
                            ? _.omit(val, ['breakpoint'])
                            : scopedLayouts[breakpointId][key]
                    } else {
                        scopedLayouts[breakpointId] = dataModel.createLayoutObject({[key]: _.omit(val, ['breakpoint'])})
                    }
                } else {
                    layouts[key] = _.isEmpty(layouts[key]) ? _.omit(val, ['breakpoint']) : layouts[key]
                }
            })
        })

        serializedCompOrModeOverride[SERIALIZED_SCOPED_DATA_KEYS[DATA_TYPES.layout]] = scopedLayouts
        serializedCompOrModeOverride[SERIALIZED_DATA_KEYS[DATA_TYPES.layout]] = layouts
    }
}

function updateComponentLayoutDefinition(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    serializedCompOrModeOverride
) {
    migrateLayoutResponsiveToScopedLayouts(ps, serializedCompOrModeOverride)
    updateComponentScopedValuesDefinition(ps, pageId, mappers, serializedCompOrModeOverride, DATA_TYPES.layout)
}

function updateComponentVariablesDefinition(
    ps: PS,
    serializedComp,
    containerPointer: Pointer,
    pageId: string,
    mappers: Mappers,
    isAncestorsChecked
) {
    const {oldToNewIdMap} = mappers ?? {}
    if (!isAncestorsChecked) {
        addAncestorsVariablesToMap(ps, containerPointer, oldToNewIdMap)
    }
    const itemType = DATA_TYPES.variables
    const variablesList = serializedComp[itemType]
    if (!_.isObject(variablesList)) return

    updateComponentNonRootScopedValuesDefinition(ps, pageId, serializedComp, itemType, mappers)

    const variableValues = []
    // @ts-expect-error
    for (const variable of variablesList.variables) {
        const variableNewId = addComponentDefinitionDataByType(ps, variable, itemType, pageId)
        addVariableIdToMap(variable, variableNewId, oldToNewIdMap)
        variableValues.push(`#${variableNewId}`)
    }

    // @ts-expect-error
    variablesList.variables = variableValues
    const id = dataModel.addDeserializedItemToPage(ps, pageId, itemType, variablesList)
    setCompQueryValue(serializedComp, id, itemType)
}

function updateComponentBreakpointDefinition(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    serializedCompOrModeOverride
) {
    if (serializedCompOrModeOverride?.breakpoints) {
        const {oldToNewIdMap} = mappers ?? {}
        optionalCustomId = getCustomId(oldToNewIdMap, serializedCompOrModeOverride.breakpoints, optionalCustomId)
        const values = _.get(serializedCompOrModeOverride.breakpoints, 'values')
        _.forEach(values, val => {
            const newId = dataModel.generateNewId(DATA_TYPES.variants)
            addIdToMap({id: val.id}, newId, oldToNewIdMap)
            val.id = newId
        })

        const componentId = serializedCompOrModeOverride.id
        const breakpointsDataToAdd = _.merge(serializedCompOrModeOverride.breakpoints, {componentId})

        const newVariantId = addVariantItemToPage(ps, breakpointsDataToAdd, pageId, optionalCustomId)
        if (newVariantId) {
            serializedCompOrModeOverride.breakpointVariantsQuery = newVariantId
            addIdToMap(serializedCompOrModeOverride.breakpointVariantsQuery, newVariantId, oldToNewIdMap)
        }
    }
}

/**
 * collect all variants of component and his ancestor and save it to map
 * it will help us later on to link scoped values to the variant that triggers the scoped value
 * when deserializing scoped values like (transform, transition, ...)
 *
 * @param {ps} ps ps
 * @param {Pointer} componentPointer
 * @param oldToNewIdMap - ids map
 * @param {boolean} isAncestorsChecked
 */
function collectAncestorsVariants(ps: PS, componentPointer: Pointer, oldToNewIdMap, isAncestorsChecked: boolean) {
    if (!isAncestorsChecked) {
        const variantsIds = variantsUtils.collectAncestorsVariants(ps, componentPointer)
        _.forEach(variantsIds, id => addIdToMap({id}, id, oldToNewIdMap))
    }
}

const collectMasterPageVariants = (ps: PS, componentPointer: Pointer, oldToNewIdMap) => {
    const variantsIds = variantsUtils.getMasterPageVariants(ps, componentPointer)
    _.forEach(variantsIds, id => addIdToMap({id}, id, oldToNewIdMap))
}

/**
 * collect all variables of component's ancestor and save it to map
 *
 * @param {ps} ps ps
 * @param {Pointer} componentPointer
 * @param oldToNewIdMap - ids map
 */
function addAncestorsVariablesToMap(ps: PS, componentPointer: Pointer, oldToNewIdMap) {
    const variablesIds = variableUtils.collectAncestorsVariablesIds(ps, componentPointer)
    _.forEach(variablesIds, id => addVariableIdToMap({id}, id, oldToNewIdMap))
}

/**
 * deserialize variants of component to the page, and replacing the ref from old comp id to new comp id
 *
 * @param {ps} ps ps
 * @param {object} clonedSerializedComp
 * @param {Pointer} containerPointer
 * @param {string} pageId
 * @param mappers
 * @param {boolean} isAncestorsChecked
 */
function updateVariantsInStructure(
    ps: PS,
    clonedSerializedComp: any,
    containerPointer: Pointer,
    pageId: string,
    mappers: Mappers,
    isAncestorsChecked: boolean
) {
    const {oldToNewIdMap} = mappers ?? {}
    collectAncestorsVariants(ps, containerPointer, oldToNewIdMap, isAncestorsChecked)
    collectMasterPageVariants(ps, containerPointer, oldToNewIdMap)

    _.forEach(clonedSerializedComp.variants, (variant, oldId) => {
        const variantCustomId = getCustomId(oldToNewIdMap, {id: oldId}, dataModel.generateNewId(DATA_TYPES.variants))
        const variantWithNewCompId = _.defaults({componentId: clonedSerializedComp.id}, variant)

        const {values} = variant
        _.forEach(values, val => {
            if (val.id) {
                const newId = dataModel.generateNewId(DATA_TYPES.variants)
                addIdToMap({id: val.id}, newId, oldToNewIdMap)
                val.id = newId
            }

            if (variant.type === VARIANTS.TYPES.REPEATER_PATTERN_LIST) {
                val.componentId = clonedSerializedComp.id
            }
        })

        const newVariantId = addVariantItemToPage(ps, variantWithNewCompId, pageId, variantCustomId)
        if (newVariantId) {
            addIdToMap({id: oldId}, newVariantId, oldToNewIdMap)
            if (variantWithNewCompId.type === VARIANTS.TYPES.BREAKPOINTS) {
                clonedSerializedComp.breakpointVariantsQuery = newVariantId
            }
            if (variantWithNewCompId.type === VARIANTS.TYPES.REPEATER_PATTERN_LIST) {
                clonedSerializedComp.variantsQuery = newVariantId
            }
        }
    })
}

function updateComponentBehaviorsDefinition(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    modeIdsInHierarchy,
    compDefinition
) {
    if (!compDefinition.behaviors) {
        return
    }

    if (_.isString(compDefinition.behaviors)) {
        //supporting old add panel data
        compDefinition.behaviors = dataModel.createBehaviorsItem(compDefinition.behaviors)
    }

    const {oldToNewIdMap} = mappers ?? {}
    compDefinition.behaviors = fixBehaviorsIds(compDefinition.behaviors, modeIdsInHierarchy, oldToNewIdMap)
    optionalCustomId = getCustomId(oldToNewIdMap, compDefinition.behaviors, optionalCustomId)
    const behaviorQuery = addComponentDefinitionBehaviors(ps, compDefinition.behaviors, pageId, optionalCustomId)

    if (behaviorQuery) {
        compDefinition.behaviorQuery = behaviorQuery
        addIdToMap(compDefinition.behaviors, behaviorQuery, oldToNewIdMap)
    }
}

function updateComponentMobileHintsDefinition(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    compDefinition
) {
    const {oldToNewIdMap} = mappers ?? {}
    optionalCustomId = getCustomId(oldToNewIdMap, compDefinition.mobileHints, optionalCustomId)
    const mobileHintsQuery = addComponentMobileHintsDefinition(ps, compDefinition.mobileHints, pageId, optionalCustomId)

    if (!mobileHintsQuery) {
        return
    }

    compDefinition.mobileHintsQuery = mobileHintsQuery
    addIdToMap(compDefinition.mobileHints, mobileHintsQuery, oldToNewIdMap)
}

function fixBehaviorItemModeIds(behaviorItem, modeIdsInHierarchy) {
    const behaviorItemModeIds = _.get(behaviorItem, ['params', 'modeIds'])
    if (behaviorItemModeIds) {
        _.set(
            behaviorItem,
            ['params', 'modeIds'],
            _.map(behaviorItemModeIds, modeId => modeIdsInHierarchy.oldModeIdsToNew[modeId] || modeId)
        )
    }
}

function fixBehaviorItemCompIdsAndTargetIds(behaviorItem, oldToNewIdMap) {
    const sourceIdPath = ['action', 'sourceId']
    if (_.get(behaviorItem, ['action', 'type']) === 'comp') {
        const oldSourceId = _.get(behaviorItem, sourceIdPath)
        _.set(behaviorItem, sourceIdPath, _.get(oldToNewIdMap, oldSourceId, oldSourceId))
    }
    const runCodeCompIdPath = ['behavior', 'params', 'compId']
    const runCodeTargetIdPath = ['behavior', 'targetId']
    if (_.get(behaviorItem, ['behavior', 'name']) === 'runCode' && _.has(behaviorItem, runCodeCompIdPath)) {
        const oldRunCodeCompId = _.get(behaviorItem, runCodeCompIdPath)
        const oldRunCodeTargetId = _.get(behaviorItem, runCodeTargetIdPath)
        _.set(behaviorItem, runCodeCompIdPath, _.get(oldToNewIdMap, oldRunCodeCompId, oldRunCodeCompId))
        _.set(behaviorItem, runCodeTargetIdPath, _.get(oldToNewIdMap, oldRunCodeTargetId, oldRunCodeTargetId))
    }
}

function fixBehaviorsIds(behaviors, modeIdsInHierarchy, oldToNewIdMap) {
    if (behaviors?.items) {
        let behaviorsItems = behaviors.items
        if (_.isString(behaviorsItems)) {
            behaviorsItems = JSON.parse(behaviorsItems)
        }
        const fixedBehaviorItems = _.map(behaviorsItems, function (behaviorItem) {
            fixBehaviorItemModeIds(behaviorItem, modeIdsInHierarchy)
            fixBehaviorItemCompIdsAndTargetIds(behaviorItem, oldToNewIdMap)
            return behaviorItem
        })
        const behaviorResult = _.omit(behaviors, 'items')
        behaviorResult.items = JSON.stringify(fixedBehaviorItems)
        return behaviorResult
    }
    return behaviors
}

function updateComponentConnectionsDefinition(
    compDefinition,
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers
) {
    const {oldToNewIdMap} = mappers ?? {}
    optionalCustomId = getCustomId(oldToNewIdMap, compDefinition.connections, optionalCustomId)
    const connectionQuery = addComponentDefinitionConnections(ps, compDefinition.connections, pageId, optionalCustomId)

    if (connectionQuery) {
        compDefinition.connectionQuery = connectionQuery
        addIdToMap(compDefinition.connections, connectionQuery, oldToNewIdMap)
    }
}

function updateComponentPropsDefinition(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    serializedCompOrModeOverride
) {
    const {oldToNewIdMap} = mappers ?? {}
    optionalCustomId = getCustomId(oldToNewIdMap, serializedCompOrModeOverride.props, optionalCustomId)
    const propertyQuery = setComponentDefinitionProps(ps, serializedCompOrModeOverride.props, pageId, optionalCustomId)

    if (propertyQuery) {
        serializedCompOrModeOverride.propertyQuery = propertyQuery
        addIdToMap(serializedCompOrModeOverride.props, propertyQuery, oldToNewIdMap)
    }
}

function updateComponentPatternsDefinition(
    ps: PS,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers,
    serializedComponent
) {
    const {oldToNewIdMap} = mappers ?? {}
    optionalCustomId = getCustomId(oldToNewIdMap, serializedComponent.patterns, optionalCustomId)

    if (serializedComponent.patterns?.items) {
        serializedComponent.patterns.items.forEach(nthChild => {
            const oldRepeaterPatternVariant = dsUtils.stripHashIfExists(nthChild.variant)

            if (nthChild.variant) {
                nthChild.variant = `#${getCustomId(oldToNewIdMap, {id: oldRepeaterPatternVariant})}`
            }
        })

        const patternsId = dataModel.addSerializedPatternsItemToPage(
            ps,
            pageId,
            serializedComponent.patterns,
            optionalCustomId
        )
        setCompQueryValue(serializedComponent, patternsId, DATA_TYPES.patterns)
        addIdToMap(serializedComponent.patterns, patternsId, oldToNewIdMap)
    }
}

function createNewModeIds(compDefinition) {
    const modeDefinitions = _.get(compDefinition, ['modes', 'definitions'])
    if (modeDefinitions) {
        const compId = compDefinition.id
        return _.reduce(
            modeDefinitions,
            function (result, modeDef) {
                result[modeDef.modeId] = componentModes.createUniqueModeId(compId)
                return result
            },
            {}
        )
    }
    return {}
}

function updateCompDefinitionModes(serializedComp, oldModeIdsToNew) {
    if (serializedComp.modes?.definitions) {
        serializedComp.modes.definitions = _.map(serializedComp.modes.definitions, function (modeDef) {
            return _.defaults(
                {
                    modeId: oldModeIdsToNew[modeDef.modeId]
                },
                modeDef
            )
        })
    }
}

function updateCompOverrideModes(serializedComp, oldModeIdsToNew) {
    if (_.get(serializedComp, ['modes', 'overrides'])) {
        serializedComp.modes.overrides = _.map(serializedComp.modes.overrides, function (modeOverride) {
            const newModeIds = _.map(modeOverride.modeIds, oldModeId => oldModeIdsToNew[oldModeId] || oldModeId)
            return _.defaults(
                {
                    modeIds: newModeIds
                },
                modeOverride
            )
        })
    }
}

function updateCompPropertiesModes(serializedComp, oldModeIdsToNew) {
    if (_.get(serializedComp, ['props', 'mobileDisplayedModeId'])) {
        serializedComp.props.mobileDisplayedModeId = oldModeIdsToNew[serializedComp.props.mobileDisplayedModeId]
    }
}

function deserializeComponentOverridesData(
    ps: PS,
    serializedComponent,
    pageId: string,
    optionalCustomId: string,
    mappers: Mappers
) {
    let overrides = _.get(serializedComponent, ['modes', 'overrides'])
    if (!_.isEmpty(overrides)) {
        _.forEach(overrides, override => {
            override.overrideOwner = serializedComponent.id
            setStructureDefinitionStyle(ps, pageId, serializedComponent.componentType, override)
            updateComponentDesignDefinition(ps, pageId, optionalCustomId, mappers, override)
            updateComponentPropsDefinition(ps, pageId, optionalCustomId, mappers, override)
        })
        overrides = _.map(overrides, omitPropertiesFromSerializedComp)
        _.set(serializedComponent, ['modes', 'overrides'], overrides)
    }
}

function getCustomId(oldToNewIdMap, dataObject, customId?: string) {
    let newId = customId
    if (!oldToNewIdMap || !dataObject) {
        return newId
    }
    const serializedDataId = stripHashIfExists(dataObject.id) ?? ''
    if (oldToNewIdMap[serializedDataId]) {
        newId = oldToNewIdMap[serializedDataId]
    } else if (_.startsWith(serializedDataId, MOBILE_COMP_DATA_PREFIX)) {
        const idSuffix = serializedDataId.substring(MOBILE_COMP_DATA_PREFIX.length)
        const mobilePropsMappedId = oldToNewIdMap[idSuffix]
        if (mobilePropsMappedId) {
            newId = MOBILE_COMP_DATA_PREFIX + mobilePropsMappedId
        }
    }
    return newId
}

function addIdToMap(dataObject, newId: string, oldToNewIdMap) {
    if (!oldToNewIdMap) {
        return
    }
    const serializedDataId = stripHashIfExists(dataObject.id) ?? ''
    newId = stripHashIfExists(newId)
    if (dataObject.id && !oldToNewIdMap[serializedDataId]) {
        oldToNewIdMap[serializedDataId] = newId
    }
}

function addVariableIdToMap(dataObject, newId: string, oldToNewIdMap) {
    if (!oldToNewIdMap) {
        return
    }
    const serializedDataId = stripHashIfExists(dataObject.id) ?? ''
    newId = stripHashIfExists(newId)
    if (dataObject.id) {
        oldToNewIdMap[serializedDataId] = newId
    }
}

function validateRemoval(ps: PS, componentPointer: Pointer) {
    validateRemovalInternal(
        ps,
        componentPointer,
        ps.setOperationsQueue.asyncPreDataManipulationComplete.bind(ps.setOperationsQueue)
    )
}

function validateRemovalInternal(ps: PS, componentPointer: Pointer, onComplete?, tpaCompRefs?) {
    onComplete = onComplete || _.noop

    if (
        ps.pointers.components.isMobile(componentPointer) &&
        constants.MOBILE_ONLY_COMPONENTS.hasOwnProperty(componentPointer.id)
    ) {
        throw new Error(componentValidations.ERRORS.CANNOT_DELETE_MOBILE_COMPONENT)
    }

    notifyComponentReadyForDeletion(ps, componentPointer, onComplete, tpaCompRefs)
}

function getAllTpaComps(ps: PS, componentPointer: Pointer) {
    const tpaChildRefs = componentStructureInfo.getTpaChildren(ps, componentPointer)

    if (tpaUtils.isTpaByCompType(componentStructureInfo.getType(ps, componentPointer))) {
        tpaChildRefs.push(componentPointer)
    }
    return tpaChildRefs
}

function executeAllTpaDeleteHandlers(ps: PS, componentPointer: Pointer) {
    const tpaChildRefs = getAllTpaComps(ps, componentPointer)

    _.forEach(tpaChildRefs, function (childRef) {
        if (tpaEventHandlersService.isDeleteHandlerExists(childRef.id)) {
            tpaEventHandlersService.executeDeleteHandler(childRef.id)
        }
    })
}

function shouldDelayDeletionTpa(ps: PS, componentPointer: Pointer, tpaCompRefs?) {
    if (!componentStructureInfo.isExist(ps, componentPointer)) {
        return false // we do that
    }
    const tpaChildRefs = tpaCompRefs || getAllTpaComps(ps, componentPointer)

    return _.some(tpaChildRefs, childRef => tpaEventHandlersService.isDeleteHandlerExists(childRef.id))
}

function notifyComponentReadyForDeletion(ps: PS, componentPointer: Pointer, onComplete, tpaCompRefs) {
    onComplete = onComplete || _.noop
    const delayDelete = shouldDelayDeletionTpa(ps, componentPointer, tpaCompRefs)

    if (delayDelete) {
        executeAllTpaDeleteHandlers(ps, componentPointer)

        _.delay(function () {
            tpaEventHandlersService.unRegisterHandlers(ps, componentPointer.id)
            onComplete()
        }, 500)
    } else {
        onComplete()
    }
}

function removeStyleItem(ps: PS, componentPointer: Pointer) {
    const desktopStyle = componentStylesAndSkinsAPI.style.internal.get(ps, componentPointer)
    if (_.get(desktopStyle, 'type') === constants.STYLES.COMPONENT_STYLE) {
        const pagePointer =
            ps.pointers.components.getPageOfComponent(componentPointer) ||
            ps.pointers.full.components.getPageOfComponent(componentPointer)
        const pageId = pagePointer.id
        theme.styles.remove(ps, desktopStyle.id, pageId)
    }
    if (dataModel.refArray.isRefArray(ps, desktopStyle)) {
        variantsUtils.removeComponentDataConsideringVariants(ps, componentPointer, DATA_TYPES.theme)
    }
}

function getDataDeletionParameters(ps: PS, isDesktop, pointer: Pointer) {
    const desktopPointer = isDesktop ? pointer : ps.pointers.components.getDesktopPointer(pointer)

    const isMobileOnly = componentsMetaData.public.isMobileOnly(ps, pointer)
    const component = ps.dal.get(pointer)
    if (component && component.type !== 'Page') {
        return {
            shouldDelete: isDesktop || isMobileOnly,
            dataComponentPointer: !isMobileOnly ? desktopPointer : pointer,
            desktopPointer
        }
    }

    return {
        shouldDelete: isDesktop,
        dataComponentPointer: desktopPointer,
        desktopPointer
    }
}

function deleteAllDataFromComponent(ps: PS, pointer: Pointer) {
    // Data is never forked
    dataModel.deleteDataItem(ps, pointer)
    dataModel.removeBehaviorsItem(ps, pointer)
    dataModel.deleteMobileHintsItem(ps, pointer)
    dataModel.deleteLayoutItem(ps, pointer)
    dataModel.deleteDesignItem(ps, pointer)
    dataModel.deletePropertiesItem(ps, pointer)
    dataModel.deleteTransitionsItem(ps, pointer)
    dataModel.deleteTransformationsItem(ps, pointer)
    dataModel.deleteStatesItem(ps, pointer)
    dataModel.deleteTriggersItem(ps, pointer)
    dataModel.deleteReactionsItem(ps, pointer)
    dataModel.deleteSlotsItem(ps, pointer)
    _.forEach(featureNamespaces, featureNamespace => dataModel.deleteFeatureItem(ps, pointer, featureNamespace))
    dataModel.removeConnectionsItem(ps, pointer)
    removeStyleItem(ps, pointer)
    dataModel.deleteVariantsItems(ps, pointer)
    dataModel.removeAnchorItem(ps, pointer)
    dataModel.removeVariablesListItem(ps, pointer)
    dataModel.removeEffectListItem(ps, pointer)
    dataModel.removePattern(ps, pointer)
}

function deleteComponentData(ps: PS, componentPointer: Pointer) {
    const comps = [componentPointer]
    if (displayedOnlyStructureUtil.isRepeatedComponent(componentPointer.id)) {
        const originalId = displayedOnlyStructureUtil.getRepeaterTemplateId(componentPointer.id)
        const pagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
        const originalCompPointer = ps.pointers.full.components.getComponent(originalId, pagePointer)
        const existInFullAndNotInDisplayed =
            ps.dal.full.isExist(originalCompPointer) && !ps.dal.isExist(originalCompPointer)
        if (existInFullAndNotInDisplayed) {
            comps.push(originalCompPointer)
        }
    }

    const isDesktop = !ps.pointers.components.isMobile(componentPointer)
    _.forEach(comps, function (pointer) {
        const {shouldDelete, dataComponentPointer, desktopPointer} = getDataDeletionParameters(ps, isDesktop, pointer)

        if (shouldDelete) {
            deleteAllDataFromComponent(ps, dataComponentPointer)
        }

        mobileUtil.deleteForkedData(ps, desktopPointer, componentData, dataModel)
    })
}

function deleteComponentDataAndInvokeHooks(
    ps: PS,
    componentPointer: Pointer,
    deletingParent,
    removeArgs,
    deletedParentFromFull,
    completeCallback = _.noop,
    deleteCompFunc?
) {
    const parentPointer = ps.pointers.components.getParent(componentPointer)
    const compType = componentStructureInfo.getType(ps, componentPointer)

    hooks.executeHook(hooks.HOOKS.REMOVE.BEFORE, compType, [
        ps,
        componentPointer,
        deletingParent,
        removeArgs,
        deletedParentFromFull,
        completeCallback,
        deleteCompFunc
    ])

    const childrenRefs = ps.pointers.full.components.getChildren(componentPointer)
    _.forEachRight(childrenRefs, child => deleteCompFunc(ps, child, true, removeArgs, true))

    const copyDataItem = dataModel.getDataItem(ps, componentPointer)
    const componentConnections = connectionsDataGetter.getPlatformAppConnections(ps, componentPointer)
    deleteComponentData(ps, componentPointer)

    const argsForHooks = [
        ps,
        componentPointer,
        deletingParent,
        removeArgs,
        deletedParentFromFull,
        copyDataItem,
        parentPointer,
        componentConnections
    ]
    const dal = ps.dal.isExist(componentPointer) ? ps.dal : ps.dal.full

    dal.remove(componentPointer)

    //TODO: check hooks pointers
    hooks.executeHook(hooks.HOOKS.REMOVE.AFTER, compType, argsForHooks)

    completeCallback(ps)
}

function deleteComponentImpl(
    ps: PS,
    componentPointer: Pointer,
    deletingParent,
    removeArgs,
    deletedParentFromFull,
    completeCallback
) {
    const removeByParent = componentsMetaData.shouldBeRemovedByParent(ps, componentPointer)
    if (removeByParent) {
        componentPointer =
            ps.pointers.components.getParent(componentPointer) ||
            ps.pointers.full.components.getParent(componentPointer)
    }

    const validationResult = componentValidations.validateComponentToDelete(ps, componentPointer, deletedParentFromFull)
    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }

    deleteComponentDataAndInvokeHooks(
        ps,
        componentPointer,
        deletingParent,
        removeArgs,
        deletedParentFromFull,
        completeCallback,
        deleteComponent
    )
}

function deleteComponentImplFromFull(
    ps: PS,
    componentPointer: Pointer,
    deletingParent,
    removeArgs,
    deletedParentFromFull,
    completeCallback
) {
    let validationResult = componentValidations.validateComponentTypeToDelete(ps, componentPointer)
    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }

    validationResult = componentValidations.validateComponentExistOnFull(ps, componentPointer)
    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }

    deleteComponentDataAndInvokeHooks(
        ps,
        componentPointer,
        deletingParent,
        removeArgs,
        deletedParentFromFull,
        completeCallback,
        deleteComponentFromFull
    )
}

/**
 * Removes a component from display for a given defined path in the document.
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {Boolean} [deletingParent] is this component is deleted as part of deletion of it's parent
 * @param {Object} [removeArgs] - additional deletion arguments
 * @param [deletedParentFromFull]
 * @param [completeCallback]
 * returns {boolean} true iff the component was removed successfully.
 */
function deleteComponent(
    ps: PS,
    componentPointer: Pointer,
    deletingParent?: boolean,
    removeArgs?,
    deletedParentFromFull?,
    completeCallback?
) {
    if (componentsMetaData.shouldRemoveAsGhost(ps, componentPointer)) {
        return ghostifyComp(ps, componentPointer, completeCallback)
    }
    const compToRemove = pointerUtils.getRepeatedItemPointerIfNeeded(componentPointer)
    completeCallback = completeCallback || _.noop
    deleteComponentImpl(ps, compToRemove, deletingParent, removeArgs, deletedParentFromFull, completeCallback)
}

/**
 * Removes a component from full for a given defined path in the document.
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {Boolean} deletingParent is this component is deleted as part of deletion of it's parent
 * @param {Object} removeArgs - additional deletion arguments
 * @param deletedParentFromFull
 * @param completeCallback
 * returns {boolean} true iff the component was removed successfully.
 */
function deleteComponentFromFull(
    ps: PS,
    componentPointer: Pointer,
    deletingParent: boolean,
    removeArgs: any,
    deletedParentFromFull,
    completeCallback
) {
    const compToRemove = pointerUtils.getRepeatedItemPointerIfNeeded(componentPointer)
    completeCallback = completeCallback || _.noop
    deleteComponentImplFromFull(ps, compToRemove, deletingParent, removeArgs, deletedParentFromFull, completeCallback)
}

function getComponentToDuplicateRef(
    ps: PS,
    componentPointer: Pointer,
    newContainerPointer: Pointer,
    customId?: string
) {
    return createCompRef(ps, newContainerPointer, customId)
}

/**
 * Duplicate a component and place it under a given path.
 * This method duplicates child components recursively.
 * @param {ps} ps ps
 * @param {Pointer} compRefToAdd
 * @param {Pointer} componentPointer the component to be duplicated
 * @param {Pointer} newContainerPointer the path for the duplicated component
 * @param {String=} customId
 * @returns {*} the duplicated component
 */
function duplicateComponent(
    ps: PS,
    compRefToAdd: Pointer,
    componentPointer: Pointer,
    newContainerPointer: Pointer,
    customId?: string
): any {
    const serializedComponent = componentSerialization.serializeComponent(ps, componentPointer)
    hooks.executeHook(hooks.HOOKS.DUPLICATE.BEFORE, serializedComponent.componentType, [
        ps,
        componentPointer,
        serializedComponent,
        newContainerPointer
    ])
    addComponentToContainer(ps, compRefToAdd, newContainerPointer, serializedComponent, customId)
}

/** Checks whether the component is removable
 * @param  {ps} ps
 * @param  {Pointer} compPointer
 * @returns {boolean} true if component is removable, false otherwise
 */
function isComponentRemovable(ps: PS, compPointer: Pointer): boolean {
    const isRemovable = componentsMetaData.public.isRemovable(ps, compPointer)
    if (!isRemovable) {
        return false
    }
    const compType = componentStructureInfo.getType(ps, compPointer)
    return hooks.executeHook(
        hooks.HOOKS.REMOVE.IS_OPERATION_ALLOWED,
        compType,
        // @ts-expect-error
        arguments,
        isAllowed => isAllowed === false
    )
}

function isComponentDuplicatable(ps: PS, compPointer: Pointer, potentialParentPointer: Pointer) {
    const isDuplicatable = componentsMetaData.public.isDuplicatable(ps, compPointer, potentialParentPointer)
    if (!isDuplicatable) {
        return false
    }
    const compType = componentStructureInfo.getType(ps, compPointer)
    // @ts-expect-error
    return hooks.executeHook(hooks.HOOKS.DUPLICATE.IS_OPERATION_ALLOWED, compType, arguments, function (isAllowed) {
        return isAllowed === false
    })
}

/**
 * Returns the Layout Object of a Component.
 *
 * @param {ps} ps
 * @param {Pointer} compPointer a Component Reference matching a component in the document.
 * @returns {Object} a Layout object of the corresponding Component.
 * @throws an exception in case the compReference doesn't correspond any component in the document.
 *
 *      @example
 *      const myPhotoLayout = documentServices.components.layout.get(myPhotoRef);
 *      // perform some changes on the layout
 *      myPhotoLayout.x += 20;
 *      ...
 *      // update the document.
 *      documentServices.components.layout.update(myPhotoRef, myPhotoLayout);
 */
function getComponentLayout(ps: PS, compPointer: Pointer) {
    const layout = structureUtils.getComponentLayout(ps, compPointer)
    if (experiment.isOpen('dm_responsiveLayoutInClassicEditor')) {
        const {dal, pointers} = ps
        return mockConvertAbsoluteLayoutToResponsive(dal, pointers, compPointer)
    }
    return layout
}

/**
 *
 * @param {ps} ps
 * @param {Pointer} compRef a Component Reference matching a component in the document.
 * @returns {ClientRect} - native browser boundingClientRect
 */
function getCompBoundingClientRect(ps: PS, compRef: Pointer) {
    return ps.siteAPI.getComponentBoundingBox(compRef)
}

/**
 *
 * @param {ps} ps
 * @param {Pointer} compRef a Component Reference matching a component in the document.
 * @param {string} selector the selector for the inner element
 * @returns {ClientRect[]} - native browser boundingClientRect array
 */
function getCompInnerElementBoundingClientRects(ps: PS, compRef: Pointer, selector: string) {
    return ps.siteAPI.getComponentInnerElementBoundingBoxes(compRef, selector)
}

/**
 * @param {ps} ps
 * @param {Pointer} compRef a Component Reference matching a component in the document.
 * @returns {ClientRect} - native browser boundingClientRect relative to the viewport
 */
function getRelativeToViewportBoundingBox(ps: PS, compRef: Pointer) {
    return ps.siteAPI.getRelativeToViewportBoundingBox(compRef)
}

/**
 *
 * @param {ps} ps
 * @param {Pointer} compRef a Component Reference matching a component in the document.
 * @returns {Padding} - native browser element padding (in px)
 */
function getPadding(ps: PS, compRef: Pointer) {
    return ps.siteAPI.getPadding(compRef)
}

const getRenderFlag = (ps: PS, flag) => ps.dal.get(ps.pointers.general.getRenderFlag(flag))

/**
 * @param {ps} ps
 * @param {Pointer} compPointer
 * @returns {boolean} true if the component is visible, false otherwise
 */
function isComponentVisible(ps: PS, compPointer: Pointer): boolean {
    /*this is for supporting editor testkit still uses santa - there the plugins were not registered and it was always true*/
    const viewMode = ps.pointers.components.getViewMode(compPointer)
    const isCompHiddenByRenderPlugin = ps.dal.get(ps.pointers.general.getIsCompHiddenPointer(compPointer.id, viewMode))
    if (getRenderFlag(ps, 'componentViewMode') !== 'preview' && isCompHiddenByRenderPlugin) {
        return false
    }
    const compProperties = componentStructureInfo.getPropertiesItem(ps, compPointer)
    const ignoreHiddenProperty = _.includes(getRenderFlag(ps, 'ignoreComponentsHiddenProperty'), compPointer.id)

    const showHiddenComponents = getRenderFlag(ps, 'showHiddenComponents')
    const isHidden = _.get(compProperties, 'isHidden')
    return (
        componentsMetaData.public.isVisible(ps, compPointer) &&
        (!isHidden || ignoreHiddenProperty || (isHidden && showHiddenComponents))
    )
}

/**
 * Generates a new random component Id to be used under a parent's path.
 * @returns {string} Unique ID
 */
function generateNewComponentId() {
    return guidUtils.getUniqueId('comp', '-', {bucket: 'componentIds'})
}

/** Migrates a component from one type to another type
 * @param  {ps} ps Private Services instance.
 * @param  {Pointer} compPointer Pointer to the component you wish to have migrated.
 * @param  {object} componentDefinition The JSON representing the component to be migrated to.
 */
function migrate(ps: PS, compPointer: Pointer, componentDefinition) {
    const mobilePtr = ps.pointers.components.getMobilePointer(compPointer)
    const mobileParentPointer = ps.pointers.components.getParent(mobilePtr)
    const mobilePagePointer = ps.dal.isExist(mobilePtr)
        ? ps.pointers.components.getPageOfComponent(mobilePtr)
        : undefined
    const mobileContainerCompLayout =
        componentDefinition.mobileStructure ||
        (ps.dal.isExist(mobileParentPointer)
            ? ps.dal.get(ps.pointers.getInnerPointer(mobileParentPointer, ['layout']))
            : null)
    const mobileCompLayout = ps.dal.isExist(mobilePtr)
        ? ps.dal.get(ps.pointers.getInnerPointer(mobilePtr, ['layout']))
        : undefined
    const compParent = ps.pointers.full.components.getParent(compPointer)

    // Fix mobile layouts to be as before (if mobile exist)
    if (!componentDefinition.mobileStructure && mobileContainerCompLayout) {
        componentDefinition.mobileStructure = {layout: mobileContainerCompLayout}
    }

    const originalZIndex = arrangement.getCompIndex(ps, compPointer)

    //Remove original comp and add the new one instead
    removeComponent(ps, compPointer, _.noop, {isReplacingComp: true})

    addComponentToContainer(ps, compPointer, compParent, componentDefinition, undefined, originalZIndex)

    // Adjustments for mobile comp
    if (mobileContainerCompLayout && mobileCompLayout) {
        const desktopComp = ps.dal.get(compPointer)
        const mobileComp = {...desktopComp, layout: mobileCompLayout, components: []}
        const mobileParentsChildrenPointer = ps.pointers.full.components.getChildrenContainer(mobileParentPointer)
        ps.dal.full.remove(
            ps.pointers.components.getComponent(
                constants.DOM_ID_PREFIX.DEAD_MOBILE_COMP + compPointer.id,
                mobilePagePointer
            )
        )
        const mobileParentsChildren = ps.dal.full.get(mobileParentsChildrenPointer)
        if (!mobileParentsChildren.includes(mobilePtr.id)) {
            ps.dal.full.push(mobileParentsChildrenPointer, mobileComp, mobilePtr)
        }
    }
}

function isComponentModal(ps: PS, compPointer: Pointer) {
    return componentsMetaData.public.isModal(ps, compPointer)
}

function isComponentUsingLegacyAppPartSchema(ps: PS, compPointer: Pointer) {
    return componentsMetaData.public.isUsingLegacyAppPartSchema(ps, compPointer)
}

/** @class documentServices.components */
function applyComponentToMode(ps: PS, components, activeModeId: string) {
    _.forEach(components, function (component) {
        const {compRef} = component
        componentModes.removeSingleModeBehaviors(ps, compRef)
        const modesPointer = ps.pointers.componentStructure.getModes(compRef)
        const compModes = ps.dal.full.get(modesPointer)

        const hasTargetMode = _.some(compModes.overrides, override => _.includes(override.modeIds, activeModeId))

        if (hasTargetMode) {
            compModes.overrides = compModes.overrides.map(override => {
                if (_.includes(override.modeIds, activeModeId)) {
                    override.isHiddenByModes = false
                }
                return override
            })
        } else {
            compModes.overrides.push({modeIds: [activeModeId], isHiddenByModes: false})
        }

        ps.dal.full.set(modesPointer, compModes)

        const sourceOverride = _.find(component.compDefinition.modes.overrides, override => {
            const sourceActiveModeId = _(component.compDefinition.activeModes).keys().head()
            return _.includes(override.modeIds, sourceActiveModeId)
        })

        const sourceLayout = sourceOverride?.layout ?? component.compDefinition.layout
        const layoutPointer = ps.pointers.getInnerPointer(compRef, 'layout')
        ps.dal.set(layoutPointer, sourceLayout)

        const sourceProps = sourceOverride?.props ?? component.compDefinition.props
        if (sourceProps) {
            dataModel.updatePropertiesItem(ps, compRef, sourceProps)
        }

        if (sourceOverride?.style) {
            componentStylesAndSkinsAPI.style.setId(ps, compRef, sourceOverride.previousStyleId)
        }
    })
}

function updateDesignItem(ps: PS, componentPointer: CompRef, designItem, retainCharas?) {
    const currentCompDesignItem = design.getDesignItem(ps, componentPointer) || {}
    const backgroundChange =
        designItem.background && !_.isEqual(designItem.background, currentCompDesignItem.background)
    const designId = design.updateDesignItem(ps, componentPointer, designItem, retainCharas)
    if (backgroundChange && !shouldUseDesignInVariants(ps, componentPointer as CompRef)) {
        removeInvalidMediaTransforms(ps, componentPointer)
    }
    return designId
}

function removeInvalidMediaTransforms(ps: PS, compPointer: Pointer) {
    const compDesignItems = componentModes.getAllCompDesignItems(ps, compPointer)
    const mediaRefs = _.map(compDesignItems, designItem =>
        _.pick(design.getMediaRef(designItem), ['uri', 'videoId', 'type'])
    )

    const sameMediaRefs = _.every(mediaRefs, mediaRef => _.isEqual(mediaRef, _.head(mediaRefs)))

    if (!sameMediaRefs || mediaRefs.length <= 1) {
        componentModes.removeDesignBehaviorsFromAllModes(ps, compPointer, ['media'])
    }
}

function validateComponentPointerRemoval(ps: PS, componentPointer: Pointer) {
    if (
        ps.pointers.components.isMobile(componentPointer) &&
        constants.MOBILE_ONLY_COMPONENTS.hasOwnProperty(componentPointer.id)
    ) {
        throw new Error(componentValidations.ERRORS.CANNOT_DELETE_MOBILE_COMPONENT)
    }

    if (ps.pointers.components.isWithVariants(componentPointer)) {
        throw new Error(componentValidations.ERRORS.CANNOT_DELETE_COMPONENT_WITH_VARIANT)
    }
}

function removeComponentFromFull(ps: PS, componentPointer: Pointer, completeCallback?, removeArgs?) {
    validateComponentPointerRemoval(ps, componentPointer)
    deleteComponentFromFull(ps, componentPointer, false, removeArgs, false, completeCallback)
}

function removeComponent(ps: PS, componentPointer: Pointer, completeCallback?, removeArgs?) {
    validateComponentPointerRemoval(ps, componentPointer)
    deleteComponent(ps, componentPointer, false, removeArgs, false, completeCallback)
}

const getMobileRef = (ps: PS, componentPointer: Pointer) => {
    const validationResult = componentValidations.validateExistingComponent(ps, componentPointer)

    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }

    if (componentPointer.type !== constants.VIEW_MODES.DESKTOP) {
        throw new Error(componentValidations.ERRORS.INVALID_DESKTOP_POINTER)
    }

    return ps.pointers.components.getMobilePointer(componentPointer)
}

const ghostifyComp = (ps: PS, compPointer: Pointer, completeCallback = _.noop) => {
    if (componentsMetaData.shouldBeRemovedByParent(ps, compPointer)) {
        compPointer =
            ps.pointers.components.getParent(compPointer) || ps.pointers.full.components.getParent(compPointer)
    }
    const pointerToGhostify = pointerUtils.getRepeatedItemPointerIfNeeded(compPointer)
    const compType = componentStructureInfo.getType(ps, pointerToGhostify)
    const copyDataItem = dataModel.getDataItem(ps, pointerToGhostify)
    const componentConnections = connectionsDataGetter.getPlatformAppConnections(ps, pointerToGhostify)
    const newPropertyItem = {ghost: 'COLLAPSED'}

    dataModel.updatePropertiesItem(ps, pointerToGhostify, newPropertyItem)
    mobileUtil.updateMobilePropertyIfNeeded(ps, pointerToGhostify, newPropertyItem, dataModel.updatePropertiesItem)

    const argsForHooks = [
        ps,
        pointerToGhostify,
        undefined,
        undefined,
        undefined,
        copyDataItem,
        undefined,
        componentConnections
    ]
    hooks.executeHook(hooks.HOOKS.GHOSTIFY.AFTER, compType, argsForHooks)

    completeCallback()
}

const getConstrainingComponents = (ps: PS, compRef) => {
    const needsConstraining =
        componentsMetaData.public.isContainer(ps, compRef) &&
        componentsMetaData.public.isEnforcingContainerChildLimitations(ps, compRef)
    if (!needsConstraining) {
        return []
    }

    const compType = componentStructureInfo.getType(ps, compRef)
    const children = componentStructureInfo.getChildren(ps, compRef)
    const hasChildren = children.length > 0
    // The reference to the specific comp type 'wysiwyg.viewer.components.StripColumnsContainer' should be temporary.
    // If we continue with this direction, this logic should be generalized. See https://jira.wixpress.com/browse/DM-5713
    const shouldSkipGenerationWhenConstraining =
        [
            'wysiwyg.viewer.components.StripColumnsContainer',
            'wysiwyg.viewer.components.StripContainerSlideShow'
        ].includes(compType) && hasChildren

    if (shouldSkipGenerationWhenConstraining) {
        return children
    }
    return [compRef]
}

const constrainRuntimeLayout = (ps: PS, compRef: Pointer, layout: Layout) => {
    const clonedLayout = _.cloneDeep(layout)
    const isEnforcingByWidth = componentsMetaData.public.isEnforcingContainerChildLimitationsByWidth(ps, compRef)
    const isEnforcingByHeight = componentsMetaData.public.isEnforcingContainerChildLimitationsByHeight(ps, compRef)
    const compsToConstrainBy = getConstrainingComponents(ps, compRef)

    for (const target of compsToConstrainBy) {
        layoutConstraintsUtils.constrainByChildrenLayout(
            ps,
            target,
            clonedLayout,
            !isEnforcingByWidth,
            !isEnforcingByHeight
        )
    }
    return clonedLayout
}

export default {
    initialize,

    /**
     * returns true if one of the component offspring type included in supplied types.
     *
     * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
     * @param {string|array} types component types to check against.
     * taken from the parent's reference or from the current view mode of the document.
     * @returns {boolean} true if one of the component offspring type included in supplied types.
     *
     *      @example
     *      const types = ['wysiwyg.viewer.components.tpapps.TPASection', 'wysiwyg.viewer.components.tpapps.TPAMultiSection'];
     *      const isContainTPASection = documentServices.components.isContainsCompWithType(mainPageRef, types);
     */
    isContainsCompWithType: componentStructureInfo.isContainsCompWithType,
    isRenderedOnSite: componentStructureInfo.isRenderedOnSite,
    getComponentToAddRef,
    getComponentToDuplicateRef,
    /**
     * Add a component to a container in the document.
     *
     * @param {Pointer} containerPointer of the container to add the component to.
     * @param {Object} componentDefinition - {componentType: String, styleId: String, data: String|Object, properties: String|Object}<br>
     * componentDefinition is a javascript object containing data such as:
     * 'componentType', 'skin', 'style', 'layout', 'components'(children components), 'data' & 'props'.<br>
     * Notice that the 'componentDefinition' object may or may not contain 'layout' object.<br>
     * Notice that the 'componentDefinition' object must contain 'data' & 'props' either being of a 'string'
     * type matching the componentType, or a dataItem/propertiesItem matching the componentType.
     * These can be created by the documentServices.data.createItem("MyDataItemType") and
     * the documentServices.properties.. respectively;
     * @param {string} [optionalCustomId] An optional ID to give the component which will be added. in case
     * componentDefinition holds 'components' (holding componentDefinitions recursively), the 'id' key, will be
     * passed recursively as the childrens' Ids.
     * @returns the added component Reference, or 'null' if failed.
     * @throws an exception in case the parent container isn't a valid one.
     *
     *      @example
     *      const mainPageRef = documentServices.pages.getReference('mainPage');
     *      const photoCompDef = {
     *          "style": "wp1",
     *          "componentType": "wysiwyg.viewer.components.WPhoto",
     *          "data": "Image",
     *          "props": "WPhotoProperties"
     *      };
     *
     *      const addedPhotoRef = documentServices.components.add(mainPageRef, photoCompDef);
     *      documentServices.components.data.update(addedPhotoRef, {"uri": "http://www.company.domain/path-to/image.png"});
     */
    add: addComponentToContainer,
    getAddComponentInteractionParams,
    /**
     * Add a component to a container in the document.
     *
     * @param {Pointer} containerReference of the container to add the component to.
     * @param {Object} componentDefinition - {componentType: String, styleId: String, data: String|Object, properties: String|Object}<br>
     * componentDefinition is a javascript object containing data such as:
     * 'componentType', 'skin', 'style', 'layout', 'components'(children components), 'data' & 'props'.<br>
     * Notice that the 'componentDefinition' object may or may not contain 'layout' object.<br>
     * Notice that the 'componentDefinition' object must contain 'data' & 'props' either being of a 'string'
     * type matching the componentType, or a dataItem/propertiesItem matching the componentType.
     * These can be created by the documentServices.data.createItem("MyDataItemType") and
     * the documentServices.properties.. respectively;
     * @param {string} [optionalCustomId] An optional ID to give the component which will be added. in case
     * componentDefinition holds 'components' (holding componentDefinitions recursively), the 'id' key, will be
     * passed recursively as the childrens' Ids.
     * @returns the added component Reference, or 'null' if failed.
     * @throws an exception in case the parent container isn't a valid one.
     *
     *      @example
     *      const mainPageRef = documentServices.pages.getReference('mainPage');
     *      const photoCompDef = {
     *          "style": "wp1",
     *          "componentType": "wysiwyg.viewer.components.WPhoto",
     *          "data": "Image",
     *          "props": "WPhotoProperties"
     *      };
     *
     *      const addedPhotoRef = documentServices.components.add(mainPageRef, photoCompDef);
     *      documentServices.components.data.update(addedPhotoRef, {"uri": "http://www.company.domain/path-to/image.png"});
     */
    addAndAdjustLayout: addComponent,
    addComponentInternal,
    /**
     * Duplicate a component and place it under a given path.<br>
     * This method duplicates child components recursively.
     * @param {Pointer} componentReference the reference to the component to duplicate.
     * @param {Pointer} newContainerReference the reference to the container to contain the new duplicated component.
     * @param {string} [customId] an optional specific custom ID to be given to the duplicated component.
     * @returns the added component Reference, or 'null' if failed.
     * @throws an exception in case the parent container isn't a valid one.
     *
     *      @example
     *      const duplicatedComponent = documentServices.components.duplicate(compReference, targetContainerReference, "compDouble");
     */
    duplicate: duplicateComponent,
    /**
     * @see componentSerialization.serializeComponent
     */
    serialize: componentSerialization.serializeComponent,
    /**
     * Deletes a component from the Document.
     *
     * @param {Pointer} componentReference the reference to the component to delete.
     * @param {Function} completeCallback - callback to be called when done
     * @param {Object} removeArgs - additional deletion arguments
     * @returns true iff the component was deleted successfully from the document.
     * @throws an exception in case ComponentReference view mode is NOT 'Desktop'.
     *
     *      @example
     *      const compReference = ...;
     *      const removeArgs = {isReplacingComp: true}
     *      const isDeleted = documentServices.components.remove(compReference, callBack, removeArgs);
     */
    remove: removeComponent,
    removeFromFull: removeComponentFromFull,
    deleteComponent,
    deleteComponentData,
    validateRemoval,
    validateRemovalInternal,
    updateComponentScopedValuesDefinition,
    shouldDelayDeletion: shouldDelayDeletionTpa,
    /**
     * Returns the parent Component of a component.
     *
     * @param {Pointer} componentReference a Component Reference corresponding a Component in the document.
     * @returns {Pointer} the Component Reference of the parent component, or null if no parent component (example - for page)
     * @throws an error in case the '<i>componentReference</i>' is invalid.
     *
     *      @example
     *      const buttonContainerCompRef = documentServices.components.layout.getParent(buttonComponentRef);
     */
    getContainer: componentStructureInfo.getContainer,
    /**
     * Returns all parent Components of a component.
     *
     * @param {Pointer} componentReference a Component Reference corresponding a Component in the document.
     * @returns [{Pointer}] array of the the Components Reference of the parent components, or empty array when there is no parent component (example - for page)
     * @throws an error in case the '<i>componentReference</i>' is invalid.
     *
     *      @example
     *      const buttonContainerCompRef = documentServices.components.getAncestors(buttonComponentRef);
     */
    getAncestors: componentStructureInfo.getAncestors,
    getAncestorsFromFull: componentStructureInfo.getAncestorsFromFull,
    /**
     * Returns the parent Component of a component.
     *
     * @returns {Pointer} the page the the component is in
     * @throws an error in case the '<i>componentReference</i>' is invalid.
     *
     *      @example
     *      const buttonContainerCompRef = documentServices.components.layout.getParent(buttonComponentRef);
     * @param {Pointer} compPointer
     */
    getPage: componentStructureInfo.getPage,
    /**
     * Returns the Siblings array of a component.
     *
     * @param {Pointer} compReference a Component Reference corresponding a component in the document.
     * @returns {Pointer[]} an array of <i>'ComponentReference'</i>s of the Component's siblings.
     * @throws an Error in case the compReference isn't valid.
     */
    getSiblings: componentStructureInfo.getSiblings,
    /**
     * Returns an array of the repeated items of a component.
     *
     * @param {Pointer} compReference a Component Reference corresponding a component in the document.
     * @returns {Pointer[]} an array of <i>'ComponentReference'</i>s of the Component's repeated components.
     * @throws an Error in case the compReference isn't valid.
     */
    getRepeatedComponents: componentStructureInfo.getRepeatedComponents,
    /**
     * returns the children components of a parent component, that should be displayed on render.
     * If a site exists, these are the currently rendered children.
     *
     * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
     * taken from the parent's reference or from the current view mode of the document.
     * @param {boolean} [isRecursive] if true, will return all parentCompReference descendants (recursively)
     * @returns {Pointer[]} an array of the Component's Children (Component) References.
     * @throws an error in case the <i>parentCompReference</i> is invalid.
     *
     *      @example
     *      const mainPageChildren = documentServices.components.getChildren(mainPageRef);
     */
    getChildren: componentStructureInfo.getChildren,
    getChildrenWithScopes: componentStructureInfo.getChildrenWithScopes,

    /**
     * returns all the children components of a parent component, from full json (both displayed and not).
     *
     * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
     * taken from the parent's reference or from the current view mode of the document.
     * @param {boolean} [isRecursive] if true, will return all parentCompReference descendants (recursively)
     * @returns {Pointer[]} an array of the Component's Children (Component) References.
     * @throws an error in case the <i>parentCompReference</i> is invalid.
     *
     *      @example
     *      const mainPageChildren = documentServices.components.getChildren(mainPageRef);
     */
    getChildrenFromFull: componentStructureInfo.getChildrenFromFull,
    getAllTpaComps,
    /**
     * returns the children components of a parent component.
     *
     * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
     * taken from the parent's reference or from the current view mode of the document.
     * @returns {Pointer[]} an array of the Component's Children (Component) References.
     * @throws an error in case the <i>parentCompReference</i> is invalid.
     *
     *      @example
     *      const mainPageChildren = documentServices.components.getAllJsonChildren(mainPageRef);
     */
    getAllJsonChildren: componentStructureInfo.getAllJsonChildren,

    /**
     * returns the children tpa components recurse of a parent component.
     *
     * @param {Pointer} parentCompReference a ComponentReference of a corresponding Component containing children.
     * @param {string} [viewMode] is the view mode of the document (DESKTOP|MOBILE), if not specified will be
     * taken from the parent's reference or from the current view mode of the document.
     * @returns {Pointer[]} an array of the Component's Children (Component) References.
     * @throws an error in case the <i>parentCompReference</i> is invalid.
     *
     *      @example
     *      const viewMode = 'DESKTOP'; // This is optional
     *      const mainPageChildren = documentServices.components.layout.getChildComponents(mainPageRef, viewMode);
     */
    getTpaChildren: componentStructureInfo.getTpaChildren,
    getBlogChildren: componentStructureInfo.getBlogChildren,
    /**
     * returns the type/"class" of a component.
     *
     * @function
     * @memberOf documentServices.components.component
     *
     * @param {Pointer} componentReference the reference to the component to get its type.
     * @returns {string} the name of the component Type/"class". 'null' if no corresponding component was found.
     *
     *      @example
     *      const photoType = documentServices.components.getType(myPhotoReference);
     */
    getType: componentStructureInfo.getType,
    getDefinition: componentStructureInfo.getDefinition,
    /**
     * returns the mobile pointers
     * @returns {Pointer} the mobile pointer of the component
     * @throws an error in case the Pointer is not desktop or the Pointer is not exist.
     * @param {Pointer} desktop component pointer
     */
    getMobileRef,
    setComponent,
    isComponentRemovable,
    isComponentVisible,
    isComponentModal,
    isComponentUsingLegacyAppPartSchema,
    isComponentDuplicatable,
    isPageComponent: componentStructureInfo.isPageComponent,
    generateNewComponentId,
    migrate,

    /** @class documentServices.components.properties*/
    properties: {
        /**
         * Updates component's Properties (Data)Item.
         * @param {Pointer} componentRef A ComponentReference to match a corresponding Component in the document.
         * @param {Object} propertiesItem A partial Properties (Data)Item corresponding the properties type of the
         * Component's Data to update.
         *
         *      @example
         *      const myPhotoRef = ...;
         *      documentServices.components.properties.update(myPhotoRef, {displayMode: "full"});
         */
        update: dataModel.updatePropertiesItem,
        /**
         * Gets a Properties(Data)Item instance corresponding a Component Reference from the document.
         *
         * @param {Pointer} componentReference a reference of a component in the document.
         * @returns {Object} a Properties (Data)Item corresponding the componentReference. 'null' if not found.
         */
        get: dataModel.getPropertiesItem,
        /** @class documentServices.components.properties.mobile*/
        mobile: {
            /**
             * Creates a copy of the desktop component properties and set it to the mobile component
             * @param {Pointer} mobileCompRef
             */
            fork: componentData.splitMobileComponentProperties,
            /**
             * Returns the mobile component properties to their default mode.<br>
             * In most cases it means that the mobile and desktop component properties will point to the same object.
             * @param {Pointer} mobileCompRef
             */
            join: componentData.resetMobileComponentProperties,
            /**
             * Checks if component has split properties - mobile and desktop.
             * @param {Pointer} mobileCompRef
             * @returns {boolean} true if component has split properties
             */
            isForked: componentData.isMobileComponentPropertiesSplit
        }
    },
    /** @class documentServices.components.data*/
    data: {
        /**
         * Gets a DataItem instance corresponding a Component Reference from the document.
         *
         * @param {Pointer} componentReference a reference of a component in the document.
         * @returns {Object} a Data Item corresponding the componentReference. 'null' if not found.
         */
        get: dataModel.getDataItem,
        /**
         * Merges the given data item to the component data item
         *
         * @param {Pointer} componentRef A ComponentReference to match a corresponding Component.
         * @param {Object} dataItem A partial DataItem corresponding the type of the Component's Data to update.
         * @param {Boolean} [useOriginalLanguage] A boolean which holds whether to maintain original language data-Item. Tpa's defaults to true
         * @returns undefined
         *
         *      @example
         *      const myPhotoRef = ...;
         *      documentServices.components.data.update(myPhotoRef, {uri: "http://static.host.com/images/image-B.png"});
         */
        update: dataModel.updateDataItem,
        /**
         * Merges the given data item to the component data item
         *
         * @param {Pointer} componentRef A ComponentReference to match a corresponding Component.
         * @param {Object} dataItem A partial DataItem corresponding the type of the Component's Data to update.
         * @param {String} [useLanguage] language code to use when setting the dataItem. e.g. he/de/es...
         * @returns undefined
         *
         *      @example
         *      const myPhotoRef = ...;
         *      documentServices.components.data.updateInLang(myPhotoRef, {uri: "http://static.host.com/images/image-B.png"},'he');
         */
        updateInLang: dataModel.updateDataItemInLang
    },
    feature: {
        // @ts-expect-error BUG?
        update: dataModel.updateLayerData,
        // @ts-expect-error BUG?
        get: dataModel.getLayerData
    },
    design: {
        updateDesignItem
    },
    /** @class documentServices.components.layout*/
    layout: {
        /**
         * Returns the Layout Object of a Component.
         *
         * @param {Pointer} compReference a Component Reference matching a component in the document.
         * @returns {Object} a Layout object of the corresponding Component.
         * @throws an exception in case the compReference doesn't correspond any component in the document.
         *
         *      @example
         *      const myPhotoLayout = documentServices.components.layout.get(myPhotoRef);
         *      // perform some changes on the layout
         *      myPhotoLayout.x += 20;
         *      ...
         *      // update the document.
         *      documentServices.components.layout.update(myPhotoRef, myPhotoLayout);
         */
        get: getComponentLayout,
        measure: {
            /**
             * Returns boundingClientRect - native browser function
             *
             * @param {Pointer} CompRef
             */
            getBoundingClientRect: getCompBoundingClientRect,
            /**
             * Returns boundingClientRect array - native browser function
             *
             * @param {Pointer} CompRef
             * @param {string} selector
             */
            getInnerElementBoundingClientRects: getCompInnerElementBoundingClientRects,
            /**
             * Returns relative to viewport boundingBox
             *
             * @param {Pointer} CompRef
             */
            getRelativeToViewportBoundingBox,
            getPadding
        },
        updateAndPushStart: (ps: PS, compRef: Pointer) => {
            return ps.siteAPI.updateAndPushStart(compRef as any)
        },
        updateAndPushUpdate: (ps: PS, compRef: Pointer, runtimeLayout) => {
            const constrainedLayout = constrainRuntimeLayout(ps, compRef, runtimeLayout)
            return ps.siteAPI.updateAndPushUpdate(compRef as any, constrainedLayout)
        },
        updateAndPushEnd: (ps: PS, compRef: Pointer) => {
            return ps.siteAPI.updateAndPushEnd(compRef as any)
        },
        runtime: {
            /** Return the components that should be used to constrain the layout of the component represented by `compRef`.
             *
             * For most comps this is the comp itself.
             * For most containers this is comp itself, if it has one of the relevant metaData flags:
             *  - enforceContainerChildLimitsByHeight
             *  - enforceContainerChildLimitsByWidth
             * For StripColumnsContainers with children these are the children. Most likely, this case will be
             *  generalized into a metaData flag in the future. See JIRA DM-5713
             *
             * @param {ps} ps
             * @param {Pointer} compRef
             * @returns {Pointer[]}
             */
            getConstrainingComponents,
            /**
             * update the comp position only on viewer and not on json. needed for optimistic drag using mesh for TB
             *
             * This function enforces layout constraints according to the result of the `getConstrainingComponents`
             * function in this module before sending the command to the viewer.
             *
             * @param {ps} ps
             * @param {Pointer} compRef
             * @param {object} runtimeLayout - object containing y/height
             */
            updateAndPush: (ps: PS, compRef: Pointer, runtimeLayout) => {
                const constrainedLayout = constrainRuntimeLayout(ps, compRef, runtimeLayout)
                return ps.siteAPI.updateRuntimeLayout(compRef.id, constrainedLayout)
            },
            /**
             * update the comp position only on viewer and not on json. needed for optimistic drag using mesh for TB
             *
             * @param {ps} ps
             * @param {Pointer} compRef
             */
            remove: (ps: PS, compRef: Pointer) => ps.siteAPI.removeRuntimeLayout(compRef.id),
            /**
             * Detach component from layout and define absolute position to it
             *
             * @param {ps} ps
             * @param {Pointer} compPointer
             */
            detachLayout: (ps: PS, compPointer: Pointer) => {
                const parentPointer = ps.pointers.components.getParent(compPointer)
                ps.siteAPI.detach(compPointer.id, parentPointer.id)
            },
            /**
             * Detach components from layout and define absolute position to it
             *
             * @param {ps} ps
             * @param {Pointer[]} componentsPointers
             */
            detachComponents: (ps: PS, componentsPointers: Pointer[]) =>
                ps.siteAPI.detachMulti(_.map(componentsPointers, 'id')),
            /**
             * Change component inner style
             *
             * @param {ps} ps
             * @param {Pointer} detachedCompPointer
             * @param {object} boundingBox
             */
            updateDetachedLayout: (ps: PS, detachedCompPointer: Pointer, boundingBox) =>
                ps.siteAPI.updateDetached(detachedCompPointer.id, boundingBox),
            reattachLayout: (ps: PS, detachedCompPointer: Pointer) => ps.siteAPI.clearDetached(detachedCompPointer.id),
            reattachLayoutAndUpdate: (ps: PS, detachedCompPointer: Pointer, layout, cb: Callback) => {
                structure.updateCompLayout(ps, detachedCompPointer, layout)
                ps.siteAPI.clearDetached(detachedCompPointer.id)
                //should be called synchronously after viewer api call, because editor need to disable reading layout deltas from viewer
                cb()
            },
            reattachComponents: (ps: PS) => ps.siteAPI.clearDetached()
        }
    },

    isExist: componentStructureInfo.isExist,
    /**
     * this should be used in every method that adds a component to a container,
     * some containers have other containers in them that the component should be added to
     * @param {ps} ps
     * @param {Pointer} containerPointer the container that we want to add a component to
     * @returns {Pointer} a pointer to the container
     */
    getContainerToAddComponentTo: componentStructureInfo.getContainerToAddComponentTo,
    applyComponentToMode,
    getA11ySchema: a11yAPI.getA11ySchema,
    modes: componentModes
}
