import type {SnapshotDal} from '@wix/document-manager-core'
import _ from 'lodash'
import * as visitableData from '../utils/visitableData'
import extractDataDeltaFromSnapshotDiff from './extractDataDeltaFromSnapshotDiff'

const {
    createFromSnapshotDal: createVisitableFromSnapshotDal,
    createFromSnapshotDiff: createVisitableFromSnapshotDiff,
    createFromJS,
    createEmpty
} = visitableData

const createFromSnapshotDal = (lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal) => {
    const diff = currentSnapshotDal.diff(lastSnapshotDal)
    const {changedData, deletedData, changedDataPageIds, deletedDataPageIds} = extractDataDeltaFromSnapshotDiff(
        diff,
        lastSnapshotDal,
        currentSnapshotDal
    )
    const isMasterPageUpdated =
        _.includes(changedDataPageIds, 'masterPage') || _.includes(deletedDataPageIds, 'masterPage')
    return createFromSnapshotDiff(
        diff,
        lastSnapshotDal,
        currentSnapshotDal,
        changedData.document_data,
        deletedData.document_data,
        isMasterPageUpdated
    )
}

const createFromSnapshotDiff = (
    diff,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    changedData,
    deletedData,
    isMasterPageUpdated
) => {
    const clientSpecMap = currentSnapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
    const updatedPagesVisitable = createVisitableFromSnapshotDiff(diff)
    const currentPagesVisitable = createFromJS(currentSnapshotDal.toJSForType('data'))
    const lastPagesVisitable = lastSnapshotDal
        ? createVisitableFromSnapshotDal(_.keys(changedData), lastSnapshotDal)
        : createEmpty()
    const deletedPagesVisitable = lastSnapshotDal
        ? createVisitableFromSnapshotDal(_.keys(deletedData), lastSnapshotDal)
        : createEmpty()
    const semanticAppVerions = currentSnapshotDal.getValue({type: 'semanticAppVersions', id: 'semanticAppVersions'})
    const routerConfigMap = _.get(currentSnapshotDal.getValue({type: 'rendererModel', id: 'routers'}), 'configMap')
    const appsState = currentSnapshotDal.getValue({type: 'appsState', id: 'appsState'})
    const branchId = currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'branchId'})

    return {
        clientSpecMap,
        isMasterPageUpdated,
        updatedPagesVisitable,
        currentPagesVisitable,
        lastPagesVisitable,
        deletedPagesVisitable,
        semanticAppVerions,
        routerConfigMap,
        appsState,
        branchId
    }
}

export {createFromSnapshotDal, createFromSnapshotDiff}
