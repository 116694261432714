import _ from 'lodash'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import wixCode from '../../wixCode/wixCode'
import platform from '../../platform/platform'
import routers from '../../routers/routers'

function notifyApplicationOfPageDeleted(ps, routerRef, pageId) {
    const routerData = routers.get.byRef(ps, routerRef)
    const appId = platform.getAppDataByAppDefId(ps, routerData.appDefinitionId).applicationId
    const pageRole = _.findKey(routerData.pages, pid => pid === pageId)
    platform.notifyApplication(ps, appId, platformEvents.factory.pageDeleted({pageRole}))
}

function beforePageRemoveHook(ps, pagePointer) {
    const routerRef = routers.getRouterRef.byPage(ps, pagePointer)
    if (routerRef) {
        notifyApplicationOfPageDeleted(ps, routerRef, pagePointer.id)
        routers.pages.removePageFromRouter(ps, routerRef, pagePointer.id)
    }
}

function removePageFromPlatformApps(ps, pagePointer) {
    platform.removePageFromPlatformApps(ps, pagePointer)
}

function replaceCompCodeBehaviorsRecursively(ps, comp, pageId) {
    if (comp.behaviorQuery) {
        const behaviorPointer = ps.pointers.data.getBehaviorsItem(comp.behaviorQuery, pageId)
        const behavior = ps.dal.get(behaviorPointer)
        let deserializedItems
        try {
            deserializedItems = JSON.parse(behavior.items)
        } catch (e) {
            // Could not parse
        }
        let changed = false
        _(deserializedItems)
            .filter(['action.type', 'comp'])
            .reject(['action.sourceId', comp.id])
            .forEach(function (item) {
                changed = true
                item.action.sourceId = comp.id
                item.behavior.targetId = pageId
                if (_.has(item, 'behavior.params.compId')) {
                    item.behavior.params.compId = comp.id
                }
            })
        if (changed) {
            behavior.items = JSON.stringify(deserializedItems)
            ps.dal.set(behaviorPointer, behavior)
        }
    }
    _.forEach(comp.components, function (child) {
        replaceCompCodeBehaviorsRecursively(ps, child, pageId)
    })
}

function fixDuplicatedPageCodeBehaviors(ps, pageId) {
    const pagePointer = ps.pointers.page.getPagePointer(pageId)
    const pageStructurePointer = ps.pointers.getInnerPointer(pagePointer, 'structure')
    const pageStructure = ps.dal.full.get(pageStructurePointer)
    replaceCompCodeBehaviorsRecursively(ps, pageStructure, pageId)
}

function duplicatePageCodeIfRequired(ps, newPagePointer, pageId, shouldDuplicatePageCode = true) {
    if (!shouldDuplicatePageCode) {
        return
    }
    wixCode.duplicatePageCode(ps, newPagePointer, pageId)
}

export default {
    beforePageRemoveHook,
    duplicatePageCodeIfRequired,
    removePageFromPlatformApps,
    fixDuplicatedPageCodeBehaviors
}
