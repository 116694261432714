import {
    pointerUtils,
    HistoryItem,
    DocumentDataTypes,
    Extension,
    Transaction,
    CreateExtArgs,
    PointerMethods,
    ExtensionAPI
} from '@wix/document-manager-core'
import _ from 'lodash'
import {guidUtils} from '@wix/santa-core-utils'
const {getGUID: generateGUID} = guidUtils

const {getPointer} = pointerUtils

const wixCodeSharedFileStatePointerType = 'wixCodeSharedFileState'

type PathChangeListener = (changedPaths: string[]) => void

const getDocumentDataTypes = (): DocumentDataTypes => ({
    [wixCodeSharedFileStatePointerType]: {}
})

const initialState = {}

export interface WixCodeSharedFileStateExtensionAPI extends ExtensionAPI {
    wixCodeSharedFileState: {
        notifyLocalPathsChanged(changedPaths: string[]): void
        subscribeToPathChanges(listener: PathChangeListener): void
    }
}

const createExtension = (): Extension => {
    const localChangesMap = {}

    const pathChangeListeners: PathChangeListener[] = []
    const notifyChangeListeners = (changedPaths: string[]) => {
        pathChangeListeners.forEach(listener => {
            try {
                listener(changedPaths)
            } catch (e) {
                console.error(e)
            }
        })
    }

    const wixCodeSharedFileStatePointers = {
        getSharedPathChangeByFile: (fileName: string) => getPointer(fileName, wixCodeSharedFileStatePointerType)
    }

    const createPointersMethods = (): PointerMethods => ({
        wixCode: wixCodeSharedFileStatePointers
    })

    const isOnIsolatedGridApp = ({dal, pointers}: Pick<CreateExtArgs, 'dal' | 'pointers'>) =>
        dal.get(pointers.wixCode.getIsolatedGridApp())

    const createExtensionAPI = ({dal, pointers}: CreateExtArgs): WixCodeSharedFileStateExtensionAPI => ({
        wixCodeSharedFileState: {
            notifyLocalPathsChanged: (changedPaths: string[]) => {
                if (isOnIsolatedGridApp({dal, pointers})) {
                    return
                }
                const changeGuid = generateGUID()

                changedPaths.forEach(changedPath => {
                    const sharedPointer = wixCodeSharedFileStatePointers.getSharedPathChangeByFile(changedPath)
                    localChangesMap[changedPath] = changeGuid
                    dal.set(sharedPointer, {guid: changeGuid})
                })
            },

            subscribeToPathChanges: (listener: PathChangeListener) => {
                if (_.isFunction(listener)) {
                    pathChangeListeners.push((...args) =>
                        isOnIsolatedGridApp({dal, pointers}) ? null : listener(...args)
                    )
                }
            }
        }
    })

    const createPostTransactionOperations = () => ({
        wixCode: (documentTransaction: Transaction) => {
            const sharedPathChangesUpdates = documentTransaction.items.filter(
                e => e?.key?.type === wixCodeSharedFileStatePointerType
            )
            const changedPathsPerTransaction = _.compact(
                sharedPathChangesUpdates.map((item: HistoryItem) => {
                    const {
                        value: updatedSharedState,
                        key: {id: filePath}
                    } = item
                    const localValue = localChangesMap[filePath]
                    const updatedGuid = _.get(updatedSharedState, ['guid'])
                    const diffFromLocalState = localValue !== updatedGuid
                    localChangesMap[filePath] = updatedGuid
                    return diffFromLocalState ? filePath : null
                })
            )

            if (!_.isEmpty(changedPathsPerTransaction)) {
                notifyChangeListeners(changedPathsPerTransaction)
            }
        }
    })

    return {
        name: 'wixCodeSharedFileState',
        getDocumentDataTypes,
        createPointersMethods,
        initialState,
        createExtensionAPI,
        createPostTransactionOperations
    }
}

export {createExtension}
