import type {PS} from '@wix/document-services-types'
import component from '../../component/component'
import documentModeInfo from '../../documentMode/documentModeInfo'

export default function (ps: PS, compPointer, newLayout) {
    if (!newLayout.width || documentModeInfo.isMobileView(ps)) {
        return
    }

    component.properties.update(ps, compPointer, {width: Math.max(newLayout.width, 400)})
}
