import _ from 'lodash'
import moveCustomStylesToPagesFixer from './moveCustomStylesToPagesFixer'
import duplicateCustomStylesDataFixer from './duplicateCustomStylesDataFixer'
import addMissingDefaultStylesDataFixer from './addMissingDefaultStylesDataFixer'
import garbageCollectionFixer from './garbageCollectionFixer'
import rgbaSystemColorsFixer from './rgbaSystemColorsFixer'
import unknownSystemStylesFixer from './unknownSystemStylesFixer'
import styleFixerUtils from './utils'

export default {
    exec(ps) {
        unknownSystemStylesFixer.exec(ps)

        if (_.get(ps.config, 'runStylesGC')) {
            garbageCollectionFixer.exec(ps)
        }

        const wasMigrated = styleFixerUtils.isSiteMigrated(ps)
        const didRunDuplicateStylesFixer = styleFixerUtils.didRunDuplicateStylesFixer(ps)

        const shouldMigrate = ps.runtimeConfig.stylesPerPage && !wasMigrated
        const shouldRunFixer = didRunBefore =>
            ps.runtimeConfig.stylesPerPage && !didRunBefore && (shouldMigrate || wasMigrated)

        const shouldRunDuplicateStylesFixer = shouldRunFixer(didRunDuplicateStylesFixer)

        if (shouldMigrate) {
            moveCustomStylesToPagesFixer.exec(ps)
        }

        if (shouldRunDuplicateStylesFixer) {
            addMissingDefaultStylesDataFixer.exec(ps)
            duplicateCustomStylesDataFixer.exec(ps)
        }

        rgbaSystemColorsFixer.exec(ps)
    },
    name: 'stylesDataFixer',
    version: 1
}
