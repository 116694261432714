import type {PS} from '@wix/document-services-types'
import generalInfo from '../../siteMetadata/generalInfo'
import errors from '../utils/errors'
import autosave from '../../autosave/autosave'
import experiment from 'experiment-amd'
import isAppStudio from '../../appStudio/isAppStudio'

function isWixCodeSaveAllowed(ps: PS) {
    if (isAppStudio(ps) && !experiment.isOpen('dm_blockWixCodeOnDisabledSaveAndAutoSaveInBlocks')) {
        return true
    }
    const isAllowedToModifySite = ps.extensionAPI.saveState.isSaveAllowed() && autosave.isAllowed(ps)
    const isTemplate = generalInfo.isFirstSave(ps)
    return isAllowedToModifySite || isTemplate
}

function ensureWixCodeSaveAllowed(ps: PS) {
    if (!isWixCodeSaveAllowed(ps)) {
        throw new errors.WixCodeDisabledSaveError()
    }
}

export default {
    isWixCodeSaveAllowed,
    ensureWixCodeSaveAllowed
}
