/* eslint-disable promise/prefer-await-to-then */
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import requestPayloadCreator from '../utils/requestPayloadCreator'
import pathUtils from '../utils/pathUtils'
import items from './items'
import BIService from '../bi/BIService'
import {contextAdapter} from '../../utils/contextAdapter'
import * as dmUtils from '@wix/document-manager-utils'

function getPublishUrls(appInstance) {
    return [
        `${santaCoreUtils.urlUtils.origin(undefined)}/apps/appBuilder/published/${appInstance.applicationInstanceId}`
    ]
}

function getBatchUrls() {
    return [
        `${santaCoreUtils.urlUtils.origin(undefined)}/apps/appBuilder/1/editor/Batch?checkConcurrentModification=true`
    ]
}

function getSaveRepoUrls(appInstance) {
    return [
        `${santaCoreUtils.urlUtils.origin(undefined)}/apps/appBuilder/saved/${
            appInstance.applicationInstanceId
        }?checkConcurrentModification=true`
    ]
}

function getBIService(biCallbacks) {
    return new BIService(biCallbacks.event, biCallbacks.error)
}

/**
 * @returns {{resolve: function(*):void, reject: function(*):void, promise: Promise<unknown>}}
 */
function deferPromise() {
    /* suggested defer migration shim http://bluebirdjs.com/docs/api/deferred-migration.html */
    return new dmUtils.Deferred()
}

const POST = ({url, headers, dataType, data, success, error}) => {
    contextAdapter.actions.sendHttpRequest(url, 'POST', _.pickBy({headers, dataType}, _.identity), data, success, error)
}

function saveRepo(appInstance, repo, biCallbacks) {
    const bi = getBIService(biCallbacks)
    bi.beforeSavingRepo(appInstance)

    const defer = deferPromise()

    function doneCallback(responseData, err) {
        if (err || !responseData.success) {
            defer.reject({errorCode: err ? responseData : responseData.errorCode, changes: null})
            bi.errorSavingRepo(appInstance, responseData, err)
        } else {
            bi.successSavingRepo(appInstance, responseData)
            let changes = null

            if (responseData.payload.applicationInstanceVersion) {
                changes = [
                    {
                        path: pathUtils.getApplicationInstanceVersionPath(),
                        value: responseData.payload.applicationInstanceVersion
                    }
                ]
                appInstance.applicationInstanceVersion = responseData.payload.applicationInstanceVersion
            }
            defer.resolve({changes})
        }
    }

    if (!repo) {
        bi.skippedSavingRepo(appInstance)
        defer.resolve(null)
    } else {
        _(repo.dataSelectors)
            .filter(currentSelector => _.isUndefined(currentSelector.dataProviderId))
            .forEach(function (currentSelector) {
                currentSelector.dataProviderId = 'wixdb'
            })

        const request = {
            urls: getSaveRepoUrls(appInstance),
            data: _.assign(
                {
                    applicationInstanceVersion: appInstance.applicationInstanceVersion,
                    dataProviders: {wixdb: {type: 'WixDb'}},
                    tags: {},
                    pages: {}
                },
                repo
            )
        }
        // @ts-expect-error
        santaCoreUtils.requestsUtil.createAndSendRequest(request, doneCallback, POST)
    }

    return defer.promise
}

function getSuccessfulSaveChanges(results, dataItems) {
    return _(results)
        .filter('success')
        .transform(function (acc, result) {
            const itemId = result.payload.id
            const item = _.find(dataItems.created, {_iid: itemId})
            if (item) {
                acc.push({
                    path: pathUtils.getItemPath(item._type, itemId, '_state'),
                    value: items.STATES.SAVED
                })
            }
        }, [])
        .value()
}

function getPartialSuccessfulChanges(results, dataItems) {
    return _(results)
        .filter('success')
        .transform(function (acc, result) {
            const itemId = result.payload.id
            const createdItem = _.find(dataItems.created, {_iid: itemId})
            if (createdItem) {
                createdItem._state = items.STATES.SAVED
                acc.push({
                    path: pathUtils.getItemPath(createdItem._type, itemId),
                    value: createdItem
                })
                return
            }

            const deletedItemId = _.find(dataItems.deleted, itemId)
            if (deletedItemId) {
                acc.push({
                    path: pathUtils.getItemPath(createdItem._type, itemId),
                    undefined
                })
            }
        }, [])
        .value()
}

function saveItems(appInstance, dataItems, biCallbacks) {
    const bi = getBIService(biCallbacks)
    bi.beforeSavingItems(appInstance, dataItems)

    const defer = deferPromise()

    function doneCallback(responseData, err) {
        const results = _.get(responseData, ['payload', 'results'])

        if (err || !responseData.success) {
            const partialSuccessChanges = results ? getPartialSuccessfulChanges(results, dataItems) : null
            // update store
            defer.reject({errorCode: err ? responseData : responseData.errorCode, changes: partialSuccessChanges})
            bi.errorSavingItems(appInstance, responseData, err)
        } else {
            defer.resolve({changes: getSuccessfulSaveChanges(results, dataItems)})
            bi.successSavingItems(appInstance, responseData)
        }
    }

    const hasDataItemChanges = _.some(dataItems, array => array.length > 0)

    if (!hasDataItemChanges) {
        bi.skippedSavingItems(appInstance)
        defer.resolve(null)
    } else {
        const batchRequest = {
            urls: getBatchUrls(),
            data: {operations: requestPayloadCreator.getSaveItemsOperations(appInstance, dataItems)}
        }
        // @ts-expect-error
        santaCoreUtils.requestsUtil.createAndSendRequest(batchRequest, doneCallback, POST)
    }

    return defer.promise
}

function saveRepoAndItems(appInstance, repo, dataItems, biCallbacks) {
    const aggregatedChanges = []

    function collectChanges(changes) {
        if (changes) {
            aggregatedChanges.push(...changes)
        }
    }

    const defer = deferPromise()
    this.saveRepo(appInstance, repo, biCallbacks)
        .then(result => {
            collectChanges(_.get(result, ['changes']))
            return this.saveItems(appInstance, dataItems, biCallbacks)
        })
        .catch(function (error) {
            collectChanges(_.get(error, ['changes']))

            const response = {changes: aggregatedChanges, errorCode: error.errorCode}
            defer.reject(response)
        })
        .then(function (result) {
            collectChanges(_.get(result, ['changes']))
            defer.resolve({changes: aggregatedChanges})
        })
        .catch(function (error) {
            collectChanges(_.get(error, ['changes']))
            const response = {changes: aggregatedChanges, errorCode: error.errorCode}
            defer.reject(response)
        })

    return defer.promise
}

function publish(appInstance, biCallbacks) {
    const bi = getBIService(biCallbacks)

    const defer = deferPromise()

    function doneCallback(responseData, err) {
        if (err || !responseData.success) {
            defer.reject({errorCode: err ? responseData : responseData.errorCode})
            bi.errorPublish(appInstance, responseData, err)
        } else {
            defer.resolve(responseData.payload)
            bi.successPublish(appInstance)
        }
    }

    const request = {
        urls: getPublishUrls(appInstance),
        data: {}
    }

    bi.beforePublish(appInstance)
    // @ts-expect-error
    santaCoreUtils.requestsUtil.createAndSendRequest(request, doneCallback, POST)
    return defer.promise
}

export default {
    saveRepo,
    saveItems,
    saveRepoAndItems,
    publish
}
