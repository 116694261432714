import {CreateExtensionArgument, Extension, PointerMethods, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {getScopedPointer} from '../utils/scopesUtils'

const {
    getPointer,
    getInnerPointer,
    getInnerPointerPathRoot,
    getOriginalPointerFromInner,
    isPointer,
    isSameComponent,
    isSamePointer
} = pointerUtils

const createExtension = ({experimentInstance}: CreateExtensionArgument): Extension => {
    const createPointersMethods = (): PointerMethods => {
        const getPointerType = (pointer: Pointer): string => {
            switch (pointer.type) {
                case 'DESKTOP':
                case 'MOBILE':
                    return _.isEmpty(pointer.innerPath) ? 'component' : 'componentStructure'
                default:
                    return pointer.type
            }
        }
        return {
            getPointer,
            getInnerPointer,
            isPointer,
            isSamePointer: _.partial(isSamePointer, _, _, experimentInstance.isOpen('dm_disableScopesHybridMode')),
            // @ts-expect-error
            getInnerPointerPathRoot,
            getOriginalPointerFromInner,
            // @ts-expect-error
            getPointerType,

            components: {
                isSameComponent: _.partial(
                    isSameComponent,
                    _,
                    _,
                    experimentInstance.isOpen('dm_disableScopesHybridMode')
                )
            },

            scopes: {
                getScopedPointer
            }
        }
    }

    return {
        name: 'dataAccess',
        createPointersMethods
    }
}

export {createExtension}
