import type {DocumentManager} from '@wix/document-manager-core'
import {constants} from '@wix/document-manager-extensions'
import type {Experiment, Pointer} from '@wix/document-services-types'
import _ from 'lodash'

const COMPS_WITH_INVALID_DESIGN = {
    'wysiwyg.viewer.components.Column': true,
    'wysiwyg.viewer.components.PopupContainer': true,
    'wysiwyg.viewer.components.MediaContainer': true
}

const MediaContainerWithDividers = 'MediaContainerWithDividers'
const MediaContainerDesignData = 'MediaContainerDesignData'
const migratePage = (documentManager: DocumentManager, pageId: string, experimentInstance: Experiment) => {
    if (!experimentInstance.isOpen('dm_fixInvalidMediaContainerWithDividers')) {
        return
    }
    const {dal, pointers} = documentManager
    _.forEach(constants.VIEW_MODES, viewMode => {
        const comps = documentManager.pointers.structure.getAllComponentPointers(viewMode, pageId)
        _.forEach(comps, (pointer: Pointer) => {
            const {componentType, designQuery} = dal.get(pointer)
            if (COMPS_WITH_INVALID_DESIGN[componentType] && designQuery) {
                const id = _.trimStart(designQuery, '#')
                const designPointer = pointers.data.getDesignItem(id, pageId)
                const designItem = dal.get(designPointer)
                const {background, metaData, type} = designItem
                if (type === MediaContainerWithDividers) {
                    const fixedDesign = {id, metaData, type: MediaContainerDesignData} as any
                    if (background) {
                        fixedDesign.background = background
                    }
                    dal.set(designPointer, fixedDesign)
                }
            }
        })
    })
}

const name = 'fixInvalidMediaContainerWithDividers'
const version = 0
const experimentalVersions = [{experiment: 'dm_fixInvalidMediaContainerWithDividers', version: 1}]

export {migratePage, name, version, experimentalVersions}
