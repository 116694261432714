import type {CompLayout, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import componentBehaviors from '../../component/componentBehaviors'
import layoutConstraintsUtils from '../../structure/utils/layoutConstraintsUtils'
import layoutHooksUtils from './utils/layoutHooksUtils'

function getCurrentTabPointer(ps: PS, compPointer: Pointer, singleTabArr: Pointer[]) {
    const {currentTabId} = componentBehaviors.getRuntimeState(ps, compPointer)
    const singleTabDataItems = singleTabArr.map(singleTab => component.data.get(ps, singleTab))
    const tabIndex = singleTabDataItems.findIndex(data => data.tabId === currentTabId)
    const currentTab = singleTabArr[tabIndex]

    return currentTab
}

function constrainTabByContentWidth(ps: PS, singleTabPointer: Pointer, desiredLayout: Partial<CompLayout>) {
    layoutConstraintsUtils.constrainByChildrenLayout(ps, singleTabPointer, desiredLayout, false, true)
}

function constrainTabByContentHeight(ps: PS, singleTabPointer: Pointer, desiredLayout: Partial<CompLayout>) {
    layoutConstraintsUtils.constrainByChildrenLayout(ps, singleTabPointer, desiredLayout, true, false)
}

// Block Tabs component from shrinking past widest tab's width or current tab's height
function applyLayoutToTabsChildrenBeforeUpdate(
    ps: PS,
    compPointer: Pointer,
    tabsNewLayout: CompLayout,
    updateCompLayoutCallback
) {
    if (!_.isFunction(updateCompLayoutCallback)) {
        return
    }

    const tabsCurrentLayout = component.layout.get(ps, compPointer)

    if (layoutHooksUtils.isCompResizing(tabsCurrentLayout, tabsNewLayout)) {
        const yShiftInPx = layoutHooksUtils.calculateDelta(tabsNewLayout.y, tabsCurrentLayout.y)
        const xShiftInPx = layoutHooksUtils.calculateDelta(tabsNewLayout.x, tabsCurrentLayout.x)
        const isHeightChanged = tabsNewLayout.height && tabsNewLayout.height !== tabsCurrentLayout.height
        const isWidthChanged = tabsNewLayout.width && tabsNewLayout.width !== tabsCurrentLayout.width
        const {width: desiredWidth} = tabsNewLayout
        const heightDiff = tabsNewLayout.height - tabsCurrentLayout.height

        const singleTabArr = ps.pointers.components.getChildren(compPointer)
        const currentTabPointer = getCurrentTabPointer(ps, compPointer, singleTabArr)

        let newWidth

        if (isWidthChanged) {
            let minWidth = 0
            singleTabArr.forEach(singleTabPointer => {
                const {x} = component.layout.get(ps, singleTabPointer)
                const desiredLayout = {width: desiredWidth, x}
                constrainTabByContentWidth(ps, singleTabPointer, desiredLayout)
                minWidth = Math.max(minWidth, desiredLayout.width)
            })
            newWidth = Math.max(desiredWidth, minWidth)

            if (minWidth > desiredWidth) {
                // When width is constrained, reset the width to constrained width
                tabsNewLayout.width = newWidth

                /**
                 * When narrowing from the left, the component is shifted to the right by the amount we narrow
                 * Since we constrain the narrowing, we need to shift the component back by the amount of pixels that were constrained
                 */
                if (xShiftInPx > 0) {
                    tabsNewLayout.x -= minWidth - desiredWidth
                }
            }
        }

        singleTabArr.forEach(singleTabPointer => {
            const isCurrentTab = singleTabPointer.id === currentTabPointer.id

            const singleTabCurrentLayout = component.layout.get(ps, singleTabPointer)
            const singleTabNewLayout = {...singleTabCurrentLayout}

            if (isHeightChanged && isCurrentTab) {
                const {y} = singleTabCurrentLayout
                const desiredHeight = singleTabCurrentLayout.height + heightDiff
                const desiredLayout = {height: desiredHeight, y}
                constrainTabByContentHeight(ps, singleTabPointer, desiredLayout)
                singleTabNewLayout.height = desiredLayout.height
                if (singleTabNewLayout.height > desiredHeight) {
                    tabsNewLayout.height = tabsCurrentLayout.height
                    /**
                     * When shrinking from the top, the component is shifted to the bottom by the amount we shrink
                     * Since we constrain the shrinking, we need to shift the component back by the amount of pixels that were constrained
                     */
                    if (yShiftInPx > 0) {
                        tabsNewLayout.y -= singleTabNewLayout.height - desiredHeight
                    }
                }
            }

            if (isWidthChanged) {
                singleTabNewLayout.width = newWidth
            }

            updateCompLayoutCallback(ps, singleTabPointer, singleTabNewLayout)
        })
    }
}

export default {
    applyLayoutToTabsChildrenBeforeUpdate
}
