import _ from 'lodash'
import {Extension, PointerMethods, DalValue, DmApis, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import type {RelationshipsAPI} from './relationships'
import {VIEW_MODES} from '../constants/constants'
const {getPointer, getRepeatedItemPointerIfNeeded} = pointerUtils

const createPointersMethods = ({pointers, dal, extensionAPI, coreConfig}: DmApis): PointerMethods => {
    const {relationships} = extensionAPI as RelationshipsAPI
    const {siteAPI} = extensionAPI as any
    const controllerIdCache = {}
    const {experimentInstance} = coreConfig

    const getController = (pagePointer: Pointer, isMobileView: boolean, controllerDataId: string) => {
        let result
        const viewMode = isMobileView ? VIEW_MODES.MOBILE : VIEW_MODES.DESKTOP
        const dataPointer = pointers.data.getDataItem(controllerDataId, pagePointer.id)

        const owningReferences = relationships.getOwningReferencesToPointer(dataPointer, viewMode)
        if (owningReferences.length) {
            const component = owningReferences[0]

            if (pointers.components.isSameComponent(pointers.components.getPageOfComponent(component), pagePointer)) {
                result = component
                return result
            }
        }
        // getCompIdByDataQueryId is only implemented in TB
        const componentId =
            _.has(siteAPI, ['getCompIdByDataQueryId']) && siteAPI.getCompIdByDataQueryId(controllerDataId)
        if (componentId) {
            result = getPointer(componentId, viewMode)
        }

        return result
    }

    const getControllerInPageByDataId = (
        pagePointer: Pointer,
        isMobileView: boolean,
        controllerDataId: string
    ): any => {
        if (!controllerDataId) {
            return null
        }
        const cacheKey = `${pagePointer.id}-${isMobileView}-${controllerDataId}`
        const cached = controllerIdCache[cacheKey]
        if (cached && dal.has(getRepeatedItemPointerIfNeeded(cached))) {
            return cached
        }

        if (
            experimentInstance.isOpen('dm_controllerSearchImprovement') ||
            experimentInstance.isOpen('dm_widgetInRepeater')
        ) {
            const controller = getController(pagePointer, isMobileView, controllerDataId)
            if (controller) {
                controllerIdCache[cacheKey] = controller
                return controller
            }
            return null
        }

        const predicate = (comp: DalValue) => relationships.getIdFromRef(comp?.dataQuery) === controllerDataId
        const viewModePagePointer = isMobileView
            ? pointers.components.getMobilePointer(pagePointer)
            : pointers.components.getDesktopPointer(pagePointer)

        let result = pointers.components.findComponentInPage(viewModePagePointer, isMobileView, predicate)
        if (!result) {
            result = pointers.full.components.findComponentInPage(viewModePagePointer, isMobileView, predicate)
        }
        if (result) {
            controllerIdCache[cacheKey] = result
        }

        return result
    }

    return {
        platform: {
            getControllerInPageByDataId
        }
    }
}

const createExtension = (): Extension => ({
    name: 'platformWithViewer',
    dependencies: new Set(['relationships', 'structure', 'page', 'fullToDisplay']),
    createPointersMethods
})

export {createExtension}
