import type {PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import * as santaCoreUtils from '@wix/santa-core-utils'
import themeConstants from '../common/constants'
import themeDAL from '../common/themeDAL'
import textThemes from '../textThemes/textThemes'

const switchToTextTheme = () => experiment.isOpen('dm_migrateToTextTheme')
const {PROPERTY_TYPE} = themeConstants
const {fontStringToTextTheme, textThemeToFontString} = santaCoreUtils.fonts

function get(ps: PS, index) {
    return themeDAL.getCollectionItem(ps, PROPERTY_TYPE.FONT, index)
}

function getAll(ps: PS) {
    return themeDAL.getCollection(ps, PROPERTY_TYPE.FONT)
}

function set(ps: PS, index, val) {
    return themeDAL.setCollectionItem(ps, PROPERTY_TYPE.FONT, index, val)
}

function setToTextTheme(ps: PS, index, val) {
    const textTheme = fontStringToTextTheme(val)
    textThemes.set(ps, index, textTheme)
}

function getFromTextTheme(ps: PS, index) {
    const textTheme = textThemes.get(ps, index)
    return textTheme ? textThemeToFontString(textTheme) : get(ps, index)
}

function getAllFromTextTheme(ps: PS) {
    const allTextThemes = textThemes.getAll(ps)
    return allTextThemes?.length ? allTextThemes.map(textThemeToFontString) : getAll(ps)
}

export default {
    // @ts-expect-error
    set: (...args: any[]) => (switchToTextTheme() ? setToTextTheme(...args) : set(...args)),
    get: getFromTextTheme,
    getAll: getAllFromTextTheme
}
