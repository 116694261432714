import _ from 'lodash'
import {errorPages, logWixCodeConsoleMessage, logWixCodeConsoleError} from '@wix/santa-core-utils'

function handleSendStatus(isPreview, primaryPageId, resultingObj) {
    logWixCodeConsoleMessage(`Send status ${resultingObj.status} with message: ${resultingObj.message}`)
    resultingObj.sendStatus = true
    if (isPreview) {
        resultingObj.pageId = primaryPageId
    } else {
        resultingObj.pageId = errorPages.IDS.UKNOWN_ERROR
    }
}

function handleUknownError(isPreview, primaryPageId, resultingObj, status) {
    logWixCodeConsoleMessage(`Uknown error(${status})`)
    if (isPreview) {
        resultingObj.pageId = primaryPageId
    } else {
        resultingObj.pageId = errorPages.IDS.UKNOWN_ERROR
    }
}

function handleForbidden(isPreview, primaryPageId, resultingObj) {
    logWixCodeConsoleMessage(
        'Forbidden (403) - The request was a valid request, but the server is refusing to respond to it. The user might be logged in but does not have the necessary permissions for the resource.'
    )
    if (isPreview) {
        resultingObj.pageId = primaryPageId
    } else {
        resultingObj.pageId = errorPages.IDS.FORBIDDEN
    }
}

function handleRedirect(primaryPageId, redirectUrl, status, resultingObj) {
    logWixCodeConsoleMessage(`Redirected with status ${status} to URL: "${redirectUrl}"`)
    resultingObj.redirectUrl = redirectUrl
    //this is set for fallback when in preview\editor when it is not allowed to redirect
    resultingObj.pageId = primaryPageId
}

function handleNotFound(isPreview, primaryPageId, resultingObj) {
    logWixCodeConsoleMessage('Not Found (404) - The requested resource could not be found')
    if (isPreview) {
        resultingObj.pageId = primaryPageId
    } else {
        resultingObj.pageId = errorPages.IDS.NOT_FOUND
    }
}

function handleInternalError(result, isPreview, primaryPageId, resultingObj) {
    const error = new Error(result.message)
    error.name = result.name
    error.stack = result.stack
    logWixCodeConsoleError(error)

    if (isPreview) {
        resultingObj.pageId = primaryPageId
    } else {
        resultingObj.pageId = errorPages.IDS.INTERNAL_ERROR
    }
}

function checkResponse(
    routerDefinition,
    suffix,
    isPreview,
    isEditorPreview,
    primaryPageId,
    publicBaseUrl,
    onSuccess,
    routerResponse
) {
    const {
        page: pageId,
        data: pageData,
        head: pageHeadData,
        tpaInnerRoute,
        publicData,
        status,
        redirectUrl,
        message
    } = routerResponse.result

    const resultingObj: Record<string, any> = {pageId, pageData, pageHeadData, status, message}

    if (tpaInnerRoute) {
        resultingObj.tpaInnerRoute = tpaInnerRoute
    }
    if (publicData) {
        resultingObj.publicData = publicData
    }

    const statusCode = _.parseInt(status)
    if (routerResponse.exception) {
        handleInternalError(routerResponse.result, isPreview, primaryPageId, resultingObj)
    } else {
        if (redirectUrl) {
            handleRedirect(primaryPageId, redirectUrl, status, resultingObj)
        } else if (statusCode === 403) {
            handleForbidden(isPreview, primaryPageId, resultingObj)
        } else if (statusCode === 404) {
            handleNotFound(isPreview, primaryPageId, resultingObj)
        } else if (statusCode && !pageId) {
            handleSendStatus(isPreview, primaryPageId, resultingObj)
        } else if (!pageId) {
            handleUknownError(isPreview, primaryPageId, resultingObj, status)
        }
        logWixCodeConsoleMessage(message)
    }

    if ((statusCode >= 400 || routerResponse.exception) && isEditorPreview) {
        const routerSuffix = `/${suffix}`.replace(/^\/\//, '/')
        // @ts-ignore
        resultingObj.errorInfo = {
            statusCode: routerResponse.exception ? 500 : statusCode,
            routerUrl: `/${routerDefinition.prefix}${routerSuffix}`,
            publicUrl: `${publicBaseUrl}/${routerDefinition.prefix}${routerSuffix}`
        }
    }
    onSuccess(resultingObj)
}

export default {
    checkResponse
}
