import type {BaseDsData, Callback1} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import documentServicesSchemas from 'document-services-schemas'

const {DATA_TYPES} = constants
const {schemasService} = documentServicesSchemas.services

function getPropertyDefinition(schema, propertyKey: string, schemaType: string) {
    if (!schema) {
        return null
    }

    if (schema.$ref) {
        const refSchema = schemasService.getSchema(schemaType, schema.$ref)
        return getPropertyDefinition(refSchema, propertyKey, schemaType)
    }

    if (schema.properties) {
        const def = schema.properties[propertyKey]
        if (def?.$ref) {
            return schemasService.getSchema(schemaType, def.$ref)
        }
        return def
    }

    if (schema.allOf) {
        const dataType = _.find(schema.allOf, innerSchema =>
            getPropertyDefinition(innerSchema, propertyKey, schemaType)
        )
        return dataType && getPropertyDefinition(dataType, propertyKey, schemaType)
    }
}

function isOfType(schemaType: string, schemaName: string, propertyKey: string, expectedType) {
    const schema = schemasService.getSchema(schemaType, schemaName)
    return isSchemaOfType(schemaType, schema, propertyKey, expectedType)
}

const isSchemaOfType = (schemaType: string, schema, propertyKey: string, expectedType) => {
    const propertyDefinition = getPropertyDefinition(schema, propertyKey, schemaType)
    if (!propertyDefinition || _.isString(propertyDefinition)) {
        return (propertyDefinition && propertyDefinition === expectedType) || false
    }
    if (expectedType === 'list') {
        expectedType = 'array'
    }
    return (
        _.includes(propertyDefinition.pseudoType, expectedType) ||
        _.includes(propertyDefinition.type, expectedType) ||
        propertyDefinition.type === expectedType
    )
}

function executeForDataItemRefs(schemaType: string, dataItem, callback: Callback1<any>) {
    const schema = schemasService.getSchema(schemaType, dataItem.type)
    if (schema) {
        _.forOwn(dataItem, function (value, key) {
            if (isSchemaOfType(schemaType, schema, key, 'ref')) {
                return callback(value)
            } else if (isSchemaOfType(schemaType, schema, key, 'refList')) {
                return _.map(value, refItem => callback(refItem))
            }
        })
    }
}

const GET_DEFAULT_META_DATA = {
    [DATA_TYPES.prop]: () => ({schemaVersion: '1.0'}),
    DEFAULT: () => ({isPreset: false, schemaVersion: '1.0', isHidden: false})
}

function addDefaultMetaData(newDataItem: BaseDsData, itemType = 'DEFAULT') {
    const defaultMetaDataCreator = GET_DEFAULT_META_DATA[itemType] || GET_DEFAULT_META_DATA.DEFAULT
    newDataItem.metaData = _.assign(defaultMetaDataCreator(), newDataItem.metaData)
}

export default {
    isOfType,
    getPropertyDefinition,
    executeForDataItemRefs,
    addDefaultMetaData
}
