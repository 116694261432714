import {DAL, DmApis, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'
import type {
    CompRef,
    Pointer,
    PossibleViewModes,
    PS,
    PublicMethodDefinition,
    ScopePointer
} from '@wix/document-services-types'
import _ from 'lodash'
import {constants} from '..'
import type {CreateViewerExtensionArgument} from '../types'
import {
    dataRefTypes,
    isRefHostType,
    structureRefTypes,
    isRefPointer,
    getRefPointerType
} from '../utils/refStructureUtils'
import {isRepeaterType, repeaterDelimiter} from '../utils/repeaterUtils'
import type {DataModelExtensionAPI} from './dataModel'
import {
    buildScopePointerByOwner,
    getScopedPointer,
    getItemIdByInflatedId,
    buildOldInflatedId,
    removeMostOuterScope,
    getPointerReportInformation,
    isSameScopesByInflatedIds,
    extractScopeFromPointer,
    getInflatedPointer
} from '../utils/scopesUtils'

const {getInnerPointer, isPointer, getRepeatedItemPointerIfNeeded} = pointerUtils

const {DATA_TYPES, VIEW_MODES, VIEWER_PAGE_DATA_TYPES, VIEWER_DATA_TYPES} = constants
const DESKTOP = VIEW_MODES.DESKTOP as PossibleViewModes

const DISPLAYED_ONLY_TYPE_CANDIDATE = _.invert(VIEWER_DATA_TYPES)
const REFERRED_DATA_TYPES_TO_OVERRIDE_GETTERS = _.keyBy([...structureRefTypes, ...dataRefTypes])
const DATA_TYPES_TO_OVERRIDE_GETTERS = {
    ...VIEW_MODES,
    ...VIEWER_PAGE_DATA_TYPES
}

const createExtension = ({
    viewerManager,
    dsConfig,
    logger,
    experimentInstance
}: CreateViewerExtensionArgument): Extension => {
    let disableScopes = false
    let shouldReportPublicApi = false
    let reportInformation: ReportInformation | undefined

    const getDisplayedFromViewer = (pointer: Pointer, shouldDisableScopes: boolean): any => {
        return viewerManager.dal.get(pointer, !shouldDisableScopes)
    }

    const getFromViewer = (pointer: Pointer, shouldDisableScopes: boolean): any =>
        getDisplayedFromViewer(getRefPointerType(pointer), shouldDisableScopes)

    const get = (dal: DAL, pointer: Pointer) => {
        if (pointer.noRefFallbacks) {
            return dal.get(pointer)
        }

        if (isRefPointer(pointer)) {
            return getFromViewer(pointer, disableScopes) ?? dal.get(pointer)
        }

        const value = dal.get(pointer)

        if (value !== undefined) {
            return value
        }

        if (DISPLAYED_ONLY_TYPE_CANDIDATE[pointer.type]) {
            return getFromViewer(pointer, disableScopes)
        }
    }

    const getByPointerWithRefType = (dal: DAL, pointer: Pointer) => {
        if (disableScopes) {
            return getFromViewer(pointer, true)
        }
    }

    const createGetters = () => {
        return {
            ..._.mapValues(DATA_TYPES_TO_OVERRIDE_GETTERS, () => get),
            ..._.mapValues(REFERRED_DATA_TYPES_TO_OVERRIDE_GETTERS, () => getByPointerWithRefType)
        }
    }

    const createExtensionAPI = ({dal, extensionAPI}: DmApis): ScopesExtensionAPI => {
        const getCompType = (compPointer: Pointer) =>
            dal.get(getRepeatedItemPointerIfNeeded(compPointer))?.componentType

        const getDefinedScopes = (compPointer: CompRef): ScopePointer[] => {
            const componentType = getCompType(compPointer)

            if (componentType) {
                if (isRefHostType(componentType)) {
                    return [buildScopePointerByOwner(compPointer.id, compPointer.scope)]
                }

                if (dsConfig.enableRepeatersInScopes && isRepeaterType(componentType)) {
                    const {dataModel} = extensionAPI as DataModelExtensionAPI
                    const {items} = dataModel.components.getItem(compPointer, DATA_TYPES.data)
                    return items.map((itm: string) =>
                        buildScopePointerByOwner(`${compPointer.id}${repeaterDelimiter}${itm}`, compPointer.scope)
                    )
                }
            }

            return []
        }

        const hasDefinedScopes = (compPointer: CompRef): boolean => {
            const componentType = getCompType(compPointer)
            if (!componentType) {
                return false
            }

            if (dsConfig.enableRepeatersInScopes && isRepeaterType(componentType)) {
                return true
            }

            return isRefHostType(componentType)
        }

        const getScopeOwner = (scopePointer: ScopePointer, viewMode: PossibleViewModes = DESKTOP): CompRef => {
            let {id} = scopePointer

            if (dsConfig.enableRepeatersInScopes) {
                id = id.split(repeaterDelimiter, 1)[0]
            }

            const pointer = getScopedPointer(id, viewMode, _.cloneDeep(scopePointer.scope)) as CompRef
            pointer.id = buildOldInflatedId(pointer, dsConfig.enableRepeatersInScopes)
            return pointer
        }

        const getRootComponent = (scopePointer: ScopePointer, viewMode: PossibleViewModes = DESKTOP): CompRef => {
            const ownerPointer = getScopeOwner(scopePointer, viewMode)
            const ownerChildrenPointer = getInnerPointer(ownerPointer, ['components'])
            let [rootId] = getDisplayedFromViewer(ownerChildrenPointer, true)

            if (dsConfig.enableRepeatersInScopes) {
                rootId = buildOldInflatedId(
                    getScopedPointer(getItemIdByInflatedId(rootId, true), viewMode, scopePointer),
                    true
                )
            }

            return getScopedPointer(rootId, viewMode, _.cloneDeep(scopePointer)) as CompRef
        }

        const getTemplateCompPointer = (compPointer: CompRef): CompRef => {
            const scope = extractScopeFromPointer(compPointer)

            if (!scope) {
                throw new ReportableError({
                    errorType: 'COMP_NOT_IN_SCOPE',
                    message: 'Component is not in a scope',
                    extras: {
                        compPointer
                    }
                })
            }

            const {id, type} = compPointer
            const pointer = getScopedPointer(
                getItemIdByInflatedId(id, dsConfig.enableRepeatersInScopes),
                type,
                scope && removeMostOuterScope(scope)
            ) as CompRef
            pointer.id = buildOldInflatedId(pointer, dsConfig.enableRepeatersInScopes)
            return pointer
        }

        const getTemplateRoot = (scopePointer: ScopePointer, viewMode: PossibleViewModes = DESKTOP): CompRef =>
            getTemplateCompPointer(getRootComponent(scopePointer, viewMode))

        const getScopesList = (compPointer: CompRef): ScopePointer[] => {
            const scopes = []
            let scope = extractScopeFromPointer(compPointer)

            while (scope) {
                scopes.push(_.cloneDeep(scope))
                scope = scope.scope
            }

            return scopes
        }

        const getComponentInScope = (compPointer: CompRef, scopes: ScopePointer | ScopePointer[]): CompRef => {
            const scopesList = Array.isArray(scopes) ? scopes : [scopes]
            const scope: ScopePointer | undefined = scopesList.reverse().reduce(
                (outerScopePointer: ScopePointer | undefined, scopePointer: ScopePointer) => ({
                    id: scopePointer.id,
                    type: 'scope',
                    ...(outerScopePointer && {scope: outerScopePointer})
                }),
                undefined
            )

            const scopedPointer = {
                ..._.omit(compPointer, 'scope'),
                ...(scope && {scope})
            }

            return getInflatedPointer(scopedPointer, dsConfig.enableRepeatersInScopes)
        }

        const getScopeWithPredicate = (
            compPointer: CompRef,
            predicate: Function = () => true
        ): ScopePointer | undefined => {
            let {scope} = compPointer

            while (scope && !predicate(scope)) {
                scope = scope.scope
            }

            return scope
        }

        const getScopesPointerConsideringHybrid = (id: string, type: string, scope?: ScopePointer) =>
            getScopedPointer(id, type, scope, disableScopes)

        const setShouldReportPublicApi = (value: boolean): void => {
            shouldReportPublicApi = value
        }

        const getReportInformation = (): ReportInformation | undefined => reportInformation

        const getInflatedId = (ptr: Pointer): string => {
            if (!ptr.scope) {
                return ptr.id
            }

            return getInflatedPointer(ptr as CompRef, dsConfig.enableRepeatersInScopes).id
        }

        const unhandledPublicApiAntiSpamSet = new Set()

        const wrapReportablePublicApiMethod = (apiDefinition: PublicMethodDefinition, methodPath: string): Function => {
            if (!experimentInstance.isOpen('dm_addScopesAffectedPublicApisBI')) {
                return apiDefinition.method
            }

            return (ps: PS, ...args: any[]) => {
                if (unhandledPublicApiAntiSpamSet.has(methodPath)) {
                    return apiDefinition.method(ps, ...args)
                }

                try {
                    setShouldReportPublicApi(true)

                    const result = apiDefinition.method(ps, ...args)

                    if (reportInformation) {
                        unhandledPublicApiAntiSpamSet.add(methodPath)

                        const {extension, functionName, sourcePointer, targetPointer} = reportInformation
                        logger.captureError(
                            new ReportableError({
                                errorType: 'SCOPES_UNHANDLED_PUBLIC_API',
                                message: 'An affected public api was found (ignore)',
                                extras: {
                                    publicApi: methodPath,
                                    extension,
                                    functionName,
                                    source: getPointerReportInformation(dal, sourcePointer),
                                    target: getPointerReportInformation(dal, targetPointer),
                                    args: args
                                        .filter(arg => isPointer(arg, true))
                                        .map(pointer => getPointerReportInformation(dal, pointer))
                                }
                            })
                        )
                    }

                    return result
                } finally {
                    setShouldReportPublicApi(false)
                    reportInformation = undefined
                }
            }
        }

        const reportUnhandledPublicApi = (
            extension: string,
            functionName: string,
            sourcePointer: Pointer,
            targetPointer: Pointer
        ) => {
            if (
                shouldReportPublicApi &&
                targetPointer &&
                !isSameScopesByInflatedIds(sourcePointer.id, targetPointer.id)
            ) {
                reportInformation = {
                    extension,
                    functionName,
                    sourcePointer,
                    targetPointer
                }
            }
        }

        const wrapMethodWithDisableScopes = (method: Function): Function => {
            if (experimentInstance.isOpen('dm_disableScopesHybridMode')) {
                return method
            }

            return (...args: any[]) => {
                try {
                    disableScopes = true
                    return method(...args)
                } finally {
                    disableScopes = false
                }
            }
        }

        return {
            scopes: {
                load: () => Promise.resolve(),
                unload: () => Promise.resolve(),
                isLoaded: () => true,
                extractScopeFromPointer,
                hasDefinedScopes,
                getDefinedScopes,
                getRootComponent,
                getScopeOwner,
                getTemplateCompPointer,
                getTemplateRoot,
                getScopesList,
                getComponentInScope,
                getScopeWithPredicate,
                getScopesPointerConsideringHybrid,
                getInflatedId,
                getDisplayedFromViewer: (pointer: Pointer) =>
                    getDisplayedFromViewer(pointer, !dsConfig.enableScopes || disableScopes),
                setShouldReportPublicApi,
                getReportInformation,
                wrapReportablePublicApiMethod,
                reportUnhandledPublicApi,
                wrapMethodWithDisableScopes
            }
        }
    }

    const extension: Extension = {
        name: 'scopes',
        dependencies: new Set([]),
        createExtensionAPI
    }

    if (dsConfig.enableScopes) {
        // @ts-expect-error
        extension.createGetters = createGetters
    }

    return extension
}

export interface ReportInformation {
    extension: string
    functionName: string
    sourcePointer: Pointer
    targetPointer: Pointer
}

export interface ScopesExtensionAPI extends ExtensionAPI {
    scopes: {
        load(scopePointer: ScopePointer): Promise<void>
        unload(scopePointer: ScopePointer): Promise<void>
        isLoaded(scopePointer: ScopePointer): boolean
        extractScopeFromPointer(pointer: Pointer): ScopePointer | undefined
        hasDefinedScopes(compPointer: CompRef): boolean
        getDefinedScopes(compPointer: CompRef): ScopePointer[]
        getRootComponent(scopePointer: ScopePointer, viewMode?: PossibleViewModes): CompRef
        getScopeOwner(scopePointer: ScopePointer, viewMode?: PossibleViewModes): CompRef
        getTemplateCompPointer(compPointer: CompRef): CompRef
        getTemplateRoot(scopePointer: ScopePointer, viewMode?: PossibleViewModes): CompRef
        getScopesList(compRef: CompRef): ScopePointer[]
        getComponentInScope(compPointer: CompRef, scope: ScopePointer): CompRef
        getComponentInScope(compPointer: CompRef, scopes: ScopePointer[]): CompRef
        getScopeWithPredicate(compPointer: CompRef, predicate: Function): ScopePointer | undefined
        getScopesPointerConsideringHybrid(id: string, type: string, scope?: ScopePointer): Pointer
        getInflatedId(ptr: Pointer): string
        getDisplayedFromViewer(pointer: Pointer): any
        setShouldReportPublicApi(value: boolean): void
        getReportInformation(): ReportInformation | undefined
        wrapReportablePublicApiMethod(apiDefinition: PublicMethodDefinition, methodPath: string): Function
        reportUnhandledPublicApi(
            extension: string,
            functionName: string,
            sourcePointer: Pointer,
            targetPointer: Pointer
        ): void
        wrapMethodWithDisableScopes(method: Function): Function
    }
}

export {createExtension}
