import {getAllSerializedCustomDefinitions, getBlocksComponentNickname, getData} from './blocksDataModel'
import _ from 'lodash'
import {CreateExtArgs, pointerUtils} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import {VIEW_MODES} from '../../constants/constants'
import {displayedOnlyStructureUtil, eventsMap} from '@wix/santa-core-utils'
import type {ActionsAndBehaviorsExtensionAPI} from '../actionsAndBehaviors'
//@ts-expect-error
import jsonSchemaUtils from '@wix/wix-json-schema-utils'

const {getRepeaterTemplateId, isRepeatedComponent} = displayedOnlyStructureUtil
const {getPointer} = pointerUtils

const createPropertiesDescriptor = (createExtArgs: CreateExtArgs, propertiesSchemas: any) => {
    const customDefinitions = _.assign({}, ...getAllSerializedCustomDefinitions(createExtArgs))
    const resolver = jsonSchemaUtils.createResolver(jsonSchemaUtils.baseDefinitions, customDefinitions)
    return (
        _(propertiesSchemas)
            .map(propSchema => resolver.resolve(propSchema.structure))
            // @ts-expect-error
            .thru(resolvedProperty => _.assign(...resolvedProperty))
            .toPairs()
            .map(([name, {default: defaultValue}]: any) => ({
                name,
                defaultValue
            }))
            .value()
    )
}

const toTemplateItemPointer = (compPointer: Pointer) => {
    const isRepeated = isRepeatedComponent(compPointer.id)
    if (isRepeated) {
        return getPointer(getRepeaterTemplateId(compPointer.id), compPointer.type)
    }
    return compPointer
}

const getChildrenForStaticEvents = (createExtArgs: CreateExtArgs, rootCompId: string) => {
    const {pointers} = createExtArgs
    const comps = pointers.structure.getChildrenRecursively(getPointer(rootCompId, VIEW_MODES.DESKTOP))

    return _(comps)
        .map(compRef => toTemplateItemPointer(compRef))
        .uniqBy('id')
        .value()
}

const pascalCase = (str: string) => _.upperFirst(_.camelCase(str))

const createCustomEventName = (name: string) => `on${pascalCase(name)}`

// returns event name based on eventsMap from santa-core-utils if exists, and creates a dynamic name for custom events that are not in the map
const getEventFromAction = (actionName: string) => {
    const eventName = eventsMap[actionName]

    return eventName || createCustomEventName(actionName)
}

const getChildrenStaticEvents = (createExtArgs: CreateExtArgs, rootCompId: string) => {
    const {actionsAndBehaviors} = createExtArgs.extensionAPI as ActionsAndBehaviorsExtensionAPI

    const comps = getChildrenForStaticEvents(createExtArgs, rootCompId)
    const compsStaticEventDefinitions = _.flatMap(comps, comp => {
        const role = getBlocksComponentNickname(createExtArgs, comp)
        const behaviors = actionsAndBehaviors.getBehaviors(comp)
        return _(behaviors)
            .filter(behavior => actionsAndBehaviors.isCodeBehavior(behavior.behavior))
            .map(({action, behavior}) => ({
                role,
                event: getEventFromAction(action.name),
                callbackId: behavior.params.callbackId
            }))
            .value()
    })

    return compsStaticEventDefinitions
}

export const createPublicDescriptor = (createExtArgs: CreateExtArgs, widgetPointer: Pointer) => {
    const widgetData = getData(createExtArgs, widgetPointer)

    return {
        functions: _.map(widgetData.widgetApi.functions, ({name}) => ({name})),
        events: _.map(widgetData.widgetApi.events, ({name}) => ({name})),
        properties: createPropertiesDescriptor(createExtArgs, widgetData.widgetApi.propertiesSchemas),
        behaviors: getChildrenStaticEvents(createExtArgs, _.trimStart(widgetData.rootCompId, '#'))
    }
}
