import type {PS} from '@wix/document-services-types'
import disabledWixCodeSave from './disabledWixCodeSave'
import fileSystemService from './fileSystemService'
import fileSystemAPI from './fileSystemAPI'
import wixCodeLifecycleService from './wixCodeLifecycleService'
import clientSpecMapUtils from '../utils/clientSpecMapUtils'
import wixCodeMonitoring from './wixCodeMonitoringWrapper'

const parseNetworkError = async (response: Response) => {
    let details
    try {
        const resObj = await response.json()
        details = resObj.message
    } catch {
        details = response.statusText
    }
    console.error('network error details:', {response, details})
    return details
}

const genEndpointUrl = (ps: PS, path: string) => {
    const baseDomain = '/wix-code-user-code-dependencies'
    const gridAppId = ps.extensionAPI.wixCode.getEditedGridAppId()
    return `${baseDomain}/v1/apps/${gridAppId}/${path}`
}

const genRequestOptions = (ps: PS, options) => {
    const spec = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
    const {instance} = spec

    return {
        credentials: 'include',
        headers: {
            'x-wix-si': instance,
            authorization: instance,
            'Content-Type': 'application/json'
        },
        ...options
    }
}

const fetchRequest = async (ps: PS, path: string, options = {}) => {
    const response = await fetch(genEndpointUrl(ps, path), genRequestOptions(ps, options))
    if (response.status !== 200) {
        const err = await parseNetworkError(response)
        throw new Error(err)
    }
    return response
}

const installCodeReusePkg = async (ps: PS, appDefId: string, pkgVersion: string) => {
    const traceEnd = wixCodeMonitoring.trace(ps, {
        action: 'codePackage.installCodeReusePkg',
        message: {
            appDefId,
            pkgVersion
        }
    })
    const result = await fetchRequest(ps, 'packages', {
        method: 'POST',
        body: JSON.stringify({appDefId, version: pkgVersion})
    })
    traceEnd({message: {appDefId}})
    return result
}

const installNpmPkg = async (ps: PS, packageName: string, pkgVersion: string) => {
    const traceEnd = wixCodeMonitoring.trace(ps, {
        action: 'codePackage.installNpmPkg',
        message: {
            packageName,
            pkgVersion
        }
    })
    const result = await fetchRequest(ps, 'packages/npm', {
        method: 'POST',
        body: JSON.stringify({packageName, version: pkgVersion})
    })
    traceEnd({message: {packageName}})
    return result
}

const getNpmPkgs = async (ps: PS) => {
    const response = await fetchRequest(ps, 'packages?dependencyFilter.codePackageType=NPM')
    const {dependencies} = await response.json()
    return {
        dependencies: dependencies.reduce((agg, val) => {
            agg[val.packageName] = val.version
            return agg
        }, {})
    }
}

const getCodeReusePkgs = async (ps: PS) => {
    const response = await fetchRequest(ps, 'packages?dependencyFilter.codePackageType=WIX')
    const {dependencies} = await response.json()
    return {
        wixCodeDependencies: dependencies.reduce((agg, val) => {
            const {wixCodeMetadata} = val
            agg[val.packageName] = {
                version: val.version,
                backEndPackage: wixCodeMetadata.npmBackEndPackageName,
                frontEndPackage: wixCodeMetadata.npmFrontEndPackageName,
                gridAppId: wixCodeMetadata.sourceCodeGridAppId,
                id: wixCodeMetadata.packageId
            }
            return agg
        }, {})
    }
}

const uninstallNpmPkg = async (ps: PS, packageName: string) => {
    const traceEnd = wixCodeMonitoring.trace(ps, {
        action: 'codePackage.uninstallNpmPkg',
        message: {packageName}
    })
    const result = await fetchRequest(ps, `packages/npm/${packageName}`, {
        method: 'DELETE'
    })

    traceEnd({message: {packageName}})
    return result
}

const uninstallCodeReusePkg = async (ps: PS, appDefId: string) => {
    const traceEnd = wixCodeMonitoring.trace(ps, {
        action: 'codePackage.uninstallCodeReusePkg',
        message: {appDefId}
    })
    const result = await await fetchRequest(ps, `packages/velo/${appDefId}`, {
        method: 'DELETE'
    })

    traceEnd({message: {appDefId}})
    return result
}

const informOtherBrowserOfPkgChange = (ps: PS, files) => {
    fileSystemService.notifyLocalPathsChanged(ps.extensionAPI, files)
}

const informLocalBrowserOfPkgChange = async (ps: PS, files) => fileSystemAPI.handleExternalChange(ps, files)

const wrapPkgChangeAction =
    callback =>
    async (ps: PS, ...args) => {
        disabledWixCodeSave.ensureWixCodeSaveAllowed(ps)
        await wixCodeLifecycleService.ensureAppIsWriteable(ps)

        await callback(ps, ...args)

        const backend = fileSystemService.getRoots().backend.location
        const pkgJsonLocation = `${backend}wix-code-package.json`
        const pkgsConfigLocation = `${backend}___config___/`
        const locationsToNotify = [backend, pkgJsonLocation, pkgsConfigLocation]
        informOtherBrowserOfPkgChange(ps, locationsToNotify)
        informLocalBrowserOfPkgChange(ps, locationsToNotify)
    }

export default {
    installCodeReusePkg: wrapPkgChangeAction(installCodeReusePkg),
    installNpmPkg: wrapPkgChangeAction(installNpmPkg),
    uninstallNpmPkg: wrapPkgChangeAction(uninstallNpmPkg),
    uninstallCodeReusePkg: wrapPkgChangeAction(uninstallCodeReusePkg),
    getNpmPkgs,
    getCodeReusePkgs
}
