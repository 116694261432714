import _ from 'lodash'
import listsDS from './lists'
import typesDS from './types'
import viewsDS from './views'
import itemsDS from './items'
import selectionDS from './selection'

const ERROR_ONLY_MANUAL_LISTS_SUPPORTED = 'Only manual lists are supported'
const ERROR_LIST_DOES_NOT_EXIST = 'List does not exist'
const ERROR_TYPE_DOES_NOT_EXIST = 'Type does not exist'
const ERROR_LIST_VIEWS_DO_NOT_EXIST = 'List views do not exist'
const ERROR_DATA_SELECTOR_DOES_NOT_EXIST = 'Data selector does not exist'
const ERROR_TEMPLATE_TYPE_IS_NOT_VALID = "Template's type definition is not valid"
const ERROR_TEMPLATE_VIEWS_ARE_NOT_VALID = "Template's view definitions are not valid"

const DEFAULT_LIST_DISPLAY_NAME = 'New List'

function throwError(errorMessage) {
    throw new Error(errorMessage)
}

function omitPrivateFields(objects) {
    return _.mapValues(objects, function (obj) {
        return _.omitBy(obj, function (value, key) {
            return key !== '_iid' && _.startsWith(key, '_')
        })
    })
}

function modifyViewsType(views, typeId) {
    _.forEach(views, function (view) {
        view.forType = view.forType === 'Array' ? 'Array' : typeId
    })
}

function createViews(ps, views) {
    const newViewIds = viewsDS.createViewsWithSameName(ps, views)
    return viewsDS.getViewById(ps, newViewIds[0]).name
}

function createItemsWithType(ps, items, typeId) {
    return _.transform(
        items,
        function (acc, item) {
            const newItemId = itemsDS.createItem(ps, typeId, item)
            acc[item._iid || newItemId] = newItemId
        },
        {}
    )
}

function createManualDataSelector(ps, typeId, itemIds) {
    const newDataSelectorId = selectionDS.createSelector(ps, typeId)
    selectionDS.setManualSelector(ps, newDataSelectorId, itemIds)
    return newDataSelectorId
}

/**
 * Generate a preset template from an existing list
 * @param {ps} ps Private Services
 * @param {string} listId
 * @returns {{type: *, views: Object[], items: Object[], displayName: (string)}}
 * @throws Throws an error if the list does not exist
 * @throws Throws an error if the list is not manual
 * @throws Throws an error if the list does not have views
 * @throws Throws an error if the list's type does not exist
 */
function generateTemplate(ps, listId) {
    const list = listsDS.getListDef(ps, listId) || throwError(ERROR_LIST_DOES_NOT_EXIST)
    const dataSelector: any = listsDS.getSelector(ps, listId) ?? throwError(ERROR_DATA_SELECTOR_DOES_NOT_EXIST)
    if (dataSelector.type !== 'ManualSelectedList') {
        throw new Error(ERROR_ONLY_MANUAL_LISTS_SUPPORTED)
    }
    const listViews = listsDS.getViews(ps, listId)
    if (_.isEmpty(listViews)) {
        throwError(ERROR_LIST_VIEWS_DO_NOT_EXIST)
    }

    const typeDef = listsDS.getType(ps, listId)
    return {
        type: typeDef || throwError(ERROR_TYPE_DOES_NOT_EXIST),
        views: _.values(listViews),
        items: omitPrivateFields(itemsDS.getAllItemsOfType(ps, typeDef.name)),
        dataSelector,
        displayName: list.displayName || DEFAULT_LIST_DISPLAY_NAME
    }
}

function createDataSelectorFromTemplate(ps, dataSelector, newItemIdsMap, newTypeId) {
    const itemIdsMap = dataSelector ? _.pick(newItemIdsMap, dataSelector.itemIds) : newItemIdsMap
    const selectedItemIds = _.values(itemIdsMap)
    return createManualDataSelector(ps, newTypeId, selectedItemIds)
}

/**
 * Create a new list according to a given template
 * @param {ps} ps Private Services
 * @param {{type: *, views: Object[], items: Object[], displayName: string, dataSelector:*}} template
 * @returns {string} The created list ID
 * @throws Throws an error if no type is defined
 * @throws Throws an error if no views are defined
 */
function createListFromTemplate(ps, template) {
    if (_.isEmpty(template.type)) {
        throw new Error(ERROR_TEMPLATE_TYPE_IS_NOT_VALID)
    }
    if (_.isEmpty(template.views)) {
        // TODO: check for real validity of each view ?
        throw new Error(ERROR_TEMPLATE_VIEWS_ARE_NOT_VALID)
    }

    const views = _.map(template.views, templateView => _.clone(templateView))

    const newTypeId = typesDS.createType(ps, template.type)
    modifyViewsType(views, newTypeId)
    const newViewName = createViews(ps, views)
    const newItemIdsMap = createItemsWithType(ps, template.items, newTypeId)
    const newDataSelectorId = createDataSelectorFromTemplate(ps, template.dataSelector, newItemIdsMap, newTypeId)

    const listPartDef = {
        displayName: template.displayName,
        dataSelector: newDataSelectorId,
        type: newTypeId,
        viewName: newViewName
    }

    return listsDS.createList(ps, listPartDef)
}

export default {
    generateTemplate,
    createListFromTemplate
}
