import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import hoverToStateReactionFixer from './fixers/hoverToStateReactionFixer'

function fix(ps: PS) {
    const dsDataFixersFedOpsTags = constants.INTERACTIONS.DOCUMENT_SERVICES_DATA_FIXERS
    const fixers = [
        {fixer: hoverToStateReactionFixer, config: {fixMobileOnly: false, fedopsTag: 'hoverToStateReactionFixer'}}
    ]

    ps.extensionAPI.logger.interactionStarted(dsDataFixersFedOpsTags.ALL)
    _.forEach(fixers, function (fixerData) {
        const fedopsTag = _.get(fixerData, ['config', 'fedopsTag'])
        ps.extensionAPI.logger.interactionStarted(fedopsTag)
        fixerData.fixer.exec(ps)
        ps.extensionAPI.logger.interactionEnded(fedopsTag)
    })
    ps.extensionAPI.logger.interactionEnded(dsDataFixersFedOpsTags.ALL)
}

export default {
    fix
}
