import _ from 'lodash'
import pathUtils from '../utils/pathUtils'
import stringUtils from '../utils/stringUtils'

const FIELD_TYPE_ORDER = {
    String: 1,
    'wix:Date': 2,
    'wix:RichText': 3,
    'String|AsPrice': 4,
    'wix:Image': 5,
    'wix:Video': 6,
    'String|AsButton': 7
}

const typeNameUnderscoreFormat = /(.*)_\d+$/

/**
 * Performs an IN-PLACE sort the given type definition's fields, according to the FIELD_TYPE_ORDER map (title field will always be first).
 * @param typeDef
 */
function reOrderFields(typeDef) {
    if (typeDef?.fields) {
        typeDef.fields = _.sortBy(typeDef.fields, function (field) {
            if (field.name === 'title') {
                return Number.MIN_VALUE
            }
            return FIELD_TYPE_ORDER[getFieldTypeKey(field)] || Number.MAX_VALUE
        })
    }
}

/**
 * @param field
 * @returns {string} returns a field key built from its type and showAs metdata (for example: String|AsButton)
 */
function getFieldTypeKey(field) {
    const showAsHint = field.metadata?.showAsHint
    return _.compact([field.type, showAsHint]).join('|')
}

function getType(ps, typeId) {
    const type = ps.wixappsDAL.getByPath(pathUtils.getTypePath(typeId))
    reOrderFields(type)
    return type
}

function getAllTypes(ps) {
    const allTypes = ps.wixappsDAL.getByPath(pathUtils.getBaseTypesPath())
    _.forEach(allTypes, reOrderFields)
    return allTypes
}

function addPathsForTypeItems(ps, typeId) {
    ps.wixappsDAL.setByPath(pathUtils.getBaseItemsPath(typeId), {})
}

function getMaxSuffixIndex(typeName, allTypesNames) {
    const regex = new RegExp(`${typeName}_(\\d+)$`)
    const maxSuffixOfTypeName = _(allTypesNames)
        .map(function (name) {
            const match = regex.exec(name)
            return match ? _.parseInt(match[1]) : null
        })
        .concat(0)
        .max()

    return maxSuffixOfTypeName + 1
}

function generateTypeName(ps, typeName) {
    const match = typeNameUnderscoreFormat.exec(typeName)
    const baseTypeName = match ? match[1] : typeName
    const allTypesNames = ps.wixappsDAL.getKeysByPath(pathUtils.getBaseTypesPath())
    const maxSuffixIndex = getMaxSuffixIndex(baseTypeName, allTypesNames)
    return `${baseTypeName}_${maxSuffixIndex}`
}

function createType(ps, typeDef) {
    typeDef = typeDef || {}
    const newType: any = {}
    newType.name = generateTypeName(ps, typeDef.name || 'type')
    newType.displayName = replaceWithUniqueDisplayName(ps, typeDef.displayName || newType.name)
    newType.version = 0
    newType.baseTypes = typeDef.baseTypes || []
    newType.fields = typeDef.fields || []
    ps.wixappsDAL.setByPath(pathUtils.getTypePath(newType.name), newType)
    addPathsForTypeItems(ps, newType.name)
    return newType.name
}

function replaceWithUniqueDisplayName(ps, originalDisplayName) {
    const existingDisplayNames = _.map(getAllTypes(ps), 'displayName')
    let uniqueDisplayName = originalDisplayName
    while (_.includes(existingDisplayNames, uniqueDisplayName)) {
        uniqueDisplayName = stringUtils.incNumberSuffix(uniqueDisplayName)
    }
    return uniqueDisplayName
}

export default {
    /**
     * Returns a type definition
     * @param {PS} ps Private Services
     * @param {string} id Type definition ID
     * @returns {Object} The type definition object with the requested ID
     */
    getType,

    /**
     * Returns all type definitions
     * @param {PS} ps Private Services
     * @returns {Object} The type definitions
     */
    getAllTypes,

    /**
     * Create a new type according to a given type definition
     * @param {PS} ps Private Services
     * @param {Object} typeDef
     * @returns {string} the new type's ID
     */
    createType
}
