import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import {pointerUtils} from '@wix/document-manager-core'
import {displayedOnlyStructureUtil} from '@wix/santa-core-utils'
import dataModel from '../dataModel/dataModel'
import constants from '../constants/constants'
import componentStructureInfo from '../component/componentStructureInfo'
import experiment from 'experiment'

const {REPEATER_TYPES, DATA_TYPES} = constants
const {getUniqueDisplayedId, isRepeatedComponent, isRefPointer, getRepeaterItemId} = displayedOnlyStructureUtil

const isRepeater = (ps: PS, compPointer: Pointer) =>
    componentStructureInfo.getType(ps, compPointer) === REPEATER_TYPES.REPEATER

const getRepeaterItems = (ps: PS, compPointer: Pointer) => {
    const repeaterPointer = ps.pointers.components.getAncestorByPredicate(compPointer, parentPointer =>
        isRepeater(ps, parentPointer)
    )
    return _.get(dataModel.getDataItem(ps, repeaterPointer), ['items'], [])
}

const addRepeatedItemsDataOverridesIfNeeded = (
    ps: PS,
    compPointer: Pointer,
    newDataItemId: string,
    dataItem,
    pageId: string,
    dataSetter: (ps: PS, pageId: string, dataItem, dataId: string) => void
) => {
    const isRepeatedItem = isRepeatedComponent(compPointer.id)
    const repeaterItems = isRepeatedItem && getRepeaterItems(ps, compPointer)
    const dataIdsToHandle = isRepeatedItem
        ? _.map(repeaterItems, itemId => getUniqueDisplayedId(newDataItemId, itemId))
        : []
    _.forEach([newDataItemId, ...dataIdsToHandle], dataId => {
        dataSetter(ps, pageId, dataItem, dataId)
    })
}

export const shouldAddRepeatedData = (ps: PS, compPointer: Pointer, templateDataPointer: Pointer) => {
    const ignoreTemplateData = experiment.isOpen('dm_repeaterDontAddRepeatedTemplateData')
    const ignoreRefComponents = experiment.isOpen('dm_repeaterDontAddRepeatedRefCompData')
    const templateCompData = templateDataPointer && ps.dal.get(templateDataPointer)
    if (!templateCompData) {
        return false
    }

    const isRefComponent = isRefPointer(compPointer)
    const byRefDataPointer = ps.pointers.referredStructure.getPointerWithoutFallbacks(templateDataPointer)
    const hasByRefDataOverrides = byRefDataPointer && !!ps.dal.get(byRefDataPointer)

    const hasCompData = !isRefComponent && !!templateCompData
    return (isRefComponent && hasByRefDataOverrides && !ignoreRefComponents) || (hasCompData && !ignoreTemplateData)
}

const addDisplayedDataItemsByType = (
    ps: PS,
    compPointer: Pointer,
    newParentPointer: Pointer,
    pageId: string,
    dataType: string
) => {
    const templatePointer = pointerUtils.getRepeatedItemPointerIfNeeded(compPointer)
    const templateDataPointer = dataModel.getComponentDataPointerByType(ps, templatePointer, dataType)
    const templateCompData = templateDataPointer && dataModel.getDataByPointer(ps, dataType, templateDataPointer, true)
    const allDisplayedContainers = ps.pointers.components.getAllDisplayedOnlyComponents(newParentPointer)

    if (shouldAddRepeatedData(ps, compPointer, templateDataPointer)) {
        _.forEach(allDisplayedContainers, displayedContainerPointer => {
            const itemId = getRepeaterItemId(displayedContainerPointer.id)
            const newDisplayedDataItemId = templateDataPointer && getUniqueDisplayedId(templateDataPointer.id, itemId)
            dataModel.addSerializedItemToPage(ps, pageId, templateCompData, newDisplayedDataItemId, dataType)
        })
    }
}

function addDisplayedDataItemsForAllChildren(
    ps: PS,
    compPointer: Pointer,
    newParentPointer: Pointer,
    pageId: string,
    children: Pointer[]
) {
    const pointersToUpdate = [compPointer].concat(children)

    _.forEach(pointersToUpdate, function (pointer) {
        addDisplayedDataItemsByType(ps, pointer, newParentPointer, pageId, DATA_TYPES.data)
        addDisplayedDataItemsByType(ps, pointer, newParentPointer, pageId, DATA_TYPES.design)
    })
}

export default {
    isRepeater,
    getRepeaterItems,
    shouldAddRepeatedData,
    addRepeatedItemsDataOverridesIfNeeded,
    addDisplayedDataItemsForAllChildren
}
