import _ from 'lodash'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'

function addRegularGetterFunctionsToNameSpace(namespace, pathsCache, regularCache) {
    const pointersGenerators = pointerGeneratorsRegistry.getRegularJsonGenerators()
    _.forEach(pointersGenerators, function (functions, name) {
        namespace[name] = _.mapValues(functions, function (func) {
            // @ts-ignore
            return func.bind(functions, pathsCache.getItemInPath, regularCache)
        })
    })
}

function addBothRegularAndFullFunctionsToNameSpace(namespace, getWrappedGetterFunction) {
    const bothRegularAndFullPointerGenerators = pointerGeneratorsRegistry.getBothRegularAndFullJsonGenerators()
    _.forEach(bothRegularAndFullPointerGenerators, function (functions, name) {
        namespace[name] = _.mapValues(functions, function (func) {
            return getWrappedGetterFunction(func, false, functions)
        })

        namespace.full[name] = _.mapValues(functions, function (func) {
            return getWrappedGetterFunction(func, true, functions)
        })
    })
}

function displayedJson(pathsCache) {
    this.full = {}

    const regularCache = pathsCache.getBoundCacheInstance(false)
    regularCache.flat = true
    const fullCache = pathsCache.getBoundCacheInstance(true)
    fullCache.flat = false

    const getWrappedGetterFunction = function (func, isFull, functions) {
        return function () {
            const args = _.toArray(arguments)
            const regularArgs = [pathsCache.getItemInPath, regularCache].concat(args)
            const fullArgs = [pathsCache.fullJsonGetItemInPath, fullCache].concat(args)
            const regularResult = func.apply(functions, regularArgs)
            const fullResult = func.apply(functions, fullArgs)
            return isFull ? fullResult : regularResult
        }
    }

    addRegularGetterFunctionsToNameSpace(this, pathsCache, regularCache)
    addBothRegularAndFullFunctionsToNameSpace(this, getWrappedGetterFunction)
}

function DataAccessPointers(pathsCache?) {
    if (pathsCache) {
        displayedJson.call(this, pathsCache)
    }
}

function getOriginalPointerFromInner(innerPointer) {
    if (innerPointer) {
        return {
            type: innerPointer.type,
            id: innerPointer.id
        }
    }
}

DataAccessPointers.prototype = {
    getGeneralTheme() {},

    getInnerPointer(pointer, innerPath) {
        const newPointer = getOriginalPointerFromInner(pointer)
        if (newPointer) {
            const additionalPath = _.isString(innerPath) ? innerPath.split('.') : innerPath
            // @ts-ignore
            newPointer.innerPath = pointer.innerPath ? pointer.innerPath.concat(additionalPath) : additionalPath
        }
        return newPointer
    },

    isSamePointer(pointer1, pointer2) {
        return pointer1 && pointer2 && pointer1.type === pointer2.type && pointer1.id === pointer2.id
    },

    getInnerPointerPathRoot(innerPointer) {
        return innerPointer.innerPath ? innerPointer.innerPath[0] : null
    },

    getOriginalPointerFromInner,

    getPointerType(pointer) {
        switch (pointer.type) {
            case 'DESKTOP':
            case 'MOBILE':
                return _.isEmpty(pointer.innerPath) ? 'component' : 'componentStructure'
            default:
                return pointer.type
        }
    }
}

export default DataAccessPointers
