import {PublishSiteRCRequest, RCLabel} from '@wix/ambassador-wix-html-editor-webapp/types'
import type {ExtensionAPI, SnapshotDal} from '@wix/document-manager-core'
import type {CSaveApi} from '@wix/document-manager-extensions'
import type {UpdateSiteDTO} from '@wix/document-manager-extensions/src/extensions/csave/continuousSave'
import type {SchemaExtensionAPI} from '@wix/document-manager-extensions/src/extensions/schema/schema'
import {asyncAttempt, ReportableError} from '@wix/document-manager-utils'
import * as documentServicesJsonSchemas from '@wix/document-services-json-schemas'
import type {Pointers} from '@wix/document-services-types'
import {guidUtils} from '@wix/santa-core-utils'
import {deepClone} from '@wix/wix-immutable-proxy'
import experiment from 'experiment-amd'
import _ from 'lodash'
import biErrors from '../../bi/errors'
import biEvents from '../../bi/events'
import constants from '../../constants/constants'
import editorServerFacade from '../../editorServerFacade/editorServerFacade'
import semanticAppVersionsCleaner from '../../metaSiteProvisioner/semanticAppVersionsCleaner'
import appStoreService from '../../tpa/services/appStoreService'
import permissionsUtils from '../../tpa/utils/permissionsUtils'
import {contextAdapter} from '../../utils/contextAdapter'
import getGridAppForRevision from '../../wixCode/services/getGridAppForRevision'
import wixCodeConstants from '../../wixCode/utils/constants'
import {createFromSnapshotDiff} from '../appServiceData'
import cloneWithoutAdditionalProperties from '../cloneWithoutAdditionalProperties'
import type {BICallbacks} from '../createSaveAPI'
import extractDataDeltaFromSnapshotDiff from '../extractDataDeltaFromSnapshotDiff'
import monitoring from '../monitoring'
import saveDataFixer from '../saveDataFixer/saveDataFixer'
import {convertHttpError} from '../saveErrors'
import {revisionPath, revisionPointer, shouldSaveDiff, versionPath, versionPointer} from '../snapshotDalSaveUtils'
import {
    addDevSiteAppDefIdToResult,
    addWixCodeFirstSaveGridAppToResult,
    addWixCodeSavedGridAppToResult,
    createDocumentForFirstSave,
    createErrorObject,
    createErrorObjectFromRestException,
    FIRST_SAVE_CRAPPY_DATA_TYPE_PROPERTY_NAME,
    FIRST_SAVE_CRAPPY_TYPE_TO_REMOVE,
    getHistoryAlteringChanges,
    isValidationError,
    SaveResult
} from './saveDocumentBase'

interface PublishOptions {
    editorOrigin?: string
    viewerName?: string
}

interface PublishSiteRCOptions extends PublishOptions {
    publishRC?: boolean
    label?: RCLabel
}

type PublishAllOptions = PublishSiteRCOptions & {
    publishTestSite?
    specificPages?
}

const TASK_NAME = 'saveDocument'
const STRUCTURE_DATA_TYPES = _.values(constants.VIEW_MODES)
const {PAGE_DATA_DATA_TYPES, MULTILINGUAL_TYPES, COMP_DATA_QUERY_KEYS} = constants

const {
    namespaceMapping: {NAMESPACE_MAPPING, OVERRIDE_NAMESPACES}
} = documentServicesJsonSchemas

const pageDataTypeToKey = _.invert(NAMESPACE_MAPPING)

const getTranslationInfoFromKey = (key: string) => _.split(key, '^')

const previousDiffIdPath = ['documentServicesModel', 'autoSaveInfo', 'previousDiffId']

const changedPageTypes = [...STRUCTURE_DATA_TYPES, ..._.keys(PAGE_DATA_DATA_TYPES)]

const isPageComponent = (type: string, comp) => type === 'DESKTOP' && comp.type === 'Page'

const getChangedPagesFromSnapshotDal = (diff, lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal) => {
    const updatedPageIdSet = new Set<string>()
    const deletedPageIdsSet = new Set<string>()
    const addedPageIdsSet = new Set<string>()

    for (const type of changedPageTypes) {
        for (const [id, comp] of Object.entries(diff[type] || {})) {
            const wasDeleted = comp === undefined
            if (wasDeleted) {
                const prevComp = lastSnapshotDal.getValue({type, id})
                const {pageId} = prevComp.metaData
                if (isPageComponent(type, prevComp)) {
                    deletedPageIdsSet.add(pageId)
                } else {
                    updatedPageIdSet.add(pageId)
                }
            } else {
                const wasAdded = !lastSnapshotDal?.exists({type, id})
                // @ts-expect-error
                const {pageId} = comp.metaData
                if (wasAdded && isPageComponent(type, comp)) {
                    addedPageIdsSet.add(pageId)
                } else {
                    updatedPageIdSet.add(pageId)
                }
            }
        }
    }

    _.forEach(diff.multilingualTranslations, (value, key) => {
        const [pageId] = getTranslationInfoFromKey(key)
        updatedPageIdSet.add(pageId)
    })

    const updatedPageIds: string[] = [...updatedPageIdSet].filter(pageId => {
        if (pageId && !addedPageIdsSet.has(pageId) && !deletedPageIdsSet.has(pageId)) {
            // Filter out pages that were inserted because of garbage components from deleted pages (for example in the mobileHints namespace)
            return currentSnapshotDal.exists({type: 'DESKTOP', id: pageId})
        }
        return false
    })
    const deletedPageIds: string[] = _.compact([...deletedPageIdsSet])
    const addedPageIds: string[] = _.compact([...addedPageIdsSet])

    return {
        updatedPageIds,
        deletedPageIds,
        addedPageIds
    }
}

const cleanComponentMetaData = component => {
    const sig = _.get(component, ['metaData', 'sig'])
    if (sig) {
        component.metaData = {sig}
    } else {
        delete component.metaData
    }
}

const getComponentsRecursively = (snapshotDal: SnapshotDal, type: string, id: string) => {
    const component = deepClone(snapshotDal.getValue({type, id}))
    cleanComponentMetaData(component)
    delete component.parent
    const children = component.components
    if (children) {
        component.components = children.map(childId => getComponentsRecursively(snapshotDal, type, childId))
    }
    return component
}

const getPageStructure = (snapshotDal: SnapshotDal, pageId: string) => {
    const rootComponent = getComponentsRecursively(snapshotDal, 'DESKTOP', pageId)
    const mobilePageComp = _.clone(snapshotDal.getValue({type: 'MOBILE', id: pageId}))
    rootComponent.mobileComponents = mobilePageComp
        ? getComponentsRecursively(snapshotDal, 'MOBILE', pageId).components
        : []
    if (mobilePageComp) {
        cleanComponentMetaData(mobilePageComp)
        rootComponent.mobileMetaData = mobilePageComp.metaData
    }
    if (pageId === 'masterPage') {
        rootComponent.children = rootComponent.components
        delete rootComponent.components
    }
    return rootComponent
}

const masterPageProperties = [
    'children',
    'mobileComponents',
    'type',
    'layout',
    'modes',
    'metaData',
    'mobileMetaData',
    'componentType',
    'variantsQuery',
    'id'
].concat(Object.values(COMP_DATA_QUERY_KEYS))

function extractUpdatedPagesSnapshotDal(lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal, diff) {
    const {updatedPageIds, deletedPageIds, addedPageIds} = getChangedPagesFromSnapshotDal(
        diff,
        lastSnapshotDal,
        currentSnapshotDal
    )
    const changedPageIds: string[] = _.concat(updatedPageIds, addedPageIds)
    const updatedPages = changedPageIds
        .filter(pageId => pageId !== 'masterPage')
        .map(pageId => getPageStructure(currentSnapshotDal, pageId))

    const masterPage = _.includes(changedPageIds, 'masterPage')
        ? _.pick(getPageStructure(currentSnapshotDal, 'masterPage'), masterPageProperties)
        : undefined

    return {
        updatedPages,
        masterPage,
        deletedPageIds
    }
}

function getSiteMetaData(snapshotDal: SnapshotDal) {
    const siteMetaData = deepClone(snapshotDal.getValue({type: 'rendererModel', id: 'siteMetaData'}))
    const customHeadTags = deepClone(snapshotDal.getValue({type: 'documentServicesModel', id: 'customHeadTags'}))
    return (
        _(siteMetaData)
            .omit(['adaptiveMobileOn'])
            // @ts-expect-error
            .merge({headTags: customHeadTags || ''})
            .value()
    )
}

function getSiteMetaDataWithoutAdditionalProperties(snapshot) {
    return cloneWithoutAdditionalProperties('siteMetaData', getSiteMetaData(snapshot))
}

function extractSiteMetaDataIfChanged(lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal) {
    const oldSiteMetaData = getSiteMetaData(lastSnapshotDal)
    const currentSiteMetaData = getSiteMetaData(currentSnapshotDal)
    if (!_.isEqual(oldSiteMetaData, currentSiteMetaData)) {
        return cloneWithoutAdditionalProperties('siteMetaData', currentSiteMetaData)
    }
}

function isAdaptiveMobileOn(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue({type: 'rendererModel', id: 'siteMetaData', innerPath: 'adaptiveMobileOn'})
}

function getSiteName(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue({type: 'documentServicesModel', id: 'siteName'})
}

function needToAddSiteName(currentSnapshotDal: SnapshotDal) {
    return currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'isDraft'})
}

function getMetaSiteData(snapshotDal: SnapshotDal) {
    const metaSiteData = deepClone(snapshotDal.getValue({type: 'documentServicesModel', id: 'metaSiteData'}))
    metaSiteData.adaptiveMobileOn = isAdaptiveMobileOn(snapshotDal)
    if (needToAddSiteName(snapshotDal)) {
        metaSiteData.siteName = getSiteName(snapshotDal)
    }
    return cloneWithoutAdditionalProperties('metaSiteData', metaSiteData)
}

function extractMetaSiteDataIfChanged(lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal, diff) {
    const {documentServicesModel} = diff
    const metaSiteDataChanged = documentServicesModel?.hasOwnProperty('metaSiteData')
    const isAdaptiveMobileChanged = isAdaptiveMobileOn(lastSnapshotDal) !== isAdaptiveMobileOn(currentSnapshotDal)
    const needToSendSiteName = needToAddSiteName(currentSnapshotDal)
    if (metaSiteDataChanged || isAdaptiveMobileChanged || needToSendSiteName) {
        return getMetaSiteData(currentSnapshotDal)
    }
}

function getProtectedPagesData(lastSnapshotDal: SnapshotDal, diff) {
    const rendererModelDiff = diff.rendererModel
    const currentPageToHashedPasswordMap =
        rendererModelDiff && deepClone(_.get(rendererModelDiff, ['pageToHashedPassword', 'pages']))
    if (currentPageToHashedPasswordMap) {
        const lastPageToHashedPasswordMap = lastSnapshotDal?.getValue({
            type: 'rendererModel',
            id: 'pageToHashedPassword',
            innerPath: ['pages']
        })
        if (lastPageToHashedPasswordMap) {
            return _.pickBy(
                currentPageToHashedPasswordMap,
                (newHash, pageId) => newHash !== lastPageToHashedPasswordMap[pageId]
            )
        }
        return currentPageToHashedPasswordMap
    }
    return {}
}

// In case no changes - server expected to get undefined
// In case of changes - server expect to get an object
function getRouters(diff) {
    const rendererModelDiff = diff.rendererModel
    if (rendererModelDiff?.hasOwnProperty('routers')) {
        const {routers} = rendererModelDiff
        if (routers) {
            return cloneWithoutAdditionalProperties('routers', routers)
        }
        // in case of undo/redo or revision history
        return {}
    }
}

function getPlatformApplications(diff) {
    const platformApplicationsDiff = diff.pagesPlatformApplications
    if (platformApplicationsDiff) {
        return deepClone(platformApplicationsDiff.pagesPlatformApplications)
    }
    return undefined
}

function getPermanentDataNodesToDelete(snapshotDal: SnapshotDal) {
    return deepClone(snapshotDal.getValue({type: 'save', id: 'orphanPermanentDataNodes'})) || []
}

function getSiteId(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue({type: 'rendererModel', id: 'siteInfo', innerPath: 'siteId'})
}

function getRevision(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue(revisionPointer)
}

function getVersion(snapshotDal: SnapshotDal) {
    return snapshotDal.getValue(versionPointer)
}

async function getWixCodeAppData(lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal, bi) {
    const gridAppIdForRevision = await getGridAppForRevision.runUsingSnapshotDal(currentSnapshotDal, bi)

    const [type, id, ...innerPath] = wixCodeConstants.paths.REVISION_GRID_APP_ID
    const previousRevisionGridAppId = lastSnapshotDal?.getValue({type, id, innerPath})

    if (gridAppIdForRevision && previousRevisionGridAppId !== gridAppIdForRevision) {
        return {
            codeAppId: gridAppIdForRevision
        }
    }
}

const getTranslationsDeltaFromDiff = (
    diff
): {
    translationsDelta?: Record<string, any>
    deletedTranslations: Record<string, string[]>
    changedTranslationPageIds: string[]
} => {
    const translationsDelta: Record<string, any> = {}
    /**
     * ts expression: {[lang: string]: string[]}
     * @example
     * {
     *   "lang-0": [
     *     "item-0",
     *     "item-1"
     *   ],
     *   "lang-1": [
     *     "item-0",
     *     "item-1"
     *   ]
     * }
     */
    const deletedTranslations: Record<string, string[]> = {}
    const changedTranslationPageIds = new Set<string>()
    _.forEach(diff.multilingualTranslations, (value, key) => {
        const [pageId, languageCode, id] = getTranslationInfoFromKey(key)

        if (value === undefined) {
            // removed translations
            const translationDataItemsToRemove = _.get(deletedTranslations, [languageCode], []).concat(id)
            _.setWith(deletedTranslations, [languageCode], translationDataItemsToRemove, Object)
            return
        }

        _.setWith(
            translationsDelta,
            [languageCode, 'data', 'document_data', id],
            cloneWithoutAdditionalProperties(id, value),
            Object
        )
        changedTranslationPageIds.add(pageId)

        // The translation of page is showing in both the masterPage and actual page.
        // Need to make sure the server loads both, otherwise there is a mismatch
        if (value.type === 'Page') {
            changedTranslationPageIds.add(id)
            changedTranslationPageIds.add('masterPage')
        }
    })
    return {
        translationsDelta: _.isEmpty(translationsDelta) ? undefined : translationsDelta,
        deletedTranslations,
        changedTranslationPageIds: Array.from(changedTranslationPageIds)
    }
}

const createBaseDataToSave = async (
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    diff,
    extensionsAPI,
    bi: BICallbacks
): Promise<UpdateSiteDTO> => {
    const dataToSave: UpdateSiteDTO = {
        lastTransactionId: currentSnapshotDal.lastTransactionId,
        protectedPagesData: getProtectedPagesData(lastSnapshotDal, diff),
        dataNodeIdsToDelete: getPermanentDataNodesToDelete(currentSnapshotDal),
        id: getSiteId(currentSnapshotDal),
        revision: getRevision(currentSnapshotDal),
        version: getVersion(currentSnapshotDal),
        routers: getRouters(diff),
        initiator: getInitiator(currentSnapshotDal),
        wixCodeAppData: await getWixCodeAppData(lastSnapshotDal, currentSnapshotDal, bi),
        pagesPlatformApplications: getPlatformApplications(diff),
        branchId: currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'branchId'}),
        signatures: getSignaturesMap(diff)
    }
    if (extensionsAPI.continuousSave.isCEditOpen()) {
        dataToSave.cedit = true
    }
    return dataToSave
}

const getSignaturesMap = diff =>
    _.pickBy({
        'rendererModel.routers': _.get(diff, ['rendererModel', 'routers', 'metaData', 'sig']),
        'rendererModel.siteMetaData': _.get(diff, ['rendererModel', 'siteMetaData', 'metaData', 'sig']),
        'rendererModel.wixCodeModel': _.get(diff, ['rendererModel', 'wixCodeModel', 'metaData', 'sig']),
        'documentServicesModel.metaSiteData': _.get(diff, ['documentServicesModel', 'metaSiteData', 'metaData', 'sig'])
    })

const getSignaturesMapFromSnapshotDal = (snapshotDal: SnapshotDal) =>
    _.pickBy({
        'rendererModel.routers': _.get(snapshotDal.getValue({type: 'rendererModel', id: 'routers'}), [
            'metaData',
            'sig'
        ]),
        'rendererModel.siteMetaData': _.get(snapshotDal.getValue({type: 'rendererModel', id: 'siteMetaData'}), [
            'metaData',
            'sig'
        ]),
        'rendererModel.wixCodeModel': _.get(snapshotDal.getValue({type: 'rendererModel', id: 'wixCodeModel'}), [
            'metaData',
            'sig'
        ]),
        'documentServicesModel.metaSiteData': _.get(
            snapshotDal.getValue({type: 'documentServicesModel', id: 'metaSiteData'}),
            ['metaData', 'sig']
        )
    })

const reportIgnoredDeletions = (isFull: boolean, ignoredDeletions) => {
    if (ignoredDeletions.length < 1) {
        return
    }
    const saveName = isFull ? 'fullSave' : 'partialSave'
    const message = `ignoredDeletions from ${saveName}`
    const err = new ReportableError({
        message,
        errorType: 'ignoredDeletions',
        tags: {
            saveName
        },
        extras: {
            ignoredDeletions
        }
    })
    contextAdapter.utils.fedopsLogger.captureError(err)
}

/**
 * @typedef {object} PartialSaveOptions
 * @property {boolean} [settleInServer]
 * @property {string} [viewerName]
 * @property {string} [initiatorOrigin]
 * @property {string} [editorOrigin]
 */

/**
 * @param bi
 * @param {PartialSaveOptions} o
 * @param lastSnapshotDal
 * @param currentSnapshotDal
 * @param extensionsAPI
 * @returns {Promise<{}>}
 */
const createPartialDataToSave = async (
    bi: BICallbacks,
    {settleInServer, viewerName, initiatorOrigin} = {
        settleInServer: undefined,
        viewerName: undefined,
        initiatorOrigin: ''
    },
    lastSnapshotDal?: SnapshotDal,
    currentSnapshotDal?: SnapshotDal,
    extensionsAPI?
): Promise<UpdateSiteDTO> => {
    const diff = currentSnapshotDal.diff(lastSnapshotDal)

    const {
        changedData,
        deletedData,
        deletedDataForSave,
        changedDataPageIds,
        deletedDataPageIds,
        deletedDataPageIdsForSave,
        ignoredDeletions
    } = extractDataDeltaFromSnapshotDiff(diff, lastSnapshotDal, currentSnapshotDal, extensionsAPI)
    reportIgnoredDeletions(false, ignoredDeletions)

    const {updatedPages, masterPage, deletedPageIds} = extractUpdatedPagesSnapshotDal(
        lastSnapshotDal,
        currentSnapshotDal,
        diff
    )

    const {deletedTranslations, translationsDelta, changedTranslationPageIds} = getTranslationsDeltaFromDiff(diff)
    const pageIdsWithChangedData = _(changedDataPageIds)
        .concat(deletedDataPageIdsForSave)
        .concat(changedTranslationPageIds)
        .uniq()
        .value()

    const nodeIdsToDelete = _.mapValues(deletedDataForSave, _.keys)
    const base = await createBaseDataToSave(lastSnapshotDal, currentSnapshotDal, diff, extensionsAPI, bi)
    const dataToSave: UpdateSiteDTO & {
        dataDelta: any
        nodeIdsToDelete: any
        deletedPageIds: any
        masterPage: any
        updatedPages: any
        siteMetaData: any
        metaSiteData: any
        initiatorOrigin: string
        translationsDelta: any
        pageIdsWithChangedData: any
        viewerName: string
        metaSiteActions?: any
    } = {
        ...base,
        dataDelta: changedData,
        nodeIdsToDelete,
        deletedPageIds,
        masterPage,
        updatedPages,
        siteMetaData: extractSiteMetaDataIfChanged(lastSnapshotDal, currentSnapshotDal),
        metaSiteData: extractMetaSiteDataIfChanged(lastSnapshotDal, currentSnapshotDal, diff),
        initiatorOrigin: initiatorOrigin || '',
        translationsDelta,
        pageIdsWithChangedData,
        viewerName
    }

    if (!_.isEmpty(deletedTranslations)) {
        _.assign(dataToSave, {translationsToDelete: deletedTranslations})
    }

    if (settleInServer) {
        const isMasterPageUpdated =
            _.includes(changedDataPageIds, 'masterPage') || _.includes(deletedDataPageIds, 'masterPage')
        const appStoreServiceData = createFromSnapshotDiff(
            diff,
            lastSnapshotDal,
            currentSnapshotDal,
            changedData.document_data,
            deletedData.document_data,
            isMasterPageUpdated
        )
        const shouldAvoidRevoking = await permissionsUtils.shouldAvoidRevoking({snapshotDal: currentSnapshotDal})
        const actions = appStoreService.getSettleActionsForSave(appStoreServiceData, shouldAvoidRevoking)
        if (!_.isEmpty(actions)) {
            dataToSave.metaSiteActions = _.omitBy(
                {
                    actions,
                    maybeBranchId: appStoreServiceData.branchId
                },
                _.isNil
            )
        }
    }

    saveDataFixer.fixData(dataToSave, {lastSnapshotDal, currentSnapshotDal, bi})

    return _.omitBy(dataToSave, value => _.isNil(value)) as UpdateSiteDTO
}

function getInitiator(currentSnapshotDal: SnapshotDal) {
    if (getAutosaveInfo(currentSnapshotDal, 'autoFullSaveFlag')) {
        return 'auto_save'
    } else if (getPublishSaveInitiator(currentSnapshotDal)) {
        return 'publish'
    } else if (getSilentSaveInitiator(currentSnapshotDal)) {
        return 'provision'
    }
    return 'manual'
}

const getAutosaveInfo = (snapshotDal: SnapshotDal, key) =>
    snapshotDal.getValue({type: 'documentServicesModel', id: 'autoSaveInfo', innerPath: key})
const getPublishSaveInitiator = (snapshotDal: SnapshotDal) =>
    snapshotDal.getValue({type: 'save', id: 'publishSaveInitiator'})
const getSilentSaveInitiator = (snapshotDal: SnapshotDal) =>
    snapshotDal.getValue({type: 'save', id: 'silentSaveInitiator'})

async function createFullDataToSave(currentSnapshotDal: SnapshotDal, options: any = {}, extensionsAPI?, bi?) {
    const diff = currentSnapshotDal.toJS()
    const {changedData, deletedData, changedDataPageIds, deletedDataPageIds, ignoredDeletions} =
        extractDataDeltaFromSnapshotDiff(diff, null, currentSnapshotDal, extensionsAPI)
    reportIgnoredDeletions(true, ignoredDeletions)
    const {deletedTranslations, translationsDelta} = getTranslationsDeltaFromDiff(diff)
    const {masterPage, updatedPages} = extractUpdatedPagesSnapshotDal(null, currentSnapshotDal, diff)
    const base = await createBaseDataToSave(null, currentSnapshotDal, diff, extensionsAPI, bi)
    const dataToSave: UpdateSiteDTO & {
        dataDelta: any
        deletedPageIds: any[]
        masterPage: any
        updatedPages: any
        siteMetaData: any
        metaSiteData: any
        initiatorOrigin: string
        metaSiteActions?: any
    } = {
        ...base,
        dataDelta: changedData,
        deletedPageIds: [],
        masterPage,
        updatedPages,
        siteMetaData: getSiteMetaDataWithoutAdditionalProperties(currentSnapshotDal),
        metaSiteData: getMetaSiteData(currentSnapshotDal),
        initiatorOrigin: _.get(options, 'initiatorOrigin', '')
    }
    if (!_.isEmpty(translationsDelta)) {
        _.assign(dataToSave, {translationsDelta})
    }
    if (!_.isEmpty(deletedTranslations)) {
        _.assign(dataToSave, {translationsToDelete: deletedTranslations})
    }

    saveDataFixer.fixData(dataToSave, {lastSnapshotDal: null, currentSnapshotDal})

    if (options.settleInServer) {
        const isMasterPageUpdated =
            _.includes(changedDataPageIds, 'masterPage') || _.includes(deletedDataPageIds, 'masterPage')
        const appStoreServiceData = createFromSnapshotDiff(
            diff,
            null,
            currentSnapshotDal,
            changedData.document_data,
            deletedData.document_data,
            isMasterPageUpdated
        )
        const shouldAvoidRevoking = await permissionsUtils.shouldAvoidRevoking({snapshotDal: currentSnapshotDal})
        dataToSave.metaSiteActions = _.omitBy(
            {
                // @ts-expect-error
                actions: appStoreService.getSettleActionsForFullSave(appStoreServiceData, shouldAvoidRevoking),
                maybeBranchId: appStoreServiceData.branchId
            },
            _.isNil
        )
    }

    return _.omitBy(dataToSave, value => _.isNil(value))
}

function convertFullSaveToFirstSaveDto(fullSaveDTO, snapshotDal: SnapshotDal) {
    const dataMapsForFirstSave = _(fullSaveDTO.dataDelta)
        .mapKeys((delta, dataMapName) => FIRST_SAVE_CRAPPY_DATA_TYPE_PROPERTY_NAME[dataMapName] || dataMapName)
        .omit(FIRST_SAVE_CRAPPY_TYPE_TO_REMOVE)
        .value()

    const data: any = {
        documents: [createDocumentForFirstSave(fullSaveDTO)],
        protectedPagesData: fullSaveDTO.protectedPagesData,
        siteMetaData: fullSaveDTO.siteMetaData,
        metaSiteData: fullSaveDTO.metaSiteData,
        pagesPlatformApplications: fullSaveDTO.pagesPlatformApplications,
        sourceSiteId: fullSaveDTO.id,
        targetName: snapshotDal.getValue({type: 'documentServicesModel', id: 'siteName'}),
        wixCodeAppData: fullSaveDTO.wixCodeAppData,
        urlFormat: snapshotDal.getValue({type: 'urlFormatModel', id: 'format'}),
        initiator: fullSaveDTO.initiator,
        initiatorOrigin: fullSaveDTO.initiatorOrigin,
        ...dataMapsForFirstSave,
        signatures: getSignaturesMapFromSnapshotDal(snapshotDal)
    }
    if (fullSaveDTO.routers) {
        data.routers = fullSaveDTO.routers
    }
    data.translations = fullSaveDTO.translationsDelta
    return data
}

function createFirstSaveResultObject(firstSaveResponsePayload, currentSnapshotDal: SnapshotDal) {
    const clientSpecMap = deepClone(currentSnapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'}))
    const usedMetaSiteNames = deepClone(
        currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'usedMetaSiteNames'})
    )

    const rendererModelChanges = [
        {
            path: ['rendererModel', 'siteInfo', 'siteId'],
            value: firstSaveResponsePayload.previewModel.siteId
        },
        {
            path: ['rendererModel', 'metaSiteId'],
            value: firstSaveResponsePayload.previewModel.metaSiteModel.metaSiteId
        },
        {
            path: ['rendererModel', 'mediaAuthToken'],
            value: firstSaveResponsePayload.mediaAuthToken
        },
        {
            path: ['rendererModel', 'premiumFeatures'],
            value: firstSaveResponsePayload.previewModel.metaSiteModel.premiumFeatures
        },
        {
            path: ['rendererModel', 'siteInfo', 'documentType'],
            value: firstSaveResponsePayload.previewModel.metaSiteModel.documentType
        },
        {
            path: ['rendererModel', 'clientSpecMap'],
            value: _.mergeWith(
                clientSpecMap,
                firstSaveResponsePayload.previewModel.metaSiteModel.clientSpecMap,
                function (oldVal, newVal) {
                    if (!newVal.demoMode || !oldVal || oldVal.demoMode) {
                        // We would like to merge the new value only if:
                        //  1. It is not in demo mode
                        //  2. There is no old value
                        //  3. The old value is not in demo mode
                        return _.merge({}, oldVal, newVal)
                    }
                    return oldVal
                }
            )
        }
    ]

    const documentServicesModelChanges = [
        {
            path: ['documentServicesModel', 'neverSaved'],
            value: false
        },
        {
            path: ['documentServicesModel', 'siteName'],
            value: firstSaveResponsePayload.previewModel.metaSiteModel.siteName
        },
        {
            path: ['documentServicesModel', 'metaSiteData'],
            value: firstSaveResponsePayload.metaSiteData
        },
        {
            path: ['documentServicesModel', 'publicUrl'],
            value: firstSaveResponsePayload.publicUrl
        },
        {
            path: ['documentServicesModel', 'usedMetaSiteNames'],
            value:
                usedMetaSiteNames.push(firstSaveResponsePayload.publicUrl.match(/^.*\/([^\/]+)/)[1]) &&
                usedMetaSiteNames
        },
        {
            path: versionPath,
            value: firstSaveResponsePayload.siteHeader.version
        },
        {
            path: revisionPath,
            value: firstSaveResponsePayload.siteHeader.revision
        },
        {
            path: ['documentServicesModel', 'autoSaveInfo'],
            value: firstSaveResponsePayload.autoSaveInfo
        },
        {
            path: ['documentServicesModel', 'mediaManagerInfo', 'siteUploadToken'],
            value: firstSaveResponsePayload.mediaSiteUploadToken
        },
        {
            path: ['documentServicesModel', 'permissionsInfo'],
            value: firstSaveResponsePayload.permissionsInfo
        },
        {
            path: ['documentServicesModel', 'hasSites'],
            value: true
        }
    ]
    const {deleted} = firstSaveResponsePayload
    const itemsToDelete = addPagesOfItemsToDelete(deleted, currentSnapshotDal)

    const resultObject = {
        changes: rendererModelChanges
            .concat(documentServicesModelChanges)
            .concat({path: ['orphanPermanentDataNodes'], value: []}),
        historyAlteringChanges: getHistoryAlteringChanges(itemsToDelete)
    }

    addWixCodeFirstSaveGridAppToResult(firstSaveResponsePayload, resultObject)

    addDevSiteAppDefIdToResult(firstSaveResponsePayload, resultObject)

    return resultObject
}

const saveEndpoints = [editorServerFacade.ENDPOINTS.OVERRIDE_SAVE, editorServerFacade.ENDPOINTS.PARTIAL_SAVE]

function cleanupData(dataDelta, extensionsAPI, conservativeRemoval: boolean) {
    const remappedDataDelta = _.mapKeys(dataDelta, (v, sns) => _.findKey(NAMESPACE_MAPPING, k => k === sns))

    const dataDeltaAfterRemovals = (extensionsAPI as SchemaExtensionAPI).schemaAPI.removeWhitelistedPropertiesSafely(
        remappedDataDelta,
        conservativeRemoval
    )
    const restrictedDataDelta = _.mapKeys(dataDeltaAfterRemovals, (v, sns) => NAMESPACE_MAPPING[sns])
    return restrictedDataDelta
}

const semverPattern = /.*(\d+\.(\d+)\.\d+)$/

function generateSaveLogMarkers(useRadicalWhitelistAtMonitoring: boolean) {
    const logsMarkers: any = {
        sessionStartTime: window.performance?.timing?.navigationStart,
        sessionLength: window.performance?.now() / 1000,
        whitelist: useRadicalWhitelistAtMonitoring ? 'radical' : 'conservative'
    }
    if (semverPattern.test(window.dmBase)) {
        const minorVersion = window.dmBase?.replace(semverPattern, '$2')
        logsMarkers.dmVersion = _.toNumber(minorVersion) || window.dmBase
    }
    return logsMarkers
}

/**
 * cleans up data delta contents according the whitelist (instead of ajv additional properties removal)
 * use radical model for monitoring and conservative for actual removal
 * @param endpoint
 * @param data
 * @param extensionsAPI
 */
function cleanupDataDeltaContents(endpoint: string, data, extensionsAPI: ExtensionAPI) {
    const useRadicalWhitelistAtMonitoring = experiment.isOpen('dm_radicalWhitelistBasedDataDeltaCleanup')
    if (saveEndpoints.includes(endpoint)) {
        const conservativeDataDelta = cleanupData(data.dataDelta, extensionsAPI, true)
        data.dataDelta = conservativeDataDelta

        if (data.translationsDelta) {
            data.translationsDelta = _.forEach(data.translationsDelta, multilingualDataDelta => {
                multilingualDataDelta.data = cleanupData(multilingualDataDelta.data, extensionsAPI, true)
            })
        }

        data.logsMarkers = generateSaveLogMarkers(useRadicalWhitelistAtMonitoring)

        if (useRadicalWhitelistAtMonitoring) {
            const restrictedDataDelta = cleanupData(data.dataDelta, extensionsAPI, false)
            data.restrictedDataDelta = restrictedDataDelta
        }
    }
}

const sendRequestAsyncWrapped = async (
    endpoint: string,
    data,
    snapshotDal: SnapshotDal,
    editorOrigin: string,
    extensionsAPI
) => {
    let response
    try {
        response = await sendRequestAsync(endpoint, data, snapshotDal, editorOrigin, extensionsAPI)
    } catch (e: any) {
        throw await convertHttpError(e)
    }
    if (response.success) {
        return response
    }
    throw createErrorObject(response)
}

const sendRestRequestAsyncWrapped = async (
    endpoint: string,
    data,
    snapshotDal: SnapshotDal,
    editorOrigin: string,
    extensionsAPI
) => {
    try {
        return await sendRequestAsync(endpoint, data, snapshotDal, editorOrigin, extensionsAPI)
    } catch (e) {
        throw await createErrorObjectFromRestException(e)
    }
}

const sendAndReport = async (endpoint: string, data, snapshotDal: SnapshotDal, editorOrigin: string) => {
    monitoring.start(monitoring.SERVER_SAVE)
    const result = await editorServerFacade.sendWithSnapshotDalAsync(snapshotDal, endpoint, data, editorOrigin)
    monitoring.end(monitoring.SERVER_SAVE)
    return result
}

async function sendRequestAsync(
    endpoint: string,
    data,
    snapshotDal: SnapshotDal,
    editorOrigin: string,
    extensionsAPI: ExtensionAPI
) {
    const editorSessionId = snapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'})
    cleanupDataDeltaContents(endpoint, data, extensionsAPI)

    const ds_recordActionsToCompareWithRC = experiment.isOpen('ds_recordActionsToCompareWithRC')
    const ds_runActionsToCompareWithRC = experiment.isOpen('ds_runActionsToCompareWithRC')
    if (ds_recordActionsToCompareWithRC || ds_runActionsToCompareWithRC) {
        let result
        if (ds_recordActionsToCompareWithRC) {
            try {
                await window.documentServices.debug.trace.upload()
            } catch (e) {
                // ignore
            }
            result = await sendAndReport(endpoint, data, snapshotDal, editorOrigin)
        }
        if (ds_runActionsToCompareWithRC) {
            if (typeof window.autopilotSaves === 'undefined') {
                window.autopilotSaves = []
            }
            const headers = getSaveDocumentHeaders(editorSessionId, editorOrigin)
            window.autopilotSaves.push({endpoint, body: data, headers})
        }
        return result
    }
    return await sendAndReport(endpoint, data, snapshotDal, editorOrigin)
}

function hasAutoSaveInfo(snapshotDal: SnapshotDal) {
    return Boolean(
        snapshotDal.getValue({type: 'documentServicesModel', id: 'autoSaveInfo', innerPath: 'shouldAutoSave'})
    )
}

function onSaveCompleteError(
    snapshotDal: SnapshotDal,
    bi: BICallbacks,
    editorOrigin: string,
    response: SaveServerResponse
) {
    const rejectionInfo = createErrorObject(response)
    const {errorType} = rejectionInfo
    const validationError = isValidationError(response)
    if (validationError) {
        contextAdapter.utils.fedopsLogger.captureError(
            new ReportableError({message: `Save Error: ${errorType}`, errorType: 'saveValidationError'}),
            {
                tags: {
                    errorType,
                    saveError: true
                },
                extras: {
                    origin: editorOrigin,
                    errorCode: _.get(rejectionInfo, 'errorCode'),
                    errorDescription: _.get(rejectionInfo, 'errorDescription'),
                    duplicateComponentId: _.get(response, 'payload.duplicateComponents[0].id') || null,
                    serverPayload: _.get(response, 'payload') as any
                }
            }
        )
    }
    bi.error(biErrors.SAVE_DOCUMENT_FAILED_ON_SERVER, {
        serverErrorCode: rejectionInfo.errorCode,
        errorType,
        origin: editorOrigin
    })
    return rejectionInfo
}

function reportVersionInfo({revision, version}) {
    contextAdapter.utils.fedopsLogger.interactionEnded('versionInfoUpdate', {
        tags: {
            revision,
            version,
            source: 'from_save'
        }
    })
}

export interface SaveServerResponse {
    success: boolean
    payload: {
        clientSpecMap: {}
        revision: string
        version: string
        deleted: {}
    }
    errorCode?: string
    errorDescription?: string
}

function onSaveCompleteSuccess(
    snapshotDal: SnapshotDal,
    bi: BICallbacks,
    settleInServer: boolean,
    response: SaveServerResponse,
    extensionsAPI: ExtensionAPI
) {
    const versionInfo = {
        revision: response.payload.revision,
        version: response.payload.version
    }
    const resolveObject: SaveResult = {
        changes: [
            {
                path: revisionPath,
                value: versionInfo.revision
            },
            {
                path: versionPath,
                value: versionInfo.version
            },
            {
                path: ['orphanPermanentDataNodes'],
                value: []
            }
        ]
    }
    reportVersionInfo(versionInfo)
    if (hasAutoSaveInfo(snapshotDal)) {
        resolveObject.changes.push({
            path: previousDiffIdPath,
            value: undefined
        })
    }

    if (snapshotDal.getValue({type: 'documentServicesModel', id: 'isDraft'})) {
        resolveObject.changes.push({
            path: ['documentServicesModel', 'isDraft'],
            value: false
        })
    }

    if (settleInServer) {
        const clientSpecMap = snapshotDal.getValue({type: 'rendererModel', id: 'clientSpecMap'})
        const documentType = snapshotDal.getValue({type: 'rendererModel', id: 'siteInfo', innerPath: 'documentType'})
        if (response.payload.clientSpecMap) {
            resolveObject.changes.push(
                {
                    path: ['rendererModel', 'clientSpecMap'],
                    value:
                        documentType === 'Template'
                            ? response.payload.clientSpecMap
                            : _.assign({}, clientSpecMap, response.payload.clientSpecMap)
                },
                {
                    path: ['rendererModel', 'clientSpecMapCacheKiller'],
                    value: {cacheKiller: guidUtils.getGUID()}
                }
            )
            resolveObject.changes.push(semanticAppVersionsCleaner())
            contextAdapter.utils.fedopsLogger.interactionEnded(constants.PLATFORM_INTERACTIONS.SETTLE_ACTIONS)
        }
        resolveObject.changes.push({
            path: ['rendererModel', 'siteInfo', 'documentType'],
            value: documentType
        })
    }

    const {deleted} = response.payload
    const itemsToDelete = addPagesOfItemsToDelete(deleted, snapshotDal)

    if (!(extensionsAPI as CSaveApi).continuousSave.isCEditOpen()) {
        resolveObject.historyAlteringChanges = getHistoryAlteringChanges(itemsToDelete)
    }

    addWixCodeSavedGridAppToResult(response.payload, resolveObject)

    return resolveObject
}

function addPagesOfItemsToDelete(itemsToDelete, snapshotDal: SnapshotDal) {
    const result = {}
    _.forEach(itemsToDelete, function (deletedIds, dataType) {
        _.forEach(deletedIds, function (deletedItemId) {
            /**
             * for dataType === 'multilingualTranslations' deletedItemId will be `itemid^langCode`
             */
            const isMultilingualTranslations = dataType === MULTILINGUAL_TYPES.multilingualTranslations
            const actualItemId = isMultilingualTranslations ? deletedItemId.split('^')[0] : deletedItemId
            const dataItem = snapshotDal.getValue({
                type: isMultilingualTranslations ? OVERRIDE_NAMESPACES[dataType] : pageDataTypeToKey[dataType],
                id: actualItemId
            })
            const pageId = _.get(dataItem, ['metaData', 'pageId'])
            if (pageId) {
                const deletesIdsArr = _.get(result, [pageId, dataType], [])
                let idToPush = deletedItemId
                if (isMultilingualTranslations) {
                    const splitted = deletedItemId.split('^')
                    idToPush = [pageId, splitted[1], splitted[0]].join('^')
                }
                deletesIdsArr.push(idToPush)
                _.set(result, [pageId, dataType], deletesIdsArr)
            }
        })
    })
    return result
}

function getSaveDocumentHeaders(sessionId: string, editorOrigin: string) {
    return {
        'X-Wix-Editor-Version': 'new',
        'X-Wix-DS-Origin': editorOrigin,
        'X-Editor-Session-Id': sessionId
    }
}

const fullSaveAsync = (
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    bi: BICallbacks,
    options,
    extensionsAPI
) =>
    saveWithFullPayloadAsync(currentSnapshotDal, bi, options, editorServerFacade.ENDPOINTS.OVERRIDE_SAVE, extensionsAPI)

const fullSave = (
    lastSnapshot: unknown,
    currentSnapshot: unknown,
    resolve,
    reject,
    bi: BICallbacks,
    options,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI
) => {
    fullSaveAsync(lastSnapshotDal, currentSnapshotDal, bi, options, extensionsAPI).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
}

async function fullPartialSave(bi: BICallbacks, options, currentSnapshotDal: SnapshotDal, extensionsAPI) {
    setSaving(extensionsAPI, true)
    try {
        await saveWithFullPayloadAsync(
            currentSnapshotDal,
            bi,
            options,
            editorServerFacade.ENDPOINTS.PARTIAL_SAVE,
            extensionsAPI
        )
    } finally {
        setSaving(extensionsAPI, false)
    }
}

const validateSite = (
    last: unknown,
    current: unknown,
    resolve,
    reject,
    bi: BICallbacks,
    options,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI
) => {
    // eslint-disable-next-line promise/prefer-await-to-then
    validateSiteAsync(last, current, bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI).then(
        resolve,
        reject
    )
}

const validateSiteAsync = async (
    last: unknown,
    current: unknown,
    bi: BICallbacks,
    options,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI
) => {
    const opts = {settleInServer: false, viewerName: '', initiatorOrigin: _.get(options, 'initiatorOrigin', '')}
    const dataToValidate = await createPartialDataToSave(bi, opts, lastSnapshotDal, currentSnapshotDal, extensionsAPI) //no settling on validation
    await sendRequestAsyncWrapped(
        editorServerFacade.ENDPOINTS.VALIDATE,
        dataToValidate,
        currentSnapshotDal,
        options.editorOrigin,
        extensionsAPI
    )
}

async function saveWithFullPayloadAsync(
    currentSnapshotDal: SnapshotDal,
    bi: BICallbacks,
    options,
    endPoint: string,
    extensionsAPI
): Promise<SaveResult> {
    setSaving(extensionsAPI, true)
    const dataToSave = await createFullDataToSave(currentSnapshotDal, options, extensionsAPI, bi)
    let response
    try {
        response = await sendRequestAsync(endPoint, dataToSave, currentSnapshotDal, options.editorOrigin, extensionsAPI)
    } catch (e: any) {
        throw await convertHttpError(e)
    } finally {
        setSaving(extensionsAPI, false)
    }
    if (response.success) {
        return onSaveCompleteSuccess(currentSnapshotDal, bi, options.settleInServer, response, extensionsAPI)
    }
    throw onSaveCompleteError(currentSnapshotDal, bi, options.editorOrigin, response)
}

const setSaving = (extensionsAPI, isSaving: boolean) => {
    extensionsAPI.continuousSave.setSaving(isSaving)
}

const convertCreateRevisionResponse = ({siteRevision, actions}) => {
    return {
        revision: siteRevision.revision,
        version: siteRevision.version,
        clientSpecMap: _.find(actions, {id: 'clientSpecMap', namespace: 'rendererModel'})?.value,
        wixCodeModel: {
            appData: {
                codeAppId: _.find(actions, {namespace: 'rendererModel', id: 'wixCodeModel'})?.value.appData?.codeAppId
            }
        },
        deleted: _(actions)
            .filter({op: 'REMOVE'})
            .groupBy('namespace')
            .mapValues(x => _.map(x, 'id'))
            .value()
    }
}

const csaveCreateRevision = async (
    currentSnapshotDal: SnapshotDal,
    options,
    updateSiteDto: UpdateSiteDTO,
    extensionsAPI
) => {
    // send origin from here
    const initiator = getInitiator(currentSnapshotDal)
    const createRevisionArgs = {
        initiator,
        viewerName: options.viewerName,
        initiatorOrigin: _.get(options, 'initiatorOrigin', ''),
        dsOrigin: options.editorOrigin,
        editorVersion: updateSiteDto.version.toString()
    }
    if (experiment.isOpen('dm_createRevisionErrorHandling')) {
        const result = await (extensionsAPI as CSaveApi).continuousSave.createRevision(
            createRevisionArgs,
            updateSiteDto
        )
        return {
            success: true,
            payload: convertCreateRevisionResponse(result)
        }
    }
    const crResult: any = await asyncAttempt(() =>
        extensionsAPI.continuousSave.createRevision(createRevisionArgs, updateSiteDto)
    )
    if (crResult.didThrow) {
        const {message, details, status, extras} = crResult.error
        const isServerError = _.isNumber(status) && !_.inRange(status, 200, 300)
        if (isServerError) {
            const error = _.pick(extras, ['status', 'statusText'])
            error.statusText += ` - ${message}`
            throw error
        }
        return {
            success: false,
            message,
            errorCode: details?.applicationError?.code,
            errorDescription: details?.applicationError?.description
        }
    }
    return {
        success: true,
        payload: convertCreateRevisionResponse(crResult.value)
    }
}

const partialSave = (
    last: unknown,
    current: unknown,
    resolve,
    reject,
    bi: BICallbacks,
    options,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI
) => {
    // eslint-disable-next-line promise/prefer-await-to-then
    partialSaveAsync(last, current, bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI).then(
        resolve,
        reject
    )
}

const partialSaveAsync = async (
    last: unknown,
    current: unknown,
    bi: BICallbacks,
    options,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI
) => {
    if (options?.fullPayload) {
        return await fullPartialSave(bi, options, currentSnapshotDal, extensionsAPI)
    }
    monitoring.start(monitoring.BUILD_PARTIAL_PAYLOAD)
    setSaving(extensionsAPI, true)
    const dataToSave = await createPartialDataToSave(bi, options, lastSnapshotDal, currentSnapshotDal, extensionsAPI)
    monitoring.end(monitoring.BUILD_PARTIAL_PAYLOAD)
    let response
    try {
        response = extensionsAPI.continuousSave.isCreateRevisionOpen()
            ? await csaveCreateRevision(currentSnapshotDal, options, dataToSave, extensionsAPI)
            : await sendRequestAsync(
                  editorServerFacade.ENDPOINTS.PARTIAL_SAVE,
                  dataToSave,
                  currentSnapshotDal,
                  options.editorOrigin,
                  extensionsAPI
              )
    } catch (e: any) {
        throw await convertHttpError(e)
    } finally {
        setSaving(extensionsAPI, false)
    }
    if (response.success) {
        return onSaveCompleteSuccess(currentSnapshotDal, bi, options.settleInServer, response, extensionsAPI)
    }
    if (isValidationError(response)) {
        bi.event(biEvents.FULL_DOCUMENT_SAVE_ATTEMPTED_AFTER_PARTIAL_FAILURE, {endpoint: 'partial'})
        monitoring.start(monitoring.FULL_PARTIAL_SAVE)
        await fullPartialSave(bi, options, currentSnapshotDal, extensionsAPI)
        monitoring.end(monitoring.FULL_PARTIAL_SAVE)
    } else {
        throw onSaveCompleteError(currentSnapshotDal, bi, options.editorOrigin, response)
    }
}

const shouldRun = (
    lastSnapshot: unknown,
    currentSnapshot: unknown,
    methodName: string,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI,
    pointers: Pointers
) => {
    if (methodName === 'partialSave') {
        const diff = currentSnapshotDal.diff(lastSnapshotDal)
        const didSnapShotsChangeFromLastSave = shouldSaveDiff(diff)
        const shouldCodeAppChange = getGridAppForRevision.shouldGridAppChangeBySnapshotDal(
            pointers,
            lastSnapshotDal,
            currentSnapshotDal
        )
        // @ts-expect-error BUG
        contextAdapter.utils.fedopsLogger.breadcrumb('shouldRun', {didSnapShotsChangeFromLastSave, shouldCodeAppChange})
        return didSnapShotsChangeFromLastSave || shouldCodeAppChange
    }
    return true
}

export interface ServerErrorDetails {
    applicationError?: {
        code: string
        description: string
    }
}

export interface ServerErrorData {
    message: string
    details: ServerErrorDetails
}

const saveAsTemplateAsync = async (
    lastSnapshot: unknown,
    currentSnapshot: unknown,
    bi: BICallbacks,
    options,
    lastSnapshotDal: SnapshotDal,
    currentSnapshotDal: SnapshotDal,
    extensionsAPI?
) => {
    await sendRequestAsyncWrapped(
        editorServerFacade.ENDPOINTS.SAVE_AS_TEMPLATE,
        null,
        currentSnapshotDal,
        options.editorOrigin,
        extensionsAPI
    )
    return {
        changes: [{path: ['rendererModel', 'siteInfo', 'documentType'], value: 'Template'}]
    }
}

const firstSaveAsync = async (
    lastSnapshot: unknown,
    currentSnapshot: unknown,
    bi: BICallbacks,
    options: any = {},
    lastSnapshotDal?: SnapshotDal,
    currentSnapshotDal?: SnapshotDal,
    extensionsAPI?
) => {
    const fullSaveDTO = await createFullDataToSave(currentSnapshotDal, options, extensionsAPI, bi)
    const firstSaveDTO = _.assign(
        {},
        convertFullSaveToFirstSaveDto(fullSaveDTO, currentSnapshotDal),
        options.extraPayload
    )
    const {payload} = await sendRequestAsyncWrapped(
        editorServerFacade.ENDPOINTS.FIRST_SAVE,
        firstSaveDTO,
        currentSnapshotDal,
        options.editorOrigin,
        extensionsAPI
    )
    return createFirstSaveResultObject(payload, currentSnapshotDal)
}

interface PublishWithOverridesOps {
    editorOrigin?: string
    label?: string
    specificPages?: string[]
}

const sendPublishWithOverridesRequest = async (
    currentSnapshotDal: SnapshotDal,
    options: PublishWithOverridesOps,
    extensionsAPI
) => {
    const endpoint = editorServerFacade.ENDPOINTS.PUBLISH_WITH_OVERRIDES
    const branchId = extensionsAPI.siteAPI.getBranchId()
    const revision = extensionsAPI.siteAPI.getSiteRevision()
    const {publishedSiteDetails}: any = await sendRestRequestAsyncWrapped(
        editorServerFacade.ENDPOINTS.PUBLISHED_SITE_DETAILS,
        null,
        currentSnapshotDal,
        options.editorOrigin,
        extensionsAPI
    )
    const {branchId: basedOnBranch, siteRevision: basedOnRevision} = publishedSiteDetails.siteProperties
    return sendRestRequestAsyncWrapped(
        endpoint,
        {
            label: options.label ?? 'publish-specific-pages',
            deployment_attributes: [
                {
                    page_attribute: {
                        page_ids: options.specificPages,
                        editor_revision: {branch_id: branchId, site_revision: revision}
                    }
                }
            ],
            specific_version: {
                branch_id: basedOnBranch,
                site_revision: basedOnRevision
            },
            should_publish: true
        },
        currentSnapshotDal,
        options.editorOrigin,
        extensionsAPI
    )
}

const sendPublishTestSiteRequest = async (currentSnapshotDal: SnapshotDal, options, extensionsAPI) => {
    const {viewerName} = options
    const endpoint = editorServerFacade.ENDPOINTS.PUBLISH_TEST_SITE
    const data: any = {viewerName}
    const branchId = extensionsAPI.siteAPI.getBranchId()
    if (branchId) {
        data.branchId = branchId
    }
    if (options.overrideRevisionInfo) {
        data.overrideRevisionInfo = {
            revision: options.overrideRevisionInfo.revision
        }
        if (options.overrideRevisionInfo.branchId) {
            data.overrideRevisionInfo.branchId = options.overrideRevisionInfo.branchId
        }
    }

    return sendRestRequestAsyncWrapped(endpoint, data, currentSnapshotDal, options.editorOrigin, extensionsAPI)
}

const sendPublishRCRequest = async (currentSnapshotDal: SnapshotDal, options: PublishSiteRCOptions, extensionAPI) => {
    const endpoint = editorServerFacade.ENDPOINTS.PUBLISH_RC
    const data: PublishSiteRCRequest = {
        label: RCLabel[options.label] || RCLabel.UNKNOWN
    }
    const branchId = extensionAPI.siteAPI.getBranchId()
    if (branchId) {
        data.branchId = branchId
    }
    // Using sendRestRequestAsyncWrapped which doesn't expect {success: true} in the response
    // expected server response is PublishSiteRCResponse {revision: number}
    return sendRestRequestAsyncWrapped(endpoint, data, currentSnapshotDal, options.editorOrigin, extensionAPI)
}

const sendFullPublishRequest = async (currentSnapshotDal: SnapshotDal, options: PublishAllOptions, extensionsAPI) => {
    const {viewerName} = options
    const endpoint = editorServerFacade.ENDPOINTS.PUBLISH
    const data: any = {viewerName}
    const branchId = extensionsAPI.siteAPI.getBranchId()
    if (branchId) {
        data.branchId = branchId
    }
    return sendRequestAsyncWrapped(endpoint, data, currentSnapshotDal, options.editorOrigin, extensionsAPI)
}

const sendPublishRequest = async (currentSnapshotDal: SnapshotDal, options: PublishAllOptions, basedOnRevision) => {
    if (options.specificPages) {
        return sendPublishWithOverridesRequest(currentSnapshotDal, options, basedOnRevision)
    }
    if (options.publishTestSite) {
        return sendPublishTestSiteRequest(currentSnapshotDal, options, basedOnRevision)
    }
    if (options.publishRC) {
        return sendPublishRCRequest(currentSnapshotDal, options, basedOnRevision)
    }
    return sendFullPublishRequest(currentSnapshotDal, options, basedOnRevision)
}

const publishAsync = async (
    currentSnapshot: unknown,
    bi: BICallbacks,
    options: PublishAllOptions = {},
    currentSnapshotDal?: SnapshotDal,
    extensionsAPI?
) => {
    let response
    setSaving(extensionsAPI, true)
    try {
        response = await sendPublishRequest(currentSnapshotDal, options, extensionsAPI)
    } finally {
        setSaving(extensionsAPI, false)
    }

    const changes = [
        {
            path: ['documentServicesModel', 'isPublished'],
            value: true
        }
    ]

    const revision =
        _.get(response, 'revision') ?? _.get(response, 'payload.revision') ?? _.get(response, 'revisionInfo.revision')

    if (revision) {
        changes.push({
            path: revisionPath,
            value: revision
        })
    }

    const version = _.get(response, 'payload.version')
    if (version) {
        changes.push({
            path: versionPath,
            value: version
        })
    }
    return {
        changes
    }
}

export interface TaskDef {
    rollback?(result)
    takeSnapshot?()
    getLastState?(): any
    getCurrentState?(): any
    shouldRun(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        methodName: string,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal,
        extensionsAPI,
        pointers
    ): boolean
}

export default {
    /** @private */
    paths: {
        versionPath,
        revisionPath,
        previousDiffId: previousDiffIdPath
    },

    csaveCreateRevision,

    /**
     *
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     * @param {Function} resolve - resolve this task (success).
     * @param {Function} reject - reject this save (fail). Can be called with errorType, errorMessage
     * @param {{error: Function, event: Function}} bi
     */
    partialSave,

    /**
     * @param {object} last - the DAL snapshot, since the last save
     * @param {object} current - the DAL snapshot, since the last save
     * @param {{error: Function, event: Function}} bi
     * @param {object} options
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     */
    partialSaveAsync,

    /**
     *
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     * @param {Function} resolve - resolve this task (success).
     * @param {Function} reject - reject this save (fail). Can be called with errorType, errorMessage
     */
    fullSave,

    /**
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     * @param {Function} resolve - resolve this task (success).
     * @param {Function} reject - reject this save (fail). Can be called with errorType, errorMessage
     */
    fullSaveAsync,

    /**
     *
     * @param {object} lastSnapshot - the DAL snapshot, since the last save
     * @param {object} currentSnapshot - the DAL snapshot, as it is right now
     * @param {Function} resolve - resolve this task (success).
     * @param {Function} reject - reject this validation (fail). Can be called with errorType, errorMessage
     */
    validateSite,

    /**
     *
     * @param lastSnapshot - the DAL snapshot, since the last save
     * @param currentSnapshot - the DAL snaUpshot, as it is right now
     * @param resolve - resolve this task (success).
     * @param reject - reject this save (fail). Can be called with errorType, errorMessage
     * @param bi
     * @param options
     * @param lastSnapshotDal
     * @param currentSnapshotDal
     * @param extensionsAPI
     */
    firstSave(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        resolve,
        reject,
        bi: BICallbacks,
        options = {},
        lastSnapshotDal?: SnapshotDal,
        currentSnapshotDal?: SnapshotDal,
        extensionsAPI?
    ) {
        firstSaveAsync(
            lastSnapshot,
            currentSnapshot,
            bi,
            options,
            lastSnapshotDal,
            currentSnapshotDal,
            extensionsAPI
        ).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
    },

    firstSaveAsync,

    saveAsTemplate(
        lastSnapshot: unknown,
        currentSnapshot: unknown,
        resolve,
        reject,
        bi: BICallbacks,
        options,
        lastSnapshotDal?: SnapshotDal,
        currentSnapshotDal?: SnapshotDal
    ) {
        // eslint-disable-next-line promise/prefer-await-to-then
        saveAsTemplateAsync(lastSnapshot, currentSnapshot, bi, options, lastSnapshotDal, currentSnapshotDal).then(
            resolve,
            reject
        )
    },

    /**
     * @param currentSnapshot the DAL snapshot, as it is right now
     * @param resolve resolve this task (success).
     * @param reject reject this save (fail). Can be called with errorType, errorMessage
     * @param bi
     * @param {{viewerName?: string, publishRC?: string, publishTestSite?: boolean, overrideRevisionInfo?:object, editorOrigin?: string}} options
     * @param currentSnapshotDal
     * @param extensionsAPI
     */
    publish(currentSnapshot, resolve, reject, bi, options = {}, currentSnapshotDal?: SnapshotDal, extensionsAPI?) {
        publishAsync(currentSnapshot, bi, options, currentSnapshotDal, extensionsAPI).then(resolve, reject) // eslint-disable-line promise/prefer-await-to-then
    },

    publishAsync,

    getTaskName() {
        return TASK_NAME
    },

    shouldRun,

    getSnapshotTags(methodName: string) {
        switch (methodName) {
            case 'partialSave':
            case 'fullSave':
            case 'firstSave':
            case 'saveAsTemplate':
                return ['primary', 'autosave'] // initial snapshot for saveDocumentautosave tag is taken right after applying patches (patchData.js). If you change the tag here - please update there
            case 'autosave':
                return ['autosave']
            case 'publish':
                return ['primary']
            default:
                return ['primary']
        }
    },

    cleanupDataDeltaContents
}
