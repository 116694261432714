import type {AnyAsyncFunction, AnyFunction, Promised} from './types'

export interface SuccessfulAttempt<T> {
    value: T
    didThrow: false
}

export interface FailedAttempt<E> {
    error: E
    didThrow: true
}

export type Attempt<T, E> = SuccessfulAttempt<T> | FailedAttempt<E>

/** Run the function `f` with the provided `args` and wrap the result in an `Attempt` instead of throwing errors */
export function attempt<F extends AnyFunction, E = unknown>(f: F, ...args: Parameters<F>): Attempt<ReturnType<F>, E> {
    try {
        return {value: f(...args), didThrow: false}
    } catch (e) {
        return {error: e as E, didThrow: true}
    }
}

/** Run the function `f` with the provided `args` and return `defaultValue` instead of throwing errors */
export function defaultAttempt<F extends AnyFunction>(
    defaultValue: ReturnType<F>,
    f: F,
    ...args: Parameters<F>
): ReturnType<F> {
    try {
        return f(...args)
    } catch (e) {
        return defaultValue
    }
}

/** Run the async function `f` with the provided `args` and wrap the result in an `Attempt` instead of throwing errors */
export async function asyncAttempt<F extends AnyAsyncFunction, E = unknown>(
    f: F,
    ...args: Parameters<F>
): Promise<Attempt<Promised<F>, E>> {
    try {
        return {value: await f(...args), didThrow: false}
    } catch (e) {
        return {error: e as E, didThrow: true}
    }
}

/** Run the async function `f` with the provided `args` and return `defaultValue` instead of throwing errors */
export async function asyncDefaultAttempt<F extends AnyAsyncFunction>(
    defaultValue: Promised<F>,
    f: F,
    ...args: Parameters<F>
): Promise<Promised<F>> {
    try {
        return await f(...args)
    } catch (e) {
        return defaultValue
    }
}
