import constants from '../../constants/constants'
import metaDataUtils from '../metaDataUtils'

const STATE_STRIP = 'wysiwyg.viewer.components.StateStrip'

function canContain(isByStructure, ps, componentPointer, potentialContainee) {
    if (potentialContainee) {
        const containeeCompType = isByStructure
            ? potentialContainee.componentType
            : metaDataUtils.getComponentType(ps, potentialContainee)
        return metaDataUtils.isComponentSuitableForNonRenderingState(containeeCompType)
    }

    return false
}

export default {
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: false,
    moveDirections: [constants.MOVE_DIRECTIONS.VERTICAL],
    resizableSides: [constants.RESIZE_SIDES.TOP, constants.RESIZE_SIDES.BOTTOM],
    fullWidth: true,
    fullWidthByStructure: true,
    canContain: canContain.bind(null, false),
    canContainByStructure: canContain.bind(null, true),
    mobileConversionConfig: {
        nestOverlayingSiblings: false,
        structuralItem: true,
        stackLayout: true,
        isScreenWidth: true,
        filterChildrenWhenHidden: true,
        preserveAspectRatio: false
    },
    allowedParentTypes: [STATE_STRIP]
}
