import type {Extension, CreateExtArgs, ExtensionAPI} from '@wix/document-manager-core'
import type {Pointer} from '@wix/document-services-types'
import _ from 'lodash'
import {constants} from '@wix/document-manager-utils'
import type {DataModelExtensionAPI} from './dataModel'
import {EVENTS as COMPONENT_EVENTS} from './components'
import {DATA_TYPES} from '../constants/constants'

export interface MobilePresetsAPI {
    removePresetOffsetDataOfChildren(compPointer: Pointer): void
}

export type MobilePresetsExtensionAPI = ExtensionAPI & {
    mobilePresets: MobilePresetsAPI
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({eventEmitter, extensionAPI, pointers}: CreateExtArgs): MobilePresetsExtensionAPI => {
        const {dataModel} = extensionAPI as DataModelExtensionAPI

        const hasSizeData = (mobileHints: any) =>
            mobileHints && _.some(constants.HINTS_PROPERTIES.SIZE_DATA, prop => _.has(mobileHints, prop))
        const hasOffsetData = (mobileHints: any) =>
            mobileHints && _.some(constants.HINTS_PROPERTIES.OFFSET_DATA, prop => _.has(mobileHints, prop))
        const isMobileHintsPreset = (mobileHints: any) => hasSizeData(mobileHints) || hasOffsetData(mobileHints)

        const removePresetData = (componentPointer: Pointer, presetDataToRemove: any) => {
            if (pointers.structure.isMobile(componentPointer)) {
                return
            }
            const mobileHintsItem = dataModel.components.getItem(componentPointer, DATA_TYPES.mobileHints)
            if (!isMobileHintsPreset(mobileHintsItem)) {
                return
            }
            const newMobileHintsItem: any = _.omit(mobileHintsItem, [
                ...presetDataToRemove,
                ...constants.HINTS_PROPERTIES.GENERAL_HINTS_PROPERTIES
            ])
            dataModel.components.removeItem(componentPointer, DATA_TYPES.mobileHints)
            newMobileHintsItem.type = mobileHintsItem.type
            if (!_.isEmpty(newMobileHintsItem)) {
                dataModel.components.addItem(componentPointer, DATA_TYPES.mobileHints, newMobileHintsItem)
            }
        }

        const removePresetHeight = (componentPointer: Pointer) => {
            const presetDataToRemove = ['recommendedHeight']
            removePresetData(componentPointer, presetDataToRemove)
            const parentPointer = pointers.structure.getParent(componentPointer)
            if (parentPointer && !pointers.structure.isPage(parentPointer)) {
                removePresetHeight(parentPointer)
            }
        }

        const removePresetOffsetData = (componentPointer: Pointer) => {
            const presetDataToRemove = constants.HINTS_PROPERTIES.OFFSET_DATA
            removePresetData(componentPointer, presetDataToRemove)
        }

        const removePresetOffsetDataOfChildren = (componentPointer: Pointer) => {
            const children = pointers.structure.getChildren(componentPointer)

            for (const childPointer of children) {
                removePresetOffsetData(childPointer)
            }

            removePresetHeight(componentPointer)
        }

        eventEmitter.addListener(COMPONENT_EVENTS.COMPONENTS.BEFORE_REMOVE, (compPointer: Pointer) => {
            const containerPointer = pointers.structure.getParent(compPointer)
            if (containerPointer !== null) {
                removePresetOffsetDataOfChildren(containerPointer)
            }
        })

        return {
            mobilePresets: {
                removePresetOffsetDataOfChildren
            }
        }
    }

    return {
        name: 'mobilePresets',
        dependencies: new Set(['structure', 'dataModel']),
        createExtensionAPI
    }
}

export {createExtension}
