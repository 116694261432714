import {loggingUtils} from '@wix/santa-ds-libs/src/warmupUtils'
import platformAppsSaveDataFixer from './plugins/platformAppsSaveDataFixer'

const plugins = [
    platformAppsSaveDataFixer
    /*
        STOP!
        If you are adding a save-data-fixer that means that you have a bug during the editing session.
        Please fix the bug and don't patch it here.
        AUTOSAVE DOESN'T GO THROUGH THIS CODE - so you have to make sure the data in the snapshots is always correct and ready to be saved.
    */
]

/**
 *
 * @param {{component_properties:object, document_data:object, theme_data:object}} dataToSave
 * @param args
 * @param fixerPlugins
 * @returns {*}
 */
function fixDataInternal(dataToSave, args, fixerPlugins) {
    loggingUtils.performance.start(loggingUtils.performanceMetrics.SAVE.SAVE_DATA_FIXER)
    fixerPlugins.forEach(plugin => {
        plugin.exec(dataToSave, args)
    })
    loggingUtils.performance.start(loggingUtils.performanceMetrics.SAVE.SAVE_DATA_FIXER)
    return dataToSave
}

const exports2 = {
    fixData: (dataToSave, args) => fixDataInternal(dataToSave, args, plugins)
}

export default exports2
