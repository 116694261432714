import _ from 'lodash'
import type {Pointer, ScopePointer} from '@wix/document-services-types'
import {REF_COMPONENT_TYPE, REF_DELIMITER, REPEATER_DELIMITER, VIEW_MODES} from './constants/constants'
import {pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils

const REF_SUFFIX = '-ref'
const {DESKTOP, MOBILE} = VIEW_MODES
const POSSIBLE_VIEW_MODES = new Set([DESKTOP, MOBILE, DESKTOP + REF_SUFFIX, MOBILE + REF_SUFFIX])

const buildScopedPointer = (id: string, type: string, scope?: ScopePointer): Pointer => {
    const pointer = getPointer(id, type)

    if (scope) {
        pointer.scope = scope
    }

    return pointer
}

const buildScopePath = (scopePointer: ScopePointer): string[] => {
    const {id, scope} = scopePointer
    if (!scope) {
        return [id]
    }

    return [...buildScopePath(scope), id]
}

const addScopeToInflatedId = (id: string, scope: string): string => {
    if (scope.includes(REPEATER_DELIMITER)) {
        const repeaterItem = scope.split(REPEATER_DELIMITER)[1]
        return `${id}${REPEATER_DELIMITER}${repeaterItem}`
    }

    return `${scope}${REF_DELIMITER}${id}`
}

const buildInflatedId = (pointer: Pointer): string => {
    if (pointer.scope) {
        return buildScopePath(pointer.scope).reverse().reduce(addScopeToInflatedId, pointer.id)
    }
    return pointer.id
}

const getScopePathWithoutRepeaters = (scopePath: string[]) => {
    let repeatersNestingSuffix = ''
    const filteredPath = []

    for (const scope of scopePath) {
        if (scope.includes(REPEATER_DELIMITER)) {
            const itemId = scope.split(REPEATER_DELIMITER)[1]
            repeatersNestingSuffix = `${REPEATER_DELIMITER}${itemId}${repeatersNestingSuffix}`
        } else {
            filteredPath.push(`${scope}${repeatersNestingSuffix}`)
        }
    }

    return filteredPath
}

const createScopedPointer = (
    pointer: Pointer,
    scopePath: string[],
    enableRepeatersInScopes: boolean = false
): Pointer => {
    if (!enableRepeatersInScopes) {
        scopePath = getScopePathWithoutRepeaters(scopePath)
    }

    const scope: ScopePointer | undefined = _.reduce(
        scopePath,
        (scopePointer: ScopePointer | undefined, scopeId: string) =>
            buildScopedPointer(scopeId, 'scope', scopePointer) as ScopePointer,
        undefined
    )

    return {
        ...pointer,
        ...buildScopedPointer(pointer.id, pointer.type, scope)
    }
}

const isInflatedIdInSameScope = (scopedPointer: Pointer, inflatedId: string) => {
    const pointerScope = scopedPointer.id.split(REF_DELIMITER)
    pointerScope.pop()
    const inflatedIdScope = inflatedId.split(REF_DELIMITER)
    inflatedIdScope.pop()

    return _.isEqual(pointerScope, inflatedIdScope)
}

const getParentAfterScopeFilter = (pointer: Pointer, value: any): string | undefined => {
    if (value.parent && isInflatedIdInSameScope(pointer, value.parent)) {
        return value.parent
    }
}

const getChildrenAfterScopeFilter = (value: any): string[] | undefined => {
    if (value.components?.length > 0 && value.componentType === REF_COMPONENT_TYPE) {
        return []
    }

    return value.components
}

const removeOutOfScopeData = (pointer: Pointer, value: any) => {
    if (!value || !POSSIBLE_VIEW_MODES.has(pointer.type)) {
        return value
    }

    if (_.isEqual(pointer.innerPath, ['parent'])) {
        value.parent = getParentAfterScopeFilter(pointer, value)
        return value
    }

    if (_.isEqual(pointer.innerPath, ['components'])) {
        value.components = getChildrenAfterScopeFilter(value)
        return value
    }

    value.parent = getParentAfterScopeFilter(pointer, value)
    value.components = getChildrenAfterScopeFilter(value)
    return value
}

export {createScopedPointer, buildScopePath, buildInflatedId, removeOutOfScopeData}
