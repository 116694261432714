import type {Pointer} from '@wix/document-services-types'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE, VIEW_MODES} from '../constants/constants'
import {namespaceMapping} from '@wix/document-services-json-schemas'

const {getNamespaceConfig} = namespaceMapping

const refCompDelimiter = '_r_'
const repeaterDelimiter = '__'
const mobileSplitSuffix = '-mobile'

const MOBILE_SPLITTABLE_TYPE = {
    props: true
}

export const getRepeatersNestingOrder = (id: string): string[] => id.split(repeaterDelimiter).slice(1)

export const getRepeatersNestingSuffix = (id: string): string => getRepeatersNestingOrder(id).join(repeaterDelimiter)

const getRepeaterTemplateId = (id: string): string => id.split(repeaterDelimiter, 1)[0]

const getUniqueRefId = (refHostId: string, compId: string) => `${refHostId}${refCompDelimiter}${compId}`

const isMobilePointer = (compPointer: Pointer) => compPointer.type === VIEW_MODES.MOBILE

const getDataItemSuffix = (itemType: string, isMobile: boolean) => {
    const maybeAddMobileSuffix = isMobile && MOBILE_SPLITTABLE_TYPE[itemType] ? mobileSplitSuffix : ''
    const itemTypeSuffix = `-${COMP_DATA_QUERY_KEYS_WITH_STYLE[itemType]}`
    return `${itemTypeSuffix}${maybeAddMobileSuffix}`
}

const isRepeatableDataItemType = (itemType: string) => getNamespaceConfig(itemType)?.supportsRepeaterItem

export const getCompIdWithRepeatersNesting = (compId: string, repeatersNestingOrder: string[]) =>
    [compId, ...repeatersNestingOrder].join(repeaterDelimiter)

export const getItemQueryId = (compPointer: Pointer, itemType: string) => {
    const compId = getRepeaterTemplateId(compPointer.id)
    const repeatersNestingOrder = isRepeatableDataItemType(itemType) ? getRepeatersNestingOrder(compPointer.id) : []
    const dataItemSuffix = getDataItemSuffix(itemType, isMobilePointer(compPointer))

    return getCompIdWithRepeatersNesting(`${compId}${dataItemSuffix}`, repeatersNestingOrder)
}

export const createRefCompQuery = (
    refHostInflatedId: string,
    referredCompId: string,
    itemType: string = '',
    isMobile?: boolean
): string => {
    const repeatersNestingOrder = isRepeatableDataItemType(itemType) ? getRepeatersNestingOrder(referredCompId) : []
    const refHostId = getRepeaterTemplateId(refHostInflatedId)
    referredCompId = getRepeaterTemplateId(referredCompId)

    const suffix = itemType ? getDataItemSuffix(itemType, !!isMobile) : ''
    const newReferredCompId = `${getUniqueRefId(refHostId, referredCompId)}${suffix}`

    return getCompIdWithRepeatersNesting(newReferredCompId, repeatersNestingOrder)
}
