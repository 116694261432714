import _ from 'lodash'

// TODO: unite with appPart2DataRequirementsChecker

// error messages
const ERROR_MISSING_TYPE_ID = 'Must provide a type ID'
const ERROR_MISSING_ITEM_ID = 'Must provide an item ID'
const ERROR_MISSING_PART_ID = 'Must provide a part ID'
const ERROR_MISSING_VIEW_ID = 'Must provide a view ID'
const ERROR_MISSING_DATA_SELECTOR_ID = 'Must provide a data selector ID'
const ERROR_MISSING_PAGE_ID = 'Must provide a page ID'

// site data paths
const BASE_WIXAPPS_PATH = ['wixapps']
const BASE_APPBUILDER_PATH = BASE_WIXAPPS_PATH.concat('appbuilder')
const BASE_REPO_PATH = BASE_APPBUILDER_PATH.concat('descriptor')

const BASE_DATA_PATHS = {
    VIEWS: BASE_REPO_PATH.concat('views'),
    TYPES: BASE_REPO_PATH.concat('types'),
    PARTS: BASE_REPO_PATH.concat('parts'),
    DATA_SELECTORS: BASE_REPO_PATH.concat('dataSelectors'),
    PAGES: BASE_REPO_PATH.concat('pages'),
    ITEMS: BASE_APPBUILDER_PATH.concat('items'),
    DELETED_ITEMS: BASE_APPBUILDER_PATH.concat('deletedItems')
}

function throwIfUndefined(param, errorMessage) {
    if (!param) {
        throw new Error(errorMessage)
    }
}

export default {
    /**
     * @param {ps} ps
     */
    initBasePaths(ps) {
        const pathsToSet = [BASE_WIXAPPS_PATH, BASE_APPBUILDER_PATH, BASE_REPO_PATH].concat(_.values(BASE_DATA_PATHS))

        _.forEach(pathsToSet, function (path) {
            if (!ps.wixappsDAL.isPathExist(path)) {
                // DAL does not support deep merge
                ps.wixappsDAL.setByPath(path, {})
            }
        })
    },

    /**
     * @param {string} [typeId]
     * @returns {string[]}
     */
    getBaseItemsPath(typeId?: string) {
        return typeId ? BASE_DATA_PATHS.ITEMS.concat(typeId) : BASE_DATA_PATHS.ITEMS
    },

    /**
     * Get the path to an item or item's property.
     * @param {String} typeId The type id.
     * @param {String} itemId The item id.
     * @param {String|String[]?} [property] The property of in the item
     * @returns {string[]} The path to the item or property of an item.
     */
    getItemPath(typeId: string, itemId: string, property?: string | string[]) {
        throwIfUndefined(typeId, ERROR_MISSING_TYPE_ID)
        throwIfUndefined(itemId, ERROR_MISSING_ITEM_ID)
        let path = this.getBaseItemsPath(typeId).concat(itemId)
        if (property) {
            path = path.concat(property)
        }
        return path
    },

    getBaseDeletedItemsPath(typeId: string) {
        return typeId ? BASE_DATA_PATHS.DELETED_ITEMS.concat(typeId) : BASE_DATA_PATHS.DELETED_ITEMS
    },

    getDeletedItemPath(typeId, itemId) {
        throwIfUndefined(typeId, ERROR_MISSING_TYPE_ID)
        throwIfUndefined(itemId, ERROR_MISSING_ITEM_ID)
        return this.getBaseDeletedItemsPath(typeId).concat(itemId)
    },

    getBaseTypesPath() {
        return BASE_DATA_PATHS.TYPES
    },

    getTypePath(typeId) {
        throwIfUndefined(typeId, ERROR_MISSING_TYPE_ID)
        return this.getBaseTypesPath().concat(typeId)
    },

    getBasePartsPath() {
        return BASE_DATA_PATHS.PARTS
    },

    getPartPath(partId) {
        throwIfUndefined(partId, ERROR_MISSING_PART_ID)
        return this.getBasePartsPath().concat(partId)
    },

    getBaseViewsPath() {
        return BASE_DATA_PATHS.VIEWS
    },

    getViewPath(viewId) {
        throwIfUndefined(viewId, ERROR_MISSING_VIEW_ID)
        return this.getBaseViewsPath().concat(viewId)
    },

    getBaseDataSelectorsPath() {
        return BASE_DATA_PATHS.DATA_SELECTORS
    },

    getDataSelectorPath(dataSelectorId) {
        throwIfUndefined(dataSelectorId, ERROR_MISSING_DATA_SELECTOR_ID)
        return this.getBaseDataSelectorsPath().concat(dataSelectorId)
    },

    getPagePath(pageId) {
        throwIfUndefined(pageId, ERROR_MISSING_PAGE_ID)
        return BASE_DATA_PATHS.PAGES.concat(pageId)
    },

    getApplicationInstanceVersionPath() {
        return BASE_REPO_PATH.concat(['applicationInstanceVersion'])
    },

    getOffsetFromServerTimePath() {
        return BASE_REPO_PATH.concat(['offsetFromServerTime'])
    },

    getRepoPath() {
        return BASE_REPO_PATH
    },

    getAppbuilderPath() {
        return BASE_APPBUILDER_PATH
    },

    getPackageMetaDataPath() {
        return BASE_APPBUILDER_PATH.concat(['metadata'])
    },

    getAppbuilderMetadataPath(innerPath?) {
        return _.compact(BASE_APPBUILDER_PATH.concat(['metadata', 'appbuilder_metadata'].concat(innerPath)))
    },

    getAppPart2MetadataPath(partName) {
        return BASE_APPBUILDER_PATH.concat(['metadata', partName])
    }
}
