/* eslint-disable promise/prefer-await-to-then */
import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as platformInit from 'platformInit'
import experiment from 'experiment-amd'
import hooks from '../hooks/hooks'
import clientSpecMap from '../siteMetadata/clientSpecMap'
import workerService from './services/workerService'
import clientSpecMapService from '../tpa/services/clientSpecMapService'
import appStoreService from '../tpa/services/appStoreService'
import originService from './services/originService'
import constants from './common/constants'
import appComponents from './appComponents'
import wixCode from '../wixCode/wixCode'
import dsConstants from '../constants/constants'
import platformStateService from './services/platformStateService'
import copyDataFromTemplate from './services/copyDataFromTemplate'
import generalInfo from '../siteMetadata/generalInfo'
import platformAppDataGetter from './services/platformAppDataGetter'
import permissionsUtils from '../tpa/utils/permissionsUtils'
import {ReportableError} from '@wix/document-manager-utils'
import callbackUtils from '../utils/callbackUtils'

const {getOnSuccessWithFallback} = callbackUtils

const getOriginInfo = (appDefinitionId: string, installOptions) => {
    if (!appDefinitionId) {
        return null
    }
    if (installOptions?.platformOrigin) {
        const info = installOptions?.platformOrigin?.info
        return info?.type || info?.appDefinitionId
    }
    if (installOptions?.origin) {
        const info = installOptions?.origin?.info
        return info?.type || info?.appDefinitionId
    }
    return null
}

const {hasCodePackage} = appComponents

const getProvisionInteractionParams = (appDefinitionId: string, options) => {
    const installationOriginInfo = getOriginInfo(appDefinitionId, options)
    return {
        tags: {
            origin_info: installationOriginInfo,
            appDefinitionId
        },
        extras: {app_id: appDefinitionId, firstInstall: options?.firstInstall}
    }
}

const extendOptionWithInternalOrigin = (options, internalOrigin) =>
    _.setWith(options, ['internalOrigin'], internalOrigin, Object)

function provision(ps: PS, appDefinitionId: string, options?) {
    ps.extensionAPI.logger.interactionStarted(
        dsConstants.PLATFORM_INTERACTIONS.PROVISION,
        getProvisionInteractionParams(appDefinitionId, options)
    )
    return new Promise<any>(function (resolve, reject) {
        if (generalInfo.isTemplate(ps)) {
            reject(new Error('cannot add apps on template'))
            return
        }
        if (!appDefinitionId) {
            reject(new Error('options must contain appDefinitionId'))
            return
        }
        if (appDefinitionId === constants.APPS.WIX_CODE.appDefId) {
            if (!workerService.isInitiated()) {
                reject(workerService.getNotInitError(ps, 'failed provisioning wix code'))
                return
            }
            wixCode.provision(ps, {
                onSuccess: resolve,
                onError: reject
            })
            return
        }

        const resolveWithEnd = getOnSuccessWithFallback('provision', reject, arg => {
            ps.extensionAPI.logger.interactionEnded(
                dsConstants.PLATFORM_INTERACTIONS.PROVISION,
                getProvisionInteractionParams(appDefinitionId, options)
            )
            resolve(arg)
        })
        addApp(ps, appDefinitionId, options, resolveWithEnd, reject)
    })
}

async function update(ps: PS, applicationId: string | number, applicationVersion: string, options?) {
    if (workerService.isInitiated()) {
        return await updateApp(ps, applicationId, applicationVersion, options)
    }
    return Promise.reject('workerService is not initiated')
}

async function updateApp(ps: PS, applicationId: string | number, applicationVersion: string, options) {
    const existingAppData = clientSpecMapService.getAppData(ps, applicationId)
    if (!existingAppData) {
        return Promise.reject(
            'Application with the given applicationId is not installed. Please provision before updating'
        )
    }

    if (applicationVersion === existingAppData.appFields.installedVersion) {
        return onUpdateSuccess(ps, options, existingAppData)
    }

    const updatedClientSpecMap = await appStoreService.update(ps, [
        {
            applicationId,
            appVersion: applicationVersion
        }
    ])

    return onUpdateSuccess(
        ps,
        options,
        _.find(updatedClientSpecMap, {appDefinitionId: existingAppData.appDefinitionId})
    )
}

async function onUpdateSuccess(ps: PS, options, appData) {
    const reportProgress = _.get(options, 'reportProgress')
    if (reportProgress) {
        reportProgress({step: 'clientSpecMap'})
    }

    clientSpecMap.registerAppData(ps, appData)

    if (experiment.isOpen('dm_uninstallCodePackages') && hasCodePackage(appData)) {
        await wixCode.codePackages.installCodeReusePkg(ps, appData.appDefinitionId, appData.version)
    }

    try {
        await workerService.notifyAppUpdated(ps, appData.appDefinitionId, options)

        return appData
    } finally {
        hooks.executeHook(hooks.HOOKS.PLATFORM.APP_UPDATED, '', [ps, appData])
    }
}

function addApp(ps: PS, appDefinitionId: string, options, resolve, reject) {
    const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
    if (!clientSpecMapService.isAppActive(ps, existingAppData)) {
        //when coming from presets
        if (_.get(options, 'sourceTemplateId')) {
            extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_WITH_SOURCE_TEMPLATE_ID)
            appStoreService.provisionAppFromSourceTemplate(
                ps,
                appDefinitionId,
                options.sourceTemplateId,
                _.partial(onPreSaveProvisionSuccess, ps, resolve, reject, options),
                reject
            )
        } else {
            appStoreService.provision(
                ps,
                [
                    {
                        appDefinitionId,
                        version: _.get(options, 'appVersion')
                    }
                ],
                getOnSuccessWithFallback('addApp', reject, provisionResponse => {
                    const csm = provisionResponse.clientSpecMap
                    const appData = _.find(csm, {appDefinitionId})
                    if (appData) {
                        const origin_instance_id = _.get(options, 'origin_instance_id')
                        if (origin_instance_id) {
                            const {copyDataFromOriginTemplateByApp} = copyDataFromTemplate
                            copyDataFromOriginTemplateByApp(ps, appDefinitionId, appData, {origin_instance_id})
                                .then(() => {
                                    extendOptionWithInternalOrigin(
                                        options,
                                        constants.ADD_APP_ORIGINS.ADD_APP_WITH_COPY_DATA_SUCC
                                    )
                                    onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                                })
                                .catch(() => {
                                    extendOptionWithInternalOrigin(
                                        options,
                                        constants.ADD_APP_ORIGINS.ADD_APP_WITH_COPY_DATA_ERROR
                                    )
                                    onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                                })
                        } else {
                            extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP)
                            onPreSaveProvisionSuccess(ps, resolve, reject, options, appData)
                        }
                    } else {
                        reject('Application wasnt installed')
                    }
                }),
                reject
            )
        }
    } else {
        extendOptionWithInternalOrigin(options, constants.ADD_APP_ORIGINS.ADD_APP_EXISTING)
        onPreSaveProvisionSuccess(ps, resolve, reject, options, existingAppData)
    }
}

function onPreSaveProvisionSuccess(ps: PS, resolve, reject, options: any = {}, appData?) {
    const appDefinitionId = _.get(appData, 'appDefinitionId')
    const installationOriginInfo = getOriginInfo(appDefinitionId, options)
    const existingAppData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)

    ps.extensionAPI.logger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.ON_PRE_SAVE_PROVISION, {
        tags: {
            origin_info: installationOriginInfo,
            appDefinitionId
        },
        extras: {app_id: appDefinitionId, firstInstall: options?.firstInstall}
    })

    if (!clientSpecMapService.isAppActive(ps, existingAppData)) {
        clientSpecMap.registerAppData(ps, appData)
    }

    if (!clientSpecMapService.hasEditorPlatformPart(appData)) {
        const addAppResponse = _.assign(
            {
                success: true,
                type: 'addAppCompleted'
            },
            appData
        )

        ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ON_PRE_SAVE_PROVISION, {
            tags: {
                origin_info: installationOriginInfo,
                appDefinitionId
            },
            extras: {
                app_id: appDefinitionId,
                addAppResponse: addAppResponse?.success,
                firstInstall: options?.firstInstall
            }
        })
        resolve(addAppResponse)
    } else {
        if (!workerService.isInitiated()) {
            const err = workerService.getNotInitError(ps, 'onPreSaveProvisionSuccess')
            reject(err)
            return
        }
        appData.firstInstall = true
        if (options.isSilent) {
            appData.silentInstallation = true
        }

        if (options.headlessInstallation) {
            appData.headlessInstallation = true
        }

        appData.origin = _.get(options, 'origin')
        if (!appData.origin || typeof appData.origin !== 'object') {
            appData.origin = {}
        }
        appData.origin.type = appData.origin.type || originService.getEditorType()
        appData.biData = _.get(options, 'biData')

        appData.settings = _.get(options, 'settings')

        workerServiceAddAppWrapper(ps, appData, options)
            .then((addAppResponse: any) => {
                ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.ON_PRE_SAVE_PROVISION, {
                    tags: {
                        origin_info: installationOriginInfo,
                        appDefinitionId
                    },
                    extras: {
                        app_id: appDefinitionId,
                        addAppResponse: addAppResponse?.success,
                        firstInstall: appData?.firstInstall
                    }
                })
                resolve(addAppResponse)
            })
            .catch(addAppError => {
                ps.extensionAPI.logger.captureError(addAppError, {
                    tags: {
                        appDefinitionId,
                        origin_info: installationOriginInfo
                    },
                    extras: {
                        errName: dsConstants.PLATFORM_ERRORS.ON_PRE_SAVE_PROVISION,
                        app_id: appDefinitionId,
                        originalError: addAppError,
                        firstInstall: appData?.firstInstall
                    }
                })
                reject(addAppError)
            })
    }
}

async function onRemoteProvision(ps: PS, appData) {
    if (platformAppDataGetter.hasAppManifest(ps, appData.appDefinitionId)) {
        return
    }

    if (!clientSpecMapService.hasEditorPlatformPart(appData)) {
        return
    }

    if (!workerService.isInitiated()) {
        return Promise.reject(workerService.getNotInitError(ps, 'onRemoteProvision'))
    }

    appData.firstInstall = false
    appData.silentInstallation = true

    if (!appData.origin) {
        appData.origin = {type: originService.getEditorType()}
    }

    await workerServiceAddAppWrapper(ps, appData, {origin: 'onRemoveProvision'})
}

async function workerServiceAddAppWrapper(ps: PS, appData, options) {
    const installationOriginInfo = getOriginInfo(appData.appDefinitionId, options)
    const serviceTopology = ps.dal.get(ps.pointers.general.getServiceTopology())
    const appDataWithResolvedUrl = platformInit.specMapUtils.resolveEditorScriptUrl(appData, {
        clientSpec: appData,
        serviceTopology
    })

    return await new Promise((resolve, reject) => {
        ps.extensionAPI.logger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.WORKER_ADD_APP, {
            tags: {
                origin_info: installationOriginInfo,
                appDefinitionId: appData.appDefinitionId
            },
            extras: {
                appDefinitionId: appData.appDefinitionId,
                origin: _.get(options, 'origin'),
                internalOrigin: _.get(options, 'internalOrigin'),
                firstInstall: appData?.firstInstall
            }
        })
        workerService.addApp(
            ps,
            appDataWithResolvedUrl,
            function (data) {
                const resultData = _.assign(appDataWithResolvedUrl, data || {})
                ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.WORKER_ADD_APP, {
                    tags: {
                        origin_info: installationOriginInfo,
                        appDefinitionId: appData.appDefinitionId
                    },
                    extras: {
                        appDefinitionId: appData.appDefinitionId,
                        resultData: data,
                        firstInstall: appData?.firstInstall
                    }
                })
                ps.extensionAPI.logger.interactionStarted(dsConstants.PLATFORM_INTERACTIONS.AFTER_APP_ADDED_ACTIONS, {
                    tags: {
                        origin_info: installationOriginInfo,
                        appDefinitionId: appData.appDefinitionId
                    },
                    extras: {
                        appDefinitionId: appData.appDefinitionId,
                        firstInstall: appData?.firstInstall
                    }
                })
                if (_.get(resultData, 'success')) {
                    hooks.executeHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, '', [ps])
                    ps.extensionAPI.logger.interactionEnded(dsConstants.PLATFORM_INTERACTIONS.AFTER_APP_ADDED_ACTIONS, {
                        tags: {
                            origin_info: installationOriginInfo,
                            appDefinitionId: appData.appDefinitionId
                        },
                        extras: {
                            appDefinitionId: appData.appDefinitionId,
                            firstInstall: appData?.firstInstall
                        }
                    })
                    resolve(resultData)
                } else {
                    permissionsUtils.shouldAvoidRevoking({ps}).then(shouldAvoidRevoking => {
                        if (shouldAvoidRevoking) {
                            platformStateService.setUnusedApps(ps, [appData])
                        } else {
                            platformStateService.setAppPendingAction(
                                ps,
                                appData.appDefinitionId,
                                constants.APP_ACTION_TYPES.REMOVE
                            )
                        }

                        clientSpecMap.registerAppData(ps, appData)
                        hooks.executeHook(hooks.HOOKS.PLATFORM.APP_PROVISIONED, '', [ps])
                        ps.extensionAPI.logger.captureError(
                            new ReportableError({
                                errorType: 'workerServiceAddAppError',
                                message: 'workerService.addApp error'
                            }),
                            {
                                tags: {
                                    origin_info: installationOriginInfo,
                                    appDefinitionId: appData.appDefinitionId,
                                    handleAppProvisionedOrUpdated: true
                                },
                                extras: {
                                    errName: dsConstants.PLATFORM_ERRORS.AFTER_APP_ADDED_ACTIONS,
                                    appDefinitionId: appData.appDefinitionId,
                                    resultData: data,
                                    firstInstall: appData?.firstInstall
                                }
                            }
                        )
                        reject(_.defaults({success: false}, resultData))
                    })
                }
            },
            options
        )
    })
}

export default {
    provision,
    update,
    onPreSaveProvisionSuccess,
    onRemoteProvision,
    extendOptionWithInternalOrigin
}
