'use strict'

const _ = require('lodash')
const menuFixer = require('../plugins/menuFixer')

let bmiCnt = 0 //eslint-disable-line @wix/santa/no-module-state
let pglkCnt = 0 //eslint-disable-line @wix/santa/no-module-state

const BMI = 'bmi'
const PGLK = 'pglk'

function convertOldMenuToNewMenu(rawData, siteData) {
    return _.map(rawData, function (data) {
        return saveMenuItem(siteData, data)
    })
}

function saveMenuItem(siteData, data) {
    const id = BMI + bmiCnt++
    const pageData = siteData[sanitizeId(data.refId)]
    siteData[id] = {
        id,
        label: pageData.title,
        isVisible: !pageData.hidePage,
        isVisibleMobile: pageData.mobileHidePage !== undefined ? !pageData.mobileHidePage : !pageData.hidePage,
        items: convertOldMenuToNewMenu(data.items, siteData),
        link: saveLinkItem(siteData, data.refId),
        type: 'BasicMenuItem',
        metaData: {}
    }

    return `#${id}`
}

function saveLinkItem(siteData, refId) {
    const id = PGLK + pglkCnt++

    siteData[id] = {
        id,
        type: 'PageLink',
        pageId: refId,
        metaData: {}
    }

    return `#${id}`
}

function getDataItem(documentData, id) {
    return id && documentData[sanitizeId(id)]
}

function setAsMenuHeader(basicMenuItem) {
    delete basicMenuItem.link
}

function getPageLink(documentData, basicMenuItem) {
    const linkItem = getDataItem(documentData, basicMenuItem.link)
    return _.get(linkItem, 'type') === 'PageLink' ? linkItem : undefined
}

function isInvalidPageLink(documentData, basicMenuItem) {
    const pageLink = getPageLink(documentData, basicMenuItem)
    return pageLink && !getDataItem(documentData, pageLink.pageId)
}

function dataItemExists(documentData, query) {
    return !!getDataItem(documentData, query)
}

function isInvalidLink(documentData, basicMenuItem) {
    return (
        basicMenuItem.link &&
        (basicMenuItem.link === '#CUSTOM_MENU_HEADER' ||
            !dataItemExists(documentData, basicMenuItem.link) ||
            isInvalidPageLink(documentData, basicMenuItem))
    )
}

function convertMenuItemsOfNonExistingPagesToHeaders(documentData, dataItem) {
    dataItem.items = _.filter(dataItem.items, function (basicMenuItemId) {
        return dataItemExists(documentData, basicMenuItemId)
    })

    _.forEach(dataItem.items, function (basicMenuItemId) {
        const basicMenuItem = getDataItem(documentData, basicMenuItemId)
        if (isInvalidLink(documentData, basicMenuItem)) {
            setAsMenuHeader(basicMenuItem)
        }
        convertMenuItemsOfNonExistingPagesToHeaders(documentData, basicMenuItem)
    })
}

function removeMenuDataItems(pageJson) {
    _(pageJson.data.document_data)
        .filter({type: 'Menu'})
        .map('id')
        .forEach(function (pageId) {
            delete pageJson.data.document_data[pageId]
        })
}

function isJsonForPage(pageJson) {
    return _.get(pageJson, 'structure.type') === 'Page'
}

function sanitizeId(str) {
    return _.replace(str, '#', '')
}

function addMissingPageIdsToMenu(docData, pageIdsInSite) {
    const customMenuItems = docData.CUSTOM_MAIN_MENU.items
    const pageIdsInMenu = _(docData)
        .filter({type: 'BasicMenuItem'})
        .map('link')
        .compact()
        .map(sanitizeId)
        .map(linkItemId => docData[linkItemId])
        .filter({type: 'PageLink'})
        .map('pageId')
        .compact()
        .map(sanitizeId)
        .value()

    const pagesManagedByApp = _(docData)
        .filter(dataItem => dataItem.type === 'Page' && dataItem.managingAppDefId)
        .map('id')
        .value()

    const allPagesMissingFromMenu = _.difference(pageIdsInSite, pageIdsInMenu)
    const regularPagesMissingFromMenu = _.difference(allPagesMissingFromMenu, pagesManagedByApp)

    _.forEach(regularPagesMissingFromMenu, function (missingPageId) {
        if (docData[missingPageId] && !docData[missingPageId].isPopup) {
            customMenuItems.push(createMenuItem(docData, missingPageId))
        }
    })
}

/**
 * Deletes the link from the menu item if the page it links to doesn't exist or if it doesn't exist in the full site's document_data
 *
 * @param {object} fullData The main sites' `document_data`
 * @param {object} currentDocumentData The current `document_data`, can be the main one, or one of the translated languages data
 * @param {string[]} pageIds Array of page IDs in the site
 */
function purgeMenus(fullData, currentDocumentData, pageIds) {
    _(currentDocumentData)
        .filter({type: 'BasicMenuItem'})
        .filter('link')
        .forEach(menuItem => {
            const linkId = sanitizeId(menuItem.link)
            const linkData = currentDocumentData[linkId] || fullData[linkId]

            if (!linkData || (linkData.type === 'PageLink' && !pageIds[sanitizeId(linkData.pageId)])) {
                delete currentDocumentData[linkId]
                delete menuItem.link
            }
        })
}

/**
 * Converts menu items which links to a non-existing page to folders
 *
 * @param {object} pageJson The full site JSON representation
 * @param {string[]} pageIdsInSite Array of page IDs present on the site
 */
function convertMenuItemsWithBrokenLinksToFolders(pageJson, pageIdsInSite) {
    const pageSet = _.mapKeys(pageIdsInSite)
    // @ts-ignore
    purgeMenus(pageJson.data.document_data, pageJson.data.document_data, pageSet)
    _.forOwn(pageJson.translations, translationLanguage =>
        // @ts-ignore
        purgeMenus(pageJson.data.document_data, translationLanguage.data.document_data, pageSet)
    )
}

function createMenuItem(siteData, pageId) {
    const id = BMI + bmiCnt++
    const pageData = siteData[pageId]
    siteData[id] = {
        id,
        label: pageData.title,
        isVisible: !pageData.hidePage,
        isVisibleMobile: pageData.mobileHidePage !== undefined ? !pageData.mobileHidePage : !pageData.hidePage,
        items: [],
        link: saveLinkItem(siteData, `#${pageId}`),
        type: 'BasicMenuItem',
        metaData: {}
    }

    return `#${id}`
}

function getInitDataIndexCounts(siteData, countId) {
    const indexedDataItems = _.filter(siteData, function (item) {
        return item.id && item.id.indexOf(countId) === 0
    })
    let maxBmiId = -1
    _.forEach(indexedDataItems, function (item) {
        const currentIndex = Number(item.id.replace(countId, ''))
        if (currentIndex > maxBmiId) {
            maxBmiId = currentIndex
        }
    })
    return ++maxBmiId
}

function menuWalker(pageJson, pageIdsArray) {
    const pageIdswithPointingBMI = {}

    function removeInvalidSubMenus(menuItem) {
        const addChildrenOfDeletedToMenu = deletionCandidate => {
            if (!_.isEmpty(deletionCandidate.items)) {
                const validDeleteCandidateItems = _.filter(deletionCandidate.items, isValidItem)
                menuItem.items = _.concat(menuItem.items, validDeleteCandidateItems)
            }
        }

        const originalMenuItemItems = menuItem.items
        menuItem.items = _.filter(menuItem.items, isValidItem)
        const invalidItems = _.difference(originalMenuItemItems, menuItem.items)

        if (!_.isEmpty(invalidItems)) {
            _(invalidItems)
                .map(item => getDataItem(pageJson.data.document_data, item))
                .forEach(addChildrenOfDeletedToMenu)
        }

        _(menuItem.items)
            .map(itemId => getDataItem(pageJson.data.document_data, itemId))
            .forEach(removeInvalidSubMenus)
    }

    function isValidItem(menuItemId) {
        const masterPageData = pageJson.data.document_data
        const menuItem = getDataItem(masterPageData, menuItemId)
        const hasNoLink = () => !menuItem.link
        const linksToNonPage = () => !getPageLink(masterPageData, menuItem)
        const isDuplicatePageLinkItem = () => {
            const linkI = getDataItem(masterPageData, menuItem.link)
            const lnkPageId = _.get(linkI, 'pageId')
            if (_.has(pageIdswithPointingBMI, sanitizeId(lnkPageId))) {
                return true
            }
            pageIdswithPointingBMI[sanitizeId(lnkPageId)] = menuItemId
            return false
        }

        const pointsToAnExistingPage = () => {
            const linkItem = getDataItem(masterPageData, menuItem.link)
            const linkedPageId = _.get(linkItem, 'pageId')
            return _.includes(pageIdsArray, sanitizeId(linkedPageId))
        }
        return hasNoLink() || linksToNonPage() || (pointsToAnExistingPage() && !isDuplicatePageLinkItem())
    }
    return {removeInvalidSubMenues: removeInvalidSubMenus}
}

module.exports = {
    name: 'customSiteMenuFixer',
    version: 1,
    exec(pageJson, pageIdsArray) {
        if (isJsonForPage(pageJson)) {
            removeMenuDataItems(pageJson)
            return
        }
        menuFixer.exec(pageJson, pageIdsArray)

        const docData = pageJson.data.document_data
        bmiCnt = getInitDataIndexCounts(docData, BMI)
        pglkCnt = getInitDataIndexCounts(docData, PGLK)

        if (!pageJson.data.document_data.CUSTOM_MAIN_MENU) {
            pageJson.data.document_data.CUSTOM_MAIN_MENU = {
                id: 'CUSTOM_MAIN_MENU',
                items: convertOldMenuToNewMenu(
                    pageJson.data.document_data.MAIN_MENU.items,
                    pageJson.data.document_data
                ),
                name: 'Custom Main Menu',
                type: 'CustomMenu',
                metaData: {}
            }
        }

        if (!pageJson.data.document_data.CUSTOM_MENUS) {
            pageJson.data.document_data.CUSTOM_MENUS = {
                id: 'CUSTOM_MENUS',
                menus: ['#CUSTOM_MAIN_MENU'],
                type: 'CustomMenusCollection',
                metaData: {}
            }
        } else if (!_.includes(pageJson.data.document_data.CUSTOM_MENUS.menus, '#CUSTOM_MAIN_MENU')) {
            pageJson.data.document_data.CUSTOM_MENUS.menus.push('#CUSTOM_MAIN_MENU')
        }
        addMissingPageIdsToMenu(docData, pageIdsArray)

        docData.CUSTOM_MAIN_MENU.items = _.uniq(docData.CUSTOM_MAIN_MENU.items) //fix for CLNT-8914 - ensure custom main menu items id uniqueness

        convertMenuItemsOfNonExistingPagesToHeaders(docData, docData.CUSTOM_MAIN_MENU)

        if (pageJson.data.document_data.MAIN_MENU.items.length > 0) {
            pageJson.data.document_data.MAIN_MENU.items = []
        }

        const menuItem = pageJson.data.document_data.CUSTOM_MAIN_MENU
        menuWalker(pageJson, pageIdsArray).removeInvalidSubMenues(menuItem)

        convertMenuItemsWithBrokenLinksToFolders(pageJson, pageIdsArray)
    }
}
