import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'

function isVerticalResize(newLayout, currLayout) {
    if (newLayout && !_.isUndefined(newLayout.height) && currLayout) {
        return newLayout.height !== currLayout.height
    }
}

export default function (ps: PS, compPointer, newLayout) {
    if (isVerticalResize(newLayout, component.layout.get(ps, compPointer))) {
        component.properties.update(ps, compPointer, {
            minHeight: newLayout.height
        })
    }
}
