import _ from 'lodash'
import {fonts} from '@wix/santa-core-utils'

const {getFontFamilyPermissions, isUploadedFontFamily} = fonts

const THEME_COLOR_PATTERN = 'color_([0-9]{1,2}|100)'
const THEME_COLOR_REGEXES = [
    ['', ''],
    ['\\[', ']'],
    ['{', '}']
]
    .map(([open, close]) => `^${open}${THEME_COLOR_PATTERN}${close}$`)
    .map(pattern => new RegExp(pattern))

const FONT_POSSIBLE_VALUES = {
    STYLE: ['normal', 'italic'],
    VARIANT: ['normal', 'small-caps', 'inherit'],
    WEIGHT: ['normal', 'bold', 'bolder', 'lighter'],
    UNITS: ['px', 'em', 'pt', 'ex', 'in', 'cm', 'mm', 'pc']
}

function validateFont(fontStr: string, allColors?) {
    const result: {isValid: boolean; error: Error | null} = {isValid: false, error: null}
    const split = fontStr.split(' ')
    if (split.length !== 6) {
        result.error = new Error(
            "font format isn't correct please supply following format: font-style font-variant font-weight font-size/line-height font-family color"
        )
        return result
    }
    const isStyleValid = _.includes(FONT_POSSIBLE_VALUES.STYLE, split[0])
    if (!isStyleValid) {
        result.error = new Error(`font-style isn't valid. possible values are: ${FONT_POSSIBLE_VALUES.STYLE}`)
        return result
    }
    const isVariantValid = _.includes(FONT_POSSIBLE_VALUES.VARIANT, split[1])
    if (!isVariantValid) {
        result.error = new Error(`font-variant isn't valid. possible values are: ${FONT_POSSIBLE_VALUES.VARIANT}`)
        return result
    }
    const isWeightValid = _.includes(FONT_POSSIBLE_VALUES.WEIGHT, split[2])
    if (!isWeightValid) {
        result.error = new Error(`font-weight isn't valid. possible values are: ${FONT_POSSIBLE_VALUES.WEIGHT}`)
        return result
    }
    const sizeSplit = split[3] ? split[3].split('/') : []
    const isSizeValid = checkIfSizeStringIsValid(sizeSplit[0])
    if (!isSizeValid) {
        result.error = new Error(
            `font-size isn't valid.Correct format is: fontSize+unit.Possible units allowed are: ${FONT_POSSIBLE_VALUES.UNITS}`
        )
        return result
    }
    const isLineHeightValid = checkIfSizeStringIsValid(sizeSplit[1])
    if (!isLineHeightValid) {
        result.error = new Error(
            `line-height isn't valid.Correct format is: lineHeight+unit.Possible units allowed are: ${FONT_POSSIBLE_VALUES.UNITS}`
        )
        return result
    }
    const fontFamily = split[4]?.replace(/\+/g, ' ').toLowerCase()
    const isFontFamilyValid = !_.isUndefined(getFontFamilyPermissions(fontFamily)) || isUploadedFontFamily(fontFamily)
    if (!isFontFamilyValid) {
        result.error = new Error(`font family isn't correct ${split[4]}`)
        return result
    }
    const isColorValid = isColorInSchema(split[5], allColors) || validateColor(split[5])
    if (!isColorValid) {
        result.error = new Error("color isn't in correct format.Correct format is {color_5} or rgba/hex")
        return result
    }
    result.isValid =
        isStyleValid &&
        isVariantValid &&
        isWeightValid &&
        isSizeValid &&
        isLineHeightValid &&
        isFontFamilyValid &&
        isColorValid
    return result
}

function isThemeColor(color: string) {
    return THEME_COLOR_REGEXES.some(reg => reg.test(color))
}

function validateColor(colorToValidate: string) {
    if (colorToValidate) {
        const isHexColor = validateHexColor(colorToValidate)
        const isRGBAColor = rgbaColor(colorToValidate)
        return isThemeColor(colorToValidate) || isHexColor || isRGBAColor
    }
    return false
}

function validateHexColor(hexColor: string) {
    return !!hexColor && /^#(([0-9|a-f|A-F]){3}){1,2}$/.test(hexColor)
}

function rgbaColor(value: string) {
    if (value === null) {
        return true
    }
    const split = value.split(',')

    if (split.length !== 3 && split.length !== 4) {
        return false
    }

    const alpha = split[3] as unknown as number
    const validRgb = _.every(split.slice(0, 3) as unknown as number[], number => number >= 0 && number <= 255)

    if (!validRgb) {
        return false
    }

    if (alpha) {
        return alpha >= 0 && alpha <= 1
    }

    return true
}

function isColorInSchema(color, allColors) {
    if (!(color[0] === '{' && color[color.length - 1] === '}')) {
        return false
    }
    let colorName = color.substring(1, color.length - 1)
    colorName = colorName.replace(/_/g, '')
    return _.includes(Object.keys(allColors), colorName)
}

function checkIfSizeStringIsValid(sizeStr) {
    sizeStr = String(sizeStr)
    const size = parseFloat(sizeStr)
    const unit = sizeStr.split(String(size)).join('')
    const isSizeValue = !isNaN(size)
    const isUnitValue = _.includes(FONT_POSSIBLE_VALUES.UNITS, unit)
    return isSizeValue && isUnitValue
}

export default {
    validateColor,
    validateHexColor,
    validateFont,
    FONT_POSSIBLE_VALUES: _.cloneDeep(FONT_POSSIBLE_VALUES)
}
