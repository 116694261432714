/**
 * Created by talm on 18/08/15.
 */
import _ from 'lodash'
import dataModel from '../../dataModel/dataModel'
import * as santaCoreUtils from '@wix/santa-core-utils'
import dsUtils from '../../utils/utils'

function setLinksCustomData(ps, compPointer, customStructureData) {
    const repeatedComponents = ps.pointers.components.getAllDisplayedOnlyComponents(compPointer)

    _.forEach(repeatedComponents, componentPointer => {
        const compData = dataModel.getDataItem(ps, componentPointer)
        const repeatedItemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(componentPointer.id)
        const linkListKey = repeatedItemId ? repeatedItemId : componentPointer.id

        if (_.has(compData, 'linkList') && compData.linkList.length > 0) {
            _.set(customStructureData, ['linkList', linkListKey], compData.linkList)
            _.set(customStructureData, 'originalCompId', linkListKey)
        }
    })

    return {success: true, description: 'Added text links to the custom component structure'}
}

function updateComponentData(ps, compPointer, fixedText, newLinks) {
    const compData = dataModel.getDataItem(ps, compPointer)
    compData.text = fixedText
    compData.linkList = newLinks
    dataModel.updateDataItem(ps, compPointer, compData)
}

function duplicateLinksDataItems(ps, compPointer, compDefinition) {
    if (!compDefinition.custom?.linkList) {
        return {success: true, description: 'Duplicated text links data items'}
    }

    const repeatedComponents = ps.pointers.components.getAllDisplayedOnlyComponents(compPointer)

    _.forEach(repeatedComponents, componentPointer => {
        const repeatedItemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(componentPointer.id)
        const linkListKey = repeatedItemId ? repeatedItemId : compDefinition.custom.originalCompId

        const repeatedData = repeatedItemId ? compDefinition.data.overrides[repeatedItemId] : compDefinition.data
        const newLinks = _.cloneDeep(compDefinition.custom.linkList[linkListKey])
        createTextLinkReferences(ps, componentPointer, newLinks, repeatedData.text)
    })

    return {success: true, description: 'Duplicated text links data items'}
}

const createTextLinkReferences = (ps, compPointer, newLinkList, text) => {
    let textToFix = _.clone(text)
    _.forEach(newLinkList, linkData => {
        if (linkData.id) {
            const newId = santaCoreUtils.guidUtils.getUniqueId('link', '-', {bucket: 'links'})
            textToFix = textToFix.replace(`#${linkData.id}`, `#${newId}`)
            linkData.id = newId
        }
    })

    updateComponentData(ps, compPointer, textToFix, newLinkList)
}

const fixTextLinkAccordingToLinkList = (ps, compPointer, text, originalLinkList, newLinkList) => {
    let textToFix = _.clone(text)
    _.forEach(originalLinkList, (linkData, index) => {
        const oldLinkId = linkData.id
        const newLinkId = newLinkList[index].id
        textToFix = textToFix.replace(`#${oldLinkId}`, `#${newLinkId}`)
    })
    return textToFix
}

const fixTextLinksAfterSetContainerToRepater = (ps, componentPointer, newContainerPointer) => {
    const isAttachedToRepeater = santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(newContainerPointer.id)

    if (isAttachedToRepeater) {
        const allRepeatedItems = ps.pointers.components.getAllDisplayedOnlyComponents(componentPointer)
        const originalComponentData = dataModel.getDataItem(ps, componentPointer)

        _.forEach(allRepeatedItems, repeatedCompPointer => {
            const repeatedData = dataModel.getDataItem(ps, repeatedCompPointer)
            const fixedText = fixTextLinkAccordingToLinkList(
                ps,
                componentPointer,
                repeatedData.text,
                originalComponentData.linkList,
                repeatedData.linkList
            )
            updateComponentData(ps, repeatedCompPointer, fixedText, repeatedData.linkList)
        })
    }
}

const handleVerticalTextPropertyChange = (ps, componentPointer, updatedProperties) => {
    const currentProperties = dataModel.getPropertiesItem(ps, componentPointer)

    if (!currentProperties) {
        return
    }
    const currentVerticalText = currentProperties.verticalText
    const nextVerticalText = updatedProperties.verticalText
    const isNotEqualToCurrent = currentVerticalText !== nextVerticalText

    if (typeof nextVerticalText !== 'undefined' && isNotEqualToCurrent) {
        dataModel.updatePropertiesItem(ps, componentPointer, {packed: false})
    }
}

const cleanUnusedLinks = (ps, compPointer, dataItem) => {
    dataItem.linkList = _.filter(dataItem.linkList, ({id}) => _.includes(dataItem.text, dsUtils.stripHashIfExists(id)))
}

export default {
    cleanUnusedLinks,
    setLinksCustomData,
    fixTextLinksAfterSetContainerToRepater,
    duplicateLinksDataItems,
    handleVerticalTextPropertyChange
}
