function createRegistrar() {
    const customNicknameGetters: Record<string, any> = {}

    return {
        registerCustomGetter(componentType: string, getter) {
            customNicknameGetters[componentType] = getter
        },
        getCustomGetter(componentType: string) {
            return customNicknameGetters[componentType]
        }
    }
}

export default createRegistrar()
