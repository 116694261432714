import type {DocumentServicesDal, Pointer, Pointers, SingleLayoutData} from '@wix/document-services-types'

const PAGE_TYPE = 'mobile.core.components.Page'
const pageResponsiveLayout: Omit<SingleLayoutData, 'id'> = {
    componentLayout: {
        type: 'ComponentLayout',
        height: {
            type: 'auto'
        }
    },
    containerLayout: {
        type: 'MeshContainerLayout'
    },
    itemLayout: {},
    type: 'SingleLayoutData'
}
export const mockConvertAbsoluteLayoutToResponsive = (
    dal: DocumentServicesDal,
    pointers: Pointers,
    componentPointer: Pointer
): Omit<SingleLayoutData, 'id'> => {
    const absoluteLayout = dal.get({...componentPointer, innerPath: ['layout']})
    const compType = dal.get(componentPointer).componentType
    if (compType === PAGE_TYPE) {
        return pageResponsiveLayout
    }
    const parentId = pointers.components.getParent(componentPointer).id
    const {x, y, height, width, rotationInDegrees} = absoluteLayout
    return {
        componentLayout: {
            type: 'ComponentLayout',
            rotationInDegrees,
            height: {
                type: 'px',
                value: height
            },
            width: {
                type: 'px',
                value: width
            }
        },
        containerLayout: {
            type: 'MeshContainerLayout'
        },
        itemLayout: {
            left: {value: x, type: 'px'},
            topAnchors: [
                {
                    distance: {value: y, type: 'px'},
                    anchorType: 'PARENT_TOP',
                    from: parentId
                }
            ],
            type: 'MeshItemLayout'
        },
        type: 'SingleLayoutData'
    }
}
