import _ from 'lodash'
import pointerGeneratorsRegistry from './pointerGeneratorsRegistry'

const type = 'partners'
pointerGeneratorsRegistry.registerPointerType(type, _.constant(null), _.constant(true))
function getIsPotentialPartnerPointer(getItemAt, cache) {
    return cache.getPointer('isPotentialPartner', type, [type])
}
const getterFunctions = {
    getIsPotentialPartnerPointer
}

pointerGeneratorsRegistry.registerDataAccessPointersGenerator(type, getterFunctions)
