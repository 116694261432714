let _state = null
let _saveRequested: boolean = false

function getAnotherSaveRequested() {
    return _saveRequested
}

function setAnotherSaveRequested(bool: boolean) {
    _saveRequested = bool
}

function getState() {
    return _state
}

function setState(state) {
    _state = state
}

export default {
    getState,
    setState,
    getAnotherSaveRequested,
    setAnotherSaveRequested
}
