import type {
    ViewerAPI,
    ComponentBoundingBox,
    ComponentPadding,
    RuntimeLayout,
    IForceStateParams,
    TransitionDef,
    GridMeasures
} from '@wix/viewer-manager-interface'
import type {
    BIEvt,
    BIParams,
    BoundingBox,
    Callback,
    Point,
    Pointer,
    Rect,
    TransformationOverrides
} from '@wix/document-services-types'
import _ from 'lodash'
import {getViewMode} from './structureUtils'
import type {ResponsiveViewerAdapterAPI, ViewerManagerAdapterConfig, ViewerSiteAPI} from './types'
import {createScopedPointer} from './scopesUtils'
import {pointerUtils} from '@wix/document-manager-core'

const {getPointer} = pointerUtils

export interface ExtendedViewerAPI {
    convertIdToScopedPointer(id: string, enableRepeatersInScopes: boolean): Pointer
    getViewMode(): string
    switchModes(pointer: Pointer, modeIdToDeactivate: string, modeIdToActivate: string): void
    activateMode(pointer: Pointer, modeId: string): void
    deactivateMode(pointer: Pointer, modeId: string): void
    registerNavigationComplete(callback: Callback): void
    registerNavigationError(id: string, callback: Callback): void
    setLockedCompsForEnforceAnchors(comps: {[compId: string]: boolean}): void
    setFullLayoutNeeded(b: boolean): void
    forceGenerateAnchors(): void
    setIsGeneratingAnchors(isGenerating: boolean): void
    setIsEnforcingAnchors(isEnforcing: boolean): void
    createChildrenAnchors(parentStructure: any, parentPageId: string, op: {forceMobileStructure: boolean}): void
    unregisterNavigationError(id: string): void
    setPremiumNavigationHandler(callback: Callback): void
    // it can be undefined for classic
    responsive?: ResponsiveViewerAdapterAPI
}

// maybe it is better to have them as map in future
// this will make possible for us to provide injected config and include not only scopes
// but validate structure. Here for now we are using lodash ability to pick by path
const REQUIRED_SCOPES = [
    'navigation',
    'query',
    'actionsAndBehaviors',
    'updateStatus',
    'component',
    'componentDetector',
    'layout',
    'fonts',
    'livePreview',
    'media',
    'members',
    'pages',
    'platform',
    'animation',
    'scroll',
    'selectiveDownload',
    'stylable',
    'tpa',
    'viewMode',
    'legacy',
    'runtime',
    'runtimeLayout',
    'variants',
    'miniSites',
    'modes.getAllActiveModeIds',
    'modes.updateActiveSOSPModes',
    'modes.switchModes',
    'modes.activateMode',
    'modes.deactivateMode',
    'modes.resetAllActiveModes',
    'byRef.getStructure',
    'rendererModel.getClientSpecMap',
    'diagnostics',
    'data.getCompIdByDataQueryId',
    'scopes'
]

const getMockedLegacyImplementations = () => ({
    getPageMargins: () => ({bottom: 0, top: 0})
})

const getExtendedViewerAPI = (viewer: ViewerAPI, config?: ViewerManagerAdapterConfig): ExtendedViewerAPI => {
    const convertIdToScopedPointer = (id: string, enableRepeatersInScopes?: boolean) => {
        const pointer = getPointer(id, getViewMode(viewer))
        const scopePath = viewer.scopes.getScope(id)
        const scopedPointer = createScopedPointer(pointer, scopePath, enableRepeatersInScopes)

        return scopedPointer
    }

    const convertIdToPointer = (id: string) => {
        if (!config?.enableScopes) {
            return getPointer(id, getViewMode(viewer))
        }
        return convertIdToScopedPointer(id, config?.enableRepeatersInScopes)
    }

    const convertPointerToId = (compPointer: Pointer) => {
        return compPointer.id
    }

    const extApi = {
        convertIdToScopedPointer,
        getComponentsUnderXY: (x: number, y: number): Pointer[] =>
            viewer.componentDetector.getComponentsUnderXY(x, y).map(convertIdToPointer),
        isComponentRenderedOnSite: (compPointer: Pointer): boolean =>
            viewer.component.isComponentRenderedOnSite(convertPointerToId(compPointer)),
        getComponentBoundingBox: (compPointer: Pointer): ComponentBoundingBox =>
            viewer.layout.getComponentBoundingBox(convertPointerToId(compPointer)),
        getOriginalComponentBoundingBox: (compPointer: Pointer): ComponentBoundingBox =>
            viewer.layout.getOriginalComponentBoundingBox(convertPointerToId(compPointer)),
        getComponentInnerElementBoundingBoxes: (compPointer: Pointer, selector: string): ComponentBoundingBox[] =>
            viewer.layout.getComponentInnerElementBoundingBoxes(convertPointerToId(compPointer), selector),
        getRelativeToViewportBoundingBox: (compPointer: Pointer): ComponentBoundingBox =>
            viewer.layout.getRelativeToViewportBoundingBox(convertPointerToId(compPointer)),
        getPadding: (compPointer: Pointer): ComponentPadding =>
            viewer.layout.getPadding(convertPointerToId(compPointer)),
        getBasicMeasureForComp: (compPointer: Pointer): Rect =>
            viewer.layout.getBasicMeasureForComp(convertPointerToId(compPointer)),
        updateAndPushStart: (compPointer: Pointer): void =>
            viewer.layout.updateAndPushStart(convertPointerToId(compPointer)),
        updateAndPushUpdate: (compPointer: Pointer, runtimeLayout: RuntimeLayout): void =>
            viewer.layout.updateAndPushUpdate(convertPointerToId(compPointer), runtimeLayout),
        updateAndPushEnd: (compPointer: Pointer): void =>
            viewer.layout.updateAndPushEnd(convertPointerToId(compPointer)),
        registerStateChange: (listenerId: string, playerPointer: Pointer, callback: Callback): void =>
            viewer.media.registerStateChange(listenerId, convertPointerToId(playerPointer), callback),
        unregisterStateChange: (listenerId: string, playerPointer: Pointer): void =>
            viewer.media.unregisterStateChange(listenerId, convertPointerToId(playerPointer)),
        previewAnimation: (
            compPointer: Pointer,
            pageId: string,
            animationDef: TransitionDef,
            onComplete: Callback
        ): string | null => viewer.animation.previewAnimation(compPointer.id, pageId, animationDef, onComplete),
        responsive: viewer.responsive && {
            ...viewer.responsive,
            getScrollHeight: (compPointer: Pointer): number | null =>
                viewer.responsive.getScrollHeight(convertPointerToId(compPointer)),
            getClientHeight: (compPointer: Pointer): number | null =>
                viewer.responsive.getClientHeight(convertPointerToId(compPointer)),
            getScrollWidth: (compPointer: Pointer): number | null =>
                viewer.responsive.getScrollWidth(convertPointerToId(compPointer)),
            getClientWidth: (compPointer: Pointer): number | null =>
                viewer.responsive.getClientWidth(convertPointerToId(compPointer)),
            getGridMeasures: (compPointer: Pointer): GridMeasures | {} =>
                viewer.responsive.getGridMeasures(convertPointerToId(compPointer)),
            detach: (compPointer: Pointer): void => viewer.responsive.detach(convertPointerToId(compPointer)),
            detachMulti: (compPointers: Pointer[]): void =>
                viewer.responsive.detachMulti(compPointers.map(convertPointerToId)),
            updateDetached: (compPointer: Pointer, boundingBox: BoundingBox): void =>
                viewer.responsive.updateDetached(convertPointerToId(compPointer), boundingBox),
            updateDetachedRotation: (compPointer: Pointer, rotationInDegrees: number): void =>
                viewer.responsive.updateDetachedRotation(convertPointerToId(compPointer), rotationInDegrees),
            updateDetachedTransformation: (
                compPointer: Pointer,
                transformationOverrides: TransformationOverrides
            ): void =>
                viewer.responsive.updateDetachedTransformation(convertPointerToId(compPointer), transformationOverrides)
        },
        getViewMode: (): string => getViewMode(viewer),
        switchModes: (compPointer: Pointer, modeIdToDeactivate: string, modeIdToActivate: string): void => {
            viewer.modes.switchModes(convertPointerToId(compPointer), modeIdToDeactivate, modeIdToActivate)
        },
        activateMode: (compPointer: Pointer, modeId: string): void => {
            viewer.modes.activateMode(convertPointerToId(compPointer), modeId)
        },
        deactivateMode: (compPointer: Pointer, modeId: string): void => {
            viewer.modes.deactivateMode(convertPointerToId(compPointer), modeId)
        },
        registerNavigationComplete: (callback: Callback): void => {
            // eslint-disable-next-line promise/no-callback-in-promise
            viewer.navigation.waitForNavigation().then(() => callback())
        },
        registerNavigationError: (id: string, callback: Callback): void => {
            // eslint-disable-next-line promise/no-callback-in-promise
            viewer.navigation.waitForNavigation().catch(() => callback())
        },
        getTotalScroll: (compPointer: Pointer): Point => viewer.scroll.getTotalScroll(compPointer.id),
        scroll: (compPointer: Pointer, x: number, y: number, callback: Callback): void =>
            viewer.scroll.scroll(compPointer.id, x, y, callback),
        scrollBy: (compPointer: Pointer, x: number, y: number, callback: Callback): void =>
            viewer.scroll.scrollBy(compPointer.id, x, y, callback),
        setQuickChanges: (compPointer: Pointer, selector: string, declarationMap: Record<string, string>): void =>
            viewer.stylable.setQuickChanges(compPointer.id, selector, declarationMap),
        revertQuickChanges: (compPointer: Pointer): void => viewer.stylable.revertQuickChanges(compPointer.id),
        forceState: (compPointer: Pointer, forceStateParams: IForceStateParams): void =>
            viewer.stylable.forceState(compPointer.id, forceStateParams),
        revertForceState: (compPointer: Pointer): void => viewer.stylable.revertForceState(compPointer.id),
        setLockedCompsForEnforceAnchors: _.noop,
        setFullLayoutNeeded: _.noop,
        forceGenerateAnchors: _.noop,
        setIsGeneratingAnchors: _.noop,
        setIsEnforcingAnchors: _.noop,
        createChildrenAnchors: _.noop,
        unregisterNavigationError: _.noop,
        setPremiumNavigationHandler: _.noop,
        reportBI: (reportDef: BIEvt, params: BIParams = {}): void => {
            params.viewMode = extApi.getViewMode()
            window.biLogger?.log(reportDef, params)
        }
    }

    return extApi
}

export const buildViewerSiteAPI = (viewer: ViewerAPI, config?: ViewerManagerAdapterConfig): ViewerSiteAPI => {
    const value = _(viewer).pick(REQUIRED_SCOPES).values().value()
    const extendedViewerAPI = getExtendedViewerAPI(viewer, config)

    return _.merge({}, getMockedLegacyImplementations(), ...value, extendedViewerAPI)
}
