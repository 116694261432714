import type {PublicMethodUtils} from '@wix/document-services-types'
import {componentDetectorAPI, component} from '@wix/document-services-implementation'

export default function (publicMethodUtils: PublicMethodUtils) {
    return {
        methods: {
            deprecatedOldBadPerformanceApis: {
                components: {
                    getAllComponents: publicMethodUtils.defineGetter(componentDetectorAPI.getAllComponents),
                    getAllComponentsFromFull: publicMethodUtils.defineGetter(
                        componentDetectorAPI.getAllComponentsFromFull
                    ),
                    getChildren: publicMethodUtils.defineGetter(component.getChildrenWithScopes),
                    getChildrenFromFull: publicMethodUtils.defineGetter(component.getChildrenFromFull),
                    getContainer: publicMethodUtils.defineGetter(component.getContainer),
                    getAncestors: publicMethodUtils.defineGetter(component.getAncestors),
                    getRepeatedComponents: publicMethodUtils.defineGetter(component.getRepeatedComponents),
                    get: {
                        byXYRelativeToStructure: publicMethodUtils.defineGetter(
                            componentDetectorAPI.getComponentsAtXYRelativeToStructure
                        ),
                        byXYRelativeToScreen: publicMethodUtils.defineGetter(
                            componentDetectorAPI.getComponentsAtXYRelativeToScreen
                        ),
                        getComponentsAtXYConsideringFrame: publicMethodUtils.defineGetter(
                            componentDetectorAPI.getComponentsAtXYConsideringFrame
                        ),
                        byType: publicMethodUtils.defineGetter(componentDetectorAPI.getComponentByType),
                        byAncestor: publicMethodUtils.defineGetter(componentDetectorAPI.getComponentsUnderAncestor)
                    }
                }
            }
        }
    }
}
