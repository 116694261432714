import _ from 'lodash'
import * as platformEvents from '@wix/platform-editor-sdk/lib/platformEvents.min'
import {classic, editorX, onBoarding} from '@wix/document-manager-utils'
import type {AppDefinitionId, ApplicationId, PS} from '@wix/document-services-types'

let customEventsRegistry: Record<string, Set<AppDefinitionId>> = {}
const EVENTS_DEBOUNCE = 10
const EVENTS_THROTTLE = 1000

const throttleMiddlewares = [func => _.debounce(func, EVENTS_DEBOUNCE), func => _.throttle(func, EVENTS_THROTTLE)]

const notifyAllAppsOnCustomEvent = (ps: PS, appsToNotify, eventType, eventPayload, notifyAppsFunc) => {
    appsToNotify.forEach(appDefinitionId =>
        notifyAppsFunc(ps, appDefinitionId, {
            eventType,
            eventPayload
        })
    )
}

const getAppsRegisteredToEventType = (eventType: string): AppDefinitionId[] => {
    return customEventsRegistry[eventType] ? Array.from(customEventsRegistry[eventType]) : []
}

const registerAppToEvents = (appDefinitionId: AppDefinitionId, eventTypes) => {
    _.forEach(eventTypes, type => {
        if (!customEventsRegistry[type]) {
            customEventsRegistry[type] = new Set()
        }
        customEventsRegistry[type].add(appDefinitionId)
    })
}

const unRegisterAppFromEvents = (appDefinitionId: AppDefinitionId, eventTypes: string[]) => {
    eventTypes.forEach(eventType => {
        if (customEventsRegistry[eventType]?.has(appDefinitionId)) {
            customEventsRegistry[eventType].delete(appDefinitionId)
        }
    })
}

const originsToPropagate = new Set([classic, editorX, onBoarding])

let propagateEvent = _.noop

function init(ps: PS) {
    if (originsToPropagate.has(ps.config.origin)) {
        propagateEvent = platformEvents.propagation.initSender().propagateEvent
    }
}

const notifyAppsOnCustomEvent = (ps: PS, eventType: string, eventPayload, notifyAppsFunc) => {
    propagateEvent(eventType, eventPayload)
    const appsToNotify = getAppsRegisteredToEventType(eventType)
    if (!appsToNotify?.length) {
        return
    }
    const throttledNotify = _.flow(throttleMiddlewares)(notifyAllAppsOnCustomEvent)
    return throttledNotify(ps, appsToNotify, eventType, eventPayload, notifyAppsFunc)
}

const propagateGeneralEvent = (applicationId: ApplicationId, eventType: string, eventPayload) => {
    propagateEvent(eventType, eventPayload, {onlyTo: {applicationId}})
}
const cleanRegistry = () => {
    customEventsRegistry = {}
}

export default {
    init,
    propagateGeneralEvent,
    notifyAppsOnCustomEvent,
    registerAppToEvents,
    getAppsRegisteredToEventType,
    unRegisterAppFromEvents,
    cleanRegistry
}
