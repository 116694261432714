import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import pageDsAPI from '../page/page'
import constants from './common/constants'
import dsUtils from '../utils/utils'
import * as platformUtils from '@wix/santa-platform-utils'
import {siteStructureUtils} from '@wix/santa-ds-libs/src/warmupUtils'

const {linkUtils} = platformUtils

function getPagesStructureInfo(ps: PS) {
    const pagesDataItems = pageDsAPI.getPagesDataItems(ps)
    const routers = ps.dal.get(ps.pointers.routers.getRoutersPointer())
    const clientSpecMap = ps.dal.get(ps.pointers.general.getClientSpecMap())
    const isPopupPage = pageDsAPI.popupPages.isPopup.bind(pageDsAPI.popupPages, ps)
    const mainPageId = ps.extensionAPI.page.getMainPageId() || 'mainPage'
    return siteStructureUtils.getPages(pagesDataItems, isPopupPage, mainPageId, routers, clientSpecMap, {
        includeBlankUrl: true
    })
}

function navigateTo(ps: PS, url: string, callback) {
    const routersMap = ps.dal.get(ps.pointers.routers.getRoutersConfigMapPointer())
    const prefixes = siteStructureUtils.getRouterPrefixes({configMap: routersMap})
    const pages = getPagesStructureInfo(ps)
    const linkObject = linkUtils.convertUrlToLinkObject(prefixes, url, null, pages)
    if (linkObject.hasOwnProperty('pageId')) {
        linkObject.pageId = linkObject.pageId === '#' ? linkObject.pageId : dsUtils.stripHashIfExists(linkObject.pageId)
    }
    pageDsAPI.navigateTo(ps, linkObject, callback)
}

function setState(ps: PS, pageStateMap) {
    const appStatePointer = ps.pointers.platform.getAppStatePointer()
    _.forEach(pageStateMap, (pageRefs, state) => {
        _.forEach(pageRefs, pageRef => {
            ps.dal.full.set(ps.pointers.getInnerPointer(appStatePointer, pageRef.id), state)
        })
    })
}

function getState(ps: PS, pageRef) {
    const appStatePointer = ps.pointers.platform.getAppStatePointer()
    return ps.dal.get(ps.pointers.getInnerPointer(appStatePointer, pageRef.id)) || constants.Page.DEFAULT_STATE
}

export default {
    navigateTo,
    setState,
    getState
}
