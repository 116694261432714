import _ from 'lodash'

export default {
    getActiveComponentModeIds(activeModes, compModeDefinitions) {
        return _(activeModes)
            .mapValues((modeData, modeId) => _.some(compModeDefinitions, {modeId}))
            .pickBy()
            .value()
    }
}
