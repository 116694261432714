import _ from 'lodash'
import type {CoreLogger} from '../../src'

const createTestLogger = (overrides: Partial<CoreLogger> = {}): CoreLogger => ({
    captureError: _.noop,
    interactionEnded: _.noop,
    interactionStarted: _.noop,
    breadcrumb: _.noop,
    flush: async () => {},
    reportBI: _.noop,
    ...overrides
})

export {createTestLogger}
