import clientSpecMapUtils from '../utils/clientSpecMapUtils'
import wixCodeMonitoring from './wixCodeMonitoringWrapper'
import wixCodeServiceFacade from './wixCodeServiceFacade'
import fetchResponseErrorObject from '../utils/fetchResponseErrorObject'
import generalInfo from '../../siteMetadata/generalInfo'
import saveRunner from '../../saveAPI/lib/saveRunner'
import markAppImmutable from './markAppImmutable'
import hooks from '../../hooks/hooks'
import experiment from 'experiment-amd'
import type {PS} from '@wix/document-services-types'
import {getMetaSiteId} from '../../utils/dalUtil'

function _updateRevisionGridApp(ps: PS, gridAppId) {
    ps.dal.set(ps.pointers.wixCode.getRevisionGridAppId(), gridAppId)
    hooks.executeHook(hooks.HOOKS.AUTOSAVE.ACTION, null, [ps])
    hooks.executeHook(hooks.HOOKS.WIXCODE.UPDATE_MODEL, null, [ps])
}

function _getIsFirstSaveFromPS(ps: PS) {
    return generalInfo.isFirstSave(ps)
}

async function _doClone(ps) {
    const options = {
        baseUrl: wixCodeServiceFacade.getBaseUrlFromPS(ps),
        gridAppId: ps.extensionAPI.wixCode.getRevisionGridAppId(),
        metasiteId: getMetaSiteId(ps),
        signedInstance: clientSpecMapUtils.getExistingWixCodeAppFromPS(ps).instance,
        isFirstSave: _getIsFirstSaveFromPS(ps)
    }
    const cloneResponse = await wixCodeServiceFacade.clone(options)
    _updateRevisionGridApp(ps, cloneResponse.gridAppId)
    setAppWriteable(ps)
    //in santa tests there is no ps.dal.commitTransaction, this can be removed after
    if (ps.dal.commitTransaction) {
        ps.dal.commitTransaction('wix-code_writableAppService')
    }
    return cloneResponse
}

async function _cloneAppWithRetries(ps, retriesLeft) {
    const traceEnd = wixCodeMonitoring.trace(ps, {action: 'cloneApp'})

    function isCloneUnsavedAppError(error) {
        const errorCode = fetchResponseErrorObject.safeGetErrorCode(error)
        return errorCode === -409 || errorCode === -409000 || errorCode === -409001
    }

    async function onCloneError(error) {
        if (isCloneUnsavedAppError(error)) {
            setAppWriteable(ps)
            traceEnd({level: wixCodeMonitoring.levels.WARN, message: error})
            await markAppImmutable.runUsingPS(ps)
            setAppReadOnly(ps)
            return _cloneAppWithRetries(ps, retriesLeft)
        }

        if (retriesLeft === 0) {
            traceEnd({level: wixCodeMonitoring.levels.ERROR, message: error})
            throw error
        }

        traceEnd({level: wixCodeMonitoring.levels.WARN, message: error, params: {retriesLeft}})
        return _cloneAppWithRetries(ps, retriesLeft - 1)
    }

    try {
        const serverResponse = await _doClone(ps)
        traceEnd({appId: ps.extensionAPI.wixCode.getRevisionGridAppId(), message: serverResponse})
    } catch (e) {
        await onCloneError(e)
    }
}

async function _cloneApp(ps) {
    if (_isAppWriteable(ps)) {
        return
    }
    const NUM_OF_RETRIES = 2
    return _cloneAppWithRetries(ps, NUM_OF_RETRIES)
}

function _isAppWriteable(ps) {
    const pointer = ps.pointers.wixCode.getIsAppReadOnly()
    return !ps.dal.get(pointer)
}

function setAppWriteable(ps) {
    const pointer = ps.pointers.wixCode.getIsAppReadOnly()
    ps.dal.set(pointer, false)
}

function setAppReadOnly(ps) {
    const pointer = ps.pointers.wixCode.getIsAppReadOnly()
    ps.dal.set(pointer, true)
}

function handleAppIsReadOnlyServerError(ps) {
    // If the app is read only on the server although,
    // we think it's not, reset the read only flag
    const pointer = ps.pointers.wixCode.getIsAppReadOnly()
    ps.dal.set(pointer, true)

    return ensureAppIsWriteable(ps)
}

function ensureAppIsWriteable(ps) {
    if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled') && !generalInfo.isFirstSave(ps)) {
        return Promise.resolve()
    }
    return saveRunner.runFunctionInSaveQueue(() => _cloneApp(ps))
}

export default {
    ensureAppIsWriteable,
    setAppWriteable,
    handleAppIsReadOnlyServerError
}
