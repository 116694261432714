import {FontComparisonEntry, ObjMap} from '../../types'

export const tableEntries: ObjMap<FontComparisonEntry> = {
    'adobe-caslon-w01-smbd': {
        referencePoints: [17, 40, 82],
        friendlyName: 'Adobe Caslon'
    },
    '"amatic sc"': {referencePoints: [18, 42, 84], friendlyName: 'Amatic SC'},
    'americantypwrteritcw01--731025': {
        referencePoints: [16, 42, 84],
        friendlyName: 'American Typewriter'
    },
    anton: {referencePoints: [15, 36, 78], friendlyName: 'Anton'},
    arial: {referencePoints: [15, 40, 82], friendlyName: 'Arial'},
    '"arial black"': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Arial Black'
    },
    'avenida-w01': {referencePoints: [20, 45, 88], friendlyName: 'Avenida'},
    'avenir-lt-w01_85-heavy1475544': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Avenir'
    },
    'avenir-lt-w01_35-light1475496': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Avenir Light'
    },
    basic: {referencePoints: [15, 40, 82], friendlyName: 'Basic'},
    'belinda-w00-regular': {
        referencePoints: [19, 44, 88],
        friendlyName: 'Belinda'
    },
    'bodoni-w01-poster': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Bodoni Poster'
    },
    'braggadocio-w01': {
        referencePoints: [15, 36, 78],
        friendlyName: 'Braggadocio'
    },
    'brandon-grot-w01-light': {
        referencePoints: [18, 42, 86],
        friendlyName: 'Brandon Grotesque'
    },
    'bree-w01-thin-oblique': {
        referencePoints: [16, 40, 82],
        friendlyName: 'Bree'
    },
    caudex: {referencePoints: [16, 41, 84], friendlyName: 'Caudex'},
    '"chelsea market"': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Chelsea Market'
    },
    cinzel: {referencePoints: [15, 40, 82], friendlyName: 'Cinzel'},
    'clarendon-w01-medium-692107': {
        referencePoints: [17, 44, 84],
        friendlyName: 'Clarendon LT'
    },
    '"comic sans ms"': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Comic Sans MS'
    },
    cookie: {referencePoints: [22, 52, 100], friendlyName: 'Cookie'},
    'coquette-w00-light': {
        referencePoints: [18, 44, 84],
        friendlyName: 'Coquette'
    },
    corben: {referencePoints: [16, 42, 82], friendlyName: 'Corben'},
    '"courier new"': {
        referencePoints: [17, 48, 96],
        friendlyName: 'Courier New'
    },
    'dinneuzeitgroteskltw01-_812426': {
        referencePoints: [15, 40, 82],
        friendlyName: 'DIN Neuzeit Grotesk'
    },
    'din-next-w01-light': {
        referencePoints: [15, 40, 82],
        friendlyName: 'DIN Next Light'
    },
    'droid-serif-w01-regular': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Droid Serif'
    },
    '"eb garamond"': {
        referencePoints: [18, 42, 84],
        friendlyName: 'EB Garamond'
    },
    enriqueta: {referencePoints: [15, 40, 80], friendlyName: 'Enriqueta'},
    forum: {referencePoints: [17, 44, 86], friendlyName: 'Forum'},
    '"fredericka the great"': {
        referencePoints: [15, 38, 76],
        friendlyName: 'Fredericka the Great'
    },
    'futura-lt-w01-book': {
        referencePoints: [15, 38, 76],
        friendlyName: 'Futura'
    },
    'futura-lt-w01-light': {
        referencePoints: [15, 38, 76],
        friendlyName: 'Futura Light'
    },
    georgia: {referencePoints: [15, 40, 80], friendlyName: 'Georgia'},
    'geotica-w01-four-open': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Geotica Four Open'
    },
    'helvetica-w01-roman': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Helvetica'
    },
    impact: {referencePoints: [15, 36, 76], friendlyName: 'Impact'},
    'itc-arecibo-w01-regular': {
        referencePoints: [18, 44, 88],
        friendlyName: 'ITC Arecibo'
    },
    '"jockey one"': {referencePoints: [16, 42, 82], friendlyName: 'Jockey One'},
    '"josefin slab"': {
        referencePoints: [18, 42, 82],
        friendlyName: 'Josefin Slab'
    },
    jura: {referencePoints: [18, 44, 90], friendlyName: 'Jura'},
    '"kelly slab"': {referencePoints: [15, 38, 78], friendlyName: 'Kelly Slab'},
    'kepler-w03-light-scd-cp': {
        referencePoints: [18, 45, 85],
        friendlyName: 'Kepler'
    },
    '"libre baskerville"': {
        referencePoints: [15, 37, 76],
        friendlyName: 'Libre Baskerville'
    },
    'didot-w01-italic': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Linotype Didot'
    },
    lobster: {referencePoints: [17, 42, 82], friendlyName: 'Lobster'},
    '"lucida console"': {
        referencePoints: [15, 38, 78],
        friendlyName: 'Lucida Console'
    },
    '"lucida sans unicode"': {
        referencePoints: [15, 38, 78],
        friendlyName: 'Lucida Sans Unicode'
    },
    'lulo-clean-w01-one-bold': {
        referencePoints: [14, 36, 76],
        friendlyName: 'Lulo Clean'
    },
    '"marck script"': {
        referencePoints: [20, 48, 92],
        friendlyName: 'Marck Script'
    },
    'marzo-w00-regular': {referencePoints: [15, 40, 78], friendlyName: 'Marzo'},
    monoton: {referencePoints: [14, 36, 76], friendlyName: 'Monoton'},
    'baskervillemtw01-smbdit': {
        referencePoints: [18, 44, 88],
        friendlyName: 'Monotype Baskerville'
    },
    '"mr de haviland"': {
        referencePoints: [22, 46, 94],
        friendlyName: 'Mr De Haviland'
    },
    'museo-w01-700': {referencePoints: [15, 40, 82], friendlyName: 'Museo'},
    'museo-slab-w01-100': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Museo Slab'
    },
    niconne: {referencePoints: [20, 46, 90], friendlyName: 'Niconne'},
    'nimbus-sans-tw01con': {
        referencePoints: [17, 44, 88],
        friendlyName: 'Nimbus Sans'
    },
    '"noticia text"': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Noticia Text'
    },
    '"open sans"': {referencePoints: [15, 40, 82], friendlyName: 'Open Sans'},
    '"open sans condensed"': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Open Sans Condensed'
    },
    overlock: {referencePoints: [16, 42, 84], friendlyName: 'Overlock'},
    'pacifica-w00-condensed': {
        referencePoints: [14, 36, 74],
        friendlyName: 'Pacifica Condensed'
    },
    '"palatino linotype"': {
        referencePoints: [16, 44, 86],
        friendlyName: 'Palatino Linotype'
    },
    '"patrick hand"': {
        referencePoints: [17, 44, 86],
        friendlyName: 'Patrick Hand'
    },
    'peaches-and-cream-regular-w00': {
        referencePoints: [26, 54, 120],
        friendlyName: 'Peaches & Cream'
    },
    play: {referencePoints: [15, 42, 84], friendlyName: 'Play'},
    '"playfair display"': {
        referencePoints: [15, 40, 82],
        friendlyName: 'Playfair Display'
    },
    'proxima-n-w01-reg': {
        referencePoints: [15, 41, 82],
        friendlyName: 'Proxima Nova'
    },
    raleway: {referencePoints: [15, 40, 82], friendlyName: 'Raleway'},
    'reklamescriptw00-medium': {
        referencePoints: [16, 40, 80],
        friendlyName: 'Reklame Script'
    },
    'rosewood-w01-regular': {
        referencePoints: [15, 39, 81],
        friendlyName: 'Rosewood'
    },
    sacramento: {referencePoints: [24, 46, 88], friendlyName: 'Sacramento'},
    sail: {referencePoints: [22, 46, 88], friendlyName: 'Sail'},
    sarina: {referencePoints: [16, 40, 82], friendlyName: 'Sarina'},
    signika: {referencePoints: [16, 42, 84], friendlyName: 'Signika'},
    'snellroundhandw01-scrip': {
        referencePoints: [20, 46, 88],
        friendlyName: 'Snell Roundhand'
    },
    'soho-w01-thin-condensed': {
        referencePoints: [16, 40, 82],
        friendlyName: 'Soho Condensed'
    },
    spinnaker: {referencePoints: [15, 40, 82], friendlyName: 'Spinnaker'},
    'stencil-w01-bold': {
        referencePoints: [14, 38, 78],
        friendlyName: 'Stencil'
    },
    tahoma: {referencePoints: [15, 40, 82], friendlyName: 'Tahoma'},
    '"times new roman"': {
        referencePoints: [16, 42, 86],
        friendlyName: 'Times New Roman'
    },
    'trend-sans-w00-four': {
        referencePoints: [14, 38, 74],
        friendlyName: 'Trend'
    },
    verdana: {referencePoints: [15, 40, 82], friendlyName: 'Verdana'}
}
