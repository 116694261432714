import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import constants from '../constants/constants'
import namespaces from '../namespaces/namespaces'
import dsUtils from '../utils/utils'
import variants from '../variants/variants'

const TRIGGER_TYPE = constants.VARIANTS.TYPES.TRIGGER
const TRIGGERS_DATA_TYPE = 'Triggers'
const TRIGGERS_NAMESPACE = constants.DATA_TYPES.triggers
const NON_UNIQUE_TRIGGERS = [
    constants.TRIGGER_TYPES.VIEWPORT_ENTER,
    constants.TRIGGER_TYPES.VIEWPORT_LEAVE,
    constants.TRIGGER_TYPES.ANIMATION_START,
    constants.TRIGGER_TYPES.ANIMATION_END
]

const getTriggerVariantToAddRef = (ps: PS, compPointer: Pointer) =>
    variants.getVariantToAddRef(ps, compPointer, TRIGGER_TYPE)

const validateTriggerData = triggerData => {
    if (!triggerData || !_.has(triggerData, ['trigger'])) {
        throw new Error('Trigger should be an object with a "trigger" property')
    }
}

const convertTriggerParams = (params: any) => {
    if (params.effect) {
        return {effect: `#${params.effect.id}`}
    }
    return params
}

/**
 * Adds a new trigger to a component
 * @param ps
 * @param variantToAddRef
 * @param componentPointer
 * @param triggerData
 */
const addTrigger = (
    ps: PS,
    variantToAddRef: Pointer,
    componentPointer: Pointer,
    triggerData: {trigger: string; params?: object}
) => {
    validateTriggerData(triggerData)
    if (triggerData.params) {
        triggerData.params = convertTriggerParams(triggerData.params)
    }

    const currentTriggers = namespaces.getNamespaceData(ps, componentPointer, TRIGGERS_NAMESPACE)?.values || []
    const page = ps.pointers.components.getPageOfComponent(componentPointer)

    if (
        !NON_UNIQUE_TRIGGERS.includes(triggerData.trigger) &&
        currentTriggers
            .map(v => ps.dal.get(ps.pointers.data.getVariantsDataItem(dsUtils.stripHashIfExists(v), page.id)))
            .some(t => t.trigger === triggerData.trigger)
    ) {
        throw new Error(
            `Trigger of type ${triggerData.trigger} already exist for component ${JSON.stringify(componentPointer)}`
        )
    }

    variants.createInternal(ps, variantToAddRef, componentPointer, TRIGGER_TYPE, triggerData)
    currentTriggers.push(`#${variantToAddRef.id}`)

    namespaces.updateNamespaceData(ps, componentPointer, TRIGGERS_NAMESPACE, {
        type: TRIGGERS_DATA_TYPE,
        values: currentTriggers
    })
}

/**
 * Removes a trigger from a component
 * @param ps
 * @param componentPointer
 * @param variantPointer
 */
const removeComponentTrigger = (ps: PS, componentPointer: Pointer, variantPointer: Pointer) => {
    if (!ps.dal.isExist(variantPointer) || ps.dal.get(variantPointer).type !== TRIGGER_TYPE) {
        throw new Error('Cannot remove a non-trigger pointer')
    }

    const allTriggers = getAllTriggers(ps, componentPointer)

    variants.remove(ps, variantPointer)

    const triggerDataAfterRemoval = allTriggers
        .filter(trigger => trigger.id !== variantPointer.id)
        .map(varPtr => `#${varPtr.id}`)
    namespaces.updateNamespaceData(ps, componentPointer, TRIGGERS_NAMESPACE, {
        type: TRIGGERS_DATA_TYPE,
        values: triggerDataAfterRemoval
    })
}

/**
 * Gets the trigger data for a given variant
 * @param ps
 * @param componentPointer
 * @param variantPointer
 * @returns {{ trigger: string, type: string}} Trigger data for the given trigger variant
 */
const getTrigger = (ps: PS, componentPointer: Pointer, variantPointer: Pointer): {trigger: string; type: string} => {
    const triggerData = ps.dal.get(variantPointer)
    if (!triggerData || triggerData.type !== TRIGGER_TYPE) {
        throw new Error('Cannot get missing or non-trigger variant')
    }

    return _.pick(triggerData, ['trigger', 'type'])
}

/**
 * Gets all the triggerVariant references we have in the components' triggersData
 *
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @returns {Array} An array of all Trigger variants of the component
 */
const getAllTriggers = (ps: PS, componentPointer: Pointer): Pointer[] => {
    const triggersData = namespaces.getNamespaceData(ps, componentPointer, TRIGGERS_NAMESPACE)

    if (triggersData?.values) {
        const originalPagePointer = ps.pointers.components.getPageOfComponent(componentPointer)
        const pageId = ps.dal.get(originalPagePointer).id

        return triggersData.values.map(variantId =>
            ps.pointers.data.getVariantsDataItem(dsUtils.stripHashIfExists(variantId), pageId)
        )
    }

    return []
}

export default {
    getTriggerVariantToAddRef,
    addTrigger,
    removeComponentTrigger,
    getTrigger,
    getAllTriggers
}
