import {CoreConfig, CoreLogger, createDMCore, DocumentManager} from '@wix/document-manager-core'
import type {ServiceTopology} from '@wix/document-manager-extensions/src/extensions/serviceTopology'
import type {DataFixer, Experiment, PageList, DSConfig} from '@wix/document-services-types'
import * as santaDataFixer from '@wix/santa-data-fixer'
import _ from 'lodash'
import {createConfig, isCEditOpen} from './createConfig'
import {createDataMigrationRunner, DataMigrationRunner} from './dataMigration/dataMigrationRunner'
import {loadExtensions} from './extensionLoader'
import {DefaultFetchPagesFacade} from './initialize/fetchPages'
import {InitResult, initialize, InitParams} from './initialize/mainInitialization'
import type {
    DocumentServicesModel,
    DocumentServicesModelForServer,
    RendererModel,
    RendererModelForServer
} from './initialize/modelTypes'
import type {BootstrapConfig} from './types'
import {ReportableError} from '@wix/document-manager-utils'
export type {FetchPagesToDalFunction} from './initialize/mainInitialization'

export {isCEditOpen}
export type EnvironmentContext = Record<string, any>

const buildCoreConfig = (
    config: DSConfig,
    experimentInstance: Experiment,
    logger: CoreLogger,
    schemaService: any
): CoreConfig => {
    const {origin} = config

    const strictModeFailDefault = experimentInstance.isOpen('dm_strictModeFail')

    return {
        experimentInstance,
        logger,
        schemaService,
        signatureSeed: config.signatureSeed,
        strictModeFailDefault,
        checkConflicts: isCEditOpen(origin, experimentInstance),
        undoRedoConfig: config.undoRedoConfig,
        supportsUsingPresetVariants: config.supportsUsingPresetVariants,
        dontCollectFixerVersionData: config.dontCollectFixerVersionData
    }
}

/**
 * Create a working document Manager which includes the core, registered extensions and initialized state
 * the Document Manager is ready to accept set operations. Public APIs are not initialized yet
 */
const createDocumentManager = (
    config: DSConfig,
    experimentInstance: Experiment,
    logger: CoreLogger,
    schemaService: any,
    environmentContext: EnvironmentContext
): DocumentManager => {
    const coreConfig = buildCoreConfig(config, experimentInstance, logger, schemaService)
    const core = createDMCore(coreConfig)
    loadExtensions(core, config, environmentContext)
    return core
}

export type AnyRendererModel = RendererModelForServer | RendererModel

export interface RendererModelBuilderForHost {
    getRendererModel(): Promise<AnyRendererModel>
}

interface Models {
    serviceTopology: ServiceTopology
    documentServicesModel: DocumentServicesModelForServer | DocumentServicesModel
}

type InitFunc = (initObj: InitParams) => Promise<InitResult>

export interface CreateHostArgs {
    models: Models
    partialPages?: string[]
    config: BootstrapConfig
    experimentInstance: Experiment
    logger: CoreLogger
    schemaService: any
    pageList: PageList
    rendererModelBuilder: RendererModelBuilderForHost
    environmentContext?: EnvironmentContext
    dataFixer?: DataFixer
    dataMigrationRunner?: DataMigrationRunner
    initFunc?: InitFunc
}

export interface Host {
    readonly documentManager: DocumentManager
    readonly config: DSConfig
    _dsInitTimeoutHandler?: any //should be number
    runInitializers(): Promise<InitResult>
    environmentContext: EnvironmentContext
}

export const createHost = ({
    models,
    partialPages = [],
    config: bootstrapConfig,
    experimentInstance,
    logger,
    schemaService,
    environmentContext = {},
    dataFixer = santaDataFixer,
    dataMigrationRunner = createDataMigrationRunner(),
    initFunc,
    pageList,
    rendererModelBuilder
}: CreateHostArgs): Host => {
    if (!experimentInstance.isOpen('dm_forceConfigName') && !bootstrapConfig.configName) {
        // Remove this whole block when dm_forceConfigName is merged
        logger.captureError(
            new ReportableError({
                message: 'Missing a configName parameter',
                errorType: 'missingConfigNameError'
            })
        )
        bootstrapConfig.configName = 'fullFunctionality'
    }

    const config: DSConfig = createConfig(bootstrapConfig, experimentInstance)
    const documentManager = createDocumentManager(config, experimentInstance, logger, schemaService, environmentContext)

    const runInitializers = async (): Promise<InitResult> => {
        await documentManager.createServiceAPIs({config})
        const {serviceTopology, documentServicesModel} = models
        const init = initFunc ?? initialize
        const initResult = await init({
            documentManager,
            partialPages: pagesToLoad(config, partialPages, pageList),
            dataFixer,
            dataMigrationRunner,
            serviceTopology,
            documentServicesModel,
            rendererModelBuilder,
            config,
            logger,
            fetchFn: _.get(environmentContext, ['fetchFn']),
            trackingFn: _.get(environmentContext, ['trackingFn']),
            experimentInstance,
            fetchPagesFacade: _.get(environmentContext, ['fetchPagesFacade']) ?? new DefaultFetchPagesFacade(),
            pageList
        })
        await documentManager.initialize()
        return initResult
    }

    return {
        documentManager,
        runInitializers,
        config,
        environmentContext
    }
}

function pagesToLoad(config: DSConfig, partialPages: string[], pageList: PageList) {
    if (!_.isEmpty(partialPages)) {
        return partialPages
    }
    if (config.lazyLoadPages) {
        return [pageList.mainPageId, 'masterPage']
    }
    return [] // This means all pages in the partial pages API
}
