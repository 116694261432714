import _ from 'lodash'
import Color from 'color'
import {cssUtils} from '@wix/santa-core-utils'

function isThemeColor(value) {
    return /color_/.test(value)
}

function getFromColorMap(colorsMap, color) {
    if (isThemeColor(color)) {
        color = color.replace(/[\[\]{}]/g, '')
        color = getColorValueFromColorsMap(colorsMap, color)
        color = cssUtils.normalizeColorStr(color)
    }
    return color
}

/**
 * Get color value, use color from theme if value is a pointer, normalize color to use rgba() syntax
 * @param {object} colorsMap
 * @param {string} color
 * @param {number} [alphaOverride]
 * @returns {string}
 */
function getColor(colorsMap, color, alphaOverride) {
    let parsedColor
    let colorObj

    // Get color from theme if the color value is represented as {color_X}
    color = getFromColorMap(colorsMap, color)

    if (color === 'none') {
        parsedColor = 'transparent'
    } else {
        colorObj = new Color(color)
        if (_.isNumber(alphaOverride)) {
            colorObj.setValues('alpha', alphaOverride)
        }
        if (colorObj.alpha() === 0) {
            parsedColor = 'transparent'
        } else {
            parsedColor = colorObj.rgbaString()
        }
    }

    return parsedColor
}

function getColorValueFromColorsMap(colorsMap, colorClassName) {
    const colorNumber = colorClassName?.split('_')[1]
    return colorsMap[colorNumber] || colorClassName
}

/**
 *  Get the color value by theme
 * @param {Object} theme - current theme
 * @param {string} colorClassName
 *  @returns {String} color rgb/rgba value
 */
function getColorValue(theme, colorClassName: string): string {
    return getColorValueFromColorsMap(theme.color, colorClassName)
}

function getHexColor(colorsMap, color) {
    const resolved = new Color(getFromColorMap(colorsMap, color))
    // @ts-ignore
    return resolved.hexString()
}

export default {
    getColor,
    getColorValue,
    getHexColor,
    isThemeColor
}
