import type {Pointer, PS} from '@wix/document-services-types'
import * as coreUtils from '@wix/santa-core-utils'
import _ from 'lodash'
import wixappsClassics from 'wixappsClassics'
import wixappsCore from 'wixappsCore'
import component from '../../component/component'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import constants from '../../constants/constants'
import dataModel from '../../dataModel/dataModel'
import hooks from '../../hooks/hooks'
import page from '../../page/page'
import pageDataModule from '../../page/pageData'
import clientSpecMap from '../../siteMetadata/clientSpecMap'
import theme from '../../theme/theme'
import dsUtils from '../../utils/utils'
import blogPaginationCustomizationsGetter from '../utils/blogPaginationCustomizationsGetter'
import classicsPathUtils from '../utils/classicsPathUtils'
import classicsUtils from '../utils/classicsUtils'
import getBlogNewSocialShareButtonsCustomizationForView from '../utils/getBlogNewSocialShareButtonsCustomizationForView'
import blogUtils from './blogUtils'

const {blogAppPartNames} = coreUtils

function invalidateComponentViewCache(ps: PS, componentId: string) {
    ps.setOperationsQueue.waitForChangesApplied(function () {
        wixappsClassics.viewCacheUtils.removeComponentViewDefs(componentId)
    })
}

/**
 * Gets a list of all appPart components
 * @param {ps} ps Private Services
 * @param packageName
 */
function getAllAppPartComps(ps: PS, packageName) {
    const appId = packageName && getApplicationId(ps, packageName)
    if (_.isNull(appId)) {
        return []
    }
    const appPartPointers = componentDetectorAPI.getComponentByType(ps, 'wixapps.integration.components.AppPart')
    return appPartPointers
        .map(appPartPointer => dataModel.getDataItem(ps, appPartPointer))
        .filter(appPartData => appPartData.appInnerID === appId || !appId)
}

/**
 * @param {ps} privateServices
 * @param {string} packageName
 */
function reloadApp(privateServices: PS, packageName) {
    privateServices.siteAPI.reloadWixappsPackage(packageName)
}

function getApplicationId(ps: PS, packageName: string): string | null {
    const clientSpecMapPointer = ps.pointers.general.getClientSpecMap()
    const clientSpecMapData = ps.dal.get(clientSpecMapPointer)
    const blogAppId = _.findLast(clientSpecMapData, {packageName})

    return blogAppId ? `${blogAppId.applicationId}` : null
}

function getDataStoreId(ps: PS, packageName: string) {
    return clientSpecMap.getAppData(ps, getApplicationId(ps, packageName)).datastoreId
}

function getBlogClientSpecMap(ps: PS) {
    return clientSpecMap.getAppData(ps, getApplicationId(ps, 'blog'))
}

function getBlogInstanceId(ps: PS) {
    return getBlogClientSpecMap(ps).datastoreId
}

function deleteAllRemainingAppParts(ps: PS) {
    ps.setOperationsQueue.runSetOperation(function () {
        const pages = getNonAppPagePages(ps)

        _.forEach(pages, function (pageData) {
            const pagePointer = page.getPage(ps, pageData.id)
            const partsToDelete = componentDetectorAPI.getComponentByType(
                ps,
                'wixapps.integration.components.AppPart',
                pagePointer
            )
            _(partsToDelete)
                .union(
                    componentDetectorAPI.getComponentByType(
                        ps,
                        'wysiwyg.common.components.rssbutton.viewer.RSSButton',
                        pagePointer
                    )
                )
                .forEach(function (partPointer) {
                    component.remove(ps, partPointer)
                })
        })

        const masterPagePointer = page.getPage(ps, 'masterPage')
        const partsToDeleteFromMasterPage = componentDetectorAPI.getComponentByType(
            ps,
            'wixapps.integration.components.AppPart',
            masterPagePointer
        )
        _(partsToDeleteFromMasterPage)
            .union(
                componentDetectorAPI.getComponentByType(
                    ps,
                    'wysiwyg.common.components.rssbutton.viewer.RSSButton',
                    masterPagePointer
                )
            )
            .forEach(function (partPointer) {
                component.remove(ps, partPointer)
            })

        activateApp(ps, 'false')
    })
}

function getNonAppPagePages(ps: PS) {
    const pages = page.getPagesDataItems(ps)
    return _(pages).compact().reject({appPageType: 'AppPage'}).value()
}

function canDeleteComp(ps: PS, compPointer: Pointer) {
    const appPart = dataModel.getDataItem(ps, compPointer)
    const undeletableAppParts = [blogAppPartNames.FEED, blogAppPartNames.SINGLE_POST]
    if (_.includes(undeletableAppParts, appPart.appPartName)) {
        return false
    }
}

function registerHooks() {
    hooks.registerHook(hooks.HOOKS.REMOVE.AFTER, deleteAllRemainingAppParts, 'wixapps.integration.components.AppPage')
    hooks.registerHook(hooks.HOOKS.REMOVE.IS_OPERATION_ALLOWED, canDeleteComp, 'wixapps.integration.components.AppPart')
    hooks.registerHook(
        hooks.HOOKS.DUPLICATE.IS_OPERATION_ALLOWED,
        canDeleteComp,
        'wixapps.integration.components.AppPart'
    )
}

const createStyleItemToFork = (ps: PS, styleValue) => {
    const defaultFields = {
        styleType: constants.STYLES.TYPES.CUSTOM,
        type: constants.STYLES.TOP_LEVEL_STYLE
    }
    // @ts-expect-error
    const styleDefToAdd = _(styleValue).omit(['id']).assign(defaultFields).value()
    return theme.styles.createStyleItemToAdd(ps, styleDefToAdd)
}

function activateApp(ps: PS, activeState) {
    let pointer = ps.pointers.page.getPageData('masterPage')
    const innerPath = ['appVars_61f33d50-3002-4882-ae86-d319c1a249ab', 'vars', 'applicationActiveState']
    while (!_.isEmpty(innerPath)) {
        pointer = ps.pointers.getInnerPointer(pointer, innerPath.splice(0, 1)[0])
        if (!ps.dal.isExist(pointer)) {
            ps.dal.set(pointer, {})
        }
    }

    ps.dal.set(pointer, {type: 'AppPartParam', value: activeState})
}

function getComponentTypeByProxyName(ps: PS, proxyName) {
    return wixappsClassics.componentTypeUtil.getComponentTypeByProxyName(proxyName)
}

function styleToFontClass(ps: PS, style) {
    return wixappsCore.styleMapping.styleToFontClass(style)
}

function fontClassToStyle(ps: PS, fontClass) {
    return wixappsCore.styleMapping.fontClassToStyle(fontClass)
}

function getLegacyAppPartContent(ps: PS, compId: Pointer) {
    const compData = ps.dal.get(compId)
    const dataItemId = dsUtils.stripHashIfExists(compData.dataQuery)
    const dataItem = dataModel.getDataItemById(ps, dataItemId)
    const appName = classicsUtils.getPackageName(ps, dataItem.appInnerID)
    const dataItemPath = classicsPathUtils.getAppPartDataPath(appName, dataItemId)
    const dataItems = ps.wixappsDAL.getByPath(classicsPathUtils.getAppPartItemsPath(appName))
    const itemTypes = ps.wixappsDAL.getByPath(dataItemPath)

    return {
        itemTypes,
        items: dataItems
    }
}

function getBlogPaginationCustomizationsByAppPartName(ps: PS, appPartName) {
    return blogPaginationCustomizationsGetter.getBlogPaginationCustomizationsByAppPartName(appPartName)
}

function getBlogSinglePostPageData(ps: PS) {
    const pageIdLists = page.getPageIdList(ps)
    let blogSinglePostPageData
    _.forEach(pageIdLists, function (item) {
        if (classics.blog.isSinglePost(ps, item)) {
            blogSinglePostPageData = pageDataModule.getPageData(ps, item)
        }
    })
    return blogSinglePostPageData
}

function convertAppPageStructure(ps: PS, pageRef) {
    const pageData = pageDataModule.getPageData(ps, pageRef.id)
    const isAppPage = pageData.appPageType === 'AppPage'
    if (isAppPage) {
        const pagetPointerCompType = _.assign({innerPath: ['componentType']}, pageRef)
        ps.dal.set(pagetPointerCompType, 'mobile.core.components.Page')
    }
}

const classics = {
    blog: {
        deleteAllAppParts: deleteAllRemainingAppParts,
        getBlogSinglePostPageData,
        isFeed: blogUtils.isFeed,
        isSinglePost: blogUtils.isSinglePost
    },
    getAllAppPartComps,
    getApplicationId,
    getLegacyAppPartContent,
    getBlogInstanceId,
    getBlogNewSocialShareButtonsCustomizationForView(ps: PS, name) {
        return getBlogNewSocialShareButtonsCustomizationForView(name)
    },
    getBlogPaginationCustomizationsByAppPartName,
    getComponentTypeByProxyName,
    getDataStoreId,
    getPackageName: classicsUtils.getPackageName,
    invalidateComponentViewCache,
    isBlogPage: blogUtils.isBlogPage,
    registerHooks,
    reloadApp,
    styleMapping: {
        fontClassToStyle,
        styleToFontClass
    },
    style: {
        createItemToFork: createStyleItemToFork
    },
    convertAppPage: convertAppPageStructure
}
export default classics
