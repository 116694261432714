import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import generalInfo from '../../siteMetadata/generalInfo'
import actionsAndBehaviors from '../../actionsAndBehaviors/actionsAndBehaviors'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import dataModel from '../../dataModel/dataModel'
import constants from '../../constants/constants'

function getMobileSettingsPointer(ps) {
    const siteStructureDataPointer = ps.pointers.data.getDataItemFromMaster(santaCoreUtils.siteConstants.MASTER_PAGE_ID)
    return ps.pointers.getInnerPointer(siteStructureDataPointer, 'mobileSettings')
}

function isActionAndBehavior(behaviorObj) {
    return _.has(behaviorObj, 'action') && _.has(behaviorObj, 'behavior')
}

function isAnimationBehavior(behavior) {
    if (isActionAndBehavior(behavior)) {
        return false
    }
    return _.get(behavior, 'type', 'animation') === 'animation'
}

function setBehaviorsWithoutViewModeToDesktop(ps) {
    const comps = componentDetectorAPI.getAllComponents(ps)
    _.forEach(comps, comp => {
        // filter animations behavior
        const updatedBehaviors = _.map(actionsAndBehaviors.getBehaviors(ps, comp), behavior => {
            if (isAnimationBehavior(behavior) && !_.has(behavior, 'viewMode')) {
                behavior.viewMode = constants.VIEW_MODES.DESKTOP
            }
            return behavior
        })
        if (updatedBehaviors.length) {
            const behaviorsToSet = JSON.stringify(updatedBehaviors)
            dataModel.updateBehaviorsItem(ps, comp, behaviorsToSet)
        }
    })
}

function siteNeverSaved(ps) {
    return generalInfo.isFirstSave(ps) || generalInfo.isDraft(ps)
}

function setMobileAnimationsEnabledFlag(ps, animationsEnabled) {
    const siteStructureDataPointer = getMobileSettingsPointer(ps)
    ps.dal.set(siteStructureDataPointer, {animationsEnabled})
}

function exec(ps) {
    const mobileSettingsPointer = getMobileSettingsPointer(ps)
    if (siteNeverSaved(ps)) {
        // new site
        setMobileAnimationsEnabledFlag(ps, true)
    } else if (!ps.dal.isExist(mobileSettingsPointer)) {
        // old site
        setMobileAnimationsEnabledFlag(ps, false)
        // getAnimations and set viewMode = 'DESKTOP'
        setBehaviorsWithoutViewModeToDesktop(ps)
    }
}

export default {
    exec,
    name: 'mobileAnimationsDataFixer',
    version: 1
}
