import experiment from 'experiment-amd'

export default {
    get isRepeatable() {
        return experiment.isOpen('dm_widgetInRepeater')
    },
    shouldAutoSetNickname: false,
    mobileConversionConfig: {
        stretchHorizontally: true
    },
    canContainByStructure: true,
    isPublicContainer: false,
    canBeStretched: true
}
