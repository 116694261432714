import {asArray} from '@wix/document-manager-utils'
import type {PossibleViewModes, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentStylesAndSkinsAPI from '../component/componentStylesAndSkinsAPI'
import componentDetectorAPI from '../componentDetectorAPI/componentDetectorAPI'
import dataModel from '../dataModel/dataModel'
import mobileHintsValidator from '../mobileConversion/modules/mobileHintsValidator'
import layoutValidation from '../structure/utils/layoutValidation'
import compDataValidator from './validators/compDataValidator'
import compPropValidator from './validators/compPropValidator'
import skinValidator from './validators/skinValidator'
import styleValidator from './validators/styleValidator'

function getInvalidComponentDescription(ps: PS, compPointer: Pointer) {
    const compStructure = ps.dal.get(compPointer)

    const pageOfComp = ps.pointers.components.getPageOfComponent(compPointer)
    const data = dataModel.getDataItem(ps, compPointer)

    return {
        id: compPointer.id,
        pageId: pageOfComp?.id,
        componentType: compStructure.componentType ?? compStructure.documentType,
        skin: componentStylesAndSkinsAPI.skin.get(ps, compPointer),
        dataType: data?.type
    }
}

const VALIDATORS = {
    mobileHints: mobileHintsValidator.validateComponentMobileHints,
    data: compDataValidator.validateCompData,
    properties: compPropValidator.validateCompProps,
    layout: layoutValidation.validateLayout,
    skin: skinValidator.validateComponentSkin,
    style: styleValidator.validateComponentStyle
}

function validateComponentsWithValidators(ps: PS, comps, validators) {
    return _.reduce(
        asArray(comps),
        function (invalidComps, comp) {
            const invalidations = _(validators)
                .mapValues(function (validator) {
                    try {
                        validator(ps, comp)
                    } catch (validationError: any) {
                        return validationError.message ?? validationError.toString()
                    }
                    return null
                })
                .omitBy(_.isNull)
                .value()

            if (!_.isEmpty(invalidations)) {
                invalidComps.push({
                    comp: getInvalidComponentDescription(ps, comp),
                    invalidations
                })
            }

            return invalidComps
        },
        []
    )
}

function validateComponents(ps: PS, comps) {
    return validateComponentsWithValidators(ps, comps, VALIDATORS)
}

/**
 * Used for automation
 * @param ps
 * @param validators
 * @param [pageId]
 * @param [viewMode]
 * @returns {*}
 */
function validateAllComponentsWithValidators(ps: PS, validators, pageId?: string, viewMode?: PossibleViewModes) {
    const allComponents = componentDetectorAPI.getAllComponents(
        ps,
        pageId,
        comp => !ps.pointers.components.isMasterPage(comp),
        viewMode
    )
    return validateComponentsWithValidators(ps, allComponents, validators)
}

function validateAllComponents(ps: PS) {
    return validateAllComponentsWithValidators(ps, VALIDATORS)
}

function validateAllMobileHints(ps: PS) {
    const pageIds = _.map(ps.pointers.page.getNonDeletedPagesPointers(true), 'id')
    try {
        mobileHintsValidator.validateMobileHintsOnPages(ps, pageIds)
    } catch (error) {
        return error
    }

    return null
}

function initialize() {
    mobileHintsValidator.initialize()
}

export default {
    initialize,
    validateComponentData: VALIDATORS.data,
    validateComponentProperties: VALIDATORS.properties,
    validateComponentLayout: VALIDATORS.layout,
    validateComponentSkin: VALIDATORS.skin,
    validateStyle: VALIDATORS.style,
    validateMobileHints: VALIDATORS.mobileHints,
    validateComponents,
    validateAllComponents,
    validateAllComponentsWithValidators,
    validateAllMobileHints
}
