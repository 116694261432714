import experiment from 'experiment-amd'
import clientSpecMapUtils from '../utils/clientSpecMapUtils'
import wixCodeMonitoring from './wixCodeMonitoringWrapper'
import wixCodeServiceFacade from './wixCodeServiceFacade'
import writeableAppService from './writeableAppService'
import hooks from '../../hooks/hooks'
import type {PS} from '@wix/document-services-types'
import {getMetaSiteId} from '../../utils/dalUtil'

function _updateWixCodeModel(ps: PS, newAppResult) {
    const {gridAppId} = newAppResult
    if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
        ps.dal.set(ps.pointers.wixCode.getOpenWixCodeAppId(), gridAppId)
    } else {
        ps.dal.set(ps.pointers.wixCode.getRevisionGridAppId(), gridAppId)
    }
    hooks.executeHook(hooks.HOOKS.AUTOSAVE.ACTION, null, [ps])
    return newAppResult
}

async function _doProvision(ps: PS, existingWixCodeApp) {
    const options = {
        baseUrl: wixCodeServiceFacade.getBaseUrlFromPS(ps),
        metasiteId: getMetaSiteId(ps),
        signedInstance: existingWixCodeApp ? existingWixCodeApp.instance : null,
        branchId: ps.extensionAPI.siteAPI.getBranchId()
    }

    const newAppResult = await wixCodeServiceFacade.create(options)
    _updateWixCodeModel(ps, newAppResult)
    writeableAppService.setAppWriteable(ps)
    return existingWixCodeApp
}

async function provision(ps) {
    const traceEnd = wixCodeMonitoring.trace(ps, {action: 'createApp'})
    const existingWixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)

    if (isProvisioned(ps)) {
        traceEnd({message: 'wix code is already provisioned'})
        return existingWixCodeApp
    }

    try {
        await _doProvision(ps, existingWixCodeApp)
        await ps.extensionAPI.rendererModel.refreshClientSpecMap()
        traceEnd()
        return clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
    } catch (error) {
        traceEnd({message: error, level: wixCodeMonitoring.levels.ERROR})
        throw error
    }
}

const isProvisioned = (ps: PS) => ps.extensionAPI.wixCode.isProvisioned()

export default {
    provision,
    isProvisioned,
    ensureAppIsWriteable: writeableAppService.ensureAppIsWriteable,
    handleAppIsReadOnlyServerError: writeableAppService.handleAppIsReadOnlyServerError
}
