import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import appWidgetDefinition from './appWidgetDefinition.json'
import classicWidgetOverrides from './classicWidgetOverrides.json'
import theme from '../../theme/theme'
import blankPageStructure from '../../page/blankPageStructure'

const createGreyLandingPage = (ps: PS) => {
    const color = '#f0f3f5'
    const blankPage: any = blankPageStructure.getBlankPageStructure(ps)
    const pageBackground = blankPageStructure.getBlankBackground()
    pageBackground.desktop.ref.color = color
    pageBackground.mobile.ref.color = color
    blankPage.data.pageBackgrounds = pageBackground
    delete blankPage.styleId
    blankPage.style = theme.styles.createStyleItemToAdd(ps, {
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.page.BasicPageSkin'
    })
    blankPage.style.style.properties.bg = color
    blankPage.data.isLandingPage = true
    blankPage.data.isMobileLandingPage = true

    return blankPage
}

const appWidgetStructure = _.assign({}, appWidgetDefinition, classicWidgetOverrides)

export default {
    getPageStructure: createGreyLandingPage,
    appWidgetStructure
}
