import _ from 'lodash'
import 'experiment'

const isPageOrMasterPage = (ps, compPointer) =>
    ps.pointers.components.isPage(compPointer) || ps.pointers.components.isMasterPage(compPointer)

const unDockNonDirectPageChildren = (ps, componentToAddRef, containerReference, componentDefinition) => {
    if (componentToAddRef.id === 'MENU_AS_CONTAINER') {
        // This seems to be an exception for pinned components, since menuContainerDataFixer specifically moved it to the header
        // I don't want to get into changing that now
        return
    }

    const isFixedClassic = _.get(componentDefinition.layout, ['fixedPosition'])
    if (isFixedClassic && !isPageOrMasterPage(ps, containerReference)) {
        delete componentDefinition.layout.docked
        delete componentDefinition.layout.fixedPosition
        _.defaults(componentDefinition.layout, {x: 0, y: 0, width: 100, height: 100})
    }
}

export default {
    unDockNonDirectPageChildren
}
