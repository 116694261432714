import type {PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import component from '../../component/component'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import structureUtils from '../../structure/structureUtils'
import environment from '../../environment/environment'

const exec = (ps: PS) => {
    if (environment.getViewerName(ps) === 'tb' && experiment.isOpen('dm_oldContactFormDeprecation')) {
        const fillerPreset = {
            componentType: 'wixui.UnsupportedComponent',
            type: 'Component',
            data: {
                type: 'UnsupportedComponentData',
                migratedFrom: ''
            },
            style: {
                type: 'ComponentStyle',
                styleType: 'custom',
                style: {
                    properties: {
                        'alpha-bg': '1',
                        'alpha-brd': '1',
                        bg: 'rgba(255,255,255,1)',
                        'boxShadowToggleOn-shd': 'false',
                        brd: '#FFFFFF',
                        brw: '0px',
                        rd: '0px',
                        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)'
                    },
                    propertiesSource: {
                        'alpha-bg': 'value',
                        'alpha-brd': 'value',
                        bg: 'value',
                        'boxShadowToggleOn-shd': 'value',
                        brd: 'value',
                        brw: 'value',
                        rd: 'value',
                        shd: 'value'
                    },
                    groups: {}
                },
                componentClassName: 'wixui.UnsupportedComponent',
                skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
            },
            layout: {}
        }
        const allDynamicContactForms = componentDetectorAPI.getComponentByType(
            ps,
            'wysiwyg.viewer.components.DynamicContactForm'
        )
        const allContactForms = componentDetectorAPI.getComponentByType(ps, 'wysiwyg.viewer.components.ContactForm')
        const allSubscribeForms = componentDetectorAPI.getComponentByType(
            ps,
            'wysiwyg.common.components.subscribeform.viewer.SubscribeForm'
        )
        const allComponents = [...allDynamicContactForms, ...allContactForms, ...allSubscribeForms]
        allComponents.forEach(compPointer => {
            const componentType = ps.dal.full.get(ps.pointers.getInnerPointer(compPointer, 'componentType'))
            const componentLayout = structureUtils.getComponentLayout(ps, compPointer)
            const filler = {
                ...fillerPreset,
                data: {...fillerPreset.data, migratedFrom: componentType},
                layout: componentLayout
            }
            component.migrate(ps, compPointer, filler)
        })
    }
}

export default {
    exec,
    name: 'dynamicContactFormDeprecationFixer',
    version: 1
}
