import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import component from '../../component/component'
import copyDataFromTemplate from '../../platform/services/copyDataFromTemplate'
import tpaConstants from '../constants'
import clientSpecMapService from '../services/clientSpecMapService'

const getAllTpaWidgetsAndGenerateId = serializedComp => {
    const compsWithApps = []
    const getAllTpaWidgetsAndGenerateIdInternal = _serializedComp => {
        const children = _serializedComp.components
        _.forEach(children, child => {
            const childData = child.data
            if (childData) {
                const isAppDataType = _.includes([tpaConstants.DATA_TYPE.TPA_WIDGET], childData.type)
                if (isAppDataType) {
                    child.id = component.generateNewComponentId()
                    compsWithApps.push(child)
                }
            }
            if (child.components) {
                getAllTpaWidgetsAndGenerateIdInternal(child)
            }
        })
    }
    getAllTpaWidgetsAndGenerateIdInternal(serializedComp)
    return compsWithApps
}

const cloneData = async (ps: PS, compsWithApps) => {
    const oldToNewMapId = {}
    for (const appComp of compsWithApps) {
        const appDefinitionId = _.get(appComp, ['data', 'appDefinitionId'])
        const appData = clientSpecMapService.getAppDataByAppDefinitionId(ps, appDefinitionId)
        if (!appData) {
            throw new Error(`app ${appDefinitionId} should be provisioned before cloning the data`)
        }
        const appComps = _.find(appData.components, {type: 'PLATFORM'})

        const shouldCloneDataPerComponent = _.get(appComps, ['data', 'shouldCloneDataPerComponent'])
        if (shouldCloneDataPerComponent) {
            oldToNewMapId[appComp.id] = appComp.id
            await copyDataFromTemplate.copyDataFromOriginTemplateByComp(ps, appDefinitionId, {
                target_comp_id: appComp.id,
                origin_comp_id: appComp.originCompId,
                origin_instance_id: appComp.originInstanceId
            })
        }
    }
    return oldToNewMapId
}
const addAndCloneTpaCompData = (
    ps: PS,
    oldToNewMapId,
    componentToAddPointer: Pointer,
    containerPointer: Pointer,
    componentDefinition,
    optionalCustomId: string,
    callback
) => {
    component.add(
        ps,
        componentToAddPointer,
        containerPointer,
        componentDefinition,
        optionalCustomId,
        null,
        oldToNewMapId
    )
    if (callback) {
        callback()
    }
}

const cloneTpaCompData = async (ps: PS, compRefToAdd, newContainerPointer: Pointer, serializedComponent) => {
    let oldToNewMapId = {}
    if (serializedComponent) {
        const compsWithApps = getAllTpaWidgetsAndGenerateId(serializedComponent)
        oldToNewMapId = await cloneData(ps, compsWithApps)
    }
    ps.setOperationsQueue.asyncPreDataManipulationComplete(oldToNewMapId)
}

export default {
    cloneTpaCompData,
    addAndCloneTpaCompData,
    getAllTpaWidgetsAndGenerateId,
    cloneData
}
