import type {Callback1, CompRef, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import {getSkinDefaultParams, getSkinExports} from '@wix/editor-elements-preview-utils/skins'
import metaDataUtils from '../componentsMetaData/metaDataUtils'
import {isError} from '../utils/utils'
import componentValidations from './componentValidations'
import constants from '../constants/constants'
import theme from '../theme/theme'
import documentServicesSchemas from 'document-services-schemas'
import isSystemStyle from '../theme/isSystemStyle'
import variantsUtils from '../variants/variantsUtils'
import {updateConsideringOverrides} from '../overrides/overrides'
import mobileUtil from '../mobileUtilities/mobileUtilities'
import * as santaCoreUtils from '@wix/santa-core-utils'
import experiment from 'experiment-amd'
import bi from '../bi/bi'
import biEvents from '../bi/events.json'

function fixStyleIdIfStyleIsMissing(ps: PS, componentPointer: Pointer, isFull: boolean, origStyleId: string) {
    if (!origStyleId || experiment.isOpen('dm_removeFixMissingStyleHack')) {
        return origStyleId
    }

    const pageId =
        !ps.runtimeConfig.stylesPerPage || isSystemStyle(origStyleId)
            ? constants.MASTER_PAGE_ID
            : _.get(ps.pointers.components.getPageOfComponent(componentPointer), 'id')
    const stylePointer = ps.pointers.data.getThemeItem(origStyleId, pageId)
    if (!ps.dal.isExist(stylePointer)) {
        const componentType = metaDataUtils.getComponentType(ps, componentPointer)
        const systemStyles = documentServicesSchemas.services.schemasService.getDefinition(componentType).styles
        const newStyleId = _.keys(systemStyles).sort()[0]
        if (!newStyleId) {
            return
        }
        setComponentStyleIdInternal(ps, componentPointer, newStyleId, _.noop, isFull)

        const params = {
            dsOrigin: ps.config.origin,
            componentType,
            componentId: componentPointer.id,
            style: origStyleId
        }
        bi.event(ps, biEvents.FIX_MISSING_COMPONENT_STYLE, params)
        return newStyleId
    }
    return origStyleId
}

const getThemeStyleIds = (componentType: string) => {
    const systemStyles = documentServicesSchemas.services.schemasService.getDefinition(componentType).styles
    const themeStyleIds = _.keys(systemStyles).sort()
    return themeStyleIds || []
}

function getComponentStyleIdInternal(ps: PS, componentPointer: Pointer, isFull: boolean = false) {
    let styleId = null
    if (ps && componentPointer) {
        const dal = isFull ? ps.dal.full : ps.dal
        styleId = dal.get(ps.pointers.getInnerPointer(componentPointer, 'styleId'))
        if (!experiment.isOpen('dm_fixMissingDefaultStyles')) {
            const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(
                ps,
                componentPointer,
                constants.DATA_TYPES.theme
            )
            const isWithVariants = ps.pointers.components.isWithVariants(componentPointer)
            //fix default style if needed
            if (!isWithVariants && shouldConsiderVariants) {
                const nonScopedStylePointer = variantsUtils.getComponentDataPointerConsideringVariants(
                    ps,
                    componentPointer,
                    constants.DATA_TYPES.theme
                )
                if (!nonScopedStylePointer) {
                    return null
                }
                if (!dal.get(nonScopedStylePointer)) {
                    return fixStyleIdIfStyleIsMissing(ps, componentPointer, isFull, nonScopedStylePointer.id)
                }
            }
        }

        styleId = fixStyleIdIfStyleIsMissing(ps, componentPointer, isFull, styleId)
    }
    return styleId
}

const getComponentStyleId = (ps: PS, componentPointer: Pointer) => {
    const compStyleId = getComponentStyleIdInternal(ps, componentPointer, false)
    const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(
        ps,
        componentPointer,
        constants.DATA_TYPES.theme
    )
    if (compStyleId && shouldConsiderVariants) {
        const styleData = variantsUtils.getComponentDataConsideringVariants(
            ps,
            componentPointer,
            constants.DATA_TYPES.theme
        )
        return styleData?.id
    }
    return compStyleId
}

const connectToThemeStyle = (ps: PS, componentPointer: Pointer, styleId: string, callback?: Callback1<any>) => {
    if (!isSystemStyle(styleId)) {
        throw new Error('connectToThemeStyle called with custom style')
    }

    const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(
        ps,
        componentPointer,
        constants.DATA_TYPES.theme
    )
    if (shouldConsiderVariants) {
        variantsUtils.connectToThemeStyleConsideringVariants(ps, componentPointer, styleId)
    } else {
        setComponentStyleId(ps, componentPointer, styleId, callback)
    }
}

const shouldOverrideExistingStyle = (componentPointer: Pointer, shouldConsiderVariants: boolean) =>
    !shouldConsiderVariants && santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(componentPointer)

/**
 * The function forks a style to a new custom style.
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {object} newStyleItem When undefined, the component's current style will be forked. Otherwise, this style will be assigned to the component
 * @param {boolean} isFull True to use full dal
 * @throws an exception if no corresponding component exists or invalid style value.
 * @return {String} New style Id
 */
const forkStyleInternal = (ps: PS, componentPointer: CompRef, newStyleItem, isFull: boolean) => {
    const pointers = isFull ? ps.pointers.full : ps.pointers
    const pageId = pointers.components.getPageOfComponent(componentPointer).id

    const styleItemToUpdate = newStyleItem || getComponentStyle(ps, componentPointer)
    styleItemToUpdate.componentClassName =
        styleItemToUpdate.componentClassName || metaDataUtils.getComponentType(ps, componentPointer)

    const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(
        ps,
        componentPointer,
        constants.DATA_TYPES.theme
    )

    const newStyleIdOverride = shouldOverrideExistingStyle(componentPointer, shouldConsiderVariants)
        ? styleItemToUpdate.id
        : undefined

    // if shouldConsiderVariants = true and current item is not a refArray, then this line creates a garbage item that is never actually used
    // as update considering variants will re-link the root item to a new refarray there
    const newStyleId = theme.styles.internal.fork(ps, styleItemToUpdate, pageId, isFull, newStyleIdOverride)

    if (shouldConsiderVariants) {
        const forkedStyleData = theme.styles.get(ps, newStyleId)
        return updateConsideringOverrides(ps, componentPointer, forkedStyleData, constants.DATA_TYPES.theme)
    }

    setComponentStyleIdInternal(ps, componentPointer, newStyleId, undefined, isFull)
    return newStyleId
}

const forkStyle = (ps: PS, componentPointer: Pointer, newStyleItem?) =>
    forkStyleInternal(ps, componentPointer as CompRef, newStyleItem, false)

/**
 * set a component's style
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {String} styleId
 * @param {Function} [callback]
 * @param {boolean} [isFull] True to use full dal
 * @throws an exception if no corresponding component exists or invalid style name.
 */
function setComponentStyleIdInternal(
    ps: PS,
    componentPointer: Pointer,
    styleId: string,
    callback?: Callback1<any>,
    isFull: boolean = false
) {
    const isWithVariants = ps.pointers.components.isWithVariants(componentPointer)
    if (isWithVariants) {
        throw new Error(
            "set component styleId isn't supported for scoped style, use style.connectToTheme or style.update instead"
        )
    }

    let pageId = constants.MASTER_PAGE_ID
    if (ps.runtimeConfig.stylesPerPage) {
        const pointers = isFull ? ps.pointers.full : ps.pointers
        pageId = pointers.components.getPageOfComponent(componentPointer).id
    }

    const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(
        ps,
        componentPointer,
        constants.DATA_TYPES.theme
    )
    if (shouldConsiderVariants) {
        variantsUtils.removeComponentDataConsideringVariants(ps, componentPointer, constants.DATA_TYPES.theme)
    }

    let styleDef = theme.styles.internal.get(ps, styleId, pageId, isFull)
    if (!styleDef) {
        styleId = createSystemStyle(ps, styleId, metaDataUtils.getComponentType(ps, componentPointer))
        styleDef = theme.styles.internal.get(ps, styleId, constants.MASTER_PAGE_ID, isFull)
    }
    const validationResult = componentValidations.validateSetStyleIdParams(ps, styleId, pageId)
    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }

    mobileUtil.syncMobileAndDesktopStyleId(ps, componentPointer, styleId, isFull)

    const dal = isFull ? ps.dal.full : ps.dal
    dal.set(ps.pointers.getInnerPointer(componentPointer, 'styleId'), styleId)

    ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
        if (callback) {
            callback({styleProperties: theme.styles.internal.get(ps, styleId, pageId, isFull).style.properties})
        }
        theme.events.onChange.executeListeners(ps, {type: 'STYLE', values: styleId})
    })

    // make sure compStructure.skin && the skin inside the style are aligned
    setComponentInnerPointerSkin(ps, componentPointer, styleDef.skin, isFull)
}

/**
 * set a component's style
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {string} styleId
 * @param [callback]
 * @throws an exception if no corresponding component exists or invalid style name. or if the component has variantRelated stuff
 */
const setComponentStyleId = (ps: PS, componentPointer: Pointer, styleId: string, callback?: Callback1<any>) => {
    if (variantsUtils.shouldConsiderVariants(ps, componentPointer, constants.DATA_TYPES.theme)) {
        throw new Error(
            "set component styleId isn't supported for scoped style, use style.connectToTheme or style.update instead"
        )
    }
    setComponentStyleIdInternal(ps, componentPointer, styleId, callback, false)
}

function createSystemStyle(ps: PS, styleId, componentType) {
    return theme.styles.createDefaultThemeStyle(ps, componentType, styleId)
}

function createComponentStyleDef(ps: PS, skinName, compType) {
    return {
        compId: '',
        componentClassName: compType,
        pageId: '',
        styleType: constants.STYLES.TYPES.CUSTOM,
        type: constants.STYLES.COMPONENT_STYLE,
        skin: skinName,
        style: {
            groups: {},
            properties: getDefaultSkinParams(ps, skinName),
            propertiesSource: {}
        }
    }
}

function getDefaultSkinParams(ps: PS, skinName: string) {
    return _.mapValues(theme.skins.getSkinDefinition(ps, skinName), 'defaultValue')
}

function setComponentInnerPointerSkin(ps: PS, componentPointer: Pointer, skinName: string, isFull?: boolean) {
    const compSkinPointer = ps.pointers.getInnerPointer(componentPointer, 'skin')
    const dal = isFull ? ps.dal.full : ps.dal
    dal.set(compSkinPointer, skinName)
}

/**
 * set a new custom style for a component.
 * If no styleId is given - generates a new one.
 * If no skin is given - tries to use the current skin.
 * If no styleProperties are given - style will use the current styleProperties.
 * @param {ps} ps
 * @param {string} [newStyleId] the requested id of the new custom style
 * @param {Pointer} componentPointer
 * @param {string} [optionalSkinName] the name of the skin the style will use
 * @param {Object} [optionalStyleProperties] the skin parameters that the style wants to override
 * return {string} the id of the created custom style
 */
function setComponentCustomStyle(
    ps: PS,
    newStyleId: string,
    componentPointer: Pointer,
    optionalSkinName?: string,
    optionalStyleProperties?
) {
    if (!ps.dal.isExist(componentPointer)) {
        throw new Error('component param does not exist')
    }

    if (variantsUtils.shouldConsiderVariants(ps, componentPointer, constants.DATA_TYPES.theme)) {
        throw new Error("set component custom style isn't supported for scoped styles, use style.update instead")
    }

    const validationResult = componentValidations.validateComponentCustomStyleParams(
        ps,
        optionalSkinName,
        optionalStyleProperties
    )
    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }

    const compId = componentPointer.id
    const componentClassName = metaDataUtils.getComponentType(ps, componentPointer)
    const currentStyle = getComponentStyle(ps, componentPointer)

    let newStyleProperties
    let newStylePropertiesSource

    if (optionalStyleProperties) {
        newStyleProperties = optionalStyleProperties
        newStylePropertiesSource = generateStylePropertiesSource(ps, newStyleProperties)
    } else if (optionalSkinName) {
        // currentStyleProperties no longer relevant for the new skin
        newStyleProperties = {}
        newStylePropertiesSource = {}
    } else {
        // use the current, or a default empty object if no style is currently used
        newStyleProperties = _.get(currentStyle, 'style.properties', {})
        newStylePropertiesSource = _.get(currentStyle, 'style.propertiesSource', {})
    }

    const pageId = ps.pointers.components.getPageOfComponent(componentPointer).id
    const newStyle = {
        id: newStyleId,
        compId,
        componentClassName,
        pageId: '',
        styleType: 'custom',
        type: 'TopLevelStyle',
        skin: optionalSkinName ?? getComponentSkin(ps, componentPointer),
        style: {
            groups: {},
            properties: newStyleProperties,
            propertiesSource: newStylePropertiesSource,
            propertiesOverride: _.get(currentStyle, 'style.propertiesOverride', {})
        }
        //todo Shimi_Liderman 12/14/14 15:42 current custom style have metadata. Is it needed for new styles?
    }

    theme.styles.update(ps, newStyleId, newStyle, pageId)
    setComponentStyleId(ps, componentPointer, newStyleId || '')
}

/**
 * @param {ps} ps
 * @param {Object} properties style properties
 * @return {Object.<string, string>} the style properties mapped to a "value" or "theme" source
 */
function generateStylePropertiesSource(ps: PS, properties) {
    return _.mapValues(properties, function (value) {
        if (
            _.isNumber(value) ||
            _.isBoolean(value) ||
            (_.isString(value) && (_.includes(value, '#') || _.includes(value, 'rgb')))
        ) {
            return 'value'
        }
        return /^(font|color)_[0-9]+$/.test(value) ? 'theme' : 'value'
    })
}

function updateComponentStyleInternal(
    ps: PS,
    componentPointer: CompRef,
    styleValue,
    callback: Callback1<any>,
    isFull = false
) {
    updateComponentStyleAndAdjustLayout(ps, componentPointer, styleValue, callback, isFull)
}

function updateComponentStyle(ps: PS, componentPointer: CompRef, styleValue, callback?: Callback1<any>) {
    const isWithVariants = ps.pointers.components.isWithVariants(componentPointer)
    if (isWithVariants) {
        return updateComponentStyleAndAdjustLayout(ps, componentPointer, styleValue, callback, false)
    }
    return updateComponentStyleInternal(ps, componentPointer, styleValue, callback, false)
}

function updateSingleStyle(ps: PS, componentPointer: CompRef, currentStyle, newStyleValue) {
    const pageId = ps.pointers.components.getPageOfComponent(componentPointer).id

    _.set(
        newStyleValue,
        ['style', 'propertiesSource'],
        generateStylePropertiesSource(ps, newStyleValue.style.properties)
    )

    const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(
        ps,
        componentPointer,
        constants.DATA_TYPES.theme
    )
    if (shouldConsiderVariants) {
        return updateConsideringOverrides(ps, componentPointer, newStyleValue, constants.DATA_TYPES.theme)
    }
    theme.styles.update(ps, currentStyle.id, newStyleValue, pageId)
    return currentStyle.id
}

function updateComponentStyleAndAdjustLayout(
    ps: PS,
    componentPointer: CompRef,
    styleValue,
    callback: Callback1<any>,
    isFull: boolean
) {
    const validationResult = componentValidations.validateExistingComponent(ps, componentPointer, isFull)
    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }

    const currentStyle = getComponentStyle(ps, componentPointer)

    let updatedStyleId
    if (!currentStyle) {
        updatedStyleId = forkStyleInternal(ps, componentPointer, styleValue, isFull)
    } else {
        updatedStyleId = currentStyle.id
        if (currentStyle.styleType === 'system') {
            updatedStyleId = forkStyle(ps, componentPointer, styleValue)
        } else {
            styleValue.componentClassName =
                styleValue.componentClassName ||
                currentStyle.componentClassName ||
                metaDataUtils.getComponentType(ps, componentPointer)
            updateSingleStyle(ps, componentPointer, currentStyle, styleValue)
        }
    }
    ps.setOperationsQueue.executeAfterCurrentOperationDone(function () {
        if (callback) {
            callback({styleProperties: styleValue.style.properties})
        }
        theme.events.onChange.executeListeners(ps, {type: 'STYLE', values: updatedStyleId})
    })

    // make sure compStructure.skin && the skin inside the style are aligned

    setComponentInnerPointerSkin(ps, componentPointer, styleValue.skin, isFull)
}

/**
 * set a component's skin
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @param {string} skinName
 */
function setComponentSkin(ps: PS, componentPointer: Pointer, skinName: string) {
    const validationResult = componentValidations.validateSetSkinParams(ps, componentPointer, skinName)
    if (isError(validationResult)) {
        throw new Error(validationResult.error)
    }
    setComponentInnerPointerSkin(ps, componentPointer, skinName)
}

/**
 * gets the skin name from style if exists, otherwise - from structure
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @return {String} the name of the Skin corresponding the Component Reference. 'null' otherwise.
 */
function getComponentSkin(ps: PS, componentPointer: Pointer) {
    let skinName = null
    if (componentPointer) {
        const componentStyle = getComponentStyle(ps, componentPointer)
        if (componentStyle?.skin) {
            skinName = componentStyle.skin
        } else {
            // get skin name from structure.
            skinName = ps.dal.get(ps.pointers.getInnerPointer(componentPointer, 'skin'))
        }
    }
    return skinName
}

function getComponentSkinExports(ps: PS, componentPointer: Pointer) {
    const skinName = getComponentSkin(ps, componentPointer)
    return getSkinExports(skinName)
}

function getComponentSkinDefaults(ps: PS, componentPointer: Pointer) {
    const skinName = getComponentSkin(ps, componentPointer)
    return getSkinDefaultParams(skinName)
}

function getCompSkinParamValue(ps: PS, componentPointer: Pointer, paramValue: string) {
    const compStyle = getComponentStyle(ps, componentPointer).style.properties || {}
    const skinDefaults = getComponentSkinDefaults(ps, componentPointer)
    // @ts-expect-error
    if (_.isArray(skinDefaults[paramValue]) && skinDefaults[paramValue].length === 1) {
        return compStyle[skinDefaults[paramValue]]
    }
    return skinDefaults[paramValue]
}

function getComponentStyleInternal(ps: PS, componentPointer: Pointer, isFull?: boolean) {
    const compStyleId = getComponentStyleIdInternal(ps, componentPointer, isFull)
    if (compStyleId) {
        const pointers = isFull ? ps.pointers.full : ps.pointers
        const pageId = pointers.components.getPageOfComponent(componentPointer).id
        return theme.styles.internal.get(ps, compStyleId, pageId, isFull)
    }
    return null
}

const getComponentStyle = (ps: PS, componentPointer: Pointer) => {
    const componentStyleInternal = getComponentStyleInternal(ps, componentPointer, false)
    const shouldConsiderVariants = variantsUtils.shouldConsiderVariants(
        ps,
        componentPointer,
        constants.DATA_TYPES.theme
    )

    if (shouldConsiderVariants) {
        return variantsUtils.getComponentDataConsideringVariants(ps, componentPointer, constants.DATA_TYPES.theme)
    }
    return componentStyleInternal
}

const removeScopedStyle = (ps: PS, compPointer: Pointer) => {
    const isWithVariants = ps.pointers.components.isWithVariants(compPointer)
    if (!isWithVariants) {
        throw new Error('cannot remove non scoped style')
    }
    variantsUtils.removeComponentDataConsideringVariants(ps, compPointer, constants.DATA_TYPES.theme)
}

export default {
    style: {
        /**
         * set a component's style
         * @param {Pointer} componentReference
         * @param {String} styleId
         * @throws an exception if no corresponding component exists or invalid style name.
         */
        setId: setComponentStyleId,

        /**
         * get the component's style id
         * @param {Pointer} componentReference
         * @return {String} the Style ID of the corresponding component. 'null' otherwise.
         */
        getId: getComponentStyleId,

        /**
         * get the component's style
         * @param {Pointer} componentReference
         * @return {object} the Style of the corresponding component.
         */
        get: getComponentStyle,

        /**
         * set a new custom style for a component.
         * If no styleId is given - generates a new one.
         * If no skin is given - tries to use the current skin.
         * If no styleProperties are given - style will use the current styleProperties.
         * @param {Pointer} componentReference
         * @param {string} [optionalSkinName] the name of the skin the style will use
         * @param {Object} [optionalStyleProperties] the skin parameters that the style wants to override
         * @param {string} [optionalStyleId] the requested id of the new custom style
         * @return {string} the id of the created custom style
         */
        setCustom: setComponentCustomStyle,

        /**
         * The function updates a component's style definition value
         * @param {Pointer} componentReference
         * @param {object} styleValue style objects we want to update. system style will be cloned to custom style
         * @throws an exception if no corresponding component exists or invalid style value.
         */
        update: updateComponentStyle,

        /**
         * The function updates a component's style definition value without updating anchors
         * @param {Pointer} componentReference
         * @param {object} styleValue style objects we want to update
         * @throws an exception if no corresponding component exists or invalid style value.
         */
        updateAndAdjustLayout: updateComponentStyleAndAdjustLayout,

        /**
         * The function creates and add a new system style
         * @param {object} styleValue style objects we want to update
         * @param {object} component type
         * @throws an exception if no corresponding component exists or invalid style value.
         */
        createSystemStyle,

        /**
         * connect component to system style
         * @param {Pointer} componentReference
         * @param {String} system styleId
         * @throws an exception if no style isn't a system style
         */
        connectToThemeStyle,

        /**
         * The function forks a style to a new custom style.
         * @param {Pointer} componentReference
         * @param {object} styleValue. When undefined, the component's current style will be forked. Otherwise, this style will be assigned to the component
         * @throws an exception if no corresponding component exists or invalid style value.
         * * @return {String} New style Id
         */
        fork: forkStyle,

        /**
         * The function removes the matching variant's scoped style if exists
         * @param {Pointer} componentPointerWithVariants
         * @throws an exception if current component pointer is without variants
         */
        removeScoped: removeScopedStyle,
        /**
         * @param {string} componentType
         * @returns {string[]} an array of the possible theme style ids
         */
        getThemeStyleIds,

        internal: {
            /**
             * set a component's style
             * @param {Pointer} componentReference
             * @param {String} styleId
             * @param {function} callback - will be called with { styleProperties: object }
             * @param {boolean} True to use full dal
             * @throws an exception if no corresponding component exists or invalid style name.
             */
            setId: setComponentStyleIdInternal,

            /**
             * get the component's style id
             * @param {Pointer} componentReference
             * @param {boolean} True to use full dal
             * @return {String} the Style ID of the corresponding component. 'null' otherwise.
             */
            getId: getComponentStyleIdInternal,

            /**
             * get the component's style
             * @param {Pointer} componentReference
             * @param {boolean} True to use full dal
             * @param {boolean} [isFull]
             * @return {object} the Style of the corresponding component.
             */
            get: getComponentStyleInternal,

            /**
             * The function forks a style to a new custom style.
             * @param {Pointer} componentReference
             * @param {object} styleValue. When undefined, the component's current style will be forked. Otherwise, this style will be assigned to the component
             * @param {boolean} True to use full dal
             * @throws an exception if no corresponding component exists or invalid style value.
             * * @return {String} New style Id
             */
            fork: forkStyleInternal,

            update: updateComponentStyleInternal,

            generateStylePropertiesSource,
            createComponentStyleDef
        }
    },
    /** @class documentServices.components.skin*/
    skin: {
        /**
         * set a component's skin
         * @param {Pointer} componentReference
         * @param {String} skinName
         * @example documentServices.components.skin.set(photoCompRef, "wysiwyg.viewer.skins.photo.RoundPhoto");
         */
        set: setComponentSkin,
        /**
         * gets the skin name from style if exists, otherwise - from structure
         * @param {Pointer} componentReference
         * @return {String} the name of the Skin corresponding the Component Reference. 'null' otherwise.
         */
        get: getComponentSkin,

        getComponentSkinExports,
        getComponentSkinDefaults, //no one is calling this - need to remove
        getCompSkinParamValue
    }
}
