import type {CompStructure, Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import * as santaCoreUtils from '@wix/santa-core-utils'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import metaDataMap from './metaDataMap'
import constants from '../constants/constants'
import metaDataUtils from './metaDataUtils'
import META_DATA_TYPES from './metaDataTypes'
import documentModeInfo from '../documentMode/documentModeInfo'
import appStudioDataModel from '../appStudio/appStudioDataModel'
import popupUtils from '../page/popupUtils'
import hooks from '../hooks/hooks'
import theme from '../theme/theme'
import componentA11yAPI from '../accessibility/componentA11yAPI'
import dataModel from '../dataModel/dataModel'
import design from '../variants/design'
import experiment from 'experiment-amd'

const METADATA_TYPES_HOOKS = {}

// link metaData hooks to metaData types:
METADATA_TYPES_HOOKS[META_DATA_TYPES.MOVE_DIRECTIONS] = hooks.HOOKS.METADATA.MOVE_DIRECTIONS
METADATA_TYPES_HOOKS[META_DATA_TYPES.REMOVABLE] = hooks.HOOKS.METADATA.REMOVABLE
METADATA_TYPES_HOOKS[META_DATA_TYPES.DUPLICATABLE] = hooks.HOOKS.METADATA.DUPLICATABLE
METADATA_TYPES_HOOKS[META_DATA_TYPES.CAN_REPARENT] = hooks.HOOKS.METADATA.CAN_REPARENT
METADATA_TYPES_HOOKS[META_DATA_TYPES.CAN_BE_STRETCHED] = hooks.HOOKS.METADATA.CAN_BE_STRETCHED
METADATA_TYPES_HOOKS[META_DATA_TYPES.ROTATABLE] = hooks.HOOKS.METADATA.ROTATABLE
METADATA_TYPES_HOOKS[META_DATA_TYPES.FIXED_POSITION] = hooks.HOOKS.METADATA.FIXED_POSITION
METADATA_TYPES_HOOKS[META_DATA_TYPES.RESIZABLE_SIDES] = hooks.HOOKS.METADATA.RESIZABLE_SIDES
METADATA_TYPES_HOOKS[META_DATA_TYPES.CONTAINABLE] = hooks.HOOKS.METADATA.CONTAINABLE
METADATA_TYPES_HOOKS[META_DATA_TYPES.LAYOUT_LIMITS] = hooks.HOOKS.METADATA.LAYOUT_LIMITS
METADATA_TYPES_HOOKS[META_DATA_TYPES.SHOULD_AUTO_SET_NICKNAME] = hooks.HOOKS.METADATA.SHOULD_AUTO_SET_NICKNAME
METADATA_TYPES_HOOKS[META_DATA_TYPES.A11Y_CONFIGURABLE] = hooks.HOOKS.METADATA.A11Y_CONFIGURABLE
METADATA_TYPES_HOOKS[META_DATA_TYPES.MAXIMUM_CHILDREN_NUMBER] = hooks.HOOKS.METADATA.MAXIMUM_CHILDREN_NUMBER
METADATA_TYPES_HOOKS[META_DATA_TYPES.HIDE_AS_GHOST] = hooks.HOOKS.METADATA.HIDE_AS_GHOST

function isTryingToInsertParentIntoChild(ps: PS, parentComponentPointer: Pointer, childComponentPointer: Pointer) {
    let parent = ps.pointers.components.getParent(childComponentPointer)
    while (parent) {
        if (_.isEqual(parent, parentComponentPointer)) {
            return true
        }
        parent = ps.pointers.components.getParent(parent)
    }

    return false
}

function isComponentAContainer(ps: PS, componentPointer: Pointer): boolean {
    return (
        metaDataUtils.isContainer(metaDataUtils.getComponentType(ps, componentPointer)) ||
        ps.pointers.components.isMasterPage(componentPointer)
    )
}

function isSwitchingScopesAllowed(ps: PS, componentPointer: Pointer, potentialContainerPointer: Pointer): boolean {
    const compPageId = ps.pointers.components.getPageOfComponent(componentPointer).id
    const containerPageId = ps.pointers.components.getPageOfComponent(potentialContainerPointer).id

    if (compPageId !== containerPageId) {
        if (
            !ps.pointers.components.isInMasterPage(componentPointer) &&
            !ps.pointers.components.isInMasterPage(potentialContainerPointer)
        ) {
            return false
        } else if (documentModeInfo.isMobileView(ps)) {
            return isValidPageSwitch(ps, componentPointer, potentialContainerPointer)
        }
    }
    return true
}

function getContainedCompType(isByStructure: boolean, ps: PS, containedComp) {
    return isByStructure ? containedComp.componentType : metaDataUtils.getComponentType(ps, containedComp)
}

const isValidPageSwitch = (ps: PS, compPointer: Pointer, potentialContainerPointer: Pointer): boolean => {
    const {components} = ps.pointers

    if (!components.isMobile(compPointer) && !components.isMobile(potentialContainerPointer)) {
        return true
    }
    const desktopPageOfComp = components.getPageOfComponent(components.getDesktopPointer(compPointer))?.id
    const newPage = components.getPageOfComponent(potentialContainerPointer)?.id

    return isMobileOnly(ps, compPointer) || desktopPageOfComp === newPage
}

function isPopupContainer(ps: PS, potentialContainerPointer: Pointer) {
    const potentialContainerCompType = metaDataUtils.getComponentType(ps, potentialContainerPointer)
    return popupUtils.isPopupContainer(potentialContainerCompType)
}

function isContainerWideEnoughForComp(
    isByStructure: boolean,
    ps: PS,
    containedComp,
    potentialContainerPointer: Pointer
): boolean {
    const potentialContainerCompType = metaDataUtils.getComponentType(ps, potentialContainerPointer)

    const isContainedFullWidth = isCompFullWidth(ps, containedComp, isByStructure)
    const isPotentialContainerFullWidth = isCompFullWidth(ps, potentialContainerPointer as CompStructure)
    const isPotentialContainerSiteSegment = metaDataUtils.isSiteSegmentContainer(potentialContainerCompType)

    return !isContainedFullWidth || isPotentialContainerFullWidth || isPotentialContainerSiteSegment
}

function isContainable(isByStructure: boolean, ps: PS, comp, potentialContainerPointer: Pointer) {
    const canBeContained =
        isComponentAContainer(ps, potentialContainerPointer) &&
        (isContainerWideEnoughForComp(isByStructure, ps, comp, potentialContainerPointer) ||
            isPopupContainer(ps, potentialContainerPointer))

    if (isByStructure) {
        return canBeContained
    }
    return canBeContained && isSwitchingScopesAllowed(ps, comp, potentialContainerPointer)
}

function defaultContainable(isByStructure: boolean, ps: PS, comp, potentialContainerPointer: Pointer) {
    if (potentialContainerPointer) {
        return isContainable(isByStructure, ps, comp, potentialContainerPointer)
    } else if (
        documentModeInfo.isMobileView(ps) ||
        isCompFullWidth(ps, comp, isByStructure) ||
        isHorizontalDockToScreen(ps, comp)
    ) {
        return false
    }
    return true
}

function defaultGroupable(ps: PS, comp) {
    return getMetaData(ps, META_DATA_TYPES.CONTAINABLE, comp)
}

function defaultDuplicatable(ps: PS, comp) {
    const isNative =
        ps.pointers.isPointer(comp, false) && ps.dal.full.isExist(comp)
            ? isNativeMobileOnlyByPointer
            : isNativeMobileOnlyByStructure

    return !isNative(ps, comp) || !documentModeInfo.isMobileView(ps)
}

function getComponentStyle(ps: PS, compPointer: Pointer) {
    //Better use componentStylesAndSkinsAPI, but can't due to circular dependency
    const styleId = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'styleId'))
    const pageId = ps.pointers.components.getPageOfComponent(compPointer).id

    if (styleId) {
        return theme.styles.get(ps, styleId, pageId)
    }
    return null
}

function getShouldKeepChildrenInPlace(ps: PS, compPointer: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.SHOULD_KEEP_CHILDREN_IN_PLACE, compPointer)
}

function shouldBeRemovedByParent(ps: PS, componentPointer: Pointer) {
    const parentPointer = ps.pointers.components.getParent(componentPointer)
    const parentType = parentPointer && metaDataUtils.getComponentType(ps, parentPointer)
    return (
        !!parentType &&
        parentType === 'wysiwyg.viewer.components.RefComponent' &&
        santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(componentPointer)
    )
}

function shouldRemoveAsGhost(ps: PS, componentPointer: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.HIDE_AS_GHOST, componentPointer)
}

function getAnchorableHeight(ps: PS, compPointer: Pointer) {
    const compHeight = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout.height'))
    if (!isContainer(ps, compPointer)) {
        return compHeight
    }
    const compStyle = getComponentStyle(ps, compPointer)
    const compSkin = compStyle?.skin || ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'skin'))

    return compHeight - coreUtils.layoutAnchors.getNonAnchorableHeightForSkin(compSkin, compStyle)
}

function defaultIsAlignable(ps: PS, compPointer: Pointer) {
    const compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
    return !(compLayout.fixedPosition || compLayout.docked)
}

const defaultIsVisible = (ps: PS, compPointer: Pointer) => {
    const compsToAlwaysShow = [constants.COMP_IDS.HEADER, constants.COMP_IDS.FOOTER]
    if (_.includes(compsToAlwaysShow, compPointer.id)) {
        return true
    }
    const allowShowingFixedComponents = ps.dal.get(ps.pointers.general.getRenderFlag('allowShowingFixedComponents'))
    const compLayout = ps.dal.get(ps.pointers.getInnerPointer(compPointer, 'layout'))
    return allowShowingFixedComponents || !compLayout.fixedPosition
}

const DEFAULTS = {
    containable: defaultContainable.bind(null, false),
    containableByStructure: defaultContainable.bind(null, true),
    container: isComponentAContainer,
    canContain: isComponentAContainer,
    canContainByStructure: isComponentAContainer,
    isPublicContainer: isComponentAContainer,
    shouldKeepChildrenInPlace: true,
    disableable: false,
    isRepeater: false,
    isRepeatable: true,
    isContainCheckRecursive: true,
    moveDirections: [constants.MOVE_DIRECTIONS.HORIZONTAL, constants.MOVE_DIRECTIONS.VERTICAL],
    resizableSides: [
        constants.RESIZE_SIDES.TOP,
        constants.RESIZE_SIDES.LEFT,
        constants.RESIZE_SIDES.BOTTOM,
        constants.RESIZE_SIDES.RIGHT
    ],
    rotatable: false,
    flippable: false,
    anchors: {
        to: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.THRESHOLD},
        from: {allow: true, lock: constants.ANCHORS.LOCK_CONDITION.THRESHOLD}
    },
    canBeFixedPosition: true,
    canBeStretched: false,
    showMarginsIndicator: false,
    removable: true,
    alignable: defaultIsAlignable,
    duplicatable: defaultDuplicatable,
    crossSiteDuplicatable: defaultDuplicatable,
    hiddenable: true,
    minimalChildrenNumber: 0,
    maximumChildrenNumber: Number.MAX_VALUE,
    collapsible: true,
    dockable: true,
    fullWidth: defaultIsFullWidth,
    fullWidthByStructure: defaultIsFullWidthByStructure,
    styleCanBeApplied: false,
    groupable: defaultGroupable,
    hideAsGhost: false,
    enforceContainerChildLimitsByWidth: true,
    enforceContainerChildLimitsByHeight: true,
    shouldAutoSetNickname: true,
    layoutLimits: {
        minWidth: santaCoreUtils.siteConstants.COMP_SIZE.MIN_WIDTH,
        minHeight: santaCoreUtils.siteConstants.COMP_SIZE.MIN_HEIGHT,
        maxWidth: santaCoreUtils.siteConstants.COMP_SIZE.MAX_WIDTH,
        maxHeight: santaCoreUtils.siteConstants.COMP_SIZE.MAX_HEIGHT,
        aspectRatio: null as number | null
    },
    isProportionallyResizable,
    ignoreChildrenOnProportionalResize: false,
    enforceMaxDimensionsOnProportionalResize: false,
    resizeOnlyProportionally: false,
    isVisible: defaultIsVisible,
    enforceResizableCorners: false,
    forceMaintainIDsOnSerialize: false,
    canReparent: true,
    heightAuto: false,
    widthAuto: false,
    a11yConfigurable: true,
    canConnectToCode: true
}

type CreateCompDriver = (ps: PS, componentPointer: Pointer) => any

let createCompDriver: CreateCompDriver

const isExternalKey = (compType: string, metaDataKey: string) => {
    const isExternalComponent = _.get(api.getMetaDataMap(), [compType, 'isExternal'], false)
    const hasExternalMetaData = _.get(api.getMetaDataMap(), [compType, metaDataKey])
    return isExternalComponent && !_.isUndefined(hasExternalMetaData)
}

function getCompOrDefaultMetaData(compType: string, metaDataKey: string) {
    const metadataValue = _.get(api.getMetaDataMap(), [compType, metaDataKey])
    return _.isUndefined(metadataValue) ? DEFAULTS[metaDataKey] : metadataValue
}

function defaultIsFullWidth(ps: PS, compPointer: Pointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)

    return (
        santaCoreUtils.dockUtils.isHorizontalDockToScreen(compLayout) ||
        metaDataUtils.isLegacyFullWidthContainer(ps, compPointer)
    )
}

function defaultIsFullWidthByStructure(ps: PS, compStructure) {
    return (
        santaCoreUtils.dockUtils.isHorizontalDockToScreen(compStructure.layout) ||
        metaDataUtils.isLegacyFullWidthContainerByType(compStructure.componentType)
    )
}
const executeMetaDataHooks = (
    ps: PS,
    componentPointer: Pointer,
    metaDataKey: string,
    metaDataValue,
    ...additionalArguments
) => {
    const compType = metaDataUtils.getComponentType(ps, componentPointer)
    const args = [componentPointer, ...additionalArguments] // remove metaDataKey from arguments, leave additional trailing optional params]
    if (METADATA_TYPES_HOOKS[metaDataKey]) {
        return hooks.executeHookAndUpdateValue(ps, METADATA_TYPES_HOOKS[metaDataKey], compType, args, metaDataValue)
    }
}
/**
 * @param ps
 * @param metaDataKey
 * @param componentPointer
 * @param additionalArguments additional params per specific meta-data type requirements - will be passed on to the meta-data function
 * @returns {boolean|string|number}
 */
function getMetaData(ps: PS, metaDataKey: string, componentPointer: Pointer, ...additionalArguments: any[]) {
    const compType = metaDataUtils.getComponentType(ps, componentPointer)
    let metaDataValue = getCompOrDefaultMetaData(compType, metaDataKey)
    const args = [componentPointer, ...additionalArguments] // remove metaDataKey from arguments, leave additional trailing optional params]

    if (_.isFunction(metaDataValue)) {
        const isMetaDataExternal = isExternalKey(compType, metaDataKey)
        let metaDataApi

        if (isMetaDataExternal) {
            if (!createCompDriver) {
                return DEFAULTS[metaDataKey]
            }
            metaDataApi = createCompDriver(ps, componentPointer)
        } else {
            metaDataApi = ps
        }

        const argsForMetaData = [metaDataApi].concat(args)
        metaDataValue = metaDataValue.apply(this, argsForMetaData)
    }

    if (METADATA_TYPES_HOOKS[metaDataKey]) {
        return executeMetaDataHooks(ps, componentPointer, metaDataKey, metaDataValue, ...additionalArguments)
    }
    return metaDataValue
}

function getMetaDataByStructure(
    ps: PS,
    metaDataKey: string,
    componentStructure: CompStructure,
    ...additionalArguments: any[]
) {
    const compType = componentStructure.componentType
    let metaData = getCompOrDefaultMetaData(compType, metaDataKey)
    if (_.isFunction(metaData)) {
        metaData = metaData(ps, componentStructure, ...additionalArguments)
    }
    return metaData
}

function getCompResizableSides(ps: PS, component: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.RESIZABLE_SIDES, component)
}

function isCompFullWidth(ps: PS, comp: CompStructure, isByStructure: boolean = false) {
    if (isByStructure) {
        return getMetaDataByStructure(ps, META_DATA_TYPES.FULL_WIDTH_BY_STRUCTURE, comp)
    }
    return getMetaData(ps, META_DATA_TYPES.FULL_WIDTH, comp as Pointer)
}

function isContainer(ps: PS, componentPointer: Pointer): boolean {
    return getMetaData(ps, META_DATA_TYPES.CONTAINER, componentPointer)
}

function isPublicContainer(ps: PS, componentPointer: Pointer): boolean {
    return getMetaData(ps, META_DATA_TYPES.IS_PUBLIC_CONTAINER, componentPointer)
}

function isMovable(ps: PS, componentPointer: Pointer): boolean {
    return !!getMetaData(ps, META_DATA_TYPES.MOVE_DIRECTIONS, componentPointer).length
}

function isResizable(ps: PS, componentPointer: Pointer): boolean {
    return !!getCompResizableSides(ps, componentPointer).length
}

function isAlignable(ps: PS, componentPointer: Pointer): boolean {
    return getMetaData(ps, META_DATA_TYPES.ALIGNABLE, componentPointer) && isMovable(ps, componentPointer)
}

function isRotated(ps: PS, componentPointer: Pointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)

    return isRotatedByLayout(compLayout)
}

function isRotatedByLayout(layoutData) {
    // @ts-expect-error
    return _.isObject(layoutData) && layoutData.rotationInDegrees !== 0
}

function canBeStretched(ps: PS, componentPointer: Pointer) {
    const page = ps.pointers.components.getPageOfComponent(componentPointer)
    if (popupUtils.isPopup(ps, page.id) && !popupUtils.isPopupFullWidth(ps, page)) {
        return false
    }

    return getMetaData(ps, META_DATA_TYPES.CAN_BE_STRETCHED, componentPointer) && !isRotated(ps, componentPointer)
}

function canBeStretchedByStructure(ps: PS, componentStructure) {
    return (
        getMetaDataByStructure(ps, META_DATA_TYPES.CAN_BE_STRETCHED, componentStructure) &&
        !isRotatedByLayout(componentStructure.layout)
    )
}

function isStretched(ps: PS, componentPointer: Pointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)

    return santaCoreUtils.dockUtils.isStretched(compLayout)
}

function isHorizontalDockToScreen(ps: PS, componentPointer: Pointer) {
    const compLayoutPointer = ps.pointers.getInnerPointer(componentPointer, 'layout')
    const compLayout = ps.dal.get(compLayoutPointer)

    return santaCoreUtils.dockUtils.isHorizontalDockToScreen(compLayout)
}

function isRotatable(ps: PS, componentPointer: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.ROTATABLE, componentPointer) && !isStretched(ps, componentPointer)
}

function isFlippable(ps: PS, componentPointer: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.FLIPPABLE, componentPointer)
}

function isChildTypeAllowed(ps: PS, potentialContainerPointer: Pointer, childComponentType) {
    const childTypesAllowed = getMetaData(ps, META_DATA_TYPES.ALLOWED_CHILD_TYPES, potentialContainerPointer)
    if (!childTypesAllowed) {
        return true
    }
    return _.includes(childTypesAllowed, childComponentType)
}

function isParentTypeAllowed(ps: PS, comp: CompStructure, potentialContainerType, isByStructure: boolean = false) {
    const parentTypesAllowed = isByStructure
        ? getMetaDataByStructure(ps, META_DATA_TYPES.ALLOWED_PARENT_TYPES, comp)
        : getMetaData(ps, META_DATA_TYPES.ALLOWED_PARENT_TYPES, comp as Pointer)
    if (!parentTypesAllowed) {
        return true
    }
    return _.includes(parentTypesAllowed, potentialContainerType)
}

function isPotentialContainerForScreenWidthComp(ps: PS, potentialContainerPointer: Pointer) {
    const compType = metaDataUtils.getComponentType(ps, potentialContainerPointer)
    return (
        metaDataUtils.isLegacyFullWidthContainer(ps, potentialContainerPointer) || popupUtils.isPopupContainer(compType)
    )
}

function isComponentContainableRecursively(
    ps: PS,
    compToAddParam,
    potentialContainerPointer: Pointer,
    containCheckFunc
) {
    let isRecursive: boolean
    let res = true
    const targetedContainerPointer = potentialContainerPointer
    while (potentialContainerPointer && res) {
        isRecursive = getMetaData(ps, META_DATA_TYPES.IS_CONTAIN_CHECK_RECURSIVE, potentialContainerPointer)
        res = containCheckFunc(ps, compToAddParam, potentialContainerPointer, targetedContainerPointer)
        if (isRecursive) {
            potentialContainerPointer = ps.pointers.components.getParent(potentialContainerPointer)
        } else {
            potentialContainerPointer = null
        }
    }
    return res
}

function isComponentCanContain(
    ps: PS,
    componentPointer: Pointer,
    potentialContainerPointer: Pointer,
    targetedContainerPointer: Pointer
) {
    return getMetaData(
        ps,
        META_DATA_TYPES.CAN_CONTAIN,
        potentialContainerPointer,
        componentPointer,
        targetedContainerPointer
    )
}

function isContainableForDisplayedOnlyComps(ps: PS, componentPointer: Pointer, potentialContainerPointer: Pointer) {
    if (!documentModeInfo.isMobileView(ps)) {
        return true
    }

    if (!isRepeatedComponent(ps, componentPointer) && !isRepeatedComponent(ps, potentialContainerPointer)) {
        return true
    }

    const compItemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(componentPointer.id)
    const potentialContainerItemId = santaCoreUtils.displayedOnlyStructureUtil.getRepeaterItemId(
        potentialContainerPointer.id
    )
    return compItemId === potentialContainerItemId
}

const isCompRepeater = (ps: PS, compPointer: Pointer) => getMetaData(ps, META_DATA_TYPES.IS_REPEATER, compPointer)

const isCompTypeRepeatable = (compType: string) => getCompOrDefaultMetaData(compType, META_DATA_TYPES.IS_REPEATABLE)
const isCompRepeatable = (ps: PS, componentPointer: Pointer) =>
    getMetaData(ps, META_DATA_TYPES.IS_REPEATABLE, componentPointer)

const hasChildren = (ps: PS, componentPointer: Pointer) => {
    const children = ps.pointers.full.components.getChildren(componentPointer)
    return _.size(children) > 0
}

const allDescendantsAllowedInRepeater = (ps: PS, componentPointer: Pointer) => {
    const compRepeatable = isCompRepeatable(ps, componentPointer)
    const children = ps.pointers.components.getChildren(componentPointer)
    const recursiveChildrenAllowedInRepeater = (): boolean =>
        _.every(children, childPointer => allDescendantsAllowedInRepeater(ps, childPointer))
    return compRepeatable && recursiveChildrenAllowedInRepeater()
}

const allowContainmentForRepeater = (ps: PS, componentPointer: Pointer, potentialContainerPointer: Pointer) => {
    const isPotentialContainerRepeater = isCompRepeater(ps, potentialContainerPointer)
    const isPotentialContainerRepeaterWithChildren =
        isPotentialContainerRepeater && hasChildren(ps, potentialContainerPointer)
    if (isPotentialContainerRepeaterWithChildren) {
        return false
    }
    const isRepeaterOrInsideRepeater =
        isPotentialContainerRepeater || isRepeatedComponent(ps, potentialContainerPointer)
    return !isRepeaterOrInsideRepeater || allDescendantsAllowedInRepeater(ps, componentPointer)
}

const isChildOfSlottedComponent = (ps: PS, componentPointer: Pointer) => {
    const {getRootRefHostCompId} = santaCoreUtils.displayedOnlyStructureUtil
    const refHostId = getRootRefHostCompId(componentPointer.id)
    const parentPointer = refHostId
        ? ps.pointers.getPointer(refHostId, componentPointer.type)
        : ps.pointers.components.getParent(componentPointer)

    if (parentPointer && ps.dal.get(parentPointer).slotsQuery) {
        const {slotsQuery} = ps.dal.get(parentPointer)
        const slotsDataPointer = ps.pointers.getPointer(slotsQuery, 'slots')
        const {slots} = ps.dal.get(slotsDataPointer)
        return Object.keys(slots)
            .map(slotName => slots[slotName])
            .includes(componentPointer.id)
    }

    return false
}

function canReparent(ps: PS, componentPointer: Pointer) {
    if (componentPointer.type === constants.VIEW_MODES.MOBILE && isChildOfSlottedComponent(ps, componentPointer)) {
        return false
    }

    return getMetaData(ps, META_DATA_TYPES.CAN_REPARENT, componentPointer)
}

function isReparent(ps: PS, componentPointer: Pointer, potentialContainerPointer: Pointer) {
    return !ps.pointers.components.isSameComponent(
        ps.pointers.components.getParent(componentPointer),
        potentialContainerPointer
    )
}

function areChildAndParentTypesMatching(
    ps: PS,
    componentPointer: CompStructure,
    potentialContainerPointer: Pointer,
    isByStructure: boolean
) {
    return (
        !!potentialContainerPointer &&
        isChildTypeAllowed(ps, potentialContainerPointer, getContainedCompType(isByStructure, ps, componentPointer)) &&
        isParentTypeAllowed(
            ps,
            componentPointer,
            metaDataUtils.getComponentType(ps, potentialContainerPointer),
            isByStructure
        )
    )
}

function isComponentContainable(ps: PS, componentPointer: Pointer, potentialContainerPointer: Pointer) {
    if (
        !potentialContainerPointer ||
        !isContainer(ps, potentialContainerPointer) ||
        !allowContainmentForRepeater(ps, componentPointer, potentialContainerPointer) ||
        santaCoreUtils.displayedOnlyStructureUtil.isRefPointer(potentialContainerPointer)
    ) {
        return false
    }

    if (!canReparent(ps, componentPointer) && isReparent(ps, componentPointer, potentialContainerPointer)) {
        return false
    }
    if (!isValidPageSwitch(ps, componentPointer, potentialContainerPointer)) {
        return false
    }

    return (
        getMetaData(ps, META_DATA_TYPES.CONTAINABLE, componentPointer, potentialContainerPointer) &&
        !isTryingToInsertParentIntoChild(ps, componentPointer, potentialContainerPointer) &&
        areChildAndParentTypesMatching(ps, componentPointer as CompStructure, potentialContainerPointer, false) &&
        isComponentContainableRecursively(ps, componentPointer, potentialContainerPointer, isComponentCanContain) &&
        isContainableForDisplayedOnlyComps(ps, componentPointer, potentialContainerPointer) &&
        (!isHorizontalDockToScreen(ps, componentPointer) ||
            isPotentialContainerForScreenWidthComp(ps, potentialContainerPointer))
    )
}

function isTryingToRepeatNonRepeatableComponentsInsideRepeater(
    ps: PS,
    componentStructure,
    potentialContainerPointer: Pointer,
    targetedContainerPointer: Pointer
) {
    const isCheckingTarget = targetedContainerPointer.id === potentialContainerPointer.id
    if (!isCheckingTarget) {
        return false
    }

    // if parent is a repeater
    const isTargetCompRepeater = isCompRepeater(ps, targetedContainerPointer)
    const repeaterAlreadyHaveDirectChild = isTargetCompRepeater && hasChildren(ps, targetedContainerPointer)
    if (repeaterAlreadyHaveDirectChild) {
        // early return, to prevent expensive calculations
        return true
    }

    // if descendant of a repeater
    const isTargetedContainerInsideRepeater = isTargetCompRepeater || isRepeatedComponent(ps, targetedContainerPointer)
    if (isTargetedContainerInsideRepeater) {
        const childrenTypes = metaDataUtils.getChildrenTypesDeep([componentStructure], ps)
        return !_.every(childrenTypes, isCompTypeRepeatable)
    }

    return false
}

function isContainableByStructure(
    ps: PS,
    componentStructure,
    potentialContainerPointer: Pointer,
    targetedContainerPointer: Pointer
) {
    if (
        isTryingToRepeatNonRepeatableComponentsInsideRepeater(
            ps,
            componentStructure,
            potentialContainerPointer,
            targetedContainerPointer
        )
    ) {
        return false
    }

    const alwaysContainRecursively = getMetaData(
        ps,
        META_DATA_TYPES.ALWAYS_CONTAIN_RECURSIVELY,
        potentialContainerPointer
    )
    const indirectParent = potentialContainerPointer.id !== targetedContainerPointer.id
    if (alwaysContainRecursively && indirectParent) {
        return true
    }

    let isCompCanContainByStructure = getMetaData(
        ps,
        META_DATA_TYPES.CAN_CONTAIN_BY_STRUCTURE,
        potentialContainerPointer,
        componentStructure,
        targetedContainerPointer
    )

    if (santaCoreUtils.dockUtils.isHorizontalDockToScreen(componentStructure.layout)) {
        isCompCanContainByStructure =
            isCompCanContainByStructure && isPotentialContainerForScreenWidthComp(ps, potentialContainerPointer)
    }

    const isCompContainableByStructure = getMetaDataByStructure(
        ps,
        META_DATA_TYPES.CONTAINABLE_BY_STRUCTURE,
        componentStructure,
        potentialContainerPointer
    )

    return Boolean(isCompCanContainByStructure && isCompContainableByStructure)
}

function isComponentContainableByStructure(ps: PS, componentStructure, potentialContainerPointer: Pointer) {
    return (
        areChildAndParentTypesMatching(ps, componentStructure, potentialContainerPointer, true) &&
        isComponentContainableRecursively(ps, componentStructure, potentialContainerPointer, isContainableByStructure)
    )
}

/**
 * @param ps
 * @param [componentPointer]
 * @return {*}
 */
function isRepeatedComponent(ps: PS, componentPointer: Pointer) {
    return santaCoreUtils.displayedOnlyStructureUtil.isRepeatedComponent(componentPointer.id)
}

function isEnforcingContainerChildLimitationsByWidth(ps: PS, componentPointer: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_WIDTH, componentPointer)
}

function isEnforcingContainerChildLimitationsByHeight(ps: PS, componentPointer: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.ENFORCE_CONTAINER_CHILD_LIMITS_BY_HEIGHT, componentPointer)
}

function resizeOnlyProportionally(ps: PS, componentPointer: Pointer) {
    const layoutLimits = _.defaults(
        getMetaData(ps, META_DATA_TYPES.LAYOUT_LIMITS, componentPointer),
        DEFAULTS.layoutLimits
    )
    return !_.isNull(layoutLimits.aspectRatio)
}

function enforceResizableCorners(ps: PS, componentPointer: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.ENFORCE_RESIZABLE_CORNERS, componentPointer)
}

function isHorizontallyResizable(ps: PS, componentPointer: Pointer) {
    return (
        !!_.intersection(getCompResizableSides(ps, componentPointer), [
            constants.RESIZE_SIDES.LEFT,
            constants.RESIZE_SIDES.RIGHT
        ]).length || resizeOnlyProportionally(ps, componentPointer)
    )
}

function isVerticallyResizable(ps: PS, componentPointer: Pointer) {
    return (
        !!_.intersection(getCompResizableSides(ps, componentPointer), [
            constants.RESIZE_SIDES.TOP,
            constants.RESIZE_SIDES.BOTTOM
        ]).length || resizeOnlyProportionally(ps, componentPointer)
    )
}

function isProportionallyResizable(ps: PS, componentPointer: Pointer) {
    return isHorizontallyResizable(ps, componentPointer) && isVerticallyResizable(ps, componentPointer)
}

function isIgnoreChildrenOnProportionalResize(ps: PS, componentPointer: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.IGNORE_CHILDREN_ON_PROPORTINAL_RESIZE, componentPointer)
}

function enforceMaxDimensionsOnProportionalResize(ps: PS, componentPointer: Pointer) {
    return getMetaData(ps, META_DATA_TYPES.ENFORCE_MAX_DIM_ON_PROPORTIONAL_RESIZE, componentPointer)
}

function isAnchorableFrom(ps: PS, componentPointer: Pointer) {
    const compType = metaDataUtils.getComponentType(ps, componentPointer)
    const compAnchorsMetaData = coreUtils.getComponentsAnchorsMetaData()
    return _.get(compAnchorsMetaData[compType], 'from.allow', compAnchorsMetaData.default.from.allow)
}

function isAnchorableTo(ps: PS, componentPointer: Pointer) {
    const compType = metaDataUtils.getComponentType(ps, componentPointer)
    const compAnchorsMetaData = coreUtils.getComponentsAnchorsMetaData()
    return _.get(compAnchorsMetaData[compType], 'to.allow', compAnchorsMetaData.default.to.allow)
}

function getMobileOnlyMetaData(ps: PS, comp: Pointer, pageId: string) {
    const mobilePagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.MOBILE)
    if (!mobilePagePointer) {
        return {}
    }
    const mobileComponentPointer = ps.pointers.components.getComponent(comp.id, mobilePagePointer)
    if (!ps.dal.isExist(mobileComponentPointer)) {
        return {}
    }
    const mobileOnly = isMobileOnly(ps, mobileComponentPointer)
    return {mobileOnly}
}

function getMobileConversionConfig(ps: PS, comp, pageId: string) {
    const compType = comp.componentType || comp.documentType
    const mobileConversionConfig = _.get(api.getMetaDataMap(), [compType, META_DATA_TYPES.MOBILE_CONVERSION_CONFIG])
    const resolvedMobileConversionConfig = _.mapValues(mobileConversionConfig, function (value) {
        return _.isFunction(value) ? value(ps, comp, pageId) : value
    })
    let layoutLimits = _.get(api.getMetaDataMap(), [compType, META_DATA_TYPES.LAYOUT_LIMITS])
    if (_.isFunction(layoutLimits)) {
        const pagePointer = ps.pointers.components.getPage(pageId, constants.VIEW_MODES.DESKTOP)
        const compPointer = ps.pointers.components.getComponent(comp.id, pagePointer)
        layoutLimits = compPointer && ps.dal.isExist(compPointer) ? layoutLimits(ps, compPointer) : layoutLimits
    }
    const hiddenable = _.get(api.getMetaDataMap(), [compType, META_DATA_TYPES.HIDDENABLE], true)
    return _.defaults(
        resolvedMobileConversionConfig,
        layoutLimits ? {layoutLimits} : {},
        {hiddenable},
        getMobileOnlyMetaData(ps, comp, pageId)
    )
}

/**
 * get a mobile conversion config specific attribute
 * @param ps Private Services
 * @param {Object} comp component structure
 * @param attributeName the wanted attribute from the mobile conversion config
 * @param pageId the component pageId
 * @returns {*} value of the attribute conversion meta-data for comp
 *
 * @example
 * const isDesktopOnly = getMobileConversionConfigByName(ps, ps.dal.get(componentPointer), 'desktopOnly', pageId);
 */
function getMobileConversionConfigByName(ps: PS, comp, attributeName: string, pageId: string) {
    const compType = comp.componentType || comp.documentType
    const value = _.get(api.getMetaDataMap(), [compType, META_DATA_TYPES.MOBILE_CONVERSION_CONFIG, attributeName])
    return _.isFunction(value) ? value(ps, comp, pageId) : value
}

/**
 * Checks if component's height determined by its content
 * @param {ps} ps
 * @param {*} compPointer
 * @returns {boolean}
 */
const heightAuto = (ps: PS, compPointer: Pointer) => getMetaData(ps, META_DATA_TYPES.HEIGHT_AUTO, compPointer)

/**
 * Checks if component's height determined by its content
 * @param ps
 * @param {*} compStructure
 * @returns {boolean}
 */
const heightAutoByStructure = (ps: PS, compStructure) =>
    getMetaDataByStructure(ps, META_DATA_TYPES.HEIGHT_AUTO, compStructure)

/**
 * Checks if component's width determined by its content
 * @param {ps} ps
 * @param {*} compPointer
 * @returns {boolean}
 */
const widthAuto = (ps: PS, compPointer: Pointer) => getMetaData(ps, META_DATA_TYPES.WIDTH_AUTO, compPointer)

/**
 * Checks if component's width determined by its content
 * @param ps
 * @param {*} compStructure
 * @returns {boolean}
 */
const widthAutoByStructure = (ps: PS, compStructure) =>
    getMetaDataByStructure(ps, META_DATA_TYPES.WIDTH_AUTO, compStructure)

/**
 * Returns whether the given pointer is a native mobile only component
 *
 * @param {ps} ps
 * @param {Pointer} componentPointer
 * @returns {boolean}
 */
function isNativeMobileOnlyByPointer(ps: PS, componentPointer: Pointer) {
    return ps.extensionAPI.componentsMetadata.isNativeMobileOnlyByPointer(componentPointer)
}

/**
 * Returns whether the given comp structure is a native mobile only component
 *
 * @param ps
 * @param {Object} componentStructure
 * @returns {boolean}
 */
function isNativeMobileOnlyByStructure(ps: PS, componentStructure) {
    return !!constants.ALLOWED_MOBILE_COMPONENTS[componentStructure.componentType]
}

/**
 * Checks if a given pointer is a mobile only component, or a regular one (both mobile and desktop)
 *
 * @param ps
 * @param componentPointer
 * @returns {boolean}
 */
function isMobileOnly(ps: PS, componentPointer: Pointer) {
    const isCompMobileOnly = ps.extensionAPI.componentsMetadata.isMobileOnly(componentPointer)
    if (isCompMobileOnly) {
        const hasBrokenRefs = _([
            dataModel.getDataItemPointer(ps, componentPointer),
            design.getDesignItemPointer(ps, componentPointer)
        ])
            .compact()
            .some(ptr => !ps.dal.isExist(ptr))
        return !hasBrokenRefs
    }
    return isCompMobileOnly
}

const api = {
    getMetaDataMap: () => metaDataMap,

    init(createCompDriverFunction: CreateCompDriver) {
        createCompDriver = createCompDriverFunction
    },

    public: {
        /**
         * Checks if a component can be contained in a potential container
         * @param {ps} ps
         * @param {AbstractComponent} componentPointer the component that will be contained
         * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
         * @returns {boolean}
         */
        isContainable(ps: PS, componentPointer: Pointer, potentialContainerPointer?: Pointer) {
            return (
                !!potentialContainerPointer &&
                isPublicContainer(ps, potentialContainerPointer) &&
                isComponentContainable(ps, componentPointer, potentialContainerPointer)
            )
        },

        /**
         * Checks if a component can be contained in a potential container, using the component's serialized structure
         * @param {ps} ps
         * @param {Pointer} componentPointer the component pointer we want to check
         * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
         * @returns {boolean}
         */
        isContainableByStructure(ps: PS, componentPointer: CompStructure, potentialContainerPointer: Pointer) {
            return (
                !!potentialContainerPointer &&
                isPublicContainer(ps, potentialContainerPointer) &&
                isComponentContainableByStructure(ps, componentPointer, potentialContainerPointer)
            )
        },

        /**
         * Checks if a component is a valid container
         * @param {ps} ps
         * @param {AbstractComponent} componentPointer
         * @returns {boolean}
         */
        isContainer: isPublicContainer,

        /**
         *
         * @param {ps} ps
         * @param componentPointer
         * @returns {boolean|*}
         */
        isAlignable,

        /**
         * Checks if a component is movable
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isMovable,

        /**
         * Checks if a component is horizontally movable
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isHorizontallyMovable: function isHorizontallyMovable(ps: PS, componentPointer: Pointer) {
            return _.includes(
                getMetaData(ps, META_DATA_TYPES.MOVE_DIRECTIONS, componentPointer),
                constants.MOVE_DIRECTIONS.HORIZONTAL
            )
        },

        /**
         * Checks if a component is vertically movable
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isVerticallyMovable: function isVerticallyMovable(ps: PS, componentPointer: Pointer) {
            return _.includes(
                getMetaData(ps, META_DATA_TYPES.MOVE_DIRECTIONS, componentPointer),
                constants.MOVE_DIRECTIONS.VERTICAL
            )
        },

        /**
         * Returns component settings in mobile view
         * @param {ps} ps
         * @param {Component} comp
         * @param {string} pageId
         * @returns {MobileConversionConfig}
         */
        getMobileConversionConfig,

        /**
         * Used internally in documentServices by mobileHintsValidator
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {string} mobile conversion config
         */
        getMobileConversionConfigByName,

        /**
         * Used internally in documentServices by mobileHintsValidator
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {number} Minimal number of children.
         */
        getMinimalChildrenNumber(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.MINIMAL_CHILDREN_NUMBER, componentPointer)
        },

        allowedToContainMoreChildren(ps: PS, componentPointer: Pointer) {
            const maximumChildrenNumber = getMetaData(ps, META_DATA_TYPES.MAXIMUM_CHILDREN_NUMBER, componentPointer)

            if (maximumChildrenNumber === Number.MAX_VALUE) {
                return true
            }

            const componentPointers = ps.pointers.components

            const childrenPointers = componentPointers.getChildren(componentPointer)

            return maximumChildrenNumber > childrenPointers.length
        },

        getDefaultMobileProperties: function getDefaultMobileProperties(ps: PS, componentStructure, desktopProps) {
            const compType = componentStructure.componentType
            const metaData = getCompOrDefaultMetaData(compType, META_DATA_TYPES.DEFAULT_MOBILE_PROPERTIES)
            return _.isFunction(metaData) ? metaData(ps, componentStructure, desktopProps) : metaData
        },

        /**
         * Gets a component's resizable sides
         * @member documentServices.components.layout
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {Array}
         */
        getResizableSides: function getResizableSides(ps: PS, componentPointer: Pointer) {
            return getCompResizableSides(ps, componentPointer)
        },

        /**
         * Checks if a component is horizontally resizable
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isHorizontallyResizable,

        /**
         * Checks if a component is vertically resizable
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isVerticallyResizable,

        /**
         * Checks if a component is proportionally resizable
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isProportionallyResizable(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.IS_PROPORTIONALLY_RESIZABLE, componentPointer)
        },

        /**
         * If resizing a container proportionally, don't try to proportionally resize its children
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isIgnoreChildrenOnProportionalResize,

        /**
         * If resizing a comp proportionally, don't ignore maxWidth/maxHeight defined in component Meta Data
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        enforceMaxDimensionsOnProportionalResize,

        /**
         * Checks if a component is resizable
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isResizable,

        /**
         * Checks if a component is rotatable
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isRotatable,

        /**
         * Checks if a component can be flipped
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        isFlippable,

        isGroupable: function isGroupable(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.GROUPABLE, componentPointer) && canReparent(ps, componentPointer)
        },

        /**
         * Checks if a component can be fixed position
         * @param {ps} ps
         * @param {Pointer} componentPointer
         * @returns {boolean}
         */
        canBeFixedPosition: function canBeFixedPosition(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.FIXED_POSITION, componentPointer)
        },

        isModal: function isModal(ps: PS, componentPointer: Pointer) {
            return !!getMetaData(ps, META_DATA_TYPES.MODAL, componentPointer)
        },

        isMobileOnly,
        isNativeMobileOnlyByPointer,
        isNativeMobileOnlyByStructure,

        isUsingLegacyAppPartSchema: function isUsingLegacyAppPartSchema(ps: PS, componentPointer: Pointer) {
            return !!getMetaData(ps, META_DATA_TYPES.USING_LEGACY_APP_PART_SCHEMA, componentPointer)
        },

        /**
         * Checks if a component can be horizontal dock to screen
         * @param {ps} ps
         * @param {AbstractComponent} componentPointer
         * @returns {boolean}
         */
        canBeStretched,

        canBeStretchedByStructure,

        resizeOnlyProportionally,

        enforceResizableCorners,

        isRepeatedComponent,

        isEnforcingContainerChildLimitations: function isEnforcingContainerChildLimitations(
            ps: PS,
            componentPointer: Pointer
        ) {
            return (
                isEnforcingContainerChildLimitationsByWidth(ps, componentPointer) ||
                isEnforcingContainerChildLimitationsByHeight(ps, componentPointer)
            )
        },

        isEnforcingContainerChildLimitationsByWidth,

        isEnforcingContainerChildLimitationsByHeight,

        /**
         * Checks if a component is rendered in a full width mode
         * @param {ps} ps
         * @param {AbstractComponent} componentPointer
         * @returns {boolean}
         */
        isFullWidth: function isFullWidth(ps: PS, componentPointer: Pointer) {
            return isCompFullWidth(ps, componentPointer as CompStructure, false)
        },

        /**
         * Checks if a component is rendered in a full width mode, using the component's serialized structure
         * @param ps
         * @param {object} componentStructure the serialized structure of the component
         * @returns {boolean}
         */
        isFullWidthByStructure: function isFullWidthByStructure(ps: PS, componentStructure) {
            return isCompFullWidth(ps, componentStructure, true)
        },

        isRemovable: function isRemovable(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.REMOVABLE, componentPointer)
        },

        isDuplicatable: function isDuplicatable(ps: PS, componentPointer: Pointer, potentialParentPointer?: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.DUPLICATABLE, componentPointer, potentialParentPointer)
        },

        isCrossSiteDuplicatable: function isCrossSiteDuplicatable(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.CROSS_SITE_DUPLICATABLE, componentPointer)
        },

        isCrossSiteDuplicatableByStructure: function isCrossSiteDuplicatableByStructure(
            ps: PS,
            componentStructure: CompStructure
        ) {
            return getMetaDataByStructure(ps, META_DATA_TYPES.CROSS_SITE_DUPLICATABLE, componentStructure)
        },

        isStyleCanBeApplied: function isStyleCanBeApplied(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.STYLE_CAN_BE_APPLIED, componentPointer)
        },

        /**
         *
         * @param ps
         * @param compPointer
         * @param [newLayout] - optionally pass newLayout, for components which calculate limits dynamically
         * @returns {*}
         */
        getLayoutLimits(ps: PS, compPointer: Pointer, newLayout?) {
            const layoutLimits = _.defaults(
                getMetaData(ps, META_DATA_TYPES.LAYOUT_LIMITS, compPointer, newLayout),
                DEFAULTS.layoutLimits
            )
            // TODO: WEED-15708 - Remove this monstrosity to a more robust mechanism
            const isMobileWidgetRoot =
                ps.pointers.components.isMobile(compPointer) &&
                appStudioDataModel.isWidgetPage(ps, _.get(ps.pointers.components.getParent(compPointer), 'id'))
            layoutLimits.maxWidth = Math.min(
                layoutLimits.maxWidth,
                DEFAULTS.layoutLimits.maxWidth,
                isMobileWidgetRoot ? 320 : Number.MAX_SAFE_INTEGER
            )
            layoutLimits.maxHeight = Math.min(layoutLimits.maxHeight, DEFAULTS.layoutLimits.maxHeight)
            layoutLimits.minWidth = Math.max(layoutLimits.minWidth, DEFAULTS.layoutLimits.minWidth)
            layoutLimits.minHeight = Math.max(layoutLimits.minHeight, DEFAULTS.layoutLimits.minHeight)
            return layoutLimits
        },
        isHiddenable(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.HIDDENABLE, componentPointer)
        },
        isCollapsible(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.COLLAPSIBLE, componentPointer)
        },

        isDockable(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.DOCKABLE, componentPointer)
        },
        isDisableable(ps: PS, componentPointer: Pointer) {
            return !!getMetaData(ps, META_DATA_TYPES.DISABLEABLE, componentPointer)
        },
        isAnchorableFrom,
        isAnchorableTo,
        isVisible(ps: PS, componentPointer: Pointer) {
            return getMetaData(ps, META_DATA_TYPES.VISIBLE, componentPointer)
        },
        isPotentialContainerForScreenWidthComp(ps: PS, compPointer: Pointer) {
            return isPotentialContainerForScreenWidthComp(ps, compPointer)
        },
        canReparent,

        heightAuto,
        heightAutoByStructure,

        widthAuto,
        widthAutoByStructure,
        /**
         * @param {ps} ps
         * @param {AbstractComponent} componentPointer
         * @returns {Boolean}
         */
        isA11yConfigurable(ps: PS, componentPointer: Pointer) {
            return (
                getMetaData(ps, META_DATA_TYPES.A11Y_CONFIGURABLE, componentPointer) &&
                !!componentA11yAPI.getA11ySchema(ps, componentPointer)
            )
        }
    },

    /**
     * Checks if a component can be contained in a potential container
     * @param {ps} ps
     * @param {AbstractComponent} componentPointer the component that will be contained
     * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
     * @returns {boolean}
     */
    isContainable: isComponentContainable,

    /**
     * Checks if a component can be contained in a potential container, using the component's serialized structure
     * @param {ps} ps
     * @param {object} componentStructure the serialized structure of the component that will be contained
     * @param {AbstractComponent} potentialContainerPointer the container which will be the component's new parent
     * @returns {boolean}
     */
    isContainableByStructure: isComponentContainableByStructure,

    /**
     * Checks if a component is a valid container
     * @param {ps} ps
     * @param {AbstractComponent} componentPointer
     * @returns {boolean}
     */
    isContainer,
    // Internal methods for use within DocumentServices
    /**
     * Used internally in documentServices by the structure module
     * @param {Object} compPointer
     * @returns {boolean} shouldKeepChildrenInPlace metaData of the component (not to be confused with the renderFlag 'enforceShouldKeepChildrenInPlace')
     */
    getShouldKeepChildrenInPlace,
    /**
     * Used internally in documentServices by the anchors module
     * @param {Object} compPointer
     * @returns {Number} height that can be used to calculate distance when creating bottom_parent anchors
     */
    getAnchorableHeight,

    /**
     * Used internally in documentServices by componentCode module to generate nicknames for the components
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {string} The default nickname that should be used for the component. Adds a 'mobile' prefix if the component is MobileOnly
     */
    getDefaultNickname: (ps: PS, componentPointer: Pointer) =>
        ps.extensionAPI.componentsMetadata.getDefaultNickname(componentPointer),

    /**
     * Used internally in documentServices by componentCode module to decide if we should auto generate nickname for the given component
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {boolean}
     */
    shouldAutoSetNickname(ps: PS, componentPointer: Pointer) {
        if (experiment.isOpen('dm_moveShouldAutoSetNicknameToExt')) {
            const metaDataKey = META_DATA_TYPES.SHOULD_AUTO_SET_NICKNAME
            const metaDataValue = ps.extensionAPI.componentsMetadata.shouldAutoSetNickname(componentPointer)
            if (METADATA_TYPES_HOOKS[metaDataKey]) {
                return executeMetaDataHooks(ps, componentPointer, metaDataKey, metaDataValue)
            }
            return metaDataValue
        }
        return getMetaData(ps, META_DATA_TYPES.SHOULD_AUTO_SET_NICKNAME, componentPointer)
    },

    canConnectToCode(ps: PS, componentPointer: Pointer) {
        return ps.extensionAPI.componentsMetadata.canConnectToCode(componentPointer)
    },

    /**
     * Used internally in documentServices by serialization to decide if this component forces maintaining IDs
     * even if the `serialize` function did not pass `maintainIdentifiers` as true.
     * @param {ps} ps
     * @param componentPointer
     * @returns {boolean}
     */
    shouldForceMaintainIDsOnSerialize(ps: PS, componentPointer: Pointer) {
        return getMetaData(ps, META_DATA_TYPES.FORCE_MAINTAIN_IDS_ON_SERIALIZE, componentPointer)
    },

    /**
     * Used internally in documentServices to define if component deletion should be processed through its parent deletion.
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {Boolean}
     */
    shouldBeRemovedByParent,

    /**
     * Used internally in documentServices to define if component should be hidden instead of removed.
     * @param {ps} ps
     * @param {Pointer} componentPointer
     * @returns {Boolean}
     */
    shouldRemoveAsGhost,
    /**
     * Used internally in documentServices by the component module
     * @param {ps} ps
     * @param {AbstractComponent} componentPointer
     * @returns {string} component type name
     */
    getComponentType: metaDataUtils.getComponentType
}

export default api
