import $ from 'zepto'
import _ from 'lodash'
import * as warmupUtils from '@wix/santa-ds-libs/src/warmupUtils'

const {
    tpaWarmup,
    loggingUtils: {performance}
} = warmupUtils

function reportIframeStartLoading($iframe, siteData, lazyLoad, compId = $iframe.attr('name')) {
    if (tpaWarmup.isTPAComp(siteData, compId)) {
        tpaWarmup.reportWarmupPerformanceBiEvent(tpaWarmup.events.APP_IFRAME_START_LOADING, siteData, compId, {
            lazyLoad
        })
    }
}

function getRealQueryString(queryString, instancePath, clientSpecMap) {
    const realInstanceValue = _.get(clientSpecMap, instancePath)
    return _.replace(queryString, `\${${instancePath}}`, realInstanceValue)
}

function getSrcAndReplaceTemplateValuesIfNeeded(siteData, src, compId) {
    const templatedValues = tpaWarmup.getTemplateValuesFromUrl(src)
    if (siteData.isClientAfterSSR() && !_.isEmpty(templatedValues)) {
        const {0: baseUrlPath, 1: instancePath} = templatedValues
        const clientSpecMap = siteData.getClientSpecMap()
        const queryString = src.split('?')[1]
        const realQueryString = getRealQueryString(queryString, instancePath, clientSpecMap)
        tpaWarmup.reportWarmupPerformanceBiEvent(
            tpaWarmup.events.FAILED_TO_LOAD_IFRAME_FROM_SSR_CACHE,
            siteData,
            compId
        )
        return `${_.get(clientSpecMap, baseUrlPath)}?${realQueryString}`
    }

    return src
}

export default {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIframesSrc(siteData) {},
    reportPresetIframes(siteData) {
        $('#SITE_CONTAINER iframe[src]').each(function () {
            const $this = $(this)
            const src = $this.attr('src')
            if (src) {
                const name = $this.attr('name')
                const dataSrc = getSrcAndReplaceTemplateValuesIfNeeded(siteData, $this.data('src'), name)
                if (!dataSrc) {
                    performance.start(`Section ${name}`, {category: 'sections'})
                    reportIframeStartLoading($this, siteData, false, name)
                }
            }
        })
    }
}
