import _ from 'lodash'
import dataModel from '../../../dataModel/dataModel'
import type {PS} from '@wix/document-services-types'

export default {
    componentsClasses: ['wixapps.integration.components.AppPart'],
    collect(ps: PS, comp) {
        const compData = dataModel.getDataItem(ps, comp)

        if (!compData) {
            return []
        }

        const partData = compData.appPartName ? compData : compData.dataItemRef

        return _(partData)
            .get(['appLogicCustomizations'], [])
            .reduce((styles, rule) => {
                if (rule.key === 'comp.style' && rule.value) {
                    styles.push(rule.value)
                }
                return styles
            }, [])
    }
}
