import type {PS} from '@wix/document-services-types'
import experiment from 'experiment-amd'
import consts from '../../constants/constants'
import * as mobileConversionUtils from '../../mobileConversion/modules/utils'
import metaDataUtils from '../metaDataUtils'

export default {
    duplicatable(ps: PS, comp, potentialContainer) {
        if (comp.type === consts.VIEW_MODES.MOBILE) {
            return false
        }

        const isDuplicatingAsSibling = ps.pointers.components.isSameComponent(
            ps.pointers.components.getParent(comp),
            potentialContainer
        )
        const numOfColumns = potentialContainer && ps.pointers.components.getChildren(potentialContainer).length

        return !!(isDuplicatingAsSibling && numOfColumns < 5)
    },
    groupable: false,
    enforceContainerChildLimitsByWidth: false,
    enforceContainerChildLimitsByHeight: true,
    anchors: {
        to: {allow: true, lock: consts.ANCHORS.LOCK_CONDITION.NEVER},
        from: {allow: false, lock: consts.ANCHORS.LOCK_CONDITION.ALWAYS}
    },
    isContainCheckRecursive: false,
    mobileConversionConfig: {
        nestOverlayingSiblings: false,
        structuralItem: true,
        filterChildrenWhenHidden: true,
        stretchHorizontally: true,
        minHeight(ps: PS) {
            return experiment.isOpen('dm_fitContainerToChildrenHeightRecoursively') &&
                mobileConversionUtils.shouldEnableImprovedMergeFlow(ps)
                ? 50
                : 200
        },
        category: 'column',
        preserveAspectRatio: false,
        hideWhenEmptyBackgroundContainer: true,
        fixedSize(ps: PS, comp, pageId: string) {
            return metaDataUtils.getMobileFixedSizesForColumn(ps, comp, pageId)
        }
    }
}
