import type {PS} from '@wix/document-services-types'
import hooks from '../hooks/hooks'
import fileSystemAPI from '../wixCode/services/fileSystemAPI'

const CUSTOM_ELEMENT_FILE_PATH_PREFIX = 'public/custom-elements/'

const isCustomElementFile = filePath => filePath.startsWith(CUSTOM_ELEMENT_FILE_PATH_PREFIX)

async function handleFileContentChanged(ps: PS, fileDescriptor) {
    const filePath = fileDescriptor.location
    if (isCustomElementFile(filePath)) {
        await fileSystemAPI.flush(ps, {origin: 'CUSTOM_ELEMENTS'})
        const pointer = ps.pointers.customElements.getCacheKillerCounter(filePath)
        const counter = (ps.dal.get(pointer) || 0) + 1
        ps.dal.set(pointer, counter)
    }
}

const initMethod = () => {
    hooks.registerHook(hooks.HOOKS.WIX_CODE.FILE_CONTENT_CHANGED, handleFileContentChanged)
}

export default {
    initMethod
}
