import _ from 'lodash'
import type {Experiment, Pointer} from '@wix/document-services-types'
import type {IndexKey, Namespace} from '../../types'
import {DalValue, DmStore, createStore} from '../store'
import {PointerMap} from './PointerMap'

export type IndexedValues = Record<string, Record<string, DalValue>>
type IndexName = string
// type IndexedKey = string
// type IndexedKeys = Map<IndexedKey, IndexedValues>
type FilterId = string
export type Indexes = Map<IndexName, Filters>
export type Filters = Map<FilterId, Namespaces>
export type Namespaces = Map<NamespaceKey, QueryNamespace>
export type NamespaceKey = string
export type QueryNamespace = Map<any, any>

const QUERY_VALIDATION_KEY = Symbol()
const EMPTY_MAP = new Map()

const getEnsuredKey = (map: Map<string, any>, key: string) => {
    let value = map.get(key)

    if (!value) {
        value = new Map<string, any>()
        map.set(key, value)
    }

    return value
}

const getIndexKey = (indexName: string, id: string): IndexKey => {
    if (_.isNil(id)) {
        throw new Error(`id is required for index results from ${indexName}`)
    }

    return {
        verificationKey: QUERY_VALIDATION_KEY,
        indexName,
        id
    }
}

export type ValueToIndexIds = (namespace: Namespace, value: DalValue) => string[]
export type ValueToIndexKeys = (namespace: Namespace, value: DalValue) => IndexKey[]

type IndexValueRemovalCallback = () => void

export interface FilterFactory {
    indexName: string
    getPassedFilters: ValueToIndexKeys
    getFilter(id: string | null): IndexKey
}

export interface QueryIndex {
    getIndexedValues(indexKey: IndexKey): Namespaces
    updateIndex(pointer: Pointer, newValue: DalValue): DalValue
    createFilterFactory(indexName: string, filter: ValueToIndexIds): FilterFactory
    forceFiltersInitialization(): void
}

const getMatchingIndexKeys = (
    indexName: string,
    filter: ValueToIndexIds,
    namespace: Namespace,
    value: DalValue
): IndexKey[] => {
    const matchingIds = filter(namespace, value)
    return _.map(matchingIds, (id: string) => getIndexKey(indexName, id))
}

export function createQueryIndex(experimentInstance: Experiment): QueryIndex {
    const indexes: Indexes = new Map<string, Filters>()
    const filterFactories: FilterFactory[] = []
    const uninitiatedFilters = new Map<string, FilterFactory>()
    const currentValueStore: DmStore = createStore()
    const currentValueRemovalStore: PointerMap<IndexValueRemovalCallback[]> = new PointerMap<
        IndexValueRemovalCallback[]
    >()

    const withRemovalCallbacksAddition = (
        pointer: Pointer,
        cb: (removalCallbacks: IndexValueRemovalCallback[]) => void
    ) => {
        let removalCallbacks: IndexValueRemovalCallback[] | undefined =
            undefined as unknown as IndexValueRemovalCallback[]

        removalCallbacks = currentValueRemovalStore.get(pointer)

        if (!removalCallbacks) {
            removalCallbacks = []
            currentValueRemovalStore.set(pointer, removalCallbacks)
        }
        cb(removalCallbacks)
    }

    const removePreviousValue = (pointer: Pointer): void => {
        const removalCallbacks: IndexValueRemovalCallback[] | undefined = currentValueRemovalStore.get(pointer)
        if (removalCallbacks) {
            let cb
            while ((cb = removalCallbacks.shift())) {
                cb()
            }
        }
    }

    const updateFilterFactoryIndex = (
        filterFactory: FilterFactory,
        pointer: Pointer,
        value: DalValue,
        removalCallbacks: IndexValueRemovalCallback[]
    ) => {
        const {type: namespace, id} = pointer
        const filters: IndexKey[] = filterFactory.getPassedFilters(namespace, value)
        const namedIndex = getEnsuredKey(indexes, filterFactory.indexName)

        filters.forEach((filter: IndexKey) => {
            const filterId: string = filter.id
            const namespaces = getEnsuredKey(namedIndex, filterId)
            const specificNamespace = getEnsuredKey(namespaces, namespace)
            specificNamespace.set(id, value)
            removalCallbacks.push(() => {
                specificNamespace.delete(id)
            })
        })
    }

    const initiateFilter = (indexName: string) => {
        const filterFactory = uninitiatedFilters.get(indexName)
        if (!filterFactory) {
            return
        }
        uninitiatedFilters.delete(indexName)
        filterFactories.push(filterFactory)

        currentValueStore.forEach((pointer: Pointer, value: DalValue) => {
            if (!_.isNil(value)) {
                withRemovalCallbacksAddition(pointer, cbs =>
                    updateFilterFactoryIndex(filterFactory, pointer, value, cbs)
                )
            }
        })
    }

    const getIndexedValues = (indexKey: IndexKey): Namespaces => {
        if (indexKey?.verificationKey !== QUERY_VALIDATION_KEY) {
            throw new Error('Invalid Query filter indexKey')
        }

        const {indexName, id} = indexKey

        if (experimentInstance.isOpen('dm_lazyFilterRegistration') && uninitiatedFilters.has(indexName)) {
            initiateFilter(indexName)
        }

        return indexes.get(indexName)?.get(id) ?? EMPTY_MAP
    }

    const updateIndex = (pointer: Pointer, newValue: DalValue): DalValue => {
        const previousValue: DalValue = currentValueStore.get(pointer)

        // the dal always clones an entire value when it changes so we can use reference compare
        if (previousValue === newValue) {
            return previousValue
        }

        currentValueStore.set(pointer, newValue)

        if (!_.isNil(previousValue)) {
            removePreviousValue(pointer)
        }

        if (_.isNil(newValue)) {
            return previousValue
        }

        withRemovalCallbacksAddition(pointer, cbs => {
            filterFactories.forEach(filterFactory => {
                updateFilterFactoryIndex(filterFactory, pointer, newValue, cbs)
            })
        })
        return previousValue
    }

    /**
     * Creates a new filter factory with the query index and initializes its index with the current state
     * @param indexName
     * @param filter
     */
    const createFilterFactory = (indexName: string, filter: ValueToIndexIds): FilterFactory => {
        const filterFactory: FilterFactory = {
            indexName,
            getPassedFilters: (namespace: Namespace, value: DalValue) =>
                getMatchingIndexKeys(indexName, filter, namespace, value),
            getFilter: (id: string): IndexKey => getIndexKey(indexName, id)
        }
        uninitiatedFilters.set(indexName, filterFactory)

        if (!experimentInstance.isOpen('dm_lazyFilterRegistration')) {
            initiateFilter(indexName)
        }

        return filterFactory
    }

    const forceFiltersInitialization = () => {
        for (const indexName of uninitiatedFilters.keys()) {
            initiateFilter(indexName)
        }
    }

    return {
        getIndexedValues,
        updateIndex,
        createFilterFactory,
        forceFiltersInitialization
    }
}
