import type {RectTL, RelativePosition} from '@wix/document-services-types'
import _ from 'lodash'

const measuringTypes: Record<string, Function> = {}

function setMeasuringForType(compTypes: string | string[], measuringFunc: Function) {
    if (!_.isArray(compTypes)) {
        compTypes = [compTypes]
    }

    _.forEach(compTypes, function (type) {
        measuringTypes[type] = measuringFunc
    })
}

function getMeasuringByType(compType: string) {
    return measuringTypes[compType]
}

export type MeasuringFunc = (
    compMeasuringInfo,
    width: number,
    height: number,
    siteMarginBottom: number
) => RelativePosition

function getFixedComponentMeasurements(
    measuringFunc: MeasuringFunc,
    compMeasuringInfo,
    screenSize,
    siteMarginBottom
): RectTL {
    if (measuringFunc) {
        const compLayout = compMeasuringInfo.layout
        const measurements = measuringFunc(
            compMeasuringInfo,
            screenSize.width,
            screenSize.height,
            siteMarginBottom || 0
        )
        return {
            top: measurements.top,
            left: measurements.left,
            height: compLayout.height,
            width: compLayout.width
        }
    }
    return null
}

export default {
    getFixedComponentMeasurements,
    setMeasuringForType,
    getMeasuringByType
}
