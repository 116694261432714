import type {PS} from '@wix/document-services-types'
import _ from 'lodash'
import appStudioDataModel from './appStudioDataModel'

function getAllDependencies(ps: PS) {
    return appStudioDataModel.getAppStudioData(ps).dependencies
}

function addDependency(ps: PS, appDefId: string, dependencyInfo?) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)

    const dependency = _.find(appStudioData.dependencies, {appDefId})
    if (dependency) {
        dependency.dependencyData = _.uniq([...dependency.dependencyData, ...dependencyInfo.dependencyData])
    } else {
        appStudioData.dependencies.push({
            appDefId,
            ...dependencyInfo
        })
    }

    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
}

function removeDependency(ps: PS, appDefId: string) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)

    appStudioData.dependencies = appStudioData.dependencies.filter(dependency => dependency.appDefId !== appDefId)
    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
}

function setDependencies(ps: PS, dependencies) {
    const appStudioData = appStudioDataModel.getAppStudioData(ps)

    appStudioData.dependencies = dependencies
    appStudioDataModel.updateAppStudioMetaData(ps, appStudioData)
}

function hasDependency(ps: PS, appDefId: string) {
    return getAllDependencies(ps).some(dependency => dependency.appDefId === appDefId)
}

export default {
    hasDependency,
    addDependency,
    removeDependency,
    setDependencies,
    getAllDependencies
}
