import _ from 'lodash'
import fetchResponseErrorObject from '../utils/fetchResponseErrorObject'
import wixCodeServiceFacade from './wixCodeServiceFacade'
import wixCodeMonitoring from './wixCodeMonitoringWrapper'
import clientSpecMapUtils from '../utils/clientSpecMapUtils'
import {getMetaSiteId} from '../../utils/dalUtil'
import type {PS} from '@wix/document-services-types'

function isAlreadySavedError(error) {
    const errorCode = fetchResponseErrorObject.safeGetErrorCode(error)
    return errorCode === -409 || errorCode === -409003
}

async function markAppImmutable({traceFunc, isAppReadOnly, wixCodeApp, baseUrl, metasiteId, gridAppId}) {
    const traceEnd = traceFunc({action: 'markAppImmutable'})

    if (isAppReadOnly) {
        traceEnd({message: 'no changes'})
        return
    }

    if (!wixCodeApp) {
        throw new Error('no wix code app')
    }

    const options = {
        baseUrl,
        metasiteId,
        gridAppId,
        signedInstance: wixCodeApp.instance
    }

    try {
        await wixCodeServiceFacade.save(options)
        traceEnd()
    } catch (error) {
        if (isAlreadySavedError(error)) {
            traceEnd({level: wixCodeMonitoring.levels.WARN, message: 'The grid app was already saved on server'})
        } else {
            traceEnd({level: wixCodeMonitoring.levels.ERROR, message: error})
            throw error
        }
    }
}

function getIsAppReadOnlyFromPS(ps: PS) {
    const pointer = ps.pointers.wixCode.getIsAppReadOnly()
    return ps.dal.get(pointer)
}

function runUsingPS(ps) {
    const traceFunc = _.partial(wixCodeMonitoring.trace, ps)
    const isAppReadOnly = getIsAppReadOnlyFromPS(ps)
    const wixCodeApp = clientSpecMapUtils.getExistingWixCodeAppFromPS(ps)
    const baseUrl = wixCodeServiceFacade.getBaseUrlFromPS(ps)
    const metasiteId = getMetaSiteId(ps)
    const gridAppId = ps.dal.get(ps.pointers.wixCode.getRevisionGridAppId())

    return markAppImmutable({
        traceFunc,
        isAppReadOnly,
        wixCodeApp,
        baseUrl,
        metasiteId,
        gridAppId
    })
}

function runUsingSnapshot(currentSnapshotDataProvider) {
    return markAppImmutable(currentSnapshotDataProvider)
}

export default {
    runUsingPS,
    runUsingSnapshot
}
