import type {Callback, PS} from '@wix/document-services-types'
import _ from 'lodash'
import page from './page'
import tpaWidgetService from '../tpa/services/tpaWidgetService'
import atomicScopesUtils from '@wix/document-services-implementation/src/atomicScopes/atomicScopesUtils'
import experiment from 'experiment-amd'

function removePageAtomicScope(ps: PS, pageId: string) {
    const {getPageUriAtomicScopeId, removeAtomicScope} = atomicScopesUtils
    const id = getPageUriAtomicScopeId(ps, pageId)
    removeAtomicScope(ps, id)
}

function removePage(ps: PS, tpaComps, _pageId: string, completeCallback: Callback) {
    _.forEach(tpaComps, tpaComp => tpaWidgetService.deleteWidget(ps, tpaComp))
    const useExtensionImplementation = experiment.isOpen('dm_removePageViaExtension')
    if (useExtensionImplementation) {
        const pageId = _.trimStart(_pageId, '#')
        removePageAtomicScope(ps, pageId)
        ps.extensionAPI.page.removePage(pageId)
        ;(completeCallback || _.noop)()
    } else {
        page.remove(ps, _pageId, completeCallback)
    }
}

function validatePageRemoval(ps: PS, _pageId: string) {
    const widgetCompRefs = page.validatePageRemovalInternal(ps, _pageId)
    _.forEach(widgetCompRefs, widgetCompRef => tpaWidgetService.notifyBeforeWidgetDeleteInternal(ps, widgetCompRef))
    ps.setOperationsQueue.asyncPreDataManipulationComplete(widgetCompRefs)
}

export default {
    remove: removePage,
    validatePageRemoval
}
