import _ from 'lodash'
import {createLayoutsDefinition, layoutDefinition} from './defaultLayoutDefinitions'
import type {CompStructure, BaseDsData, StyleRef} from '@wix/document-services-types'
import {schemas} from '@wix/document-services-json-schemas'
import type {CreateExtArgs, Extension, ExtensionAPI} from '@wix/document-manager-core'
import {createBreakpointsDefinition} from './defaultBreakpointsDefinitions'

export interface DefaultDefinitionsAPI extends ExtensionAPI {
    createComponentDefinition(compDefinition: CompStructure, overrides?: any): CompStructure
    createRefArrayDefinition(references: BaseDsData[]): {values: BaseDsData[]; type: string}
    createSystemStyle(
        styleId: string,
        compType: string,
        skin: string,
        styleProperties: Record<string, any>,
        propertiesSource: Record<string, any>
    ): StyleRef
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({extensionAPI}: CreateExtArgs): ExtensionAPI => {
        const supportedTypes = {
            'responsive.components.Section': true,
            'wysiwyg.viewer.components.RefComponent': true,
            'mobile.core.components.Page': true
        }

        const isSupported = (componentType: string) => {
            // TODO: Reinstate the responsive check once the testkit supports it
            // return dal.get(pointers.general.isResponsive()) && supportedTypes[componentType]
            return Boolean(supportedTypes[componentType])
        }

        const createComponentDefinition = (
            compDefinition: CompStructure,
            overrides?: Record<any, any>
        ): CompStructure => {
            const {componentType} = compDefinition

            if (!isSupported(componentType)) {
                throw Error('The creation of this component type is not supported.')
            }

            const {type, styles} = schemas.default.allComponentsDefinitionsMap[componentType]
            const component = {
                layout: layoutDefinition,
                layouts: createLayoutsDefinition(extensionAPI, componentType, overrides?.parentPointer),
                type,
                styleId: _.keys(styles)[0],
                ...compDefinition
            }

            const defaultBreakpoints = createBreakpointsDefinition(componentType)
            if (!_.isNil(defaultBreakpoints)) {
                component.breakpointVariants = defaultBreakpoints
            }
            return component
        }

        const createRefArrayDefinition = (references: string[]) => {
            return {
                type: 'RefArray',
                values: references
            }
        }

        const createSystemStyle = (
            styleId: string,
            compType: string,
            skin: string,
            styleProperties: Record<string, any>,
            propertiesSource: Record<string, any>
        ): StyleRef => {
            return {
                id: styleId,
                compId: '',
                componentClassName: compType,
                pageId: '',
                styleType: 'system',
                type: 'TopLevelStyle',
                skin,
                style: {
                    groups: {},
                    properties: styleProperties,
                    propertiesSource
                }
            }
        }

        return {
            defaultDefinitions: {
                createBreakpointsDefinition,
                createComponentDefinition,
                createRefArrayDefinition,
                createSystemStyle
            }
        }
    }
    return {
        name: 'defaultDefinitions',
        createExtensionAPI
    }
}

export {createExtension}
