import _ from 'lodash'
import {urlUtils, stringUtils} from '@wix/santa-core-utils'
import slashUrlParser from './parsers/slashUrlParser'
import hashBangUrlParser from './parsers/hashBangUrlParser'
import urlParserUtils from './utils/urlParserUtils'

const FORMAT_PARSERS = {
    slash: slashUrlParser,
    hashBang: hashBangUrlParser
}

function useSlashFormat(siteData, url: string | Record<string, any>) {
    const urlObj: any = _.isObject(url) ? url : urlUtils.parseUrl(url)
    if (stringUtils.startsWith(urlObj.hash, '#!')) {
        return false
    }
    return siteData.isUsingSlashUrlFormat
}

function getFormatParser(siteData, requestedFormat: string) {
    return FORMAT_PARSERS[requestedFormat || siteData.urlFormat] || FORMAT_PARSERS[siteData.urlFormat]
}

function isWixMobileApp(externalBaseUrl: string, url: string) {
    return url === `${externalBaseUrl.replace(/\/$/, '')}/app`
}

/**
 *
 * @param {core.SiteData} siteData
 * @param {string|object} url
 * @returns {{
 *      pageTitle: string,
 *      pageId: string,
 *      pageItemId: ?string,
 *      pageAdditionalData: ?string
 * }}
 */
function parseUrl(siteData, url) {
    const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData)

    if (!url || isWixMobileApp(resolvedSiteData.externalBaseUrl, url)) {
        return null
    }

    if (url === '#') {
        return parseUrl(resolvedSiteData, resolvedSiteData.currentUrl.full)
    }

    if (useSlashFormat(resolvedSiteData, url)) {
        return slashUrlParser.parseUrl(resolvedSiteData, url)
    }

    return hashBangUrlParser.parseUrl(resolvedSiteData, url)
}

export interface PageInfo {
    pageId: string
    title: string
    format: string
}

function getUrl(
    siteData,
    pageInfo: PageInfo,
    forceAddPageInfo?: boolean,
    cleanQuery?: boolean,
    baseUrl?: string,
    urlMapping?,
    dismissQueryParamList?,
    ignorePageUriSeo?
) {
    const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData)
    const parser = getFormatParser(resolvedSiteData, pageInfo.format)
    return parser.getUrl(
        resolvedSiteData,
        pageInfo,
        forceAddPageInfo,
        cleanQuery,
        baseUrl,
        urlMapping,
        siteData,
        dismissQueryParamList,
        ignorePageUriSeo
    )
}

function isUrlToCurrentSite(siteData, url): boolean {
    const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData)
    if (useSlashFormat(resolvedSiteData, url)) {
        return slashUrlParser.isUrlToCurrentSite(resolvedSiteData, url)
    }
    return hashBangUrlParser.isUrlToCurrentSite(resolvedSiteData, url)
}

function normalizeProtocolForSameSite(siteData, url) {
    if (isUrlToCurrentSite(siteData, url)) {
        const currentProtocol = siteData.currentUrl.protocol
        return url.replace(/^https?:/, currentProtocol)
    }
    return url
}

export default {
    getUrl,
    parseUrl,
    utils: urlParserUtils,
    normalizeProtocolForSameSite
}
