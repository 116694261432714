import type {SnapshotDal} from '@wix/document-manager-core'
import _ from 'lodash'
import type {SaveTaskDefinition} from '../../saveAPI/lib/registry'
import pendingAppsService from '../services/pendingAppsService'
import appStoreService from '../services/appStoreService'
import metaSiteProvisioner from '../../metaSiteProvisioner/metaSiteProvisioner'
import semanticAppVersionsCleaner from '../../metaSiteProvisioner/semanticAppVersionsCleaner'
import * as appServiceData from '../../saveAPI/appServiceData'
import experiment from 'experiment-amd'

const TASK_NAME = 'unProvisionedAppsAppFlows'

function clearPendingApps() {
    pendingAppsService.onSave()
}

const fetchSettleDataFromSnapshotDal = (lastSnapshotDal: SnapshotDal, currentSnapshotDal: SnapshotDal) => ({
    revision: currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'revision'}),
    appStoreUrl: _.get(currentSnapshotDal.getValue({type: 'serviceTopology', id: 'serviceTopology'}), 'appStoreUrl'),
    metaSiteId: currentSnapshotDal.getValue({type: 'rendererModel', id: 'metaSiteId'}),
    editorSessionId: currentSnapshotDal.getValue({type: 'documentServicesModel', id: 'editorSessionId'}),
    appStoreServiceData: appServiceData.createFromSnapshotDal(lastSnapshotDal, currentSnapshotDal)
})

const settleAfterSiteSave = function (
    {revision, appStoreUrl, metaSiteId, editorSessionId, appStoreServiceData},
    firstSave,
    concurrentResolver,
    reject
) {
    const onComplete = function (msg) {
        concurrentResolver(msg)
        clearPendingApps()
    }

    setTimeout(function () {
        const urlData = {appStoreUrl, metaSiteId, editorSessionId}
        if (firstSave) {
            appStoreService.settleOnFirstSave(appStoreServiceData, revision, urlData, onComplete, reject)
        } else {
            appStoreService.settleOnSave(appStoreServiceData, revision, urlData, true, onComplete, reject)
        }
    }, 200)
}

const settleAfterSiteSaveWithImmutable = function (
    currentImmutable,
    {revision, appStoreUrl, metaSiteId, editorSessionId, appStoreServiceData},
    firstSave,
    concurrentResolver,
    reject
) {
    const onComplete = function (msg) {
        concurrentResolver(msg)
        clearPendingApps()
    }

    setTimeout(function () {
        const urlData = {appStoreUrl, metaSiteId, editorSessionId}
        if (firstSave) {
            appStoreService.settleOnFirstSaveWithImmutable(
                currentImmutable,
                appStoreServiceData,
                revision,
                urlData,
                onComplete,
                reject
            )
        } else {
            appStoreService.settleOnSaveWithImmutable(
                currentImmutable,
                appStoreServiceData,
                revision,
                urlData,
                true,
                onComplete,
                reject
            )
        }
    }, 200)
}

const settleAfterSiteSaveWithImmutableSnapshot = function (
    currentImmutableSnapshot,
    {revision, appStoreUrl, metaSiteId, editorSessionId, appStoreServiceData},
    firstSave,
    concurrentResolver,
    reject
) {
    const onComplete = function (msg) {
        concurrentResolver(msg)
        clearPendingApps()
    }

    setTimeout(function () {
        const urlData = {appStoreUrl, metaSiteId, editorSessionId}
        if (firstSave) {
            appStoreService.settleOnFirstSaveWithImmutableSnapshot(
                currentImmutableSnapshot,
                appStoreServiceData,
                revision,
                urlData,
                onComplete,
                reject
            )
        } else {
            appStoreService.settleOnSaveWithImmutableSnapshot(
                currentImmutableSnapshot,
                appStoreServiceData,
                revision,
                urlData,
                true,
                onComplete,
                reject
            )
        }
    }, 200)
}

const getSettleWithConcurrencyErrorRetry = fetchSettleData =>
    metaSiteProvisioner(
        function (
            lastImmutable,
            currentImmutable,
            resolve,
            reject,
            bi,
            options,
            lastSnapshotDal: SnapshotDal,
            currentSnapshotDal: SnapshotDal
        ) {
            const settleData = fetchSettleData(lastImmutable, currentImmutable, lastSnapshotDal, currentSnapshotDal)
            if (experiment.isOpen('dm_settleThroughProxy')) {
                settleAfterSiteSaveWithImmutable(currentImmutable, settleData, false, resolve, reject)
            } else {
                settleAfterSiteSave(settleData, false, resolve, reject)
            }
        },
        [semanticAppVersionsCleaner]
    )

const getOnSave =
    fetchSettleData =>
    (
        lastImmutable,
        currentImmutable,
        resolve,
        reject: (res: {changes: any[]}) => void,
        bi,
        options: {settleInServer: boolean},
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal
    ) => {
        const {settleInServer} = options || {}
        if (settleInServer) {
            clearPendingApps()
            resolve({
                changes: []
            })
        } else {
            const settleWithConcurrencyErrorRetry = getSettleWithConcurrencyErrorRetry(fetchSettleData)
            settleWithConcurrencyErrorRetry(
                lastImmutable,
                currentImmutable,
                resolve,
                reject,
                bi,
                {},
                lastSnapshotDal,
                currentSnapshotDal
            )
        }
    }

const createTask = (): SaveTaskDefinition => {
    const fetchSettleData = (
        lastImmutable,
        currentImmutable,
        lastSnapshotDal: SnapshotDal,
        currentSnapshotDal: SnapshotDal
    ) => fetchSettleDataFromSnapshotDal(lastSnapshotDal, currentSnapshotDal)

    const onSave = getOnSave(fetchSettleData)

    return {
        saveAsTemplate(lastImmutableSnapshot, currentImmutableSnapshot, resolve) {
            resolve()
        },
        publish(currentImmutableSnapshot, resolve) {
            resolve()
        },
        getTaskName() {
            return TASK_NAME
        },
        getSnapshotTags() {
            return ['primary']
        },
        firstSave: metaSiteProvisioner(
            function (
                lastImmutableSnapshot,
                currentImmutableSnapshot,
                resolve,
                reject,
                bi,
                options,
                lastSnapshotDal: SnapshotDal,
                currentSnapshotDal: SnapshotDal
            ) {
                const settleData = fetchSettleData(
                    lastImmutableSnapshot,
                    currentImmutableSnapshot,
                    lastSnapshotDal,
                    currentSnapshotDal
                )
                if (experiment.isOpen('dm_settleThroughProxy')) {
                    settleAfterSiteSaveWithImmutableSnapshot(currentSnapshotDal, settleData, true, resolve, reject)
                } else {
                    settleAfterSiteSave(settleData, true, resolve, reject)
                }
            },
            [semanticAppVersionsCleaner]
        ),
        partialSave: onSave,
        fullSave: onSave,
        autosave: onSave,
        promises: {
            partialSave(
                lastImmutable,
                currentImmutable,
                bi,
                options,
                lastSnapshotDal: SnapshotDal,
                currentSnapshotDal: SnapshotDal
            ) {
                return new Promise((resolve, reject) => {
                    onSave(
                        lastImmutable,
                        currentImmutable,
                        resolve,
                        reject,
                        bi,
                        options,
                        lastSnapshotDal,
                        currentSnapshotDal
                    )
                })
            },
            fullSave(
                lastImmutable,
                currentImmutable,
                bi,
                options,
                lastSnapshotDal: SnapshotDal,
                currentSnapshotDal: SnapshotDal
            ) {
                return new Promise((resolve, reject) => {
                    onSave(
                        lastImmutable,
                        currentImmutable,
                        resolve,
                        reject,
                        bi,
                        options,
                        lastSnapshotDal,
                        currentSnapshotDal
                    )
                })
            },
            autosave(
                lastImmutable,
                currentImmutable,
                bi,
                options,
                lastSnapshotDal: SnapshotDal,
                currentSnapshotDal: SnapshotDal
            ) {
                return new Promise((resolve, reject) => {
                    onSave(
                        lastImmutable,
                        currentImmutable,
                        resolve,
                        reject,
                        bi,
                        options,
                        lastSnapshotDal,
                        currentSnapshotDal
                    )
                })
            }
        }
    }
}

export default () => createTask()
