import type {Pointer, PS} from '@wix/document-services-types'
import _ from 'lodash'
import componentDetectorAPI from '../../componentDetectorAPI/componentDetectorAPI'
import component from '../../component/component'
import dataModel from '../../dataModel/dataModel'
import dsUtils from '../../utils/utils'

const LOGIN_BAR_TYPE = 'wysiwyg.viewer.components.LoginSocialBar'

const isValidMenuRef = (ps: PS, menuData) =>
    !!dataModel.getDataItemById(ps, dsUtils.stripHashIfExists(menuData.menuRef))

const removeBrokenMembersLoginBar = (ps: PS, compPointer: Pointer) => {
    const compData = dataModel.getDataItem(ps, compPointer)
    const compWithInvalidMenuRef = [compData.menuItemsRef, compData.iconItemsRef].some(
        menu => menu && !isValidMenuRef(ps, menu)
    )
    if (compWithInvalidMenuRef) {
        component.remove(ps, compPointer)
    }
}

export default {
    exec(ps: PS) {
        const loginBarComps = componentDetectorAPI.getComponentByType(ps, LOGIN_BAR_TYPE)
        _.forEach(loginBarComps, comp => removeBrokenMembersLoginBar(ps, comp))
    },
    name: 'brokenLoginBarFixer',
    version: 1
}
