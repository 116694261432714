import type {Pointer, PS, Size} from '@wix/document-services-types'
import {layoutUtils as wuLayoutUtils} from '@wix/santa-ds-libs/src/warmupUtils'
import experiment from 'experiment-amd'
import _ from 'lodash'
import appStudioDataModel from '../../appStudio/appStudioDataModel'
import componentStructureInfo from '../../component/componentStructureInfo'
import componentsMetaData from '../../componentsMetaData/componentsMetaData'
import connectionsDataGetter from '../../connections/connectionsDataGetter'
import layoutCalcPlugins from '../layoutCalcPlugins/layoutCalcPlugins'
import layoutUtils from '../layoutUtils'
import structureUtils from '../structureUtils'

const GROUP_COMPONENT_TYPE = 'wysiwyg.viewer.components.Group'

const AXIS = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal'
}

const DIRECTIONS = {
    TOP: 'top',
    BOTTOM: 'bottom',
    LEFT: 'left',
    RIGHT: 'right'
}

function getCompLayoutFromData(ps: PS, compPointer: Pointer) {
    const layoutPointer = ps.pointers.getInnerPointer(compPointer, 'layout')
    return ps.dal.get(layoutPointer)
}

function getCompActualLayout(ps: PS, compPointer: Pointer) {
    const compLayout = getCompLayoutFromData(ps, compPointer)
    const positionAndSize = structureUtils.getPositionAndSize(ps, compPointer, compLayout)
    return structureUtils.getBoundingLayout(ps, _.merge(positionAndSize, _.pick(compLayout, ['rotationInDegrees'])))
}

function getLayoutsMap(ps: PS, axis, edge, compPointers) {
    const layoutsMap = {
        dockedToEdge: [],
        nonDockedToEdge: []
    }

    const isStretched =
        axis === AXIS.VERTICAL ? wuLayoutUtils.isVerticallyStretched : wuLayoutUtils.isHorizontallyStretched

    _.forEach(compPointers, compPointer => {
        const compLayout = getCompLayoutFromData(ps, compPointer)
        const compActualLayout = getCompActualLayout(ps, compPointer)

        if (!isStretched(compLayout)) {
            if (wuLayoutUtils.isDockedToDirection(compLayout, edge)) {
                layoutsMap.dockedToEdge.push(compActualLayout)
            } else {
                layoutsMap.nonDockedToEdge.push(compActualLayout)
            }
        }
    })

    return layoutsMap
}

function handleNarrowingFromLeft(ps: PS, currentLayout, newLayout, childrenPointers) {
    const layoutsMap = getLayoutsMap(ps, AXIS.HORIZONTAL, DIRECTIONS.LEFT, childrenPointers)

    const minPossibleWidthForNonDockedComponents = _.map(
        layoutsMap.nonDockedToEdge,
        layout => currentLayout.width - layout.x
    )

    const minPossibleWidthForDockedComponents = _.map(layoutsMap.dockedToEdge, layout => layout.x + layout.width)

    const minPossibleWidth = _.max(minPossibleWidthForNonDockedComponents.concat(minPossibleWidthForDockedComponents))

    if (minPossibleWidth > currentLayout.width) {
        if (currentLayout.x) {
            newLayout.x = currentLayout.x
        }
        newLayout.width = currentLayout.width
    } else if (newLayout.width < minPossibleWidth) {
        const widthDiff = minPossibleWidth - newLayout.width
        if (newLayout.x) {
            newLayout.x -= widthDiff
        }
        newLayout.width += widthDiff
    }
}

function handleNarrowingFromRight(ps: PS, currentLayout, newLayout, childrenPointers) {
    const layoutsMap = getLayoutsMap(ps, AXIS.HORIZONTAL, DIRECTIONS.RIGHT, childrenPointers)

    const minPossibleWidthForNonDockedComponents = _.map(layoutsMap.nonDockedToEdge, layout => layout.x + layout.width)

    const minPossibleWidthForDockedComponents = _.map(layoutsMap.dockedToEdge, layout => currentLayout.width - layout.x)

    const minPossibleWidth = _.max(minPossibleWidthForNonDockedComponents.concat(minPossibleWidthForDockedComponents))

    if (minPossibleWidth > currentLayout.width) {
        newLayout.width = currentLayout.width
    } else if (newLayout.width < minPossibleWidth) {
        const widthDiff = minPossibleWidth - newLayout.width
        newLayout.width += widthDiff
    }
}

function handleShorteningFromTop(ps: PS, currentLayout, newLayout, childrenPointers) {
    const layoutsMap = getLayoutsMap(ps, AXIS.VERTICAL, DIRECTIONS.TOP, childrenPointers)

    const minPossibleHeightForNonDockedComponents = _.map(
        layoutsMap.nonDockedToEdge,
        layout => currentLayout.height - layout.y
    )

    const minPossibleHeightForDockedComponents = _.map(layoutsMap.dockedToEdge, layout => layout.y + layout.height)

    const minPossibleHeight = _.max(
        minPossibleHeightForNonDockedComponents.concat(minPossibleHeightForDockedComponents)
    )

    if (minPossibleHeight > currentLayout.height) {
        if (currentLayout.y) {
            newLayout.y = currentLayout.y
        }
        newLayout.height = currentLayout.height
    } else if (newLayout.height < minPossibleHeight) {
        const heightDiff = minPossibleHeight - newLayout.height
        if (newLayout.y) {
            newLayout.y -= heightDiff
        }
        newLayout.height += heightDiff
    }
}

function removeChildrenOverflowingContainerFromBottom(childrenLayouts, containerHeight) {
    return childrenLayouts.filter(({y}) => y < containerHeight)
}

function handleShorteningFromBottom(ps: PS, currentLayout, newLayout, childrenPointers) {
    const layoutsMap = getLayoutsMap(ps, AXIS.VERTICAL, DIRECTIONS.BOTTOM, childrenPointers)

    const nonDockedToEdge = experiment.isOpen('dm_allowShorteningPastChildrenOutsideContainer')
        ? removeChildrenOverflowingContainerFromBottom(layoutsMap.nonDockedToEdge, currentLayout.height)
        : layoutsMap.nonDockedToEdge

    const minPossibleHeightForNonDockedComponents = _.map(nonDockedToEdge, layout => layout.y + layout.height)

    const minPossibleHeightForDockedComponents = _.map(
        layoutsMap.dockedToEdge,
        layout => currentLayout.height - layout.y
    )

    const minPossibleHeight = _.max(
        minPossibleHeightForNonDockedComponents.concat(minPossibleHeightForDockedComponents)
    )

    if (minPossibleHeight > currentLayout.height) {
        newLayout.height = currentLayout.height
    } else if (newLayout.height < minPossibleHeight) {
        const heightDiff = minPossibleHeight - newLayout.height
        newLayout.height += heightDiff
    }
}

function constrainByChildrenLayout(
    ps: PS,
    compPointer: Pointer,
    newLayout,
    dontConstrainByWidth?,
    dontConstrainByHeight?
) {
    const childrenPointers = ps.pointers.components.getChildren(compPointer)

    if (_.isEmpty(childrenPointers)) {
        return
    }

    const currLayout = getCompActualLayout(ps, compPointer)
    const nextLayout = _.assign({}, currLayout, newLayout)

    const isEnlargingOrMoving = nextLayout.width >= currLayout.width && nextLayout.height >= currLayout.height
    if (isEnlargingOrMoving) {
        return
    }

    const isNarrowing = nextLayout.width < currLayout.width
    const isNarrowingFromLeft =
        isNarrowing && ((nextLayout.docked && newLayout.docked.right) || nextLayout.x > currLayout.x)
    const isNarrowingBothSides =
        isNarrowingFromLeft && currLayout.width - nextLayout.width > nextLayout.x - currLayout.x

    if (isNarrowing && !dontConstrainByWidth) {
        if (isNarrowingBothSides) {
            const symmetric = _.has(newLayout, ['docked', 'hCenter'])
            handleNarrowingFromBothSides(ps, currLayout, newLayout, childrenPointers, symmetric)
        } else if (isNarrowingFromLeft) {
            handleNarrowingFromLeft(ps, currLayout, newLayout, childrenPointers)
        } else {
            handleNarrowingFromRight(ps, currLayout, newLayout, childrenPointers)
        }
    }

    const shouldEnforceKeepChildrenInPlace = ps.dal.get(
        ps.pointers.general.getRenderFlag('enforceShouldKeepChildrenInPlace')
    )
    const isShortening = nextLayout.height < currLayout.height
    const isShorteningFromTop =
        isShortening && ((nextLayout.docked && newLayout.docked.bottom) || nextLayout.y > currLayout.y)
    const isShorteningBothSides =
        isShorteningFromTop && currLayout.height - nextLayout.height > nextLayout.y - currLayout.y

    if (isShortening && !dontConstrainByHeight) {
        if (isShorteningBothSides && shouldEnforceKeepChildrenInPlace) {
            handleShorteningFromTop(ps, currLayout, newLayout, childrenPointers)
            handleShorteningFromBottom(ps, currLayout, newLayout, childrenPointers)
        } else if (isShorteningFromTop && shouldEnforceKeepChildrenInPlace) {
            handleShorteningFromTop(ps, currLayout, newLayout, childrenPointers)
        } else if (shouldEnforceKeepChildrenInPlace) {
            handleShorteningFromBottom(ps, currLayout, newLayout, childrenPointers)
        }
    }
}

// eslint-disable-next-line no-mixed-operators
const constrainWidthSymmetrically = (width: number, x1: number, x0: number) => width - 2 * (x1 - x0)

function handleNarrowingFromBothSides(ps: PS, currentLayout, newLayout, childrenPointers, symmetric) {
    // calculate leftmost component left and rightmost component right
    const layoutsMap = getLayoutsMap(ps, AXIS.HORIZONTAL, null, childrenPointers)
    const xLeft = _.min(_.map(layoutsMap.nonDockedToEdge, layout => layout.x + currentLayout.x))
    const xRight = _.max(_.map(layoutsMap.nonDockedToEdge, layout => currentLayout.x + layout.x + layout.width))

    // if some component crosses container boundaries - leave horizontal layout as is
    if (xLeft < currentLayout.x || xRight > currentLayout.x + currentLayout.width) {
        _.assign(newLayout, _.pick(currentLayout, ['x', 'width']))
        return
    }

    const newLayoutRight = _.max([xRight, newLayout.x + newLayout.width])
    const newLayoutLeft = _.min([xLeft, newLayout.x])

    // should constrain the width symmetrically from left and right
    if (symmetric) {
        const currentRight = currentLayout.x + currentLayout.width
        // if leftmost comp left is closer to container left than rightmost comp right is closer to container right
        if (xLeft - currentLayout.x <= currentRight - xRight) {
            // constrain by left
            newLayout.x = newLayoutLeft
            newLayout.width = constrainWidthSymmetrically(currentLayout.width, newLayout.x, currentLayout.x)
        } else {
            // constrain by right
            newLayout.width = constrainWidthSymmetrically(currentLayout.width, currentRight, newLayoutRight)
            newLayout.x = newLayoutRight - newLayout.width
        }
    } else {
        // constrain left and right independent of each other
        newLayout.x = newLayoutLeft
        newLayout.width = newLayoutRight - newLayout.x
    }
}

function getLayoutAspectRatio(/**layoutObject*/ compLayout) {
    return compLayout.width / compLayout.height
}

function isGroup(ps: PS, compPointer: Pointer) {
    const componentType = componentsMetaData.getComponentType(ps, compPointer)
    return componentType === GROUP_COMPONENT_TYPE
}

function getComponentMinLayout(ps: PS, compPointer: Pointer): Size {
    const limits = componentsMetaData.public.getLayoutLimits(ps, compPointer)
    const componentLayout = structureUtils.getComponentLayout(ps, compPointer)
    const horizontallyResizable = componentsMetaData.public.isHorizontallyResizable(ps, compPointer)
    const verticallyResizable = componentsMetaData.public.isVerticallyResizable(ps, compPointer)
    const proportionallyResizable = componentsMetaData.public.isProportionallyResizable(ps, compPointer)
    const minLayout: Size = _.pick(componentLayout, ['width', 'height'])

    // TODO: naora 9/10/15 12:18 PM Remove group exception when removing proportional resize experiment

    if (proportionallyResizable || horizontallyResizable || isGroup(ps, compPointer)) {
        minLayout.width = limits.minWidth
    }

    if (proportionallyResizable || verticallyResizable || isGroup(ps, compPointer)) {
        minLayout.height = limits.minHeight
    }

    return minLayout
}

function calcMinLayoutAndPreserveAspectRatio(compLayout, minLayoutConstrain) {
    const aspectRatio = getLayoutAspectRatio(compLayout)
    const heightForMinWidth = minLayoutConstrain.width / aspectRatio
    const widthForMinHeight = minLayoutConstrain.height * aspectRatio

    if (heightForMinWidth > minLayoutConstrain.height) {
        minLayoutConstrain.height = heightForMinWidth
    } else {
        minLayoutConstrain.width = widthForMinHeight
    }

    return minLayoutConstrain
}

function addLayoutPositionConstraintAccordingToDirection(ps: PS, minLayoutConstraint, compPointer: Pointer, direction) {
    const compLayout = structureUtils.getComponentLayout(ps, compPointer)

    minLayoutConstraint = calcMinLayoutAndPreserveAspectRatio(compLayout, minLayoutConstraint)

    const heightDiff = compLayout.height - minLayoutConstraint.height
    const widthDiff = compLayout.width - minLayoutConstraint.width

    if (direction.y === -1) {
        minLayoutConstraint.y = compLayout.y + heightDiff
    }

    if (direction.x === -1) {
        minLayoutConstraint.x = compLayout.x + widthDiff
    }

    if (direction.x !== 0 && direction.y === 0) {
        minLayoutConstraint.y = compLayout.y + heightDiff / 2 // eslint-disable-line no-mixed-operators
    }

    if (direction.y !== 0 && direction.x === 0) {
        minLayoutConstraint.x = compLayout.x + widthDiff / 2 // eslint-disable-line no-mixed-operators
    }
    return minLayoutConstraint
}

function isLayoutExceedsContainerBoundaries(/**layoutObject*/ layout, /**layoutObject*/ containerLayout) {
    return (
        layout.x < 0 ||
        layout.x + layout.width > containerLayout.width ||
        layout.y < 0 ||
        layout.y + layout.height > containerLayout.height
    )
}

/**
 * Set layout width or height according to a component declared aspect ratio.
 * Originally this function used only the height to calculate width,
 * this version can calculate the height by the width if no height supplied,
 * keeping the original default of using the height if it is supplied, even if a width exists
 * @param {{height:number,width:number}} layout
 * @param aspectRatio
 */
function maintainCompAspectRatio(layout, aspectRatio) {
    if (layout.height) {
        layout.width = layout.height * aspectRatio
    } else if (layout.width) {
        layout.height = layout.width / aspectRatio
    } else {
        throw new Error('maintainCompAspectRatio must get at least height or width values in the layout parameter')
    }
}

function updateMinLayoutDimensionConstraintByChildren(ps: PS, proportionStructure, minLayoutConstraint) {
    const containerLayout = structureUtils.getComponentLayout(ps, proportionStructure.component)

    _.forEach(
        proportionStructure.children,
        function getChildMinLayout(/**proportionStructure*/ childProportionStructure) {
            const childMinLayout = structureUtils.getBoundingLayout(ps, childProportionStructure.minLayout)
            let childLayout = structureUtils.getComponentLayout(ps, childProportionStructure.component)
            childLayout = structureUtils.getBoundingLayout(ps, childLayout)

            if (isLayoutExceedsContainerBoundaries(childLayout, containerLayout)) {
                minLayoutConstraint.width = containerLayout.width
                minLayoutConstraint.height = containerLayout.height
                return false
            }
            // TODO: naora 9/8/15 2:11 PM Find a cleaner way to implement this logic
            const minWidthCandidate = childMinLayout.width / (1 - childProportionStructure.proportions.x)
            const minHeightCandidate = childMinLayout.height / (1 - childProportionStructure.proportions.y)

            minLayoutConstraint.width = _.max([minWidthCandidate, minLayoutConstraint.width])
            minLayoutConstraint.height = _.max([minHeightCandidate, minLayoutConstraint.height])
        }
    )
}

// TODO: naora 9/8/15 1:27 PM Find a way to remove editor logic from DS (such as considering resize direction)
function addCompMinLayout(ps: PS, proportionStructure, resizeDirection?) {
    const ignoreChildren = componentsMetaData.public.isIgnoreChildrenOnProportionalResize(
        ps,
        proportionStructure.component
    )
    let structureChildren = proportionStructure.children

    if (_.isArray(ignoreChildren)) {
        structureChildren = structureUtils.getChildrenToPreserveProportionsByType(
            ps,
            componentsMetaData,
            structureChildren,
            ignoreChildren
        )
    }
    if (ignoreChildren !== true && !_.isEmpty(structureChildren)) {
        _.forEach(structureChildren, childCompStructure => {
            addCompMinLayout(ps, childCompStructure)
        })
    }

    const compPointer = proportionStructure.component
    let minLayoutConstraint: Size = getComponentMinLayout(ps, compPointer)

    if (!ignoreChildren) {
        updateMinLayoutDimensionConstraintByChildren(ps, proportionStructure, minLayoutConstraint)
    }

    const isRootComp = !!resizeDirection
    if (isRootComp) {
        minLayoutConstraint = addLayoutPositionConstraintAccordingToDirection(
            ps,
            minLayoutConstraint,
            compPointer,
            resizeDirection
        )
    }

    // @ts-expect-error
    minLayoutConstraint = _.pick(minLayoutConstraint, ['x', 'y', 'width', 'height', 'rotationInDegrees'])
    //proportionStructure.minLayout = _.mapValues(minLayoutConstraint, Math.round);
    proportionStructure.minLayout = minLayoutConstraint
}

function isLayoutExceedMinLayout(proportionStructure, newLayout) {
    return (
        proportionStructure.minLayout.width > newLayout.width || proportionStructure.minLayout.height > newLayout.height
    )
}

function constrainProportionalResize(
    ps: PS,
    /**proportionStructure*/ proportionStructure,
    /**layoutObject*/ newLayout,
    isRoot,
    enforceMax
) {
    if (isRoot) {
        if (enforceMax) {
            const layoutLimits = componentsMetaData.public.getLayoutLimits(ps, proportionStructure.component)
            const currentLayout = structureUtils.getComponentLayout(ps, proportionStructure.component)
            if (newLayout.width > layoutLimits.maxWidth) {
                newLayout.width = layoutLimits.maxWidth
                newLayout.height = newLayout.width / (currentLayout.width / currentLayout.height)
            } else if (newLayout.height > layoutLimits.maxHeight) {
                newLayout.height = layoutLimits.maxHeight
                newLayout.width = newLayout.height * (currentLayout.width / currentLayout.height)
            }
        }

        if (isLayoutExceedMinLayout(proportionStructure, newLayout)) {
            _.assign(newLayout, proportionStructure.minLayout)
        }

        if (componentsMetaData.public.resizeOnlyProportionally(ps, proportionStructure.component)) {
            const {aspectRatio} = componentsMetaData.public.getLayoutLimits(ps, proportionStructure.component)
            maintainCompAspectRatio(newLayout, aspectRatio)
        }
    } else {
        newLayout.width = _.max([proportionStructure.minLayout.width, newLayout.width])
        newLayout.height = _.max([proportionStructure.minLayout.height, newLayout.height])
    }
}

//</editor-fold>

function constrainByDimensionsLimits(ps: PS, compPointer: Pointer, newLayout) {
    const oldLayout = getCompLayoutFromData(ps, compPointer)
    const layoutLimits = componentsMetaData.public.getLayoutLimits(ps, compPointer, newLayout)

    if (!_.isUndefined(newLayout.width) && newLayout.width !== oldLayout.width) {
        const widthWithinLimits = structureUtils.ensureWithinLimits(
            newLayout.width,
            layoutLimits.minWidth,
            layoutLimits.maxWidth
        )
        if (!_.isUndefined(newLayout.x) && newLayout.x !== oldLayout.x) {
            newLayout.x -= widthWithinLimits - newLayout.width
        }

        newLayout.width = widthWithinLimits
    }

    if (!_.isUndefined(newLayout.height) && newLayout.height !== oldLayout.height) {
        const heightWithinLimits = structureUtils.ensureWithinLimits(
            newLayout.height,
            layoutLimits.minHeight,
            layoutLimits.maxHeight
        )
        if (!_.isUndefined(newLayout.y) && newLayout.y !== oldLayout.y) {
            newLayout.y -= heightWithinLimits - newLayout.height
        }

        newLayout.height = heightWithinLimits
    }
}

function constrainBySpecificType(ps: PS, compPointer: Pointer, newLayout) {
    const compType = componentStructureInfo.getType(ps, compPointer)
    const fixLayout = layoutCalcPlugins[compType]
    if (!fixLayout) {
        return newLayout
    }
    const pluginLayout = fixLayout(ps, compPointer, newLayout)
    _.assign(newLayout, pluginLayout)
}

const getAllowedPositionForComponent = (borderRelativeToScreen, compParentRelativeToScreen) => ({
    minLeft: borderRelativeToScreen.x - compParentRelativeToScreen.x,
    minTop: borderRelativeToScreen.y - compParentRelativeToScreen.y,
    maxRight: borderRelativeToScreen.x + borderRelativeToScreen.width - compParentRelativeToScreen.x,
    maxBottom: borderRelativeToScreen.y + borderRelativeToScreen.height - compParentRelativeToScreen.y
})

function getConnectedAppWidget(ps: PS, compRef: Pointer): Pointer | null {
    const primaryConnection = connectionsDataGetter.getPrimaryConnection(ps, compRef)
    if (!primaryConnection) {
        return null
    }
    const controllerType = componentStructureInfo.getType(ps, (primaryConnection as any).controllerRef)
    return controllerType === 'platform.components.AppWidget' ? (primaryConnection as any).controllerRef : null
}

const getFirstAppWidgetAncestor = (ps: PS, compRef: Pointer): Pointer => {
    let currentAncestor = ps.pointers.full.components.getParent(compRef)
    while (currentAncestor && !isAppWidget(ps, currentAncestor)) {
        currentAncestor = ps.pointers.full.components.getParent(currentAncestor)
    }
    return isAppWidget(ps, currentAncestor) ? currentAncestor : null
}

function getAppWidgetToMakeConstrainsBy(ps: PS, compRef: Pointer) {
    const isContainer = componentsMetaData.public.isContainer(ps, compRef)
    const compAppWidget = getConnectedAppWidget(ps, compRef)
    const connectedContainer = compAppWidget && isContainer
    if (!isContainer || connectedContainer) {
        return compAppWidget
    }

    const firstAppWidgetAncestor = getFirstAppWidgetAncestor(ps, compRef)
    if (!firstAppWidgetAncestor) {
        return null
    }

    const descendantsRefs = ps.pointers.full.components.getChildrenRecursively(compRef)
    return _(descendantsRefs)
        .map(ref => getConnectedAppWidget(ps, ref))
        .find(appWidget => !_.isNil(appWidget))
}

function fixByAspectRatio(sizeName: string, layout, aspectRatio) {
    if (sizeName === 'width') {
        layout.height = layout.width / aspectRatio
    } else if (sizeName === 'height') {
        layout.width = layout.height * aspectRatio
    }
}

function fixLayoutOfComponentByCloseBorder(newLayout, prevLayout, axis, minValue, aspectRatio) {
    const startCoord = axis === AXIS.HORIZONTAL ? 'x' : 'y'
    const size = axis === AXIS.HORIZONTAL ? 'width' : 'height'
    const originalNewSize = newLayout[size]

    minValue = Math.min(prevLayout[startCoord], minValue)
    const sizeChanged = newLayout[size] !== prevLayout[size]

    const tryingToGoOutOfAllowed = newLayout[startCoord] < minValue

    if (tryingToGoOutOfAllowed) {
        if (sizeChanged) {
            const isResize = prevLayout[size] - newLayout[size] === newLayout[startCoord] - prevLayout[startCoord]
            const overflow = minValue - newLayout[startCoord]
            newLayout[size] = isResize ? newLayout[size] - overflow : newLayout[size]
        }

        newLayout[startCoord] = minValue
    }

    if (newLayout[size] !== originalNewSize && aspectRatio) {
        fixByAspectRatio(size, newLayout, aspectRatio)
    }
}

function fixLayoutOfComponentByFarBorder(newLayout, prevLayout, axis, maxValue: number, maxSize: number, aspectRatio) {
    const startCoord = axis === AXIS.HORIZONTAL ? 'x' : 'y'
    const size = axis === AXIS.HORIZONTAL ? 'width' : 'height'
    const originalNewSize = newLayout[size]

    const originalMaxValue = maxValue
    maxValue = Math.max(prevLayout[startCoord] + prevLayout[size], maxValue)
    maxSize = maxSize + maxValue - originalMaxValue
    const positionChanged = newLayout[startCoord] !== prevLayout[startCoord]
    const sizeChanged = newLayout[size] !== prevLayout[size]

    const outOfAllowed = newLayout[startCoord] + newLayout[size] > maxValue

    if (outOfAllowed) {
        if (positionChanged && !sizeChanged) {
            newLayout[startCoord] = maxValue - newLayout[size]
        } else if (!positionChanged && sizeChanged) {
            newLayout[size] = maxValue - newLayout[startCoord]
        } else if (positionChanged && sizeChanged) {
            newLayout[size] = newLayout[size] > maxSize ? maxSize : newLayout[size]
            newLayout[startCoord] = maxValue - newLayout[size]
        }
    }

    if (newLayout[size] !== originalNewSize && aspectRatio) {
        fixByAspectRatio(size, newLayout, aspectRatio)
    }
}

const fixLayoutByLeftBorder = (newCompLayout, prevLayout, allowedPosition, aspectRatio) =>
    fixLayoutOfComponentByCloseBorder(newCompLayout, prevLayout, AXIS.HORIZONTAL, allowedPosition.minLeft, aspectRatio)

const fixLayoutByTopBorder = (newCompLayout, prevLayout, allowedPosition, aspectRatio) =>
    fixLayoutOfComponentByCloseBorder(newCompLayout, prevLayout, AXIS.VERTICAL, allowedPosition.minTop, aspectRatio)

const fixLayoutByRightBorder = (newCompLayout, prevLayout, allowedPosition, aspectRatio) =>
    fixLayoutOfComponentByFarBorder(
        newCompLayout,
        prevLayout,
        AXIS.HORIZONTAL,
        allowedPosition.maxRight,
        allowedPosition.maxRight - allowedPosition.minLeft,
        aspectRatio
    )

const fixLayoutByBottomBorder = (newCompLayout, prevLayout, allowedPosition, aspectRatio) =>
    fixLayoutOfComponentByFarBorder(
        newCompLayout,
        prevLayout,
        AXIS.VERTICAL,
        allowedPosition.maxBottom,
        allowedPosition.maxBottom - allowedPosition.minTop,
        aspectRatio
    )

function fixLayoutOfComp(newCompLayout, prevLayout, allowedPosition, aspectRatio) {
    fixLayoutByLeftBorder(newCompLayout, prevLayout, allowedPosition, aspectRatio)
    fixLayoutByTopBorder(newCompLayout, prevLayout, allowedPosition, aspectRatio)
    fixLayoutByRightBorder(newCompLayout, prevLayout, allowedPosition, aspectRatio)
    fixLayoutByBottomBorder(newCompLayout, prevLayout, allowedPosition, aspectRatio)
}

const isAppWidget = (ps: PS, compRef: Pointer) =>
    componentStructureInfo.getType(ps, compRef) === 'platform.components.AppWidget'

const isChildOfMultiStateAsAppWidgetRoot = (ps: PS, parentRef) => {
    let parent = parentRef
    let parentType = componentStructureInfo.getType(ps, parent)
    if (parentType === 'wysiwyg.viewer.components.Repeater') {
        parent = ps.pointers.components.getParent(parent)
        parentType = componentStructureInfo.getType(ps, parent)
    }
    if (parentType === 'wysiwyg.viewer.components.StateBox') {
        const grandparent = ps.pointers.components.getParent(parent)
        return isAppWidget(ps, grandparent)
    }
    return false
}

// TODO: Technical debt - get this out and design a better mechanism
const getWidgetRoot = (ps: PS, compRef) => {
    const pageRef = componentStructureInfo.getPage(ps, compRef)
    const isWidgetPage = appStudioDataModel.isWidgetPage(ps, pageRef.id)
    const widgetRoot = ps.pointers.components.getChildren(pageRef)[0]
    return isWidgetPage && !_.isEqual(widgetRoot, compRef) ? widgetRoot : null
}

function constrainByAppWidgetBoundaries(ps: PS, componentPointer: Pointer, newCompLayout, prevLayout, isProportional) {
    const parentRef = ps.pointers.components.getParent(componentPointer)

    if (isAppWidget(ps, parentRef) || !parentRef || isChildOfMultiStateAsAppWidgetRoot(ps, parentRef)) {
        return
    }

    const boundingContainerRef =
        getAppWidgetToMakeConstrainsBy(ps, componentPointer) ?? getWidgetRoot(ps, componentPointer)
    if (!boundingContainerRef) {
        return
    }

    const appWidgetLayoutRelativeToScreenOrStructure = layoutUtils.getCompLayoutRelativeToStructure(
        ps,
        boundingContainerRef
    )
    const compParentLayoutRelativeToScreenOrStructure = layoutUtils.getCompLayoutRelativeToStructure(ps, parentRef)
    const compConstrainsRelativeToParent = getAllowedPositionForComponent(
        appWidgetLayoutRelativeToScreenOrStructure,
        compParentLayoutRelativeToScreenOrStructure
    )

    const aspectRatio = isProportional
        ? componentsMetaData.public.getLayoutLimits(ps, componentPointer).aspectRatio
        : null
    fixLayoutOfComp(newCompLayout, prevLayout, compConstrainsRelativeToParent, aspectRatio)
}

const isRepeater = (ps: PS, compRef: Pointer) =>
    componentStructureInfo.getType(ps, compRef) === 'wysiwyg.viewer.components.Repeater'

const isRepeaterItem = (ps: PS, compRef: Pointer) => isRepeater(ps, ps.pointers.components.getParent(compRef))

function constrainByRepeaterItemBoundariesInAppWidget(ps: PS, componentPointer: Pointer, newCompLayout, prevLayout) {
    const appWidget = getConnectedAppWidget(ps, componentPointer)
    if (appWidget) {
        const repeaterItem = _.find(
            componentStructureInfo.getAncestors(ps, componentPointer),
            _.partial(isRepeaterItem, ps)
        )
        if (repeaterItem) {
            const repeater = ps.pointers.components.getParent(repeaterItem)
            if (
                !componentsMetaData.public.canReparent(ps, componentPointer) ||
                isChildOfMultiStateAsAppWidgetRoot(ps, repeater)
            ) {
                const parentRef = ps.pointers.components.getParent(componentPointer)
                const compParentLayoutRelativeToScreen = layoutUtils.getCompLayoutRelativeToScreen(ps, parentRef)
                if (_.isEqual(parentRef, repeaterItem)) {
                    const compConstrainsRelativeToParent = {
                        minLeft: 0,
                        minTop: 0,
                        maxRight: compParentLayoutRelativeToScreen.width,
                        maxBottom: compParentLayoutRelativeToScreen.height
                    }
                    fixLayoutOfComp(newCompLayout, prevLayout, compConstrainsRelativeToParent, null)
                } else {
                    const repeaterItemLayoutRelativeToScreen = layoutUtils.getCompLayoutRelativeToScreen(
                        ps,
                        repeaterItem
                    )
                    const compConstrainsRelativeToParent = getAllowedPositionForComponent(
                        repeaterItemLayoutRelativeToScreen,
                        compParentLayoutRelativeToScreen
                    )
                    fixLayoutOfComp(newCompLayout, prevLayout, compConstrainsRelativeToParent, null)
                }
            }
        }
    }
}

function constrainsByContainer(ps: PS, compPointer: Pointer, newCompLayout, prevLayout, isProportional?) {
    constrainByAppWidgetBoundaries(ps, compPointer, newCompLayout, prevLayout, isProportional)
    constrainByRepeaterItemBoundariesInAppWidget(ps, compPointer, newCompLayout, prevLayout)
}

export default {
    addCompMinLayout,
    constrainByChildrenLayout,
    constrainByDimensionsLimits,
    constrainProportionalResize,
    constrainBySpecificType,
    constrainsByContainer
}
