import _ from 'lodash'
import omit from 'lodash/fp/omit'
import {
    collectIdsToNicknamesMap,
    Comp,
    CompOp,
    errorTypes,
    getCompsInPage,
    isPage,
    mapPageExport,
    PageExport,
    replaceReferencesInComp
} from './utils'
import {COMP_DATA_QUERY_KEYS_WITH_STYLE} from '../../constants/constants'
import type {Pointers} from '@wix/document-services-types'
import type {ExtensionAPI} from '@wix/document-manager-core'
import {ReportableError} from '@wix/document-manager-utils'

const getPageCompAfterPartition = (pages: Comp[]): Comp => {
    if (pages.length !== 1) {
        throw new ReportableError({
            errorType: errorTypes.INVALID_PAGE,
            message: `Expected exactly one page component but got ${pages.length}`,
            extras: {pagesCount: pages.length}
        })
    }
    return pages[0]!
}

const getCompKey = (comp: Comp): string => comp.nickname ?? comp.id

const removeQueries: CompOp = omit(_.values(COMP_DATA_QUERY_KEYS_WITH_STYLE))

const isOnlyWixCodeConnection: (value: Record<string, any>, key: string) => boolean = (value, key) => {
    return key === 'connections' && value?.length === 1 && value[0]?.type === 'WixCodeConnectionItem'
}

const removeRedundantKeys: CompOp = comp =>
    _(comp)
        .thru(removeQueries)
        .omitBy(_.isNil)
        .omitBy(isOnlyWixCodeConnection)
        .omit(['components', 'children', 'pageId', 'metaData', 'mobileMetaData', 'fixerVersions'])
        .value()

const removeId: CompOp = comp => _.omit(comp, 'id')

const replaceReferences = (comps: Comp[]): Comp[] => {
    const idsToNicknames = collectIdsToNicknamesMap(comps)
    return comps.map(replaceReferencesInComp(idsToNicknames))
}

const partitionToPageExport = ([pages, components]: Comp[][]): PageExport => ({
    page: getPageCompAfterPartition(pages),
    components: _.keyBy(components, getCompKey)
})

export const exportPage = (pageId: string, extensionApi: ExtensionAPI, pointers: Pointers): PageExport => {
    return _(getCompsInPage(pageId, extensionApi, pointers))
        .map(removeRedundantKeys)
        .thru(replaceReferences)
        .partition(isPage(pointers))
        .thru(partitionToPageExport)
        .thru(mapPageExport(removeId))
        .value()
}
