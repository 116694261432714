import _ from 'lodash'

function getPackagePath(packageName: string): [string, string] {
    return ['wixapps', packageName]
}

/**
 * @param {string} packageName
 * @returns {!Array.<string>}
 */
function getPackageDescriptorPath(packageName: string): string[] {
    return getPackagePath(packageName).concat(['descriptor'])
}

function getPackageMetaDataPath(packageName: string) {
    return _.compact(getPackagePath(packageName).concat(['metadata']))
}

function getAppPartDataPath(packageName: string, compId: string) {
    return getPackagePath(packageName).concat([compId])
}

function getAppPartItemsPath(packageName: string) {
    return getPackagePath(packageName).concat('items')
}

export default {
    getPackagePath,
    getPackageDescriptorPath,
    getPackageMetaDataPath,
    getAppPartDataPath,
    getAppPartItemsPath
}
