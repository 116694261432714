import consts from '../../constants/constants'
import siteGapMap from '../../structure/siteGapMap'

export default {
    resizableSides: [consts.RESIZE_SIDES.BOTTOM],
    moveDirections(ps) {
        const initialSiteGapMap = siteGapMap.getInitialGapMap(ps)
        return initialSiteGapMap && initialSiteGapMap.aboveHeader >= consts.SITE_SEGMENTS_GAP_THRESHOLD
            ? [consts.MOVE_DIRECTIONS.VERTICAL]
            : []
    },
    canBeFixedPosition: true,
    removable: false,
    duplicatable: false,
    containable: false,
    fullWidth: true,
    enforceContainerChildLimitsByHeight(ps, compPointer) {
        return !ps.pointers.components.isMobile(compPointer)
    },
    styleCanBeApplied(ps) {
        const isStudioUserPointer = ps.pointers.general.getIsStudioUser()
        return ps.dal.get(isStudioUserPointer)
    },
    hiddenable: false,
    collapsible: false,
    mobileConversionConfig: {
        preserveAspectRatio: false,
        minHeight: 90,
        topRightMargin: [90, 90],
        descendantImageScaleFactor: 0.7,
        siteSegmentRole: 'SITE_HEADER',
        category: 'siteSegments'
    }
}
