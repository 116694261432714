import type {CreateExtArgs, Extension, ExtensionAPI, InitializeExtArgs} from '@wix/document-manager-core'
import _ from 'lodash'
import {ReportableError} from '@wix/document-manager-utils'
import {extractBaseComponentId} from '../utils/refStructureUtils'
import type {DataModelExtensionAPI} from './dataModel'
import type {WixCodeConnectionItem, PossibleViewModes, Pointer} from '@wix/document-services-types'
import type {ConnectionsAPI} from './connections'
import {MASTER_PAGE_ID, NICKNAMES, COMP_TYPES} from '../constants/constants'
import type {ComponentsMetadataAPI} from './componentsMetadata/componentsMetadata'
import type {DalPointers} from '../types'
import type {WixCodeExtensionAPI} from './wixCode'
const {VALIDATIONS} = NICKNAMES
const MAX_NICKNAME_LENGTH = 128
type HasComponentWithThatNicknameFn = (
    dalPointers: DalPointers,
    containingPagePointer: Pointer,
    searchedNickname: string,
    compPointerToExclude: Pointer | undefined
) => boolean
export type SetNickname = (compPointer: Pointer, nickname: string) => void
export type ValidateNickname = (
    compPointer: Pointer,
    nickname: string,
    hasComponentWithThatNicknameFn?: HasComponentWithThatNicknameFn
) => void
export type GenerateNicknamesForComponent = (
    compPointer: Pointer,
    pagePointer: Pointer,
    viewMode?: PossibleViewModes
) => void
export type ShouldSetNickname = (compPointer: Pointer) => boolean
type UsedNickNames = {[x: string]: string} | undefined
type GetComponentNickname = (compPointer: Pointer, context?: Record<string, any>) => Record<string, string>
export interface NicknamesAPI {
    setNickname: SetNickname
    generateNicknamesForComponent: GenerateNicknamesForComponent
    shouldSetNickname: ShouldSetNickname
    validateNickname: ValidateNickname
    getComponentNickname: GetComponentNickname
}

export type NicknamesExtensionAPI = ExtensionAPI & {
    nicknames: NicknamesAPI
}

const createExtension = (): Extension => {
    const createExtensionAPI = ({pointers, extensionAPI, dal}: CreateExtArgs): NicknamesExtensionAPI => {
        const getWixCodeConnectionItem = (currentConnections: any): WixCodeConnectionItem | undefined => {
            return _.find(currentConnections, {type: 'WixCodeConnectionItem'})
        }
        function findConnectionByContext(compConnections: Record<string, any>, context?: Record<string, any>) {
            return context
                ? _.find(compConnections, {controllerRef: {id: context.id}})
                : getWixCodeConnectionItem(compConnections)
        }
        const getNicknameFromConnectionList = (compConnections: any, context?: Record<string, any>) => {
            const connectionItem = findConnectionByContext(compConnections, context)
            if (connectionItem) {
                return connectionItem.role
            }
        }
        const getNicknameByConnectionPointer = (
            connectionPtr: Pointer,
            pagePointer: Pointer,
            context?: Record<string, any>
        ) => {
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const connectionItem = dataModel.getItem(connectionPtr.id, 'connections', pagePointer.id)
            return getNicknameFromConnectionList(connectionItem.items, context)
        }

        const getRefComponentNicknameFromOverrides = (
            compPointer: Pointer,
            compNickname: string,
            context?: Record<string, any>
        ) => {
            const pagePointer = pointers.structure.getPageOfComponent(compPointer)
            return _(compPointer)
                .thru(pointers.referredStructure.getConnectionOverrides)
                .mapKeys((connectionPtr: Pointer) => extractBaseComponentId(connectionPtr))
                .mapValues((connectionPtr: Pointer) =>
                    getNicknameByConnectionPointer(connectionPtr, pagePointer, context)
                )
                .assign({[compPointer.id]: compNickname})
                .pickBy()
                .value()
        }

        function getComponentNickname(compPointer: Pointer, context?: Record<string, any>) {
            const {connections} = extensionAPI as ConnectionsAPI
            const compConnections = connections.get(compPointer)

            const nickname = getNicknameFromConnectionList(compConnections, context)
            const compData = dal.get(compPointer)
            const componentType = _.get(compData, ['componentType'])

            if (componentType === COMP_TYPES.REF_TYPE) {
                return getRefComponentNicknameFromOverrides(compPointer, nickname!, context)
            }
            return nickname ? {[compPointer.id]: nickname} : {}
        }

        const getNicknames = (comps: Pointer[]) => {
            return _(comps)
                .map(c => getComponentNickname(c))
                .reduce(_.assign)
        }

        const getPagePointersInSameContext = (pagePointer: Pointer) => {
            const viewMode = pagePointer.type
            if (pagePointer.id === 'masterPage') {
                const nonDeletedPagesPointers = pointers.page.getNonDeletedPagesPointers(true)
                return _.map(nonDeletedPagesPointers, nonDeletedPagePointer =>
                    pointers.components.getPage(nonDeletedPagePointer.id, viewMode)
                )
            }
            return [pagePointer, pointers.structure.getMasterPage(viewMode)]
        }

        const hasInvalidCharacters = (nickname: string) => {
            const validName = /^[a-zA-Z0-9]+$/
            return !validName.test(nickname)
        }

        const hasComponentWithThatNickname = (
            containingPagePointer: Pointer,
            searchedNickname: string,
            compPointerToExclude: Pointer | undefined
        ) => {
            if (!searchedNickname) {
                return false
            }

            compPointerToExclude = compPointerToExclude
                ? pointers.structure.getComponent(compPointerToExclude.id, containingPagePointer)
                : undefined
            const pagesSharingNicknames = getPagePointersInSameContext(containingPagePointer)
            return _.some(pagesSharingNicknames, pagePointer => {
                const compsInPage = pointers.structure.getChildrenRecursivelyRightLeftRootIncludingRoot(pagePointer)
                return !_(compsInPage)
                    .thru(comps => getNicknames(comps))
                    .pickBy((nickname: string, compId: string) =>
                        compPointerToExclude ? compPointerToExclude.id !== compId : true
                    )
                    .pickBy((nickname: string) => nickname === searchedNickname)
                    .isEmpty()
            })
        }

        const validateNickname = (compPointer: Pointer, nickname: string, hasComponentWithThatNicknameFn?: any) => {
            if (_.isEmpty(nickname)) {
                return VALIDATIONS.TOO_SHORT
            }
            if (nickname.length > MAX_NICKNAME_LENGTH) {
                return VALIDATIONS.TOO_LONG
            }
            const _hasComponentWithThatNicknameFn = hasComponentWithThatNicknameFn || hasComponentWithThatNickname
            const componentPagePointer = pointers.structure.getPageOfComponent(compPointer)
            if (_hasComponentWithThatNicknameFn(componentPagePointer, nickname, compPointer)) {
                return VALIDATIONS.ALREADY_EXISTS
            }

            if (hasInvalidCharacters(nickname)) {
                return VALIDATIONS.INVALID_NAME
            }
            return VALIDATIONS.VALID
        }

        const setNickname = (compPointer: Pointer, nickname: string) => {
            if (validateNickname(compPointer, nickname) !== VALIDATIONS.VALID) {
                throw new ReportableError({
                    errorType: 'invalidNickname',
                    message: 'The new nickname you provided is invalid'
                })
            }
            const {connections} = extensionAPI as ConnectionsAPI
            let currentConnections = connections.get(compPointer)
            const connection = getWixCodeConnectionItem(currentConnections)
            if (connection) {
                connection.role = nickname
            } else {
                const newConnectionItem = connections.createWixCodeConnectionItem(nickname)
                currentConnections = currentConnections!.concat([newConnectionItem])
            }

            connections.updateConnectionsItem(compPointer, currentConnections!)
        }

        const getComponentsInContainer = (containerPointer: Pointer) =>
            pointers.structure.getChildrenRecursivelyRightLeftRootIncludingRoot(containerPointer)

        const getNextSuffixIndex = (compNickname: string, compsNicknames: UsedNickNames) => {
            const regex = new RegExp(`${compNickname}(\\d+)`) //will match the number in the end of the nickname
            const maxSuffixOfDefaultNickname = _(compsNicknames)
                .map(nickname => {
                    const match = regex.exec(nickname)
                    return match ? _.parseInt(match[1]) : null
                })
                .concat(0)
                .max()

            return maxSuffixOfDefaultNickname! + 1
        }

        const shouldSetNickname = (compPointer: Pointer) => {
            const {componentsMetadata} = extensionAPI as ComponentsMetadataAPI
            return (
                !pointers.structure.isMasterPage(compPointer) &&
                !getComponentNickname(compPointer)[compPointer.id] &&
                componentsMetadata.shouldAutoSetNickname(compPointer)
            )
        }

        const generateNicknamesForComponentsImpl = (compPointer: Pointer, usedNickNames: UsedNickNames) => {
            const {componentsMetadata} = extensionAPI as ComponentsMetadataAPI
            const defaultCompNickname = componentsMetadata.getDefaultNickname(compPointer)
            let maxSuffixOfDefaultNickname = getNextSuffixIndex(defaultCompNickname, usedNickNames)
            const newNickname = defaultCompNickname + maxSuffixOfDefaultNickname++
            setNickname(compPointer, newNickname)
        }
        const generateNicknamesForComponent = (
            compPointer: Pointer,
            pagePointer: Pointer,
            viewMode: PossibleViewModes = 'DESKTOP'
        ) => {
            const masterPagePointer = pointers.structure.getPage('masterPage', viewMode)
            if (pagePointer.id === MASTER_PAGE_ID) {
                throw Error('Cannot add currently add to master page')
            }
            const allCompsInContext = getComponentsInContainer(pagePointer).concat(
                getComponentsInContainer(masterPagePointer)
            )
            const usedNicknames = getNicknames(allCompsInContext)
            generateNicknamesForComponentsImpl(compPointer, usedNicknames)
        }

        return {
            nicknames: {
                shouldSetNickname,
                getComponentNickname,
                generateNicknamesForComponent,
                setNickname,
                validateNickname
            }
        }
    }

    const initialize = async (extArgs: InitializeExtArgs) => {
        const {eventEmitter, EVENTS, extensionAPI} = extArgs
        const addNicknameIfNeeded = (compPointer: Pointer, pagePointer: Pointer) => {
            const {wixCode} = extensionAPI as WixCodeExtensionAPI
            const {nicknames} = extensionAPI as NicknamesExtensionAPI
            if (wixCode.isProvisioned() && nicknames.shouldSetNickname(compPointer)) {
                nicknames.generateNicknamesForComponent(compPointer, pagePointer)
            }
        }

        eventEmitter.on(EVENTS.COMPONENTS.AFTER_ADD, (componentPointer: Pointer, pagePointer: Pointer) =>
            addNicknameIfNeeded(componentPointer, pagePointer)
        )
    }

    return {
        name: 'nicknames',
        dependencies: new Set(['dataModel', 'components']),
        createExtensionAPI,
        initialize
    }
}

export {createExtension}
