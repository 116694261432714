import type {Pointer, Pointers, PS} from '@wix/document-services-types'
import _ from 'lodash'
import documentModeInfo from '../documentMode/documentModeInfo'
import * as coreUtils from '@wix/santa-ds-libs/src/coreUtils'
import * as santaCoreUtils from '@wix/santa-core-utils'

const MODES_WITH_MANUAL_OVERRIDES = {}
MODES_WITH_MANUAL_OVERRIDES[santaCoreUtils.siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES] = true

function getMasterPageModeDefinitions(ps: PS) {
    const viewMode = documentModeInfo.getViewMode(ps)
    const masterPagePointer = ps.pointers.components.getMasterPage(viewMode)
    const modesPointer = ps.pointers.componentStructure.getModes(masterPagePointer)

    if (!modesPointer) {
        return []
    }

    return _.get(ps.dal.get(modesPointer), 'definitions', [])
}

function isModeOverrideAffective(supportedModeIds, override) {
    return _.every(override.modeIds, modeId => !!supportedModeIds[modeId])
}

function getCompDefinitions(pointers, fullJsonDal, compPointer) {
    const definitionsPointer = pointers.componentStructure.getModesDefinitions(compPointer)

    return fullJsonDal.get(definitionsPointer)
}

function getAllModeIdsOfAncestors(pointers: Pointers, fullJsonDal, currentAncestorPointer) {
    let modeIdsInAncestors = {}
    while (currentAncestorPointer !== null) {
        const modeDefinitions = getCompDefinitions(pointers, fullJsonDal, currentAncestorPointer)
        const modeIdsOfComp = _.map(modeDefinitions, 'modeId')
        modeIdsInAncestors = _.merge(modeIdsInAncestors, _.zipObject(modeIdsOfComp, modeIdsOfComp))
        currentAncestorPointer = pointers.components.getParent(currentAncestorPointer)
    }

    return modeIdsInAncestors
}

function getRootActiveModesSet(pointers: Pointers, displayedJsonDal, rootId) {
    const activeModesPointer = pointers.general.getActiveModes()
    const activeModes = displayedJsonDal.get(activeModesPointer)
    if (rootId && activeModes[rootId]) {
        return _.omitBy(activeModes[rootId], val => !val)
    }

    return {}
}

function removeUnusedOverridesInComponentTree(pointers: Pointers, fullJsonDal, fullCompStructure, newParentPointer) {
    const res = santaCoreUtils.objectUtils.cloneDeep(fullCompStructure)
    let modeIds = getAllModeIdsOfAncestors(pointers, fullJsonDal, newParentPointer)

    let compsToScan = [res]
    while (!_.isEmpty(compsToScan)) {
        const comp = compsToScan.pop()
        const modes = _.get(comp, 'modes.definitions')
        const modeIdsInComp = _.map(modes, 'modeId')
        modeIds = _.merge(modeIds, _.zipObject(modeIdsInComp, modeIdsInComp))

        const overrides = _.get(comp, 'modes.overrides')
        if (overrides) {
            const affectiveOverrides = _.filter(overrides, _.partial(isModeOverrideAffective, modeIds))
            _.set(comp, 'modes.overrides', affectiveOverrides)
            if (_.isEmpty(affectiveOverrides)) {
                delete comp.modes.isHiddenByModes
            }
        }

        compsToScan = compsToScan.concat(comp.components || [])
    }

    return res
}

function getSOSPModes(ps: PS) {
    const masterPageModes = getMasterPageModeDefinitions(ps)
    return _.filter(masterPageModes, {type: santaCoreUtils.siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES})
}

function getSospModeByPagesGroup(ps: PS, pagesGroupPointer: Pointer) {
    if (!ps.dal.isExist(pagesGroupPointer)) {
        return undefined
    }
    const sospModes = getSOSPModes(ps)
    return _.find(sospModes, ['settings.pagesGroupId', `#${pagesGroupPointer.id}`])
}

/**
 * @return {{isHiddenByModes: boolean, overrides: *[], definitions: *[]}}
 */
function createEmptyModesObject() {
    return {
        isHiddenByModes: false,
        definitions: [],
        overrides: []
    }
}

function getFirstAncestorWithModesToGenerateOverrides(
    pointers: Pointers,
    fullJsonDal,
    displayedJsonDal,
    compPointer: Pointer,
    isSelfIncluded
) {
    if (!compPointer) {
        return null
    }

    let ancestorPointer = isSelfIncluded ? compPointer : pointers.full.components.getParent(compPointer)
    while (ancestorPointer) {
        if (
            !_.isEmpty(getComponentModesToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, ancestorPointer))
        ) {
            return ancestorPointer
        }
        ancestorPointer = pointers.components.getParent(ancestorPointer)
    }

    return null
}

function getFirstAncestorModeToGenerateOverrides(
    pointers: Pointers,
    fullJsonDal,
    displayedJsonDal,
    compPointer: Pointer,
    isSelfIncluded?
) {
    const ancestorWithActiveModes = getFirstAncestorWithModesToGenerateOverrides(
        pointers,
        fullJsonDal,
        displayedJsonDal,
        compPointer,
        isSelfIncluded
    )
    if (ancestorWithActiveModes) {
        return _.head(
            getComponentModesToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, ancestorWithActiveModes)
        )
    }
    return null
}

function createModesObjectForCompAddedInMode(activeModes, existingDefaultLayout) {
    const modes = createEmptyModesObject()
    modes.isHiddenByModes = true
    modes.overrides.push({
        modeIds: activeModes,
        isHiddenByModes: false,
        layout: existingDefaultLayout
    })

    return modes
}

function getComponentModesToGenerateOverrides(pointers: Pointers, fullJsonDal, displayedJsonDal, compPointer: Pointer) {
    const containingRootPointer = pointers.components.getPageOfComponent(compPointer)
    if (containingRootPointer) {
        const rootActiveModes = getRootActiveModesSet(pointers, displayedJsonDal, containingRootPointer.id)
        const compModesDefPointer = pointers.componentStructure.getModesDefinitions(compPointer)

        const modesToGenerateOverrides = _.reject(
            fullJsonDal.get(compModesDefPointer),
            modeDef => MODES_WITH_MANUAL_OVERRIDES[modeDef.type]
        )

        return _.keys(coreUtils.modesUtils.getActiveComponentModeIds(rootActiveModes, modesToGenerateOverrides))
    }

    return []
}

function adjustModesToNewContainer(
    pointers: Pointers,
    fullJsonDal,
    displayedJsonDal,
    newContainerPointer: Pointer,
    compStructure
) {
    compStructure = removeUnusedOverridesInComponentTree(pointers, fullJsonDal, compStructure, newContainerPointer)
    const ancestorActiveMode =
        getFirstAncestorModeToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, newContainerPointer) ??
        _.head(getComponentModesToGenerateOverrides(pointers, fullJsonDal, displayedJsonDal, newContainerPointer))

    if (ancestorActiveMode) {
        const initializedModes = createModesObjectForCompAddedInMode([ancestorActiveMode], compStructure.layout)
        if (compStructure.modes) {
            compStructure.modes.isHiddenByModes = true
            compStructure.modes.overrides = compStructure.modes.overrides || []
            const matchingOverride = _.find(compStructure.modes.overrides, override =>
                _.isEqual(override.modeIds, [ancestorActiveMode])
            )
            if (matchingOverride) {
                matchingOverride.isHiddenByModes = false
            } else {
                compStructure.modes.overrides.push(initializedModes.overrides[0])
            }
        } else {
            compStructure.modes = initializedModes
        }
    }

    return compStructure
}

export default {
    adjustModesToNewContainer,
    getSospModeByPagesGroup,
    getSOSPModes
}
