export class Stack<T> {
    private _stack: T[] = []

    constructor(private readonly _name: string) {}

    push(item: T): void {
        this._stack.push(item)
    }

    pop(): T | undefined {
        return this._stack.pop()
    }

    top(): T | undefined {
        const len = this._stack.length
        return len ? this._stack[len - 1] : undefined
    }

    isEmpty(): boolean {
        return this._stack.length === 0
    }

    clear(): void {
        this._stack = []
    }

    size(): number {
        return this._stack.length
    }

    toString() {
        return `${this._name} stack:\n${this._stack.map(e => JSON.stringify(e)).join('\n')}`
    }
}
