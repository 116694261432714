import {CreateExtArgs, CreateExtensionArgument, Extension, ExtensionAPI, pointerUtils} from '@wix/document-manager-core'
import type {
    CompRef,
    ScopeMetaData,
    ScopeMetaDataTemplate,
    PlatformMetaDataTemplate,
    PlatformMetaData,
    ScopePointer
} from '@wix/document-services-types'
import _ from 'lodash'
import {getInflatedPointer, getScopedPointer} from '../utils/scopesUtils'
import {getPlatformData} from '../utils/structureMetaDataUtils'
import type {DataModelExtensionAPI} from './dataModel'

export interface StructureMetaDataExtensionAPI extends ExtensionAPI {
    structureMetaData: {
        getMetaDataFromTemplate(
            metaDataTemplate: ScopeMetaDataTemplate,
            scopeOwner: CompRef,
            scopePointer: ScopePointer
        ): ScopeMetaData
        getPlatformMetaDataFromTemplate(
            rootPointer: CompRef,
            platformTemplate?: PlatformMetaDataTemplate
        ): PlatformMetaData | undefined
        getMetaDataTemplate(root: CompRef): ScopeMetaDataTemplate
        getMetaData(root: CompRef, scopeOwner: CompRef, scopePointer: ScopePointer): ScopeMetaData
    }
}

const createExtension = ({dsConfig}: CreateExtensionArgument): Extension => {
    const createExtensionAPI = ({dal, extensionAPI, pointers}: CreateExtArgs): StructureMetaDataExtensionAPI => {
        function getMetaData(root: CompRef, scopeOwner: CompRef, scopePointer: ScopePointer): ScopeMetaData {
            const metaDataTemplate = getMetaDataTemplate(root)

            return getMetaDataFromTemplate(metaDataTemplate, scopeOwner, scopePointer)
        }

        function getMetaDataFromTemplate(
            {rootId, rootType, platform}: ScopeMetaDataTemplate,
            scopeOwner: CompRef,
            scopePointer: ScopePointer
        ): ScopeMetaData {
            const scopedRootPointer = getScopedPointer(rootId, scopeOwner.type, _.cloneDeep(scopePointer)) as CompRef
            const rootPointer = getInflatedPointer(scopedRootPointer, dsConfig.enableRepeatersInScopes)

            return {
                rootPointer,
                rootType,
                platform: getPlatformMetaDataFromTemplate(rootPointer, platform)
            }
        }

        function getPlatformMetaDataFromTemplate(
            rootPointer: CompRef,
            platformTemplate?: PlatformMetaDataTemplate
        ): PlatformMetaData | undefined {
            if (!platformTemplate) {
                return
            }

            const appStatePointer = pointers.platform.getAppStatePointer()
            const controllerStatePointer = pointerUtils.getInnerPointer(appStatePointer, [rootPointer.id])

            return {
                ...platformTemplate,
                controllerStatePointer
            }
        }

        function getMetaDataTemplate(root: CompRef): ScopeMetaDataTemplate {
            const comp = dal.get(root)
            const {dataModel} = extensionAPI as DataModelExtensionAPI
            const compData = dataModel.components.getItem(root, 'data')
            const platform = getPlatformData(compData)

            return {
                rootId: root.id,
                rootType: comp.componentType,
                platform
            }
        }

        return {
            structureMetaData: {
                getMetaDataFromTemplate,
                getPlatformMetaDataFromTemplate,
                getMetaDataTemplate,
                getMetaData
            }
        }
    }

    return {
        name: 'structureMetaData',
        createExtensionAPI,
        dependencies: new Set(['dataModel', 'platform'])
    }
}

export {createExtension}
